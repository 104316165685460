/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddBusinessUnit, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const BusinessUnit = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDBusinessUnit, setLzDDBusinessUnit] = useRecoilState(ddBusinessUnit);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const businessUnitApi = useMutation(() => axios.get(endPoints.landingZones.businessUnit));

    const setBusinessUnit = (businessUnit) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                businessUnit,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setBusinessUnit(null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setBusinessUnit(selected?.label);
        } else {
            setBusinessUnit(null);
        }
    };
    React.useEffect(() => {
        if (businessUnitApi?.isSuccess) {
            setOptions(businessUnitApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
            setLzDDBusinessUnit({ ...lzDDBusinessUnit, isLoaded: true });
        }
    }, [businessUnitApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const businessUnit = options.find((value) => lzModal?.lzDetails?.config?.businessUnit === value?.label);
            if (businessUnit) setLzDDBusinessUnit({ ...lzDDBusinessUnit, isLoaded: true, data: businessUnit });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        businessUnitApi.mutate();
    }, []);
    return (
        <Box bgcolor="white">
            <Select
                key="businessUnit"
                value={landZoneForm?.businessUnit || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={businessUnitApi.isLoading}
                isLoading={businessUnitApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Business Unit"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(BusinessUnit);
