import { orderStatus } from 'utils/orderStatus';

export default {
    [orderStatus[1]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Completed',
            status: 'wait',
        },
    ],

    [orderStatus[4]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Completed',
            status: 'done',
        },
    ],
    [orderStatus[11]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Failed',
            status: 'clear',
        },
    ],
    [orderStatus[6]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'System Approval',
            status: 'done',
        },
        {
            name: 'Completed',
            status: 'hourglass',
        },
    ],
};
