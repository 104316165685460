import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import { orderPayload } from 'pages/mdt-onboarding/store';
import { CssTextField } from 'components/select';

const ApplicationOwner = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const appOwnerApi = useMutation(() => axios.get(`${endPoints.mdt.appOwner}?key=${ccInputValue}`));
    const appOwnerPostApi = useMutation((payload) => axios.get(`${endPoints.mdt.appOwner}?key=${payload}`));

    const setAppOwner = (appOwner, appOwnerDetails) => {
        setOrder({
            ...order,
            appOwner,
            appOwnerDetails,
        });
    };
    React.useEffect(() => {
        if (!order?.appOwner && !order?.appOwnerDetails) {
            setOptions([]);
        }
    }, [order?.appOwner, order?.appOwnerDetails]);

    const resetOptions = () => {
        setOptions([]);
        setAppOwner(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    };
    const isExistsInOptions = (appownerValue) => options.find((option) => appownerValue === option.label);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.appOwnerDetails) {
            setOrder({ ...order, appOwner: options?.[0].label, appOwnerDetails: options?.[0] });
        }
        if (options && options?.length && order?.appOwnerDetails) {
            const appOwerObj = isExistsInOptions(order?.appOwner);
            setOrder({ ...order, appOwner: appOwerObj?.label, appOwnerDetails: appOwerObj });
        }
    }, [options]);

    React.useEffect(() => {
        if (appOwnerApi?.data?.data) {
            const combinedValue = appOwnerApi?.data?.data?.map((app) => ({
                label: `${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (appOwnerPostApi?.data?.data) {
            const combinedValue = appOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setAppOwner(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (order?.appOwner && !order?.appOwnerDetails) {
            appOwnerPostApi.mutate(order?.appOwner);
        }
    }, [order?.appOwner, order?.appOwnerDetails]);

    React.useEffect(() => {
        if (!order?.appOwner && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appOwner, order?.isTouched]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.appOwner) || isTouched) {
            return 'validate';
        }
        return '';
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="app-owner-search">
            <Autocomplete
                key="appOwnerDetails"
                size="small"
                loading={appOwnerApi?.isLoading}
                value={order?.appOwner || null}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue) setCCInputValue(newInputValue);
                }}
                className={validateInput()}
                onChange={(event, newValue) => {
                    // istanbul ignore else
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                //    filterOptions={filterOptions}

                filterOptions={(optionValues, params) => {
                    // eslint-disable-next-line no-underscore-dangle
                    const _results = [];
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < optionValues.length; i++) {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const key in optionValues[i]) {
                            if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                    _results.push(optionValues[i]);
                                }
                            }
                        }
                    }
                    return [...new Set(_results)];
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableClearable
                id="free-solo-with-text-demo"
                options={options}
                isOptionEqualToValue={(option, value1) => option.id === value1.id}
                sx={{ width: '100%' }}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        resetOptions();
                    }
                }}
                renderInput={(params) => (
                    <CssTextField
                        sx={{
                            '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                                paddingRight: '10px',
                            },
                        }}
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Application Owner * "
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    <SearchIcon sx={{ cursor: 'pointer', color: '#0047BA' }} onClick={() => handleSearch()} data-testid="search-btn" />
                                </>
                            ),
                        }}
                        fullWidth
                    />
                )}
            />
            <HelpImg title="Search Application Owner" />
        </Box>
    );
};

export default React.memo(ApplicationOwner);
