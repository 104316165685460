import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import { endPoints } from 'chatbot/components/api/endpoints';
import chatBotStyle from '../style';

const Feedback = (props) => {
    const { actions, state } = props;
    const [ratingValue, setRatingValue] = React.useState(0);
    const [comments, setComments] = React.useState('');
    const [showFeedback, setShowFeedback] = React.useState(true);
    const [showFbResponse, setShowFbResponse] = React.useState(false);

    const handleOnChange = (value) => {
        setComments(value);
    };
    const handleClick = async () => {
        try {
            await axios.post(
                endPoints.feedback,
                {
                    comments,
                    score: ratingValue,
                    userid: state?.userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${state?.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setShowFbResponse(true);
        } catch (error) {
            actions.displayErrorAction(error?.message || 'Please try again later');
        }
        setShowFeedback(false);
    };

    React.useEffect(() => {
        setShowFbResponse(false);
    }, []);

    return (
        <>
            {showFeedback && (
                <Grid container sx={chatBotStyle.feedbackContainer}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        Please provide your valuable feedback
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Rating
                            name="simple-controlled"
                            value={ratingValue}
                            size="large"
                            onChange={(event, newValue) => {
                                setRatingValue(newValue);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.2} />

                    <Grid item xs={6.8}>
                        <TextField
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '27px',
                                    fontSize: '12px',
                                },
                            }}
                            placeholder="Optional comments"
                            id="outlined-basic"
                            onChange={(e) => handleOnChange(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <Button variant="contained" disabled={!ratingValue} size="small" style={{ marginLeft: '5px', height: '26px' }} onClick={handleClick}>
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            )}
            {showFbResponse && <Box sx={{ marginLeft: '35px' }}>Thank you for your valuable feedback!</Box>}
        </>
    );
};

Feedback.propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
};

Feedback.defaultProps = {
    actions: {},
    state: {},
};

export default Feedback;
