import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const usersCount = atom({
    key: 'usersCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const publishedCatalogsCount = atom({
    key: 'publishedCatalogsCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const applicationCount = atom({
    key: 'applicationCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const ordersSubmittedCount = atom({
    key: 'ordersSubmittedCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const ordersFulfilledCount = atom({
    key: 'ordersFulfilledCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const provisionedOrdersCount = atom({
    key: 'provisionedOrdersCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const orderCreatedWithCMP = atom({
    key: 'orderCreatedWithCMP',
    default: [],
});

export const applicationOnBoard = atom({
    key: 'applicationOnBoard',
    default: [],
});

export const orderCreationTrend = atom({
    key: 'orderCreationTrend',
    default: [],
});
