import React, { useEffect, Suspense } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { selectOrder } from 'pages/orders/store';
import ProvisioningStyles from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

export const SubmitForApproval = (props) => {
    const { isBgApiLoading } = props;
    const { cartItemId, operation, catalogServiceId } = useParams();
    const history = useHistory();
    const [, setSelectedOrderId] = useRecoilState(selectOrder);
    const submitForApproval = useMutation((payload) => axios.post(`${endPoints.riServices.RI002.Jwt.submitApproval}/${payload?.orderId}`));

    const handleRedirect = async () => {
        await setSelectedOrderId(cartItemId);
        history.push('/orders');
    };

    const handleSubmit = async () => {
        const reSubmitPayload = { orderId: cartItemId };
        submitForApproval.mutate(reSubmitPayload);
    };

    useEffect(() => {
        if (submitForApproval?.data?.isSuccess) {
            toast.success(`${submitForApproval?.data?.messages?.[0]}`);
            handleRedirect();
        }
    }, [submitForApproval?.isSuccess]);

    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {cartItemId && operation && catalogServiceId && (
                    <Btn
                        size="medium"
                        onClick={() => handleSubmit()}
                        variant="contained"
                        disabled={submitForApproval?.isLoading || isBgApiLoading}
                        sx={{ ...ProvisioningStyles?.CostSummary?.button, width: '185px' }}
                        color="cmpPrimary2"
                        data-testid="save-changes-btn"
                    >
                        {submitForApproval?.isLoading && !isBgApiLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit for Approval'}
                    </Btn>
                )}
            </Box>
        </Suspense>
    );
};

SubmitForApproval.propTypes = {
    isBgApiLoading: PropTypes.bool,
};

SubmitForApproval.defaultProps = {
    isBgApiLoading: false,
};

export default React.memo(SubmitForApproval);
