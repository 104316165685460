export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        billingEntity: catalogConfig?.billingEntity || null,
        sector: data?.itemDetail?.catalogService?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        dataFactoryName: catalogConfig?.resource?.name || null,
        dataFactoryVersion: catalogConfig?.resource?.version || null,
        tagsQc: { ...catalogConfig?.tags },
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
        isADFNameValid: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        dataFactoryName: catalogConfig?.resource?.name || null,
        dataFactoryVersion: catalogConfig?.resource?.version || null,
        tagsQc: { ...catalogConfig?.tags },
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
        isADFNameValid: false,
    };
};

export const resetCostEstimateDetails = {
    details: [],
    qty: 1,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: 0,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: 0,
        currency: 'USD',
    },
    notes: "ADF Workspace provision doesn't have any cost associated. However, the costing model is based on the Datasets, Linked Services, Pipelines, Integration Runtime, SHIR, Triggers and Debug Runs. Further details please reachout to DL-ITAdvancedAnalyticsPlatformSupport@pepsico.com",
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    hCode: null,
    hCodeDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    landingZone: null,
    landingZoneDetails: null,
    dataFactoryName: null,
    dataFactoryVersion: null,
    envDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
    validationStatus: 'clearValidation',
    isTouched: false,
    isADFNameValid: false,
};

export const resetForQuickConfig = {
    environment: null,
    region: null,
    regionDisplayName: null,
    hCode: null,
    hCodeDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    landingZone: null,
    landingZoneDetails: null,
    dataFactoryName: null,
    dataFactoryVersion: null,
    envDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
    validationStatus: 'clearValidation',
    isTouched: false,
    isADFNameValid: false,
};

export const resetEnvironmentDetails = {
    subscription: '-',
    resource_group: '-',
    Approval_Manager: '-',
    Project: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isDiskOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
};
