import { selector } from 'recoil';
import { sspSubmitOrder } from './index';

export const addEventHubDetails = selector({
    key: 'eventhub_addEventHubDetails',
    get: ({ get }) => get(sspSubmitOrder).eventHubDetails,
    set: ({ set, get }, count) => {
        const currentEventHubDetails = get(sspSubmitOrder).eventHubDetails;
        let eventHubDetails = [{ eventHubName: '', partitionCount: 1, retention: null, consumerGroupList: [{ value: '' }] }];
        eventHubDetails = [
            ...currentEventHubDetails,
            ...Array(count.count).fill({
                eventHubName: '',
                partitionCount: 1,
                retention: null,
                isTouched: false,
                consumerGroupList: [{ value: '' }],
            }),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            eventHubDetails,
        });
    },
});

export const removeEventHubDetails = selector({
    key: 'eventhub_removeEventHubDetails',
    get: ({ get }) => get(sspSubmitOrder).eventHubDetails,
    set: ({ set, get }, eventHubDataToRemove) => {
        const currentEventHubDetails = get(sspSubmitOrder).eventHubDetails;
        const eventHubDataToRemoveIndex = currentEventHubDetails.findIndex((val) => val === eventHubDataToRemove);
        const eventHubDetails = [...currentEventHubDetails.slice(0, eventHubDataToRemoveIndex), ...currentEventHubDetails.slice(eventHubDataToRemoveIndex + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            eventHubDetails,
        });
    },
});

export const updateEventHubDetails = selector({
    key: 'eventhub-updateEventHubDetails',
    get: ({ get }) => get(sspSubmitOrder).eventHubDetails,
    set: ({ set, get }, eventHubDataToUpdate) => {
        const currentEventHubDetails = get(sspSubmitOrder).eventHubDetails;
        const eventHubDataToUpdateIndex = currentEventHubDetails.findIndex((val) => val === eventHubDataToUpdate.index);
        const updatedEventHubData = { ...eventHubDataToUpdate.index, [eventHubDataToUpdate.key]: eventHubDataToUpdate.selected };
        const eventHubDetails = [
            ...currentEventHubDetails.slice(0, eventHubDataToUpdateIndex),
            updatedEventHubData,
            ...currentEventHubDetails.slice(eventHubDataToUpdateIndex + 1),
        ];
        set(sspSubmitOrder, { ...get(sspSubmitOrder), eventHubDetails });
    },
});

export const addConsumerGroup = selector({
    key: 'eventhub_addConsumerGroup',
    get: ({ get }) => get(sspSubmitOrder).eventHubDetails,
    set: ({ set, get }, params) => {
        const eventHubDetails = get(sspSubmitOrder)?.eventHubDetails;
        const currentConsumerList = get(sspSubmitOrder).eventHubDetails[params?.indexVal]?.consumerGroupList;
        let updatedConsumerList = [{ value: '' }];
        updatedConsumerList = [
            ...currentConsumerList,
            ...Array(params.count).fill({
                value: '',
            }),
        ];
        const eventHubUpdatedConsumerList = { ...eventHubDetails[params?.indexVal], consumerGroupList: updatedConsumerList };
        const updatedEventHubDetails = [
            ...eventHubDetails.slice(0, params?.indexVal),
            eventHubUpdatedConsumerList,
            ...eventHubDetails.slice(params?.indexVal + 1),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            eventHubDetails: updatedEventHubDetails,
        });
    },
});

export const removeConsumerGroup = selector({
    key: 'eventhub_removeConsumerGroup',
    get: ({ get }) => get(sspSubmitOrder).eventHubDetails,
    set: ({ set, get }, params) => {
        const eventHubDetails = get(sspSubmitOrder)?.eventHubDetails;
        const currentConsumerList = get(sspSubmitOrder).eventHubDetails[params?.indexVal]?.consumerGroupList;
        const updatedConsumerList = [...currentConsumerList?.slice(0, params.index), ...currentConsumerList?.slice(params.index + 1)];
        const eventHubUpdatedConsumerList = { ...eventHubDetails[params?.indexVal], consumerGroupList: updatedConsumerList };
        const updatedEventHubDetails = [
            ...eventHubDetails.slice(0, params?.indexVal),
            eventHubUpdatedConsumerList,
            ...eventHubDetails.slice(params?.indexVal + 1),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            eventHubDetails: updatedEventHubDetails,
        });
    },
});

export const updateConsumerGroup = selector({
    key: 'eventhub_updateConsumerGroup',
    get: ({ get }) => get(sspSubmitOrder).eventHubDetails,
    set: ({ set, get }, params) => {
        const eventHubDetails = get(sspSubmitOrder)?.eventHubDetails;
        const currentConsumerList = get(sspSubmitOrder).eventHubDetails[params?.indexVal]?.consumerGroupList;
        const updatedConsumer = { ...currentConsumerList[params?.index], [params?.key]: params.inputValue };
        const updatedConsumerList = [...currentConsumerList.slice(0, params?.index), updatedConsumer, ...currentConsumerList.slice(params?.index + 1)];
        const eventHubUpdatedConsumerList = { ...eventHubDetails[params?.indexVal], consumerGroupList: updatedConsumerList };
        const updatedEventHubDetails = [
            ...eventHubDetails.slice(0, params?.indexVal),
            eventHubUpdatedConsumerList,
            ...eventHubDetails.slice(params?.indexVal + 1),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            eventHubDetails: updatedEventHubDetails,
        });
    },
});
