import { selector } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';

export const addRoles = selector({
    key: 'ssoOkta_addRoles',
    get: ({ get }) => get(OrderPayload).roles,
    set: ({ set, get }, count) => {
        const currentRolesList = get(OrderPayload).roles;
        let roles = [null];
        roles = [...currentRolesList, ...Array(count.count).fill({ role: '' })];

        set(OrderPayload, {
            ...get(OrderPayload),
            roles,
        });
    },
});

export const removeRoles = selector({
    key: 'ssoOkta_removeRoles',
    get: ({ get }) => get(OrderPayload).roles,
    set: ({ set, get }, adGroupToRemove) => {
        const currentRolesList = get(OrderPayload).roles;
        const removeIndex = currentRolesList.findIndex((val) => val === adGroupToRemove);
        const roles = [...currentRolesList.slice(0, removeIndex), ...currentRolesList.slice(removeIndex + 1)];
        set(OrderPayload, {
            ...get(OrderPayload),
            roles,
        });
    },
});

export const updateRoles = selector({
    key: 'ssoOkta_updateRoles',
    get: ({ get }) => get(OrderPayload).roles,
    set: ({ set, get }, params) => {
        const currentRolesList = get(OrderPayload).roles;
        if (params?.key === 'inputChange') {
            const updatedRoles = { ...currentRolesList[params?.index], role: params.selectedValue };
            const roles = [...currentRolesList.slice(0, params?.index), updatedRoles, ...currentRolesList.slice(params?.index + 1)];
            set(OrderPayload, { ...get(OrderPayload), roles });
        }
    },
});
