/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const LandingZones = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const landingZoneApi = useMutation((payload) => axios.post(`${endPoints.landingZones.landingZones}`, payload));
    const setLandingZone = (landingZone, landingZoneDetails) => {
        setOrder({
            ...order,
            landingZone,
            landingZoneDetails,
            nsExist: 'no',
            namespaceName: null,
            performance: 'Standard',
            throughputUnits: '1',
            isAutoInflate: 'no',
            autoInflateMaxThroughputUnits: null,
            processingUnits: '1',
            eventHubDetails: [
                {
                    eventHubName: '',
                    partitionCount: 1,
                    retention: null,
                    isEventHubTouched: false,
                    consumerGroupList: [{ value: '' }],
                },
            ],
            cartMessage: false,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    async function setPreconfig() {
        if (order?.landingZoneDetails) {
            const checkExist = await options.find((option) => order?.landingZoneDetails?.id === option?.data?.id);
            if (checkExist) {
                await setOrder({ ...order, landingZone: checkExist?.value, landingZoneDetails: checkExist?.data });
            }
        }
    }
    const seldValueExistsInOptions = (landingZoneValue) => options.find((option) => landingZoneValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== order?.landingZone && seldValueExistsInOptions(selected?.value)) {
            if (order?.landingZone !== selected?.value) setLandingZone(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (landingZoneApi?.isSuccess) {
            setOptions(landingZoneApi?.data?.data?.landingZones?.map((app) => ({ label: app.label, value: app.label, data: app })));
        }
    }, [landingZoneApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order.landingZoneDetails) {
            setOrder({ ...order, landingZone: options?.[0].value, landingZoneDetails: options?.[0]?.data });
        }
        if (options && order?.landingZoneDetails) {
            setPreconfig();
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order.appProfile && order.environment && order?.region && order?.cloudProvider && order?.cloudService) {
            landingZoneApi.mutate({
                appProfileName: order.appProfile,
                environment: order.environment,
                region: order.region,
                cloudProvider: order?.cloudProvider,
                cloudService: order?.cloudService,
            });
        }
    }, [order.appProfile, order.environment, order?.region, order?.cloudProvider, order?.cloudService]);
    React.useEffect(() => {
        if (!order?.landingZoneDetails && order?.isTouched) setIsTouched(true);
    }, [order?.landingZoneDetails, order?.isTouched]);
    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <Select
                key="landingZoneDetails"
                label="Landing Zone *"
                value={order?.landingZone || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={landingZoneApi.isLoading}
                isLoading={landingZoneApi.isLoading}
                isRequired={isTouched}
                renderOption={(props, option) => (
                    <Box component="div" sx={{ display: 'flex' }} {...props}>
                        <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box component="div" sx={{ fontWeight: 'Medium' }}>
                                {option.label}
                            </Box>
                            <Box component="div" sx={{ fontSize: '12px' }}>
                                {option?.data?.description}
                            </Box>
                        </Box>
                    </Box>
                )}
            />
            <HelpImg title="Choose a landing zone based on your requirment." />
        </Stack>
    );
};

export default React.memo(LandingZones);
