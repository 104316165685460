import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';

export const BusinessJustification = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [helperText, setHelperText] = React.useState(false);
    const setBusinessJustification = (businessJustification = null) => {
        setOrder({
            ...order,
            businessJustification,
        });
    };

    React.useEffect(() => {
        if (
            order?.businessJustification &&
            order?.businessJustification !== null &&
            order?.businessJustification !== '' &&
            order?.businessJustification !== undefined &&
            !/^[a-zA-Z0-9_-\s\\(\\)]{4,64}$/.test(order?.businessJustification)
        ) {
            setHelperText(true);
        } else {
            setHelperText(false);
        }
    }, [order?.businessJustification]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.businessJustification) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.businessJustification && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessJustification, order?.isTouched]);

    return (
        <>
            {ROLE_TO_SHOW?.includes(order?.dataClassification?.toLowerCase()) && (
                <Box display="flex" alignItems="center" gap={1} data-testid="application-url-box">
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        autoComplete="off"
                        label="Business Justification *"
                        error={helperText}
                        helperText={helperText && <span>Business Justification is invalid.</span>}
                        value={order?.businessJustification}
                        onChange={(e) => setBusinessJustification(e.target.value)}
                        className={validateInput()}
                        data-testid="business-justification-text"
                    />
                    <HelpImg
                        title={
                            <Box sx={{ whiteSpace: 'pre' }}>
                                <>
                                    <Box>Please provide business justification if signing certificate is not available.</Box>
                                </>
                            </Box>
                        }
                        maxWidth="600px"
                    />
                </Box>
            )}
        </>
    );
};

export default BusinessJustification;
