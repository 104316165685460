import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Box, Grid, Typography } from '@mui/material';
import Select, { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder, validateMaxAutoInflateTPUnit } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const AutoInflateMaxThroughputUnits = () => {
    const { cartItemId, operation, catalogServiceId } = useParams();
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const [isValidMaxAutoInflateTPUnit, setIsValidMaxAutoInflateTPUnit] = React.useState(validateMaxAutoInflateTPUnit);
    const autoInflateMaxThroughputUnitsApi = useMutation(() => axios.get(`${endPoints.ssp.eventHub.throughputUnits}`));

    const SetAutoInflateMaxThroughputUnits = (autoInflateMaxThroughputUnits) => {
        setOrder({
            ...order,
            autoInflateMaxThroughputUnits,
        });
    };

    const handleOnSelectChange = (selected) => {
        SetAutoInflateMaxThroughputUnits(selected.value);
    };

    React.useEffect(() => {
        if (autoInflateMaxThroughputUnitsApi?.isSuccess) {
            if (autoInflateMaxThroughputUnitsApi?.data?.data?.unitsList)
                setOptions(autoInflateMaxThroughputUnitsApi?.data?.data?.unitsList.map((val) => ({ label: val, value: val })));
        }
    }, [autoInflateMaxThroughputUnitsApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.nsExist === 'no' && order?.performance === 'Standard' && order?.isAutoInflate === 'yes') {
            if (!cartItemId && !operation && !catalogServiceId) SetAutoInflateMaxThroughputUnits(null);
            autoInflateMaxThroughputUnitsApi.mutate();
        }
    }, [order?.nsExist, order?.performance, order?.isAutoInflate]);

    React.useEffect(() => {
        if (!order?.autoInflateMaxThroughputUnits && order?.isTouched) setIsTouched(true);
    }, [order?.autoInflateMaxThroughputUnits, order?.isTouched]);

    const getHelpText = () => (
        <>
            <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            Auto-Inflate automatically scales the number of Throughput Units assigned to your Standard Tier Event Hubs Namespace when your
                            traffic exceeds the capacity of the Throughput Units assigned to it. You can specify a limit to which the Namespace will
                            automatically scale.
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
    React.useEffect(() => {
        setIsValidMaxAutoInflateTPUnit(false);
        if (order?.isAutoInflate === 'yes' && order?.autoInflateMaxThroughputUnits) {
            if (Number(order?.throughputUnits) > Number(order?.autoInflateMaxThroughputUnits)) {
                setIsValidMaxAutoInflateTPUnit(true);
            } else {
                setIsValidMaxAutoInflateTPUnit(false);
            }
        }
    }, [order?.isAutoInflate, order?.throughputUnits, order?.autoInflateMaxThroughputUnits]);

    return (
        <>
            {order?.nsExist === 'no' ? (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <Select
                        key="autoInflateMaxthroughputUnits"
                        label="Auto Inflate Maximum Throughput Units *"
                        value={order?.autoInflateMaxThroughputUnits || null}
                        options={options}
                        handleOnSelect={(value) => handleOnSelectChange(value)}
                        isRequired={isTouched}
                    />
                    <HelpImg maxmWidth="450px" title={getHelpText()} />
                </Stack>
            ) : (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        label="Auto Inflate Maximum Throughput Units *"
                        value={order?.autoInflateMaxThroughputUnits || null}
                        disabled
                    />
                    <HelpImg maxmWidth="450px" title={getHelpText()} />
                </Stack>
            )}
            {isValidMaxAutoInflateTPUnit && order?.isAutoInflate === 'yes' && (
                <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                    Max autoinflate throughput unit should not be less than throughput unit.
                </Typography>
            )}
        </>
    );
};
export default React.memo(AutoInflateMaxThroughputUnits);
