import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';

const ConfigDrawer = ({ isOpen, title, subHeader, handleDrawerAction, children }) => {
    React.useEffect(() => {
        const element = document.getElementById('rwc');
        if (element !== null) {
            if (isOpen) {
                document.getElementById('rwc').style.display = 'none';
            } else {
                document.getElementById('rwc').style.display = 'block';
            }
        }
    }, [isOpen?.toString()]);

    return (
        <Modal open={isOpen} width="60%" title={title} subHeader={subHeader} handleClose={() => handleDrawerAction('cancel')}>
            <>{children}</>
        </Modal>
    );
};

ConfigDrawer.propTypes = {
    isOpen: PropTypes.bool,
    handleDrawerAction: PropTypes.func.isRequired,
    title: PropTypes.string,
    subHeader: PropTypes.string,
    children: PropTypes.any,
};

ConfigDrawer.defaultProps = {
    title: '',
    isOpen: true,
    children: <></>,
    subHeader: null,
};

export default React.memo(ConfigDrawer);
