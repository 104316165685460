/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { profileForm } from '../../../store';
import { CssTextField } from '../../style';

const Sector = () => {
    const [form, setForm] = useRecoilState(profileForm);
    const [options, setOptions] = React.useState([]);
    const setSector = (sector, sectorDetail) => {
        setForm({ ...form, sector, sectorDetail });
    };
    const sectorApi = useMutation(() => axios.get(`${endPoints.ssp.vm.sector}`));
    const seldValueExistsInOptions = (sectorValue) => options.find((option) => sectorValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setSector(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            if (sectorApi?.data?.data?.sectors) setOptions(sectorApi?.data?.data?.sectors.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [sectorApi?.isSuccess]);

    React.useEffect(() => {
        sectorApi.mutate();
        // eslint-disable-next-line
    }, []);
    // const loaddisplay =true
    return (
        <Box bgcolor="white">
            <Select
                key="sectorName"
                value={form?.sector || null}
                label="sectorName"
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading}
                isLoading={sectorApi.isLoading}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Sector Name"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Sector);
