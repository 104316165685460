/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddSku, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Sku = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDSku, setLzDDSku] = useRecoilState(ddSku);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const skuApi = useMutation(() => axios.get(`${endPoints.landingZones.sku}`));

    const setSku = (sku = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                sku,
            });
        }
    };
    const seldValueExistsInOptions = (item) => options.find((value) => item === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSku(selected?.label);
        } else {
            setSku();
        }
    };
    React.useEffect(() => {
        if (skuApi?.isSuccess) {
            setOptions(skuApi?.data?.map((app) => ({ label: app.value, value: app.value, data: app })));
            setLzDDSku({ ...lzDDSku, isLoaded: true });
        }
    }, [skuApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const sku = options.find((value) => lzModal?.lzDetails?.config?.sku === value?.label);
            if (sku) setLzDDSku({ ...lzDDSku, isLoaded: true, data: sku });
        }
        if (options?.length && options?.length === 1 && !lzModal?.lzDetails) {
            setLandingZoneForm({ ...landZoneForm, sku: options[0]?.label });
            setLzDDSku({ ...lzDDSku, isLoaded: true, data: { label: options[0]?.label, value: options[0]?.value, data: options[0]?.data } });
        }
    }, [options]);

    React.useEffect(() => {
        setOptions([]);
        skuApi.mutate();
    }, [lzModal?.lzDetails]);

    return (
        <Box bgcolor="white">
            <Select
                key="alb-sku"
                value={landZoneForm?.sku}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled
                isLoading={skuApi.isLoading}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="SKU"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Sku);
