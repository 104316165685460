import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload, SQLServer } from 'pages/service-account-creation/store';
import { orderSuccessPopup } from 'pages/reference-implementation/store';
import { InputInfo } from 'pages/service-account-creation/components/input-info';

export const AccountType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isSuccessOpen] = useRecoilState(orderSuccessPopup);
    const [isSQLServer] = useRecoilState(SQLServer);
    const [options, setOptions] = React.useState([]);
    const accountTypeApi = useMutation(() => axios.get(endPoints.serviceAccountCreation.accountTypes));

    const setEnv = (accountType) => {
        setOrder({
            ...order,
            accountType,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setEnv(selected.value);

    React.useEffect(() => {
        if (accountTypeApi?.isSuccess) {
            setOptions(
                accountTypeApi?.data?.data
                    ?.filter((app) => (isSQLServer ? !app?.isTypeToDisplay : app?.isTypeToDisplay))
                    .map((app) => ({ label: app?.displayName, value: app?.displayName, data: app }))
            );
        }
    }, [accountTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.accountType) {
            setOrder({ ...order, accountType: options?.[0].value });
        }
        if (options && options?.length && order?.accountType) {
            const accountType = isExistsInOptions(order?.accountType);
            setOrder({ ...order, accountType: accountType?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        accountTypeApi.mutate();
    }, []);

    React.useEffect(() => {
        resetOptions();
        accountTypeApi.mutate();
    }, [isSuccessOpen]);

    React.useEffect(() => {
        if (!order?.accountType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.accountType, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="accountType"
                label="Account Type *"
                value={order?.accountType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={accountTypeApi.isLoading || isSQLServer}
                isLoading={accountTypeApi.isLoading}
                isRequired={isTouched}
            />
            <InputInfo title="Please select valid accountType." />
        </Box>
    );
};

export default React.memo(AccountType);
