const retentionData = [
    { displayValue: '30 Mins', millisec: 1800000 },
    { displayValue: '1 Hour', millisec: 3600000 },
    { displayValue: '3 Hours', millisec: 10800000 },
    { displayValue: '6 Hours', millisec: 21600000 },
    { displayValue: '12 Hours', millisec: 43200000 },
    { displayValue: '1 Day', millisec: 86400000 },
    { displayValue: '2 Days', millisec: 172800000 },
    { displayValue: '3 Days', millisec: 259200000 },
    { displayValue: '4 Days', millisec: 345600000 },
    { displayValue: '5 Days', millisec: 432000000 },
    { displayValue: '6 Days', millisec: 518400000 },
    { displayValue: '7 Days', millisec: 604800000 },
    { displayValue: '8 Days', millisec: 691200000 },
    { displayValue: '9 Days', millisec: 777600000 },
    { displayValue: '10 Days', millisec: 864000000 },
    { displayValue: '11 Days', millisec: 950400000 },
    { displayValue: '12 Days', millisec: 1036800000 },
    { displayValue: '13 Days', millisec: 1123200000 },
    { displayValue: '14 Days', millisec: 1209600000 },
];

export const getRetentionData = (retentionTime = null) => {
    const result = retentionData?.find((data) => data?.millisec === retentionTime)?.displayValue;
    return result || '-';
};

export default getRetentionData;
