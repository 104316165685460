import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const ProjectDetails = () => {
    const [order] = useRecoilState(OrderPayload);
    const LabelValue = (label, value) => (
        <Grid item xs={6} sm={6}>
            <Box sx={Style?.preview?.projectDetails?.subWrapper}>
                <Box sx={Style?.preview?.projectDetails?.label}>{label}:</Box>
                <Box sx={Style?.preview?.projectDetails?.value}>{value || '-'}</Box>
            </Box>
        </Grid>
    );
    const getADOProjectAvailableStatus = () => {
        if (order?.isADOProject === 'yes') {
            return 'Yes';
            // eslint-disable-next-line
        } else if (order?.isADOProject === 'no') {
            return 'No';
        } else {
            return '-';
        }
    };

    const getIsClarityIDStatus = () => {
        if (order?.isClarityId === 'yes') {
            return 'Yes';
            // eslint-disable-next-line
        } else if (order?.isADOProject === 'no') {
            return 'No';
        } else {
            return '-';
        }
    };

    return (
        <Box sx={Style?.preview?.wrapper}>
            <Box sx={Style?.preview?.heading}>Project Details</Box>
            <Divider variant="horizontal" sx={Style?.preview?.divder} />
            <Grid container sx={Style?.preview?.projectDetails?.container} spacing={2}>
                {LabelValue('Sector', order?.sector)}
                {LabelValue('ADO Platform', order?.adoPlatform)}
                {LabelValue('Is ADO Project Available', getADOProjectAvailableStatus())}
                {LabelValue('ADO Project Name', order?.projectName)}
                {LabelValue('Clarity Id', getIsClarityIDStatus())}
                {LabelValue('ADO ProjectType', order?.adoProjectType)}
                {order?.serviceResources?.isTestPlan && LabelValue('H-Code', order?.hCode)}
            </Grid>
        </Box>
    );
};

export default ProjectDetails;
