import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Paper, Typography, FormHelperText, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { convertBytesToMB } from 'utils';

const FileUpload = ({ onFileUpload, uploadedFileList, accept, maxSize, maxFiles, multiple, isValid, maxTotalSize }) => {
    const [errors, setErrors] = useState([]);

    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            const newErrors = new Set();
            const invalidFileTypeErrors = [];
            const fileSizeErrors = [];
            const totalSizeErrors = [];
            const duplicates = acceptedFiles.filter((file) => uploadedFileList.some((uploadedFile) => uploadedFile?.name === file?.name));
            const numberOfFilesAttempted = acceptedFiles?.length + fileRejections?.length;
            const uploadedFileCount = uploadedFileList.length;
            const availableSlots = maxFiles - uploadedFileCount;
            const remainingSlots = maxFiles - uploadedFileList?.length;
            const currentTotalSize = uploadedFileList.reduce((acc, file) => acc + file.size, 0);
            const newFilesTotalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
            const totalSize = currentTotalSize + newFilesTotalSize;

            if (totalSize > maxTotalSize) {
                totalSizeErrors.push(`Max file size should not exceed ${convertBytesToMB(maxTotalSize)} MB. Please select fewer files for upload.`);
            }

            // Existing checks for file types, sizes, and duplicates
            if (totalSizeErrors.length > 0) {
                newErrors.add(totalSizeErrors[0]);
            }

            if (numberOfFilesAttempted > availableSlots) {
                const errorMessage =
                    uploadedFileCount === 0
                        ? `Maximum of ${maxFiles} files allowed per upload. You've attempted to upload ${numberOfFilesAttempted} files. Please select fewer files and try again.`
                        : `You have already uploaded ${uploadedFileCount} file${
                              uploadedFileCount > 1 ? 's' : ''
                          }. You can upload up to ${availableSlots} more files. You've attempted to upload ${numberOfFilesAttempted} files. Please select fewer files and try again.`;
                newErrors.add(errorMessage);
            }

            duplicates?.forEach((file) => newErrors.add(`"${file.name}" has already been selected. Please choose a different file.`));

            fileRejections.forEach(({ file, errors: rejectionErrors }) => {
                rejectionErrors.forEach((error) => {
                    let errorMessage;
                    if (error.code === 'file-invalid-type') {
                        invalidFileTypeErrors.push(file?.name);
                    } else if (error.code === 'file-too-large') {
                        fileSizeErrors.push(file.name);
                    } else if (error.code === 'too-many-files') {
                        errorMessage =
                            remainingSlots > 0
                                ? `You can upload only ${remainingSlots} more file${remainingSlots > 1 ? 's' : ''}. Please select fewer files and try again.`
                                : `You have reached the limit of ${maxFiles} files. Please remove some files if you want to add others.`;
                    } else {
                        errorMessage = `${file?.name}: ${error?.message}`;
                    }
                    newErrors.add(errorMessage);
                });
            });

            if (invalidFileTypeErrors?.length > 0) {
                const allowedExtensions = Object.values(accept).flat().join(', ');
                newErrors.add(
                    `Your uploaded file${invalidFileTypeErrors?.length > 1 ? 's' : ''} ${invalidFileTypeErrors.join(
                        ', '
                    )} are not supported. Please upload only ${allowedExtensions} files.`
                );
            }

            if (fileSizeErrors?.length > 0) {
                newErrors.add(`Max file size should not exceed ${convertBytesToMB(maxTotalSize)} MB. Please select fewer files for upload.`);
            }

            if (acceptedFiles?.length > availableSlots) {
                newErrors.add(
                    availableSlots === 0 ? `You can only upload a maximum of ${maxFiles} files.` : `You can only upload ${availableSlots} more file(s).`
                );
            }

            if (!newErrors.size) onFileUpload(acceptedFiles);
            setErrors(Array.from(newErrors).filter(Boolean));
        },
        [onFileUpload, uploadedFileList, accept, maxFiles, maxSize]
    );
    React.useEffect(() => {
        setErrors([]);
    }, [uploadedFileList]);
    const isMaxFilesReached = React.useMemo(() => uploadedFileList?.length >= maxFiles, [uploadedFileList?.length, maxFiles]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: isMaxFilesReached ? () => {} : onDrop,
        accept,
        maxSize,
        maxFiles,
        multiple,
        noClick: isMaxFilesReached,
    });
    const getDropzoneMessage = () => {
        if (isMaxFilesReached) {
            return `Maximum of ${maxFiles} files reached.`;
        }
        if (isDragActive) {
            return 'Drop the files here ...';
        }
        return `Drag 'n' drop files here, or click to select ${multiple ? 'files' : 'a file'}`;
    };
    return (
        <>
            <Paper
                variant="outlined"
                {...getRootProps()}
                style={{
                    padding: 20,
                    textAlign: 'center',
                    backgroundColor: isDragActive ? '#e3f2fd' : '',
                    cursor: isMaxFilesReached ? 'not-allowed' : 'pointer',
                    // borderColor: isMaxFilesReached ? 'grey' : '',
                    ...(isValid && {
                        border: '1px solid red',
                    }),
                }}
                data-testid="file-upload"
            >
                <input {...getInputProps()} disabled={isMaxFilesReached} />
                <CloudUploadIcon style={{ fontSize: 50, color: isMaxFilesReached ? 'grey' : '#0047BA' }} />
                <Typography variant="body1">{getDropzoneMessage()}</Typography>
            </Paper>
            {errors.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {errors?.length && <FormHelperText sx={{ color: '#ff0000' }}>{errors[0]}</FormHelperText>}
                </Box>
            )}
        </>
    );
};

FileUpload.propTypes = {
    onFileUpload: PropTypes.func.isRequired,
    uploadedFileList: PropTypes.array.isRequired,
    accept: PropTypes.object,
    maxSize: PropTypes.number,
    maxTotalSize: PropTypes.number,
    maxFiles: PropTypes.number,
    multiple: PropTypes.bool,
    isValid: PropTypes.bool,
};

FileUpload.defaultProps = {
    accept: {
        'application/pdf': [],
        'application/vnd.ms-powerpoint': [],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    },
    maxSize: 10485760,
    maxTotalSize: 10485760,
    maxFiles: 1,
    multiple: false,
    isValid: false,
};

export default FileUpload;
