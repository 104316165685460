import React from 'react';
import { Box, Grid, Typography, Table, TableBody, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { CssTextField } from 'components/select';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { Link } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import SearchIcon from '@mui/icons-material/Search';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import AddMember from 'pages/project-onboarding/components/Form/ServiceMembers/AddMember';
import HelpImg from 'pages/project-onboarding/components/helpImg';

const AdminAccessMember = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [searched, setSearched] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);

    const memberApi = useMutation(() => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.member}?key=${ccInputValue}`));
    const adminMemberPostApi = useMutation((payload) => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.member}?key=${payload}`));

    const setAdminAccessMember = (adminAccessMember, adminAccessMemberDetails) => {
        setOrder({
            ...order,
            adminAccessMember,
            adminAccessMemberDetails,
        });
    };

    React.useEffect(() => {
        if (!order?.adminAccessMember && !order?.adminAccessMemberDetails) {
            setOptions([]);
        }
    }, [order?.adminAccessMember, order?.adminAccessMemberDetails]);

    const resetOptions = () => {
        setOptions([]);
        setAdminAccessMember(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                memberApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };
    React.useEffect(() => {
        if (memberApi?.data?.data) {
            const combinedValue = memberApi?.data?.data?.map((app) => ({
                label: `${app?.gpid} - ${app?.name}`,
                gpid: `${app?.gpid}`,
                name: `${app?.name}`,
                email: app?.email,
            }));
            setOptions(combinedValue);
        }
    }, [memberApi?.data?.data]);

    React.useEffect(() => {
        if (adminMemberPostApi?.data?.data) {
            const combinedValue = adminMemberPostApi?.data?.data?.map((app) => ({
                label: `${app?.gpid} - ${app?.name}`,
                gpid: `${app?.gpid}`,
                name: `${app?.name}`,
                email: app?.email,
            }));
            setOptions(combinedValue);
        }
    }, [adminMemberPostApi?.data?.data]);

    React.useEffect(() => {
        if (order?.adminAccessMember && !order?.adminAccessMemberDetails) {
            adminMemberPostApi.mutate(order?.adminAccessMember);
        }
    }, [order?.adminAccessMember, order?.adminAccessMemberDetails]);

    const isExistsInOptions = (adminAccessMemberValue) => options.find((option) => adminAccessMemberValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.adminAccessMember && order?.adminAccessMemberDetails) {
            setOrder({ ...order, adminAccessMember: options[0]?.label, adminAccessMemberDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.adminAccessMemberDetails && !order?.adminAccessMember) {
            const adminAccessMemberObj = isExistsInOptions(order?.adminAccessMemberDetails?.name);
            setOrder({ ...order, adminAccessMember: options[0]?.label, adminAccessMemberDetails: adminAccessMemberObj });
        }
    }, [options]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setAdminAccessMember(value?.label, value);
        }
    }, [value]);

    const validateMember = (member) => {
        const existingMemberList = order?.projectDetails?.projectAdmin?.memberList ? order?.projectDetails?.projectAdmin?.memberList : [];
        const existingProjectAdmin = existingMemberList?.filter((val) => val === member.email);
        const duplicate = order?.adminAccessMemberList?.filter((item) => item?.gpid === member?.gpid);

        if (duplicate && duplicate?.length) {
            setValidForm({ ...isValidForm, isDuplicateAdminAccessMember: true });
        } else if (existingProjectAdmin && existingProjectAdmin?.length) {
            setValidForm({ ...isValidForm, isDuplicateAdminAccessMember: true });
        } else {
            setValidForm({ ...isValidForm, isDuplicateAdminAccessMember: false });
        }
    };

    const handleAddMember = async () => {
        setLoading(!loading);
        try {
            const result = await axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.validateMember}${order?.adminAccessMemberDetails?.gpid}`);
            const existingProjectMemberLength = order?.projectDetails?.projectAdmin?.memberList ? order?.projectDetails?.projectAdmin?.memberList?.length : 0;

            if (existingProjectMemberLength + order?.adminAccessMemberList?.length >= order?.allowedAdminMembers) {
                setValidForm({ ...isValidForm, isAdminAccessMaxMemberLimit: true });
            } else {
                setValidForm({ ...isValidForm, isAdminAccessMaxMemberLimit: false });
                // eslint-disable-next-line
                if (!isValidForm?.isDuplicateAdminAccessMember) {
                    if (result.data?.status === 'Completed') {
                        setValidForm({ ...isValidForm, isAdminMemberTrainingValid: false });

                        setOrder({
                            ...order,
                            adminAccessMemberList: [...order?.adminAccessMemberList, order?.adminAccessMemberDetails],
                            adminAccessMember: null,
                            adminAccessMemberDetails: null,
                        });
                        setLoading(false);
                    } else {
                        setValidForm({ ...isValidForm, isAdminMemberTrainingValid: true });
                    }
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception', e);
        }
    };

    const getHelperText = () => {
        let errorText;
        if (isValidForm?.isDuplicateAdminAccessMember) {
            errorText = 'Member already exists in the list.';
        } else if (isValidForm?.isAdminAccessMaxMemberLimit) {
            errorText = 'You have reached max allowed limit for adding Project Admins.';
        } else if (isValidForm?.isAdminMemberTrainingValid) {
            errorText = 'Member has not completed the Azure DevOps Training.';
        }
        return errorText;
    };

    React.useEffect(() => {
        return () =>
            setValidForm({ ...isValidForm, isDuplicateAdminAccessMember: false, isAdminAccessMaxMemberLimit: false, isAdminMemberTrainingValid: false });
        // eslint-disable-next-line
    }, []);

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };

    return (
        <>
            {' '}
            <Box display="flex" alignItems="flex-start" gap={1} data-testid="admin-section">
                <Autocomplete
                    data-testid="project-admin-autocomplete"
                    key="member"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    includeInputInList
                    loading={memberApi?.isLoading}
                    value={order?.adminAccessMember || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                            validateMember(newValue);
                        }
                    }}
                    isOptionEqualToValue={(option, value1) => option.id === value1.id}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.gpid}>
                            {option?.label}
                        </li>
                    )}
                    onBlur={() => {
                        if (!value || !order?.adminAccessMember) {
                            setSearched(false);
                            setOptions([]);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                            setValidForm({
                                ...isValidForm,
                                isDuplicateAdminAccessMember: false,
                                isAdminAccessMaxMemberLimit: false,
                                isAdminMemberTrainingValid: false,
                            });
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                        // return _results
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    sx={{ width: '50%' }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Admin Access Members *"
                            helperText={<span style={{ color: '#FF3939' }}>{getHelperText()}</span>}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={ProjectOnboardingStyles.MemberAction.searchIcon}
                                            onClick={() => {
                                                setSearched(true);
                                                handleSearch();
                                            }}
                                            data-testid="search-btn"
                                        />
                                    </>
                                ),
                            }}
                            className={order?.adminAccessMemberList?.length === 0 && order?.isTouched ? 'validate' : ''}
                            fullWidth
                        />
                    )}
                />

                <Box display="flex" alignItems="center" gap={1}>
                    {' '}
                    <AddMember handleAddMember={() => handleAddMember()} isLoading={loading} />
                    <HelpImg
                        margin
                        maxWidth="520px"
                        title={
                            <>
                                <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                                    <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                    <b>Project Administrators</b> are required to complete the <b>Azure DevOps (ADO) Project Admin Training</b>{' '}
                                                    before requesting admin access.
                                                </Typography>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                    <b>Training Details:</b>
                                                </Typography>
                                                <TableContainer>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ padding: '2px', fontWeight: '600' }}>Course Name</TableCell>
                                                                <TableCell style={{ padding: '2px', fontWeight: '600' }}>Course Code</TableCell>
                                                                <TableCell style={{ padding: '2px', fontWeight: '600' }}>Course Link</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {order?.courseDetails?.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell style={{ width: '40%', padding: '2px' }}>{item?.courseName}</TableCell>
                                                                    <TableCell style={{ width: '30%', padding: '2px' }}>{item?.courseCode}</TableCell>
                                                                    <TableCell style={{ width: '30%', padding: '2px' }}>
                                                                        <Link
                                                                            to={{
                                                                                pathname: `${item?.courseLink}`,
                                                                            }}
                                                                            target="_blank"
                                                                        >
                                                                            Click here
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                    Please ensure that you complete the training to effectively manage ADO projects.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Box>
            </Box>
        </>
    );
};

export default React.memo(AdminAccessMember);
