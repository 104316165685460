import { atom } from 'recoil';

export const cmpRouteState = atom({
    key: 'root-cmpRouteState',
    default: null,
});

export const catalogs = atom({
    key: 'cmp-catalogs',
    default: [],
});
export const currentLayoutScroll = atom({
    key: 'cmp-currentLayoutScroll',
    default: 0,
});

export const riCatalogs = atom({
    key: 'ri-catalogs',
    default: [],
});

export const cmpSectors = atom({
    key: 'cmp-sectors',
    default: [],
});

export const AuthRequiredModalOpen = atom({
    key: 'cmp-AuthRequiredModalOpen',
    default: false,
});
