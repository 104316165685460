import React from 'react';
import { Box, Typography, Button, Stack } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getEnv, LoggedUserFirstName } from 'utils';
import { endPoints } from 'api/endpoints';
import HomePopUpRocket from 'assets/images/Home-Pop-Up-Rocket.png';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { saveAs } from 'file-saver';
import welcomePageStyles from './styles';

const WelcomeDialog = () => {
    const [cloudPayload, setCloudPayload] = React.useState();
    const [cmpPayload, setCmpPayload] = React.useState();
    const downloadCloudApi = useMutation((payload) => axios.post(endPoints.helpDocuments.downloadDoc, payload), {
        onSuccess: (resp) => {
            if (resp) {
                const blob = new Blob([resp], { type: 'application/pdf' });
                const file = new File([blob], cloudPayload?.blobRelativeLink?.split(['/']).slice(-1).pop(), { type: 'application/pdf' });
                saveAs(file);
            }
        },
    });
    const downloadCmpApi = useMutation((payload) => axios.post(endPoints.helpDocuments.downloadDoc, payload), {
        onSuccess: (resp) => {
            if (resp) {
                const blob = new Blob([resp], { type: 'application/pdf' });
                const file = new File([blob], cmpPayload?.blobRelativeLink?.split(['/']).slice(-1).pop(), { type: 'application/pdf' });
                saveAs(file);
            }
        },
    });
    React.useEffect(() => {
        if (cloudPayload) downloadCloudApi.mutate(cloudPayload);
    }, [cloudPayload]);
    React.useEffect(() => {
        if (cmpPayload) downloadCmpApi.mutate(cmpPayload);
    }, [cmpPayload]);

    return (
        <div data-testid="welcome-popup">
            <Box sx={welcomePageStyles.wrapper}>
                <Stack direction="row" spacing={6}>
                    <Box>
                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Typography varient="h5" sx={welcomePageStyles.nameTypoH}>
                                Hi{' '}
                            </Typography>
                            <Typography sx={welcomePageStyles.firstNameTypo} variant="h5">
                                {LoggedUserFirstName().FirstName()}
                                <span style={welcomePageStyles.notation}>!</span>
                            </Typography>
                        </Stack>

                        <Typography sx={welcomePageStyles.welcomeMsg}>
                            Welcome to the Cloud Marketplace portal (CMP), a self-service portal that help application owners and cloud admins provision cloud
                            services quicker than ever.
                        </Typography>
                        <Box sx={welcomePageStyles.downloadMsgContainer}>
                            <Typography sx={welcomePageStyles.downloadMsg}>Click to download our onboarding resources or navigate to the FAQ </Typography>
                            <Typography sx={welcomePageStyles.startedMsg}>to get you started:</Typography>
                        </Box>
                    </Box>
                </Stack>

                <Stack direction="row" spacing={1}>
                    <Box sx={welcomePageStyles.btnContainer}>
                        <Button
                            data-testid="download-Cloud-Onboarding-btn"
                            sx={welcomePageStyles.cloudBtn}
                            variant="contained"
                            target="_blank"
                            onClick={() =>
                                setCloudPayload({
                                    id: getEnv('REACT_APP_HELP_DOC_CLOUD_ON_BOARDING_ID'),
                                    blobRelativeLink: getEnv('REACT_APP_HELP_DOC_CLOUD_ON_BOARDING_BLOB_LINK'),
                                })
                            }
                            disabled={downloadCloudApi?.isLoading}
                        >
                            {!downloadCloudApi?.isLoading && 'Cloud Onboarding'} {downloadCloudApi?.isLoading && 'Downloading...'}
                        </Button>
                        <Button
                            data-testid="download-CMP-Onboarding-btn"
                            sx={welcomePageStyles.cmpBtn}
                            variant="contained"
                            target="_blank"
                            onClick={() =>
                                setCmpPayload({
                                    id: getEnv('REACT_APP_HELP_DOC_CMP_ON_BOARDING_ID'),
                                    blobRelativeLink: getEnv('REACT_APP_HELP_DOC_CMP_ON_BOARDING_BLOB_LINK'),
                                })
                            }
                            disabled={downloadCmpApi?.isLoading}
                        >
                            {!downloadCmpApi?.isLoading && 'CMP Onboarding'} {downloadCmpApi?.isLoading && 'Downloading...'}
                        </Button>
                    </Box>

                    <Box sx={welcomePageStyles.imageContainer}>
                        {' '}
                        <img width={160} src={HomePopUpRocket} alt="" />
                    </Box>
                </Stack>
            </Box>
        </div>
    );
};

export default WelcomeDialog;
