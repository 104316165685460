import {
    aksCatalogServiceCode,
    AlbCatalogServiceCode,
    eventhubCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    iaasVMCatalogServiceCode,
    kafkaCatalogServiceCode,
    keyvaultCatalogServiceCode,
    paasSQLCatalogServiceCode,
    privateEndPointCatalogServiceCode,
    storageAccountCatalogServiceCode,
    iaasCouchbaseServiceCode,
    adfCatalogServiceCode,
} from 'utils/catalogServiceCode';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

export const getLandingZoneSummary = ({ appName, data }) => ({
    id: data?.id,
    isDraft: data?.isDraft,
    appProfileName: appName,
    cloudProvider: data?.cloudProvider,
    cloudService: data?.cloudService,
    label: data?.label,
    description: data?.description,
    environment: data?.environment,
    region: data?.region.name,
    ...((iaasVMCatalogServiceCode === data?.cloudService ||
        iaasOracleCatalogServiceCode === data?.cloudService ||
        iaasSQLCatalogServiceCode === data?.cloudService) && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        vNet: data?.config?.vnet?.name,
        subNet: data?.config?.subnet,
        availabilitySet: data?.config?.availabilitySet,
        availabilityZone: Array.isArray(data?.config?.availabilityZone) ? data?.config?.availabilityZone?.join(', ') : '',
    }),
    ...(iaasCouchbaseServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        vNet: data?.config?.vnet?.name,
        subNet: data?.config?.subnet,
        availabilityZone: Array.isArray(data?.config?.availabilityZone) ? data?.config?.availabilityZone?.join(', ') : '',
    }),
    ...(paasSQLCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        Selected_Networks: (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Network Subscription</TableCell>
                            <TableCell>Vnet</TableCell>
                            <TableCell>Subnet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.config?.vnetSubnet?.map((z, i) => (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row" sx={{ color: '#000000' }}>
                                    {z?.networkSubscription}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ color: '#000000' }}>
                                    {z?.vnet?.name}
                                </TableCell>
                                <TableCell sx={{ color: '#000000' }}>{z?.subnet}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ),
    }),
    ...(storageAccountCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        dataClassification: data?.config?.dataClassification?.displayName,
        Selected_Networks: (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Network Subscription</TableCell>
                            <TableCell>Vnet</TableCell>
                            <TableCell>Subnet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.config?.vnetSubnet?.map((z, i) => (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row" sx={{ color: '#000000' }}>
                                    {z?.networkSubscription}
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ color: '#000000' }}>
                                    {z?.vnet?.name}
                                </TableCell>
                                <TableCell sx={{ color: '#000000' }}>{z?.subnet}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ),
    }),
    ...(aksCatalogServiceCode === data?.cloudService && {
        department: data?.config?.department,
        businessUnit: data?.config?.businessUnit,
        cluster: data?.config?.cluster,
        // projectName: data?.config?.projectName,
        'ISA Document': data?.config?.isaDocument,
    }),
    ...(keyvaultCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        vNet: data?.config?.vnet?.name,
        subNet: data?.config?.subnet,
        dataClassification: data?.config?.dataClassification?.displayName,
        networkConnectivity: data?.config?.networkConnectivity,
        appName: data?.config?.appName,
    }),
    ...(kafkaCatalogServiceCode === data?.cloudService && {
        department: data?.config?.department,
        cluster: data?.config?.cluster,
        projectName: data?.config?.projectName,
        businessUnit: data?.config?.businessUnit,
        ldap: data?.config?.ldapGroup,
        'ISA Document': data?.config?.isaDocument,
    }),
    ...(AlbCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        vNet: data?.config?.vnet?.name,
        subNet: data?.config?.subnet,
        sku: data?.config?.sku,
        type: data?.config?.type,
        appName: data?.config?.appName,
    }),
    ...(privateEndPointCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        vNet: data?.config?.vnet?.name,
        subNet: data?.config?.subnet,
        dataClassification: data?.config?.dataClassification?.displayName,
    }),
    ...(eventhubCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        dataClassification: data?.config?.dataClassification?.displayName,
        geoRecoveryRegion: data?.config?.geoRecoveryRegion?.name,
        Selected_Networks: (
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Vnet</TableCell>
                            <TableCell>Subnet</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.config?.vnetSubnet?.map((z, i) => (
                            <TableRow key={i}>
                                <TableCell component="th" scope="row" sx={{ color: '#000000' }}>
                                    {z?.vnet?.name}
                                </TableCell>
                                <TableCell sx={{ color: '#000000' }}>{z?.subnet}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        ),
    }),
    ...(adfCatalogServiceCode === data?.cloudService && {
        subscription: data?.config?.subscription?.name,
        resourceGroup: data?.config?.resourceGroup,
        dataClassification: data?.config?.dataClassification?.displayName,
        dataClassificationDetails: data?.config?.dataClassification,
    }),
});
export default getLandingZoneSummary;
