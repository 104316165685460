import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';

const SourceSubscription = () => {
    const [order] = useRecoilState(sspSubmitOrder);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                autoComplete="off"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Source Subscription"
                value={order?.targetSubscription || ''}
                disabled
            />
        </Stack>
    );
};

export default SourceSubscription;
