/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const MaxIops = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setMaxIops = (maxIops, maxIopsDetail) => {
        setOrder({ ...order, maxIops, maxIopsDetail, cost: '' });
    };
    const maxIopsApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.iops}`));
    const seldValueExistsInOptions = (maxIopsValue) => options.find((option) => maxIopsValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setMaxIops(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (maxIopsApi?.isSuccess) {
            if (maxIopsApi?.data?.data) setOptions(maxIopsApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [maxIopsApi?.isSuccess]);

    React.useEffect(() => {
        maxIopsApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.maxIops && order?.isTouched) setIsTouched(true);
    }, [order?.maxIops, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <Select
                key="maxIops"
                label="Max IOPS *"
                value={order?.maxIops || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={maxIopsApi.isLoading}
                isLoading={maxIopsApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="For disk bursting, credits accumulate in a burst bucket whenever disk traffic is below the provisioned performance target for their disk size, and consume credits when traffic bursts beyond the target. Disk traffic is tracked against both IOPS and throughput in the provisioned target. Disk bursting is enabled by default on supported sizes." />
        </Stack>
    );
};

export default React.memo(MaxIops);
