import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import AddedToCartImg from 'assets/images/added-to-cart.png';
import DialogModal from 'components/dialog';
import { sspSubmitOrder, toggleSspModels, environmentDetails, isMandatoryTagsFullfilled, orderId } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import { Close } from '@mui/icons-material';
import Btn from 'components/button';
import { resetOrder } from './utils';

const AddedToCartConfirmModal = () => {
    const history = useHistory();
    const modal = useRecoilValue(toggleSspModels);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const resetEnvStore = useResetRecoilState(environmentDetails);
    const resetModalStore = useResetRecoilState(toggleSspModels);
    const resetIsMandatoryTagsFullfilled = useResetRecoilState(isMandatoryTagsFullfilled);
    const resetOrderId = useResetRecoilState(orderId);

    const handlePlaceNewOrder = () => {
        setOrder({
            ...order,
            ...resetOrder,
        });

        resetEnvStore();
        resetModalStore();
        resetIsMandatoryTagsFullfilled();
        resetOrderId();
    };

    return (
        <DialogModal open={modal.isSubmitOrderSuccess} handleClose={() => {}} data-testid="dialogModal">
            <>
                <IconButton
                    onClick={() => handlePlaceNewOrder()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <Box sx={ProvisioningStyles.submitOrder.wrapper}>
                    <img src={AddedToCartImg} alt="" />
                    <Typography variant="h5" gutterBottom align="center" sx={ProvisioningStyles.submitOrder.message}>
                        Added to cart
                    </Typography>
                    <Typography component="span" gutterBottom align="center" sx={ProvisioningStyles.submitOrder.description}>
                        <Box>Thank you for using Cloud Marketplace!</Box>
                        <Box>Your item was successfully added to the cart.</Box>
                    </Typography>
                    <Box sx={ProvisioningStyles.submitOrder.actionWrapper}>
                        <Btn
                            className="custom-btn"
                            onClick={() => handlePlaceNewOrder()}
                            sx={ProvisioningStyles.submitOrder.newOrder}
                            disableElevation
                            variant="contained"
                            color="primary"
                            data-testid="keep-btn"
                        >
                            Keep Shopping
                        </Btn>
                        <Btn
                            className="custom-btn"
                            onClick={() => history.push('/cart')}
                            component={Link}
                            to="/cart"
                            variant="outlined"
                            color="primary"
                            disableElevation
                            data-testid="checkout-btn"
                        >
                            Checkout
                        </Btn>
                    </Box>
                </Box>
            </>
        </DialogModal>
    );
};

export default React.memo(AddedToCartConfirmModal);
