/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0047BA',
        fontWeight: 700,
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: '#0047BA',
        padding: '0 2px',
        fontWeight: 700,
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: '#27344B',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9 !important',
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E9E9E9',
        },
    },
});

const Select = (props) => {
    const {
        options,
        label,
        value,
        handleOnSelect,
        disableClearable,
        disabled,
        isLoading,
        isRequired,
        bluredOnSelected,
        customValidate,
        placeholder,
        dataTestId,
        ...rest
    } = props;
    const validateInput = () => {
        if (customValidate) return customValidate;
        if (isRequired) {
            return value === '' || value === null || value === undefined ? 'validate' : '';
        }
        return '';
    };
    return (
        <Autocomplete
            data-testid={dataTestId}
            loading={isLoading}
            options={options}
            size="small"
            sx={{
                background: '#FFFFFF',
            }}
            fullWidth
            disablePortal
            disableClearable={disableClearable}
            disabled={disabled}
            onChange={(event, newValue, reason) => {
                handleOnSelect(newValue, reason);
            }}
            value={value}
            blurOnSelect={bluredOnSelected}
            getOptionLabel={(option) => (option.label ? option.label.toString() : option.toString())}
            isOptionEqualToValue={(option, selectedValue) => option.label === selectedValue}
            renderInput={(params) => (
                <CssTextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        'data-testid': `${dataTestId}-input`,
                        endAdornment: (
                            <>
                                {isLoading && <CircularProgress color="black" size={16} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    className={validateInput()}
                />
            )}
            {...rest}
        />
    );
};

Select.propTypes = {
    options: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.any,
    handleOnSelect: PropTypes.func,
    disableClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isRequired: PropTypes.bool,
    bluredOnSelected: PropTypes.bool,
    customValidate: PropTypes.string,
    placeholder: PropTypes.string,
    dataTestId: PropTypes.string,
};

Select.defaultProps = {
    options: [],
    label: '',
    value: undefined,
    handleOnSelect: undefined,
    disableClearable: true,
    disabled: false,
    isLoading: false,
    isRequired: false,
    bluredOnSelected: false,
    customValidate: undefined,
    placeholder: undefined,
    dataTestId: 'select-dropdown',
};

export default Select;
