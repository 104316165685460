/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useRequest } from 'hooks';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import axios from 'api/request';
import Modal from 'components/modal';
import Select, { CssTextField } from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails, addDataDisk, removeDataDisk, updateDataDisksDetails, validateDisk } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import Btn from 'components/button';

const ManageDataDisk = ({ indexVal }) => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const addDisk = useSetRecoilState(addDataDisk);
    const removeDisk = useSetRecoilState(removeDataDisk);
    const updateDiskIndex = useSetRecoilState(updateDataDisksDetails);
    const validate = useSetRecoilState(validateDisk);
    const updateNodesData = useSetRecoilState(updateNodesDetails);
    const [diskTypeOptions, setDiskTypeOptions] = React.useState([]);
    const [diskSizeOptions, setDiskSizeOptions] = React.useState([]);
    const [fileSystemOptions, setFileSystemOptions] = React.useState([]);
    const [lastModifiedDDCount, setLastModifiedDDCount] = React.useState(undefined);
    const [lastModifiedDD, setLastModifiedDD] = React.useState(undefined);
    const azureManagedDisksApi = useMutation(() => axios.get(`${endPoints.ssp.vm.azureManagedDisks}/${order?.environment}`));

    const DataDiskConfig = useRequest({
        key: 'linuxDataDiskConfig',
        url: endPoints.ssp.vm.linuxDiskConfigurations,
    });

    const setDataDiskOpen = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };
    const setOsDiskDetail = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    const onClickAdd = (count) => {
        addDisk({ count, indexVal });
    };

    const onClickDelete = (index) => {
        removeDisk({ index, indexVal });
    };

    const updateDataDiskIndex = (key, selected, index) => {
        updateDiskIndex({ key, selected, index, indexVal });
    };

    const checkMountPointExists = (mountPoint) => {
        const mountPoints = DataDiskConfig?.data?.data?.values[0].reservedPaths || [];
        if (mountPoint && (mountPoint !== '' || mountPoint !== null)) {
            return mountPoint?.charAt(0) === '/' ? mountPoints.indexOf(mountPoint) > -1 : true;
        }
        return false;
    };

    const checkMountPointDuplicates = (mountPoint) => {
        const mountPoints = order?.nodes?.[indexVal]?.dataDisks?.filter((disk) => (disk?.mountPoint ? disk.mountPoint : false)).map((disk) => disk?.mountPoint);
        const duplicateMountPoints = mountPoints.filter((item, index) => mountPoints.indexOf(item) !== index);
        if (mountPoint) {
            return duplicateMountPoints.indexOf(mountPoint) > -1;
        }
        return false;
    };

    const getFileSystemOptions = () => {
        const fileSystems = DataDiskConfig?.data?.data?.values[0]?.allowedFilesytems || [];
        return fileSystems.map((item) => item.toString());
    };

    const getDataDiskSize = () => {
        const size = azureManagedDisksApi?.data?.data?.values[0]?.possibleValues || [];
        return size.map((item) => item);
    };

    const isFormValid = () => {
        let errorCount = 0;
        let isDataDiskValid = false;
        if (order?.nodes?.[indexVal]?.dataDisks?.length) {
            order?.nodes?.[indexVal]?.dataDisks?.forEach((disk) => {
                if (
                    !(
                        disk.diskSizeInGib !== '' &&
                        disk.diskType !== '' &&
                        disk.mountPoint !== '' &&
                        disk.mountPoint !== undefined &&
                        disk.fsType !== '' &&
                        !DataDiskConfig?.data?.data?.values[0].reservedPaths.includes(disk.mountPoint) &&
                        disk?.mountPoint?.charAt(0) === '/'
                    )
                ) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isDataDiskValid = errorCount === 0;
        return isDataDiskValid;
    };

    React.useEffect(() => {
        if (azureManagedDisksApi?.isSuccess) {
            if (azureManagedDisksApi?.data?.data?.values) {
                setDiskTypeOptions(azureManagedDisksApi?.data?.data?.values);
                setDiskSizeOptions(getDataDiskSize());
                setFileSystemOptions(getFileSystemOptions());
                if (order?.environment && order?.environment?.toLowerCase()?.includes('development')) {
                    const diskDetail = {
                        osDiskType: 'Standard SSD',
                        osDiskTypeCode: azureManagedDisksApi?.data?.data?.values?.find((data) => data?.name === 'Standard SSD')?.code,
                        diskTypeOptions: azureManagedDisksApi?.data?.data?.values,
                    };
                    setOsDiskDetail('osDiskDetail', diskDetail, indexVal);
                }
                if (order?.environment && !order?.environment?.toLowerCase()?.includes('development')) {
                    const diskDetail = {
                        osDiskType: 'Premium SSD',
                        osDiskTypeCode: azureManagedDisksApi?.data?.data?.values?.find((data) => data?.name === 'Premium SSD')?.code,
                        diskTypeOptions: azureManagedDisksApi?.data?.data?.values,
                    };
                    setOsDiskDetail('osDiskDetail', diskDetail, indexVal);
                }
            }
        }
    }, [azureManagedDisksApi?.isSuccess]);

    React.useEffect(() => {
        if (order.environment) {
            azureManagedDisksApi.mutate();
        }
    }, [order.environment]);

    React.useEffect(() => {
        if (order?.nodes?.[indexVal]?.isDiskOpen) {
            setLastModifiedDD(order?.nodes?.[indexVal]?.dataDisks);
            setLastModifiedDDCount(order?.nodes?.[indexVal]?.dataDisksCount);
        }
    }, [order?.nodes?.[indexVal]?.isDiskOpen]);

    const isMountPointDuplicate = () => {
        const mountPointsArray = order?.dataDisks?.map((disk) => disk?.mountPoint) || [];
        return new Set(mountPointsArray)?.size !== mountPointsArray?.length;
    };
    return (
        <Modal
            title="Data disks"
            handleClose={() => {
                const nodeUpdatedDataDiskList = {
                    ...order?.nodes?.[indexVal],
                    dataDisks: lastModifiedDD,
                    dataDisksCount: lastModifiedDDCount,
                    isDiskOpen: false,
                };
                const updatedNodeDetails = [...order?.nodes?.slice(0, indexVal), nodeUpdatedDataDiskList, ...order?.nodes?.slice(indexVal + 1)];
                setOrder({ ...order, nodes: updatedNodeDetails });
                validate({ DataDiskConfig, indexVal });
            }}
            open={order?.nodes?.[indexVal]?.isDiskOpen}
        >
            <Box sx={ProvisioningStyles.dataDiskModel.wrapper}>
                <Box sx={ProvisioningStyles.dataDiskModel.contentWrapper}>
                    {order?.nodes?.[indexVal]?.dataDisks?.map((disk, index) => (
                        <Box key={index.toString()} sx={ProvisioningStyles.dataDiskModel.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={ProvisioningStyles.dataDiskModel.diskTypeWrapper}>
                                <Select
                                    key={`diskTypeName${index.toString()}`}
                                    label="Disk Type"
                                    value={disk?.diskType}
                                    options={diskTypeOptions}
                                    handleOnSelect={(value) => updateDataDiskIndex('diskType', value, index)}
                                    isOptionEqualToValue={(option, value) => option.name === value.name || option.name === value}
                                    getOptionLabel={(option) => (option.name ? option.name : option)}
                                    isRequired
                                    sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                />
                            </Box>
                            <Box sx={ProvisioningStyles.dataDiskModel.fileSystemWrapper}>
                                <Select
                                    key={`fileSystemName${index.toString()}`}
                                    label="File System"
                                    value={disk?.fsType}
                                    options={fileSystemOptions}
                                    handleOnSelect={(value) => updateDataDiskIndex('fsType', value, index)}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                    isRequired
                                    sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                />
                            </Box>
                            <Box sx={ProvisioningStyles.dataDiskModel.diskletterWrapper}>
                                <>
                                    <CssTextField
                                        id="outlined-size-small"
                                        size="small"
                                        label="Mount Points"
                                        variant="outlined"
                                        fullWidth
                                        onBlur={() => checkMountPointDuplicates(disk?.mountPoint)}
                                        onChange={(e) => {
                                            checkMountPointExists(e.target.value);
                                            updateDataDiskIndex('mountPoint', e.target.value, index);
                                        }}
                                        value={disk?.mountPoint ?? ''}
                                        sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                        error={checkMountPointExists(disk?.mountPoint) || checkMountPointDuplicates(disk?.mountPoint)}
                                        autoComplete="off"
                                        className={
                                            checkMountPointExists(disk?.mountPoint) ||
                                            checkMountPointDuplicates(disk?.mountPoint) ||
                                            disk?.mountPoint === '' ||
                                            disk?.mountPoint === null ||
                                            disk?.mountPoint === undefined
                                                ? 'validate'
                                                : ''
                                        }
                                    />
                                    {disk?.mountPoint && checkMountPointDuplicates(disk?.mountPoint) && !checkMountPointExists(disk?.mountPoint) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.dataDiskModel.warningTypography}>
                                            Duplicate mount Points are not allowed
                                        </Typography>
                                    )}
                                    {disk?.mountPoint && checkMountPointExists(disk?.mountPoint) && !checkMountPointDuplicates(disk?.mountPoint) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.dataDiskModel.warningTypography}>
                                            Must start with &apos;/&apos; and the following paths are reserved
                                            {DataDiskConfig?.data?.data?.values[0].reservedPaths?.join(', ')}
                                        </Typography>
                                    )}
                                </>
                            </Box>
                            <Box sx={ProvisioningStyles.dataDiskModel.diskSizeWrapper}>
                                <Box className="flex w-full items-center">
                                    <Select
                                        key={`diskSizeInGib${index.toString()}`}
                                        label="Disk Size in GB"
                                        value={disk?.diskSizeInGib}
                                        options={diskSizeOptions}
                                        handleOnSelect={(value) => updateDataDiskIndex('diskSizeInGib', value, index)}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option}
                                        isRequired
                                        sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                    />
                                </Box>
                            </Box>
                            <Box sx={ProvisioningStyles.dataDiskModel.iconWrapper}>
                                <Box className="flex w-full ">
                                    {order?.nodes?.[indexVal]?.dataDisks.length > 1 && (
                                        <Box sx={ProvisioningStyles.dataDiskModel.deleteWrapper}>
                                            <FaTrashAlt
                                                style={ProvisioningStyles.dataDiskModel.delete}
                                                onClick={() => onClickDelete(index)}
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.nodes?.[indexVal]?.dataDisks.length - 1 &&
                                    order?.nodes?.[indexVal]?.dataDisks.length < order?.nodes?.[indexVal]?.maxDataDiskCount ? (
                                        <MdAdd
                                            style={ProvisioningStyles.dataDiskModel.add}
                                            onClick={() => {
                                                onClickAdd(1);
                                            }}
                                            className="w-7 h-7 text-blue-base cursor-pointer"
                                        />
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={ProvisioningStyles.dataDiskModel.actionWrapper}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary"
                        onClick={() => {
                            validate({ DataDiskConfig, indexVal });
                            setDataDiskOpen('isDiskOpen', false, indexVal);
                        }}
                        disabled={!isFormValid() || isMountPointDuplicate()}
                        sx={{ textTransform: 'none' }}
                        data-testid="save"
                    >
                        Save
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.dataDiskModel.resetBtn}
                        onClick={() => {
                            const nodeUpdatedDataDiskList = {
                                ...order?.nodes?.[indexVal],
                                dataDisks: lastModifiedDD,
                                dataDisksCount: lastModifiedDDCount,
                            };
                            const updatedNodeDetails = [...order?.nodes?.slice(0, indexVal), nodeUpdatedDataDiskList, ...order?.nodes?.slice(indexVal + 1)];
                            setOrder({ ...order, nodes: updatedNodeDetails });
                            validate({ DataDiskConfig, indexVal });
                        }}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                    >
                        Reset
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

ManageDataDisk.propTypes = {
    indexVal: PropTypes.number,
};

ManageDataDisk.defaultProps = {
    indexVal: 0,
};

export default ManageDataDisk;
