const newFooterStyles = {
    footer: {
        flex: '0 1 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // background: '#27344B',
        background: '#000000',
        padding: (theme) => theme.spacing(0, 2),
        color: (theme) => theme.palette.white,
        fontSize: 15,
        '& .link': {
            color: (theme) => theme.palette.white,
            letterSpacing: 1,
        },
        height: '72px',
    },
    copytext: { fontSize: '16px', fontFamily: 'Poppins', color: (theme) => theme.palette.white, fontWeight: 'normal', display: 'flex' },
    copyRightText: { fontSize: '61%', mr: '10px' },
    rightBox: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        color: (theme) => theme.palette.white,
        fontWeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    infoStyle: { color: (theme) => theme.palette.white, marginLeft: (theme) => theme.spacing(-0.875) },
};

export default newFooterStyles;
