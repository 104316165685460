import React from 'react';
import Aksns from './AKSNS';
import Cosmos from './Cosmos';
import PostgresSql from './CosmosPostgresSql';
import KAFKA from './Kafka';
import ServiceBus from './ServiceBus';
import Sql from './Sql';

const ConfigurationModal = (props) => {
    const { userSelectedResource, handleDrawerAction, order, secondApprover, handleSecondOwner } = props;

    const component = () => {
        const { configurationComponentCode } = userSelectedResource;
        switch (configurationComponentCode) {
            case 'AKSNS': {
                return <Aksns resource={userSelectedResource} handleDrawerAction={handleDrawerAction} order={order} />;
            }
            case 'AZCOSMOSTABLE':
            case 'AZCOSMOSCASSANDRA':
            case 'AZCOSMOSMONGO':
            case 'AZCOSMOSNOSQL':
            case 'AZCOSMOSGREMLIN': {
                return <Cosmos resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            case 'KAFKA': {
                return (
                    <KAFKA
                        resource={userSelectedResource}
                        handleDrawerAction={handleDrawerAction}
                        order={order}
                        secondApprover={secondApprover}
                        handleSecondApprover={handleSecondOwner}
                    />
                );
            }
            case 'AZSERVICEBUS': {
                return <ServiceBus resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            case 'AZCOSMOSPOSTGRE': {
                return <PostgresSql resource={userSelectedResource} handleDrawerAction={handleDrawerAction} />;
            }
            case 'AZSQLPAAS': {
                return (
                    <Sql
                        resource={userSelectedResource}
                        handleDrawerAction={handleDrawerAction}
                        handleSecondApprover={handleSecondOwner}
                        secondApprover={secondApprover}
                        order={order}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    return component();
};

export default ConfigurationModal;
