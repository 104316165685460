/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const Ram = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setRam = (ram, ramDetail) => {
        setOrder({ ...order, ram, ramDetail, cost: '' });
    };
    const ramApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.ram}`));
    const seldValueExistsInOptions = (ramValue) => options.find((option) => ramValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setRam(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (ramApi?.isSuccess) {
            if (ramApi?.data?.data) setOptions(ramApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [ramApi?.isSuccess]);

    React.useEffect(() => {
        ramApi.mutate();
        // eslint-disable-next-line
    }, [order?.entityDetails?.ram]);
    React.useEffect(() => {
        if (!order?.ram && order?.isTouched) setIsTouched(true);
    }, [order?.ram, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="ram"
                label="RAM *"
                value={order?.ram || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={ramApi.isLoading}
                isLoading={ramApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Ram);
