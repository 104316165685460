import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/day2-operation-paas/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const BlobAccessTier = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const setBlobAccessTierValue = (blobAccessTier) => {
        setOrder({
            ...order,
            blobAccessTier,
        });
    };

    const handleOnSelectChange = (selected) => {
        setBlobAccessTierValue(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="blobAccessTierRadioGrp">
                    Blob Access Tier
                </Box>
                <CustomRadioGroup
                    aria-label="blobAccessTier"
                    name="blobAccessTierRadioBtnGroup"
                    row
                    value={order?.blobAccessTier}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Cool" control={<CustomRadio />} label="Cool" />
                    <Stack direction="row">
                        <FormControlLabel value="Hot" sx={ProvisioningStyles.radioFields.radioBtnNoForm} control={<CustomRadio />} label="Hot" />
                        <Box sx={ProvisioningStyles?.helpImg}>
                            <HelpImg title="Please select blob access tier" />
                        </Box>
                    </Stack>
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default BlobAccessTier;
