/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack, Grid, Typography, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { landingZoneData } from 'pages/app-profiles/store';

const GeoRecoveryRegion = () => {
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);

    const geoRecoveryRegionApi = useMutation(() => axios.get(`${endPoints.landingZones.geoRecoveryRegion}/${landingZoneForm?.regionDetails?.value}`));

    const setGeoRecoveryRegion = (geoRecoveryRegion, geoRecoveryRegionDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            geoRecoveryRegion,
            geoRecoveryRegionDetails,
        });
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setGeoRecoveryRegion(selected?.label, selected);
        }
    };
    React.useEffect(() => {
        if (geoRecoveryRegionApi?.isSuccess) {
            setOptions([{ label: geoRecoveryRegionApi?.data?.data?.locationName, value: geoRecoveryRegionApi?.data?.data?.locationCode }]);
        }
    }, [geoRecoveryRegionApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.geoRecoveryRegion) {
            const geoRecoveryRegion = options.find((value) => landingZoneForm?.geoRecoveryRegion === value?.label);
            if (geoRecoveryRegion) setGeoRecoveryRegion(geoRecoveryRegion?.label, geoRecoveryRegion);
        }
    }, [options]);
    React.useEffect(() => {
        if (landingZoneForm?.region) {
            geoRecoveryRegionApi.mutate();
        }
    }, [landingZoneForm?.region]);
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Select
                    key="georecoveryRegionName"
                    value={landingZoneForm?.geoRecoveryRegion || null}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    disabled={geoRecoveryRegionApi.isLoading || landingZoneForm?.environment !== 'Production'}
                    isLoading={geoRecoveryRegionApi.isLoading}
                    disableClearable={false}
                    label="Geo-Replication Region"
                />
                <Box sx={{ marginLeft: '8px' }}>
                    <InfoImg
                        title={
                            <>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>Geo-replication region is enabled for production environment only.</Typography>
                                        <Typography>Geo-replication region should be in pairing as below:</Typography>
                                        <Typography>1) East US - South Central US</Typography>
                                        <Typography>2) Australia East - Southeast Asia</Typography>
                                        <Typography>3) Germany West Central - UK South</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    />
                </Box>
            </Stack>
        </>
    );
};

export default React.memo(GeoRecoveryRegion);
