/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { OrderPayload, isValidappSupportMailId } from 'pages/reference-implementation/provisioning/RI003/store';
import { RIStyles } from 'pages/reference-implementation/Style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const AppSupportMailIdDL = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setValidEmail] = useRecoilState(isValidappSupportMailId);
    const [showHelperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');

    const handleValueChange = (value) => {
        if (value !== order?.appSupportMailId) {
            setOrder({
                ...order,
                appSupportMailId: value,
            });
        }
    };

    const emailValidation = () => {
        setValidEmail(true);
        setHelperText(true);
        setHelperMsg('Enter Valid email');
    };

    React.useEffect(() => {
        if (
            order?.appSupportMailId &&
            order?.appSupportMailId !== null &&
            order?.appSupportMailId !== '' &&
            order?.appSupportMailId !== undefined &&
            !/[^0-9][A-Za-z0-9.-]{3,}@pepsico.com$/i.test(order?.appSupportMailId)
        ) {
            emailValidation();
        } else if (/\s/.test(order?.appSupportMailId)) {
            emailValidation();
        } else {
            setValidEmail(false);
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.appSupportMailId]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.appSupportMailId) || showHelperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.appSupportMailId && order?.isTouched) setIsTouched(true);
    }, [order?.appSupportMailId, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                data-testid="appSupportDLtext"
                label={<InputLabel label="Application Support DL " isRequired />}
                autoComplete="off"
                error={showHelperText}
                helperText={showHelperText && <span style={{ color: '#FF3939' }}>{showHelperMsg}</span>}
                value={order?.appSupportMailId || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
            />
            <HelpImg maxmWidth="450px" title="Email group or DL should be like this example format: DL-GlobalCMPOperations@pepsico.com" />
        </Stack>
    );
};

export default AppSupportMailIdDL;
