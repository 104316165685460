import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Box } from '@mui/material';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import Alert from 'components/alert';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import {
    sspSubmitOrder,
    toggleSspModels,
    // isMandatoryTagsFullfilled,
    costEstimateState,
    isLoadingCostApiState,
    isSkuConfigFullfilled,
    isStorageDiskConfigFullfilled,
    isDataDiskConfigFullfilled,
} from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import { getPayload } from 'pages/provisioning/day2-operation/getPayload';
import { resetCostEstimateDetails } from 'pages/provisioning/day2-operation/utils';

const ProvisioningHeader = () => {
    const { catalogCode, cartItemId, catalogServiceId } = useParams();
    const [isWelcomeBannerOpen, setWelcomeBannerOpen] = React.useState(true);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [, setIsLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const [modal] = useRecoilState(toggleSspModels);
    const [isSkuConfigValid] = useRecoilState(isSkuConfigFullfilled);
    const [isStorageDiskConfigValid] = useRecoilState(isStorageDiskConfigFullfilled);
    const [isDataDiskConfigValid] = useRecoilState(isDataDiskConfigFullfilled);
    const catalogListApi = useMutation(() => axios.get(endPoints.catalog.allList));
    const getCostPayload = () => {
        const payload = getPayload({ order, catalogCode, cartItemId, catalogServiceId });
        const costPayload = payload?.catalogService;
        delete costPayload?.estimatedCost;
        return costPayload;
    };
    const checkVarConditions = () =>
        !!(
            order?.sector &&
            order.region &&
            order?.entityDetails &&
            order?.envDetails &&
            order?.tags &&
            order?.hCodeDetails &&
            order?.projectName &&
            // order?.notes &&
            order?.serverName &&
            order?.modificationType &&
            (order?.modificationType?.toLowerCase() === 'sku' ? order?.configureNewSku && isSkuConfigValid : true) &&
            (order?.modificationType?.toLowerCase() === 'data disk'
                ? Number(order?.dataDisksCount) === 0 || (order?.dataDisksCount && isDataDiskConfigValid)
                : true) &&
            (order?.modificationType?.toLowerCase() === 'storage disk' ? isStorageDiskConfigValid : true)
        );
    const cost = useMutation(() => axios.get(`/day2-cost-calculation.json`, getCostPayload()));
    // const cost = useMutation(() => axios.post(endPoints?.ssp?.costCalculation, getCostPayload()));
    const isReadyToSubmit = () =>
        !order?.sector ||
        !order.region ||
        !order?.entityDetails ||
        !order?.envDetails ||
        !order?.tags ||
        !order?.hCodeDetails ||
        !order?.projectName ||
        !order?.notes ||
        !order?.serverName ||
        !order?.modificationType ||
        (order?.modificationType?.toLowerCase() === 'sku' && !order?.configureNewSku) ||
        (order?.modificationType?.toLowerCase() === 'sku' && !isSkuConfigValid) ||
        (order?.modificationType?.toLowerCase() === 'storage disk' && !order?.diskSku) ||
        (order?.modificationType?.toLowerCase() === 'storage disk' && !isStorageDiskConfigValid) ||
        (order?.modificationType?.toLowerCase() === 'add disk' && !order?.dataDisksCount) ||
        (order?.modificationType?.toLowerCase() === 'add disk' && !(Number(order?.dataDisksCount) === 0 || (order?.dataDisksCount && isDataDiskConfigValid)));
    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        if (checkVarConditions() === true) {
            cost?.mutate();
        }
    }, [
        order?.sector,
        order.region,
        order?.entityDetails,
        order?.envDetails,
        order?.tags,
        order?.hCodeDetails,
        order?.projectName,
        order?.notes,
        order?.serverName,
        order?.modificationType,
        // order?.currentSku,
        order?.configureNewSku,
        order?.dataDisksCount,
        isSkuConfigValid,
        isStorageDiskConfigValid,
        isDataDiskConfigValid,
    ]);
    React.useEffect(() => {
        catalogListApi.mutate();
    }, []);
    React.useEffect(() => {
        setIsLoadingCostApi({ isLoading: cost?.isFetching });
    }, [cost?.isFetching]);
    React.useEffect(() => {
        if (cost?.data?.data && checkVarConditions()) {
            if (cost?.data?.data) setCostEstimate(cost?.data?.data);
            setOrder({ ...order, estimatedCost: cost?.data?.data });
        }
        // eslint-disable-next-line
    }, [cost?.isSuccess]);

    const getDisImage = (code) => {
        if (catalogListApi?.data?.data?.catalogs?.length) {
            const result = catalogListApi?.data?.data?.catalogs.filter((service) => service.catalogDefinitionId === code);
            return result[0]?.iconPath;
        }
        return null;
    };

    const getProvisiongTitle = (code) => {
        if (catalogListApi?.data?.data?.catalogs?.length) {
            const result = catalogListApi?.data?.data?.catalogs.filter((service) => service.catalogDefinitionId === code);
            return 'IaaS - Day2Operation' || result[0]?.displayName;
        }
        return null;
    };

    return (
        <>
            {!catalogListApi?.isLoading && !catalogListApi?.isError && (
                <StickyHeader title={`${getProvisiongTitle(catalogCode)}`} imgSrc={getDisImage(catalogCode)} />
            )}
            <Box sx={ProvisioningStyles.ProvisioningHeader.header}>
                {!order?.isTouched && isReadyToSubmit() && isWelcomeBannerOpen && !modal.isCostSplitOpen && (
                    <Alert
                        title={null}
                        message="Welcome! Please choose from the PreConfig options by clicking the button or fill out all of the required fields marked with the * asterisk to complete your order."
                        Icon={<InfoImg style={ProvisioningStyles.ProvisioningHeader.infoIcon} />}
                        borderLeftColor="#0047BA"
                        backgroundColor="#DFE6F2"
                        color="#000000"
                        ftSize="15px"
                        onClose={() => setWelcomeBannerOpen(false)}
                        closeicon
                    />
                )}
            </Box>
        </>
    );
};
export default React.memo(ProvisioningHeader);
