export const getAppProfileData = ({ data }) => ({
    isDraft: data?.isDraft,
    appName: data?.name,
    businessCI: data?.businessCi,
    businessCIDetails: { label: data?.businessCi },
    financial: data?.financialDetails,
    financialDetails: { label: data?.financialDetails },
    projectName: data?.projectName || '-',
    recoveryPriority: data?.recoveryPriority,
    billingEntity: data?.billingEntity,
    sector: data?.sector,
    appOwner: data?.owner?.name,
    appOwnerDetails: {
        label: data?.owner?.name,
        gpid: data?.owner?.id,
        name: data?.owner?.name,
        email: data?.owner?.emailId,
    },
    id: data?.id,
});
export default getAppProfileData;
