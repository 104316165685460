import React from 'react';
import { CircularProgress } from '@mui/material';
import ProvisioningStyles from 'pages/myidm/style';
import { OrderPayload, toggleSspModels, selectedMembersList } from 'pages/myidm/store';
import Btn from 'components/button';
import { useRecoilState, useRecoilValue } from 'recoil';

const SelectMemberBtn = () => {
    const [isLoading, setLoading] = React.useState(false);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [selectedMemList, setSelectedMemList] = useRecoilState(selectedMembersList);
    const order = useRecoilValue(OrderPayload);

    const getBtnStatus = () => {
        return (
            ((order?.isNewADGroup === 'no' || order?.isNewADGroup === null) && !order?.adGroup) ||
            (order?.isNewADGroup === 'yes' && (!order?.appShortName || !order?.groupOwner || !order?.adGroupType || !order?.applicationName)) ||
            !order?.businessApplication ||
            !order?.environment ||
            !order?.sector ||
            !order?.adGroupType
        );
    };
    const handleOpenMemlistModal = () => {
        if (order?.members?.length !== selectedMemList?.length) {
            setSelectedMemList(order?.members);
        }
        setLoading(true);
        setModal({ ...modal, isMemberlist: true });

        setLoading(false);
    };
    return (
        <Btn
            color="cmpPrimary"
            data-testid="select-mem-btn"
            disabled={isLoading || getBtnStatus()}
            variant="contained"
            sx={ProvisioningStyles?.selectMemBtn}
            onClick={() => {
                handleOpenMemlistModal();
            }}
        >
            {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Select Member'}
        </Btn>
    );
};
export default SelectMemberBtn;
