import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box, Typography } from '@mui/material';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    color: '#ffffff',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#64C6EA',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#FFFFFF',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function CustomSwitchMUI(props) {
    const { label, handleChange, isChecked, ...rest } = props;
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Typography as="span" sx={{ color: '#fff', fontFamily: 'Roboto', fontSize: '18px' }}>
                {label}
            </Typography>
            <IOSSwitch sx={{ m: 1 }} defaultChecked onChange={handleChange} checked={isChecked} {...rest} />
        </Box>
        // <FormGroup>
        //     <FormControlLabel
        //         control={}
        //         label="New Look"
        //         labelPlacement="start"
        //     />
        // </FormGroup>
    );
}

CustomSwitchMUI.propTypes = {
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    handleChange: PropTypes.func,
};

CustomSwitchMUI.defaultProps = {
    label: 'Switch',
    isChecked: false,
    handleChange: undefined,
};
