import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';

const styles = {
    loadingStack: { width: (theme) => theme.spacing('100%'), height: 'calc(100vh - 100px)' },
    headercontainer: {
        px: 6,
        py: 2,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        width: '100%',
    },
    catalogServiceAllWraper: { position: 'relative', width: '260px', height: '100%', mr: 'auto' },
    headingTypo: { color: (theme) => theme.palette.black.dark, fontFamily: 'Stolzl', fontSize: (theme) => theme.spacing('25px') },
    actionContainer: { display: 'flex', mr: (theme) => theme.spacing(1) },
    serachbox: {
        display: 'flex',
        alignItems: 'center',
        width: (theme) => theme.spacing(40),
        background: (theme) => theme.palette.white,
        borderRadius: '1px',
        mr: (theme) => theme.spacing(2),
    },
    clearicon: { color: (theme) => theme.palette.blue.main },
    searchicon: { color: (theme) => theme.palette.blue.main },
    filterButton: {
        height: '38px',
        width: '100px',
        color: (theme) => theme.palette.blue.main,
        borderColor: (theme) => theme.palette.blue.main,
        border: '2px solid',
        '&:hover': {
            color: (theme) => theme.palette.cyan.main,
            borderColor: (theme) => theme.palette.cyan.main,
            border: '2px solid',
            backgroundColor: 'transparent !important',
        },
    },

    cataloginncontainer: { width: 'auto', display: 'flex', justifyContent: 'center' },
    gridWrapper: { paddingX: '80px' },
    gridcontain: { backgroundColor: (theme) => theme.palette.grey.lighter },
    griditemcontain: { mb: 2, backgroundColor: (theme) => theme.palette.grey.lighter },
    mainstack: {
        width: (theme) => theme.spacing('100%'),
        // height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    orderFilter: {
        container: {
            color: (theme) => theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: (theme) => theme.palette.white,
            width: 260,
            padding: 2,
            position: 'relative',
            top: 2,
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 100,
            fill: (theme) => theme.palette.white,
            zIndex: -1,
        },
        // filter: {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     border: 'solid 1px #ffffff',
        //     marginRight: 1.5,
        //     paddingX: 0.5,
        // }
        filterIcon: { color: (theme) => theme.palette.white },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#f99c24' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
    },
};
export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: '#27344B',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            background: 'transparent',
        },
        '&:focus': {
            borderColor: '#0047BA',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E9E9E9',
        },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E0E0E0',
        borderRadius: '20px',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #0047BA',
        borderRadius: '20px',
    },
});

export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: (theme) => theme.palette.white,
    '&:hover': {
        backgroundColor: '#0047BA',
        color: (theme) => theme.palette.white,
    },
}));
export default styles;
