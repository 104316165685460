const welcomePageStyles = {
    wrapper: { position: 'relative', padding: '20px 25px', display: 'flex', flexDirection: 'column' },
    nameTypoH: { color: '#000000', fontSize: 29, fontWeight: 'bold' },
    firstNameTypo: { color: '#00A0DD', fontSize: 29, fontWeight: 'bold' },
    notation: { color: '#000000', fontSize: 28 },
    welcomeMsg: { color: '#000000', fontSize: 13, fontWeight: 500, fontFamily: 'inherit' },
    downloadMsgContainer: { padding: '16px 41px', display: 'flex', justifyContent: 'center', flexDirection: 'column' },
    downloadMsg: { color: '#000000', fontSize: 13, fontWeight: 500, fontFamily: 'inherit' },
    startedMsg: { color: '#000000', fontSize: 13, fontWeight: 500, fontFamily: 'inherit', textAlign: 'center' },
    btnContainer: { width: '60%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 3 },
    cloudBtn: { my: 1, mx: 2, height: 35, textTransform: 'none', padding: 1, width: '80%', backgroundColor: '#0047BA' },
    cmpBtn: { my: 1, mx: 2, height: 35, textTransform: 'none', padding: 1, width: '80%', backgroundColor: '#0047BA' },
    faqBtn: { my: 1, mx: 2, height: 35, textTransform: 'none', padding: 1, width: '80%', backgroundColor: '#0047BA' },
    imageContainer: { width: '40%' },
};

export default welcomePageStyles;
