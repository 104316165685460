import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import DialogModal from 'components/dialog';
import SuccessImage from 'assets/images/success.png';
import { toggleModal } from 'pages/project-onboarding/store';
import { Style } from './style';

export const DialogBox = ({ handleNewOrderBtn, handleHomeBtn, operation }) => {
    const history = useHistory();
    const [isLoading] = React.useState(false);
    const modal = useRecoilValue(toggleModal);

    const handleOrderIdOnClick = async () => {
        history.push('/orders');
    };

    return (
        <DialogModal open={modal?.isSuccessOpen}>
            <Box sx={Style.wrapper}>
                <>
                    <img src={SuccessImage} alt="" />
                    <Typography gutterBottom sx={Style.requsetTypography}>
                        Your Request is {operation === 'edit' ? 'Re-submitted' : 'Submitted'}
                    </Typography>
                    <Typography gutterBottom sx={Style.thankyouTypography}>
                        Thank you for using Cloud Marketplace. Your order has been {operation === 'edit' ? 're-submitted' : 'submitted'} successfully. Please go
                        to
                        <Typography component="span" variant="body2" sx={Style.orderIdTypography} onClick={() => handleOrderIdOnClick()} data-testid="order-id">
                            {modal?.orderId}
                        </Typography>
                        to review your order
                    </Typography>
                </>

                {operation !== 'edit' && (
                    <Box sx={Style.newOrderBox}>
                        <Button
                            data-testid="placeReq-btn"
                            onClick={() => handleNewOrderBtn()}
                            disabled={isLoading}
                            disableElevation
                            variant="contained"
                            sx={Style.leftBtn}
                            color="cmpPrimary"
                        >
                            Place New Order
                        </Button>
                        <Button
                            data-testid="backHome-btn"
                            onClick={() => handleHomeBtn()}
                            disabled={isLoading}
                            disableElevation
                            variant="outlined"
                            sx={Style.rightBtn}
                            color="cmpPrimary"
                        >
                            Back to Home
                        </Button>
                    </Box>
                )}
                {operation === 'edit' && <Box marginBottom="30px" />}
            </Box>
        </DialogModal>
    );
};

DialogBox.propTypes = {
    handleNewOrderBtn: PropTypes.func,
    handleHomeBtn: PropTypes.func,
    operation: PropTypes.string,
};

DialogBox.defaultProps = {
    handleNewOrderBtn: () => null,
    handleHomeBtn: () => null,
    operation: '',
};
export default React.memo(DialogBox);
