import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { createFilterOptions } from '@mui/material/Autocomplete';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import Select, { CssTextField } from 'components/select';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import { OrderPayload, validOnboardingForm, isValidationLoading } from 'pages/project-onboarding/store';

const filter = createFilterOptions();
export const ServiceAccountSelect = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [, setIsLoading] = useRecoilState(isValidationLoading);
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const serviceAccountsListAPI = useMutation(() =>
        axios.get(`${endPoints.day2Operation.ProjectOnboarding?.Jwt?.serviceAccountsList}?projectName=${order?.projectName}`)
    );

    const validateServiceAccount = useMutation(() =>
        axios.get(`${endPoints.day2Operation.ProjectOnboarding?.Jwt?.validateServiceAccount}?serviceAccountId=${order?.serviceAccount}`)
    );

    const setServiceAccount = (serviceAccount) => {
        setOrder({
            ...order,
            serviceAccount,
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setServiceAccount(null);
    };

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setServiceAccount(value?.label);
        }
    }, [value]);
    const isExistsInOptions = (serviceAccount) => options.find((option) => serviceAccount === option.value);

    //   const handleOnSelectChange = (selected) => setApplicationService(selected.value);

    React.useEffect(() => {
        if (serviceAccountsListAPI?.isSuccess) {
            const serviceOptions = serviceAccountsListAPI?.data?.data?.serviceAccounts?.map((service) => ({ label: service, value: service }));
            setOptions(serviceOptions);
            setInitOptions(serviceOptions);
        }
        if (!serviceAccountsListAPI?.isSuccess) {
            setValidForm({ ...validForm, isInvalidServiceAccount: true });
        }
    }, [serviceAccountsListAPI?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.serviceAccount) {
            return setOrder({ ...order, serviceAccount: options?.[0].value });
        }
        if (options && options?.length && order?.serviceAccount === null) {
            return setOrder({ ...order, serviceAccount: null });
        }
        if (options && options?.length && order?.serviceAccount) {
            const serviceAccount = isExistsInOptions(order?.serviceAccount);
            return setOrder({ ...order, serviceAccount: serviceAccount?.value || order?.serviceAccount });
        }
        return null;
    }, [options]);

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.serviceAccount) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.serviceAccount && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.serviceAccount, order?.isTouched]);

    React.useEffect(() => {
        if (validateServiceAccount?.isSuccess && validateServiceAccount?.data?.data) {
            setValidForm({ ...validForm, isInvalidServiceAccount: false });
        }
        if (validateServiceAccount?.isSuccess && !validateServiceAccount?.data?.data) {
            setValidForm({ ...validForm, isInvalidServiceAccount: true });
        }
        if (!validateServiceAccount?.isSuccess) {
            setValidForm({ ...validForm, isInvalidServiceAccount: true });
        }
    }, [validateServiceAccount?.isSuccess]);

    React.useEffect(() => {
        if (order?.serviceAccount) {
            validateServiceAccount.mutate();
        }
    }, [order?.serviceAccount]);

    React.useEffect(() => {
        setIsLoading(validateServiceAccount?.isLoading || serviceAccountsListAPI?.isLoading || serviceAccountsListAPI?.isError);
    }, [validateServiceAccount]);

    React.useEffect(() => {
        serviceAccountsListAPI.mutate();
    }, []);

    return (
        <Box display="flex" alignItems="flex-start" flexDirection="column">
            <Box display="flex" gap={1} width="100%" alignItems="center">
                <Select
                    key="serviceAccount"
                    size="small"
                    label="Service Account *"
                    value={order?.serviceAccount}
                    disabled={serviceAccountsListAPI?.isLoading}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                                value: newValue,
                                data: null,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                                value: newValue.inputValue,
                                data: null,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    freeSolo
                    renderInput={(params) => (
                        <CssTextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Service Account *"
                            sx={{
                                '& label.MuiInputLabel-shrink': {
                                    color: '#0047BA !important',
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            fullWidth
                        />
                    )}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    className={validateInput()}
                />
                <InfoTooltip
                    maxWidth="390px"
                    title={
                        <>
                            <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                                <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                Please type and select from dropdown to validate the service account.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    }
                />
            </Box>
            {validForm?.isInvalidServiceAccount && (validateServiceAccount?.data?.messages?.length > 0 || validateServiceAccount?.error?.length > 0) && (
                <Box sx={ProjectOnboardingStyles.error}>{validateServiceAccount?.data?.messages?.[0] || validateServiceAccount?.error?.[0]}</Box>
            )}
        </Box>
    );
};

export default React.memo(ServiceAccountSelect);
