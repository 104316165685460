const Styles = {
    provisionHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'capitalize',
            fontSize: 17,
        },
        header: {
            borderColor: (theme) => theme.palette.gray.low,
            mx: (theme) => theme.spacing(4),
        },
        gridContainer: { alignItems: 'center' },
        gridItem: { display: 'flex' },
        spanStyle: { marginRight: '4px' },
        imageStyle: { width: '26px' },
    },

    formCard: {
        paper: {
            height: '100%',
            borderRadius: (theme) => theme.spacing(0.75),
            marginBottom: (theme) => theme.spacing(3.125),
            border: '1px solid #E0E0E0',
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(2.5),
            fontWeight: 'medium',
            fontFamily: 'Stolzl',
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },
        wrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: (theme) => theme.spacing(2), py: (theme) => theme.spacing(1) },
        preconfigbtn: {
            color: (theme) => theme.palette.blue.main,
            textTransform: 'none',
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
    },
    envDetails: {
        wrapper: {
            background: (theme) => theme.palette.white,
            boxShadow: '0px 2px 8px #0000000F',
            border: '1px solid #E0E0E0',
            borderRadius: '6px',
        },
        titleWrapper: {
            px: (theme) => theme.spacing(2),
            py: (theme) => theme.spacing(0.8),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        typograpy: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(2.5),
            fontWeight: 'medium',
            fontFamily: 'Stolzl',
        },
        labelBOx: {
            background: (theme) => theme.palette.white,
            pt: (theme) => theme.spacing(4),
            pb: (theme) => theme.spacing(2),
            pl: (theme) => theme.spacing(4),
            pr: (theme) => theme.spacing(2),
        },
        loadingBox: { height: (theme) => theme.spacing(16.25), display: 'flex', alignItems: 'center', justifyContent: 'center' },
        crlProg: { color: (theme) => theme.palette.black },
        labelGrid: { color: '#003182' },
    },
    AppDetails: {
        span: { color: (theme) => theme?.palette?.black?.dark },
        cirPrg: { color: (theme) => theme.palette.black },
        circularBox: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
    CostSummary: {
        card: {
            background: '#FFFFFF',
            padding: '15px 25px',
            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
            // minHeight: '30%',
            // maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '600px' },
        },
        headingWrapper: { display: 'flex' },
        heading: { marginBottom: '8px', fontSize: '20px', fontWeight: 'medium', fontFamily: 'Stolzl' },
        loaderIcon: { color: (theme) => theme?.palette?.black?.dark },
        // summaryWrapper: { flexGrow: 1, overflowY: 'auto', display: 'flex' },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex' },
        item: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        unit: { fontSize: '12px', fontWeight: '300', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        estCost: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        qtyLabel: { fontSize: '16px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        labelStyle: { fontSize: '16px', fontWeight: 'bold', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        note: { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        oneTimeBtn: {
            marginLeft: '10px',
            color: '#0047BA',
            fontSize: '28px',
            cursor: 'pointer',
        },
        monthlyBtn: {
            marginLeft: '10px',
            color: '#0047BA',
            fontSize: '28px',
            cursor: 'pointer',
        },
    },
    CostDetails: {
        card: {
            background: '#FFFFFF',
            px: '16px',
            pt: '16px',
            pb: '0px',
            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
        },
        headingWrapper: { display: 'flex' },
        heading: { marginBottom: '8px', fontSize: '20px', fontWeight: 'medium', fontFamily: 'Stolzl' },
        loaderIcon: { color: (theme) => theme?.palette?.black?.dark },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex', alignItems: 'center' },
        item: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        unit: { fontSize: '14px', fontWeight: '300', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        estCost: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        labelStyle: { fontSize: '18px', fontWeight: 'bold', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        note: { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        infoWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '200px',
            maxWidth: '500px',
        },
        infoBox: { overflow: 'auto' },
        gridcontainer: { padding: '10px 0px 10px 10px' },
        griditem: {
            mb: (theme) => theme.spacing('3px'),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // flexGrow:1
        },
        labelItem: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
        labelValue: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
    },
    StickHeader: {
        wrapper: {
            background: (theme) => theme.palette.white,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #E0E0E0',
            minHeight: '82px',
        },
        subWrapper: { display: 'flex', alignItems: 'center', ml: '53px' },
        ImgSpan: { display: 'flex', alignItems: 'center' },
        img: { width: '33.43px', height: '33.43px' },
        keyvaultimg: { width: '53.43px', height: '33.43px' },
        title: {
            pl: 2,
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: '30px',
            fontWeight: 'medium',
            fontFamily: 'Stolzl',
        },
        buttonWrapper: {
            mr: '53px',
        },
        showInfoIcon: {
            display: 'flex',
            alignItems: 'baseline',
        },
    },
    infoIcon: { color: '#0047BA', fontSize: '20px', marginTop: '3px' },
    backBtn: { marginLeft: '-20px' },
    backIcon: { color: (theme) => theme.palette.blue.main },
    page: {
        provisioning_wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: (theme) => theme.spacing('100%'),
            height: 'calc(100vh - 100px)',
        },
        circularProgressColor: { color: (theme) => theme.palette.black.dark },
        provisioningBox: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            width: (theme) => theme.spacing('100%'),
            height: (theme) => theme.spacing('100%'),
            minHeight: '600px',
        },
        sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    },
};

export default Styles;
