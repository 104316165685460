import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
    Stepper,
    Step,
    StepLabel,
    TextField,
    TextareaAutosize,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Select,
    MenuItem,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeleteIcon from '@mui/icons-material/Delete';
import { style } from '../style';
import stickyNotes from '../../../assets/images/dlopsImages/sticky-note-solid.svg';

const steps = ['Select Project', 'Select Model', 'Create the Project', 'Create the Model'];

export default function HorizontalLinearStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [projectType, setProjectType] = useState('');
    const [modelType, setModelType] = useState('');
    const [projectName, setProjectName] = useState('');
    const [description, setDescription] = useState('');
    const [showAdditionalTags, setShowAdditionalTags] = useState(false);
    const [modelName, setModelName] = useState('');
    const [modelDescription, setModelDescription] = useState('');
    const [modelVersion, setModelVersion] = useState('');
    const [modelTypes, setModelTypes] = useState('');
    const [tags, setTags] = useState([{ name: '', type: '', defaultValue: '', checked: false }]);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleProjectTypeChange = (event) => {
        setProjectType(event.target.value);
    };

    const handleModelTypeChange = (event) => {
        setModelType(event.target.value);
    };

    const handleShowAdditionalTagsChange = (event) => {
        setShowAdditionalTags(event.target.checked);
        if (!event.target.checked) {
            setTags([{ name: '', type: '', defaultValue: '', checked: false }]);
        }
    };

    const handleTagInputChange = (index, key, value) => {
        const newTags = [...tags];
        newTags[index][key] = value;
        setTags(newTags);
    };

    const addTagRow = () => {
        setTags([...tags, { name: '', type: '', defaultValue: '', checked: false }]);
    };

    const deleteTagRow = (index) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        setTags(newTags);
    };

    const handleCheckboxChange = (index) => {
        const newTags = [...tags];
        newTags[index].checked = !newTags[index].checked;
        setTags(newTags);
    };

    const selectAllTags = () => {
        const newTags = tags.map((tag) => ({ ...tag, checked: true }));
        setTags(newTags);
    };

    const deselectAllTags = () => {
        const newTags = tags.map((tag) => ({ ...tag, checked: false }));
        setTags(newTags);
    };

    // const deleteTagRow = () => {
    //     const newTags = tags.filter((tag) => !tag.checked);
    //     setTags(newTags);
    // };

    return (
        <Box sx={{ width: '60%' }}>
            <Typography style={style.headerText} sx={{ mt: 2, mb: 1 }}>
                Model Onboarding
            </Typography>
            <Stepper activeStep={activeStep} sx={{ marginTop: '70px', marginLeft: '300px' }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </>
            ) : (
                <>
                    {activeStep === 0 && (
                        <>
                            <div style={style.stepperNotes} data-testid="allprojectslink" aria-hidden="true" tabIndex={0} role="link">
                                <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                                <h5 style={style.stepperText}>Step {activeStep + 1} of 3</h5>
                            </div>
                            <RadioGroup
                                style={style.radioText}
                                aria-label="project-type"
                                name="project-type"
                                value={projectType}
                                onChange={handleProjectTypeChange}
                            >
                                <Typography style={style.selectType}>
                                    Select the Project type
                                    <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <FormControlLabel value="create" control={<Radio />} label="Create New Project" />
                                <FormControlLabel value="edit" control={<Radio />} label="Edit Existing Project" />
                            </RadioGroup>
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <div style={style.stepperNotes} data-testid="allprojectslink" aria-hidden="true" tabIndex={0} role="link">
                                <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                                <h5 style={style.stepperText}>Step {activeStep + 1} of 3</h5>
                            </div>
                            <RadioGroup style={style.radioText} aria-label="model-type" name="project-type" value={modelType} onChange={handleModelTypeChange}>
                                <Typography style={style.selectType}>
                                    Select the Model type
                                    <span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <FormControlLabel value="create" control={<Radio />} label="Create New Model" />
                                <FormControlLabel value="edit" control={<Radio />} label="Edit Existing Model" />
                            </RadioGroup>
                        </>
                    )}
                    {activeStep === 2 && (
                        <>
                            <div style={style.stepperNotes} data-testid="allprojectslink" aria-hidden="true" tabIndex={0} role="link">
                                <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                                <h5 style={style.stepperText}>Step {activeStep + 1} of 3</h5>
                            </div>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.projectType}>
                                Create Project
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.projectName}>
                                Project Name
                                <TextField
                                    style={{ marginBottom: '50px', marginLeft: '60px' }}
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                    size="small"
                                />
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.projectDescription}>
                                Project Description
                            </Typography>
                            <TextareaAutosize
                                aria-label="Project Description"
                                placeholder="Project Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                minRows={3}
                                style={{ marginLeft: '375px', width: '40%', marginTop: '-25px' }}
                            />
                            <FormControlLabel
                                style={{ marginLeft: '362px' }}
                                control={<Checkbox checked={showAdditionalTags} onChange={handleShowAdditionalTagsChange} />}
                                label="Provide additional tags for the project"
                            />
                            {showAdditionalTags && (
                                <>
                                    <div style={{ marginLeft: '362px' }}>
                                        <Typography sx={{ mt: 2, mb: 1 }} style={{ marginLeft: '-145px' }}>
                                            Additional Tags:
                                        </Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                icon={<SelectAllIcon />}
                                                                checkedIcon={<SelectAllIcon />}
                                                                checked={tags.every((tag) => tag.checked)}
                                                                onChange={(e) => (e.target.checked ? selectAllTags() : deselectAllTags())}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Default Value</TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tags.map((tag, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <Checkbox checked={tag.checked} onChange={() => handleCheckboxChange(index)} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={tag.name}
                                                                    size="small"
                                                                    onChange={(e) => handleTagInputChange(index, 'name', e.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={tag.type}
                                                                    size="small"
                                                                    onChange={(e) => handleTagInputChange(index, 'type', e.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={tag.defaultValue}
                                                                    size="small"
                                                                    onChange={(e) => handleTagInputChange(index, 'defaultValue', e.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton onClick={() => deleteTagRow(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Button style={{ marginLeft: '20px', marginTop: '20px' }} variant="outlined" onClick={addTagRow}>
                                            Add Tag
                                        </Button>
                                        <Button style={{ marginLeft: '20px', marginTop: '20px' }} variant="outlined" onClick={deleteTagRow}>
                                            Delete Selected
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {activeStep === 3 && (
                        <>
                            <div style={style.stepperNotes} data-testid="allprojectslink" aria-hidden="true" tabIndex={0} role="link">
                                <img src={stickyNotes} alt={stickyNotes} style={style.stickyNote} />
                                <h5 style={style.stepperText}>Step {activeStep + 1} of 3</h5>
                            </div>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.projectType}>
                                Create Model
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.projectName}>
                                Model name
                                <TextField
                                    style={{ marginBottom: '50px', marginLeft: '100px' }}
                                    value={modelName}
                                    onChange={(e) => setModelName(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                    size="small"
                                />
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.projectDescription}>
                                Model description
                            </Typography>
                            <TextareaAutosize
                                aria-label="model Description"
                                value={modelDescription}
                                onChange={(e) => setModelDescription(e.target.value)}
                                minRows={3}
                                style={{ marginLeft: '408px', width: '40%', marginTop: '-30px' }}
                            />
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.modelVersion}>
                                Model version
                                <TextField
                                    style={{ marginLeft: '92px' }}
                                    value={modelVersion}
                                    onChange={(e) => setModelVersion(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                    size="small"
                                />
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.modelType}>
                                Model type
                                {/* <InputLabel id="demo-multiple-chip-label">Model type</InputLabel> */}
                                <Select
                                    size="small"
                                    value={modelTypes}
                                    onChange={(e) => setModelTypes(e.target.value)}
                                    input={<OutlinedInput id="select-multiple-chip" label="Model type" />}
                                    style={{ marginLeft: '112px', width: '200px', marginTop: '-50px' }}
                                >
                                    <MenuItem value="Classification">Classification</MenuItem>
                                    <MenuItem value="Regression">Regression</MenuItem>
                                    <MenuItem value="Object detection">Object detection</MenuItem>
                                    <MenuItem value="Segmentation">Segmentation</MenuItem>
                                    <MenuItem value="Ranking">Ranking</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.modelVersion}>
                                Model metrics
                                <TextField
                                    style={{ marginLeft: '90px' }}
                                    value={modelVersion}
                                    onChange={(e) => setModelVersion(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                    size="small"
                                />
                            </Typography>
                            <Typography sx={{ mt: 2, mb: 1 }} style={style.modelVersion}>
                                Model metrics threshold
                                <TextField
                                    style={{ marginLeft: '22px' }}
                                    value={modelVersion}
                                    onChange={(e) => setModelVersion(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                    size="small"
                                />
                            </Typography>
                            <FormControlLabel
                                style={{ marginLeft: '400px' }}
                                control={<Checkbox checked={showAdditionalTags} onChange={handleShowAdditionalTagsChange} />}
                                label="Provide additional tags for the project"
                            />
                            {showAdditionalTags && (
                                <>
                                    <div style={{ marginLeft: '400px' }}>
                                        <Typography sx={{ mt: 2, mb: 1 }} style={{ marginLeft: '-145px' }}>
                                            Additional Tags:
                                        </Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Checkbox
                                                                icon={<SelectAllIcon />}
                                                                checkedIcon={<SelectAllIcon />}
                                                                checked={tags.every((tag) => tag.checked)}
                                                                onChange={(e) => (e.target.checked ? selectAllTags() : deselectAllTags())}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Default Value</TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tags.map((tag, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <Checkbox checked={tag.checked} onChange={() => handleCheckboxChange(index)} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={tag.name}
                                                                    size="small"
                                                                    onChange={(e) => handleTagInputChange(index, 'name', e.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={tag.type}
                                                                    size="small"
                                                                    onChange={(e) => handleTagInputChange(index, 'type', e.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={tag.defaultValue}
                                                                    size="small"
                                                                    onChange={(e) => handleTagInputChange(index, 'defaultValue', e.target.value)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton onClick={() => deleteTagRow(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Button style={{ marginLeft: '20px', marginTop: '20px' }} variant="outlined" onClick={addTagRow}>
                                            Add Tag
                                        </Button>
                                        <Button style={{ marginLeft: '20px', marginTop: '20px' }} variant="outlined" onClick={deleteTagRow}>
                                            Delete Selected
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} style={{ marginLeft: '250px', marginTop: '100px' }}>
                        <Button
                            color="inherit"
                            disabled={(activeStep === 0 && projectType === '') || (activeStep === 1 && modelType === '')}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Submit' : 'Next'}</Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
