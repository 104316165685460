import { getLoggedUserGPID, getValueFromKey } from 'utils';
import { isShowAppRegionField, ShowAppUrlBasedOnCatalogCode, checkIsApplicable } from 'pages/reference-implementation/provisioning/RI003/utils';
import { RICatalogCode003, RICatalogCode003A, RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';
import mappingLabel from 'utils/mappingLabel';

export const getPreviewPayload = ({ order }) => {
    const getADGroupOwnerId = () => {
        let ownerId = '';
        if (order?.nonPrivilegedADGroupOwnerDetails) {
            ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid
                ? order?.nonPrivilegedADGroupOwnerDetails?.gpid
                : order?.nonPrivilegedADGroupOwnerDetails?.id;
        } else if (order?.privilegedADGroupOwnerDetails) {
            ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
        }
        return ownerId;
    };
    return {
        ...(order?.projectId && { 'project-id': order?.projectId }),
        'business-unit': order?.businessUnit,
        ...(ShowAppUrlBasedOnCatalogCode?.includes(order?.catalogCode) && { 'application-url': order?.appUrl }),
        sector: order?.sector,
        ...(checkIsApplicable(order?.program) && { program: order?.program }),
        // ...(order?.appContextPath && { 'application-context-path': order?.appContextPath }),
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        'budget-code': order?.budgetCode,
        ...(order?.billingInfo && { 'billing-information': order?.billingInfo }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'business-application': order?.businessApp,
        'manager-id': order?.appOwnerDetails?.gpid,
        ...(order?.businessAppService === 'Yes' && {
            'application-service': order?.appService,
        }),
        'existing-applicaton-service': order?.appServiceRadioBtn === 'Yes',
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'business-owner-id': order?.businessContactDetails?.gpid,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-group': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
        ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-group': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
        ...([RICatalogCode003, RICatalogCode003A, RICatalogCode005]?.includes(order?.catalogCode) && {
            'app-support-mail-id': order?.appSupportMailId,
        }),
        ...([RICatalogCode003A, RICatalogCode003, RICatalogCode005]?.includes(order?.catalogCode) && {
            'certificate-owner-one': order?.certificateOwnerOneDetails?.gpid,
            'certificate-owner-two': order?.certificateOwnerTwoDetails?.gpid,
        }),
    };
};

export const getCostCalcPayload = ({ order }) => {
    return {
        'project-id': order?.projectId,
        sector: order?.sector,
        environment: order?.environment,
    };
};

export const getDownloadConfigPayload = ({ order = {} }) => {
    const payload = {
        'project-id': order?.projectId,
        'application-name': order?.applicationName,
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(checkIsApplicable(order?.program) && { program: order?.program }),
        // ...(order?.appContextPath && { 'application-context-path': order?.appContextPath }),
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'budget-code': order?.budgetCode,
        'reader-ad-group': order?.adGroupReader || null,
        'contributor-ad-group': order?.adGroupContributor || null,
        requestorGpid: getLoggedUserGPID(),
    };
    return payload;
};

export const getOrderPayload = ({ order }) => {
    const getADGroupOwnerId = () => {
        let ownerId = '';
        if (order?.nonPrivilegedADGroupOwnerDetails) {
            ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid
                ? order?.nonPrivilegedADGroupOwnerDetails?.gpid
                : order?.nonPrivilegedADGroupOwnerDetails?.id;
        } else if (order?.privilegedADGroupOwnerDetails) {
            ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
        }
        return ownerId;
    };
    const payload = {
        ...(order?.projectId && { 'project-id': order?.projectId }),
        'business-unit': order?.businessUnit,
        ...(ShowAppUrlBasedOnCatalogCode?.includes(order?.catalogCode) && { 'application-url': order?.appUrl }),
        sector: order?.sector,
        ...(checkIsApplicable(order?.program) && { program: order?.program }),
        // ...(order?.appContextPath && { 'application-context-path': order?.appContextPath }),
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        ...(isShowAppRegionField({ order }) && { 'application-region': order?.region }),
        'budget-code': order?.budgetCode,
        ...(order?.billingInfo && { 'billing-information': order?.billingInfo }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'business-application': order?.businessApp,
        'manager-id': order?.appOwnerDetails?.gpid,
        ...(order?.businessAppService === 'Yes' && {
            'application-service': order?.appService,
        }),
        'existing-applicaton-service': order?.appServiceRadioBtn === 'Yes',
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'business-owner-id': order?.businessContactDetails?.gpid,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-group': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
        ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-group': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),
        ...([RICatalogCode003, RICatalogCode003A, RICatalogCode005]?.includes(order?.catalogCode) && {
            'app-support-mail-id': order?.appSupportMailId,
        }),
        ...([RICatalogCode003A, RICatalogCode003, RICatalogCode005]?.includes(order?.catalogCode) && {
            'certificate-owner-one': order?.certificateOwnerOneDetails?.gpid,
            'certificate-owner-two': order?.certificateOwnerTwoDetails?.gpid,
        }),
    };
    return payload;
};

export const getResubmitOrderPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    const nonPrivilegedADGroup = catalog?.readerAdGroup?.length ? 'Yes' : 'No';
    const privilegedADGroup = catalog?.contributorAdGroup?.length ? 'Yes' : 'No';
    const payload = {
        ...(catalog?.projectId && { 'project-id': catalog?.projectId }),
        'business-unit': catalog?.businessUnit,
        'application-url': catalog?.applicationUrl,
        sector: catalog?.sector,
        ...(checkIsApplicable(catalog?.program) && { program: catalog?.program === 'Global Shared' ? 'Not Applicable' : catalog?.program }),
        // ...(order?.appContextPath && { 'application-context-path': catalog?.applicationContextPath }),
        ...(['cgf']?.includes(catalog?.sector?.toLowerCase()) && { division: catalog?.division }),
        ...(isShowAppRegionField({ order: { sector: catalog?.sector, catalogCode: order?.catalogCode } }) && { 'application-region': catalog?.region }),
        'budget-code': catalog?.costCenter,
        ...(catalog?.billingInformation && { 'billing-information': catalog?.billingInformation }),
        environment: catalog?.environment,
        'data-classification': catalog?.dataClassification,
        'business-application': catalog?.businessApplication,
        'manager-id': order?.manager?.id,
        ...(catalog?.businessApplication &&
            catalog?.applicationService && {
                'application-service': catalog?.applicationService,
            }),
        'application-name': catalog?.applicationName,
        'app-short-name': catalog?.applicationShortName,
        'business-owner-id': catalog?.businessOwnerId,
        ...(catalog?.readerAdGroup && catalog?.readerAdGroup?.length && { 'reader-ad-group': catalog?.readerAdGroup }),
        ...(catalog?.contributorAdGroup && catalog?.contributorAdGroup?.length && { 'contributor-ad-group': catalog?.contributorAdGroup }),
        ...(catalog?.environment?.toLowerCase() !== 'production' &&
            (nonPrivilegedADGroup === 'No' || privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id,
            }),
        ...(catalog?.environment?.toLowerCase() === 'production' &&
            privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id,
            }),
        ...([RICatalogCode003, RICatalogCode003A, RICatalogCode005]?.includes(order?.catalogCode) && {
            'app-support-mail-id': catalog?.appSupportMailId,
        }),
        ...([RICatalogCode003A, RICatalogCode003, RICatalogCode005]?.includes(order?.catalogServices?.[0]?.catalogServiceCode) && {
            'certificate-owner-one': catalog?.certificateOwnerOne,
            'certificate-owner-two': catalog?.certificateOwnerTwo,
        }),
    };
    return payload;
};

export const snakeToTitle = (camelCase = '') => {
    const snakeCase = camelCase
        ?.toLowerCase()
        ?.split('-')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
    return snakeCase;
};
const transformResourceProperties = (item) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
        if (typeof value === 'string' && key !== 'storage-type') {
            acc[snakeToTitle(key)] = value;
        }
        return acc;
    }, {});
};
const transformRoleAssignments = (roleAssignments, isOrderpage = false) => {
    if (!Array.isArray(roleAssignments)) return null;
    if (roleAssignments.every(({ 'access-levels': accessLevels }) => accessLevels === null)) {
        return null;
    }
    if (isOrderpage) {
        return roleAssignments.reduce((acc, { 'assignee-name': assigneeName, 'access-levels': accessLevels }) => {
            accessLevels?.forEach((accessLevel) => {
                if (!acc[accessLevel]) acc[accessLevel] = [];
                acc[accessLevel].push(assigneeName);
            });
            return acc;
        }, {});
    }
    return roleAssignments.reduce((acc, { assignee, 'access-levels': accessLevels }) => {
        accessLevels?.forEach((accessLevel) => {
            if (!acc[accessLevel]) acc[accessLevel] = [];
            acc[accessLevel].push(assignee);
        });
        return acc;
    }, {});
};
export const transformAccessLevels = (roleAssignments) => {
    if (!Array.isArray(roleAssignments)) return null;
    return roleAssignments.map((item) => {
        const { 'access-levels': accessLevels, ...rest } = item;
        return {
            ...rest,
            'access-levels': accessLevels?.length ? accessLevels : ['Group Assignment'],
        };
    });
};
const getFormattedObject = ({ resourceType, resourceProperties, roleAssignments = null, accessPolicy = null }) => {
    return {
        'Resource Type': resourceType,
        'Resource Properties': resourceProperties,
        'Role Assignments': roleAssignments,
        'Access Policy': accessPolicy,
    };
};
const getlabelType = (item, resource) => {
    if (item[getValueFromKey(resource, mappingLabel)]) {
        return item[getValueFromKey(resource, mappingLabel)] !== 'DataFoundation' ? `(${item[getValueFromKey(resource, mappingLabel)]})` : '(Data Foundation)';
    }
    return '';
};
const getResourceType = (resource, componentId, item) => {
    // return snakeToTitle(resource)?.toLowerCase() === snakeToTitle(componentId?.replace(/_/g, ' '))?.toLowerCase() || !componentId
    //     ? `${snakeToTitle(resource)}`
    //     : `${snakeToTitle(resource)} (${componentId})`;

    let resourceType = '';

    if (
        resource !== 'data-factory' &&
        resource !== 'data-lake-storage' &&
        resource !== 'data-bricks-cluster' &&
        (snakeToTitle(resource)?.toLowerCase() === snakeToTitle(componentId?.replace(/_/g, ' '))?.toLowerCase() || !componentId)
    ) {
        resourceType = `${snakeToTitle(resource)}`;
    } else if (['data-factory', 'data-lake-storage', 'data-bricks-cluster']?.includes(resource)) {
        resourceType = `${snakeToTitle(resource)} ${getlabelType(item, resource)}`;
    }
    return resourceType;
};
const convertToObjectArray = (input) => {
    if (input && typeof input === 'object' && !Array.isArray(input)) {
        return [input];
    }
    return input;
};
export const getResources = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    const result = [];
    Object.keys(resources)?.map((resource) => {
        if (resource === 'blob-storage') {
            const storageAccountName = resources[resource]?.map((item) => item?.['storage-account-name']).join(', ');
            const resourceType = `${snakeToTitle(resource)}`;
            const resourceProperties = { 'Storage Account Name': storageAccountName };
            result.push(getFormattedObject({ resourceType, resourceProperties }));
            return '';
        }
        const convertedResource = convertToObjectArray(resources[resource]);
        if (typeof convertedResource === 'string') return '';
        convertedResource?.map((item) => {
            const { 'component-id': componentId, ...rest } = item;
            const resourceType = getResourceType(resource, componentId, item);
            const resourceProperties = transformResourceProperties(rest);
            const newRoleAssignments = transformAccessLevels(rest['role-assignments']);
            const roleAssignments = transformRoleAssignments(newRoleAssignments, order?.isOrderPage);
            const accessPolicy = transformRoleAssignments(rest['access-Policy'] || rest['access-policy'], order?.isOrderPage);
            result.push(getFormattedObject({ resourceType, resourceProperties, roleAssignments, accessPolicy }));
            return '';
        });
        return '';
    });
    return result;
};
export const getpreviewDetails = ({ order }) => {
    const previewDetails = { resources: getResources({ order }) };
    Object.keys(order?.environmentDetails).map((key) => {
        if (!['estimated-cost', 'resources', 'tech-input-json', 'catalogServiceCode', 'catalog-service-code']?.includes(key)) {
            if (['subscription']?.includes(key) && typeof order?.environmentDetails?.[key] === 'object') {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key]?.subscription;
            } else {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
            }
        }
        return '';
    });
    return previewDetails;
};
export const transformSubscriptions = ({ resources }) => {
    return resources.reduce((acc, sub) => {
        const key = `${sub?.['architecture-component-name']} Subscription`;
        if (typeof sub?.subscription === 'object' && sub?.subscription?.subscription) {
            acc[key] = sub.subscription.subscription;
        } else {
            acc[key] = sub.subscription;
        }
        return acc;
    }, {});
};
export const getPreviewDetailsMultiRI = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    const previewDetails = {
        resources: resources?.map((resource) =>
            getpreviewDetails({
                order: { environmentDetails: { resources: resource, 'architecture-component-name': resource?.['architecture-component-name'] } },
            })
        ),
    };
    Object.keys(order?.environmentDetails).map((key) => {
        if (!['estimated-cost', 'resources', 'tech-input-json']?.includes(key)) {
            if (['subscription']?.includes(key) && typeof order?.environmentDetails?.[key] === 'object') {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key]?.subscription;
            } else {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
            }
        }
        return '';
    });
    const multiSubscription = transformSubscriptions({ resources });
    return { ...previewDetails, ...multiSubscription };
};
export const getPreviewDetailsMultiRIOrderSummary = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    return {
        resources: resources?.map((resource) =>
            getpreviewDetails({
                order: {
                    ...(order?.isOrderPage && {
                        isOrderPage: order?.isOrderPage,
                    }),
                    environmentDetails: {
                        resources: resource,
                        'architecture-component-name': resource?.['architecture-component-name'],
                    },
                },
            })
        ),
    };
};
