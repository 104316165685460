import React from 'react';
import { Stack } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { ddResGroup, landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import { useTranslation } from 'react-i18next';
import { privateEndPointCatalogServiceCode } from 'utils/catalogServiceCode';
import LandingZoneStyle, { CssTextField } from '../../LandingZoneStyle';

const filter = createFilterOptions();
const ResourceGroup = () => {
    const { t } = useTranslation();
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDResGroup, setLzDDResGroup] = useRecoilState(ddResGroup);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const getResourceGroupUrl = (payload) => {
        if (landZoneForm?.cloudService === privateEndPointCatalogServiceCode)
            return `${endPoints.landingZones.privateEndpoint.resourceGroup
                .replace('{region}', payload?.region)
                .replace('{subscriptionId}', payload?.subscriptionId)}?type=${payload?.endPointType}`;

        return `${endPoints.landingZones.resourceGroup}/location/${payload?.region}/subscription/${payload?.subscriptionId}`;
    };

    const resGrpGetApi = useMutation(() =>
        axios.get(
            `${endPoints.landingZones.resourceGroup}/location/${landZoneForm?.regionDetails?.value}/subscription/${landZoneForm?.subscriptionDetails?.id}?name=${ccInputValue}`
        )
    );

    const resGrpPostApi = useMutation((payload) => axios.get(getResourceGroupUrl(payload)));

    const setHCode = (resGroup, resGroupDetails) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                resGroup,
                resGroupDetails,
                ...((landZoneForm?.landingZoneLabel === '' || landZoneForm?.landingZoneLabel === null || landZoneForm?.landingZoneLabel === undefined) && {
                    landingZoneLabel: resGroup?.split('-')?.slice(0, 3)?.join('-'),
                }),
            });
        }
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setHCode(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                resGrpGetApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const resGrp = options.find((app) => lzModal?.lzDetails?.config?.resourceGroup === app?.label);
            if (resGrp) setLzDDResGroup({ ...lzDDResGroup, isLoaded: true, data: resGrp });
        }
    }, [options]);

    React.useEffect(() => {
        if (resGrpGetApi?.isSuccess) {
            if (resGrpGetApi?.data?.data) {
                const combinedValue = resGrpGetApi?.data?.data?.map((app) => ({ label: `${app.name}`, value: `${app.name}` }));
                setOptions(combinedValue);
            }
        }
    }, [resGrpGetApi?.isSuccess]);

    React.useEffect(() => {
        if (resGrpPostApi?.isSuccess) {
            if (resGrpPostApi?.data?.data) {
                setLzDDResGroup({ ...lzDDResGroup, isLoaded: true });
                const combinedValue = resGrpPostApi?.data?.data?.map((app) => ({ label: app.name, value: app.name }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [resGrpPostApi?.isSuccess]);

    React.useEffect(() => {
        if (
            landZoneForm.region &&
            landZoneForm?.subscriptionDetails &&
            landZoneForm?.cloudService !== privateEndPointCatalogServiceCode &&
            !lzModal?.lzDetails
        ) {
            resetOptions();
            resGrpPostApi.mutate({ region: landZoneForm?.regionDetails?.value, subscriptionId: landZoneForm?.subscriptionDetails?.id });
        } else if (
            landZoneForm.region &&
            landZoneForm?.subscriptionDetails &&
            landZoneForm?.endPointType &&
            landZoneForm?.cloudService === privateEndPointCatalogServiceCode &&
            !lzModal?.lzDetails
        ) {
            resetOptions();
            resGrpPostApi.mutate({
                region: landZoneForm?.regionDetails?.value,
                subscriptionId: landZoneForm?.subscriptionDetails?.id,
                endPointType: landZoneForm?.endPointType,
            });
        } else if (!lzModal?.lzDetails) {
            resetOptions();
        }
    }, [landZoneForm.region, landZoneForm?.subscriptionDetails, landZoneForm?.endPointType]);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            setHCode(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (lzModal?.lzDetails?.region?.code && lzModal?.lzDetails?.config?.subscription?.id && lzModal?.lzDetails) {
            setCCInputValue(lzModal?.lzDetails?.config?.resourceGroup);
        }
    }, [lzModal?.lzDetails]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Select
                    noOptionsText="No options"
                    loading={resGrpGetApi?.isLoading || resGrpPostApi?.isLoading}
                    forcePopupIcon={false}
                    disabled={resGrpPostApi?.isLoading}
                    key="Resource GroupName"
                    value={landZoneForm?.resGroup || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={LandingZoneStyle.select}
                    // freeSolo
                    disableClearable={false}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Resource Group Name"
                            InputProps={{
                                ...params.InputProps,
                            }}
                            fullWidth
                        />
                    )}
                />
                <InfoImg title={t([`landingZone.infoText.resourceGroup`])} />
            </Stack>
        </>
    );
};

export default React.memo(ResourceGroup);
