import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Modal from 'components/modal';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import Btn from 'components/button';

const VSDrawer = ({ isOpen, isSubOpen, title, subHeader, handleDrawerAction, children }) => {
    const closeButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleDrawerAction('save', isSubOpen)} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={ProvisioningStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel', isSubOpen)}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );
    React.useEffect(() => {
        const element = document.getElementById('rwc');
        if (element !== null) {
            if (isOpen) {
                document.getElementById('rwc').style.display = 'none';
            } else {
                document.getElementById('rwc').style.display = 'block';
            }
        }
    }, [isOpen?.toString()]);

    return (
        <Modal
            open={isOpen}
            width="50%"
            title={title}
            subHeader={subHeader}
            primaryButton={closeButton()}
            secondaryButton={cancelButton()}
            handleClose={() => handleDrawerAction('cancel', isSubOpen)}
        >
            <>
                <Box style={ProvisioningStyles.tags.wrapper}>
                    <Box sx={ProvisioningStyles.tags.contentWrapper}>
                        {subHeader ? (
                            <Typography component="h3" sx={ProvisioningStyles.tags.subHeader}>
                                {subHeader}
                            </Typography>
                        ) : null}
                        {children}
                    </Box>
                    <Box className="footer" sx={ProvisioningStyles.tags.actionWrapper}>
                        {closeButton()}
                        {cancelButton()}
                    </Box>
                </Box>
            </>
        </Modal>
    );
};

VSDrawer.propTypes = {
    isOpen: PropTypes.bool,
    isSubOpen: PropTypes.bool,
    handleDrawerAction: PropTypes.func.isRequired,
    title: PropTypes.string,
    subHeader: PropTypes.string,
    children: PropTypes.any,
};

VSDrawer.defaultProps = {
    title: '',
    isOpen: true,
    isSubOpen: false,
    children: <></>,
    subHeader: null,
};

export default React.memo(VSDrawer);
