/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { landingZoneData, toggleSummaryLZ } from 'pages/app-profiles/store';
import { getLandingZoneData } from '../getLandingZoneData';
import { getLandingZoneSummary } from '../getLandingZoneSummary';

const ViewLandingZone = ({ index }) => {
    const [landingZoneForm] = useRecoilState(landingZoneData);
    const [lzSummary, setLzSummary] = useRecoilState(toggleSummaryLZ);
    const landingZoneApi = useMutation((payload) => axios.get(`${endPoints.landingZones.landingZone}/${payload?.id}`));

    React.useEffect(() => {
        if (landingZoneApi?.data?.data) {
            const data = getLandingZoneData({ appName: landingZoneApi?.data?.data?.appProfileName, data: landingZoneApi?.data?.data });
            const summary = getLandingZoneSummary({ appName: landingZoneApi?.data?.data?.appProfileName, data: landingZoneApi?.data?.data });
            setLzSummary({ ...lzSummary, isOpen: true, lzDetails: summary, form: data });
        }
    }, [landingZoneApi?.isSuccess]);

    return (
        <>
            <RemoveRedEyeRoundedIcon
                sx={{
                    fontSize: 19,
                    color: '#0047BA',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    if (landingZoneForm?.components?.[index]?.landingZoneDetails?.id) {
                        landingZoneApi.mutate({ id: landingZoneForm?.components?.[index]?.landingZoneDetails?.id });
                    }
                }}
                data-testid={`view-${index}`}
            />
        </>
    );
};

ViewLandingZone.propTypes = {
    index: PropTypes.number,
};

ViewLandingZone.defaultProps = {
    index: 0,
};

export default React.memo(ViewLandingZone);
