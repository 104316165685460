import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const toggleModal = atom({
    key: 'toggleHelpModal',
    default: false,
});

export const selectedDocument = atom({
    key: 'selectedDocument',
    default: undefined,
});
