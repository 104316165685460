import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import Modal from 'components/modal';
import Empty from 'components/empty';
import { titleCase } from 'utils';
import { toggleCostModals, catalogDetails } from 'pages/cart/store';
import LabelValue from 'components/label-value';
import StackCatalogDetails from 'pages/cart/components/StackCatalogDetails';
import { cartStyle } from 'pages/cart/style';
import CostSplit from 'pages/cart/components/cost-split';
import { couchbaseDbDefinitionId, oracleIaaSDefinitionId } from 'utils/catalogDefinitionId';
import { titleWithSummary } from 'components/order-summary-details/components/catalog-summary';

function CatalogDetails() {
    const [modal, setModal] = useRecoilState(toggleCostModals);
    const catalog = useRecoilValue(catalogDetails);
    const isDetailsLoading = modal?.isCatalogDetails?.isDetailsLoading;
    const handleCloseModal = () => {
        setModal({ ...modal, isCatalogDetails: { id: '', isDetailsOpen: false, isDetailsLoading: false } });
    };
    React.useEffect(() => {}, [catalog, isDetailsLoading]);
    return (
        <Modal
            title={titleCase(catalog?.catalogType || catalog?.stackCatalogs?.catalogService?.catalogType)}
            handleClose={handleCloseModal}
            open={modal?.isCatalogDetails?.isDetailsOpen}
            width="800px"
        >
            <Box>
                {isDetailsLoading && (
                    <Box display="flex" alignItems="center" justifyContent="center" height={250}>
                        <Empty color="#000000" isLoading={isDetailsLoading} />
                    </Box>
                )}

                {!isDetailsLoading && !catalog?.stackCatalogs && catalog?.summary && (
                    <Box>
                        <Typography sx={cartStyle?.catalogDetails?.summary}>Summary</Typography>
                        <Grid container spacing={2} className="order-details">
                            {Object.keys(catalog?.summary).map((label) => (
                                <>
                                    {label === 'cost' && (
                                        <CostSplit
                                            data={catalog?.summary[label]}
                                            width={oracleIaaSDefinitionId?.includes(catalog?.catalogServiceCode) ? 228 : 160}
                                        />
                                    )}
                                    {label !== 'cost' && (
                                        <Grid className="" key={label} xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                                            <LabelValue
                                                width={oracleIaaSDefinitionId?.includes(catalog?.catalogServiceCode) ? 228 : 160}
                                                key={label}
                                                label={`${label}`}
                                                value={catalog?.summary[label]}
                                            />
                                        </Grid>
                                    )}
                                </>
                            ))}
                            {Object.keys(catalog?.summary).length % 2 !== 0 && (
                                <>
                                    <Grid className="" xs={12}>
                                        &nbsp;
                                    </Grid>
                                </>
                            )}
                            {couchbaseDbDefinitionId.includes(catalog?.catalogServiceCode) && (
                                <>
                                    {titleWithSummary({ title: 'Instance Details:', catalogObj: catalog?.instance })}
                                    {catalog?.components?.map((component, indexComp) =>
                                        titleWithSummary({ title: `Node ${indexComp + 1}:`, catalogObj: component })
                                    )}
                                    {catalog?.serviceMappings?.map((serviceMap, indexService) =>
                                        titleWithSummary({
                                            title: indexService === 0 ? `Service Mapping:` : '',
                                            catalogObj: {
                                                clusterService: serviceMap?.clusterService,
                                                memoryQuota: serviceMap?.memoryQuota,
                                                nodes: serviceMap?.nodes?.toString(),
                                                storagePath: serviceMap?.storagePath,
                                            },
                                        })
                                    )}
                                    {titleWithSummary({
                                        title: 'Bucket Details:',
                                        catalogObj: catalog?.buckets?.reduce((acc, curr) => {
                                            acc[curr.BucketType] = curr.BucketNames;
                                            return acc;
                                        }, {}),
                                    })}
                                </>
                            )}
                        </Grid>
                    </Box>
                )}
                {!isDetailsLoading && catalog?.stackCatalogs && (
                    <Box>
                        <StackCatalogDetails items={catalog?.stackCatalogs?.catalogService} />
                    </Box>
                )}

                {!isDetailsLoading && !catalog?.summary && !catalog?.stackCatalogs && (
                    <Empty notFound>
                        <span style={cartStyle?.catalogDetails?.nodata}>No data Found</span>
                    </Empty>
                )}
            </Box>
        </Modal>
    );
}

export default React.memo(CatalogDetails);
