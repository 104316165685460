/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const Sector = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setSector = (sector, sectorDetail) => {
        setOrder({ ...order, sector, sectorDetail });
    };
    const sectorApi = useMutation(() => axios.get(`${endPoints.ssp.vm.sector}`));
    const seldValueExistsInOptions = (sectorValue) => options.find((option) => sectorValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setSector(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            if (sectorApi?.data?.data?.sectors) setOptions(sectorApi?.data?.data?.sectors.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [sectorApi?.isSuccess]);
    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.entityDetails?.sector) {
            setOrder({ ...order, sector: options?.[0]?.name, sectorDetail: options?.[0]?.data });
        }
        if (options && options?.length && order?.entityDetails?.sector) {
            const sector = seldValueExistsInOptions(order?.entityDetails?.sector);
            if (sector) setOrder({ ...order, sector: sector?.data?.name, sectorDetail: sector?.data });
        }
    }, [options, order?.entityDetails?.sector]);

    React.useEffect(() => {
        sectorApi.mutate();
        // eslint-disable-next-line
    }, [order?.entityDetails?.sector]);
    React.useEffect(() => {
        if (!order?.sector && order?.isTouched) setIsTouched(true);
    }, [order?.sector, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="sectorName"
                label="Sector *"
                value={order?.sector || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading}
                isLoading={sectorApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Sector);
