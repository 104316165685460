import React from 'react';
import Lz from 'pages/app-profiles/landing-zones/components/Form';
import { Grid } from '@mui/material';

const CouchbaseFields = () => (
    <>
        <Grid item xs={6}>
            {' '}
            <Lz.Environments />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.Region />
        </Grid>
        <Grid item xs={6}>
            <Lz.Subscription />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.ResourceGroup />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.VNet />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.SubNet />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.AvailabilityZone />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.LandingZoneLabel />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Lz.LandingZoneDesc />
        </Grid>
    </>
);

export default CouchbaseFields;
