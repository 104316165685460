import { RICatalogCode001A, RICatalogCode002, RICatalogCode003, RICatalogCode003A, RICatalogCode004 } from 'pages/reference-implementation/utils/RICatalogCode';
import {
    ApplicationGateWayURLCatalogDefinitionID,
    MdtCatalogDefinitionId,
    ProjectOnboardCatalogDefinitionId,
    SecurityToolCatalogDefinitionId,
    VirtualServiceCatalogDefinitionId,
} from './catalogDefinitionId';

export const scopedCatalogs = [
    RICatalogCode001A,
    RICatalogCode002,
    RICatalogCode003,
    RICatalogCode003A,
    RICatalogCode004,
    ...ApplicationGateWayURLCatalogDefinitionID,
    ...MdtCatalogDefinitionId,
    ...SecurityToolCatalogDefinitionId,
    ...ProjectOnboardCatalogDefinitionId,
    ...VirtualServiceCatalogDefinitionId,
];

export default scopedCatalogs;
