/* eslint-disable import/no-named-default */

import BusinessCI from './BusinessCI';
import FinancialDetails from './FinancialDetails';
import BillingEntities from './BillingEntities';
import Sector from './Sector';
import AppOwner from './AppOwner';

const Forms = {
    BusinessCI,
    FinancialDetails,
    BillingEntities,
    Sector,
    AppOwner,
};

export default Forms;
