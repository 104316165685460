import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTopOnNavigation() {
    const location = useLocation();
    const prevLocation = useRef(location);
    React.useEffect(() => {
        if (prevLocation.current.pathname !== location.pathname) {
            document.getElementById('applayoutbox').scrollTop = 0;
            prevLocation.current = location;
        }
    }, [location]);

    return null;
}

export default ScrollToTopOnNavigation;
