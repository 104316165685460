import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { couchbaseDbDefinitionId, MdtCatalogDefinitionId } from 'utils/catalogDefinitionId';
import approvalStyle from 'components/order-summary-details/style';
import LabelValue from 'components/label-value';
import GetSummary from 'components/order-summary-details/components/get-summary';

export const titleWithSummary = ({ title, catalogObj }) => (
    <React.Fragment key={`fragment-${title}`}>
        {title && (
            <Grid key={`${title}`} className="" sx={approvalStyle.connectionHeader}>
                {title}
            </Grid>
        )}
        <Grid className="" key={`grid-${title}`} xs={12} sx={approvalStyle.gridLabel} item>
            {Object.keys(catalogObj).map((label, index) => (
                <React.Fragment key={`fragment-summary-${label}-${index}`}>
                    <Grid className="" key={`grid-summary-${label}-${index}`} xs={12} sx={approvalStyle.gridLabel} item>
                        <LabelValue width={160} key={`label-summary-${label}-${index}`} label={`${label}`} value={catalogObj[label]} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    </React.Fragment>
);

const CatalogSummary = ({ catalog, selectedApprovalId }) => {
    return (
        <Grid container spacing={2} className="order-details" key={`details-summary-${selectedApprovalId}`}>
            <GetSummary catalog={catalog} selectedApprovalId={selectedApprovalId} />
            {MdtCatalogDefinitionId.includes(catalog?.catalogServiceCode) && (
                <>
                    {titleWithSummary({ title: 'Source Details:', catalogObj: catalog?.sourceDetails })}
                    {titleWithSummary({ title: 'Destination Details:', catalogObj: catalog?.destinationDetails })}
                </>
            )}
            {couchbaseDbDefinitionId.includes(catalog?.catalogServiceCode) && (
                <>
                    {titleWithSummary({ title: 'Instance Details:', catalogObj: catalog?.instance })}
                    {catalog?.components?.map((component, indexComp) => titleWithSummary({ title: `Node ${indexComp + 1}:`, catalogObj: component }))}
                    {catalog?.serviceMappings?.map((serviceMap, indexService) =>
                        titleWithSummary({
                            title: indexService === 0 ? `Service Mapping:` : '',
                            catalogObj: {
                                clusterService: serviceMap?.clusterService,
                                memoryQuota: serviceMap?.memoryQuota,
                                nodes: serviceMap?.nodes?.toString(),
                                storagePath: serviceMap?.storagePath,
                            },
                        })
                    )}
                    {catalog?.buckets?.map((bucket, index) =>
                        titleWithSummary({
                            title: index === 0 ? `Bucket Details:` : '',
                            catalogObj: {
                                bucketType: bucket?.BucketType,
                                bucketMemSize: bucket?.BucketMemSize,
                                bucketNames: bucket?.BucketNames,
                            },
                        })
                    )}
                </>
            )}
        </Grid>
    );
};

CatalogSummary.propTypes = {
    catalog: PropTypes.any,
    selectedApprovalId: PropTypes.any,
};

CatalogSummary.defaultProps = {
    catalog: undefined,
    selectedApprovalId: '',
};

export default React.memo(CatalogSummary);
