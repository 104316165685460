import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
import { RIStyles, CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';

const ConsumerGroupRadioBtn = (props) => {
    const { consumerGroupValue, handleOnSelect } = props;

    return (
        <Box>
            <Typography sx={RIStyles?.configuration?.cosmos?.heading}>Do You require Consumer Group?</Typography>
            <FormControl>
                <CustomRadioGroup
                    aria-label="consumerGroup"
                    name="consumergroupRadioBtnGroup"
                    row
                    value={consumerGroupValue}
                    onChange={(event, value) => {
                        handleOnSelect(value);
                    }}
                >
                    <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" data-testid="consumerGrpYes" />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '60px',
                        }}
                        data-testid="consumerGrpNo"
                        control={<CustomRadio />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Box>
    );
};

ConsumerGroupRadioBtn.propTypes = {
    consumerGroupValue: PropTypes.any,
    handleOnSelect: PropTypes.func,
};

ConsumerGroupRadioBtn.defaultProps = {
    consumerGroupValue: 'no',
    handleOnSelect: () => {},
};

export default ConsumerGroupRadioBtn;
