import { OktaAuth } from '@okta/okta-auth-js';
import { getEnv } from 'utils';

export const OKTA_ISSUER = getEnv('REACT_APP_OKTA_ISSUER') || 'https://{yourOktaDomain}.com/oauth2/default';
export const OKTA_CLIENT_ID = getEnv('REACT_APP_OKTA_CLIENT_ID') || '{clientId}';
export const OKTA_TESTING_DISABLEHTTPSCHECK = getEnv('REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK') || false;
export const REDIRECT_URI = `${window.location.origin}/login/callback`;

const OKTA_CONFIG = {
    oidc: {
        issuer: OKTA_ISSUER.toString(),
        clientId: OKTA_CLIENT_ID.toString(),
        redirectUri: REDIRECT_URI.toString(),
        pkce: true, // with default PKCE configuration as true
        scopes: ['openid', 'profile', 'email', 'cmp.auth'],
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    tokenManager: {
        // expireEarlySeconds: getEnv('REACT_APP_EXPIRE_TIME'),
    },
};

const oktaConfig = new OktaAuth(OKTA_CONFIG.oidc);

export default oktaConfig;
