export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: entityDetails?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        instanceName: catalogConfig?.instanceName,
        fqdns: catalogConfig?.fqdns,
        instanceFrontendPoolName: catalogConfig?.frontEndPoolName,
        availabilityZone: catalogConfig?.availabilityZone,
        bpPoolName: catalogConfig?.backEndPools[0]?.poolName,
        poolConfigurations: catalogConfig?.backEndPools[0]?.poolConfig,
        virtualMachine: catalogConfig?.backEndPools[0]?.virtualMachines?.map((vm) => ({
            label: vm?.virtualMachineName,
            value: vm?.virtualMachineName,
            ipAddress: vm?.ipAddress,
        })),
        vNet: environmentDetails?.virtualNetwork,
        rulesName: catalogConfig?.loadBalancerRules[0]?.rulesName,
        protocols: catalogConfig?.loadBalancerRules[0]?.protocol,
        frontendPort: catalogConfig?.loadBalancerRules[0]?.frontEndPort,
        backendPort: catalogConfig?.loadBalancerRules[0]?.backEndPort,
        sessionPersistance: catalogConfig?.loadBalancerRules[0]?.sessionPersistance,
        ...(catalogConfig?.loadBalancerRules[0]?.protocol?.toLowerCase() === 'tcp' && {
            timeOut: catalogConfig?.loadBalancerRules[0]?.idealTimeout,
        }),
        healthProbeName: catalogConfig?.loadBalancerRules[0]?.healthProbe?.healthProbeName,
        hpProtocol: catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols,
        port: catalogConfig?.loadBalancerRules[0]?.healthProbe?.port,
        ...(['http', 'https'].includes(catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols?.toLowerCase()) && {
            urlPath: catalogConfig?.loadBalancerRules[0]?.healthProbe?.pathUrl,
        }),
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: entityDetails?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        instanceName: catalogConfig?.instanceName,
        fqdns: catalogConfig?.fqdns,
        instanceFrontendPoolName: catalogConfig?.frontEndPoolName,
        availabilityZone: catalogConfig?.availabilityZone,
        bpPoolName: catalogConfig?.backEndPools[0]?.poolName,
        poolConfigurations: catalogConfig?.backEndPools[0]?.poolConfig,
        virtualMachine: catalogConfig?.backEndPools[0]?.virtualMachines?.map((vm) => ({
            label: vm?.virtualMachineName,
            value: vm?.virtualMachineName,
            ipAddress: vm?.ipAddress,
        })),
        vNet: environmentDetails?.virtualNetwork,
        rulesName: catalogConfig?.loadBalancerRules[0]?.rulesName,
        protocols: catalogConfig?.loadBalancerRules[0]?.protocol,
        frontendPort: catalogConfig?.loadBalancerRules[0]?.frontEndPort,
        backendPort: catalogConfig?.loadBalancerRules[0]?.backEndPort,
        sessionPersistance: catalogConfig?.loadBalancerRules[0]?.sessionPersistance,
        ...(catalogConfig?.loadBalancerRules[0]?.protocol?.toLowerCase() === 'tcp' && {
            timeOut: catalogConfig?.loadBalancerRules[0]?.idealTimeout,
        }),
        healthProbeName: catalogConfig?.loadBalancerRules[0]?.healthProbe?.healthProbeName,
        hpProtocol: catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols,
        port: catalogConfig?.loadBalancerRules[0]?.healthProbe?.port,
        ...(['http', 'https'].includes(catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols?.toLowerCase()) && {
            urlPath: catalogConfig?.loadBalancerRules[0]?.healthProbe?.pathUrl,
        }),
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    sectorDetails: null,
    region: null,
    regionDisplayName: null,
    environment: null,
    hoursOfOperation: null,
    hoursOfOperationDetail: null,
    hCode: null,
    hCodeDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    tags: [],
    estimatedCost: null,
    cloudProvider: 'Azure',
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
    projectName: null,
    projectNameDetail: null,
    notes: null,
    serverName: null,
    serverNameDetail: null,
    modificationType: null,
    modificationTypeDetail: null,
    currentSku: null,
    configureNewSku: null,
    configureNewSkuDetail: null,
    skuConfig: null,
    maxIops: null,
    ram: null,
    dataDiskSupport: null,
    premiumDiskSupport: null,
    cost: null,
    currentStorageDisk: null,
    modifyTagsCount: null,
    modifyTags: [{ name: null, value: null }],
    maxModifyTagsCount: 5,
    enableKeyRotation: 'Yes',
    accountKey1: 'cmp account key 1',
    accountKey2: 'cmp account key 2',
    connectionString1: 'cmp connection string 1',
    connectionString2: 'cmp connection string 2',
    staticWebpage: 'Disabled',
    performance: 'Standard',
    blobAccessTier: 'Cool',
};
export const resetForQuickConfig = {
    region: null,
    regionDisplayName: null,
    environment: null,
    projectName: null,
    projectNameDetail: null,
    notes: null,
    serverName: null,
    serverNameDetail: null,
    modificationType: null,
    modificationTypeDetail: null,
    currentSku: null,
    configureNewSku: null,
    configureNewSkuDetail: null,
    enableKeyRotation: 'Yes',
    accountKey1: 'cmp account key 1',
    accountKey2: 'cmp account key 2',
    connectionString1: 'cmp connection string 1',
    connectionString2: 'cmp connection string 2',
    staticWebpage: 'Disabled',
    performance: 'Standard',
    blobAccessTier: 'Cool',
};
export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
    Approval_Manager: '---',
    Subnet_Name: '---',
    Virtual_Network: '---',
    'TCP Reset': '---',
    'Floating IP': '---',
    Type: '---',
    SKU: '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSkuModalOpen: false,
    isConfigTagsModalOpen: false,
    isSubmitOrderSuccess: false,
    isUpdatedToCart: false,
    isCostSplitOpen: false,
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};
export const generateRandomKey = () => `${Math.random().toString(16).slice(2)}`;
