/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { landingZoneData, isProjectNameVaild } from 'pages/app-profiles/store';

const ProjectName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [isValid, setProjectNameVaild] = useRecoilState(isProjectNameVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setProjectName = (projectName) => {
        setLandingZoneForm({
            ...landingZoneForm,
            projectName,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 10 || value?.length < 3) {
                setProjectNameVaild(false);
                setProjectName(value);
            } else if (!/^[a-zA-Z]+$/.test(value)) {
                setProjectNameVaild(false);
                setProjectName(value);
            } else {
                setProjectNameVaild(true);
                setProjectName(value);
            }
        } else {
            setProjectNameVaild(false);
            setProjectName('');
        }
    };

    React.useEffect(() => {
        if (landingZoneForm?.projectName) setProjectName(landingZoneForm?.projectName);
    }, [landingZoneForm?.projectName]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landingZoneForm?.projectName?.length > 0);
    }, [landingZoneForm?.projectName]);

    React.useEffect(() => {
        if (!landingZoneForm?.projectName && landingZoneForm?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.projectName, landingZoneForm?.isTouched]);
    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Project Name *"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landingZoneForm?.projectName}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid || isTouched}
                    helperText={`
                    ${!isValid ? 'Project name should be min. 3 to max. 10 chars and alphabets only' : ''}
                `}
                />
            </Stack>
        </>
    );
};

export default React.memo(ProjectName);
