export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        department: environmentDetails?.department,
        departmentDetails: { label: environmentDetails?.department, value: environmentDetails?.department },
        cluster: catalogConfig?.cluster,
        appSupportMailId: catalogConfig?.appSupportMailId,
        clusterDetails: { label: catalogConfig?.cluster, value: catalogConfig?.cluster },
        catalogSize: null,
        catalogSizeDetails: { value: catalogConfig?.size },
        businessUnit: environmentDetails?.businessUnit,
        businessUnitDetails: { label: environmentDetails?.businessUnit, value: environmentDetails?.businessUnit },
        isaDocument: catalogConfig?.isaDocument?.toString(),
        project: catalogConfig?.project,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        adGroup: catalogConfig?.adGroup || null,
        nonProdNamespace: catalogConfig?.nonprodnamespace || '',
        primaryNamespace: catalogConfig?.primarynamespace || '',
        nonProdCluster: catalogConfig?.nonprodcluster || '',
        primaryCluster: catalogConfig?.primarycluster || '',
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        department: environmentDetails?.department,
        departmentDetails: { label: environmentDetails?.department, value: environmentDetails?.department },
        cluster: catalogConfig?.cluster,
        appSupportMailId: catalogConfig?.appSupportMailId,
        clusterDetails: { label: catalogConfig?.cluster, value: catalogConfig?.cluster },
        catalogSize: null,
        catalogSizeDetails: { value: catalogConfig?.size },
        businessUnit: environmentDetails?.businessUnit,
        businessUnitDetails: { label: environmentDetails?.businessUnit, value: environmentDetails?.businessUnit },
        isaDocument: catalogConfig?.isaDocument?.toString(),
        project: catalogConfig?.project,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        adGroup: catalogConfig?.adGroup || null,
        nonProdNamespace: catalogConfig?.nonprodnamespace || '',
        primaryNamespace: catalogConfig?.primarynamespace || '',
        nonProdCluster: catalogConfig?.nonprodcluster || '',
        primaryCluster: catalogConfig?.primarycluster || '',
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = (data = null, cloudService = null) => {
    const currentComponent = data?.landingZoneDetails?.config?.components?.filter((component) => component?.service === cloudService);
    return {
        appProfile: data?.appProfile,
        appProfileDetails: data?.appProfileDetails,
        entityDetails: data?.entityDetails,
        billingEntity: data?.entityDetails?.billingEntity || null,
        hCode: data?.entityDetails?.financialDetails || null,
        ...(data?.entityDetails?.financialDetails && {
            hCodeDetails: { label: data?.entityDetails?.financialDetails, projectName: data?.entityDetails?.projectName },
        }),
        ...(!data?.entityDetails?.financialDetails && { hCodeDetails: null }),
        sector: data?.sector,
        environment: data?.environment,
        region: data?.region || null,
        landingZoneDetails: {
            id: currentComponent?.[0]?.landingzoneId || null,
        },
    };
};
export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    department: null,
    departmentDetails: null,
    appSupportMailId: null,
    cluster: null,
    clusterDetails: null,
    catalogSize: null,
    catalogSizeDetails: null,
    businessUnit: null,
    businessUnitDetails: null,
    isaDocument: null,
    project: '',
    hCode: null,
    hCodeDetails: null,
    adGroup: null,
    appTier: 'AKS Namespace',
    appTierCode: 'AN',
    envDetails: null,
    nonProdNamespace: '',
    nonProdCluster: '',
    primaryNamespace: '',
    primaryCluster: '',
    tags: [],
    estimatedCost: {},
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetEnvironmentDetails = {
    Project: '-',
    Approval_Manager: '-',
    'ISA Document': '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isDiskOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};
export const aksErrorCodes = ['AKS001', 'AKS002', 'AKS003'];
export const isSupportNS = ({ errorCode }) => {
    if (errorCode === aksErrorCodes[0]) {
        return ['nonProdNamespace', 'nonProdCluster'];
    }
    if (errorCode === aksErrorCodes[1]) {
        return ['primaryNamespace', 'primaryCluster'];
    }
    if (errorCode === aksErrorCodes[2]) {
        return ['primaryNamespace', 'primaryCluster'];
    }
    return [];
};
export const checkConditionForValidationAPI = ({ order, isSupportNSFields = [] }) =>
    !!(
        order?.sector &&
        order?.environment &&
        order?.region &&
        order?.department &&
        order?.cluster &&
        order?.project &&
        (isSupportNSFields?.includes('nonProdNamespace') || order?.nonProdNamespace ? order?.nonProdNamespace : true) &&
        (isSupportNSFields?.includes('primaryNamespace') || order?.primaryNamespace ? order?.primaryNamespace : true) &&
        (isSupportNSFields?.includes('nonProdCluster') || order?.nonProdCluster ? order?.nonProdCluster : true) &&
        (isSupportNSFields?.includes('primaryCluster') || order?.primaryCluster ? order?.primaryCluster : true)
    );
