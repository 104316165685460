/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { adfCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddDataClassification, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const DataClassification = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDDataClassification, setLzDDDataClassification] = useRecoilState(ddDataClassification);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const dataClassificationApi = useMutation(() => axios.get(endPoints.landingZones.dataClassification));

    const setDataClassification = (dataClassification, dataClassificationDetails) => {
        // if (!lzModal?.lzDetails) {
        setLandingZoneForm({
            ...landZoneForm,
            dataClassification,
            dataClassificationDetails,
        });
        // }
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setDataClassification(selected?.label, selected?.data);
        } else {
            setDataClassification(null, null);
        }
    };
    React.useEffect(() => {
        if (dataClassificationApi?.isSuccess) {
            setOptions(dataClassificationApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.displayName, data: app })));
            setLzDDDataClassification({ ...lzDDDataClassification, isLoaded: true });
        }
    }, [dataClassificationApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const dataClassification = options.find((value) => lzModal?.lzDetails?.config?.dataClassification?.displayName === value?.label);
            if (dataClassification) setLzDDDataClassification({ ...lzDDDataClassification, isLoaded: true, data: dataClassification });
        }
    }, [options]);

    React.useEffect(() => {
        dataClassificationApi.mutate();
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (landZoneForm?.cloudService === adfCatalogServiceCode) {
            setLandingZoneForm({
                ...landZoneForm,
                dataClassification: 'Restricted',
            });
        }
    }, []);

    return (
        <>
            {landZoneForm?.cloudService === adfCatalogServiceCode ? (
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <CssTextField size="small" label="Data Classification" fullWidth value="Restricted" disabled />
                </Stack>
            ) : (
                <Box bgcolor="white">
                    <Select
                        key="dataClassification"
                        value={landZoneForm?.dataClassification || null}
                        options={options}
                        handleOnSelect={(value) => handleOnSelectChange(value)}
                        disabled={dataClassificationApi.isLoading}
                        isLoading={dataClassificationApi.isLoading}
                        disableClearable={false}
                        renderInput={(params) => (
                            <CssTextField
                                size="large"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Data Classification*"
                                InputProps={{
                                    ...params.InputProps,
                                }}
                                fullWidth
                            />
                        )}
                    />
                </Box>
            )}
        </>
    );
};

export default React.memo(DataClassification);
