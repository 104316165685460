import React from 'react';
import { Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003/store';
import Select, { CssTextField } from 'pages/reference-implementation/components/select';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

const filter = createFilterOptions();
const BusinessApp = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const bAppGetApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.businessApp}?applicationName=${ccInputValue?.toUpperCase()}`));
    const bAppPostApi = useMutation((payload) => axios.post(`${endPoints.riServices.COMMON.businessApp}`, payload));

    const setBusinessApp = (businessApp, businessAppDetails) => {
        setOrder({
            ...order,
            businessApp,
            businessAppDetails,
            appService: null,
            appOwner: null,
            appOwnerDetails: null,
            appShortName: null,
            applicationName: null,
        });
    };
    const resetOptions = () => {
        setOptions(initOptions);
        setBusinessApp(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                bAppGetApi?.mutate();
            }, 1000),
        []
    );
    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.businessApp) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);
    React.useEffect(() => {
        if (bAppGetApi?.isSuccess) {
            if (bAppGetApi?.data?.data?.applications) {
                const combinedValue = bAppGetApi?.data?.data?.applications.map((app) => ({
                    label: `${app.businessApplicationName}`,
                    value: app?.businessApplicationName,
                    data: app,
                }));
                setOptions(combinedValue);
            }
        }
    }, [bAppGetApi?.isSuccess]);
    React.useEffect(() => {
        if (bAppPostApi?.isSuccess) {
            if (bAppPostApi?.data?.data?.applications) {
                const combinedValue = bAppPostApi?.data?.data?.applications.map((app) => ({
                    label: `${app.businessApplicationName}`,
                    value: app?.businessApplicationName,
                    data: app,
                }));
                if (order?.businessAppDetails) combinedValue.push(order?.businessAppDetails);
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [bAppPostApi?.isSuccess]);
    React.useEffect(() => {
        bAppPostApi?.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);
    const isExistsInOptions = (region) => {
        return options.find((option) => option?.label === region.label || option?.value === region);
    };

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setBusinessApp(value?.label, value?.data);
        }
    }, [value]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.businessApp) {
            setOrder({ ...order, businessApp: options?.[0].label, businessAppDetails: options?.[0]?.data });
        }
        if (options && options?.length && order?.businessAppDetails && !order?.businessApp) {
            const businessapp = isExistsInOptions(order?.businessAppDetails);
            setOrder({ ...order, businessApp: businessapp?.label, businessAppDetails: businessapp?.data });
        }
    }, [options]);
    React.useEffect(() => {
        if (!order?.businessApp && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessApp, order?.isTouched]);
    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="businessApp"
                    size="small"
                    loading={bAppGetApi?.isLoading}
                    value={order?.businessApp || null}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                                value: newValue,
                                data: null,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                                value: newValue.inputValue,
                                data: null,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    freeSolo
                    renderInput={(params) => (
                        <CssTextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label={<InputLabel label="Business Application" isRequired />}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            fullWidth
                        />
                    )}
                    className={validateInput()}
                />
            </Box>
        </>
    );
};

export default React.memo(BusinessApp);
