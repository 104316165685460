import { styled } from '@mui/material/styles';
import { Stack, Box, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const CartContainer = styled(Stack)(() => ({
    background: '#F8F8F8 0% 0% no-repeat padding-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    width: '100%',
}));

export const Wrapper = styled(Box)(({ theme }) => ({
    background: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40,
    marginRight: 40,
    marginTop: 20,
    padding: 20,
    color: theme.palette.blue.main,
}));

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#242424',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '0px',
        border: 0,
        '&:nth-child(1)': {
            paddingLeft: '18px',
            // width:'170px'
        },
        // '&:nth-child(2)': {
        //     paddingLeft:'18px',
        //     width:'170px'
        // },
        // '&:nth-child(3)': {
        //     paddingLeft:'5px',
        //     width:'100px'
        // },
        // '&:nth-child(4)': {
        //     paddingLeft:'5px',
        //     width:'150px'
        // },
        // '&:nth-child(5)': {
        //     paddingLeft:'5px',
        //     width:'150px'
        // }
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '0px',
        color: '#003182',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderSpacing: 0,
        '&:nth-child(1)': {
            paddingLeft: '18px',
        },
        // '&:nth-child(2)': {
        //     paddingLeft:'18px',
        //     width:'170px'
        // },
        // '&:nth-child(3)': {
        //     paddingLeft:'5px',
        //     width:'100px'
        // },
        // '&:nth-child(4)': {
        //     paddingLeft:'5px',
        //     width:'150px'
        // },
        // '&:nth-child(5)': {
        //     paddingLeft:'5px',
        //     width:'150px'
        // }
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        height: '50px',

        // color: '#003182',
        // fontSize: '16px',
        // padding: '0px',
        // border: 0,
    },
    backgroundColor: theme.palette.white,
    height: '51px',
    '&:nth-of-type(odd)': {
        backgroundColor: '#ffffff',
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F8F8F8',
    },
    // '&:hover': {
    //     backgroundColor: theme.palette.cyan['90'],
    //     '&:last-child': {
    //         background: 'none',
    //     },
    // },
    // hide last border
    '&:last-child td, &:last-child th': {},
}));

export const cartStyle = {
    divider: {
        color: (theme) => theme.palette.gray.lowMedium,
        marginTop: (theme) => theme.spacing(1),
        marginBottom: (theme) => theme.spacing(2),
    },
    container: {
        accordion: {
            color: (theme) => theme.palette.blue.main,
        },
        cost: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: (theme) => theme.palette.gray.lowMedium,
            fontWeight: 600,
            // marginRight: '1%',
        },
        info: {
            color: (theme) => theme.palette.gray.lowMedium,
            marginRight: (theme) => theme.spacing(0.35),
        },
        table: {
            iconbtn: {
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: (theme) => theme.spacing(3.375),
                height: (theme) => theme.spacing(3.375),
                background: (theme) => theme.palette.blue.main,
            },
            editbtn: {
                width: (theme) => theme.spacing(3.75),
                height: (theme) => theme.spacing(3.75),
                fill: '#FFFFFF',
                backgroundColor: '#0047BA',
                cursor: 'pointer',
                marginLeft: '3px',
            },
            tableWrapper: {
                marginTop: '4px',
            },
            tableRow: {
                background: 'transparent !important',
            },
            tableCell: {
                border: '0 !important',
            },
            orderIdBtn: {
                padding: '0px',
                textDecoration: 'underline',
                cursor: 'pointer',
            },
            approvalBtn: {
                color: '#003182',
                cursor: 'pointer',
            },
            deleteBtn: {
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: (theme) => theme.spacing(3.375),
                height: (theme) => theme.spacing(3.375),
                background: '#F4273B',
            },
            rejectBtn: {
                fontSize: (theme) => theme.spacing(2.25),
                fill: '#FFFFFF',
                color: (theme) => theme.palette.white,
                cursor: 'pointer',
                width: (theme) => theme.spacing(1.5),
                height: (theme) => theme.spacing(1.625),
            },
            main: {
                marginTop: '-9px',
                borderSpacing: '0',
                minWidth: 500,
                width: '100%',
                border: '1px solid #E8E8E8',
                borderRadius: '5px',
            },
            container: {
                flexShrink: 0,
                ml: 2.5,
            },
            icon: {
                color: (theme) => theme.palette.white,
            },
            checkbox: {
                height: '32px',
                color: (theme) => theme.palette.white,
            },
            checkbox_1: {
                height: '30px',
            },
            pagination: {
                width: '100%',
                color: (theme) => theme.palette.white,
            },
        },
        note: {
            fontSize: '0.875rem',
            color: '#626262',
        },
        submitOrderBtn: {
            background: (theme) => theme.palette.orange.main,
            color: (theme) => theme.palette.white,
            fontSize: (theme) => theme.spacing(2),
            // width: (theme) => theme.spacing(18.75),
            // height: (theme) => theme.spacing(4.375),
        },
        addServiceBtn: {
            marginRight: 2,
            // background: (theme) => theme.palette.orange.main,
            color: '#626262',
            fontSize: (theme) => theme.spacing(2),
            // width: (theme) => theme.spacing(18.75),
            // height: (theme) => theme.spacing(4.375),
        },
    },
    deleteIcon: {
        background: '#DB1E36',
        borderRadius: 1,
        color: '#fff',
        marginLeft: 'auto',
        marginRight: 2,
    },
    dialog: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        icon: { color: '#d02424' },
        backBtn: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        AgreeBtn: { marginRight: (theme) => theme.spacing(1.25), textTransform: 'none' },
        noBtn: {
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        nsgOrderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'none', mr: (theme) => theme.spacing(0.5) },
        newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1) },
        newOrderBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
        backHome: {
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
        thankyouTypography: { textAlign: 'center' },
    },

    CostSummary: {
        summaryWrapper: {
            dispaly: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        card: {
            background: '#FFFFFF',
            padding: '20px',

            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
            // minHeight: '30%',
            // maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '600px' },
        },
        headingWrapper: { display: 'flex' },
        heading: { marginBottom: '8px', fontSize: '20px', fontWeight: 'medium', fontFamily: 'Stolzl' },
        loaderIcon: { color: (theme) => theme.palette.black.dark },
        costEstWrapper: { pb: 2, px: 0, pt: 1 },
        labelWrapper: { textAlign: 'right', marginTop: 2 },
        estWrapper: { display: 'flex' },
        item: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        unit: { fontSize: '12px', fontWeight: '300', fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark, width: '50%' },
        estCost: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'left', paddingLeft: 0, paddingBottom: 0 },
        labelStyle: {
            fontSize: { md: '12px', lg: '14px', xl: '16px' },
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            color: (theme) => theme.palette.black.dark,
            py: 0.5,
        },
        note: { fontSize: '12px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark, mt: '5px' },
        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        application: {
            fontSize: { md: '12px', lg: '16px' },
            fontFamily: 'Roboto',
            fontStyle: 'italic',
            color: '#000000',
            fontWeight: 'bold',
            paddingLeft: (theme) => theme.spacing(0.5),
        },
        totalCost: {
            fontSize: { md: '12px', lg: '16px' },
            fontFamily: 'Roboto',
            fontStyle: 'italic',
            color: '#000000',
            fontWeight: 'bold',
            pt: 1,
        },
        boxWrapper: { paddingLeft: 3 },
        servicesTypo: { fontSize: '12px', fontFamily: 'Roboto', color: '#000000', fontWeight: '300', py: '3px', textAlign: 'right' },
        serivcesValue: { marginLeft: '15px' },
        monthlyTitle: { fontSize: '12px', fontFamily: 'Roboto', color: '#000000', fontWeight: '300', py: '3px', textAlign: 'right' },
        OneTimeCost: { fontSize: '12px', fontFamily: 'Roboto', color: '#000000', fontWeight: '300', py: '3px', textAlign: 'right' },
        expandIcon: { color: '#0047BA', paddingLeft: '5px', fontSize: '30px', marginRight: '-5px' },
        gridItemPadding: { paddingLeft: '0px !important' },
        infoIconStyle: {
            marginLeft: '5px',
            marginBottom: '-5px',
            color: '#0047BA',
            fontSize: '25px',
            cursor: 'pointer',
        },
        totalOneTImeInfo: {
            marginLeft: '1px',
            color: '#0047BA',
            marginBottom: '-5px',
            fontSize: '25px',
            cursor: 'pointer',
        },
    },

    appAccordian: {
        catalogType: {
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontSize: { md: '10px', lg: '13px' },
            fontWeight: 400,
            letterSpacing: ' 0px',
            color: '#0047BA',
            opacity: 1,
        },
        expandIcon: { color: 'white' },
        descname: { color: '#000000', fontFamily: 'Roboto', fontSize: '14px', fontWeight: 'bold' },
        descValue: { fontFamily: 'Roboto', fontSize: '14px', color: '#0000000', marginLeft: '10px' },
        containersummary: {
            borderBottom: '1px solid #CCCCCC',
            background: '#0047BA 0% 0% no-repeat padding-box',
            height: '54px',
            borderRadius: '5px 5px 0px 0px',
        },
        cartIcon: { color: (theme) => theme.palette.white },
        stack: { width: '100%', paddingX: (theme) => theme.spacing(1) },
        application: { fontFamily: 'Roboto', fontSize: '16px', color: (theme) => theme.palette.white, position: 'relative', fontWeight: 'medium' },
        remove: {
            background: ' #AE172B 0% 0% no-repeat padding-box',
            height: '28px',
            width: '96px',
            borderRadius: '5px',
            color: (theme) => theme.palette.white,
            textTransform: 'none',
            position: 'relative',
            '&:hover': {
                background: ' #BE4555 0% 0% no-repeat padding-box',
            },
        },
        containerAccDetails: {
            paddingTop: (theme) => theme.spacing('26px'),
            borderRadius: '0px 0px 5px 5px',
            background: '#FAFAFA 0% 0% no-repeat padding-box',
        },
        tableAccorSummary: {
            background: '#E8E8E8 0% 0% no-repeat padding-box',
            minHeight: '50px !important',
            height: '50px !important',
            borderRadius: '4px 4px 0px 0px',
            padding: 0,
        },
        tableWrapper: {
            width: '-webkit-fill-available',

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: 3,
        },

        thCostCenter: {
            paddingLeft: 3,
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontSize: { md: '12px', lg: '16px' },
            fontWeight: 'Bold',
            letterSpacing: ' 0px',
            color: '#242424',
            opacity: 1,
        },

        tdExpandIcon: { color: '#616161', paddingRight: 1, fontSize: '35px' },

        summaryTypo: { color: '#000000', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'bold', paddingX: 2, marginBottom: 1 },
        gridcontainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: { md: 1, lg: 3.5 },
            paddingRight: { md: 1, lg: 2 },
        },
        tableheader: {
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontSize: { md: '12px', lg: '16px' },
            fontWeight: 'Bold',
            letterSpacing: ' 0px',
            color: '#242424',
            opacity: 1,
        },
        tableBodyVal: {
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontSize: { md: '10px', lg: '13px' },
            fontWeight: '500',
            letterSpacing: ' 0px',
            color: '#000000',
            opacity: 1,
        },
        cartItemSummaryWrapper: { background: '#F8F8F8 0% 0% no-repeat padding-box' },
        actionsWrapper: { paddingLeft: '10px' },
    },
    cartItemSummary: {
        gridcontainer: { paddingLeft: 1.8 },
    },
    submitOrdersBtn: { height: '40px', textTransform: 'none', color: (theme) => theme.palette.white, backgroundColor: '#0047BA', width: '155px' },
    continueShoppingBtn: {
        height: '40px',
        textTransform: 'none',
        color: (theme) => theme.palette.blue.main,
        backgroundColor: '#FFFFFFF',
        width: '155px',
        borderWidth: '2px',
        borderColor: '#0047BA',
        marginBottom: 2,
        marginTop: 2,
        '&:hover': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#00A0DD',
            color: '#00A0DD',
        },
    },
    cartSummaryWrapper: {
        px: (theme) => theme.spacing('53px'),
        paddingY: (theme) => theme.spacing(2),
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        height: (theme) => theme.spacing('82px'),
        background: '#FFFFFF 0% 0% no-repeat padding-box',
    },
    cartSummaryTitle: { fontFamily: 'Stolzl', fontSize: '30px', fontWeight: 'medium', color: (theme) => theme.palette.black.dark },
    costSummaryWrapper: { paddingX: (theme) => theme.spacing('53px'), marginTop: (theme) => theme.spacing(3) },
    costSummaryContainer: { paddingRight: (theme) => theme.spacing('10px') },
    emptycontainer: { backgroundColor: '#FAFAFA', borderTop: '1px solid #E8E8E8' },
    emptymsg: { fontFamily: 'Stolzl', fontSize: '20px', color: '#000000', fontWeigtht: 'medium' },
    // noorderapproval: { fontFamily: 'Roboto', fontWeight: 'normal', color: '#000000', fontSize: '16px' },

    appAccordianWrapper: { marginBottom: (theme) => theme.spacing(2) },
    applicationCostDetails: {
        wrapper: { paddingLeft: '0px !important' },
        accordian: { border: 'none', boxShadow: 'none', width: '-webkit-fill-available' },
        accordianSummary: { padding: '0 !important', height: '35px !important', minHeight: '15px !important' },
        application: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'medium',
            whiteSpace: 'nowrap',
            width: '198px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        expandIcon: { color: '#0047BA', paddingLeft: '5px', fontSize: '30px', marginRight: '-5px' },
    },
    catalogDetails: {
        gridLabel: { paddingLeft: (theme) => theme.spacing(4) },
        summary: { fontFamily: 'Stolzl', fontSize: '25px', fontWeight: 'medium', mb: 2, paddingLeft: '20px' },
        nodata: { color: '#000000' },
    },
    CostDetails: {
        card: {
            background: '#FFFFFF',
            px: '16px',
            pt: '16px',
            pb: '0px',
            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
            // minHeight: '30%',
            // maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '600px' },
        },
        headingWrapper: { display: 'flex' },
        heading: { marginBottom: '8px', fontSize: '20px', fontWeight: 'medium', fontFamily: 'Stolzl' },
        loaderIcon: { color: (theme) => theme.palette.black.dark },
        // summaryWrapper: { flexGrow: 1, overflowY: 'auto', display: 'flex' },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex' },
        item: { fontSize: '16px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        unit: { fontSize: '12px', fontWeight: '300', fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        estCost: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            color: (theme) => theme.palette.black.dark,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        labelStyle: { fontSize: '18px', fontWeight: 'bold', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        note: { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        application: { fontFamily: 'Stolzl', fontSize: '26px', fontWeight: 'medium', marginBottom: 1 },
        gridContainer: { marginTop: '0px !important' },
        catalogType: { fontFamily: 'Roboto', fontSize: '18px', fontWeight: 'bold' },
        OneTimeCostLabel: { fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'bold' },
        stackContainer: { marginTop: '5px !important' },
        infoWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '200px',
            maxWidth: '500px',
        },
        infogridContainer: { padding: '10px 0px 10px 10px' },
        infogriditem: {
            mb: (theme) => theme.spacing('3px'),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // flexGrow:1
        },
        infotypo: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
        infotypoValue: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
    },
    costSplit: {
        gridLabel: { paddingLeft: (theme) => theme.spacing(4) },
        stackWrapper: {
            alignItems: 'center',
            justifyContent: 'left',
            height: '22px',
        },
    },
    costAccordian: {
        accordian: {
            background: '#FAFAFA 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: '#ffffff',
            borderRadius: '0px 0px 5px 5px !important',
            marginBottom: 1,
            border: '1px solid #E8E8E8',
        },
        container: { background: '#0047BA 0% 0% no-repeat padding-box', borderRadius: 1 },
        summaryTitle: { fontFamily: 'Stolzl', fontSize: (theme) => theme.spacing('20px'), color: '#FFFFFF', fontWeight: 'normal' },
        accordianDetails: {
            background: '#FAFAFA',
            margin: (theme) => theme.spacing(0.25),
            marginTop: 0,
            color: '#333',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        expandIcon: { color: (theme) => theme.palette.white },
    },
};
