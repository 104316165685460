import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import Select, { CssTextField } from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';

export const ApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const appServiceApi = useMutation((payload) => axios.post(`${endPoints.day2Operation.ProjectOnboarding.applicationService}`, payload));

    const setApplicationService = (applicationService) => {
        setOrder({
            ...order,
            applicationService,
            applicationName: null,
            appShortName: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (applicationService) => options.find((option) => applicationService === option.value);

    const handleOnSelectChange = (selected) => setApplicationService(selected.value);

    React.useEffect(() => {
        if (appServiceApi?.isSuccess) {
            setOptions(appServiceApi?.data?.data?.applications?.map((app) => ({ label: app?.serviceName, value: app?.serviceName })));
        }
    }, [appServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.applicationService) {
            setOrder({ ...order, applicationService: options?.[0].value });
        }
        if (options && options?.length && order?.applicationService) {
            const applicationService = isExistsInOptions(order?.applicationService);
            setOrder({ ...order, applicationService: applicationService?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order?.adoPlatform === 'Production' && order?.businessApplicationDetails?.businessApplicationId) {
            appServiceApi.mutate({
                businessApplicationId: order?.businessApplicationDetails?.businessApplicationId,
                environment: 'prod',
            });
        }
    }, [order?.businessApplicationDetails]);

    React.useEffect(() => {
        if (!order?.applicationService && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationService, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {order?.adoPlatform === 'Production' ? (
                <Select
                    key="ApplicationService"
                    label="Application Service *"
                    value={order?.applicationService || null}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    disabled={appServiceApi.isLoading}
                    isLoading={appServiceApi.isLoading}
                    isRequired={isTouched}
                />
            ) : (
                <>
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                            '& label.MuiInputLabel-shrink': {
                                color: '#0047BA !important',
                            },
                        }}
                        data-testid="app-ci-text"
                        disabled
                        autoComplete="off"
                        label="Application Service *"
                        value={order?.applicationService || ''}
                    />
                </>
            )}
        </Box>
    );
};

export default React.memo(ApplicationService);
