/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const VmType = ({ indexVal }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const newVmTypeApi = useMutation((payload) => axios.post(endPoints.ssp.vm.vmTypes, payload));
    const catalogCodeApi = useMutation((code) => axios.get(`${endPoints.catalog.get}/${code}`));
    const setVmType = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    const seldValueExistsInOptions = (vmTypeValue) => options.find((option) => vmTypeValue === option?.data?.displayName || vmTypeValue === option?.data?.name);

    const handleOnSelectChange = (selected) => {
        if (selected?.value !== order?.vmType && seldValueExistsInOptions(selected?.value)) {
            setVmType('vmType', selected, indexVal, 'add');
        }
    };

    const resetOptions = () => {
        setOptions([]);
    };

    React.useEffect(() => {
        if (newVmTypeApi?.isSuccess) {
            setOptions(newVmTypeApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.displayName, data: app })));
        }
    }, [newVmTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (
            order?.appProfile &&
            order?.environment &&
            order?.appTier &&
            order?.region &&
            order?.landingZoneDetails &&
            order?.envDetails?.config?.subscription?.id &&
            order?.envDetails?.config?.availabilityZone &&
            order?.cloudService &&
            order?.nodes?.[indexVal]?.osTypeVersion &&
            order?.nodes?.[indexVal]?.osTypeVersionDetail?.hyperVGeneration
        ) {
            resetOptions();
            newVmTypeApi.mutate({
                hyperVGeneration: order?.nodes?.[indexVal]?.osTypeVersionDetail?.hyperVGeneration,
                region: order?.region,
                subscriptionID: order?.envDetails?.config?.subscription?.id,
                environment: order?.environment,
                catalogService: order?.cloudService,
                allowedZonesList: order?.envDetails?.config?.availabilityZone,
            });
        }
    }, [
        order?.appProfile,
        order?.environment,
        order?.appTier,
        order?.region,
        order?.landingZoneDetails,
        order?.envDetails?.config?.subscription?.id,
        order?.envDetails?.config?.availabilityZone,
        order?.cloudService,
        order?.nodes?.[indexVal]?.osTypeVersion,
        order?.nodes?.[indexVal]?.osTypeVersionDetail?.hyperVGeneration,
    ]);

    React.useEffect(() => {
        if (order?.nodes?.[indexVal]?.vmType && order?.nodes?.[indexVal]?.vmTypeDetails) {
            const maxDisk = order?.nodes?.[indexVal]?.vmTypeDetails?.maxDataDiskCount > 15 ? 15 : order?.nodes?.[indexVal]?.vmTypeDetails?.maxDataDiskCount;
            setVmType('maxDataDiskCount', maxDisk, indexVal);
        }
    }, [order?.nodes?.[indexVal]?.vmType, order?.nodes?.[indexVal]?.vmTypeDetails]);

    React.useEffect(() => {
        if (order?.nodes?.[indexVal]?.osTypeVersion === null) {
            resetOptions();
        }
    }, [order?.nodes?.[indexVal]?.osTypeVersion]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.nodes?.[indexVal]?.vmType) {
            const obj = {
                label: options?.[0]?.data?.displayName,
                value: options?.[0]?.data?.displayName,
                data: options?.[0]?.data,
            };
            setVmType('vmType', obj, indexVal, 'edit');
        }
        if (options && options?.length > 0 && order?.nodes?.[indexVal]?.vmType) {
            const vm = seldValueExistsInOptions(order?.nodes?.[indexVal]?.vmType);
            const obj = {
                label: vm?.data?.displayName,
                value: vm?.data?.displayName,
                data: vm?.data,
            };
            setVmType('vmType', obj, indexVal, 'edit');
        }
    }, [options]);

    React.useEffect(() => {
        if (catalogCodeApi?.isSuccess) {
            const data = {
                cloudProvider: catalogCodeApi?.data?.data?.cloudProvider,
                cloudService: catalogCodeApi?.data?.data?.cloudService,
                catalogType: catalogCodeApi?.data?.data?.displayName,
            };
            setVmType('serviceData', data, indexVal);
        }
    }, [catalogCodeApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.nodes?.[indexVal]?.catalogServiceCode !== '') {
            catalogCodeApi.mutate(order?.nodes?.[indexVal]?.catalogServiceCode);
        }
    }, [order?.nodes?.[indexVal]?.catalogServiceCode]);

    React.useEffect(() => {
        if (!order?.nodes?.[indexVal]?.vmType && order?.isTouched) setIsTouched(true);
    }, [order?.nodes?.[indexVal]?.vmType, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="vmType"
                label="VM Type *"
                value={order?.nodes?.[indexVal]?.vmType || null}
                options={options}
                isOptionEqualToValue={(option, value) => option.label.toString() === value.toString()}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={newVmTypeApi.isLoading}
                isLoading={newVmTypeApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

VmType.propTypes = {
    indexVal: PropTypes.number,
};

VmType.defaultProps = {
    indexVal: 0,
};

export default React.memo(VmType);
