const styles = {
    infoIcon: { color: '#0047BA', fontSize: '20px', marginTop: '3px' },

    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    application: { width: '100%' },
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: '90.43px', height: '93.43px' },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    },
    helpMessage: { color: '#FF3939' },
};

export default styles;
