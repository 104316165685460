import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import Form from 'pages/app-profiles/landing-zones-new/components/Form';
import { Box, Stack, Grid, Typography, Paper, Divider, CircularProgress } from '@mui/material';
import InfoImg from 'components/info-img';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { landingZoneData } from 'pages/app-profiles/store';
import Btn from 'components/button';
import { eipStackCatalogDefinitionId } from 'utils/catalogDefinitionId';
import LandingZoneStyle from '../../landing-zones/LandingZoneStyle';
import ViewLandingZone from './ViewLandingZone';
import { resetLandingZone, setLandingZoneOnLocalStorage } from '../utils';

const EIAPStackFields = ({ appProfileName, windowType, setLoading }) => {
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const getComponentsApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.eipStack.components?.replace('{cloudprovider}', payload?.provider)?.replace('{cloudservice}', payload?.service)}`)
    );

    // const getComponentsApi = useMutation(() => axios.get(endPoints.landingZones.eipStack.components));

    React.useEffect(() => {
        if (landingZoneForm?.provider && landingZoneForm?.service && landingZoneForm?.environment && landingZoneForm?.region) {
            getComponentsApi.mutate({
                provider: landingZoneForm?.provider,
                service: eipStackCatalogDefinitionId[0] || null,
            });
            // getComponentsApi.mutate();
        }
    }, [landingZoneForm?.provider, landingZoneForm?.service, landingZoneForm?.environment, landingZoneForm?.region]);

    const getLandingZoneDetails = (components, item) => {
        if (components?.find((component) => component?.name === item?.name)?.landingzoneId) {
            return { id: components?.find((component) => component?.name === item?.name)?.landingzoneId };
        }
        if (components?.find((component) => component?.name === item?.name)?.landingZoneDetails) {
            return { id: components?.find((component) => component?.name === item?.name)?.landingZoneDetails?.id };
        }
        return null;
    };

    React.useEffect(() => {
        if (getComponentsApi?.isSuccess) {
            const form = { ...landingZoneForm };
            try {
                if (getComponentsApi?.data?.data?.components?.length) {
                    form.components = getComponentsApi?.data?.data?.components?.map((item) => ({
                        componentId: item?.componentId,
                        landingZone: null,
                        landingZoneDetails: getLandingZoneDetails(landingZoneForm?.components, item),
                        name: item?.name,
                        provider: item?.provider,
                        service: item?.service,
                        optional: item?.optional,
                    }));
                }
                if (getComponentsApi?.data?.data?.connections?.length) {
                    form.connections = getComponentsApi?.data?.data?.connections?.map((item) => ({
                        connectionType: null,
                        connectionTypeDetails: { id: item?.connectionType },
                        name: item?.name,
                        sourceComponentId: item?.sourceComponent,
                        sourceComponentName: getComponentsApi?.data?.data?.components?.find((component) => component?.name === item?.sourceComponent)?.service,
                        targetComponentId: item?.targetComponent,
                        targetComponentName: getComponentsApi?.data?.data?.components?.find((component) => component?.name === item?.targetComponent)?.service,
                        optional: getComponentsApi?.data?.data?.components?.find((component) => component?.name === item?.targetComponent)?.optional,
                    }));
                }
            } catch (e) {
                // eslint-disable-next-line
                console.log(e);
            }
            setLandingZoneForm(form);
            // }
        }
    }, [getComponentsApi?.isSuccess]);

    return (
        <>
            <Grid item xs={6}>
                {' '}
                <Form.Environments />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.Region />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.DataClassification />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.LandingZoneLabel />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.LandingZoneDesc />
            </Grid>
            {!getComponentsApi?.isLoading && landingZoneForm?.components?.length > 0 && (
                <Grid item xs={12} mt={2}>
                    <Paper
                        sx={{
                            height: '100%',
                            borderRadius: (theme) => theme.spacing(0.75),
                            marginBottom: (theme) => theme.spacing(3.125),
                            border: '1px solid #E0E0E0',
                        }}
                    >
                        <Box display="flex" m={2}>
                            <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                                <Stack direction="row">
                                    Components
                                    <span style={{ marginLeft: '7px', marginTop: '-4px' }}>
                                        <InfoImg title="Astrik * marked landing zones are mandatory." />
                                    </span>
                                </Stack>
                            </Typography>
                        </Box>
                        <Divider />
                        <Box mx={2} my={2}>
                            {getComponentsApi?.isLoading && (
                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress color="inherit" size={16} sx={{ color: (theme) => theme.palette.black.dark }} />
                                    <Typography variant="body2" sx={{ color: (theme) => theme.palette.black.dark }}>
                                        Loading
                                    </Typography>
                                </Box>
                            )}
                            {!getComponentsApi?.isLoading &&
                                landingZoneForm?.components?.map((item, index) => (
                                    <Box key={index} display="flex" alignItems="center" mx={1} my={2}>
                                        <Grid item xs={4} mr={2}>
                                            <Form.ComponentName index={index} />
                                        </Grid>
                                        <Grid item xs={4} mr={2}>
                                            <Form.LandingZones index={index} appProfileName={appProfileName} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <ViewLandingZone index={index} />
                                        </Grid>
                                        <Grid item xs={3} mr={2}>
                                            <Btn
                                                variant="outlined"
                                                color="cmpPrimary"
                                                disabled={!landingZoneForm?.environment || !landingZoneForm?.region}
                                                onClick={() => {
                                                    setLoading(true);
                                                    setLandingZoneOnLocalStorage(JSON.stringify({ ...landingZoneForm, windowType }));
                                                    setLandingZoneForm({
                                                        provider: landingZoneForm?.components?.[index]?.provider,
                                                        service: landingZoneForm?.components?.[index]?.service,
                                                        environment: null,
                                                        environmentDetails: null,
                                                        region: null,
                                                        regionDetails: null,
                                                        ...resetLandingZone(landingZoneForm?.components?.[index]?.service),
                                                    });
                                                    // setLZModal({ isOpen: true, type: 'create', appProfileName });
                                                    setTimeout(() => {
                                                        setLoading(false);
                                                    }, 2000);
                                                }}
                                            >
                                                Add New
                                            </Btn>
                                        </Grid>
                                    </Box>
                                ))}
                        </Box>
                    </Paper>
                </Grid>
            )}
            {!getComponentsApi?.isLoading &&
                landingZoneForm?.connections?.filter(
                    (connection) => landingZoneForm?.components?.find((component) => component?.name === connection?.targetComponentId)?.landingZoneDetails?.id
                )?.length > 0 && (
                    <Grid item xs={12} mt={2}>
                        <Paper
                            sx={{
                                height: '100%',
                                borderRadius: (theme) => theme.spacing(0.75),
                                marginBottom: (theme) => theme.spacing(3.125),
                                border: '1px solid #E0E0E0',
                            }}
                        >
                            <Box display="flex" m={2}>
                                <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                                    Connections
                                </Typography>
                            </Box>
                            <Divider />
                            <Box mx={2} my={2}>
                                {getComponentsApi?.isLoading && (
                                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress color="inherit" size={16} sx={{ color: (theme) => theme.palette.black.dark }} />
                                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.black.dark }}>
                                            Loading
                                        </Typography>
                                    </Box>
                                )}
                                {!getComponentsApi?.isLoading &&
                                    landingZoneForm?.connections?.map(
                                        (item, index) =>
                                            landingZoneForm?.components?.find((component) => component?.name === item?.targetComponentId)?.landingZoneDetails
                                                ?.id && (
                                                <Box key={index} display="flex" alignItems="center" mx={1} my={2}>
                                                    <Grid item xs={4} mr={2}>
                                                        <Form.SourceResource index={index} />
                                                    </Grid>
                                                    <Grid item xs={4} mr={2}>
                                                        <Form.DestinationResource index={index} />
                                                    </Grid>
                                                    <Grid item xs={4} mr={2}>
                                                        <Form.ConnectionType index={index} />
                                                    </Grid>
                                                </Box>
                                            )
                                    )}
                            </Box>
                        </Paper>
                    </Grid>
                )}
        </>
    );
};

EIAPStackFields.propTypes = {
    appProfileName: PropTypes.string,
    windowType: PropTypes.string,
    setLoading: PropTypes.func,
};

EIAPStackFields.defaultProps = {
    appProfileName: '',
    windowType: '',
    setLoading: () => null,
};

export default EIAPStackFields;
