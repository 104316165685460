export const getPayload = ({ order = [], catalogCode }) => {
    const payload = {
        virtualNetworkName: order?.vnet,
        subnetName: order?.subnet,
        changeRequestNo: order?.requestNumber,
        catalogType: catalogCode,
    };
    return payload;
};
export default getPayload;
