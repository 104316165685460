import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Select from 'pages/reference-implementation/components/select';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003/store';
import { updateReaderADGroup } from 'pages/reference-implementation/provisioning/RI003/store/selector';
import useDebounce from 'pages/reference-implementation/provisioning/RI003/hooks/use-debounce';
import { isADGroupError, isDuplicate } from 'pages/reference-implementation/provisioning/RI003/utils/validation';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

const ADGroupReader = ({ indexVal, validationData, indexList, updateEmptyList }) => {
    const [order] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const updateGroup = useSetRecoilState(updateReaderADGroup);
    const adGroupsApi = useMutation((searchVal) => axios.get(`${endPoints.riServices.COMMON.adGroups}/${searchVal}`));

    const debouncedInputValue = useDebounce(order?.nonPrivilegedADGroupList?.[indexVal]?.readerInputValue, 500);

    const handleOnSelectChange = (selectedValue, index, key) => {
        updateGroup({ selectedValue, index, key });
    };
    const inputChangeHandler = (selectedValue, index, key) => {
        updateGroup({ selectedValue, index, key });
    };

    React.useEffect(() => {
        if (adGroupsApi?.isSuccess && adGroupsApi.data?.data && debouncedInputValue !== '') {
            const adList = adGroupsApi.data?.data?.map((adGroup) => ({
                label: adGroup,
                value: adGroup,
            }));
            setOptions(adList);
        }
    }, [adGroupsApi?.isSuccess]);

    useEffect(() => {
        if (debouncedInputValue) {
            adGroupsApi.mutate(debouncedInputValue);
        } else {
            setOptions([]);
        }
    }, [debouncedInputValue]);

    const isEmpty = (indexValue) => {
        if (indexList?.length) return indexList.includes(indexValue);
        return false;
    };

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="adGroupReader"
                    label={<InputLabel label="Active Directory Group Reader" isRequired />}
                    value={order?.nonPrivilegedADGroupList?.[indexVal]?.readerValue || null}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value, indexVal, 'inputSelect')}
                    isLoading={adGroupsApi.isLoading}
                    noOptionsText="Type to search"
                    autoComplete
                    includeInputInList
                    // filterSelectedOptions
                    fullWidth
                    onInputChange={(event, newInputValue) => {
                        updateEmptyList(indexVal);
                        inputChangeHandler(newInputValue, indexVal, 'inputChange');
                    }}
                    customValidate={
                        isDuplicate(
                            order?.nonPrivilegedADGroupList?.map((item) => item?.readerValue),
                            order?.nonPrivilegedADGroupList?.[indexVal]?.readerValue
                        ) ||
                        isADGroupError(validationData, order?.nonPrivilegedADGroupList?.[indexVal]?.readerValue) ||
                        isEmpty(indexVal)
                            ? 'validate'
                            : ''
                    }
                />
            </Box>
        </>
    );
};

ADGroupReader.propTypes = {
    indexVal: PropTypes.number,
    validationData: PropTypes.array,
    indexList: PropTypes.array,
    updateEmptyList: PropTypes.func,
};

ADGroupReader.defaultProps = {
    indexVal: 0,
    validationData: [],
    indexList: [],
    updateEmptyList: () => {},
};

export default React.memo(ADGroupReader);
