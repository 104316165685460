import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Paper, Divider } from '@mui/material';
// import style from '../dashboard.module.css';
import dashboardStyle from '../style';

function Chart(props) {
    const { title, children, w } = props;
    return (
        <Grid item xs={w}>
            <Paper sx={dashboardStyle.chart.chartPaper}>
                <Typography sx={dashboardStyle.chart.chartTitle}>{title}</Typography>
                <Divider />
                {children}
            </Paper>
        </Grid>
    );
}

Chart.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    w: PropTypes.number,
};

Chart.defaultProps = {
    title: '',
    w: 12,
    children: 'text',
};

export default React.memo(Chart);
