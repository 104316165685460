import React from 'react';
import { Box, Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';
import HelpImg from 'pages/provisioning/_components/helpImg';

const IndexDocumentName = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [showHelperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');
    const handleValueChange = (value) => {
        if (value !== order?.indexDocumentName) {
            setOrder({
                ...order,
                indexDocumentName: value,
            });
        }
    };
    React.useEffect(() => {
        if (order?.indexDocumentName && !/^[a-zA-Z0-9-_ ]{3,255}$/.test(order?.indexDocumentName)) {
            setHelperText(true);
            setHelperMsg('Index document name should be min. 3 to max. 255 chars only.');
        } else {
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.indexDocumentName]);

    return (
        <Stack direction="row" sx={ProvisioningStyles.stack}>
            <CssTextField
                autoComplete="off"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Index document name *"
                error={showHelperText}
                helperText={showHelperText && <span style={ProvisioningStyles.disposal.helpText}>{showHelperMsg}</span>}
                value={order?.indexDocumentName || ''}
                onChange={(e) => handleValueChange(e?.target?.value)}
            />
            <Box sx={ProvisioningStyles?.helpImg}>
                <HelpImg title="Please provide your index document name" />
            </Box>
        </Stack>
    );
};

export default IndexDocumentName;
