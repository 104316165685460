/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const HaDeployment = ({ indexVal }) => {
    const [order] = useRecoilState(sspSubmitOrder);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select key="HaDeployment" label="HA Deployment *" value={order?.nodes?.[indexVal]?.vmType ? 'Availability Zones' : ''} disabled />
        </Box>
    );
};

HaDeployment.propTypes = {
    indexVal: PropTypes.number,
};

HaDeployment.defaultProps = {
    indexVal: 0,
};

export default React.memo(HaDeployment);
