import React from 'react';
import { Box, Divider, Grid, Stack } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const ArtifactoryDetails = () => {
    const [order] = useRecoilState(OrderPayload);
    return (
        <Box sx={Style?.preview?.wrapper}>
            <Box sx={Style?.preview?.heading}>Artifactory</Box>
            <Divider variant="horizontal" sx={Style?.preview?.divder} />
            <Grid container sx={Style?.preview?.fs?.container}>
                <Grid item xs={12} sm={12}>
                    <Stack direction="column" spacing={1}>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Box sx={Style?.preview?.fs?.label}>Programing Language:</Box>
                            <Box sx={Style?.preview?.fs?.value}>{order?.progLanguage || '-'}</Box>
                        </Box>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Box sx={Style?.preview?.fs?.label}>Build Tools:</Box>
                            <Box sx={Style?.preview?.fs?.value}>{order?.buildTool || '-'}</Box>
                        </Box>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Box sx={Style?.preview?.fs?.label}>Build Tools:</Box>
                            <Box sx={Style?.preview?.fs?.value}>{order?.packageType || '-'}</Box>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ArtifactoryDetails;
