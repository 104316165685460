import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { orderPayload, validVSForm, restrictedPorts, selectedVSService } from 'pages/provisioning/virtual-service/store';
import InfoImg from 'components/info-img';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';

export const ServicePort = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [restrictedPortsOptions] = useRecoilState(restrictedPorts);
    const [selectedVS] = useRecoilState(selectedVSService);

    const [helperText, setHelperText] = React.useState(false);
    const [isTouched, setIsTouched] = React.useState(false);
    const [restrictedPort, setRestrictedPort] = React.useState(false);
    const handleValueChange = (servicePort) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                if (index === selectedVS) {
                    return {
                        ...item,
                        aksService: [
                            {
                                ...item?.aksService[0],
                                servicePort,
                            },
                            ...item?.aksService.slice(1),
                        ],
                    };
                }
                return item;
            }),
        }));
    };

    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort !== undefined &&
            !/^[0-9]{2,5}$/.test(order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort)
        ) {
            setRestrictedPort(false);
            setHelperText(true);
            setValidForm({ ...validForm, isInvalidServicePort: true });
        } else {
            if (restrictedPortsOptions.indexOf(order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort) !== -1) {
                setRestrictedPort(true);
                setHelperText(true);
                setValidForm({ ...validForm, isInvalidServicePort: true });
                return;
            }
            setHelperText(false);
            setValidForm({ ...validForm, isInvalidServicePort: false });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort]);
    const validateInput = () => {
        if ((isTouched && order?.isConfigureTouched && !order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort) || helperText) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (!order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort && order?.isConfigureTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort, order?.isConfigureTouched]);
    const getToolTip = () => {
        return (
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>1) Service port can have only numeric values.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>2) It can contain 2 to 5 digits.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            8080
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };
    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="service-port-box">
            <CssTextField
                size="small"
                fullWidth
                autoComplete="off"
                placeholder="Please enter 2 to 5 digits"
                label="Service Port *"
                // isRequired={isTouched}
                error={!!(helperText || isTouched || validateInput())}
                helperText={helperText && <span>{restrictedPort ? 'This is a restricted Service Port' : 'Service Port should be 2 to 5 digits long.'}</span>}
                value={order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
            />
            {getToolTip()}
        </Box>
    );
};
export default React.memo(ServicePort);
