import React from 'react';
import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import { Stack, Box, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import styles from './style';

const SASToken = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [helperMsg, setHelperMsg] = React.useState(false);
    const [showHelperText, setHelperText] = React.useState(false);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);
    const handleOnSelectChange = (selected) => {
        if (selected !== order?.sasToken) {
            setOrder({
                ...order,
                sasToken: selected,
            });
        }
    };

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.sasToken) || showHelperText) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (order?.sasToken && order?.sasToken !== '' && order?.sasToken !== undefined && !/^\?(.*)/.test(order?.sasToken)) {
            setHelperText(true);
            setHelperMsg('Invalid SAS Token.');
            setValidForm({ ...validForm, isSasTokenValid: true });
        } else {
            setValidForm({ ...validForm, isSasTokenValid: false });
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.sasToken]);
    React.useEffect(() => {
        if (!order?.sasToken && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.sasToken, order?.isTouched]);
    return (
        <Stack direction="row" spacing={1} sx={styles.stack} data-testid="sas-token-text">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="SAS Token *"
                autoComplete="off"
                error={isTouched || showHelperText}
                helperText={showHelperText && <span style={styles?.helpText}>{helperMsg}</span>}
                value={order?.sasToken || ''}
                onChange={(e) => handleOnSelectChange(e.target.value)}
                className={validateInput()}
            />
            <HelpImg
                title={
                    <>
                        <Box sx={styles.infoStyle.wrapper}>
                            <Typography sx={styles.infoStyle.typographyStyle}>The SAS Token should start with &quot;?&quot;.</Typography>
                            <Typography sx={styles.infoStyle.typographyStyle}>
                                {' '}
                                <span style={{ fontWeight: '600' }}>Example:</span>
                                <br />
                                ?sv=2021-10-04&se=2022-03-20T11%3A20%3A45Z&sr=b&sp=r&sig=M5GaoRgyIWcdCNsUuk%3D
                            </Typography>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

export default SASToken;
