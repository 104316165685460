import React from 'react';
import { Box } from '@mui/material';
import ApplicationSection from 'pages/reference-implementation/provisioning/RI003O/components/sections/Application';
import AccessMgmtSection from 'pages/reference-implementation/provisioning/RI003O/components/sections/AccessMgmtSection';
import ResourceMgmtSection from 'pages/reference-implementation/provisioning/RI003O/components/sections/ResourceMgmtSection';

const IndexForm = () => {
    return (
        <Box flex={3}>
            <ApplicationSection />
            <ResourceMgmtSection />
            <AccessMgmtSection />
        </Box>
    );
};

export default IndexForm;
