import React from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, TablePagination } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import TablePaginationActions from 'components/tablePaginationActions';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Empty from 'components/empty';
import { useTranslation } from 'react-i18next';
import {
    appProfileTypeState,
    profileForm,
    toggleConfirmProfileModel,
    toggleCreateProfile,
    appProfileTotalCount,
    profileFilter,
} from 'pages/app-profiles/store';
import profileStyle, { StyledTableCell, StyledTableRow } from 'pages/app-profiles/profile/style';
import { scope } from 'utils/scope';
import { getUserScopes, titleCase } from 'utils';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { filterDefaultObj, isCreatedByUser } from 'pages/app-profiles/utils';
import { getAppProfileData } from 'pages/app-profiles/profile/getAppProfileData';

const AppProfileList = () => {
    const { t } = useTranslation();
    const [appProfileType, setAppProfileType] = useRecoilState(appProfileTypeState);
    const [totalCount, setTotalCount] = useRecoilState(appProfileTotalCount);
    const [profileType, setProfileType] = useRecoilState(profileFilter);
    const appListApi = useMutation((payload) =>
        axios.post(`${profileType?.profileType === 'My AppProfiles' ? endPoints.appProfile.myAppProfiles : endPoints.appProfile.allAppProfiles}`, payload)
    );
    const [form, setForm] = useRecoilState(profileForm);
    const [confirmModal, setConfirmModal] = useRecoilState(toggleConfirmProfileModel);
    const [profileModal, setProfileModal] = useRecoilState(toggleCreateProfile);
    const history = useHistory();
    const handleChangePage = (event, newPage) => {
        setAppProfileType({ ...appProfileType, pageNumber: newPage + 1 });
    };

    const handleDelete = (id) => {
        setConfirmModal({
            ...confirmModal,
            type: 'delete',
            isOpen: true,
            message: t([`appProfile.confirmText.delete`]),
            id,
        });
    };

    React.useEffect(() => {
        setProfileType(filterDefaultObj());
    }, []);

    React.useEffect(() => {
        setAppProfileType({ key: '', pageNumber: 1, pageSize: 10 });
    }, [profileType]);

    React.useEffect(() => {
        appListApi.mutate({ page: appProfileType?.pageNumber, size: appProfileType?.pageSize, searchKey: appProfileType?.key });
    }, [appProfileType]);

    React.useEffect(() => {
        if (appListApi?.data) {
            setTotalCount(appListApi?.data?.data?.totalAppProfileCount);
        }
    }, [appListApi?.data]);

    const handleChangeRowsPage = (event) => {
        setAppProfileType({ ...appProfileType, pageNumber: 1, pageSize: parseInt(event.target.value, 10) });
    };
    const modTableActions = () => (
        <TablePaginationActions
            rowsPerPageOptions={[5, 10, 20, 25, 100]}
            handleChRowsPerPage={handleChangeRowsPage}
            count={totalCount}
            page={appProfileType?.pageNumber - 1}
            rowsPerPage={appProfileType?.pageSize}
            onPageChange={handleChangePage}
        />
    );
    return (
        <>
            <Box sx={profileStyle?.list?.wrapper} data-testid="app-profile-list">
                <TableContainer sx={profileStyle?.list?.tableContainer}>
                    <Table sx={profileStyle?.list?.table} size="small" stickyHeader>
                        <TableHead>
                            <TableRow sx={profileStyle?.list?.hrow}>
                                <StyledTableCell>&nbsp;</StyledTableCell>
                                <StyledTableCell>App Name</StyledTableCell>
                                <StyledTableCell>Business CI</StyledTableCell>
                                <StyledTableCell>Financial Details</StyledTableCell>
                                <StyledTableCell>Recovery Priority</StyledTableCell>
                                <StyledTableCell sx={{ paddingLeft: '-30px' }}>Billing Entity</StyledTableCell>
                                <StyledTableCell>Sector</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Created By</StyledTableCell>
                                <StyledTableCell align="center">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ border: '1px solid #E8E8E8' }}>
                            {appListApi?.isLoading && (
                                <StyledTableRow sx={profileStyle?.list?.emptyRow?.row}>
                                    <StyledTableCell sx={profileStyle?.list?.emptyRow?.cell} colSpan="8">
                                        <Empty isLoading={appListApi?.isLoading} msg="Loading..." color="#000000" />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {!appListApi.isLoading && (appListApi.error || !appListApi?.data?.data?.appProfiles?.length > 0) && (
                                <StyledTableRow sx={profileStyle?.list?.emptyRow?.row}>
                                    <StyledTableCell sx={profileStyle?.list?.emptyRow?.cell} colSpan="8">
                                        <Empty isLoading={appListApi?.isLoading} notFound msg={appListApi?.error || 'No records'} color="#0000000" />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {!appListApi.isLoading &&
                                appListApi?.data?.data?.appProfiles?.length &&
                                appListApi?.data?.data?.appProfiles?.map((result, index) => (
                                    <StyledTableRow sx={profileStyle?.list?.row} className="animate-opacity" key={`row-${index.toString()}`}>
                                        <StyledTableCell>
                                            <Box component="span" sx={{ paddingX: '10px' }}>
                                                {isCreatedByUser({ name: result?.created?.by?.name }) && (
                                                    <AccountCircleIcon sx={profileStyle?.list?.userIcon} color="cmpPrimary" />
                                                )}
                                                {!isCreatedByUser({ name: result?.created?.by?.name }) && (
                                                    <VerifiedUserIcon sx={profileStyle?.list?.userIcon} color={result?.isDraft ? 'cmpWarning' : 'cmpSuccess'} />
                                                )}
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            data-testid={`app-details-${index}`}
                                            onClick={() => {
                                                history.push(`/app-details/${result?.id}`);
                                            }}
                                        >
                                            {titleCase(result?.name?.toLowerCase())}
                                        </StyledTableCell>
                                        <StyledTableCell>{titleCase(result?.businessCi?.toLowerCase())}</StyledTableCell>
                                        <StyledTableCell>{result?.financialDetails}</StyledTableCell>
                                        <StyledTableCell>{result?.recoveryPriority}</StyledTableCell>
                                        <StyledTableCell>{titleCase(result?.billingEntity?.toLowerCase())}</StyledTableCell>
                                        <StyledTableCell>{result?.sector}</StyledTableCell>
                                        <StyledTableCell>{result?.isDraft ? 'Draft' : 'Approved'}</StyledTableCell>
                                        <StyledTableCell>{result?.created?.by?.name}</StyledTableCell>
                                        <StyledTableCell>
                                            <Box display="flex" justifyContent="center">
                                                <Box
                                                    sx={profileStyle?.list?.editBtn}
                                                    onClick={() => {
                                                        const appProfileData = getAppProfileData({ data: result });
                                                        setForm({
                                                            ...form,
                                                            ...appProfileData,
                                                        });
                                                        setProfileModal({ ...profileModal, isOpen: true, type: 'edit', profileDetails: result });
                                                    }}
                                                    data-testid="edit-btn"
                                                >
                                                    <FaEdit style={profileStyle?.list?.editIcon} />
                                                </Box>
                                                {getUserScopes && getUserScopes()?.includes(scope[2]) && (
                                                    <Box sx={profileStyle?.list?.deleteBtn} onClick={() => handleDelete(result?.id)} data-testid="delete-btn">
                                                        <FaTrashAlt style={profileStyle?.list?.deleteIcon} />
                                                    </Box>
                                                )}
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                    {!appListApi.isLoading && appListApi?.data?.data?.appProfiles?.length && totalCount !== 0 && (
                        <TablePagination
                            component="div"
                            sx={profileStyle?.list?.tablePagination}
                            count={totalCount || 0}
                            rowsPerPage={appProfileType?.pageSize}
                            page={appProfileType?.pageNumber - 1}
                            SelectProps={{
                                sx: {
                                    display: 'none',
                                },
                            }}
                            labelRowsPerPage=""
                            ActionsComponent={modTableActions}
                            onPageChange={() => {}}
                        />
                    )}
                </TableContainer>
            </Box>
        </>
    );
};

export default AppProfileList;
