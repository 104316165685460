import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Grid, Divider, Typography, Tooltip } from '@mui/material';
import range from 'lodash/range';
import Select, { CssTextField } from 'components/select';
import MultiSelect from 'components/multiSelect';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder, duplicateClusterService, isInvalidMemoryQuota } from 'pages/provisioning/store/couchbase';
import { addServiceMappings, removeServiceMappings, updateServiceMappings } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

const ServiceMappings = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [, setIsDuplicateClusterService] = useRecoilState(duplicateClusterService);
    const [, setInvalidMemoryQuota] = useRecoilState(isInvalidMemoryQuota);
    const [shrinkField, setShrinkField] = React.useState(false);
    const [nodeOptions, setNodeOptions] = React.useState([]);
    const [nodeChange, setNodeChange] = React.useState(false);
    const [serviceMappingsApiData, setServiceMappingsApiData] = React.useState([]);
    const [clusterServiceOptions, setClusterServiceOptions] = React.useState([]);
    const addMappingData = useSetRecoilState(addServiceMappings);
    const removeMappingData = useSetRecoilState(removeServiceMappings);
    const updateMappingData = useSetRecoilState(updateServiceMappings);
    const { cartItemId, operation, catalogServiceId } = useParams();

    const getServiceMappings = useMutation(() => axios.get(endPoints.ssp.couchbase.serviceMappings));

    React.useEffect(() => {
        if (getServiceMappings?.isSuccess) {
            setClusterServiceOptions(getServiceMappings?.data?.data?.serviceMapping?.map((app) => ({ label: app.clusterService, value: app.clusterService })));
            setServiceMappingsApiData(getServiceMappings?.data?.data?.serviceMapping);
        }
    }, [getServiceMappings?.isSuccess]);

    React.useEffect(() => {
        getServiceMappings.mutate();
    }, []);

    const getNodeDetailValues = (nodeVal) => {
        const res = nodeVal && nodeVal?.map((item) => nodeOptions.filter((opt) => opt.value === item));
        return res?.length ? res.reduce((acc, val) => acc.concat(val), []) : [];
    };

    const changeSelection = (key, selected, index) => {
        updateMappingData({ key, selected, index, serviceMappingsApiData });
    };

    const handleOnSelectChange = (selected, index) => {
        const s = selected?.length && selected.map((obj) => obj.value);
        changeSelection('nodes', s, index);
    };

    React.useEffect(() => {
        if (order?.nodesCount !== null) {
            setNodeOptions(
                range(1, Number(order?.nodesCount) + 1).map((i) => ({
                    label: `Node${i}`,
                    value: `Node${i}`,
                }))
            );
        }
    }, [order?.nodesCount]);

    const isDuplicateClusterService = (value) => {
        const serviceMappingValues = order?.serviceMappings?.map((data) => data?.clusterService);
        const duplicateCluster = serviceMappingValues.filter((item, index) => serviceMappingValues.indexOf(item) !== index);
        if (value) {
            setIsDuplicateClusterService(true);
            return duplicateCluster.indexOf(value) > -1;
        }
        return false;
    };

    const memoryQuotaValidation = (memQuota, memReq) => {
        setInvalidMemoryQuota(false);
        if (memReq === 'true' && memQuota && (memQuota !== '' || memQuota !== null) && !/^[0-9]+$/.test(memQuota)) {
            setInvalidMemoryQuota(true);
            return true;
        }
        return false;
    };

    return (
        <>
            <Grid container spacing={2}>
                {order?.serviceMappings?.map((mappingData, index) => (
                    <React.Fragment key={index.toString()}>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                    <Select
                                        key="clusterService"
                                        label="Cluster Service*"
                                        value={mappingData?.clusterService || null}
                                        handleOnSelect={(value) => changeSelection('clusterService', value, index)}
                                        options={clusterServiceOptions}
                                        isRequired={mappingData?.isServiceMappingTouched && !mappingData?.clusterService}
                                        disabled={index === 0 && mappingData?.clusterService === 'data'}
                                        data-testid="cluster-service"
                                    />
                                </Stack>
                                {isDuplicateClusterService(mappingData?.clusterService) && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.couchbaseStyle.warningTypography}>
                                            Duplicate cluster service is not allowed.
                                        </Typography>
                                    </Grid>
                                )}
                                <Box sx={ProvisioningStyles.box}>
                                    <MultiSelect
                                        key="nodeDetail"
                                        label="Node Detail*"
                                        value={getNodeDetailValues(mappingData?.nodes)}
                                        options={nodeOptions}
                                        handleOnSelect={(value) => {
                                            setNodeChange(true);
                                            handleOnSelectChange(value, index);
                                        }}
                                        closeOnSelect
                                        isRequired={
                                            mappingData?.isServiceMappingTouched &&
                                            (mappingData?.nodes?.length === 0 || mappingData?.nodes?.length === undefined)
                                        }
                                    />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                    <CssTextField
                                        size="small"
                                        sx={{
                                            width: (theme) => theme.spacing('100%'),
                                            '& .MuiFormHelperText-root': {
                                                marginTop: (theme) => theme.spacing('-2'),
                                            },
                                            ' & label.MuiInputLabel-root.MuiInputLabel-shrink': {
                                                color: mappingData?.memoryQuota && '#0047BA',
                                            },
                                        }}
                                        autoComplete="off"
                                        label="Memory Quota(in MB)*"
                                        value={mappingData?.memoryQuota}
                                        onChange={(e) => {
                                            changeSelection('memoryQuota', e.target.value, index);
                                            if (e.target.value.length > 0) setShrinkField(true);
                                            else setShrinkField(false);
                                        }}
                                        onClick={() => setShrinkField(true)}
                                        onBlur={() => {
                                            if (mappingData?.memoryQuota?.length > 0) setShrinkField(true);
                                            else setShrinkField(false);
                                        }}
                                        InputLabelProps={{
                                            shrink:
                                                (mappingData.isMemoryRequired === 'false' && mappingData?.memoryQuota !== '') ||
                                                (mappingData.isMemoryRequired === 'true' && shrinkField) ||
                                                (mappingData.isMemoryRequired === 'true' && mappingData?.memoryQuota !== '') ||
                                                (cartItemId && operation && !catalogServiceId && mappingData?.memoryQuota !== '') ||
                                                (cartItemId && operation && catalogServiceId && mappingData?.memoryQuota !== '') ||
                                                false,
                                        }}
                                        disabled={
                                            mappingData?.isMemoryRequired === 'false' || !mappingData?.clusterService || mappingData?.memoryQuota === 'NA'
                                        }
                                        data-testid="memory-quota"
                                        className={
                                            memoryQuotaValidation(mappingData?.memoryQuota, mappingData?.isMemoryRequired)
                                                ? 'validate'
                                                : '' || (mappingData?.isServiceMappingTouched && !mappingData?.memoryQuota ? 'validate' : '')
                                        }
                                    />
                                    <HelpImg title="Memory quota should contain only digits" />
                                </Stack>
                                {mappingData?.memoryQuota && memoryQuotaValidation(mappingData?.memoryQuota, mappingData?.isMemoryRequired) && (
                                    <Typography variant="body2" gutterBottom sx={ProvisioningStyles.couchbaseStyle.warningTypography}>
                                        Memory quota should contain only digits.
                                    </Typography>
                                )}
                                <Stack direction="row">
                                    <Grid item xs={9.6}>
                                        <Tooltip title={mappingData?.storagePath} arrow>
                                            <CssTextField
                                                size="small"
                                                sx={{
                                                    width: (theme) => theme.spacing('100%'),
                                                    '& .MuiFormHelperText-root': {
                                                        marginTop: (theme) => theme.spacing('-2'),
                                                    },
                                                    ' & label.MuiInputLabel-root.MuiInputLabel-shrink': {
                                                        color: mappingData?.clusterService && '#0047BA',
                                                    },
                                                }}
                                                autoComplete="off"
                                                label="Data Storage Path*"
                                                value={mappingData?.storagePath}
                                                InputLabelProps={{ shrink: mappingData?.storagePath || false }}
                                                disabled
                                                data-testid="storage-path"
                                                className={mappingData?.isServiceMappingTouched && !mappingData?.storagePath ? 'validate' : ''}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={2.4}>
                                        <Grid container justifyContent="flex-end">
                                            {index !== 0 && order?.serviceMappings?.length > 1 && (
                                                <Box sx={ProvisioningStyles.couchbaseStyle.deleteWrapper} mr={1}>
                                                    <FaTrashAlt
                                                        style={ProvisioningStyles.couchbaseStyle.delete}
                                                        onClick={() => removeMappingData(mappingData)}
                                                        className="w-7 h-7 text-red-base cursor-pointer"
                                                    />
                                                </Box>
                                            )}
                                            {index === order?.serviceMappings?.length - 1 && order?.serviceMappings?.length < clusterServiceOptions.length ? (
                                                <Box sx={ProvisioningStyles.couchbaseStyle.addWrapper}>
                                                    <MdAdd
                                                        style={ProvisioningStyles.couchbaseStyle.add}
                                                        onClick={() => {
                                                            setShrinkField(false);
                                                            addMappingData({ count: 1 });
                                                        }}
                                                        className="w-7 h-7 text-blue-base cursor-pointer"
                                                    />
                                                </Box>
                                            ) : (
                                                <span className="w-7 h-7" />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Stack>
                        </Grid>
                        {order?.serviceMappings?.length > 1 && index !== order?.serviceMappings?.length - 1 && (
                            <Grid item xs={12}>
                                <Divider style={{ borderColor: 'rgba(0, 0, 0, 0.30)' }} />
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
            {nodeChange && order?.unassignedNodes?.length > 0 && (
                <Typography variant="body2" gutterBottom sx={ProvisioningStyles.couchbaseStyle.warningTypography}>
                    {order?.unassignedNodes?.join(', ')} not assigned to any cluster service.
                </Typography>
            )}
        </>
    );
};

export default ServiceMappings;
