import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const ApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const appServiceApi = useMutation((payload) => axios.post(`${endPoints.riServices.COMMON.applicationService}`, payload));

    const setApplicationService = (appService) => {
        setOrder({ ...order, appService, applicationName: null, appShortName: null });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (dataClassifiation) => options.find((option) => dataClassifiation === option.value);

    const handleOnSelectChange = (selected) => setApplicationService(selected.value);

    React.useEffect(() => {
        if (appServiceApi?.isSuccess) {
            setOptions(appServiceApi?.data?.data?.applications?.map((app) => ({ label: app?.serviceName, value: app?.serviceName })));
        }
    }, [appServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.appService) {
            setOrder({ ...order, appService: options?.[0].value });
        }
        if (options && options?.length && order?.appService) {
            const appService = isExistsInOptions(order?.appService);
            setOrder({ ...order, appService: appService?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order?.businessAppDetails?.businessApplicationId && order?.businessAppService === 'Yes' && order?.envShortName) {
            appServiceApi.mutate({
                businessApplicationId: order?.businessAppDetails?.businessApplicationId,
                environment: order?.envShortName,
            });
        }
    }, [order?.businessAppDetails, order?.businessAppService, order?.envShortName]);

    React.useEffect(() => {
        if (!order?.appService && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appService, order?.isTouched]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="ApplicationService"
                label={<InputLabel label="Application Service" isRequired />}
                value={order?.appService || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={appServiceApi.isLoading || !order?.envShortName || !order?.businessAppDetails}
                isLoading={appServiceApi.isLoading}
                isRequired={isTouched}
            />
            <InputInfo
                title={
                    <>
                        Please click{' '}
                        <a href="/onboard-application-service" target="_blank" rel="noreferrer">
                            here
                        </a>{' '}
                        if you want to create new application service under the selected business application
                    </>
                }
            />
        </Box>
    );
};

export default React.memo(ApplicationService);
