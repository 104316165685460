import _ from 'lodash';

export const isReadyToSubmit = ({ order, isValidForm }) => {
    const invalidCodeRepo = () => {
        let errorCount = 0;
        const codeRepository = order?.codeRepoList?.map((item) => item?.repo);

        order?.codeRepoList?.forEach((z) => {
            if (
                z?.repo === null ||
                z?.repo === '' ||
                z?.repo === undefined ||
                (z?.repo !== null &&
                    z?.repo !== '' &&
                    z?.repo !== undefined &&
                    // eslint-disable-next-line
                    !/^(?!http:|https:|\/)([a-zA-Z0-9-\/._]+)$/.test(z?.repo))
            ) {
                errorCount += 1;
            }
        });
        if (errorCount > 0 || new Set(codeRepository).size !== codeRepository.length) {
            return true;
        }
        return false;
    };
    return (
        !order?.applicationCI ||
        !order?.applicationOwner ||
        !order?.applicationName ||
        !order?.applicationUrl ||
        !order?.environment ||
        !order?.sector ||
        !order?.teamName ||
        invalidCodeRepo() ||
        isValidForm?.isAppNameValid ||
        isValidForm?.isAppUrlValid ||
        order?.memberList?.length === 0
    );
};

export const getOrderPayload = ({ order }) => {
    const newMemberArray = order?.memberList?.map((obj) => {
        return _.omit(obj, ['label']);
    });
    const payload = {
        applicationCI: order?.applicationCI,
        applicationOwner: order?.applicationOwnerDetails?.gpid,
        applicationName: order?.applicationName,
        applicationURL: order?.applicationUrl,
        environment: order?.environment,
        sector: order?.sector,
        teamName: order?.teamName,
        codeRepository: order?.codeRepoList?.map((item) => ({
            name: 'https://dev.azure.com/PepsiCoIT',
            url: item?.repo,
        })),
        members: newMemberArray,
    };
    return payload;
};

export const getResubmitOrderPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    const payload = {
        applicationCI: catalog?.applicationCI,
        applicationOwner: order?.manager?.id,
        applicationName: catalog?.applicationName,
        applicationURL: catalog?.applicationURL,
        environment: catalog?.environment,
        sector: catalog?.sector,
        teamName: catalog?.teamName,
        codeRepository: catalog?.codeRepository,
        members: catalog?.members,
    };
    return payload;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    const catalog = data?.catalogServices[0] || null;
    const catalogConfig = catalog?.catalogConfig || null;
    return {
        catalogCode: catalog?.catalogServiceCode,
        applicationCI: catalogConfig?.applicationCI,
        applicationOwner: data?.manager?.name,
        applicationName: catalogConfig?.applicationName,
        applicationUrl: catalogConfig?.applicationURL,
        environment: catalogConfig?.environment,
        sector: catalogConfig?.sector,
        teamName: catalogConfig?.teamName,
        codeRepoList: catalogConfig?.codeRepository?.map((item) => ({
            repo: item?.url,
        })),
        memberList: catalogConfig?.members,
        createdBy: data?.created?.by,
        isTouched: false,
    };
};

export const resetOrder = {
    catalogCode: null,
    applicationCI: null,
    applicationCIDetails: null,
    applicationOwner: null,
    applicationOwnerDetails: null,
    applicationName: null,
    applicationUrl: null,
    environment: null,
    sector: null,
    teamName: null,
    codeRepoList: [{ repo: '', isRepoTouched: false }],
    member: null,
    memberDetails: null,
    memberList: [],
    isTouched: false,
};

export const resetValidProvisionForm = {
    isAppNameValid: false,
    isAppUrlValid: false,
    isDuplicateMember: false,
    isMaxMemberLimit: false,
};
