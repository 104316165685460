import React, { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import {
    sspSubmitOrder,
    toggleSspModels,
    isSkuConfigFullfilled,
    isDataDiskConfigFullfilled,
    isStorageDiskConfigFullfilled,
} from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import { getPayload } from 'pages/provisioning/day2-operation/getPayload';
import { validateObject } from 'utils/validateObject';

const ErrorBanner = lazy(() => import('components/ErrorBanner'));

const CartBtn = () => {
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [isSkuConfigValid] = useRecoilState(isSkuConfigFullfilled);
    const [isStorageDiskConfigValid] = useRecoilState(isStorageDiskConfigFullfilled);
    const [isDataDiskConfigValid] = useRecoilState(isDataDiskConfigFullfilled);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const payload = await getPayload({ order, catalogCode, catalogServiceId });
        try {
            await validateObject({ data: payload, catalogCode });
            setModal({ ...modal, isSubmitOrderSuccess: true });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    const isReadyToSubmit = () =>
        !order?.sector ||
        !order.region ||
        !order?.entityDetails ||
        !order?.envDetails ||
        !order?.tags ||
        !order?.hCodeDetails ||
        // !order?.projectName ||
        !order?.notes ||
        !order?.serverName ||
        !order?.modificationType ||
        (order?.modificationType?.toLowerCase() === 'sku' && !order?.configureNewSku) ||
        (order?.modificationType?.toLowerCase() === 'sku' && !isSkuConfigValid) ||
        (order?.modificationType?.toLowerCase() === 'storage disk' && !order?.diskSku) ||
        (order?.modificationType?.toLowerCase() === 'storage disk' && !isStorageDiskConfigValid) ||
        (order?.modificationType?.toLowerCase() === 'add disk' && !order?.dataDisksCount) ||
        (order?.modificationType?.toLowerCase() === 'add disk' && !(Number(order?.dataDisksCount) === 0 || (order?.dataDisksCount && isDataDiskConfigValid)));

    const handleValidation = () => {
        if (!isReadyToSubmit()) {
            handleSubmit();
        } else {
            setOrder({ ...order, isTouched: true });
            handleClickErrorBanner();
        }
    };

    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        sx={ProvisioningStyles?.cartBtn}
                        color="cmpPrimary"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add To Cart'}
                    </Btn>
                )}
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

CartBtn.propTypes = {};

CartBtn.defaultProps = {};

export default React.memo(CartBtn);
