import React from 'react';
import { Grid } from '@mui/material';
import FormCard from 'pages/provisioning/_components/FormCard';
import Form from 'pages/provisioning/virtual-service/components';

const ApplicationForm = () => {
    return (
        <FormCard title="Application ">
            <Grid container spacing={2}>
                <Grid item container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.Sector />
                    </Grid>
                    <Form.Division />
                    <Form.ApplicationRegion />
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.Environment />
                    </Grid>
                    <Form.Program />
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.ApplicationService />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.ApplicationOwner />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.CostCenter />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.CertificateOwnerOne />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.CertificateOwnerTwo />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Form.Department />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.AksCluster />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Form.AksNamespace />
                    </Grid>
                </Grid>
            </Grid>
        </FormCard>
    );
};

export default ApplicationForm;
