/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddAppTier, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const AppTier = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDAppTier, setLzDDAppTier] = useRecoilState(ddAppTier);
    const [landZoneForm, setLandZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const appTierApi = useMutation((payload) => axios.post(endPoints.landingZones.appTier, payload));

    const setAppTier = (appTier, appTierDetails) => {
        if (!lzModal?.lzDetails) {
            setLandZoneForm({
                ...landZoneForm,
                appTier,
                appTierDetails,
            });
        }
    };
    const seldValueExistsInOptions = (appTierValue) => options.find((option) => appTierValue === option?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setAppTier(selected?.label, selected);
        } else {
            setAppTier(null, null);
        }
    };
    React.useEffect(() => {
        if (appTierApi?.isSuccess) {
            setOptions(appTierApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, code: app.code })));
            setLzDDAppTier({ ...lzDDAppTier, isLoaded: true });
        }
    }, [appTierApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const appTier = options.find((value) => lzModal?.lzDetails?.appTier?.name === value?.label);
            if (appTier) setLzDDAppTier({ ...lzDDAppTier, isLoaded: true, data: appTier });
        }
        if (options?.length && options?.length === 1) {
            handleOnSelectChange(options[0]);
        }
    }, [options]);
    React.useEffect(() => {
        if (landZoneForm?.cloudService) {
            appTierApi.mutate({
                cloudServiceName: landZoneForm?.cloudService,
            });
        }
    }, [landZoneForm?.cloudService]);
    return (
        <Box bgcolor="white">
            <Select
                key="appTier"
                value={landZoneForm?.appTier || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={appTierApi.isLoading || lzModal?.type === 'edit'}
                isLoading={appTierApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        {...params}
                        label="App Tier"
                        placeholder="App Tier"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(AppTier);
