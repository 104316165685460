import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import Style from 'pages/project-onboarding/style';

const MemberAccessTable = (props) => {
    const { member, index } = props;

    const resoureDisplay = (title, haveAccess) => {
        return (
            <Box sx={Style?.preview?.memberAccess?.subWrapper} key={title}>
                <Box sx={Style?.preview?.memberAccess?.label}>{title}</Box>
                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                <Box sx={Style?.preview?.memberAccess?.value}>{haveAccess && <DoneIcon sx={Style?.preview?.memberAccess?.doneIcon} />}</Box>
            </Box>
        );
    };

    return (
        <Grid container sx={Style?.preview?.memberAccess?.container} key={`ma-${index}`}>
            <Grid item xs={7} sm={7}>
                <Stack direction="column" spacing={2}>
                    <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                        <Box sx={Style?.preview?.memberAccess?.label}>GPID</Box>
                        <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                        <Box sx={Style?.preview?.memberAccess?.value}>{member?.gpid}</Box>
                    </Box>
                    <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                        <Box sx={Style?.preview?.memberAccess?.label}>Member Name</Box>
                        <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                        <Box sx={Style?.preview?.memberAccess?.value}>{member?.name}</Box>
                    </Box>
                    <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                        <Box sx={Style?.preview?.memberAccess?.label}>Email ID</Box>
                        <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                        <Box sx={Style?.preview?.memberAccess?.value}>{member?.email !== null ? member?.email : '-'}</Box>
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={7} sm={5}>
                <Stack direction="column" spacing={2}>
                    {resoureDisplay('Project Admin', member?.projectAdmin)}
                    {resoureDisplay('SonarQube', member?.sonarQube)}
                    {resoureDisplay('Artifactory', member?.artifactory)}
                    {resoureDisplay('Fortify and Synk', member?.fs)}
                    {resoureDisplay('Test Plan', member?.testPlan)}
                </Stack>
            </Grid>
        </Grid>
    );
};

MemberAccessTable.propTypes = {
    member: PropTypes.object,
    index: PropTypes.number,
};

MemberAccessTable.defaultProps = {
    member: {},
    index: 0,
};

export default MemberAccessTable;
