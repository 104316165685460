/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddType, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Type = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDType, setLzDDType] = useRecoilState(ddType);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const typeApi = useMutation(() => axios.get(`${endPoints.landingZones.type}`));

    const setType = (type = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                type,
            });
        }
    };
    const seldValueExistsInOptions = (item) => options.find((value) => item === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setType(selected?.label);
        } else {
            setType();
        }
    };
    React.useEffect(() => {
        if (typeApi?.isSuccess) {
            setOptions(typeApi?.data?.map((app) => ({ label: app.value, value: app.value, data: app })));
            setLzDDType({ ...lzDDType, isLoaded: true });
        }
    }, [typeApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const type = options.find((value) => lzModal?.lzDetails?.config?.type === value?.label);
            if (type) setLzDDType({ ...lzDDType, isLoaded: true, data: type });
        }
        if (options?.length && options?.length === 1 && !lzModal?.lzDetails) {
            setLandingZoneForm({ ...landZoneForm, type: options[0]?.label });
            setLzDDType({ ...lzDDType, isLoaded: true, data: { label: options[0]?.label, value: options[0]?.value, data: options[0]?.data } });
        }
    }, [options]);

    React.useEffect(() => {
        setOptions([]);
        typeApi.mutate();
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="alb-type"
                value={landZoneForm?.type}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled
                isLoading={typeApi.isLoading}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Type"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Type);
