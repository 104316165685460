import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Box, Grid } from '@mui/material';
import Select, { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const ProcessingUnits = () => {
    const { cartItemId, operation, catalogServiceId } = useParams();
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);

    const processingUnitsApi = useMutation(() => axios.get(`${endPoints.ssp.eventHub.processingUnits}`));

    const SetProcessingUnits = (processingUnits) => {
        setOrder({
            ...order,
            processingUnits,
        });
    };

    const handleOnSelectChange = (selected) => {
        SetProcessingUnits(selected.value);
    };

    React.useEffect(() => {
        if (processingUnitsApi?.isSuccess) {
            if (processingUnitsApi?.data?.data?.unitsList) setOptions(processingUnitsApi?.data?.data?.unitsList.map((val) => ({ label: val, value: val })));
        }
    }, [processingUnitsApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.performance === 'Premium') {
            if (!cartItemId && !operation && !catalogServiceId) SetProcessingUnits('1');
            processingUnitsApi.mutate();
        }
    }, [order?.performance, order?.nsExist]);

    React.useEffect(() => {
        if (!order?.processingUnits && order?.isTouched) setIsTouched(true);
    }, [order?.processingUnits, order?.isTouched]);

    const getHelpText = () => (
        <>
            <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            The resources in a Premium tier are isolated at the CPU and memory level so that each tenant workload runs in isolation.
                            <br />
                            Event Hubs Premium namespace with 1 PU and 1 event hub (100 partitions) can approximately offer core capacity of ~5-10 MB/s ingress
                            and 10-20 MB/s egress for both AMQP or Kafka workloads.
                        </Grid>
                        <Grid item xs={12}>
                            <a
                                href="https://learn.microsoft.com/en-us/azure/event-hubs/event-hubs-scalability#processing-units"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Learn more
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {order?.nsExist === 'no' ? (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <Select
                        key="processingUnits"
                        label="Processing Units *"
                        value={order?.processingUnits || null}
                        options={options}
                        handleOnSelect={(value) => handleOnSelectChange(value)}
                        disabled={order?.nsExist === 'yes' && order?.namespaceName}
                        isRequired={isTouched}
                    />
                    <HelpImg maxmWidth="450px" title={getHelpText()} />
                </Stack>
            ) : (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        label="Processing Units *"
                        value={order?.processingUnits || null}
                        disabled
                    />
                    <HelpImg maxmWidth="450px" title={getHelpText()} />
                </Stack>
            )}
        </>
    );
};
export default React.memo(ProcessingUnits);
