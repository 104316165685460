import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Badge } from '@mui/material';
import Btn from 'components/button';
import { RI003Styles } from 'pages/reference-implementation/provisioning/RI003O/style';

const ConsumerGroup = (props) => {
    const { handleOnSelect, editButton, showBadge } = props;

    return (
        <>
            <Grid item xs={12}>
                <Box sx={RI003Styles?.consumerGrp?.badge}>
                    <Badge variant="dot" overlap="circular" color={showBadge ? 'error' : 'default'}>
                        <Btn
                            onClick={() => handleOnSelect('consumerGroups')}
                            data-testid="consumerGrpBtn"
                            variant="contained"
                            color="cmpPrimary"
                            sx={{ minWidth: '42px', width: 'auto', height: '32px' }}
                        >
                            {editButton ? 'Edit Consumer Group' : 'Add Consumer Group'}
                        </Btn>
                    </Badge>
                </Box>
            </Grid>
        </>
    );
};

ConsumerGroup.propTypes = {
    handleOnSelect: PropTypes.func,
    editButton: PropTypes.bool,
    showBadge: PropTypes.bool,
};

ConsumerGroup.defaultProps = {
    handleOnSelect: () => {},
    editButton: false,
    showBadge: false,
};

export default ConsumerGroup;
