import React from 'react';
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { currencySymbols } from 'utils';
import { cartStyle } from 'pages/cart/style';

function ApplicationCostDetails(props) {
    const { app } = props;
    return (
        <Grid item xs={12} sx={cartStyle.applicationCostDetails.wrapper}>
            <Accordion sx={cartStyle.applicationCostDetails.accordian}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={cartStyle.CostSummary.expandIcon} />} sx={cartStyle.applicationCostDetails.accordianSummary}>
                    <Typography sx={cartStyle.applicationCostDetails.application}>{app?.application}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={5} sx={cartStyle.CostSummary.servicesTypo}>
                            {' '}
                            Catalog Service(s):
                        </Grid>
                        <Grid item xs={5} sx={{ paddingLeft: 2 }}>
                            {app?.items?.length}
                        </Grid>
                        <Grid item xs={5} sx={cartStyle.CostSummary.monthlyTitle}>
                            Monthly Cost:{' '}
                        </Grid>
                        <Grid item xs={7} sx={{ paddingLeft: (theme) => theme.spacing(2) }}>
                            {currencySymbols[app?.estimatedCosts?.totalRecurringCost?.currency]}
                            {app?.estimatedCosts?.totalRecurringCost?.estCost?.toFixed(2)}
                        </Grid>
                        <Grid item xs={5} sx={cartStyle.CostSummary.OneTimeCost}>
                            One Time Cost:
                        </Grid>
                        <Grid item xs={7} sx={{ paddingLeft: (theme) => theme.spacing(2) }}>
                            {currencySymbols[app?.estimatedCosts?.totalOneTimeCost?.currency]}
                            {app?.estimatedCosts?.totalOneTimeCost?.estCost?.toFixed(2)}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

ApplicationCostDetails.propTypes = {
    app: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ApplicationCostDetails.defaultProps = {};
export default ApplicationCostDetails;
