import { endPoints } from 'api/endpoints';
import jsPDF from 'jspdf';

export const getEndpointForCatalogCode = (code) => {
    return endPoints.riServices?.[code?.toUpperCase()];
};

export const addLabelClass = (label) => {
    // eslint-disable-next-line
    for (let i = 0; i < label.length; i++) {
        // add bordered class
        label[i].classList.add('label-space');
        // remove bordered class
    }
};

export const addValueClass = (value) => {
    // eslint-disable-next-line
    for (let i = 0; i < value.length; i++) {
        value[i].classList.add('value-space');
    }
};

export const removeLabelClass = (label) => {
    // eslint-disable-next-line
    for (let i = 0; i < label.length; i++) {
        label[i].classList.remove('label-space');
    }
};

export const removeValueClass = (value) => {
    // eslint-disable-next-line
    for (let i = 0; i < value.length; i++) {
        value[i].classList.remove('value-space');
    }
};

export const generatePDF = (canvas, order) => {
    const image = canvas.toDataURL('image/jpeg', 1.0);
    // eslint-disable-next-line
    const doc = new jsPDF('p', 'px', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio;
    const canvasHeight = canvas.height * ratio;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
    const fileName = `${order?.applicationName?.replaceAll(' ', '')}_${order?.environment?.replaceAll(' ', '')}_preview`;
    doc.save(`${fileName}.pdf`);
};
export default {};
