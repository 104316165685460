import React from 'react';
import { FormControl, FormControlLabel, Box, Stack, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder, validateMaxAutoInflateTPUnit } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const AutoInflate = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setIsValidMaxAutoInflateTPUnit] = React.useState(validateMaxAutoInflateTPUnit);

    const setAutoInflateValue = (isAutoInflate = 'no') => {
        setOrder({
            ...order,
            isAutoInflate,
        });
        if (isAutoInflate === 'no') {
            setIsValidMaxAutoInflateTPUnit(false);
        }
    };

    const handleOnSelectChange = (selected) => {
        setAutoInflateValue(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="autoinflateRadioGrp">
                    Enable Auto Inflate
                </Box>
                <CustomRadioGroup
                    aria-label="autoinflate"
                    name="autoinflateRadioBtnGroup"
                    row
                    value={order?.isAutoInflate || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" disabled={order?.nsExist === 'yes' && order?.namespaceName} />
                    <FormControlLabel
                        value="no"
                        sx={ProvisioningStyles.radioFields.radioBtnNoForm}
                        control={<CustomRadio />}
                        label="No"
                        disabled={order?.nsExist === 'yes' && order?.namespaceName}
                    />
                </CustomRadioGroup>
            </FormControl>
            <HelpImg
                maxmWidth="450px"
                title={
                    <>
                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        Auto-Inflate automatically scales the number of Throughput Units assigned to your Standard Tier Event Hubs Namespace
                                        when your traffic exceeds the capacity of the Throughput Units assigned to it.
                                        <br />
                                        You can specify a limit to which the Namespace will automatically scale.
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

export default AutoInflate;
