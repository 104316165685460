import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import Select from 'pages/reference-implementation/components/select';
import { isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI003O/utils';

const Program = (props) => {
    const { handleOnSelect, programValue, isRequired, order, formData, setFormData } = props;
    const [options, setOptions] = React.useState([]);

    const programApi = useMutation((payload) => axios.post(`${endPoints.riServices.COMMON.program}`, payload));

    const resetOptions = () => {
        setOptions([]);
    };

    useEffect(() => {
        if (programApi?.isSuccess) {
            setOptions(programApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [programApi?.isSuccess]);

    useEffect(() => {
        if (options && options?.length === 1 && !formData?.program) {
            setFormData({ ...formData, program: options?.[0].value });
        }
    }, [options, formData]);

    React.useEffect(() => {
        if (
            !(
                !order?.sector ||
                !order?.environment ||
                (['cgf']?.includes(order?.sector?.toLowerCase()) && !order?.division) ||
                (isShowAppRegionField({ order }) && !order?.region)
            )
        ) {
            programApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
                ...(isShowAppRegionField({ order }) && { applicationRegion: order?.region }),
            });
        } else {
            resetOptions();
        }
    }, [order?.sector, order?.environment, order?.division, order?.region]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="Program"
                label="Program *"
                value={programValue || null}
                options={options}
                disabled={programApi.isLoading}
                handleOnSelect={(value) => handleOnSelect(value?.value)}
                isRequired={isRequired}
                dataTestId="programSelect"
            />
        </Box>
    );
};

Program.propTypes = {
    programValue: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
    order: PropTypes.object,
    formData: PropTypes.any,
    setFormData: PropTypes.any,
};

Program.defaultProps = {
    programValue: null,
    handleOnSelect: () => {},
    isRequired: false,
    order: {},
    formData: {},
    setFormData: () => {},
};

export default Program;
