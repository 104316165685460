const Styles = {
    wrapper: {
        background: (theme) => theme.palette.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E0E0E0',
        height: '68px',
    },
    subWrapper: { display: 'flex', alignItems: 'center', ml: '32px', justifyContent: 'center' },
    ImgSpan: { display: 'flex', alignItems: 'center' },
    img: { width: '33.43px', height: '33.43px' },
    keyvaultimg: { width: '53.43px', height: '33.43px' },
    title: {
        pl: 2,
        color: (theme) => theme?.palette?.black?.dark,
        fontSize: '20px',
        fontWeight: 'medium',
        fontFamily: 'Roboto',
    },
    infoIconStyle: { marginLeft: 1, marginTop: 1 },
    buttonWrapper: {
        mr: '53px',
    },
    backBtn: { marginLeft: '-20px' },
    backIcon: { color: (theme) => theme.palette.blue.main },
};
export default Styles;
