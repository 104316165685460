import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { RIStyles } from 'pages/reference-implementation/Style';

const Heading = ({ title }) => {
    return <Box sx={RIStyles?.form?.heading}>{title}</Box>;
};

Heading.propTypes = {
    title: PropTypes.any.isRequired,
};

export default React.memo(Heading);
