// import React from 'react';
// eslint-disable-next-line import/no-cycle
import axios from 'api/request';
// import PropTypes from 'prop-types';

// const AxiosContext = React.createContext(
//     new Proxy(axios, {
//         apply: () => {
//             throw new Error('You must wrap your component in an AxiosProvider');
//         },
//         get: () => {
//             throw new Error('You must wrap your component in an AxiosProvider');
//         },
//     })
// );

// export const AxiosProvider = ({ children }) => {
//     const axiosValue = React.useMemo(() => axios, []);
//     return <AxiosContext.Provider value={axiosValue}>{children}</AxiosContext.Provider>;
// };

// AxiosProvider.propTypes = {
//     children: PropTypes.element.isRequired,
// };

// AxiosProvider.propTypes = {};

const useAxios = () => axios;

export default useAxios;
