/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Stack, TextField, Button } from '@mui/material';
import axios from 'api/request';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import Empty from 'components/empty';
import { findNested } from 'pages/helpdocs/utils';
import HelpDocumentModal from './components/HelpDocumentModal';
import HelpCard from './components/HelpCard';
import styles from './styles';
import { toggleModal, selectedDocument } from './store';

const CustomButton = styled(Button)(() => ({
    color: '#FFFFFF',
    textTransform: 'none',
    background: '#0047BA',
    margin: '2px',
}));

const helpDoc = () => {
    const [data, setData] = React.useState([]);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setSelectedDocument] = useRecoilState(selectedDocument);
    const [searchedstring, setSearchedstring] = React.useState(' ');
    const [allData, setAllData] = React.useState([]);

    const getHelpDocumentsApi = useMutation(() => axios.get(endPoints.helpDocuments.getDocuments));

    const resetStore = () => {
        setToggleModal(false);
        setSelectedDocument(undefined);
    };

    React.useEffect(() => {
        resetStore();
        getHelpDocumentsApi.mutate();
    }, []);

    React.useEffect(() => {
        if (getHelpDocumentsApi?.isSuccess) {
            setData(getHelpDocumentsApi?.data?.data);
            setAllData(getHelpDocumentsApi?.data?.data);
        }
    }, [getHelpDocumentsApi?.isSuccess]);
    React.useEffect(() => {
        if (searchedstring === '') {
            setData(allData);
        }
    }, [searchedstring]);

    const findSearched = (val) => {
        if (searchedstring === '') {
            setData(allData);
        } else {
            // eslint-disable-next-line
            var s = findNested(allData, val);
            setData([...new Set(s)]);
        }
    };
    return (
        <>
            <Box sx={styles.wrapper} data-testid="help-doc">
                {!getHelpDocumentsApi.isLoading && !getHelpDocumentsApi.isError && (
                    <Box sx={styles.container}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mt: 2 }} width="65%">
                            <TextField
                                id="searchtopic"
                                placeholder="Search Topic"
                                variant="outlined"
                                sx={styles.searchfield}
                                onChange={(e) => {
                                    setSearchedstring(e.target.value);
                                    findSearched(searchedstring);
                                }}
                                data-testid="search-input"
                            />
                            <CustomButton sx={styles.searchbtn} size="large" onClick={() => findSearched(searchedstring)} data-testid="search-btn">
                                Search
                            </CustomButton>
                        </Box>{' '}
                    </Box>
                )}

                {getHelpDocumentsApi.isLoading && (
                    <Stack height="100%" direction="column" alignItems="center" justifyContent="center" spacing={1}>
                        <CircularProgress />
                    </Stack>
                )}
                {!getHelpDocumentsApi.isLoading && getHelpDocumentsApi.isError && (
                    <Stack height="100%" direction="column" alignItems="center" justifyContent="center" spacing={1} sx={styles.wrapper}>
                        <Empty notFound msg={getHelpDocumentsApi?.error.message || 'No records'} color="#000000" />
                    </Stack>
                )}
                {!getHelpDocumentsApi.isLoading && !getHelpDocumentsApi.isError && (!data || data.length <= 0) && (
                    <Stack height="100%" direction="column" alignItems="center" justifyContent="center" spacing={1} sx={styles.emptywrapper}>
                        <Empty notFound msg="No records found." color="#000000" />
                    </Stack>
                )}

                {!getHelpDocumentsApi.isLoading && !getHelpDocumentsApi.isError && data && data.length > 0 && (
                    <>
                        <Box sx={styles.helpcardWrapper}>
                            <Box sx={styles.helpcardContainer}>
                                <Box sx={styles.helpCardBox} width="90%">
                                    {data.map((row, index) => (
                                        <HelpCard data={row} key={index.toString()} />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            <HelpDocumentModal minWidth="40%" />
        </>
    );
};
export default helpDoc;
