export const RI001 = {
    projectId: 'api/static/lookups/v2/1.0/clarity-project/{projectId}',
    sectors: 'api/static/lookups/v2/1.0/sectors',
    regions: 'api/static/lookups/v2/1.0/regions',
    dataClassification: 'api/static/lookups/v2/1.0/data-classifications/{RID}',
    environments: 'api/static/lookups/v2/1.0/environments',
    applicationService: 'api/cmdb/lookups/1.0/business-application/services',
    businessApp: 'api/cmdb/lookups/1.0/business-application',
    costCenter: 'api/cmdb/lookups/1.0/projects',
    adGroups: 'api/eip/lookups/1.0/all-adgroups',
    download: 'api/order/ansible/v2/1.0/ess-payload',
    businessAppOwner: 'api/order/snow/1.0/{businessApplicationName}/it-owner',
    adGroupManager: 'api/employees/2.0/managers',
    appOwner: 'api/employees/3.0/employee/list',
    adGroupSuccess: 'ri-adgroup-validation-success.json',
    // appShortName: 'ri-appshortname.json',
    appShortName: 'api/ri/resource/name/1.0/app-short-name',
    // appShortNameValidation: 'ri-appshortname-validation.json',
    appShortNameValidation: 'api/ri/resource/name/1.0/app-short-name/availability',
    adGroupFailed: 'ri-adgroup-validation-failed.json',
    adGroupValidation: 'api/ri/order/myidm/1.0/validate-ad-group-access',
    Jwt: {
        // old provisioning API's
        // previewOrder: 'api/reference-implementation/ri001/1.0/internal/order/preview',
        // submitOrder: 'api/reference-implementation/ri001/1.0/internal/order/submit',
        // saveChangesInOrder: 'api/reference-implementation/ri001/1.0/order',
        // reSubmit: 'api/reference-implementation/ri001/1.0/internal/order/resubmit',
        // submitApproval: 'api/reference-implementation/ri001/1.0/internal/order/submit-approval',
        // costCalculation: 'ri-cost-calculation.json', // 'api/reference-implementation/ri001/1.0/internal/cost-calculation',
        // internalPreviewOrder: 'api/reference-implementation/ri001/1.0/internal/preview/order',
        // downloadTF: 'api/reference-implementation/ri001/1.0/internal/order',
        // new provisioning API's
        previewOrder: 'api/reference-implementation/ri001/1.0/internal/order/ri001/preview', //    api/reference-implementation/ri001/1.0/internal/order/preview
        submitOrder: 'api/reference-implementation/ri001/1.0/internal/order/ri001/submit', // api/reference-implementation/ri001/1.0/internal/order/submit
        saveChangesInOrder: 'api/reference-implementation/ri001/1.0/order/ri001',
        reSubmit: 'api/reference-implementation/ri001/1.0/internal/order/ri001/resubmit',
        submitApproval: 'api/reference-implementation/ri001/1.0/internal/order/submit-approval',
        costCalculation: 'ri-cost-calculation.json', // 'api/reference-implementation/ri001/1.0/internal/cost-calculation',
        internalPreviewOrder: 'api/reference-implementation/ri001/1.0/internal/preview/order',
        downloadTF: 'api/reference-implementation/ri001/1.0/internal/order',
    },
};
export default RI001;
