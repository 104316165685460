import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { camelToTitle, toDateTime } from 'utils';
import mappingLabel from 'utils/mappingLabel';

const LabelValue = (props) => {
    const { label, value, isCamelToTitle, width, sx, children, istextTransform } = props;
    const dateTimeRegex = /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;

    const [assigneeHoverStatus, setAssigneeHover] = useState(false);

    const assigneeRef = useRef();
    const compareSize = () => {
        const compareAssignee = assigneeRef.current.scrollWidth > assigneeRef.current.clientWidth;
        setAssigneeHover(compareAssignee);
    };
    useEffect(() => {
        if (label) {
            compareSize();
            window.addEventListener('resize', compareSize);
        }
    }, [label]);

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener('resize', compareSize);
        },
        []
    );
    return (
        <Box data-testid="key_label" sx={sx} display="flex" className="key" flexWrap="wrap">
            <Tooltip interactive="true" disableHoverListener={!assigneeHoverStatus} title={label}>
                <Box
                    className="label"
                    ref={assigneeRef}
                    width={width}
                    flex={1.1}
                    style={{
                        textTransform: `${istextTransform ? 'capitalize' : ''}`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {isCamelToTitle &&
                    ![
                        'DR Priority',
                        'VM Type',
                        'LogicalHost Name',
                        'SQL Database Name',
                        'vCore Count',
                        'AD OU',
                        'AD Group',
                        'SAM Account ID',
                        'OS disk type',
                        'OS Timezone',
                        'DB Size',
                        'ASR Enabled',
                        'business CI',
                        'ISADoc',
                        'FQDN',
                        'IP',
                        'IDM Request Number',
                        'ISA Document',
                        'ADLS Gen2 Enabled',
                        'TCP Reset',
                        'Floating IP',
                        'SKU',
                        'FQDNS',
                        'vNet',
                        'FrontEnd IPs',
                        'TLS Version',
                        'OS',
                        'Application Support DL',
                        'FQDN Name',
                        'IP Address',
                        'SSL Certificate',
                        'Databrick/AML Workspace',
                        'DNS RITM Request',
                        'SSL Certificate RITM Request',
                        'AKS Servicename',
                        'Kafka Servicename',
                        'SQL Servicename',
                        'ASR',
                        'HA Deployment',
                        'SAM Account',
                        'Couchbase DB Version',
                        'CLE Integration',
                        'ECE Integration',
                        'IT LDAP Group',
                        'Requestor GPID',
                        'ADF Name',
                        'OrgGrpCodeProjectCode',
                        'ProjectNumber',
                        'ProjectName',
                        'BusinessSector',
                        'BillingInformation',
                        'SupportContact',
                        'BusinessContact',
                        'serviceName',
                        'Reader AD Group',
                        'Contributor AD Group',
                        'AD Group Owner',
                        'Storage Account Name(s)',
                        'Power BI Gateway Request URL',
                        ...Object.values(mappingLabel),
                    ].includes(label)
                        ? camelToTitle(label)
                        : label}
                    :
                </Box>
            </Tooltip>
            <Box className="value" style={{ width: 400 }} flex={2.9}>
                {!children && (dateTimeRegex.test(value) ? toDateTime(value) : value || '-')}
                {children && <>{children}</>}
            </Box>
        </Box>
    );
};

LabelValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    isCamelToTitle: PropTypes.bool,
    width: PropTypes.number,
    children: PropTypes.element,
    sx: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
    istextTransform: PropTypes.bool,
};

LabelValue.defaultProps = {
    value: '-',
    isCamelToTitle: true,
    width: 200,
    children: null,
    sx: { padding: 1 },
    istextTransform: true,
};

export default React.memo(LabelValue);
