/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { landingZoneData, isLdapVaild } from 'pages/app-profiles/store';

const Ldap = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [isValid, setIsLdapVaild] = useRecoilState(isLdapVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setLdap = (ldap) => {
        setLandingZoneForm({
            ...landingZoneForm,
            ldap,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length < 3 || value?.length > 215) {
                setIsLdapVaild(false);
                setLdap(value);
            } else if (/^([a-z0-9-_])$/.test(value)) {
                setIsLdapVaild(false);
                setLdap(value);
            } else {
                setIsLdapVaild(true);
                setLdap(value);
            }
        } else {
            setIsLdapVaild(false);
            setLdap('');
        }
    };

    React.useEffect(() => {
        if (landingZoneForm?.ldap) setLdap(landingZoneForm?.ldap);
    }, [landingZoneForm?.ldap]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landingZoneForm?.ldap?.length > 0);
    }, [landingZoneForm?.ldap]);

    React.useEffect(() => {
        if (!landingZoneForm?.ldap && landingZoneForm?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.ldap, landingZoneForm?.isTouched]);
    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="LDAP Group *"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landingZoneForm?.ldap || ''}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid || isTouched}
                    helperText={`
                    ${!isValid ? 'ldap group must be atleast 3 chars in length and can contain only hyphen(-) & underscore(_) as special char ' : ''}
                `}
                />
            </Stack>
        </>
    );
};

export default React.memo(Ldap);
