/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const BillingEntities = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setBillingEntity = (billingEntity = null, billingEntityDetails = null) => {
        setOrder({
            ...order,
            billingEntity,
            billingEntityDetails,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };
    const billingEntityApi = useMutation(() => axios.get(`${endPoints.ssp.vm.billingEntities}`));
    const seldValueExistsInOptions = (billingEntityValue) => options.find((option) => billingEntityValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setBillingEntity(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (billingEntityApi?.isSuccess) {
            setOptions(billingEntityApi?.data?.data?.companies.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [billingEntityApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.entityDetails?.billingEntity) {
            setOrder({ ...order, billingEntity: options?.[0]?.name, billingEntityDetails: options?.[0]?.data });
        }
    }, [options, order?.entityDetails?.billingEntity]);

    React.useEffect(() => {
        resetOptions();
        if (order?.osType) {
            billingEntityApi.mutate();
        }
        // eslint-disable-next-line
    }, [order?.osType]);
    React.useEffect(() => {
        if (!order?.billingEntity && order?.isTouched) setIsTouched(true);
    }, [order?.billingEntity, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="billingEntity"
                label="Billing Entities *"
                value={order?.billingEntity}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={billingEntityApi.isLoading}
                isLoading={billingEntityApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(BillingEntities);
