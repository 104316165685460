/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { aksCatalogServiceCode, kafkaCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';

const Department = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    let departmentEndpoint;
    switch (landingZoneForm?.service) {
        case 'PaaS/AKSNS':
            departmentEndpoint = `${endPoints.landingZones.eipDepartments}/AKS`;
            break;
        case 'PaaS/Kafka Topics':
            departmentEndpoint = `${endPoints.landingZones.eipDepartments}/Kafka`;
            break;
        default:
            departmentEndpoint = endPoints.landingZones.departments;
    }
    const departmentApi = useMutation((payload) => axios.post(departmentEndpoint, payload));
    const departmentGetApi = useMutation(() => axios.get(departmentEndpoint));

    const setDepartment = (department) => {
        setLandingZoneForm({
            ...landingZoneForm,
            department,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        setDepartment(null);
    };
    const seldValueExistsInOptions = (departmentValue) => options.find((value) => departmentValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setLandingZoneForm({
                ...landingZoneForm,
                department: selected?.label,
                ...([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service) && {
                    environment: null,
                    region: null,
                    cluster: null,
                }),
            });
        } else {
            setDepartment(null);
        }
    };
    React.useEffect(() => {
        if (departmentApi?.isSuccess) {
            setOptions(departmentApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        }
    }, [departmentApi?.isSuccess]);
    React.useEffect(() => {
        if (departmentGetApi?.isSuccess) {
            setOptions(departmentGetApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        }
    }, [departmentGetApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.department) {
            const department = options.find((value) => landingZoneForm?.department === value?.label);
            setDepartment(department?.label);
        }
    }, [options]);

    React.useEffect(() => {
        if (
            ![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service) &&
            landingZoneForm?.environment &&
            landingZoneForm?.regionDetails?.value
        ) {
            resetOptions();
            departmentApi.mutate({
                environment: landingZoneForm?.environment,
                region: landingZoneForm?.regionDetails?.value,
            });
        }
    }, [landingZoneForm?.environment, landingZoneForm?.regionDetails?.value]);
    React.useEffect(() => {
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service)) {
            departmentGetApi?.mutate();
        }
    }, []);
    React.useEffect(() => {
        if (!landingZoneForm?.department && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.department, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="department"
                value={landingZoneForm?.department || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={departmentApi.isLoading}
                isLoading={departmentApi.isLoading}
                disableClearable={false}
                label="Department *"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Department);
