/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';

const Project = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setProject = (projectName, projectNameDetail) => {
        setOrder({ ...order, projectName, projectNameDetail });
    };
    const projectNameApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.projects}`));
    const seldValueExistsInOptions = (projectNameValue) => options.find((option) => projectNameValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setProject(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (projectNameApi?.isSuccess) {
            if (projectNameApi?.data?.data) setOptions(projectNameApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [projectNameApi?.isSuccess]);

    React.useEffect(() => {
        projectNameApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.projectName && order?.isTouched) setIsTouched(true);
    }, [order?.projectName, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="projectName"
                label="Project *"
                value={order?.projectName || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={projectNameApi.isLoading}
                isLoading={projectNameApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Project);
