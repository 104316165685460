import { selector } from 'recoil';
import { OrderPayload } from './index';

//  SecurityToolCodeRepo
export const addSecurityToolCodeRepo = selector({
    key: 'projectOnboarding_addSecurityToolCodeRepo',
    get: ({ get }) => get(OrderPayload).securityToolCodeRepoList,
    set: ({ set, get }, count) => {
        const currentCodeRepoList = get(OrderPayload).securityToolCodeRepoList;
        let securityToolCodeRepoList = [{ repo: null }];
        securityToolCodeRepoList = [
            ...currentCodeRepoList,
            ...Array(count.count).fill({
                repo: null,
            }),
        ];

        set(OrderPayload, {
            ...get(OrderPayload),
            securityToolCodeRepoList,
        });
    },
});

export const removeSecurityToolCodeRepo = selector({
    key: 'projectOnboarding_removeSecurityToolCodeRepo',
    get: ({ get }) => get(OrderPayload).securityToolCodeRepoList,
    set: ({ set, get }, codeRepoToRemove) => {
        const currentCodeRepoList = get(OrderPayload).securityToolCodeRepoList;
        const codeRepoToRemoveIndex = currentCodeRepoList.findIndex((val) => val === codeRepoToRemove);
        const securityToolCodeRepoList = [...currentCodeRepoList.slice(0, codeRepoToRemoveIndex), ...currentCodeRepoList.slice(codeRepoToRemoveIndex + 1)];
        set(OrderPayload, {
            ...get(OrderPayload),
            securityToolCodeRepoList,
        });
    },
});

export const updateSecurityToolCodeRepo = selector({
    key: 'projectOnboarding_updateSecurityToolCodeRepo',
    get: ({ get }) => get(OrderPayload).securityToolCodeRepoList,
    set: ({ set, get }, params) => {
        const currentCodeRepoList = get(OrderPayload).securityToolCodeRepoList;
        const updatedCodeRepo = { ...currentCodeRepoList[params.index], repo: params.selected.value, isRepoTouched: false };
        const securityToolCodeRepoList = [...currentCodeRepoList.slice(0, params?.index), updatedCodeRepo, ...currentCodeRepoList.slice(params?.index + 1)];
        set(OrderPayload, { ...get(OrderPayload), securityToolCodeRepoList });
    },
});

// SonarQubeRepo

export const addSonarQubeRepo = selector({
    key: 'projectOnboarding_addSonarQubeRepo',
    get: ({ get }) => get(OrderPayload).sonarQubeCodeRepoList,
    set: ({ set, get }, count) => {
        const currentRepoList = get(OrderPayload).sonarQubeCodeRepoList;
        let sonarQubeCodeRepoList = [{ repo: null }];
        sonarQubeCodeRepoList = [
            ...currentRepoList,
            ...Array(count.count).fill({
                repo: null,
            }),
        ];

        set(OrderPayload, {
            ...get(OrderPayload),
            sonarQubeCodeRepoList,
        });
    },
});

export const removeSonarQubeRepo = selector({
    key: 'projectOnboarding_removeSonarQubeRepo',
    get: ({ get }) => get(OrderPayload).sonarQubeCodeRepoList,
    set: ({ set, get }, RepoToRemove) => {
        const currentRepoList = get(OrderPayload).sonarQubeCodeRepoList;
        const RepoToRemoveIndex = currentRepoList.findIndex((val) => val === RepoToRemove);
        const sonarQubeCodeRepoList = [...currentRepoList.slice(0, RepoToRemoveIndex), ...currentRepoList.slice(RepoToRemoveIndex + 1)];
        set(OrderPayload, {
            ...get(OrderPayload),
            sonarQubeCodeRepoList,
        });
    },
});

export const updateSonarQubeRepo = selector({
    key: 'projectOnboarding_updateSonarQubeRepo',
    get: ({ get }) => get(OrderPayload).sonarQubeCodeRepoList,
    set: ({ set, get }, params) => {
        const currentRepoList = get(OrderPayload).sonarQubeCodeRepoList;
        const updatedRepo = { ...currentRepoList[params.index], repo: params.selected.value, isRepoTouched: false };
        const sonarQubeCodeRepoList = [...currentRepoList.slice(0, params?.index), updatedRepo, ...currentRepoList.slice(params?.index + 1)];
        set(OrderPayload, { ...get(OrderPayload), sonarQubeCodeRepoList });
    },
});
