import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, Stack, CircularProgress } from '@mui/material';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import Empty from 'components/empty';
import { useParams } from 'react-router-dom';
import {
    OrderPayload,
    isAppUrlValid,
    isDestUrlValid,
    isRecipientUrlValid,
    ssoOktaDialogPopup,
    isRolesValid,
    isServiceProviderSigningCertValid,
} from 'pages/sso-okta/store';
import { formOrderObjFromOrderDetails, resetOrder } from 'pages/sso-okta/utils';
import SsoOktaStyles from 'pages/sso-okta/style';
import Roles from 'pages/sso-okta/components/Dialog-box/Roles';
import axios from 'api/request';

import SsoForm from 'pages/sso-okta/SsoForm';

const SsoOktaHeader = React.lazy(() => import('pages/sso-okta/ssoOktaHeader'));
const RequestTypeForm = React.lazy(() => import('pages/sso-okta/RequestTypeForm'));

const ssoOkta = () => {
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setAppUrlValid] = useRecoilState(isAppUrlValid);
    const [, setDestUrlValid] = useRecoilState(isDestUrlValid);
    const [, setRecipientUrlValid] = useRecoilState(isRecipientUrlValid);
    const [, setServiceProviderSigningCertValid] = useRecoilState(isServiceProviderSigningCertValid);
    const [, setRolesValid] = useRecoilState(isRolesValid);
    const [, setDialogPopup] = useRecoilState(ssoOktaDialogPopup);
    const [isLoading, setLoading] = React.useState(false);

    // const axios = useAxios();
    const handleReset = () => {
        setOrder(resetOrder);
        setAppUrlValid(true);
        setDestUrlValid(true);
        setRecipientUrlValid(true);
        setServiceProviderSigningCertValid(true);
        setDialogPopup(false);
    };

    async function checkCatalog() {
        setLoading(true);
        try {
            // const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}`);
            if (cartItemId && operation && catalogServiceId) {
                const { data: orderDetails } = await axios.get(`${endPoints.riServices.Jwt.orderDetails}/${cartItemId}`);
                const orderObj = formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
                setAppUrlValid(true);
                setDestUrlValid(true);
                setRecipientUrlValid(true);
                setServiceProviderSigningCertValid(true);
                setRolesValid(true);
                setOrder((previous) => ({
                    ...previous,
                    ...orderObj,
                }));
            } else {
                setOrder((previous) => ({
                    ...previous,
                    isTouched: false,
                }));
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (catalogCode) {
            handleReset();
            checkCatalog();
        }
    }, [catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => handleReset();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Helmet>
                <title>SSO Integration</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={SsoOktaStyles.ssoOkta_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={SsoOktaStyles.circularProgressColor} />
                    </Box>
                }
            >
                {isLoading && (
                    <Stack sx={SsoOktaStyles?.emptyStack}>
                        <Empty isLoading color="#000000" msg="Loading..." />
                    </Stack>
                )}
                {!isLoading && (
                    <>
                        <SsoOktaHeader reset={() => handleReset()} />
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Grid display="flex" flexDirection="column" padding={4} gap={3}>
                                    {!order?.requestType && <RequestTypeForm />}
                                    {order?.requestType && <SsoForm />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Roles />
            </Suspense>
        </>
    );
};

export default ssoOkta;
