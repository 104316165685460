/* eslint-disable import/named */
import React from 'react';
import Select from 'components/select';
import PropTypes from 'prop-types';
import { endPoints } from 'api/endpoints';
import { useAxios } from 'hooks';
import { useMutation } from 'react-query';
import { allCatalogsList } from 'pages/provisioning/store/catalog';
import { useRecoilValue } from 'recoil';
import Popper from '@mui/material/Popper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Badge, Button, Paper, Stack, Box } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { statusArray, filterDefaultObj } from './utils';
import styles, { ColorButton } from './styles';

const ServiceFilter = (props) => {
    const { handleFilter } = props;
    const axios = useAxios();
    const [catlogServiceOptions, setCatlogServiceOptions] = React.useState([]);
    const allCatalogsDataList = useRecoilValue(allCatalogsList);
    const [cloudProviders, setCloudProvider] = React.useState([]);
    const [selectedProvider, setSelectedProvider] = React.useState('');
    const [catlogModArray, setCatalogModArray] = React.useState([]);
    const [filters, setFilter] = React.useState(filterDefaultObj);
    const [tagOptions, setTagOptions] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const getCloudProviders = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));

    React.useEffect(() => {
        if (allCatalogsDataList?.length) {
            setCatalogModArray(allCatalogsDataList);
            const tags = allCatalogsDataList?.map((item) => item?.tags?.Catalog?.split(',') || []);
            if (tags?.length > 0) {
                const result = tags?.reduce((a, b) => a.concat(b)) || [];
                setTagOptions([...new Set(result)]);
            }
        }
    }, [allCatalogsDataList]);
    React.useEffect(() => {
        if (getCloudProviders?.isSuccess) {
            setCloudProvider(getCloudProviders?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [getCloudProviders?.isSuccess]);
    React.useEffect(() => {
        getCloudProviders.mutate();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const getSelectedValue = (val) => {
        let res = [];
        if (val?.value) {
            res = catlogServiceOptions.filter((v) => v?.value === val?.value);
        } else {
            res = catlogServiceOptions.filter((v) => v?.value === val);
        }
        return res?.length ? res[0] : '';
    };
    const handleOnSelect = (name, selected) => {
        if (name === 'cloudProvider') {
            setFilter({ cloudProvider: selected, tag: '', catalogServiceCode: null, status: null });
        } else {
            setFilter({ ...filters, [name]: selected });
        }
    };

    React.useEffect(() => {
        setCatlogServiceOptions(
            catlogModArray
                ?.filter((val) => val.cloudProvider === selectedProvider)
                .map((app) => ({ value: app.catalogDefinitionId, label: app.displayName, data: app }))
        );
        const tags = catlogModArray?.filter((val) => val.cloudProvider === selectedProvider)?.map((item) => item?.tags?.Catalog?.split(',') || []);
        if (tags?.length > 0) {
            const result = tags?.reduce((a, b) => a.concat(b)) || [];
            setTagOptions([...new Set(result)]);
        }
    }, [selectedProvider]);
    const handleSubmitFilter = () => {
        const appliedFilters = {};
        if (filters) {
            Object.keys(filters)?.forEach((filter) => {
                if (filters[filter]) {
                    if (filter === 'status') {
                        appliedFilters[filter] = filters[filter]?.value === 'Active';
                    } else {
                        appliedFilters[filter] = filters[filter]?.value || filters[filter];
                    }
                }
            });
        }
        handleFilter(appliedFilters);
        handleClick();
    };
    return (
        <div>
            <Button
                sx={styles.filterButton}
                startIcon={
                    <Badge
                        variant="dot"
                        overlap="circular"
                        color={filters && Object.keys(filters)?.filter((key) => filters[key] !== null && filters[key] !== '')?.length ? 'error' : 'default'}
                    >
                        <FilterAltIcon color="#0047BA" size={36} />
                    </Badge>
                }
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={handleClick}
            >
                Filter
            </Button>

            <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                <Paper elevation={5} sx={styles.orderFilter.wrapper}>
                    <ArrowDropUpIcon sx={styles.orderFilter.arrow} />
                    <Box sx={styles.orderFilter.popper.container}>
                        <CloseIcon
                            fontSize="small"
                            sx={styles.orderFilter.closeIcon}
                            onClick={() => {
                                setFilter(filterDefaultObj);
                                handleFilter(filterDefaultObj);
                                handleClick();
                            }}
                        />
                    </Box>
                    <Stack spacing={2} mb={2} pt={1}>
                        <Select
                            key="cpName"
                            options={cloudProviders}
                            value={filters?.cloudProvider || ''}
                            handleOnSelect={(value) => {
                                setSelectedProvider(value?.value);
                                handleOnSelect('cloudProvider', value);
                            }}
                            label="Select Cloud Provider"
                        />

                        <Select
                            key="catlogservice"
                            data-testid="catalogServiceField"
                            value={getSelectedValue(filters?.catalogServiceCode) || ''}
                            options={catlogServiceOptions}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            label="Select Catlog Service"
                            handleOnSelect={(value) => handleOnSelect('catalogServiceCode', value)}
                        />
                        <Select
                            key="tags"
                            value={filters?.tag || ''}
                            options={tagOptions}
                            label="Select Tag"
                            handleOnSelect={(value) => handleOnSelect('tag', value)}
                        />
                        <Select
                            key="status"
                            value={filters?.status || ''}
                            options={statusArray}
                            label="Select Status"
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            handleOnSelect={(value) => handleOnSelect('status', value)}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <ColorButton disableElevation onClick={handleSubmitFilter} variant="contained">
                            Apply
                        </ColorButton>
                    </Stack>
                </Paper>
            </Popper>
        </div>
    );
};

ServiceFilter.propTypes = {
    handleFilter: PropTypes.func.isRequired,
};

export default ServiceFilter;
