/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import ProvisioningStyles from 'pages/provisioning/storage/style';

const ConnectionType = ({ index }) => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);

    const [options, setOptions] = React.useState([]);
    const connectionTypeApi = useMutation(() => axios.get(endPoints.landingZones.eipStack.connection));
    const setConnectionType = (connectionType, connectionTypeDetails) => {
        const form = { ...landZoneForm };
        try {
            const result = form.connections.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = { ...obj, connectionType, connectionTypeDetails };
                }
                return obj;
            });
            form.connections = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };
    const resetOptions = () => {
        setOptions([]);
    };

    async function setPreconfig() {
        if (landZoneForm?.connections?.[index].connectionTypeDetails) {
            const checkExist = await options.find((option) => landZoneForm?.connections?.[index].connectionTypeDetails?.id === option?.data?.id);
            if (checkExist) {
                await setConnectionType(checkExist?.value, checkExist?.data);
            }
        }
    }
    const handleOnSelectChange = (selected) => {
        setConnectionType(selected?.value, selected?.data);
    };
    React.useEffect(() => {
        if (connectionTypeApi?.isSuccess) {
            setOptions(connectionTypeApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [connectionTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (
            options &&
            lzModal?.type === 'edit' &&
            !landZoneForm?.connections?.[index].connectionType &&
            landZoneForm?.connections?.[index].connectionTypeDetails
        ) {
            setPreconfig();
        }
    }, [options, landZoneForm?.connections?.[index].connectionType]);

    React.useEffect(() => {
        resetOptions();
        connectionTypeApi.mutate();
    }, [lzModal?.lzDetails]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <Select
                key="connectionTypeDetails"
                label={landZoneForm?.connections?.[index]?.optional ? 'Connection Type' : 'Connection Type*'}
                value={landZoneForm?.connections?.[index]?.connectionType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={connectionTypeApi.isLoading}
                isLoading={connectionTypeApi.isLoading}
            />
        </Stack>
    );
};

ConnectionType.propTypes = {
    index: PropTypes.number,
};

ConnectionType.defaultProps = {
    index: 0,
};

export default React.memo(ConnectionType);
