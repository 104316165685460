import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isBudgetCodeValid } from 'pages/reference-implementation/provisioning/RI002/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const BudgetCode = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setBudgetCodeValid] = useRecoilState(isBudgetCodeValid);
    const [helperText, setHelperText] = React.useState(false);

    const setBudgetCode = (budgetCode = null) => {
        setOrder({
            ...order,
            budgetCode,
        });
    };

    React.useEffect(() => {
        if (
            order?.budgetCode &&
            order?.budgetCode !== null &&
            order?.budgetCode !== '' &&
            order?.budgetCode !== undefined &&
            !/^[-a-zA-Z0-9]{1,64}$/.test(order?.budgetCode)
        ) {
            setHelperText(true);
            setBudgetCodeValid(false);
        } else {
            setHelperText(false);
            setBudgetCodeValid(true);
        }
    }, [order?.budgetCode]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.budgetCode) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.budgetCode && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.budgetCode, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Budget Code (H-code, Cross Charge)" isRequired />}
                error={helperText}
                helperText={helperText && <span>Budget Code must be 1 to 64 characters long and can include letters, numbers, and hyphens only.</span>}
                value={order?.budgetCode || ''}
                onChange={(e) => setBudgetCode(e.target.value)}
                className={validateInput()}
            />
        </Box>
    );
};

export default BudgetCode;
