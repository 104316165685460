import React from 'react';
import { Box } from '@mui/material';
import orderStyle from 'pages/orders/style';
import MultiSelect from 'components/multiSelect';
import { allowedMethods as methods, orderPayload, selectedVSService } from 'pages/provisioning/virtual-service/store';
import { useRecoilState } from 'recoil';

const AllowedMethods = () => {
    const [allowedMethods] = useRecoilState(methods);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [selectedVS] = useRecoilState(selectedVSService);

    const handleOnSelect = (selected) => {
        setOrder((prev) => ({
            ...prev,
            aksVirtualService: prev?.aksVirtualService?.map((vs, index) => {
                if (index === selectedVS) {
                    return {
                        ...vs,
                        aksCors: {
                            ...vs?.aksCors,
                            allowedMethods: selected,
                        },
                    };
                }
                return vs;
            }),
        }));
    };

    const sendSelectedValues = (selectedSerives) => {
        const res = selectedSerives && selectedSerives?.map((service) => (service?.value ? service?.value : service));
        handleOnSelect(res);
    };

    const getSelectedValue = (selectedSerives) => {
        const res = selectedSerives && selectedSerives?.map((service) => allowedMethods.filter((opt) => opt.value === service));
        return res?.length ? res.reduce((acc, val) => acc.concat(val), []) : [];
    };

    return (
        <MultiSelect
            key="methods"
            data-testid="allowed-method-select"
            value={getSelectedValue(order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedMethods)}
            sx={orderStyle?.orderFilter?.seletedFieldlabel}
            options={allowedMethods}
            label="Allowed Methods"
            handleOnSelect={(value) => sendSelectedValues(value)}
            filterOptions={(option, state) =>
                option.filter((element) => {
                    return element?.value?.toLowerCase()?.includes(state?.inputValue?.toLowerCase());
                })
            }
            renderTags={(val) => <Box sx={{ marginLeft: '10px' }}>{`${val?.length} Selected`}</Box>}
        />
    );
};

export default React.memo(AllowedMethods);
