import { getLoggedUserClaims } from 'utils';

export const getPayload = ({ order = [], catalogCode, catalogServiceId }) => {
    const userClaims = getLoggedUserClaims();
    const getEndpoint = (endpoint) => {
        return {
            ...(endpoint?.sourceResource && {
                sourceResource: {
                    resourceType: endpoint?.sourceResource?.resourceType,
                    resourceTypeDisplayName: endpoint?.sourceResource?.resourceTypeDisplayName,
                    resourceName: endpoint?.sourceResource?.resourceName,
                    subscription: endpoint?.sourceResource?.subscriptionId,
                    subscriptionName: endpoint?.sourceResource?.subscription,
                    location: endpoint?.sourceResource?.location,
                    resourceGroup: endpoint?.sourceResource?.resourceGroup,
                    resourceId: endpoint?.sourceResource?.resourceId,
                    environment: endpoint?.sourceResource?.environment,
                },
            }),
            ...(endpoint?.targetResource && {
                targetResource: {
                    resourceType: endpoint?.targetResource.resourceType,
                    resourceTypeDisplayName: endpoint?.targetResource?.resourceTypeDisplayName,
                    resourceName: endpoint?.targetResource.resourceName,
                    subscription: endpoint?.targetResource.subscriptionId,
                    subscriptionName: endpoint?.targetResource?.subscription,
                    resourceGroup: endpoint?.targetResource.resourceGroup,
                    location: endpoint?.targetResource.location,
                    resourceSubType: endpoint?.targetResource?.resourceSubType,
                    resourceId: endpoint?.targetResource?.resourceId,
                    environment: endpoint?.targetResource.environment,
                },
            }),
        };
    };
    return {
        requestorGpid: userClaims?.accessToken?.claims?.gpid,
        entityDetails: {
            name: order?.appProfile,
            // applicationManager: order?.entityDetails?.owner?.name,
            // applicationManagerEmail: order?.entityDetails?.owner?.emailId,
            // applicationManagerGpid: order?.entityDetails?.owner?.id,
            sector: order?.entityDetails?.sector || null,
        },
        estimatedCosts: order?.estimatedCost,
        catalogServices: order.instanceDetails.map((instanceDetail) => ({
            ...(catalogServiceId && { catalogServiceId }),
            cloudProvider: order?.cloudProvider,
            cloudService: order?.cloudService,
            catalogServiceCode: catalogCode,
            catalogType: order?.catalogType,
            sector: order?.sector,
            estimatedCost: order?.initCost,
            quantity: 1,
            catalogConfig: {
                name: order?.appProfile,
                sector: order?.sector,
                privateEndpointType: instanceDetail?.privateEndpointType === 'Enterprise PE' ? 'enterprise' : 'isolated',
                ...getEndpoint(instanceDetail),
                type: catalogCode,
            },
        })),
    };
};
export default getPayload;
