/* istanbul ignore file */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';
import HelpImg from 'pages/project-onboarding/components/helpImg';

export const TeamName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const teamNameApi = useMutation(() => axios.get(`${endPoints.day2Operation.ProjectOnboarding.Jwt.teamName}/${order?.sector?.toLowerCase()}`));

    const setTeamName = (teamName) => {
        setOrder({
            ...order,
            teamName,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (teamName) => options.find((option) => teamName === option.value);

    const handleOnSelectChange = (selected) => setTeamName(selected.value);

    React.useEffect(() => {
        if (teamNameApi?.isSuccess) {
            setOptions(teamNameApi?.data?.data?.teamNames?.map((app) => ({ label: app, value: app })));
        }
    }, [teamNameApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.teamName) {
            setOrder({ ...order, teamName: options?.[0].value });
        }
        if (options && options?.length && order?.teamName) {
            const teamName = isExistsInOptions(order?.teamName);
            setOrder({ ...order, teamName: teamName?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.sector) {
            resetOptions();
            teamNameApi.mutate();
        }
    }, [order?.sector]);

    React.useEffect(() => {
        if (!order?.teamName && order?.isConfigureTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.teamName, order?.isConfigureTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="teamName-section">
            <Select
                dataTestId="sectool-teamName-autocomplete"
                key="teamName"
                label="Team Name *"
                value={order?.teamName || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={teamNameApi.isLoading}
                isLoading={teamNameApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg
                maxWidth="500px"
                title={
                    <Box>
                        <Typography variant="span">The team name acts as a container for all repositories to be onboarded in Fortify and Snyk.</Typography>
                        <ol type="1">
                            <li>
                                <Box display="flex" flexDirection="column">
                                    If the team name is not found in the dropdown list:
                                    <Box>&nbsp;&nbsp;&#9900;&nbsp;Coordinate with your application owner to determine the correct team name.</Box>
                                </Box>
                            </li>
                            <li>
                                <Box display="flex" flexDirection="column">
                                    If the team name is still not found:
                                    <Box>
                                        &nbsp;&nbsp;&#9900;&nbsp;Contact the Distribution List (DL) at{' '}
                                        <a href="mailto:DevSecOpsSecurityTools@pepsico.com" rel="noreferrer" target="_blank">
                                            DevSecOpsSecurityTools@pepsico.com
                                        </a>{' '}
                                        to create a new team name.
                                    </Box>
                                </Box>
                            </li>
                        </ol>
                    </Box>
                }
            />
        </Box>
    );
};

export default React.memo(TeamName);
