/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const MaxBurstThroughput = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setMaxBurstThroughput = (maxBurstThroughput, maxBurstThroughputDetail) => {
        setOrder({ ...order, maxBurstThroughput, maxBurstThroughputDetail });
    };
    const seldValueExistsInOptions = (maxBurstThroughputValue) => options.find((option) => maxBurstThroughputValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setMaxBurstThroughput(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        setOptions(['120', '240', '500', '1100', '2300', '5000', '7000', '16000', '18000', '20000']?.map((data) => ({ label: data, value: data })));
    }, []);
    React.useEffect(() => {
        if (!order?.maxBurstThroughput && order?.isTouched) setIsTouched(true);
    }, [order?.maxBurstThroughput, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="maxBurstThroughput"
                label="Max Burst Throughput *"
                value={order?.maxBurstThroughput || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(MaxBurstThroughput);
