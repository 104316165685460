// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { CssTextField } from 'pages/reference-implementation/components/select';
import ConfirmDialog from 'pages/reference-implementation/provisioning/RI003O/components/Form/ConfirmDialog';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import HelpImg from 'pages/provisioning/_components/helpImg';

const ConsumerGroupList = (props) => {
    const { consumerGroups, handleSave, errors, handleGroupsCancel, allconsumerGrpList } = props;
    const [modalGroups, setModalGroups] = React.useState(consumerGroups);
    const [showConGrpDeleteModal, setShowConGrpDeleteModal] = React.useState(false);
    const [consumerRemoveIdx, setConsumerRemoveIdx] = React.useState('');
    // const [isExist, setIsExist] = React.useState(false);

    const changeSelection = (inputValue, index) => {
        const tempGroups = [...modalGroups];
        tempGroups[index] = { value: inputValue };
        setModalGroups([...tempGroups]);
    };
    const handleRemoveConsumerGroup = (removeIdx) => {
        setConsumerRemoveIdx(removeIdx);
        setShowConGrpDeleteModal(true);
    };
    const checkValidConsumerGroup = (consumerGroupValue) => {
        if (consumerGroupValue && consumerGroupValue !== '' && consumerGroupValue !== undefined) {
            const consumerGrp = consumerGroupValue;
            // const generalPattern = /^[a-zA-Z]+-[a-zA-Z0-9]+-[a-zA-Z0-9]+(?:-v\d+)?$/;
            const parts = consumerGrp.split('-');
            if (parts.length < 3) {
                return { isError: true, message: 'Consumer group must include Project, Service Name, Process Name, and optionally versioning.' };
            }
            if (!/^[a-zA-Z]+$/.test(parts[0])) {
                return { isError: true, message: 'Project must contain alphabetic characters only.' };
            }
            if (!/^[a-zA-Z0-9]+$/.test(parts[1])) {
                return { isError: true, message: 'Service Name must be alphanumeric.' };
            }
            if (!/^[a-zA-Z0-9]+$/.test(parts[2])) {
                return { isError: true, message: 'Process Name must be alphanumeric.' };
            }
            if (parts?.length > 2 && !parts?.slice(2).every((p) => /^[a-zA-Z0-9]+$/.test(p))) {
                return { isError: true, message: 'Process Name must be alphanumeric.' };
            }
            // if (parts[3] && !/^v\d+$/.test(parts[3])) {
            //     return { isError: true, message: 'Versioning, if present, must start with "v" followed by numeric values.' };
            // }
            // if (!generalPattern.test(consumerGrp)) {
            //     return { isError: true, message: 'Consumer group should be [project]-[servicename]-[processname]-v1' };
            // }

            if ([...allconsumerGrpList]?.filter((grp) => grp === consumerGrp)?.length > 1) {
                return { isError: true, message: 'Consumer group has already given in another topic' };
            }
        }
        return { isError: false, message: null };
    };

    const checkConsumerGroupDuplicates = (value) => {
        const consumerGroupValues = modalGroups?.map((group) => group?.value);
        const duplicateConsumerGroups = consumerGroupValues.filter((item, index) => consumerGroupValues.indexOf(item) !== index);
        if (value) {
            return duplicateConsumerGroups.indexOf(value) > -1;
        }
        return false;
    };

    const isFormValid = () => {
        let errorCount = 0;
        let isConsumerGroupValid = false;
        if (modalGroups?.length) {
            modalGroups?.forEach((group) => {
                if (!(group.value.length > 0 && !checkValidConsumerGroup(group.value)?.isError)) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isConsumerGroupValid = errorCount === 0;
        return isConsumerGroupValid;
    };

    function ifDuplicateValue() {
        const arr = modalGroups?.map((group) => group?.value);
        // eslint-disable-next-line
        for (var i = 0; i < arr?.length - 1; i++) {
            // eslint-disable-next-line
            for (var j = i + 1; j < arr?.length; j++) {
                if (arr[i] === arr[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    const handleAction = (actionType) => {
        if (actionType === 'yes') {
            if (modalGroups?.length) {
                const consumergrps = [...modalGroups];
                const updatedResources = consumergrps?.filter((_, idx) => idx !== consumerRemoveIdx);

                setModalGroups([...updatedResources]);
            }
            setShowConGrpDeleteModal(false);
        } else {
            setShowConGrpDeleteModal(false);
        }
    };
    const handleAddConsumerGroup = () => {
        setModalGroups([...modalGroups, { value: '' }]);
    };
    const namingConventionRules = [
        'Project should be alphabet',
        'Service name and Process name can contain alpha numeric values',
        'Versioning is optional, should start with V and numeric values',
        'Min.: 6 chars to Max.: 209 chars',
        'Can contain only hyphen(-) as special char',
        'Should not contain consecutive hyphens',
    ];
    // const checkConsumerGrpList = (val) => {
    //     let testVal = false;
    //     if (val !== '' && val !== null && [...allconsumerGrpList]?.includes(val)) {
    //         testVal = true;
    //     } else {
    //         testVal = false;
    //     }
    //     return testVal;
    // };

    // const checkModelElements = () =>
    //     modalGroups?.length > 0 && allconsumerGrpList?.length > 0 && modalGroups?.map((val) => val?.value).some((r) => allconsumerGrpList.includes(r));
    return (
        <Box sx={ProvisioningStyles.consumerGroupModal.wrapper}>
            <Stack direction="row" spacing={1}>
                <Typography variant="h6">Add Consumer Groups</Typography>
                <HelpImg
                    maxWidth="500px"
                    title={
                        <>
                            <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.topDividerRule}>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2}>
                                                Consumer group should be [project]-[servicename]-[processname]-v1
                                            </Typography>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2}>Example: cmp-log-data-v1</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2}>
                                                <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                            </Typography>
                                            {namingConventionRules?.map((nameConv, index) => {
                                                return (
                                                    <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle2} key={`consumer-rule-${index}`}>
                                                        {index + 1}) {nameConv}
                                                    </Typography>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    }
                />
            </Stack>
            <Box sx={ProvisioningStyles.consumerGroupModal.contentWrapper}>
                {modalGroups?.map((groupData, index) => (
                    <Box key={index.toString()} sx={ProvisioningStyles.consumerGroupModal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                        <Box sx={ProvisioningStyles.consumerGroupModal.consumerGrpWrapper}>
                            <>
                                <CssTextField
                                    id="outlined-size-small"
                                    size="small"
                                    sx={{
                                        width: (theme) => theme.spacing('100%'),
                                        '& .MuiFormHelperText-root': {
                                            marginTop: (theme) => theme.spacing('-2'),
                                        },
                                    }}
                                    label="Group Name *"
                                    variant="outlined"
                                    fullWidth
                                    autoComplete="off"
                                    error={
                                        errors[index]?.showError ||
                                        checkValidConsumerGroup(groupData?.value)?.isError ||
                                        checkConsumerGroupDuplicates(groupData?.value)
                                        // checkConsumerGrpList(groupData?.value)
                                    }
                                    onChange={(e) => {
                                        changeSelection(e.target.value, index);
                                    }}
                                    value={groupData?.value ?? ''}
                                    className={
                                        checkValidConsumerGroup(groupData?.value)?.isError || checkConsumerGroupDuplicates(groupData?.value) ? 'validate' : ''
                                    }
                                    // onBlur={() => checkConsumerGrpList(groupData?.value)}
                                    helperText={
                                        checkValidConsumerGroup(groupData?.value)?.message && (
                                            <span style={ProvisioningStyles.disposal.helpText}>
                                                {checkValidConsumerGroup(groupData?.value)?.isError && (
                                                    <>{checkValidConsumerGroup(groupData?.value)?.message}</>
                                                )}
                                            </span>
                                        )
                                    }
                                    data-testid="consumerGrpInput"
                                />
                                {groupData?.value && checkConsumerGroupDuplicates(groupData?.value) && !checkValidConsumerGroup(groupData?.value)?.isError && (
                                    <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                        Consumer group must not have duplicate values.
                                    </Typography>
                                )}
                                {/* {groupData?.value &&
                                    !checkConsumerGroupDuplicates(groupData?.value) &&
                                    !checkValidConsumerGroup(groupData?.value)?.isError &&
                                    checkConsumerGrpList(groupData?.value) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                            Consumer group has already given in another topic
                                        </Typography>
                                    )} */}
                            </>
                        </Box>
                        <Box sx={ProvisioningStyles.consumerGroupModal.iconWrapper}>
                            <Box className="flex w-full ">
                                {index !== 0 && modalGroups?.length > 1 && (
                                    <Box sx={ProvisioningStyles.consumerGroupModal.deleteWrapper}>
                                        <FaTrashAlt
                                            style={ProvisioningStyles.consumerGroupModal.delete}
                                            onClick={() => handleRemoveConsumerGroup(index)}
                                            className="w-7 h-7 text-red-base cursor-pointer"
                                            data-testid="consumerDeleteBtn"
                                        />
                                    </Box>
                                )}
                                {index === modalGroups.length - 1 && modalGroups?.length < 5 ? (
                                    <MdAdd
                                        style={ProvisioningStyles.consumerGroupModal.add}
                                        onClick={() => handleAddConsumerGroup()}
                                        className="w-7 h-7 text-blue-base cursor-pointer"
                                        data-testid="consumerAddBtn"
                                    />
                                ) : (
                                    <span className="w-7 h-7" />
                                )}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box className="footer" sx={ProvisioningStyles.consumerGroupModal.actionWrapper}>
                <Btn
                    className="custom-btn"
                    variant="contained"
                    color="cmpPrimary"
                    onClick={() => {
                        handleSave(modalGroups);
                    }}
                    disabled={!isFormValid() || ifDuplicateValue()}
                    data-testid="consumerSaveBtn"
                >
                    Save
                </Btn>
                <Btn
                    className="custom-btn"
                    sx={ProvisioningStyles.consumerGroupModal.cancelBtn}
                    onClick={() => handleGroupsCancel(consumerGroups)}
                    variant="outlined"
                    color="cmpWarning"
                    layout="outline"
                    data-testid="consumerCancelBtn"
                >
                    Cancel
                </Btn>
            </Box>
            {showConGrpDeleteModal && (
                <ConfirmDialog
                    isModalOpen={showConGrpDeleteModal}
                    title="Are you sure you want to remove ConsumerGroup?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                    isCompact
                />
            )}
        </Box>
    );
};

ConsumerGroupList.propTypes = {
    consumerGroups: PropTypes.any,
    handleSave: PropTypes.func,
    errors: PropTypes.any,
    handleGroupsCancel: PropTypes.func,
    allconsumerGrpList: PropTypes.any,
};

ConsumerGroupList.defaultProps = {
    consumerGroups: [],
    handleSave: () => {},
    errors: {},
    handleGroupsCancel: () => {},
    allconsumerGrpList: [],
};
export default ConsumerGroupList;
