import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { OrderPayload, isValidOwners } from 'pages/reference-implementation/provisioning/RI003O/store';
import { RIStyles } from 'pages/reference-implementation/Style';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { isUniqueOwner } from 'pages/reference-implementation/provisioning/RI003O/utils';

const CertificateOwnerTwo = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validOwner, setValidOwner] = useRecoilState(isValidOwners);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [searched, setSearched] = React.useState(false);

    const [options, setOptions] = React.useState([]);
    const appOwnerApi = useMutation(() => axios.get(`${endPoints.riServices.RI001.appOwner}?key=${ccInputValue}`));
    const appOwnerPostApi = useMutation((payload) => axios.get(`${endPoints.riServices.RI001.appOwner}?key=${payload}`));

    const setAppOwner = (certificateOwnerTwo, certificateOwnerTwoDetails) => {
        setOrder({
            ...order,
            certificateOwnerTwo,
            certificateOwnerTwoDetails,
        });
    };
    React.useEffect(() => {
        if (!order?.certificateOwnerTwo && !order?.certificateOwnerTwoDetails) {
            setOptions([]);
        }
    }, [order?.certificateOwnerTwo, order?.certificateOwnerTwoDetails]);
    const resetOptions = () => {
        setOptions([]);
        setAppOwner(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };
    const isExistsInOptions = (appownerValue) => options.find((option) => appownerValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.certificateOwnerTwo && order?.certificateOwnerTwoDetails) {
            setOrder({ ...order, certificateOwnerTwo: options?.[0].label, certificateOwnerTwoDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.certificateOwnerTwoDetails && !order?.certificateOwnerTwo) {
            const appOwerObj = isExistsInOptions(order?.certificateOwnerTwo?.name);
            setOrder({ ...order, certificateOwnerTwo: appOwerObj?.label, certificateOwnerTwoDetails: appOwerObj });
        }
    }, [options]);
    React.useEffect(() => {
        if (appOwnerApi?.data?.data) {
            const combinedValue = appOwnerApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (appOwnerPostApi?.data?.data) {
            const combinedValue = appOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setAppOwner(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.certificateOwnerTwo && order?.certificateOwnerTwoDetails) {
            appOwnerPostApi.mutate(order?.certificateOwnerTwoDetails?.gpid);
        }
        if (!order?.certificateOwnerTwo && !order?.certificateOwnerTwoDetails) {
            setOptions([]);
        }
    }, [order?.certificateOwnerTwo, order?.certificateOwnerTwoDetails]);

    React.useEffect(() => {
        if (!order?.certificateOwnerTwo && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.certificateOwnerTwo, order?.isTouched]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.certificateOwnerTwo) || isTouched || validOwner?.isvalidCertificateOwnerTwo === false) {
            return 'validate';
        }
        return '';
    };
    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };
    React.useEffect(() => {
        if (order?.certificateOwnerTwo) {
            const checkValidOwner = isUniqueOwner(order?.certificateOwnerTwo, [order?.certificateOwnerOne, order?.appOwner]);
            if (!checkValidOwner) {
                setValidOwner({ ...validOwner, isvalidCertificateOwnerTwo: false });
            } else {
                setValidOwner({ ...validOwner, isvalidCertificateOwnerTwo: true });
            }
        }
    }, [order?.certificateOwnerTwo]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Autocomplete
                    key="cert1OwnerDetails"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    loading={appOwnerApi?.isLoading}
                    value={order?.certificateOwnerTwo || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    //    filterOptions={filterOptions}

                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                        // return _results
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    isOptionEqualToValue={(option, value1) => option?.label === value1}
                    sx={{ width: '100%' }}
                    onBlur={() => {
                        if (!value || !order?.certificateOwnerTwo) {
                            setSearched(false);
                            setOptions([]);
                            setValidOwner({ ...validOwner, isvalidCertificateOwnerTwo: true });
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                            setValidOwner({ ...validOwner, isvalidCertificateOwnerTwo: true });
                        }
                    }}
                    className={validateInput()}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            error={validOwner?.isvalidCertificateOwnerTwo === false}
                            helperText={validOwner?.isvalidCertificateOwnerTwo === false && <span>Please Choose different member</span>}
                            className={validateInput()}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label={<InputLabel label="Certificate Owner 2" isRequired />}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={RIStyles?.form?.searchIcon}
                                            onClick={() => {
                                                setSearched(true);
                                                handleSearch();
                                            }}
                                            data-testid="cert2search-btn"
                                        />
                                    </>
                                ),
                            }}
                            fullWidth
                            data-testid="Cert1Search"
                        />
                    )}
                />
                <InputInfo title="Search Certificate Owner Two" />
            </Box>
        </>
    );
};

export default React.memo(CertificateOwnerTwo);
