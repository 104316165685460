import { getLoggedUserGPID, getUserScopes } from 'utils';
import { scope } from 'utils/scope';

export const appOptions = [
    { label: 'All AppProfiles', value: 'All AppProfiles' },
    { label: 'My AppProfiles', value: 'My AppProfiles' },
];

export const filterDefaultObj = () => ({
    profileType: getUserScopes().includes(scope[2]) || getUserScopes().includes(scope[5]) ? 'All AppProfiles' : 'My AppProfiles',
});

export const isCreatedByUser = ({ name }) => name?.toLowerCase() !== 'system';
export const isAppOwner = ({ appOwnerGpid }) => getLoggedUserGPID() === appOwnerGpid;
