/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const AvailabilityZone = ({ indexVal }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const setAvailabilityZone = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const seldValueExistsInOptions = (availabilityZone) => options?.find((option) => availabilityZone === option?.value);
    const handleOnSelectChange = (selected) => {
        setAvailabilityZone('availabilityZone', selected.value, indexVal);
    };

    React.useEffect(() => {
        resetOptions();
        if (order?.nodes?.[indexVal]?.haDeployment === 'Availability Zones' && order?.availabilityZoneOptions?.length > 0) {
            setOptions(order?.availabilityZoneOptions?.map((app) => ({ label: app, value: app })));
        }
    }, [order?.nodes?.[indexVal]?.haDeployment, order?.availabilityZoneOptions]);

    React.useEffect(() => {
        if (options?.length && options?.length === 0 && !order?.nodes?.[indexVal]?.availabilityZone) {
            setAvailabilityZone('availabilityZone', options?.[0]?.value, indexVal);
        }
        if (options?.length && options?.length > 1 && order?.nodes?.[indexVal]?.availabilityZone) {
            const availabilityZone = seldValueExistsInOptions(order?.nodes?.[indexVal]?.availabilityZone);
            if (availabilityZone) {
                setAvailabilityZone('availabilityZone', availabilityZone?.value, indexVal);
            }
        }
    }, [options]);

    React.useEffect(() => {
        if (!order?.nodes?.[indexVal]?.availabilityZone && order?.isTouched) setIsTouched(true);
        if (order?.nodes?.[indexVal]?.availabilityZone?.length === 0 && order?.isTouched) setIsTouched(true);
    }, [order?.nodes?.[indexVal]?.availabilityZone, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="Availability Zone"
                label="Availability Zone *"
                value={order?.nodes?.[indexVal]?.availabilityZone || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isRequired={isTouched}
            />
        </Box>
    );
};

AvailabilityZone.propTypes = {
    indexVal: PropTypes.number,
};

AvailabilityZone.defaultProps = {
    indexVal: 0,
};

export default React.memo(AvailabilityZone);
