export const Style = {
    wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
    orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
    nsgOrderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'none', mr: (theme) => theme.spacing(0.5) },
    newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1) },
    newOrderBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
    thankyouTypography: { textAlign: 'center' },
    backHome: {
        textTransform: 'none',
        color: '#0047BA',
        border: '2px solid #0047BA',
        '&:hover': {
            color: '#00A0DD',
            borderWidth: '2px',
            border: '2px solid #00A0DD',
            backgroundColor: 'white',
        },
    },
};
export default Style;
