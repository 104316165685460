import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { CssTextField } from 'components/select';
import { orderPayload, validVSForm, selectedVSService, isDuplicateCustomDomainName, validationStatus } from 'pages/provisioning/virtual-service/store';
import { validateCustomDomain } from 'pages/provisioning/virtual-service/utils';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import InfoImg from 'components/info-img';
import useDebounce from 'pages/provisioning/virtual-service/hooks/use-debounce';

export const CustomDomainName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [helperText, setHelperText] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [isDuplicate, setIsDuplicate] = useRecoilState(isDuplicateCustomDomainName);
    const [validCustomDomainName, setValidCustomDomainName] = React.useState('');
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [validationErrorStatus] = useRecoilState(validationStatus);
    const [helperMsg, setHelperMsg] = React.useState('');

    const certificateValidatationApi = useMutation((payload) => axios.post(`${endPoints.virtualService.certificateValidation}`, payload));

    const debouncedCustomDomainValue = useDebounce(validCustomDomainName, 500);

    const customDomainName = order?.aksVirtualService?.[selectedVS]?.customDomain?.[0]?.name;

    const handleValueChange = (name) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                if (index === selectedVS) {
                    return {
                        ...item,
                        customDomain: [
                            {
                                name,
                            },
                        ],
                    };
                }
                return item;
            }),
        }));
    };

    React.useEffect(() => {
        if (certificateValidatationApi?.isSuccess) {
            if (certificateValidatationApi?.data?.data?.customDomain?.[0]?.certificateExist) {
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                        if (index === selectedVS) {
                            return {
                                ...item,
                                certificateExists: true,
                            };
                        }
                        return item;
                    }),
                }));
            } else {
                setOrder((prevOrder) => ({
                    ...prevOrder,
                    aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                        if (index === selectedVS) {
                            return {
                                ...item,
                                certificateExists: false,
                            };
                        }
                        return item;
                    }),
                }));
            }
        }
    }, [certificateValidatationApi?.isSuccess]);

    React.useEffect(() => {
        if (certificateValidatationApi?.isError) {
            setOrder((prevOrder) => ({
                ...prevOrder,
                aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                    if (index === selectedVS) {
                        return {
                            ...item,
                            customDomain: [
                                {
                                    name: null,
                                },
                            ],
                        };
                    }
                    return item;
                }),
            }));
        }
    }, [certificateValidatationApi?.isError]);

    const checkDuplicate = (value) => {
        const customDomainNameList = [];
        order?.aksVirtualService?.forEach((item, index) => {
            if (index !== selectedVS) {
                customDomainNameList.push(item?.customDomain?.[0]?.name);
            }
        });
        if (value) {
            return customDomainNameList?.indexOf(value) > -1;
        }
        return false;
    };

    React.useEffect(() => {
        if (debouncedCustomDomainValue) {
            setIsDuplicate(checkDuplicate(debouncedCustomDomainValue));

            if (!checkDuplicate(debouncedCustomDomainValue)) {
                const payload = {
                    customDomain: [
                        {
                            name: debouncedCustomDomainValue,
                        },
                    ],
                };
                certificateValidatationApi?.mutate(payload);
            }
        }
    }, [debouncedCustomDomainValue]);

    React.useEffect(() => {
        const { isValid, message } = validateCustomDomain(customDomainName, order?.environment);
        if (customDomainName && customDomainName !== null && customDomainName !== '' && customDomainName !== undefined && !isValid) {
            setHelperText(true);
            setHelperMsg(message);
            setValidForm({ ...validForm, isInvalidCustomDomainName: true });
        } else if (customDomainName === null || customDomainName === '' || customDomainName === undefined) {
            setHelperMsg(message);
            setValidForm({ ...validForm, isInvalidCustomDomainName: true });
        } else if (customDomainName && isValid) {
            setValidCustomDomainName(customDomainName);
            setHelperText(false);
            setHelperMsg('');
            setValidForm({ ...validForm, isInvalidCustomDomainName: false });
        }
    }, [customDomainName]);

    React.useEffect(() => {
        if (!customDomainName && order?.isConfigureTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [customDomainName, order?.isConfigureTouched]);

    const getVSValidationStatus = () => {
        const failedCustomDomain = [];
        if (validationErrorStatus?.length > 0) {
            validationErrorStatus?.filter((error) => {
                return error?.customDomain?.map((domain) => {
                    if (domain?.name === order?.aksVirtualService?.[selectedVS]?.customDomain?.[0]?.name) {
                        failedCustomDomain.push(domain);
                    }
                    return null;
                });
            });
            return failedCustomDomain;
        }
        return false;
    };

    const getErrorMessage = () => {
        const validationError = getVSValidationStatus();
        if (helperText) {
            return helperMsg;
        }
        if (isDuplicate) {
            return 'Custom domain name already used in another Virtual service, please use unique one.';
        }
        if (!helperText && !isDuplicate && validationError?.length > 0) {
            return (
                <>
                    {validationError?.map((error, errorIndex) => (
                        <React.Fragment key={`error-${errorIndex}`}>
                            {error?.msg?.map((msg, index) => (
                                <p key={index}>{msg}</p>
                            ))}
                        </React.Fragment>
                    ))}
                </>
            );
        }
        return '';
    };

    const validateInput = () => {
        if ((isTouched && order?.isConfigureTouched && !customDomainName) || helperText || getErrorMessage()) {
            return 'validate';
        }
        return '';
    };

    const getToolTip = () => {
        return (
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Custom Domain Name:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>Custom Domain Description</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle} />
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>1) Custom domain name should be unique.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>2) It can have alpha-numeric value.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            3) It should end with pepsico.com or mypepsico.com
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            cert.dev.mypepsico.com
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="custom-domain-box">
            <CssTextField
                data-testid="custom-domain-name"
                size="small"
                fullWidth
                autoComplete="off"
                label="Custom Domain Name *"
                error={!!(helperText || isTouched || isDuplicate || getErrorMessage())}
                helperText={getErrorMessage()}
                value={customDomainName || ''}
                onChange={(e) => handleValueChange(e?.target?.value)}
                className={validateInput()}
            />
            {getToolTip()}
        </Box>
    );
};
export default React.memo(CustomDomainName);
