import React from 'react';
import { Box, Card, CircularProgress, Divider, Grid, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alb, currencySymbols, azKeyVault } from 'utils';
import Empty from 'components/empty';
import Styles from 'pages/provisioning/_components/style';
import { BsInfoSquare } from 'react-icons/bs';
import {
    adfCatalogDefinitionId,
    couchbaseDbDefinitionId,
    ApplicationGateWayURLCatalogDefinitionID,
    VirtualServiceCatalogDefinitionId,
    Day2CatalogDefinitionId,
} from 'utils/catalogDefinitionId';

const CostSummary = ({ cartBtn, costEstimate, isLoading, handleCostInfoBtn }) => {
    const { catalogCode } = useParams();
    return (
        <Card sx={Styles?.CostSummary?.card}>
            <Box sx={Styles?.CostSummary?.headingWrapper}>
                <Typography variant="h6" sx={Styles?.CostSummary?.heading}>
                    Estimated Cost Summary{' '}
                </Typography>
            </Box>
            <Divider />
            {isLoading && (
                <Empty>
                    <CircularProgress size={16} disableShrink sx={Styles?.CostSummary?.loaderIcon} />
                </Empty>
            )}
            {!isLoading && (
                <>
                    <Box sx={{ py: 1 }}>
                        <Grid container spacing={2} sx={{ py: 1 }}>
                            {!adfCatalogDefinitionId?.includes(catalogCode) && (
                                <>
                                    {![...ApplicationGateWayURLCatalogDefinitionID, ...VirtualServiceCatalogDefinitionId]?.includes(catalogCode) && (
                                        <>
                                            {' '}
                                            <Grid item xs={6} sx={Styles?.CostSummary?.qtyWrapper}>
                                                <Box sx={Styles?.CostSummary?.qtyLabel}>Quantity: </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Box sx={Styles?.CostSummary?.qtyLabel}>{costEstimate?.qty || '---'}</Box>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}

                                    {!azKeyVault.includes(catalogCode) && !alb.includes(catalogCode) && (
                                        <>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Box sx={Styles?.CostSummary?.labelStyle}>Total One Time Cost:</Box>
                                                {costEstimate?.totalOneTimeCost?.frequency && (
                                                    <Box sx={Styles?.CostSummary?.unit}>({costEstimate?.totalOneTimeCost?.frequency})</Box>
                                                )}
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Box sx={Styles?.CostSummary?.labelStyle}>
                                                        {costEstimate?.totalOneTimeCost?.estCost?.toString() &&
                                                            currencySymbols[costEstimate?.totalOneTimeCost?.currency]}
                                                        {(costEstimate?.totalOneTimeCost?.estCost?.toString() &&
                                                            costEstimate?.totalOneTimeCost?.estCost?.toFixed(2)) ||
                                                            '---'}
                                                    </Box>
                                                    <Box component="span">
                                                        <BsInfoSquare
                                                            data-testid="onetime-btn"
                                                            onClick={() => handleCostInfoBtn('one time')}
                                                            style={Styles?.CostSummary?.oneTimeBtn}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                    {!ApplicationGateWayURLCatalogDefinitionID?.includes(catalogCode) && (
                                        <>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Box sx={Styles?.CostSummary?.labelStyle}>Total Recurring Cost:</Box>
                                                {costEstimate?.totalRecurringCost?.frequency && (
                                                    <Box sx={Styles?.CostSummary?.unit}>({costEstimate?.totalRecurringCost?.frequency})</Box>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    <Box sx={Styles?.CostSummary?.labelStyle}>
                                                        {costEstimate?.totalRecurringCost?.estCost?.toString() &&
                                                            currencySymbols[costEstimate?.totalRecurringCost?.currency]}
                                                        {(costEstimate?.totalRecurringCost?.estCost?.toString() &&
                                                            costEstimate?.totalRecurringCost?.estCost?.toFixed(2)) ||
                                                            '---'}
                                                    </Box>
                                                    <Box component="span">
                                                        <BsInfoSquare
                                                            data-testid="monthly-btn"
                                                            onClick={() =>
                                                                handleCostInfoBtn(
                                                                    [...Day2CatalogDefinitionId, ...VirtualServiceCatalogDefinitionId]?.includes(catalogCode)
                                                                        ? costEstimate?.totalRecurringCost?.frequency
                                                                        : 'monthly'
                                                                )
                                                            }
                                                            style={Styles?.CostSummary?.monthlyBtn}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </>
                                    )}

                                    {!couchbaseDbDefinitionId?.includes(catalogCode) && (
                                        <Grid item xs={12}>
                                            <Box sx={Styles?.CostSummary?.note}> {costEstimate?.notes && <>Note : {costEstimate?.notes}</>}</Box>
                                        </Grid>
                                    )}
                                    {couchbaseDbDefinitionId?.includes(catalogCode) && (
                                        <Grid item xs={12}>
                                            <Box sx={Styles?.CostSummary?.note}>
                                                Note: Total Est. Cost doesn&apos;t include Couchbase database license cost & any cross changes. Please reach out{' '}
                                                <Link href="mailto:DL-ITHHNextCouchbaseAdmin@pepsico.com">DL-ITHHNextCouchbaseAdmin@pepsico.com</Link> further
                                                information.
                                            </Box>
                                        </Grid>
                                    )}
                                </>
                            )}
                            {/* {ApplicationGateWayURLCatalogDefinitionID?.includes(catalogCode) && (
                                <Grid item xs={12}>
                                    <Box sx={Styles?.CostSummary?.note}>Note: {costEstimate?.notes || '---'}</Box>
                                </Grid>
                            )} */}
                            {adfCatalogDefinitionId?.includes(catalogCode) && (
                                <Grid item xs={12}>
                                    <Box sx={Styles?.CostSummary?.note}>
                                        Note: Provisioning Azure Data Factory from Cloud Marketplace will not incur any cost. The pricing model of Azure Data
                                        Factory is based on usage of integration services, datasets, linked services, read/write operations. Refer the{' '}
                                        <Link href="https://learn.microsoft.com/en-us/azure/data-factory/pricing-concepts" target="_blank">
                                            Azure article
                                        </Link>{' '}
                                        to understand the pricing model. EDAP team (
                                        <Link href="mailto:DL-ITAdvancedAnalyticsPlatformSupport@pepsico.com">
                                            DL-ITAdvancedAnalyticsPlatformSupport@pepsico.com
                                        </Link>
                                        ) will be able to help for further details on pricing model.
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12} sx={Styles?.CostSummary?.cartWrapper}>
                                {cartBtn}
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Card>
    );
};
CostSummary.propTypes = {
    cartBtn: PropTypes.any,
    costEstimate: PropTypes.any,
    isLoading: PropTypes.bool,
    handleCostInfoBtn: PropTypes.func,
};

CostSummary.defaultProps = {
    cartBtn: undefined,
    costEstimate: undefined,
    isLoading: false,
    handleCostInfoBtn: undefined,
};

export default React.memo(CostSummary);
