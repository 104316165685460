/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { eipStackCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddCloudProvider, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';
import { getLandingZoneFromLocalStorage } from '../../utils';

const CloudProvider = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDCloudProvider, setLzDDCloudProvider] = useRecoilState(ddCloudProvider);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const cloudProviderApi = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    const setcloudProvider = (cloudProvider) => {
        setLandingZoneForm({
            ...landZoneForm,
            cloudProvider,
        });
    };
    const seldValueExistsInOptions = (regionValue) => options?.find((value) => regionValue === value?.data?.name);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== landZoneForm?.cloudProvider && seldValueExistsInOptions(selected?.value)) {
            setcloudProvider(selected?.data?.name);
        }
    };
    React.useEffect(() => {
        if (cloudProviderApi?.isSuccess) {
            setOptions(cloudProviderApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
            setLzDDCloudProvider({ ...lzDDCloudProvider, isLoaded: true });
        }
    }, [cloudProviderApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const cloudProvider = options.find((value) => lzModal?.lzDetails?.cloudProvider === value?.value);
            if (cloudProvider) setLzDDCloudProvider({ ...lzDDCloudProvider, isLoaded: true, data: cloudProvider });
        }
        if (options?.length && options?.length === 1 && !lzModal?.lzDetails) {
            setTimeout(() => {
                handleOnSelectChange(options[0]);
            }, 300);
        }
    }, [options]);
    React.useEffect(() => {
        if (lzModal?.isLoadedAllDD) handleOnSelectChange(lzDDCloudProvider?.data);
    }, [lzModal?.isLoadedAllDD]);

    React.useEffect(() => {
        cloudProviderApi.mutate();
    }, []);
    return (
        <Box bgcolor="white">
            <Select
                key="Catalog Provider"
                value={landZoneForm?.cloudProvider || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    cloudProviderApi.isLoading ||
                    lzModal?.type === 'edit' ||
                    (lzModal?.type === 'create' && getLandingZoneFromLocalStorage() && eipStackCatalogServiceCode !== landZoneForm?.cloudService)
                }
                isLoading={cloudProviderApi.isLoading}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Provider*"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(CloudProvider);
