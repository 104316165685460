export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const privilegedADGroup = catalogConfig?.contributorAdGroup?.length ? 'Yes' : 'No';
    const nonPrivilegedADGroup = catalogConfig?.readerAdGroup?.length ? 'Yes' : 'No';
    return {
        catalogCode: catalog?.catalogServiceCode,
        projectId: catalogConfig?.projectId ? catalogConfig?.projectId : null,
        projectDetails: catalogConfig?.projectId
            ? {
                  businessUnit: catalogConfig?.businessUnit,
                  hyperionNumber: catalogConfig?.budgetCode,
                  impactedSectors: catalogConfig?.sector,
                  projectNumber: catalogConfig?.projectId,
              }
            : null,
        businessUnit: catalogConfig?.businessUnit,
        sector: catalogConfig?.sector,
        ...(catalogConfig?.sector?.toLowerCase() === 'cgf' && { division: catalogConfig?.division }),
        budgetCode: catalogConfig?.costCenter,
        billingInfo: catalogConfig?.billingInformation,
        environment: catalogConfig?.environment,
        dataClassification: catalogConfig?.dataClassification,
        businessAppService: catalogConfig?.businessApplication && catalogConfig?.applicationService ? 'Yes' : 'No',
        // businessApp: catalogConfig?.businessApplication ? catalogConfig?.businessApplication : catalogConfig?.applicationName,
        businessApp: catalogConfig?.businessApplication,
        appService: !catalogConfig?.applicationService
            ? `${catalogConfig?.applicationName} ${['production']?.includes(catalogConfig?.environment?.toLowerCase()) ? 'PROD' : 'NON-PROD'}`
            : catalogConfig?.applicationService,
        applicationName: catalogConfig?.applicationName,
        appShortName: catalogConfig?.applicationShortName,
        appOwner: null,
        appOwnerDetails: { name: data?.manager?.name, gpid: data?.manager?.id },
        businessContact: null,
        businessContactDetails: { gpid: catalogConfig?.businessOwnerId },
        // appOwner: data?.manager?.id,
        privilegedADGroup,
        nonPrivilegedADGroup,
        ...(privilegedADGroup === 'Yes' && {
            privilegedADGroupList: catalogConfig?.contributorAdGroup.map((app) => ({
                contributorValue: app,
                contributorInputValue: app,
            })),
        }),
        ...(nonPrivilegedADGroup === 'Yes' && {
            nonPrivilegedADGroupList: catalogConfig?.readerAdGroup.map((app) => ({
                readerValue: app,
                readerInputValue: app,
            })),
        }),
        ...(!['production']?.includes(catalogConfig?.environment?.toLowerCase()) &&
            nonPrivilegedADGroup === 'No' && {
                nonPrivilegedADGroupOwner: null,
                nonPrivilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        ...(!['production']?.includes(catalogConfig?.environment?.toLowerCase()) &&
            privilegedADGroup === 'No' && {
                privilegedADGroupOwner: null,
                privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        ...(['production']?.includes(catalogConfig?.environment?.toLowerCase()) &&
            privilegedADGroup === 'No' && {
                privilegedADGroupOwner: null,
                privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        createdBy: data?.created?.by,
        isTouched: false,
    };
};

export const formUploadObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;

    return (
        catalogConfig?.filesUploadResponse?.uploadResponses?.map((item) => ({
            id: item?.id,
            name: item?.fileName,
            blobRelativeLink: item?.blobRelativeLink,
            size: item?.fileSize || 0,
        })) || []
    );
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    catalogCode: null,
    projectId: null,
    projectDetails: null,
    businessUnit: null,
    sector: null,
    division: null,
    budgetCode: null,
    budgetCodeDetails: null,
    billingInfo: null,
    environment: null,
    dataClassification: null,
    environmentDetails: null,
    businessAppService: 'Yes',
    businessApp: null,
    appService: null,
    appOwner: null,
    appOwnerDetails: null,
    applicationName: null,
    appShortName: null,
    businessContact: null,
    businessContactDetails: null,
    privilegedADGroup: null,
    nonPrivilegedADGroup: null,
    nonPrivilegedADGroupList: [{ readerValue: '' }],
    privilegedADGroupList: [{ contributorValue: '' }],
    privilegedADGroupOwner: null,
    privilegedADGroupOwnerDetails: null,
    nonPrivilegedADGroupOwner: null,
    nonPrivilegedADGroupOwnerDetails: null,
    isTouched: false,
};
