function searchInChild(objects, toSearch) {
    // eslint-disable-next-line
    var _finded = false;
    // eslint-disable-next-line
    for (var i = 0; i < objects.length; i++) {
        // eslint-disable-next-line
        for (let key in objects[i]) {
            if (objects[i][key] !== null && typeof objects[i][key] !== 'boolean' && typeof objects[i][key] !== 'number') {
                if (typeof objects[i][key] === 'object') {
                    _finded = searchInChild(objects[i][key], toSearch);
                } else if (objects[i][key].toLowerCase().includes(toSearch.toLowerCase())) {
                    _finded = true;
                }
            }
        }
    }
    return _finded;
}
function findNested(objects, toSearch) {
    // setSearchString(true)
    // eslint-disable-next-line
    var _results = [];
    // eslint-disable-next-line
    for (var i = 0; i < objects.length; i++) {
        // eslint-disable-next-line
        for (let key in objects[i]) {
            if (objects[i][key] !== null && typeof objects[i][key] !== 'boolean' && typeof objects[i][key] !== 'number') {
                if (typeof objects[i][key] === 'object') {
                    if (searchInChild(objects[i][key], toSearch)) {
                        _results.push(objects[i]);
                    }
                } else if (objects[i][key].toLowerCase().includes(toSearch.toLowerCase())) {
                    _results.push(objects[i]);
                }
            }
        }
    }
    return _results;
}
export { findNested, searchInChild };
