import React from 'react';
import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import { LoggedUserFirstName } from 'utils';
import ChesterAvatar from './components/ChesterAvatar';
import UserAvatar from './components/UserAvatar';
import Roles from './components/Roles';
import TopicButton from './components/TopicButton';
import TopicList from './components/TopicList';
import Feedback from './components/Feedback';
import Header from './components/Header';
import HistorySeparator from './components/HistorySeparator';

const config = (historyData = [], gpid = '123', accessToken) => {
    return {
        initialMessages: [
            ...historyData,
            historyData.length > 0 && createCustomMessage('divider', 'divider'),

            createChatBotMessage(
                `Hi ${LoggedUserFirstName().FirstName()}, I am your Virtual Agent. Let me know how can I help you today.
            `
            ),
            createChatBotMessage(`Please select the role you are in to get started.`, {
                withAvatar: true,
            }),
            createCustomMessage('role', 'roles'),
        ],
        customComponents: {
            header: (props) => <Header {...props} />,
            botAvatar: (props) => <ChesterAvatar {...props} />,
            userAvatar: (props) => <UserAvatar {...props} />,
        },
        state: {
            accessToken,
            userId: gpid,
            role: null,
            topicList: [],
            topicText: '',
        },
        customMessages: {
            topicButton: (props) => <TopicButton {...props} />,
            topicList: (props) => <TopicList {...props} />,
            feedback: (props) => <Feedback {...props} />,
            divider: (props) => <HistorySeparator {...props} />,
            roles: (props) => <Roles {...props} />,
        },
        widgets: [
            {
                widgetName: 'roles',
                widgetFunc: (props) => <Roles {...props} />,
            },
        ],
    };
};

export default config;
