import React from 'react';
import PropTypes from 'prop-types';
import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import { Box, Grid, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import { updateSourceFileNamePattern } from 'pages/mdt-onboarding/store/selector';
import { patternRegex, isSourceFilePatternError } from '../../utils';
import styles from './style';

const SourceFileNamePattern = ({ indexVal, indexList, updateEmptyList, validationData }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order] = useRecoilState(orderPayload);
    const [helperMsg, setHelperMsg] = React.useState(false);
    const [showHelperText, setHelperText] = React.useState(false);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);
    const updatePattern = useSetRecoilState(updateSourceFileNamePattern);

    const handleOnInputChange = (fileName) => {
        updatePattern({ fileName, indexVal });
    };
    React.useEffect(() => {
        if (patternRegex(order?.sourceFileNamePatternList[indexVal]?.fileName)) {
            setHelperText(true);
            setHelperMsg('Invalid Source Filename Pattern.');
            setValidForm({ ...validForm, isSourceFileNamePatternValid: true });
        } else {
            setValidForm({ ...validForm, isSourceFileNamePatternValid: false });
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.sourceFileNamePatternList[indexVal]?.fileName]);

    React.useEffect(() => {
        if (!order?.sourceFileNamePatternList[indexVal] && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.sourceFileNamePatternList[indexVal]?.fileName, order?.isTouched]);
    const isEmpty = (indexValue) => {
        if (indexList?.length) return indexList.includes(indexValue);
        return false;
    };

    const isDuplicate = (fileNameArray, fileName) => {
        const duplicateFileNamePattern = fileNameArray?.filter((item, index) => fileNameArray.indexOf(item) !== index);
        if (fileName) {
            return duplicateFileNamePattern.indexOf(fileName) > -1;
        }
        return false;
    };

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.sourceFileNamePattern) ||
            showHelperText ||
            isEmpty(indexVal) ||
            isDuplicate(
                order?.sourceFileNamePatternList?.map((item) => item?.fileName),
                order?.sourceFileNamePatternList?.[indexVal]?.fileName
            )
        ) {
            return 'validate';
        }
        return '';
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }} data-testid="source-filename-pattern-text" paddingTop="9px">
                <CssTextField
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                    }}
                    label="Source Filename Pattern*"
                    value={order?.sourceFileNamePatternList?.[indexVal]?.fileName || null}
                    onChange={(e) => {
                        updateEmptyList(indexVal);
                        handleOnInputChange(e.target.value);
                    }}
                    className={
                        isSourceFilePatternError(validationData, order?.sourceFileNamePatternList?.[indexVal]?.fileName) || validateInput() ? 'validate' : ''
                    }
                    autoComplete="off"
                    error={isTouched || showHelperText}
                    helperText={showHelperText && <span style={styles?.helpText}>{helperMsg}</span>}
                />
            </Box>
            <HelpImg
                title={
                    <>
                        <Box sx={styles.infoStyle.wrapper}>
                            <Box sx={styles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={styles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            <span style={styles.infoStyle.headingStyle}>Filename pattern may have</span>
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>1) Alpha-Numeric values.</Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>2) Only asterisk (*) regex literal supported and allowed.</Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            3) Basename may start or end with one asterisk (*). Ex &quot;*tfile.txt&quot; or &quot;mdtf*.txt&quot;.
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            4) Extension may start or end with one asterisk (*). Ex &quot;mdtfile.*xt&quot; or &quot;mdtfile.tx*&quot;.
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            5) Maximum two asterisks (*) are allowed. One in basename and one in extension. Ex. &quot;*tfile.*xt&quot;.
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            6) Enter only filename pattern without directory or file path.
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            7) Allowed special chars. are Hyphen(-), Underscore(_), Dot(.), Asterisk(*), Comma(,)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={styles.infoStyle.bottomDividerRule}>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            <span style={styles.infoStyle.headingStyle}>Examples:</span>
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            Valid: &quot;mdtfile.txt&quot;, &quot;mdtfi*.*xt&quot;, &quot;*tfile.tx*&quot;
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>Invalid: &quot;md*ile.t*t&quot;, &quot;*tfi*.txt&quot;</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

SourceFileNamePattern.propTypes = {
    indexVal: PropTypes.number,
    indexList: PropTypes.array,
    validationData: PropTypes.array,
    updateEmptyList: PropTypes.func,
};

SourceFileNamePattern.defaultProps = {
    indexVal: 0,
    indexList: [],
    validationData: [],
    updateEmptyList: () => {},
};

export default SourceFileNamePattern;
