import React from 'react';
import Modal from 'components/modal';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { RecurringFrequency } from 'utils/costFrequency';
import { currencySymbols } from 'utils';
import InfoTooltip from 'pages/reference-implementation/components/info-tooltip';
import InfoImg from 'components/info-img';
import { adfCatalogDefinitionId, couchbaseDbDefinitionId } from 'utils/catalogDefinitionId';
import Styles from './style';

const CostDetails = ({ type, isCostOpen, costEstimate, handleCloseModal, showInfo, connections, catalogCode }) => {
    const getData = (value) => {
        const connArray = connections && Object.keys(connections).map((key) => connections[key]);
        if (couchbaseDbDefinitionId?.includes(catalogCode)) {
            const catlg = connArray?.length && connArray?.filter((conn) => conn?.componentName === value);
            return catlg[0]?.catalogService?.estimatedCost?.details || [];
        }
        const catlg = connArray?.length && connArray?.filter((conn) => conn?.catalogService?.catalogType === value);
        return catlg[0]?.catalogService?.estimatedCost?.details || [];
    };
    return (
        <Modal
            title={type.toLowerCase() === 'one time' ? 'One Time Cost Details' : 'Recurring Cost Details'}
            handleClose={() => handleCloseModal()}
            open={isCostOpen}
            width="800px"
        >
            <Box sx={{ px: 2 }}>
                {!adfCatalogDefinitionId?.includes(catalogCode) && (
                    <>
                        <Grid container spacing={2}>
                            {costEstimate?.details &&
                                costEstimate?.details
                                    ?.filter((cost) => cost?.frequency?.toLowerCase() === type.toLowerCase())
                                    ?.map((value, i) => (
                                        <React.Fragment key={i.toString()}>
                                            <Grid item xs={6}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Box sx={Styles?.CostDetails?.item}>{value?.item || value?.name}:</Box>
                                                    {value?.notes && (
                                                        <Box sx={{ marginLeft: 1, marginTop: 0.5 }}>
                                                            <InfoTooltip title={value?.notes} />
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Box sx={Styles?.CostDetails?.unit}>({value?.frequency})</Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={Styles?.CostDetails?.estWrapper}>
                                                    <Box sx={Styles?.CostDetails?.colon}>&nbsp;</Box>
                                                    <Box sx={Styles?.CostDetails?.estCost}>
                                                        {value?.estCost?.toString() && currencySymbols[value?.currency]}
                                                        {(value?.estCost?.toString() && value?.estCost?.toFixed(2)) || '---'}
                                                    </Box>
                                                    {showInfo && value?.estCost?.toString() && RecurringFrequency.includes(type?.toLowerCase()) && (
                                                        <Box sx={{ marginLeft: 2 }}>
                                                            <InfoImg
                                                                title={
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            height: 'auto',
                                                                            width: '200px',
                                                                            maxWidth: '500px',
                                                                        }}
                                                                    >
                                                                        <Grid container sx={Styles?.CostDetails?.gridcontainer}>
                                                                            {getData(value?.item || value?.name)?.map((label, index) => (
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                    sx={{
                                                                                        mb: (theme) => theme.spacing('3px'),
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        justifyContent: 'flex-start',
                                                                                        alignItems: 'center',
                                                                                        // flexGrow:1
                                                                                    }}
                                                                                    key={index}
                                                                                >
                                                                                    <Box width="70%">
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: 'block',
                                                                                                vaiant: (theme) => theme.typography.caption.fontSize,
                                                                                                fontFamily: 'Roboto',
                                                                                                fontSize: '14px',
                                                                                                fontWeight: 'normal',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            {label?.item}:
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box width="30%">
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: 'block',
                                                                                                vaiant: (theme) => theme.typography.caption.fontSize,
                                                                                                fontFamily: 'Roboto',
                                                                                                fontSize: '14px',
                                                                                                fontWeight: 'normal',
                                                                                            }}
                                                                                        >
                                                                                            {' '}
                                                                                            {currencySymbols[label?.currency]}
                                                                                            {label?.estCost}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    </Box>
                                                                }
                                                                placement="right"
                                                            />{' '}
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                        </Grid>
                        <Grid container spacing={2} sx={{ py: 1 }}>
                            {type === 'one time' && (
                                <>
                                    <Grid item xs={6}>
                                        <Box sx={Styles?.CostDetails?.labelStyle}>Total one time cost:</Box>
                                        <Box sx={Styles?.CostDetails?.unit}>({costEstimate?.totalOneTimeCost?.frequency})</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={Styles?.CostDetails?.colon}>&nbsp;</Box>
                                            <Box sx={Styles?.CostDetails?.labelStyle}>
                                                {costEstimate?.totalOneTimeCost?.estCost?.toString() &&
                                                    currencySymbols[costEstimate?.totalOneTimeCost?.currency]}
                                                {(costEstimate?.totalOneTimeCost?.estCost?.toString() && costEstimate?.totalOneTimeCost?.estCost?.toFixed(2)) ||
                                                    '---'}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            {(type === 'monthly' || type === 'per rule' || type === 'yearly') && (
                                <>
                                    <Grid item xs={6}>
                                        <Box sx={Styles?.CostDetails?.labelStyle}>Total recurring cost:</Box>
                                        <Box sx={Styles?.CostDetails?.unit}>({costEstimate?.totalRecurringCost?.frequency})</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={Styles?.CostDetails?.colon}>&nbsp;</Box>
                                            <Box sx={Styles?.CostDetails?.labelStyle}>
                                                {costEstimate?.totalRecurringCost?.estCost?.toString() &&
                                                    currencySymbols[costEstimate?.totalRecurringCost?.currency]}
                                                {(costEstimate?.totalRecurringCost?.estCost?.toString() &&
                                                    costEstimate?.totalRecurringCost?.estCost?.toFixed(2)) ||
                                                    '---'}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </>
                )}
                {adfCatalogDefinitionId?.includes(catalogCode) && <>Note: {costEstimate?.notes}</>}
            </Box>
        </Modal>
    );
};
CostDetails.propTypes = {
    type: PropTypes.string,
    isCostOpen: PropTypes.bool,
    costEstimate: PropTypes.any,
    handleCloseModal: PropTypes.func.isRequired,
    showInfo: PropTypes.any,
    connections: PropTypes.any,
    catalogCode: PropTypes.string,
};

CostDetails.defaultProps = {
    isCostOpen: false,
    type: '',
    costEstimate: [],
    showInfo: false,
    connections: [],
    catalogCode: undefined,
};

export default React.memo(CostDetails);
