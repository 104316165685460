import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const ApplicationServiceText = () => {
    const [order] = useRecoilState(OrderPayload);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Application Service" isRequired />}
                value={order?.appService || ''}
                disabled
            />
        </Box>
    );
};

export default ApplicationServiceText;
