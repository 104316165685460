// import theme from 'assets/style/theme';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const landingStyle = {
    landingWraper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    bannerWrapper: {
        '@media (992 <= width <= 1200)': {
            height: '380px',
        },
        '@media (768 <= width <= 991)': {
            height: '274px',
        },
        '@media (600 <= width <= 767)': {
            height: '134px',
        },
    },
    banner1: {
        '@media (992 <= width <= 1200)': {
            height: '510px',
        },
        '@media (768 <= width <= 991)': {
            height: '300px',
        },
        '@media (600 <= width <= 767)': {
            height: '300px',
        },
    },
    bannerImage: {
        backgroundImage: 'url("/static/images/banner/Banner.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'contain',
        height: '100%',
        position: 'relative',
        backgroundSize: 'contain',
    },

    solutionStyle: {
        title: {
            color: (theme) => theme.palette.black.dark,
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            mt: (theme) => theme.spacing('30px'),
            mb: (theme) => theme.spacing('25px'),
            fontFamily: 'Stolzl',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        sliderWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignContent: 'stretch',
        },
        slidercontainer: { width: '100%' },
        viewMoreServicesWrapper: { height: '225px', width: '260px', position: 'relative' },
        solutionsOffering: { fontWeight: 'bolder', fontSize: 15, color: '#000000', mb: '22px', mt: '0', fontFamily: 'Stolzl !important' },
        serviceOffering: { fontWeight: 'bolder', fontSize: 15, color: '#000000', mb: '25px', mt: '-15px', fontFamily: 'Stolzl !important' },
        viewMoreProductImg: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            height: '340px',
            width: '285px',
            position: 'relative',
            marginBottom: '16px',
            backgroundSize: 'cover',
            transition: 'box-shadow 0.5s',
            '&:hover': {
                boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
            },
        },
        nextArrow: { color: '#FFFFFF', fontSize: 35 },
        prevarrow: {
            color: '#FFFFFF',
            fontSize: 35,
            '&.MuiIcon-root:hover': {
                color: '#FFFFFF',
            },
        },
        gridWrapper: { marginLeft: '46px', marginRight: '38px' },
    },
    roles: {
        rightwrapper: {
            backgroundColor: '#001C4A',
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        cloudImg: { width: '100px', height: '61px', marginTop: '119px' },
        benfitsTypo: { color: '#FFFFFF', fontSize: { md: 24, lg: 30 }, fontFamily: 'Stolzl', fontWeight: 'normal', mt: { md: 2, lg: 2 } },
        expertise: { color: '#FFFFFF', fontSize: 16, fontFamily: 'Stolzl', fontWeight: 400, mt: { md: 0, lg: 0 } },
        leftwrapper: { backgroundColor: '#001C4A', mr: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' },
        griditem: { display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 1, maxWidth: '16% !important' },
        boxIcon: { padding: 0 },
        msgicon: { width: 'auto', color: '#FFFFFF', fontSize: '13px', fontWeight: 600, textAlign: 'center', textTransform: 'uppercase' },
        userexpmsg: {
            width: '100%',
            color: '#FFFFFF',
            fontSize: '13px',
            fontWeight: 600,
            textAlign: 'center',
            textTransform: 'uppercase',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
        },
        utilizationmsg: { width: '100%', color: '#FFFFFF', fontSize: '13px', fontWeight: 600, ml: 2, textTransform: 'uppercase', textAlign: 'center' },
        imgIcon: { height: '51px', width: '51px' },
        numberdiv: { fontSize: '150px', color: '#1AAAE0', marginLeft: '35px', marginTop: '-15px', fontFamily: 'Stolzl' },
    },
    viewMoreProduct: {
        boxStyle: { pl: (theme) => theme.spacing(4), pr: (theme) => theme.spacing(4), pt: (theme) => theme.spacing(3) },
        viewMoreDesc: { fontSize: '25px', marginTop: '50px', fontFamily: 'Stolzl !important', textAlign: 'center' },
        viewMoreButtonContainer: { position: 'absolute', bottom: 0, width: '100%', paddingRight: '64px', marginBottom: '100px' },
        serviceButton: { alignItems: 'center', display: 'flex', justifyContent: 'center' },
        viewMoreSolutionsButton: {
            marginBottom: 1,
            color: '#ffffff',
            backgroundColor: '#1478D4',
            borderColor: '#ffffff',
            fontFamily: 'Stolzl !important',
            textWrap: 'nowrap',
            '&:hover': {
                backgroundColor: '#1478D4',
                borderColor: '#ffffff',
            },
        },
        viewMoreServiceButton: {
            marginBottom: 1,
            color: '#001C4A',
            backgroundColor: '#ffffff',
            borderColor: '#001C4A',
            fontFamily: 'Stolzl !important',
            '&:hover': {
                backgroundColor: '#ffffff',
                borderColor: '#001C4A',
            },
        },
    },
    productDetailsCard: {
        maxWidth: '285px',
        position: 'relative',
        marginBottom: '11px',
        height: '340px',
        width: 'auto',
        transition: 'box-shadow 0.5s',
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
        },
    },
};

export const CatalogServiceTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.white,
    fontSize: theme.spacing(3.6),
    display: 'flex',
    justifyContent: 'center',
    my: 1,
    mb: 2,
    fontWeight: 500,
}));

export default landingStyle;
