import React from 'react';
import { Box } from '@mui/material';
import Select from 'components/select';
import { useRecoilState } from 'recoil';
import { orderPayload } from 'pages/mdt-onboarding/store';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';

const Source = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [options, setOptions] = React.useState([]);

    const sourceApi = useMutation(() => axios.get(`${endPoints.mdt.Jwt.source}?environment=${order?.environment}`));

    const setSource = (source) => {
        setOrder({
            ...order,
            source,
            sourceValue: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (source) => options.find((option) => source === option.value);

    const handleOnSelectChange = (selected) => setSource(selected.value);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.source) {
            setOrder({ ...order, source: options?.[0].value });
        }
        if (options && options?.length && order?.source) {
            const source = isExistsInOptions(order?.source);
            setOrder({ ...order, source: source?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (sourceApi?.data) {
            setOptions(sourceApi?.data?.data?.map((item) => ({ label: item.source, value: item.source, data: item })));
        }
    }, [sourceApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.environment) {
            resetOptions();
            sourceApi.mutate();
        }
    }, [order?.environment]);

    React.useEffect(() => {
        if ((!order?.source, order?.isTouched)) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.isTouched, order?.source]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="source-autocomplete"
                key="source"
                label="Source *"
                value={order?.source || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sourceApi.isLoading}
                isLoading={sourceApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="Please select valid source." />
        </Box>
    );
};

export default Source;
