import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack } from '@mui/material';
import LabelValue from 'components/label-value';
import InfoImg from 'components/info-img';
import approvalStyle from 'components/order-summary-details/style';
import { currencySymbols } from 'utils';
import { OneTimeFrequency, RecurringFrequency } from 'utils/costFrequency';
import { AlbCatalogDefinitionId, keyvaultCatalogDefinitionId } from 'utils/catalogDefinitionId';

const CostSplit = ({ data, width }) => {
    const isOldOrder = Object.keys(data)?.includes('totalEstimatedCost') || false;
    const totalEstimatedCost = data?.totalEstimatedCost?.includes('/month')
        ? Number(data?.totalEstimatedCost?.split('/')?.[0]?.substring(1))
        : Number(data?.totalEstimatedCost) || 0;
    const totalOneTimeCost = data?.totalOneTimeCost?.estCost || 0;
    const totalRecurringCost = data?.totalRecurringCost?.estCost || 0;
    const oneTimeCosts = data?.details?.filter((item) => OneTimeFrequency.map((rf) => rf.toLowerCase()).includes(item.frequency.toLowerCase()));
    const recurringCosts = data?.details?.filter((item) => RecurringFrequency.map((rf) => rf.toLowerCase()).includes(item.frequency.toLowerCase()));
    const currency = data?.totalOneTimeCost?.currency || 'USD';

    return (
        <>
            {isOldOrder && (
                <>
                    <Grid key="Total estimated cost" xs={12} sx={approvalStyle.gridLabel}>
                        <LabelValue
                            width={width}
                            key="Total estimated cost"
                            label="Total estimated cost"
                            value={`${currencySymbols[currency]}${totalEstimatedCost?.toFixed(2) || '$0.00'}`}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    height: '22px',
                                }}
                            >
                                <Box>{`${currencySymbols[currency]}${totalEstimatedCost?.toFixed(2) || '$0.00'}`}</Box>
                                {Object.keys(data)?.length > 0 && (
                                    <InfoImg
                                        title={
                                            <>
                                                {Object.keys(data)?.map((label, index) =>
                                                    !['totalEstimatedCost', 'type'].includes(label) ? (
                                                        <Box key={index}>
                                                            {label}: &nbsp;{data[label]}
                                                        </Box>
                                                    ) : (
                                                        false
                                                    )
                                                )}
                                            </>
                                        }
                                        placement="right"
                                    />
                                )}
                            </Stack>
                        </LabelValue>
                    </Grid>
                </>
            )}
            {!isOldOrder && (
                <>
                    {![...keyvaultCatalogDefinitionId, ...AlbCatalogDefinitionId].includes(data?.type) && (
                        <Grid key="Total estimated cost" xs={12} sx={approvalStyle.gridLabel} item>
                            <LabelValue
                                width={width}
                                key="Total one time cost"
                                label="Total one time cost"
                                value={`${currencySymbols[currency]}${totalOneTimeCost?.toFixed(2) || '$0.00'}`}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                        height: '22px',
                                    }}
                                >
                                    <Box>{`${currencySymbols[currency]}${totalOneTimeCost?.toFixed(2) || '$0.00'}`}</Box>
                                    {oneTimeCosts?.length > 0 && (
                                        <InfoImg
                                            title={
                                                <>
                                                    {oneTimeCosts?.map((costItem, index) => (
                                                        <Box key={index}>
                                                            {costItem?.item} : {currencySymbols[costItem?.currency]} {costItem?.estCost}
                                                        </Box>
                                                    ))}
                                                </>
                                            }
                                            placement="right"
                                        />
                                    )}
                                </Stack>
                            </LabelValue>
                        </Grid>
                    )}
                    <Grid key="Total recurring cost" xs={12} sx={approvalStyle.gridLabel} item>
                        <LabelValue
                            width={width}
                            key="Total recurring cost"
                            label="Total recurring cost"
                            value={`${currencySymbols[currency]}${totalRecurringCost?.toFixed(2) || '$0.00'}`}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                    height: '22px',
                                }}
                            >
                                <Box>{`${currencySymbols[currency]}${totalRecurringCost?.toFixed(2) || '$0.00'}`}</Box>

                                {recurringCosts?.length > 0 && (
                                    <InfoImg
                                        title={
                                            <>
                                                {recurringCosts?.map((costItem, index) => (
                                                    <Box key={index}>
                                                        {costItem?.item} : {currencySymbols[costItem?.currency]} {Number(costItem?.estCost).toFixed(2)}
                                                    </Box>
                                                ))}
                                            </>
                                        }
                                        placement="right"
                                    />
                                )}
                            </Stack>
                        </LabelValue>
                    </Grid>
                </>
            )}
        </>
    );
};

CostSplit.propTypes = {
    data: PropTypes.any,
    width: PropTypes.number,
};

CostSplit.defaultProps = {
    data: {
        totalEstimatedCost: '$0.00/month',
    },
    width: 160,
};

export default React.memo(CostSplit);
