import * as React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import { useHeaderStyle as style } from 'assets/style';
import { CascadingContext } from 'components/cascading-hover-menus/components/cascading-context';

export const CascadingMenuItem = ({ onClick, children, ...props }) => {
    const { rootPopupState } = React.useContext(CascadingContext);
    if (!rootPopupState) throw new Error('must be used inside a CascadingMenu');

    const handleClick = React.useCallback(
        (event) => {
            rootPopupState.close(event);
            if (onClick) onClick(event);
        },
        [rootPopupState, onClick]
    );

    return (
        <MenuItem {...props} onClick={handleClick} sx={style?.hovermenuitems}>
            <Typography component="h4" sx={{ ...style.menuitemColor, width: '100%' }}>
                {children}
            </Typography>
        </MenuItem>
    );
};

CascadingMenuItem.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
};
CascadingMenuItem.defaultProps = {
    onClick: () => {},
    children: null,
};

export default {};
