/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';

const Cluster = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    let clusterEndpoint;
    switch (landingZoneForm?.service) {
        case 'PaaS/AKSNS':
            clusterEndpoint = `${endPoints.landingZones.cluster}/AKS`;
            break;
        case 'PaaS/Kafka Topics':
            clusterEndpoint = `${endPoints.landingZones.cluster}/Kafka`;
            break;
        default:
            clusterEndpoint = endPoints.landingZones.cluster;
    }
    const clusterApi = useMutation((payload) => axios.post(clusterEndpoint, payload));

    const setCluster = (cluster) => {
        setLandingZoneForm({
            ...landingZoneForm,
            cluster,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        setCluster(null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setCluster(selected?.label);
        } else {
            setCluster(null);
        }
    };
    React.useEffect(() => {
        if (clusterApi?.isSuccess) {
            setOptions(clusterApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        }
    }, [clusterApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.cluster) {
            const cluster = options.find((value) => landingZoneForm?.cluster === value?.label);
            setCluster(cluster?.label);
        }
    }, [options]);

    React.useEffect(() => {
        if (landingZoneForm?.environment && landingZoneForm?.regionDetails?.value && landingZoneForm?.department) {
            resetOptions();
            clusterApi.mutate({
                environment: landingZoneForm?.environment,
                region: landingZoneForm?.regionDetails?.value,
                department: landingZoneForm?.department,
            });
        }
    }, [landingZoneForm?.environment, landingZoneForm?.regionDetails?.value, landingZoneForm?.department]);

    React.useEffect(() => {
        if (!landingZoneForm?.cluster && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.cluster, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="cluster"
                value={landingZoneForm?.cluster || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={clusterApi.isLoading}
                isLoading={clusterApi.isLoading}
                disableClearable={false}
                label="Cluster *"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Cluster);
