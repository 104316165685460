import { orderStatus } from 'utils/orderStatus';

export default {
    [orderStatus[0]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    'PA-All Pending': [
        {
            name: 'Account Owner Approval',
            status: 'hourglass',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'hourglass',
        },
    ],
    'PA-Manager Approved': [
        {
            name: 'Account Owner Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'hourglass',
        },
    ],
    'PA-Groupowner Approved': [
        {
            name: 'Account Owner Manager Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'hourglass',
        },
    ],
    'PA-Only Manager': [
        {
            name: 'Account Owner Approval',
            status: 'hourglass',
        },
    ],
    [orderStatus[1]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'hourglass',
        },
        {
            name: orderStatus[4]?.name,
            status: 'wait',
        },
    ],
    [orderStatus[2]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Cancelled',
            status: 'clear',
        },
    ],
    [orderStatus[3]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'clear',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
    ],
    'Groupowner Rejected': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'clear',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
    ],
    'Manager Rejected': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'clear',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
    ],
    'Manager Rejected-Groupowner Pending': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'hourglass',
        },
        {
            name: 'Account Owner Approval',
            status: 'clear',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
    ],
    'Groupowner Rejected-Manager Pending': [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'hourglass',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'clear',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
    ],
    [orderStatus[4]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'done',
        },
        {
            name: 'Completed',
            status: 'done',
        },
    ],
    [orderStatus[5]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Action Required',
            status: 'priority-high',
        },
        {
            name: 'Account Owner Approval',
            status: 'wait',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'wait',
        },
        {
            name: 'Provisioning',
            status: 'wait',
        },
        {
            name: 'Completed',
            status: 'wait',
        },
    ],
    [orderStatus[6]?.name]: [
        {
            name: 'Requested',
            status: 'done',
        },
        {
            name: 'Account Owner Approval',
            status: 'done',
        },
        {
            name: 'Account Owner Manager Approval',
            status: 'done',
        },
        {
            name: 'Provisioning',
            status: 'done',
        },
        {
            name: orderStatus[4]?.name,
            status: 'hourglass',
        },
    ],
};
