import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { OrderPayload, isAppShortNameValid, isAppShortNameExist, isAppNameValid } from 'pages/sso-okta/store';
import useDebounce from 'pages/reference-implementation/provisioning/RI003/hooks/use-debounce';

export const ApplicationShortName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [appNameValid] = useRecoilState(isAppNameValid);
    const [, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const [helperText, setHelperText] = React.useState(false);
    const [appShortNameExistsText, setAppShortNameExistsText] = React.useState(false);
    const [validAppShortName, setValidAppShortName] = React.useState('');
    const appShortNameValidationApi = useMutation((payload) => axios.post(endPoints.ssoOkta.appShortNameValidation, payload));
    const getappShortNameApi = useMutation((payload) => axios.post(endPoints.ssoOkta.appShortName, payload));
    const debouncedInputValue = useDebounce(validAppShortName, 500);
    const debouncedApplicationNameValue = useDebounce(order?.applicationName, 500);

    const setAppShortName = (appShortName = null) => {
        setOrder({
            ...order,
            appShortName,
        });
    };

    React.useEffect(() => {
        if (appShortNameValidationApi?.isSuccess) {
            if (appShortNameValidationApi?.isSuccess) {
                setAppShortName(order?.appShortName);
                setAppShortNameExist(false);
                setAppShortNameExistsText(false);
            } else {
                setAppShortNameExist(true);
                setAppShortNameExistsText(true);
            }
        }
    }, [appShortNameValidationApi?.isSuccess]);

    React.useEffect(() => {
        if (
            debouncedInputValue &&
            order?.applicationName &&
            appNameValid &&
            order?.applicationCI &&
            getappShortNameApi?.data?.data == null &&
            !getappShortNameApi?.isLoading &&
            getappShortNameApi?.isSuccess
        ) {
            const payload = {
                applicationName: order?.applicationName,
                applicationShortName: debouncedInputValue,
                applicationService: order?.applicationCI,
            };
            appShortNameValidationApi?.mutate(payload);
        }
    }, [debouncedInputValue, order?.applicationName, order?.applicationCI]);
    React.useEffect(() => {
        if (getappShortNameApi?.data?.data) {
            setOrder({
                ...order,
                appShortName: getappShortNameApi?.data?.data,
            });
        }
    }, [getappShortNameApi?.isSuccess]);
    React.useEffect(() => {
        if (debouncedApplicationNameValue && order?.applicationCI && appNameValid && order?.applicationName) {
            const payload = {
                applicationName: order?.applicationName,
                applicationService: order?.applicationCI,
            };
            getappShortNameApi.mutate(payload);
        }
    }, [order?.applicationCI, order?.appService, debouncedApplicationNameValue, appNameValid, order?.applicationName]);

    React.useEffect(() => {
        if (
            order?.appShortName &&
            order?.appShortName !== null &&
            order?.appShortName !== '' &&
            order?.appShortName !== undefined &&
            !/^[a-zA-Z0-9]{2,8}$/.test(order?.appShortName)
        ) {
            setHelperText(true);
            setAppShortNameVaild(false);
        } else {
            setValidAppShortName(order?.appShortName);
            setHelperText(false);
            setAppShortNameVaild(true);
        }
    }, [order?.appShortName]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.appShortName) ||
            helperText ||
            (appShortNameValidationApi?.error && appShortNameValidationApi?.error?.length)
        ) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.appShortName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appShortName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="app-short-name-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Application Short Name *"
                error={helperText || appShortNameExistsText}
                helperText={
                    (helperText && (
                        <span>The &apos;ApplicationShortName&apos; must consist of alphanumeric characters and have a length between 2 and 8 characters.</span>
                    )) ||
                    (appShortNameExistsText && <span>App short name already exists.</span>)
                }
                value={order?.appShortName || ''}
                onChange={(e) => {
                    setAppShortName(e.target.value);
                }}
                disabled={!order?.applicationName || !appNameValid || getappShortNameApi?.data?.data}
                className={validateInput()}
            />
        </Box>
    );
};

export default ApplicationShortName;
