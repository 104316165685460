import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { serviceList, sandboxServices, basicServices, intermediateServices, advancedServices } from 'pages/project-onboarding/utils/constants';

export const getServiceName = (service) => {
    switch (service) {
        case 'projectAdmin':
            return 'Project Admin Members';
        case 'boards':
            return 'Board';
        case 'repos':
            return 'Repos';
        case 'pipelines':
            return 'Pipelines';
        case 'sonarQube':
            return 'SonarQube';
        case 'artifactory':
            return 'Artifactory (Coming soon in CMP)';
        case 'securityTools':
            return 'Fortify/Snyk - SAST';
        case 'testplans':
            return 'Test Plan';
        default:
            return 'Board';
    }
};

export const getServiceVal = (service, serviceResources) => {
    switch (service) {
        case 'projectAdmin':
            return serviceResources?.isProjectAdmin;
        case 'boards':
            return serviceResources?.isBoard;
        case 'repos':
            return serviceResources?.isRepos;
        case 'pipelines':
            return serviceResources?.isPipelines;
        case 'sonarQube':
            return serviceResources?.isSonarQube;
        case 'artifactory':
            return serviceResources?.isArtifactory;
        case 'securityTools':
            return serviceResources?.isSecurityTools;
        case 'testplans':
            return serviceResources?.isTestPlan;
        default:
            return false;
    }
};

const getSonarQubeValidationStatus = (order) => {
    if (order?.isADOProject === 'yes') {
        if (order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) {
            return false;
        }
        if (order?.onboardMembers && order?.sonarQubeUserList?.length === 0) {
            return false;
        }
        if (order?.onboardRepositories && order?.sonarQubeCodeRepoList?.some((repo) => repo?.repo === null || repo?.repo === '')) {
            return false;
        }

        return !Object.values({
            onboardServiceAccount: order?.onboardServiceAccount,
            onboardRepositories: order?.onboardRepositories,
            onboardMembers: order?.onboardMembers,
        }).every((value) => value === false);
    }
    return order?.isADOProject === 'no' && order?.sonarQubeUserList?.length > 0;
};

export const getBadgeColor = (service, order) => {
    switch (service) {
        case 'projectAdmin':
            return order?.adminAccessMemberList?.length > 0;
        case 'boards':
            return order?.adminAccessMemberList?.length > 0;
        case 'sonarQube':
            return getSonarQubeValidationStatus(order);
        case 'artifactory':
            return order?.progLanguage && order?.packageType && order?.buildTool && order?.artifactoryUserList?.length > 0;
        case 'securityTools':
            return order?.teamName && order?.applicationUrl && order?.fsMemberList?.length > 0;
        case 'testplans':
            return order?.hCode && order?.testPlanUserList?.length > 0;
        default:
            return false;
    }
};

export const getExistingServices = (projectDetails) => {
    const existingServices = [];
    let existingAdminMemberCount = 0;
    Object.keys(projectDetails || {})?.forEach((key) => {
        if (projectDetails[key] !== null && projectDetails[key] !== 0) {
            if (key === 'projectAdmin' && projectDetails?.projectAdmin?.memberList?.length > 0) {
                existingAdminMemberCount = projectDetails[key]?.memberList?.length;
            }
            existingServices.push(key);
        }
    });
    const existingServicesResult =
        existingServices?.includes('projectAdmin') && existingAdminMemberCount < 5 ? existingServices?.filter((z) => z !== 'projectAdmin') : existingServices;

    return existingServicesResult;
};

const getDisabled = (service, availableService, order) => {
    let isDisabled = false;
    let disableServices = [];
    if (order?.adoProjectType === 'Basic') {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? [...difference(serviceList, basicServices), ...basicServices?.filter((z) => availableService?.includes(z))]
                : [...difference(serviceList, basicServices)];
    }
    if (order?.adoProjectType === 'Intermediate') {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? [...difference(serviceList, intermediateServices), ...intermediateServices?.filter((z) => availableService?.includes(z))]
                : [...difference(serviceList, intermediateServices)];
    }
    if (order?.adoProjectType === 'Advanced') {
        disableServices =
            order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                ? [...advancedServices?.filter((z) => availableService?.includes(z))]
                : [...difference(serviceList, advancedServices)];
    }
    if (disableServices?.length > 0) {
        disableServices?.forEach((item) => {
            if (item === service) isDisabled = true;
        });
    } else if (order?.isADOProject === 'no') {
        isDisabled = false;
    }
    return isDisabled;
};

export const getServiceDisabled = (service, availableService, order) => {
    let isDisabled = false;
    if (order?.adoPlatform === 'Sandbox/Personal') {
        if (!sandboxServices?.includes(service)) {
            isDisabled = true;
        }
    } else if (order?.adoPlatform === 'Production') {
        if (order?.adoProjectType) {
            isDisabled = getDisabled(service, availableService, order);
        } else isDisabled = true;
    } else isDisabled = true;
    return isDisabled;
};

export const getServiceChecked = (service, availableService, order) => {
    let checkedService = [];
    if (
        (order?.isADOProject === 'yes' || (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)) &&
        order?.adoProjectType
    ) {
        if (order?.adoProjectType === 'Basic') {
            checkedService = [...intersection(availableService, basicServices)];
        }
        if (order?.adoProjectType === 'Intermediate') {
            checkedService = [...intersection(availableService, intermediateServices)];
        }
        if (order?.adoProjectType === 'Advanced') {
            checkedService = [...intersection(availableService, advancedServices)];
        }
    }
    return checkedService?.includes(service);
};

export const getModalTitle = (isOpen) => {
    let modalTitle = '';
    if (isOpen?.isProjectAdminOpen) modalTitle = 'Project Admin Members';
    else if (isOpen?.isFortifySnykOpen) modalTitle = 'Fortify / Snyk - SAST';
    else if (isOpen?.isTestPlanOpen) modalTitle = 'Test Plan';
    else if (isOpen?.isSonarQubeOpen) modalTitle = 'SonarQube';
    else if (isOpen?.isArtifactoryOpen) modalTitle = 'Artifactory';

    return modalTitle;
};

export const getOnCloseConfigData = (lastModifiedConfigData) => {
    return {
        adminAccessMember: lastModifiedConfigData?.adminAccessMember,
        adminAccessMemberDetails: lastModifiedConfigData?.adminAccessMemberDetails,
        adminAccessMemberList: lastModifiedConfigData?.adminAccessMemberList,
        teamName: lastModifiedConfigData?.teamName,
        applicationUrl: lastModifiedConfigData?.applicationUrl,
        securityTool: lastModifiedConfigData?.securityTool,
        fsMember: lastModifiedConfigData?.fsMember,
        fsMemberDetails: lastModifiedConfigData?.fsMemberDetails,
        fsMemberList: lastModifiedConfigData?.fsMemberList,
        securityToolCodeRepoList: lastModifiedConfigData?.securityToolCodeRepoList,
        hCode: lastModifiedConfigData?.hCode,
        hCodeDetails: lastModifiedConfigData?.hCodeDetails,
        testPlanUser: lastModifiedConfigData?.testPlanUser,
        testPlanUserDetails: lastModifiedConfigData?.testPlanUserDetails,
        testPlanUserList: lastModifiedConfigData?.testPlanUserList,
        sonarQubeUser: lastModifiedConfigData?.sonarQubeUser,
        sonarQubeUserDetails: lastModifiedConfigData?.sonarQubeUserDetails,
        sonarQubeUserList: lastModifiedConfigData?.sonarQubeUserList,
        progLanguage: lastModifiedConfigData?.progLanguage,
        progLanguageDetails: lastModifiedConfigData?.progLanguageDetails,
        buildTool: lastModifiedConfigData?.buildTool,
        buildToolDetails: lastModifiedConfigData?.buildToolDetails,
        packageType: lastModifiedConfigData?.packageType,
        packageTypeDetails: lastModifiedConfigData?.packageTypeDetails,
        artifactoryUser: lastModifiedConfigData?.artifactoryUser,
        artifactoryUserDetails: lastModifiedConfigData?.artifactoryUserDetails,
        artifactoryUserList: lastModifiedConfigData?.artifactoryUserList,
        isConfigureTouched: lastModifiedConfigData?.isConfigureTouched,
    };
};

export const getLastModifiedConfigData = (order) => {
    return {
        adminAccessMember: order?.adminAccessMember,
        adminAccessMemberDetails: order?.adminAccessMemberDetails,
        adminAccessMemberList: order?.adminAccessMemberList,
        sonarQubeUser: order?.sonarQubeUser,
        sonarQubeUserDetails: order?.sonarQubeUserDetails,
        sonarQubeUserList: order?.sonarQubeUserList,
        progLanguage: order?.progLanguage,
        progLanguageDetails: order?.progLanguageDetails,
        buildTool: order?.buildTool,
        buildToolDetails: order?.buildToolDetails,
        packageType: order?.packageType,
        packageTypeDetails: order?.packageTypeDetails,
        artifactoryUser: order?.artifactoryUser,
        artifactoryUserDetails: order?.artifactoryUserDetails,
        artifactoryUserList: order?.artifactoryUserList,
        teamName: order?.teamName,
        applicationUrl: order?.applicationUrl,
        securityTool: order?.securityTool,
        securityToolCodeRepoList: order?.securityToolCodeRepoList,
        fsMember: order?.fsMember,
        fsMemberDetails: order?.fsMemberDetails,
        fsMemberList: order?.fsMemberList,
        hCode: order?.hCode,
        hCodeDetails: order?.hCodeDetails,
        testPlanUser: order?.testPlanUser,
        testPlanUserDetails: order?.testPlanUserDetails,
        testPlanUserList: order?.testPlanUserList,
        isConfigureTouched: order?.isConfigureTouched,
    };
};

export const getMemberAccessForPreview = (roleData) => {
    const roleMap = {};
    const updateRole = (person, roleName) => {
        const { gpid } = person;
        if (!roleMap[gpid]) {
            roleMap[gpid] = { ...person };
            Object.keys(roleData).forEach((role) => {
                roleMap[gpid][role] = false;
            });
        }
        roleMap[gpid][roleName] = true;
    };
    Object.entries(roleData).forEach(([role, data]) => {
        data.forEach((person) => updateRole(person, role));
    });
    return Object.values(roleMap);
};

export const getRepoName = (name) => {
    const url = 'https://dev.azure.com/PepsiCoIT/';
    return `${url}${name}/_git/`;
};
