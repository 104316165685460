import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Stack, Box } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import Empty from 'components/empty';
import { selectedIdsState, inventoryTypeState, toggleDetailModal, totalCountState, sspSubmitOrder } from 'pages/inventory-report/store';
import inventoryStyle from 'pages/inventory-report/style';
import { resetPageFilter } from 'pages/inventory-report/utils';

const ListHeader = React.lazy(() => import('pages/inventory-report/list/list-header'));
const InventoryList = React.lazy(() => import('pages/inventory-report/list/inventory-list'));

const InventoryListPage = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const resetInventoryType = useResetRecoilState(inventoryTypeState);
    const resetSelectedIds = useResetRecoilState(selectedIdsState);
    const resetTotalCount = useResetRecoilState(totalCountState);
    const resetToggleDetailModal = useResetRecoilState(toggleDetailModal);
    const reset = () => {
        resetInventoryType();
        resetSelectedIds();
        resetTotalCount();
        resetToggleDetailModal();
        setOrder({ ...order, ...resetPageFilter });
    };
    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
    }, []);
    return (
        <>
            <Helmet>
                <title>Inventory Report - CMP</title>
            </Helmet>
            <Suspense
                fallback={
                    <Stack sx={inventoryStyle?.loadingStack}>
                        <Empty color="#000000" isLoading />
                    </Stack>
                }
            >
                <Box sx={inventoryStyle?.wrapper} data-testid="inventory-index">
                    <ListHeader />
                    <InventoryList />
                </Box>
            </Suspense>
        </>
    );
};
export default InventoryListPage;
