import { selector } from 'recoil';
import { orderPayload } from './index';

export const addSourceFileNamePattern = selector({
    key: 'mdt_addSourceFileNamePattern',
    get: ({ get }) => get(orderPayload).sourceFileNamePatternList,
    set: ({ set, get }, count) => {
        const currentSourceFileNamePattern = get(orderPayload).sourceFileNamePatternList;
        let sourceFileNamePatternList = [null];
        sourceFileNamePatternList = [...currentSourceFileNamePattern, ...Array(count.count).fill({ fileName: null })];

        set(orderPayload, {
            ...get(orderPayload),
            sourceFileNamePatternList,
        });
    },
});

export const removeSourceFileNamePattern = selector({
    key: 'mdt_removeSourceFileNamePattern',
    get: ({ get }) => get(orderPayload).sourceFileNamePatternList,
    set: ({ set, get }, adGroupToRemove) => {
        const currentSourceFileNamePattern = get(orderPayload).sourceFileNamePatternList;
        const sourceFileNamePatternToRemoveIndex = currentSourceFileNamePattern.findIndex((val) => val === adGroupToRemove);
        const sourceFileNamePatternList = [
            ...currentSourceFileNamePattern.slice(0, sourceFileNamePatternToRemoveIndex),
            ...currentSourceFileNamePattern.slice(sourceFileNamePatternToRemoveIndex + 1),
        ];
        set(orderPayload, {
            ...get(orderPayload),
            sourceFileNamePatternList,
        });
    },
});

export const updateSourceFileNamePattern = selector({
    key: 'mdt_updateSourceFileNamePattern',
    get: ({ get }) => get(orderPayload).sourceFileNamePatternList,
    set: ({ set, get }, params) => {
        const currentSourceFileNamePatternList = get(orderPayload).sourceFileNamePatternList;
        const updatedSourceFileNamePattern = { ...currentSourceFileNamePatternList[params.indexVal], fileName: params.fileName };
        const sourceFileNamePatternList = [
            ...currentSourceFileNamePatternList.slice(0, params?.indexVal),
            updatedSourceFileNamePattern,
            ...currentSourceFileNamePatternList.slice(params?.indexVal + 1),
        ];
        set(orderPayload, { ...get(orderPayload), sourceFileNamePatternList });
    },
});
