export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const deploymentModel = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPaasHost?.purchaseModel || null;
    const deploymentType = data?.itemDetail?.catalogService?.catalogConfig?.deploymentType || null;
    const sku = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase?.sku || null;
    const azureSqlPaasHost = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPaasHost || null;
    const elastic = data?.itemDetail?.catalogService?.catalogConfig?.elasticPool || null;
    // const azureSqlPurchase = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: entityDetails?.costCenter,
            projectName: entityDetails?.project,
        },
        tagsQc: { ...data?.itemDetail?.catalogService?.catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: data?.itemDetail?.catalogService?.catalogConfig?.billingEntity || null,
        costCenter: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            costCenterDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!entityDetails?.costCenter && { costCenterDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        serverCollation: data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase?.collation || null,
        logicalHostExist: azureSqlPaasHost?.isLogicalHostExists ? 'Yes' : 'No',

        zoneRedundancy: data?.itemDetail?.catalogService?.catalogConfig?.zoneRedundancy === true ? 'yes' : 'no',
        logicalHost: azureSqlPaasHost?.name || null,
        sqlDatabaseName: azureSqlPaasHost?.dbName || null,
        deploymentType: deploymentType === 'Elastic Pool' ? 'Azure SQL Elastic Pool' : deploymentType || null,
        ...(deploymentType === 'Elastic Pool' && { elasticPoolExist: elastic?.isExistingPool ? 'Yes' : 'No', elasticPoolName: elastic?.name || '' }),
        // eslint-disable-next-line
        deploymentModel: deploymentModel === 'DTU' ? 'DTU Based' : deploymentModel === 'vCore' ? 'VCore Based' : null,
        deploymentModelDetails: null,
        serviceTiers: deploymentModel === 'DTU' ? `${sku?.name}-${sku?.capacity} ${deploymentModel}` : sku?.tier || null,
        ...(deploymentModel === 'vCore' && { vCoresCount: sku?.capacity }), // vCore
        storageSize: data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase?.maxSizeBytes || null,
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (data = null, catalogServiceId = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    const deploymentModel = catalogConfig?.azureSqlPaasHost?.purchaseModel || null;
    const deploymentType = catalogConfig?.deploymentType || null;
    const sku = catalogConfig?.azureSqlPurchase?.sku || null;
    const azureSqlPaasHost = catalogConfig?.azureSqlPaasHost || null;
    const elastic = catalogConfig?.elasticPool || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: catalogConfig?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        costCenter: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            costCenterDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { costCenterDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        serverCollation: catalogConfig?.azureSqlPurchase?.collation || null,
        logicalHostExist: azureSqlPaasHost?.isLogicalHostExists ? 'Yes' : 'No',
        zoneRedundancy: catalogConfig?.zoneRedundancy === true ? 'yes' : 'no',
        logicalHost: azureSqlPaasHost?.name || null,
        sqlDatabaseName: azureSqlPaasHost?.dbName || null,
        deploymentType: deploymentType === 'Elastic Pool' ? 'Azure SQL Elastic Pool' : deploymentType || null,
        ...(deploymentType === 'Elastic Pool' && { elasticPoolExist: elastic?.isExistingPool ? 'Yes' : 'No', elasticPoolName: elastic?.name || '' }),
        // eslint-disable-next-line
        deploymentModel: deploymentModel === 'DTU' ? 'DTU Based' : deploymentModel === 'vCore' ? 'VCore Based' : null,
        deploymentModelDetails: null,
        serviceTiers: deploymentModel === 'DTU' ? `${sku?.name}-${sku?.capacity} ${deploymentModel}` : sku?.tier || null,
        ...(deploymentModel === 'vCore' && { vCoresCount: sku?.capacity }), // vCore
        storageSize: catalogConfig?.azureSqlPurchase?.maxSizeBytes || null,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = (data = null, cloudService = null) => {
    const currentComponent = data?.landingZoneDetails?.config?.components?.filter((component) => component?.service === cloudService);
    return {
        appProfile: data?.appProfile,
        appProfileDetails: data?.appProfileDetails,
        entityDetails: data?.entityDetails,
        billingEntity: data?.entityDetails?.billingEntity || null,
        costCenter: data?.entityDetails?.financialDetails || null,
        ...(data?.entityDetails?.financialDetails && {
            costCenterDetails: { label: data?.entityDetails?.financialDetails, projectName: data?.entityDetails?.projectName },
        }),
        ...(!data?.entityDetails?.financialDetails && { costCenterDetails: null }),
        sector: data?.sector,
        environment: data?.environment,
        region: data?.region || null,
        landingZoneDetails: {
            id: currentComponent?.[0]?.landingzoneId || null,
        },
    };
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    sectorDetail: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    serverCollation: null,
    sqlDatabaseName: '',
    deploymentType: null,
    logicalHostExist: null,
    logicalHost: null,
    elasticPoolExist: null,
    elasticPoolName: '',
    geoReplication: true,
    deploymentModel: null,
    deploymentModelDetails: null,
    serviceTiers: null,
    serviceTiersDetails: null,
    type: null,
    storageSize: null,
    storageSizeDetails: null,
    vCoresCount: null,
    vCoreDetails: null,
    tags: [],
    tagsQc: null,
    estimatedCost: resetCostEstimateDetails,
    defaultTags: [],
    costCenter: null,
    costCenterDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    envDetails: null,
    hoursOfOperation: null,
    hoursOfOperationDetail: null,
    validationStatus: 'clearValidation',
    isTouched: false,
    zoneRedundancy: 'no',
};

export const resetForQuickConfig = {
    environment: null,
    region: null,
    regionDisplayName: null,
    serverCollation: null,
    sqlDatabaseName: '',
    deploymentType: null,
    logicalHostExist: null,
    logicalHost: null,
    elasticPoolExist: null,
    elasticPoolName: '',
    geoReplication: true,
    deploymentModel: null,
    deploymentModelDetails: null,
    serviceTiers: null,
    serviceTiersDetails: null,
    type: null,
    storageSize: null,
    storageSizeDetails: null,
    vCoresCount: null,
    vCoreDetails: null,
    tags: [],
    defaultTags: [],
    envDetails: null,
    estimatedCost: resetCostEstimateDetails,
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    iszoneRedundancyModal: false,
};

export const getDefaultDBName = (order) => {
    let sqlDbValue = `SQL${order?.sector.toLowerCase()}${order?.appProfile.substring(0, 3)}`;
    if (order?.environment.includes('Disaster Recovery')) {
        sqlDbValue = `${sqlDbValue}dr`;
    }
    if (order?.environment.includes('Preprod')) {
        sqlDbValue = `${sqlDbValue}pp`;
    }
    if (order?.environment.includes('Production')) {
        sqlDbValue = `${sqlDbValue}p`;
    }
    if (order?.environment.includes('Development')) {
        sqlDbValue = `${sqlDbValue}d`;
    }
    if (order?.environment.includes('QA')) {
        sqlDbValue = `${sqlDbValue}q`;
    }
    if (order?.environment.includes('TST')) {
        sqlDbValue = `${sqlDbValue}t`;
    }
    return sqlDbValue.toUpperCase();
};

export const getDefaultHostName = (order) => {
    let logicalHostValue = `pep${order?.sector.toLowerCase()}${order?.appProfile.substring(0, 3)}1`;
    if (order?.environment.includes('Disaster Recovery')) {
        logicalHostValue = `${logicalHostValue}dr`;
    }
    if (order?.environment.includes('Preprod')) {
        logicalHostValue = `${logicalHostValue}pp`;
    }
    if (order?.environment.includes('Production')) {
        logicalHostValue = `${logicalHostValue}p`;
    }
    if (order?.environment.includes('Development')) {
        logicalHostValue = `${logicalHostValue}d`;
    }
    if (order?.environment.includes('QA')) {
        logicalHostValue = `${logicalHostValue}q`;
    }
    if (order?.environment.includes('TST')) {
        logicalHostValue = `${logicalHostValue}t`;
    }
    return logicalHostValue.toLowerCase();
};
