/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import Modal from 'components/modal';
import { sspSubmitOrder, toggleSspModels, isSkuConfigFullfilled } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import Form from 'pages/provisioning/day2-operation/components/Form/index';
import { isSkuFormFilled } from 'pages/provisioning/day2-operation/utils';

const ConfigSkuModal = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [lastModifiedBp, setLastModifiedBp] = React.useState(undefined);
    const [, setIsBackendPoolsConfig] = useRecoilState(isSkuConfigFullfilled);
    const reset = () => {
        setIsOpen({ ...isOpen, isSkuModalOpen: false });
        setOrder({
            ...order,
            ...lastModifiedBp,
        });
    };

    React.useEffect(() => {
        if (isOpen?.isSkuModalOpen) {
            setLastModifiedBp({
                skuConfig: order?.skuConfig,
                maxIops: order?.maxIops,
                ram: order?.ram,
                dataDiskSupport: order?.dataDiskSupport,
                premiumDiskSupport: order?.premiumDiskSupport,
                cost: order?.cost,
            });
        }
    }, [isOpen?.isSkuModalOpen]);

    return (
        <Modal title="Config SKU" handleClose={() => reset()} open={isOpen.isSkuModalOpen}>
            <Box sx={ProvisioningStyles.modalBox.wrapper}>
                <Box sx={ProvisioningStyles.modalBox.contentWrapper}>
                    <Grid container spacing={3.125} pt={3.125} pb={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Stack direction="column" spacing={2}>
                                        <Form.Sku />
                                        <Form.MaxIOPS />
                                        <Form.Ram />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="column" spacing={2}>
                                        <Form.DataDiskSupport />
                                        <Form.PremiumDiskSupport />
                                        <Form.Cost />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="footer" sx={ProvisioningStyles.modalBox.actionWrapper}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isSkuModalOpen: false });
                            setIsBackendPoolsConfig(true);
                        }}
                        disabled={isSkuFormFilled({ order })}
                        data-testid="save"
                    >
                        SAVE
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.modalBox.cancelBtn}
                        onClick={() => reset()}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel"
                    >
                        CANCEL
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfigSkuModal;
