/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { profileForm } from '../../../store';
import { CssTextField } from '../../style';

const BillingEntities = () => {
    const [form, setForm] = useRecoilState(profileForm);
    const [options, setOptions] = React.useState([]);
    const setBillingEntity = (billingEntity, billingEntityDetails) => {
        setForm({
            ...form,
            billingEntity,
            billingEntityDetails,
        });
    };
    const billingEntityApi = useMutation(() => axios.get(`${endPoints.ssp.vm.billingEntities}`));
    const seldValueExistsInOptions = (billingEntityValue) => options.find((option) => billingEntityValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setBillingEntity(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (billingEntityApi?.isSuccess) {
            setOptions(billingEntityApi?.data?.data?.companies.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [billingEntityApi?.isSuccess]);

    React.useEffect(() => {
        billingEntityApi.mutate();
        // eslint-disable-next-line
    }, []);
    return (
        <Box bgcolor="white">
            <Select
                key="billingEntity"
                value={form?.billingEntity || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={billingEntityApi.isLoading}
                isLoading={billingEntityApi.isLoading}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Billing Entity"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(BillingEntities);
