/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';

const Capabilities = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setCapabilities = (capabilities, capabilitiesDetail) => {
        setOrder({ ...order, capabilities, capabilitiesDetail, modifyService: null, modifyServiceDetail: null });
    };
    const capabilitiesApi = useMutation(() => axios.get(`${endPoints.ssp.day2OperationPass.capabilities}`));
    const seldValueExistsInOptions = (capabilitiesValue) => options.find((option) => capabilitiesValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setCapabilities(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (capabilitiesApi?.isSuccess) {
            if (capabilitiesApi?.data?.data) setOptions(capabilitiesApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [capabilitiesApi?.isSuccess]);

    React.useEffect(() => {
        capabilitiesApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.capabilities && order?.isTouched) setIsTouched(true);
    }, [order?.capabilities, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="capabilities"
                label="Capabilities *"
                value={order?.capabilities || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={capabilitiesApi.isLoading}
                isLoading={capabilitiesApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Capabilities);
