import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isRecipientUrlValid } from 'pages/sso-okta/store';
import { urlRegex } from 'pages/sso-okta/utils/constant';
import HelpImg from 'pages/provisioning/_components/helpImg';

export const RecipientUrl = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setRecipientVaild] = useRecoilState(isRecipientUrlValid);
    const [helperText, setHelperText] = React.useState(false);

    const setRecipientUrl = (recipientUrl = null) => {
        setOrder({
            ...order,
            recipientUrl,
        });
    };

    React.useEffect(() => {
        if (
            order?.recipientUrl &&
            order?.recipientUrl !== null &&
            order?.recipientUrl !== '' &&
            order?.recipientUrl !== undefined &&
            !urlRegex.test(order?.recipientUrl)
        ) {
            setHelperText(true);
            setRecipientVaild(false);
        } else {
            setHelperText(false);
            setRecipientVaild(true);
        }
    }, [order?.recipientUrl]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.recipientUrl) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.recipientUrl && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.recipientUrl, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-url-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Recipient URL *"
                error={helperText}
                helperText={helperText && <span>Recipient URL is invalid.</span>}
                value={order?.recipientUrl || ''}
                onChange={(e) => setRecipientUrl(e.target.value)}
                className={validateInput()}
                data-testid="RecipientURLText"
            />
            <HelpImg
                title={
                    <Box sx={{ whiteSpace: 'pre' }}>
                        <Box>The location where the application may present the SAML assertion. </Box>
                        <Box>This is often same as Single sign-on URL.</Box>
                    </Box>
                }
                maxWidth="600px"
            />
        </Box>
    );
};

export default RecipientUrl;
