import { atom } from 'recoil';

export const toggleSspModels = atom({
    key: 'couchbase-toggle-Models',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isDataDiskOpen: false,
        isBucketNamesOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'couchbase-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'couchbase-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'couchbase-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'couchbase-order',
    default: {
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        environment: null,
        appTier: null,
        appTierCode: null,
        region: null,
        regionDisplayName: null,
        landingZone: null,
        landingZoneDetails: null,
        hCode: null,
        hCodeDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        sector: null,
        sectorDetails: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        cluster: null,
        dbVersion: null,
        dbVersionDetails: null,
        nodesCount: null,
        maxNodeCount: null,
        availabilityZoneOptions: [],
        nodes: [
            {
                catalogServiceCode: '',
                catalogType: '',
                cloudProvider: '',
                cloudService: '',
                osType: 'Linux',
                vmType: null,
                osTypeVersion: null,
                osTypeVersionDetail: null,
                osDiskType: null,
                osDiskTypeCode: null,
                diskTypeOptions: null,
                domain: null,
                haDeployment: null,
                availabilityZone: null,
                availabilitySet: null,
                dataDisksCount: null,
                maxDataDiskCount: null,
                dataDisks: [],
                samAccountId: null,
                timeZone: null,
                sslCertificate: 'no',
                quantity: 1,
                isDiskOpen: false,
                isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
                isConfigDiskFulfilled: false,
                isDataDiskLoaded: false,
                copyPrevChecked: false,
                serverRefresh: false,
                estimatedCost: {
                    details: [],
                    qty: null,
                    totalOneTimeCost: {
                        frequency: 'one time',
                        estCost: null,
                        currency: 'USD',
                    },
                    totalRecurringCost: {
                        frequency: 'monthly',
                        estCost: null,
                        currency: 'USD',
                    },
                    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
                },
            },
        ],
        serviceMappings: [
            {
                clusterService: 'data',
                memoryQuota: '',
                nodes: [],
                storagePath: '/opt/couchbase/var/lib/couchbase/data/data',
                isMemoryRequired: 'true',
                isServiceMappingTouched: false,
            },
        ],
        unassignedNodes: [],
        buckets: [
            {
                bucketType: null,
                bucketNames: [{ name: '' }],
                bucketMemSize: '',
                isBucketTouched: false,
            },
        ],
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'couchbase-env-Details',
    default: {
        Approval_Manager: '---',
        Project_Name: '---',
        ASR: '---',
        Enable_Acceleration_Network: '---',
    },
});

export const costEstimateState = atom({
    key: 'couchbase-costEstimateState',
    default: null,
});

export const isLoadingCostApiState = atom({
    key: 'couchbase-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isValidCluster = atom({
    key: 'couchbase-isValidCluster',
    default: false,
});

export const duplicateClusterService = atom({
    key: 'couchbase-duplicateClusterService',
    default: false,
});

export const isInvalidMemoryQuota = atom({
    key: 'couchbase-isInvalidMemoryQuota',
    default: false,
});

export const duplicateBucketType = atom({
    key: 'couchbase-duplicateBucketType',
    default: false,
});
