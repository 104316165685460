import React from 'react';
import { Box, Badge, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { toggleSspModels, isConfigureTagsFullfilled } from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import Btn from 'components/button';
import Form from 'pages/provisioning/day2-operation-paas/components/Form';

const ConfigureTags = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [isTagsConfig] = useRecoilState(isConfigureTagsFullfilled);
    const getVariant = () => {
        if (isTagsConfig === false) return 'dot';
        return 'standard';
    };
    return (
        <>
            <Stack direction="row" spacing={1} my={2} sx={ProvisioningStyles.configure.stack}>
                <Box sx={ProvisioningStyles.configure.boxWrapper}>
                    <Form.ModifyService />
                </Box>
                <Box sx={ProvisioningStyles.configure.btnWrapper}>
                    <Badge sx={ProvisioningStyles.configure.badgeWidth} color="error" variant={getVariant()}>
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            onClick={() => setIsOpen({ ...isOpen, isConfigTagsModalOpen: true })}
                            size="small"
                            sx={ProvisioningStyles.configure.configureBtn}
                            variant="contained"
                            color="cmpPrimary"
                        >
                            Configure *
                        </Btn>
                    </Badge>
                </Box>
            </Stack>
        </>
    );
};

export default ConfigureTags;
