/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import ProvisioningStyles from 'pages/provisioning/storage/style';

const LandingZones = ({ index, appProfileName }) => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);

    const [options, setOptions] = React.useState([]);
    const landingZoneApi = useMutation((payload) => axios.post(`${endPoints.landingZones.landingZones}`, payload));
    const setLandingZone = (landingZone, landingZoneDetails) => {
        const form = { ...landZoneForm };
        try {
            const result = form.components.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = { ...obj, landingZone, landingZoneDetails };
                }
                return obj;
            });
            form.components = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }

        setLandingZoneForm(form);
    };
    const resetOptions = () => {
        setOptions([]);
    };

    async function setPreconfig() {
        if (landZoneForm?.components?.[index].landingZoneDetails) {
            const checkExist = await options.find((option) => landZoneForm?.components?.[index].landingZoneDetails?.id === option?.data?.id);
            if (checkExist) {
                await setLandingZone(checkExist?.value, checkExist?.data);
            }
        }
    }
    const handleOnSelectChange = (selected) => {
        setLandingZone(selected?.value, selected?.data);
    };
    React.useEffect(() => {
        if (landingZoneApi?.isSuccess) {
            setOptions(landingZoneApi?.data?.data?.landingZones?.map((app) => ({ label: app.label, value: app.label, data: app })));
        }
    }, [landingZoneApi?.isSuccess]);

    React.useEffect(() => {
        if (options && !landZoneForm?.components?.[index].landingZone && landZoneForm?.components?.[index].landingZoneDetails) {
            setPreconfig();
        }
    }, [options, landZoneForm?.components?.[index].landingZone]);

    React.useEffect(() => {
        resetOptions();
        if (
            appProfileName &&
            landZoneForm.environment &&
            landZoneForm?.regionDetails?.value &&
            landZoneForm?.components?.[index]?.service &&
            landZoneForm?.components?.[index]?.provider
        ) {
            landingZoneApi.mutate({
                appProfileName,
                environment: landZoneForm.environment,
                region: landZoneForm?.regionDetails?.value,
                cloudProvider: landZoneForm?.components?.[index]?.provider,
                cloudService: landZoneForm?.components?.[index]?.service,
            });
        }
    }, [
        appProfileName,
        landZoneForm.environment,
        landZoneForm?.regionDetails?.value,
        landZoneForm?.components?.[index]?.provider,
        landZoneForm?.components?.[index]?.service,
        lzModal?.lzDetails,
    ]);

    React.useEffect(() => {
        if (landZoneForm?.components?.[index]?.landingZone === undefined) {
            const connections = landZoneForm?.connections?.map((item) => {
                if (item?.targetComponentName === landZoneForm?.components?.[index]?.service) {
                    return { ...item, connectionType: null };
                }
                return item;
            });
            setLandingZoneForm({ ...landZoneForm, connections });
        }
    }, [landZoneForm?.components?.[index]?.landingZone]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <Select
                key="landingZoneDetails"
                label={landZoneForm?.components?.[index]?.optional ? 'Landing Zone' : 'Landing Zone*'}
                value={landZoneForm?.components?.[index]?.landingZone || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disableClearable={false}
                disabled={landingZoneApi.isLoading}
                isLoading={landingZoneApi.isLoading}
                renderOption={(props, option) => (
                    <Box component="div" sx={{ display: 'flex' }} {...props}>
                        <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box component="div" sx={{ fontWeight: 'Medium' }}>
                                {option.label}
                            </Box>
                            <Box component="div" sx={{ fontSize: '12px' }}>
                                {option?.data?.description}
                            </Box>
                        </Box>
                    </Box>
                )}
            />
        </Stack>
    );
};

LandingZones.propTypes = {
    index: PropTypes.number,
    appProfileName: PropTypes.string,
};

LandingZones.defaultProps = {
    index: 0,
    appProfileName: '',
};

export default React.memo(LandingZones);
