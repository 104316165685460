import React from 'react';
import Modal from 'components/modal';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toggleCostModals, costSummaryData } from 'pages/cart/store';
import { cartStyle } from 'pages/cart/style';
import CostAccordian from './CostAccordian';

const CostDetails = () => {
    const [modal, setModal] = useRecoilState(toggleCostModals);
    const cartdata = useRecoilValue(costSummaryData);

    const handleCloseModal = () => {
        setModal({ ...modal, isCost: { costType: '', isCostOpen: false } });
    };
    return (
        <Modal
            title={modal?.isCost?.costType?.toLowerCase() === 'one time' ? 'One Time Cost Details' : 'Recurring Cost Details'}
            handleClose={handleCloseModal}
            open={modal?.isCost?.isCostOpen}
            width="800px"
        >
            <Box sx={{ px: 2 }}>
                <Grid container spacing={1}>
                    {cartdata?.cartSummary?.map((data, idx) => (
                        <Grid item xs={12} key={`costDetail${idx}`}>
                            <Typography sx={cartStyle?.CostDetails?.application}>{data?.application}</Typography>
                            {data?.items?.map((item, itemIdx) => (
                                <CostAccordian item={item} modal={modal} defaultExpanded={itemIdx === 0 && idx === 0} key={`item${itemIdx}`} />
                            ))}
                            {idx !== cartdata?.cartSummary?.length - 1 && <Divider sx={{ my: 1 }} />}
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Modal>
    );
};

CostDetails.propTypes = {};

CostDetails.defaultProps = {};

export default React.memo(CostDetails);
