/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select, { CssTextField } from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { toggleCreateLandingZone, landingZoneForm } from 'pages/app-profiles/store';

const DnsZoneEnable = ({ index }) => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);

    const setDnsZone = (dnsZone = null) => {
        const form = { ...landZoneForm };
        try {
            const result = form.privateEndPoints.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = { ...obj, dnsZone: dnsZone !== '' || dnsZone !== null ? dnsZone : null };
                }
                return obj;
            });
            form.privateEndPoints = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    const seldValueExistsInOptions = (value) => options.find((option) => value === option?.label);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setDnsZone(selected?.label);
        } else {
            setDnsZone();
        }
    };

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const dnsZoneEnable = options.find((value) => lzModal?.lzDetails?.config?.privateEndPoints[index]?.dnsZone === value?.label);
            if (dnsZoneEnable) setDnsZone(dnsZoneEnable?.label);
        }
        if (options?.length && options?.length === 1) {
            handleOnSelectChange(options[0]);
        }
    }, [options]);

    React.useEffect(() => {
        setOptions([
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
        ]);
    }, []);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Select
                    key="DnsZoneEnable"
                    value={landZoneForm?.privateEndPoints?.[index]?.dnsZone}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    disableClearable={false}
                    renderInput={(params) => (
                        <CssTextField
                            {...params}
                            label="Inteagrate with DNS Zone"
                            placeholder="Inteagrate with DNS Zone"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                        />
                    )}
                />
                <InfoImg title="Type  Endpoint Name" />
            </Stack>
        </>
    );
};
DnsZoneEnable.propTypes = {
    index: PropTypes.number,
};

DnsZoneEnable.defaultProps = {
    index: 0,
};

export default React.memo(DnsZoneEnable);
