import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import HelpImg from 'pages/project-onboarding/components/helpImg';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const ADOProjectType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm] = useRecoilState(validOnboardingForm);
    const [options, setOptions] = React.useState([]);

    const adoProjectTypeApi = useMutation(() => axios.get(endPoints.day2Operation.ProjectOnboarding.adoProjectType));

    const setADOProjectType = (adoProjectType) => {
        setOrder({
            ...order,
            adoProjectType,
            ...(((order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
                (order?.isADOProject === 'no' && order?.isClarityId === 'yes' && order?.clarityDetails?.devopsProject === null)) && {
                serviceResources: {
                    ...order.serviceResources,
                    isProjectAdmin: false,
                    isBoard: false,
                    isRepos: false,
                    isPipelines: false,
                    isSonarQube: false,
                    isArtifactory: false,
                    isSecurityTools: false,
                    isTestPlan: false,
                },
            }),
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (adoProjectType) => options.find((option) => adoProjectType === option.value);

    const handleOnSelectChange = (selected) => setADOProjectType(selected.value);

    React.useEffect(() => {
        if (adoProjectTypeApi?.isSuccess) {
            setOptions(adoProjectTypeApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [adoProjectTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.adoProjectType) {
            setOrder({ ...order, adoProjectType: options?.[0].value });
        }
        if (options && options?.length && order?.adoProjectType) {
            const adoProjectType = isExistsInOptions(order?.adoProjectType);
            setOrder({ ...order, adoProjectType: adoProjectType?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        adoProjectTypeApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.adoProjectType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.adoProjectType, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="adoProjectType-autocomplete"
                key="adoProjectType"
                label="ADO Project Type *"
                value={order?.adoProjectType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    adoProjectTypeApi.isLoading ||
                    order?.adoPlatform !== 'Production' ||
                    (order?.adoPlatform === 'Production' && !order?.projectName && !order?.existingProjectName) ||
                    (order?.adoPlatform === 'Production' && validForm?.isProjectNameExists)
                }
                isLoading={adoProjectTypeApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg
                maxWidth="500px"
                title={
                    <>
                        <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                            <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                            Please select the appropriate option for your use case:
                                        </Typography>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                            <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Basic:</b> This includes a board for requirement management.
                                        </Typography>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                            <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Intermediate:</b> It comprises a board for requirement
                                            management along with repositories for code version control.
                                        </Typography>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                            <span style={{ fontSize: '8px' }}>&#9899;</span>
                                            <b> Advanced:</b> This comprehensive option includes a requirement board, repositories for code version control, a
                                            pipeline for build and deployment, a test plan, and tools such as SonarQube, Artifactory, Fortify, and Snyk for
                                            security analysis.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

export default React.memo(ADOProjectType);
