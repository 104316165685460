export const resetCostEstimateDetails = {
    details: [],
    totalOneTimeCost: {
        item: 'Total One Time',
        frequency: 'One Time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'yearly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'The License cost for safe in myPAM per user per year is $350 and cost is not estimated in CMP for existing user with safe details.',
};
export const resetOrder = {
    adGroup: null,
    businessApplication: null,
    businessApplicationDetails: null,
    environment: null,
    envShortName: null,
    sector: null,
    isNewADGroup: null,
    AppShortName: null,
    applicationName: null,
    adGroupType: null,
    adGroupTypeDetails: null,
    groupOwner: null,
    groupOwnerDetails: null,
    appService: null,
    members: null,
    mypamsafeDetails: [],
    nonSafeDetailsGpid: [],
    estimatedCost: resetCostEstimateDetails,
    isTouched: false,
};
