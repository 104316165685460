import React from 'react';
import Form from 'pages/app-profiles/landing-zones-new/components/Form';
import { Grid } from '@mui/material';
// import LandingZoneStyle from 'pages/app-profiles/landing-zones/LandingZoneStyle';

const ALBFields = () => (
    <>
        <Grid item xs={6}>
            {' '}
            <Form.Environments />
        </Grid>
        {/* <Grid item xs={6}>
            {' '}
            <Lz.AppTier />
        </Grid> */}
        <Grid item xs={6}>
            {' '}
            <Form.Region />
        </Grid>
        <Grid item xs={6}>
            <Form.Subscription />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.ResourceGroup />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.VNet />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.Subnet />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.Sku />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.Type />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.AppName />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.LandingZoneLabel />
        </Grid>
        <Grid item xs={6}>
            {' '}
            <Form.LandingZoneDesc />
        </Grid>
    </>
);

export default ALBFields;
