import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import riStyle from 'pages/reference-implementation/Style';

const ComponentCard = ({ componentName, imageUrl, isSelected, onClick }) => {
    const cardStyles = {
        ...riStyle?.componentSelectionModal?.componentCardWrapper,
        padding: isSelected ? 'none' : '2px',
        border: isSelected ? '2px solid #0047BA' : 'none',
        borderRadius: isSelected ? '6px' : 'none',
    };

    return (
        <Box sx={cardStyles} onClick={onClick}>
            <Box sx={riStyle?.componentSelectionModal?.catalogImg}>
                <img src={imageUrl} alt={componentName} style={{ width: '50px', height: '50px' }} />
            </Box>
            <Box sx={riStyle?.componentSelectionModal?.componentWrapper}>
                <Box component="span" sx={riStyle?.componentSelectionModal?.componentName} data-testid={componentName}>
                    {componentName}
                </Box>
            </Box>
        </Box>
    );
};

ComponentCard.propTypes = {
    componentName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default React.memo(ComponentCard);
