import React from 'react';
import { Box, Checkbox, FormControlLabel, InputLabel, Grid, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const OnboardQuestion = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const handleChange = (e, type) => {
        if (type === 'repos') {
            setOrder({
                ...order,
                onboardRepositories: !order?.onboardRepositories,
                sonarQubeCodeRepoList: order?.onboardRepositories ? [{ repo: '', isRepoTouched: false }] : order?.sonarQubeCodeRepoList,
            });
        }
        if (type === 'members') {
            setOrder({
                ...order,
                onboardMembers: !order?.onboardMembers,
                sonarQubeUserList: order?.onboardMembers ? [] : order?.sonarQubeUserList,
            });
        }
        if (type === 'serviceAccount') {
            setOrder({
                ...order,
                onboardServiceAccount: !order?.onboardServiceAccount,
                serviceAccount: null,
                isServiceAccountExists: order?.onboardServiceAccount ? null : 'no',
            });
        }
    };

    return (
        <>
            <Typography mb={2} mt={1}>
                What would you like to Onboard in SonarQube? *
            </Typography>
            <Box display="flex" flexDirection="row" marginTop="-20px" justifyContent="start" gap={8}>
                <Box>
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'serviceAccount')} checked={order?.onboardServiceAccount} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                    Service account
                                </Grid>
                            </InputLabel>
                        }
                    />
                </Box>{' '}
                {order?.isADOProject === 'yes' && (
                    <Box>
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => handleChange(e, 'repos')} checked={order?.onboardRepositories} />}
                            label={
                                <InputLabel htmlFor="short-description">
                                    <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                        Repos
                                    </Grid>
                                </InputLabel>
                            }
                        />
                    </Box>
                )}
                <Box>
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'members')} checked={order?.onboardMembers} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                    Members
                                </Grid>
                            </InputLabel>
                        }
                    />
                </Box>
            </Box>
        </>
    );
};

export default React.memo(OnboardQuestion);
