import React from 'react';
import { Stack, FormControl, Box, FormControlLabel } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI001/store';
import { RIStyles, CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';

const BusinessAppServiceRadioBtn = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const setBusinessAppServiceRadioBtn = (businessAppService) => {
        setOrder({
            ...order,
            businessAppService,
            businessApp: null,
            businessAppDetails: null,
            appService: null,
            appOwner: null,
            appOwnerDetails: null,
        });
    };
    const handleOnSelectChange = (selected) => {
        setBusinessAppServiceRadioBtn(selected);
    };
    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={RIStyles?.form?.radioLabel} id="performanceRadioGrp">
                    Do you have Business application & Application Service?
                </Box>
                <CustomRadioGroup
                    aria-label="BusinessAppServiceRadioBtn"
                    name="businessAppServiceRadioBtnGroup"
                    row
                    value={order?.businessAppService || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '60px',
                        }}
                        control={<CustomRadio />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default BusinessAppServiceRadioBtn;
