import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { endPoints } from 'chatbot/components/api/endpoints';
import { isResponseLoading } from 'chatbot/store';
import chatBotStyle from '../style';

const TopicList = (props) => {
    const { actions, state } = props;
    const [, setResponseLoading] = useRecoilState(isResponseLoading);
    const [isLoading, setIsLoading] = React.useState(false);

    const getResponse = async (data) => {
        try {
            setIsLoading(true);
            setResponseLoading(true);
            const responseData = await axios.post(
                `${endPoints.chat}/${state?.userId}/${state?.role}`,
                {
                    question: data,
                },
                {
                    headers: {
                        Authorization: `Bearer ${state?.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (responseData?.data?.[0]?.content) actions.topicAnswersAction(responseData?.data?.[0]);
        } catch (error) {
            actions.stopLoaderAction(error?.response?.data?.detail || 'Please try again later');
        } finally {
            setIsLoading(false);
            setResponseLoading(false);
        }
    };

    const handleClick = async (data) => {
        actions.userQuestionAction(data);
        actions.loaderAction();
        getResponse(data);
    };

    return (
        <div style={{ marginLeft: '32px', marginBottom: '20px' }}>
            {state?.topicList?.length > 0 &&
                state?.topicList?.map((item, index) => (
                    <li key={index} style={{ listStyleType: 'none', marginRight: '10px' }}>
                        <Button disabled={isLoading} sx={chatBotStyle.listButton} variant="outlined" onClick={() => handleClick(item)}>
                            {item}
                        </Button>
                    </li>
                ))}
        </div>
    );
};

TopicList.propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
};

TopicList.defaultProps = {
    actions: {},
    state: {},
};

export default TopicList;
