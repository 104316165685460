import React from 'react';
import { createCustomMessage, createClientMessage } from 'react-chatbot-kit';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { endPoints } from 'chatbot/components/api/endpoints';
import { isResponseLoading } from 'chatbot/store';
import Loader from './components/Loader';

const ActionProvider = ({ createChatBotMessage, setState, state, children }) => {
    const [, setResponseLoading] = useRecoilState(isResponseLoading);

    const updateMessages = () => {
        const messageArray = state?.messages;
        const feedbackArr = messageArray?.filter((item) => item?.type === 'feedback');
        const feedbackIdx = messageArray?.findIndex((item) => item?.type === 'feedback');

        if (feedbackIdx > -1 && feedbackArr?.length > 1) {
            messageArray.splice(feedbackIdx, 1);
            setState((prev) => ({
                ...prev,
                messages: messageArray,
            }));
        }

        const roleArray = messageArray?.filter((item) => item?.type === 'roles');
        const roleIdx = messageArray?.findIndex((item) => item?.type === 'roles');

        if (roleIdx > -1 && roleArray?.length > 1) {
            messageArray.splice(roleIdx, 1);
            setState((prev) => ({
                ...prev,
                messages: messageArray,
            }));
        }
    };

    React.useEffect(() => {
        updateMessages();
    }, [state]);

    const updateState = (message) => {
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };

    const displayErrorAction = (error) => {
        const message = createChatBotMessage(error);
        updateState(message);
    };

    const loaderAction = () => {
        const loading = createChatBotMessage(<Loader />);
        setState((prev) => ({ ...prev, messages: [...prev.messages, loading] }));
    };

    const stopLoaderAction = (resp) => {
        const message = createChatBotMessage(resp);
        setState((prev) => {
            const newPrevMsg = prev.messages.slice(0, -1);
            return { ...prev, messages: [...newPrevMsg, message] };
        });
    };

    const roleButtonAction = (roleValue, role) => {
        setState((prev) => ({
            ...prev,
            role: roleValue,
        }));
        const message1 = createClientMessage(role);
        updateState(message1);
        const message2 = createCustomMessage('topicBtn', 'topicButton');
        updateState(message2);
    };

    const topicButtonAction = (data) => {
        setState((prev) => ({
            ...prev,
            topicList: data,
        }));
        const message = createCustomMessage('topicList', 'topicList');
        setState((prev) => {
            const newPrevMsg = prev.messages.slice(0, -1);
            return { ...prev, messages: [...newPrevMsg, message] };
        });
    };

    const topicAnswersAction = (data) => {
        setState((prev) => ({
            ...prev,
            topicText: data,
        }));

        /* eslint-disable */
        const message1 = createChatBotMessage(<div dangerouslySetInnerHTML={{ __html: data?.content }} />);
        setState((prev) => {
            const newPrevMsg = prev.messages.slice(0, -1);
            return { ...prev, messages: [...newPrevMsg, message1] };
        });
        if (data?.is_greet === 0)
            setTimeout(() => {
                const message2 = createCustomMessage('feedback', 'feedback');
                updateState(message2);
            }, 1000);
    };

    const userQuestionAction = (ques) => {
        const message = createClientMessage(ques);
        updateState(message);
    };

    const getResponse = async (data) => {
        try {
            setResponseLoading(true);
            const responseData = await axios.post(
                `${endPoints.chat}/${state?.userId}/${state?.role}`,
                {
                    question: data,
                },
                {
                    headers: {
                        Authorization: `Bearer ${state?.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (responseData?.data?.[0]?.content) {
                const message1 = createChatBotMessage(<div dangerouslySetInnerHTML={{ __html: responseData?.data?.[0]?.content }} />);
                setState((prev) => {
                    const newPrevMsg = prev.messages.slice(0, -1);
                    return { ...prev, messages: [...newPrevMsg, message1] };
                });
                if (responseData?.data?.[0]?.is_greet === 0)
                    setTimeout(() => {
                        const message2 = createCustomMessage('feedback', 'feedback');
                        updateState(message2);
                    }, 1000);
            }
        } catch (error) {
            stopLoaderAction(error?.response?.data?.detail || 'Please try again later');
        } finally {
            setResponseLoading(false);
        }
    };

    const userInputResult = (param) => {
        if (state?.role !== null) {
            const loading = createChatBotMessage(<Loader />);
            setState((prev) => ({ ...prev, messages: [...prev.messages, loading] }));
            getResponse(param);
        } else {
            const message1 = createChatBotMessage(`Please select the role you are in to get started.`, {
                withAvatar: true,
            });
            updateState(message1);
            const message2 = createCustomMessage('role', 'roles');
            updateState(message2);
        }
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        roleButtonAction,
                        topicButtonAction,
                        displayErrorAction,
                        loaderAction,
                        stopLoaderAction,
                        topicAnswersAction,
                        userInputResult,
                        userQuestionAction,
                    },
                });
            })}
        </div>
    );
};

ActionProvider.propTypes = {
    createChatBotMessage: PropTypes.any,
    setState: PropTypes.any,
    state: PropTypes.object,
    children: PropTypes.any,
};

ActionProvider.defaultProps = {
    createChatBotMessage: undefined,
    setState: undefined,
    state: {},
    children: undefined,
};

export default ActionProvider;
