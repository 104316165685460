import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Select from 'components/select';
import Popper from '@mui/material/Popper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Badge, Button, Paper, Stack, Box } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { profileFilter } from 'pages/app-profiles/store';
import { appOptions, filterDefaultObj } from 'pages/app-profiles/utils';
import { scope } from 'utils/scope';
import { getUserScopes } from 'utils';
import styles, { ColorButton } from '../../style';

const AppFilter = () => {
    const profileTypeobj = useRecoilValue(profileFilter);
    const [filters, setFilter] = React.useState(filterDefaultObj());
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [, setappprofileFilter] = useRecoilState(profileFilter);
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleOnSelect = (name, selected) => {
        setFilter({ ...filters, [name]: selected });
    };
    const handleSubmitFilter = () => {
        const appliedFilters = {};
        if (filters) {
            Object.keys(filters)?.forEach((filter) => {
                if (filters[filter]) {
                    appliedFilters[filter] = filters[filter]?.value || filters[filter];
                }
            });
        }
        setappprofileFilter(appliedFilters);
        handleClick();
    };
    React.useEffect(() => {
        setFilter(profileTypeobj);
    }, [profileTypeobj]);
    return (
        <div>
            <Button
                sx={styles.filterButton}
                startIcon={
                    <Badge
                        variant="dot"
                        overlap="circular"
                        color={filters && Object.keys(filters)?.filter((key) => filters[key] !== null && filters[key] !== '')?.length ? 'error' : 'default'}
                    >
                        <FilterAltIcon color="inherit" size={36} />
                    </Badge>
                }
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={handleClick}
                data-testid="filter-btn"
            >
                Filter
            </Button>

            <Popper style={{ zIndex: '999999' }} id={id} open={open} anchorEl={anchorEl}>
                <Paper elevation={5} sx={styles.orderFilter.wrapper}>
                    <ArrowDropUpIcon sx={styles.orderFilter.arrow} />
                    <Box sx={styles.orderFilter.popper.container}>
                        <CloseIcon
                            fontSize="small"
                            sx={styles.orderFilter.closeIcon}
                            onClick={() => {
                                setFilter(filterDefaultObj());
                                setappprofileFilter(filterDefaultObj());
                                // handleFilter(filterDefaultObj);
                                handleClick();
                            }}
                            data-testid="close-icon"
                        />
                    </Box>
                    <Stack spacing={2} mb={2} pt={1}>
                        <Select
                            key="catlogservice"
                            value={filters?.profileType}
                            options={appOptions}
                            label="Select Scope"
                            disabled={!getUserScopes()?.includes(scope[2])}
                            handleOnSelect={(value) => handleOnSelect('profileType', value)}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <ColorButton disableElevation onClick={handleSubmitFilter} variant="contained" data-testid="apply-btn">
                            Apply
                        </ColorButton>
                    </Stack>
                </Paper>
            </Popper>
        </div>
    );
};

export default AppFilter;
