export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: { ...entityDetails },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null }, // TBD
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        capacity: catalogConfig?.capacity,
        capacityDetails: {
            storageDisplay: catalogConfig?.capacity || null,
            storageValue:
                catalogConfig?.capacity?.split(' ')?.[1] === 'TB'
                    ? Number(catalogConfig?.capacity?.split(' ')?.[0]) * 1024 * 1024 * 1024 * 1024
                    : Number(catalogConfig?.capacity?.split(' ')?.[0]) * 1024 * 1024 * 1024 || null,
        },
        redundancy: catalogConfig?.redundancyDetails?.displayName || null,
        redundancyDetails: {
            label: catalogConfig?.redundancyDetails?.displayName || null,
            value: catalogConfig?.redundancyDetails?.value || null,
            data: catalogConfig?.redundancyDetails,
        },
        isADSLEnabled: catalogConfig?.isAdls ? 'Yes' : 'No',
        premiumAccountType: catalogConfig?.premiumAccountType,
        premiumAccountTypeDetails: { label: catalogConfig?.premiumAccountType, value: catalogConfig?.premiumAccountType },
        accessTier: catalogConfig?.accessTier,
        accountName: catalogConfig?.accountName,
        containerName: catalogConfig?.containerName,
        performance: catalogConfig?.performance,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: { ...entityDetails },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || '-',
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null }, // TBD
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        accessTier: catalogConfig?.accessTier,
        accountName: catalogConfig?.accountName,
        containerName: catalogConfig?.containerName,
        performance: catalogConfig?.performance,
        capacity: catalogConfig?.capacity,
        capacityDetails: {
            storageDisplay: catalogConfig?.capacity || null,
            storageValue:
                catalogConfig?.capacity?.split(' ')?.[1] === 'TB'
                    ? Number(catalogConfig?.capacity?.split(' ')?.[0]) * 1024 * 1024 * 1024 * 1024
                    : Number(catalogConfig?.capacity?.split(' ')?.[0]) * 1024 * 1024 * 1024 || null,
        },
        redundancy: catalogConfig?.redundancyDetails?.displayName || null,
        redundancyDetails: {
            label: catalogConfig?.redundancyDetails?.displayName || null,
            value: catalogConfig?.redundancyDetails?.value || null,
            data: catalogConfig?.redundancyDetails,
        },
        isADSLEnabled: catalogConfig?.isAdls ? 'Yes' : 'No',
        premiumAccountType: catalogConfig?.premiumAccountType,
        premiumAccountTypeDetails: { label: catalogConfig?.premiumAccountType, value: catalogConfig?.premiumAccountType },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = (data = null, cloudService = null) => {
    const currentComponent = data?.landingZoneDetails?.config?.components?.filter((component) => component?.service === cloudService);

    return {
        appProfile: data?.appProfile,
        appProfileDetails: data?.appProfileDetails,
        entityDetails: data?.entityDetails,
        billingEntity: data?.entityDetails?.billingEntity || null,
        hCode: data?.entityDetails?.financialDetails || null,
        ...(data?.entityDetails?.financialDetails && {
            hCodeDetails: { label: data?.entityDetails?.financialDetails, projectName: data?.entityDetails?.projectName },
        }),
        ...(!data?.entityDetails?.financialDetails && { hCodeDetails: null }),
        sector: data?.sector,
        environment: data?.environment,
        region: data?.region || null,
        landingZoneDetails: {
            id: currentComponent?.[0]?.landingzoneId || null,
        },
    };
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    environment: null,
    capacity: '100 GB',
    capacityDetails: { storageDisplay: '100 GB', storageValue: 107374182400 },
    redundancy: null,
    redundancyDetails: null,
    premiumAccountType: null,
    premiumAccountTypeDetails: null,
    appTier: 'Storage Server',
    appTierCode: 'SS',
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    accessTier: 'Hot',
    accountName: '',
    containerName: '',
    hCode: null,
    hCodeDetails: null,
    performance: 'Standard',
    envDetails: null,
    tags: [],
    tagsQc: null,
    estimatedCost: resetCostEstimateDetails,
    validationStatus: 'clearValidation',
    isTouched: false,
};
export const resetForQuickConfig = {
    environment: null,
    capacity: '100 GB',
    capacityDetails: { storageDisplay: '100 GB', storageValue: 107374182400 },
    redundancy: null,
    redundancyDetails: null,
    premiumAccountType: null,
    premiumAccountTypeDetails: null,
    appTier: 'Storage Server',
    appTierCode: 'SS',
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    accessTier: 'Hot',
    accountName: '',
    performance: 'Standard',
    envDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
};

export const resetEnvironmentDetails = {
    Subscription: '-',
    Resource_Group: '-',
    Approval_Manager: '-',
    Subnet_Name: '-',
    Virtual_Network: '-',
    Project: '-',
    Routing_Preference: '-',
    EncryptionType: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
};
