import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import PreviewStyles from 'pages/provisioning/virtual-service/components/preview/style';

const CorsDetails = ({ details }) => {
    const location = useLocation();
    return (
        <>
            <Box display="flex" flexDirection="row">
                <Grid container py={1}>
                    {Object?.hasOwn(details, 'allowedMethods') && (
                        <Grid item xs={12} sm={6} md={location?.pathname?.includes('order') ? 6 : 4}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={PreviewStyles?.vsTypeHeaders}>Allowed Methods</th>
                                </tr>
                                {details?.allowedMethods?.map((method, k) => (
                                    <tr key={k}>
                                        <td style={PreviewStyles?.valueText}>{method}</td>
                                    </tr>
                                ))}
                            </table>
                        </Grid>
                    )}
                    {Object?.hasOwn(details, 'allowedHeaders') && (
                        <Grid item xs={12} sm={6} md={location?.pathname?.includes('order') ? 6 : 4}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={PreviewStyles?.vsTypeHeaders}>Allowed Headers</th>
                                </tr>
                                {details?.allowedHeaders?.map((header, k) => (
                                    <tr key={k}>
                                        <td style={PreviewStyles?.valueText}>{header}</td>
                                    </tr>
                                ))}
                            </table>
                        </Grid>
                    )}
                    {Object?.hasOwn(details, 'exposeHeaders') && (
                        <Grid item xs={12} sm={6} md={location?.pathname?.includes('order') ? 6 : 4}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={PreviewStyles?.vsTypeHeaders}>Expose Headers</th>
                                </tr>
                                {details?.exposeHeaders?.map((expHeader, k) => (
                                    <tr key={k}>
                                        <td style={PreviewStyles?.valueText}>{expHeader}</td>
                                    </tr>
                                ))}
                            </table>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={location?.pathname?.includes('order') ? 6 : 4}>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <th style={PreviewStyles?.vsTypeHeaders}>Allowed Origins</th>
                            </tr>
                            {details?.allowedOrigins?.map((origin, k) => (
                                <tr key={k}>
                                    <td style={PreviewStyles?.valueText}>{origin}</td>
                                </tr>
                            ))}
                        </table>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

CorsDetails.propTypes = {
    details: PropTypes.object,
};

CorsDetails.defaultProps = {
    details: {},
};

export default CorsDetails;
