import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import { isConfigureTagsFullfilled, sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import Btn from 'components/button';
import Select, { CssTextField } from 'components/select';
import {
    addModifyTag,
    removeModifyTag,
    updateModifyTag,
    updateModifyTagByIndex,
    validateModifyTag,
} from 'pages/provisioning/store/day2-operation-paas/selecters';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

const ConfigTagsModal = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setIsHPConfig] = useRecoilState(isConfigureTagsFullfilled);
    const [lastModifiedDDCount, setLastModifiedDDCount] = React.useState(undefined);
    const [lastModifiedDD, setLastModifiedDD] = React.useState(undefined);
    const addTag = useSetRecoilState(addModifyTag);
    const removeTag = useSetRecoilState(removeModifyTag);
    const validate = useSetRecoilState(validateModifyTag);
    const updateTag = useSetRecoilState(updateModifyTag);
    const updateTagIndex = useSetRecoilState(updateModifyTagByIndex);
    const reset = () => {
        setIsOpen({ ...isOpen, isConfigTagsModalOpen: false });
        setOrder({
            ...order,
            modifyTags: lastModifiedDD,
            modifyTagsCount: lastModifiedDDCount,
        });
        validate();
    };
    const changeSelection = (key, selected, index) => {
        updateTag({ key, selected, index });
    };
    const handleUpdateTagIndex = (key, selected, index) => {
        updateTagIndex({ key, selected, index });
    };
    const isFormValid = () => {
        let errorCount = 0;
        let isTagValid = false;
        if (order?.modifyTags?.length) {
            order?.modifyTags?.forEach((modifyTag) => {
                if (!(modifyTag?.name !== null && modifyTag?.value !== null)) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isTagValid = errorCount === 0;
        return isTagValid;
    };
    React.useEffect(() => {
        if (isOpen?.isConfigTagsModalOpen) {
            setLastModifiedDD(order?.modifyTags);
            setLastModifiedDDCount(order?.modifyTagsCount);
        }
    }, [isOpen?.isConfigTagsModalOpen]);
    return (
        <Modal title="Tags" handleClose={() => reset()} open={isOpen.isConfigTagsModalOpen}>
            <Box sx={ProvisioningStyles.modalBox.wrapper}>
                <Box sx={ProvisioningStyles.modalBox.contentWrapper}>
                    {order?.modifyTags?.map((modifyTag, index) => (
                        <React.Fragment key={index.toString()}>
                            <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                                <Box sx={{ width: '45%' }}>
                                    <CssTextField
                                        data-testid={`name${index.toString()}`}
                                        id="outlined-size-small"
                                        size="small"
                                        label="Name"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => {
                                            changeSelection('name', e.target.value, modifyTag);
                                        }}
                                        value={modifyTag?.name || ''}
                                        sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                        autoComplete="off"
                                        className={modifyTag?.name === '' || modifyTag?.name === null || modifyTag?.name === undefined ? 'validate' : ''}
                                    />
                                </Box>
                                <Box sx={{ width: '45%' }}>
                                    <Select
                                        key={`value${index.toString()}`}
                                        label="Value"
                                        value={modifyTag?.value || null}
                                        options={['azure-cloud-shell', 'azure-cloud-shell 2', 'azure-cloud-shell 3']}
                                        handleOnSelect={(value) => handleUpdateTagIndex('value', value, index)}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option?.toString()}
                                        isRequired
                                        sx={ProvisioningStyles.dataDiskModel.activeSelectField}
                                    />
                                </Box>
                                <Box sx={{ width: '10%' }}>
                                    <Box className="flex w-full ">
                                        {order.modifyTags.length > 1 && (
                                            <Box sx={ProvisioningStyles.dataDiskModel.deleteWrapper}>
                                                <FaTrashAlt
                                                    style={ProvisioningStyles.dataDiskModel.delete}
                                                    onClick={() => removeTag(modifyTag)}
                                                    className="w-7 h-7 text-red-base cursor-pointer"
                                                    data-testid={`remove-modifyTag-${index.toString()}`}
                                                />
                                            </Box>
                                        )}
                                        {index === order.modifyTags.length - 1 && order.modifyTags.length < order.maxModifyTagsCount && (
                                            <MdAdd
                                                style={ProvisioningStyles.dataDiskModel.add}
                                                onClick={() => addTag({ count: 1 })}
                                                className="w-7 h-7 text-blue-base cursor-pointer"
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </React.Fragment>
                    ))}
                </Box>
                <Box className="footer" sx={ProvisioningStyles.modalBox.actionWrapper}>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.modalBox.saveBtn}
                        variant="contained"
                        color="cmpPrimary"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isConfigTagsModalOpen: false });
                            setIsHPConfig(true);
                        }}
                        disabled={!isFormValid()}
                        data-testid="save"
                    >
                        SAVE
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.modalBox.cancelBtn}
                        onClick={() => reset()}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel"
                    >
                        CANCEL
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfigTagsModal;
