import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { landingZoneData } from 'pages/app-profiles/store';
import { CssTextField } from 'components/select';

const DestinationResource = ({ index }) => {
    const [landingZoneForm] = useRecoilState(landingZoneData);
    const [ShrinkField, setShrinkField] = React.useState(true);

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Destination Resource"
                    value={landingZoneForm?.connections?.[index]?.targetComponentName}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    disabled
                />
            </Stack>
        </>
    );
};

DestinationResource.propTypes = {
    index: PropTypes.number,
};

DestinationResource.defaultProps = {
    index: 0,
};

export default React.memo(DestinationResource);
