import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isAppNameValid } from 'pages/sso-okta/store';
// import HelpImg from 'pages/provisioning/_components/helpImg';
// import SsoOktaStyles from 'pages/sso-okta/style';

export const ApplicationName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setApplicationNameVaild] = useRecoilState(isAppNameValid);
    const [helperText, setHelperText] = React.useState(false);

    const setApplicationName = (applicationName = null) => {
        setOrder({
            ...order,
            applicationName: applicationName?.trimStart(),
            appShortName: null,
        });
    };

    React.useEffect(() => {
        if (
            order?.applicationName &&
            order?.applicationName !== null &&
            order?.applicationName !== '' &&
            order?.applicationName !== undefined &&
            !/^[a-zA-Z0-9_-\s()]{4,64}$/.test(order?.applicationName?.trimStart())
        ) {
            setHelperText(true);
            setApplicationNameVaild(false);
        } else {
            setHelperText(false);
            setApplicationNameVaild(true);
        }
    }, [order?.applicationName]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.applicationName) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.applicationName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-name-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Application Name *"
                error={helperText}
                helperText={helperText && <span>Application name is invalid.</span>}
                value={order?.applicationName || ''}
                onChange={(e) => setApplicationName(e.target.value)}
                className={validateInput()}
                onKeyDown={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (event.target.value || event.target.value !== '')) {
                        setOrder({
                            ...order,
                            appShortName: null,
                        });
                    }
                }}
            />
            {/* <HelpImg
                title={
                    <>
                        <Box sx={SsoOktaStyles.infoStyle.wrapper}>
                            <Box sx={SsoOktaStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={SsoOktaStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>
                                            <span style={SsoOktaStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                        <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>2) Min.: 4 chars to Max.: 64 chars</Typography>
                                        <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>
                                            3) Can contain underscore(_), hyphen(-) and parenthesis() as special characters
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={SsoOktaStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}> Example: CLOUD MARKETPLACE (CMP) NON-PROD</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            /> */}
        </Box>
    );
};

export default ApplicationName;
