import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { OrderPayload, isProjectIdValid, costEstimateState } from 'pages/reference-implementation/provisioning/RI002/store';
import { resetCostEstimateDetails } from 'pages/reference-implementation/provisioning/RI002/utils';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { RIStyles } from 'pages/reference-implementation/Style';

export const ProjectId = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [projectIdValid, setProjectIdVaild] = useRecoilState(isProjectIdValid);
    const [inValidText, setInValidText] = React.useState(false);
    const [idNotFoundText, setIdNotFoundText] = React.useState(false);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [, setProjectId] = React.useState('');
    const projectDetailsApi = useMutation(() => axios.get(`${endPoints.riServices.RI002.projectId?.replace('{projectId}', order?.projectId)}`));

    React.useEffect(() => {
        if (projectDetailsApi?.isSuccess) {
            if (projectDetailsApi?.data?.data) {
                setIdNotFoundText(false);
                setProjectIdVaild(true);
                setProjectId(order?.projectId);
                setOrder({
                    ...order,
                    projectDetails: projectDetailsApi?.data?.data || null,
                    sector: projectDetailsApi?.data?.data?.impactedSectors,
                    sponsor: projectDetailsApi?.data?.data?.portfolioManager,
                    businessUnit: projectDetailsApi?.data?.data?.businessUnit || null,
                    budgetCode: projectDetailsApi?.data?.data?.hyperionNumber || null,
                    budgetCodeDetails: { label: projectDetailsApi?.data?.data?.hyperionNumber },
                    dataClassification: null,
                    environment: null,
                    estimatedCost: null,
                });
                setCostEstimate(resetCostEstimateDetails);
            } else {
                setProjectIdVaild(false);
                setIdNotFoundText(true);
                setOrder({
                    ...order,
                    projectDetails: null,
                    sector: null,
                    businessUnit: null,
                    budgetCode: null,
                    budgetCodeDetails: null,
                    dataClassification: null,
                    environment: null,
                    estimatedCost: null,
                });
                setCostEstimate(resetCostEstimateDetails);
            }
        }
    }, [projectDetailsApi?.isSuccess]);

    const handleSearch = () => {
        if (!inValidText) {
            setOrder({
                ...order,
                projectDetails: null,
            });
            setCostEstimate(resetCostEstimateDetails);
            projectDetailsApi.mutate();
        }
    };

    const validateInput = () => {
        if (inValidText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (
            order?.projectId &&
            order?.projectId !== null &&
            order?.projectId !== '' &&
            order?.projectId !== undefined &&
            !/^PR[0-9]{6,8}$/.test(order?.projectId)
        ) {
            setInValidText(true);
            setIdNotFoundText(false);
        } else {
            setInValidText(false);
            setIdNotFoundText(false);
        }
    }, [order?.projectId]);

    const getText = () => {
        if (inValidText) return "Project Id must start with 'PR' followed by numbers within 6 to 8 chars.";
        if (idNotFoundText) return 'Project Id not found';
        return '';
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                data-testid="projectId"
                fullWidth
                label={<InputLabel label="Project ID" />}
                autoComplete="off"
                value={order?.projectId || ''}
                size="small"
                error={inValidText || idNotFoundText}
                helperText={getText()}
                onChange={(e) => {
                    setOrder({
                        ...order,
                        projectId: e.target.value?.toUpperCase(),
                        projectDetails: null,
                    });
                    setCostEstimate(resetCostEstimateDetails);
                }}
                onBlur={() => {
                    if (!projectIdValid) {
                        setOrder({
                            ...order,
                            projectId: null,
                        });
                    }
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <IconButton
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleSearch();
                                }}
                            >
                                <SearchIcon sx={RIStyles?.form?.searchIcon} />
                            </IconButton>
                        </>
                    ),
                }}
                className={validateInput()}
            />
        </Box>
    );
};

export default React.memo(ProjectId);
