import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { isRolesValid, OrderPayload } from 'pages/sso-okta/store';
import { ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';

export const DataClassification = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setRolesValid] = useRecoilState(isRolesValid);
    const [options, setOptions] = React.useState([]);
    const dataClassificationApi = useMutation(() => axios.get(`${endPoints.ssoOkta.dataClassification}`));

    const setDataClassification = (dataClassification) => {
        setRolesValid(false);
        setOrder({
            ...order,
            dataClassification,
            authorizationRolesRequired: 'no',
            isCertificateAvailable: 'no',
            ...(ROLE_TO_SHOW?.includes(dataClassification?.toLowerCase()) && { roles: [{ role: '' }] }),
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (dataClassifiation) => options.find((option) => dataClassifiation === option.value);

    const handleOnSelectChange = (selected) => setDataClassification(selected.value);

    React.useEffect(() => {
        if (dataClassificationApi?.isSuccess) {
            setOptions(
                dataClassificationApi?.data?.data
                    ?.map((app) => ({
                        label: app?.displayName,
                        value: app?.displayName,
                    }))
                    ?.filter((app) => !['public']?.includes(app?.label?.toLowerCase()))
            );
        }
    }, [dataClassificationApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.dataClassification) {
            setOrder({ ...order, dataClassification: options?.[0].value });
        }
        if (options && options?.length && order?.dataClassification) {
            const dataClassification = isExistsInOptions(order?.dataClassification);
            setOrder({ ...order, dataClassification: dataClassification?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        dataClassificationApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.dataClassification && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.dataClassification, order?.isTouched]);
    return (
        <Box display="flex" gap={1}>
            <Box display="flex" alignItems="center" gap={1} sx={{ width: '100%' }}>
                <Select
                    dataTestId="data-classification-autocomplete"
                    key="DataClassification"
                    label="Data Classification *"
                    value={order?.dataClassification || null}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    disabled={dataClassificationApi.isLoading}
                    isLoading={dataClassificationApi.isLoading}
                    isRequired={isTouched}
                />
            </Box>
        </Box>
    );
};

export default React.memo(DataClassification);
