import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, onboardAppServiceDialogPopup } from 'pages/onboard-app-service/store';
import { resetOrder } from 'pages/onboard-app-service/utils';
import OnboardAppServiceStyles from 'pages/onboard-app-service/style';

const OnboardAppServiceHeader = React.lazy(() => import('pages/onboard-app-service/onboardAppServiceHeader'));
const IndexForm = React.lazy(() => import('pages/onboard-app-service/IndexForm'));

const OnboardAppService = () => {
    const [, setOrder] = useRecoilState(OrderPayload);
    const [, setDialogPopup] = useRecoilState(onboardAppServiceDialogPopup);

    // const axios = useAxios();
    const handleReset = () => {
        setOrder(resetOrder);
        setDialogPopup(false);
    };

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => handleReset();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Onboard Application Service</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={OnboardAppServiceStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={OnboardAppServiceStyles.circularProgressColor} />
                    </Box>
                }
            >
                <>
                    <OnboardAppServiceHeader reset={() => handleReset()} />
                    <Grid item container spacing={2} data-testid="index-form">
                        <Grid item xs={12}>
                            <Grid display="flex" flexDirection="column" padding={4} gap={3}>
                                <IndexForm />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Suspense>
        </>
    );
};

export default OnboardAppService;
