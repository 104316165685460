/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';

const ModifyService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setModifyService = (modifyService, modifyServiceDetail) => {
        setOrder({ ...order, modifyService, modifyServiceDetail });
    };
    const modifyServiceApi = useMutation(() => axios.get(`${endPoints.ssp.day2OperationPass.modifyService}`));
    const seldValueExistsInOptions = (modifyServiceValue) => options.find((option) => modifyServiceValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setModifyService(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (modifyServiceApi?.data?.data)
            setOptions(
                modifyServiceApi?.data?.data
                    ?.filter((app) => app?.capability === order?.capabilities)
                    ?.map((app) => ({ label: app.name, value: app.name, data: app }))
            );
    }, [modifyServiceApi?.isSuccess, order?.capabilities]);

    React.useEffect(() => {
        modifyServiceApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.modifyService && order?.isTouched) setIsTouched(true);
    }, [order?.modifyService, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="modifyService"
                label="Modify Service *"
                value={order?.modifyService || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={modifyServiceApi.isLoading}
                isLoading={modifyServiceApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ModifyService);
