/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiInputLabel-shrink': {
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: '#27344B',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E9E9E9',
        },
    },
});

const MultiSelect = (props) => {
    const { options, label, value, handleOnSelect, disabled, isLoading, isRequired, closeOnSelect, onClosed, onBlured, onFocus, ...rest } = props;
    let inputRef;

    const validateInput = () => {
        if (isRequired) {
            return value === '' || value === null || value === undefined || value?.length === 0 || value === 0 || onFocus ? 'validate' : '';
        }
        return '';
    };

    React.useEffect(() => {
        if (onFocus && inputRef) {
            inputRef.focus();
        }
    }, [onFocus]);
    return (
        <Autocomplete
            data-testid="multi-select"
            multiple
            openOnFocus
            onClose={onClosed}
            size="small"
            sx={{
                background: '#FFFFFF',
            }}
            options={options}
            disableCloseOnSelect={closeOnSelect}
            disabled={disabled}
            value={value}
            onChange={(event, newValue, reason) => {
                handleOnSelect(newValue, reason);
            }}
            renderOption={(myprops, option, { selected }) => (
                <li {...myprops}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option?.value}
                </li>
            )}
            renderInput={(params) => (
                <CssTextField
                    {...params}
                    label={label}
                    inputRef={(input) => {
                        inputRef = input;
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading && <CircularProgress color="inherit" size={16} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    onBlur={onBlured}
                />
            )}
            className={validateInput()}
            {...rest}
        />
    );
};

MultiSelect.propTypes = {
    options: PropTypes.any,
    label: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    handleOnSelect: PropTypes.func,
    disableClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isRequired: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    onClosed: PropTypes.func,
    onBlured: PropTypes.func,
    onFocus: PropTypes.bool,
    // handleOpen: PropTypes.func,
};

MultiSelect.defaultProps = {
    options: [],
    label: '',
    value: undefined,
    handleOnSelect: undefined,
    disableClearable: true,
    disabled: false,
    isLoading: false,
    isRequired: false,
    closeOnSelect: true,
    onClosed: undefined,
    onBlured: undefined,
    onFocus: undefined,
    // handleOpen: undefined,
};

export default MultiSelect;
