import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box, TableRow } from '@mui/material';

const summaryStyle = {
    orderSummary: {
        table: {
            common: {
                minWidth: '650px',
                fontSize: '14px',
            },
            cell: {
                color: '#2b3a57',
            },
            row: {
                'td,th': {
                    color: '#003182',
                },
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            },
        },
    },
    gridLabel: { paddingLeft: (theme) => theme.spacing(4) },
    connectionHeader: {
        color: '#0047BA',
        fontSize: '16px',
        fontStyle: 'Stolzl',
        fontWeight: 'bold',
        paddingLeft: (theme) => theme.spacing(3),
        marginTop: (theme) => theme.spacing(1),
    },
    membersTable: { width: { lg: 750, xl: 750 } },
    virtualService: {
        orderItemId: { fontFamily: 'SansMedium', fontSize: '18px', letterSpacing: '0px', color: '#1378D4', marginBottom: '16px' },
        heading: { textAlign: 'left', fontFamily: 'SansBold', fontSize: '18px', letterSpacing: '-0.14px', color: '#000', marginBottom: '10px' },
        serviceNameKey: { textAlign: 'left', fontSize: '16px', color: '#000', fontFamily: 'SansMedium', letterSpacing: '0px' },
        serviceNameValue: { textAlign: 'right', fontSize: '16px', color: '#000', fontFamily: 'SansMedium', letterSpacing: '0px' },
        divider: { border: '1px solid #acacac', marginTop: '10px', marginBottom: '10px' },
    },
};
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.black.dark,
        fontSize: '16px',
        padding: '0px',
        border: 0,
        zIndex: 'auto',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        color: '#242424',
        borderSpacing: 0,
    },
}));

export const BoxWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 1,
    paddingBottom: 5,
}));

export const MembersStyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '13px',
        paddingLeft: 15,
        paddingTop: 8,
        paddingBottom: 8,
        border: 0,
        marginLeft: 4,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#000000',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: 'none',
        '&:first-of-type': {
            //   borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            // borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));

export const MembersStyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    paddingTop: '1px',
    borderTop: '1.5px solid #E8E8E8',
    // hide last border

    '&:last-child td, &:last-child th': {},
}));
export default summaryStyle;
