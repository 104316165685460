/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { isSubNetValid, isVnetValid, isChangeRequestNumberValid, modal, nsgOrder, jobId } from 'pages/provisioning/store/nsg-common-rule';
import ProvisioningStyles from 'pages/provisioning/aksns/style';
import Btn from 'components/button';
import { validateObject } from 'utils/validateObject';
import { getPayload } from 'pages/provisioning/nsg-common-rule/getPayload';

const SubmitRule = () => {
    const { catalogCode } = useParams();
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(nsgOrder);
    const [, setJobId] = useRecoilState(jobId);
    const [subNetValid] = useRecoilState(isSubNetValid);
    const [vNetValid] = useRecoilState(isVnetValid);
    const [requestNumberValid] = useRecoilState(isChangeRequestNumberValid);
    const [modalObj, setModal] = useRecoilState(modal);

    const handleSubmit = async () => {
        setLoading(true);
        const payload = await getPayload({ order, catalogCode });
        try {
            const validate = await validateObject({ data: payload, catalogCode });
            if (validate?.isValid) {
                const submitOrder = await axios.post(endPoints.ssp.nsg.submitRule, payload);
                if (submitOrder?.data) {
                    setJobId(submitOrder?.data?.job);
                    setModal({ ...modalObj, isSubmitRuleSuccess: true });
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };
    const isReadyToSubmit = () => !order?.vnet || !order?.subnet || !order?.requestNumber || subNetValid || vNetValid || requestNumberValid;

    const handleValidation = () => {
        if (!isReadyToSubmit()) {
            handleSubmit();
        } else {
            setOrder({ ...order, isTouched: true });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Btn
                    size="medium"
                    onClick={() => handleValidation()}
                    variant="contained"
                    color="cmpPrimary"
                    disabled={isLoading}
                    sx={ProvisioningStyles?.cartBtn}
                >
                    {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Rule'}
                </Btn>
            </Box>
        </>
    );
};

SubmitRule.propTypes = {};

SubmitRule.defaultProps = {};

export default React.memo(SubmitRule);
