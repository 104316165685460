/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const Sku = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setSku = (skuConfig, skuConfigDetail) => {
        setOrder({ ...order, skuConfig, skuConfigDetail, cost: '' });
    };
    const skuConfigApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.sku}`));
    const seldValueExistsInOptions = (skuConfigValue) => options.find((option) => skuConfigValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setSku(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (skuConfigApi?.isSuccess) {
            if (skuConfigApi?.data?.data) setOptions(skuConfigApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [skuConfigApi?.isSuccess]);

    React.useEffect(() => {
        skuConfigApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.skuConfig && order?.isTouched) setIsTouched(true);
    }, [order?.skuConfig, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <Select
                key="skuConfig"
                label="SKU *"
                value={order?.skuConfig || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={skuConfigApi.isLoading}
                isLoading={skuConfigApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="Premium SSD disks offer high-performance, low-latency disk support for I/O-intensive applications and production workloads. Standard SSD Disks are a cost effective storage option optimized for workloads that need consistent performance at lower IOPS levels. Use Standard HDD disks for Dev/Test scenarios and less critical workloads at lowest cost." />
        </Stack>
    );
};

export default React.memo(Sku);
