import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { costEstimateState, toggleSspModels, sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import CostDetails from 'pages/provisioning/_components/CostDetails';
import { getPayload } from 'pages/provisioning/couchbase/getPayload';

const ShowCostDetails = () => {
    const { catalogCode, catalogServiceId } = useParams();
    const [costEstimate] = useRecoilState(costEstimateState);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const order = useRecoilValue(sspSubmitOrder);

    return (
        <CostDetails
            type={modal?.isCost?.costType}
            isCostOpen={modal?.isCost?.isCostOpen}
            costEstimate={costEstimate}
            showInfo
            connections={getPayload({ order, catalogCode, catalogServiceId })?.catalogService?.catalogConfig?.components}
            catalogCode={order?.catalogCode}
            handleCloseModal={() => setModal({ ...modal, isCost: { costType: '', isCostOpen: false } })}
        />
    );
};

ShowCostDetails.propTypes = {};

ShowCostDetails.defaultProps = {};

export default ShowCostDetails;
