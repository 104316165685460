import React from 'react';
import AppLayout from 'layout';
import { RecoilRoot } from 'recoil';
import Empty from 'components/empty';
import { oktaConfig, queryClient } from 'config';
import { QueryClientProvider } from 'react-query';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter, Route, useHistory } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';

const AppWithOktaRouter = () => {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
    return (
        <Security oktaAuth={oktaConfig} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/login/callback">
                <LoginCallback />
                <Empty isLoading />
            </Route>
            <SecureRoute path="/" component={AppLayout} />
        </Security>
    );
};

const AppRouter = () => (
    <RecoilRoot>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AppWithOktaRouter />
            </BrowserRouter>
        </QueryClientProvider>
    </RecoilRoot>
);

export default AppRouter;
