import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Dialog, DialogTitle, DialogContent, CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import InfoTooltip from 'pages/reference-implementation/components/info-tooltip';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        overflow: 'visible',
        maxWidth: '90%',
        height: '600px',
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, pt: 1, pb: 0 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '-15px',
                        top: '-15px',
                        color: '#0047ba',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #0047ba',
                        '&:hover': {
                            backgroundColor: '#FFFFFF',
                        },
                    }}
                >
                    <CloseIcon sx={{ width: '0.8em', height: '0.8em' }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.element,
    onClose: PropTypes.func,
};

BootstrapDialogTitle.defaultProps = {
    children: <></>,
    onClose: () => null,
};

export default function ArchDialog({ isOpen, setOpen, imgUrl }) {
    return (
        <BootstrapDialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={isOpen}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)} />
            {/* <Box display="flex" alignItems="center">
                    <Typography sx={{ mr: 1, fontSize: 14, fontWeight: 900 }}> {title}</Typography>
                    <InfoTooltip
                        title={
                            <>
                                <a href={helpUrl} target="_blank" rel="noreferrer">
                                    Click here for further details
                                </a>
                            </>
                        }
                    />
                </Box> */}
            {/* </BootstrapDialogTitle> */}
            {/* <Divider /> */}
            <DialogContent>
                {!imgUrl && (
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    </Box>
                )}
                {imgUrl && <img src={imgUrl} alt="loading" width="100%" height="100%" />}
            </DialogContent>
        </BootstrapDialog>
    );
}

ArchDialog.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    imgUrl: PropTypes.string,
};

ArchDialog.defaultProps = {
    isOpen: false,
    setOpen: () => null,
    imgUrl: undefined,
};
