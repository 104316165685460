export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: entityDetails?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        subscription: catalogConfig?.subscription || null,
        subscriptionId: catalogConfig?.subscriptionId || null,
        databricksSubscription: catalogConfig?.databricksSubscription,
        databricksSubscriptionId: catalogConfig?.databricksSubscriptionId,
        resourceTypeDetails: { resourceTypeName: catalogConfig?.resourceType } || null,
        resource: catalogConfig?.resource || null,
        targetSubResource: catalogConfig?.targetSubResource,
        ...(catalogConfig?.privateEndpointType === 'Isolated' && { databricks: catalogConfig?.databricks }),
        endPointType: catalogConfig?.privateEndpointType || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        subscription: catalogConfig?.subscription,
        subscriptionId: catalogConfig?.subscriptionId,
        databricksSubscription: catalogConfig?.databricksSubscription,
        databricksSubscriptionId: catalogConfig?.databricksSubscriptionId,
        resourceTypeDetails: { resourceTypeName: catalogConfig?.resourceType } || null,
        resource: catalogConfig?.resource,
        endPointType: catalogConfig?.privateEndpointType,
        targetSubResource: catalogConfig?.targetSubResource,
        ...(catalogConfig?.privateEndpointType === 'Isolated' && { databricks: catalogConfig?.databricks }),
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include data processed (inbound/outbound), there will be additional charges applicable $0.01 per GB based on data usage.',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    environment: null,
    appTier: 'Azure Private Endpoint',
    appTierCode: 'PE',
    region: null,
    regionDisplayName: null,
    hCode: null,
    hCodeDetails: null,
    endPointType: null,
    endPointTypeDetails: null,
    resourceType: null,
    resourceTypeDetails: null,
    resource: null,
    resourceDetails: null,
    targetSubResource: null,
    subscription: null,
    subscriptionId: null,
    databricksSubscription: null,
    databricksSubscriptionId: null,
    envDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetForQuickConfig = {
    environment: null,
    appTier: 'Azure Private Endpoint',
    appTierCode: 'PE',
    region: null,
    regionDisplayName: null,
    hCode: null,
    hCodeDetails: null,
    endpointType: null,
    endpointTypeDetails: null,
    resourceType: null,
    resourceTypeDetails: null,
    resource: null,
    resourceDetails: null,
    targetSubResource: null,
    targetSubResourceDetails: null,
    subscription: null,
    subscriptionId: null,
    databricksSubscription: null,
    databricksSubscriptionId: null,
    databricks: null,
    envDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetEnvironmentDetails = {
    Approval_Manager: '-',
    Project: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isDiskOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
};

export const dtClassAllowResouces = ['Microsoft.DataFactory/factories', 'Microsoft.Synapse/workspaces'];
