import React from 'react';
import { Box } from '@mui/material';
import Select from 'components/select';
import { useRecoilState } from 'recoil';
import { orderPayload } from 'pages/mdt-onboarding/store';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';

const SourceValue = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [options, setOptions] = React.useState([]);

    const sourceValueApi = useMutation(() => axios.get(`${endPoints.mdt.Jwt.sourceValue}?environment=${order?.environment}&source=${order?.source}`));

    const setSourceValue = (sourceValue) => {
        setOrder({
            ...order,
            sourceValue,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sourceValue) => options.find((option) => sourceValue === option.value);

    const handleOnSelectChange = (selected) => setSourceValue(selected.value);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.sourceValue) {
            setOrder({ ...order, sourceValue: options?.[0].value });
        }
        if (options && options?.length && order?.sourceValue) {
            const sourceValue = isExistsInOptions(order?.sourceValue);
            setOrder({ ...order, sourceValue: sourceValue?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (sourceValueApi?.data) {
            setOptions(sourceValueApi?.data?.data?.map((item) => ({ label: item.sourceValue, value: item.sourceValue, data: item })));
        }
    }, [sourceValueApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.source && order?.environment) {
            resetOptions();
            sourceValueApi.mutate();
        }
    }, [order?.source, order?.environment]);

    React.useEffect(() => {
        if ((!order?.sourceValue, order?.isTouched)) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.isTouched, order?.sourceValue]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="sourcevalue-autocomplete"
                key="sourceValue"
                label="Source Value *"
                value={order?.sourceValue || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sourceValueApi.isLoading}
                isLoading={sourceValueApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="Please select valid source value." />
        </Box>
    );
};

export default SourceValue;
