/* eslint-disable import/named,react/no-danger */
import React from 'react';
import Modal from 'components/modal';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { currencySymbols } from 'utils';
import InfoTooltip from 'pages/reference-implementation/components/info-tooltip';
import { RecurringFrequency } from 'utils/costFrequency';
import CostInfo from './CostInfo';
import { Styles } from './style';

const CostDetails = ({ type, isCostOpen, costEstimate, handleCloseModal, showCostInfo }) => {
    const getNotes = (notes) => {
        return (
            <div>
                {notes?.map((item, i) => (
                    <ul key={i} style={Styles?.CostDetails?.noteList}>
                        <li style={Styles?.CostDetails?.recurringHtmlNote} dangerouslySetInnerHTML={{ __html: item }} />
                    </ul>
                ))}
            </div>
        );
    };
    return (
        <Modal
            title={type.toLowerCase() === 'one time' ? 'One Time Cost Details' : 'Estimated Recurring Cost Details'}
            handleClose={() => handleCloseModal()}
            open={isCostOpen}
            width="800px"
        >
            <Box sx={{ px: 2 }}>
                <Grid container spacing={2}>
                    {costEstimate?.details &&
                        costEstimate?.details
                            ?.filter(
                                (cost) =>
                                    cost?.frequency?.toLowerCase() === type.toLowerCase() ||
                                    (type.toLowerCase() === 'monthly' && RecurringFrequency?.includes(cost?.frequency?.toLowerCase()))
                            )
                            ?.map((value, i) => (
                                <React.Fragment key={i.toString()}>
                                    <Grid item xs={6}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={Styles?.CostDetails?.item}>{value?.item || value?.name} :</Box>
                                            {!showCostInfo && value?.notes && (
                                                <Box sx={{ marginLeft: 1, marginTop: 0.5 }}>
                                                    <InfoTooltip title={value?.notes} />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box sx={Styles?.CostDetails?.unit}>({value?.frequency})</Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={Styles?.CostDetails?.estWrapper}>
                                            <Box sx={Styles?.CostDetails?.colon}>&nbsp;</Box>
                                            <Box sx={Styles?.CostDetails?.estCost}>
                                                {(value?.['estimated-cost'] ?? value?.estCost)?.toString() && currencySymbols[value?.currency]}
                                                {((value?.['estimated-cost'] ?? value?.estCost)?.toString() &&
                                                    (value?.['estimated-cost'] ?? value?.estCost)?.toFixed(2)) ??
                                                    '---'}
                                            </Box>
                                            {showCostInfo && (value?.['estimated-cost'] || value?.estCost)?.toString() && value?.details && (
                                                <CostInfo costInfodata={value?.details} />
                                            )}
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            ))}
                </Grid>
                <Grid container spacing={2} sx={{ py: 1 }}>
                    {type === 'one time' && (
                        <>
                            <Grid item xs={6}>
                                <Box sx={Styles?.CostDetails?.labelStyle}>Total one time cost:</Box>
                                <Box sx={Styles?.CostDetails?.unit}>({costEstimate?.totalOneTimeCost?.frequency})</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={Styles?.CostDetails?.colon}>&nbsp;</Box>
                                    <Box sx={Styles?.CostDetails?.labelStyle}>
                                        {costEstimate?.totalOneTimeCost?.estCost?.toString() && currencySymbols[costEstimate?.totalOneTimeCost?.currency]}
                                        {(costEstimate?.totalOneTimeCost?.estCost?.toString() && costEstimate?.totalOneTimeCost?.estCost?.toFixed(2)) || '---'}
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    )}
                    {(type === 'monthly' || type === 'per rule') && (
                        <>
                            <Grid item xs={6}>
                                <Box sx={Styles?.CostDetails?.labelStyle}>Total recurring cost:</Box>
                                <Box sx={Styles?.CostDetails?.unit}>({costEstimate?.totalRecurringCost?.frequency})</Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={Styles?.CostDetails?.colon}>&nbsp;</Box>
                                    <Box sx={Styles?.CostDetails?.labelStyle}>
                                        {costEstimate?.totalRecurringCost?.estCost?.toString() && currencySymbols[costEstimate?.totalRecurringCost?.currency]}
                                        {(costEstimate?.totalRecurringCost?.estCost?.toString() && costEstimate?.totalRecurringCost?.estCost?.toFixed(2)) ||
                                            '---'}
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
                {type === 'monthly' && costEstimate?.notes?.length > 0 && (
                    <Grid item xs={12}>
                        <Box sx={Styles?.CostDetails?.recurringNote}>Note: {getNotes(costEstimate?.notes) || '---'}</Box>
                    </Grid>
                )}
            </Box>
        </Modal>
    );
};
CostDetails.propTypes = {
    type: PropTypes.string,
    isCostOpen: PropTypes.bool,
    costEstimate: PropTypes.any,
    handleCloseModal: PropTypes.func.isRequired,
    showCostInfo: PropTypes.bool,

    // catalogCode: PropTypes.string,
};

CostDetails.defaultProps = {
    isCostOpen: false,
    type: '',
    costEstimate: [],
    showCostInfo: false,

    // catalogCode: undefined,
};

export default React.memo(CostDetails);
