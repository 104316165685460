import { selector } from 'recoil';
import intersection from 'lodash/intersection';
import { sspSubmitOrder } from './index';

export const updateNodesDetails = selector({
    key: 'couchbase-updateNodeData',
    get: ({ get }) => get(sspSubmitOrder).nodes,
    set: ({ set, get }, nodesDataToUpdate) => {
        const currentNodes = get(sspSubmitOrder).nodes;
        if (nodesDataToUpdate.key === 'osTypeVersion') {
            const nodes = get(sspSubmitOrder).nodes?.map((item) => ({
                ...item,
                osTypeVersion: nodesDataToUpdate.selected.label,
                osTypeVersionDetail: nodesDataToUpdate.selected.data,
                catalogServiceCode: nodesDataToUpdate.selected.code,
                ...(nodesDataToUpdate.action === 'add' && { vmType: null, vmTypeDetails: null }),
            }));
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'osDiskDetail') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                osDiskType: nodesDataToUpdate.selected.osDiskType,
                osDiskTypeCode: nodesDataToUpdate.selected.osDiskTypeCode,
                diskTypeOptions: nodesDataToUpdate.selected.diskTypeOptions,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'serviceData') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                cloudProvider: nodesDataToUpdate.selected.cloudProvider,
                cloudService: nodesDataToUpdate.selected.cloudService,
                catalogType: nodesDataToUpdate.selected.catalogType,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        const getUniqueAvailabilityZones = ({ avZones }) => {
            try {
                return intersection(get(sspSubmitOrder)?.envDetails?.config?.availabilityZone, avZones)?.sort();
            } catch (e) {
                // eslint-disable-next-line
                console.log('Exception', e);
            }
            return [];
        };
        if (nodesDataToUpdate.key === 'vmType') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                vmType: nodesDataToUpdate.selected.label,
                vmTypeDetails: nodesDataToUpdate.selected.data,
                haDeployment: 'Availability Zones',
                availabilityZoneOptions: getUniqueAvailabilityZones({ avZones: nodesDataToUpdate.selected.data?.availabilityZones }),
                ...(nodesDataToUpdate.action === 'add' && { dataDisksCount: null, dataDisks: [], defaultDataDisks: [] }),
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'maxDataDiskCount') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                maxDataDiskCount: nodesDataToUpdate.selected,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'domain') {
            const nodes = get(sspSubmitOrder).nodes?.map((item) => ({
                ...item,
                domain: nodesDataToUpdate.selected,
            }));
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'dataDisks') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                dataDisksCount: nodesDataToUpdate?.selected?.length,
                dataDisks: nodesDataToUpdate.selected,
                defaultDataDisks: nodesDataToUpdate.selected,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'isDataDisk') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                isDataDisk: nodesDataToUpdate.selected,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'dataDisksCount') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                dataDisksCount: nodesDataToUpdate.selected,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'isConfigDiskFulfilled') {
            const updatedNode = {
                ...currentNodes[nodesDataToUpdate?.index],
                isConfigDiskFulfilled: nodesDataToUpdate.selected,
            };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'samAccountId') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], samAccountId: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'timeZone') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], timeZone: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'sslCertificate') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], sslCertificate: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'availabilityZone') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], availabilityZone: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'isDiskOpen') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], isDiskOpen: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'copyPrevChecked') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], copyPrevChecked: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
        if (nodesDataToUpdate.key === 'estimatedCost') {
            const updatedNode = { ...currentNodes[nodesDataToUpdate?.index], estimatedCost: nodesDataToUpdate.selected };
            const nodes = [...currentNodes.slice(0, nodesDataToUpdate?.index), updatedNode, ...currentNodes.slice(nodesDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
    },
});

export const copyPrevNodeDetails = selector({
    key: 'couchbase-copyPrevNodeDetails',
    get: ({ get }) => get(sspSubmitOrder).nodes,
    set: ({ set, get }, params) => {
        const currentNodes = get(sspSubmitOrder).nodes;
        if (params?.checkedVal) {
            const copy = JSON.parse(JSON.stringify(currentNodes[params.index - 1]));
            if (copy?.availabilityZone) copy.availabilityZone = null;
            const updatedNode = { ...currentNodes[params.index], ...copy };
            const nodes = [...currentNodes.slice(0, params?.index), updatedNode, ...currentNodes.slice(params?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        } else {
            const updatedNode = {
                ...currentNodes[params.index],
                vmType: null,
                osTypeVersion: currentNodes[0]?.osTypeVersion,
                osTypeVersionDetail: currentNodes[0]?.osTypeVersionDetail,
                domain: null,
                haDeployment: null,
                availabilityZone: null,
                availabilitySet: null,
                dataDisksCount: null,
                maxDataDiskCount: null,
                dataDisks: [],
                osDiskType: null,
                osDiskTypeCode: null,
                diskTypeOptions: null,
                samAccountId: null,
                timeZone: 'UTC',
                sslCertificate: 'no',
                quantity: 1,
                isDiskOpen: false,
                isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
                isConfigDiskFulfilled: false,
                isDataDiskLoaded: false,
                estimatedCost: {
                    details: [],
                    qty: null,
                    totalOneTimeCost: {
                        frequency: 'one time',
                        estCost: null,
                        currency: 'USD',
                    },
                    totalRecurringCost: {
                        frequency: 'monthly',
                        estCost: null,
                        currency: 'USD',
                    },
                    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
                },
            };
            const nodes = [...currentNodes.slice(0, params?.index), updatedNode, ...currentNodes.slice(params?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
    },
});

export const addDataDisk = selector({
    key: 'couchbase-addDataDisk',
    get: ({ get }) => get(sspSubmitOrder).nodes,
    set: ({ set, get }, params) => {
        const nodeDetails = get(sspSubmitOrder)?.nodes;
        const currentNodeList = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
        let updatedDataDiskList = [];
        updatedDataDiskList = [
            ...currentNodeList,
            ...Array(params.count).fill({
                diskSizeInGib: 32,
                diskType: ['Production'].includes(get(sspSubmitOrder).environment) ? 'Premium SSD' : 'Standard SSD',
                fsType: 'ext4',
                mountPoint: '',
            }),
        ];

        const nodeUpdatedDataDiskList = {
            ...nodeDetails[params?.indexVal],
            dataDisks: updatedDataDiskList,
            dataDisksCount: updatedDataDiskList.length,
            isConfigDiskFulfilled: false,
        };
        const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];

        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            nodes: updatedNodeDetails,
        });
    },
});

export const removeDataDisk = selector({
    key: 'couchbase-removeDataDisk',
    get: ({ get }) => get(sspSubmitOrder).nodes,
    set: ({ set, get }, params) => {
        const nodeDetails = get(sspSubmitOrder)?.nodes;
        const currentNodeList = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
        const updatedDataDiskList = [...currentNodeList?.slice(0, params.index), ...currentNodeList?.slice(params.index + 1)];
        const nodeUpdatedDataDiskList = {
            ...nodeDetails[params?.indexVal],
            dataDisks: updatedDataDiskList,
            dataDisksCount: updatedDataDiskList.length,
            isConfigDiskFulfilled: false,
        };
        const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            nodes: updatedNodeDetails,
        });
    },
});

export const removeDataDiskIndex = selector({
    key: 'couchbase-removeDataDiskIndex',
    get: ({ get }) => get(sspSubmitOrder).nodes,
    set: ({ set, get }, params) => {
        const nodeDetails = get(sspSubmitOrder)?.nodes;
        const currentDataDisks = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
        const updatedDataDiskList = [...currentDataDisks?.slice(0, params.diskLength), ...currentDataDisks?.slice(params.diskLength + 1)];
        const nodeUpdatedDataDiskList = {
            ...nodeDetails[params?.indexVal],
            dataDisks: updatedDataDiskList,
            dataDisksCount: updatedDataDiskList.length,
            isConfigDiskFulfilled: false,
        };
        const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            nodes: updatedNodeDetails,
        });
    },
});

export const updateDataDisksDetails = selector({
    key: 'couchbase-updateDataDisksDetails',
    get: ({ get }) => get(sspSubmitOrder).nodes,
    set: ({ set, get }, params) => {
        const nodeDetails = get(sspSubmitOrder)?.nodes;
        if (params.key === 'diskType') {
            const currentNodeList = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
            const updatedNode = { ...currentNodeList[params?.index], diskType: params.selected.name, diskTypeCode: params.selected.code };
            const updatedNodeList = [...currentNodeList.slice(0, params?.index), updatedNode, ...currentNodeList.slice(params?.index + 1)];
            const nodeUpdatedDataDiskList = { ...nodeDetails[params?.indexVal], dataDisks: updatedNodeList, isConfigDiskFulfilled: false };
            const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];
            set(sspSubmitOrder, {
                ...get(sspSubmitOrder),
                nodes: updatedNodeDetails,
            });
        }
        if (params.key === 'fsType') {
            const currentNodeList = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
            const updatedNode = { ...currentNodeList[params?.index], fsType: params.selected };
            const updatedNodeList = [...currentNodeList.slice(0, params?.index), updatedNode, ...currentNodeList.slice(params?.index + 1)];
            const nodeUpdatedDataDiskList = { ...nodeDetails[params?.indexVal], dataDisks: updatedNodeList, isConfigDiskFulfilled: false };
            const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];
            set(sspSubmitOrder, {
                ...get(sspSubmitOrder),
                nodes: updatedNodeDetails,
            });
        }
        if (params.key === 'mountPoint') {
            const currentNodeList = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
            const updatedNode = { ...currentNodeList[params?.index], mountPoint: params.selected };
            const updatedNodeList = [...currentNodeList.slice(0, params?.index), updatedNode, ...currentNodeList.slice(params?.index + 1)];
            const nodeUpdatedDataDiskList = { ...nodeDetails[params?.indexVal], dataDisks: updatedNodeList, isConfigDiskFulfilled: false };
            const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];
            set(sspSubmitOrder, {
                ...get(sspSubmitOrder),
                nodes: updatedNodeDetails,
            });
        }
        if (params.key === 'diskSizeInGib') {
            const currentNodeList = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
            const updatedNode = { ...currentNodeList[params?.index], diskSizeInGib: params.selected };
            const updatedNodeList = [...currentNodeList.slice(0, params?.index), updatedNode, ...currentNodeList.slice(params?.index + 1)];
            const nodeUpdatedDataDiskList = { ...nodeDetails[params?.indexVal], dataDisks: updatedNodeList, isConfigDiskFulfilled: false };
            const updatedNodeDetails = [...nodeDetails.slice(0, params?.indexVal), nodeUpdatedDataDiskList, ...nodeDetails.slice(params?.indexVal + 1)];
            set(sspSubmitOrder, {
                ...get(sspSubmitOrder),
                nodes: updatedNodeDetails,
            });
        }
    },
});

export const validateDisk = selector({
    key: 'couchbase-validateDisk',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }, params) => {
        const currentNode = get(sspSubmitOrder)?.nodes;
        const currentDataDisks = get(sspSubmitOrder).nodes[params?.indexVal]?.dataDisks;
        let errorCount = 0;
        if (currentDataDisks?.length) {
            let isDataDiskValid = false;
            currentDataDisks.forEach((disk) => {
                if (
                    disk.diskSizeInGib !== '' &&
                    disk.diskType !== '' &&
                    disk.mountPoint !== '' &&
                    disk.mountPoint !== undefined &&
                    !params?.DataDiskConfig?.data?.data?.values[0].reservedPaths.includes(disk.mountPoint) &&
                    disk.fsType &&
                    disk.fsType !== ''
                ) {
                    isDataDiskValid = true;
                } else {
                    isDataDiskValid = false;
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
            isDataDiskValid = errorCount === 0;
            const updatedNode = { ...currentNode[params?.indexVal], isConfigDiskFulfilled: isDataDiskValid };
            const nodes = [...currentNode.slice(0, params?.indexVal), updatedNode, ...currentNode.slice(params?.indexVal + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        } else {
            const updatedNode = { ...currentNode[params?.indexVal], isConfigDiskFulfilled: true };
            const nodes = [...currentNode.slice(0, params?.indexVal), updatedNode, ...currentNode.slice(params?.indexVal + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), nodes });
        }
    },
});

export const addServiceMappings = selector({
    key: 'couchbase_addServiceMappings',
    get: ({ get }) => get(sspSubmitOrder).serviceMappings,
    set: ({ set, get }, count) => {
        const currentServiceMappings = get(sspSubmitOrder).serviceMappings;
        let serviceMappings = [{ clusterService: null, memoryQuota: '', nodes: [], storagePath: '' }];
        serviceMappings = [
            ...currentServiceMappings,
            ...Array(count.count).fill({
                clusterService: null,
                memoryQuota: '',
                nodes: [],
                storagePath: '',
                isTouched: false,
            }),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            serviceMappings,
        });
    },
});

export const removeServiceMappings = selector({
    key: 'couchbase_removeServiceMappings',
    get: ({ get }) => get(sspSubmitOrder).serviceMappings,
    set: ({ set, get }, mappingDataToRemove) => {
        const currentServiceMappings = get(sspSubmitOrder).serviceMappings;
        const mappingDataToRemoveIndex = currentServiceMappings.findIndex((val) => val === mappingDataToRemove);
        const serviceMappings = [...currentServiceMappings.slice(0, mappingDataToRemoveIndex), ...currentServiceMappings.slice(mappingDataToRemoveIndex + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            serviceMappings,
        });
    },
});

export const updateServiceMappings = selector({
    key: 'couchbase-updateServiceMappings',
    get: ({ get }) => get(sspSubmitOrder).serviceMappings,
    set: ({ set, get }, mappingDataToUpdate) => {
        const currentServiceMappings = get(sspSubmitOrder).serviceMappings;
        if (mappingDataToUpdate.key === 'clusterService') {
            const result = mappingDataToUpdate?.serviceMappingsApiData?.filter((z) => z.clusterService === mappingDataToUpdate.selected.value);
            const updatedMappingData = {
                ...currentServiceMappings[mappingDataToUpdate?.index],
                clusterService: mappingDataToUpdate.selected.label,
                storagePath: result[0]?.storagePath,
                isMemoryRequired: result[0]?.isMemoryRequired,
                memoryQuota: result[0]?.isMemoryRequired === 'false' ? 'NA' : '',
            };

            const serviceMappings = [
                ...currentServiceMappings.slice(0, mappingDataToUpdate?.index),
                updatedMappingData,
                ...currentServiceMappings.slice(mappingDataToUpdate?.index + 1),
            ];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), serviceMappings });
        }
        if (mappingDataToUpdate.key === 'nodes') {
            const updatedMappingData = {
                ...currentServiceMappings[mappingDataToUpdate?.index],
                nodes: mappingDataToUpdate.selected,
            };

            const serviceMappings = [
                ...currentServiceMappings.slice(0, mappingDataToUpdate?.index),
                updatedMappingData,
                ...currentServiceMappings.slice(mappingDataToUpdate?.index + 1),
            ];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), serviceMappings });
        }
        if (mappingDataToUpdate.key === 'memoryQuota') {
            const updatedMappingData = {
                ...currentServiceMappings[mappingDataToUpdate?.index],
                memoryQuota: mappingDataToUpdate.selected,
            };

            const serviceMappings = [
                ...currentServiceMappings.slice(0, mappingDataToUpdate?.index),
                updatedMappingData,
                ...currentServiceMappings.slice(mappingDataToUpdate?.index + 1),
            ];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), serviceMappings });
        }
    },
});

export const addBuckets = selector({
    key: 'couchbase_addBuckets',
    get: ({ get }) => get(sspSubmitOrder).buckets,
    set: ({ set, get }, count) => {
        const currentBuckets = get(sspSubmitOrder).buckets;
        let buckets = [{ bucketType: null, bucketNames: [{ name: '' }], bucketMemSize: '' }];
        buckets = [
            ...currentBuckets,
            ...Array(count.count).fill({
                bucketType: null,
                bucketNames: [{ name: '' }],
                bucketMemSize: '',
            }),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            buckets,
        });
    },
});

export const removeBuckets = selector({
    key: 'couchbase_removeBuckets',
    get: ({ get }) => get(sspSubmitOrder).buckets,
    set: ({ set, get }, bucketDataToRemove) => {
        const currentBuckets = get(sspSubmitOrder).buckets;
        const buckets = [...currentBuckets.slice(0, bucketDataToRemove), ...currentBuckets.slice(bucketDataToRemove + 1)];

        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            buckets,
        });
    },
});

export const updateBuckets = selector({
    key: 'couchbase-updateBuckets',
    get: ({ get }) => get(sspSubmitOrder).buckets,
    set: ({ set, get }, bucketDataToUpdate) => {
        const currentBuckets = get(sspSubmitOrder).buckets;
        if (bucketDataToUpdate.key === 'bucketType') {
            const updatedBucketData = {
                ...currentBuckets[bucketDataToUpdate?.index],
                bucketType: bucketDataToUpdate.selected,
            };

            const buckets = [...currentBuckets.slice(0, bucketDataToUpdate?.index), updatedBucketData, ...currentBuckets.slice(bucketDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), buckets });
        }
        if (bucketDataToUpdate.key === 'bucketMemSize') {
            const updatedBucketData = {
                ...currentBuckets[bucketDataToUpdate?.index],
                bucketMemSize: bucketDataToUpdate.selected,
            };

            const buckets = [...currentBuckets.slice(0, bucketDataToUpdate?.index), updatedBucketData, ...currentBuckets.slice(bucketDataToUpdate?.index + 1)];
            set(sspSubmitOrder, { ...get(sspSubmitOrder), buckets });
        }
    },
});

export const addBucketNames = selector({
    key: 'couchbase_addBucketNames',
    get: ({ get }) => get(sspSubmitOrder).buckets,
    set: ({ set, get }, params) => {
        const buckets = get(sspSubmitOrder)?.buckets;
        const currentBucketNames = get(sspSubmitOrder).buckets[params?.indexVal]?.bucketNames;
        let updatedBucketNames = [];
        updatedBucketNames = [
            ...currentBucketNames,
            ...Array(params.count).fill({
                name: '',
            }),
        ];
        const updatedBucketNameList = { ...buckets[params?.indexVal], bucketNames: updatedBucketNames };
        const updatedBuckets = [...buckets.slice(0, params?.indexVal), updatedBucketNameList, ...buckets.slice(params?.indexVal + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            buckets: updatedBuckets,
        });
    },
});

export const removeBucketNames = selector({
    key: 'couchbase_removeBucketNames',
    get: ({ get }) => get(sspSubmitOrder).buckets,
    set: ({ set, get }, params) => {
        const buckets = get(sspSubmitOrder)?.buckets;
        const currentBucketNames = get(sspSubmitOrder).buckets[params?.indexVal]?.bucketNames;
        const updatedBucketNames = [...currentBucketNames?.slice(0, params.index), ...currentBucketNames?.slice(params.index + 1)];
        const updatedBucketNameList = { ...buckets[params?.indexVal], bucketNames: updatedBucketNames };
        const updatedBuckets = [...buckets.slice(0, params?.indexVal), updatedBucketNameList, ...buckets.slice(params?.indexVal + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            buckets: updatedBuckets,
        });
    },
});

export const updateBucketNames = selector({
    key: 'couchbase_updateBucketNames',
    get: ({ get }) => get(sspSubmitOrder).buckets,
    set: ({ set, get }, params) => {
        const buckets = get(sspSubmitOrder)?.buckets;
        const currentBucketNames = get(sspSubmitOrder).buckets[params?.indexVal]?.bucketNames;
        const updatedNames = { ...currentBucketNames[params?.index], [params?.key]: params.inputValue };
        const updatedBucketNames = [...currentBucketNames.slice(0, params?.index), updatedNames, ...currentBucketNames.slice(params?.index + 1)];
        const updatedBucketNameList = { ...buckets[params?.indexVal], bucketNames: updatedBucketNames };
        const updatedBuckets = [...buckets.slice(0, params?.indexVal), updatedBucketNameList, ...buckets.slice(params?.indexVal + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            buckets: updatedBuckets,
        });
    },
});
