import React from 'react';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import SAMLForm from './SAMLForm';
import OIDCForm from './OIDCForm';
import { SAMLRequestType } from './utils/constant';

const SsoForm = () => {
    const order = useRecoilValue(OrderPayload);
    return <>{order?.requestType === SAMLRequestType ? <SAMLForm /> : <OIDCForm />}</>;
};

export default SsoForm;
