import React, { useState, Suspense, lazy } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    orderPayload,
    showPreviewOrder,
    isDivisionRequired,
    isAppRegionRequired,
    validVSForm,
    isNamespaceLoading,
    validationErrorReport,
    certCreationRequired,
    isValidOwners,
} from 'pages/provisioning/virtual-service/store';
import { isReadyToSubmit } from 'pages/provisioning/virtual-service/utils';

const ErrorBanner = lazy(() => import('components/ErrorBanner'));

export const PreviewOrder = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [, setShowPreview] = useRecoilState(showPreviewOrder);
    const divisionReq = useRecoilValue(isDivisionRequired);
    const appRegionReq = useRecoilValue(isAppRegionRequired);
    const namespaceLoading = useRecoilValue(isNamespaceLoading);
    const [validForm] = useRecoilState(validVSForm);
    const [validationError] = useRecoilState(validationErrorReport);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [isRequired] = useRecoilState(certCreationRequired);
    const [validOwners] = useRecoilState(isValidOwners);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };
    const checkVSValidationError = () => {
        return validationError?.some((value) => value?.error === true);
    };
    const handleValidation = () => {
        document.getElementById('applayoutbox').scrollTop = 0;
        if (
            !isReadyToSubmit({
                order,
                divisionReq,
                appRegionReq,
                validForm,
                isRequired,
                validOwners,
            }) &&
            !checkVSValidationError()
        ) {
            setShowPreview(true);
        } else {
            setShowPreview(null);
            setOrder({ ...order, isTouched: true });
            handleClickErrorBanner();
        }
    };
    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <div className="card-btn-container">
                <Button
                    size="medium"
                    onClick={() => handleValidation()}
                    disabled={namespaceLoading}
                    className="cmpCancelBtn preview-button"
                    color="cmpPrimary"
                    variant="outlined"
                    data-testid="preview-btn"
                >
                    Preview order before submitting
                </Button>
            </div>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

export default React.memo(PreviewOrder);
