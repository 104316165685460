import React from 'react';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import ApplicationForm from 'pages/provisioning/virtual-service/ApplicationForm';
import ProjectForm from 'pages/provisioning/virtual-service/ProjectForm';
import CostSummary from 'pages/provisioning/_components/CostSummary';
import { SubmitForValidation } from 'pages/provisioning/virtual-service/components/SubmitForValidation';
import { PreviewOrder } from 'pages/provisioning/virtual-service/components/PreviewOrder';
import { costEstimateData, toggleSspModels } from 'pages/provisioning/virtual-service/store';
import { Box, Grid } from '@mui/material';

const InputForm = () => {
    const [costData] = useRecoilState(costEstimateData);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };

    const costDetailsApi = useMutation((payload) => axios.post(endPoints.virtualService.costDetails, payload));

    return (
        <Grid container spacing={3.125} pt={3.125} pb={1}>
            <Grid item xs={8}>
                <div>
                    <ApplicationForm />
                    <ProjectForm />
                </div>
            </Grid>
            <Grid item xs={4}>
                <CostSummary
                    handleCostInfoBtn={handleCostInfoBtn}
                    cartBtn={
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <PreviewOrder />
                            <SubmitForValidation />
                        </Box>
                    }
                    costEstimate={costData}
                    isLoading={costDetailsApi?.isLoading}
                />
            </Grid>
        </Grid>
    );
};

export default InputForm;
