import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { CssTextField } from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';
import HelpImg from 'pages/project-onboarding/components/helpImg';

const ApplicationOwner = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const applicationOwnerApi = useMutation(() =>
        axios.get(`${endPoints.day2Operation.ProjectOnboarding.appOwner.replace('{businessApp}', order?.businessApplication)}`)
    );

    React.useEffect(() => {
        if (applicationOwnerApi?.isSuccess) {
            setOrder({
                ...order,
                applicationOwner: applicationOwnerApi?.data?.data?.owner,
                applicationOwnerDetails: applicationOwnerApi?.data?.data,
            });
        }
    }, [applicationOwnerApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.businessApplication) {
            applicationOwnerApi.mutate();
        } else {
            setOrder({
                ...order,
                applicationOwner: '',
                applicationOwnerDetails: null,
            });
        }
    }, [order?.businessApplication]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1} data-testid="app-owner-search">
                <CssTextField
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& label.MuiInputLabel-shrink': {
                            color: '#0047BA !important',
                        },
                    }}
                    disabled
                    autoComplete="off"
                    label="Application/Service Account Owner *"
                    value={order?.applicationOwner || ''}
                    InputProps={{
                        endAdornment: <>{applicationOwnerApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                    }}
                />
                <HelpImg
                    maxWidth="300px"
                    title="It is applicable when SonarQube service has been selected and requires a service account to be created. 
                So, the application owner will be used as SonarQube service account owner."
                />
            </Box>
        </>
    );
};

export default React.memo(ApplicationOwner);
