import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'alb-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'alb-quickConfigDetails',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'alb-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isBackendPoolsOpen: false,
        isHealthProbeOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'alb-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'alb-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'alb-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'alb-sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        appTier: null,
        appTierCode: null,
        sector: null,
        sectorDetails: null,
        region: null,
        regionDisplayName: null,
        environment: null,
        hoursOfOperation: null,
        hoursOfOperationDetail: null,
        hCode: null,
        hCodeDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        protocols: null,
        tags: [],
        estimatedCost: '0/month',
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
        haDeployment: null,
        poolName: '',
        instanceName: '',
        fqdns: '',
        instanceFrontendPoolName: '',
        availabilityZone: null,
        availabilityZoneOptions: [],
        instanceBackendPoolName: null,
        virtualMachine: null,
        poolConfigurations: null,
        vNet: null,
        inboundFrontendPoolName: 'pep-cmp-frontend-lb',
        inboundBackendPoolName: 'pep-cmp-bp-lb',
        haPorts: 'yes',
        inboundProtocol: null,
        frontendPort: '',
        backendPort: '',
        healthProbeName: '',
        hpProtocol: null,
        port: '',
        urlPath: '',
        interval: '',
        sessionPersistance: null,
        timeOut: null,
    },
});

export const environmentDetails = atom({
    key: 'alb-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
        Approval_Manager: '---',
        Subnet_Name: '---',
        Virtual_Network: '---',
        'TCP Reset': '---',
        'Floating IP': '---',
        Type: '---',
        SKU: '---',
    },
});

export const costEstimateState = atom({
    key: 'alb-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'alb-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
export const isValidInstance = atom({
    key: 'alb-isValidInstance',
    default: false,
});
export const isValidFqdns = atom({
    key: 'alb-isValidFqdns',
    default: false,
});
export const isValidInstanceFrontendPool = atom({
    key: 'alb-isValidInstanceFrontendPool',
    default: false,
});
export const isValidBackendPool = atom({
    key: 'alb-isValidBackendPool',
    default: false,
});
export const isValidRulesName = atom({
    key: 'alb-isValidRulesName',
    default: false,
});
export const isValidFrontendPort = atom({
    key: 'alb-isValidFrontendPort',
    default: false,
});
export const isValidBackendPort = atom({
    key: 'alb-isValidBackendPort',
    default: false,
});
export const isValidHealthProbName = atom({
    key: 'alb-isValidHealthProbName',
    default: false,
});
export const isValidPort = atom({
    key: 'alb-isValidPort',
    default: false,
});
export const isValidUrlPath = atom({
    key: 'alb-isValidUrlPath',
    default: false,
});
export const isBackendPoolsConfigFullfilled = atom({
    key: 'alb-isBackendPoolsConfigFullfilled',
    default: false,
});
export const isHealthProbeFullfilled = atom({
    key: 'alb-isHealthProbeFullfilled',
    default: false,
});
