/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { aksCatalogServiceCode, kafkaCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddEnvironment, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Environments = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDEnv, setLzDDEnv] = useRecoilState(ddEnvironment);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    let envEndpoint;
    switch (landZoneForm?.cloudService) {
        case aksCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipEnvironments}/AKS/${landZoneForm?.department}`;
            break;
        case kafkaCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipEnvironments}/Kafka/${landZoneForm?.department}`;
            break;
        default:
            envEndpoint = endPoints.landingZones.environments;
    }
    const environmentApi = useMutation(() => axios.get(envEndpoint));
    const setEnv = (environment, environmentDetails) => {
        // if (!lzModal?.lzDetails) {
        setLandingZoneForm({
            ...landZoneForm,
            environment,
            environmentDetails,
            geoRecoveryRegion: null,
            geoRecoveryRegionDetails: null,
            ...([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) && {
                region: null,
                cluster: null,
            }),
            connections:
                landZoneForm?.connections?.map((item) => ({
                    componentId: item?.componentId,
                    landingZone: null,
                    landingZoneDetails: null,
                    name: item?.name,
                    provider: item?.provider,
                    service: item?.service,
                })) || [],
        });
        // }
    };
    const seldValueExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEnv(selected?.label, selected);
        } else {
            setEnv(null, null);
        }
    };
    const isDisable = () => {
        if (lzModal?.type === 'edit') {
            return ![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService);
        }
        return false;
    };
    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService)) {
                setOptions(environmentApi?.data?.data?.map((app) => ({ label: app, value: app })));
            } else {
                setOptions(environmentApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
            }
            setLzDDEnv({ ...lzDDEnv, isLoaded: true });
        }
    }, [environmentApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const environment = options.find((value) => lzModal?.lzDetails?.environment === value?.value);
            if (environment) setLzDDEnv({ ...lzDDEnv, isLoaded: true, data: environment });
        }
    }, [options]);
    React.useEffect(() => {
        if (![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService)) environmentApi.mutate();
    }, []);
    React.useEffect(() => {
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) && landZoneForm?.department) environmentApi.mutate();
    }, [landZoneForm?.department]);
    return (
        <Box bgcolor="white">
            <Select
                key="environmentName"
                value={landZoneForm?.environment || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={environmentApi.isLoading || isDisable()}
                isLoading={environmentApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        {...params}
                        label="Environment Name*"
                        placeholder="Environment Name"
                        InputProps={{
                            ...params.InputProps,

                            endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Environments);
