export const getPayload = ({ order = [], catalogCode, catalogServiceId }) => {
    const tags = {};

    // Formatting Tags for submitOrderAPI
    order?.tags?.forEach((tag) => {
        if (tag.name === 'Sector') {
            tags[tag.name] = order?.sector || '-';
        } else if (tag.name === 'BillingEntity') {
            tags[tag.name] = order?.billingEntity;
        } else if (tag.name === 'CostCenter') {
            tags[tag.name] = order?.hCodeDetails?.label;
        } else {
            tags[tag.name] = tag.defaultValue;
        }
    });

    const payload = {
        entityDetails: {
            name: order?.appProfile,
            businessCi: order?.entityDetails?.businessCi,
            applicationManager: order?.entityDetails?.owner?.name,
            applicationManagerEmail: order?.entityDetails?.owner?.emailId,
            applicationManagerGpid: order?.entityDetails?.owner?.id,
            costCenter: order?.entityDetails?.financialDetails,
            project: order?.entityDetails?.projectName || null,
            recoveryPriority: order?.entityDetails?.recoveryPriority,
            billingEntity: order?.entityDetails?.billingEntity || '-',
        },
        catalogService: {
            ...(catalogServiceId && { catalogServiceId }),
            cloudProvider: order?.cloudProvider,
            cloudService: order?.cloudService,
            catalogServiceCode: catalogCode,
            catalogType: order?.catalogType,
            sector: order?.sector,
            estimatedCost: { ...order?.estimatedCost, type: catalogCode },
            quantity: 1,
            catalogConfig: {
                namespaceExist: order?.nsExist,
                namespaceName: order?.namespaceName,
                performance: order?.performance,
                ...(order?.performance === 'Standard' && {
                    throughputUnit: Number(order?.throughputUnits),
                    enableAutoInflate: order?.isAutoInflate,
                }),
                ...(order?.performance === 'Standard' &&
                    order?.isAutoInflate === 'yes' && {
                        autoInflateMaximumThroughputUnit: Number(order?.autoInflateMaxThroughputUnits),
                    }),
                ...(order?.performance === 'Premium' && {
                    processingUnit: Number(order?.processingUnits),
                    enableAutoInflate: 'no',
                }),
                ...(order?.environment === 'Production' && {
                    enableGeoReplication: order?.isGeoReplication,
                }),
                ...(order?.environment !== 'Production' && {
                    enableGeoReplication: 'no',
                }),
                ...(order?.environment !== 'Production' ||
                    (order?.environment === 'Production' && order?.isGeoReplication === 'no' && { geoRecoveryRegion: null })),
                ...(order?.environment === 'Production' &&
                    order?.isGeoReplication === 'yes' && { geoRecoveryRegion: order?.envDetails?.config?.geoRecoveryRegion?.name }),
                dataClassification: order?.envDetails?.config?.dataClassification?.displayName,
                tlsVersion: '1.2',
                eventHubDetails: order?.eventHubDetails?.map((item) => ({
                    eventHubName: item?.eventHubName,
                    retentionPeriod: Number(item?.retention) || Number(item?.retention?.value),
                    partitionCount: Number(item?.partitionCount),
                    consumerGroup: item?.consumerGroupList?.map((z1) => z1.value).toString(),
                })),
                environmentDetails: {
                    projectName: order?.hCodeDetails?.projectName || null,
                    applicationManager: order?.entityDetails?.owner?.name,
                    applicationManagerEmail: order?.entityDetails?.owner?.emailId,
                    applicationManagerGpid: order?.entityDetails?.owner?.id,
                    environment: order?.environment,
                    region: order?.region,
                    costCenter: order?.hCode,
                    subscription: order?.envDetails?.config?.subscription?.name,
                    subscriptionId: order?.envDetails?.config?.subscription?.id,
                    resourceGroup: order?.envDetails?.config?.resourceGroup,
                    selectedNetworks: order?.envDetails?.config?.vnetSubnet?.map((item) => ({
                        subNetName: item?.subnet,
                        virtualNetworkId: item?.vnet?.id,
                        virtualNetwork: item?.vnet?.name,
                    })),
                    landingZoneId: order?.landingZoneDetails?.id,
                },
                tags,
                type: catalogCode,
            },
        },
    };
    return payload;
};
export default getPayload;
