import React from 'react';
import { Box } from '@mui/material';
import { orderPayload, validProvisionForm, successPopup, toggleModal } from 'pages/mdt-onboarding/store';
import { useRecoilState } from 'recoil';
import { resetOrder, resetvalidProvisionForm } from 'pages/mdt-onboarding/utils';
import ProvisioningHeader from './ProvisioningHeader';
import InputForm from './InputForm';
import OrderSuccessPopup from '../components/OrderSuccessPopup';
import SourceFilenameModal from './components/SourceFilenameModal';

const MdtOnboarding = () => {
    const [, setOrder] = useRecoilState(orderPayload);
    const [isvalidForm, setFormValid] = useRecoilState(validProvisionForm);
    const [popUp, setPopUp] = useRecoilState(successPopup);
    const [, setModal] = useRecoilState(toggleModal);

    const handleReset = () => {
        setOrder(resetOrder);
        setFormValid({ ...isvalidForm, resetvalidProvisionForm });
        setPopUp({ ...popUp, isOpen: false, orderId: null });
        setModal({ isSourceFilenameModalOpen: false, sourceFileNamePatternAdded: [] });
    };

    React.useEffect(() => {
        return () => handleReset();
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                width: (theme) => theme.spacing('100%'),
                height: (theme) => theme.spacing('100%'),
            }}
        >
            <ProvisioningHeader />
            <InputForm />
            <SourceFilenameModal />
            <OrderSuccessPopup />
        </Box>
    );
};

export default MdtOnboarding;
