/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddAsg, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const AppSecGroup = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDAsg, setLzDDAsg] = useRecoilState(ddAsg);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const appSecGrpApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.AppSecGroup}/location/${payload?.region}/subscription/${payload?.subscriptionId}`)
    );
    const setAppSecGrp = (appSecGrp = null, appSecGrpDetails = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                appSecGrp,
                appSecGrpDetails,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setAppSecGrp();
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setAppSecGrp(selected?.label, selected);
        }
    };
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const asg = options.find((value) => lzModal?.lzDetails?.config?.applicationSecurityGroup === value?.label);
            if (asg) setLzDDAsg({ ...lzDDAsg, isLoaded: true, data: asg });
        }
    }, [options]);
    React.useEffect(() => {
        if (appSecGrpApi?.isSuccess) {
            setOptions(appSecGrpApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [appSecGrpApi?.isSuccess]);

    React.useEffect(() => {
        if (landZoneForm?.region && landZoneForm?.subscriptionDetails && !lzModal?.lzDetails) {
            resetOptions();
            appSecGrpApi.mutate({ region: landZoneForm?.regionDetails?.value, subscriptionId: landZoneForm?.subscriptionDetails?.id });
        }
    }, [landZoneForm?.region, landZoneForm?.subscriptionDetails]);
    React.useEffect(() => {
        if (lzModal?.lzDetails?.region?.code && lzModal?.lzDetails?.config?.subscription?.name && lzModal?.lzDetails) {
            appSecGrpApi.mutate({ region: lzModal?.lzDetails?.region?.code, subscriptionId: lzModal?.lzDetails?.config?.subscription?.id });
        }
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="appSecGrp"
                // label="ApplicationSecurityGroup"
                value={landZoneForm?.appSecGrp}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={appSecGrpApi?.isLoading}
                isLoading={appSecGrpApi?.isLoading}
            />
        </Box>
    );
};

export default React.memo(AppSecGroup);
