import { atom } from 'recoil';
import { filterDefaultObj } from '../utils';

// eslint-disable-next-line import/prefer-default-export
/* App Profile */
export const appProfileTypeState = atom({
    key: 'appProfileType',
    default: { key: '', pageNumber: 1, pageSize: 10 },
});

export const appProfileTotalCount = atom({
    key: 'appProfileTotalCount',
    default: null,
});

export const toggleCreateProfile = atom({
    key: 'toggleCreateProfile',
    default: { isOpen: false, type: 'create' },
});

export const toggleConfirmProfileModel = atom({
    key: 'toggleConfirmProfile',
    default: {
        isOpen: false,
        type: 'delete',
        message: '',
    },
});
export const profileForm = atom({
    key: 'profileForm',
    default: {
        appName: null,
        businessCI: null,
        financialDetails: null,
        projectName: null,
        recoveryPriority: null,
        billingEntity: null,
        sector: null,
        appOwner: null,
    },
});

/* App Default */
export const toggleCreateAppDefault = atom({
    key: 'toggleCreateAppDefault',
    default: { isOpen: false, type: 'create' },
});

export const appDefaultForm = atom({
    key: 'appDefaultForm',
    default: {
        cloudProvider: null,
        cloudService: null,
        catalogService: null,
        environment: null,
        adGroups: null,
        deploymentModalCode: null,
        storage: null,
    },
});
export const toggleConfirmModel = atom({
    key: 'toggleConfirmModel',
    default: {
        isOpen: false,
        type: 'delete',
        message: '',
    },
});

/* Landing Zone */
export const toggleCreateLandingZone = atom({
    key: 'toggleCreateLandingZone',
    default: { isOpen: false, type: 'create' },
});

export const toggleConfirmLZModel = atom({
    key: 'toggleConfirmLZModel',
    default: {
        isOpen: false,
        type: 'delete',
        message: '',
    },
});

export const toggleSummaryLZ = atom({
    key: 'toggleSummaryLZ',
    default: { isOpen: false, lzDetails: null },
});

export const landingZoneForm = atom({
    key: 'landingZoneForm',
    default: {
        cloudProvider: null,
        projectName: null,
        ISADocument: null,
        privateEndPoints: [],
        vnetSubnet: [{ networkSubscription: null, networkSubscriptionDetails: null, vNet: null, vNetDetails: null, subnet: null, subnetDetails: null }],
        components: [],
        connections: [],
        // environment: null,
        // cloudService: null,
        // appTier: null,
        // region: null,
        // subscription: null,
        // resGroup: null,
        // vNet: null,
        // subNet: null,
        // nwSecGrp: null,
        // appSecGrp: null,
        // proxPlacGrp: null,
        // availSet: null,
        // availZone: null,
    },
});

export const landingZoneTotalCount = atom({
    key: 'landingZoneTotalCount',
    default: null,
});

export const landZoneListFilter = atom({
    key: 'landZoneListFilter',
    default: {
        appProfileName: '',
        // appTier: '',
        cloudProvider: '',
        cloudService: '',
        environment: '',
        pageSize: 5,
        region: '',
        pageNumber: 1,
    },
});
export const ddCloudProvider = atom({
    key: 'dropDownCloudProvider',
    default: { isLoaded: false, data: null },
});
export const ddCloudService = atom({
    key: 'dropDownCloudService',
    default: { isLoaded: false, data: null },
});
export const ddEnvironment = atom({
    key: 'dropDownEnvironment',
    default: { isLoaded: false, data: null },
});
export const ddAppTier = atom({
    key: 'dropDownAppTier',
    default: { isLoaded: false, data: null },
});

export const ddendPointType = atom({
    key: 'dropDownendPointType',
    default: { isLoaded: false, data: null },
});

export const ddRegion = atom({
    key: 'dropDownRegion',
    default: { isLoaded: false, data: null },
});
export const ddGeoRecoveryRegion = atom({
    key: 'dropDownGeoRecoveryRegion',
    default: { isLoaded: false, data: null },
});
export const ddSubscription = atom({
    key: 'dropDownSubscription',
    default: { isLoaded: false, data: null },
});
export const ddResGroup = atom({
    key: 'dropDownResGroup',
    default: { isLoaded: false, data: null },
});
export const ddVnet = atom({
    key: 'dropDownVnet',
    default: { isLoaded: false, data: null },
});
export const ddSubNet = atom({
    key: 'dropDownSubnet',
    default: { isLoaded: false, data: null },
});
export const ddReplication = atom({
    key: 'dropDownReplication',
    default: { isLoaded: false, data: null },
});
export const ddDataClassification = atom({
    key: 'dropDownDataClassification',
    default: { isLoaded: false, data: null },
});
export const ddDepartment = atom({
    key: 'dropDownDepartment',
    default: { isLoaded: false, data: null },
});
export const ddBusinessUnit = atom({
    key: 'dropDownBusinessUnit',
    default: { isLoaded: false, data: null },
});
export const ddCluster = atom({
    key: 'dropDownCluster',
    default: { isLoaded: false, data: null },
});
export const ddNsg = atom({
    key: 'dropDownNsg',
    default: { isLoaded: false, data: null },
});
export const ddAsg = atom({
    key: 'dropDownAsg',
    default: { isLoaded: false, data: null },
});
export const ddppGrp = atom({
    key: 'DropDownProxPlacGrp',
    default: { isLoaded: false, data: null },
});
export const ddAvailSet = atom({
    key: 'DropDownAvailabilitySet',
    default: { isLoaded: false, data: null },
});
export const ddAvailZone = atom({
    key: 'DropDownAvailabilityZone',
    default: { isLoaded: false, data: null },
});
export const ddSku = atom({
    key: 'dropDownSku',
    default: { isLoaded: false, data: null },
});
export const ddType = atom({
    key: 'dropDownType',
    default: { isLoaded: false, data: null },
});
export const profileFilter = atom({
    key: 'Profile Filter',
    default: filterDefaultObj(),
});
export const isProjectNameVaild = atom({
    key: 'isProjectNameVaild',
    default: true,
});
export const isISADocumentVaild = atom({
    key: 'isISADocumentVaild',
    default: true,
});
export const isAppNameValid = atom({
    key: 'isAppNameValid',
    default: true,
});
export const islandingZoneLabelVaild = atom({
    key: 'islandingZoneLabelVaild',
    default: true,
});
export const islandingZoneDescVaild = atom({
    key: 'islandingZoneDescVaild',
    default: true,
});
export const isLdapVaild = atom({
    key: 'isLdapVaild',
    default: true,
});

export const toggleLandingZoneModal = atom({
    key: 'newLandingzone-toggleLandingZoneModal',
    default: { isOpen: false },
});

export const landingZoneData = atom({
    key: 'newLandingzone-landingZoneData',
    default: {
        provider: null,
        service: null,
        serviceCode: null,
        serviceDetails: null,
        environment: null,
        environmentDetails: null,
        region: null,
        regionDetails: null,
        subscription: null,
        subscriptionDetails: null,
        resGroup: null,
        resGroupDetails: null,
        vNet: null,
        vNetDetails: null,
        subNet: null,
        subNetDetails: null,
        availSet: null,
        availSetDetails: null,
        availZone: null,
        availZoneDetails: null,
        landingZoneLabel: '',
        landingZoneDesc: '',
        components: [],
        connections: [],
        isTouched: false,
    },
});
