import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { requestDetails } from 'pages/onboard-developers/store';
import HelpImg from 'pages/onboard-developers/components/helpImg';

const ApplicationCI = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [details, setDetails] = useRecoilState(requestDetails);
    const [value, setValue] = React.useState(details?.applicationCIDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(details?.applicationCI || '');
    const [options, setOptions] = React.useState([]);

    const appGetApi = useMutation(() => axios.get(`${endPoints.onBoardDevelopers.applicationCI}?searchKey=${ccInputValue}`));

    const setAppService = (applicationCI) => {
        setDetails({
            ...details,
            applicationCI: applicationCI?.label,
            applicationCIDetails: applicationCI,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appGetApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (appGetApi?.isSuccess) {
            if (appGetApi?.data?.data?.appProfiles) {
                const combinedValue = appGetApi?.data?.data?.appProfiles?.map((app) => ({ label: `${app.name}`, value: `${app.name}`, data: app }));
                setOptions(combinedValue);
            }
        }
    }, [appGetApi?.isSuccess]);

    React.useEffect(() => {
        resetOptions();
        appGetApi.mutate();
    }, []);

    React.useEffect(() => {
        if (value !== null && value?.data?.id) {
            setAppService(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!details?.applicationCI && details?.isTouched) setIsTouched(true);
    }, [details?.applicationCI, details?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="application Name"
                label="Application CI*"
                loading={appGetApi?.isLoading}
                placeholder="Type to search"
                value={details?.applicationCIDetails || null}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                filterOptions={(x) => x}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props}>{option.label}</li>
                )}
                freeSolo
                isRequired={isTouched}
            />
            <HelpImg title="Type to Search Application ." />
        </Box>
    );
};

export default React.memo(ApplicationCI);
