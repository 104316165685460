import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isDestUrlValid } from 'pages/sso-okta/store';
import { urlRegex } from 'pages/sso-okta/utils/constant';
import HelpImg from 'pages/provisioning/_components/helpImg';

export const DestinationUrl = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setDestinationUrlVaild] = useRecoilState(isDestUrlValid);
    const [helperText, setHelperText] = React.useState(false);

    const setDestinationUrl = (destinationUrl = null) => {
        setOrder({
            ...order,
            destinationUrl,
        });
    };

    React.useEffect(() => {
        if (
            order?.destinationUrl &&
            order?.destinationUrl !== null &&
            order?.destinationUrl !== '' &&
            order?.destinationUrl !== undefined &&
            !urlRegex.test(order?.destinationUrl)
        ) {
            setHelperText(true);
            setDestinationUrlVaild(false);
        } else {
            setHelperText(false);
            setDestinationUrlVaild(true);
        }
    }, [order?.destinationUrl]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.destinationUrl) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.destinationUrl && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.destinationUrl, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-url-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Destination URL *"
                error={helperText}
                helperText={helperText && <span>Destination URL is invalid.</span>}
                value={order?.destinationUrl || ''}
                onChange={(e) => setDestinationUrl(e.target.value)}
                className={validateInput()}
                data-testid="destUrlText"
            />
            <HelpImg
                title={
                    <Box sx={{ whiteSpace: 'pre' }}>
                        <Box>Identifies the location where the SAML response is intended to be sent inside of the SAML assertion. </Box>
                        <Box>This should be same as the Single sign-on URL unless your application needs any explicit specific value.</Box>
                    </Box>
                }
                maxWidth="600px"
            />
        </Box>
    );
};

export default DestinationUrl;
