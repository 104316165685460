import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const DataDiskSupport = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setDataDiskSupport = (dataDiskSupport, dataDiskSupportDetail) => {
        setOrder({ ...order, dataDiskSupport, dataDiskSupportDetail, cost: '' });
    };
    const seldValueExistsInOptions = (dataDiskSupportValue) => options.find((option) => dataDiskSupportValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setDataDiskSupport(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        setOptions([
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
        ]);
    }, []);
    React.useEffect(() => {
        if (!order?.dataDiskSupport && order?.isTouched) setIsTouched(true);
    }, [order?.dataDiskSupport, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="dataDiskSupport"
                label="Data Disk Support *"
                value={order?.dataDiskSupport || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(DataDiskSupport);
