/* eslint-disable import/no-named-default */
import { default as ApplicationName } from './ApplicationName';
import { default as Environments } from './Environments';
import { default as ApplicationUrl } from './ApplicationUrl';
import { default as ApplicationType } from './ApplicationType';
import { default as ApplicationShortName } from './ApplicationShortName';
import { default as DataClassification } from './DataClassification';
import { default as AppOwnerSearch } from './AppOwnerSearch';
import { default as Sector } from './Sector';
import { default as RoleWrapper } from './RoleWrapper';
import { default as AuthorizationRolesRadio } from './AuthorizationRolesRadio';
import { default as RequestType } from './RequestType';
import { default as DestinationUrl } from './DestinationUrl';
import { default as RecipientUrl } from './RecipientUrl';
import { default as AudienceURI } from './AudienceURl';
import { default as ServiceProviderSigningCert } from './ServiceProviderSigningCertificate';
import { default as LogoutUrl } from './LogoutUrl';
import { default as CertificateRadio } from './CertificateRadio';
import { default as BusinessJustification } from './BusinessJustification';
import { default as BusinessApplication } from './BusinessApplication';
import { default as ApplicationService } from './ApplicationService';

const Forms = {
    ApplicationName,
    Environments,
    ApplicationUrl,
    ApplicationType,
    ApplicationShortName,
    DataClassification,
    AppOwnerSearch,
    Sector,
    RoleWrapper,
    AuthorizationRolesRadio,
    RequestType,
    DestinationUrl,
    RecipientUrl,
    AudienceURI,
    ServiceProviderSigningCert,
    LogoutUrl,
    CertificateRadio,
    BusinessJustification,
    BusinessApplication,
    ApplicationService,
};

export default Forms;
