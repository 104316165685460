import React from 'react';
import { Grid } from '@mui/material';
import FormCard from 'pages/reference-implementation/components/form-card';
import Form from 'pages/reference-implementation/provisioning/RI003/components';
import Heading from 'pages/reference-implementation/components/Heading';
import { useParams } from 'react-router-dom';
import { ShowAppUrlBasedOnCatalogCode } from 'pages/reference-implementation/provisioning/RI003/utils';
import { RICatalogCode003, RICatalogCode003A, RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';

const ApplicationSection = () => {
    const { catalogCode } = useParams();
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Heading title="Application" />
                </Grid>
            </Grid>
            <FormCard>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Form.Environments />
                    </Grid>
                    <Form.Program />
                    <Grid item xs={12} sm={6}>
                        <Form.DataClassification />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessApp />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationService />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.AppOwnerSearch />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.ApplicationShortName />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.BusinessContact />
                    </Grid>
                    {catalogCode && ShowAppUrlBasedOnCatalogCode?.includes(catalogCode) && (
                        <Grid item xs={12} sm={6}>
                            <Form.AppUrl />
                        </Grid>
                    )}
                    {[RICatalogCode003, RICatalogCode003A, RICatalogCode005]?.includes(catalogCode) && (
                        <Grid item xs={12} sm={6}>
                            <Form.ApplicationSupportDl />
                        </Grid>
                    )}
                    {[RICatalogCode003, RICatalogCode003A, RICatalogCode005]?.includes(catalogCode) && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Form.CertificateOwnerOne />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.CertificateOwnerTwo />
                            </Grid>
                        </>
                    )}
                </Grid>
            </FormCard>
        </>
    );
};

export default ApplicationSection;
