import React, { useEffect } from 'react';
import { Stack, Grid } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import Empty from 'components/empty';
import axios from 'api/request';
import ProvisioningStyles from 'pages/service-account-creation/style';

import ProvisioningHeader from 'pages/service-account-creation/ProvisioningHeader';
import ProjectForm from 'pages/service-account-creation/ProjectForm';

import { endPoints } from 'api/endpoints';
import { optionsList, OrderPayload, SQLServer } from 'pages/service-account-creation/store';
import { orderSuccessPopup } from 'pages/reference-implementation/store';
import { formOrderObjFromOrderDetails } from './utils/getPayload';

const ServiceAccountCreationPage = () => {
    const [, setOptions] = useRecoilState(optionsList);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setSQLServer] = useRecoilState(SQLServer);
    const [, resetSuccessPopup] = useRecoilState(orderSuccessPopup);
    const { operation, cartItemId } = useParams();
    const location = useLocation();
    const orderDetailsApi = useMutation(() => axios.get(`${endPoints.riServices.Jwt.orderDetails}/${cartItemId}`));

    const resetForm = () => {
        setOptions([]);
        setOrder({
            ...order,
            applicationName: null,
            environment: null,
            appOwner: null,
            appOwnerDetails: null,
            sqlServerName: null,
            sqlServerDetails: null,
            applicationServiceCI: null,
            appShortName: null,
            accountType: null,
            sector: null,
            isTouched: false,
        });
        resetSuccessPopup(false);
    };

    const checkIsSQLServer = () => {
        const pathname = location.pathname.match(/service-account-ci/i);
        // eslint-disable-next-line
        setSQLServer(pathname ? true : false);
    };

    const getOrderDetails = async () => {
        try {
            if (cartItemId && operation) {
                const { data: orderDetails } = await orderDetailsApi.mutateAsync();
                const orderObj = formOrderObjFromOrderDetails(orderDetails);
                if (operation === 'edit') {
                    setOrder({
                        ...order,
                        accountType: orderObj?.accountType,
                        applicationName: orderObj?.applicationName,
                        sector: orderObj?.sector,
                        environment: orderObj?.environment,
                        appOwner: orderObj?.appOwner,
                        appOwnerDetails: orderObj?.appOwnerDetails,
                        accountOwnerId: orderObj?.accountOwnerId,
                        applicationServiceCI: orderObj?.applicationServiceCI,
                        sqlServerName: orderObj?.sqlServerName,
                        sqlServerDetails: orderObj?.sqlServerName,
                        appShortName: orderObj?.appShortName,
                        isTouched: false,
                    });
                }
            }
        } catch (err) {
            // eslint-disable-next-line
            console.log('Error');
        }
    };
    useEffect(() => {
        return () => resetForm();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        return () => resetForm();
        // eslint-disable-next-line
    }, [operation]);
    useEffect(() => {
        return () => checkIsSQLServer();
        // eslint-disable-next-line
    });
    useEffect(() => {
        getOrderDetails();
    }, [operation, cartItemId]);
    return (
        <>
            {orderDetailsApi?.isLoading ? (
                <Stack sx={ProvisioningStyles?.emptyStack}>
                    <Empty isLoading color="#000000" msg="Loading..." />
                </Stack>
            ) : (
                <>
                    <ProvisioningHeader />
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <Grid display="flex" flexDirection="column" padding={4} gap={3}>
                                <ProjectForm />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default ServiceAccountCreationPage;
