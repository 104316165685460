import { uuid } from 'uuidv4';

export const getNodePayload = ({ order = [], tags = {} }) => {
    const components = order?.nodes?.map((item, index) => ({
        componentName: `Node-${(index + 1).toString()}`,
        catalogService: {
            catalogServiceCode: item?.catalogServiceCode,
            cloudProvider: item?.cloudProvider,
            cloudService: item?.cloudService,
            catalogType: item?.catalogType,
            componentId: uuid(),
            sector: order?.sector,
            quantity: 1,
            timeZone: item?.timeZone,
            hoursOfOperation: 'Always ON',
            recoveryVaultName: order?.recoveryVaultName,
            recoveryVaultPolicyName: order?.recoveryVaultPolicyName,
            recoveryVaultResourceGroup: order?.recoveryVaultResourceGroup,
            estimatedCost: item?.estimatedCost,
            catalogConfig: {
                sslCertificate: item?.sslCertificate === 'yes',
                billingEntity: order?.billingEntity,
                haDeployment: item?.haDeployment,
                ...(item?.haDeployment === 'Availability Zones' && { availabilityZones: item?.availabilityZone }),
                samAccountId: item?.samAccountId,
                vmType: item?.vmTypeDetails?.name,
                serverRefresh: item?.serverRefresh,
                type: item?.catalogServiceCode,
                os: {
                    osType: item?.osType,
                    offer: 'RHEL',
                    publisher: item?.osTypeVersionDetail?.publisher,
                    sku: item?.osTypeVersionDetail?.sku,
                    osDiskType: item?.osDiskType,
                    osDiskTypeCode: item?.osDiskTypeCode,
                    ephemeralDiskAvailable: item?.vmTypeDetails?.ephemeralDiskAvailable,
                },
                diskDefinitions: item?.dataDisks?.map((disk) => ({
                    diskType: disk.diskType,
                    mountPoint: disk.mountPoint,
                    fsType: disk.fsType,
                    diskSizeInGib: disk.diskSizeInGib,
                    diskTypeCode: item?.diskTypeOptions?.find((diskFind) => diskFind.name === disk.diskType)?.code,
                })),
                environmentDetails: {
                    subscription: order?.envDetails?.config?.subscription?.name,
                    subscriptionId: order?.envDetails?.config?.subscription?.id,
                    resourceGroup: order?.envDetails?.config?.resourceGroup,
                    virtualNetwork: order?.envDetails?.config?.vnet?.name,
                    virtualNetworkId: order?.envDetails?.config?.vnet?.id,
                    subNetName: order?.envDetails?.config?.subnet,
                    networkSecurityGroup: order?.envDetails?.config?.networkSecurityGroup || null,
                    applicationSecurityGroup: order?.envDetails?.config?.applicationSecurityGroup || null,
                    applicationManager: order?.envDetails?.applicationManager,
                    applicationManagerEmail: order?.envDetails?.applicationManagerEmail,
                    applicationManagerGpid: order?.envDetails?.applicationManagerGpid,
                    businessService: order?.envDetails?.businessService,
                    drPriority: order?.envDetails?.drPriority,
                    metallic: order?.envDetails?.metallic,
                    backupTier: order?.envDetails?.backupTier,
                    domain: item?.domain,
                    environment: order?.environment,
                    projectName: order?.hCodeDetails?.projectName || null,
                    region: order?.region,
                    applicationTier: order?.appTier || '',
                    appTierCode: order?.appTierCode || '',
                    costCenter: order?.hCode,
                    asr: order?.envDetails?.asr === 'enabled',
                    landingZoneId: order?.landingZoneDetails?.id,
                },
                tags,
            },
        },
    }));

    return components;
};

export const getNodeCostPayload = ({ order = [], indexVal }) => {
    const catalogService = {
        catalogServiceCode: order?.nodes?.[indexVal]?.catalogServiceCode,
        cloudProvider: order?.nodes?.[indexVal]?.cloudProvider,
        cloudService: order?.nodes?.[indexVal]?.cloudService,
        catalogType: order?.nodes?.[indexVal]?.catalogType,
        sector: order?.sector,
        quantity: 1,
        timeZone: order?.nodes?.[indexVal]?.timeZone,
        hoursOfOperation: 'Always ON',
        recoveryVaultName: order?.recoveryVaultName,
        recoveryVaultPolicyName: order?.recoveryVaultPolicyName,
        recoveryVaultResourceGroup: order?.recoveryVaultResourceGroup,
        catalogConfig: {
            billingEntity: order?.billingEntity,
            haDeployment: order?.nodes?.[indexVal]?.haDeployment,
            samAccountId: order?.nodes?.[indexVal]?.samAccountId,
            vmType: order?.nodes?.[indexVal]?.vmTypeDetails?.name,
            serverRefresh: order?.nodes?.[indexVal]?.serverRefresh,
            type: order?.nodes?.[indexVal]?.catalogServiceCode,
            os: {
                osType: order?.nodes?.[indexVal]?.osType,
                offer: 'RHEL',
                publisher: order?.nodes?.[indexVal]?.osTypeVersionDetail?.publisher,
                sku: order?.nodes?.[indexVal]?.osTypeVersionDetail?.sku,
                osDiskType: order?.nodes?.[indexVal]?.osDiskType,
                osDiskTypeCode: order?.nodes?.[indexVal]?.osDiskTypeCode,
                ephemeralDiskAvailable: order?.nodes?.[indexVal]?.vmTypeDetails?.ephemeralDiskAvailable,
            },
            diskDefinitions: order?.nodes?.[indexVal]?.dataDisks?.map((disk) => ({
                diskType: disk.diskType,
                mountPoint: disk.mountPoint,
                fsType: disk.fsType,
                diskSizeInGib: disk.diskSizeInGib,
                diskTypeCode: order?.nodes?.[indexVal]?.diskTypeOptions?.find((diskFind) => diskFind.name === disk.diskType)?.code,
            })),
            environmentDetails: {
                subscription: order?.envDetails?.config?.subscription?.name,
                subscriptionId: order?.envDetails?.config?.subscription?.id,
                resourceGroup: order?.envDetails?.config?.resourceGroup,
                virtualNetwork: order?.envDetails?.config?.vnet?.name,
                virtualNetworkId: order?.envDetails?.config?.vnet?.id,
                subNetName: order?.envDetails?.config?.subnet,
                networkSecurityGroup: order?.envDetails?.config?.networkSecurityGroup || null,
                applicationSecurityGroup: order?.envDetails?.config?.applicationSecurityGroup || null,
                applicationManager: order?.envDetails?.applicationManager,
                applicationManagerEmail: order?.envDetails?.applicationManagerEmail,
                applicationManagerGpid: order?.envDetails?.applicationManagerGpid,
                businessService: order?.envDetails?.businessService,
                drPriority: order?.envDetails?.drPriority,
                metallic: order?.envDetails?.metallic,
                backupTier: order?.envDetails?.backupTier,
                domain: order?.nodes?.[indexVal]?.domain,
                environment: order?.environment,
                projectName: order?.hCodeDetails?.projectName || null,
                region: order?.region,
                applicationTier: order?.appTier || '',
                appTierCode: order?.appTierCode || '',
                costCenter: order?.hCode,
                asr: order?.envDetails?.asr === 'enabled',
                landingZoneId: order?.landingZoneDetails?.id,
            },
            tags: order?.tags,
        },
    };

    return catalogService;
};
