/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const filter = createFilterOptions();
const Project = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [value, setValue] = React.useState(order?.hCodeDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.hCode || '');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const projectIdSearchApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.projects}/${ccInputValue?.toUpperCase()}`));
    const projectIdApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.projects}`));

    const setProjectId = (projectId) => {
        setOrder({
            ...order,
            projectId,
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                projectIdSearchApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (projectIdSearchApi?.isSuccess) {
            if (projectIdSearchApi?.data?.data) {
                const combinedValue = projectIdSearchApi?.data?.data?.map((app) => ({ ...app, label: `${app.id}`, value: `${app.id}` }));
                setOptions(combinedValue);
            }
        }
    }, [projectIdSearchApi?.isSuccess]);

    React.useEffect(() => {
        if (projectIdApi?.isSuccess) {
            if (projectIdApi?.data?.data) {
                const combinedValue = projectIdApi?.data?.data?.map((app) => ({ ...app, label: `${app.id}`, value: `${app.id}` }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [projectIdApi?.isSuccess]);

    React.useEffect(() => {
        projectIdApi.mutate();
    }, []);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            setProjectId(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.projectId && order?.isTouched) setIsTouched(true);
    }, [order?.projectId, order?.isTouched]);

    return (
        <>
            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                <Select
                    key="projectId"
                    label="Project Id *"
                    value={order?.projectId || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={ProvisioningStyles.costCenter}
                    freeSolo
                    isRequired={isTouched}
                />
                <HelpImg title="Type to Search Project Id (e.g. PR000XXX)." />
            </Stack>
        </>
    );
};

export default React.memo(Project);
