import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'components/switch';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { Table, TableBody, TableContainer, TableHead, TablePagination, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useAxios } from 'hooks';
import TablePaginationActions from 'components/tablePaginationActions';
import { togglecatalogDetails } from 'pages/provisioning/store/catalog';
import { endPoints } from 'api/request';
import CatalogDetails from './catalogDetails';
import styles, { StyledTableCell, StyledTableRow } from './styles';
import Btn from '../../components/button';

const headCells = [
    { id: 'sequence', label: 'Sequence' },
    { id: 'catalogService', label: 'Catalog Service' },
    { id: 'description', label: 'Description' },
    { id: 'tags', label: 'Tags' },
    { id: 'modifiedBy', label: 'Modified By' },
    { id: 'actions', label: 'Actions' },
];

function CatalogsList(props) {
    const axios = useAxios();
    const history = useHistory();
    const { data } = props;
    const [catalogsDataList, setCatalogsDataList] = React.useState(data);
    const { catalogDefinitionId } = useParams();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [switchLoading, setSwitchLoading] = React.useState(false);
    const [loadingId, setLoadingId] = React.useState('');
    const [selectedCatalog, setSelectedCatalog] = useRecoilState(togglecatalogDetails);

    const catalogsPutApi = useMutation((payload) => axios.put(`${endPoints.catalog.allList}`, payload));

    const handleChangeRowsPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    React.useEffect(() => {
        if (catalogsDataList !== data) {
            setCatalogsDataList(data);
        }
    }, [data]);
    React.useEffect(() => {
        if (catalogDefinitionId) {
            setSelectedCatalog({
                ...selectedCatalog,
                isOpen: true,
            });
        }
    }, [catalogDefinitionId]);
    const modTableActions = () => (
        <TablePaginationActions
            rowsPerPageOptions={[5, 10, 20, 25, 100]}
            handleChRowsPerPage={handleChangeRowsPage}
            count={catalogsDataList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
        />
    );

    const handleCatalogStatus = (item) => {
        setSwitchLoading(true);
        setLoadingId(item?.catalogDefinitionId);
        catalogsPutApi.mutate({
            id: item?.catalogDefinitionId,
            catalogSequence: item?.catalogSequence,
            isActive: !item?.isActive,
            description: item?.description,
            displayName: item?.displayName,
            tags: {
                Catalog: item?.tags?.Catalog,
            },
        });
    };

    React.useEffect(() => {
        if (catalogsPutApi?.data?.data) {
            const newCatLists = catalogsDataList.map((el) =>
                el?.catalogDefinitionId === catalogsPutApi?.data?.data?.catalogDefinitionId ? { ...el, isActive: !el?.isActive } : el
            );
            setCatalogsDataList(newCatLists);
            setSwitchLoading(false);
        }
    }, [catalogsPutApi?.isSuccess]);

    return (
        <Box sx={styles?.listWrapper}>
            <TableContainer sx={styles?.list?.tableContainer}>
                <Table sx={styles?.list?.table} size="small" stickyHeader>
                    <>
                        <TableHead>
                            <StyledTableRow>
                                {headCells?.map((head, index) => (
                                    <StyledTableCell key={`head-${index.toString()}`}>{head.label}</StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {catalogsDataList &&
                                catalogsDataList?.length > 0 &&
                                (rowsPerPage > 0 ? catalogsDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : catalogsDataList).map(
                                    (catalog, cindx) => (
                                        <StyledTableRow className="animate-opacity" key={`row-${cindx.toString()}`}>
                                            <StyledTableCell>{catalog?.catalogSequence || ''}</StyledTableCell>
                                            <StyledTableCell
                                                onClick={() => {
                                                    setSelectedCatalog({
                                                        ...selectedCatalog,
                                                        isOpen: true,
                                                    });
                                                    history.push(`/catalogs/${catalog?.catalogDefinitionId}`);
                                                }}
                                            >
                                                <Btn sx={styles.list?.catalogServiceBtn}>{catalog?.displayName || ''}</Btn>
                                            </StyledTableCell>
                                            <StyledTableCell>{catalog?.description || ''}</StyledTableCell>
                                            <StyledTableCell>{catalog?.tags?.Catalog || ''}</StyledTableCell>
                                            <StyledTableCell>{catalog?.requestedBy?.name || ''}</StyledTableCell>
                                            <StyledTableCell>
                                                {switchLoading && loadingId === catalog?.catalogDefinitionId ? (
                                                    <CircularProgress size={16} sx={styles?.list?.circularIcon} />
                                                ) : (
                                                    <Switch
                                                        data-testid={catalog?.catalogDefinitionId}
                                                        onClick={() => handleCatalogStatus(catalog)}
                                                        checked={catalog?.isActive}
                                                    />
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                )}
                        </TableBody>
                    </>
                </Table>
                {catalogsDataList && catalogsDataList?.length > 0 && (
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 100, { label: 'All', value: -1 }]}
                        sx={styles?.list?.pagination}
                        count={catalogsDataList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            sx: {
                                display: 'none',
                            },
                        }}
                        labelRowsPerPage=""
                        ActionsComponent={modTableActions}
                        onPageChange={() => {}}
                    />
                )}
            </TableContainer>
            {selectedCatalog?.isOpen && <CatalogDetails />}
        </Box>
    );
}
CatalogsList.propTypes = {
    data: PropTypes.array.isRequired,
};

export default React.memo(CatalogsList);
