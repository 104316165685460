import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import useDebounce from 'pages/myidm/components/hooks/use-debounce';

export const AppShortName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [helperText, setHelperText] = React.useState(false);
    const [appShortNameExistsText, setAppShortNameExistsText] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [validAppShortName, setValidAppShortName] = React.useState('');

    const appShortNameValidationApi = useMutation((payload) => axios.post(endPoints.day2Operation.Day2Common.appShortNameValidation, payload));
    const appShortNameApi = useMutation((payload) => axios.post(endPoints.day2Operation.ProjectOnboarding.appShortName, payload));

    const debouncedInputValue = useDebounce(validAppShortName, 500);
    const debouncedApplicationNameValue = useDebounce(order?.applicationName, 500);

    const setAppShortName = (appShortName = null) => {
        setOrder({
            ...order,
            appShortName,
        });
    };

    React.useEffect(() => {
        if (appShortNameValidationApi?.isSuccess) {
            if (appShortNameValidationApi?.isSuccess) {
                setAppShortName(order?.appShortName);
                setValidForm({
                    ...validForm,
                    isAppShortNameExist: false,
                });
                setAppShortNameExistsText(false);
                setValidForm({ ...validForm, isAppShortNameValid: false });
                toast.dismiss();
            } else {
                setValidForm({
                    ...validForm,
                    isAppShortNameExist: true,
                });
                setAppShortNameExistsText(true);
            }
        }
    }, [appShortNameValidationApi?.isSuccess]);

    React.useEffect(() => {
        setValidForm({ ...validForm, isAppShortNameValid: true });
    }, [appShortNameValidationApi?.error]);

    React.useEffect(() => {
        if (
            debouncedInputValue &&
            order?.applicationName &&
            order?.applicationService &&
            appShortNameApi?.data?.data == null &&
            !appShortNameApi?.isLoading &&
            appShortNameApi?.isSuccess
        ) {
            const payload = {
                applicationName: order?.applicationName,
                applicationShortName: debouncedInputValue,
                applicationService: order?.applicationService,
            };
            appShortNameValidationApi?.mutate(payload);
        }
    }, [debouncedInputValue, order?.applicationName, order?.applicationService]);

    React.useEffect(() => {
        if (appShortNameApi?.data?.data && order?.serviceResources?.isSonarQube) {
            setOrder({
                ...order,
                appShortName: appShortNameApi?.data?.data,
            });
            setValidForm({ ...validForm, isAppShortNameValid: false });
        }
    }, [appShortNameApi?.data?.data]);

    React.useEffect(() => {
        if (
            debouncedApplicationNameValue &&
            order?.applicationService &&
            !validForm?.isAppNameValid &&
            order?.applicationName &&
            order?.serviceResources?.isSonarQube
        ) {
            const payload = {
                applicationName: order?.applicationName,
                applicationService: order?.applicationService,
            };
            appShortNameApi?.mutate(payload);
        }
    }, [debouncedApplicationNameValue, order?.applicationService, order?.applicationName, order?.serviceResources?.isSonarQube]);

    const hasWhiteSpace = (value) => {
        return value?.indexOf(' ') >= 0;
    };

    React.useEffect(() => {
        if (
            order?.appShortName &&
            order?.appShortName !== null &&
            order?.appShortName !== '' &&
            order?.appShortName !== undefined &&
            (order?.appShortName?.length < 2 || order?.appShortName?.length > 8)
        ) {
            setHelperText(true);
            setErrorText('Application short name must have min 2 chars and max 8');
            setValidForm({ ...validForm, isAppShortNameValid: true });
        } else {
            if (hasWhiteSpace(order?.appShortName)) {
                setHelperText(true);
                setErrorText('Space are not allowed in application short name.');
                return setValidForm({ ...validForm, isAppShortNameValid: true });
            }
            setValidAppShortName(order?.appShortName);
            setHelperText(false);
            setErrorText('');
            setValidForm({ ...validForm, isAppShortNameValid: false });
        }
        return null;
    }, [order?.appShortName]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.appShortName) ||
            helperText ||
            (appShortNameValidationApi?.error && appShortNameValidationApi?.error?.length)
        ) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.appShortName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appShortName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="app-short-name-text">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                disabled={!order?.applicationName || validForm?.isAppNameValid || !order?.serviceResources?.isSonarQube || appShortNameApi?.data?.data}
                autoComplete="off"
                label="Application Short Name *"
                error={helperText || appShortNameExistsText}
                helperText={(helperText && <span>{errorText}</span>) || (appShortNameExistsText && <span>App short name already exists.</span>)}
                value={order?.appShortName || ''}
                onChange={(e) => setAppShortName(e.target.value)}
                className={validateInput()}
            />
        </Box>
    );
};

export default React.memo(AppShortName);
