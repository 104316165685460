import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/sso-okta/store';

export const Environments = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const environmentApi = useMutation(() => axios.get(endPoints.ssoOkta.environments));

    const setEnv = (environment, envShortName) => {
        setOrder({
            ...order,
            environment,
            envShortName,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setEnv(selected.value, selected?.data?.backendName);

    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            setOptions(environmentApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName, data: app })));
        }
    }, [environmentApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.environment) {
            setOrder({ ...order, environment: options?.[0].value, envShortName: options?.[0].data?.backendName });
        }
        if (options && options?.length && order?.environment) {
            const environment = isExistsInOptions(order?.environment);
            setOrder({ ...order, environment: environment?.value, envShortName: environment?.data?.backendName });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        environmentApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.environment && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.environment, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="environment-autocomplete"
                key="environment"
                label="Environment *"
                value={order?.environment || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={environmentApi.isLoading}
                isLoading={environmentApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Environments);
