export const getPayload = ({ data, type }) => ({
    isDraft: data?.isDraft || false,
    name: data?.appName,
    businessCi: data?.businessCI,
    financialDetails: data?.financial,
    recoveryPriority: data?.recoveryPriority,
    projectName: data?.projectName !== '-' ? data?.projectName : '-',
    billingEntity: data?.billingEntity || null,
    sector: data?.sector || null,
    owner: {
        id: data?.appOwnerDetails?.gpid,
        name: data?.appOwnerDetails?.name,
        emailId: data?.appOwnerDetails?.email,
    },
    id: type === 'create' ? '' : data?.id,
});
export default getPayload;
