// AuthComponent.js
import axios from 'axios';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';

const AuthComponent = () => {
    // const getAccessToken = async (clientId, clientSecret) => {
    //     // eslint-disable-next-line no-useless-catch
    //     try {
    //         const credentials = `${clientId}:${clientSecret}`;
    //         const base64Credentials = Buffer.from(credentials).toString('base64');
    //         const response = await axios.post(endPoints.registertedModelAccessToken, null, {
    //             headers: {
    //                 Authorization: `Basic ${base64Credentials}`,
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //         });
    //         const accessToken = response.data;
    //         return accessToken;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    const getAccessToken = () => {
        const cmpAccessToken = localStorage.getItem('accessToken');
        return cmpAccessToken;
    };

    const getOrRefreshAccessToken = async (clientId, clientSecret) => {
        const accessToken = localStorage.getItem('dlopsAccessToken');
        if (accessToken) {
            return accessToken;
        }
        try {
            const newAccessToken = await getAccessToken(clientId, clientSecret);
            localStorage.setItem('dlopsAccessToken', newAccessToken.access_token);
            // Return the new access token
            return newAccessToken.access_token;
        } catch (error) {
            return error;
        }
    };

    const getAuthTokenForObservability = async (clientId, clientSecret) => {
        try {
            const accessTokenForOb = localStorage.getItem('accessTokenForObserv');
            // const expiredTimeForOb = localStorage.getItem('tokenExpirationTimeForObserv');

            if (accessTokenForOb) {
                return accessTokenForOb;
            }
            const credentials = `${clientId}:${clientSecret}`;
            const base64Credentials = Buffer.from(credentials).toString('base64');
            const response = await axios.post(endPoints.observabilityAccessToken, null, {
                headers: {
                    Authorization: `Basic ${base64Credentials}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            const newAccessToken = response.data;
            localStorage.setItem('accessTokenForObserv', newAccessToken.access_token);
            return newAccessToken.access_token;
        } catch (error) {
            return error;
        }
    };

    const getOrRefreshAccessTokenForObservability = async (clientId, clientSecret) => {
        // Delegate the token retrieval to getAuthTokenForObservability
        return getAuthTokenForObservability(clientId, clientSecret);
    };

    const handleTokenError = async (error, endpoint, method, payload) => {
        if (error.response && error.response.status === 401) {
            try {
                const newAccessToken = await getAccessToken;
                // if (tokenVariable === 'registertedToken') {
                //     localStorage.setItem('dlopsAccessToken', newAccessToken.access_token);
                // } else if (tokenVariable === 'observabilityToken') {
                //     localStorage.setItem('accessTokenForObserv', newAccessToken.access_token);
                // }

                const config = {
                    headers: {
                        Authorization: `Bearer ${newAccessToken}`,
                    },
                };
                let responseData;
                switch (method.toLowerCase()) {
                    case 'get':
                        responseData = await axios.get(endpoint, config);
                        break;
                    case 'post':
                        responseData = await axios.post(endpoint, payload, config);
                        break;
                    default:
                        throw new Error('Unsupported HTTP method');
                }
                return responseData.data;
            } catch (refreshError) {
                return refreshError;
            }
        } else {
            return error;
        }
    };
    return {
        getAccessToken,
        getOrRefreshAccessToken,
        handleTokenError,
        getAuthTokenForObservability,
        getOrRefreshAccessTokenForObservability,
    };
};

export default AuthComponent;
