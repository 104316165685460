import React from 'react';
import { Box, InputLabel, Stack, Typography } from '@mui/material';

import { showRITMForEnvironments } from 'pages/mdt-onboarding/utils';
import { toggleModal, orderPayload } from 'pages/mdt-onboarding/store';
import { useRecoilState } from 'recoil';
import FilePatternWrapper from './FilePatternWrapper';
import styles from './style';

const SourcePatternTextArea = () => {
    const [modal] = useRecoilState(toggleModal);
    const [order] = useRecoilState(orderPayload);

    return (
        <Stack direction="row" alignItems="center" marginTop={showRITMForEnvironments.includes(order?.environment) ? '-80px' : '-11px'}>
            <Box sx={styles.filenamePattern} data-testid="short-desc-box">
                <Box sx={styles.filenamePatternHeader}>
                    <InputLabel htmlFor="short-description">
                        <Box sx={styles.filenamePatternText}>Source Filename Patterns *</Box>
                    </InputLabel>
                    <FilePatternWrapper />
                </Box>
                {modal?.sourceFileNamePatternAdded && modal?.sourceFileNamePatternAdded?.[0]?.fileName && (
                    <Box sx={styles?.addedFilePattern}>
                        {modal?.sourceFileNamePatternAdded?.map((pattern, key) => {
                            if (pattern?.fileName) {
                                return (
                                    <Typography>
                                        {key + 1}. {pattern?.fileName}
                                    </Typography>
                                );
                            }
                            return '';
                        }) || ''}
                    </Box>
                )}
            </Box>
        </Stack>
    );
};

export default SourcePatternTextArea;
