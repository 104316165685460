/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { catalogs } from 'store';
import { onBoardDevelopersServiceCode } from 'utils/catalogServiceCode';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import Styles from 'pages/onboard-developers/style';

const ProvisioningHeader = () => {
    const cmpCatalogs = useRecoilValue(catalogs);
    // const getDisImage = (code) => {
    //     if (cmpCatalogs?.length) {
    //         const result = cmpCatalogs.find((service) => service.catalogDefinitionId === code);
    //         return result?.iconPath;
    //     }
    //     return null;
    // };
    const getProvisiongTitle = (code) => {
        if (cmpCatalogs?.length) {
            const result = cmpCatalogs.find((service) => service.catalogDefinitionId === code);
            return result?.displayName;
        }
        return '';
    };
    return (
        <Box>
            <StickyHeader
                title={`${getProvisiongTitle(onBoardDevelopersServiceCode)} `}
                sx={Styles.ProvisioningHeader}
                width="100px"
                infoTitle="Info text here."
            />
        </Box>
    );
};

export default React.memo(ProvisioningHeader);
