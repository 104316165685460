import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'eventhub-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'eventhub-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'eventhub-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isConsumerGroupOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'eventhub-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'eventhub-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'eventhub-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'eventhub-sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        environment: null,
        appTier: null,
        appTierCode: null,
        sector: null,
        sectorDetails: null,
        region: null,
        regionDisplayName: null,
        hCode: null,
        hCodeDetails: null,
        costCenter: null,
        tags: [],
        estimatedCost: '0/month',
        namespaceName: null,
        performance: 'Standard',
        nsExist: 'no',
        throughputUnits: '1',
        isAutoInflate: 'no',
        autoInflateMaxThroughputUnits: null,
        processingUnits: '1',
        isGeoReplication: 'yes',
        eventHubDetails: [
            {
                eventHubName: '',
                partitionCount: 1,
                retention: null,
                isEventHubTouched: false,
                consumerGroupList: [{ value: '' }],
            },
        ],
        project: '',
        catalogCode: null,
        isTouched: false,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        cartMessage: false,
    },
});

export const environmentDetails = atom({
    key: 'eventhub-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
        Approval_Manager: '---',
        Data_Clasification: '---',
        Project: '---',
        'TLS Version': '---',
        Geo_Replication_Region: '---',
        Service_Endpoints: '---',
    },
});

export const costEstimateState = atom({
    key: 'eventhub-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'eventhub-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isValidNamespaceName = atom({
    key: 'eventhub-isValidNamespaceName',
    default: false,
});

export const validateMaxAutoInflateTPUnit = atom({
    key: 'eventhub-validateMaxAutoInflateTPUnit',
    default: false,
});

export const isInvalidEventhubName = atom({
    key: 'eventhub-isInValidEventhubName',
    default: false,
});

export const isEventhubNameExist = atom({
    key: 'eventhub-isEventhubNameExist',
    default: false,
});

export const isInvalidPartitionCount = atom({
    key: 'eventhub-isInvalidPartitionCount',
    default: false,
});
