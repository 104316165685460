export const getOrderPayload = (order, vsTypes, isRequired) => {
    const getCertificateDetails = () => {
        return {
            certificateOwnerOneId: order?.certificateOwnerOneDetails?.gpid,
            certificateOwnerOneName: order?.certificateOwnerOneDetails?.name,
            certificateOwnerTwoId: order?.certificateOwnerTwoDetails?.gpid,
            certificateOwnerTwoName: order?.certificateOwnerTwoDetails?.name,
        };
    };

    const payload = {
        applicationService: order?.applicationService,
        applicationOwner: order?.applicationOwnerDetails?.gpid || null,
        applicationOwnerDetails: order?.applicationOwnerDetails,
        sector: order?.sector,
        division: order?.division,
        applicationRegion: order?.applicationRegion,
        environment: order?.environment,
        programName: order?.program,
        department: order?.department,
        aksCluster: order?.aksCluster,
        aksNamespace: order?.aksNamespace,
        costCenter: order?.costCenter,
        ...(isRequired && { certificateDetails: getCertificateDetails() }),
        aksVirtualService: order?.aksVirtualService?.map((item) => {
            const getAksCorsPayload = (aksCors) => {
                return {
                    ...(aksCors?.allowedMethods?.length && { allowedMethods: aksCors?.allowedMethods }),
                    allowedOrigins: aksCors?.allowedOrigins?.map((origin) => origin?.allowedOrigin),
                    ...(aksCors?.allowedHeaders?.[0]?.allowedHeader && {
                        allowedHeaders: aksCors?.allowedHeaders?.map((header) => header?.allowedHeader?.label),
                    }),
                    ...(aksCors?.exposeHeaders?.[0]?.exposeHeader && { exposeHeaders: aksCors?.exposeHeaders?.map((header) => header?.exposeHeader?.label) }),
                };
            };

            return {
                virtualServiceType: vsTypes?.find((z) => z.label === item?.virtualServiceType)?.value,
                virtualServiceTypeName: item?.virtualServiceType,
                virtualServiceName: item?.virtualServiceName,
                hostNamesPrefix: item?.hostNamesPrefix?.map((host) => host?.hostNamePrefix),
                customDomainSelected: item?.customDomainSelected === 'Yes',
                ...(item?.customDomainSelected === 'Yes' && {
                    customDomain: [{ name: item?.customDomain?.[0]?.name }],
                    certificateCreationRequired: item?.certificateExists === false,
                }),
                corsPolicy: item?.corsPolicy === 'Yes',
                aksService: item?.aksService,
                ...(item?.corsPolicy === 'Yes' && { aksCors: getAksCorsPayload(item?.aksCors) }),
            };
        }),
    };

    return payload;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    if (data?.catalogServices?.[0]?.catalogConfig) {
        const response = data?.catalogServices?.[0]?.catalogConfig;
        const virtualServiceData = response?.aksVirtualService?.map((item) => {
            const aksCors = item?.aksCors;
            return {
                virtualServiceType: item?.virtualServiceTypeName,
                virtualServiceName: item?.virtualServiceName,
                hostNamesPrefix: item?.hostNamesPrefix?.map((host) => ({ hostNamePrefix: host, isHostNameTouched: false })),
                customDomainSelected: item?.customDomainSelected ? 'Yes' : 'No',
                customDomain: [
                    {
                        name: item?.customDomain?.[0]?.name || null,
                    },
                ],
                certificateExists: item?.certificateCreationRequired === false,
                corsPolicy: item?.corsPolicy ? 'Yes' : 'No',
                aksService: item?.aksService,
                aksCors: {
                    allowedHeaders: aksCors?.allowedHeaders
                        ? aksCors?.allowedHeaders?.map((header) => ({
                              allowedHeader: {
                                  label: header,
                                  value: header,
                              },
                              isAllowedHeaderTouched: false,
                          }))
                        : [{ allowedHeader: null, isInvalid: false, isAllowedHeaderTouched: false }],
                    allowedOrigins: aksCors?.allowedOrigins?.map((origin) => ({
                        allowedOrigin: origin,
                        isAllowedOriginTouched: false,
                        isInvalid: false,
                    })),
                    exposeHeaders: aksCors?.exposeHeaders
                        ? aksCors?.exposeHeaders?.map((header) => ({
                              exposeHeader: {
                                  label: header,
                                  value: header,
                              },
                              isExposeHeaderTouched: false,
                          }))
                        : [{ exposeHeader: null, isInvalid: false, isAllowedHeaderTouched: false }],
                    allowedMethods: aksCors?.allowedMethods,
                },
            };
        });
        return {
            applicationService: response?.applicationService,
            sector: response?.sector,
            division: response?.division,
            environment: response?.environment,
            applicationRegion: response?.applicationRegion,
            program: response?.programName === 'Global Shared' ? 'Not Applicable' : response?.programName,
            department: response?.department,
            aksCluster: response?.aksCluster,
            aksNamespace: response?.aksNamespace,
            costCenter: response?.costCenter,
            certificateOwnerOne: response?.certificateDetails?.certificateOwnerOneName || null,
            certificateOwnerOneDetails: response?.certificateDetails
                ? {
                      name: response?.certificateDetails?.certificateOwnerOneName,
                      gpid: response?.certificateDetails?.certificateOwnerOneId,
                  }
                : null,
            certificateOwnerTwo: response?.certificateDetails?.certificateOwnerTwoName || null,
            certificateOwnerTwoDetails: response?.certificateDetails
                ? {
                      name: response?.certificateDetails?.certificateOwnerTwoName,
                      gpid: response?.certificateDetails?.certificateOwnerTwoId,
                  }
                : null,

            applicationOwner: data?.manager?.name?.toLowerCase() !== 'system' ? data?.manager?.name : null,
            applicationOwnerDetails: data?.manager?.name?.toLowerCase() !== 'system' ? { name: data?.manager?.name, gpid: data?.manager?.id } : null,
            aksVirtualService: virtualServiceData,
        };
    }
    return null;
};

export const isDisplayField = (options, field) => {
    switch (field) {
        case 'appRegion':
        case 'division':
            return options?.length > 1;
        case 'program':
            return !(options?.length === 1 && options?.[0]?.value?.toLowerCase() === 'not applicable');
        default:
            return true;
    }
};

export const platformManaged = 'EIAP';

export const getRandomString = (length) => {
    const alphabets = 'abcdefghijklmnopqrstuvwxyz';
    const randomString = Array(length)
        .join()
        .split(',')
        .map(() => {
            return alphabets.charAt(Math.floor(Math.random() * alphabets.length));
        })
        .join('');
    return randomString;
};

export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
