import { styled } from '@mui/material/styles';
import { Box, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const approvalStyle = {
    container: {
        background: '#F8F8F8',
        color: '#000000',
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        height: '100%',
        // paddingLeft: 3,
        // paddingRight: 3,
        // paddingTop: 2,
    },
    loadingStack: { width: (theme) => theme.spacing('100%'), height: 'calc(100vh - 100px)' },
    approvalHeader: {
        wrapper: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            py: (theme) => theme.spacing(2),
            paddingRight: (theme) => theme.spacing('53px'),
            paddingLeft: (theme) => theme.spacing('53px'),
            borderBottom: '1px solid #E0E0E0',
            height: (theme) => theme.spacing('82px'),
        },
        container: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
        headWrapper: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            height: '62px',
            borderBottom: '1px solid #E0E0E0',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        header: { fontFamily: 'Stolzl', fontSize: '30px', fontWeight: 'medium' },
    },
    approvalfooter: {
        container: {
            paddingLeft: (theme) => theme.spacing('53px'),
            paddingRight: (theme) => theme.spacing('53px'),
            paddingTop: (theme) => theme.spacing('20px'),
        },
        rejectbtn: { textTransform: 'none' },
        approvebtn: {
            textTransform: 'none',
            // backgroundColor: '#91C24C', '&:hover': { backgroundColor: '#91C24C' }
        },
    },
    approvalList: {
        container: {
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            paddingTop: (theme) => theme.spacing('26px'),
            paddingBottom: 1,
            color: (theme) => theme.palette.white,
            paddingLeft: (theme) => theme.spacing('53px'),
            paddingRight: (theme) => theme.spacing('53px'),
            backgroundColor: '#F8F8F8',
        },
        table: {
            tableWrapper: {
                marginTop: '4px',
                borderRadius: '3px',
                boxShadow: '1px 1px 2px #00000014',
            },
            tableRow: {
                background: 'transparent !important',
                border: '0 !important',
            },
            tableCell: {
                border: '0 !important',
            },
            orderIdBtn: {
                padding: '0px',
                //   textDecoration: 'underline',
                cursor: 'pointer',
            },
            approvalBtn: {
                cursor: 'pointer',
                color: '#90C04B',
            },
            rejectBtn: {
                cursor: 'pointer',
                color: '#F4273B',
            },
            returnToUserBtn: {
                cursor: 'pointer',
                color: '#0047BA',
                transform: 'rotateY(175deg)',
            },
            main: {
                marginTop: '-9px',
                borderSpacing: '0 4px',
                minWidth: 500,
            },
            container: {
                flexShrink: 0,
                ml: 2.5,
            },
            icon: {
                color: (theme) => theme.palette.black.main,
            },
            checkbox: {
                height: '32px',
                color: (theme) => theme.palette.white,
            },
            checkbox_1: {
                height: '30px',
                fill: '#0047BA',
            },
            tableFooter: { height: '70px', border: '1px solid rgba(224, 224, 224, 1)', backgroundColor: '#FFFFFF' },
            pagination: {
                background: '#ffffff',
                fontFamily: 'Roboto',
                fontSize: '14px',
                width: 'auto',
                color: (theme) => theme.palette.black.main,
                border: '1px solid #E0E0E0',
                '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
                    // fontWeight: "bold",
                    // color: "blue"
                    display: 'none',
                },
                '.MuiTablePagination-spacer': {
                    flex: 0,
                },
                '.MuiToolbar-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                },
                '.MuiTablePagination-displayedRows': {
                    fontWeight: 500,
                    fontSize: '1rem',
                    color: '#1E1E1E',
                    paddingTop: '2px',
                },
            },
        },
        noapprovalcontainer: { backgroundColor: '#FAFAFA' },
        noapprovalmsg: { fontFamily: 'Stolzl', fontSize: '15px', color: '#000000', fontWeigtht: 'medium' },
        noorderapproval: { fontFamily: 'Roboto', fontWeight: 'normal', color: '#000000', fontSize: '16px' },
        footercontainer: { paddingLeft: '53px', paddingRight: '53px' },
        footermsg: { fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal' },
    },
    approvalDetails: {
        approvalText: { fontFamily: 'Roboto', fontSize: '14px', color: '#000000', fontWeight: 'normal' },
        financetable: { width: { lg: 750, xl: 750 } },
        multifinancetable: { width: { lg: 880, xl: 880 } },
        iconbtn: {
            backgroundColor: '#0047BA',
            '&:hover': {
                backgroundColor: '#0047BA',
            },
        },
        editbtn: {
            color: '#FFFFFF',
            '&:hover': {
                color: '#FFFFFF',
            },
        },
        label: {
            fontSize: 14,
            mb: 0.5,
            whiteSpace: 'pre-wrap',
        },
        span: {
            color: '#000000',
        },
        textArea: {
            width: '100%',
            background: (theme) => theme.palette.white,
            padding: 8,
        },
        footer: {
            marginLeft: 'auto',
        },
        approverComments: {
            fontFamily: 'Stolzl',
            fontWeight: 'medium',
            fontSize: '14px',
            paddingBottom: '5px',
        },
        summary: {
            marginBottom: 1,
        },
        note: {
            color: '#DB1E36',
        },
        finanacetext: { fontFamily: 'Stolzl', fontWeight: 'medium', fontSize: '22px', color: '#00000' },
        textTransformBtn: {
            textTransform: 'none',
        },
        approvebtn: {
            textTransform: 'none',

            // backgroundColor: '#91C24C  !important',
            // color: '#FFFFFF',
            // '&:hover': {
            //     backgroundColor: '#91C24C  !important',
            // },
        },
        rejectBtn: {
            textTransform: 'none',

            // backgroundColor: '#DB1E36', color: '#FFFFFF'
        },
        costCenter: { width: '100%' },
    },
    multiApprovals: {
        formLabel: {
            fontSize: '13px',
        },
        textArea: {
            width: '100%',
            background: (theme) => theme.palette.white,
            padding: 8,
        },
        backBtn: {
            fontSize: '12px',
            color: (theme) => theme.palette.white,
            marginLeft: '-15px',
            paddingRight: '25px',
        },
        span: {
            color: '#000000',
        },
        label: {
            fontSize: 14,
            whiteSpace: 'pre-wrap',
        },
        orderIdList: {
            background: (theme) => theme.palette.blue.main,
            width: '90px',
            height: '24px',
            border: (theme) => `2px solid ${theme.palette.blue.main}`,
            borderRadius: 1,
            color: (theme) => theme.palette.white,
            fontSize: 15,
            marginRight: 1,
            marginBottom: 1,
        },
        orderId: {
            paddingTop: 0.2,
            backgroundColor: (theme) => theme.palette.blue.main,
            color: '#FFFFFF',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 'medium',
            paddingBottom: 0.2,
            paddingLeft: 1,
            paddingRight: 1,
            cursor: 'pointer',
        },
        finacialCode: {
            paddingTop: 0.5,
            paddingBottom: 0.5,
            paddingLeft: 1,
            paddingRight: 1,
            background: (theme) => theme.palette.white,
            color: (theme) => theme.palette.blue.main,
            borderRadius: 1,
        },
        approvalheader: { fontFamily: 'Stolzl', fontSize: '25px', color: '#000000', fontWeight: 'medium' },
        approvaltext: { color: '#000000', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'normal' },
        checklabeltext: {
            color: '#0047BA',
            '&.Mui-checked': {
                color: '#0047BA',
            },
        },

        orderSummarystack: { mt: 2 },
        cancelbtn: { color: '#6E6F6F', borderColor: '#6E6F6F' },
        cancelbutton: {
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.2),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            textTransform: 'none',
            '&:hover': {
                borderWidth: (theme) => theme.spacing(0.2),
                borderColor: (theme) => theme.palette.cyan.main,
                color: (theme) => theme.palette.cyan.main,
            },
        },
    },
    orderDetails: {
        label: {
            marginBottom: 0.75,
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            color: '#000000',
        },
        label_1: {
            marginBottom: 0.75,
            whiteSpace: 'pre-wrap',
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            color: '#000000',
        },
        totaltext: {
            marginBottom: 0.75,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000000',
        },
        applicationname: {
            fontFamily: 'Stolzl',
            fontSize: '24px',
            fontWeight: 'medium',
            color: '#000000',
        },
        approvers: {
            fontFamily: 'Stolzl',
            fontSize: '18px',
            fontWeight: 'medium',
            color: '#000000',
        },
    },
    orderSummary: {
        table: {
            common: {
                minWidth: '650px',
                fontSize: '14px',
            },
            cell: {
                color: '#2b3a57',
            },
            row: {
                'td,th': {
                    color: (theme) => theme.palette.blue[30],
                },
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            },
        },
    },
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
        paddingLeft: 15,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        // borderTop: '1px solid rgba(224, 224, 224, 1)',
        //  marginTop: 12,
        color: '#242424',
        fontWeight: 'normal',
        // paddingTop:'4px',
        // paddingBottom:'4px',

        '&:first-of-type': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    paddingTop: '5px',
    height: '50px',
    '&:nth-of-type(1) td': {
        height: '60px',
        paddingTop: '15px',
    },
    // '&:nth-of-type(even)': {
    //     backgroundColor: '#ffffff',
    // },
    '&:hover': {
        backgroundColor: '#E5F5FC',
        // '&:last-child': {
        //     background: 'none',
        // },
    },
    // hide last border
    th: {
        marginLeft: 20,
    },
    '&:last-child td, &:last-child th': {},
}));

export const ApprovalDetailsContainer = styled(Box)(() => ({
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-10px',
}));

export const BoxWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 1,
    paddingBottom: 5,
}));

export const FinanceStyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '13px',
        paddingLeft: 15,
        paddingTop: 8,
        paddingBottom: 8,
        border: 0,
        marginLeft: 4,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#000000',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        paddingTop: 8,
        paddingBottom: 8,
        '&:first-of-type': {
            //   borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            // borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));

export const MembersStyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '13px',
        paddingLeft: 15,
        paddingTop: 8,
        paddingBottom: 8,
        border: 0,
        marginLeft: 4,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#000000',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: 'none',
        '&:first-of-type': {
            //   borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            // borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));
export const MultiFinanceStyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '13px',
        paddingLeft: 15,
        paddingTop: 8,
        paddingBottom: 8,
        border: 0,
        //     marginLeft:4,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#000000',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        paddingTop: 8,
        paddingBottom: 8,

        '&:first-of-type': {
            color: '#0047BA',

            //   borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            // borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));
export const FinanceStyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    paddingTop: '1px',
    borderTop: '1px',

    // '&:nth-of-type(odd)': {
    //     backgroundColor: '#ffffff', // '#99d9f1'
    // },
    // '&:nth-of-type(even)': {
    //     backgroundColor: '#ffffff',
    // },
    '&:hover': {
        // backgroundColor: theme.palette.cyan['90'],
        // '&:last-child': {
        //     background: 'none',
        // },
    },
    // hide last border

    '&:last-child td, &:last-child th': {},
}));

export default approvalStyle;
