// import groupBy from 'lodash/groupBy';
import { getLoggedUserGPID, converObjectToBlob, getValueFromKey } from 'utils';
import { RICatalogCode001, RICatalogCode001A } from 'pages/reference-implementation/utils/RICatalogCode';
import mappingLabel from 'utils/mappingLabel';

export const getPreviewPayload = ({ order }) => {
    const getADGroupOwnerId = () => {
        let ownerId = '';
        if (order?.nonPrivilegedADGroupOwnerDetails) {
            ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid
                ? order?.nonPrivilegedADGroupOwnerDetails?.gpid
                : order?.nonPrivilegedADGroupOwnerDetails?.id;
        } else if (order?.privilegedADGroupOwnerDetails) {
            ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
        }
        return ownerId;
    };
    const payload = {
        ...(order?.projectId && { 'project-id': order?.projectId }),
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        'budget-code': order?.budgetCode,
        ...(order?.billingInfo && { 'billing-information': order?.billingInfo }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'business-application': order?.businessApp,
        'manager-id': order?.appOwnerDetails?.gpid,
        ...(order?.businessAppService === 'Yes' && {
            'application-service': order?.appService,
        }),
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'business-owner-id': order?.businessContactDetails?.gpid,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-group': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
        ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-group': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
    };
    return payload;
};

export const getCostCalcPayload = ({ order }) => {
    return {
        'project-id': order?.projectId,
        sector: order?.sector,
        environment: order?.environment,
    };
};

export const getDownloadConfigPayload = ({ order = {} }) => {
    const payload = {
        'project-id': order?.projectId,
        'application-name': order?.applicationName,
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'budget-code': order?.budgetCode,
        'reader-ad-group': order?.adGroupReader || null,
        'contributor-ad-group': order?.adGroupContributor || null,
        requestorGpid: getLoggedUserGPID(),
    };
    return payload;
};

export const getOrderPayload = ({ order, uploadedFiles = [] }) => {
    const getADGroupOwnerId = () => {
        let ownerId = '';
        if (order?.nonPrivilegedADGroupOwnerDetails) {
            ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid
                ? order?.nonPrivilegedADGroupOwnerDetails?.gpid
                : order?.nonPrivilegedADGroupOwnerDetails?.id;
        } else if (order?.privilegedADGroupOwnerDetails) {
            ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
        }
        return ownerId;
    };
    const payload = {
        ...(order?.orderId && { 'order-id': order?.orderId }),
        ...(order?.projectId && { 'project-id': order?.projectId }),
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        'budget-code': order?.budgetCode,
        ...(order?.billingInfo && { 'billing-information': order?.billingInfo }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'business-application': order?.businessApp,
        'manager-id': order?.appOwnerDetails?.gpid,
        ...(order?.businessAppService === 'Yes' && {
            'application-service': order?.appService,
        }),
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'business-owner-id': order?.businessContactDetails?.gpid,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-group': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
        ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-group': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),
    };
    if ([RICatalogCode001, RICatalogCode001A]?.includes(order?.catalogCode)) {
        const newPayload = {
            ...payload,
            ...(order?.orderId && {
                deletedFiles: order?.deletedFiles || [],
            }),
        };
        const formData = new FormData();
        const blobPayload = converObjectToBlob(newPayload);
        formData.append('orderRequest', blobPayload);
        if (uploadedFiles && uploadedFiles?.length) {
            uploadedFiles?.forEach((file) => {
                if (!order?.orderId) {
                    formData.append('files', file);
                }
                if (!file?.blobRelativeLink && order?.orderId) {
                    formData.append('files', file);
                }
            });
        } else {
            formData.append('files', null);
        }
        if (uploadedFiles && uploadedFiles?.length && uploadedFiles?.every((file) => file?.blobRelativeLink)) {
            formData.append('files', new Blob([''], { type: 'application/octet-stream' }), '');
        }
        return formData;
    }
    return payload;
};

export const getResubmitOrderPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    const nonPrivilegedADGroup = catalog?.readerAdGroup?.length ? 'Yes' : 'No';
    const privilegedADGroup = catalog?.contributorAdGroup?.length ? 'Yes' : 'No';

    const payload = {
        ...(catalog?.projectId && { 'project-id': catalog?.projectId }),
        'business-unit': catalog?.businessUnit,
        sector: catalog?.sector,
        ...(['cgf']?.includes(catalog?.sector?.toLowerCase()) && { division: catalog?.division }),
        'budget-code': catalog?.costCenter,
        ...(catalog?.billingInformation && { 'billing-information': catalog?.billingInformation }),
        environment: catalog?.environment,
        'data-classification': catalog?.dataClassification,
        'business-application': catalog?.businessApplication,
        'manager-id': order?.manager?.id,
        ...(catalog?.businessApplication &&
            catalog?.applicationService && {
                'application-service': catalog?.applicationService,
            }),
        'application-name': catalog?.applicationName,
        'app-short-name': catalog?.applicationShortName,
        'business-owner-id': catalog?.businessOwnerId,
        ...(catalog?.readerAdGroup && catalog?.readerAdGroup?.length && { 'reader-ad-group': catalog?.readerAdGroup }),
        ...(catalog?.contributorAdGroup && catalog?.contributorAdGroup?.length && { 'contributor-ad-group': catalog?.contributorAdGroup }),
        ...(catalog?.environment?.toLowerCase() !== 'production' &&
            (nonPrivilegedADGroup === 'No' || privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id,
            }),
        ...(catalog?.environment?.toLowerCase() === 'production' &&
            privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id,
            }),
    };
    if ([RICatalogCode001, RICatalogCode001A]?.includes(order?.catalogCode)) {
        const newPaylod = { ...payload, deletedFiles: [], newFiles: [] };
        return newPaylod;
    }
    return payload;
};
// const getRoleAssignments = (roles = []) => {
//     const accessLevels = [...new Set(roles?.map((role) => role['access-levels'])?.flat(Infinity))];
//     const rolesobj = {};
//     if (!accessLevels?.includes(null)) {
//         accessLevels?.forEach((accessLevel) => {
//             rolesobj[accessLevel] =
//                 (accessLevel !== null && roles?.filter((role) => role['access-levels']?.includes(accessLevel))?.map((item) => item?.assignee)) || [];
//         });
//     }
//     return Object.keys(rolesobj)?.length > 0 ? rolesobj : null;
// };

export const snakeToTitle = (camelCase = '') => {
    const snakeCase = camelCase
        ?.toLowerCase()
        ?.split('-')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
    return snakeCase;
};
// const getResourceItem = (resourceType = '', resourceObj = {}) => {
//     const formattedObj = { 'Resource Type': snakeToTitle(resourceType), 'Resource Properties': {}, 'Role Assignments': null, 'Access Policy': null };

//     Object.keys(resourceObj)?.map((key) => {
//         if (key === 'role-assignments' || key === 'access-Policy') {
//             formattedObj[snakeToTitle(key)] = getRoleAssignments(resourceObj[key]);
//         }
//         if (key !== 'network' && key !== 'tags' && key !== 'role-assignments' && key !== 'access-Policy') {
//             formattedObj['Resource Properties'][key !== 'powerBi-Gateway-RequestUrl' ? snakeToTitle(key) : 'Power BI Gateway Request URL'] = resourceObj[key];
//         }
//         return '';
//     });
//     return formattedObj;
// };
// const getResources = (resources = {}) => {
//     const result = [];
//     Object.keys(resources)?.map((resource) => {
//         if (resource === 'resource-group' || resource === 'log-analytics-workspace' || resource === 'Others') {
//             result?.push(getResourceItem(resource, resources[resource]));
//         } else if (resource === 'blob-storage' || resource === 'data-lake-storage') {
//             const groupByStorageType = groupBy(resources[resource], 'storage-type');
//             Object.keys(groupByStorageType)?.map((item) => {
//                 const formattedObj = {
//                     'Resource Type': `${snakeToTitle(resource)} (${item?.split('_')?.[0]})`,
//                     'Resource Properties': { 'Storage Account Name(s)': [] },
//                     'Role Assignments': null,
//                     'Access Policy': null,
//                 };
//                 groupByStorageType?.[item]?.forEach((i) => {
//                     Object.keys(i)?.map((key) => {
//                         if (key === 'role-assignments' || key === 'access-Policy') {
//                             formattedObj[snakeToTitle(key)] = getRoleAssignments(i[key]);
//                         }
//                         if (key !== 'network' && key !== 'tags' && key !== 'role-assignments' && key !== 'storage-type' && key !== 'access-Policy') {
//                             if (key === 'storage-account-name') {
//                                 formattedObj['Resource Properties']['Storage Account Name(s)'] = [
//                                     ...formattedObj?.['Resource Properties']?.['Storage Account Name(s)'],
//                                     i?.['storage-account-name'],
//                                 ];
//                             } else {
//                                 formattedObj['Resource Properties'][snakeToTitle(key)] = i[key];
//                             }
//                         }
//                         return '';
//                     });
//                 });
//                 result.push(formattedObj);
//                 return '';
//             });
//         } else {
//             //  eslint-disable-next-line  no-lonely-if
//             if (resources[resource]) {
//                 resources[resource]?.map((item) => {
//                     result?.push(getResourceItem(resource, item));
//                     return '';
//                 });
//             }
//         }
//         return '';
//     });

//     const resourceWithAdGroup = result?.filter((item) => item['Role Assignments'] !== null) || [];
//     const resourceWithoutAdGroup = result?.filter((item) => item['Role Assignments'] === null) || [];
//     const sortByResourceProperties =
//         [
//             ...resourceWithoutAdGroup?.filter((item) => Object?.keys(item?.['Resource Properties'])?.length > 1),
//             ...resourceWithoutAdGroup?.filter((item) => Object?.keys(item?.['Resource Properties'])?.length === 1),
//         ] || [];

//     return [...resourceWithAdGroup, ...sortByResourceProperties];
// };

// export const getpreviewDetails = ({ order }) => {
//     const previewDetails = { resources: getResources(order?.environmentDetails?.resources) };
//     Object.keys(order?.environmentDetails).map((key) => {
//         if (!['estimated-cost', 'resources', 'tech-input-json']?.includes(key)) {
//             previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
//         }
//         return '';
//     });
//     return previewDetails;
// };
export const transformAccessLevels = (roleAssignments) => {
    if (!Array.isArray(roleAssignments)) return null;
    return roleAssignments.map((item) => {
        const { 'access-levels': accessLevels, ...rest } = item;
        return {
            ...rest,
            'access-levels': accessLevels?.length ? accessLevels : ['Group Assignment'],
        };
    });
};
const transformRoleAssignments = (roleAssignments, isOrderpage = false) => {
    if (!Array.isArray(roleAssignments)) return null;
    if (roleAssignments.every(({ 'access-levels': accessLevels }) => accessLevels === null)) {
        return null;
    }
    if (isOrderpage) {
        return roleAssignments.reduce((acc, { 'assignee-name': assigneeName, 'access-levels': accessLevels }) => {
            accessLevels?.forEach((accessLevel) => {
                if (!acc[accessLevel]) acc[accessLevel] = [];
                acc[accessLevel].push(assigneeName);
            });
            return acc;
        }, {});
    }
    return roleAssignments.reduce((acc, { assignee, 'access-levels': accessLevels }) => {
        accessLevels?.forEach((accessLevel) => {
            if (!acc[accessLevel]) acc[accessLevel] = [];
            acc[accessLevel].push(assignee);
        });
        return acc;
    }, {});
};
const getFormattedObject = ({ resourceType, resourceProperties, roleAssignments = null, accessPolicy = null }) => {
    return {
        'Resource Type': resourceType,
        'Resource Properties': resourceProperties,
        'Role Assignments': roleAssignments,
        'Access Policy': accessPolicy,
    };
};
const convertToObjectArray = (input) => {
    if (input && typeof input === 'object' && !Array.isArray(input)) {
        return [input];
    }
    return input;
};
const getlabelType = (item, resource) => {
    if (item[getValueFromKey(resource, mappingLabel)]) {
        return item[getValueFromKey(resource, mappingLabel)] !== 'DataFoundation' ? `(${item[getValueFromKey(resource, mappingLabel)]})` : '(Data Foundation)';
    }
    return '';
};
const getResourceType = (resource, componentId, item, catalogCode) => {
    let resourceType = '';

    if (
        resource !== 'data-factory' &&
        resource !== 'data-lake-storage' &&
        resource !== 'data-bricks-cluster' &&
        // [RICatalogCode001A]?.includes(catalogCode) &&
        (snakeToTitle(resource)?.toLowerCase() === snakeToTitle(componentId?.replace(/_/g, ' '))?.toLowerCase() || !componentId)
    ) {
        resourceType = `${snakeToTitle(resource)}`;
    } else if (['data-factory', 'data-lake-storage', 'data-bricks-cluster']?.includes(resource)) {
        resourceType = `${snakeToTitle(resource)} ${getlabelType(item, resource)}`;
    } else if (![RICatalogCode001A]?.includes(catalogCode)) {
        resourceType = `${snakeToTitle(resource)} `;
    }
    return resourceType;
};
const transformResourceProperties = (item) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
        if (
            typeof value === 'string' &&
            key !== 'adls-adGroup-id' &&
            key !== 'dataLake-storage-id' &&
            key !== 'storage-account-id' &&
            key !== 'data-factory-type' &&
            key !== 'storage-type' &&
            key !== 'data-bricks-cluster-type'
        ) {
            acc[snakeToTitle(key)] = value;
        }
        return acc;
    }, {});
};
export const getResources = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    const result = [];
    Object.keys(resources)?.map((resource) => {
        if (resource === 'blob-storage') {
            const storageAccountName = resources[resource]?.map((item) => item?.['storage-account-name']).join(', ');
            const resourceType = `${snakeToTitle(resource)} (${
                resources[resource][0]?.['storage-type'] !== 'DataFoundation' ? resources[resource][0]?.['storage-type'] : 'Data Foundation'
            })`;
            const resourceProperties = { 'Storage Account Name': storageAccountName };
            result.push(getFormattedObject({ resourceType, resourceProperties }));
            return '';
        }
        // if (resource === 'data-factory' && [RICatalogCode001A]?.includes(order?.catalogCode)) {
        //     const storageAccountName = resources[resource]?.map((item) => item?.name).join(', ');
        //     const resourceType = `${snakeToTitle(resource)}`;
        //     const resourceProperties = { Name: storageAccountName, 'Master ADF Name': resources[resource]?.[0]?.['master-adf-name'] };
        //     result.push(getFormattedObject({ resourceType, resourceProperties }));
        //     return '';
        // }
        const convertedResource = convertToObjectArray(resources[resource]);
        if (typeof convertedResource === 'string') return '';
        convertedResource?.map((item) => {
            const { 'component-id': componentId, ...rest } = item;
            const resourceType = getResourceType(resource, componentId, item, order?.catalogCode);
            const resourceProperties = transformResourceProperties(rest);
            const newRoleAssignments = transformAccessLevels(rest['role-assignments']);
            const roleAssignments = transformRoleAssignments(newRoleAssignments, order?.isOrderPage);
            const accessPolicy = transformRoleAssignments(rest['access-Policy'] || rest['access-policy'], order?.isOrderPage);
            result.push(getFormattedObject({ resourceType, resourceProperties, roleAssignments, accessPolicy }));
            return '';
        });
        return '';
    });
    return result;
};
export const getpreviewDetails = ({ order }) => {
    const previewDetails = { resources: getResources({ order }) };
    Object.keys(order?.environmentDetails).map((key) => {
        if (!['estimated-cost', 'resources', 'tech-input-json']?.includes(key)) {
            if (['subscription']?.includes(key) && typeof order?.environmentDetails?.[key] === 'object') {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key]?.subscription;
            } else {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
            }
        }
        return '';
    });
    return previewDetails;
};
