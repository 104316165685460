/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select, { CssTextField } from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';

const ApplicationCI = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(order?.applicationCI || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.applicationCI || '');
    const [options, setOptions] = React.useState([]);

    const applicationCIApi = useMutation(() => axios.get(`${endPoints?.day2Operation?.Day2Common?.applicationCI}?searchKey=${ccInputValue}`));

    const setAppService = (applicationCI, appData) => {
        setOrder({
            ...order,
            applicationCI: applicationCI?.label,
            applicationCIDetails: applicationCI,
            entityDetails: appData,
            applicationName: null,
            appShortName: null,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                applicationCIApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (applicationCIApi?.isSuccess) {
            if (applicationCIApi?.data?.data?.appProfiles) {
                const combinedValue = applicationCIApi?.data?.data?.appProfiles?.map((app) => ({ label: `${app.name}`, value: `${app.name}`, data: app }));
                setOptions(combinedValue);
            }
        }
    }, [applicationCIApi?.isSuccess]);

    React.useEffect(() => {
        resetOptions();
        applicationCIApi.mutate();
    }, []);

    React.useEffect(() => {
        if (value !== null && value?.data?.id) {
            setAppService(value, value?.data);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.applicationCI && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationCI, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            {order?.adoPlatform === 'Production' ? (
                <Select
                    dataTestId="app-ci-autocomplete"
                    key="applicationCI"
                    loading={applicationCIApi?.isLoading}
                    label="Application Service CI *"
                    placeholder="Type to search"
                    disabled={!order?.adoPlatform}
                    value={order?.applicationCI || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(x) => x}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    freeSolo
                    isRequired={isTouched}
                />
            ) : (
                <>
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        data-testid="app-ci-text"
                        disabled
                        autoComplete="off"
                        label="Application Service CI *"
                        value={order?.applicationCI || ''}
                    />
                </>
            )}
        </Box>
    );
};

export default React.memo(ApplicationCI);
