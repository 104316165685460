import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { Grid, Stack } from '@mui/material';
import FormCard from 'pages/provisioning/_components/FormCard';
import Forms from 'pages/provisioning/couchbase/components/Form';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { getNodeCostPayload } from 'pages/provisioning/couchbase/getNodePayload';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ConfirmDD from 'pages/provisioning/couchbase/ConfirmDD';

const NodesForm = ({ indexVal }) => {
    const [order] = useRecoilState(sspSubmitOrder);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const cost = useMutation((payload) => axios.post(endPoints?.ssp?.costCalculation, payload));

    const setEstimatedCost = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    const checkNodeVarConditions = () =>
        order?.sector &&
        order?.region &&
        order?.environment &&
        order?.tags &&
        order?.billingEntity &&
        order?.hCodeDetails &&
        order?.envDetails &&
        order?.nodes?.[indexVal]?.osTypeVersion &&
        order?.nodes?.[indexVal]?.vmTypeDetails?.name &&
        order?.nodes?.[indexVal]?.quantity &&
        order?.nodes?.[indexVal]?.isConfigDiskFulfilled &&
        order?.nodes?.[indexVal]?.haDeployment &&
        (order?.nodes?.[indexVal]?.haDeployment === 'Availability Zones' ? order?.nodes?.[indexVal]?.availabilityZone : true) &&
        order?.nodes?.[indexVal]?.domain &&
        order?.nodes?.[indexVal]?.serverRefresh === false;

    React.useEffect(() => {
        if (checkNodeVarConditions()) {
            cost.mutate(getNodeCostPayload({ order, indexVal }));
        }
        // eslint-disable-next-line
    }, [
        order?.sector,
        order?.region,
        order?.environment,
        order?.envDetails,
        order?.tags,
        order?.billingEntity,
        order?.hCodeDetails,
        order?.nodes?.[indexVal]?.osTypeVersion,
        order?.nodes?.[indexVal]?.vmTypeDetails?.name,
        order?.nodes?.[indexVal]?.isConfigDiskFulfilled,
        order?.nodes?.[indexVal]?.quantity,
        order?.nodes?.[indexVal]?.haDeployment,
        order?.nodes?.[indexVal]?.availabilityZone,
        order?.nodes?.[indexVal]?.domain,
        order?.nodes?.[indexVal]?.serverRefresh,
    ]);

    React.useEffect(() => {
        if (cost.data?.data && checkNodeVarConditions()) {
            setEstimatedCost('estimatedCost', cost.data?.data, indexVal);
        }
        // eslint-disable-next-line
    }, [cost.isSuccess]);

    return (
        <React.Fragment key={indexVal}>
            <Grid item xs={12} key={indexVal}>
                <FormCard title={`Node ${(indexVal + 1).toString()}`} indexVal={indexVal}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Forms.OsType indexVal={indexVal} />
                                <Forms.Domain indexVal={indexVal} />
                                <Forms.DataDisk indexVal={indexVal} />
                                <Forms.SamAccountId indexVal={indexVal} />
                                <Forms.SslCertificate indexVal={indexVal} />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Forms.VmType indexVal={indexVal} />
                                <Forms.HaDeployment indexVal={indexVal} />
                                {order?.nodes?.[indexVal]?.haDeployment === 'Availability Zones' && <Forms.AvailabilityZone indexVal={indexVal} />}
                                <Forms.TimeZone indexVal={indexVal} />
                            </Stack>
                        </Grid>
                    </Grid>
                </FormCard>
            </Grid>
            <ConfirmDD indexVal={indexVal} />
        </React.Fragment>
    );
};

NodesForm.propTypes = {
    indexVal: PropTypes.number,
};

NodesForm.defaultProps = {
    indexVal: 0,
};

export default NodesForm;
