/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, Tooltip } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import { appdateRangeValue, appdashboardFilter } from './store';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload?.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};
const CustomYAxisTick = ({ x, y, payload }) => {
    // eslint-disable-next-line
    const value = payload.value;
    let yaxis = (
        <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
            0
        </text>
    );
    yaxis = (
        <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
            {Math.floor(value)}
        </text>
    );
    return yaxis;
};
const ApplicationOnBoardChart = () => {
    const [data, setData] = React.useState([]);
    const [totalApplications, setTotalApplications] = React.useState('');
    const [appdateRange, setappDateRange] = useRecoilState(appdateRangeValue);
    const [dashboardFilterObj, setAppDashboardFilterObj] = useRecoilState(appdashboardFilter);

    const query = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(appdateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(appdateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            sectors: dashboardFilterObj?.sector || [],
            metricType: 'applicationsOnboarded',
        };
        setData([]);
        query.mutate(payloadObj);
    }, [appdateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (query?.isSuccess) {
            setData(
                query?.data?.data?.map((item) => ({
                    name: DateTime.fromISO(item?.key_as_string, { zone: 'utc' }).toFormat('LLL yy'),
                    value: item?.doc_count,
                })) || []
            );
            setTotalApplications(query?.data?.data?.reduce((acc, item) => acc + parseInt(item?.doc_count, 10), 0));
        }
    }, [query?.isSuccess]);

    React.useEffect(() => {
        return () => {
            const dt = new Date();
            dt.setMonth(dt.getMonth() - 5);
            setappDateRange({ startDate: dt.toString(), endDate: new Date() });
            setAppDashboardFilterObj({ sector: null });
        };
        // eslint-disable-next-line
    }, []);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            return (
                <Stack direction="row" sx={dashboardStyle.provisionedServices.cutomtooltipWrapper}>
                    <Box sx={{ ...dashboardStyle.provisionedServices.cutomtooltip, background: payload[0].payload.fill }} />
                    <Typography sx={dashboardStyle.provisionedServices.typographyStyle}>
                        Application(s):<b>{payload[0]?.payload?.value}</b>
                    </Typography>
                </Stack>
            );
        }
        return null;
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {query.isLoading && (
                <Box sx={dashboardStyle.ApplicationOnBoardStyle.circularProgress_wrapper}>
                    <CircularProgress sx={dashboardStyle.ApplicationOnBoardStyle.circularProg} size={16} />
                </Box>
            )}
            {!query.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.ApplicationOnBoardStyle.circularProgress_wrapper}>No data available</Box>}
            {!query.isLoading && data?.length > 0 && (
                <Box sx={dashboardStyle.ApplicationOnBoardStyle.chart_wrapper} width="50%" className="charScrollbar">
                    <ResponsiveContainer width="90%" height={280}>
                        <BarChart data={data}>
                            <XAxis interval={0} dataKey="name" height={60} tickCount={10} tick={<CustomizedAxisTick />} />
                            <YAxis domain={[0, 'dataMax + 2']} tick={<CustomYAxisTick />} />
                            <Tooltip content={<CustomTooltip />} cursor={false} />
                            <Bar type="monotone" dataKey="value" barSize={25} fill="#0047BA" label={{ position: 'top' }} radius={[25, 25, 0, 0]} />
                        </BarChart>
                    </ResponsiveContainer>
                    <Box width="50%" height={280} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '18px', fontStyle: 'Roboto' }}>Total Applications Onboarded</Typography>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '22px', fontStyle: 'Stolzl' }}>{totalApplications || 0}</Typography>
                    </Box>
                </Box>
            )}
        </ErrorBoundary>
    );
};
export default React.memo(ApplicationOnBoardChart);
