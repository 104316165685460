import { atom } from 'recoil';

export const OrderPayload = atom({
    key: 'MYIDM-OrderPayload',
    default: {
        adGroup: null,
        businessApplication: null,
        businessApplicationDetails: null,
        environment: null,
        envShortName: null,
        sector: null,
        members: null,
        isTouched: false,
        isNewADGroup: null,
        appShortName: null,
        groupOwner: null,
        groupOwnerDetails: null,
        adGroupType: null,
        adGroupTypeDetails: null,
        applicationName: null,
        adGroupMembers: null,
        isUniversalADGroup: false,
        crossDomainUser: null,
        appService: null,
        mypamsafeDetails: [],
        estimatedCost: {
            details: [],
            totalRecurringCost: {
                frequency: 'yearly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'The License cost for safe in myPAM per user per year is $350 and cost is not estimated in CMP for existing user with safe details.',
        },
    },
});
export default OrderPayload;

export const adGroupErrorMsg = atom({
    key: 'MYIDM-adGroupErrorMsg',
    default: false,
});

export const businessAppErrorMsg = atom({
    key: 'MYIDM-businessAppErrorMsg',
    default: false,
});

export const memberForm = atom({
    key: 'MYIDM-memberForm',
    default: {
        selectedMember: null,
        member: null,
    },
});

export const selectedMembersList = atom({
    key: 'MYIDM-selectedMembersList',
    default: [],
});

export const currentSelection = atom({
    key: 'MYIDM-currentSelection',
    default: null,
});

export const optionsList = atom({
    key: 'MYIDM-optionsList',
    default: [],
});

export const invalidMember = atom({
    key: 'MYIDM-invalidMember',
    default: true,
});

export const toggleConfirmModel = atom({
    key: 'MYIDM-toggleConfirmModel',
    default: {
        isOpen: false,
        type: 'delete',
        message: '',
        action: false,
        removeAll: false,
        removeAllAction: false,
    },
});

export const isAppShortNameValid = atom({
    key: 'MYIDM-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'MYIDM-isAppShortNameExist',
    default: false,
});

export const orderId = atom({
    key: 'MYIDM-orderId',
    default: undefined,
});

export const orderSuccessPopup = atom({
    key: 'MYIDM-orderSuccessPopup',
    default: false,
});

export const showisExistADGrp = atom({
    key: 'MYIDM-showisExistADGrp',
    default: false,
});
export const isAppNameValid = atom({
    key: 'MYIDM-isAppNameValid',
    default: true,
});

export const isMembersApiLoading = atom({
    key: 'MYIDM-isMembersApiLoading',
    default: false,
});

export const toggleSspModels = atom({
    key: 'myidm-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isMemberlist: false,
    },
});

export const costEstimateState = atom({
    key: 'myidm-costEstimateState',
    default: null,
});
export const costApiLoading = atom({
    key: 'myidm-costApiLoading',
    default: false,
});
