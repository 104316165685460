import React from 'react';
import { Stack } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { privateEndPointCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddVnet, landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import { useTranslation } from 'react-i18next';
import LandingZoneStyle, { CssTextField } from '../../LandingZoneStyle';

const filter = createFilterOptions();
const Vnet = () => {
    const { t } = useTranslation();
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDVnet, setLzDDVnet] = useRecoilState(ddVnet);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const vnetGetApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.Vnet}/location/${payload?.region}/subscription/${payload?.subscriptionId}`)
    );

    const setVnet = (vNet = null, vNetDetails = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                vNet,
                vNetDetails,
                subNet: null,
                subNetDetails: null,
            });
        }
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setVnet();
    };

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const vnet = options?.find((app) => lzModal?.lzDetails?.config?.vnet?.name === app?.label);
            if (vnet) setLzDDVnet({ ...lzDDVnet, isLoaded: true, data: vnet });
        }
    }, [options]);

    React.useEffect(() => {
        if (vnetGetApi?.isSuccess) {
            if (vnetGetApi?.data?.data) {
                setLzDDVnet({ ...lzDDVnet, isLoaded: true });
                const combinedValue = vnetGetApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, resourceId: app?.resourceId }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [vnetGetApi?.isSuccess]);

    React.useEffect(() => {
        if (
            landZoneForm?.cloudService !== privateEndPointCatalogServiceCode &&
            landZoneForm.region &&
            landZoneForm?.subscriptionDetails &&
            !lzModal?.lzDetails
        ) {
            resetOptions();
            vnetGetApi.mutate({ region: landZoneForm?.regionDetails?.value, subscriptionId: landZoneForm?.subscriptionDetails?.id });
        } else if (!lzModal?.lzDetails) {
            resetOptions();
        }
    }, [landZoneForm.region, landZoneForm?.subscriptionDetails, landingZoneForm?.resGroupDetails]);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            setVnet(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (lzModal?.lzDetails?.region?.code && lzModal?.lzDetails?.config?.subscription?.id && lzModal?.lzDetails) {
            vnetGetApi.mutate({
                region: lzModal?.lzDetails?.region?.code,
                subscriptionId: lzModal?.lzDetails?.config?.subscription?.id,
            });
        }
    }, [lzModal?.lzDetails]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Select
                    key="VNet"
                    noOptionsText="No options"
                    loading={vnetGetApi?.isLoading}
                    forcePopupIcon={false}
                    disabled={vnetGetApi?.isLoading}
                    value={landZoneForm?.vNet}
                    onChange={(_event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demoVnet"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={LandingZoneStyle.select}
                    // freeSolo
                    disableClearable={false}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="VNet"
                            InputProps={{
                                ...params.InputProps,
                            }}
                            fullWidth
                        />
                    )}
                />
                <InfoImg title={t([`landingZone.infoText.vnet`])} />
            </Stack>
        </>
    );
};

export default React.memo(Vnet);
