export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        instanceName: catalogConfig?.instanceName,
        fqdns: catalogConfig?.fqdns,
        instanceFrontendPoolName: catalogConfig?.frontEndPoolName,
        bpPoolName: catalogConfig?.backEndPools[0]?.poolName,
        poolConfigurations: catalogConfig?.backEndPools[0]?.poolConfig,
        virtualMachine: catalogConfig?.backEndPools[0]?.virtualMachines?.map((vm) => ({
            label: vm?.virtualMachineName,
            value: vm?.virtualMachineName,
            ipAddress: vm?.ipAddress,
        })),
        vNet: environmentDetails?.virtualNetwork,
        rulesName: catalogConfig?.loadBalancerRules[0]?.rulesName,
        protocols: catalogConfig?.loadBalancerRules[0]?.protocol,
        frontendPort: catalogConfig?.loadBalancerRules[0]?.frontEndPort,
        backendPort: catalogConfig?.loadBalancerRules[0]?.backEndPort,
        sessionPersistance: catalogConfig?.loadBalancerRules[0]?.sessionPersistance,
        ...(catalogConfig?.loadBalancerRules[0]?.protocol?.toLowerCase() === 'tcp' && {
            timeOut: catalogConfig?.loadBalancerRules[0]?.idealTimeout,
        }),
        healthProbeName: catalogConfig?.loadBalancerRules[0]?.healthProbe?.healthProbeName,
        hpProtocol: catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols,
        port: catalogConfig?.loadBalancerRules[0]?.healthProbe?.port,
        ...(['http', 'https'].includes(catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols?.toLowerCase()) && {
            urlPath: catalogConfig?.loadBalancerRules[0]?.healthProbe?.pathUrl,
        }),
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        instanceName: catalogConfig?.instanceName,
        fqdns: catalogConfig?.fqdns,
        instanceFrontendPoolName: catalogConfig?.frontEndPoolName,
        bpPoolName: catalogConfig?.backEndPools[0]?.poolName,
        poolConfigurations: catalogConfig?.backEndPools[0]?.poolConfig,
        virtualMachine: catalogConfig?.backEndPools[0]?.virtualMachines?.map((vm) => ({
            label: vm?.virtualMachineName,
            value: vm?.virtualMachineName,
            ipAddress: vm?.ipAddress,
        })),
        vNet: environmentDetails?.virtualNetwork,
        rulesName: catalogConfig?.loadBalancerRules[0]?.rulesName,
        protocols: catalogConfig?.loadBalancerRules[0]?.protocol,
        frontendPort: catalogConfig?.loadBalancerRules[0]?.frontEndPort,
        backendPort: catalogConfig?.loadBalancerRules[0]?.backEndPort,
        sessionPersistance: catalogConfig?.loadBalancerRules[0]?.sessionPersistance,
        ...(catalogConfig?.loadBalancerRules[0]?.protocol?.toLowerCase() === 'tcp' && {
            timeOut: catalogConfig?.loadBalancerRules[0]?.idealTimeout,
        }),
        healthProbeName: catalogConfig?.loadBalancerRules[0]?.healthProbe?.healthProbeName,
        hpProtocol: catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols,
        port: catalogConfig?.loadBalancerRules[0]?.healthProbe?.port,
        ...(['http', 'https'].includes(catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols?.toLowerCase()) && {
            urlPath: catalogConfig?.loadBalancerRules[0]?.healthProbe?.pathUrl,
        }),
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    sectorDetails: null,
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    instanceName: '',
    fqdns: '',
    instanceFrontendPoolName: '',
    bpPoolName: '',
    poolConfigurations: 'nic',
    virtualMachine: null,
    vNet: null,
    rulesName: '',
    protocols: null,
    frontendPort: '',
    backendPort: '',
    sessionPersistance: null,
    timeOut: 15,
    healthProbeName: '',
    hpProtocol: null,
    hpProtocolDetails: null,
    port: '',
    urlPath: '',
    hCode: null,
    hCodeDetails: null,
    hoursOfOperation: null,
    hoursOfOperationDetail: null,
    tags: [],
    tagsQc: null,
    estimatedCost: null,
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
};
export const resetForQuickConfig = {
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    instanceName: '',
    healthProbeName: '',
};
export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
    Approval_Manager: '---',
    Subnet_Name: '---',
    Virtual_Network: '---',
    'TCP Reset': '---',
    'Floating IP': '---',
    Type: '---',
    SKU: '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isBackendPoolsOpen: false,
    isHealthProbeOpen: false,
    isSubmitOrderSuccess: false,
    isUpdatedToCart: false,
    isCostSplitOpen: false,
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalRecurringCost: {
        frequency: 'per rule',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};
export const isBpFormFilled = ({ order }) => !(order?.bpPoolName && order?.poolConfigurations && order?.virtualMachine?.length && order?.vNet);
export const isHpFormFilled = ({ order, validPort }) =>
    !(
        order?.healthProbeName &&
        order?.port &&
        !validPort &&
        order?.hpProtocol &&
        (['https', 'http'].includes(order?.hpProtocol?.toLowerCase()) ? !(!order?.urlPath || order?.urlPath === '') : true)
    );
