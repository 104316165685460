/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { BsQuestionSquare } from 'react-icons/bs';
import Styles from 'pages/onboard-developers/components/style';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const HelpImg = (props) => {
    const { title, maxWidth } = props;

    const StyledTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            backgroundColor: theme.palette.white,
            fontSize: 15,
            '&::before': {
                border: '1px solid #E9E9E9',
                color: theme?.palette?.black?.dark,
                backgroundColor: theme.palette.white,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            fontSize: 10,
            textAlign: 'left',
            whiteSpace: 'normal',
            width: 'auto',
            maxWidth,
            height: '100%',
            backgroundColor: theme.palette.white,
            color: theme?.palette?.black?.dark,
            border: '1px solid #E9E9E9',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
    }));
    return (
        <StyledTooltip arrow title={title} data-testid="help-img">
            <Box component="span">
                <BsQuestionSquare style={Styles.infoIcon} />
            </Box>
        </StyledTooltip>
    );
};

HelpImg.propTypes = {
    title: PropTypes.any,
    maxWidth: PropTypes.string,
};

HelpImg.defaultProps = {
    title: '',
    maxWidth: 'auto',
};

export default React.memo(HelpImg);
