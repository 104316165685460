import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/day2-operation-paas/style';

const StaticWebpage = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const setStaticWebpageValue = (staticWebpage) => {
        setOrder({
            ...order,
            staticWebpage,
        });
    };

    const handleOnSelectChange = (selected) => {
        setStaticWebpageValue(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="staticWebpageRadioGrp">
                    Static Webpage
                </Box>
                <CustomRadioGroup
                    aria-label="staticWebpage"
                    name="staticWebpageRadioBtnGroup"
                    row
                    value={order?.staticWebpage}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Disabled" control={<CustomRadio />} label="Disabled" />
                    <FormControlLabel value="Enabled" sx={ProvisioningStyles.radioFields.radioBtnNoForm} control={<CustomRadio />} label="Enabled" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default StaticWebpage;
