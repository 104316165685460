/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddAvailSet, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const AvailabilitySet = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDAvailSet, setLzDDAvailSet] = useRecoilState(ddAvailSet);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const availSetApi = useMutation((payload) => axios.post(endPoints.landingZones.availabilitySet, payload));
    const setAvailSetApi = (availSet = null, availSetDetails = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                availSet,
                availSetDetails,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setAvailSetApi();
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setAvailSetApi(selected?.label, selected);
        } else {
            setAvailSetApi();
        }
    };
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const availSet = options.find((value) => lzModal?.lzDetails?.config?.availabilitySet === value?.label);
            if (availSet) setLzDDAvailSet({ ...lzDDAvailSet, isLoaded: true, data: availSet });
        }
    }, [options]);
    React.useEffect(() => {
        if (availSetApi?.isSuccess) {
            setOptions(availSetApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
            setLzDDAvailSet({ ...lzDDAvailSet, isLoaded: true });
        }
    }, [availSetApi?.isSuccess]);

    React.useEffect(() => {
        if (landZoneForm?.region && landZoneForm?.resGroup && landZoneForm.subscriptionDetails?.id && !lzModal?.lzDetails) {
            resetOptions();
            availSetApi.mutate({
                subscriptionId: landZoneForm.subscriptionDetails?.id,
                resourceGroup: landZoneForm?.resGroup,
                location: landZoneForm?.regionDetails?.value,
            });
        } else if (!lzModal?.lzDetails) {
            resetOptions();
        }
    }, [landZoneForm?.region, landZoneForm?.resGroup, landZoneForm.subscriptionDetails]);
    React.useEffect(() => {
        if (
            lzModal?.lzDetails?.region?.code &&
            lzModal?.lzDetails?.config?.resourceGroup &&
            lzModal?.lzDetails?.config?.subscription?.id &&
            lzModal?.lzDetails
        ) {
            availSetApi.mutate({
                subscriptionId: lzModal?.lzDetails?.config?.subscription?.id,
                resourceGroup: lzModal?.lzDetails?.config?.resourceGroup,
                location: lzModal?.lzDetails?.region?.code,
            });
        }
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="AvailabilitySet"
                // label="Availability Set"
                value={landZoneForm?.availSet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={availSetApi.isLoading}
                isLoading={availSetApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Availability Set"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(AvailabilitySet);
