// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Box, Grid, Typography } from '@mui/material';
import Select, { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder, isValidNamespaceName } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const NamespaceName = () => {
    const { cartItemId, operation, catalogServiceId } = useParams();
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setValidNamespaceName] = useRecoilState(isValidNamespaceName);
    const [showHelperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const validateNamespaceNameApi = useMutation((payload) => axios.post(`${endPoints?.ssp?.eventHub?.validateNamespace}`, payload));
    const namespaceApi = useMutation(() =>
        axios.get(
            `${endPoints?.ssp?.eventHub?.eventHubLookup}/subscription/${order?.envDetails?.config?.subscription?.id}/resourceGroup/${order?.envDetails?.config?.resourceGroup}`
        )
    );

    const setNamespaceName = (namespaceName = null, namespaceDetails = null) => {
        const getAutoInflate = () => {
            if (order?.nsExist === 'yes') {
                if (namespaceDetails?.data?.properties?.isAutoInflateEnabled) return 'yes';
                return 'no';
            }
            return 'no';
        };
        setOrder({
            ...order,
            namespaceName,
            namespaceDetails,
            performance: order?.nsExist === 'yes' ? namespaceDetails?.data?.sku?.name : 'Standard',
            throughputUnits: order?.nsExist === 'yes' ? namespaceDetails?.data?.sku?.capacity?.toString() : '1',
            isAutoInflate: getAutoInflate(),
            autoInflateMaxThroughputUnits: order?.nsExist === 'yes' ? namespaceDetails?.data?.properties?.maximumThroughputUnits : null,
            processingUnits: order?.nsExist === 'yes' ? namespaceDetails?.data?.sku?.capacity?.toString() : '1',
            eventHubDetails: [
                {
                    eventHubName: '',
                    partitionCount: 1,
                    retention: null,
                    isEventHubTouched: false,
                    consumerGroupList: [{ value: '' }],
                },
            ],
            cartMessage: false,
        });
    };

    React.useEffect(() => {
        if (namespaceApi?.isSuccess) {
            setOptions(namespaceApi?.data?.data?.map((val) => ({ label: val.name, value: val.name, data: val })));
        }
    }, [namespaceApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.nsExist === 'yes' && order?.envDetails) {
            namespaceApi.mutate();
        }
    }, [order?.nsExist, order?.envDetails]);

    const seldValueExistsInOptions = (namespaceValue) => options.find((data) => namespaceValue === data?.value);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== order?.namespaceName && seldValueExistsInOptions(selected?.value)) {
            if (order?.namespaceName !== selected.value) setNamespaceName(selected.value, selected);
        }
    };

    const handleValueChange = (value) => {
        if (value !== order?.namespaceName) {
            setOrder({
                ...order,
                namespaceName: value,
                cartMessage: false,
            });
        }
    };

    React.useEffect(() => {
        if (
            order?.nsExist === 'no' &&
            order?.namespaceName &&
            (order?.namespaceName !== null || order?.namespaceName !== '' || order?.namespaceName !== undefined) &&
            order?.envDetails?.config?.subscription?.id
        ) {
            validateNamespaceNameApi?.mutate({
                name: order?.namespaceName,
                subscriptionId: order?.envDetails?.config?.subscription?.id,
            });
        } else {
            setValidNamespaceName(false);
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.nsExist, order?.namespaceName, order?.envDetails?.config?.subscription?.id]);

    React.useEffect(() => {
        if (validateNamespaceNameApi?.data && validateNamespaceNameApi?.data?.data) {
            if (!validateNamespaceNameApi?.data?.data?.nameAvailable) {
                setValidNamespaceName(true);
                setHelperText(true);
                setHelperMsg(validateNamespaceNameApi?.data?.messages[0]);
            } else {
                setValidNamespaceName(false);
                setHelperText(false);
                setHelperMsg();
            }
        }
    }, [validateNamespaceNameApi?.isSuccess]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.namespaceName) || showHelperText || order?.cartMessage) {
            return 'validate';
        }
        return '';
    };

    const customValidateInput = () => {
        if ((isTouched && order?.isTouched && !order?.namespaceName) || order?.cartMessage) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.namespaceName && order?.isTouched) setIsTouched(true);
    }, [order?.namespaceName, order?.isTouched]);

    return (
        <>
            {order?.nsExist === 'no' ? (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        autoComplete="off"
                        disabled={cartItemId && operation && catalogServiceId}
                        label="Name *"
                        error={showHelperText}
                        helperText={showHelperText && <span style={ProvisioningStyles.disposal.helpText}>{showHelperMsg}</span>}
                        value={order?.namespaceName || ''}
                        onChange={(e) => handleValueChange(e.target.value)}
                        className={validateInput()}
                    />
                    <HelpImg
                        maxmWidth="450px"
                        title={
                            <>
                                <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                    <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>2) Min.: 6 chars to Max.: 50 chars</Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    3) Can contain only hyphen(-) as special char
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    4) Should start with &quot;pep-&quot; and end with letter or number
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>5) Should be unique value</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Stack>
            ) : (
                <>
                    <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                        <Select
                            key="NamespaceName"
                            label="Name *"
                            value={order?.namespaceName || null}
                            options={options}
                            handleOnSelect={(value) => handleOnSelectChange(value)}
                            disabled={namespaceApi.isLoading || (cartItemId && operation && catalogServiceId)}
                            isLoading={namespaceApi.isLoading}
                            customValidate={customValidateInput()}
                        />
                    </Stack>
                </>
            )}
            {order?.cartMessage && (
                <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                    This namespace name already exists in cart.
                </Typography>
            )}
        </>
    );
};

export default NamespaceName;
