/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress } from '@mui/material';
import { LineChart, Line, ResponsiveContainer, XAxis, Tooltip, LabelList, YAxis, Legend } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import Chart from '../components/Chart';
import { dateRangeValue, dashboardFilter } from '../store';
import OverallCount from '../OverallCount';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const OrdersCreationTrend = () => {
    const [data, setData] = React.useState([]);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);

    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            providers: dashboardFilterObj?.provider || [],
            sectors: dashboardFilterObj?.sector || [],
            services: dashboardFilterObj?.services || [],
            solutions: dashboardFilterObj?.solutions || [],
            environments: dashboardFilterObj?.environment || [],
            regions: dashboardFilterObj?.regions || [],
            applicationService: dashboardFilterObj?.ApplicationService?.label || '',
            metricType: 'orderTrend',
        };
        setData([]);
        ordersTrendQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess && ordersTrendQuery?.data?.data) {
            const results = ordersTrendQuery?.data?.data?.map((item) => ({
                name: DateTime.fromISO(item?.key_as_string, { zone: 'utc' }).toFormat('LLL yy'),
                'Orders Completed': item?.distinct_order_ids?.value,
            }));
            setData(results || []);
        }
    }, [ordersTrendQuery?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 6);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);
    const CustomYAxisTick = ({ x, y, payload }) => {
        // eslint-disable-next-line
        const value = payload.value;
        let yaxis = (
            <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
                0
            </text>
        );
        // if (value >= 1000000) {
        //     yaxis = <text x={x} y={y} dy={4} textAnchor="end" fill="#666">{`${value / 1000000}M`}</text>;
        // } else if (value >= 1000) {
        //     yaxis = <text x={x} y={y} dy={4} textAnchor="end" fill="#666">{`${value / 1000}k`}</text>;
        // } else {
        yaxis = (
            <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
                {Math.floor(value)}
            </text>
        );
        // }
        return yaxis;
    };

    return (
        <Chart subTitle title="ORDERS TREND">
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {ordersTrendQuery.isLoading && (
                        <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                            <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                        </Box>
                    )}
                    {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                    {data?.length > 0 && (
                        <Box sx={dashboardStyle.ApplicationOnBoardStyle.chart_wrapper}>
                            <ResponsiveContainer height="100%" width="100%">
                                <LineChart data={data} width={500} height={data?.length * 180 + 100}>
                                    <XAxis interval={0} padding={{ left: 10, right: 10 }} dataKey="name" height={70} tick={<CustomizedAxisTick />} />
                                    <YAxis
                                        tick={<CustomYAxisTick />}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 40,
                                            bottom: 5,
                                        }}
                                        domain={[0, (dataMax) => dataMax * 1.5]}
                                    />
                                    <Tooltip />
                                    <Legend iconType="square" />
                                    {/* <CartesianGrid /> */}
                                    {/* <Line dataKey="OrdersSubmitted" dot={{ stroke: '#FB9906', strokeWidth: 5 }} stroke="#FB9906" strokeWidth={2}>
                                    <LabelList position="top" />
                                </Line> */}
                                    <Line
                                        dataKey="Orders Completed"
                                        dot={{ stroke: '#0056BA', strokeWidth: 2 }}
                                        stroke="#0056BA"
                                        // strokeWidth={2}
                                        type="monotone"
                                    >
                                        <LabelList position="top" />
                                    </Line>
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    )}
                </ErrorBoundary>
                <Box sx={{ marginTop: 2, marginLeft: 4 }}>
                    <OverallCount />
                </Box>
            </Box>
        </Chart>
    );
};
export default React.memo(OrdersCreationTrend);
