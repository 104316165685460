/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const MaxBurstIops = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setMaxBurstIops = (maxBurstIops, maxBurstIopsDetail) => {
        setOrder({ ...order, maxBurstIops, maxBurstIopsDetail });
    };
    const seldValueExistsInOptions = (maxBurstIopsValue) => options.find((option) => maxBurstIopsValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setMaxBurstIops(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        setOptions(['600', '1000']?.map((data) => ({ label: data, value: data })));
    }, []);
    React.useEffect(() => {
        if (!order?.maxBurstIops && order?.isTouched) setIsTouched(true);
    }, [order?.maxBurstIops, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="maxBurstIops"
                label="Max Burst IOPS *"
                value={order?.maxBurstIops || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(MaxBurstIops);
