/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// import { DateTime } from 'luxon';
import { Stack, Grid } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import DashboardDateRange from './dateRange';
import DashboardFilter from './filter';
import Chart from './components/Chart';
// import OverallCount from './OverallCount';
import DashboardMetricsChart from './DashBoardMetricsCharts';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};
const DashboardMetricsFilter = () => {
    return (
        <Stack spacing={2} direction="row" sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
            <DashboardDateRange />
            <DashboardFilter />
        </Stack>
    );
};
const DashboardMetrics = () => {
    return (
        <Chart w={12} title="Dashboard Metrics" showFilter FilterComponent={DashboardMetricsFilter}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Grid container spacing={1}>
                    {/* <Grid item xs={12}>
                        {' '}
                        <OverallCount />
                    </Grid> */}
                    <Grid item xs={12}>
                        <DashboardMetricsChart.OrderCreationTrend />
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMetricsChart.OrdersProcessingByService />
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMetricsChart.OrdersProcessingBySolutions />
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMetricsChart.ServicesProvisionedByMonth />
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMetricsChart.ServicesProvisioned />
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMetricsChart.SolutionsProvisionedByMonth />
                    </Grid>
                    <Grid item xs={6}>
                        <DashboardMetricsChart.SolutionsProvisioned />
                    </Grid>
                </Grid>
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(DashboardMetrics);
