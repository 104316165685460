import { atom } from 'recoil';

export const ArchImgUrl = atom({
    key: 'RI002-ArchImgUrl',
    default: undefined,
});

export const isExpandArchDiagram = atom({
    key: 'RI002-isExpandArchDiagram',
    default: false,
});

export const OrderPayload = atom({
    key: 'RI002-OrderPayload',
    default: {
        catalogCode: null,
        projectId: null,
        projectDetails: null,
        businessUnit: null,
        sector: null,
        division: null,
        environment: null,
        dataClassification: null,
        budgetCode: null,
        budgetCodeDetails: null,
        billingInfo: null,
        nonPrivilegedADGroupList: [{ readerValue: '' }],
        privilegedADGroupList: [{ contributorValue: '' }],
        environmentDetails: null,
        adGroup: 'Yes',
        privilegedADGroup: null,
        nonPrivilegedADGroup: null,
        businessAppService: 'Yes',
        businessApp: null,
        appService: null,
        isTouched: false,
        estimatedCost: null,
        appOwner: null,
        appOwnerDetails: null,
        businessContact: null,
        businessContactDetails: null,
        privilegedADGroupOwner: null,
        privilegedADGroupOwnerDetails: null,
        nonPrivilegedADGroupOwner: null,
        nonPrivilegedADGroupOwnerDetails: null,
        applicationName: null,
        appShortName: null,
        isaNumber: null,
    },
});

export const isGetAppShortNameLoading = atom({
    key: 'RI002-isGetAppShortNameLoading',
    default: false,
});

export const isAppNameValid = atom({
    key: 'RI002-isAppNameValid',
    default: true,
});

export const isAppShortNameValid = atom({
    key: 'RI002-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'RI002-isAppShortNameExist',
    default: false,
});

export const isBusinessNameValid = atom({
    key: 'RI002-isBusinessNameValid',
    default: true,
});

export const isBudgetCodeValid = atom({
    key: 'RI002-isBudgetCodeValid',
    default: true,
});

export const isBillingInfoValid = atom({
    key: 'RI002-isBillingInfoValid',
    default: true,
});

export const isProjectIdValid = atom({
    key: 'RI002-isProjectIdValid',
    default: false,
});

export const adGroupErrorMsg = atom({
    key: 'RI002-adGroupErrorMsg',
    default: false,
});

export const contributorErrorMsg = atom({
    key: 'RI002-contributorErrorMsg',
    default: false,
});

export const isADGroupValid = atom({
    key: 'RI002-isADGroupValid',
    default: true,
});

export const isBusinessAppValid = atom({
    key: 'RI002-isBusinessAppValid',
    default: false,
});

export const isNonPrivilegedADGroupValid = atom({
    key: 'RI002-isNonPrivilegedADGroupValid',
    default: false,
});

export const isPrivilegedADGroupValid = atom({
    key: 'RI002-isPrivilegedADGroupValid',
    default: false,
});

export const costEstimateState = atom({
    key: 'RI002-costEstimateState',
    default: null,
});

export const toggleModal = atom({
    key: 'RI002-toggleModal',
    default: {
        isADGroupModalOpen: false,
        isNonPrivilegedADGroupOpen: false,
        isPrivilegedADGroupOpen: false,
        isCost: { costType: '', isCostOpen: false },
    },
});

export const showPreviewOrder = atom({
    key: 'RI002-showPreviewOrder',
    default: false,
});

export const previewDetails = atom({
    key: 'RI002-previewdetails',
    default: null,
});
export const previewApiLoading = atom({
    key: 'RI002-previewApiLoading',
    default: false,
});
export const isSectorDisabled = atom({
    key: 'RI002-isSectorDisabled',
    default: true,
});
export const isBusinessUnitDisabled = atom({
    key: 'RI002-isBusinessUnitDisabled',
    default: true,
});
export const isHcodeDisabled = atom({
    key: 'RI002-isHcodeDisabled',
    default: true,
});
export const previewOrderError = atom({
    key: 'RI002-previewError',
    default: {
        isPreviewError: false,
        message: '',
    },
});
