/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        backgroundColor: theme.palette.white,
        fontSize: 15,
        '&::before': {
            border: '1px solid #E9E9E9',
            color: theme.palette.black.dark,
            backgroundColor: theme.palette.white,
            boxSizing: 'border-box',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        },
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.white,
        color: theme.palette.black.dark,
        border: '1px solid #E9E9E9',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
}));

const InfoImg = (props) => {
    const { title, placement } = props;
    return (
        <StyledTooltip arrow title={title} placement={placement}>
            <Box component="span">
                <FaInfoCircle style={{ color: '#0047BA', fontSize: '20px', marginTop: '5px' }} />
            </Box>
        </StyledTooltip>
    );
};

InfoImg.propTypes = {
    title: PropTypes.any,
    placement: PropTypes.any,
};

InfoImg.defaultProps = {
    title: '',
    placement: 'bottom',
};

export default React.memo(InfoImg);
