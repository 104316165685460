import React from 'react';
import { Grid, Box, TableContainer, Table, TableBody, Typography, Divider } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import TestPlanUser from 'pages/project-onboarding/components/Form/ServiceMembers/TestPlanUser';
import HCode from '../HCode';
import ServiceDialog from './ServiceDialog';

const TestPlanConfiguration = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [deleteMember, setDeleteMember] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleDeleteMember = () => {
        if (order?.testPlanUserDetails) {
            if (deleteMember?.gpid === order?.testPlanUserDetails?.gpid) {
                setOrder({
                    ...order,
                    testPlanUserList: null,
                    testPlanUserDetails: null,
                });
                setValidForm({ ...isValidForm, isDuplicateTestPlanUser: false });
            }
        }
        setOrder({
            ...order,
            testPlanUserList: order?.testPlanUserList?.filter((item) => item.gpid !== deleteMember?.gpid),
        });
        setValidForm({ ...isValidForm, isTestPlanMaxMemberLimit: false });
    };

    const handleNo = () => {
        setIsOpen(false);
    };
    const handleYes = () => {
        handleDeleteMember();
        setIsOpen(false);
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <HCode />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TestPlanUser />
                </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" mt={2}>
                <Box display="flex" mt={2}>
                    <Typography variant="h6" mr={1}>
                        Test Plan Users *
                    </Typography>
                    <InfoTooltip title="Add the User who require Test Plan License to work on the Test Suite." />
                </Box>
                <Divider />
                <Box display="flex">
                    {order?.testPlanUserList?.length > 0 ? (
                        <Box width="100%">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {order?.testPlanUserList &&
                                            order?.testPlanUserList?.length &&
                                            order?.testPlanUserList?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left" width="1">
                                                        <Typography>{item?.gpid || ''}</Typography>
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.name || '-'}
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.email === 'null' || !item?.email ? '-' : item?.email}
                                                    </TableCell>
                                                    <TableCell width="1">
                                                        <DeleteIcon
                                                            sx={ProjectOnboardingStyles?.removeBtn}
                                                            color="error"
                                                            onClick={() => {
                                                                setDeleteMember(item);
                                                                setIsOpen(true);
                                                            }}
                                                            className="animate-opacity"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box mt={2}>
                            <Typography variant="body1">No Members Selected</Typography>
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                    {order?.testPlanUserList && order?.testPlanUserList?.length > 0 ? (
                        <Typography>
                            <b>{order?.testPlanUserList?.length}</b> {order?.testPlanUserList?.length < 2 ? 'Member' : 'Members'} Selected{' '}
                        </Typography>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Box>
            </Box>
            <ServiceDialog isOpen={isOpen} member={deleteMember?.name} handleNo={handleNo} handleYes={handleYes} />
        </>
    );
};

export default React.memo(TestPlanConfiguration);
