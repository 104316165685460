import OrderCreationTrend from './OrderCreationTrend';
import ServicesProvisionedByMonth from './ServicesProvisionedbyMonth';
import ServicesProvisioned from './ServicesProvisioned';
import OrdersProcessingByService from './OrderProcessingByService';
import OrdersProcessingBySolutions from './OrderProcessingBySolutions';
import SolutionsProvisioned from './SolutionsProvisioned';
import SolutionsProvisionedByMonth from './SolutionsProvisionedByMonth';

const DashboardMetricsChart = {
    OrderCreationTrend,
    ServicesProvisionedByMonth,
    ServicesProvisioned,
    OrdersProcessingByService,
    OrdersProcessingBySolutions,
    SolutionsProvisioned,
    SolutionsProvisionedByMonth,
};

export default DashboardMetricsChart;
