import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';
import { resetOrder } from 'pages/project-onboarding/utils';
import HelpImg from 'pages/project-onboarding/components/helpImg';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const ADOPlatform = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const adoPlatformApi = useMutation(() => axios.get(endPoints.day2Operation.ProjectOnboarding.adoPlatform));

    const setADOPlatform = (adoPlatformVal) => {
        const { sector, adoPlatform, businessApplication, applicationService, courseDetails, ...rest } = resetOrder;
        setOrder({
            ...order,
            adoPlatform: adoPlatformVal,
            businessApplication: adoPlatformVal === 'Sandbox/Personal' ? 'AZURE DEVOPS' : null,
            applicationService: adoPlatformVal === 'Sandbox/Personal' ? 'AZURE DEVOPS NON-PROD' : null,
            ...rest,
            serviceResources: {
                ...order.serviceResources,
                isProjectAdmin: false,
                isBoard: false,
                isRepos: false,
                isPipelines: false,
                isSonarQube: false,
                isArtifactory: false,
                isSecurityTools: false,
                isTestPlan: false,
            },
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (adoPlatform) => options.find((option) => adoPlatform === option.value);

    const handleOnSelectChange = (selected) => setADOPlatform(selected.value);

    React.useEffect(() => {
        if (adoPlatformApi?.isSuccess) {
            setOptions(adoPlatformApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName })));
        }
    }, [adoPlatformApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.adoPlatform) {
            setOrder({ ...order, adoPlatform: options?.[0].value });
        }
        if (options && options?.length && order?.adoPlatform) {
            const adoPlatform = isExistsInOptions(order?.adoPlatform);
            setOrder({ ...order, adoPlatform: adoPlatform?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        adoPlatformApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.adoPlatform && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.adoPlatform, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="adoPlatform-autocomplete"
                key="adoPlatform"
                label="ADO Platform *"
                value={order?.adoPlatform || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={!order?.sector || adoPlatformApi.isLoading}
                isLoading={adoPlatformApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg
                maxWidth="500px"
                title={
                    <>
                        <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                            <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                            Please select the appropriate option for your use case:
                                        </Typography>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                            <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Production:</b> Choose this if you intend to use the tool for
                                            business applications or if you’re migrating from StarTeam. It’s also suitable for any production instance.
                                        </Typography>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                            <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Personal or Sandbox:</b> Opt for this if you’re conducting a
                                            proof of concept (POC) or simply want to explore and understand the tool.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                            <b>Note:</b> Personal or sandbox projects will be deleted after one month. If you require a project for a longer
                                            duration, please select the Production option.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

export default React.memo(ADOPlatform);
