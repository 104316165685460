/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import ProvisioningStyles from 'pages/mdt-onboarding/style';
import SubmitOrderBtn from '../components/SubmitOrder';

const ProvisioningHeader = () => {
    return (
        <Box>
            <StickyHeader
                title="Managed Data Transfer Onboarding"
                sx={ProvisioningStyles.ProvisioningHeader}
                width="100px"
                showCartBtn
                cartBtn={<SubmitOrderBtn />}
            />
        </Box>
    );
};

export default React.memo(ProvisioningHeader);
