import React from 'react';
import PropTypes from 'prop-types';
import InfoTooltip from 'pages/reference-implementation/components/info-tooltip';
import { currencySymbols } from 'utils';
import { Box, Grid, Typography } from '@mui/material';
import { Styles } from './style';

const CostInfo = ({ costInfodata }) => {
    return (
        <Box sx={{ marginLeft: 1, marginTop: 1 }}>
            <InfoTooltip
                title={
                    <Box sx={Styles?.costInfo?.titleWrapper}>
                        <Grid container sx={Styles?.CostDetails?.gridcontainer}>
                            {costInfodata?.map((label, index) => (
                                <Grid item xs={12} sx={Styles?.costInfo?.gridcontainer} key={index}>
                                    <Box width="70%">
                                        <Typography sx={Styles?.costInfo?.labelItem}> {label?.item} :</Typography>
                                    </Box>
                                    <Box width="30%">
                                        <Typography sx={Styles?.costInfo?.labelValue}>
                                            {' '}
                                            {currencySymbols[label?.currency]}
                                            {label?.estCost}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                }
                placement="right"
            />{' '}
        </Box>
    );
};

CostInfo.propTypes = {
    costInfodata: PropTypes.any,
};

CostInfo.defaultProps = {
    costInfodata: null,
};
export default CostInfo;
