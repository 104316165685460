export const formOrderObjFromCart = (data = null, sku = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        sku,
        cluster: catalogConfig?.cluster,
        dbVersion: catalogConfig?.dbVersion,
        nodesCount: catalogConfig?.nodesCount,
        nodes: catalogConfig?.components?.map((item) => ({
            catalogServiceCode: item?.catalogService?.catalogServiceCode,
            catalogType: item?.catalogService?.catalogType,
            cloudProvider: item?.catalogService?.cloudProvider,
            cloudService: item?.catalogService?.cloudService,
            osType: 'Linux',
            vmType: item?.catalogService?.catalogConfig?.vmType,
            osTypeVersion: item?.catalogService?.catalogConfig?.os?.publisher
                ? `${item?.catalogService?.catalogConfig?.os?.publisher},${item?.catalogService?.catalogConfig?.os?.sku}`
                : null,
            osDiskType: item?.catalogService?.catalogConfig?.osDiskType,
            osDiskTypeCode: item?.catalogService?.catalogConfig?.osDiskTypeCode,
            diskTypeOptions: null,
            domain: item?.catalogService?.catalogConfig?.environmentDetails?.domain,
            haDeployment: item?.catalogService?.catalogConfig?.haDeployment,
            availabilityZone: item?.catalogService?.catalogConfig?.availabilityZones,
            dataDisksCount: item?.catalogService?.catalogConfig?.diskDefinitions?.length || 0,
            dataDisks: item?.catalogService?.catalogConfig?.diskDefinitions?.map((z) => ({
                // eslint-disable-next-line
                diskType: z?.diskType ? z?.diskType : ['Production'].includes(environmentDetails?.environment) ? 'Premium SSD' : 'Standard SSD',
                mountPoint: z?.mountPoint,
                fsType: z?.fsType,
                diskSizeInGib: z?.diskSizeInGib,
                diskTypeCode: z?.diskTypeCode,
            })),
            quantity: 1,
            serverRefresh: false,
            samAccountId: item?.catalogService?.catalogConfig?.samAccountId,
            timeZone: item?.catalogService?.timeZone,
            sslCertificate: item?.catalogService?.catalogConfig?.sslCertificate ? 'yes' : 'no',
            estimatedCost: item?.catalogService?.estimatedCost,
        })),
        serviceMappings: catalogConfig?.serviceMappings?.map((z) => ({
            clusterService: z?.clusterService,
            memoryQuota: z?.memoryQuota,
            storagePath: z?.storagePath,
            nodes: z?.nodes,
            isMemoryRequired: z?.isMemoryRequired,
        })),
        buckets: catalogConfig?.buckets?.map((z) => ({
            bucketType: z?.bucketType,
            bucketNames: z?.bucketNames.map((z1) => ({ name: z1 })),
            bucketMemSize: z?.bucketMemSize,
        })),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null, sku = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && { hCodeDetails: { label: environmentDetails?.costCenter, projectName: environmentDetails?.projectName } }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        sku,
        cluster: catalogConfig?.cluster,
        dbVersion: catalogConfig?.dbVersion,
        nodesCount: catalogConfig?.nodesCount,
        nodes: catalogConfig?.components?.map((item) => ({
            catalogServiceCode: item?.catalogService?.catalogServiceCode,
            catalogType: item?.catalogService?.catalogType,
            cloudProvider: item?.catalogService?.cloudProvider,
            cloudService: item?.catalogService?.cloudService,
            osType: 'Linux',
            vmType: item?.catalogService?.catalogConfig?.vmType,
            osTypeVersion: item?.catalogService?.catalogConfig?.os?.publisher
                ? `${item?.catalogService?.catalogConfig?.os?.publisher},${item?.catalogService?.catalogConfig?.os?.sku}`
                : null,
            osDiskType: item?.catalogService?.catalogConfig?.osDiskType,
            osDiskTypeCode: item?.catalogService?.catalogConfig?.osDiskTypeCode,
            diskTypeOptions: null,
            domain: item?.catalogService?.catalogConfig?.environmentDetails?.domain,
            haDeployment: item?.catalogService?.catalogConfig?.haDeployment,
            availabilityZone: item?.catalogService?.catalogConfig?.availabilityZones,
            dataDisksCount: item?.catalogService?.catalogConfig?.diskDefinitions?.length || 0,
            dataDisks: item?.catalogService?.catalogConfig?.diskDefinitions?.map((z) => ({
                // eslint-disable-next-line
                diskType: z?.diskType ? z?.diskType : ['Production'].includes(environmentDetails?.environment) ? 'Premium SSD' : 'Standard SSD',
                mountPoint: z?.mountPoint,
                fsType: z?.fsType,
                diskSizeInGib: z?.diskSizeInGib,
                diskTypeCode: z?.diskTypeCode,
            })),
            samAccountId: item?.catalogService?.catalogConfig?.samAccountId,
            timeZone: item?.catalogService?.timeZone,
            sslCertificate: item?.catalogService?.catalogConfig?.sslCertificate ? 'yes' : 'no',
            estimatedCost: item?.catalogService?.estimatedCost,
        })),
        serviceMappings: catalogConfig?.serviceMappings?.map((z) => ({
            clusterService: z?.clusterService,
            memoryQuota: z?.memoryQuota,
            storagePath: z?.storagePath,
            nodes: z?.nodes,
            isMemoryRequired: z?.isMemoryRequired,
        })),
        buckets: catalogConfig?.buckets?.map((z) => ({
            bucketType: z?.bucketType,
            bucketNames: z?.bucketNames.map((z1) => ({ name: z1 })),
            bucketMemSize: z?.bucketMemSize,
        })),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    hCode: null,
    hCodeDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    sector: null,
    sectorDetails: null,
    tags: [],
    tagsQc: null,
    estimatedCost: {
        details: [],
        qty: null,
        totalOneTimeCost: {
            frequency: 'one time',
            estCost: null,
            currency: 'USD',
        },
        totalRecurringCost: {
            frequency: 'monthly',
            estCost: null,
            currency: 'USD',
        },
        notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
    },
    cluster: null,
    dbVersion: null,
    dbVersionDetails: null,
    nodesCount: null,
    maxNodeCount: null,
    availabilityZoneOptions: [],
    nodes: [
        {
            catalogServiceCode: '',
            catalogType: '',
            cloudProvider: '',
            cloudService: '',
            osType: 'Linux',
            vmType: null,
            osTypeVersion: null,
            osTypeVersionDetail: null,
            osDiskType: null,
            osDiskTypeCode: null,
            diskTypeOptions: null,
            domain: null,
            haDeployment: null,
            availabilityZone: null,
            dataDisksCount: null,
            maxDataDiskCount: null,
            dataDisks: [],
            samAccountId: null,
            timeZone: null,
            sslCertificate: 'no',
            quantity: 1,
            isDiskOpen: false,
            isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
            isConfigDiskFulfilled: false,
            isDataDiskLoaded: false,
            copyPrevChecked: false,
            serverRefresh: false,
            estimatedCost: {
                details: [],
                qty: null,
                totalOneTimeCost: {
                    frequency: 'one time',
                    estCost: null,
                    currency: 'USD',
                },
                totalRecurringCost: {
                    frequency: 'monthly',
                    estCost: null,
                    currency: 'USD',
                },
                notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
            },
        },
    ],
    serviceMappings: [
        {
            clusterService: 'data',
            memoryQuota: '',
            nodes: [],
            storagePath: '/opt/couchbase/var/lib/couchbase/data/data',
            isMemoryRequired: 'true',
            isServiceMappingTouched: false,
        },
    ],
    buckets: [
        {
            bucketType: null,
            bucketNames: [{ name: '' }],
            isBucketTouched: false,
            bucketMemSize: '',
        },
    ],
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetEnvironmentDetails = {
    Approval_Manager: '---',
    Project_Name: '---',
    ASR: '---',
    Enable_Acceleration_Network: '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
};
