import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { landingZoneForm } from 'pages/app-profiles/store';
import { CssTextField } from 'components/select';

const ComponentName = ({ index }) => {
    const [landZoneForm] = useRecoilState(landingZoneForm);
    const [ShrinkField, setShrinkField] = React.useState(true);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label={landZoneForm?.components?.[index]?.name}
                    value={landZoneForm?.components?.[index]?.service}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    disabled
                />
            </Stack>
        </>
    );
};

ComponentName.propTypes = {
    index: PropTypes.number,
};

ComponentName.defaultProps = {
    index: 0,
};

export default React.memo(ComponentName);
