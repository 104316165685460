import React, { Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { useAxios } from 'hooks';
import Empty from 'components/empty';
import { Helmet } from 'react-helmet';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { isRIEnabled, generateQueryString, allowedServices } from 'utils';
import { endPoints } from 'api/endpoints';
import NoOrders from 'assets/images/No-orders.png';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { Box, Divider, Typography, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import {
    checkOrderIsLoading,
    orderList,
    orderListFilter,
    selectOrder,
    catalogProviders,
    selectedCatalogProvider,
    catalogServiceCode,
    orderStatuses,
    totalOrders,
    pageNumber,
} from 'pages/orders/store';
import orderBy from 'lodash/orderBy';
import { useQuery } from 'hooks/useQuery';
import orderStyle, { ResetButton } from './style';
import { filterDefaultObj, generateOrderPageFilterObj } from './utils';

const OrderSummary = React.lazy(() => import('pages/orders/order-summary'));
const OrderList = React.lazy(() => import('pages/orders/order-list'));

const OrdersPage = () => {
    const query = useQuery();
    const history = useHistory();
    const axios = useAxios();
    const [isLoading, setIsLoading] = useRecoilState(checkOrderIsLoading);
    const [appliedFilters, setAppliedFilters] = useRecoilState(orderListFilter);
    const [selectedOrderId, setSelectedOrder] = useRecoilState(selectOrder);
    const [ordersList, setOrderList] = useRecoilState(orderList);
    const [orderListArray, setOrderListArray] = React.useState([]);
    const [totalOrdersCount, setTotalOrdersCount] = useRecoilState(totalOrders);
    const [, setCatalogProviders] = useRecoilState(catalogProviders);
    const [, setSelectedCatalogProviderValue] = useRecoilState(selectedCatalogProvider);
    const [, setOrderStatuses] = useRecoilState(orderStatuses);
    const [, setCatalogServiceArray] = useRecoilState(catalogServiceCode);
    const [riError, setRiError] = React.useState(false);
    const [nonRiError, setNonRiError] = React.useState(false);
    const refToTop = React.useRef();

    const resetFunctions = {
        orderList: useResetRecoilState(orderList),
        selectOrder: useResetRecoilState(selectOrder),
        orderListFilter: useResetRecoilState(orderListFilter),
        checkOrderIsLoading: useResetRecoilState(checkOrderIsLoading),
        totalOrders: useResetRecoilState(totalOrders),
        pageNumber: useResetRecoilState(pageNumber),
    };
    const getOrders = (payload) => axios.post(endPoints.order[appliedFilters?.orderType === 'My Orders' ? 'myOrders' : 'allOrders'], payload?.body || {});
    const getOrderStatus = useMutation(() => axios.get(endPoints.order.orderStatus));
    const getCatalogProviders = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    const getRiOrders = (payload) =>
        axios.post(endPoints.riServices.Jwt[appliedFilters?.orderType === 'My Orders' ? 'myOrders' : 'allOrders'], payload?.body || {});

    const riOrder = useMutation(getRiOrders, {
        onSuccess: (data) => {
            setRiError(false);
            const ordersListResp = data?.data?.orderList?.filter((item) => allowedServices(item?.catalogServices?.[0]?.catalogServiceCode)) || [];
            setTotalOrdersCount(data?.data?.totalOrdersCount + totalOrdersCount || 0);
            setOrderList(orderBy([...orderListArray, ...ordersListResp], 'updatedOn', 'desc') || []);
            setIsLoading(false);
        },
        onError: () => {
            setRiError(true);
            setOrderList(orderListArray || []);
            setIsLoading(false);
        },
    });

    const order = useMutation(getOrders, {
        onSuccess: (data) => {
            setNonRiError(false);
            const ordersListResp = data?.data?.orderList?.filter((item) => allowedServices(item?.catalogServices?.[0]?.catalogServiceCode)) || [];
            setOrderListArray(ordersListResp || []);
            setTotalOrdersCount(data?.data?.totalOrdersCount || 0);
            if (isRIEnabled()) {
                riOrder.mutate({
                    body: {
                        size: 50,
                        page: 1,
                        ...appliedFilters,
                        toDate: appliedFilters?.toDate
                            ? DateTime.fromJSDate(appliedFilters?.toDate).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
                            : null,
                        fromDate: appliedFilters?.fromDate
                            ? DateTime.fromJSDate(appliedFilters?.fromDate).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
                            : null,
                    },
                });
            } else {
                setOrderList(ordersListResp);
                setIsLoading(false);
            }
        },
        onError: () => {
            setNonRiError(true);
            if (isRIEnabled()) {
                riOrder.mutate({
                    body: {
                        size: 50,
                        page: 1,
                        ...appliedFilters,
                        toDate: appliedFilters?.toDate
                            ? DateTime.fromJSDate(appliedFilters?.toDate).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
                            : null,
                        fromDate: appliedFilters?.fromDate
                            ? DateTime.fromJSDate(appliedFilters?.fromDate).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
                            : null,
                    },
                });
            } else {
                setOrderList([]);
                setIsLoading(false);
            }
        },
    });

    const getOrdersMutation = async () => {
        const payload = {
            ...appliedFilters,
            toDate: appliedFilters?.toDate ? DateTime.fromJSDate(appliedFilters?.toDate).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : null,
            fromDate: appliedFilters?.fromDate
                ? DateTime.fromJSDate(appliedFilters?.fromDate).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
                : null,
        };
        const additionalPayload = {
            ...(query?.get('seld_order_id') && {
                seld_order_id: query?.get('seld_order_id'),
            }),
            ...(query?.get('seld_catalog_service_code') && {
                seld_catalog_service_code: query?.get('seld_catalog_service_code'),
            }),
        };
        history?.replace(`/orders?${generateQueryString(generateOrderPageFilterObj({ appliedFilters, additionalPayload }))}`);
        setIsLoading(true);
        order.mutate({
            body: { ...payload, size: 50, page: 1 },
        });
    };
    const handleResetFilter = () => {
        resetFunctions.pageNumber();
        setAppliedFilters(filterDefaultObj);
        setSelectedCatalogProviderValue(null);
    };
    const getQueryData = () => {
        return {
            ...filterDefaultObj(),
            ...(query?.get('order_id') && {
                orderId: query?.get('order_id'),
            }),
            ...(query?.get('order_type') && {
                orderType: query?.get('order_type'),
            }),
            ...(query?.getAll('sectors')?.length && {
                sectors: query?.getAll('sectors'),
            }),
            ...(query?.getAll('status')?.length && {
                status: query?.getAll('status'),
            }),
            ...(query?.get('requestor_id') && {
                requestorId: query?.get('requestor_id'),
            }),
            ...(query?.get('application_name') && {
                applicationName: query?.get('application_name'),
            }),
            ...(query?.get('cloud_provider') && {
                cloudProvider: query?.get('cloud_provider'),
            }),
            ...(query?.getAll('catalog_service_codes')?.length && {
                catalogServiceCodes: query?.getAll('catalog_service_codes'),
            }),
            ...(query?.get('from_date') && {
                fromDate: new Date(query?.get('from_date')),
            }),
            ...(query?.get('to_date') && {
                toDate: new Date(query?.get('to_date')),
            }),
        };
    };

    React.useEffect(() => {
        if (ordersList?.length && !selectedOrderId?.orderId) {
            setSelectedOrder(ordersList[0]);
        }
        if (ordersList?.length && selectedOrderId?.orderId) {
            if (selectedOrderId?.catalogServices?.[0]?.catalogServiceCode) {
                const additionalPayload = {
                    seld_order_id: selectedOrderId?.orderId,
                    seld_catalog_service_code: selectedOrderId?.catalogServices?.[0]?.catalogServiceCode,
                };
                history?.replace(`/orders?${generateQueryString(generateOrderPageFilterObj({ appliedFilters, additionalPayload }))}`);
            }
            setSelectedOrder(selectedOrderId);
        }
    }, [ordersList, selectedOrderId]);

    React.useEffect(() => {
        if (appliedFilters) {
            setSelectedOrder(undefined);
            getOrdersMutation();
        }
    }, [appliedFilters]);
    React.useEffect(() => {
        setAppliedFilters(getQueryData());
        if (query?.get('cloud_provider')) {
            setSelectedCatalogProviderValue(query?.get('cloud_provider'));
        }
        return () => {
            setCatalogServiceArray([]);
            setCatalogProviders([]);
            setSelectedCatalogProviderValue(null);
            Object.keys(resetFunctions).forEach((key) => {
                resetFunctions[key]();
            });
        };
    }, []);

    React.useEffect(() => {
        getCatalogProviders.mutate();
        getOrderStatus.mutate();
    }, []);

    React.useEffect(() => {
        if (getCatalogProviders?.isSuccess) {
            setCatalogProviders(getCatalogProviders?.data?.data);
        }
    }, [getCatalogProviders?.isSuccess]);

    React.useEffect(() => {
        if (getOrderStatus?.isSuccess) {
            setOrderStatuses(getOrderStatus?.data?.data?.orderStatues);
            const orderId = query?.get('seld_order_id');
            const selectedCatalogServiceCode = query?.get('seld_catalog_service_code');
            // const { order_id: orderId, selectedCatalogServiceCode } = parseQueryString(location?.search);
            if (orderId) {
                setSelectedOrder({ orderId, catalogServices: [{ catalogServiceCode: selectedCatalogServiceCode }] });
            }
        }
    }, [getOrderStatus?.isSuccess]);

    React.useEffect(() => {
        return refToTop.current && refToTop.current.scrollIntoView();
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>{appliedFilters && appliedFilters?.orderType === 'My Orders' ? 'My' : 'All'} Orders - CMP</title>
            </Helmet>
            <Suspense
                fallback={
                    <Stack sx={orderStyle.emptyStack}>
                        <Empty isLoading color="#000000" />
                    </Stack>
                }
            >
                <Box sx={orderStyle.container} data-testid="ordercontainer" ref={refToTop}>
                    {isLoading && ordersList?.length === 0 && (
                        <Stack sx={orderStyle.emptyStack}>
                            <Empty isLoading={isLoading} color="#000000" msg="Loading..." />
                        </Stack>
                    )}
                    {/* {order?.isError && <Empty fixed notFound msg={order.error || 'Error Fetching Orders'} color="#000000" />} */}
                    {riError && nonRiError && <Empty fixed msg="Error Fetching Orders" color="#000000" />}
                    {!isLoading && !riError && !nonRiError && ordersList?.length === 0 && (
                        <Box width="100%" sx={orderStyle?.emptycontainer} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <img src={NoOrders} alt="" />
                            <Typography sx={orderStyle?.emptymsg}>No orders found</Typography>
                            {appliedFilters && (
                                <ResetButton data-testid="resetBtn" onClick={() => handleResetFilter()}>
                                    Reset Filter
                                </ResetButton>
                            )}
                        </Box>
                    )}
                    {ordersList?.length !== 0 && <OrderList data-testid="orderItems" />}
                    {ordersList?.length !== 0 && (
                        <>
                            <Divider orientation="vertical" sx={orderStyle.divider} />
                            <OrderSummary />
                        </>
                    )}
                </Box>
            </Suspense>
        </ErrorBoundary>
    );
};

export default OrdersPage;
