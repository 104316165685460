export const Style = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(0.5) },
    confirmTypography: { fontFamily: 'Stolzl', fontSize: '20px', fontWeight: 'bold', color: '#000000', paddingTop: '10px', paddingBottom: '5px' },
    confirmBtnBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2), paddingBottom: '5px' },
    newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1) },
    leftBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
    rightBtn: {
        textTransform: 'none',
        color: '#0047BA',
        border: '2px solid #0047BA',
        '&:hover': {
            color: '#00A0DD',
            borderWidth: '2px',
            border: '2px solid #00A0DD',
            backgroundColor: 'white',
        },
    },
};
export default Style;
