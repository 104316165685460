/* eslint-disable react/prop-types   */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress, Tooltip as LabelTooltip } from '@mui/material';
import { BarChart, Bar, Tooltip, YAxis, XAxis, ResponsiveContainer } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import { getServiceValueFromKey, servicesMappingLabel } from '../utils';
import Chart from '../components/Chart';
import { dateRangeValue, dashboardFilter } from '../store';

const CustomizedAxisTick = ({ x, y, payload }) => {
    const limit = 12; // put your maximum character
    if (payload?.value.length < limit) return payload?.value;
    // return `${value.substring(0, limit)}...`;
    return (
        <g transform={`translate(${x},${y})`}>
            <LabelTooltip title={payload.value}>
                <text x={0} y={0} dy={3.5} textAnchor="middle" fill="#666" className="tick-text" width="50px">
                    {payload.value.substring(0, limit)}...
                </text>
            </LabelTooltip>
        </g>
    );
};
CustomizedAxisTick.defaultProps = {
    x: 0,
    y: 0,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const EllipsisAxisTick = ({ x, y, payload, ...rest }) => {
    const [text] = React.useState(payload.value);
    return (
        <g transform={`translate(${x},${y})`}>
            <text {...rest} x={0} y={0} dy={3.5} fontSize="16px" fontFamily="Roboto" fontWeight="medium">
                {text?.length > 11 ? `${text.substring(0, 11)}...` : text}
            </text>
            {text?.length > 11 && <title>{text}</title>}
        </g>
    );
};
const ServicesProvisioned = () => {
    const [data, setData] = React.useState([]);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);
    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));
    const catalogApi = useMutation(() => axios.get(`${endPoints.catalog.allList}?includeInActiveCatalogs=true`));

    React.useEffect(() => {
        catalogApi.mutate();

        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            providers: dashboardFilterObj?.provider || [],
            sectors: dashboardFilterObj?.sector || [],
            services: dashboardFilterObj?.services || [],
            solutions: dashboardFilterObj?.solutions || [],
            environments: dashboardFilterObj?.environment || [],
            regions: dashboardFilterObj?.regions || [],
            applicationService: dashboardFilterObj?.ApplicationService?.label || '',
            metricType: 'servicesByCatalog',
        };
        setData([]);
        ordersTrendQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess && catalogApi?.isSuccess) {
            const results = ordersTrendQuery?.data?.data?.map((item) => ({
                catalogCode: catalogApi?.data?.data?.catalogs?.find((catalog) => catalog?.catalogDefinitionId === item?.key)?.catalogDefinitionId,
                catalogTitle: catalogApi?.data?.data?.catalogs?.find((catalog) => catalog?.catalogDefinitionId === item?.key)?.displayName,
                displayTitle: getServiceValueFromKey(item?.key, servicesMappingLabel),
                Services: item?.distinct_item_ids?.value,
            }));

            const finalRes = results?.sort((a, b) => a.displayTitle.localeCompare(b.displayTitle));
            setData(finalRes || []);
        }
    }, [ordersTrendQuery?.isSuccess, catalogApi?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 6);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);
    const hideXAxis = true;

    return (
        <Chart title="SERVICES PROVISIONED - BY SERVICE" subTitle>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {ordersTrendQuery.isLoading && (
                    <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                        <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                    </Box>
                )}
                {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                {data?.length > 0 && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.provisioned_resource}>
                        <ResponsiveContainer width="100%" height={data?.length < 4 ? 250 : data?.length * 18 + 100}>
                            <BarChart
                                width={470}
                                height={data?.length < 4 ? 250 : data.length * 18 + 100}
                                // height={300}
                                layout="vertical" // Set layout to "vertical" for horizontal bars
                                data={data}
                                // barCategoryGap={1}
                                margin={{
                                    top: 5,
                                    right: 0,
                                    left: 5,
                                    bottom: 5,
                                }}
                            >
                                {/* <CartesianGrid strokeDasharray="5 5" /> */}
                                <XAxis
                                    type="number"
                                    hide={hideXAxis}
                                    tick={<CustomizedAxisTick />}
                                    scale="auto"
                                    domain={[0, (dataMax) => Number(dataMax) * 1.5]}
                                />
                                <YAxis dataKey={'displayTitle' || 'catalogTitle'} type="category" interval={0} width={120} tick={<EllipsisAxisTick />} />
                                <Tooltip cursor={false} />
                                <Bar dataKey="Services" fill="#91C24C" label={{ position: 'right' }} barSize={25} radius={[0, 25, 25, 0]} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(ServicesProvisioned);
