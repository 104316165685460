/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useParams } from 'react-router-dom';
import Select from 'components/select';
import { eipStackCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';
import { getLandingZoneFromLocalStorage, clearLandingZoneFromLocalStorage } from '../../utils';

const Provider = () => {
    const params = useParams();
    const { operation } = params;
    const [isTouched, setIsTouched] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const cloudProviderApi = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    // const setProvider = (provider) => {
    //     setLandingZoneForm({
    //         ...landingZoneForm,
    //         provider,
    //     });
    // };
    const seldValueExistsInOptions = (providerValue) => options?.find((value) => providerValue === value?.data?.name);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== landingZoneForm?.provider && seldValueExistsInOptions(selected?.value)) {
            setLandingZoneForm({
                ...landingZoneForm,
                provider: selected?.data?.name,
                environment: null,
                region: null,
                service: null,
                businessUnit: null,
                cluster: null,
                subscription: null,
                dataClassification: null,
                subscriptionDetails: null,
                resGroup: null,
                resGroupDetails: null,
                vNet: null,
                vNetDetails: null,
                subNet: null,
                subNetDetails: null,
                nwSecGrp: null,
                nwSecGrpDetails: null,
                appSecGrp: null,
                appSecGrpDetails: null,
                proxPlacGrp: null,
                proxPlacGrpDetails: null,
                availSet: null,
                availSetDetails: null,
                availZone: null,
                availZoneDetails: null,
                department: null,
                ldap: null,
                landingZoneDesc: null,
                landingZoneLabel: null,
                projectName: null,
                ISADocument: null,
                components: [],
            });
            clearLandingZoneFromLocalStorage();
        }
    };

    React.useEffect(() => {
        if (cloudProviderApi?.isSuccess) {
            setOptions(cloudProviderApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [cloudProviderApi?.isSuccess]);

    React.useEffect(() => {
        cloudProviderApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.provider && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.provider, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="Provider"
                label="Provider*"
                value={landingZoneForm?.provider || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    cloudProviderApi.isLoading ||
                    operation === 'edit' ||
                    (operation === 'create' && getLandingZoneFromLocalStorage() && eipStackCatalogServiceCode !== landingZoneForm?.service)
                }
                isLoading={cloudProviderApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Provider);
