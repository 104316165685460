import React from 'react';
import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { FiAlertTriangle } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { landZoneListFilter, toggleConfirmLZModel } from 'pages/app-profiles/store';
import LandingZoneStyle from 'pages/app-profiles/landing-zones/LandingZoneStyle';
import Btn from '../../../components/button';

const ConfirmDeleteModal = () => {
    const [filter, setFilter] = useRecoilState(landZoneListFilter);
    const [modal, setModal] = useRecoilState(toggleConfirmLZModel);
    const deleteApi = useMutation(() => axios.delete(`${endPoints.landingZones.editLandingZone}/${modal?.id}`));
    const handleYes = () => {
        if (modal?.type === 'delete') deleteApi.mutate();
    };
    const handleNo = () => {
        setModal({ ...modal, isOpen: false });
    };
    React.useEffect(() => {
        if (deleteApi?.isSuccess) {
            setFilter({ ...filter, pageNumber: 1 });
            toast.success(deleteApi?.data?.messages[0]);
            setModal({ ...modal, isOpen: false });
        }
    }, [deleteApi?.isSuccess]);
    return (
        <>
            <Dialog
                open={modal?.isOpen}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-testid="dialogModal"
            >
                <DialogContent sx={{ maxWidth: modal?.type === 'delete' ? '375px' : '450px' }}>
                    {modal?.type === 'delete' && (
                        <>
                            <Box sx={LandingZoneStyle?.deleteModal?.title}>
                                {modal?.type === 'delete' && <FiAlertTriangle color="#d02424" size={70} />}
                                <Typography variant="h5">{modal?.message}</Typography>
                            </Box>

                            <Box sx={LandingZoneStyle?.deleteModal?.btnWrapper}>
                                <Btn
                                    className="custom-btn"
                                    sx={LandingZoneStyle?.deleteModal?.yesBtn}
                                    variant="contained"
                                    onClick={() => handleYes()}
                                    color="cmpPrimary"
                                    data-testid="yes-btn"
                                >
                                    Yes{' '}
                                    {deleteApi?.isLoading && <CircularProgress sx={LandingZoneStyle?.deleteModal?.circularIcon} color="inherit" size={14} />}
                                </Btn>
                                <Btn
                                    className="custom-btn"
                                    variant="outlined"
                                    color="cmpWarning"
                                    sx={LandingZoneStyle?.deleteModal?.noBtn}
                                    onClick={() => handleNo()}
                                    layout="outline"
                                    data-testid="no-btn"
                                >
                                    No
                                </Btn>
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};
export default ConfirmDeleteModal;
