/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { privateEndPointCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddSubNet, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Subnet = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDSubNet, setLzDDSubNet] = useRecoilState(ddSubNet);
    const [options, setOptions] = React.useState([]);

    const getSubNetUrl = (payload) => {
        if (landZoneForm?.cloudService === privateEndPointCatalogServiceCode)
            return `${endPoints.landingZones.privateEndpoint.subnet}?subscriptionName=${payload?.subscriptionName}&name=${payload?.name}&type=${payload?.endPointType}`;

        return `${endPoints.landingZones.Subnet}?subscriptionName=${payload?.subscriptionName}&name=${payload?.name}`;
    };

    const subNetApi = useMutation((payload) => axios.get(getSubNetUrl(payload)));

    const setSubNet = (subNet, subNetDetails) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                subNet,
                subNetDetails,
            });
        }
    };

    const resetOptions = () => {
        setOptions([]);
        setSubNet(null, null);
    };

    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSubNet(selected?.label, selected);
        } else {
            setSubNet(null, null);
        }
    };

    React.useEffect(() => {
        if (subNetApi?.isSuccess) {
            setOptions(subNetApi?.data?.data?.length && subNetApi?.data?.data[0].subnets.map((app) => ({ label: app.name, value: app.name })));
            setLzDDSubNet({ ...lzDDSubNet, isLoaded: true });
        }
    }, [subNetApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const subnet = options.find((value) => lzModal?.lzDetails?.config?.subnet === value?.label);
            if (subnet) setLzDDSubNet({ ...lzDDSubNet, isLoaded: true, data: subnet });
        }
    }, [options]);

    React.useEffect(() => {
        if (
            landZoneForm?.cloudService === privateEndPointCatalogServiceCode &&
            landZoneForm.endPointType &&
            landZoneForm.vNet &&
            landZoneForm.subscription &&
            !lzModal?.lzDetails
        ) {
            resetOptions();
            subNetApi.mutate({
                name: landZoneForm.vNet,
                subscriptionName: landZoneForm.subscription,
                endPointType: landZoneForm.endPointType,
            });
        } else if (landZoneForm?.cloudService !== privateEndPointCatalogServiceCode && landZoneForm.vNet && landZoneForm.subscription && !lzModal?.lzDetails) {
            resetOptions();
            subNetApi.mutate({ name: landZoneForm.vNet, subscriptionName: landZoneForm.subscription });
        } else if (!lzModal?.lzDetails) {
            resetOptions();
        }
    }, [landZoneForm.vNet, landZoneForm.subscription, landZoneForm?.endPointType]);

    React.useEffect(() => {
        if (
            landZoneForm?.cloudService === privateEndPointCatalogServiceCode &&
            lzModal?.lzDetails?.config?.type &&
            lzModal?.lzDetails?.config?.vnet?.name &&
            lzModal?.lzDetails?.config?.subscription?.name &&
            lzModal?.lzDetails
        ) {
            subNetApi.mutate({
                name: lzModal?.lzDetails?.config?.vnet?.name,
                subscriptionName: lzModal?.lzDetails?.config?.subscription?.name,
                endPointType: lzModal?.lzDetails?.config?.type,
            });
        } else if (
            landZoneForm?.cloudService !== privateEndPointCatalogServiceCode &&
            lzModal?.lzDetails?.config?.vnet?.name &&
            lzModal?.lzDetails?.config?.subscription?.name &&
            lzModal?.lzDetails
        ) {
            subNetApi.mutate({
                name: lzModal?.lzDetails?.config?.vnet?.name,
                subscriptionName: lzModal?.lzDetails?.config?.subscription?.name,
            });
        }
    }, [lzModal?.lzDetails]);

    return (
        <Box bgcolor="white">
            <Select
                key="SubNet"
                value={landZoneForm?.subNet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subNetApi.isLoading}
                isLoading={subNetApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Subnet"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Subnet);
