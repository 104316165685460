/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const ConfigureNewSku = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setConfigureNewSku = (configureNewSku, configureNewSkuDetail) => {
        setOrder({
            ...order,
            configureNewSku,
            configureNewSkuDetail,
            skuConfig: null,
            maxIops: null,
            ram: null,
            dataDiskSupport: null,
            premiumDiskSupport: null,
            cost: '',
        });
    };
    const configureNewSkuApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.configureNewSku}`));
    const seldValueExistsInOptions = (configureNewSkuValue) => options.find((option) => configureNewSkuValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setConfigureNewSku(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (configureNewSkuApi?.isSuccess) {
            if (configureNewSkuApi?.data?.data) setOptions(configureNewSkuApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [configureNewSkuApi?.isSuccess]);

    React.useEffect(() => {
        configureNewSkuApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.configureNewSku && order?.isTouched) setIsTouched(true);
    }, [order?.configureNewSku, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="configureNewSku"
                label="Configure New SKU *"
                value={order?.configureNewSku || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={configureNewSkuApi.isLoading}
                isLoading={configureNewSkuApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ConfigureNewSku);
