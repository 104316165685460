import React from 'react';
import { Box, FormControl, FormControlLabel, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, memberForm, selectedMembersList, costEstimateState } from 'pages/myidm/store';
import { resetCostEstimateDetails } from 'pages/myidm/utils';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/myidm/style';

const IsADGroupCreate = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [form, setForm] = useRecoilState(memberForm);
    const [, setMembers] = useRecoilState(selectedMembersList);
    const [, setcostEstimate] = useRecoilState(costEstimateState);

    const setADGroupCreate = (isNewADGroup) => {
        setOrder({
            ...order,
            isNewADGroup,
            adGroup: null,
            appShortName: null,
            groupOwner: null,
            groupOwnerDetails: null,
            members: null,
            adGroupType: null,
            applicationName: null,
            adGroupTypeDetails: null,
            appService: null,
            mypamsafeDetails: [],
            nonSafeDetailsGpid: [],
            adGroupMembers: [],
            estimatedCost: resetCostEstimateDetails,
        });
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
        setMembers([]);
        setcostEstimate(resetCostEstimateDetails);
    };
    const handleOnSelectChange = (selected) => {
        setADGroupCreate(selected);
    };
    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack} data-testid="adGrpform">
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="performanceRadioGrp">
                    Are you not able to find Active Directory Group?
                </Box>
                <CustomRadioGroup
                    aria-label="Are you not able to find Active Directory Group?"
                    name="ADGroupRadioBtnGroup"
                    row
                    value={order?.isNewADGroup || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    data-testid="ADGroupRadioBtnGroup"
                >
                    <FormControlLabel value="yes" control={<CustomRadio />} data-testid="adGrpYes" label="Yes" />
                    <FormControlLabel
                        value="no"
                        data-testid="adGrpNo"
                        sx={ProvisioningStyles.serverRefresh.radioBtnNoForm}
                        control={<CustomRadio />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(IsADGroupCreate);
