import * as React from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { bindMenu } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useHeaderStyle as style } from 'assets/style';
import { CascadingContext } from 'components/cascading-hover-menus/components/cascading-context';

export const CascadingMenu = ({ popupState, children, ...props }) => {
    const { rootPopupState } = React.useContext(CascadingContext);
    const context = React.useMemo(
        () => ({
            rootPopupState: rootPopupState || popupState,
            parentPopupState: popupState,
        }),
        [rootPopupState, popupState]
    );

    return (
        <CascadingContext.Provider value={context}>
            <HoverMenu {...props} {...bindMenu(popupState)} sx={style.hovermenus}>
                {children}
            </HoverMenu>
        </CascadingContext.Provider>
    );
};

CascadingMenu.propTypes = {
    popupState: PropTypes.object.isRequired,
    children: PropTypes.node,
};
CascadingMenu.defaultProps = {
    children: null,
};
export default {};
