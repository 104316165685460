/* istanbul ignore file */
import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, toggleModal } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/project-onboarding/style';

const SecurityTools = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen] = useRecoilState(toggleModal);

    const setAvailableClarityId = (securityTool = 'Fortify') => {
        setOrder({
            ...order,
            securityTool,
        });
    };

    const handleOnSelectChange = (selected) => {
        setAvailableClarityId(selected);
    };

    const getValidate = () => {
        if (isOpen?.isFortifySnykOpen && !order?.securityTool && order?.isConfigureTouched) return 'ritmCheckbox';
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={ProjectOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="autoinflateRadioGrp">
                    Required Security Tools Onboarding *
                </Box>
                <CustomRadioGroup
                    data-testid="security-tools-radio-group"
                    aria-label="availableClarityId"
                    name="availableClarityIdRadioBtnGroup"
                    row
                    value={order?.securityTool || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    className={getValidate()}
                >
                    <FormControlLabel value="Fortify" control={<CustomRadio className={getValidate()} />} label="Fortify" />
                    <FormControlLabel value="Snyk" control={<CustomRadio className={getValidate()} />} label="Snyk" />
                    <FormControlLabel value="Fortify/Snyk" control={<CustomRadio className={getValidate()} />} label="Both Fortify/Snyk" />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(SecurityTools);
