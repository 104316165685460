/* eslint-disable import/no-named-default */
import { default as Applications } from './Application';
import { default as Sector } from './Sector';
import { default as Region } from './Region';
import { default as LandingZones } from './LandingZones';
import { default as Environments } from './Environments';
import { default as CostCenter } from './CostCenter';
import { default as NamespaceName } from './NamespaceName';
import { default as NSExist } from './NSExist';
import { default as Performance } from './Performance';
import { default as ThroughputUnits } from './ThroughputUnits';
import { default as ProcessingUnits } from './ProcessingUnits';
import { default as AutoInflate } from './AutoInflate';
import { default as AutoInflateMaxThroughputUnits } from './AutoInflateMaxThroughputUnits';
import { default as GeoReplication } from './GeoReplication';
import { default as EventHubDetails } from './EventHubDetails';
import { default as AvailablePartitionCount } from './AvailablePartitionCount';

const Forms = {
    Applications,
    Sector,
    Region,
    LandingZones,
    Environments,
    CostCenter,
    NamespaceName,
    NSExist,
    Performance,
    ThroughputUnits,
    ProcessingUnits,
    AutoInflate,
    GeoReplication,
    EventHubDetails,
    AutoInflateMaxThroughputUnits,
    AvailablePartitionCount,
};

export default Forms;
