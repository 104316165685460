/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';

const Subnet = () => {
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const [isTouched, setIsTouched] = React.useState(false);

    const getSubNetUrl = (payload) => `${endPoints.landingZones.Subnet}?subscriptionName=${payload?.subscriptionName}&name=${payload?.name}`;

    const subNetApi = useMutation((payload) => axios.get(getSubNetUrl(payload)));

    const setSubNet = (subNet, subNetDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            subNet,
            subNetDetails,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSubNet(selected?.label, selected);
        }
    };

    React.useEffect(() => {
        if (subNetApi?.isSuccess) {
            setOptions(subNetApi?.data?.data?.length && subNetApi?.data?.data[0].subnets.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [subNetApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.subNet) {
            setLandingZoneForm({ ...landingZoneForm, subNet: options?.[0].label, subNetDetails: options?.[0] });
        }
        if (options && options?.length && landingZoneForm?.subNet) {
            const subNet = seldValueExistsInOptions(landingZoneForm?.subNet);
            setLandingZoneForm({ ...landingZoneForm, subNet: subNet?.value, subNetDetails: subNet });
        }
    }, [options]);

    React.useEffect(() => {
        if (landingZoneForm.vNet && landingZoneForm.subscription) {
            resetOptions();
            subNetApi.mutate({ name: landingZoneForm.vNet, subscriptionName: landingZoneForm.subscription });
        }
    }, [landingZoneForm.vNet, landingZoneForm.subscription]);

    React.useEffect(() => {
        if (!landingZoneForm?.subNet && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.subNet, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="SubNet"
                label="Subnet*"
                value={landingZoneForm?.subNet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subNetApi.isLoading}
                isLoading={subNetApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Subnet);
