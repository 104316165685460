/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, Tooltip } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard/style';
import Chart from './components/Chart';
import { applicationOnBoard, dateRangeValue, dashboardFilter } from './store';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload?.value?.replace(/-/g, "'")}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const ApplicationOnBoarded = () => {
    const [data, setData] = useRecoilState(applicationOnBoard);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);

    const query = useMutation((payload) => axios.post(endPoints.ssp.dashboard.applicationOnBoard, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            ...(dashboardFilterObj?.sector && dashboardFilterObj?.sector !== null && { sector: dashboardFilterObj?.sector }),
        };
        query.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (query?.isSuccess) {
            setData(
                query?.data?.data?.applicationOnboarded?.map((item) => ({
                    name: DateTime.fromISO(item?.key_as_string, { zone: 'utc' }).toFormat('LLL-yy'),
                    value: item?.doc_count,
                })) || []
            );
        }
    }, [query?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 5);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            return (
                <Stack direction="row" sx={dashboardStyle.provisionedServices.cutomtooltipWrapper}>
                    <Box sx={{ ...dashboardStyle.provisionedServices.cutomtooltip, background: payload[0].payload.fill }} />
                    <Typography sx={dashboardStyle.provisionedServices.typographyStyle}>
                        Application(s):<b>{payload[0]?.payload?.value}</b>
                    </Typography>
                </Stack>
            );
        }
        return null;
    };
    return (
        <Chart title="Applications OnBoarded">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {query.isLoading && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.circularProgress_wrapper}>
                        <CircularProgress sx={dashboardStyle.ApplicationOnBoardStyle.circularProg} size={16} />
                    </Box>
                )}
                {!query.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.ApplicationOnBoardStyle.circularProgress_wrapper}>No data available</Box>}
                {!query.isLoading && data?.length > 0 && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.chart_wrapper}>
                        <ResponsiveContainer width="90%" height={280}>
                            <BarChart data={data}>
                                <XAxis interval={0} dataKey="name" height={60} tickCount={10} tick={<CustomizedAxisTick />} />
                                <YAxis domain={[0, 'dataMax + 2']} />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar type="monotone" dataKey="value" barSize={15} fill="#00A0DD" label={{ position: 'top' }} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(ApplicationOnBoarded);
