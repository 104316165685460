/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { privateEndPointCatalogServiceCode, eventhubCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddSubscription, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Subscription = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDSubscription, setLzDDSubscription] = useRecoilState(ddSubscription);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const subscriptionApi = useMutation(() => axios.get(endPoints.landingZones.subscription));
    const privateEndpointsubscriptionApi = useMutation((params) =>
        axios.get(`${endPoints.landingZones.privateEndpoint.subscription}?type=${params.endPointType}`)
    );

    const setSubScription = (subscription, subscriptionDetails) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                subscription,
                subscriptionDetails,
                resGroup: null,
                resGroupDetails: null,
                vNet: null,
                vNetDetails: null,
                subNet: null,
                subNetDetails: null,
                proxPlacGrp: null,
                proxPlacGrpDetails: null,
                availSet: null,
                availSetDetails: null,
                vnetSubnet:
                    landZoneForm?.cloudService === eventhubCatalogServiceCode
                        ? [{ vNet: null, vNetDetails: null, subNet: null, subNetDetails: null }]
                        : landZoneForm?.vnetSubnet,
            });
        }
    };

    const seldValueExistsInOptions = (subValue) => options.find((value) => subValue === value?.label);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSubScription(selected?.label, selected);
        } else {
            setSubScription(null, null);
        }
    };

    React.useEffect(() => {
        if (subscriptionApi?.isSuccess) {
            setOptions(subscriptionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.subscriptionId, id: app?.subscriptionId })));
            setLzDDSubscription({ ...lzDDSubscription, isLoaded: true });
        }
    }, [subscriptionApi?.isSuccess]);

    React.useEffect(() => {
        if (privateEndpointsubscriptionApi?.isSuccess) {
            setOptions(
                privateEndpointsubscriptionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.subscriptionId, id: app?.subscriptionId }))
            );
            setLzDDSubscription({ ...lzDDSubscription, isLoaded: true });
        }
    }, [privateEndpointsubscriptionApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const region = options.find((value) => lzModal?.lzDetails?.config?.subscription?.name === value?.label);
            if (region) setLzDDSubscription({ ...lzDDSubscription, isLoaded: true, data: region });
        }
    }, [options]);

    React.useEffect(() => {
        if (landZoneForm?.cloudService !== privateEndPointCatalogServiceCode) {
            subscriptionApi.mutate();
        } else if (landZoneForm?.cloudService === privateEndPointCatalogServiceCode && landZoneForm?.endPointType) {
            privateEndpointsubscriptionApi.mutate({ endPointType: landZoneForm?.endPointType });
        }
    }, [landZoneForm.endPointType]);

    return (
        <Box bgcolor="white">
            <Select
                key="Subscription"
                value={landZoneForm?.subscription || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subscriptionApi.isLoading}
                isLoading={subscriptionApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Subscription"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Subscription);
