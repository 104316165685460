import { atom } from 'recoil';

export const openChatbot = atom({
    key: 'chatbot-openChatbot',
    default: false,
});

export const isResponseLoading = atom({
    key: 'chatbot-isResponseLoading',
    default: false,
});
