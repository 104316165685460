/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress } from '@mui/material';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import Chart from '../components/Chart';
import { dateRangeValue, dashboardFilter } from '../store';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

// const COLORS = ['#F99C24', '#0047BA', '#F4273B', '#91C24C'];

const OrdersProcessingByService = () => {
    const [data, setData] = React.useState([]);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);

    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            providers: dashboardFilterObj?.provider || [],
            sectors: dashboardFilterObj?.sector || [],
            services: dashboardFilterObj?.services || [],
            solutions: dashboardFilterObj?.solutions || [],
            environments: dashboardFilterObj?.environment || [],
            regions: dashboardFilterObj?.regions || [],
            applicationService: dashboardFilterObj?.ApplicationService?.label || '',
            metricType: 'servicesSLA',
        };
        setData([]);
        ordersTrendQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess) {
            const results = ordersTrendQuery?.data?.data?.map((item) => ({
                name: item?.key,
                value: item?.doc_count,
                percentage: item?.percentage,
            }));
            setData(results || []);
        }
    }, [ordersTrendQuery?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 6);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);

    const gradients = [
        ['#91C24C', '#91C24C'], // Gradient for sector A  < 15 Mins #93C572
        ['#fdd7a7', '#fdd7a7'], // Gradient for sector B 15-30 Mins
        ['#F99C24', '#F99C24'], // Gradient for sector C 30-45 Mins
        ['#F4273B', '#F4273B'], // Gradient for sector D > 45 Mins
    ];

    // Custom stroke path for L-shaped stroke
    // const customStrokePath = (startX, startY, endX, endY) => `M${startX},${startY} L${endX},${startY} L${endX},${endY}`;
    return (
        <Chart title="SERVICES PROCESSING TIME" subTitle>
            {/* <Typography sx={dashboardStyle.chart.subchartTitle}>ORDERS PROCESSING BY SERVICE </Typography> */}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {ordersTrendQuery.isLoading && (
                    <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                        <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                    </Box>
                )}
                {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                {data?.length > 0 && (
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart width={300} height={300}>
                            <defs>
                                {gradients.map((gradient, index) => (
                                    <linearGradient key={`gradnt-${index}`} id={`gradnt-${index}`} x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor={gradient[0]} />
                                        <stop offset="100%" stopColor={gradient[1]} />
                                    </linearGradient>
                                ))}
                            </defs>
                            <Pie
                                data={data}
                                dataKey="value"
                                cx={150}
                                cy={150}
                                innerRadius={70}
                                label
                                outerRadius={90}
                                fill="#82ca9d"
                                startAngle={180}
                                endAngle={-180}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={`url(#gradnt-${index})`}
                                        // path={customStrokePath(200, 200, Math.cos(Math.PI * 0.5) * 80 + 200, Math.sin(Math.PI * 0.5) * 80 + 200)}
                                    />
                                ))}
                            </Pie>
                            <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                formatter={(value, entry) => `${value} - ${entry.payload.percentage.toFixed(2)}%`}
                                style={{ marginBottom: '10px' }}
                            />
                            {/* <LabelList dataKey="name" position="right" style={{ fontSize: '10px' }} /> */}
                        </PieChart>
                    </ResponsiveContainer>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(OrdersProcessingByService);
