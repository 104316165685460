// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack, Box, Grid, Typography, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder, isInvalidPartitionCount } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const AvailablePartitionCount = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [, setInvalidPartitionCount] = useRecoilState(isInvalidPartitionCount);
    const [availablePartitionCount, setAvailablePartitionCount] = React.useState(0);

    const eventHubDetailApi = useMutation(() =>
        axios.get(
            `${endPoints?.ssp?.eventHub?.eventHubLookup}/subscription/${order?.envDetails?.config?.subscription?.id}/resourceGroup/${order?.envDetails?.config?.resourceGroup}/namespace/${order?.namespaceName}`
        )
    );

    React.useEffect(() => {
        setAvailablePartitionCount(0);
        if (order?.nsExist === 'no') {
            if (order?.performance === 'Standard') {
                setAvailablePartitionCount(32);
            } else {
                setAvailablePartitionCount(Number(order?.processingUnits) * 200);
            }
        }
    }, [order?.performance, order?.processingUnits]);

    React.useEffect(() => {
        if (eventHubDetailApi?.isSuccess) {
            setAvailablePartitionCount(0);
            let availPartitionCount = 0;
            if (order?.performance === 'Premium') {
                availPartitionCount = Number(order?.processingUnits) * 200 - Number(eventHubDetailApi?.data?.data?.totalPartitionCount);
            } else {
                availPartitionCount = 32 - Number(eventHubDetailApi?.data?.data?.totalPartitionCount);
            }
            setAvailablePartitionCount(availPartitionCount);
        }
    }, [eventHubDetailApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.nsExist === 'yes' && order?.namespaceName && order?.envDetails?.config) {
            eventHubDetailApi.mutate();
        }
    }, [order?.nsExist, order?.namespaceName, order?.envDetails?.config]);

    const partitionCountCheck = () => {
        setInvalidPartitionCount(false);
        let totalPartitionCount = 0;
        order?.eventHubDetails?.forEach((val) => {
            totalPartitionCount += Number(val?.partitionCount);
        });
        if (order?.nsExist === 'no') {
            if (totalPartitionCount > availablePartitionCount) {
                setInvalidPartitionCount(true);
                return true;
            }
            setInvalidPartitionCount(false);
            return false;
        }
        if (order?.nsExist === 'yes') {
            if (eventHubDetailApi?.isSuccess) {
                if (totalPartitionCount > availablePartitionCount) {
                    setInvalidPartitionCount(true);
                    return true;
                }
                setInvalidPartitionCount(false);
                return false;
            }
        }
        return false;
    };

    return (
        <>
            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                <CssTextField
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                    }}
                    disabled
                    label="Available Partition Count *"
                    value={availablePartitionCount || ''}
                    InputProps={{
                        endAdornment: <>{eventHubDetailApi.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                    }}
                />
                <HelpImg
                    maxmWidth="450px"
                    title={
                        <>
                            <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                            </Typography>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>2) Min.: 6 chars to Max.: 50 chars</Typography>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                3) Can contain only hyphen(-) as special char
                                            </Typography>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                4) Should start with &quot;pep-&quot; and end with letter or number
                                            </Typography>
                                            <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>5) Should be unique value</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    }
                />
            </Stack>
            {partitionCountCheck() && (
                <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                    Total partition count exceeding available partition count.
                </Typography>
            )}
        </>
    );
};

export default AvailablePartitionCount;
