import React from 'react';
import Select from 'pages/reference-implementation/components/select';
import PropTypes from 'prop-types';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import { Box } from '@mui/material';

const Partitions = (props) => {
    const { isRequired, handleOnSelect, partitionvalue } = props;
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        setOptions(
            Array.from({ length: 24 }, (_, i) => i + 1)
                .filter((i) => i % 3 === 0)
                .map((i) => ({
                    label: `${i}`,
                    value: `${i}`,
                }))
        );
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="partitions"
                label="Partitions *"
                value={partitionvalue || null}
                options={options}
                handleOnSelect={(value) => handleOnSelect(value?.value)}
                isRequired={isRequired}
                dataTestId="partionSelect"
            />
        </Box>
    );
};
Partitions.propTypes = {
    // errors: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
    partitionvalue: PropTypes.string,
};

Partitions.defaultProps = {
    // errors: null,
    handleOnSelect: () => {},
    isRequired: false,
    partitionvalue: '',
};
export default Partitions;
