import React from 'react';
import Select from 'components/select';
import Popper from '@mui/material/Popper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PropTypes from 'prop-types';
import { Badge, Box, Button, Paper, Stack, TextField, Grid } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { landZoneListFilter, profileFilter } from 'pages/app-profiles/store';
import { useTranslation } from 'react-i18next';
import { getResetFilter } from 'pages/app-profiles/landing-zones/utils';
import LandingZoneStyle, { ColorButton, RestButton } from '../../LandingZoneStyle';

const LandZonesFilter = (props) => {
    const { t } = useTranslation();
    const { appProfileName } = props;
    const [listFilter, setListFilter] = useRecoilState(landZoneListFilter);
    const [filters, setFilter] = React.useState({ ...listFilter });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [cloudProviderOptions, setCloudProviderOptions] = React.useState([]);
    const [cloudServiceOptions, setCloudServiceOptions] = React.useState([]);
    const [environmentOptions, setEnvironmentOptions] = React.useState([]);
    const [regionOptions, setRegionOptions] = React.useState([]);
    // const [appTierOptions, setAppTierOptions] = React.useState([]);
    const cloudProviderApi = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    const cloudServiceApi = useMutation((payload) => axios.get(`${endPoints.landingZones.cloudService}/${payload}/cloudservices`));
    const environmentApi = useMutation(() => axios.get(endPoints.landingZones.environments));
    const regionApi = useMutation(() => axios.get(endPoints.landingZones.regions));
    // const appTierApi = useMutation((payload) => axios.post(endPoints.landingZones.appTier, payload));
    const { profileType } = useRecoilValue(profileFilter);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const handleOnSelect = (name, selected) => {
        const resetValue = getResetFilter({ fieldName: name });
        setFilter({ ...filters, ...resetValue, [name]: selected });
    };
    const handleSubmitFilter = () => {
        setListFilter({
            ...listFilter,
            // appTier: filters?.appTier?.data?.code || '',
            cloudProvider: filters?.cloudProvider?.value || '',
            cloudService: filters?.cloudService?.value || '',
            environment: filters?.environment?.value || '',
            region: filters?.region?.name || '',
            pageNumber: 1,
        });
        handleClick();
    };
    const handleReset = () => {
        setFilter({
            ...filters,
            // appTier: null,
            cloudProvider: null,
            cloudService: null,
            environment: null,
            region: null,
        });
    };
    const getDistinctByCloudService = (cloudServices) =>
        cloudServices?.reduce((memo, e1) => {
            const matches = memo.filter((e2) => e1.value === e2.value);
            if (matches.length === 0) memo.push(e1);
            return memo;
        }, []);
    const isFiltered = () =>
        Object.keys(filters)?.filter((key) => !['appProfileName', 'pageSize', 'pageNumber'].includes(key) && filters[key] !== null && filters[key] !== '')
            ?.length;
    React.useEffect(() => {
        if (filters?.cloudProvider?.value) {
            cloudServiceApi.mutate(filters?.cloudProvider?.value);
        }
    }, [filters?.cloudProvider]);
    React.useEffect(() => {
        if (cloudProviderApi?.isSuccess) {
            setCloudProviderOptions(cloudProviderApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [cloudProviderApi?.isSuccess]);
    React.useEffect(() => {
        if (cloudServiceApi?.isSuccess) {
            setCloudServiceOptions(
                getDistinctByCloudService(cloudServiceApi?.data?.data?.map((app) => ({ label: app.cloudService, value: app.cloudService })))
            );
        }
    }, [cloudServiceApi?.isSuccess]);
    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            setEnvironmentOptions(environmentApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [environmentApi?.isSuccess]);
    React.useEffect(() => {
        if (regionApi?.isSuccess) {
            setRegionOptions(regionApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.displayName, name: app?.name })));
        }
    }, [regionApi?.isSuccess]);
    // React.useEffect(() => {
    //     if (appTierApi?.isSuccess) {
    //         setAppTierOptions(appTierApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
    //     }
    // }, [appTierApi?.isSuccess]);
    React.useEffect(() => {
        cloudProviderApi.mutate();
        environmentApi.mutate();
        regionApi.mutate();
        return () => {
            handleReset();
            setListFilter({
                ...listFilter,
                // appTier: '',
                cloudProvider: '',
                cloudService: '',
                environment: '',
                region: '',
                pageNumber: 1,
            });
        };
    }, []);
    // React.useEffect(() => {
    //     if (filters?.cloudService && filters.cloudService?.value) {
    //         setFilter({
    //             ...filters,
    //             appTier: null,
    //         });
    //         appTierApi.mutate({ cloudServiceName: filters?.cloudService?.value });
    //     }
    // }, [filters?.cloudService]);
    return (
        <>
            <Button
                sx={LandingZoneStyle.landingZoneFilter.filterBtn}
                startIcon={
                    <Badge variant="dot" overlap="circular" color={filters && isFiltered() ? 'error' : 'default'}>
                        <FilterAltIcon color="inherit" size={36} />
                    </Badge>
                }
                disableElevation
                disableFocusRipple
                disableRipple
                onClick={handleClick}
                data-testid="filter-btn"
            >
                Filter
            </Button>

            <Popper
                open={open}
                anchorEl={anchorEl}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                            fallbackPlacements: ['bottom'],
                            padding: 8,
                        },
                    },
                ]}
                style={LandingZoneStyle.landingZoneFilter.popperStyle}
                className="landFilter"
            >
                <Paper elevation={3} sx={LandingZoneStyle.landingZoneFilter.wrapper}>
                    <ArrowDropUpIcon
                        sx={{
                            position: 'absolute',
                            fontSize: 50,
                            top: -30,
                            left: `${profileType?.profileType === 'My AppProfiles' ? '220px' : '320px'}`,
                            fill: '#FFFFFF',
                            zIndex: 999,
                        }}
                    />
                    <Stack spacing={2} sx={LandingZoneStyle.landingZoneFilter.dropDownStack}>
                        <Box sx={LandingZoneStyle.landingZoneFilter.dropDownContainer}>
                            <Box
                                sx={LandingZoneStyle.landingZoneFilter.closeBox}
                                onClick={() => {
                                    handleReset();
                                    setListFilter({
                                        ...listFilter,
                                        appTier: '',
                                        cloudProvider: '',
                                        cloudService: '',
                                        environment: '',
                                        region: '',
                                        pageNumber: 1,
                                    });
                                    handleClick();
                                }}
                            >
                                <CloseIcon color="#AFB8CC" sx={{ marginRight: 1.5, marginTop: 1, cursor: 'pointer', '&:hover': { color: '#3381FF' } }} />
                            </Box>
                        </Box>
                        <Grid container spacing={1.5}>
                            <Grid item xs={6}>
                                <TextField
                                    label={t([`landingZone.label.application`])}
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    value={appProfileName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    label={t([`landingZone.label.provider`])}
                                    key="cloudProviderKey"
                                    value={filters?.cloudProvider}
                                    options={cloudProviderOptions}
                                    handleOnSelect={(value) => handleOnSelect('cloudProvider', value)}
                                    isLoading={cloudProviderApi?.isLoading}
                                    disabled={cloudProviderApi?.isLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    label={t([`landingZone.label.service`])}
                                    key="cloudServiceKey"
                                    value={filters?.cloudService}
                                    options={cloudServiceOptions}
                                    handleOnSelect={(value) => handleOnSelect('cloudService', value)}
                                    noOptionsText={filters?.cloudProvider ? '' : 'Select Provider first'}
                                    isLoading={cloudServiceApi?.isLoading}
                                    disabled={cloudServiceApi?.isLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    label={t([`landingZone.label.environment`])}
                                    key="environmentName"
                                    value={filters?.environment}
                                    options={environmentOptions}
                                    handleOnSelect={(value) => handleOnSelect('environment', value)}
                                    disabled={environmentApi.isLoading}
                                    isLoading={environmentApi.isLoading}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    label={t([`landingZone.label.region`])}
                                    key="regionName"
                                    value={filters?.region}
                                    options={regionOptions}
                                    handleOnSelect={(value) => handleOnSelect('region', value)}
                                    disabled={regionApi.isLoading}
                                    isLoading={regionApi.isLoading}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <Select
                                    label={t([`landingZone.label.appTier`])}
                                    key="appTier"
                                    value={filters?.appTier}
                                    options={appTierOptions}
                                    handleOnSelect={(value) => handleOnSelect('appTier', value)}
                                    disabled={appTierApi.isLoading}
                                    isLoading={appTierApi.isLoading}
                                />
                            </Grid> */}
                        </Grid>
                    </Stack>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <ColorButton disableElevation onClick={handleSubmitFilter} variant="contained" color="cmpPrimary" data-testid="apply-btn">
                            {t([`landingZone.btnText.apply`])}
                        </ColorButton>
                        <RestButton disableElevation variant="contained" onClick={() => handleReset()} color="cmpPrimary" data-testid="reset-btn">
                            {t([`landingZone.btnText.reset`])}
                        </RestButton>
                        <RestButton
                            disableElevation
                            variant="contained"
                            onClick={() => {
                                setFilter(listFilter);
                                handleClick();
                            }}
                            data-testid="close-btn"
                        >
                            {t([`landingZone.btnText.close`])}
                        </RestButton>
                    </Stack>
                </Paper>
            </Popper>
        </>
    );
};
LandZonesFilter.propTypes = {
    appProfileName: PropTypes.string.isRequired,
};
LandZonesFilter.defaultProps = {};
export default React.memo(LandZonesFilter);
