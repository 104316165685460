/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { endPoints } from 'api/endpoints';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { landingZoneForm } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Applications = () => {
    const [, setSeldApp] = React.useState(null);
    const [landZoneForm, setLandZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const applicationApi = useMutation((payload) => axios.post(`${endPoints.appProfile.allAppProfiles}`, payload));
    const setAppService = (applicationService) => {
        setLandZoneForm({
            ...landZoneForm,
            applicationService,
        });
    };

    const handleOnSelectChange = (selected) => {
        if (selected?.value !== landZoneForm?.applicationService) {
            setSeldApp(selected);
            setAppService(selected?.value);
        }
    };
    React.useEffect(() => {
        applicationApi.mutate({ page: 0, size: 10, searchKey: '' });
    }, []);

    useEffect(() => {
        if (applicationApi?.isSuccess) {
            setOptions(applicationApi?.data?.data?.appProfiles.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [applicationApi?.isSuccess]);
    React.useEffect(() => {
        if (options.length && options.length === 1) {
            handleOnSelectChange(options[0]);
        }
    }, [options]);
    return (
        <Box>
            <Select
                key="applicationName"
                value={landZoneForm?.applicationService || null}
                options={options}
                sx={{ width: '150px', borderColor: 'none' }}
                handleOnSelect={handleOnSelectChange}
                disabled={applicationApi.isLoading}
                isLoading={applicationApi.isLoading}
                renderInput={(params) => (
                    <CssTextField
                        {...params}
                        label="Application Name"
                        placeholder="Application"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Applications);
