export const formOrderObjFromCart = (data = null, osType = null, serverType = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const dataDisks = data?.itemDetail?.catalogService?.catalogConfig?.diskDefinitions || null;
    const os = data?.itemDetail?.catalogService?.catalogConfig?.os || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: entityDetails?.costCenter,
            projectName: entityDetails?.project,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: data?.itemDetail?.catalogService?.catalogConfig?.billingEntity || null,
        hCode: entityDetails?.costCenter || null,
        ...(entityDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!entityDetails?.costCenter && { hCodeDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        osVersion: os?.publisher ? `${os?.publisher},${os?.sku}` : null,
        // osVersionDetail: data?.osType || null,
        osType: os?.osType,
        sku: os?.sku,
        vmType: data?.itemDetail?.catalogService?.catalogConfig?.vmType || null,
        dataDisksCount: dataDisks?.length || 0,
        dataDisks:
            dataDisks?.map((item) => ({
                // eslint-disable-next-line
                diskType: item?.diskType ? item?.diskType : ['Production'].includes(environmentDetails?.environment) ? 'Premium SSD' : 'Standard SSD',
                diskDriveLetter: item?.diskDriveLetter,
                mountPoint: item?.mountPoint,
                // eslint-disable-next-line
                fsType: item?.fsType ? item?.fsType : os?.osType === 'Windows' ? 'NTFS' : 'ext4',
                diskSizeInGib: item?.diskSizeInGib,
            })) || [],
        expDBSize: Number(catalogConfig?.dbSize) || 0,
        databaseName: catalogConfig?.dbName,
        dBCharacterSet: catalogConfig?.dbCharacterSet,
        dBNationalCharSet: catalogConfig?.dbNationalCharSet,
        sqlVersion: catalogConfig?.sqlVersion,
        serviceInstalled: catalogConfig?.services || [],
        sqlServerCollation: catalogConfig?.sqlServerCollation || null,
        domain: environmentDetails?.domain || null,
        acceleratedNetworking: catalogConfig?.acceleratedNetworking === true ? 'yes' : 'no',
        // domainDetail: data?.domain || null,
        adousDefault: environmentDetails?.adOu || 'Default OU',
        ...(osType === 'Windows' && { adGroup: data?.itemDetail?.catalogService?.catalogConfig?.adGroup || '' }),
        ...(osType === 'Linux' && { samAccountId: data?.itemDetail?.catalogService?.catalogConfig?.samAccountId || '' }),
        ...(environmentDetails?.availabilityZones && {
            haDeployment: 'Availability Zones',
            availabilityZone: environmentDetails?.availabilityZones,
            isValidAvailabilityZone: true,
        }),
        ...(environmentDetails?.availabilitySet && {
            haDeployment: 'Availability Set',
            isValidAvailabilityZone: true,
        }),
        ...(catalogConfig?.serverRefresh && {
            retiringServerName: catalogConfig?.retiringServerName || null,
            retiringServerSysId: catalogConfig?.retiringServerSysId || null,
            scheduledRetirement: catalogConfig?.scheduledRetirement || null,
            disposalReason: catalogConfig?.disposalReason || null,
        }),
        quantity: data?.itemDetail?.catalogService?.quantity,
        timeZone: data?.itemDetail?.catalogService?.timeZone,
        serverRefresh: catalogConfig?.serverRefresh === true ? 'yes' : 'no',
        serverType,
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null, osType = null, serverType = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const dataDisks = catalogConfig?.diskDefinitions || null;
    const os = catalogConfig?.os || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: environmentDetails?.projectName,
            billingEntity: catalogConfig?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        osVersion: os?.publisher ? `${os?.publisher},${os?.sku}` : null,
        // osVersionDetail: data?.osType || null,
        osType: os?.osType,
        sku: os?.sku,
        vmType: catalogConfig?.vmType || null,
        dataDisksCount: dataDisks?.length || 0,
        dataDisks:
            dataDisks?.map((item) => ({
                // eslint-disable-next-line
                diskType: item?.diskType ? item?.diskType : ['Production'].includes(environmentDetails?.environment) ? 'Premium SSD' : 'Standard SSD',
                diskDriveLetter: item?.diskDriveLetter,
                mountPoint: item?.mountPoint,
                // eslint-disable-next-line
                fsType: item?.fsType ? item?.fsType : os?.osType === 'Windows' ? 'NTFS' : 'ext4',
                diskSizeInGib: item?.diskSizeInGib,
            })) || [],
        expDBSize: Number(catalogConfig?.dbSize) || 0,
        databaseName: catalogConfig?.dbName,
        dBCharacterSet: catalogConfig?.dbCharacterSet,
        dBNationalCharSet: catalogConfig?.dbNationalCharSet,
        sqlVersion: catalogConfig?.sqlVersion,
        serviceInstalled: catalogConfig?.services || [],
        sqlServerCollation: catalogConfig?.sqlServerCollation || null,
        domain: environmentDetails?.domain || null,
        acceleratedNetworking: catalogConfig?.acceleratedNetworking === true ? 'yes' : 'no',

        // domainDetail: data?.domain || null,
        adousDefault: environmentDetails?.adOu || 'Default OU',
        ...(osType === 'Windows' && { adGroup: catalogConfig?.adGroup || '' }),
        ...(osType === 'Linux' && { samAccountId: catalogConfig?.samAccountId || '' }),
        ...(environmentDetails?.availabilityZones && {
            haDeployment: 'Availability Zones',
            availabilityZone: environmentDetails?.availabilityZones,
            isValidAvailabilityZone: true,
        }),
        ...(environmentDetails?.availabilitySet && {
            haDeployment: 'Availability Set',
            isValidAvailabilityZone: true,
        }),
        ...(catalogConfig?.serverRefresh && {
            retiringServerName: catalogConfig?.retiringServerName || null,
            retiringServerSysId: catalogConfig?.retiringServerSysId || null,
            scheduledRetirement: catalogConfig?.scheduledRetirement || null,
            disposalReason: catalogConfig?.disposalReason || null,
        }),
        quantity: catalog?.quantity,
        timeZone: catalog?.timeZone,
        serverRefresh: catalogConfig?.serverRefresh === true ? 'yes' : 'no',
        serverType,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time cost',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'per item per month',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};
export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    envDetails: null,
    osVersion: null,
    osDiskType: null,
    vmType: null,
    vmTypeDetails: null,
    quantity: 1,
    maxDataDiskCount: null,
    dataDisksCount: null,
    dataDisks: [],
    defaultDataDisks: [],
    adGroup: null,
    hoursOfOperation: null,
    hoursOfOperationDetail: null, // { active: 1, id: '763cf923-85d9-43f7-b338-7be17fded399', name: 'Always ON', type: 'hours_of_operation' },
    timeZone: 'UTC',
    timeZoneDetail: null, // { id: 'd3bb59b6-72b3-49c0-a1c2-c0494ea28802', name: 'UTC', type: 'time_zones', value: 'Default-UTC' }
    domain: null,
    domainDetails: null,
    acceleratedNetworking: 'yes',
    hCode: null,
    hCodeDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    adousDefault: null,
    adous: null,
    adousDetails: null,
    sector: null,
    sectorDetails: null,
    tags: [],
    tagsQc: null,
    estimatedCost: resetCostEstimateDetails,
    serverRefresh: 'no',
    validationStatus: 'clearValidation',
    isTouched: false,
    haDeployment: null,
    availabilityZone: null,
    availabilityZoneOptions: [],
    retiringServerName: null,
    retiringServerSysId: null,
    disposalReason: null,
    scheduledRetirement: null,
    expDBSize: '',
    databaseName: null,
    serviceInstalled: [],
};

export const restForQuickConfig = {
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    osVersion: null,
    osVersionDetail: null,
    vmType: null,
    vmTypeDetails: null,
    dataDisksCount: 0,
    dataDisks: [],
    domain: null,
    domainDetail: null,
    adousDefault: null,
    expDBSize: '',
    haDeployment: null,
    envDetails: null,
    availabilityZone: null,
    availabilityZoneOptions: [],
    databaseName: null,
    serverCollation: null,
    sqlVersion: null,
    serviceInstalled: null,
};
export const restEnv = {
    Subscription: '-',
    'DR Priority': '-',
    Resource_Group: '-',
    Metallic_Selection: '-',
    Backup_Tier: '-',
    Virtual_Network: '-',
    // Network_Security_Group: '-',
    Subnet_Name: '-',
    // Application_Security_Group: '-',
    Recovery_Vault_Name: '-',
    Approval_Manager: '-',
    Project: '-',
    Availability_Set: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isDiskOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
};
