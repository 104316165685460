export const IsReadyToSubmit = ({ details, validApplication }) => {
    return (
        !details?.applicationCI ||
        !details?.applicationCIDetails ||
        !details?.sector ||
        !details?.firstName ||
        !details?.lastName ||
        !details?.gpid ||
        !details?.email ||
        validApplication
    );
};

export const ResetRequestDetails = {
    applicationCI: null,
    applicationCIDetails: null,
    firstName: null,
    lastName: null,
    gpid: null,
    email: null,
    sector: null,
    isTouched: false,
};

export const getPayload = ({ details }) => {
    return {
        applicationName: details?.applicationCI,
        developerGpid: details?.gpid,
        firstName: details?.firstName,
        lastName: details?.lastName,
        email: details?.email,
        sector: details?.sector,
    };
};
