import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Select from 'components/select';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const SamAccountId = ({ indexVal }) => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(order?.nodes?.[indexVal]?.samAccountId || '');
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const samApi = useMutation(() => axios.get(`${endPoints.ssp.vm.sam}?accountId=${inputValue}`), {
        onSuccess: (resp) => {
            if (resp?.data?.accounts && inputValue.length > 2) {
                setOptions(resp?.data?.accounts.map((app) => ({ label: app.accountId, value: app.accountId, data: app })));
            } else {
                setOptions([]);
            }
        },
    });

    const setSamAccount = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    const seldValueExistsInOptions = (samAccValue) => options.find((option) => samAccValue === option.value);
    const handleOnSelectChange = (selected) => {
        setSamAccount('samAccountId', selected?.value, indexVal);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                samApi.mutate();
            }, 1000),
        []
    );
    React.useEffect(() => {
        if (options && options?.length && order?.nodes?.[indexVal]?.samAccountId) {
            const samAccount = seldValueExistsInOptions(order?.nodes?.[indexVal]?.samAccountId);
            if (samAccount) setOrder({ ...order, samAccountId: samAccount.value, samAccountIdDetails: samAccount?.data });
        }
    }, [options]);
    React.useEffect(() => {
        if (inputValue === '') {
            setOptions([]);
            setOrder({ ...order, samAccountId: null, samAccountIdDetails: null });
        }
        if (inputValue !== '' && inputValue?.length > 2) fetch();
    }, [inputValue, fetch]);

    return (
        <Stack spacing={1} direction="row" sx={ProvisioningStyles.stack}>
            <Select
                key="SamAccountIdKey"
                label="SAM Account"
                value={order?.nodes?.[indexVal]?.samAccountId || ''}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isLoading={samApi?.isLoading}
                noOptionsText="Enter atleast 3 characters for suggestions"
                autoComplete
                includeInputInList
                filterSelectedOptions
                fullWidth
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
            />
        </Stack>
    );
};

SamAccountId.propTypes = {
    indexVal: PropTypes.number,
};

SamAccountId.defaultProps = {
    indexVal: 0,
};

export default React.memo(SamAccountId);
