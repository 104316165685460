/* eslint import/no-unresolved: [2, { commonjs: true, amd: true }] */
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { DateRange } from 'react-date-range';
import { CssTextField } from 'components/select';
import Popper from '@mui/material/Popper';
import format from 'date-fns/format';
import { addMonths } from 'date-fns';
import { Paper, Box, IconButton, ClickAwayListener } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { dateRangeValue } from './store';
import dashboardStyle from './style';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DashboardDateRange = () => {
    const [range, setRange] = useState([
        {
            startDate: addMonths(new Date(), -6),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [dashboardDateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [maxDate, setMaxDate] = useState(new Date());
    const [selectedfocusedRange, setFocusedRange] = useState([]); // Initial focus on start date

    const handleSelect = (item) => {
        const { startDate } = item.selection;
        // Calculate max date to 12 months after the selected minDate
        const maxDt = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());

        setMaxDate(maxDt);
        setRange([{ ...item?.selection, key: 'selection' }]);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        setRange([
            {
                startDate: new Date(dashboardDateRange?.startDate),
                endDate: new Date(dashboardDateRange?.endDate),
                key: 'selection',
            },
        ]);
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-popper' : undefined;
    React.useEffect(() => {
        if (selectedfocusedRange[0] === 0 && selectedfocusedRange[1] === 0) {
            setAnchorEl(null);
            setOpen(false);
            setDateRange({ startDate: range[0]?.startDate.toString(), endDate: range[0]?.endDate.toString() });
        }
    }, [selectedfocusedRange]);
    const handleRangeFocusChange = (focusedRange) => {
        setFocusedRange(focusedRange);
    };
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <CssTextField
                    mt={2}
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& .MuiOutlinedInput-input': {
                            fontWeight: '500',
                            fontFamily: 'Stolzl',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#0047BA',
                                color: '#AFB8CC',
                                borderWidth: '2px',
                            },
                            '&:hover fieldset': {
                                borderColor: '#0047BA',
                                borderWidth: '2px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#0047BA',
                                borderWidth: '2px',
                            },
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton onClick={handleClick} data-testid="dashboardDate">
                                    <CalendarMonthIcon color="primary" />
                                </IconButton>
                            </>
                        ),
                        readOnly: true,
                    }}
                    value={`${format(range[0].startDate, 'MM/dd/yyyy')} - ${format(range[0].endDate, 'MM/dd/yyyy')}`}
                />

                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                    <Paper elevation={5} sx={dashboardStyle.dateRange.calendarWrap}>
                        <Box sx={dashboardStyle.orderFilter.popper.container}>
                            <DateRange
                                id="testingdatemetric"
                                onChange={handleSelect}
                                editableDateInputs={false}
                                moveRangeOnFirstSelection={false}
                                ranges={range}
                                months={1}
                                direction="horizontal"
                                maxDate={maxDate}
                                onRangeFocusChange={handleRangeFocusChange}
                            />
                        </Box>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default DashboardDateRange;
