import { atom } from 'recoil';

export const OrderPayload = atom({
    key: 'onboardAppService-OrderPayload',
    default: {
        businessApp: null,
        businessAppDetails: null,
        appService: null,
        environment: null,
        supportGrp: null,
        businessCriticality: null,
        soxClassification: 'No',
        sector: null,
        shortDescription: null,
        isTouched: false,
    },
});

export const orderId = atom({
    key: 'onboardAppService-orderId',
    default: undefined,
});

export const onboardAppServiceDialogPopup = atom({
    key: 'onboardAppService-onboardAppServiceDialogPopup',
    default: false,
});
