/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Checkbox from '@mui/material/Checkbox';
import { iaasCouchbaseServiceCode } from 'utils/catalogServiceCode';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { landingZoneData } from 'pages/app-profiles/store';
import MultiSelect from 'components/multiSelect';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AvailabilityZone = () => {
    const [isTouched, setIsTouched] = React.useState(false);

    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const availZoneApi = useMutation(() => axios.get(endPoints.landingZones.availabilityZone));

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.availZone) {
            const sel = landingZoneForm?.availZone?.map((lz) => options.filter((option) => option?.label === lz));
            setLandingZoneForm({ ...landingZoneForm, availZone: landingZoneForm?.availZone?.toString(), availZoneDetails: [].concat(...sel) });
        }
    }, [options]);
    React.useEffect(() => {
        if (availZoneApi?.isSuccess) {
            const avZone = availZoneApi?.data?.data[0]?.zonesList?.find((app) => app?.region === landingZoneForm?.regionDetails?.value);
            if (avZone)
                setOptions(
                    avZone?.zones
                        ?.join()
                        .split(',')
                        .map((app) => ({ label: app, value: app }))
                );
        }
    }, [availZoneApi?.isSuccess]);
    React.useEffect(() => {
        if (landingZoneForm?.region) {
            availZoneApi.mutate();
        }
    }, [landingZoneForm?.region]);

    React.useEffect(() => {
        if (!landingZoneForm?.availZone && landingZoneForm?.isTouched && !landingZoneForm?.availSet) setIsTouched(true);
    }, [landingZoneForm?.availSet, landingZoneForm?.isTouched, landingZoneForm?.availZone]);
    return (
        <Box bgcolor="white">
            <MultiSelect
                id="checkboxes-tags-availabilityZone"
                size="small"
                key="AvailabilityZone"
                label={landingZoneForm?.service === iaasCouchbaseServiceCode ? 'Availability Zone *' : 'Availability Zone'}
                fullWidth
                disablePortal
                disabled={availZoneApi?.isLoading}
                multiple
                value={landingZoneForm?.availZoneDetails || []}
                onChange={(_event, newValue) => {
                    setLandingZoneForm({
                        ...landingZoneForm,
                        availZone: [...newValue]
                            ?.map((app) => app?.label)
                            .sort((a, b) => (a?.label > b?.label && 1) || -1)
                            .join(','),
                        availZoneDetails: [...newValue].sort((a, b) => (a?.label > b?.label && 1) || -1),
                    });
                }}
                disableClearable
                options={options}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                    </li>
                )}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(AvailabilityZone);
