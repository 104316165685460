import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import Styles from 'pages/provisioning/_components/style';

const ProvisioningHeader = (props) => {
    const { title, children, displayimage } = props;
    return (
        <Box sx={Styles.provisionHeader.header} data-testid="prov-header">
            <Grid container sx={Styles.provisionHeader.gridContainer}>
                <Grid item sx={Styles.provisionHeader.gridItem}>
                    <span style={Styles.provisionHeader.spanStyle}>
                        <img src={displayimage.iconPath} alt="" style={Styles.provisionHeader.imageStyle} />
                    </span>
                    <Typography sx={Styles.provisionHeader.pageTitle} variant="h6">
                        {title}
                    </Typography>
                </Grid>
                <Grid item sm />
                {children && <Grid item>{children}</Grid>}
            </Grid>
        </Box>
    );
};

ProvisioningHeader.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    displayimage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ProvisioningHeader.defaultProps = {
    title: 'Page Title',
    children: '',
    displayimage: '',
};

export default React.memo(ProvisioningHeader);
