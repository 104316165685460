/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import Modal from 'components/modal';
import { sspSubmitOrder, toggleSspModels, isStorageDiskConfigFullfilled } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import Form from 'pages/provisioning/day2-operation/components/Form/index';
import { isStorageDiskFormFilled } from 'pages/provisioning/day2-operation/utils';

const ConfigStorageDiskModal = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [lastModifiedStorageDisk, setLastModifiedStorageDisk] = React.useState(undefined);
    const [, setIsBackendPoolsConfig] = useRecoilState(isStorageDiskConfigFullfilled);
    const reset = () => {
        setIsOpen({ ...isOpen, isStorageDiskModalOpen: false });
        setOrder({
            ...order,
            ...lastModifiedStorageDisk,
        });
    };

    React.useEffect(() => {
        if (isOpen?.isStorageDiskModalOpen) {
            setLastModifiedStorageDisk({
                diskSizeInGB: order?.diskSizeInGB,
                diskTier: order?.diskTier,
                provisionedIops: order?.provisionedIops,
                provisionedThroughput: order?.provisionedThroughput,
                maxShare: order?.maxShare,
                maxBurstIops: order?.maxBurstIops,
                maxBurstThroughput: order?.maxBurstThroughput,
                costStorage: order?.costStorage,
            });
        }
    }, [isOpen?.isStorageDiskModalOpen]);

    return (
        <Modal title="Config Size + Performance" handleClose={() => reset()} open={isOpen.isStorageDiskModalOpen}>
            <Box sx={ProvisioningStyles.modalBox.wrapper}>
                <Box sx={ProvisioningStyles.modalBox.contentWrapper}>
                    <Grid container spacing={3.125} pt={3.125} pb={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Stack direction="column" spacing={2}>
                                        <Form.DiskSize />
                                        <Form.DiskTier />
                                        <Form.ProvisionedIops />
                                        <Form.ProvisionedThroughput />
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="column" spacing={2}>
                                        <Form.MaxShare />
                                        <Form.MaxBurstIops />
                                        <Form.MaxBurstThroughput />
                                        <Form.CostStorage />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="footer" sx={ProvisioningStyles.modalBox.actionWrapper}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isStorageDiskModalOpen: false });
                            setIsBackendPoolsConfig(true);
                        }}
                        disabled={isStorageDiskFormFilled({ order })}
                        data-testid="save"
                    >
                        SAVE
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.modalBox.cancelBtn}
                        onClick={() => reset()}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel"
                    >
                        CANCEL
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfigStorageDiskModal;
