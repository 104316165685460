const PreviewStyles = {
    previewHeaderText1: { fontSize: '21px', color: '#021D49', fontFamily: 'SansBold' },
    previewHeaderText2: { fontSize: '16px', color: '#021D49', fontFamily: 'SansRegular' },
    card: {
        boxShadow: '0px 0px 2px #0000003E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        marginTop: 2,
        padding: '15px 15px 15px 25px',
    },
    generalInfo: { fontSize: '18px', color: '#000', fontFamily: 'SansMedium', letterSpacing: '-0.14px ' },
    generalWrapper: { display: 'flex', flexDirection: 'column', marginTop: '-5px' },
    divider: { color: '#707070', marginTop: 1, marginBottom: 1 },
    keyText: {
        fontSize: '16px',
        color: '#000',
        fontFamily: 'SansMedium',
        letterSpacing: '0px ',
    },
    valueText: {
        fontSize: '14px',
        color: '#000',
        fontFamily: 'SansRegular',
        letterSpacing: '0px ',
        wordWrap: 'break-word',
    },
    vsCard: {
        boxShadow: '0px 0px 2px #0000003E',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '4px',
        opacity: 1,
        padding: '24px 0 10px 15px',
        marginBottom: 1.5,
    },
    vsType: {
        fontSize: '18px',
        color: '#000',
        fontFamily: 'SansBold',
        letterSpacing: '-0.14px',
        pb: 1,
    },
    vsSubType: {
        fontSize: '16px',
        color: '#1E1E1E',
        fontFamily: 'SansBold',
        letterSpacing: '0px',
    },
    vsTypeHeaders: {
        fontSize: '16px',
        color: '#1378D4',
        fontFamily: 'SansMedium',
        letterSpacing: '0',
        paddingBottom: 2,
        textAlign: 'left',
        width: '33.33%',
    },
    previewBtn: {
        borderRadius: '4px',
        fontSize: '14px !important',
        fontFamily: 'SansMedium',
        textTransform: 'none',
    },
};
export default PreviewStyles;
