import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import style from './style';

const ErrorBanner = ({ show, message, handleCloseCallback }) => {
    const [open, setOpen] = React.useState(true);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        handleCloseCallback();
    };
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={!open ? open : show}
            key="bottomcenter"
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} icon={<WarningRoundedIcon sx={style.warningIcon} />} sx={style.errorAlert}>
                {message}
            </Alert>
        </Snackbar>
    );
};
ErrorBanner.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.any,
    handleCloseCallback: PropTypes.any,
};

ErrorBanner.defaultProps = {
    message: 'Error! Please fill out the missing values marked with the * asterisk and click submit.',
    show: false,
    handleCloseCallback: () => {},
};

export default ErrorBanner;
