import { endPoints } from 'api/endpoints';
import axios from 'api/request';

export const getCartDetails = async ({ catalogCode }) => {
    const returnVal = [];
    const cartData = await axios.get(endPoints.cart.getCart);
    if (cartData?.data) {
        const catalogFilterVal = cartData?.data?.cartSummary?.[0]?.items?.filter((item) => item?.catalogServiceCode === catalogCode);
        /* eslint-disable no-restricted-syntax */
        for (const item of catalogFilterVal) {
            /* eslint-disable no-await-in-loop */
            const result = await axios.get(`${endPoints.cart.getCartItem}/${item?.itemId}`);
            /* eslint-enable no-await-in-loop */
            returnVal.push(result?.data?.itemDetail?.catalogService);
        }
        /* eslint-enable no-restricted-syntax */
    }
    return returnVal || [];
};

export const getCartItemIdCartDetails = async ({ catalogCode, cartItemId }) => {
    let returnVal = {};
    const cartData = await axios.get(endPoints.cart.getCart);
    if (cartData?.data) {
        const catalogFilterVal = cartData?.data?.cartSummary?.[0]?.items?.filter((item) => item?.catalogServiceCode === catalogCode);
        /* eslint-disable no-restricted-syntax */
        for (const item of catalogFilterVal) {
            /* eslint-disable no-await-in-loop */
            const result = await axios.get(`${endPoints.cart.getCartItem}/${item?.itemId}`);
            /* eslint-enable no-await-in-loop */
            if (result?.data?.itemDetail?.cartItemId === cartItemId) returnVal = result?.data?.itemDetail?.catalogService?.catalogConfig;
        }
        /* eslint-enable no-restricted-syntax */
    }
    return returnVal;
};
