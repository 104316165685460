import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilValue } from 'recoil';
import { orderPayload, isClusterLoading } from 'pages/provisioning/virtual-service/store';

export const Department = () => {
    const order = useRecoilValue(orderPayload);
    const clusterLoading = useRecoilValue(isClusterLoading);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                    '& label.MuiInputLabel-shrink': {
                        color: '#0047BA !important',
                    },
                }}
                size="small"
                fullWidth
                autoComplete="off"
                label="Cluster Department *"
                value={order?.department || ''}
                disabled
                InputProps={{
                    endAdornment: <>{clusterLoading && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
        </Box>
    );
};

export default React.memo(Department);
