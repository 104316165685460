import React from 'react';
import Lz from 'pages/app-profiles/landing-zones/components/Form';
import { Grid, Stack } from '@mui/material';

const KafkaFields = () => (
    <>
        <Grid item xs={6}>
            <Stack direction="column" spacing={2}>
                <Lz.Department />
                <Lz.Region />
                <Lz.Cluster />
                <Lz.ProjectName />
                <Lz.LandingZoneLabel />
            </Stack>
        </Grid>
        <Grid item xs={6}>
            <Stack direction="column" spacing={2}>
                <Lz.Environments />
                <Lz.BusinessUnit />
                <Lz.Ldap />
                <Lz.IsaDocument />
                <Lz.LandingZoneDesc />
            </Stack>
        </Grid>
    </>
);

export default KafkaFields;
