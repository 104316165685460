/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddNsg, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const NetSecGroup = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDNsg, setLzDDNsg] = useRecoilState(ddNsg);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const nwSecGrpApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.NetSecGroup}/location/${payload?.region}/subscription/${payload?.subscriptionId}`)
    );
    const setNwSecGrp = (nwSecGrp = null, nwSecGrpDetails = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                nwSecGrp,
                nwSecGrpDetails,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setNwSecGrp();
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setNwSecGrp(selected?.label, selected);
        }
    };
    React.useEffect(() => {
        if (nwSecGrpApi?.isSuccess) {
            setOptions(nwSecGrpApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [nwSecGrpApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const nsg = options.find((value) => lzModal?.lzDetails?.config?.networkSecurityGroup === value?.label);
            if (nsg) setLzDDNsg({ ...lzDDNsg, isLoaded: true, data: nsg });
        }
    }, [options]);

    React.useEffect(() => {
        if (landZoneForm.region && landZoneForm?.subscriptionDetails && !lzModal?.lzDetails) {
            resetOptions();
            nwSecGrpApi.mutate({ region: landZoneForm?.regionDetails?.value, subscriptionId: landZoneForm?.subscriptionDetails?.id });
        }
    }, [landZoneForm.region, landZoneForm?.subscriptionDetails]);
    React.useEffect(() => {
        if (lzModal?.lzDetails?.region?.code && lzModal?.lzDetails?.config?.subscription?.name && lzModal?.lzDetails) {
            nwSecGrpApi.mutate({ region: lzModal?.lzDetails?.region?.code, subscriptionId: lzModal?.lzDetails?.config?.subscription?.id });
        }
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="networkSecurityGroup"
                value={landZoneForm?.nwSecGrp}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={nwSecGrpApi.isLoading}
                isLoading={nwSecGrpApi.isLoading}
            />
        </Box>
    );
};

export default React.memo(NetSecGroup);
