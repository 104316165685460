import { selector } from 'recoil';
import { isDataDiskConfigFullfilled, sspSubmitOrder } from './index';

export const addDataDisk = selector({
    key: 'day2-addDataDisk',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }, count) => {
        const currentDataDisks = get(sspSubmitOrder).dataDisks;
        let dataDisks = [];
        dataDisks = [
            ...currentDataDisks,
            ...Array(count.count).fill({
                lun: null,
                diskName: null,
                storageType: null,
                diskSizeInGib: null,
                maxIops: null,
                maxThroughput: null,
                encryption: null,
                hostCaching: null,
            }),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            dataDisks,
            dataDisksCount: dataDisks.length,
        });
        set(isDataDiskConfigFullfilled, false);
    },
});
export const updateDataDisk = selector({
    key: 'day2-updateDataDisk',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }, dataDiskToUpdate) => {
        const currentDataDisks = get(sspSubmitOrder).dataDisks;
        const dataDisksToUpdateIndex = currentDataDisks.findIndex((val) => val === dataDiskToUpdate.index);
        const updatedDisk = { ...dataDiskToUpdate.index, [dataDiskToUpdate.key]: dataDiskToUpdate.selected };
        const dataDisks = [...currentDataDisks.slice(0, dataDisksToUpdateIndex), updatedDisk, ...currentDataDisks.slice(dataDisksToUpdateIndex + 1)];
        set(sspSubmitOrder, { ...get(sspSubmitOrder), dataDisks });
        set(isDataDiskConfigFullfilled, false);
    },
});
export const updateDataDiskByIndex = selector({
    key: 'day2-updateDataDiskByIndex',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }, params) => {
        const currentDataDisks = get(sspSubmitOrder).dataDisks;
        const updatedDisk = { ...currentDataDisks[params?.index], [params?.key]: params.selected };
        const dataDisks = [...currentDataDisks.slice(0, params?.index), updatedDisk, ...currentDataDisks.slice(params?.index + 1)];
        set(sspSubmitOrder, { ...get(sspSubmitOrder), dataDisks });
        set(isDataDiskConfigFullfilled, false);
    },
});
export const removeDataDisk = selector({
    key: 'day2-removeDataDisk',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }, dataDiskToRemove) => {
        const currentDataDisks = get(sspSubmitOrder).dataDisks;
        const dataDisksToRemoveIndex = currentDataDisks.findIndex((val) => val === dataDiskToRemove);
        const dataDisks = [...currentDataDisks.slice(0, dataDisksToRemoveIndex), ...currentDataDisks.slice(dataDisksToRemoveIndex + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            dataDisks,
            dataDisksCount: dataDisks.length,
        });
        set(isDataDiskConfigFullfilled, false);
    },
});

export const removeDataDiskIndex = selector({
    key: 'day2-removeDataDiskIndex',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }, dataDisksToRemoveIndex) => {
        const currentDataDisks = get(sspSubmitOrder).dataDisks;
        const dataDisks = [...currentDataDisks.slice(0, dataDisksToRemoveIndex), ...currentDataDisks.slice(dataDisksToRemoveIndex + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            dataDisks,
            dataDisksCount: dataDisks.length,
        });
        set(isDataDiskConfigFullfilled, false);
    },
});
export const validateDisk = selector({
    key: 'day2-validateDisk',
    get: ({ get }) => get(sspSubmitOrder).dataDisks,
    set: ({ set, get }) => {
        const currentDataDisks = get(sspSubmitOrder)?.dataDisks;
        let errorCount = 0;
        if (currentDataDisks?.length) {
            let isDataDiskValid = false;
            currentDataDisks.forEach((disk) => {
                if (
                    disk?.lun &&
                    disk?.diskName &&
                    disk?.storageType &&
                    disk?.diskSizeInGib &&
                    disk?.maxIops &&
                    disk?.maxThroughput &&
                    disk?.encryption &&
                    disk?.hostCaching
                ) {
                    isDataDiskValid = true;
                } else {
                    isDataDiskValid = false;
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
            isDataDiskValid = errorCount === 0;
            // console.log(errorCount);
            set(isDataDiskConfigFullfilled, isDataDiskValid);
        } else {
            set(isDataDiskConfigFullfilled, true);
        }
    },
});
