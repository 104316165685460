const chatBotStyle = {
    chatBotButton: {
        my: 1,
        mx: 2,
        height: 47,
        width: 47,
        minWidth: 50,
        backgroundColor: '#0047BA',
        borderRadius: '50%',
        '&.MuiButtonBase-root:hover': {
            backgroundColor: '#0076ba',
        },
    },
    chatIcon: {
        color: '#fff',
        fontSize: '25px',
    },
    chatBotWindow: { position: 'fixed', bottom: 42, right: 68, transition: 'visibility 0.2s, opacity 0.2s linear' },
    botHeaderWrapper: {
        fontWeight: 800,
        backgroundColor: '#104E8B',
        color: '#fff',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    botHeader: { display: 'inline-flex', alignItems: 'center', fontWeight: '600' },
    closeIcon: { float: 'right', margin: '16px 10px', cursor: 'pointer' },
    roleList: { listStyleType: 'none', marginRight: '10px', display: 'inline-block' },
    topicButton: { margin: '5px', marginLeft: '3.5rem', backgroundColor: '#1976d2', padding: '6px 8px', fontWeight: 'normal' },
    listButton: {
        borderRadius: '12px',
        padding: '1px 8px',
        textAlign: 'justify',
        marginBottom: '5px',
        fontSize: '12.5px !important',
    },
    feedbackContainer: {
        width: 'auto',
        display: 'flex',
        border: '1.5px solid grey',
        borderRadius: '5px',
        margin: '10px 10px 20px 35px',
        padding: '8px 0px',
        alignItems: 'center',
    },
};

export default chatBotStyle;
