import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import PreviewStyles from 'pages/reference-implementation/components/preview-details/style';
import LabelValue from 'pages/reference-implementation/components/label-value';
import Chip from 'components/Chip';
import PropTypes from 'prop-types';
import { getValueFromKey } from 'utils';
import mappingLabel from 'utils/mappingLabel';

const ResourceWrapper = (props) => {
    const { resources } = props;
    const getKey = (resourceName) => {
        return resourceName !== 'Key Vault' ? 'Role Assignments' : 'Access Policy';
    };
    return (
        <Box>
            {resources?.map((resource, resIdx) => (
                <Card sx={PreviewStyles?.reourceCard} key={`card-${resIdx}`}>
                    <Typography component="span" sx={PreviewStyles?.resourceType}>
                        {resource['Resource Type']}
                    </Typography>
                    {Object.keys(resource['Resource Properties'])?.map((resourceProperty, resourcePropertyIndex) => (
                        <Box className="ripreview-details" sx={PreviewStyles?.resourceProperty} key={`resProp-${resourcePropertyIndex}`}>
                            <LabelValue
                                istextTransform={false}
                                width={220}
                                label={getValueFromKey(resourceProperty, mappingLabel)}
                                value={
                                    Array.isArray(resource['Resource Properties'][resourceProperty])
                                        ? resource['Resource Properties'][resourceProperty]?.map((storagename, storageIdx) => (
                                              <Chip status="storage" key={`storageName-${storageIdx}`}>
                                                  <Box sx={{ textTransform: 'capitalize' }}>{storagename}</Box>
                                              </Chip>
                                          ))
                                        : resource['Resource Properties'][resourceProperty]
                                }
                            />
                        </Box>
                    ))}
                    {resource[getKey(resource['Resource Type'])] !== null && (
                        <Box sx={PreviewStyles?.roleAssgnWrapper} gap={1}>
                            <Box flex={1} sx={PreviewStyles?.roleAssignMent}>
                                {getKey(resource['Resource Type'])}:
                            </Box>
                            <Box flex={3}>
                                <Box sx={PreviewStyles?.roleAssignMentVal}>
                                    {Object?.keys(resource[getKey(resource['Resource Type'])])?.map((role, roleIndex) => (
                                        <Box flex={1.5} key={`role-${roleIndex}`} sx={PreviewStyles?.roleAssignMentWrapper}>
                                            <Typography component="span" sx={PreviewStyles?.role}>
                                                {role}&#40;s&#41;
                                            </Typography>
                                            {resource[getKey(resource['Resource Type'])][role]?.map((item, index) => (
                                                <Box key={`assign-${index}`} sx={PreviewStyles?.roleVal}>
                                                    {item}
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Card>
            ))}
        </Box>
    );
};

ResourceWrapper.propTypes = {
    resources: PropTypes.any,
};

ResourceWrapper.defaultProps = {
    resources: null,
};

export default ResourceWrapper;
