/* eslint-disable import/no-named-default */

import { default as AddMemberSearch } from './AddMemberSearch';
import { default as ADGroup } from './ADGroup';
import { default as BusinessApp } from './BusinessApp';
import { default as Sector } from './Sector';
import { default as Environments } from './Environments';
import { default as GroupOwner } from './GroupOwner';
import { default as IsADGroupCreate } from './IsADGroupCreate';
import { default as AppShortName } from './AppShortName';
import { default as ADGroupType } from './ADGroupType';
import { default as ApplicationName } from './ApplicationName';
import { default as ADGroupMembers } from './ADGroupMembers';
import { default as ApplicationService } from './ApplicationService';
import { default as SelectMemberBtn } from './SelectMemberBtn';

const Forms = {
    ADGroup,
    AddMemberSearch,
    BusinessApp,
    Sector,
    Environments,
    GroupOwner,
    IsADGroupCreate,
    AppShortName,
    ADGroupType,
    ApplicationName,
    ADGroupMembers,
    ApplicationService,
    SelectMemberBtn,
};

export default Forms;
