/* eslint-disable import/no-named-default */

import { default as VmFields } from './VmFields';
import { default as IaaSSQLFields } from './IaaSSQLFields';
import { default as OracleFields } from './OracleFields';
import { default as PaaSSQLFields } from './PaasSQLFields';
import { default as StorageAccountFields } from './StorageAccountFields';
import { default as AKSNamespaceFields } from './AKSNamespaceFields';
import { default as CouchbaseFields } from './CouchbaseFields';
import { default as AKSKeyVaultFields } from './AKSKeyVaultFields';
import { default as ALBFields } from './ALBFields';
import { default as EventHubFields } from './EventHubFields';
import { default as KafkaFields } from './KafkaFields';
import { default as EIAPStackFields } from './EIAPStackFields';
import { default as ADFFields } from './ADFFields';

const Components = {
    VmFields,
    IaaSSQLFields,
    OracleFields,
    PaaSSQLFields,
    StorageAccountFields,
    AKSNamespaceFields,
    CouchbaseFields,
    AKSKeyVaultFields,
    ALBFields,
    EventHubFields,
    KafkaFields,
    EIAPStackFields,
    ADFFields,
};

export default Components;
