import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'i18n';

import AppRouter from 'router';
import { Theme } from 'assets/style';
import { Toaster } from 'react-hot-toast';
import TagManager from 'react-gtm-module';

import 'assets/style/app.css';
import { getEnv, getLoggedUserClaims } from './utils';

const tagManagerArgs = {
    gtmId: `GTM-${getEnv('REACT_APP_GTM_ID')}`,
    auth: getEnv('REACT_APP_AUTH_ID'),
    preview: getEnv('REACT_APP_PREVIEW_ID'),
    dataLayer: {},
};

if (getLoggedUserClaims()) {
    tagManagerArgs.dataLayer = {
        userId: getLoggedUserClaims().accessToken?.claims?.gpid,
    };
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={Theme}>
            <CssBaseline />
            <AppRouter />
        </ThemeProvider>
        <Toaster
            position="top-center"
            toastOptions={{
                style: {
                    maxWidth: '80%',
                },
                success: {
                    duration: 3000,
                },
                error: {
                    duration: 10000,
                },
                custom: {
                    duration: 3000,
                },
            }}
        />
    </React.StrictMode>,
    document.getElementById('root')
);
