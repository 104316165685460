import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import SearchIcon from '@mui/icons-material/Search';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { updateCatalog, allCatalogsList } from 'pages/provisioning/store/catalog';
import Empty from 'components/empty';
import { Box, Stack, IconButton, Typography } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import ServiceFilter from './filter';
import CatalogsList from './catalogs-list';
import styles, { CssTextField } from './styles';
import { findNested } from './utils';

function Catalogs() {
    const axios = useAxios();
    const [allCatalogsData, setAllCatalogsData] = useRecoilState(allCatalogsList);
    const [catalogsDataList, setCatalogsDataList] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');
    const updateCatalogsData = useRecoilValue(updateCatalog);

    const servicesApi = useMutation(() => axios.get(endPoints.catalog.allList, { params: { includeInActiveCatalogs: true } }));

    React.useEffect(() => {
        servicesApi.mutate();
    }, []);

    React.useEffect(() => {
        if (updateCatalogsData) {
            servicesApi.mutate();
        }
    }, [updateCatalogsData]);

    React.useEffect(() => {
        if (servicesApi?.isSuccess) {
            const catalogs = servicesApi?.data?.data?.catalogs || [];
            setAllCatalogsData(catalogs);
            setCatalogsDataList(catalogs);
        }
    }, [servicesApi?.isSuccess]);

    const findSearched = (val) => {
        if (searchText === '') {
            setCatalogsDataList(allCatalogsData);
        } else {
            const s = findNested(allCatalogsData, val);
            setCatalogsDataList([...new Set(s)]);
        }
    };

    React.useEffect(() => {
        if (searchText === '') {
            setCatalogsDataList(allCatalogsData);
        } else {
            findSearched(searchText);
        }
    }, [searchText]);

    const filterData = (catalog) => {
        const result = allCatalogsData.filter((item) => {
            if ((catalog.status === true || catalog?.status === false) && catalog?.catalogServiceCode && catalog?.tag) {
                return (
                    item?.isActive === catalog?.status && item?.catalogDefinitionId === catalog?.catalogServiceCode && item?.tags?.Catalog.includes(catalog.tag)
                );
            }
            if ((catalog.status === true || catalog?.status === false) && catalog?.catalogServiceCode) {
                return item?.isActive === catalog?.status && item?.catalogDefinitionId === catalog?.catalogServiceCode;
            }
            if ((catalog.status === true || catalog?.status === false) && catalog?.tag) {
                return item?.isActive === catalog?.status && item?.tags?.Catalog.includes(catalog.tag);
            }
            if (catalog?.catalogServiceCode && catalog?.tag) {
                return item?.catalogDefinitionId === catalog?.catalogServiceCode && item?.tags?.Catalog.includes(catalog.tag);
            }
            if (catalog?.catalogServiceCode) {
                return item?.catalogDefinitionId === catalog?.catalogServiceCode;
            }
            if (catalog?.tag) {
                return item?.tags?.Catalog.includes(catalog.tag);
            }
            if (catalog?.status === true || catalog?.status === false) {
                return item?.isActive === catalog?.status;
            }
            if (!catalog?.status && !catalog?.catalogServiceCode && !catalog?.tag) {
                return allCatalogsData;
            }
            return true;
        });
        setCatalogsDataList(result);
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>Catalogs - CMP</title>
            </Helmet>
            <Stack spacing={2} sx={styles.mainstack}>
                <Box sx={styles.headercontainer} spacing={1}>
                    <Typography variant="h6" sx={styles.headingTypo}>
                        {' '}
                        Catalog Services
                    </Typography>
                    <Box sx={styles.actionContainer}>
                        <Box sx={styles.serachbox}>
                            <CssTextField
                                fullWidth
                                label="Search"
                                size="small"
                                variant="outlined"
                                onChange={(e) => setSearchText(e.target.value)}
                                value={searchText}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {searchText && (
                                                <IconButton
                                                    aria-label="Search by App Name"
                                                    sx={styles.clearicon}
                                                    onClick={() => {
                                                        setSearchText('');
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            )}
                                            <>
                                                <IconButton onClick={() => findSearched(searchText)}>
                                                    <SearchIcon sx={styles.searchicon} />
                                                </IconButton>
                                            </>
                                        </>
                                    ),
                                }}
                            />
                        </Box>
                        <ServiceFilter handleFilter={filterData} />
                    </Box>
                </Box>

                {!servicesApi?.isLoading && !servicesApi.isError && catalogsDataList?.length > 0 && <CatalogsList data={catalogsDataList} />}

                {!servicesApi?.isLoading && !servicesApi.isError && catalogsDataList?.length <= 0 && (
                    <Empty fixed notFound msg="No Catalogs Found" color="#000000" />
                )}
                {!servicesApi?.isLoading && servicesApi.isError && <Empty fixed notFound msg="Something Wrong!" color="#000000" />}
                {servicesApi?.isLoading && <Empty fixed isLoading={servicesApi?.isLoading} msg="Loading..." color="#000000" />}
            </Stack>
        </ErrorBoundary>
    );
}

export default Catalogs;
