import React from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload, isDivisionRequired } from 'pages/provisioning/virtual-service/store';
import { isDisplayField, platformManaged } from 'pages/provisioning/virtual-service/utils/getFunction';

export const Division = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [, setDivisionReq] = useRecoilState(isDivisionRequired);
    const [options, setOptions] = React.useState([]);

    const divisionApi = useMutation(() => axios.get(`${endPoints.virtualService.sector}/${order?.sector}?platformManaged=${platformManaged}`));

    const setDivision = (division) => {
        setOrder({
            ...order,
            division,
            program: null,
            department: null,
            aksCluster: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (division) => options.find((option) => division === option.value);

    const handleOnSelectChange = (selected) => {
        setDivision(selected.value);
    };

    React.useEffect(() => {
        if (divisionApi?.isSuccess) {
            setOptions(divisionApi?.data?.data?.divisions?.map((app) => ({ label: app?.divisionDisplayName, value: app?.divisionDisplayName })));
        }
    }, [divisionApi?.isSuccess]);

    React.useEffect(() => {
        setDivisionReq(isDisplayField(options, 'division'));
        if (options && options?.length === 1 && !order?.division) {
            setOrder({ ...order, division: null });
        }
        if (options && options?.length && order?.division) {
            const division = isExistsInOptions(order?.division);
            setOrder({ ...order, division: division?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.sector) {
            resetOptions();
            divisionApi.mutate();
        }
    }, [order?.sector]);

    React.useEffect(() => {
        if (!order?.division && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.division, order?.isTouched]);

    return (
        <>
            {divisionApi?.isSuccess && isDisplayField(options, 'division') && (
                <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Select
                            key="division"
                            dataTestId="division-autocomplete"
                            label="Division *"
                            value={order?.division || null}
                            options={options}
                            handleOnSelect={(value) => handleOnSelectChange(value)}
                            disabled={divisionApi.isLoading}
                            isLoading={divisionApi.isLoading}
                            isRequired={isTouched}
                        />
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default React.memo(Division);
