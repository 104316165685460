import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI001/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const BillingInfo = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const setBillingInfo = (billingInfo = null) => {
        setOrder({
            ...order,
            billingInfo,
        });
    };

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Billing Information (TP Code, Others)" />}
                value={order?.billingInfo || ''}
                onChange={(e) => setBillingInfo(e.target.value)}
            />
        </Box>
    );
};

export default BillingInfo;
