import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState } from 'recoil';
import { openChatbot } from '../store';
import chatBotStyle from '../style';

const Header = () => {
    const [, setIsOpenChat] = useRecoilState(openChatbot);

    const handleClose = () => {
        setIsOpenChat(false);
    };

    return (
        <div style={chatBotStyle?.botHeaderWrapper}>
            <span style={chatBotStyle?.botHeader}>
                <img src="https://pepsicosb.service-now.com//d0dcf80c87561d508c74315d0ebb35cc.iix" alt="bot" height="35px" style={{ margin: '10px' }} />
                ASK CHESTER
            </span>
            <span style={chatBotStyle?.closeIcon}>
                <CloseIcon onClick={handleClose} />
            </span>
        </div>
    );
};

export default Header;
