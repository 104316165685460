/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const PremiumDiskSupport = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setPremiumDiskSupport = (premiumDiskSupport, premiumDiskSupportDetail) => {
        setOrder({ ...order, premiumDiskSupport, premiumDiskSupportDetail, cost: '' });
    };
    const seldValueExistsInOptions = (premiumDiskSupportValue) => options.find((option) => premiumDiskSupportValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setPremiumDiskSupport(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        setOptions([
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
        ]);
    }, []);
    React.useEffect(() => {
        if (!order?.premiumDiskSupport && order?.isTouched) setIsTouched(true);
    }, [order?.premiumDiskSupport, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="premiumDiskSupport"
                label="Premium Disk Support *"
                value={order?.premiumDiskSupport || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(PremiumDiskSupport);
