import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Typography,
    CircularProgress,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import axios from 'axios';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { Item, StyledTableHeader, StyledTableCell, style } from './style';
import { ProgressIcon } from '../style';
import CopyUrl from '../../../assets/images/dlopsImages/copy-solid (2).svg';
import ErrorIcon from '../../../assets/images/dlopsImages/Drift-errorIcon.svg';
import AccessToken from '../components/accessToken/accessToken';

const ModelDriftImages = () => {
    const { getAccessToken, handleTokenError } = AccessToken();
    const [driftDataSet, setDriftDataSet] = useState([]);
    const [batchSize] = useState(51);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(batchSize - 1);
    const [inputError, setInputError] = useState('');
    const history = useHistory();
    const [hasMore, setHasMore] = useState(true);
    const [inputBlobPath, updateInputBlobPath] = useState('');
    const [arizeModelUrl, setArizeModelUrl] = useState('');
    const [arizeModelName, setArizeModelName] = useState('');
    const [saveAttemptCount, setSaveAttemptCount] = useState(0);
    const [displayPopup, setDisplayPopup] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    let receivedData;

    const showPredictionValues = () => {
        const row = localStorage.getItem('viewDriftDataSetVersion');
        const driftDataSetStorage = JSON.parse(row);
        return driftDataSetStorage?.tags.some((tag) => tag.key === 'model_type' && tag.value === 'classification');
    };

    const columns = [
        {
            name: 'Image',
        },
        { name: 'Image URL' },
        {
            name: 'Action',
        },
    ];

    if (showPredictionValues()) {
        columns.splice(1, 0, { name: 'Predicted Label' });
        columns.splice(2, 0, { name: 'Predicted Score' });
    }

    const fetchData = async (_startIndex, _endIndex) => {
        const driftDataSetString = localStorage.getItem('viewDriftDataSetVersion');
        const driftDataSetStorage = JSON.parse(driftDataSetString);
        const payload = {
            model_id: driftDataSetStorage?.run_id || driftDataSetStorage.model_id,
        };
        try {
            const accessToken = await getAccessToken();
            const response = await axios.post(endPoints.driftImages(_startIndex, _endIndex), payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            receivedData = response?.data?.data?.drifted_images;
            if (receivedData && receivedData.length > 0) {
                setArizeModelUrl(response?.data?.data?.arize_model_url);
                setArizeModelName(response?.data?.data?.arize_model_name);
                setDriftDataSet((prevDriftDataSet) => [...prevDriftDataSet, ...receivedData]);
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            handleTokenError(error, endPoints.driftImages(_startIndex, _endIndex), 'post', payload);
        } finally {
            setInitialLoading(false);
            setScrollLoading(false);
            setInitialLoadComplete(true);
        }
    };

    const fetchMoreData = () => {
        const newStartIndex = driftDataSet.length;
        const newEndIndex = newStartIndex + batchSize - 1;
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
        setScrollLoading(true);
        fetchData(newStartIndex, newEndIndex);
    };

    const handleInputChange = (event) => {
        const blobContainerPath = event.target.value;
        updateInputBlobPath(blobContainerPath);
        if (!blobContainerPath.trim()) {
            setInputError('Please enter a valid blob container path');
        } else {
            setInputError('');
        }
    };

    useEffect(() => {
        fetchData(startIndex, endIndex);
    }, []);

    const handleCopyClick = (imageURL) => {
        const tempInput = document.createElement('input');
        tempInput.value = imageURL;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999);

        document.execCommand('copy');

        document.body.removeChild(tempInput);

        toast('Image URL copied to clipboard', {
            position: 'top-right',
            autoClose: 3000,
        });
    };

    const handleRemove = (row) => {
        setDriftDataSet((prevData) => prevData.filter((item) => item.pointId !== row.pointId));
    };

    const handleNavigation = () => {
        history.push('/dlops-model-versions');
    };

    const saveImagesToBlob = async () => {
        try {
            if (!inputBlobPath.trim()) {
                setInputError('Please enter a blob container path');
                return;
            }
            const imagesUrl = driftDataSet.map((item) => item.linkToData);
            const payload = {
                destination_blob_path: `${inputBlobPath}`,
                images_url: imagesUrl,
            };
            const accessToken = await getAccessToken();

            await axios.post(endPoints.saveToBlobStorage, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            toast.success('Saved to Blob Storage Successfully', {
                position: 'top-right',
                autoClose: 3000,
            });
            setSaveAttemptCount(saveAttemptCount + 1);
            if (saveAttemptCount >= 1) {
                setDisplayPopup(true);
            }
            setDisplayPopup(false);
        } catch (error) {
            setDisplayPopup(false);
            const errorMessage = error.response?.data?.message || 'Error saving images to blob';
            setInputError(errorMessage);
        }
    };

    const handleSaveClick = async () => {
        if (saveAttemptCount === 0) {
            await saveImagesToBlob();
        } else {
            setDisplayPopup(true);
        }
        setSaveAttemptCount(saveAttemptCount + 1);
    };

    const closePopup = () => {
        setDisplayPopup(false);
    };

    const showPredictionLabelScoreValues = (row) => {
        const localDriftStorage = localStorage.getItem('viewDriftDataSetVersion');
        const driftDataSetStorage = JSON.parse(localDriftStorage);
        return driftDataSetStorage?.tags.some((tag) => tag.key === 'model_type' && tag.value === 'classification') ? (
            <>
                <StyledTableCell sx={{ color: '#000000' }}>{row?.predictionLabel}</StyledTableCell>
                <StyledTableCell sx={{ color: '#000000' }}>{row?.predictionScore}</StyledTableCell>
            </>
        ) : null;
    };

    const createSupportLink = (message) => {
        const supportText = 'support';
        const supportEmail = 'dl-pepeedlops@pepsico.com';

        if (message.includes(supportText)) {
            const parts = message.split(supportText);
            return (
                <>
                    {parts[0]}
                    <a href={`mailto:${supportEmail}`} style={{ color: '#D40020' }}>
                        {supportText}
                    </a>
                    {parts[1]}
                </>
            );
        }
        return message;
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>DLOPS Dashboard</title>
            </Helmet>
            <Box style={{ marginBottom: '20px' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div
                                    style={{
                                        marginLeft: '28px',
                                        marginTop: '8px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <span>DLOPS</span>
                                    <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                        /Model versions
                                    </span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Drift images</span>
                                </div>
                            </Item>
                        </Typography>
                    </Grid>
                </Grid>
                <div style={style.dataTableDriftFlexColumn}>
                    <div style={style.dataTableFlex}>
                        <div style={style.subText1B}>
                            Enter the blob container path for re-training model:{' '}
                            <a target="_blank" href={arizeModelUrl} rel="noreferrer" style={{ color: '#1378D4' }}>
                                {arizeModelName}
                            </a>
                        </div>
                    </div>
                    <div style={style.inputdriftRow}>
                        <span style={{ color: '#D40020', verticalAlign: 'middle', marginRight: '5px', fontSize: '16px' }}>*</span>
                        <input
                            placeholder="Enter the blob container path"
                            style={{ ...style.inputSize, border: inputError ? '1px solid #D40020' : '1px solid #d9d9d9' }}
                            onChange={(e) => handleInputChange(e)}
                            onBlur={(e) => handleInputChange(e)}
                        />
                        <button type="button" style={style.buttonBg} onClick={handleSaveClick}>
                            Save to blob
                        </button>
                    </div>
                    {inputError && (
                        <Box style={{ color: '#D40020', marginTop: '-10px', marginLeft: '30px', font: 'normal normal medium 14px/18px PP Object Sans' }}>
                            <img src={ErrorIcon} alt="errorIcon" />
                            &nbsp;&nbsp;
                            {createSupportLink(inputError)}.
                        </Box>
                    )}
                </div>

                <Box
                    id="scrollableDiv"
                    style={{
                        height: '800px',
                        overflow: 'auto',
                        width: '98%',
                        fontSize: 13.5,
                        borderRadius: '0px 0px 12px 12px',
                        margin: '0px 10px 10px 10px',
                    }}
                >
                    {initialLoading && (
                        <Box sx={ProgressIcon.provisioning_wrapper}>
                            <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                            <Typography variant="body2" sx={ProgressIcon.circularProgressColor}>
                                Loading
                            </Typography>
                        </Box>
                    )}
                    <InfiniteScroll
                        dataLength={driftDataSet.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv"
                        loader={
                            scrollLoading && (
                                <Box sx={style.loading_progress}>
                                    <CircularProgress color="inherit" size={16} sx={style.loading_progress} />
                                    <Typography variant="body2" sx={style.circularProgressColor}>
                                        Loading More Data
                                    </Typography>
                                </Box>
                            )
                        }
                        endMessage={
                            initialLoadComplete && !hasMore && driftDataSet.length > 0 ? (
                                <p style={{ textAlign: 'center' }}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            ) : null
                        }
                    >
                        {driftDataSet && driftDataSet.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                    {column.name}
                                                </StyledTableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {driftDataSet.map((row, indexs) => (
                                            <TableRow key={indexs}>
                                                <StyledTableCell sx={{ color: '#000000', fontWeight: 'normal' }}>
                                                    <img src={`${row.linkToData}`} alt="" style={style.imgSize} />
                                                </StyledTableCell>

                                                {showPredictionLabelScoreValues(row)}
                                                <StyledTableCell sx={{ color: '#000000' }}>
                                                    {row?.linkToData}{' '}
                                                    <Typography
                                                        style={{
                                                            background: '#1378D4 0% 0% no-repeat padding-box',
                                                            borderRadius: '4px',
                                                            width: '100px',
                                                            height: '32px',
                                                            display: 'flex',
                                                            paddingLeft: '15px',
                                                            paddingBottom: '10px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleCopyClick(row.linkToData)}
                                                    >
                                                        <Typography
                                                            style={{
                                                                background: 'transparent 0% 0% no-repeat padding-box',
                                                                width: '100px',
                                                                height: '30px',
                                                                opacity: 1,
                                                                padding: '0px',
                                                                color: '#FFFFFF',
                                                                textAlign: 'left',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            Copy URL
                                                            <img src={CopyUrl} alt="copyUrl" style={{ marginTop: '5px', marginLeft: '5px' }} />
                                                        </Typography>
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <DeleteIcon onClick={() => handleRemove(row)} />
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div
                                variant="body1"
                                style={{
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    fontSize: '16px',
                                    marginTop: '300px',
                                }}
                            >
                                No drift images to display
                            </div>
                        )}
                    </InfiniteScroll>
                    {displayPopup && (
                        <Dialog open={displayPopup} onClose={closePopup}>
                            <DialogTitle>Are you sure you want to save again?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{/* Your content here */}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closePopup} className="btnCancelColor" style={{ lineHeight: '43px', color: '#1378D4' }}>
                                    Cancel
                                </Button>
                                <Button onClick={saveImagesToBlob} className="btnThemecolor">
                                    Yes, Save again!
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Box>
            </Box>
        </ErrorBoundary>
    );
};

export default ModelDriftImages;
