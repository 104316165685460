import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Paper, Divider, Box } from '@mui/material';
// import style from '../dashboard.module.css';
import dashboardStyle from '../style';

function Chart(props) {
    const { title, children, w, showFilter, FilterComponent, showDivider, subTitle } = props;
    return (
        <Grid item xs={w}>
            <Paper sx={dashboardStyle.chart.chartPaper} elevation={subTitle ? 0 : 1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography sx={subTitle ? dashboardStyle.chart.subChartTitle : dashboardStyle.chart.chartTitle}>{title}</Typography>
                    {showFilter && <FilterComponent />}
                </Box>
                {showDivider && <Divider sx={{ mb: 1 }} />}
                {children}
            </Paper>
        </Grid>
    );
}

Chart.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    w: PropTypes.number,
    showFilter: PropTypes.bool,
    FilterComponent: PropTypes.any,
    showDivider: PropTypes.bool,
    subTitle: PropTypes.bool,
};

Chart.defaultProps = {
    title: '',
    w: 12,
    children: 'text',
    showFilter: false,
    FilterComponent: <></>,
    showDivider: true,
    subTitle: false,
};

export default React.memo(Chart);
