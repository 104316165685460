import React from 'react';
import { Box, Stack, Table, TableBody, TableContainer, TableHead, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import tableSortLabelClasses from '@mui/material/TableSortLabel/tableSortLabelClasses';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { camelToTitle } from 'utils';
import TablePaginationActions from 'components/tablePaginationActions';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { appDefaultForm, toggleConfirmModel, toggleCreateAppDefault } from './store';

// const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
//     [`&.${tableSortLabelClasses.active}`]: {
//         color: theme.palette.white,
//     },
//     '&:hover': {
//         color: theme.palette.white,
//     },
// }));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#2b3a57',
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: '0px',
        color: '#003182',
        '&:first-child': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#ffffff',
    '&:hover': {
        backgroundColor: theme.palette.cyan['90'],
    },
    // hide last border
    '&:last-child td, &:last-child th': {},
}));

const AppDefaultList = () => {
    const { profileType } = useParams();
    const [form, setForm] = useRecoilState(appDefaultForm);
    const [adModal, setADModal] = useRecoilState(toggleCreateAppDefault);
    const [modal, setModal] = useRecoilState(toggleConfirmModel);
    const displayColumns = ['cloudProvider', 'cloudService', 'catalogService', 'environment', 'appTier'];
    const defaultAppLists = [
        {
            cloudProvider: 'Azure',
            cloudService: 'PaaS/SQL',
            catalogService: 'AZPAASSQL',
            environment: 'Production',
            appTier: 'Database server - SQL',
        },
        {
            cloudProvider: 'Azure',
            cloudService: 'PaaS/SQL',
            catalogService: 'AZPAASSQL',
            environment: 'Development',
            appTier: 'Database server - SQL',
        },
        {
            cloudProvider: 'Azure',
            cloudService: 'PaaS/SQL',
            catalogService: 'AZPAASSQL',
            environment: 'Testing',
            appTier: 'Database server - SQL',
        },
        {
            cloudProvider: 'Azure',
            cloudService: 'Azure',
            catalogService: 'Azure',
            environment: 'Quaker',
            appTier: 'Pepsico Cloud Marketplace',
        },
        {
            cloudProvider: 'Azure',
            cloudService: 'Azure',
            catalogService: 'Azure',
            environment: 'Tripocana',
            appTier: 'Pepcom',
        },
        {
            cloudProvider: 'Azure',
            cloudService: 'Azure',
            catalogService: 'Azure',
            environment: 'Fritolay',
            appTier: 'Pepsico Cloud Marketplace',
        },
    ];
    return (
        <>
            <Box flexGrow={1} overflow="auto" display="flex" pt={1.7} pb={1} sx={{ color: '#ffffff' }}>
                <TableContainer sx={{ marginTop: '4px' }}>
                    <Table sx={{ marginTop: '-9px', borderSpacing: '0 4px', minWidth: 500, height: 'auto' }} size="small" stickyHeader>
                        <TableHead>
                            <TableRow sx={{ height: '36px' }}>
                                {displayColumns?.map((head, index) => (
                                    <>
                                        {index === 0 && <StyledTableCell key={`head-dummy-${index.toString()}`}>&nbsp;</StyledTableCell>}
                                        <StyledTableCell key={`head-${index.toString()}`}>{camelToTitle(head)}</StyledTableCell>
                                    </>
                                ))}
                                <StyledTableCell align="center">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {defaultAppLists.slice(0, profileType === undefined ? defaultAppLists?.length : 2)?.map((result, index) => (
                                <StyledTableRow sx={{ height: '36px' }} className="animate-opacity" key={`row-${index.toString()}`}>
                                    {displayColumns?.map((head, dataIndex) => (
                                        <>
                                            {dataIndex === 0 && (
                                                <StyledTableCell key={`data-dummy-${index.toString()}`} width="20px">
                                                    &nbsp;
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell key={`data-${dataIndex.toString()}`}>{result[head]?.toString()}</StyledTableCell>
                                        </>
                                    ))}
                                    <StyledTableCell>
                                        <Box display="flex" justifyContent="center">
                                            <Box
                                                sx={{
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '27px',
                                                    height: '27px',
                                                    background: '#0047BA',
                                                    marginRight: '8px',
                                                }}
                                            >
                                                <EditIcon
                                                    style={{
                                                        width: '14px',
                                                        height: '12px',
                                                        color: '#FFFFFF',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setForm({
                                                            ...form,
                                                            cloudProvider: 'Azure',
                                                            cloudService: 'PaaS/SQL',
                                                            catalogService: 'AZPAASSQL',
                                                            environment: 'Production',
                                                            appTier: 'Database server - SQL',
                                                            adGroups: 'AD Group 2',
                                                            deploymentModalCode: 's2',
                                                            storage: '100 GB',
                                                        });
                                                        setADModal({ ...adModal, isOpen: true, type: 'edit' });
                                                    }}
                                                />
                                            </Box>
                                            {profileType === undefined && (
                                                <Box
                                                    sx={{
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '27px',
                                                        height: '27px',
                                                        background: '#DB1E36',
                                                    }}
                                                    onClick={() =>
                                                        setModal({ ...modal, type: 'delete', isOpen: true, message: 'Do you want to delete App Default?' })
                                                    }
                                                >
                                                    <DeleteIcon
                                                        style={{
                                                            fontSize: '18px',
                                                            color: '#FFFFFF',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Stack direction="column" spacing={1} justifyContent="center">
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 25, 100]}
                        sx={{ width: '100%', color: '#ffffff' }}
                        count={10}
                        rowsPerPage={10}
                        page={1}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                        }}
                        // onPageChange={handleChangePage}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Stack>
            </Stack>
        </>
    );
};

export default AppDefaultList;
