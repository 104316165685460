/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { profileFilter } from 'pages/app-profiles/store';
import AppProfileList from 'pages/app-profiles/profile/AppProfileList';
import CreateAppProfile from 'pages/app-profiles/profile/CreateAppProfile';
import ConfirmDeleteModal from 'pages/app-profiles/profile/ConfirmDeleteModal';
import AppProfileHeader from './AppProfileHeader';
import profileStyle from './style';

const AppProfilePage = () => {
    const { profileType } = useRecoilValue(profileFilter);
    return (
        <>
            <Helmet>
                <title>{profileType?.profileType === 'My AppProfiles' ? 'My' : 'All'} App Profiles - CMP</title>
            </Helmet>
            <CreateAppProfile />
            <ConfirmDeleteModal />
            <Box sx={profileStyle?.mainwraper}>
                <AppProfileHeader />
                <AppProfileList />
            </Box>
        </>
    );
};

export default AppProfilePage;
