import React from 'react';
import { useMutation } from 'react-query';
import { Typography, Divider, CircularProgress, Box, Stack, Paper, Grid } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import dashboardStyle from 'pages/dashboard-enhancement/style';

const ServiceOffering = () => {
    const [data, setData] = React.useState([]);

    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess && ordersTrendQuery?.data?.data) {
            setData(ordersTrendQuery?.data?.data);
        }
    }, [ordersTrendQuery?.isSuccess]);

    React.useEffect(() => {
        ordersTrendQuery?.mutate({ metricType: 'catalogCounts' });
    }, []);

    const DashboardMetricsFilter = () => {
        return (
            <Stack spacing={2} direction="row" sx={dashboardStyle?.serviceOffering?.wrapper}>
                <Typography sx={dashboardStyle?.serviceOffering?.typoText}>
                    Services : <span style={dashboardStyle?.serviceOffering?.typoCount}>{data?.services}</span>
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography sx={dashboardStyle?.serviceOffering?.typoText}>
                    Solutions : <span style={dashboardStyle?.serviceOffering?.typoCount}>{data?.solutions}</span>
                </Typography>
            </Stack>
        );
    };
    return (
        <Grid item xs={12}>
            <Paper sx={dashboardStyle?.serviceOffering?.paperStyle}>
                <Box sx={dashboardStyle?.serviceOffering?.outterBox}>
                    <Typography sx={dashboardStyle.chart.chartTitle}>Offerings</Typography>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        {ordersTrendQuery.isLoading && (
                            <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                                <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                            </Box>
                        )}
                        {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                    </ErrorBoundary>
                    <DashboardMetricsFilter />
                </Box>
            </Paper>
        </Grid>
    );
};

export default ServiceOffering;
