import { atom } from 'recoil';

export const OrderPayload = atom({
    key: 'secTool-OrderPayload',
    default: {
        applicationCI: null,
        applicationCIDetails: null,
        applicationOwner: null,
        applicationOwnerDetails: null,
        applicationName: null,
        applicationUrl: null,
        environment: null,
        sector: null,
        teamName: null,
        codeRepoList: [{ repo: '', isRepoTouched: false }],
        member: null,
        memberDetails: null,
        memberList: [],
        isTouched: false,
    },
});

export const orderId = atom({
    key: 'secTool-orderId',
    default: undefined,
});

export const validProvisionForm = atom({
    key: 'secTool-validProvisionForm',
    default: {
        isAppNameValid: false,
        isAppUrlValid: false,
        isDuplicateMember: false,
        isMaxMemberLimit: false,
    },
});

export const toggleModal = atom({
    key: 'secTool-toggleModal',
    default: {
        isSuccessOpen: false,
        isClearAllConfirmOpen: false,
        isRemoveConfirmOpen: false,
        action: '',
    },
});
