/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import { getNodeCatalogServiceCode } from 'utils/couchbaseNodeCatalogServiceCode';

const OsType = ({ indexVal }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const newOsVersionApi = useMutation(() => axios.get(`${endPoints.ssp.vm.osVersions}/RHEL/${order?.region}`));

    const setOsTypeVersion = (key, selected, index, action) => {
        updateNodesData({ key, selected, index, action });
    };

    const seldValueExistsInOptions = (osTypeValue) => options.find((option) => osTypeValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== order?.nodes?.[indexVal]?.osType && seldValueExistsInOptions(selected?.value)) {
            /* eslint-disable no-param-reassign */
            selected.code = getNodeCatalogServiceCode(selected.value);
            /* eslint-enable no-param-reassign */
            setOsTypeVersion('osTypeVersion', selected, indexVal, 'add');
        }
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const getOSOption = (sku) => {
        let OSOptions = [];
        OSOptions = newOsVersionApi?.data?.data?.marketPlaceImages
            ?.filter((item) => sku.includes(item.sku))
            ?.map((app) => ({
                label: `${app.publisher},${app.sku}`,
                value: `${app.publisher},${app.sku}`,
                data: app,
            }));

        return OSOptions;
    };

    React.useEffect(() => {
        if (newOsVersionApi?.isSuccess && newOsVersionApi?.data?.data) {
            setOptions(getOSOption(order?.sku));
        }
    }, [newOsVersionApi?.isSuccess]);

    React.useEffect(() => {
        resetOptions();
        if (order.region && order?.landingZone) {
            newOsVersionApi.mutate();
        }
        // eslint-disable-next-line
    }, [order?.region, order?.envDetails?.config?.subscription?.id, order?.landingZone]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0) {
            if (order?.nodes?.[indexVal]?.osTypeVersion) {
                const osTypeVersion = seldValueExistsInOptions(order?.nodes?.[indexVal]?.osTypeVersion);
                if (osTypeVersion) {
                    const obj = {
                        label: `${osTypeVersion?.data?.publisher},${osTypeVersion?.data?.sku}`,
                        value: `${osTypeVersion?.data?.publisher},${osTypeVersion?.data?.sku}`,
                        data: osTypeVersion?.data,
                        code: order?.nodes?.[indexVal]?.catalogServiceCode,
                    };
                    setOsTypeVersion('osTypeVersion', obj, indexVal, 'edit');
                }
            } else {
                const osTypeVersion = newOsVersionApi?.data?.data?.marketPlaceImages.filter((app) => order?.sku === app.sku);
                if (osTypeVersion?.[0]?.publisher && osTypeVersion?.[0]?.sku && osTypeVersion?.[0]) {
                    const obj = {
                        label: `${osTypeVersion?.[0]?.data?.publisher},${osTypeVersion?.[0]?.data?.sku}`,
                        value: `${osTypeVersion?.[0]?.data?.publisher},${osTypeVersion?.[0]?.data?.sku}`,
                        data: osTypeVersion?.data,
                        code: order?.nodes?.[0]?.catalogServiceCode,
                    };
                    setOsTypeVersion('osTypeVersion', obj, indexVal, 'edit');
                }
            }
        }
    }, [options]);

    React.useEffect(() => {
        if (!order?.nodes?.[indexVal]?.osTypeVersion && order?.isTouched) setIsTouched(true);
    }, [order?.nodes?.[indexVal]?.osTypeVersion, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="OsType"
                label="OS Type*"
                value={order?.nodes?.[indexVal]?.osTypeVersion || null}
                options={options}
                disabled={[1, 2, 3].includes(indexVal)}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isLoading={newOsVersionApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

OsType.propTypes = {
    indexVal: PropTypes.number,
};

OsType.defaultProps = {
    indexVal: 0,
};

export default React.memo(OsType);
