import React from 'react';
import { Stack } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { profileForm } from 'pages/app-profiles/store';
import profileStyle, { CssTextField } from '../../style';

const filter = createFilterOptions();
const FinancialDetails = () => {
    const { t } = useTranslation();
    const [form, setForm] = useRecoilState(profileForm);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const hCodeGetApi = useMutation(() => axios.get(`${endPoints.ssp.vm.hCode}/${ccInputValue?.toUpperCase()}`));
    const hCodePostApi = useMutation((payload) => axios.post(`${endPoints.ssp.vm.hCode}`, payload));
    const setHCode = (financial, financialDetails) => {
        setForm({
            ...form,
            financial,
            financialDetails,
            projectName: financialDetails?.projectName ? financialDetails?.projectName : '-',
        });
    };
    const resetOptions = () => {
        setOptions(initOptions);
        setHCode(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                hCodeGetApi.mutate();
            }, 1000),
        []
    );
    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);
    React.useEffect(() => {
        if (hCodeGetApi?.isSuccess) {
            if (hCodeGetApi?.data?.data?.projects) {
                const combinedValue = hCodeGetApi?.data?.data?.projects.map((app) => ({
                    label: `${app.id}`,
                    projectName: app?.projectName,
                }));
                setOptions(combinedValue);
            }
        }
    }, [hCodeGetApi?.isSuccess]);
    React.useEffect(() => {
        if (hCodePostApi?.isSuccess) {
            if (hCodePostApi?.data?.data?.projects) {
                const combinedValue = hCodePostApi?.data?.data?.projects.map((app) => ({
                    label: `${app.id}`,
                    projectName: app?.projectName,
                }));
                if (form?.financialDetails) combinedValue.push(form?.financialDetails);
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [hCodePostApi?.isSuccess]);
    React.useEffect(() => {
        hCodePostApi.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);
    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setHCode(value?.label, value);
        }
    }, [value]);
    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Autocomplete
                    key="financialDetails"
                    size="small"
                    loading={hCodeGetApi?.isLoading}
                    value={form?.financialDetails || null}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    sx={profileStyle.appWoner.select}
                    freeSolo
                    renderInput={(params) => (
                        <CssTextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            label="Financial Details"
                            fullWidth
                        />
                    )}
                />
                <InfoImg title={t([`appProfile.infoText.financialDetails`])} />
            </Stack>
        </>
    );
};

export default React.memo(FinancialDetails);
