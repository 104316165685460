import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
import { RIStyles, CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';

const TransactionIdRadioBtn = (props) => {
    const { isTransactionalIdValue, handleOnSelect } = props;

    return (
        <Box>
            <Typography sx={RIStyles?.configuration?.cosmos?.heading}>Transactional ID</Typography>
            <FormControl>
                <CustomRadioGroup
                    aria-label="transactionalId"
                    name="transactionalId"
                    row
                    value={isTransactionalIdValue}
                    onChange={(event, value) => handleOnSelect(value)}
                >
                    <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" data-testid="transAccessYes" />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '60px',
                        }}
                        control={<CustomRadio />}
                        label="No"
                        data-testid="transAccessNo"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Box>
    );
};

TransactionIdRadioBtn.propTypes = {
    isTransactionalIdValue: PropTypes.any,
    handleOnSelect: PropTypes.func,
};

TransactionIdRadioBtn.defaultProps = {
    isTransactionalIdValue: null,
    handleOnSelect: () => {},
};

export default TransactionIdRadioBtn;
