import React from 'react';
import { Grid, Typography, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import Btn from 'components/button';

export const SubModalField = ({ label, handleAction, action, isError }) => {
    return (
        <Grid display="flex" justifyContent="space-between">
            <Typography className="vs-service-list-label">{label}</Typography>
            <Badge className="badge" color="error" variant={isError ? 'dot' : 'standard'}>
                <Btn variant="contained" color="cmpPrimary" onClick={() => handleAction('configure')} disabled={false} data-testid={label}>
                    {action}
                </Btn>
            </Badge>
        </Grid>
    );
};

SubModalField.propTypes = {
    label: PropTypes.string.isRequired,
    handleAction: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    isError: PropTypes.bool,
};

SubModalField.defaultProps = {
    isError: false,
};

export default React.memo(SubModalField);
