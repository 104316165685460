/* eslint-disable react/prop-types   */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress } from '@mui/material';
import { BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, YAxis, CartesianGrid } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import Chart from '../components/Chart';
import { orderCreationTrend, dateRangeValue, dashboardFilter } from '../store';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const ServicesProvisionedByMonth = () => {
    const [data, setData] = useRecoilState(orderCreationTrend);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);
    const showhorizontalcartisiean = true;
    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            providers: dashboardFilterObj?.provider || [],
            sectors: dashboardFilterObj?.sector || [],
            services: dashboardFilterObj?.services || [],
            solutions: dashboardFilterObj?.solutions || [],
            environments: dashboardFilterObj?.environment || [],
            regions: dashboardFilterObj?.regions || [],
            applicationService: dashboardFilterObj?.ApplicationService?.label || '',
            metricType: 'solutionsByMonth',
        };
        setData([]);
        ordersTrendQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess) {
            const results = ordersTrendQuery?.data?.data?.map((item) => ({
                name: DateTime.fromISO(item?.key_as_string, { zone: 'utc' }).toFormat('LLL yy'),
                Solutions: item?.distinct_item_ids?.value,
            }));
            setData(results || []);
        }
    }, [ordersTrendQuery?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 6);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);
    const CustomYAxisTick = ({ x, y, payload }) => {
        // eslint-disable-next-line
        const value = payload.value;
        let yaxis = (
            <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
                0
            </text>
        );
        yaxis = (
            <text x={x} y={y} dy={4} textAnchor="end" fill="#666">
                {Math.floor(value)}
            </text>
        );
        return yaxis;
    };
    return (
        <Chart title="SOLUTIONS PROVISIONED - BY MONTH" subTitle>
            {/* <Typography sx={dashboardStyle.chart.subchartTitle}>SOLUTIONS PROVISIONED - BY MONTH </Typography> */}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {ordersTrendQuery.isLoading && (
                    <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                        <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                    </Box>
                )}
                {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                {data?.length > 0 && (
                    <Box sx={{ ...dashboardStyle.ApplicationOnBoardStyle.chart_wrapper, marginLeft: '-30px' }}>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                width={1000}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 0,
                                    left: 0,
                                    bottom: 15,
                                }}
                            >
                                <defs>
                                    <linearGradient id="gradientsolutions" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#F99C24" stopOpacity={1} />
                                        <stop offset="100%" stopColor="#AF6D19" stopOpacity={1} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" horizontal={showhorizontalcartisiean} vertical={!showhorizontalcartisiean} />
                                <XAxis dataKey="name" interval={0} padding={{ left: 10, right: 10 }} height={60} tick={<CustomizedAxisTick />} />
                                <YAxis domain={[0, (dataMax) => dataMax * 1.5]} tick={<CustomYAxisTick />} />
                                <Tooltip cursor={false} />
                                {/* <Legend /> */}
                                <Bar dataKey="Solutions" fill="url(#gradientsolutions)" radius={[20, 20, 0, 0]} barSize={25} label={{ position: 'top' }} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(ServicesProvisionedByMonth);
