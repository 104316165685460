import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { orderPayload, certCreationRequired, isValidOwners, customDomainFieldsDiasbled } from 'pages/provisioning/virtual-service/store';
import { CssTextField } from 'components/select';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import InfoImg from 'components/info-img';
import { isUniqueOwner } from 'pages/provisioning/virtual-service/utils';

export const ApplicationOwner = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [searched, setSearched] = React.useState(false);
    const [isRequired] = useRecoilState(certCreationRequired);
    const [validOwner, setValidOwner] = useRecoilState(isValidOwners);
    const [isDisabled] = useRecoilState(customDomainFieldsDiasbled);

    const appOwnerApi = useMutation(() => axios.get(`${endPoints.virtualService.applicationOwner}?key=${ccInputValue}`));
    const appOwnerPostApi = useMutation((payload) => axios.get(`${endPoints.virtualService.applicationOwner}?key=${payload}`));

    const setApplicationOwner = (applicationOwner, applicationOwnerDetails) => {
        setOrder({
            ...order,
            applicationOwner,
            applicationOwnerDetails,
        });
    };
    React.useEffect(() => {
        if (!order?.applicationOwner && !order?.applicationOwnerDetails) {
            setOptions([]);
        }
    }, [order?.applicationOwner, order?.applicationOwnerDetails]);

    const resetOptions = () => {
        setOptions([]);
        setApplicationOwner(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        if (ccInputValue === '') {
            resetOptions();
        }

        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };
    const isExistsInOptions = (applicationOwnerValue) => options.find((option) => applicationOwnerValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.applicationOwner && order?.applicationOwnerDetails) {
            setOrder({ ...order, applicationOwner: options[0]?.label, applicationOwnerDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.applicationOwnerDetails && !order?.applicationOwner) {
            const appOwerObj = isExistsInOptions(order?.applicationOwnerDetails?.name);
            setOrder({ ...order, applicationOwner: options[0]?.label, applicationOwnerDetails: appOwerObj });
        }
    }, [options]);
    React.useEffect(() => {
        if (appOwnerApi?.data?.data) {
            const combinedValue = appOwnerApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (appOwnerPostApi?.data?.data) {
            const combinedValue = appOwnerPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerPostApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setApplicationOwner(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.applicationOwner && order?.applicationOwnerDetails) {
            appOwnerPostApi.mutate(order?.applicationOwnerDetails?.name);
        }
    }, [order?.applicationOwner, order?.applicationOwnerDetails]);

    React.useEffect(() => {
        if (!order?.applicationOwner && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationOwner, order?.isTouched]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.applicationOwner) || isTouched) {
            return 'validate';
        }
        return '';
    };

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };

    React.useEffect(() => {
        if (order?.applicationOwner) {
            const checkValidOwner = isUniqueOwner(order?.applicationOwner, [order?.certificateOwnerOne, order?.certificateOwnerTwo]);
            if (!checkValidOwner) {
                setValidOwner({ ...validOwner, isValidApplicationOwner: false });
            } else {
                setValidOwner({ ...validOwner, isValidApplicationOwner: true });
            }
        }
    }, [order?.applicationOwner]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="app-owner-box">
            <Autocomplete
                key="applicationOwnerDetails"
                size="small"
                noOptionsText={getNoOptionText()}
                loading={appOwnerApi?.isLoading}
                value={order?.applicationOwner || null}
                disabled={!isRequired || isDisabled}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                }}
                className={validateInput()}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                filterOptions={(optionValues, params) => {
                    // eslint-disable-next-line no-underscore-dangle
                    const _results = [];
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < optionValues.length; i++) {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const key in optionValues[i]) {
                            if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                    _results.push(optionValues[i]);
                                }
                            }
                        }
                    }
                    return [...new Set(_results)];
                    // return _results
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableClearable
                id="free-solo-with-text-demo"
                options={options}
                isOptionEqualToValue={(option, value1) => option.label === value1}
                sx={{ width: '100%' }}
                onBlur={() => {
                    if (!value || !order?.applicationOwner) {
                        setSearched(false);
                        setOptions([]);
                        setValidOwner({ ...validOwner, isValidApplicationOwner: true });
                    }
                }}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        setSearched(true);
                        handleSearch();
                    }
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        setSearched(false);
                        resetOptions();
                        setValidOwner({ ...validOwner, isValidApplicationOwner: true });
                    }
                }}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Application Owner *"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    <SearchIcon
                                        sx={ProvisioningStyles?.searchIcon}
                                        onClick={() => {
                                            setSearched(true);
                                            handleSearch();
                                        }}
                                        data-testid="search-btn"
                                    />
                                </>
                            ),
                        }}
                        error={validOwner?.isValidApplicationOwner === false}
                        helperText={validOwner?.isValidApplicationOwner === false && <span>Please Choose different member</span>}
                        fullWidth
                    />
                )}
            />
            <InfoImg title="Application owner is required only if there is a need for certificate creation for given custom domain." />
        </Box>
    );
};

export default React.memo(ApplicationOwner);
