/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { adfCatalogServiceCode } from 'utils/catalogServiceCode';
import { CssTextField } from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';
import { landingZoneData } from 'pages/app-profiles/store';

const DataClassification = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const dataClassificationApi = useMutation(() => axios.get(endPoints.landingZones.dataClassification));

    const setDataClassification = (dataClassification, dataClassificationDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            dataClassification,
            dataClassificationDetails,
        });
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setDataClassification(selected?.label, selected?.data);
        } else {
            setDataClassification(null, null);
        }
    };
    React.useEffect(() => {
        if (dataClassificationApi?.isSuccess) {
            setOptions(dataClassificationApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.displayName, data: app })));
        }
    }, [dataClassificationApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.dataClassification) {
            const dataClassification = options.find((value) => landingZoneForm?.dataClassification === value?.label);
            setDataClassification(dataClassification?.label, dataClassification?.data);
        }
    }, [options]);

    React.useEffect(() => {
        dataClassificationApi.mutate();
    }, []);

    React.useEffect(() => {
        if (landingZoneForm?.service === adfCatalogServiceCode) {
            setLandingZoneForm({
                ...landingZoneForm,
                dataClassification: 'Restricted',
            });
        }
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.dataClassification && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.dataClassification, landingZoneForm?.isTouched]);
    return (
        <>
            {landingZoneForm?.service === adfCatalogServiceCode ? (
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                    <CssTextField size="small" label="Data Classification" fullWidth value="Restricted" disabled />
                </Stack>
            ) : (
                <Box bgcolor="white">
                    <Select
                        key="dataClassification"
                        value={landingZoneForm?.dataClassification || null}
                        options={options}
                        label="Data Classification*"
                        handleOnSelect={(value) => handleOnSelectChange(value)}
                        disabled={dataClassificationApi?.isLoading}
                        isLoading={dataClassificationApi?.isLoading}
                        disableClearable={false}
                        isRequired={isTouched}
                    />
                </Box>
            )}
        </>
    );
};

export default React.memo(DataClassification);
