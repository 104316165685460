/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const ModificationType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setModificationType = (modificationType, modificationTypeDetail) => {
        setOrder({ ...order, modificationType, modificationTypeDetail, configureNewSku: null, dataDisksCount: '0' });
    };
    const modificationTypeApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.modificationTypes}`));
    const seldValueExistsInOptions = (modificationTypeValue) => options.find((option) => modificationTypeValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setModificationType(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (modificationTypeApi?.isSuccess) {
            if (modificationTypeApi?.data?.data) setOptions(modificationTypeApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [modificationTypeApi?.isSuccess]);

    React.useEffect(() => {
        modificationTypeApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.modificationType && order?.isTouched) setIsTouched(true);
    }, [order?.modificationType, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <Select
                key="modificationType"
                label="Modification Type *"
                value={order?.modificationType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={modificationTypeApi.isLoading}
                isLoading={modificationTypeApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="Select Modification Type" />
        </Stack>
    );
};

export default React.memo(ModificationType);
