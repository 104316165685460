import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OrderPayload, isBusinessNameValid, previewOrderError } from 'pages/reference-implementation/provisioning/RI002/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const BusinessUnit = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setBusinessUnitValid] = useRecoilState(isBusinessNameValid);
    const [helperText, setHelperText] = React.useState(false);
    const previewError = useRecoilValue(previewOrderError);

    const setBusinessUnit = (businessUnit = null) => {
        setOrder({
            ...order,
            businessUnit,
        });
    };

    React.useEffect(() => {
        if (
            order?.businessUnit &&
            order?.businessUnit !== null &&
            order?.businessUnit !== '' &&
            order?.businessUnit !== undefined &&
            !/^[a-zA-Z0-9_-\s]{3,64}$/.test(order?.businessUnit)
        ) {
            setHelperText(true);
            setBusinessUnitValid(false);
        } else {
            setHelperText(false);
            setBusinessUnitValid(true);
        }
    }, [order?.businessUnit]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.businessUnit) ||
            helperText ||
            (Array.isArray(previewError?.message) && previewError?.message?.includes('business-unit'))
        ) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.businessUnit && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessUnit, order?.isTouched]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Business Unit" isRequired />}
                error={helperText}
                helperText={helperText && <span>Business unit must be min 3 chars and accepts only hyphen(-) & underscore(_) as special chars.</span>}
                value={order?.businessUnit || ''}
                onChange={(e) => setBusinessUnit(e.target.value)}
                className={validateInput()}
            />
        </Box>
    );
};

export default BusinessUnit;
