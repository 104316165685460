import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'storage-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'storage-quickConfigDetails',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'storage-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'storage-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'storage-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'storage-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'storage-sspOrder',
    default: {
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        environment: null,
        capacity: '100 GB',
        capacityDetails: { storageDisplay: '100 GB', storageValue: 107374182400 },
        redundancy: null,
        redundancyDetails: null,
        isADSLEnabled: 'No',
        premiumAccountType: null,
        premiumAccountTypeDetails: null,
        appTier: 'Storage Server',
        appTierCode: 'SS',
        region: null,
        regionDisplayName: null,
        accessTier: 'Hot',
        accountName: '',
        containerName: '',
        hCode: null,
        hCodeDetails: null,
        performance: 'Standard',
        envDetails: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'storage-environmentDetails',
    default: {
        Subscription: '-',
        Resource_Group: '-',
        Approval_Manager: '-',
        Subnet_Name: '-',
        Virtual_Network: '-',
        Project: '-',
        Routing_Preference: '-',
        EncryptionType: '-',
    },
});

export const isStorageAccountNameVaild = atom({
    key: 'storage-isStorageAccountNameVaild',
    default: false,
});

export const isContainerNameVaild = atom({
    key: 'storage-isContainerNameVaild',
    default: false,
});

export const costEstimateState = atom({
    key: 'storage-costEstimateState',
    default: null,
});

export const isLoadingCostApiState = atom({
    key: 'storage-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
