import React from 'react';
import Modal from 'components/modal';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
    ddCloudProvider,
    ddCloudService,
    ddEnvironment,
    ddRegion,
    ddGeoRecoveryRegion,
    ddResGroup,
    ddVnet,
    landingZoneForm,
    landZoneListFilter,
    toggleCreateLandingZone,
    ddSubNet,
    ddNsg,
    ddAsg,
    ddppGrp,
    ddAvailSet,
    ddAvailZone,
    ddSubscription,
    ddDataClassification,
    ddDepartment,
    ddBusinessUnit,
    ddCluster,
    isProjectNameVaild,
    isISADocumentVaild,
    isAppNameValid,
    islandingZoneDescVaild,
    islandingZoneLabelVaild,
    isLdapVaild,
    ddSku,
    ddType,
    ddendPointType,
} from 'pages/app-profiles/store';
import Clz from 'pages/app-profiles/landing-zones/components/Form';
import VmFields from 'pages/app-profiles/landing-zones/VmFields';
import OracleFields from 'pages/app-profiles/landing-zones/OracleFields';
import IaaSSQLFields from 'pages/app-profiles/landing-zones/IaaSSQLFields';
import SqlFields from 'pages/app-profiles/landing-zones/SqlFields';
import StorageAccountFields from 'pages/app-profiles/landing-zones/StorageAccountFields';
import AKSNamespace from 'pages/app-profiles/landing-zones/AKSNamespace';
import AKSKeyVault from 'pages/app-profiles/landing-zones/AKSKeyVault';
import KafkaFields from 'pages/app-profiles/landing-zones/KafkaFields';
import ALBFields from 'pages/app-profiles/landing-zones/ALBFields';
import EventHubFields from 'pages/app-profiles/landing-zones/EventHubFields';
import CouchbaseFields from 'pages/app-profiles/landing-zones/CouchbaseFields';
import ADFFields from 'pages/app-profiles/landing-zones/ADFFields';
import LandingZoneStyle from 'pages/app-profiles/landing-zones/LandingZoneStyle';
import { useTranslation } from 'react-i18next';
import {
    iaasVMCatalogServiceCode,
    keyvaultCatalogServiceCode,
    kafkaCatalogServiceCode,
    AlbCatalogServiceCode,
    eventhubCatalogServiceCode,
    storageAccountCatalogServiceCode,
    aksCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    paasSQLCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    privateEndPointCatalogServiceCode,
    eipStackCatalogServiceCode,
    iaasCouchbaseServiceCode,
    adfCatalogServiceCode,
} from 'utils/catalogServiceCode';
import Btn from 'components/button';
import AzureEndPointFields from 'pages/app-profiles/landing-zones/AzureEndpointFields';
import { getPayload } from './getPayload';
import EIPStackFields from './EIPStackFields';
import { clearLandingZoneFromLocalStorage, getLandingZoneFromLocalStorage } from './utils';

// eslint-disable-next-line react/prop-types
const CreateLandingZone = (props) => {
    const [isLoading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const { appProfileName } = props;
    const [lzDDCloudProvider] = useRecoilState(ddCloudProvider);
    const [lzDDCloudService] = useRecoilState(ddCloudService);
    const [lzDDEnv] = useRecoilState(ddEnvironment);
    const [lzDDEndPointType] = useRecoilState(ddendPointType);
    const [lzDDRegion] = useRecoilState(ddRegion);
    const [lzDDGeoRecoveryRegion] = useRecoilState(ddGeoRecoveryRegion);
    const [lzDDSubscription] = useRecoilState(ddSubscription);
    const [lzDDResGroup] = useRecoilState(ddResGroup);
    const [lzDDVnet] = useRecoilState(ddVnet);
    const [lzDDSubNet] = useRecoilState(ddSubNet);
    const [lzDDDataClassification] = useRecoilState(ddDataClassification);
    const [lzDDDepartment] = useRecoilState(ddDepartment);
    const [lzDDCluster] = useRecoilState(ddCluster);
    const [lzDDBusinessUnit] = useRecoilState(ddBusinessUnit);
    const [lzDDAvailSet] = useRecoilState(ddAvailSet);
    const [lzDDAvailZone] = useRecoilState(ddAvailZone);
    const [lzDDSku] = useRecoilState(ddSku);
    const [lzDDType] = useRecoilState(ddType);
    const [projectNameVaild, setIsProjectNameValid] = useRecoilState(isProjectNameVaild);
    const [appNameValid, setIsAppNameValid] = useRecoilState(isAppNameValid);
    const [labelValid, setIsLabelValid] = useRecoilState(islandingZoneLabelVaild);
    const [descValid, setIsDescValid] = useRecoilState(islandingZoneDescVaild);
    const [ISADocumentValid, setISADocumentValid] = useRecoilState(isISADocumentVaild);
    const [ldapVaild, setISLdapVaild] = useRecoilState(isLdapVaild);
    const resetLzDDCloudProvider = useResetRecoilState(ddCloudProvider);
    const resetLzDDCloudService = useResetRecoilState(ddCloudService);
    const resetLzDDEnv = useResetRecoilState(ddEnvironment);
    const resetLzDEndPointType = useResetRecoilState(ddendPointType);
    const resetLzDDRegion = useResetRecoilState(ddRegion);
    const resetLzDDGeoRecoveryRegion = useResetRecoilState(ddGeoRecoveryRegion);
    const resetLzDDSubscription = useResetRecoilState(ddSubscription);
    const resetLzDDResGroup = useResetRecoilState(ddResGroup);
    const resetLzDDVnet = useResetRecoilState(ddVnet);
    const resetLzDDSubNet = useResetRecoilState(ddSubNet);
    const resetLzDDDataClassification = useResetRecoilState(ddDataClassification);
    const resetLzDDDepartment = useResetRecoilState(ddDepartment);
    const resetLzDDBusinessUnit = useResetRecoilState(ddBusinessUnit);
    const resetLzDDCluster = useResetRecoilState(ddCluster);
    const resetLzDDNsg = useResetRecoilState(ddNsg);
    const resetLzDDAsg = useResetRecoilState(ddAsg);
    const resetLzDDPPGrp = useResetRecoilState(ddppGrp);
    const resetLzDDAvailSet = useResetRecoilState(ddAvailSet);
    const resetLzDDAvailZone = useResetRecoilState(ddAvailZone);
    const resetLzDDSku = useResetRecoilState(ddSku);
    const resetLzDDType = useResetRecoilState(ddType);
    const [filterObj, setFilterObj] = useRecoilState(landZoneListFilter);
    const [form, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const resetModal = useResetRecoilState(toggleCreateLandingZone);
    const resetForm = useResetRecoilState(landingZoneForm);
    const [lzModal, setLZModal] = useRecoilState(toggleCreateLandingZone);
    const createApi = useMutation((payload) => axios.post(`${endPoints.landingZones.createLandingZone}`, payload));
    const updateApi = useMutation((payload) => axios.put(`${endPoints.landingZones.editLandingZone}`, payload));
    React.useEffect(() => {
        if (lzModal?.isOpen === false) {
            resetForm();
            resetModal();
            resetLzDDCloudProvider();
            resetLzDDCloudService();
            resetLzDDEnv();
            resetLzDEndPointType();
            resetLzDDRegion();
            resetLzDDGeoRecoveryRegion();
            resetLzDDSubscription();
            resetLzDDResGroup();
            resetLzDDVnet();
            resetLzDDSubNet();
            resetLzDDDataClassification();
            resetLzDDDepartment();
            resetLzDDBusinessUnit();
            resetLzDDCluster();
            resetLzDDNsg();
            resetLzDDAsg();
            resetLzDDPPGrp();
            resetLzDDAvailSet();
            resetLzDDAvailZone();
            resetLzDDSku();
            resetLzDDType();
            setIsProjectNameValid(true);
            setISADocumentValid(true);
            setIsAppNameValid(true);
            setIsDescValid(true);
            setIsLabelValid(true);
            setISLdapVaild(true);
        }
    }, [lzModal?.isOpen]);
    const isReadyToSubmit = () => {
        if (!form?.cloudService) {
            return true;
        }
        if (form?.cloudService === iaasVMCatalogServiceCode || form?.cloudService === iaasSQLCatalogServiceCode) {
            if (!form?.resGroup || !form?.vNet || !form?.subNet || !form?.landingZoneLabel || !form?.landingZoneDesc || !labelValid || !descValid) return true;
            if (!form?.availSet && !form?.availZoneDetails?.length) return true;
        }
        if (form?.cloudService === iaasCouchbaseServiceCode) {
            if (
                !form?.resGroup ||
                !form?.vNet ||
                !form?.subNet ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid ||
                !appNameValid
            )
                return true;
            if (!form?.availZoneDetails?.length) return true;
        }
        if (form?.cloudService === iaasSQLCatalogServiceCode) {
            if (!form?.resGroup || !form?.vNet || !form?.subNet || !form?.landingZoneLabel || !form?.landingZoneDesc || !labelValid || !descValid) return true;
            if (!form?.availSet && !form?.availZoneDetails?.length) return true;
        }
        if (form?.cloudService === iaasOracleCatalogServiceCode) {
            if (!form?.resGroup || !form?.vNet || !form?.subNet || !form?.landingZoneLabel || !form?.landingZoneDesc || !labelValid || !descValid) return true;
            if (!form?.availSet && !form?.availZoneDetails?.length) return true;
        }
        if (form?.cloudService === paasSQLCatalogServiceCode) {
            const isValidVnetSubnet = () => {
                let invalidVnetSubnet = false;
                if (form?.vnetSubnet?.length === 1) {
                    form?.vnetSubnet?.forEach((z) => {
                        if (z?.vNet === null && z?.subnet === null && z?.networkSubscription === null) invalidVnetSubnet = false;
                        if (z?.networkSubscription !== null && (z?.vNet === null || z?.subnet === null)) invalidVnetSubnet = true;
                        if (z?.networkSubscription === null && (z?.vNet !== null || z?.subnet !== null)) invalidVnetSubnet = false;
                    });
                }
                if (form?.vnetSubnet?.length > 1) {
                    form?.vnetSubnet?.forEach((z) => {
                        if (z?.vNet === null || z?.subnet === null || z?.networkSubscription === null) invalidVnetSubnet = true;
                    });
                }
                return invalidVnetSubnet;
            };
            if (form?.cloudService === adfCatalogServiceCode) {
                if (!form?.resGroup || !form?.dataClassification || !form?.landingZoneLabel || !form?.landingZoneDesc || !labelValid || !descValid) return true;
            }

            const isDuplicateVnetSubnetValue = () => {
                if (form?.vnetSubnet?.length > 1)
                    return form?.vnetSubnet
                        ?.map((value) => value?.vNet + value?.subnet + value?.networkSubscription)
                        ?.some((val, index, array) => array.indexOf(val) !== array.lastIndexOf(val));
                return false;
            };

            if (
                !form?.environment ||
                !form?.region ||
                !form?.subscription ||
                !form?.resGroup ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid ||
                isValidVnetSubnet() ||
                isDuplicateVnetSubnetValue()
            )
                return true;
        }
        if (form?.cloudService === storageAccountCatalogServiceCode) {
            const isValidVnetSubnet = () => {
                let invalidVnetSubnet = false;
                if (form?.vnetSubnet?.length === 1) {
                    form?.vnetSubnet?.forEach((z) => {
                        if (z?.vNet === null && z?.subnet === null && z?.networkSubscription === null) invalidVnetSubnet = false;
                        if (z?.networkSubscription !== null && (z?.vNet === null || z?.subnet === null)) invalidVnetSubnet = true;
                        if (z?.networkSubscription === null && (z?.vNet !== null || z?.subnet !== null)) invalidVnetSubnet = false;
                    });
                }
                if (form?.vnetSubnet?.length > 1) {
                    form?.vnetSubnet?.forEach((z) => {
                        if (z?.vNet === null || z?.subnet === null || z?.networkSubscription === null) invalidVnetSubnet = true;
                    });
                }
                return invalidVnetSubnet;
            };
            const isDuplicateVnetSubnetValue = () => {
                if (form?.vnetSubnet?.length > 1)
                    return form?.vnetSubnet
                        ?.map((value) => value?.vNet + value?.subnet + value?.networkSubscription)
                        ?.some((val, index, array) => array.indexOf(val) !== array.lastIndexOf(val));
                return false;
            };

            if (
                !form?.resGroup ||
                !form?.dataClassification ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid ||
                isValidVnetSubnet() ||
                isDuplicateVnetSubnetValue()
            )
                return true;
        }
        if (form?.cloudService === aksCatalogServiceCode) {
            if (
                !form?.environment ||
                !form?.businessUnit ||
                !form?.department ||
                !form?.region ||
                // !form?.projectName ||
                // !projectNameVaild ||
                !ISADocumentValid ||
                !form?.cluster ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid
            )
                return true;
        }
        if (form?.cloudService === keyvaultCatalogServiceCode) {
            if (
                !form?.resGroup ||
                !form?.vNet ||
                !form?.subNet ||
                !form?.dataClassification ||
                !form?.networkConnectivity ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !appNameValid ||
                !labelValid ||
                !descValid
            )
                return true;
        }
        if (form?.cloudService === kafkaCatalogServiceCode) {
            if (
                !form?.environment ||
                !form?.businessUnit ||
                !form?.region ||
                !form?.department ||
                !form?.cluster ||
                !form?.projectName ||
                !form?.ISADocument ||
                !form?.ldap ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !projectNameVaild ||
                !ISADocumentValid ||
                !ldapVaild ||
                !labelValid ||
                !descValid
            )
                return true;
        }
        if (form?.cloudService === AlbCatalogServiceCode) {
            if (
                !form?.resGroup ||
                !form?.vNet ||
                !form?.subNet ||
                !form?.sku ||
                !form?.type ||
                !appNameValid ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid
            )
                return true;
        }
        if (form?.cloudService === privateEndPointCatalogServiceCode) {
            if (
                !form?.resGroup ||
                !form?.vNet ||
                !form?.subNet ||
                !appNameValid ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid ||
                !form?.dataClassification
            )
                return true;
        }
        if (form?.cloudService === eventhubCatalogServiceCode) {
            let invalidVnetSubnet = false;
            form?.vnetSubnet?.forEach((z) => {
                if (z?.vNet === null || z?.subnet === null) invalidVnetSubnet = true;
            });
            const isDuplicateVnetSubnetValue = () =>
                form?.vnetSubnet?.map((value) => value?.vNet + value?.subnet)?.some((val, index, array) => array.indexOf(val) !== array.lastIndexOf(val));
            if (
                !form?.environment ||
                !form?.region ||
                (form?.environment === 'Production' && !form?.geoRecoveryRegion) ||
                !form?.subscription ||
                !form?.resGroup ||
                !form?.dataClassification ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid ||
                invalidVnetSubnet ||
                form?.dataClassification === 'Restricted' ||
                isDuplicateVnetSubnetValue()
            )
                return true;
        }
        if (form?.cloudService === eipStackCatalogServiceCode) {
            let invalidComponents = false;
            let invalidConnections = false;
            let invalidMandatoryComponents = false;
            let mandatoryErrorCount = 0;
            form?.components?.forEach((item) => {
                if (item?.landingZone) {
                    form?.connections?.forEach((conn) => {
                        let errorCount = 0;
                        if (conn?.targetComponentName === item?.service) {
                            if (conn?.connectionType === null || conn?.connectionType === '' || conn?.connectionType === undefined) {
                                errorCount += 1;
                            }
                            invalidComponents = errorCount !== 0;
                        }
                    });
                }

                if (!item?.optional && (item?.landingZone === null || item?.landingZone === '' || item?.landingZone === undefined)) {
                    mandatoryErrorCount += 1;
                }
                invalidMandatoryComponents = mandatoryErrorCount !== 0;
            });
            form?.connections?.forEach((item) => {
                if (item?.connectionType) {
                    form?.components?.forEach((comp) => {
                        let errorCount = 0;
                        if (comp?.service === item?.targetComponentName) {
                            if (comp?.landingZone === null || comp?.landingZone === '' || comp?.landingZone === undefined) {
                                errorCount += 1;
                            }
                            invalidConnections = errorCount !== 0;
                        }
                    });
                }
            });
            if (
                !form?.environment ||
                !form?.region ||
                !form?.dataClassification ||
                !form?.landingZoneLabel ||
                !form?.landingZoneDesc ||
                !labelValid ||
                !descValid ||
                invalidComponents ||
                invalidMandatoryComponents ||
                invalidConnections
            )
                return true;
        }
        return false;
    };

    const handleSubmit = async () => {
        const data = getLandingZoneFromLocalStorage();
        if (data && data?.environment !== form?.environment) {
            toast.error(`Choose environment as ${data?.environment} and region as ${data?.region} which you choosed on EIP stack.`);
        } else if (data && data?.region !== form?.region) {
            toast.error(`Choose environment as ${data?.environment} and region as ${data?.region} which you choosed on EIP stack.`);
        } else {
            if (form?.cloudService === eipStackCatalogServiceCode || lzModal?.lzDetails?.cloudService === eipStackCatalogServiceCode)
                clearLandingZoneFromLocalStorage();
            const payload = await getPayload({ appName: appProfileName || lzModal?.appProfileName, data: form, type: lzModal?.type });
            if (lzModal?.type === 'edit') {
                updateApi?.mutate(payload);
            } else {
                createApi.mutate(payload);
            }
        }
    };
    React.useEffect(() => {
        if (createApi?.isSuccess && !getLandingZoneFromLocalStorage()) {
            setFilterObj({ ...filterObj, pageNumber: 1 });
            setLZModal({ ...lzModal, isOpen: false });
            toast.success(createApi?.data?.messages);
        }
        if (createApi?.isSuccess && getLandingZoneFromLocalStorage()) {
            const data = getLandingZoneFromLocalStorage();
            const components = data?.components?.map((item) => ({
                ...item,
                ...(item?.service === createApi?.data?.data?.cloudService && { landingZone: null, landingZoneDetails: { id: createApi?.data?.data?.id } }),
            }));
            data.components = components;
            setLandingZoneForm(data);
            setLZModal({ ...lzModal, type: data?.windowType, isOpen: true, lzDetails: data });
            toast.success(createApi?.data?.messages);
        }
    }, [createApi?.isSuccess]);
    React.useEffect(() => {
        if (updateApi?.isSuccess) {
            setFilterObj({ ...filterObj, pageNumber: 1 });
            setLZModal({ ...lzModal, isOpen: false });
            toast.success(updateApi?.data?.messages);
            if (form?.cloudService === eipStackCatalogServiceCode || lzModal?.lzDetails?.cloudService === eipStackCatalogServiceCode)
                clearLandingZoneFromLocalStorage();
        }
    }, [updateApi?.isSuccess]);
    React.useEffect(() => {
        if (
            (lzModal?.lzDetails?.cloudService === iaasVMCatalogServiceCode ||
                lzModal?.lzDetails?.cloudService === iaasSQLCatalogServiceCode ||
                lzModal?.lzDetails?.cloudService === iaasOracleCatalogServiceCode ||
                lzModal?.lzDetails?.cloudService === iaasCouchbaseServiceCode) &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDVnet?.isLoaded &&
            lzDDSubNet?.isLoaded &&
            lzDDAvailSet?.isLoaded &&
            lzDDAvailZone?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === iaasCouchbaseServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDVnet?.isLoaded &&
            lzDDSubNet?.isLoaded &&
            lzDDAvailZone?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === AlbCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDVnet?.isLoaded &&
            lzDDSubNet?.isLoaded &&
            lzDDSku?.isLoaded &&
            lzDDType?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === storageAccountCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDVnet?.isLoaded &&
            lzDDSubNet?.isLoaded &&
            lzDDDataClassification?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === keyvaultCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDVnet?.isLoaded &&
            lzDDSubNet?.isLoaded &&
            lzDDDataClassification?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === aksCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDBusinessUnit?.isLoaded &&
            lzDDDepartment?.isLoaded &&
            lzDDCluster?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === paasSQLCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === kafkaCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDBusinessUnit?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDDepartment?.isLoaded &&
            lzDDCluster?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === privateEndPointCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDEndPointType?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDVnet?.isLoaded &&
            lzDDSubNet?.isLoaded &&
            lzDDDataClassification?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === eventhubCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDGeoRecoveryRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDDataClassification?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === eipStackCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDDataClassification?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
        if (
            lzModal?.lzDetails?.cloudService === adfCatalogServiceCode &&
            lzDDCloudProvider?.isLoaded &&
            lzDDCloudService?.isLoaded &&
            lzDDEnv?.isLoaded &&
            lzDDRegion?.isLoaded &&
            lzDDSubscription?.isLoaded &&
            lzDDResGroup?.isLoaded &&
            lzDDDataClassification?.isLoaded
        ) {
            setTimeout(() => {
                setLZModal({ ...lzModal, isLoadedAllDD: true, lzDetails: undefined });
            }, 1000);
        }
    }, [
        lzDDCloudProvider?.isLoaded,
        lzDDCloudService?.isLoaded,
        lzDDEnv?.isLoaded,
        lzDDRegion?.isLoaded,
        lzDDGeoRecoveryRegion?.isLoaded,
        lzDDSubscription?.isLoaded,
        lzDDResGroup?.isLoaded,
        lzDDVnet?.isLoaded,
        lzDDSubNet?.isLoaded,
        lzDDAvailSet?.isLoaded,
        lzDDAvailZone?.isLoaded,
        lzDDBusinessUnit?.isLoaded,
        lzDDDepartment?.isLoaded,
        lzDDCluster?.isLoaded,
        lzDDDataClassification?.isLoaded,
        lzDDSku?.isLoaded,
        lzDDType?.isLoaded,
    ]);

    const handleClose = () => {
        if (!getLandingZoneFromLocalStorage()) {
            setLZModal({ ...lzModal, isOpen: false });
        }
        if (
            getLandingZoneFromLocalStorage() &&
            !(form?.cloudService === eipStackCatalogServiceCode || lzModal?.lzDetails?.cloudService === eipStackCatalogServiceCode)
        ) {
            const data = getLandingZoneFromLocalStorage();
            setLandingZoneForm(data);
            setLZModal({ ...lzModal, type: data?.windowType, isOpen: true, lzDetails: data });
        }
        if (
            getLandingZoneFromLocalStorage() &&
            (form?.cloudService === eipStackCatalogServiceCode || lzModal?.lzDetails?.cloudService === eipStackCatalogServiceCode)
        ) {
            clearLandingZoneFromLocalStorage();
            setLZModal({ ...lzModal, isOpen: false, lzDetails: undefined });
        }
    };

    return (
        <Modal
            title={
                lzModal?.type === 'create'
                    ? `Create Landing Zone - ${appProfileName || lzModal?.appProfileName}`
                    : `Modify Landing Zone - ${appProfileName || lzModal?.appProfileName}`
            }
            handleClose={() => handleClose()}
            open={lzModal?.isOpen}
            sx={LandingZoneStyle?.createModal?.modal}
        >
            <Box style={LandingZoneStyle?.createModal?.wrapper}>
                {isLoading && (
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress color="inherit" size={16} sx={{ color: (theme) => theme.palette.black.dark }} />
                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.black.dark }}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box sx={LandingZoneStyle?.createModal?.fieldsWrapper}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box sx={LandingZoneStyle?.createModal?.fieldValue}>
                                    <Clz.CloudProvider />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={LandingZoneStyle?.createModal?.fieldValue}>
                                    <Clz.CloudService />
                                </Box>
                            </Grid>
                            {(form?.cloudService === iaasVMCatalogServiceCode || lzModal?.lzDetails?.cloudService === iaasVMCatalogServiceCode) && <VmFields />}
                            {(form?.cloudService === iaasCouchbaseServiceCode || lzModal?.lzDetails?.cloudService === iaasCouchbaseServiceCode) && (
                                <CouchbaseFields />
                            )}
                            {(form?.cloudService === iaasOracleCatalogServiceCode || lzModal?.lzDetails?.cloudService === iaasOracleCatalogServiceCode) && (
                                <OracleFields />
                            )}
                            {(form?.cloudService === paasSQLCatalogServiceCode || lzModal?.lzDetails?.cloudService === paasSQLCatalogServiceCode) && (
                                <SqlFields />
                            )}
                            {(form?.cloudService === iaasSQLCatalogServiceCode || lzModal?.lzDetails?.cloudService === iaasSQLCatalogServiceCode) && (
                                <IaaSSQLFields />
                            )}
                            {(form?.cloudService === storageAccountCatalogServiceCode ||
                                lzModal?.lzDetails?.cloudService === storageAccountCatalogServiceCode) && <StorageAccountFields />}
                            {(form?.cloudService === aksCatalogServiceCode || lzModal?.lzDetails?.cloudService === aksCatalogServiceCode) && <AKSNamespace />}
                            {(form?.cloudService === keyvaultCatalogServiceCode || lzModal?.lzDetails?.cloudService === keyvaultCatalogServiceCode) && (
                                <AKSKeyVault />
                            )}
                            {(form?.cloudService === kafkaCatalogServiceCode || lzModal?.lzDetails?.cloudService === kafkaCatalogServiceCode) && (
                                <KafkaFields />
                            )}
                            {(form?.cloudService === AlbCatalogServiceCode || lzModal?.lzDetails?.cloudService === AlbCatalogServiceCode) && <ALBFields />}
                            {(form?.cloudService === privateEndPointCatalogServiceCode ||
                                lzModal?.lzDetails?.cloudService === privateEndPointCatalogServiceCode) && <AzureEndPointFields />}
                            {(form?.cloudService === eventhubCatalogServiceCode || lzModal?.lzDetails?.cloudService === eventhubCatalogServiceCode) && (
                                <EventHubFields />
                            )}
                            {(form?.cloudService === eipStackCatalogServiceCode || lzModal?.lzDetails?.cloudService === eipStackCatalogServiceCode) && (
                                <EIPStackFields appProfileName={appProfileName || lzModal?.appProfileName} windowType={lzModal?.type} setLoading={setLoading} />
                            )}
                            {(form?.cloudService === adfCatalogServiceCode || lzModal?.lzDetails?.cloudService === adfCatalogServiceCode) && <ADFFields />}
                        </Grid>

                        <Box sx={LandingZoneStyle?.createModal?.btnWrapper}>
                            <Btn
                                variant="contained"
                                color="cmpPrimary"
                                onClick={() => handleSubmit()}
                                disabled={createApi?.isLoading || isReadyToSubmit()}
                                style={{ textTransform: 'none' }}
                                data-testid="save-btn"
                            >
                                {t([`common.btnText.save`])}

                                {(createApi?.isLoading || updateApi?.isLoading) && (
                                    <CircularProgress sx={LandingZoneStyle?.createModal?.circularIcon} color="inherit" size={14} />
                                )}
                            </Btn>
                            <Btn
                                style={LandingZoneStyle?.createModal?.cancelBtn}
                                variant="outlined"
                                layout="outline"
                                color="cmpWarning"
                                onClick={() => handleClose()}
                                data-testid="cancel-btn"
                            >
                                {t([`common.btnText.cancel`])}
                            </Btn>
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};
CreateLandingZone.propTypes = {
    appProfileName: PropTypes.string,
};
CreateLandingZone.defaultProps = {
    appProfileName: '',
};
export default CreateLandingZone;
