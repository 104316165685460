import React from 'react';
import { Divider } from '@mui/material';

const HistorySeparator = () => {
    return (
        <Divider>
            <span style={{ color: '#c4c4c4' }}>Scroll to top for history</span>
        </Divider>
    );
};

export default HistorySeparator;
