import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import styles, { CustomTagButton } from '../styles';
import { toggleModal, selectedDocument } from '../store';

function HelpCard({ data }) {
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setSelectedDocument] = useRecoilState(selectedDocument);

    const handleSelect = (document) => {
        setSelectedDocument(document);
        setToggleModal(true);
    };

    return (
        <Paper elevation={0} sx={styles.helpCard.wrapper} data-testid="help-card">
            <Box mx={2} my={1}>
                <Typography
                    variant="h6"
                    gutterBottom
                    component="div"
                    sx={styles.helpCard.title}
                    onClick={() => {
                        handleSelect(data);
                    }}
                    data-testid="select-btn"
                >
                    {data?.title}
                </Typography>
                <Typography variant="h1" component="h2" sx={styles.helpCard.summary}>
                    {data?.summary}
                </Typography>
                <Box>
                    {data?.tags?.length > 0 &&
                        data?.tags.map((e, index) => (
                            <CustomTagButton disableElevation disableRipple key={index.toString()}>
                                {e}
                            </CustomTagButton>
                        ))}
                </Box>
            </Box>
        </Paper>
    );
}

HelpCard.propTypes = {
    data: PropTypes.any,
};

HelpCard.defaultProps = {
    data: {},
};

export default React.memo(HelpCard);
