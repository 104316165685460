// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, Box, Alert, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import { customToast } from 'api/utils';
import { orderPayload, toggleModal } from 'pages/mdt-onboarding/store';
import { endPoints } from 'api/endpoints';
import toast from 'react-hot-toast';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { addSourceFileNamePattern, removeSourceFileNamePattern } from 'pages/mdt-onboarding/store/selector';
import { RI001Styles } from 'pages/reference-implementation/provisioning/RI001/style';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { patternRegex } from '../../utils';
import SourceFileNamePattern from './SourceFileNamePattern';

const SourceFilenameModal = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [openInfo, setOpenInfo] = React.useState(true);
    const addGroup = useSetRecoilState(addSourceFileNamePattern);
    const removeGroup = useSetRecoilState(removeSourceFileNamePattern);
    const [emptyPatternList, setEmptyPatternList] = React.useState([]);
    const [lastModifiedSFN, setLastModifiedSFN] = React.useState(undefined);

    const sourceFileNameValidationApi = useMutation((payload) => axios.post(endPoints.mdt.Jwt.sourceNameValidation, payload));

    const isFormValid = () => {
        let errorCount = 0;
        let isSourceFileNamePatternValid = false;
        if (order?.sourceFileNamePatternList?.length) {
            order?.sourceFileNamePatternList?.forEach((fileNamePattern) => {
                if (fileNamePattern && (fileNamePattern?.fileName === '' || fileNamePattern?.fileName === null)) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isSourceFileNamePatternValid = errorCount === 0;
        return isSourceFileNamePatternValid;
    };
    const isDuplicateSourceFileNamePattern = () => {
        const sourceFileNamePatternArray = order?.sourceFileNamePatternList?.map((item) => item?.fileName);
        return new Set(sourceFileNamePatternArray).size !== sourceFileNamePatternArray.length;
    };

    React.useEffect(() => {
        if (isOpen?.isSourceFilenameModalOpen) {
            setLastModifiedSFN(order?.sourceFileNamePatternList);
            setOpenInfo(true);
            setIsOpen({ ...isOpen, sourceFileNamePatternAdded: order?.sourceFileNamePatternList });
        } else toast.dismiss();
    }, [isOpen?.isSourceFilenameModalOpen]);

    const handleSubmit = async () => {
        const emptyArr = [];
        let invalidPattern = 0;
        order?.sourceFileNamePatternList.forEach((z, i) => {
            if (z?.fileName === '' || z?.fileName === null) {
                emptyArr.push(i);
            }
            if (patternRegex(order?.sourceFileNamePatternList[i]?.fileName)) {
                // eslint-disable-next-line no-plusplus
                invalidPattern++;
            }
        });
        setEmptyPatternList(emptyArr);
        if (!isFormValid() || isDuplicateSourceFileNamePattern() || invalidPattern > 0) {
            customToast(['Source Filename cannot be empty or duplicate or invalid.']);
        } else {
            sourceFileNameValidationApi?.mutate({
                sourceFilenamePattern: order?.sourceFileNamePatternList.map((fileNamePattern) => fileNamePattern?.fileName),
                sourceDirectory: order?.sourceDirectory,
                environment: order?.environment,
            });
        }
    };
    React.useEffect(() => {
        if (sourceFileNameValidationApi?.isSuccess) {
            if (sourceFileNameValidationApi?.data?.isSuccess) {
                setIsOpen({ ...isOpen, isSourceFilenameModalOpen: false, sourceFileNamePatternAdded: order?.sourceFileNamePatternList });
                toast.dismiss();
            } else {
                setIsOpen({ ...isOpen, isSourceFilenameModalOpen: true });
            }
        }
    }, [sourceFileNameValidationApi?.isSuccess]);

    React.useEffect(() => {
        if (sourceFileNameValidationApi?.isError && sourceFileNameValidationApi?.error?.length) setIsOpen({ ...isOpen, isSourceFilenameModalOpen: true });
    }, [sourceFileNameValidationApi?.isError]);

    const updateEmptyIndexList = (indexVal) => {
        setEmptyPatternList(emptyPatternList?.filter((z) => z !== indexVal));
    };

    return (
        <Modal
            title="Source Filename Patterns"
            handleClose={() => {
                setIsOpen({ ...isOpen, isSourceFilenameModalOpen: false });
                setOrder({
                    ...order,
                    sourceFileNamePatternList: lastModifiedSFN,
                });
            }}
            open={isOpen.isSourceFilenameModalOpen}
        >
            <Box sx={RI001Styles.modal.wrapper}>
                <Box sx={{ ...RI001Styles.modal.contentWrapper, paddingTop: '0px' }}>
                    <Grid container spacing={4} sx={{ marginBottom: '10px' }}>
                        <Grid item xs={12} sm={1} />
                        <Grid item xs={12} sm={10}>
                            {openInfo && (
                                <Alert
                                    icon={false}
                                    severity="info"
                                    color="info"
                                    sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenInfo(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Maximum allowed filename patterns are 10
                                </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={1} />
                    </Grid>

                    {order?.sourceFileNamePatternList?.map((item, index) => (
                        <Box key={index.toString()} sx={RI001Styles.modal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={RI001Styles.modal.groupWrapper}>
                                <React.Fragment key={index.toString()}>
                                    <SourceFileNamePattern
                                        indexVal={index}
                                        indexList={emptyPatternList}
                                        updateEmptyList={updateEmptyIndexList}
                                        validationData={sourceFileNameValidationApi?.data?.messages || sourceFileNameValidationApi?.error}
                                    />
                                </React.Fragment>
                            </Box>
                            <Box sx={RI001Styles.modal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {index !== 0 && order?.sourceFileNamePatternList?.length > 1 && (
                                        <Box sx={RI001Styles.modal.deleteWrapper}>
                                            <FaTrashAlt
                                                style={RI001Styles.modal.delete}
                                                onClick={() => removeGroup(item)}
                                                data-testid="removeIcon"
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.sourceFileNamePatternList.length - 1 && order?.sourceFileNamePatternList?.length < 10 ? (
                                        <MdAdd
                                            style={RI001Styles.modal.add}
                                            onClick={() => {
                                                addGroup({ count: 1 });
                                            }}
                                            data-testid="addIcon"
                                            className="w-7 h-7 text-blue-base cursor-pointer"
                                        />
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={RI001Styles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary2"
                        onClick={() => {
                            handleSubmit();
                        }}
                        data-testid="save-btn"
                    >
                        {sourceFileNameValidationApi?.isLoading ? <CircularProgress color="inherit" size={25} /> : 'Save'}
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={RI001Styles.modal.cancelBtn}
                        onClick={() => {
                            setIsOpen({ ...isOpen, isSourceFilenameModalOpen: false });
                            setOrder({
                                ...order,
                                sourceFileNamePatternList: lastModifiedSFN,
                            });
                        }}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default SourceFilenameModal;
