import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'PE-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'PE-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'PE-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        instanceDetailsModal: { open: false, index: null, type: null },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'PE-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'PE-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'PE-sspOrder',
    default: {
        showPreview: null,
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        sector: null,
        environment: null,
        instanceDetails: [
            {
                privateEndpointType: null,
                sourceResource: null,
                targetResource: null,
                isClone: false,
            },
        ],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'PE-environmentDetails',
    default: {
        Approval_Manager: '-',
        Project: '-',
    },
});

export const costEstimateState = atom({
    key: 'PE-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'PE-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isValidEndpoint = atom({
    key: 'PE-isValidEndpoint',
    default: false,
});

export const isValidDatabrickName = atom({
    key: 'PE-isValidDatabrickName',
    default: true,
});
