import React from 'react';
import { Box } from '@mui/material';
import ProjectSection from 'pages/reference-implementation/provisioning/RI003/components/sections/Project';
import ApplicationSection from 'pages/reference-implementation/provisioning/RI003/components/sections/Application';
import AccessMgmtSection from 'pages/reference-implementation/provisioning/RI003/components/sections/AccessMgmtSection';

const IndexForm = () => {
    return (
        <Box flex={3}>
            <ProjectSection />
            <ApplicationSection />
            <AccessMgmtSection />
        </Box>
    );
};

export default IndexForm;
