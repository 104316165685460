export const topicNameValidation = (val, allTopics) => {
    let valid = {};

    if (val && val !== null && val !== '' && val !== undefined) {
        const name = val;
        const parts = name.split('-');
        if (!name.startsWith('pep-')) {
            valid = { showError: true, errorMessage: 'Topic name must start with "pep-".' };
        } else if (parts.length < 5) {
            valid = { showError: true, errorMessage: 'Topic name must include Sector, Project, Service Name, Process Name, and optionally versioning.' };
        } else if (!/^[a-zA-Z]+$/.test(parts[1])) {
            valid = { showError: true, errorMessage: 'Sector must contain alphabetic characters only.' };
        } else if (!/^[a-zA-Z]+$/.test(parts[2])) {
            valid = { showError: true, errorMessage: 'Project must contain alphabetic characters only.' };
        } else if (!/^[a-zA-Z0-9]+$/.test(parts[3])) {
            valid = { showError: true, errorMessage: 'Service Name must be alphanumeric.' };
        } else if (!/^[a-zA-Z0-9]+$/.test(parts[4])) {
            valid = { showError: true, errorMessage: 'Process Name must be alphanumeric.' };
        } else if (parts?.length > 4 && !parts?.slice(4).every((p) => /^[a-zA-Z0-9]+$/.test(p))) {
            valid = { showError: true, errorMessage: 'Process Name must be alphanumeric.' };
        } else if (allTopics?.includes(val)) {
            valid = { showError: true, errorMessage: 'Topic Name is already given' };
        } else {
            valid = { showError: false, errorMessage: 'Topic name is valid.' };
        }
    } else {
        valid = { showError: false, errorMessage: '' };
    }
    return valid;
};
export const kafkaFieldValidation = (fieldName, fieldValue, fieldindex, fillingFormErrors, formData, writeAccessStatus, readAccessStatus, allTopics) => {
    const tempFormDataErrors = { ...fillingFormErrors } || {};
    const tempErrorTopics = fillingFormErrors?.kafkatopics || [];
    const tempErrorKafkaTopic = { ...tempErrorTopics[fieldindex] } || {};

    const tempData = { ...formData } || {};
    const tempTopics = [...tempData?.kafkatopics] || [];
    const tempfkaTopic = tempTopics[fieldindex] || {};
    let validationObj = { ...fillingFormErrors };
    if (!['disableReadServiceAccOwner', 'disableWriteServiceAccOwner', 'readAccessUserValid', 'writeAccessUserValid']?.includes(fieldName)) {
        if (fieldName === 'ldap') {
            if (fieldValue !== '' && fieldValue !== null && fieldValue !== undefined) {
                if (fieldValue?.length < 3 || fieldValue?.length > 215) {
                    tempFormDataErrors[fieldName] = {
                        showError: true,
                        errorMessage: 'LDAP group must be atleast 3 chars in length and can contain only hyphen(-) & underscore(_) as special char',
                    };
                } else if (!/^[a-zA-Z0-9-_]+$/.test(fieldValue)) {
                    tempFormDataErrors[fieldName] = {
                        showError: true,
                        errorMessage: 'LDAP group must be atleast 3 chars in length and can contain only hyphen(-) & underscore(_) as special char',
                    };
                } else {
                    tempFormDataErrors[fieldName] = {
                        showError: false,
                        errorMessage: '',
                    };
                }
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else {
                tempFormDataErrors[fieldName] = {
                    showError: false,
                    errorMessage: '',
                };
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            }
            validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
        } else if (fieldName === 'topicName') {
            const topicValid = topicNameValidation(fieldValue, allTopics);
            tempErrorKafkaTopic[fieldName] = topicValid;
            tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
            validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
        } else if (fieldName === 'isWriteAccess') {
            tempErrorKafkaTopic.writeAccessUser = { showError: false, showMessage: '' };
            // tempErrorKafkaTopic.writeAccessGroupOwner = { showError: false, showMessage: '' };
            tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
            validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
        } else if (fieldName === 'isReadAccess') {
            tempErrorKafkaTopic.readAccessUser = { showError: false, showMessage: '' };
            tempErrorKafkaTopic.readAccessGroupOwner = { showError: false, showMessage: '' };
            tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
            validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
        } else if (fieldName === 'writeAccessUser') {
            if (fieldValue && fieldValue !== '' && fieldValue !== null && !/^(?![_])(?!.*__)[a-zA-Z0-9_]{3,209}(?<!_)$/.test(fieldValue)) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: true,
                    showMessage: 'Write Access User should be min. 3 chars, starts with alphanumeric and contains only underscore(_) as special char.',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (tempfkaTopic?.readAccessUser !== null && tempfkaTopic?.readAccessUser !== '' && tempfkaTopic?.readAccessUser === fieldValue) {
                tempErrorKafkaTopic[fieldName] = { showError: true, showMessage: 'Read Access User and Write Access User should not have same value' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (
                tempfkaTopic?.readAccessUser !== null &&
                tempfkaTopic?.readAccessUser !== '' &&
                tempfkaTopic?.readAccessUser !== fieldValue &&
                readAccessStatus?.valid === false
            ) {
                tempErrorKafkaTopic.readAccessUser = { showError: false, showMessage: '' };
                tempErrorKafkaTopic.writeAccessUser = { showError: false, showMessage: '' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (fieldValue !== null && fieldValue !== '' && writeAccessStatus?.valid === false) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: true,
                    showMessage: 'Given Service Account does not exist',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (fieldValue !== null && fieldValue !== '' && writeAccessStatus?.valid === true) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: false,
                    showMessage: '',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else {
                tempErrorKafkaTopic[fieldName] = { showError: false, showMessage: '' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            }
        } else if (fieldName === 'readAccessUser') {
            if (fieldValue !== '' && !/^(?![_])(?!.*__)[a-zA-Z0-9_]{3,209}(?<!_)$/.test(fieldValue)) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: true,
                    showMessage: 'Read Access User should be min. 3 chars, starts with alphanumeric and contains only underscore(_) as special char.',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (tempfkaTopic?.writeAccessUser !== null && tempfkaTopic?.writeAccessUser !== '' && fieldValue === tempfkaTopic?.writeAccessUser) {
                tempErrorKafkaTopic[fieldName] = { showError: true, showMessage: 'Write Access User and Read Access User should not have same value' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (
                tempfkaTopic?.writeAccessUser !== null &&
                tempfkaTopic?.writeAccessUser !== '' &&
                fieldValue === tempfkaTopic?.writeAccessUser &&
                writeAccessStatus?.valid === true
            ) {
                tempErrorKafkaTopic.writeAccessUser = { showError: false, showMessage: '' };
                tempErrorKafkaTopic.readAccessUser = { showError: false, showMessage: '' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (fieldValue !== '' && fieldValue !== null && readAccessStatus?.valid === true) {
                tempErrorKafkaTopic[fieldName] = { showError: false, showMessage: '' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else if (fieldValue !== '' && fieldValue !== null && readAccessStatus?.valid === false) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: true,
                    showMessage: 'Given Service Account does not exist',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else {
                tempErrorKafkaTopic[fieldName] = { showError: false, showMessage: '' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            }
        } else if (fieldName === 'transactionprefixtext') {
            if (!/^(?![-])(?!.*--)[a-zA-Z0-9-]{3,20}(?<!-)$/i.test(fieldValue)) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: true,
                    showMessage: 'Transactionprefix should be min. 3 chars, starts with alphanumeric and can contain only hyphen(-) as special char.',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            } else {
                tempErrorKafkaTopic[fieldName] = { showError: false, showMessage: '' };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
                validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
            }
        } else if (fieldName === 'transactionalPrincipalId') {
            if (
                fieldValue &&
                fieldValue !== null &&
                fieldValue !== '' &&
                fieldValue !== undefined &&
                !/^(?![_])(?!.*__)[a-zA-Z0-9_]{3,209}(?<!_)$/.test(fieldValue)
            ) {
                tempErrorKafkaTopic[fieldName] = {
                    showError: true,
                    showMessage: 'Transactional Principal Id should be min. 3 chars, starts with alphanumeric and contains only underscore(_) as special char.',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
            } else {
                tempErrorKafkaTopic[fieldName] = {
                    showError: false,
                    showMessage: '',
                };
                tempErrorTopics[fieldindex] = tempErrorKafkaTopic;
            }
            validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
        } else if ((fieldName === 'isConsumerGroup' && readAccessStatus?.valid === false) || readAccessStatus?.valid === null) {
            tempErrorKafkaTopic.readAccessUser = { showError: false, showMessage: '' };
        } else {
            validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
        }
    } else {
        validationObj = { ...tempFormDataErrors, kafkatopics: tempErrorTopics };
    }
    return validationObj;
};

export const validEmptyForm = (formData, errors) => {
    let newErrors = { businessUnit: '', ldap: '', program: '', kafkatopics: [] };
    if (!formData.businessUnit || formData?.businessUnit === '') {
        newErrors.businessUnit = { type: 'required', errorMessage: 'BusinessUnit should not be null' };
    }
    if (!formData.ldap || formData?.lap === '') {
        newErrors.ldap = { type: 'required', errorMessage: 'LDAP Group should not be null' };
    }
    if (!formData.program || formData?.program === '') {
        newErrors.program = { type: 'required', message: 'Program should not be null' };
    }
    if (formData?.kafkatopics?.length > 0) {
        const kafkaTopicArray = formData?.kafkatopics;
        const errorTopicArray = newErrors?.kafkatopics;
        kafkaTopicArray?.map((topic, index) => {
            const tempObj = {};
            Object.keys(topic)?.map((key) => {
                if (
                    (topic[key] === '' || topic[key] === null) &&
                    ![
                        'readAccessUser',
                        'transactionprefixtext',
                        'writeAccessUser',
                        'readAccessGroupOwner',
                        'writeAccessGroupOwner',
                        'transactionalPrincipalId',
                        'disableReadServiceAccOwner',
                        'disableWriteServiceAccOwner',
                        'readAccessUserValid',
                        'writeAccessUserValid',
                        'topicName',
                        'isReadAccess',
                    ]?.includes(key)
                ) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                if (key === 'topicName' && (topic[key] === '' || topic[key] === null)) {
                    tempObj[key] = { type: 'required', errorMessage: `${key} should not be null` };
                }
                if (key === 'isReadAccess' && formData?.kafkatopics?.[index]?.isConsumerGroup === 'Yes' && (topic[key] === '' || topic[key] === null)) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                if (key === 'topicName' && errors?.kafkatopics?.[index]?.[key]?.showError === true) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                } else if (key === 'topicName' && errors?.kafkatopics?.[index]?.[key]?.errorMessage === '') {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                } else if (
                    key === 'topicName' &&
                    errors?.kafkatopics?.[index]?.[key]?.showError === false &&
                    formData?.kafkatopics?.[index]?.[key] === '' &&
                    formData?.kafkatopics?.[index]?.[key] === null
                ) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                if (key === 'partitions') {
                    if (topic[key] == null || topic[key] === '') {
                        tempObj[key] = { type: 'required', message: `${key} should not be null` };
                    }
                }
                if (key === 'retention') {
                    if (
                        topic[key]?.retention == null ||
                        topic[key]?.retention === '' ||
                        topic[key]?.retentionDetails === null ||
                        topic[key]?.retentionDetails === ''
                    ) {
                        tempObj[key] = { type: 'required', message: `${key} should not be null` };
                    }
                }
                if (key === 'isConsumerGroup' && topic[key] === 'Yes') {
                    const grpObj = topic?.consumerGroups?.every((grp) => grp?.value === null || grp?.value === '');
                    if (grpObj) {
                        tempObj[key] = { type: 'required', message: `${key} should not be null` };
                    }
                }

                if (key === 'writeAccessGroupOwner' && topic?.isWriteAccess === 'No' && (topic[key] === null || topic[key] === '')) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }

                if (key === 'writeAccessUser' && topic?.isWriteAccess === 'Yes' && (topic?.writeAccessUser === null || topic?.writeAccessUser === '')) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }

                // if (key === 'writeAccessUser' && topic?.isWriteAccess === 'Yes' && (topic?.writeAccessUser !== null || topic?.writeAccessUser !== '')) {
                //     tempObj[key] = '';
                // }
                if (key === 'readAccessUser' && topic?.isReadAccess === 'Yes' && (topic[key] === null || topic[key] === '')) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                if (
                    key === 'writeAccessUser' &&
                    topic?.isWriteAccess === 'Yes' &&
                    (topic[key] !== null || topic[key] !== '') &&
                    errors?.kafkatopics?.[index]?.[key] &&
                    errors?.kafkatopics?.[index]?.[key]?.showError === true
                ) {
                    tempObj[key] = errors?.kafkatopics?.[index]?.[key];
                }
                // if (
                //     key === 'writeAccessUser' &&
                //     topic?.isWriteAccess === 'Yes' &&
                //     (topic[key] === null || topic[key] === '' || topic[key] === undefined) &&
                //     errors?.kafkatopics?.[index]?.[key] &&
                //     errors?.kafkatopics?.[index]?.[key]?.showError === false
                // ) {
                //     tempObj[key] = { type: 'notrequired' };
                // }

                if (
                    key === 'readAccessUser' &&
                    topic?.isReadAccess === 'Yes' &&
                    (topic[key] !== null || topic[key] !== '') &&
                    errors?.kafkatopics?.[index]?.[key] &&
                    errors?.kafkatopics?.[index]?.[key]?.showError === true
                ) {
                    tempObj[key] = errors?.kafkatopics?.[index]?.[key];
                }
                if (
                    key === 'readAccessGroupOwner' &&
                    topic?.isReadAccess === 'No' &&
                    topic?.isConsumerGroup === 'Yes' &&
                    (topic[key] === null || topic[key] === '')
                ) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                if (
                    key === 'transactionprefixtext' &&
                    topic?.isTransactionalId === 'Yes' &&
                    (topic?.transactionprefixtext === '' || topic?.transactionprefixtext === null)
                ) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                if (
                    key === 'transactionalPrincipalId' &&
                    topic?.isTransactionalId === 'Yes' &&
                    (topic?.transactionalPrincipalId === '' || topic?.transactionalPrincipalId === null)
                ) {
                    tempObj[key] = { type: 'required', message: `${key} should not be null` };
                }
                // if (submit) {
                if (
                    key === 'writeAccessUser' &&
                    topic?.writeAccessUser !== null &&
                    topic?.writeAccessUser !== '' &&
                    (topic?.writeAccessUserValid === false || topic?.writeAccessUserValid === 'notValidated')
                ) {
                    if (errors?.kafkatopics?.[index]?.[key]) {
                        tempObj[key] = errors?.kafkatopics?.[index]?.[key];
                    } else {
                        tempObj[key] = { type: 'required', message: `${key} should not be null` };
                    }
                }

                if (
                    key === 'readAccessUser' &&
                    topic?.readAccessUser !== null &&
                    topic?.readAccessUser !== '' &&
                    (topic?.readAccessUserValid === false || topic?.readAccessUserValid === 'notValidated')
                ) {
                    if (errors?.kafkatopics?.[index]?.[key]) {
                        tempObj[key] = errors?.kafkatopics?.[index]?.[key];
                    } else {
                        tempObj[key] = { type: 'required', message: `${key} should not be null` };
                    }
                }
                // }
                return tempObj;
            });
            errorTopicArray[index] = tempObj;
            return errorTopicArray;
        });

        newErrors = { ...newErrors, kafkatopics: errorTopicArray };
    }
    return newErrors;
};

export const handleCloneStatus = (topic, errors, index) => {
    const tempObj = {};
    Object.keys(topic)?.map((key) => {
        if (
            (topic[key] === '' || topic[key] === null) &&
            ![
                'readAccessUser',
                'transactionprefixtext',
                'writeAccessUser',
                'readAccessGroupOwner',
                'writeAccessGroupOwner',
                'transactionalPrincipalId',
                'disableReadServiceAccOwner',
                'disableWriteServiceAccOwner',
                'readAccessUserValid',
                'writeAccessUserValid',
                'topicName',
                'isReadAccess',
            ]?.includes(key)
        ) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        if (key === 'topicName' && (topic[key] === '' || topic[key] === null)) {
            tempObj[key] = { type: 'required', errorMessage: `${key} should not be null` };
        }
        if (key === 'isReadAccess' && topic?.isConsumerGroup === 'Yes' && (topic[key] === '' || topic[key] === null)) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        if (key === 'topicName' && errors?.kafkatopics?.[index]?.[key]?.showError === true) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        } else if (key === 'topicName' && errors?.kafkatopics?.[index]?.[key]?.errorMessage === '') {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        } else if (key === 'topicName' && errors?.kafkatopics?.[index]?.[key]?.showError === false && topic?.[key] === '' && topic?.[key] === null) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        if (key === 'partitions') {
            if (topic[key] == null || topic[key] === '') {
                tempObj[key] = { type: 'required', message: `${key} should not be null` };
            }
        }
        if (key === 'retention') {
            if (topic[key]?.retention == null || topic[key]?.retention === '' || topic[key]?.retentionDetails === null || topic[key]?.retentionDetails === '') {
                tempObj[key] = { type: 'required', message: `${key} should not be null` };
            }
        }
        if (key === 'isConsumerGroup' && topic[key] === 'Yes') {
            const grpObj = topic?.consumerGroups?.every((grp) => grp?.value === null || grp?.value === '');
            if (grpObj) {
                tempObj[key] = { type: 'required', message: `${key} should not be null` };
            }
        }

        if (key === 'writeAccessGroupOwner' && topic?.isWriteAccess === 'No' && (topic[key] === null || topic[key] === '')) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }

        if (key === 'writeAccessUser' && topic?.isWriteAccess === 'Yes' && (topic?.writeAccessUser === null || topic?.writeAccessUser === '')) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }

        if (key === 'readAccessUser' && topic?.isReadAccess === 'Yes' && (topic[key] === null || topic[key] === '')) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        if (
            key === 'writeAccessUser' &&
            topic?.isWriteAccess === 'Yes' &&
            (topic[key] !== null || topic[key] !== '') &&
            errors?.kafkatopics?.[index]?.[key] &&
            errors?.kafkatopics?.[index]?.[key]?.showError === true
        ) {
            tempObj[key] = errors?.kafkatopics?.[index]?.[key];
        }
        if (
            key === 'readAccessUser' &&
            topic?.isReadAccess === 'Yes' &&
            (topic[key] !== null || topic[key] !== '') &&
            errors?.kafkatopics?.[index]?.[key] &&
            errors?.kafkatopics?.[index]?.[key]?.showError === true
        ) {
            tempObj[key] = errors?.kafkatopics?.[index]?.[key];
        }
        if (key === 'readAccessGroupOwner' && topic?.isReadAccess === 'No' && topic?.isConsumerGroup === 'Yes' && (topic[key] === null || topic[key] === '')) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        if (
            key === 'transactionprefixtext' &&
            topic?.isTransactionalId === 'Yes' &&
            (topic?.transactionprefixtext === '' || topic?.transactionprefixtext === null)
        ) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        if (
            key === 'transactionalPrincipalId' &&
            topic?.isTransactionalId === 'Yes' &&
            (topic?.transactionalPrincipalId === '' || topic?.transactionalPrincipalId === null)
        ) {
            tempObj[key] = { type: 'required', message: `${key} should not be null` };
        }
        // if (submit) {
        if (
            key === 'writeAccessUser' &&
            topic?.writeAccessUser !== null &&
            topic?.writeAccessUser !== '' &&
            (topic?.writeAccessUserValid === false || topic?.writeAccessUserValid === 'notValidated')
        ) {
            if (errors?.kafkatopics?.[index]?.[key]) {
                tempObj[key] = errors?.kafkatopics?.[index]?.[key];
            } else {
                tempObj[key] = { type: 'required', message: `${key} should not be null` };
            }
        }

        if (
            key === 'readAccessUser' &&
            topic?.readAccessUser !== null &&
            topic?.readAccessUser !== '' &&
            (topic?.readAccessUserValid === false || topic?.readAccessUserValid === 'notValidated')
        ) {
            if (errors?.kafkatopics?.[index]?.[key]) {
                tempObj[key] = errors?.kafkatopics?.[index]?.[key];
            } else {
                tempObj[key] = { type: 'required', message: `${key} should not be null` };
            }
        }

        return tempObj;
    });
    return { isValid: Object.keys(tempObj)?.length > 0, ErrorObj: tempObj };
};
export default {};
