/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';

const TargetSubscription = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setTargetSubscription = (targetSubscription, targetSubscriptionDetail) => {
        setOrder({ ...order, targetSubscription, targetSubscriptionDetail });
    };
    const targetSubscriptionApi = useMutation(() => axios.get(`${endPoints.ssp.day2OperationPass.targetSub}`));
    const seldValueExistsInOptions = (targetSubscriptionValue) => options.find((option) => targetSubscriptionValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setTargetSubscription(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (targetSubscriptionApi?.isSuccess) {
            if (targetSubscriptionApi?.data?.data)
                setOptions(targetSubscriptionApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [targetSubscriptionApi?.isSuccess]);

    React.useEffect(() => {
        targetSubscriptionApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.targetSubscription && order?.isTouched) setIsTouched(true);
    }, [order?.targetSubscription, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="targetSubscription"
                label="Target Subscription *"
                value={order?.targetSubscription || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={targetSubscriptionApi.isLoading}
                isLoading={targetSubscriptionApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(TargetSubscription);
