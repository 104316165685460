import React, { useState, Suspense, lazy } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import toast from 'react-hot-toast';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import {
    orderPayload,
    showPreviewOrder,
    toggleModal,
    validVSForm,
    isDivisionRequired,
    isAppRegionRequired,
    virtualServicesType,
    isClusterLoading,
    isNamespaceLoading,
    isDisplayProgram,
    validationErrorReport,
    validationStatus,
    isDuplicateCustomDomainName,
    certCreationRequired,
    costEstimateData,
    isValidOwners,
} from 'pages/provisioning/virtual-service/store';
import { isReadyToSubmit } from 'pages/provisioning/virtual-service/utils';
import { resetOrder, resetToggleModal, resetVSForm, resetValidOwners } from 'pages/provisioning/virtual-service/utils/resetFunction';
import { getOrderPayload } from 'pages/provisioning/virtual-service/utils/getFunction';

const ErrorBanner = lazy(() => import('components/ErrorBanner'));
const DialogBox = lazy(() => import('pages/provisioning/virtual-service/dialog-box'));

export const SubmitForValidation = () => {
    const { cartItemId, operation } = useParams();
    const history = useHistory();
    const [order, setOrder] = useRecoilState(orderPayload);
    const [modal, setToggleModal] = useRecoilState(toggleModal);
    const [validForm, setVSForm] = useRecoilState(validVSForm);
    const [divisionReq, setDivisionReq] = useRecoilState(isDivisionRequired);
    const [appRegionReq, setAppRegionReq] = useRecoilState(isAppRegionRequired);
    const [, setClusterLoading] = useRecoilState(isClusterLoading);
    const [namespaceLoading, setNamespaceLoading] = useRecoilState(isNamespaceLoading);
    const [, setDisplayProgram] = useRecoilState(isDisplayProgram);
    const vsTypes = useRecoilValue(virtualServicesType);
    const [, setShowPreview] = useRecoilState(showPreviewOrder);
    const [, setValidationStatus] = useRecoilState(validationStatus);
    const [validationError, setValidationError] = useRecoilState(validationErrorReport);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const [isRequired, setIsRequired] = useRecoilState(certCreationRequired);
    const [, setIsDuplicate] = useRecoilState(isDuplicateCustomDomainName);
    const [, setCostEstimateData] = useRecoilState(costEstimateData);
    const [validOwners, setValidOwners] = useRecoilState(isValidOwners);

    const submitOrderApi = useMutation((payload) => axios.post(endPoints.virtualService.submitOrder, payload));
    const editOrderApi = useMutation((payload) => axios.put(`${endPoints.virtualService.submitOrder}/${cartItemId}`, payload));

    React.useEffect(() => {
        if (submitOrderApi?.data?.data) {
            setToggleModal({
                ...modal,
                isSuccessOpen: true,
                orderId: submitOrderApi?.data?.data?.orderId,
            });
            toast.dismiss();
        }
    }, [submitOrderApi?.isSuccess]);

    React.useEffect(() => {
        if (editOrderApi?.data?.data) {
            setToggleModal({
                ...modal,
                isSuccessOpen: true,
                orderId: editOrderApi?.data?.data?.orderId,
            });
            toast.dismiss();
        }
    }, [editOrderApi?.isSuccess]);

    const handleSubmit = async () => {
        const payload = await getOrderPayload(order, vsTypes, isRequired);

        // eslint-disable-next-line
        try {
            if (operation === 'edit') editOrderApi.mutate(payload);
            else submitOrderApi.mutate(payload);
        } catch (e) {
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };
    const checkVSValidationError = () => {
        return validationError?.some((value) => value?.error === true);
    };
    const handleValidation = () => {
        if (!isReadyToSubmit({ order, divisionReq, appRegionReq, validForm, isRequired, validOwners }) && !checkVSValidationError()) {
            handleSubmit();
        } else {
            setOrder({ ...order, isTouched: true });
            handleClickErrorBanner();
        }
    };

    const handleReset = () => {
        setOrder(resetOrder);
        setShowPreview(false);
        setToggleModal(resetToggleModal);
        setVSForm(resetVSForm);
        setDivisionReq(false);
        setAppRegionReq(false);
        setClusterLoading(false);
        setNamespaceLoading(false);
        setDisplayProgram(false);
        setValidationStatus(null);
        setValidationError([]);
        setIsRequired(false);
        setIsDuplicate(false);
        setCostEstimateData(null);
        setValidOwners(resetValidOwners);
    };

    const getButtonText = (mode, submitOrderLoading, editOrderLoading) => {
        if (mode === 'edit') {
            return editOrderLoading ? 'Re-submitting...' : 'Resubmit for Validation';
        }
        return submitOrderLoading ? 'Submitting...' : 'Submit for Validation';
    };

    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Button
                size="medium"
                className="cmpSubmitBtn"
                onClick={() => handleValidation()}
                variant="contained"
                disabled={submitOrderApi?.isLoading || editOrderApi?.isLoading || namespaceLoading}
                color="cmpPrimary"
                data-testid="submit-for-validation-btn"
            >
                {getButtonText(operation, submitOrderApi?.isLoading, editOrderApi?.isLoading)}
            </Button>
            <DialogBox handleNewOrderBtn={handleReset} handleHomeBtn={() => history.push('/')} operation={operation} />
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

export default React.memo(SubmitForValidation);
