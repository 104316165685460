/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const ServiceEndPointSubNet = ({ index }) => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const subNetApi = useMutation((params) => axios.get(endPoints.landingZones.Subnet, { params: params }));

    const setEventHubSubNet = (subnet, subnetDetails = null) => {
        const form = { ...landZoneForm };
        try {
            const result = form.vnetSubnet.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        subnet: subnet !== '' && subnet !== null && subnet !== undefined ? subnet : null,
                        subnetDetails: subnetDetails !== '' && subnetDetails !== null && subnetDetails !== undefined ? subnetDetails : null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    const seldValueExistsInOptions = (value) => options.find((option) => value === option?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEventHubSubNet(selected?.label, selected);
        } else {
            setEventHubSubNet();
        }
    };

    React.useEffect(() => {
        if (subNetApi?.isSuccess) {
            setOptions(subNetApi?.data?.data?.length && subNetApi?.data?.data[0].subnets.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [subNetApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const subnet = options.find((value) => lzModal?.lzDetails?.config?.vnetSubnet?.[index]?.subnet === value?.label);
            if (subnet) setEventHubSubNet(subnet?.label, subnet);
        }
    }, [options]);

    React.useEffect(() => {
        if (landZoneForm?.vnetSubnet?.[index]?.vNet && landZoneForm?.vnetSubnet?.[index]?.networkSubscriptionDetails && !options?.length) {
            const params = { name: landZoneForm?.vnetSubnet?.[index]?.vNet, subscriptionName: landZoneForm?.vnetSubnet?.[index]?.networkSubscription };
            subNetApi.mutate(params);
        }
    }, [landZoneForm?.vnetSubnet]);

    return (
        <Box bgcolor="white">
            <Select
                key="SubNet"
                value={landZoneForm?.vnetSubnet?.[index]?.subnet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subNetApi.isLoading || landZoneForm?.dataClassification === 'Restricted'}
                isLoading={subNetApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Subnet"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

ServiceEndPointSubNet.propTypes = {
    index: PropTypes.number,
};

ServiceEndPointSubNet.defaultProps = {
    index: 0,
};

export default React.memo(ServiceEndPointSubNet);
