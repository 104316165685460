/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';
import { endPoints } from 'api/endpoints';

const Type = () => {
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const [isTouched, setIsTouched] = React.useState(false);
    // eslint-disable-next-line
    const typeApi = useMutation(() => axios.get(`${endPoints.landingZones.type}`));

    const setType = (type = null) => {
        setLandingZoneForm({
            ...landingZoneForm,
            type,
        });
    };

    React.useEffect(() => {
        if (typeApi?.isSuccess) {
            setOptions(typeApi?.data?.map((app) => ({ label: app.value, value: app.value, data: app })));
        }
    }, [typeApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.type) {
            const type = options.find((value) => landingZoneForm?.type === value?.label);
            setType(type?.label);
        }
        if (options?.length && options?.length === 1 && !landingZoneForm?.type) {
            setLandingZoneForm({ ...landingZoneForm, type: options[0]?.label });
        }
    }, [options]);

    React.useEffect(() => {
        setOptions([]);
        typeApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!landingZoneForm?.type && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.type, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="alb-type"
                value={landingZoneForm?.type}
                options={options}
                disabled
                isLoading={typeApi.isLoading}
                label="Type *"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Type);
