import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const Cost = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const handleValueChange = (value) => {
        if (value !== order?.cost) {
            setOrder({
                ...order,
                cost: `$${value}`,
            });
        }
    };

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.cost) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (order?.skuConfig && order?.maxIops && order?.ram && order?.dataDiskSupport && order?.premiumDiskSupport) {
            handleValueChange(Math.floor(Math.random().toFixed(2) * 1000)?.toString());
        }
    }, [order?.skuConfig, order?.maxIops, order?.ram, order?.dataDiskSupport, order?.premiumDiskSupport]);
    React.useEffect(() => {
        if (!order?.cost && order?.isTouched) setIsTouched(true);
    }, [order?.cost, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                autoComplete="off"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Cost / Month *"
                value={order?.cost || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
                disabled
            />
        </Stack>
    );
};

export default Cost;
