import React from 'react';
import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import { Stack, Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import styles from './style';

const SourceAssignmentGrp = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [helperMsg, setHelperMsg] = React.useState(false);
    const [showHelperText, setHelperText] = React.useState(false);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);

    const handleOnSelectChange = (selected) => {
        if (selected !== order?.sourceAssignmentGrp) {
            setOrder({
                ...order,
                sourceAssignmentGrp: selected,
            });
        }
    };

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.sourceAssignmentGrp) || showHelperText) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (
            order?.sourceAssignmentGrp &&
            order?.sourceAssignmentGrp !== '' &&
            order?.sourceAssignmentGrp !== undefined &&
            !/^([a-zA-Z0-9-_ ]){3,255}$/.test(order?.sourceAssignmentGrp)
        ) {
            setHelperText(true);
            setHelperMsg('Invalid Source Assignment Group.');
            setValidForm({ ...validForm, isSourceAssignmentGrpValid: true });
        } else {
            setValidForm({ ...validForm, isSourceAssignmentGrpValid: false });
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.sourceAssignmentGrp]);
    React.useEffect(() => {
        if (!order?.sourceAssignmentGrp && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.sourceAssignmentGrp, order?.isTouched]);
    return (
        <Stack direction="row" spacing={1} sx={styles.stack} data-testid="source-assignment-text">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Source Assignment Group *"
                autoComplete="off"
                error={isTouched || showHelperText}
                helperText={showHelperText && <span style={styles?.helpText}>{helperMsg}</span>}
                value={order?.sourceAssignmentGrp || ''}
                onChange={(e) => handleOnSelectChange(e.target.value)}
                className={validateInput()}
            />
            <HelpImg
                title={
                    <>
                        <Box sx={styles.infoStyle.wrapper}>
                            <Box sx={styles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={styles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            <span style={styles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>2) Min.: 3 chars to Max.: 255 chars</Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            3) Can contain hyphen(-) and underscore(_) as special chars.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={styles.infoStyle.bottomDividerRule}>
                                        <Typography sx={styles.infoStyle.typographyStyle}> Example: cmpblobGroup</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

export default SourceAssignmentGrp;
