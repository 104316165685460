import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { getMemberAccessForPreview } from 'pages/project-onboarding/utils/getFunctions';
import Style from 'pages/project-onboarding/style';

const ProjectOnboardingMembersDetails = (membersDetails) => {
    const roleData = {
        projectAdmin: membersDetails?.membersDetails?.projectAdmin || [],
        fs: membersDetails?.membersDetails?.fs || [],
        testPlan: membersDetails?.membersDetails?.testPlan || [],
        sonarQube: membersDetails?.membersDetails?.sonarQube || [],
        artifactory: membersDetails?.membersDetails?.artifactory || [],
    };

    const resoureDisplay = (title, haveAccess) => {
        return (
            <Box sx={Style?.preview?.memberAccess?.subWrapper} key={title}>
                <Box minWidth="100px" marginLeft="130px">
                    {title}
                </Box>
                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                <Box sx={Style?.preview?.memberAccess?.value}>{haveAccess && <DoneIcon sx={Style?.preview?.memberAccess?.doneIcon} />}</Box>
            </Box>
        );
    };

    if (getMemberAccessForPreview(roleData)?.length === 0) return <Box color="#000000">No member requested.</Box>;
    return (
        <Box sx={Style?.preview?.orderMemberWrapper}>
            {getMemberAccessForPreview(roleData)?.map((member) => (
                <Grid container sx={Style?.preview?.memberAccess?.orderMemberContainer}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="column" spacing={2}>
                            <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                                <Box minWidth="95px">GPID</Box>
                                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                                <Box sx={Style?.preview?.memberAccess?.value}>{member?.gpid}</Box>
                            </Box>
                            <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                                <Box minWidth="95px">Member Name</Box>
                                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                                <Box sx={Style?.preview?.memberAccess?.value}>{member?.name}</Box>
                            </Box>
                            <Box sx={Style?.preview?.memberAccess?.subWrapper}>
                                <Box minWidth="95px">Email ID</Box>
                                <Box sx={Style?.preview?.memberAccess?.colon}>:</Box>
                                <Box sx={Style?.preview?.memberAccess?.value}>{member?.email === 'null' ? '-' : member?.email}</Box>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="column" spacing={2}>
                            {resoureDisplay('Project Admin', member?.projectAdmin)}
                            {resoureDisplay('SonarQube', member?.sonarQube)}
                            {resoureDisplay('Artifactory', member?.artifactory)}
                            {resoureDisplay('Fortify and Synk', member?.fs)}
                            {resoureDisplay('Test Plan', member?.testPlan)}
                        </Stack>
                    </Grid>
                </Grid>
            ))}
        </Box>
    );
};

export default ProjectOnboardingMembersDetails;
