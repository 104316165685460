import { atom } from 'recoil';

export const checkOrderIsLoading = atom({
    key: 'checkOrderIsLoading',
    default: true,
});

export const orderTriageToggle = atom({
    key: 'orderTriageToggle',
    default: false,
});
export const orderServiceResourceToggle = atom({
    key: 'orderServiceResourceToggle',
    default: {
        title: '',
        events: [],
        isOpen: false,
    },
});

export const orderManagerListToggle = atom({
    key: 'orderManagerListToggle',
    default: false,
});

export const orderDetailsToggle = atom({
    key: 'orderDetailsToggle',
    default: false,
});

export const orderList = atom({
    key: 'orderList',
    default: [],
});

export const totalOrders = atom({
    key: 'totalOrders',
    default: 0,
});

export const pageNumber = atom({
    key: 'pageNumber',
    default: 1,
});

export const catalogProviders = atom({
    key: 'catalogProviders',
    default: [],
});

export const selectedCatalogProvider = atom({
    key: 'selectedCatalogProvider',
    default: null,
});

export const orderStatuses = atom({
    key: 'orderStatuses',
    default: [],
});

export const selectOrder = atom({
    key: 'selected_order',
    default: undefined,
});

export const orderListFilter = atom({
    key: 'orderListFilter',
    default: null,
});

export const catalogServiceCode = atom({
    key: 'catalogServiceCode',
    default: [],
});

export const requestorOptions = atom({
    key: 'requestorOptions',
    default: [],
});
export const applicationOptions = atom({
    key: 'applicationOptions',
    default: [],
});
