// eslint-disable-next-line import/no-cycle
import { useAxios } from 'hooks';
import { useQuery } from 'react-query';

const useRequest = (options, manualFetch = true, refetchInterval = false) => {
    const { key, method = 'get', url, data, params } = options;
    const axios = useAxios();
    const service = async () => {
        const payload = data;
        if (params) payload.params = params;
        const response = await axios[method](url, payload);
        return response;
    };

    return useQuery(key, () => service(), { enabled: manualFetch, refetchInterval });
};

export default useRequest;
