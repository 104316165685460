import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const Performance = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const setPerformanceValue = (performance = 'Standard') => {
        setOrder({
            ...order,
            performance,
            throughputUnits: '1',
            isAutoInflate: 'no',
            autoInflateMaxThroughputUnits: null,
            processingUnits: '1',
            eventHubDetails: [
                {
                    eventHubName: '',
                    partitionCount: 1,
                    retention: null,
                    isEventHubTouched: false,
                    consumerGroupList: [{ value: '' }],
                },
            ],
        });
    };

    const handleOnSelectChange = (selected) => {
        setPerformanceValue(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="performanceRadioGrp">
                    Performance
                </Box>
                <CustomRadioGroup
                    aria-label="performance"
                    name="performanceRadioBtnGroup"
                    row
                    value={order?.performance || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Standard" control={<CustomRadio />} label="Standard" disabled={order?.nsExist === 'yes' && order?.namespaceName} />
                    <FormControlLabel
                        value="Premium"
                        sx={ProvisioningStyles.radioFields.radioBtnNoForm}
                        control={<CustomRadio />}
                        label="Premium"
                        disabled={order?.nsExist === 'yes' && order?.namespaceName}
                    />
                </CustomRadioGroup>
            </FormControl>
            <HelpImg title="Performance should be Standard or Premium" />
        </Stack>
    );
};

export default Performance;
