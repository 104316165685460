import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import {
    orderPayload,
    validVSForm,
    selectedVSService,
    validationStatus,
    duplicateVirtualService as duplicateVirtualServiceData,
    virtualServicesType,
    vsRandomValue,
} from 'pages/provisioning/virtual-service/store';
import { getSelectedVSServiceTypeIndex, virtualServiceTypes } from 'pages/provisioning/virtual-service/utils';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import InfoImg from 'components/info-img';

export const VirtualServiceName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [helperText, setHelperText] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [validationErrorStatus] = useRecoilState(validationStatus);
    const [duplicateVirtualService] = useRecoilState(duplicateVirtualServiceData);
    const [vsTypes] = useRecoilState(virtualServicesType);
    const [randomValue] = useRecoilState(vsRandomValue);

    const isSingleVSType = [virtualServiceTypes?.[0]?.code, virtualServiceTypes?.[1]?.code].includes(
        getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value
    );

    const handleValueChange = (virtualServiceName) => {
        if (!isSingleVSType) {
            setOrder((prevOrder) => ({
                ...prevOrder,
                aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                    if (index === selectedVS) {
                        return {
                            ...item,
                            virtualServiceName,
                        };
                    }
                    return item;
                }),
            }));
        }
    };
    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.virtualServiceName &&
            order?.aksVirtualService?.[selectedVS]?.virtualServiceName !== null &&
            order?.aksVirtualService?.[selectedVS]?.virtualServiceName !== '' &&
            order?.aksVirtualService?.[selectedVS]?.virtualServiceName !== undefined &&
            !/^[a-zA-Z0-9_-]{1,255}-vs$/.test(order?.aksVirtualService?.[selectedVS]?.virtualServiceName)
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isInvalidServiceName: true });
        } else {
            setHelperText(false);
            setValidForm({ ...validForm, isInvalidServiceName: false });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.virtualServiceName]);
    React.useEffect(() => {
        if (!order?.aksVirtualService?.[selectedVS]?.virtualServiceName && order?.isConfigureTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.aksVirtualService?.[selectedVS]?.virtualServiceName, order?.isConfigureTouched]);

    const getVSValidationStatus = () => {
        if (validationErrorStatus?.length > 0) {
            const result = validationErrorStatus?.filter((error) => {
                if (error?.virtualServiceName === order?.aksVirtualService?.[selectedVS]?.virtualServiceName) {
                    return error?.msg;
                }
                return false;
            });
            return result;
        }
        return false;
    };

    const getErrorMessage = () => {
        const validationError = getVSValidationStatus();
        if (helperText) {
            return 'VS name should have alphanumeric value, can contain underscore(_) and hyphen(-) as special chars and should end with -vs.';
        }
        if (!helperText && validationError?.length > 0 && validationError?.[0]?.msg.length > 0) {
            return (
                <>
                    {validationError?.map((error, errorIndex) => (
                        <React.Fragment key={`error-${errorIndex}`}>
                            {error?.msg?.map((msg, index) => (
                                <p key={index}>{msg}</p>
                            ))}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return '';
    };

    React.useEffect(() => {
        getVSValidationStatus();
        getErrorMessage();
    }, []);
    const validateInput = () => {
        if ((isTouched && order?.isConfigureTouched && !order?.aksVirtualService?.[selectedVS]?.virtualServiceName) || helperText || getErrorMessage()) {
            return 'validate';
        }
        return '';
    };
    const getToolTip = () => {
        return (
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Virtual Service Name:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            A Virtual Service sets rules for routing traffic based on host addresses, specifying criteria for matching traffic
                                            of a specific protocol and directing it to named destination services in the registry.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle} />
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>1) Virtual Service Name should be unique.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>2) It can have alpha-numeric value.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            3) It can only contain underscore(_) and hyphen(-) as special chars.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>4) It should end with -vs.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            {`pep-cmp-dev-webdeploy-${randomValue}-vs`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };

    const getVSValue = () => {
        if (isSingleVSType) {
            if (!validForm?.isInvalidServiceName) return order?.aksVirtualService?.[selectedVS]?.virtualServiceName;
            return '';
        }
        return order?.aksVirtualService?.[selectedVS]?.virtualServiceName;
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="vs-name-box">
            <CssTextField
                sx={
                    isSingleVSType
                        ? {
                              width: (theme) => theme.spacing('100%'),
                              '& .MuiFormHelperText-root': {
                                  marginTop: (theme) => theme.spacing('-2'),
                              },
                              '& label.MuiInputLabel-shrink': {
                                  color: '#0047BA !important',
                              },
                          }
                        : {}
                }
                data-testid="vs-name"
                size="small"
                fullWidth
                autoComplete="off"
                label="Virtual Service Name *"
                disabled={isSingleVSType}
                error={
                    helperText ||
                    isTouched ||
                    getErrorMessage() ||
                    duplicateVirtualService?.map((item) => item === order?.aksVirtualService?.[selectedVS]?.virtualServiceName).includes(true)
                }
                helperText={
                    getErrorMessage() ||
                    (duplicateVirtualService?.map((item) => item === order?.aksVirtualService?.[selectedVS]?.virtualServiceName).includes(true) &&
                        'Virtual service name is already used in another Virtual service, Please use unique one')
                }
                value={getVSValue() || ''}
                onChange={(e) => handleValueChange(e?.target?.value)}
                className={validateInput()}
            />
            {getToolTip()}
        </Box>
    );
};
export default React.memo(VirtualServiceName);
