import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import LandingZoneStyle, { CssTextField } from '../../LandingZoneStyle';

const filter = createFilterOptions();

const ServiceEndPointVNet = ({ index }) => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);

    const vnetGetApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.Vnet}/location/${payload?.region}/subscription/${payload?.subscriptionId}`, payload)
    );
    const setEventHubVnet = (vNet = null, vNetDetails = null) => {
        const form = { ...landZoneForm };
        try {
            const result = form.vnetSubnet.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        vNet: vNet !== '' || vNet !== null ? vNet : null,
                        vNetDetails: vNetDetails !== null ? vNetDetails : null,
                        subnet: null,
                        subnetDetails: null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    const seldValueExistsInOptions = (value) => options.find((option) => value === option?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEventHubVnet(selected?.label, selected);
        } else {
            setEventHubVnet();
        }
    };

    React.useEffect(() => {
        if (
            options?.length &&
            options?.length > 0 &&
            lzModal?.lzDetails &&
            (!lzModal?.lzDetails?.config?.vnetSubnet?.[index]?.vnet?.name || !landZoneForm?.vnetSubnet?.[index]?.vNet)
        ) {
            const vnet = options?.find((value) => lzModal?.lzDetails?.config?.vnetSubnet?.[index]?.vnet?.name === value?.label);
            if (vnet) setEventHubVnet(vnet?.label, vnet);
        }
    }, [options]);

    React.useEffect(() => {
        if (vnetGetApi?.isSuccess) {
            if (vnetGetApi?.data?.data) {
                const combinedValue = vnetGetApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, resourceId: app?.resourceId }));
                setOptions(combinedValue);
            }
        }
    }, [vnetGetApi?.isSuccess]);
    React.useEffect(() => {
        if (
            landZoneForm.region &&
            landZoneForm?.vnetSubnet[index]?.networkSubscriptionDetails &&
            (!options?.length || !landZoneForm?.vnetSubnet[index]?.vNet)
        ) {
            vnetGetApi.mutate({ region: landZoneForm?.regionDetails?.value, subscriptionId: landZoneForm?.vnetSubnet[index]?.networkSubscriptionDetails?.id });
        }
    }, [landZoneForm.region, landZoneForm?.vnetSubnet[index]?.networkSubscriptionDetails]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Select
                    key="VNet"
                    noOptionsText="No options"
                    loading={vnetGetApi?.isLoading}
                    forcePopupIcon={false}
                    disabled={vnetGetApi?.isLoading || landZoneForm?.dataClassification === 'Restricted'}
                    value={landZoneForm?.vnetSubnet?.[index]?.vNet}
                    onChange={(_event, newValue) => {
                        if (typeof newValue === 'string') {
                            handleOnSelectChange(newValue);
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            handleOnSelectChange(newValue.inputValue);
                        } else {
                            handleOnSelectChange(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demoVnet"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={LandingZoneStyle.select}
                    // freeSolo
                    disableClearable={false}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="VNet"
                            InputProps={{
                                ...params.InputProps,
                            }}
                            fullWidth
                        />
                    )}
                />
            </Stack>
        </>
    );
};

ServiceEndPointVNet.propTypes = {
    index: PropTypes.number,
};

ServiceEndPointVNet.defaultProps = {
    index: 0,
};

export default React.memo(ServiceEndPointVNet);
