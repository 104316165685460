/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import CreateLandingZone from 'pages/app-profiles/landing-zones/CreateLandingZone';
import LandingZoneList from 'pages/app-profiles/landing-zones/LandingZoneList';
import { useParams } from 'react-router-dom';
import ConfirmDeleteModal from 'pages/app-profiles/landing-zones/ConfirmDeleteModal';
import LandingZoneStyle from 'pages/app-profiles/landing-zones/LandingZoneStyle';

const LandingZonePage = () => {
    const { appProfileName } = useParams();
    return (
        <>
            <Helmet>
                <title>{`Landing Zones - ${appProfileName}`}</title>
            </Helmet>
            <CreateLandingZone />
            <ConfirmDeleteModal />
            <Box sx={LandingZoneStyle?.wrapper}>
                <LandingZoneList />
            </Box>
        </>
    );
};

export default LandingZonePage;
