import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import { updateRoles } from 'pages/sso-okta/store/selector';
import SsoOktaStyles from '../../style';
import HelpImg from '../../../provisioning/_components/helpImg';

const Role = ({ indexVal }) => {
    const [order] = useRecoilState(OrderPayload);
    const [isTouched, setIsTouched] = React.useState(false);
    const [helperText, setHelperText] = React.useState(false);
    const [helperMessage, setHelperMessage] = React.useState('');
    const updateGroup = useSetRecoilState(updateRoles);
    const handleOnSelectChange = (selectedValue, index, key) => {
        updateGroup({ selectedValue, index, key });
    };
    const isDuplicateRole = (arr, input, ignoreIndex) => {
        return arr.some((item, index) => {
            if (index === ignoreIndex || !item || item.role.trim() === '') {
                return false;
            }
            return item.role === input;
        });
    };
    const validateInput = () => {
        if ((isTouched && order?.isRoleFormTouched && !order?.roles?.[indexVal]?.role) || helperText) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (
            order?.roles?.[indexVal]?.role &&
            order?.roles?.[indexVal]?.role !== null &&
            order?.roles?.[indexVal]?.role !== '' &&
            order?.roles?.[indexVal]?.role !== undefined &&
            !/^[a-zA-Z0-9_-\s\\(\\)]{4,64}$/.test(order?.roles?.[indexVal]?.role)
        ) {
            setHelperText(true);
            setHelperMessage('Role is invalid.');
        } else if (isDuplicateRole(order?.roles, order?.roles?.[indexVal]?.role, indexVal)) {
            setHelperText(true);
            setHelperMessage('Duplicate roles are not allowed.');
        } else {
            setHelperText(false);
            setHelperMessage('');
        }
    }, [order?.roles]);
    React.useEffect(() => {
        if (!order?.roles?.[indexVal]?.role && order?.isRoleFormTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.roles?.[indexVal]?.role, order?.isRoleFormTouched]);
    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <CssTextField
                    key={`ssoRole${indexVal}`}
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                    }}
                    autoComplete="off"
                    label="Role *"
                    error={helperText}
                    helperText={helperText && <span>{helperMessage}</span>}
                    value={order?.roles?.[indexVal]?.role || ''}
                    onChange={(e) => handleOnSelectChange(e.target.value, indexVal, 'inputChange')}
                    className={validateInput()}
                />
                <HelpImg
                    title={
                        <>
                            <Box sx={SsoOktaStyles.infoStyle.wrapper}>
                                <Box sx={SsoOktaStyles.infoStyle.namingRuleWrapper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs sx={SsoOktaStyles.infoStyle.topLeftNamingRule}>
                                            <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>
                                                <span style={SsoOktaStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                            </Typography>
                                            <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                            <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>2) Min.: 4 chars to Max.: 64 chars</Typography>
                                            <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}>
                                                3) Can contain underscore(_), hyphen(-) and parenthesis() as special characters
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={SsoOktaStyles.infoStyle.bottomDividerRule}>
                                            <Typography sx={SsoOktaStyles.infoStyle.typographyStyle}> Example: Admin</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    }
                />
            </Box>
        </>
    );
};

Role.propTypes = {
    indexVal: PropTypes.number,
};

Role.defaultProps = {
    indexVal: 0,
};

export default React.memo(Role);
