import { styled } from '@mui/material/styles';
import { TextField, RadioGroup, Radio } from '@mui/material';

const ProvisioningStyles = {
    card: {
        paper: {
            padding: (theme) => theme.spacing(2),
            background: (theme) => theme?.palette?.gray?.lighter,
            height: (theme) => theme.spacing('100%'),
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },

        box: { mb: (theme) => theme.spacing(0.375) },
        typography: { marginBottom: (theme) => theme.spacing(0.25) },
    },
    provisioning_wrapper: {
        // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    provisioningBox: {
        // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
            mt: (theme) => theme.spacing(3),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: 26 },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
    },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    TotEstCost: {
        wrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        totEstMessage: {
            fontSize: (theme) => theme.spacing(1.75),
            background: 'linear-gradient(to top, #29323C, #485563)',
            color: (theme) => theme.palette.cyan['90'],
            borderRadius: (theme) => theme.spacing(0.5),
            marginRight: (theme) => theme.spacing(0.35),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: (theme) => theme.spacing(0.5),
            py: (theme) => theme.spacing(0.3),
        },
        totEstCost: {
            fontSize: (theme) => theme.spacing(2),
            fontWeight: (theme) => theme.typography.fontWeightBold,
        },
        info: { color: (theme) => theme.palette.gray.light, marginRight: (theme) => theme.spacing(0.35) },
        submitOrder: {
            background: (theme) => theme.palette.orange.main,
            color: (theme) => theme.palette.white,
            fontSize: (theme) => theme.spacing(2),
            width: (theme) => theme.spacing(18.75),
            height: (theme) => theme.spacing(4.375),
        },
        alert: {
            mt: (theme) => theme.spacing(3.125),
            background: (theme) => theme.palette.red.main,
            color: (theme) => theme.palette.white,
            '& .MuiAlert-icon': {
                color: (theme) => theme.palette.white,
            },
        },
        welcomeAlert: {
            mt: (theme) => theme.spacing(3.125),
            background: (theme) => theme?.palette?.blue?.[30],
            color: (theme) => theme.palette.white,
            '& .MuiAlert-icon': {
                color: (theme) => theme.palette.white,
            },
        },
    },
    Dialog: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        icon: { color: '#d02424' },
        backBtn: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        AgreeBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },
    application: {
        stack: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
    },
    Sector: {
        box: {
            bgcolor: (theme) => theme.palette.white,
        },
    },
    box: {
        bgcolor: (theme) => theme.palette.white,
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    vmType: {
        toastBoxStyle: {
            display: 'flex',
            width: '50%',
            background: '#ffffff',
            // backgroundColor:(theme) => theme.palette.white,
            color: (theme) => theme.palette.black,
            justifyContent: 'center',
            alignItems: 'center',
            padding: (theme) => theme.spacing(1),
        },
        reportProblem: {
            color: '#F99C24',
            margin: (theme) => theme.spacing(1),
        },
    },
    haDeployment: {
        toastBox: {
            display: 'flex',
            background: '#FFFFFF',
            color: (theme) => theme.palette.black,
            minWidth: '300px',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            padding: (theme) => theme.spacing(1),
        },
        icon: { color: '#F99C24', margin: (theme) => theme.spacing(1) },
    },
    dataDisk: {
        stack: { alignItems: 'center', justifyContent: 'flex-start' },
        boxWrapper: { width: '60%', bgcolor: (theme) => theme.palette.white },
        btnWrapper: { width: '40%', ml: (theme) => theme.spacing(0.25) },
        badgeWidth: { width: '100%' },
        configureBtn: {
            paddingTop: (theme) => theme.spacing(1),
            paddingBottom: (theme) => theme.spacing(1),
            height: (theme) => theme.spacing(4.641),
            fontSize: { md: (theme) => theme.spacing(1.25), lg: (theme) => theme.spacing(1.5) },
        },
    },
    infoIcon: { color: '#0047BA', fontSize: '19px', marginTop: '5px' },
    costCenter: { width: '100%' },
    availabilityZone: {
        dialogContent: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        btnWrapperBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(0.25) },
        ackldge: { marginLeft: (theme) => theme.spacing(1.25) },
    },
    currentServerName: {
        serverIcon: { color: '#2C67C6', animation: 'zoom-in-zoom-out 2s linear infinite', cursor: 'pointer', width: 15 },
        select: { width: (theme) => theme.spacing('83%') },
        infoIcon: { color: (theme) => theme.palette.gray.main },
    },
    serverRefresh: {
        textField: {
            input: {
                '&::placeholder': {
                    textAlign: { md: 'left', lg: 'left' },
                    fontSize: { md: (theme) => theme.spacing(1.625), lg: (theme) => theme.spacing(1.75) },
                    color: (theme) => theme.palette.gray[44],
                    opacity: (theme) => theme.spacing(1),
                },
            },
            '& .MuiOutlinedInput-root': {
                display: { md: 'flex' },
                flexDirection: { md: 'row', lg: 'row' },
            },
        },
        radioGroup: {
            display: 'flex',
            justifyContent: 'right',
            width: 'inherit',
            fontSize: { md: (theme) => theme.spacing(1.25) },
            mr: { md: (theme) => theme.spacing(-2.25) },
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNo: {
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    currentServerDetails: {
        span: { color: '#000000' },
    },
    disposal: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        helpText: { color: '#FF3939' },
    },
    scheduledRetirement: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        textField: {
            width: '85%',
            '& label.Mui-focused': {
                color: (theme) => theme.palette.blue.main,
            },
            '& label.MuiFormLabel-filled': {
                color: (theme) => theme.palette.blue.main,
                padding: (theme) => theme.spacing(0.2),
            },
            '& .MuiOutlinedInput-input': {
                height: (theme) => theme.spacing(1),
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                    color: (theme) => theme.palette.blue.lightest,
                },
                '&:hover fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                },
                '&.Mui-focused fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                },
            },
        },
    },
    serverRefreshContainer: {
        paper: {
            background: (theme) => theme.palette.white,
            height: 'auto',
            boxShadow: '0px 2px 8px #0000000F',
            border: '1px solid #E0E0E0',
            marginBottom: (theme) => theme.spacing(2),
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            paddingLeft: (theme) => theme.spacing(2),
            paddingRight: (theme) => theme.spacing(2),
            paddingTop: (theme) => theme.spacing(1),
            paddingBottom: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.black.dark,
            fontSize: (theme) => theme.spacing(2.5),
            fontWeight: 'medium',
            fontFamily: 'Stolzl',
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },
        box: {
            // backgroundColor: (theme) => theme.palette.blue.dark,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTopRightRadius: (theme) => theme.spacing(0.625),
            borderTopLeftRadius: (theme) => theme.spacing(0.625),
        },
        serverDetailsBtn: {
            height: (theme) => theme.spacing('35%'),
            marginRight: (theme) => theme.spacing(2),
        },
        gridContainer: { my: (theme) => theme.spacing(1), pb: (theme) => theme.spacing(2), px: (theme) => theme.spacing(1) },
    },
    confirmDD: {
        dialogContentBox: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
        dialogActionBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        dialogNoBtn: { marginLeft: (theme) => theme.spacing(1.25) },
    },
    costSplit: {
        costSplitWrap: {
            display: 'flex',
            // alignItems: 'center',
            paddingTop: (theme) => theme.spacing(2),
            paddingBottom: (theme) => theme.spacing(2),
            paddingLeft: (theme) => theme.spacing(3),
            paddingRight: (theme) => theme.spacing(3),
            flexDirection: 'column',
            // borderBottom: (theme) => `1px solid ${theme.palette.gray.low}`,
            background: 'linear-gradient(to right, #093028, #237A57)',
            width: '100%',
            marginTop: (theme) => theme.spacing(1),
        },
        flexRow: {
            display: 'flex',
            width: '100%',
            marginBottom: (theme) => theme.spacing(1),
        },
        costSplitBlock: {
            width: '30%',
            display: 'flex',
            // justifyContent: 'space-between',
            '&:first-child': {
                marginLeft: '-0.5rem',
            },
            '&:not(:first-child)': {
                paddingLeft: '0.5rem',
                // marginLeft: '-0.5rem',
            },
            '&:not(:last-child)': {
                paddingLeft: '0.5rem',
            },
        },
        costSplitBlockLeft: {
            display: 'flex',
            flexDirection: 'column',
        },
        costSplitTitle: {
            fontSize: (theme) => theme.spacing('1rem'),
            color: (theme) => theme.palette.white,
        },
        costSplitSubTitle: {
            fontSize: '0.875rem',
            color: (theme) => theme.palette.white,
        },
        estimatedCost: {
            fontSize: (theme) => theme.spacing('1.5rem'),
            marginLeft: 'auto',
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            marginRight: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.white,
        },
        noteWrapper: { display: 'flex', marginTop: (theme) => theme.spacing(1), alignItems: 'flex-start' },
        divider: { backgroundColor: (theme) => theme.palette.blue.lighter },
        loadingBox: { display: 'flex', width: '100%', height: (theme) => theme.spacing(10), alignItems: 'center', justifyContent: 'center' },
        circularProgress: { color: (theme) => theme.palette.white },
        notedevider: { width: '100%', backgroundColor: (theme) => theme.palette.blue.lighter, marginBottom: (theme) => theme.spacing(1) },
    },
    dataDiskModel: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        diskTypeWrapper: { width: '30%' },
        fileSystemWrapper: { width: '30%', pl: (theme) => theme.spacing(1) },
        diskletterWrapper: { width: '30%', px: (theme) => theme.spacing(1) },
        diskSizeWrapper: { width: '30%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        delete: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        resetBtn: { marginLeft: (theme) => theme.spacing(1.25) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    envContainer: {
        tagBtn: { textTransform: 'none' },
        badge: { marginRight: (theme) => theme.spacing(2) },
        samBtn: {
            marginRight: (theme) => theme.spacing(2),
            // borderWidth: (theme) => theme.spacing(0.125),
            // borderStyle: 'solid',
            // borderColor: (theme) => theme.palette.cyan.main,
            // color: (theme) => theme.palette.cyan[70],
        },
        applicationBtn: {
            textTransform: 'none',
            color: (theme) => theme?.palette?.blue?.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme?.palette?.blue?.main,
            '&:hover': {
                color: (theme) => theme?.palette?.cyan?.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme?.palette?.cyan?.main,
                background: 'transparent',
            },
        },
    },
    helpBox: {
        marginLeft: (theme) => theme.spacing(1),
    },
    quickConfig: {
        envTitle: { backgroundColor: '#0047BA', borderRadius: '5px 5px 0px 0px' },
        spanColor: { color: '#000000' },
        labelValue: { padding: (theme) => theme.spacing(0.5, 1) },
        selectBtn: {
            marginLeft: (theme) => theme.spacing('auto'),
            display: 'flex',
            alignItems: 'flex-end',
            pr: (theme) => theme.spacing(1.5),
            pb: (theme) => theme.spacing(1.5),
        },
        paper: {
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue[80],
        },
        wrapper: {
            background: '#FBFBFB 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: (theme) => theme.palette.white,
            borderRadius: '5px',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: '#E8E8E8',
        },
        content: {
            background: (theme) => theme.palette.white,
            margin: (theme) => theme.spacing(0.25),
            marginTop: (theme) => theme.spacing(0),
            color: (theme) => theme.palette.gray[33],
            borderBottomLeftRadius: (theme) => theme.spacing(1),
            borderBottomRightRadius: (theme) => theme.spacing(1),
        },
        cirProgWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        labeledPaper: { display: 'flex', flexDirection: 'column' },
        selectedBtn: {
            textTransform: 'none',
            px: 1,
            '&.Mui-disabled': {
                backgroundColor: '#01408B',
                color: 'white',
            },
        },
        emptycontainer: { backgroundColor: '#FFFFFF' },
        emptymsg: { fontFamily: 'Stolzl', fontSize: '20px', color: '#000000', fontWeigtht: 'medium' },
        emptySuggestion: { fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'normal' },
        expandIcon: { color: (theme) => theme.palette.white },
        summaryTitle: { width: '93%', flexShrink: 0, fontSize: '16px', fontWeight: 'normal' },
    },
    samRequest: {
        warningBox: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', minHeight: (theme) => theme.spacing(25) },
        circularBox: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' },
        labelValue: { width: 200 }, //   parameter , so i didn't use theme.spacing
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
        message: { fontSize: '30px', fontWeight: 500, fontFamily: 'Stolzl', color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        checkOutBtn: {
            width: '180px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },
    tags: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        box: { display: 'flex', flexDirection: 'column' },
        titlebox: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(2) },
        name: { fontWeight: 'bold', width: '31%' },
        colon: { width: (theme) => theme.spacing(0.25), mx: 1.5 },
        val: { width: (theme) => theme.spacing(50), fontWeight: 'bold', flexGrow: 1 },
        dataWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(1) },
        dataNameWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',

            borderRadius: (theme) => theme.spacing(0.25),
            borderColor: 'gray.lowMedium',
            py: (theme) => theme.spacing(0.5),
            px: (theme) => theme.spacing(1.5),
        },
        dataColan: { width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        dataValueBoxWrapper: { flexGrow: 1 },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        activeSelectField: {
            background: 'transparent',
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                // background: '#00709B',
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0047BA',
            },
        },
    },
};
export default ProvisioningStyles;

export const ServerTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.blue.main,
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: theme.palette.blue.main,
        padding: theme.spacing(0.2),
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: theme.palette.blue.dark,
        background: theme.palette.white,
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.gray.light,
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: theme.palette.gray.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.gray.light,
        },
    },
    input: {
        '&::placeholder': {
            [theme.breakpoints.only('md')]: {
                fontSize: theme.spacing(1.625),
                textAlign: 'left',
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: theme.spacing(1.75),
                textAlign: 'left',
            },
            color: theme.palette.gray[44],
            opacity: 1,
        },
    },
}));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
