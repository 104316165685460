import { atom } from 'recoil';

export const requestDetails = atom({
    key: 'Onboard Developer',
    default: {
        applicationName: null,
        firstName: null,
        lastName: null,
        gpid: null,
        email: null,
        sector: null,
        isTouched: false,
    },
});

export const successPopup = atom({
    key: 'Onboard Developer-successpopup',
    default: {
        isOpen: false,
        orderId: null,
    },
});

export const isValidApplicationName = atom({
    key: 'Onboard Developer-validApplicationName',
    default: false,
});
