import React from 'react';
import { Stack } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { environmentDetails, sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const filter = createFilterOptions();
const CostCenter = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [envDetails, setEnvDetails] = useRecoilState(environmentDetails);
    const [value, setValue] = React.useState(order?.hCodeDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.hCode || '');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const hCodeGetApi = useMutation(() => axios.get(`${endPoints.ssp.vm.hCode}/${ccInputValue?.toUpperCase()}`));
    const hCodePostApi = useMutation((payload) => axios.post(`${endPoints.ssp.vm.hCode}`, payload));

    const setHCode = (hCode, hCodeDetails) => {
        setOrder({
            ...order,
            hCode,
            hCodeDetails,
        });
        setEnvDetails({
            ...envDetails,
            ...(hCodeDetails?.projectName && { Project: hCodeDetails?.projectName }),
            ...(!hCodeDetails?.projectName && { Project: '-' }),
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                hCodeGetApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (hCodeGetApi?.isSuccess) {
            if (hCodeGetApi?.data?.data?.projects) {
                const combinedValue = hCodeGetApi?.data?.data?.projects.map((app) => ({ ...app, label: `${app.id}`, value: `${app.id}` }));
                setOptions(combinedValue);
            }
        }
    }, [hCodeGetApi?.isSuccess]);

    React.useEffect(() => {
        if (hCodePostApi?.isSuccess) {
            if (hCodePostApi?.data?.data?.projects) {
                const combinedValue = hCodePostApi?.data?.data?.projects.map((app) => ({ ...app, label: `${app.id}`, value: `${app.id}` }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [hCodePostApi?.isSuccess]);

    React.useEffect(() => {
        hCodePostApi.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else {
            setHCode(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.hCodeDetails && order?.isTouched) setIsTouched(true);
    }, [order?.hCodeDetails, order?.isTouched]);

    return (
        <>
            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                <Select
                    key="costCenterHcodeName"
                    label="Cost Center, GL Account, PEP Element or Project Id *"
                    value={order?.hCodeDetails || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={ProvisioningStyles.costCenter}
                    freeSolo
                    isRequired={isTouched}
                />
                <HelpImg title="Type to Search H-Code (e.g. H-000XXX) or Enter a GL Account / PEP Element and hit enter to set the value." />
            </Stack>
        </>
    );
};

export default React.memo(CostCenter);
