import React from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload, isAppRegionRequired } from 'pages/provisioning/virtual-service/store';
import { isDisplayField } from 'pages/provisioning/virtual-service/utils/getFunction';

export const ApplicationRegion = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [, setAppRegionReq] = useRecoilState(isAppRegionRequired);
    const [options, setOptions] = React.useState([]);

    const appRegionApi = useMutation(() => axios.get(`${endPoints.virtualService.appRegions}?sector=${order?.sector}`));

    const setApplicationRegion = (applicationRegion, applicationRegionDetails) => {
        setOrder({
            ...order,
            applicationRegion,
            applicationRegionDetails,
            program: null,
            department: null,
            aksCluster: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (applicationRegion) => options.find((option) => applicationRegion === option.value);

    const handleOnSelectChange = (selected) => setApplicationRegion(selected.value, selected);

    React.useEffect(() => {
        if (appRegionApi?.isSuccess) {
            setOptions(appRegionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName, data: app })));
        }
    }, [appRegionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length > 0) setAppRegionReq(true);
        else setAppRegionReq(false);

        if (options && options?.length === 1 && !order?.applicationRegion) {
            setOrder({ ...order, applicationRegion: options?.[0].value });
        }
        if (options && options?.length && order?.applicationRegion) {
            const applicationRegion = isExistsInOptions(order?.applicationRegion);
            setOrder({ ...order, applicationRegion: applicationRegion?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.sector) {
            resetOptions();
            appRegionApi.mutate();
        }
    }, [order?.sector]);

    React.useEffect(() => {
        if (!order?.applicationRegion && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationRegion, order?.isTouched]);

    return (
        <>
            {appRegionApi?.isSuccess && isDisplayField(options, 'appRegion') && (
                <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Select
                            key="ApplicationRegion"
                            dataTestId="app-region-autocomplete"
                            fullWidth
                            label="Application Region *"
                            value={order?.applicationRegion || null}
                            options={options}
                            handleOnSelect={(value) => handleOnSelectChange(value)}
                            disabled={appRegionApi.isLoading}
                            isLoading={appRegionApi.isLoading}
                            isRequired={isTouched}
                        />
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default React.memo(ApplicationRegion);
