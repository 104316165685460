import React from 'react';
import { Box, Grid, Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { useRecoilState } from 'recoil';
import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import RITMNumber from 'pages/mdt-onboarding/provisioning/components/RITMNumber';
import { showRITMForEnvironments } from 'pages/mdt-onboarding/utils';
import styles from './style';

const RITMContainer = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);
    const [isTouched, setIsTouched] = React.useState(false);

    const handleChange = () => {
        setOrder({
            ...order,
            ritmChecked: !order?.ritmChecked,
        });
    };

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.ritmChecked) {
            return 'ritmCheckbox';
        }
        return '';
    };
    React.useEffect(() => {
        if (!order?.ritmChecked && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }

        if (showRITMForEnvironments.includes(order?.environment) && !order?.ritmChecked) {
            setValidForm({ ...validForm, isRitmCheckboxValid: true });
        } else {
            setValidForm({ ...validForm, isRitmCheckboxValid: false });
        }
    }, [order?.ritmChecked, order?.isTouched]);

    return (
        <Box display="flex" flexDirection="column" marginTop="-20px">
            <Box>
                <FormControlLabel
                    id="333333"
                    control={<Checkbox className={validateInput()} onChange={(e) => handleChange(e)} checked={order?.ritmChecked} />}
                    label={
                        <InputLabel htmlFor="short-description">
                            <Grid sx={styles.ritmProjectText} item xs={6} sm={6}>
                                Project implemented in lower environments.
                            </Grid>
                        </InputLabel>
                    }
                />
            </Box>
            {order?.ritmChecked && (
                <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <Box display="flex" width="-webkit-fill-available">
                        <RITMNumber />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(RITMContainer);
