/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { aksCatalogServiceCode, kafkaCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddDepartment, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Department = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDDepartment, setLzDDDepartment] = useRecoilState(ddDepartment);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    let departmentEndpoint;
    switch (landZoneForm?.cloudService) {
        case 'PaaS/AKSNS':
            departmentEndpoint = `${endPoints.landingZones.eipDepartments}/AKS`;
            break;
        case 'PaaS/Kafka Topics':
            departmentEndpoint = `${endPoints.landingZones.eipDepartments}/Kafka`;
            break;
        default:
            departmentEndpoint = endPoints.landingZones.departments;
    }
    const departmentApi = useMutation((payload) => axios.post(departmentEndpoint, payload));
    const departmentGetApi = useMutation(() => axios.get(departmentEndpoint));

    const setDepartment = (department) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                department,
                ...([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) && {
                    environment: null,
                    region: null,
                    cluster: null,
                }),
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setDepartment(null);
    };
    const seldValueExistsInOptions = (departmentValue) => options.find((value) => departmentValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setDepartment(selected?.label);
        } else {
            setDepartment(null);
        }
    };
    React.useEffect(() => {
        if (departmentApi?.isSuccess) {
            setOptions(departmentApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
            setLzDDDepartment({ ...lzDDDepartment, isLoaded: true });
        }
    }, [departmentApi?.isSuccess]);
    React.useEffect(() => {
        if (departmentGetApi?.isSuccess) {
            setOptions(departmentGetApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
            setLzDDDepartment({ ...lzDDDepartment, isLoaded: true });
        }
    }, [departmentGetApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const department = options.find((value) => lzModal?.lzDetails?.config?.department === value?.label);
            if (department) setLzDDDepartment({ ...lzDDDepartment, isLoaded: true, data: department });
        }
    }, [options]);

    React.useEffect(() => {
        if (
            ![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) &&
            landZoneForm?.environment &&
            landZoneForm?.regionDetails?.value
        ) {
            resetOptions();
            departmentApi.mutate({
                environment: landZoneForm?.environment,
                region: landZoneForm?.regionDetails?.value,
            });
        }
    }, [landZoneForm?.environment, landZoneForm?.regionDetails?.value]);
    React.useEffect(() => {
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService)) {
            departmentGetApi?.mutate();
        }
    }, []);
    return (
        <Box bgcolor="white">
            <Select
                key="department"
                value={landZoneForm?.department || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={departmentApi.isLoading}
                isLoading={departmentApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Department"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Department);
