import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, Stack, CircularProgress } from '@mui/material';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import Empty from 'components/empty';
import { useParams } from 'react-router-dom';
import { OrderPayload, validProvisionForm, toggleModal } from 'pages/security-tool/store';
import { formOrderObjFromOrderDetails, resetOrder, resetValidProvisionForm } from 'pages/security-tool/utils';
import SecurityToolStyles from 'pages/security-tool/style';

const StickyHeader = React.lazy(() => import('pages/provisioning/_components/StickyHeader'));
const InputForm = React.lazy(() => import('pages/security-tool/InputForm'));
const MemberList = React.lazy(() => import('pages/security-tool/components/Form/MemberList'));

const SecurityTool = () => {
    const { catalogCode, cartItemId, operation } = useParams();
    const [, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validProvisionForm);
    const [modal, setModal] = useRecoilState(toggleModal);
    const [isLoading, setLoading] = React.useState(false);
    const orderDetailsApi = useMutation(() => axios.get(`${endPoints.riServices.Jwt.orderDetails}/${cartItemId}`));

    const handleReset = () => {
        setOrder(resetOrder);
        setValidForm({ ...isValidForm, resetValidProvisionForm });
        setModal({
            ...modal,
            isSuccessOpen: false,
            isClearAllConfirmOpen: false,
            isRemoveConfirmOpen: false,
            action: '',
        });
    };

    async function checkCatalog() {
        setLoading(true);
        try {
            if (operation === 'edit') {
                const { data: orderDetails } = await orderDetailsApi.mutateAsync();
                const orderObj = formOrderObjFromOrderDetails(orderDetails);
                // eslint-disable-next-line
                setOrder((previous) => ({
                    ...previous,
                    ...orderObj,
                }));
            } else {
                setOrder((previous) => ({
                    ...previous,
                    isTouched: false,
                }));
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (catalogCode) {
            handleReset();
            checkCatalog();
        }
    }, [catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => handleReset();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        return () => handleReset();
        // eslint-disable-next-line
    }, [operation]);

    return (
        <>
            <Helmet>
                <title>Automate Security Tool</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={SecurityToolStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={SecurityToolStyles.circularProgressColor} />
                    </Box>
                }
            >
                {isLoading && (
                    <Stack sx={SecurityToolStyles?.emptyStack}>
                        <Empty isLoading color="#000000" msg="Loading..." />
                    </Stack>
                )}
                {!isLoading && (
                    <>
                        <StickyHeader title="Web Application Security Tool Onboarding" imgSrc={null} />
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Grid display="flex" flexDirection="column" padding={4} gap={3} data-testId="secTool">
                                    <InputForm />
                                    <MemberList />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Suspense>
        </>
    );
};

export default React.memo(SecurityTool);
