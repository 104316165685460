import React from 'react';
import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import { Stack, Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import styles from './style';

const SourceDirectory = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [helperMsg, setHelperMsg] = React.useState(false);
    const [showHelperText, setHelperText] = React.useState(false);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);

    const handleOnSelectChange = (selected) => {
        if (selected !== order?.sourceDirectory) {
            setOrder({
                ...order,
                sourceDirectory: selected,
            });
        }
    };

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.sourceDirectory) || showHelperText) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (
            order?.sourceDirectory &&
            order?.sourceDirectory !== '' &&
            order?.sourceDirectory !== undefined &&
            !/^([a-zA-Z0-9-/_ ]){3,255}$/.test(order?.sourceDirectory)
        ) {
            setHelperText(true);
            setHelperMsg('Invalid Source Directory.');
            setValidForm({ ...validForm, isSourceDirectoryValid: true });
        } else {
            setValidForm({ ...validForm, isSourceDirectoryValid: false });
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.sourceDirectory]);
    React.useEffect(() => {
        if (!order?.sourceDirectory && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.sourceDirectory, order?.isTouched]);
    return (
        <Stack direction="row" spacing={1} sx={styles.stack} data-testid="source-directory-text">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Source Directory *"
                autoComplete="off"
                error={isTouched || showHelperText}
                helperText={showHelperText && <span style={styles?.helpText}>{helperMsg}</span>}
                value={order?.sourceDirectory || ''}
                onChange={(e) => handleOnSelectChange(e.target.value)}
                className={validateInput()}
            />
            <HelpImg
                title={
                    <>
                        <Box sx={styles.infoStyle.wrapper}>
                            <Box sx={styles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={styles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            <span style={styles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>2) Min.: 3 chars to Max.: 255 chars</Typography>
                                        <Typography sx={styles.infoStyle.typographyStyle}>
                                            3) Can contain hyphen(-), slash(/) and underscore(_) as special chars.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={styles.infoStyle.bottomDividerRule}>
                                        <Typography sx={styles.infoStyle.typographyStyle}> Example: cmpblob</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

export default SourceDirectory;
