import React from 'react';
import PropTypes from 'prop-types';
// import Select from 'components/select';
import Select from 'pages/reference-implementation/components/select';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { Box } from '@mui/material';

const BusinessUnit = (props) => {
    const { handleOnSelect, businessUnitvalue, isRequired } = props;
    const [options, setOptions] = React.useState([]);

    const businessUnitApi = useMutation(() => axios.get(endPoints.ssp.aks.businessUnit));

    const resetOptions = () => {
        setOptions([]);
    };

    React.useEffect(() => {
        if (businessUnitApi?.isSuccess) {
            setOptions(businessUnitApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        }
    }, [businessUnitApi?.isSuccess]);

    React.useEffect(() => {
        resetOptions();
        businessUnitApi.mutate();
    }, []);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="businessUnitDetails"
                label="Business Unit *"
                value={businessUnitvalue || null}
                options={options}
                disabled={businessUnitApi.isLoading}
                handleOnSelect={(value) => handleOnSelect(value?.value)}
                isRequired={isRequired}
                dataTestId="selectbusinessUnit"
            />
        </Box>
    );
};

BusinessUnit.propTypes = {
    businessUnitvalue: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
};

BusinessUnit.defaultProps = {
    businessUnitvalue: null,
    handleOnSelect: () => {},
    isRequired: false,
};

export default BusinessUnit;
