import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import ErrorBanner from 'components/ErrorBanner';
import { OrderPayload, toggleModal } from 'pages/reference-implementation/provisioning/RI001/store';
import { addADGroup, removeADGroup } from 'pages/reference-implementation/provisioning/RI001/store/selector';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { RI001Styles } from 'pages/reference-implementation/provisioning/RI001/style';
import { isADGroupFormValid, isADGroupDuplicate } from 'pages/reference-implementation/provisioning/RI001/utils/validation';
import ADGroupField from './ADGroupField';

const AddADGroups = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const addGroup = useSetRecoilState(addADGroup);
    const removeGroup = useSetRecoilState(removeADGroup);
    const [lastModifiedAG, setLastModifiedAG] = React.useState(undefined);

    React.useEffect(() => {
        if (isOpen?.isADGroupModalOpen) {
            setLastModifiedAG(order?.adGroupList);
        }
    }, [isOpen?.isADGroupModalOpen]);

    const checkADGroupDuplicates = (value) => {
        const adGroupValues = order?.adGroupList?.map((item) => item?.adGroupValue);
        const duplicateADGroups = adGroupValues.filter((item, index) => adGroupValues.indexOf(item) !== index);
        if (value) {
            return duplicateADGroups.indexOf(value) > -1;
        }
        return false;
    };

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const handleSubmit = async () => {
        if (order?.adGroupList?.length < 2 || !isADGroupFormValid(order) || isADGroupDuplicate(order)) {
            handleClickErrorBanner();
        } else {
            setIsOpen({ ...isOpen, isADGroupModalOpen: false });
        }
    };

    return (
        <Modal
            title="AD Groups"
            handleClose={() => {
                handleCloseErrorBanner();
                setIsOpen({ ...isOpen, isADGroupModalOpen: false });
                setOrder({
                    ...order,
                    adGroupList: lastModifiedAG,
                });
            }}
            open={isOpen.isADGroupModalOpen}
        >
            <Box sx={RI001Styles.modal.wrapper}>
                {errorBannerOpen && (
                    <ErrorBanner
                        show={errorBannerOpen}
                        message="Error! AD group criteria is not fulfilled. Please check the help text."
                        handleCloseCallback={handleCloseErrorBanner}
                    />
                )}
                <Stack direction="row" spacing={1}>
                    <Typography variant="h6">Add AD Groups</Typography>
                    <HelpImg
                        maxmWidth="450px"
                        title={
                            <Box display="flex" flexDirection="column" gap={0.5}>
                                <Typography>
                                    <span style={{ fontWeight: 600 }}>AD Group criteria:</span>
                                </Typography>
                                <Typography>1) Minimum 2 AD Groups should be added.</Typography>
                                <Typography>2) Maximum 5 AD Groups are allowed.</Typography>
                                <Typography>3) AD Groups cannot be empty.</Typography>
                                <Typography>4) AD Groups should not be duplicate.</Typography>
                            </Box>
                        }
                    />
                </Stack>
                <Box sx={RI001Styles.modal.contentWrapper}>
                    {order?.adGroupList?.map((item, index) => (
                        <Box key={index.toString()} sx={RI001Styles.modal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={RI001Styles.modal.groupWrapper}>
                                <React.Fragment key={index.toString()}>
                                    <ADGroupField indexVal={index} checkADGroupDuplicates={checkADGroupDuplicates} />
                                    {item?.adGroupValue && checkADGroupDuplicates(item?.adGroupValue) && (
                                        <Typography variant="body2" gutterBottom sx={RI001Styles.modal.warningTypography}>
                                            AD group must not be duplicate.
                                        </Typography>
                                    )}
                                </React.Fragment>
                            </Box>
                            <Box sx={RI001Styles.modal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {index !== 0 && order?.adGroupList?.length > 1 && (
                                        <Box sx={RI001Styles.modal.deleteWrapper}>
                                            <FaTrashAlt
                                                style={RI001Styles.modal.delete}
                                                onClick={() => removeGroup(item)}
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.adGroupList.length - 1 && order?.adGroupList?.length < 5 ? (
                                        <MdAdd
                                            style={RI001Styles.modal.add}
                                            onClick={() => {
                                                addGroup({ count: 1 });
                                            }}
                                            className="w-7 h-7 text-blue-base cursor-pointer"
                                        />
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={RI001Styles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary2"
                        onClick={() => {
                            handleSubmit();
                        }}
                        data-testid="save-btn"
                    >
                        Save
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={RI001Styles.modal.cancelBtn}
                        onClick={() => {
                            handleCloseErrorBanner();
                            setIsOpen({ ...isOpen, isADGroupModalOpen: false });
                            setOrder({
                                ...order,
                                adGroupList: lastModifiedAG,
                            });
                        }}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default React.memo(AddADGroups);
