import React from 'react';
import { useRecoilState } from 'recoil';
import MemberAccess from 'pages/project-onboarding/components/preview/member-access';
import ProjectDetails from 'pages/project-onboarding/components/preview/project-details';
import ApplicationDetails from 'pages/project-onboarding/components/preview/application-details';
import SecurityTool from 'pages/project-onboarding/components/preview/security-tool';
import PreviewHeader from 'pages/project-onboarding/components/preview/preview-header';
import ArtifactoryDetails from 'pages/project-onboarding/components/preview/artifactory-details';
import SonarQubeDetails from 'pages/project-onboarding/components/preview/sonarQube-details';
import { OrderPayload } from 'pages/project-onboarding/store';

const PreviewOrderDetails = () => {
    const [order] = useRecoilState(OrderPayload);
    return (
        <>
            <PreviewHeader />
            <ProjectDetails />
            <ApplicationDetails />
            {order?.serviceResources?.isArtifactory && <ArtifactoryDetails />}
            {order?.serviceResources?.isSonarQube && <SonarQubeDetails />}
            {order?.serviceResources?.isSecurityTools && <SecurityTool />}
            <MemberAccess />
        </>
    );
};

export default PreviewOrderDetails;
