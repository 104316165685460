/* eslint-disable import/no-named-default */
import { default as Environments } from './Environments';
import { default as AccountType } from './AccountType';
import { default as ApplicationName } from './ApplicationName';
import { default as ApplicationShortName } from './ApplicationShortName';
import { default as AccountOwner } from './AccountOwner';
import { default as ApplicationService } from './ApplicationService';
import { default as Sector } from './Sector';
import { default as SQLServerName } from './SQLServerName';

const Forms = {
    Environments,
    AccountType,
    ApplicationName,
    ApplicationShortName,
    AccountOwner,
    ApplicationService,
    Sector,
    SQLServerName,
};

export default Forms;
