import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { orderPayload, validVSForm, isNamespaceLoading } from 'pages/provisioning/virtual-service/store';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import useDebounce from 'pages/provisioning/virtual-service/hooks/use-debounce';
import InfoImg from 'components/info-img';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';

export const AksNamespace = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [helperText, setHelperText] = React.useState(false);
    const [validAksNamespace, setValidAksNamespace] = React.useState('');
    const [namespaceExists, setNamespaceExists] = React.useState(true);
    const [, setNamespaceLoading] = useRecoilState(isNamespaceLoading);

    const namespaceValidateApi = useMutation((payload) => axios.post(`${endPoints.virtualService.namespaceValidate}`, payload));

    const debouncedNamespaceValue = useDebounce(validAksNamespace, 500);

    React.useEffect(() => {
        if (namespaceValidateApi?.isSuccess) {
            setNamespaceLoading(false);
            if (namespaceValidateApi?.data?.data?.namespaceExists) {
                setValidForm({
                    ...validForm,
                    isNamespaceExists: true,
                });
                setNamespaceExists(true);
            } else {
                setValidForm({
                    ...validForm,
                    isNamespaceExists: false,
                });
                setNamespaceExists(false);
            }
        }
    }, [namespaceValidateApi?.isSuccess]);
    React.useEffect(() => {
        if (namespaceValidateApi?.isError) {
            setNamespaceLoading(false);
            setOrder({
                ...order,
                aksNamespace: '',
            });
        }
    }, [namespaceValidateApi?.isError]);

    React.useEffect(() => {
        if (namespaceValidateApi?.isLoading) {
            setNamespaceLoading(true);
        }
    }, [namespaceValidateApi?.isLoading]);

    React.useEffect(() => {
        setNamespaceExists(true);
        if (debouncedNamespaceValue && order?.aksCluster && order?.environment) {
            const payload = {
                cluster: order?.aksCluster,
                env: order?.environment,
                namespace: debouncedNamespaceValue,
            };
            namespaceValidateApi?.mutate(payload);
        }
    }, [debouncedNamespaceValue, order?.aksCluster, order?.environment]);

    const handleValueChange = (value) => {
        if (value !== order?.aksNamespace) {
            setOrder({
                ...order,
                aksNamespace: value,
            });
        }
    };

    React.useEffect(() => {
        if (
            order?.aksNamespace &&
            order?.aksNamespace !== null &&
            order?.aksNamespace !== '' &&
            order?.aksNamespace !== undefined &&
            !/^[a-zA-Z0-9_-]{3,64}$/.test(order?.aksNamespace)
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isInvalidAksNamespace: true });
        } else {
            setValidAksNamespace(order?.aksNamespace);
            setHelperText(false);
            setValidForm({ ...validForm, isInvalidAksNamespace: false });
        }
    }, [order?.aksNamespace]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.aksNamespace) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.aksNamespace && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.aksNamespace, order?.isTouched]);

    const getHelperText = () => {
        if (helperText) {
            return 'Namespace name should have alpha-numeric value with min 3 chars and can contain only hyphen(-) and underscore(_) as special chars.';
        }
        if (!namespaceExists) {
            return 'Namespace does not exist';
        }
        return '';
    };

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="aks-namespace-box">
            <CssTextField
                size="small"
                fullWidth
                autoComplete="off"
                label="AKS Namespace Name *"
                error={helperText || !namespaceExists}
                // helperText={helperText && <span>Invalid Namespace Name</span>}
                helperText={getHelperText()}
                value={order?.aksNamespace || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
                disabled={!order?.aksCluster}
                InputProps={{
                    endAdornment: <>{namespaceValidateApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                }}
            />
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>AKS Namespace Name:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            A Kubernetes resource where the pods and deployments are logically grouped into namespaces to divide an AKS cluster.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

export default React.memo(AksNamespace);
