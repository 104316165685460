import { atom } from 'recoil';

export const selectedApprovalOrder = atom({
    key: 'selectedApprovalOrder',
    default: undefined,
});

export const selectedRows = atom({
    key: 'selectedRows',
    default: [],
});

export const perviousSelection = atom({
    key: 'perviousSelection',
    default: [],
});

export const multiApprovalDialog = atom({
    key: 'multiApprovalDialog',
    default: false,
});

export const isApproveAll = atom({
    key: 'isApproveAll',
    default: false,
});

export const isApprove = atom({
    key: 'isApprove',
    default: undefined,
});

export const totalRecords = atom({
    key: 'totalRecords',
    default: 0,
});

export const isOrderDetailsOpen = atom({
    key: 'isOrderDetailsOpen',
    default: false,
});

export const costCenter = atom({
    key: 'approval_costCenter',
    default: {
        costCenter: null,
        costCenterDetails: null,
    },
});
