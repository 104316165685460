import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import Lz from 'pages/app-profiles/landing-zones/components/Form';
import { Box, Stack, Grid, Typography, Paper, Divider, CircularProgress } from '@mui/material';
import InfoImg from 'components/info-img';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import Btn from 'components/button';
import { eipStackCatalogDefinitionId } from 'utils/catalogDefinitionId';
import LandingZoneStyle from './LandingZoneStyle';
import ViewLandingZone from './components/ViewLandingZone';
import { resetLandingZone, setLandingZoneOnLocalStorage } from './utils';

const EIPStackFields = ({ appProfileName, windowType, setLoading }) => {
    const [lzModal, setLZModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const getComponentsApi = useMutation((payload) =>
        axios.get(
            `${endPoints.landingZones.eipStack.components
                ?.replace('{cloudprovider}', payload?.cloudProvider)
                ?.replace('{cloudservice}', payload?.cloudService)}`
        )
    );

    // const getComponentsApi = useMutation(() => axios.get(endPoints.landingZones.eipStack.components));

    React.useEffect(() => {
        if (landZoneForm?.cloudProvider && landZoneForm?.cloudServiceCode && landZoneForm?.environment && landZoneForm?.region) {
            getComponentsApi.mutate({
                cloudProvider: landZoneForm?.cloudProvider,
                cloudService: landZoneForm?.cloudServiceCode,
            });
            // getComponentsApi.mutate();
        }
    }, [landZoneForm?.cloudProvider, landZoneForm?.cloudServiceCode, landZoneForm?.environment, landZoneForm?.region]);

    React.useEffect(() => {
        if (lzModal?.lzDetails?.cloudProvider && lzModal?.lzDetails?.cloudService && lzModal?.lzDetails?.environment && lzModal?.lzDetails?.region) {
            getComponentsApi.mutate({
                cloudProvider: lzModal?.lzDetails?.cloudProvider,
                cloudService: eipStackCatalogDefinitionId[0] || null,
            });
            // getComponentsApi.mutate();
        }
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (getComponentsApi?.isSuccess) {
            const fromEdit = lzModal?.lzDetails?.config?.components || [];
            const fromOther = lzModal?.lzDetails?.components || [];
            // if (!lzModal?.lzDetails) {
            //     console.log("I'm called", lzModal?.lzDetail);
            const form = { ...landZoneForm };
            try {
                if (getComponentsApi?.data?.data?.components?.length) {
                    form.components = getComponentsApi?.data?.data?.components?.map((item) => ({
                        componentId: item?.componentId,
                        landingZone: null,
                        ...(fromEdit?.length > 0 && {
                            landingZoneDetails: fromEdit?.find((component) => component?.name === item?.name)?.landingzoneId
                                ? { id: fromEdit?.find((component) => component?.name === item?.name)?.landingzoneId }
                                : null,
                        }),
                        ...(fromOther?.length > 0 && {
                            landingZoneDetails: fromOther?.find((component) => component?.name === item?.name)?.landingZoneDetails
                                ? { id: fromOther?.find((component) => component?.name === item?.name)?.landingZoneDetails?.id }
                                : null,
                        }),
                        ...(fromEdit?.length <= 0 &&
                            fromOther?.length <= 0 && {
                                landingZoneDetails: null,
                            }),
                        name: item?.name,
                        provider: item?.provider,
                        service: item?.service,
                        optional: item?.optional,
                    }));
                }
                if (getComponentsApi?.data?.data?.connections?.length) {
                    form.connections = getComponentsApi?.data?.data?.connections?.map((item) => ({
                        connectionType: null,
                        connectionTypeDetails: { id: item?.connectionType },
                        name: item?.name,
                        sourceComponentId: item?.sourceComponent,
                        sourceComponentName: getComponentsApi?.data?.data?.components?.find((component) => component?.name === item?.sourceComponent)?.service,
                        targetComponentId: item?.targetComponent,
                        targetComponentName: getComponentsApi?.data?.data?.components?.find((component) => component?.name === item?.targetComponent)?.service,
                        optional: getComponentsApi?.data?.data?.components?.find((component) => component?.name === item?.targetComponent)?.optional,
                    }));
                }
            } catch (e) {
                // eslint-disable-next-line
                console.log(e);
            }
            setLandingZoneForm(form);
            // }
        }
    }, [getComponentsApi?.isSuccess]);

    return (
        <>
            <Grid item xs={6}>
                {' '}
                <Lz.Environments />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.Region />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.DataClassification />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.LandingZoneLabel />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.LandingZoneDesc />
            </Grid>
            {!getComponentsApi?.isLoading && landZoneForm?.components?.length > 0 && (
                <Grid item xs={12} mt={2}>
                    <Paper
                        sx={{
                            height: '100%',
                            borderRadius: (theme) => theme.spacing(0.75),
                            marginBottom: (theme) => theme.spacing(3.125),
                            border: '1px solid #E0E0E0',
                        }}
                    >
                        <Box display="flex" m={2}>
                            <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                                <Stack direction="row">
                                    Components
                                    <span style={{ marginLeft: '7px', marginTop: '-4px' }}>
                                        <InfoImg title="Astrik * marked landing zones are mandatory." />
                                    </span>
                                </Stack>
                            </Typography>
                        </Box>
                        <Divider />
                        <Box mx={2} my={2}>
                            {getComponentsApi?.isLoading && (
                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress color="inherit" size={16} sx={{ color: (theme) => theme.palette.black.dark }} />
                                    <Typography variant="body2" sx={{ color: (theme) => theme.palette.black.dark }}>
                                        Loading
                                    </Typography>
                                </Box>
                            )}
                            {!getComponentsApi?.isLoading &&
                                landZoneForm?.components?.map((item, index) => (
                                    <Box key={index} display="flex" alignItems="center" mx={1} my={2}>
                                        <Grid item xs={4} mr={2}>
                                            <Lz.ComponentName index={index} />
                                        </Grid>
                                        <Grid item xs={4} mr={2}>
                                            <Lz.LandingZones index={index} appProfileName={appProfileName} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <ViewLandingZone index={index} />
                                        </Grid>
                                        <Grid item xs={3} mr={2}>
                                            <Btn
                                                variant="outlined"
                                                color="cmpPrimary"
                                                disabled={!landZoneForm?.environment || !landZoneForm?.region}
                                                onClick={() => {
                                                    setLoading(true);
                                                    setLandingZoneOnLocalStorage(JSON.stringify({ ...landZoneForm, windowType }));
                                                    setLandingZoneForm({
                                                        cloudProvider: landZoneForm?.components?.[index]?.provider,
                                                        cloudService: landZoneForm?.components?.[index]?.service,
                                                        environment: null,
                                                        environmentDetails: null,
                                                        region: null,
                                                        regionDetails: null,
                                                        ...resetLandingZone(landZoneForm?.components?.[index]?.service),
                                                    });
                                                    setLZModal({ isOpen: true, type: 'create', appProfileName });
                                                    setTimeout(() => {
                                                        setLoading(false);
                                                    }, 2000);
                                                }}
                                            >
                                                Add New
                                            </Btn>
                                        </Grid>
                                    </Box>
                                ))}
                        </Box>
                    </Paper>
                </Grid>
            )}
            {!getComponentsApi?.isLoading &&
                landZoneForm?.connections?.filter(
                    (connection) => landZoneForm?.components?.find((component) => component?.name === connection?.targetComponentId)?.landingZoneDetails?.id
                )?.length > 0 && (
                    <Grid item xs={12} mt={2}>
                        <Paper
                            sx={{
                                height: '100%',
                                borderRadius: (theme) => theme.spacing(0.75),
                                marginBottom: (theme) => theme.spacing(3.125),
                                border: '1px solid #E0E0E0',
                            }}
                        >
                            <Box display="flex" m={2}>
                                <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                                    Connections
                                </Typography>
                            </Box>
                            <Divider />
                            <Box mx={2} my={2}>
                                {getComponentsApi?.isLoading && (
                                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress color="inherit" size={16} sx={{ color: (theme) => theme.palette.black.dark }} />
                                        <Typography variant="body2" sx={{ color: (theme) => theme.palette.black.dark }}>
                                            Loading
                                        </Typography>
                                    </Box>
                                )}
                                {!getComponentsApi?.isLoading &&
                                    landZoneForm?.connections?.map(
                                        (item, index) =>
                                            landZoneForm?.components?.find((component) => component?.name === item?.targetComponentId)?.landingZoneDetails
                                                ?.id && (
                                                <Box key={index} display="flex" alignItems="center" mx={1} my={2}>
                                                    <Grid item xs={4} mr={2}>
                                                        <Lz.SourceResource index={index} />
                                                    </Grid>
                                                    <Grid item xs={4} mr={2}>
                                                        <Lz.DestinationResource index={index} />
                                                    </Grid>
                                                    <Grid item xs={4} mr={2}>
                                                        <Lz.ConnectionType index={index} />
                                                    </Grid>
                                                </Box>
                                            )
                                    )}
                            </Box>
                        </Paper>
                    </Grid>
                )}
        </>
    );
};

EIPStackFields.propTypes = {
    appProfileName: PropTypes.string,
    windowType: PropTypes.string,
    setLoading: PropTypes.func,
};

EIPStackFields.defaultProps = {
    appProfileName: '',
    windowType: '',
    setLoading: () => null,
};

export default EIPStackFields;
