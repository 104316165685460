/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const DiskSku = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setDiskSku = (diskSku, diskSkuDetail) => {
        setOrder({ ...order, diskSku, diskSkuDetail });
    };
    const diskSkuApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.diskSku}`));
    const seldValueExistsInOptions = (diskSkuValue) => options.find((option) => diskSkuValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setDiskSku(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (diskSkuApi?.isSuccess) {
            if (diskSkuApi?.data?.data) setOptions(diskSkuApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [diskSkuApi?.isSuccess]);

    React.useEffect(() => {
        diskSkuApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.diskSku && order?.isTouched) setIsTouched(true);
    }, [order?.diskSku, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="diskSku"
                label="Disk SKU *"
                value={order?.diskSku || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={diskSkuApi.isLoading}
                isLoading={diskSkuApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(DiskSku);
