import React from 'react';
import PropTypes from 'prop-types';
import theme from 'assets/style/theme';
import { Chip as MuiChip } from '@mui/material';
import { FAILED, RUNNING, SUCCESS } from 'utils/ValidationStatus';

const Styles = {
    running: {
        color: theme.palette.white,
        background: '#00A0DD',
        width: '25px',
        height: '25px',
        borderRadius: '4px',
        padding: '3px',
    },
    failed: {
        color: theme.palette.white,
        background: theme.palette.red.main,
        width: '25px',
        height: '25px',
        borderRadius: '4px',
        padding: '3px',
    },
    success: {
        color: () => theme.palette.white,
        background: '#91C24C',
        width: '25px',
        height: '25px',
        borderRadius: '4px',
        padding: '3px',
    },
    storage: {
        color: '#000000',
        background: '#ffffff',
        fontSize: '14px',
        fontFamily: 'Roboto',
        width: 'auto',
        height: '30px',
        border: '1px solid #000000',
        padding: '10px',
        margin: '3px',
    },
};

function Chip(props) {
    const { status, children } = props;
    const getStatusColor = () => {
        if (status === RUNNING) {
            return Styles.running;
        }
        if (status === SUCCESS) {
            return Styles.success;
        }
        if (status === FAILED) {
            return Styles.failed;
        }
        if (status === 'storage') {
            return Styles.storage;
        }
        return {};
    };
    return <MuiChip label={children} sx={{ ...getStatusColor(), width: 'fit-content' }} />;
}

Chip.propTypes = {
    status: PropTypes.string,
    children: PropTypes.any,
};

Chip.defaultProps = {
    status: 'completed',
    children: 'Completed',
};

export default Chip;
