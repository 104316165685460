import React from 'react';
import { useRecoilState } from 'recoil';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Vm from 'pages/provisioning/day2-operation-paas/components/Form';
import FormCard from 'pages/provisioning/_components/FormCard';
import CostSummary from 'pages/provisioning/_components/CostSummary';
import { toggleSspModels, costEstimateState, sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';
import EnvironmentContainer from 'pages/provisioning/day2-operation-paas/EnvironmentContainer';
import CartBtn from 'pages/provisioning/day2-operation-paas/components/CartBtn';
import ConfigureTags from 'pages/provisioning/day2-operation-paas/components/ConfigureTags';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';

const SspForm = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [costEstimate] = useRecoilState(costEstimateState);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };

    return (
        <Grid container spacing={3.125} pt={3.125} pb={1}>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <FormCard title="Application">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.Applications />
                                    <Vm.Sector />
                                    <Vm.Environments />
                                    <Vm.Region />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.CostCenter />
                                    <Vm.Project />
                                    <Vm.Notes />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <FormCard title="Instance Details">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.ModificationType />
                                    <Vm.Capabilities />
                                    {order?.modifyService?.toLowerCase() === 'container' && <Vm.ContainerName />}
                                    {order?.modifyService?.toLowerCase() === 'file share' && <Vm.FileShareName />}
                                    {order?.modifyService?.toLowerCase() === 'queues' && <Vm.QueueName />}
                                    {order?.modifyService?.toLowerCase() === 'redundancy' && <Vm.Redundancy />}
                                    {order?.modifyService?.toLowerCase() === 'static webpage enable' && <Vm.StaticWebpage />}
                                    {order?.modifyService?.toLowerCase() === 'static webpage enable' && order?.staticWebpage?.toLowerCase() === 'enabled' && (
                                        <Vm.ErrorDocumentPath />
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.StorageAccount />
                                    {!['modify tags'].includes(order?.modifyService?.toLowerCase()) && <Vm.ModifyService />}
                                    {order?.modifyService?.toLowerCase() === 'modify tags' && <ConfigureTags />}
                                    {order?.modifyService?.toLowerCase() === 'file share' && <Vm.Tier />}
                                    {order?.modifyService?.toLowerCase() === 'static webpage enable' && order?.staticWebpage?.toLowerCase() === 'enabled' && (
                                        <Vm.IndexDocumentName />
                                    )}
                                </Stack>
                            </Grid>
                            {order?.modifyService?.toLowerCase() === 'configurations' && (
                                <>
                                    <Grid item xs={12}>
                                        <Stack direction="column">
                                            <Typography sx={ProvisioningStyles.accountKind.heading} variant="subtitle1">
                                                Account Kind
                                            </Typography>
                                            <Box>StorageV2 (general purpose v2)</Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="column" spacing={2}>
                                            <Vm.Performance />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="column" spacing={2}>
                                            <Vm.BlobAccessTier />
                                        </Stack>
                                    </Grid>
                                </>
                            )}
                            {order?.modifyService?.toLowerCase() === 'move resources' && (
                                <>
                                    <Grid item xs={6}>
                                        <Stack direction="column" spacing={2}>
                                            <Vm.SourceSubscription />
                                            <Vm.TargetSubscription />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="column" spacing={2}>
                                            <Vm.ResourceGroupText />
                                            <Vm.ResourceGroup />
                                        </Stack>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <EnvironmentContainer />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <CostSummary handleCostInfoBtn={handleCostInfoBtn} cartBtn={<CartBtn />} costEstimate={costEstimate} isLoading={false} />
            </Grid>
        </Grid>
    );
};

export default SspForm;
