/* eslint-disable import/no-named-as-default */
import Application from './Application';
import Email from './Email';
import FirstName from './FirstName';
import LastName from './LastName';
import Gpid from './Gpid';
import Sector from './Sector';
import ApplicationCI from './ApplicationCI';

const Form = {
    Application,
    Email,
    FirstName,
    LastName,
    Gpid,
    Sector,
    ApplicationCI,
};

export default Form;
