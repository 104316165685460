import { atom } from 'recoil';

export const successPopup = atom({
    key: 'mdt-successPopUp',
    default: {
        isOpen: false,
        orderId: null,
    },
});

export const orderPayload = atom({
    key: 'mdt-orderPayload',
    default: {
        applicationCI: null,
        applicationCIDetails: null,
        sector: null,
        environment: null,
        source: null,
        sourceDirectory: null,
        sourceAssignmentGrp: null,
        sourceFileNamePattern: null,
        sasUrl: null,
        sasToken: null,
        destinationDirectory: null,
        destinationAssignmentGrp: null,
        appOwner: null,
        appOwnerDetails: null,
        tokenExpiry: null,
        isTouched: false,
        sourceFileNamePatternList: [{ fileName: null }],
        sourceEmail: null,
        destinationEmail: null,
        ritmNumber: [],
        ritmChecked: false,
    },
});

export const validProvisionForm = atom({
    key: 'mdt-validProvisionForm',
    default: {
        isSourceDirectoryValid: false,
        isSourceAssignmentGrpValid: false,
        isSourceFileNamePatternValid: true,
        isSasUrlValid: false,
        isSasTokenValid: false,
        isDestinationDirectoryValid: false,
        isDestinationAssignmentGrpValid: false,
        isSourceEmailValid: false,
        isDestinationEmailValid: false,
        isRitmNumberValid: false,
        isRitmCheckboxValid: false,
    },
});

export const toggleModal = atom({
    key: 'mdt-toggleModal',
    default: {
        isSourceFilenameModalOpen: false,
        sourceFileNamePatternAdded: [],
    },
});
