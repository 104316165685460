import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import DialogModal from 'components/dialog';
import SuccessImage from 'assets/images/success.png';
import { successPopup, orderPayload, validProvisionForm, toggleModal } from 'pages/mdt-onboarding/store';
import { selectOrder } from 'pages/orders/store';
import ProvisioningStyles from 'pages/mdt-onboarding/style';
import { resetOrder, resetvalidProvisionForm } from '../utils';

const OrderSuccessPopup = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(false);
    const [modal, setModal] = useRecoilState(successPopup);
    const [, setOrder] = useRecoilState(orderPayload);
    const [formvalid, setFormValid] = useRecoilState(validProvisionForm);
    const setSelectedOrder = useSetRecoilState(selectOrder);
    const [, setToggleModal] = useRecoilState(toggleModal);

    const storeReset = async () => {
        setOrder(resetOrder);
        setFormValid({ ...formvalid, resetvalidProvisionForm });
        setModal({ isOpen: false, orderId: null });
        setModal({ isSourceFilenameModalOpen: false });
        setIsLoading(false);
        setToggleModal({ isSourceFilenameModalOpen: false, sourceFileNamePatternAdded: [] });
    };

    const handleOrderIdOnClick = async () => {
        await setSelectedOrder({ orderId: modal?.orderId });
        history.push('/orders');
    };

    return (
        <DialogModal open={modal?.isOpen} handleClose={() => {}} data-testid="orderSuccessModal">
            <Box sx={ProvisioningStyles.successPopup.wrapper}>
                <img src={SuccessImage} alt="" />
                <Typography gutterBottom sx={ProvisioningStyles.successPopup.requsetTypography}>
                    Thank you for using Cloud Marketplace.
                </Typography>
                <Typography gutterBottom sx={ProvisioningStyles.successPopup.thankyouTypography}>
                    Thank you for using Cloud Marketplace. Your order has been submitted successfully. Please go to
                    <Typography
                        component="span"
                        variant="body2"
                        sx={ProvisioningStyles.successPopup.orderIdTypography}
                        onClick={() => handleOrderIdOnClick()}
                        data-testid="order-id"
                    >
                        {modal?.orderId}
                    </Typography>
                    to review your order
                </Typography>
                <Box sx={ProvisioningStyles.successPopup.newOrderBox}>
                    <Button
                        onClick={() => {
                            setIsLoading(true);
                            storeReset();
                        }}
                        disabled={isLoading}
                        sx={ProvisioningStyles.successPopup.newOrderBtn}
                        disableElevation
                        variant="contained"
                        color="primary"
                        data-testid="placeReq-btn"
                    >
                        Place New Request
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoading(true);
                            storeReset();
                            history.push('/');
                        }}
                        component={Link}
                        to="/"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        disabled={isLoading}
                        data-testid="backHome-btn"
                    >
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </DialogModal>
    );
};
export default React.memo(OrderSuccessPopup);
