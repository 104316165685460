import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import { createFilterOptions } from '@mui/material/Autocomplete';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/provisioning/_components/helpImg';
import Select from 'components/select';
import { OrderPayload } from 'pages/service-account-creation/store';

const filter = createFilterOptions();
const SQLServerName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(order?.sqlServerDetails || null);
    const [options, setOptions] = React.useState([]);

    const serverNameGetApi = useMutation(() =>
        axios.get(`${endPoints.serviceAccountCreation.sqlServerName}=${order?.applicationServiceCI}&environment=${order?.environment}`)
    );

    const setSQLServerName = (sqlServerName, sqlServerDetails) => {
        setOrder({
            ...order,
            sqlServerName,
            sqlServerDetails,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    React.useEffect(() => {
        resetOptions();
        if (order?.environment && order?.applicationServiceCI) serverNameGetApi.mutate();
    }, [order?.environment, order?.applicationServiceCI]);

    React.useEffect(() => {
        if (serverNameGetApi?.isSuccess) {
            if (serverNameGetApi?.data?.data) {
                const combinedValue = serverNameGetApi?.data?.data.map((app) => ({ ...app, label: `${app.serverName}`, value: `${app.serverName}` }));
                setOptions(combinedValue);
            }
        }
    }, [serverNameGetApi?.isSuccess]);

    React.useEffect(() => {
        if (value === null) {
            resetOptions();
        } else {
            setSQLServerName(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.sqlServerName && order?.isTouched) setIsTouched(true);
    }, [order?.sqlServerName, order?.isTouched]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="sqlServerName"
                    label="SQL Server *"
                    value={order?.sqlServerName || null}
                    loading={serverNameGetApi?.isLoading}
                    disabled={!order?.environment || !order.applicationServiceCI}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option, i) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props} key={i}>
                            {option.label}
                        </li>
                    )}
                    sx={{ width: '100%' }}
                    freeSolo
                    isRequired={isTouched}
                />
                <HelpImg title="Type to Search SQL Server or Enter a custom name and hit enter to set the value." />
            </Box>
        </>
    );
};

export default React.memo(SQLServerName);
