import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'private-endpoint-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'private-endpoint-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'private-endpoint-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'private-endpoint-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'private-endpoint-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'private-endpoint-sspOrder',
    default: {
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        sector: null,
        environment: null,
        appTier: 'Azure Private Endpoint',
        appTierCode: 'PE',
        region: null,
        regionDisplayName: null,
        hCode: null,
        hCodeDetails: null,
        endPointType: null,
        endpointTypeDetails: null,
        resourceType: null,
        resourceTypeDetails: null,
        resource: null,
        resourceDetails: null,
        subscription: null,
        subscriptionId: null,
        targetSubResource: null,
        databricksSubscription: null,
        databricksSubscriptionId: null,
        databricks: null,
        envDetails: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'private-endpoint-environmentDetails',
    default: {
        Approval_Manager: '-',
        Project: '-',
    },
});

export const costEstimateState = atom({
    key: 'private-endpoint-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'private-endpoint-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isValidEndpoint = atom({
    key: 'private-endpoint-isValidEndpoint',
    default: false,
});

export const isValidDatabrickName = atom({
    key: 'private-endpoint-isValidDatabrickName',
    default: true,
});
