import React from 'react';
import { Box, Stack, Grow, Grid, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FaTrashAlt } from 'react-icons/fa';

import { orderPayload, selectedVSService, multiFieldValidation, validationStatus } from 'pages/provisioning/virtual-service/store';
import { addMultiHostNamePrefix, removeMultiHostNamePrefix, updateMultiHostNames } from 'pages/provisioning/virtual-service/store/selectors';
import { CssTextField } from 'components/select';

import Btn from 'components/button';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import ConfirmDialog from 'pages/provisioning/virtual-service/dialog-box/ConfirmDialog';
import InfoImg from 'components/info-img';

const MultiHostName = () => {
    const [isModalOpen, setModal] = React.useState(false);
    const [confirmModal, setConfirmModal] = React.useState(null);
    const [helperText, setHelperText] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const [order, setOrder] = useRecoilState(orderPayload);
    const [multiValdation, setMultiValidation] = useRecoilState(multiFieldValidation);
    const [validationErrorStatus] = useRecoilState(validationStatus);

    const [selectedVS] = useRecoilState(selectedVSService);
    const addHostNamePrefix = useSetRecoilState(addMultiHostNamePrefix);
    const removeHostName = useSetRecoilState(removeMultiHostNamePrefix);
    const updateMultiHostNamesField = useSetRecoilState(updateMultiHostNames);

    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.[activeIndex]?.hostNamePrefix &&
            order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.[activeIndex]?.hostNamePrefix !== null &&
            order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.[activeIndex]?.hostNamePrefix !== '' &&
            order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.[activeIndex]?.hostNamePrefix !== undefined &&
            !/^[a-zA-Z0-9-]{1,64}$/.test(order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.[activeIndex]?.hostNamePrefix)
        ) {
            setHelperText(true);
            setMultiValidation({
                ...multiValdation,
                hostNamePrefix: multiValdation?.hostNamePrefix?.map((item, index) => {
                    if (index === activeIndex) {
                        return {
                            ...item,
                            isInvalid: true,
                            isTouched: false,
                            message: 'It should have alpha numeric value with max 64 chars and can contain only hypen(-) as special chars.',
                        };
                    }
                    return item;
                }),
            });
        } else {
            setHelperText(false);
            setMultiValidation({
                ...multiValdation,
                hostNamePrefix: multiValdation?.hostNamePrefix?.map((item, index) => {
                    if (index === activeIndex) {
                        return { ...item, isInvalid: false, isTouched: false, message: '' };
                    }
                    return item;
                }),
            });
            const result = [];
            order?.aksVirtualService[selectedVS]?.hostNamesPrefix?.forEach((z, index) => {
                if (index === activeIndex) {
                    const updateIsTouched = { ...z, isHostNameTouched: false };
                    result.push(updateIsTouched);
                } else result.push(z);
            });
            setOrder({
                ...order,
                aksVirtualService: order?.aksVirtualService.map((vs, index) => {
                    if (index === selectedVS) {
                        return {
                            ...vs,
                            hostNamesPrefix: result,
                        };
                    }
                    return vs;
                }),
            });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.[activeIndex]?.hostNamePrefix]);

    const handleValueChange = (value, index, field) => {
        setActiveIndex(index);
        updateMultiHostNamesField({ value, index, selectedVS, field });
    };

    const handleAction = (type) => {
        if (type === 'yes') {
            removeHostName({ confirmModal, selectedVS });
        }
        setConfirmModal(null);
        setModal(false);
    };

    const checkDuplicate = (service) => {
        const hostNamesPrefix = order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix;
        const hostNamesPrefixList = hostNamesPrefix?.map((item) => item?.hostNamePrefix);
        const duplicateHostNamesPrefix = hostNamesPrefixList?.filter((item, index) => hostNamesPrefixList?.indexOf(item) !== index);
        if (service?.hostNamePrefix) {
            return duplicateHostNamesPrefix?.indexOf(service?.hostNamePrefix) > -1;
        }
        return false;
    };

    const getVSValidationStatus = (hostNamePrefix) => {
        const failedHostNames = [];
        if (validationErrorStatus?.length > 0) {
            validationErrorStatus?.filter((error) => {
                return error?.hostNames?.map((hostName) => {
                    if (hostName?.prefix === hostNamePrefix) {
                        failedHostNames.push(hostName);
                    }
                    return null;
                });
            });
            return failedHostNames;
        }
        return false;
    };
    const getErrorMessage = (hostName) => {
        const validationError = getVSValidationStatus(hostName);

        if (!helperText && validationError?.length > 0) {
            return (
                <>
                    {validationError?.map((error, errorIndex) => (
                        <React.Fragment key={`error-${errorIndex}`}>
                            {error?.msg?.map((msg, index) => (
                                <p key={index}>{msg}</p>
                            ))}
                        </React.Fragment>
                    ))}
                </>
            );
        }

        return '';
    };
    const getToolTip = () => {
        return (
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Hostname Prefix:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            A virtual service hostname prefix is used to derive a hostname which is an alias for a network service, enabling
                                            clients to connect without knowing the exact server. It supports load balancing and failover by directing traffic to
                                            the appropriate backend server.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle} />
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>1) Host Name Prefix should be unique.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            2) It can have alpha-numeric value with max 64 chars.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            3) It can only contain hyphen(-) as special chars.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            pep-cmp-dev-order-processor
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };

    const checkDuplicateAcrossVS = (service) => {
        const hostNamesPrefixList = [];
        order?.aksVirtualService?.forEach((item, index) => {
            if (index !== selectedVS) {
                hostNamesPrefixList.push(...item?.hostNamesPrefix);
            }
        });
        const hostNamePrefixListAcrossVS = hostNamesPrefixList?.map((item) => item?.hostNamePrefix)?.filter((item) => item !== null && item !== '');
        if (service?.hostNamePrefix) {
            return hostNamePrefixListAcrossVS?.indexOf(service?.hostNamePrefix) > -1;
        }
        return false;
    };

    return (
        <>
            {order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.map((service, index) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={`service-${index}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grow
                                in={order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length > 0}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length > 0 ? { timeout: 500 } : {})}
                            >
                                <Stack direction="row" spacing={2} key={index.toString()} alignItems="center">
                                    <Box display="flex" alignItems="center" gap={1} width="100%" data-testid={`hostname-box-${index}`}>
                                        <CssTextField
                                            size="small"
                                            fullWidth
                                            autoComplete="off"
                                            placeholder="pep-sector-env-service-name"
                                            label="Host Name Prefix"
                                            error={
                                                !!(
                                                    (helperText && activeIndex === index) ||
                                                    multiValdation?.hostNamePrefix?.[index]?.isInvalid ||
                                                    service?.isHostNameTouched ||
                                                    checkDuplicate(service) ||
                                                    checkDuplicateAcrossVS(service) ||
                                                    getErrorMessage(service?.hostNamePrefix)
                                                )
                                            }
                                            helperText={
                                                ((multiValdation?.hostNamePrefix?.[index]?.isInvalid || service?.isHostNameTouched) &&
                                                    multiValdation?.hostNamePrefix?.[index]?.message) ||
                                                (checkDuplicate(service) && 'Duplicate host name not allowed.') ||
                                                (checkDuplicateAcrossVS(service) &&
                                                    'Host name prefix already used in another Virtual service, please use unique one.') ||
                                                getErrorMessage(service?.hostNamePrefix)
                                            }
                                            value={service?.hostNamePrefix || ''}
                                            onChange={(e) => handleValueChange(e.target.value, index, 'hostNamePrefix')}
                                            className={
                                                (helperText && activeIndex === index) ||
                                                multiValdation?.hostNamePrefix?.[index]?.isInvalid ||
                                                multiValdation?.hostNamePrefix?.[index]?.isTouched ||
                                                checkDuplicate(service) ||
                                                checkDuplicateAcrossVS(service) ||
                                                getErrorMessage(service?.hostNamePrefix)
                                                    ? 'validate'
                                                    : ''
                                            }
                                        />
                                    </Box>
                                    {order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length > 1 && (
                                        <Box>
                                            {order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length > 1 ? (
                                                <FaTrashAlt
                                                    data-testid={`remove-hostname-${index}`}
                                                    style={ProvisioningStyles.vsType.delete}
                                                    onClick={() => {
                                                        setConfirmModal({ service, index });
                                                        setModal(true);
                                                    }}
                                                />
                                            ) : null}
                                        </Box>
                                    )}
                                    {getToolTip()}
                                </Stack>
                            </Grow>
                        </Grid>
                        <Grid item xs={12} sx={{ float: 'right', marginTop: '10px' }}>
                            {index === order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length - 1 &&
                            order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length < 5 ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 1 }}>
                                    <Btn
                                        onClick={() => addHostNamePrefix({ count: 1, selectedVS })}
                                        disabled={order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.length > 4}
                                        data-testid="add-hostname"
                                        variant="contained"
                                        color="cmpPrimary"
                                        sx={{ minWidth: '42px', width: 'auto', height: '32px' }}
                                    >
                                        {/* <AddIcon sx={{ fontSize: '25px' }} /> */}
                                        Add Hostname prefix
                                    </Btn>
                                </Box>
                            ) : null}{' '}
                        </Grid>
                    </Grid>
                </Box>
            ))}

            {isModalOpen && (
                <ConfirmDialog
                    isModalOpen={isModalOpen}
                    title="Are you sure you want to remove Hostname Prefix?"
                    primaryBtnText="Yes"
                    secondaryBtnText="No"
                    primaryBtnAction={() => handleAction('yes')}
                    secondaryBtnAction={() => handleAction('no')}
                    isCompact
                />
            )}
        </>
    );
};

export default MultiHostName;
