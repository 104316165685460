import { useTheme } from '@mui/material/styles';
import { Box, Select, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import React from 'react';

export default function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, handleChRowsPerPage, rowsPerPageOptions } = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    const handleChange = (event) => {
        handleChRowsPerPage(event);
    };
    return (
        <Box data-testid="table-pagination" sx={{ flexShrink: 0, ml: '-10px' }} className="paginationBtn">
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? (
                    <SkipNextIcon sx={{ color: '#000000', fontSize: 26 }} />
                ) : (
                    <SkipPreviousIcon sx={{ color: '#000000', fontSize: 26 }} />
                )}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight sx={{ color: '#000000', fontSize: 26 }} />
                ) : (
                    <KeyboardArrowLeft sx={{ color: '#000000', fontSize: 26 }} />
                )}
            </IconButton>
            <Select
                key="paginationSelect"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={handleChange}
                sx={{ height: '30px', width: '70px', borderRadius: '2px' }}
                data-testid="pagination-select"
            >
                {rowsPerPageOptions.map((e) => (
                    <MenuItem key={e} value={e}>
                        {e}
                    </MenuItem>
                ))}
            </Select>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft sx={{ color: '#000000', fontSize: 26 }} />
                ) : (
                    <KeyboardArrowRight sx={{ color: '#000000', fontSize: 26 }} />
                )}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? (
                    <SkipPreviousIcon sx={{ color: '#000000', fontSize: 26 }} />
                ) : (
                    <SkipNextIcon sx={{ color: '#000000', fontSize: 26 }} />
                )}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.any,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.array.isRequired,
    handleChRowsPerPage: PropTypes.func.isRequired,
};

TablePaginationActions.defaultProps = {
    count: 0,
};
