import React from 'react';
import { orderPayload, validProvisionForm } from 'pages/mdt-onboarding/store';
import { Stack, Box, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import styles from './style';

const DestinationEmail = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [helperMsg, setHelperMsg] = React.useState(false);
    const [showHelperText, setHelperText] = React.useState(false);
    const [validForm, setValidForm] = useRecoilState(validProvisionForm);

    const handleOnSelectChange = (selected) => {
        if (selected !== order?.destinationEmail) {
            setOrder({
                ...order,
                destinationEmail: selected,
            });
        }
    };

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.destinationEmail) || showHelperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (
            order?.destinationEmail &&
            order?.destinationEmail !== '' &&
            order?.destinationEmail !== undefined &&
            // eslint-disable-next-line
            !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(order?.destinationEmail)
        ) {
            setHelperText(true);
            setHelperMsg('Invalid Destination Email.');
            setValidForm({ ...validForm, isDestinationEmailValid: true });
        } else {
            setValidForm({ ...validForm, isDestinationEmailValid: false });
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.destinationEmail]);
    React.useEffect(() => {
        if (!order?.destinationEmail && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.destinationEmail, order?.isTouched]);
    return (
        <Stack direction="row" spacing={1} sx={styles.stack} data-testid="source-directory-text">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Destination Email *"
                autoComplete="off"
                error={isTouched || showHelperText}
                helperText={showHelperText && <span style={styles?.helpText}>{helperMsg}</span>}
                value={order?.destinationEmail || ''}
                onChange={(e) => handleOnSelectChange(e.target.value)}
                className={validateInput()}
            />
            <HelpImg
                title={
                    <>
                        <Box sx={styles.infoStyle.wrapper}>
                            <Typography sx={styles.infoStyle.typographyStyle}>Please enter a valid email.</Typography>
                            <Typography sx={styles.infoStyle.typographyStyle}>
                                <b>Example:</b> sample@email.com
                            </Typography>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

export default DestinationEmail;
