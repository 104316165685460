import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Typography } from '@mui/material';
import DialogModal from 'components/dialog';
// import toast from 'react-hot-toast';
import { getEnv } from 'utils';
import { useOktaAuth } from '@okta/okta-react';
import axios, { endPoints } from 'api/request';
import { FaInfoCircle } from 'react-icons/fa';
import { useIdleTimer } from 'react-idle-timer';

const SessionTimeout = (props) => {
    const { children } = props;
    const okta = useOktaAuth();
    const expireTime = getEnv('REACT_APP_EXPIRE_TIME');
    const sessionTime = getEnv('REACT_APP_SESSION_TIME');
    const [isExpired, setIsExpired] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [remaining, setRemaining] = React.useState(Number(sessionTime));

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * (sessionTime - expireTime),
        // eslint-disable-next-line no-use-before-define
        onIdle: () => handleOnIdle(),
        // eslint-disable-next-line no-use-before-define
        onActive: () => handleOnActive(),
        // eslint-disable-next-line no-use-before-define
        onAction: () => handleOnAction(),
        // crossTab: true,
    });

    const getTime = () => `${expireTime / 60} Minutes`;

    const handleLogOut = async () => {
        const newUser = localStorage.getItem('newUser');
        localStorage.clear();
        okta?.oktaAuth.tokenManager.clear();
        window.location.replace(getEnv('REACT_APP_OKTA_LOGOUT_URL'));
        localStorage.setItem('newUser', newUser);
    };

    const sessionHandler = async () => {
        setIsLoading(true);
        const data = await axios.get(endPoints.user.auth);
        if (data && data.isSuccess) {
            localStorage.setItem('accessToken', data.data.accessToken);
            localStorage.setItem('pages', JSON.stringify(data.data.authorizedTo));
            setIsLoading(false);
        } else {
            await handleLogOut();
        }
    };

    const handleSession = async () => {
        setIsLoading(true);
        try {
            await okta?.oktaAuth?.session.refresh();
            await sessionHandler();
        } catch (error) {
            // eslint-disable-next-line
            console.error('error in extending session', error);
            // toast.error('Failed to re-authenticate!');
            // await handleLogOut();
            await sessionHandler();
        }
        setIsExpired(false);
        setIsLoading(false);
        setRemaining(sessionTime);
    };

    const handleOnIdle = () => {
        // console.log('user is idle', );
        // eslint-disable-next-line
        console.log('last active', getLastActiveTime());
        setIsExpired(true);
    };

    const handleOnActive = async () => {
        // console.log('user is active', );
        // eslint-disable-next-line
        console.log('time remaining', getRemainingTime());
    };

    const handleOnAction = async () => {
        // console.log('user did something', event);
        // await handleSession();
        if (!isExpired) {
            setRemaining(Number(sessionTime));
        }
        // setIsExpired(false);
    };

    React.useEffect(() => {
        const timer = setTimeout(async () => {
            // console.log(remaining);
            if (Number(remaining) > 0) {
                setRemaining(remaining - 1);
                // if (remaining === Number(expireTime)) {
                //     setIsExpired(true);
                // }
            } else {
                await handleLogOut();
            }
        }, 1000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [remaining]);

    return (
        <>
            <DialogModal open={isExpired}>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <FaInfoCircle
                        style={{
                            color: '#A5A5A5',
                            marginBottom: 10,
                        }}
                        size={50}
                    />
                    <Typography variant="h5" gutterBottom>
                        Session Timeout Warning
                    </Typography>
                    <Typography gutterBottom align="center">
                        Your online session will expire in approximately five minutes.
                    </Typography>
                    <Typography gutterBottom align="center">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Click "Extend Session" to continue your online session or click "Log Off"
                    </Typography>
                    <Typography gutterBottom align="center">
                        to end your session now.
                    </Typography>
                    <Typography sx={{ display: 'none' }}>{getTime()}</Typography>
                    <Box my={2}>
                        <Button
                            onClick={() => handleSession()}
                            style={{ marginRight: 15 }}
                            disableElevation
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                        >
                            {!isLoading ? 'Extend Session' : 'Extending Session'}
                        </Button>
                        <Button onClick={() => handleLogOut()} variant="outlined" color="primary" disableElevation>
                            Log Off
                        </Button>
                    </Box>
                </Box>
            </DialogModal>
            {children && <>{children}</>}
        </>
    );
};

SessionTimeout.propTypes = {
    children: PropTypes.element,
};

SessionTimeout.defaultProps = {
    children: <span>content here</span>,
};

export default SessionTimeout;
