/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, Badge, Stack, Grid, Divider, Typography } from '@mui/material';
import Btn from 'components/button';
import Select, { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder, toggleSspModels, duplicateBucketType } from 'pages/provisioning/store/couchbase';
import { addBuckets, removeBuckets, updateBuckets } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import BucketNameModal from 'pages/provisioning/couchbase/BucketNameModal';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import HelpImg from 'pages/provisioning/_components/helpImg';

const AddBucket = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [, setIsDuplicateBucketType] = useRecoilState(duplicateBucketType);
    const [bucketTypeOptions, setBucketTypeOptions] = useState([]);
    // const [totalQuantityCount, setTotalCount] = useState(false);
    const [indexVal, setIndexVal] = React.useState(0);
    const addBucketData = useSetRecoilState(addBuckets);
    const removeBucketData = useSetRecoilState(removeBuckets);
    const updateBucketData = useSetRecoilState(updateBuckets);

    const getBucketTypes = useMutation(() => axios.get(endPoints.ssp.couchbase.bucketTypes));

    React.useEffect(() => {
        if (getBucketTypes?.isSuccess) {
            setBucketTypeOptions(getBucketTypes?.data?.data?.bucketTypes);
        }
    }, [getBucketTypes?.isSuccess]);

    React.useEffect(() => {
        getBucketTypes.mutate();
    }, []);

    // function isNumeric(value) {
    //     return /^-?\d+$/.test(value);
    // }
    const changeSelection = (key, selected, index, indexDel) => {
        updateBucketData({ key, selected, index, indexDel });
    };

    const isDuplicateBucketType = (value) => {
        const bucketValues = order?.buckets?.map((data) => data?.bucketType);
        const duplicateBucketTypes = bucketValues.filter((item, index) => bucketValues.indexOf(item) !== index);
        if (value) {
            setIsDuplicateBucketType(true);
            return duplicateBucketTypes.indexOf(value) > -1;
        }
        return false;
    };

    // React.useEffect(() => {
    //     setTotalCount(false);
    //     const totalCount = order.buckets?.reduce((a, b) => {
    //         /* eslint-disable no-nested-ternary */
    //         return a + parseInt(b.bucketQuantity === '' ? 0 : isNumeric(b.bucketQuantity) === false ? 0 : b.bucketQuantity, 10);
    //     }, 0);
    //     if (totalCount > 32) setTotalCount(true);
    // }, [order.buckets]);

    const badgeVariant = (index) => {
        if (order?.buckets?.[index]?.bucketType) {
            if (order?.buckets[index]?.bucketNames?.[0]?.name?.length === 0) {
                return 'dot';
            }
            return 'standard';
        }
        return '';
    };

    // const bucketQuantityCountValidation = (quantityCount, orderBucketData) => {
    //     if (quantityCount && (quantityCount !== '' || quantityCount !== null)) {
    //         const totalCount = orderBucketData.reduce((a, b) => {
    //             /* eslint-disable no-nested-ternary */
    //             return a + parseInt(b.bucketQuantity === '' ? 0 : isNumeric(b.bucketQuantity) === false ? 0 : b.bucketQuantity, 10);
    //         }, 0);
    //         if (totalCount > 32) return true;
    //     }
    //     return false;
    // };

    // const bucketQuantityDataTypeValidation = (quantityCount) => {
    //     if (quantityCount && (quantityCount !== '' || quantityCount !== null || !/^(?!(0))[0-9]+$/.test(quantityCount))) {
    //         if (!/^(?!(0))[0-9]+$/.test(quantityCount)) return true;
    //     }
    //     return false;
    // };

    const bucketSizeValidation = (quantitySize) => {
        if (quantitySize && (quantitySize !== '' || quantitySize !== null)) {
            if (!/^(?!(0))[0-9]+$/.test(quantitySize)) return true;
        }
        return false;
    };

    return (
        <>
            <Grid container spacing={2}>
                {order?.buckets?.map((bucketData, index) => (
                    <React.Fragment key={index.toString()}>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                    <Select
                                        key="bucketType"
                                        label="Bucket Type*"
                                        value={bucketData?.bucketType || null}
                                        handleOnSelect={(value) => changeSelection('bucketType', value, index)}
                                        options={bucketTypeOptions}
                                        data-testid="bucket-type"
                                        isRequired={bucketData?.isBucketTouched && !bucketData?.bucketType}
                                    />
                                </Stack>
                                {isDuplicateBucketType(bucketData?.bucketType) && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.couchbaseStyle.warningTypography}>
                                            Duplicate bucket type is not allowed.
                                        </Typography>
                                    </Grid>
                                )}
                                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                    <CssTextField
                                        size="small"
                                        autoComplete="off"
                                        label="Bucket Memory(in MB)*"
                                        value={bucketData?.bucketMemSize || null}
                                        data-testid="bucketMemSize"
                                        onChange={(e) => {
                                            changeSelection('bucketMemSize', e.target.value, index);
                                        }}
                                        className={
                                            bucketSizeValidation(bucketData?.bucketMemSize)
                                                ? 'validate'
                                                : '' || (bucketData?.isBucketTouched && !bucketData?.bucketMemSize ? 'validate' : '')
                                        }
                                        sx={{
                                            width: (theme) => theme.spacing('100%'),
                                            '& .MuiFormHelperText-root': {
                                                marginTop: (theme) => theme.spacing('-2'),
                                            },
                                            ' & label.MuiInputLabel-root.MuiInputLabel-shrink': {
                                                color: bucketData?.bucketMemSize && '#0047BA',
                                            },
                                        }}
                                    />
                                    <HelpImg title="Bucket size should contain only digits." />
                                </Stack>
                                {bucketSizeValidation(bucketData?.bucketMemSize) && (
                                    <Typography variant="body2" gutterBottom sx={ProvisioningStyles.couchbaseStyle.warningTypography}>
                                        Bucket size should be digits.
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" spacing={1} sx={ProvisioningStyles.modalBtnStyle.stack}>
                                <Grid container justifyContent="flex-start">
                                    <Box sx={ProvisioningStyles.modalBtnStyle.btnWrapper}>
                                        <Badge sx={ProvisioningStyles.modalBtnStyle.badgeStyle} color="error" variant={badgeVariant(index)}>
                                            <Btn
                                                className="btn-disabled custom-btn"
                                                fullWidth
                                                onClick={() => {
                                                    setIsOpen({ ...isOpen, isBucketNamesOpen: true });
                                                    setIndexVal(index);
                                                }}
                                                size="small"
                                                sx={ProvisioningStyles.modalBtnStyle.modalBtn}
                                                variant="contained"
                                                color="cmpPrimary"
                                                data-testid="add-bucketNames"
                                                disabled={!bucketData?.bucketType}
                                            >
                                                Add Bucket Names
                                            </Btn>
                                        </Badge>
                                    </Box>
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    {index !== 0 && order?.buckets?.length > 1 && (
                                        <Box sx={ProvisioningStyles.couchbaseStyle.deleteWrapper} mr={1}>
                                            <FaTrashAlt
                                                style={ProvisioningStyles.couchbaseStyle.delete}
                                                onClick={() => removeBucketData(index)}
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.buckets?.length - 1 && order?.buckets?.length < 2 ? (
                                        <Box sx={ProvisioningStyles.couchbaseStyle.addWrapper}>
                                            <MdAdd
                                                style={ProvisioningStyles.couchbaseStyle.add}
                                                onClick={() => addBucketData({ count: 1 })}
                                                className="w-7 h-7 text-blue-base cursor-pointer"
                                            />
                                        </Box>
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Grid>
                            </Stack>
                        </Grid>
                        {order?.buckets?.length > 1 && index !== order?.buckets?.length - 1 && (
                            <Grid item xs={12}>
                                <Divider style={{ borderColor: 'rgba(0, 0, 0, 0.30)' }} />
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
                <BucketNameModal indexVal={indexVal} />
            </Grid>
        </>
    );
};

export default AddBucket;
