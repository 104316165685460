// A custom theme for this app
import { createTheme } from '@mui/material';

const theme = createTheme({
    typography: {
        fontFamily: ["'Poppins', sans-serif"],
        // fontSize:{
        //     2:'2px',
        //     12:'12px',
        //     10:'10px',
        //     13:'13px',
        //     14:'14px',
        //     20:'20px',
        //     rem:{
        //        1:'0.5rem',
        //         2:'2rem',
        //         22:'2rem',
        //         3:'3rem',
        //         5:'5rem',
        //         12:'1.2rem',
        //         15:'1.5rem'
        //     }
        // }
    },

    palette: {
        cmpSuccess: {
            main: '#658835',
            dark: '#84A05D',
            contrastText: '#FFFFFF',
        },
        cmpDanger: {
            main: '#AE172B',
            dark: '#BE4555',
            contrastText: '#FFFFFF',
        },
        cmpPrimary: {
            main: '#0047BA',
            dark: '#00A0DD',
            contrastText: '#FFFFFF',
        },
        cmpPrimary2: {
            main: '#1478D4',
            dark: '#1478D4',
            contrastText: '#FFFFFF',
        },
        cmpSelected: {
            main: '#01408B',
            dark: '#01408B',
            contrastText: '#FFFFFF',
        },
        cmpBlack: {
            main: '#000000',
            dark: '#000000',
            contrastText: '#FFFFFF',
        },
        cmpWarning: {
            main: '#707070',
            dark: '#707070',
            contrastText: '#707070',
        },
        white: '#FFFFFF',
        black: {
            dark: '#000000',
            medium: '#1E1E1E',
        },
        yellow: '#F9E61E',
        gray: {
            dark: '#626262',
            medium: '#A5A5A5',
            lowMedium: '#CCCCCC',
            low: '#C8C8C8',
            light: '#E9E9E9',
            lighter: '#F8F8F8',
            lightest: '#E0E0E0',
            main: '#b0b0b0',
            44: '#707070',
            33: '#333333',
            darkest: '#a3a1a1',
            darker: '#E8E8E8',
            lightMedium: '#A5A5A5',
        },
        blue: {
            main: '#0047BA',
            30: '#003182',
            40: '#6690D5',
            60: '#001C4A',
            70: '#99B5E3',
            80: '#00358F',
            90: '#E5ECF8',
            alice: '#E3F0FF',
            dark: '#27344B',
            light: '#BFC6D6',
            lighter: '#66c6ea',
            lightest: '#AFB8CC',
            lightMedium: '#3381FF',
        },
        cyan: {
            main: '#00A0DD',
            30: '#003182',
            40: '#6690D5',
            60: '#001C4A',
            70: '#99D9F1',
            80: '#4D5970',
            90: '#E5F5FC',
        },
        green: {
            main: '#91C24C',
            30: '#658835',
            40: '#BDDA93',
            60: '#3A4E1E',
            70: '#D3E7B7',
            80: '#16BF5E',
            90: '#F4F9ED',
        },
        orange: {
            main: '#F99C24',
            30: '#AF6D19',
            40: '#FBC37B',
            60: '#643E0E',
            70: '#FDD7A7',
            90: '#FFF5E8',
        },
        pink: {
            main: '#E50695',
            30: '#A10568',
            40: '#EF69BF',
            60: '#5C023B',
            70: '#F59BD4',
            90: '#FDE5F4',
        },
        red: {
            main: '#DB1E36',
            30: '#991525',
            40: '#E97886',
            60: '#580C15',
            70: '#F1A5AE',
            90: '#FCE8EA',
            dark: '#d02424',
            light: '#FF3939',
        },
    },
});

export default theme;
