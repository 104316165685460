export const getNodeCatalogServiceCode = (osType) => {
    if (osType === 'RedHat,8_4') {
        return 'AZRHEL8VM';
    }
    if (osType === 'RedHat,7-LVM') {
        return 'AZRHEL7VM';
    }
    return '';
};
export default getNodeCatalogServiceCode;
