import React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { FiAlertTriangle } from 'react-icons/fi';
import { isDataDiskConfigFullfilled, sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/day2-operation';
import { removeDataDiskIndex, validateDisk } from 'pages/provisioning/store/day2-operation/selecters';
import ProvisioningStyles from 'pages/provisioning/vm/style';
import Btn from 'components/button';

const ConfirmDD = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const removeDiskIndex = useSetRecoilState(removeDataDiskIndex);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const validate = useSetRecoilState(validateDisk);
    const resetModalStore = useResetRecoilState(toggleSspModels);
    const [, setIsConfigDiskFulfilled] = useRecoilState(isDataDiskConfigFullfilled);

    const handleYes = () => {
        setOrder({
            ...order,
            dataDisksCount: modal?.isDataDisk?.newDataDiskCount,
        });
        let diskLength = order?.dataDisks?.length;
        if (modal?.isDataDisk?.deleteCount !== 0) {
            setIsConfigDiskFulfilled(modal?.isDataDisk?.newDataDiskCount.toString() === '0');
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < modal?.isDataDisk?.deleteCount; i++) {
                // eslint-disable-next-line no-plusplus
                diskLength--;
                removeDiskIndex(diskLength);
            }
        }
        setTimeout(() => {
            validate();
        }, 500);
        resetModalStore();
    };

    const handleNo = () => {
        setModal({ ...modal, isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 } });
    };
    return (
        <>
            <Dialog
                open={modal?.isDataDisk?.isOpen || false}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-testid="dialogModal"
            >
                <DialogContent>
                    <Box sx={ProvisioningStyles.confirmDD.dialogContentBox}>
                        <FiAlertTriangle color={ProvisioningStyles.Dialog.IconColor} size={70} />
                        <Typography variant="h5">Are you sure?</Typography>
                        <div className="text-gray-dark">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            You'll lose last {modal?.isDataDisk?.deleteCount} data disks from the list.
                            <br />
                            Do you want delete?
                        </div>
                    </Box>

                    <Box sx={ProvisioningStyles.confirmDD.dialogActionBox}>
                        <Btn
                            variant="contained"
                            onClick={() => handleYes()}
                            className="uppercase tracking-wider text-sm custom-btn"
                            color="cmpPrimary"
                            sx={{ textTransform: 'none' }}
                            data-testid="yes-btn"
                        >
                            Yes
                        </Btn>
                        <Btn
                            variant="outlined"
                            color="cmpWarning"
                            sx={ProvisioningStyles.confirmDD.dialogNoBtn}
                            onClick={() => handleNo()}
                            layout="outline"
                            className="uppercase tracking-wider text-sm custom-btn"
                            data-testid="no-btn"
                        >
                            No
                        </Btn>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default ConfirmDD;
