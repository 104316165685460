import { linuxSLESCatalogDefinitionId } from 'utils/catalogDefinitionId';

export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        cartItemId: data?.itemDetail?.cartItemId,
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: entityDetails?.costCenter,
            projectName: entityDetails?.project,
        },
        sector: data?.itemDetail?.catalogService?.sector || null,
        hCode: environmentDetails?.costCenter || null,
        ...(entityDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!entityDetails?.costCenter && { hCodeDetails: null }),
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        quantity: data?.itemDetail?.catalogService?.quantity,
        components: catalogConfig?.components?.reduce((obj, component) => ({ ...obj, [component.catalogService?.componentId]: component }), {}),
        tagsQc: catalogConfig?.tags,
        timeZone: data?.itemDetail?.catalogService?.timeZone,
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        domainNameService: catalogConfig?.isDnsRequired === true ? 'Yes' : 'No',
        fqdnName: catalogConfig?.fqdn || null,
        ipAddress: catalogConfig?.ipAddress || null,
        sslCertificate: catalogConfig?.sslCertificate || null,
        eceIntegration: catalogConfig?.isEceRequired === true ? 'Yes' : 'No',
        iTLDAPGroup: catalogConfig?.ldapGroup,
        cleIntegration: catalogConfig?.isCleRequired === true ? 'Yes' : 'No',
        conjureIntegration: catalogConfig?.isConjourRequired === true ? 'Yes' : 'No',
        ...(catalogConfig?.isConjourRequired === true && {
            safeName: catalogConfig?.safeName,
            sox: catalogConfig?.sox,
        }),
        catalogSize: catalogConfig?.catalogSize,
        serviceNowAssignmentGroup: catalogConfig?.serviceNowAssignmentGroup,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const environmentDetails = catalog.catalogConfig?.environmentDetails || null;
    const catalogConfig = catalog.catalogConfig || null;
    return {
        id: data?.id,
        catalogServiceId: catalog?.catalogServiceId,
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: catalog?.sector,
            projectName: entityDetails?.project,
            sector: catalog?.sector || null,
            billingEntity: environmentDetails?.billingEntity || null,
        },
        sector: catalog?.sector || null,
        billingEntity: environmentDetails?.billingEntity || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        quantity: catalog?.quantity,
        components: catalogConfig?.components?.reduce((obj, component) => ({ ...obj, [component.catalogService?.componentId]: component }), {}),
        tagsQc: catalogConfig?.tags,
        timeZone: data?.catalogService?.timeZone,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        domainNameService: catalogConfig?.isDnsRequired === true ? 'Yes' : 'No',
        fqdnName: catalogConfig?.fqdn || null,
        ipAddress: catalogConfig?.ipAddress || null,
        sslCertificate: catalogConfig?.sslCertificate || null,
        eceIntegration: catalogConfig?.isEceRequired === true ? 'Yes' : 'No',
        iTLDAPGroup: catalogConfig?.ldapGroup,
        cleIntegration: catalogConfig?.isCleRequired === true ? 'Yes' : 'No',
        conjureIntegration: catalogConfig?.isConjourRequired === true ? 'Yes' : 'No',
        ...(catalogConfig?.isConjourRequired === true && {
            safeName: catalogConfig?.safeName,
            sox: catalogConfig?.sox,
        }),
        catalogSize: catalogConfig?.catalogSize,
        serviceNowAssignmentGroup: catalogConfig?.serviceNowAssignmentGroup,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = ({ order, catalogCode, catalogServiceId }) => ({
    ...(catalogServiceId && { catalogServiceId }),
    cloudProvider: order?.cloudProvider,
    cloudService: order?.cloudService,
    catalogServiceCode: catalogCode,
    catalogType: order?.catalogType,
    sector: order?.sector,
    dnsName: order?.domainNameService === 'Yes',
    ...(order?.domainNameService === 'Yes' && {
        fqdn: order?.fqdnName,
        ipAddress: order?.ipAddress,
        sslCertificate: order?.sslCertificate,
        dnsName: order?.domainNameService,
    }),
    isEceRequired: order?.eceIntegration === 'Yes',
    ldapGroup: order?.iTLDAPGroup,
    isCleRequired: order?.cleIntegration === 'Yes',
    isConjourRequired: order?.conjureIntegration === 'Yes',
    ...(order?.conjureIntegration === 'Yes' && {
        safeName: order?.safeName,
        sox: order?.sox,
    }),
    catalogSize: order?.catalogSize,
    serviceNowAssignmentGroup: order?.serviceNowAssignmentGroup,
    quantity: order?.quantity,
    catalogConfig: {
        components: order?.components && Object.values(order?.components).filter((val) => val),
        connections: [
            {
                name: order?.envDetails?.config?.connections[0]?.name,
                targetComponent: order?.envDetails?.config?.connections[0]?.targetComponent,
                sourceComponent: order?.envDetails?.config?.connections[0]?.sourceComponent,
                connectionType: order?.envDetails?.config?.connections[0]?.connectionType,
            },
        ],
        environmentDetails: {
            applicationManager: order?.envDetails?.applicationManager,
            applicationManagerEmail: order?.envDetails?.applicationManagerEmail,
            applicationManagerGpid: order?.envDetails?.applicationManagerGpid,
            costCenter: order?.hCodeDetails?.label,
            projectName: order?.hCodeDetails?.projectName || null,
            region: order?.region,
            environment: order?.environment,
            landingZoneId: order?.landingZoneDetails?.id,
        },
    },
    estimatedCost: { ...order?.estimatedCost, type: catalogCode },
    entityDetails: {
        name: order?.appProfile,
        businessCi: order?.entityDetails?.businessCi,
        applicationManager: order?.entityDetails?.owner?.name,
        applicationManagerEmail: order?.entityDetails?.owner?.emailId,
        applicationManagerGpid: order?.entityDetails?.owner?.id,
        costCenter: order?.entityDetails?.financialDetails,
        project: order?.hCodeDetails?.projectName || null,
        recoveryPriority: order?.entityDetails?.recoveryPriority,
        billingEntity: order?.entityDetails?.billingEntity,
        sector: order?.entityDetails?.sector,
    },
});

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    environment: null,
    appTier: null,
    appTierCode: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    envDetails: null,
    quantity: 1,
    hCode: null,
    hCodeDetails: null,
    domainNameService: 'No',
    eceIntegration: 'No',
    cleIntegration: 'No',
    iTLDAPGroup: null,
    catalogSize: null,
    serviceNowAssignmentGroup: null,
    conjureIntegration: 'No',
    safeName: '',
    sox: 'No',
    fqdnName: null,
    ipAddress: null,
    sslCertificate: null,
    sector: null,
    sectorDetails: null,
    tags: [],
    estimatedCost: resetCostEstimateDetails,
    components: null,
    validationStatus: 'clearValidation',
    isTouched: false,
    cartItemId: null,
    id: null,
    catalogServiceId: null,
};

export const resetEnvironmentDetails = {
    Approval_Manager: '---',
    Project: '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
};
export const getSKUNameForSearch = ({ catalogCode }) => {
    if (linuxSLESCatalogDefinitionId?.includes(catalogCode)) {
        return 'SLES';
    }
    return 'OEL';
};

export const getComponentsValid = (components) => {
    let valid = true;
    Object.values(components)?.forEach((item) => {
        if (!item) valid = false;
    });
    return valid;
};
export const isReadyToSubmit = ({ order, isMandatoryTags, validFqdns, validLDAP }) =>
    !order?.appProfile ||
    !order?.environment ||
    !order?.sector ||
    !order?.region ||
    !order?.landingZoneDetails ||
    !order?.domainNameService ||
    (order?.domainNameService === 'Yes' && (!order?.ipAddress || !order?.fqdnName)) ||
    !order?.cleIntegration ||
    (order?.cleIntegration === 'Yes' && !order?.serviceNowAssignmentGroup) ||
    !order?.eceIntegration ||
    (order?.eceIntegration === 'Yes' && !order?.iTLDAPGroup) ||
    !order?.conjureIntegration ||
    (order?.conjureIntegration === 'Yes' && (!order?.safeName || !order?.sox)) ||
    isMandatoryTags ||
    validFqdns ||
    validLDAP ||
    !order?.components ||
    !(
        order?.components &&
        order?.config?.components
            ?.filter((item) => !item?.optional)
            ?.every((compt) => order?.components && Object?.keys(order?.components)?.includes(compt?.componentId))
    );
// (order?.components && Object.values(order?.components).filter((val) => val)?.length !== order?.config?.components?.filter(item=>!item?.optional));

export const previewEnvironmentDetails = (order = null) => ({
    'Other Configuration': {
        'Domain Name Service': order?.domainNameService,
        ...(order?.domainNameService === 'Yes' && {
            FQDN: order?.fqdnName,
        }),
        'ECE Integration': order?.eceIntegration,
        ...(order?.eceIntegration === 'Yes' && {
            'IT LDAP Group': order?.iTLDAPGroup,
        }),
        'CLE Integration': order?.cleIntegration,
        ...(order?.cleIntegration === 'Yes' && {
            'Catalog Size': order?.catalogSize,
            'ServiceNow Assignment Group': order?.serviceNowAssignmentGroup,
        }),
        'Conjure Integration': order?.conjureIntegration,
        ...(order?.conjureIntegration === 'Yes' && {
            'Safe Name': order?.safeName,
            SOX: order?.sox,
        }),
    },
    Approval_Manager: order?.envDetails?.applicationManager,
    Project: order?.hCodeDetails?.projectName ? order?.hCodeDetails?.projectName : '-',
    connections: order?.envDetails?.config?.connections?.filter((connection) =>
        order?.envDetails?.config?.components?.find(
            (component) => connection?.targetComponent === component?.name && order?.components?.[component?.componentId]
        )
    ),
});
