import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/sso-okta/store';

export const ApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const appServiceApi = useMutation((payload) => axios.post(`${endPoints.ssoOkta.applicationCI}`, payload));

    const setApplicationService = (applicationCI) => {
        setOrder({
            ...order,
            applicationCI,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (applicationCI) => options.find((option) => applicationCI === option.value);

    const handleOnSelectChange = (selected) => setApplicationService(selected.value);

    React.useEffect(() => {
        if (appServiceApi?.isSuccess) {
            setOptions(appServiceApi?.data?.data?.applications?.map((app) => ({ label: app?.serviceName, value: app?.serviceName })));
        }
    }, [appServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.applicationCI) {
            setOrder({ ...order, applicationCI: options?.[0].value });
        }
        if (options && options?.length && order?.applicationCI) {
            const applicationCI = isExistsInOptions(order?.applicationCI);
            setOrder({ ...order, applicationCI: applicationCI?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order?.businessApplicationDetails?.businessApplicationId) {
            appServiceApi.mutate({
                businessApplicationId: order?.businessApplicationDetails?.businessApplicationId,
                environment: order?.envShortName,
            });
        }
    }, [order?.businessApplicationDetails]);

    React.useEffect(() => {
        if (!order?.applicationCI && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationCI, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="ApplicationService"
                dataTestId="app-ci-autocomplete"
                label="Application Service *"
                value={order?.applicationCI || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={appServiceApi.isLoading || order?.businessApplicationDetails === null}
                isLoading={appServiceApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ApplicationService);
