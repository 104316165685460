import React from 'react';
import { useRecoilState } from 'recoil';
import { Dialog, DialogContent, Box, Typography, Button } from '@mui/material';
import { FiAlertTriangle } from 'react-icons/fi';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { cartStyle } from 'pages/cart/style';
import { deleteCartItemId, deleteModalPopup, cartCount, cart, deleteByApplication, costSummaryData } from 'pages/cart/store';

const DeleteConfirmModal = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModal] = useRecoilState(deleteModalPopup);
    const [deleteItemId, setDeleteCartItemId] = useRecoilState(deleteCartItemId);
    const [deleteByApp, setDeleteByApplication] = useRecoilState(deleteByApplication);
    const [, setCartCount] = useRecoilState(cartCount);
    const [, setCart] = useRecoilState(cart);
    const [, setCartData] = useRecoilState(costSummaryData);

    const handleDelete = async () => {
        setIsLoading(true);
        let deleteOrderItem;
        if (deleteItemId) {
            deleteOrderItem = await axios.delete(`${endPoints.cart.addItem}/${deleteItemId}`);
        }
        if (deleteByApp) {
            deleteOrderItem = await axios.post(endPoints.cart.deleteCartItemByApplication, { applicationName: deleteByApp });
        }
        if (deleteOrderItem?.isSuccess) {
            const updatedCartSummary = await axios.get(endPoints.cart.getCart);
            if (updatedCartSummary?.data) {
                setCart(updatedCartSummary?.data?.cartSummary);
                setCartData(updatedCartSummary?.data);
            } else {
                setCart([]);
            }
            const updatedCart = await axios.get(endPoints.cart.getCartCount);
            if (updatedCart?.data) {
                setCartCount(updatedCart?.data?.itemCount);
            } else {
                setCartCount(0);
            }
        }
        setIsLoading(false);
        setDeleteByApplication(undefined);
        setDeleteCartItemId(undefined);
        setIsDeleteModal(false);
    };

    return (
        <Dialog
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Box sx={cartStyle.dialog.wrapper}>
                    <FiAlertTriangle color={cartStyle.dialog.icon.color} size={70} />
                    <Typography variant="h5" data-testid="confirm">
                        Are you sure?
                    </Typography>
                    <div className="text-gray-dark">Are you sure want to delete this item from the cart?</div>
                </Box>

                <Box sx={cartStyle.dialog.backBtn}>
                    <Button
                        variant="contained"
                        sx={cartStyle.dialog.AgreeBtn}
                        onClick={() => handleDelete()}
                        layout="outline"
                        color="cmpPrimary"
                        disabled={isLoading}
                        className="uppercase tracking-wider text-sm"
                        data-testid="yes"
                    >
                        {`${!isLoading ? 'Yes' : 'Deleting...'}`}
                    </Button>
                    <Button
                        disabled={isLoading}
                        variant="outlined"
                        onClick={() => setIsDeleteModal(false)}
                        className="uppercase tracking-wider text-sm"
                        color="cmpWarning"
                        sx={cartStyle.dialog.noBtn}
                        data-testid="no"
                    >
                        No
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(DeleteConfirmModal);
