import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

function ErrorFallback({ error }) {
    const env = process.env.NODE_ENV;
    return (
        <Alert sx={{ width: '100%' }} severity="error">
            <AlertTitle>Something went wrong:</AlertTitle>
            {env === 'development' && <code>{error.message}</code>}
        </Alert>
    );
}

ErrorFallback.propTypes = {
    error: PropTypes.shape().isRequired,
};

ErrorFallback.defaultProps = {};

export default ErrorFallback;
