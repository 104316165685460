/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Grid, Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import OverallCount from './OverallCount';
// import ProvisionedServices from './ProvisionedServices';
import ProvisionedResources from './ProvisionedResources';
import ApplicationOnboarded from './ApplicationOnBoard';
import OrderCreationTrend from './OrderCreationTrend';
import DashboardDateRange from './dateRange';
import DashboardFilter from './filter';
import dashboardStyle from './style';

const DashboardNew = () => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Helmet>
            <title>Dashboard - CMP</title>
        </Helmet>
        <Box sx={dashboardStyle.dashboard_wrapper}>
            <Box sx={dashboardStyle.box_wrapper}>
                <Box sx={dashboardStyle.box_title}>
                    <Typography variant="h4" sx={dashboardStyle.dashboard_title}>
                        CMP DASHBOARD
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ display: 'flex' }}>
                        <DashboardDateRange />
                        <DashboardFilter />
                    </Stack>
                </Box>
                <Box sx={dashboardStyle.dashboard_container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <OverallCount />
                        </Grid>
                        <Grid item xs={6}>
                            <ApplicationOnboarded />
                        </Grid>
                        <Grid item xs={6}>
                            <ProvisionedResources />
                        </Grid>
                        <Grid item xs={12}>
                            <OrderCreationTrend />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    </ErrorBoundary>
);

export default DashboardNew;
