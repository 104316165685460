import React from 'react';
import Form from 'pages/app-profiles/landing-zones-new/components/Form';
import { Grid, Stack } from '@mui/material';

const AKSNamespace = () => (
    <>
        <Grid item xs={6}>
            <Stack direction="column" spacing={2}>
                <Form.Department />
                <Form.Region />
                <Form.BusinessUnit />
                {/* <Lz.ProjectName /> */}
                <Form.LandingZoneLabel />
            </Stack>
        </Grid>
        <Grid item xs={6}>
            <Stack direction="column" spacing={2}>
                <Form.Environments />
                <Form.Cluster />
                <Form.ISADocument />
                <Form.LandingZoneDesc />
            </Stack>
        </Grid>
    </>
);

export default AKSNamespace;
