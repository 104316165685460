import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import PreviewStyles from 'pages/provisioning/virtual-service/components/preview/style';
import { convertToTitleCase } from 'utils';
import ServiceDetails from 'pages/provisioning/virtual-service/components/preview/ServiceDetails';
import { virtualServiceTypes } from 'pages/provisioning/virtual-service/utils';
import VSAccordion from '../accordion';

const VSTypeDetails = React.memo(({ virtualServiceData, vsType, vsTypeIndex }) => {
    const getValue = (value) => {
        return value.map((data) =>
            data.virtualServiceTypeName !== virtualServiceTypes?.[2]?.name
                ? {
                      virtualServiceType: data?.virtualServiceType,
                      virtualServiceTypeName: data?.virtualServiceType,
                      serviceName: data?.aksService?.[0]?.serviceName,
                      virtualServiceName: data?.virtualServiceName,
                      hostNamesPrefix: data?.hostNamesPrefix,
                      customDomainSelected: data?.customDomainSelected,
                      ...(data?.customDomain && {
                          customDomainName: data?.customDomain?.[0]?.name,
                          certificateCreationRequired: data?.certificateCreationRequired ? 'Yes' : 'No',
                      }),
                      'CORS Policy': data?.corsPolicy,
                      aksService: data?.aksService,
                      ...(data?.aksCors && { aksCors: data?.aksCors }),
                  }
                : {
                      virtualServiceType: data?.virtualServiceType,
                      virtualServiceTypeName: data?.virtualServiceType,
                      virtualServiceName: data?.virtualServiceName,
                      hostNamesPrefix: data?.hostNamesPrefix,
                      customDomainSelected: data?.customDomainSelected,
                      ...(data?.customDomain && {
                          customDomainName: data?.customDomain?.[0]?.name,
                          certificateCreationRequired: data?.certificateCreationRequired ? 'Yes' : 'No',
                      }),
                      'CORS Policy': data?.corsPolicy,
                      aksService: data?.aksService,
                      ...(data?.aksCors && { aksCors: data?.aksCors }),
                  }
        );
    };

    const virtualServiceDetails = {};
    Object.entries(virtualServiceData).forEach(([key, value]) => {
        virtualServiceDetails[key] = getValue(value);
    });

    const getFieldValue = (key, vsDetails) => {
        if (key === 'hostNamesPrefix') {
            return vsDetails?.[key]?.map((item) => (vsDetails?.[key]?.length === 1 ? item : <li style={{ listStyle: 'none' }}>{item}</li>));
        }
        if (key === 'customDomainSelected') {
            return vsDetails?.[key] ? 'Yes' : 'No';
        }
        if (key === 'CORS Policy') {
            return vsDetails?.[key] ? 'Yes' : 'No';
        }
        return vsDetails?.[key];
    };
    return (
        <Card key={vsType} sx={{ ...PreviewStyles?.vsCard, padding: 2 }}>
            <Typography sx={PreviewStyles?.vsType}>{vsType}</Typography>
            {virtualServiceDetails?.[vsType]?.map((vsDetails, index) => (
                <React.Fragment key={`accordion-${index}`}>
                    <VSAccordion
                        title={`Service Requested - ${index < 9 ? `0${index + 1}` : `${index + 1}`}`}
                        key={index}
                        open={vsTypeIndex === 0 && index === 0}
                    >
                        <>
                            {Object.keys(vsDetails).map((key, idx) => (
                                <React.Fragment key={`${index}-${idx}`}>
                                    {!['virtualServiceType', 'virtualServiceTypeName', 'aksService', 'aksCors'].includes(key) && (
                                        <>
                                            <Divider variant="horizontal" sx={PreviewStyles?.divider} />
                                            <Grid container py={1}>
                                                <Grid item xs={12} sm={4} md={4}>
                                                    <Box sx={PreviewStyles?.keyText}>{convertToTitleCase(key)}:</Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8} md={8}>
                                                    <Box sx={PreviewStyles?.valueText}>{getFieldValue(key, vsDetails)}</Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    <ServiceDetails serviceKey={key} vsDetails={vsDetails} vsType={vsType} />
                                </React.Fragment>
                            ))}
                        </>
                    </VSAccordion>
                </React.Fragment>
            ))}
        </Card>
    );
});

VSTypeDetails.propTypes = {
    virtualServiceData: PropTypes.object.isRequired,
    vsType: PropTypes.string.isRequired,
    vsTypeIndex: PropTypes.any.isRequired,
};

export default VSTypeDetails;
