import React from 'react';
import { Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';
import Select, { CssTextField } from 'components/select';

const filter = createFilterOptions();
const BusinessApplication = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const bAppGetApi = useMutation(() => axios.get(`${endPoints.day2Operation.ProjectOnboarding.businessApp}?applicationName=${ccInputValue?.toUpperCase()}`));
    const bAppPostApi = useMutation((payload) => axios.post(`${endPoints.day2Operation.ProjectOnboarding.businessApp}`, payload));

    const setBusinessApplication = (businessApplication, businessApplicationDetails) => {
        setOrder({
            ...order,
            businessApplication,
            businessApplicationDetails,
            applicationService: order?.adoPlatform === 'Production' ? null : order?.applicationService,
            appOwner: null,
            appOwnerDetails: null,
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setBusinessApplication(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                bAppGetApi?.mutate();
            }, 1000),
        []
    );

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.businessApplication) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (bAppGetApi?.isSuccess) {
            if (bAppGetApi?.data?.data?.applications) {
                const combinedValue = bAppGetApi?.data?.data?.applications.map((app) => ({
                    label: `${app.businessApplicationName}`,
                    value: app?.businessApplicationName,
                    data: app,
                }));
                setOptions(combinedValue);
            }
        }
    }, [bAppGetApi?.isSuccess]);

    React.useEffect(() => {
        if (bAppPostApi?.isSuccess) {
            if (bAppPostApi?.data?.data?.applications) {
                const combinedValue = bAppPostApi?.data?.data?.applications.map((app) => ({
                    label: `${app.businessApplicationName}`,
                    value: app?.businessApplicationName,
                    data: app,
                }));
                if (order?.businessApplicationDetails) combinedValue.push(order?.businessApplicationDetails);
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [bAppPostApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.adoPlatform) {
            bAppPostApi?.mutate({
                pageOffset: 0,
                pageLimit: 20,
            });
        }
    }, [order?.adoPlatform]);

    const isExistsInOptions = (region) => {
        return options.find((option) => option?.label === region.label || option?.value === region);
    };

    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setBusinessApplication(value?.label, value?.data);
        }
    }, [value]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.businessApplication) {
            setOrder({ ...order, businessApplication: options?.[0].label, businessApplicationDetails: options?.[0]?.data });
        }
        if (options && options?.length && order?.businessApplicationDetails && !order?.businessApplication) {
            const businessApplication = isExistsInOptions(order?.businessApplicationDetails);
            setOrder({ ...order, businessApplication: businessApplication?.label, businessApplicationDetails: businessApplication?.data });
        }
    }, [options]);

    React.useEffect(() => {
        if (!order?.businessApplication && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessApplication, order?.isTouched]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Select
                    key="businessApplication"
                    size="small"
                    loading={bAppGetApi?.isLoading}
                    label="Business Application *"
                    value={order?.businessApplication || null}
                    disabled={order?.adoPlatform !== 'Production'}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                                value: newValue,
                                data: null,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                                value: newValue.inputValue,
                                data: null,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `"${inputValue}"`,
                            });
                        }
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    freeSolo
                    renderInput={(params) => (
                        <CssTextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Business Application *"
                            sx={{
                                '& label.MuiInputLabel-shrink': {
                                    color: '#0047BA !important',
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            fullWidth
                        />
                    )}
                    className={validateInput()}
                />
            </Box>
        </>
    );
};

export default React.memo(BusinessApplication);
