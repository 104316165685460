export const searchInChild = (objects, toSearch) => {
    // eslint-disable-next-line no-underscore-dangle
    let _finded = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < objects.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in objects[i]) {
            if (objects[i][key] != null && typeof objects[i][key] !== 'boolean' && typeof objects[i][key] !== 'number') {
                if (typeof objects[i][key] === 'object') {
                    _finded = searchInChild(objects[i][key], toSearch);
                } else if (objects[i][key].toLowerCase().includes(toSearch.toLowerCase())) {
                    _finded = true;
                }
            }
        }
    }
    return _finded;
};

export const findNested = (objects, toSearch) => {
    // eslint-disable-next-line no-underscore-dangle
    const _results = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < objects.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in objects[i]) {
            if (objects[i][key] != null && typeof objects[i][key] !== 'boolean' && typeof objects[i][key] !== 'number') {
                if (typeof objects[i][key] === 'object') {
                    if (searchInChild(objects[i][key], toSearch)) {
                        _results.push(objects[i]);
                    }
                } else if (objects[i][key].toLowerCase().includes(toSearch.toLowerCase())) {
                    _results.push(objects[i]);
                }
            }
        }
    }
    return _results;
};

export const getCatalogDetails = (catalog = {}) => ({
    'Catalog Sequence': catalog?.catalogSequence,
    'Catalog Service Code': catalog?.catalogDefinitionId,
    'Catalog Service Name': catalog.displayName,
    'Cloud Provider': catalog?.cloudProvider,
    'Cloud Service': catalog?.cloudService,
    Description: catalog?.description,
    Tags: catalog?.tags?.Catalog,
    isActive: catalog?.isActive ? 'True' : 'False',
});

export const statusArray = [
    { label: 'Active', value: 'Active' },
    { label: 'InActive', value: 'InActive' },
];

export const filterDefaultObj = {
    cloudProvider: null,
    tag: null,
    catalogServiceCode: null,
    status: null,
};
