/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress } from '@mui/material';
import { LineChart, Line, ResponsiveContainer, XAxis, Tooltip, LabelList, YAxis } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard/style';
import Chart from './components/Chart';
//     eslint-disable-next-line
import { orderCreationTrend, dateRangeValue, dashboardFilter } from './store';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const OrdersCreationTrend = () => {
    const [data, setData] = useRecoilState(orderCreationTrend);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);

    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.orderTrend, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            ...(dashboardFilterObj?.provider && dashboardFilterObj?.provider !== null && { provider: dashboardFilterObj?.provider }),
            ...(dashboardFilterObj?.service && dashboardFilterObj?.service !== null && { service: `${dashboardFilterObj?.service}*` }),
            ...(dashboardFilterObj?.sector && dashboardFilterObj?.sector !== null && { sector: dashboardFilterObj?.sector }),
            ...(dashboardFilterObj?.region && dashboardFilterObj?.region !== null && { region: dashboardFilterObj?.region }),
        };
        ordersTrendQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess) {
            const orderComp = ordersTrendQuery?.data?.data?.orderCompleted?.map((item) => item?.key_as_string) || [];
            const orderSub = ordersTrendQuery?.data?.data?.orderSubmitted?.map((item) => item?.key_as_string) || [];
            const getAllDatesData = orderComp?.length > orderSub?.length ? orderComp : orderSub;
            const dates = [...new Set([...getAllDatesData])];
            const results = dates?.map((item) => {
                let obj = { name: DateTime.fromISO(item, { zone: 'utc' }).toFormat('LLL-yyyy'), OrdersCompleted: 0, OrdersSubmitted: 0 };
                ordersTrendQuery?.data?.data?.orderCompleted?.map((orderCompleted) => {
                    if (orderCompleted?.key_as_string === item) {
                        obj = { ...obj, OrdersCompleted: orderCompleted?.doc_count };
                    }
                    return null;
                });
                ordersTrendQuery?.data?.data?.orderSubmitted?.map((orderSubmitted) => {
                    if (orderSubmitted?.key_as_string === item) {
                        obj = { ...obj, OrdersSubmitted: orderSubmitted?.doc_count };
                    }
                    return null;
                });
                return obj;
            });
            setData(results || []);
        }
    }, [ordersTrendQuery?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 5);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Chart w={12} title="Orders Trend">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {ordersTrendQuery.isLoading && (
                    <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                        <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                    </Box>
                )}
                {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                {!ordersTrendQuery.isLoading && data?.length > 0 && (
                    <Box sx={{ marginTop: 4, marginRight: 4 }}>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={data}>
                                <XAxis interval={0} padding={{ left: 10, right: 10 }} dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                                <YAxis padding={{ top: 10 }} />
                                <Tooltip />
                                <Line dataKey="OrdersSubmitted" dot={{ stroke: '#FB9906', strokeWidth: 5 }} stroke="#FB9906" strokeWidth={2}>
                                    <LabelList position="top" />
                                </Line>
                                <Line dataKey="OrdersCompleted" dot={{ stroke: '#0056BA', strokeWidth: 5 }} stroke="#0056BA" strokeWidth={2}>
                                    <LabelList position="top" />
                                </Line>
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(OrdersCreationTrend);
