import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload } from 'pages/provisioning/virtual-service/store';
import { platformManaged } from 'pages/provisioning/virtual-service/utils/getFunction';

export const Sector = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [options, setOptions] = React.useState([]);

    const sectorApi = useMutation(() => axios.get(`${endPoints.virtualService.sector}?platformManaged=${platformManaged}`));

    const setSector = (sector) => {
        setOrder({
            ...order,
            sector,
            division: null,
            environment: null,
            program: null,
            applicationRegion: null,
            department: null,
            aksCluster: null,
            aksNamespace: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sector) => options.find((option) => sector === option.value);

    const handleOnSelectChange = (selected) => setSector(selected.value);

    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            setOptions(sectorApi?.data?.data?.map((app) => ({ label: app?.sectorDisplayName, value: app?.sectorDisplayName })));
        }
    }, [sectorApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.sector) {
            setOrder({ ...order, sector: options?.[0].value });
        }
        if (options && options?.length && order?.sector) {
            const sector = isExistsInOptions(order?.sector);
            setOrder({ ...order, sector: sector?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        sectorApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.sector && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.sector, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="sector"
                dataTestId="sector-autocomplete"
                label="Sector *"
                value={order?.sector || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading}
                isLoading={sectorApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Sector);
