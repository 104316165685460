import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';
import LandingZoneStyle from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';

const filter = createFilterOptions();

const ServiceEndPointVNet = ({ index }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);

    const [options, setOptions] = React.useState([]);

    const vnetGetApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.Vnet}/location/${payload?.region}/subscription/${payload?.subscriptionId}`, payload)
    );
    const setEventHubVnet = (vNet, vNetDetails) => {
        const form = { ...landingZoneForm };
        try {
            const result = form.vnetSubnet.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        vNet: vNet !== '' || vNet !== null ? vNet : null,
                        vNetDetails: vNetDetails !== null ? vNetDetails : null,
                        subnet: null,
                        subnetDetails: null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    const seldValueExistsInOptions = (value) => options.find((option) => value === option?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEventHubVnet(selected?.label, selected);
        } else {
            setEventHubVnet();
        }
    };

    React.useEffect(() => {
        if (
            options?.length &&
            options?.length > 0 &&
            landingZoneForm &&
            (landingZoneForm?.vnetSubnet?.[index]?.vnet?.name || !landingZoneForm?.[index]?.vNet)
        ) {
            const vnet = options?.find((value) => landingZoneForm?.vnetSubnet?.[index]?.vnet?.name === value?.label);
            if (vnet) setEventHubVnet(vnet?.label, vnet);
        }
    }, [options]);

    React.useEffect(() => {
        if (vnetGetApi?.isSuccess) {
            if (vnetGetApi?.data?.data) {
                const combinedValue = vnetGetApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, resourceId: app?.resourceId }));
                setOptions(combinedValue);
            }
        }
    }, [vnetGetApi?.isSuccess]);
    React.useEffect(() => {
        if (
            landingZoneForm.region &&
            landingZoneForm?.vnetSubnet?.[index]?.networkSubscriptionDetails &&
            (!options?.length || !landingZoneForm?.vnetSubnet?.[index]?.vNet)
        ) {
            vnetGetApi.mutate({
                region: landingZoneForm?.regionDetails?.value,
                subscriptionId: landingZoneForm?.vnetSubnet?.[index]?.networkSubscriptionDetails?.id,
            });
        }
    }, [landingZoneForm.region, landingZoneForm?.vnetSubnet?.[index]?.networkSubscriptionDetails]);

    React.useEffect(() => {
        if (
            landingZoneForm?.isTouched &&
            ((!landingZoneForm?.vnetSubnet?.[index]?.vNet && landingZoneForm?.vnetSubnet?.[index]?.networkSubscription) || index >= 1)
        ) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.vnetSubnet, landingZoneForm?.isTouched]);
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Select
                    key="VNet"
                    noOptionsText="No options"
                    loading={vnetGetApi?.isLoading}
                    forcePopupIcon={false}
                    disabled={vnetGetApi?.isLoading || landingZoneForm?.dataClassification === 'Restricted'}
                    value={landingZoneForm?.vnetSubnet?.[index]?.vNet || null}
                    onChange={(_event, newValue) => {
                        if (typeof newValue === 'string') {
                            handleOnSelectChange(newValue);
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            handleOnSelectChange(newValue.inputValue);
                        } else {
                            handleOnSelectChange(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demoVnet"
                    options={options}
                    label="VNet"
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={LandingZoneStyle.select}
                    // freeSolo
                    disableClearable={false}
                    isRequired={isTouched}
                />
            </Stack>
        </>
    );
};

ServiceEndPointVNet.propTypes = {
    index: PropTypes.number,
};

ServiceEndPointVNet.defaultProps = {
    index: 0,
};

export default React.memo(ServiceEndPointVNet);
