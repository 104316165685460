import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import PEAccordion from 'pages/provisioning/private-endpoint-v2/components/accordion';
import Styles from 'pages/provisioning/private-endpoint-v2/style';
import LabelValue from 'pages/provisioning/private-endpoint-v2/components/label-value';
// import ResourceWrapper from './ResourceWrapper';

const MultipleResource = (props) => {
    const { resources, defaultExpanded, isLastPanelOpen, isOrderPage, isProvisionSection } = props;
    return (
        <Box>
            {Object.entries(resources)?.map(([key, value], index) => (
                <React.Fragment key={`main-res-${index}`}>
                    <PEAccordion
                        title={key}
                        key={`ri-resource-${index}`}
                        defaultExpanded={defaultExpanded}
                        open={defaultExpanded || (isLastPanelOpen && index === Object.keys(resources).length - 1)}
                        isOrderPage={isOrderPage}
                        isExpandIconML={!isOrderPage}
                    >
                        {value.map((resource, i) => (
                            <React.Fragment key={`sub-res-${index}-${i}`}>
                                <PEAccordion
                                    title={`Private Endpoint - ${i + 1 < 10 ? `0${i + 1}` : i + 1}`}
                                    key={`ri-sub-resource-${i}`}
                                    defaultExpanded={defaultExpanded}
                                    open={defaultExpanded || i === 0}
                                    isOrderPage={isOrderPage}
                                >
                                    <Box key={`box-sr-${index}-${i}`}>
                                        {(!isOrderPage || isProvisionSection) &&
                                            resource?.properties &&
                                            Object.entries(resource?.properties).map(([propKey, propValue], propertyIndex) => (
                                                <Box
                                                    className="ripreview-details"
                                                    sx={{
                                                        ...Styles?.preview?.resourceProperty,
                                                        // ...(isOrderPage && {
                                                        //     borderTop: 'none',
                                                        // }),
                                                    }}
                                                    key={`box-sr-${index}-${i}-${propertyIndex}`}
                                                >
                                                    <LabelValue istextTransform={false} width={220} label={propKey} value={propValue} />
                                                </Box>
                                            ))}
                                        {/* <Box className="ripreview-details" sx={Styles?.preview?.resourceProperty}>
                                            <Typography component="span" sx={Styles?.preview?.config}>
                                                Configuration:
                                            </Typography>
                                        </Box> */}
                                        <Divider variant="horizontal" sx={{ ...Styles?.preview?.divider, marginTop: 0 }} />
                                        {resource?.configuration && (
                                            <Grid container spacing={2}>
                                                {Object.entries(resource?.configuration).map(
                                                    ([configKey, configValue], configIndex) =>
                                                        configValue && (
                                                            <Grid
                                                                item
                                                                xs={isOrderPage ? 12 : 6}
                                                                key={`sub-res-config-${index}-${i}-${configIndex}-${configKey}`}
                                                            >
                                                                <Typography component="span" sx={Styles?.preview?.resourceType}>
                                                                    {configKey}
                                                                </Typography>
                                                                {Object.entries(configValue).map(([innerKey, innerValue], innerIndex) => (
                                                                    <Box className="ripreview-details" key={`box-${innerIndex}-${innerKey}`}>
                                                                        <LabelValue
                                                                            istextTransform={false}
                                                                            width={220}
                                                                            label={innerKey}
                                                                            value={innerValue}
                                                                            labelFlex={isOrderPage ? 1 : 2}
                                                                        />
                                                                    </Box>
                                                                ))}
                                                            </Grid>
                                                        )
                                                )}
                                            </Grid>
                                        )}
                                    </Box>
                                </PEAccordion>
                            </React.Fragment>
                        ))}
                    </PEAccordion>
                </React.Fragment>
            ))}
        </Box>
    );
};

MultipleResource.propTypes = {
    resources: PropTypes.any,
    defaultExpanded: PropTypes.bool,
    isLastPanelOpen: PropTypes.bool,
    isOrderPage: PropTypes.bool,
    isProvisionSection: PropTypes.bool,
};

MultipleResource.defaultProps = {
    resources: null,
    defaultExpanded: false,
    isLastPanelOpen: true,
    isOrderPage: false,
    isProvisionSection: false,
};

export default MultipleResource;
