import { atom } from 'recoil';

export const toggleSamRequest = atom({
    key: 'aksns-toggleSamRequest',
    default: false,
});

export const toggleQuickConfig = atom({
    key: 'aksns-toggleQuickConfig',
    default: false,
});

export const defaultVmDataDisk = atom({
    key: 'aksns-defaultVmDataDisk',
    default: undefined,
});
export const isDataDiskLoaded = atom({
    key: 'aksns-isDataDiskLoaded',
    default: false,
});
export const defaultOsDiskType = atom({
    key: 'aksns-defaultOsDiskType',
    default: undefined,
});
export const quickConfigDetails = atom({
    key: 'aksns-quickConfigDetails',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'aksns-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isDiskOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
        isErrorCode: false,
    },
});

export const isValidappSupportMailId = atom({
    key: 'aksns-isValidappSupportMailId',
    default: false,
});

export const isEnvDetailsLoading = atom({
    key: 'aksns-isEnvDetailsLoading',
    default: false,
});

export const isConfigDiskFulfilled = atom({
    key: 'aksns-isConfigDiskFulfilled',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'aksns-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'aksns-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'aksns-sspOrder',
    default: {
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        sector: null,
        environment: null,
        region: null,
        appSupportMailId: null,
        regionDisplayName: null,
        department: null,
        departmentDetails: null,
        cluster: null,
        clusterDetails: null,
        catalogSize: null,
        catalogSizeDetails: null,
        businessUnit: null,
        businessUnitDetails: null,
        isaDocument: null,
        project: '',
        hCode: null,
        hCodeDetails: null,
        adGroup: null,
        appTier: 'AKS Namespace',
        appTierCode: 'AN',
        envDetails: null,
        tags: [],
        estimatedCost: {},
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'aksns-environmentDetails',
    default: {
        Project: '-',
        Approval_Manager: '-',
        'ISA Document': '-',
    },
});
export const toggleCurrentServer = atom({
    key: 'aksns-toggleCurrentServer',
    default: false,
});

export const CurrentServerDetails = atom({
    key: 'aksns-CurrentServerDetails',
    default: null,
});
export const validationErrorCode = atom({
    key: 'aksns-validationErrorCode',
    default: null,
});

export const costEstimateState = atom({
    key: 'aksns-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'aksns-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
