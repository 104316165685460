import React from 'react';
import Modal from 'components/modal';
import PropTypes from 'prop-types';
import { useRequest } from 'hooks';
import { endPoints } from 'api/endpoints';
import { Box, CircularProgress } from '@mui/material';
import Empty from 'components/empty';
import LabelValue from 'components/label-value';
import Styles from 'pages/provisioning/_components/style';

const AppDetails = ({ isApplicationOpen, applicationService, handleCloseModal }) => {
    const { isLoading, data, isFetching, isError, refetch } = useRequest(
        {
            key: `get${applicationService}Details`,
            url: `${endPoints.ssp.applicationDetails}/${applicationService}`,
        },
        false
    );
    React.useEffect(() => {
        if (isApplicationOpen && applicationService) refetch();
    }, [isApplicationOpen]);
    return (
        <Modal title="Application Details" handleClose={() => handleCloseModal()} open={isApplicationOpen} width="55%">
            <>
                {!isLoading && isError && (
                    <>
                        <Empty notFound isPopup>
                            <span style={Styles.AppDetails.span}>Something went wrong!</span>
                        </Empty>
                    </>
                )}
                {!isLoading && !data?.data?.applications && (
                    <Empty notFound isPopup>
                        <span style={Styles.AppDetails.span}>No Data Found</span>
                    </Empty>
                )}
                {(isLoading || isFetching) && (
                    <Box sx={Styles.AppDetails.circularBox}>
                        <CircularProgress color="inherit" size={16} sx={Styles.AppDetails.cirPrg} />{' '}
                    </Box>
                )}
                {!isLoading &&
                    !isFetching &&
                    Object.keys(data?.data?.applications[0] || {}).map((key) => (
                        <Box className="app-details" key={`appDetails${key}`}>
                            <LabelValue key={[key]} label={key} value={data?.data?.applications[0][key]} />
                        </Box>
                    ))}
            </>
        </Modal>
    );
};

AppDetails.propTypes = {
    isApplicationOpen: PropTypes.bool,
    applicationService: PropTypes.string,
    handleCloseModal: PropTypes.func.isRequired,
};

AppDetails.defaultProps = {
    isApplicationOpen: true,
    applicationService: '',
};

export default React.memo(AppDetails);
