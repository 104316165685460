import {
    iaasVMCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    paasSQLCatalogServiceCode,
    adfCatalogServiceCode,
    storageAccountCatalogServiceCode,
    aksCatalogServiceCode,
    iaasCouchbaseServiceCode,
    keyvaultCatalogServiceCode,
    AlbCatalogServiceCode,
    eventhubCatalogServiceCode,
    kafkaCatalogServiceCode,
    eipStackCatalogServiceCode,
} from 'utils/catalogServiceCode';

export const getSubmitData = ({ data, labelValid, descValid, ISADocumentValid, appNameValid, projectNameVaild, ldapVaild }) => {
    if (!data?.provider) {
        return true;
    }
    if (!data?.service) {
        return true;
    }
    if (data?.service === iaasVMCatalogServiceCode || data?.service === iaasOracleCatalogServiceCode || data?.service === iaasSQLCatalogServiceCode) {
        if (
            !data?.environment ||
            !data?.region ||
            !data?.subscription ||
            !data?.resGroup ||
            !data?.vNet ||
            !data?.subNet ||
            !data?.landingZoneLabel ||
            data?.landingZoneLabel === '' ||
            data?.landingZoneDesc === '' ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid
        )
            return true;
        if (!data?.availSet && !data?.availZoneDetails?.length) return true;
    }
    if (data?.service === iaasCouchbaseServiceCode) {
        if (
            !data?.resGroup ||
            !data?.vNet ||
            !data?.subNet ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            data?.landingZoneLabel === '' ||
            data?.landingZoneDesc === '' ||
            !labelValid ||
            !descValid
        )
            return true;
        if (!data?.availZoneDetails?.length) return true;
    }
    if (data?.service === adfCatalogServiceCode) {
        if (!data?.resGroup || !data?.dataClassification || !data?.landingZoneLabel || !data?.landingZoneDesc || !labelValid || !descValid) return true;
    }
    if (data?.service === paasSQLCatalogServiceCode) {
        const isValidVnetSubnet = () => {
            let invalidVnetSubnet = false;
            if (data?.vnetSubnet?.length === 1) {
                data?.vnetSubnet?.forEach((z) => {
                    if (z?.vNet === null && z?.subnet === null && z?.networkSubscription === null) invalidVnetSubnet = false;
                    if (z?.networkSubscription !== null && (z?.vNet === null || z?.subnet === null)) invalidVnetSubnet = true;
                    if (z?.networkSubscription === null && (z?.vNet !== null || z?.subnet !== null)) invalidVnetSubnet = false;
                });
            }
            if (data?.vnetSubnet?.length > 1) {
                data?.vnetSubnet?.forEach((z) => {
                    if (z?.vNet === null || z?.subnet === null || z?.networkSubscription === null) invalidVnetSubnet = true;
                });
            }
            return invalidVnetSubnet;
        };

        const isDuplicateVnetSubnetValue = () => {
            if (data?.vnetSubnet?.length > 1)
                return data?.vnetSubnet
                    ?.map((value) => value?.vNet + value?.subnet + value?.networkSubscription)
                    ?.some((val, index, array) => array.indexOf(val) !== array.lastIndexOf(val));
            return false;
        };

        if (
            !data?.environment ||
            !data?.region ||
            !data?.subscription ||
            !data?.resGroup ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid ||
            isValidVnetSubnet() ||
            isDuplicateVnetSubnetValue()
        )
            return true;
    }

    if (data?.service === storageAccountCatalogServiceCode) {
        const isValidVnetSubnet = () => {
            let invalidVnetSubnet = false;
            if (data?.vnetSubnet?.length === 1) {
                data?.vnetSubnet?.forEach((z) => {
                    if (z?.vNet === null && z?.subnet === null && z?.networkSubscription === null) invalidVnetSubnet = false;
                    if (z?.networkSubscription !== null && (z?.vNet === null || z?.subnet === null)) invalidVnetSubnet = true;
                    if (z?.networkSubscription === null && (z?.vNet !== null || z?.subnet !== null)) invalidVnetSubnet = false;
                });
            }
            if (data?.vnetSubnet?.length > 1) {
                data?.vnetSubnet?.forEach((z) => {
                    if (z?.vNet === null || z?.subnet === null || z?.networkSubscription === null) invalidVnetSubnet = true;
                });
            }
            return invalidVnetSubnet;
        };
        const isDuplicateVnetSubnetValue = () => {
            if (data?.vnetSubnet?.length > 1)
                return data?.vnetSubnet
                    ?.map((value) => value?.vNet + value?.subnet + value?.networkSubscription)
                    ?.some((val, index, array) => array.indexOf(val) !== array.lastIndexOf(val));
            return false;
        };

        if (
            !data?.resGroup ||
            !data?.dataClassification ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid ||
            isValidVnetSubnet() ||
            isDuplicateVnetSubnetValue()
        )
            return true;
    }
    if (data?.service === aksCatalogServiceCode) {
        if (
            !data?.environment ||
            !data?.businessUnit ||
            !data?.department ||
            !data?.region ||
            // !form?.projectName ||
            // !projectNameVaild ||
            !ISADocumentValid ||
            !data?.cluster ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid
        )
            return true;
    }

    if (data?.service === keyvaultCatalogServiceCode) {
        if (
            !data?.resGroup ||
            !data?.vNet ||
            !data?.subNet ||
            !data?.dataClassification ||
            !data?.networkConnectivity ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !appNameValid ||
            !labelValid ||
            !descValid
        )
            return true;
    }

    if (data?.service === AlbCatalogServiceCode) {
        if (
            !data?.resGroup ||
            !data?.vNet ||
            !data?.subNet ||
            !data?.sku ||
            !data?.type ||
            !appNameValid ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid
        )
            return true;
    }

    if (data?.service === eventhubCatalogServiceCode) {
        let invalidVnetSubnet = false;
        data?.vnetSubnet?.forEach((z) => {
            if (z?.vNet === null || z?.subnet === null) invalidVnetSubnet = true;
        });
        const isDuplicateVnetSubnetValue = () =>
            data?.vnetSubnet?.map((value) => value?.vNet + value?.subnet)?.some((val, index, array) => array.indexOf(val) !== array.lastIndexOf(val));
        if (
            !data?.environment ||
            !data?.region ||
            (data?.environment === 'Production' && !data?.geoRecoveryRegion) ||
            !data?.subscription ||
            !data?.resGroup ||
            !data?.dataClassification ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid ||
            invalidVnetSubnet ||
            data?.dataClassification === 'Restricted' ||
            isDuplicateVnetSubnetValue()
        )
            return true;
    }
    if (data?.service === kafkaCatalogServiceCode) {
        if (
            !data?.environment ||
            !data?.businessUnit ||
            !data?.region ||
            !data?.department ||
            !data?.cluster ||
            !data?.projectName ||
            !data?.ISADocument ||
            !data?.ldap ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !projectNameVaild ||
            !ISADocumentValid ||
            !ldapVaild ||
            !labelValid ||
            !descValid
        )
            return true;
    }
    if (data?.service === eipStackCatalogServiceCode) {
        let invalidComponents = false;
        let invalidConnections = false;
        let invalidMandatoryComponents = false;
        let mandatoryErrorCount = 0;
        data?.components?.forEach((item) => {
            if (item?.landingZone) {
                data?.connections?.forEach((conn) => {
                    let errorCount = 0;
                    if (conn?.targetComponentName === item?.service) {
                        if (conn?.connectionType === null || conn?.connectionType === '' || conn?.connectionType === undefined) {
                            errorCount += 1;
                        }
                        invalidComponents = errorCount !== 0;
                    }
                });
            }

            if (!item?.optional && (item?.landingZone === null || item?.landingZone === '' || item?.landingZone === undefined)) {
                mandatoryErrorCount += 1;
            }
            invalidMandatoryComponents = mandatoryErrorCount !== 0;
        });
        data?.connections?.forEach((item) => {
            if (item?.connectionType) {
                data?.components?.forEach((comp) => {
                    let errorCount = 0;
                    if (comp?.service === item?.targetComponentName) {
                        if (comp?.landingZone === null || comp?.landingZone === '' || comp?.landingZone === undefined) {
                            errorCount += 1;
                        }
                        invalidConnections = errorCount !== 0;
                    }
                });
            }
        });
        if (
            !data?.environment ||
            !data?.region ||
            !data?.dataClassification ||
            !data?.landingZoneLabel ||
            !data?.landingZoneDesc ||
            !labelValid ||
            !descValid ||
            invalidComponents ||
            invalidMandatoryComponents ||
            invalidConnections
        )
            return true;
    }
    return false;
};

export default getSubmitData;
