import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Box, Grid } from '@mui/material';
import Select, { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const ThroughputUnits = () => {
    const { cartItemId, operation, catalogServiceId } = useParams();
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const throughputUnitsApi = useMutation(() => axios.get(`${endPoints.ssp.eventHub.throughputUnits}`));

    const SetThroughputUnits = (throughputUnits) => {
        setOrder({
            ...order,
            throughputUnits,
        });
    };

    const handleOnSelectChange = (selected) => {
        SetThroughputUnits(selected.value);
    };

    React.useEffect(() => {
        if (throughputUnitsApi?.isSuccess) {
            if (throughputUnitsApi?.data?.data?.unitsList) setOptions(throughputUnitsApi?.data?.data?.unitsList.map((val) => ({ label: val, value: val })));
        }
    }, [throughputUnitsApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.nsExist === 'no' && order?.performance === 'Standard') {
            if (!cartItemId && !operation && !catalogServiceId) SetThroughputUnits('1');
            throughputUnitsApi.mutate();
        }
    }, [order?.nsExist, order?.performance]);

    React.useEffect(() => {
        if (!order?.throughputUnits && order?.isTouched) setIsTouched(true);
    }, [order?.throughputUnits, order?.isTouched]);

    const getHelpText = () => (
        <>
            <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            The throughput capacity of Event Hubs is controlled by throughput units. Throughput units are pre-purchased units of capacity.{' '}
                            <br />
                            A single throughput unit lets you: <br />
                            Ingress: Up to 1 MB per second or 1000 events per second (whichever comes first). <br />
                            Egress: Up to 2 MB per second or 4096 events per second.
                        </Grid>
                        <Grid item xs={12}>
                            <a
                                href="https://learn.microsoft.com/en-us/azure/event-hubs/event-hubs-scalability#throughput-units"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Learn more
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {order?.nsExist === 'no' ? (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <Select
                        key="throughputUnits"
                        label="Throughput Units *"
                        value={order?.throughputUnits || null}
                        options={options}
                        handleOnSelect={(value) => handleOnSelectChange(value)}
                        isRequired={isTouched}
                    />
                    <HelpImg maxmWidth="450px" title={getHelpText()} />
                </Stack>
            ) : (
                <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        label="Throughput Units *"
                        value={order?.throughputUnits || null}
                        disabled
                    />
                    <HelpImg maxmWidth="450px" title={getHelpText()} />
                </Stack>
            )}
        </>
    );
};
export default React.memo(ThroughputUnits);
