import { getRepoName } from './getFunctions';

export const formatSector = (sector) => {
    return sector.replace(/ /g, '_').replace(/&/g, 'n');
};

export const isReadyToSubmit = ({ order, isValidForm }) => {
    const {
        sector,
        adoPlatform,
        isADOProject,
        projectName,
        projectDetails,
        existingProjectName,
        isClarityId,
        clarityDetails,
        adoProjectType,
        applicationService,
        applicationName,
        appShortName,
        applicationOwner,
        serviceResources,
        adminAccessMemberList,
        sonarQubeUserList,
        progLanguage,
        buildTool,
        packageType,
        artifactoryUserList,
        teamName,
        applicationUrl,
        securityTool,
        fsMemberList,
        hCode,
        testPlanUserList,
    } = order;
    const { isClarityIdValid, isProjectNameValid, isAppNameValid, isAppShortNameValid, isAppUrlValid, isInvalidServiceAccount } = isValidForm;
    const isFormInfoIncomplete =
        !sector ||
        !adoPlatform ||
        !applicationService ||
        !applicationName ||
        !applicationOwner ||
        (adoPlatform === 'Production' &&
            (!isADOProject ||
                (adoPlatform === 'Production' && isClarityId === 'yes' && clarityDetails && clarityDetails?.devopsProject !== null
                    ? !existingProjectName
                    : !projectName) ||
                (isADOProject === 'no' && !isClarityId) ||
                !adoProjectType));
    const isFormInvalid = (isClarityId === 'yes' && !isClarityIdValid) || isProjectNameValid || isAppNameValid;
    const isProjectAdminIncomplete =
        (adoPlatform === 'Sandbox/Personal' && serviceResources?.isProjectAdmin && adminAccessMemberList?.length === 0) ||
        (adoPlatform === 'Production' &&
            ((isADOProject === 'no' && isClarityId === 'no') ||
                projectDetails?.projectAdmin?.memberList?.length < 5 ||
                projectDetails?.projectAdmin === null ||
                projectDetails?.projectAdmin?.memberList === null) &&
            serviceResources?.isProjectAdmin &&
            adminAccessMemberList?.length === 0) ||
        (adoPlatform === 'Production' && projectDetails?.projectAdmin?.memberList?.length >= 5 && !serviceResources?.isProjectAdmin);
    const isSonarQubeIncomplete =
        serviceResources?.isSonarQube &&
        (!appShortName ||
            isAppShortNameValid ||
            isInvalidServiceAccount ||
            (order?.isADOProject === 'no' && sonarQubeUserList?.length === 0) ||
            (order?.isADOProject === 'no' && order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) ||
            (order?.isADOProject === 'yes' && order?.isServiceAccountExists === 'yes' && order?.serviceAccount === null) ||
            (order?.onboardMembers && order?.sonarQubeUserList?.length === 0) ||
            (order?.onboardRepositories && order?.sonarQubeCodeRepoList?.some((repo) => repo?.repo === null || repo?.repo === '')));

    const isArtifactoryIncomplete =
        serviceResources?.isArtifactory &&
        (!appShortName || isAppShortNameValid || !progLanguage || !buildTool || !packageType || artifactoryUserList?.length === 0);
    const isFortifySnykIncomplete =
        serviceResources?.isSecurityTools && (!teamName || !applicationUrl || isAppUrlValid || !securityTool || fsMemberList?.length === 0);
    const isTestPlanIncomplete = serviceResources?.isTestPlan && (!hCode || testPlanUserList?.length === 0);
    const isServiceResourceFormInValid = !(
        serviceResources?.isProjectAdmin ||
        serviceResources?.isBoard ||
        serviceResources?.isRepos ||
        serviceResources?.isPipelines ||
        serviceResources?.isSonarQube ||
        serviceResources?.isArtifactory ||
        serviceResources?.isSecurityTools ||
        serviceResources?.isTestPlan
    );

    return (
        isFormInfoIncomplete ||
        isServiceResourceFormInValid ||
        isFormInvalid ||
        isProjectAdminIncomplete ||
        isSonarQubeIncomplete ||
        isArtifactoryIncomplete ||
        isFortifySnykIncomplete ||
        isTestPlanIncomplete
    );
};

export const getOrderPayload = ({ order }) => {
    const isProjectSector =
        order?.adoPlatform === 'Sandbox/Personal' ||
        (order?.adoPlatform === 'Production' &&
            ((order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
                (order?.isADOProject === 'no' &&
                    order?.isClarityId === 'yes' &&
                    order?.clarityDetails &&
                    (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                        order?.clarityDetails?.devopsProject === null ||
                        order?.clarityDetails?.devopsProject === undefined))));
    const getProjectName = () => {
        let projectNameVal = null;
        if (isProjectSector) projectNameVal = `${formatSector(order?.sector)}_${order?.projectName}`;
        else if (
            order?.adoPlatform === 'Production' &&
            order?.isClarityId === 'yes' &&
            order?.clarityDetails &&
            order?.clarityDetails?.devopsProject !== null
        ) {
            projectNameVal = order?.existingProjectName;
        } else projectNameVal = order?.projectName;
        return projectNameVal;
    };

    const setClarityId = (object) => {
        if (object?.isClarityId === 'yes') {
            return true;
            // eslint-disable-next-line
        } else if (object?.isClarityId === 'no') {
            return false;
        } else {
            return null;
        }
    };

    const setExistingProject = (object) => {
        if (object?.isADOProject === 'yes') {
            return true;
            // eslint-disable-next-line
        } else if (object?.isADOProject === 'no') {
            return false;
        } else {
            return null;
        }
    };

    const getName = () => {
        if (order?.existingProjectName) return getRepoName(order?.existingProjectName);
        return getRepoName(order?.projectName);
    };

    const getOnboardRepoStatus = (object) => {
        if (object?.onboardRepositories === 'yes' || object?.onboardRepositories === true) {
            return 'yes';
        }
        return null;
    };

    const payload = {
        sector: order?.sector,
        adoPlatform: order?.adoPlatform,
        existingProject: setExistingProject(order),
        projectName: getProjectName(),
        existingProjectName: order?.existingProjectName,
        existingClarityId: setClarityId(order),
        clarityId: order?.isClarityId === 'yes' ? order?.clarityId : null,
        clarityDetails: order?.clarityDetails
            ? {
                  docType: order?.clarityDetails?.docType,
                  projectNumber: order?.clarityDetails?.projectNumber,
                  projectStatus: order?.clarityDetails?.projectStatus,
                  devopsProject: order?.clarityDetails?.devopsProject,
              }
            : null,
        adoProjectType: order?.adoProjectType,
        applicationName: order?.applicationName,
        applicationShortName: order?.appShortName,
        businessApplication: order?.businessApplication,
        applicationService: order?.applicationService,
        applicationOwner: order?.applicationOwnerDetails?.gpId,
        boards: order?.serviceResources?.isBoard,
        repos: order?.serviceResources?.isRepos,
        pipelines: order?.serviceResources?.isPipelines,
        projectAdmin: {
            members: order?.adminAccessMemberList || null,
            existingAdminMembers: order?.projectDetails?.projectAdmin ? order?.projectDetails?.projectAdmin?.memberList : null,
            adGroupName: order?.projectDetails?.projectAdmin?.name || null,
        },
        sonarQube: order?.serviceResources?.isSonarQube
            ? {
                  members: order?.sonarQubeUserList,
                  codeRepositories:
                      order?.sonarQubeCodeRepoList?.length > 0 && order?.onboardRepositories
                          ? order?.sonarQubeCodeRepoList
                                ?.filter((item) => item?.repo !== '')
                                .map((item) => ({
                                    name: getName(),
                                    url: item?.repo,
                                }))
                          : [],
                  onboardRepositories: getOnboardRepoStatus(order),
                  isServiceAccountExists: order?.isServiceAccountExists,
                  serviceAccount: order?.serviceAccount,
              }
            : null,
        artifactory: order?.serviceResources?.isArtifactory
            ? {
                  programmingLanguage: order?.progLanguage,
                  buildTool: order?.buildTool,
                  packageTypes: order?.packageType,
                  members: order?.artifactoryUserList,
              }
            : null,
        securityTool: order?.serviceResources?.isSecurityTools
            ? {
                  securityToolRequired: order?.securityTool,
                  teamName: order?.teamName,
                  applicationURL: order?.applicationUrl,
                  codeRepository:
                      order?.securityToolCodeRepoList?.[0]?.repo !== '' && order?.securityToolCodeRepoList?.[0]?.repo !== null
                          ? order?.securityToolCodeRepoList?.map((item) => ({
                                name: 'https://dev.azure.com/PepsiCoIT',
                                url: item?.repo,
                            }))
                          : null,
                  members: order?.fsMemberList,
              }
            : null,
        testPlans: order?.serviceResources?.isTestPlan
            ? {
                  cmpHCode: order?.hCode,
                  members: order?.testPlanUserList,
              }
            : null,
    };
    return payload;
};

export const resetOrder = {
    sector: null,
    adoPlatform: null,
    isADOProject: null,
    projectName: null,
    projectDetails: null,
    existingProjectName: null,
    isClarityId: null,
    clarityId: null,
    clarityDetails: null,
    adoProjectType: null,
    serviceAccount: null,
    applicationName: null,
    appShortName: null,
    businessApplication: null,
    businessApplicationDetails: null,
    applicationService: null,
    applicationOwner: null,
    applicationOwnerDetails: null,
    existingADOServices: null,
    serviceResources: {
        isProjectAdmin: false,
        isBoard: false,
        isRepos: false,
        isPipelines: false,
        isSonarQube: false,
        isArtifactory: false,
        isSecurityTools: false,
        isTestPlan: false,
    },
    adminAccessMember: null,
    adminAccessMemberDetails: null,
    adminAccessMemberList: [],
    allowedAdminMembers: 5,
    courseDetails: null,
    teamName: null,
    applicationUrl: null,
    securityTool: null,
    securityToolCodeRepoList: [{ repo: '', isRepoTouched: false }],
    fsMember: null,
    fsMemberDetails: null,
    fsMemberList: [],
    hCode: null,
    hCodeDetails: null,
    testPlanUser: null,
    testPlanUserDetails: null,
    testPlanUserList: [],
    isCostApiLoading: false,
    repoName: null,
    sonarQubeUser: null,
    sonarQubeUserDetails: null,
    sonarQubeUserList: [],
    sonarQubeCodeRepo: null,
    sonarQubeRepoList: [],
    progLanguage: null,
    progLanguageDetails: null,
    buildTool: null,
    buildToolDetails: null,
    packageType: null,
    packageTypeDetails: null,
    artifactoryUser: null,
    artifactoryUserDetails: null,
    artifactoryUserList: [],
    isConfigureTouched: false,
    isServiceAccountExists: false,
    onboardServiceAccount: false,
    onboardRepositories: false,
    onboardMembers: false,
    estimatedCost: {
        details: [],
        qty: null,
        totalOneTimeCost: {
            frequency: 'one time',
            estCost: null,
            currency: 'USD',
        },
        totalRecurringCost: {
            frequency: 'monthly',
            estCost: null,
            currency: 'USD',
        },
        notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
    },
    isTouched: false,
};

export const resetValidOnboardingForm = {
    isClarityIdValid: false,
    isProjectNameValid: false,
    isProjectNameExists: false,
    isAppNameValid: false,
    isAppShortNameValid: false,
    isAppShortNameExist: false,
    isAppUrlValid: false,
    isRepoNameValid: false,
    isDuplicateAdminAccessMember: false,
    isDuplicateFSMember: false,
    isDuplicateTestPlanUser: false,
    isDuplicateSonarQubeUser: false,
    isDuplicateArtifactoryUser: false,
    isAdminAccessMaxMemberLimit: false,
    isFSMaxMemberLimit: false,
    isTestPlanMaxMemberLimit: false,
    isSonarQubeMaxMemberLimit: false,
    isArtifactoryMaxMemberLimit: false,
};

export const resetToggleModal = {
    isProjectAdminOpen: false,
    isSonarQubeOpen: false,
    isArtifactoryOpen: false,
    isFortifySnykOpen: false,
    isTestPlanOpen: false,
    isSuccessOpen: false,
    action: '',
    isCost: { costType: '', isCostOpen: false },
    orderId: null,
};

export const restCostEstimate = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

const getProjectName = (values) => {
    if (
        values?.adoPlatform === 'SandBox/Personal' ||
        (values?.adoPlatform === 'Production' &&
            ((!values?.existingProject && !values?.existingClarityId) ||
                (!values?.existingProject && values?.existingClarityId && values?.clarityDetails?.devopsProject === null)))
    ) {
        return values?.projectName.replace(`${formatSector(values?.sector)}_`, '');
    }
    return values?.projectName;
};

const getIsADOProject = (object, param) => {
    if (object?.existingProject !== undefined) {
        if (param === 'formObj') return object?.existingProject === true ? 'yes' : 'no';
        return object?.existingProject === true;
    }
    return null;
};

const getIsClarityId = (object, param) => {
    if (object?.existingClarityId !== undefined) {
        if (param === 'formObj') return object?.existingClarityId === true ? 'yes' : 'no';
        return object?.existingClarityId === true;
    }
    return null;
};

const getClarityId = (object) => {
    if (object?.existingClarityId !== undefined) {
        return object?.clarityId ? object?.clarityId : '**NO PPMID**';
    }
    return null;
};

const getSonarRepos = (object) => {
    if (object?.codeRepositories?.length > 0) {
        return object?.codeRepositories?.map((item) => ({
            repo: item?.url,
        }));
    }
    return [{ repo: '', isRepoTouched: false }];
};

const getOnboadServiceAccount = (object) => {
    if (object?.isServiceAccountExists === 'yes' || object?.isServiceAccountExists === 'no') {
        return true;
    }
    return false;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    if (data?.catalogServices?.[0]?.catalogConfig) {
        const response = data?.catalogServices?.[0]?.catalogConfig;
        return {
            sector: response?.sector,
            adoPlatform: response?.adoPlatform,
            isADOProject: response?.existingProject !== undefined ? getIsADOProject(response, 'formObj') : null,
            projectName: response?.existingProjectName === undefined ? getProjectName(response) : null,
            existingProjectName: response?.existingProjectName,
            isClarityId: response?.existingClarityId !== undefined ? getIsClarityId(response, 'formObj') : null,
            clarityId: getClarityId(response),
            clarityDetails: response?.clarityDetails || null,
            adoProjectType: response?.adoProjectType,
            applicationName: response?.applicationName,
            appShortName: response?.applicationShortName,
            businessApplication: response?.businessApplication,
            applicationService: response?.applicationService,
            applicationOwner: data?.manager?.name,
            applicationOwnerDetails: { owner: data?.manager?.name, gpId: data?.manager?.id },
            serviceResources: {
                isProjectAdmin: response?.projectAdmin?.members !== undefined,
                isBoard: response?.boards || false,
                isRepos: response?.repos || false,
                isPipelines: response?.pipelines || false,
                isSonarQube: response?.sonarQube !== undefined,
                isArtifactory: response?.artifactory !== undefined,
                isSecurityTools: response?.securityTool !== undefined,
                isTestPlan: response?.testPlans !== undefined,
            },
            securityTool: response?.securityTool?.securityToolRequired,
            sonarQubeCodeRepo: response?.sonarQube?.codeRepository?.url,
            sonarQubeCodeRepoList: getSonarRepos(response?.sonarQube),
            onboardRepositories: response?.sonarQube?.onboardRepositories,
            isServiceAccountExists: response?.sonarQube?.isServiceAccountExists,
            onboardServiceAccount: getOnboadServiceAccount(response?.sonarQube),
            onboardMembers: response?.sonarQube?.members?.length > 0,
            serviceAccount: response?.sonarQube?.serviceAccount,
            applicationUrl: response?.securityTool?.applicationURL,
            teamName: response?.securityTool?.teamName,
            sonarQubeRepoList: [],
            adminAccessMemberList: response?.projectAdmin?.members || [],
            fsMemberList: response?.securityTool?.members || [],
            sonarQubeUserList: response?.sonarQube?.members || [],
            artifactoryUserList: response?.artifactory?.members || [],
            progLanguage: response?.artifactory?.programmingLanguage,
            progLanguageDetails: response?.progLanguageDetails,
            buildTool: response?.artifactory?.buildTool,
            packageType: response?.artifactory?.packageTypes,
            buildToolDetails: response?.buildToolDetails,
            packageTypeDetails: response?.packageTypeDetails,
            testPlanUserList: response?.testPlans?.members || [],
            hCode: response?.testPlans?.cmpHCode,
            hCodeDetails: response?.hCodeDetails,
            securityToolCodeRepoList: response?.securityTool?.codeRepository?.map((item) => ({
                repo: item?.url,
            })) || [{ repo: '', isRepoTouched: false }],
            isTouched: false,
        };
    }

    return null;
};

export const getProjectOnboardingResubmitPayload = ({ order }) => {
    const response = order?.catalogServices?.[0]?.catalogConfig;
    const payload = {
        sector: response?.sector,
        adoPlatform: response?.adoPlatform,
        existingProject: response?.existingProject !== undefined ? getIsADOProject(response) : null,
        projectName: response?.existingProjectName === undefined ? getProjectName(response) : null,
        existingProjectName: response?.existingProjectName,
        existingClarityId: response?.existingClarityId !== undefined ? getIsClarityId(response) : null,
        clarityId: response?.clarityId !== undefined ? response?.clarityId : null,
        clarityDetails: response?.clarityDetails || null,
        adoProjectType: response?.adoProjectType,
        applicationService: response?.applicationService,
        applicationName: response?.applicationName,
        applicationShortName: response?.applicationShortName,
        applicationOwner: order?.manager?.id,
        boards: response?.boards,
        repos: response?.repos,
        pipelines: response?.pipelines,
        projectAdmin: response?.projectAdmin
            ? {
                  members: response?.projectAdmin?.members || [],
                  existingAdminMembers: response?.projectAdmin?.existingAdminMembers || [],
                  adGroupName: response?.projectAdmin?.adGroupName || null,
              }
            : null,
        sonarQube: response?.sonarQube
            ? {
                  members: response?.sonarQube?.members,

                  codeRepository: response?.sonarQube?.codeRepository
                      ? {
                            name: 'https://dev.azure.com/PepsiCoIT',
                            url: response?.sonarQube?.codeRepository?.url,
                        }
                      : null,
              }
            : null,

        artifactory: response?.artifactory
            ? {
                  programmingLanguage: response?.artifactory?.programmingLanguage,
                  buildTool: response?.artifactory?.buildTool,
                  packageTypes: response?.artifactory?.packageTypes,
                  members: response?.artifactory?.members || null,
              }
            : null,
        securityTool: response?.securityTool
            ? {
                  securityToolRequired: response?.securityTool?.securityToolRequired,
                  teamName: response?.securityTool?.teamName,
                  applicationURL: response?.securityTool?.applicationURL,
                  codeRepository:
                      response?.securityTool?.codeRepository?.map((item) => ({
                          name: 'https://dev.azure.com/PepsiCoIT',
                          url: item?.url,
                      })) || null,
                  members: response?.securityTool?.members || null,
              }
            : null,
        testPlans: response?.testPlans
            ? {
                  cmpHCode: response?.testPlans?.cmpHCode,
                  members: response?.testPlans?.members || null,
              }
            : null,
    };
    return payload;
};
