import React from 'react';
import { Box, Badge, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toggleSspModels, isSkuConfigFullfilled, sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import { isSkuFormFilled } from 'pages/provisioning/day2-operation/utils';
import Form from 'pages/provisioning/day2-operation/components/Form';

const ConfigureSKU = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [isSkuConfig, setIsSkuConfig] = useRecoilState(isSkuConfigFullfilled);
    const order = useRecoilValue(sspSubmitOrder);
    const getVariant = () => {
        if (!order?.configureNewSku) return 'standard';
        if (isSkuConfig === false) return 'dot';
        return 'standard';
    };
    React.useEffect(() => {
        setIsSkuConfig(!isSkuFormFilled({ order }) && !isOpen?.isSkuModalOpen);
    }, [order?.skuConfig, order?.maxIops, order?.ram, order?.dataDiskSupport, order?.premiumDiskSupport]);
    return (
        <>
            <Stack direction="row" spacing={1} my={2} sx={ProvisioningStyles.configure.stack}>
                <Box sx={ProvisioningStyles.configure.boxWrapper}>
                    <Form.ConfigureNewSku />
                </Box>
                <Box sx={ProvisioningStyles.configure.btnWrapper}>
                    <Badge sx={ProvisioningStyles.configure.badgeWidth} color="error" variant={getVariant()}>
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            onClick={() => setIsOpen({ ...isOpen, isSkuModalOpen: true })}
                            size="small"
                            sx={ProvisioningStyles.configure.configureBtn}
                            variant="contained"
                            color="cmpPrimary"
                            disabled={!order?.configureNewSku}
                        >
                            Configure *
                        </Btn>
                    </Badge>
                </Box>
            </Stack>
        </>
    );
};

export default ConfigureSKU;
