/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';
import ProvisioningStyles from 'pages/provisioning/storage/style';

const LandingZones = ({ index, appProfileName }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);

    const landingZoneApi = useMutation((payload) => axios.post(`${endPoints.landingZones.landingZones}`, payload));
    const setLandingZone = (landingZone, landingZoneDetails) => {
        const form = { ...landingZoneForm };
        try {
            const result = form.components.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = { ...obj, landingZone, landingZoneDetails };
                }
                return obj;
            });
            form.components = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }

        setLandingZoneForm(form);
    };
    const resetOptions = () => {
        setOptions([]);
    };

    async function setPreconfig() {
        if (landingZoneForm?.components?.[index].landingZoneDetails) {
            const checkExist = await options.find((option) => landingZoneForm?.components?.[index].landingZoneDetails?.id === option?.data?.id);
            if (checkExist) {
                await setLandingZone(checkExist?.value, checkExist?.data);
            }
        }
    }
    const handleOnSelectChange = (selected) => {
        setLandingZone(selected?.value, selected?.data);
    };
    React.useEffect(() => {
        if (landingZoneApi?.isSuccess) {
            setOptions(landingZoneApi?.data?.data?.landingZones?.map((app) => ({ label: app.label, value: app.label, data: app })));
        }
    }, [landingZoneApi?.isSuccess]);

    React.useEffect(() => {
        if (options && !landingZoneForm?.components?.[index].landingZone && landingZoneForm?.components?.[index].landingZoneDetails) {
            setPreconfig();
        }
    }, [options, landingZoneForm?.components?.[index].landingZone]);

    React.useEffect(() => {
        resetOptions();
        if (
            appProfileName &&
            landingZoneForm.environment &&
            landingZoneForm?.regionDetails?.value &&
            landingZoneForm?.components?.[index]?.service &&
            landingZoneForm?.components?.[index]?.provider
        ) {
            landingZoneApi.mutate({
                appProfileName,
                environment: landingZoneForm.environment,
                region: landingZoneForm?.regionDetails?.value,
                cloudProvider: landingZoneForm?.components?.[index]?.provider,
                cloudService: landingZoneForm?.components?.[index]?.service,
            });
        }
    }, [
        appProfileName,
        landingZoneForm.environment,
        landingZoneForm?.regionDetails?.value,
        landingZoneForm?.components?.[index]?.provider,
        landingZoneForm?.components?.[index]?.service,
    ]);

    React.useEffect(() => {
        if (landingZoneForm?.components?.[index]?.landingZone === undefined) {
            const connections = landingZoneForm?.connections?.map((item) => {
                if (item?.targetComponentName === landingZoneForm?.components?.[index]?.service) {
                    return { ...item, connectionType: null };
                }
                return item;
            });
            setLandingZoneForm({ ...landingZoneForm, connections });
        }
    }, [landingZoneForm?.components?.[index]?.landingZone]);

    React.useEffect(() => {
        if (!landingZoneForm?.components?.[index]?.landingZone && landingZoneForm?.isTouched && !landingZoneForm?.components?.[index]?.optional)
            setIsTouched(true);
    }, [landingZoneForm?.components?.[index]?.landingZone, landingZoneForm?.isTouched]);

    return (
        <Stack direction="row" sx={ProvisioningStyles.stack}>
            <Select
                key="landingZoneDetails"
                label={landingZoneForm?.components?.[index]?.optional ? 'Landing Zone' : 'Landing Zone*'}
                value={landingZoneForm?.components?.[index]?.landingZone || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disableClearable={false}
                disabled={landingZoneApi.isLoading}
                isLoading={landingZoneApi.isLoading}
                isRequired={isTouched}
            />
        </Stack>
    );
};

LandingZones.propTypes = {
    index: PropTypes.number,
    appProfileName: PropTypes.string,
};

LandingZones.defaultProps = {
    index: 0,
    appProfileName: '',
};

export default React.memo(LandingZones);
