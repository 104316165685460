import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import Btn from 'components/button';
import { useRecoilState } from 'recoil';
import ErrorBanner from 'components/ErrorBanner';
import { IsReadyToSubmit, getPayload } from '../utils';
import { requestDetails, successPopup, isValidApplicationName } from '../store';
import styles from './style';

const SubmitRequestBtn = () => {
    const [isLoading, setisLoading] = React.useState(false);
    const [details, setDetails] = useRecoilState(requestDetails);
    const [, setSuccessModal] = useRecoilState(successPopup);
    const [validApplication, setValidApplication] = useRecoilState(isValidApplicationName);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const submitApi = useMutation((payload) => axios.post(endPoints?.onBoardDevelopers?.Jwt?.submitOrder, payload));
    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };
    React.useEffect(() => {
        if (submitApi?.data?.data) {
            setSuccessModal({ orderId: submitApi?.data?.data?.orderId, isOpen: true });
            setDetails({ ...details, isTouched: false });
            setValidApplication(false);
            setisLoading(false);
        } else {
            setisLoading(false);
            setSuccessModal({ orderId: null, isOpen: false });
            setDetails({ ...details, isTouched: true });
        }
    }, [submitApi?.isSuccess]);

    const handleValidation = () => {
        if (!IsReadyToSubmit({ details, validApplication })) {
            setisLoading(true);
            const payload = getPayload({ details });
            submitApi.mutate(payload);
        } else {
            setDetails({
                ...details,
                isTouched: true,
            });
            handleClickErrorBanner();
        }
    };

    return (
        <Box>
            <Btn
                size="medium"
                onClick={() => handleValidation()}
                variant="contained"
                disabled={isLoading}
                sx={styles?.submitBtn}
                color="cmpPrimary"
                data-testid="update-cart-btn"
            >
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Request'}
            </Btn>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit request."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Box>
    );
};

export default SubmitRequestBtn;
