/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';

const AvailabilitySet = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const availSetApi = useMutation((payload) => axios.post(endPoints.landingZones.availabilitySet, payload));
    const setAvailSetApi = (availSet = null, availSetDetails = null) => {
        setLandingZoneForm({
            ...landingZoneForm,
            availSet,
            availSetDetails,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        setAvailSetApi();
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setAvailSetApi(selected?.label, selected);
        } else {
            setAvailSetApi();
        }
    };
    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.availSet) {
            setLandingZoneForm({ ...landingZoneForm, availSet: options?.[0].label });
        }
        if (options && options?.length && landingZoneForm?.availSet) {
            const availSet = seldValueExistsInOptions(landingZoneForm?.availSet);
            setLandingZoneForm({ ...landingZoneForm, availSet: availSet?.label });
        }
    }, [options]);
    React.useEffect(() => {
        if (availSetApi?.isSuccess) {
            setOptions(availSetApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [availSetApi?.isSuccess]);

    React.useEffect(() => {
        if (landingZoneForm?.region && landingZoneForm?.resGroup && landingZoneForm.subscriptionDetails?.id) {
            resetOptions();
            availSetApi.mutate({
                subscriptionId: landingZoneForm.subscriptionDetails?.id,
                resourceGroup: landingZoneForm?.resGroup,
                location: landingZoneForm?.regionDetails?.value,
            });
        }
    }, [landingZoneForm?.region, landingZoneForm?.resGroup, landingZoneForm.subscriptionDetails]);

    React.useEffect(() => {
        if (!landingZoneForm?.availSet && landingZoneForm?.isTouched && !landingZoneForm?.availZone) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.availSet, landingZoneForm?.isTouched, landingZoneForm?.availZone]);
    return (
        <Box bgcolor="white">
            <Select
                key="AvailabilitySet"
                label="Availability Set"
                value={landingZoneForm?.availSet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={availSetApi.isLoading}
                isLoading={availSetApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(AvailabilitySet);
