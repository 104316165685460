/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CssTextField } from 'components/select';
import { Box } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { orderPayload } from 'pages/mdt-onboarding/store';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/mdt-onboarding/components/helpImg';
import styles from './style';

const TokenExpiry = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [isTouched, setIsTouched] = React.useState(false);

    const handleOnSelect = (val) => {
        setOrder({ ...order, tokenExpiry: Date.parse(val) });
    };

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.tokenExpiry) {
            return 'validate';
        }
        return '';
    };

    const onKeyDown = (e) => {
        e.preventDefault();
    };

    React.useEffect(() => {
        if (!order?.tokenExpiry && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.tokenExpiry, order?.isTouched]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" alignItems="center" gap={1}>
                <DatePicker
                    inputFormat="yyyy-MM-dd"
                    label="Token Expiry *"
                    value={order?.tokenExpiry || null}
                    onChange={(value) => {
                        handleOnSelect(value);
                    }}
                    minDate={new Date()}
                    className={validateInput()}
                    renderInput={(params) => (
                        <CssTextField
                            {...params}
                            onKeyDown={onKeyDown}
                            error={isTouched}
                            className={validateInput()}
                            sx={{ ...styles.textField, svg: { color: '#0047BA' } }}
                            autoComplete="off"
                            placeholder=""
                            data-testid="Token"
                        />
                    )}
                />

                <HelpImg title="Please click calender icon to choose token expiry." />
            </Box>
        </LocalizationProvider>
    );
};

export default TokenExpiry;
