const ProvisioningStyles = {
    card: {
        paper: {
            padding: (theme) => theme.spacing(2),
            background: (theme) => theme?.palette?.gray?.lighter,
            height: (theme) => theme.spacing('100%'),
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },

        box: { mb: (theme) => theme.spacing(0.375) },
        typography: { marginBottom: (theme) => theme.spacing(0.25) },
    },
    infoLabel: { display: 'block', vaiant: (theme) => theme.typography.caption.fontSize },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    provisioningBox: {
        // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: '90.43px', height: '93.43px' },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
    resubmitBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
    },
    Dialog: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        icon: { color: '#d02424' },
        backBtn: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        AgreeBtn: { marginLeft: (theme) => theme.spacing(1.25) },
    },
    box: {
        bgcolor: (theme) => theme.palette.white,
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    infoIcon: { color: '#0047BA', fontSize: '19px', marginTop: '5px' },
    helpertext: { display: 'flex', fontSize: 12, wordBreak: 'break-word' },
    helperFtext: { display: 'flex', fontSize: 12, wordBreak: 'break-all' },
    helperExtext: { display: 'flex', flexDirection: 'column', fontSize: 12, wordBreak: 'break-all', textAlign: 'left' },
    scheduledRetirement: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        textField: {
            width: '85%',
            '& label.Mui-focused': {
                color: (theme) => theme.palette.blue.main,
            },
            '& label.MuiFormLabel-filled': {
                color: (theme) => theme.palette.blue.main,
                padding: (theme) => theme.spacing(0.2),
            },
            '& .MuiOutlinedInput-input': {
                height: (theme) => theme.spacing(1),
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                    color: (theme) => theme.palette.blue.lightest,
                },
                '&:hover fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                },
                '&.Mui-focused fieldset': {
                    borderColor: (theme) => theme.palette.gray.light,
                },
            },
        },
    },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
};
export default ProvisioningStyles;

export const Styles = {
    ApprovalForm: {
        shadow: { boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' },
    },
    infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    formCard: {
        paper: {
            height: '100%',
            borderRadius: (theme) => theme.spacing(0.75),
            marginBottom: (theme) => theme.spacing(3.125),
            border: '1px solid #E0E0E0',
        },
        column: {
            padding: (theme) => theme.spacing(2),
            position: 'relative',
        },
        heading: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(2.5),
            fontWeight: 'medium',
            fontFamily: 'Stolzl',
        },
        wrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: (theme) => theme.spacing(2), py: (theme) => theme.spacing(1) },
    },
};
