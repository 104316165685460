/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';
import { eipStackCatalogServiceCode } from 'utils/catalogServiceCode';
import { getLandingZoneFromLocalStorage, resetLandingZone } from '../../utils';

const CloudService = () => {
    const params = useParams();
    const { operation } = params;
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const cloudServiceApi = useMutation((payload) => axios.get(`${endPoints.landingZones.cloudService}/${payload}/cloudservices`));
    const setcloudService = (cloudService, cloudServiceDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            cloudService,
            cloudServiceCode: cloudServiceDetails?.catalogDefinitionId,
            cloudServiceDetails,
            environment: null,
            environmentDetails: null,
            region: null,
            regionDetails: null,
            ...resetLandingZone(cloudService),
        });
        // }
    };
    const resetOptions = () => {
        setOptions([]);
        setcloudService(null, null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setcloudService(selected?.value, selected);
        }
    };
    const getDistinctByCloudService = (cloudServices) =>
        cloudServices?.reduce((memo, e1) => {
            const matches = memo.filter((e2) => e1.value === e2.value);
            if (matches.length === 0) memo.push(e1);
            return memo;
        }, []);
    React.useEffect(() => {
        if (cloudServiceApi?.isSuccess) {
            setOptions(
                getDistinctByCloudService(
                    cloudServiceApi?.data?.data?.map((app) => ({
                        label: app.cloudService,
                        value: app.cloudService,
                        catalogDefinitionId: app.catalogDefinitionId,
                    }))
                )
            );
            // setLzDDCloudService({ ...lzDDCloudService, isLoaded: true });
        }
    }, [cloudServiceApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.cloudService) {
            const cloudService = options.find((value) => landingZoneForm?.cloudService === value?.value);
            if (cloudService) setcloudService(cloudService?.label, cloudService);
        }
    }, [options]);
    React.useEffect(() => {
        if (landingZoneForm?.cloudProvider) {
            cloudServiceApi.mutate(landingZoneForm?.cloudProvider);
        }
    }, [landingZoneForm?.cloudProvider]);

    React.useEffect(() => {
        if (!landingZoneForm?.cloudService && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.cloudService, landingZoneForm?.isTouched]);
    React.useEffect(() => {
        return () => resetOptions();
    }, []);
    return (
        <Box bgcolor="white">
            <Select
                key="CatalogService"
                value={landingZoneForm?.cloudService || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    cloudServiceApi.isLoading ||
                    operation === 'edit' ||
                    (operation === 'create' && getLandingZoneFromLocalStorage() && eipStackCatalogServiceCode !== landingZoneForm?.cloudService)
                }
                isLoading={cloudServiceApi.isLoading}
                disableClearable={false}
                label="Service*"
                isRequired={isTouched}
                // renderInput={(params) => (
                //     <CssTextField
                //         size="large"
                //         // eslint-disable-next-line react/jsx-props-no-spreading
                //         {...params}
                //         InputProps={{
                //             ...params.InputProps,
                //         }}
                //         fullWidth
                //     />
                // )}
            />
        </Box>
    );
};

export default React.memo(CloudService);
