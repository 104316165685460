import ApplicationCI from './ApplicationCI';
import Sector from './Sector';
import Environment from './Environment';
import Source from './Source';
import SourceDirectory from './SourceDirectory';
import SourceAssignmentGrp from './SourceAssignmentGrp';
import FilePatternWrapper from './FilePatternWrapper';
import SASUrl from './SASUrl';
import SASToken from './SASToken';
import TokenExpiry from './TokenExpiry';
import DestinationDirectory from './DestinationDirectory';
import DestinationAssignmentGrp from './DestinationAssignmentGrp';
import ApplicationOwner from './ApplicationOwner';
import SourceValue from './SourceValue';
import SourcePatternTextArea from './SourcePatternTextArea';
import DestinationEmail from './DestinationEmail';
import SourceEmail from './SourceEmail';
import RITMContainer from './RITMContainer';
import RITMNumber from './RITMNumber';

const Form = {
    ApplicationCI,
    Sector,
    Environment,
    Source,
    SourceDirectory,
    SourceAssignmentGrp,
    FilePatternWrapper,
    SASUrl,
    SASToken,
    TokenExpiry,
    DestinationDirectory,
    DestinationAssignmentGrp,
    ApplicationOwner,
    SourceValue,
    SourcePatternTextArea,
    SourceEmail,
    DestinationEmail,
    RITMContainer,
    RITMNumber,
};

export default Form;
