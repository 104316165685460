import React from 'react';
import Modal from 'components/modal';
import { useRecoilState } from 'recoil';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import LabelValue from 'components/label-value';
import PreConfig from 'assets/images/PreConfig.png';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import groupBy from 'lodash/groupBy';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from 'components/alert';
import Empty from 'components/empty';
import { sspSubmitOrder, toggleQuickConfig } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import { resetForQuickConfig } from './utils';

const QuickConfig = () => {
    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState({});
    const [selectedItemIndex, setSelectedItemIndex] = React.useState();
    const [isOpen, setIsopen] = useRecoilState(toggleQuickConfig);
    // const [, setQuickConfig] = useRecoilState(quickConfigDetails);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const quickConfigApi = useMutation(() => axios.get(endPoints.ssp.day2Operation.quickConfig));
    // const quickConfigApi = useMutation((payload) => axios.post(endPoints.ssp.day2Operation.quickConfig, payload));

    const handleSelect = async ({ data }) => {
        await setOrder({
            ...order,
            ...resetForQuickConfig,
        });
        setTimeout(() => {
            setOrder({
                ...order,
                environment: data?.environment || null,
                region: data?.region?.code || null,
                regionDisplayName: data?.region?.name || null,
                projectName: data?.project || null,
                serverName: data?.serverName || null,
                modificationType: data?.modificationType || null,
                configureNewSku: null,
                dataDisksCount: '0',
            });
            setLoading(false);
            setIsopen(false);
        }, 1000);
    };
    React.useEffect(() => {
        setSelectedItemIndex(undefined);
    }, [order?.appProfile]);

    React.useEffect(() => {
        if (order?.appProfile && order?.cloudProvider && order?.cloudService) {
            quickConfigApi.mutate();
            // quickConfigApi.mutate({
            //     cloudProvider: order?.cloudProvider,
            //     cloudService: order?.cloudService,
            //     applicationName: order?.appProfile,
            // });
        }
    }, [order?.appProfile, order?.cloudProvider, order?.cloudService]);

    React.useEffect(() => {
        if (quickConfigApi?.isSuccess) {
            if (quickConfigApi?.data?.data?.length > 0) {
                const result = groupBy(quickConfigApi?.data?.data, 'environment');
                setProducts(result || {});
            }
        }
    }, [quickConfigApi?.isSuccess]);

    return (
        <Modal title="PreConfig" handleClose={() => setIsopen(false)} open={isOpen}>
            <>
                {!quickConfigApi?.isLoading && quickConfigApi?.isError && (
                    <>
                        <Empty notFound isPopup>
                            <span style={ProvisioningStyles.quickConfig.spanColor}>Something went wrong!</span>
                        </Empty>
                    </>
                )}
                {(quickConfigApi?.isLoading || quickConfigApi?.isFetching) && (
                    <Box sx={ProvisioningStyles.quickConfig.cirProgWrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.quickConfig.spanColor} />{' '}
                    </Box>
                )}
                {!quickConfigApi?.isLoading && !quickConfigApi?.isFetching && !quickConfigApi?.isError && (
                    <Stack direction="column" spacing={2}>
                        <Alert
                            title={null}
                            message="Pick one of the PreConfig options to pre-populate the configurations, then click select to save."
                            Icon={null}
                            borderLeftColor="#A5A5A5"
                            backgroundColor="#F3F3F3"
                            color="#000000"
                            ftSize="14px"
                        />{' '}
                        {Object.keys(products)?.length <= 0 && (
                            <Box
                                width="100%"
                                sx={ProvisioningStyles?.quickConfig?.emptycontainer}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                            >
                                <img src={PreConfig} alt="" />
                                <Typography sx={ProvisioningStyles?.quickConfig?.emptymsg}>Sorry, there’s no PreConfig options available</Typography>
                                <Typography sx={ProvisioningStyles?.quickConfig?.emptySuggestion}>Please manually fill out the provisioning options</Typography>
                            </Box>
                        )}
                        {Object.keys(products)?.length > 0 && (
                            <>
                                {Object.keys(products).map((key, index) => (
                                    <Accordion defaultExpanded disableGutters sx={ProvisioningStyles.quickConfig.wrapper} key={`${key}-${index.toString()}`}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon sx={ProvisioningStyles.quickConfig.expandIcon} />}
                                            sx={ProvisioningStyles.quickConfig.envTitle}
                                        >
                                            <Typography sx={ProvisioningStyles?.quickConfig?.summaryTitle}>{`${key} Environment`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={ProvisioningStyles.quickConfig.content}>
                                            {products[key].map((prod, indexPro) => (
                                                <Paper
                                                    sx={
                                                        selectedItemIndex === `${index.toString()}-${indexPro.toString()}`
                                                            ? ProvisioningStyles.quickConfig.paper
                                                            : null
                                                    }
                                                    key={`${index.toString()}-${indexPro.toString()}`}
                                                    style={ProvisioningStyles.quickConfig.labeledPaper}
                                                    variant="outlined"
                                                    elevation={0}
                                                    square
                                                >
                                                    <Stack p={1} className="preconfig-details">
                                                        <LabelValue
                                                            sx={ProvisioningStyles.quickConfig.labelValue}
                                                            label="Environment"
                                                            value={prod?.environment}
                                                        />
                                                        <LabelValue sx={ProvisioningStyles.quickConfig.labelValue} label="Region" value={prod?.region?.name} />
                                                        <LabelValue sx={ProvisioningStyles.quickConfig.labelValue} label="Project" value={prod?.project} />
                                                        <LabelValue
                                                            sx={ProvisioningStyles.quickConfig.labelValue}
                                                            label="Server Name"
                                                            value={prod?.serverName}
                                                        />
                                                        <LabelValue
                                                            sx={ProvisioningStyles.quickConfig.labelValue}
                                                            label="Modification Type"
                                                            value={prod?.modificationType}
                                                        />
                                                    </Stack>
                                                    <Box sx={ProvisioningStyles.quickConfig.selectBtn}>
                                                        <Btn
                                                            className="custom-btn"
                                                            size="small"
                                                            variant="contained"
                                                            color={`${
                                                                selectedItemIndex !== `${index.toString()}-${indexPro.toString()}`
                                                                    ? 'cmpPrimary'
                                                                    : 'cmpSelected'
                                                            }`}
                                                            onClick={() => {
                                                                setLoading(true);
                                                                handleSelect({ data: prod });
                                                                setSelectedItemIndex(`${index.toString()}-${indexPro.toString()}`);
                                                            }}
                                                            disabled={selectedItemIndex === `${index.toString()}-${indexPro.toString()}`}
                                                            sx={ProvisioningStyles.quickConfig.selectedBtn}
                                                            data-testid="select-btn"
                                                        >
                                                            {selectedItemIndex !== `${index.toString()}-${indexPro.toString()}` && !loading ? 'Select' : false}
                                                            {selectedItemIndex !== `${index.toString()}-${indexPro.toString()}` && loading ? 'Select' : false}
                                                            {selectedItemIndex === `${index.toString()}-${indexPro.toString()}` && !loading
                                                                ? 'Selected'
                                                                : false}
                                                            {selectedItemIndex === `${index.toString()}-${indexPro.toString()}` && loading
                                                                ? 'Selecting'
                                                                : false}
                                                        </Btn>
                                                    </Box>
                                                </Paper>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </>
                        )}
                    </Stack>
                )}
            </>
        </Modal>
    );
};

export default React.memo(QuickConfig);
