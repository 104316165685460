import { atom } from 'recoil';

export const sspSubmitOrder = atom({
    key: 'app-gw-sspOrder',
    default: {
        securityScanCompleted: 'no',
        appProfile: null,
        region: null,
        appProfileDetails: null,
        appOwner: null,
        appOwnerDetails: null,
        certificateOwner1: null,
        certificateOwner1Details: null,
        certificateOwner2: null,
        certificateOwner2Details: null,
        gtmLoadBalancer: null,
        appUrl: null,
        backendFQDN: null,
        backEndHealthProbe: null,
        hCode: null,
        hCodeDetails: null,
        entityDetails: null,
        environment: null,
        envShortName: null,
        regionDisplayname: null,
        appTier: null,
        appTierCode: null,
        isaNumber: null,
        scanNumber: null,
        deletedFiles: [],
        program: null,
        applicationName: null,
        sslEncryption: 'yes',
        supportBackendCertificate: null,
        routeType: 'Basic',
        routingPath: '/*',
        sector: null,
        sectorDetails: null,
        tags: [],
        adGroup: null,
        appSupportMailId: null,
        provisioning: false,
        estimatedCost: {
            details: [],
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'There is no cost for the Application Gateway URL onboarding in the shared Application Gateway from Cloud Marketplace. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard OV certificate part of automation process.',
        },
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const uploadedFilesState = atom({
    key: 'app-gw-uploadedFilesState',
    default: [],
});

export const environmentDetails = atom({
    key: 'app-gw-environmentDetails',
    default: {
        Subscription: '---',
        Resource_Group: '---',
        'Application gateway Name': '---',
    },
});

export const isValidApplicationName = atom({
    key: 'app-gw-validApplicationName',
    default: false,
});
export const isAppUrlValid = atom({
    key: 'app-gw-isAppUrlValid',
    default: false,
});
export const isValidBackendHealthProbe = atom({
    key: 'app-gw-validBackENdHealthProbe',
    default: false,
});
export const isValidFqdns = atom({
    key: 'app-gw-isValidFqdns',
    default: false,
});
export const isValidISANumber = atom({
    key: 'app-gw-isValidISANumber',
    default: false,
});
export const isValidScanNumber = atom({
    key: 'app-gw-isValidScanNumber',
    default: false,
});
export const toggleSspModels = atom({
    key: 'app-gw-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'app-gw-isMandatoryTagsFullfilled',
    default: false,
});

export const isEnvDetailsLoading = atom({
    key: 'appGateWay isEnvDetailsLoading',
    default: false,
});
export const costEstimateState = atom({
    key: 'appGateway-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'appGateWay-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const orderId = atom({
    key: 'appGateWay-orderId',
    default: '',
});

export const isValidappSupportMailId = atom({
    key: 'appGateWay-isValidappSupportMailId',
    default: false,
});

export const isValidgtmLoadBalancer = atom({
    key: 'appGateWay-isValidgtmLoadBalancer',
    default: false,
});

export const isValidOwners = atom({
    key: 'appGateway-isValidOwners',
    default: {
        isvalidAppOwner: true,
        isvalidCertificateOwner1: true,
        isvalidCertificateOwner2: true,
    },
});
