import { atom } from 'recoil';

export const OrderPayload = atom({
    key: 'CREATION-OrderPayload',
    default: {
        applicationName: null,
        environment: null,
        appOwner: null,
        appOwnerDetails: null,
        applicationServiceCI: null,
        sqlServerName: null,
        sqlServerDetails: null,
        appShortName: null,
        accountType: null,
        sector: null,
        isTouched: false,
    },
});
export default OrderPayload;

export const optionsList = atom({
    key: 'CREATION-optionsList',
    default: [],
});

export const isAppNameValid = atom({
    key: 'CREATION-isAppNameValid',
    default: true,
});

export const isAppShortNameValid = atom({
    key: 'CREATION-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'CREATION-isAppShortNameExist',
    default: false,
});

export const SQLServer = atom({
    key: 'CREATION-SQLServer',
    default: false,
});
