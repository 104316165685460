/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { range } from 'lodash';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const NodesCount = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setNodesCount = (nodesCount) => {
        const nodes = [
            ...Array(Number(nodesCount)).fill({
                catalogServiceCode: '',
                catalogType: '',
                cloudProvider: '',
                cloudService: '',
                osType: 'Linux',
                vmType: null,
                osTypeVersion: null,
                osTypeVersionDetail: null,
                osDiskType: null,
                osDiskTypeCode: null,
                diskTypeOptions: null,
                domain: null,
                haDeployment: null,
                availabilityZone: null,
                dataDisksCount: null,
                maxDataDiskCount: null,
                dataDisks: [],
                samAccountId: null,
                timeZone: 'UTC',
                sslCertificate: 'no',
                quantity: 1,
                isDiskOpen: false,
                isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
                isConfigDiskFulfilled: false,
                isDataDiskLoaded: false,
                copyPrevChecked: false,
                serverRefresh: false,
                estimatedCost: {
                    details: [],
                    qty: null,
                    totalOneTimeCost: {
                        frequency: 'one time',
                        estCost: null,
                        currency: 'USD',
                    },
                    totalRecurringCost: {
                        frequency: 'monthly',
                        estCost: null,
                        currency: 'USD',
                    },
                    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
                },
            }),
        ];

        setOrder({
            ...order,
            nodesCount,
            nodes,
            serviceMappings: [
                {
                    clusterService: 'data',
                    memoryQuota: '',
                    nodes: [],
                    storagePath: '/opt/couchbase/var/lib/couchbase/data/data',
                    isMemoryRequired: 'true',
                },
            ],
            buckets: [
                {
                    bucketType: null,
                    bucketNames: [{ name: '' }],
                    bucketMemSize: '',
                },
            ],
        });
    };

    const seldValueExistsInOptions = (nodesCountValue) => options.find((option) => nodesCountValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setNodesCount(selected?.value);
        }
    };

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.nodesCount) {
            setOrder({ ...order, nodesCount: options?.[0]?.value });
        }
        if (options && options?.length && order?.nodesCount) {
            const nodesCount = seldValueExistsInOptions(order?.nodesCount);
            setOrder({ ...order, nodesCount: nodesCount.value });
        }
    }, [options]);

    const checkVarConditions = () =>
        order?.sector && order?.environment && order?.region && order?.hCodeDetails && order?.billingEntity && order?.landingZoneDetails;

    React.useEffect(() => {
        if (checkVarConditions) {
            setOptions(
                range(1, Number(order?.maxNodeCount) + 1).map((i) => ({
                    label: i.toString(),
                    value: i.toString(),
                }))
            );
        }
    }, [order?.envDetails]);

    React.useEffect(() => {
        if (!order?.nodesCount && order?.isTouched) setIsTouched(true);
    }, [order?.nodesCount, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="nodesCount"
                label="No. of Server/Nodes *"
                value={order?.nodesCount || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={!checkVarConditions()}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(NodesCount);
