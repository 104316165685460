/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { landingZoneData } from '../../../store';

const NetworkConnectivity = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);

    const [options, setOptions] = React.useState([]);

    const setNetworkConnectivity = (networkConnectivity) => {
        setLandingZoneForm({
            ...landingZoneForm,
            networkConnectivity,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        setNetworkConnectivity(null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setNetworkConnectivity(selected?.label);
        } else {
            setNetworkConnectivity(null);
        }
    };

    React.useEffect(() => {
        if (options?.length && options?.length === 1) {
            handleOnSelectChange(options[0]);
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        setOptions([{ label: 'Selected Networks (Default)', value: 'Selected Networks (Default)' }]);
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.networkConnectivity && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.networkConnectivity, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="networkConnectivity"
                value={landingZoneForm?.networkConnectivity || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disableClearable={false}
                label="Network Connectivity *"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(NetworkConnectivity);
