import { atom } from 'recoil';

export const orderPayload = atom({
    key: 'vs-orderPayload',
    default: {
        applicationService: null,
        applicationOwner: null,
        applicationOwnerDetails: null,
        sector: null,
        division: null,
        environment: null,
        applicationRegion: null,
        applicationRegionDetails: null,
        department: null,
        program: null,
        aksCluster: null,
        aksNamespace: null,
        costCenter: null,
        costCenterDetails: null,
        certificateOwnerOne: null,
        certificateOwnerOneDetails: null,
        certificateOwnerTwo: null,
        certificateOwnerTwoDetails: null,
        virtualServiceType: null,
        aksVirtualServiceType: null,
        aksVirtualService: [
            {
                virtualServiceType: null,
                virtualServiceName: null,
                hostNamesPrefix: [{ hostNamePrefix: null, isHostNameTouched: false }],
                customDomainSelected: 'No',
                customDomain: [
                    {
                        name: null,
                    },
                ],
                certificateExists: true,
                corsPolicy: 'No',
                aksService: [
                    {
                        serviceName: null,
                        servicePort: null,
                        contextPath: null,
                    },
                ],
                aksCors: {
                    allowedHeaders: [{ allowedHeader: null, isInvalid: false, isAllowedHeaderTouched: false }],
                    allowedOrigins: [{ allowedOrigin: null, isInvalid: false, isAllowedOriginTouched: false }],
                    exposeHeaders: [{ exposeHeader: null, isInvalid: false, isExposeHeaderTouched: false }],
                    allowedMethods: [],
                },
                isServiceNameInvalid: false,
                isVirtualServiceNameInvalid: false,
                isServicePortInvalid: false,
                isHostNamePrefixInvalid: false,
                isContextPathInvalid: false,
            },
        ],
        aksService: {},
        serviceName: null,
        virtualServiceName: null,
        servicePort: null,
        contextPath: null,
        allowedHeader: null,
        estimatedCost: {
            details: [],
            qty: 1,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        isTouched: false,
        isConfigureTouched: false,
    },
});

export const isDuplicateCustomDomainName = atom({
    key: 'vs-isDuplicateCustomDomainName',
    default: true,
});

export const certCreationRequired = atom({
    key: 'vs-certCreationRequired',
    default: false,
});

export const customDomainFieldsDiasbled = atom({
    key: 'vs-customDomainFieldsDiasbled',
    default: false,
});

export const isValidOwners = atom({
    key: 'vs-isValidOwners',
    default: {
        isValidApplicationOwner: true,
        isValidCertificateOwnerOne: true,
        isValidCertificateOwnerTwo: true,
    },
});

export const costEstimateData = atom({
    key: 'vs-costEstimateData',
    default: null,
});

export const toggleSspModels = atom({
    key: 'vs-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
    },
});

export const validationStatus = atom({
    key: 'vs-validationStatus',
    default: null,
});

export const vsErrorIndexValue = atom({
    key: 'vs-vsErrorIndexValue',
    default: null,
});

export const currentSubModule = atom({
    key: 'vs-currentSubModule',
    default: null,
});

export const selectedVSCopy = atom({
    key: 'vs-selectedVSCopy',
    default: null,
});

export const selectedVSSubModuleCopy = atom({
    key: 'vs-selectedVSSubModuleCopy',
    default: null,
});

export const vsRandomValue = atom({
    key: 'vs-vsRandomValue',
    default: null,
});

export const toggleQuickConfig = atom({
    key: 'vs-toggleQuickConfig',
    default: false,
});

export const toggleSubQuickConfig = atom({
    key: 'vs-toggleSubQuickConfig',
    default: false,
});

export const selectedVSService = atom({
    key: 'vs-selectedVSService',
    default: null,
});

export const maxVSErrorMessage = atom({
    key: 'vs-maxVSErrorMessage',
    default: null,
});

export const virtualServicesType = atom({
    key: 'vs-virtualServicesType',
    default: [],
});

export const allowedMethods = atom({
    key: 'vs-allowedMethods',
    default: [],
});

export const restrictedPorts = atom({
    key: 'vs-restrictedPorts',
    default: [],
});

export const exposeHeaders = atom({
    key: 'vs-exposeHeaders',
    default: [],
});

export const allowedHeaders = atom({
    key: 'vs-allowedHeaders',
    default: [],
});

export const validVSForm = atom({
    key: 'vs-validVSForm',
    default: {
        isInvalidAksNamespace: false,
        isInvalidServiceName: false,
        isInvalidVirtualServiceName: false,
        isInvalidHostName: false,
        isInvalidCustomDomainName: false,
        isInvalidServicePort: false,
        isInvalidContextPath: false,
        isInvalidHeaderName: false,
        isInvalidAllowedOrigin: false,
        isInvalidAllowedHeader: false,
        isNamespaceExists: true,
    },
});

export const isDivisionRequired = atom({
    key: 'vs-isDivisionRequired',
    default: false,
});

export const isAppRegionRequired = atom({
    key: 'vs-isAppRegionRequired',
    default: false,
});

export const isClusterLoading = atom({
    key: 'vs-isClusterLoading',
    default: false,
});

export const isNamespaceLoading = atom({
    key: 'vs-isNamespaceLoading',
    default: false,
});

export const isDisplayProgram = atom({
    key: 'vs-isDisplayProgram',
    default: false,
});

export const showPreviewOrder = atom({
    key: 'vs-showPreviewOrder',
    default: false,
});

export const toggleModal = atom({
    key: 'vs-toggleModal',
    default: {
        isSuccessOpen: false,
        action: '',
        orderId: null,
    },
});
export const validationErrorReport = atom({
    key: 'vs-validationErrorReport',
    default: [],
});
export const duplicateVirtualService = atom({
    key: 'vs-duplicateVirtualService',
    default: [],
});

export const multiFieldValidation = atom({
    key: 'vs-multiFieldValidation',
    default: {
        serviceName: [
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
        ],
        servicePort: [
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
        ],
        contextPath: [
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
        ],
        hostNamePrefix: [
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
            { isInvalid: false, isTouched: false, message: '' },
        ],
    },
});
