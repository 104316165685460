/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useParams } from 'react-router-dom';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';
import { eipStackCatalogServiceCode } from 'utils/catalogServiceCode';
import { getLandingZoneFromLocalStorage, resetLandingZone } from '../../utils';

const Service = () => {
    const params = useParams();
    const { operation } = params;
    const [isTouched, setIsTouched] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const cloudServiceApi = useMutation((payload) => axios.get(`${endPoints.landingZones.cloudService}/${payload}/cloudservices`));
    const setService = (service, serviceDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            service,
            serviceCode: serviceDetails?.catalogDefinitionId,
            serviceDetails,
            isTouched: false,
            environment: null,
            environmentDetails: null,
            region: null,
            regionDetails: null,
            ...resetLandingZone(service),
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };
    const seldValueExistsInOptions = (serviceValue) => options.find((value) => serviceValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setService(selected?.value, selected);
        }
    };
    const getDistinctByCloudService = (services) =>
        services?.reduce((memo, e1) => {
            const matches = memo.filter((e2) => e1.value === e2.value);
            if (matches.length === 0) memo.push(e1);
            return memo;
        }, []);

    React.useEffect(() => {
        if (cloudServiceApi?.isSuccess) {
            setOptions(
                getDistinctByCloudService(
                    cloudServiceApi?.data?.data?.map((app) => ({
                        label: app.cloudService,
                        value: app.cloudService,
                        catalogDefinitionId: app.catalogDefinitionId,
                    }))
                )
            );
        }
    }, [cloudServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.service) {
            setLandingZoneForm({ ...landingZoneForm, service: options?.[0].label });
        }
        if (options && options?.length && landingZoneForm?.service) {
            const service = seldValueExistsInOptions(landingZoneForm?.service);
            setLandingZoneForm({ ...landingZoneForm, service: service?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (landingZoneForm.provider) {
            resetOptions();
            cloudServiceApi.mutate(landingZoneForm.provider);
        }
    }, [landingZoneForm.provider]);

    React.useEffect(() => {
        if (!landingZoneForm?.service && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.service, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="Service"
                label="Service*"
                value={landingZoneForm?.service || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    cloudServiceApi.isLoading ||
                    operation === 'edit' ||
                    (operation === 'create' && getLandingZoneFromLocalStorage() && eipStackCatalogServiceCode !== landingZoneForm?.service)
                }
                isLoading={cloudServiceApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Service);
