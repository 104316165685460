/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress } from '@mui/material';
import { LineChart, Line, ResponsiveContainer, XAxis, Tooltip, LabelList, YAxis } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { getEnv } from 'utils';
import dashboardStyle from 'pages/dashboard/style';
import Chart from './components/Chart';
//     eslint-disable-next-line
import { orderCreationTrend } from './store';
// import { toDateTime } from '../../utils';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const OrdersCreationTrend = () => {
    const [data, setData] = useRecoilState(orderCreationTrend);
    const orderCompletedQuery = useMutation((payload) => axios.post(`${endPoints.ssp.dashboard.es}/${getEnv('REACT_APP_ES_INDEX_EVENTS')}`, payload));
    const orderSubmittedQuery = useMutation((payload) => axios.post(`${endPoints.ssp.dashboard.es}/${getEnv('REACT_APP_ES_INDEX_EVENTS')}`, payload));

    React.useEffect(() => {
        orderCompletedQuery.mutate({
            query: {
                bool: {
                    should: [
                        {
                            term: {
                                'eventName.keyword': 'ORDER COMPLETED',
                            },
                        },
                        {
                            term: {
                                'eventName.keyword': 'ORDER Completed',
                            },
                        },
                    ],
                    must_not: [
                        {
                            wildcard: {
                                'entityDetails.name.keyword': 'CLOUD MARKETPLACE*',
                            },
                        },
                    ],
                },
            },
            aggs: {
                orders_by_time: {
                    date_histogram: {
                        field: 'timestamp',
                        calendar_interval: '1M',
                    },
                },
            },
        });

        orderSubmittedQuery.mutate({
            query: {
                bool: {
                    must: [
                        {
                            term: {
                                'eventName.keyword': 'Order Submitted',
                            },
                        },
                    ],
                },
            },
            aggs: {
                orders_by_time: {
                    date_histogram: {
                        field: 'timestamp',
                        calendar_interval: '1M',
                    },
                },
            },
        });
    }, []);

    React.useEffect(() => {
        if (orderCompletedQuery?.isSuccess && orderSubmittedQuery?.isSuccess) {
            const orderComp = orderCompletedQuery?.data?.data?.aggregations?.orders_by_time?.buckets?.map((item) => item?.key_as_string) || [];
            const orderSub = orderSubmittedQuery?.data?.data?.aggregations?.orders_by_time?.buckets?.map((item) => item?.key_as_string) || [];
            const getAllDatesData = orderComp?.length > orderSub?.length ? orderComp : orderSub;
            const dates = [...new Set([...getAllDatesData])];
            const results = dates?.map((item) => {
                let obj = { name: DateTime.fromISO(item, { zone: 'utc' }).toFormat('LLL-yyyy'), OrdersCompleted: 0, OrdersSubmitted: 0 };
                orderCompletedQuery?.data?.data?.aggregations?.orders_by_time?.buckets?.map((orderCompleted) => {
                    if (orderCompleted?.key_as_string === item) {
                        obj = { ...obj, OrdersCompleted: orderCompleted?.doc_count };
                    }
                    return null;
                });
                orderSubmittedQuery?.data?.data?.aggregations?.orders_by_time?.buckets?.map((orderSubmitted) => {
                    if (orderSubmitted?.key_as_string === item) {
                        obj = { ...obj, OrdersSubmitted: orderSubmitted?.doc_count };
                    }
                    return null;
                });
                return obj;
            });
            setData(results || []);
        }
    }, [orderCompletedQuery?.isSuccess, orderSubmittedQuery?.isSuccess]);

    return (
        <Chart w={12} title="Orders Trend">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {(orderCompletedQuery.isLoading || orderSubmittedQuery.isLoading || data?.length <= 0) && (
                    <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                        <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                    </Box>
                )}
                {!orderCompletedQuery.isLoading && !orderSubmittedQuery.isLoading && data?.length > 0 && (
                    <Box sx={{ marginTop: 4, marginRight: 4 }}>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={data}>
                                <XAxis interval={0} padding={{ left: 10, right: 10 }} dataKey="name" height={60} tick={<CustomizedAxisTick />} />
                                <YAxis padding={{ top: 10 }} />
                                <Tooltip />
                                <Line dataKey="OrdersSubmitted" dot={{ stroke: '#FB9906', strokeWidth: 5 }} stroke="#FB9906" strokeWidth={2}>
                                    <LabelList position="top" />
                                </Line>
                                <Line dataKey="OrdersCompleted" dot={{ stroke: '#0056BA', strokeWidth: 5 }} stroke="#0056BA" strokeWidth={2}>
                                    <LabelList position="top" />
                                </Line>
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(OrdersCreationTrend);
