import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const skuTypeState = atom({
    key: 'skuType',
    default: null,
});

export const skuSearchTextState = atom({
    key: 'skuSearchTextState',
    default: '',
});

export const selectedIdsState = atom({
    key: 'skuSelectedIds',
    default: [],
});

export const skuListsState = atom({
    key: 'skuLists',
    default: [],
});

export const totalCountState = atom({
    key: 'skuTotalCount',
    default: null,
});

export const toggleDetailModal = atom({
    key: 'skuDetailModal',
    default: {
        isDetailOpen: false,
        skuDetails: null,
    },
});
