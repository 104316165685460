import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload, showPreviewOrder, validOnboardingForm, toggleModal, isSonarQubeRepoAvailable } from 'pages/project-onboarding/store';
import Btn from 'components/button';
import { getOrderPayload, resetOrder, resetValidOnboardingForm, resetToggleModal } from 'pages/project-onboarding/utils';
import { DialogBox } from 'pages/project-onboarding/components/dialog-box';

const PreviewHeader = () => {
    const { cartItemId, operation } = useParams();
    const history = useHistory();
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [modal, setToggleModal] = useRecoilState(toggleModal);
    const [, setAvailableRepo] = useRecoilState(isSonarQubeRepoAvailable);

    const submitOrderApi = useMutation((payload) => axios.post(endPoints.day2Operation?.ProjectOnboarding?.Jwt?.submitOrder, payload));
    const editOrderApi = useMutation((payload) => axios.put(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.editOrder}/${cartItemId}`, payload));

    React.useEffect(() => {
        if (submitOrderApi?.data?.data) {
            setToggleModal({
                ...modal,
                isSuccessOpen: true,
                orderId: submitOrderApi?.data?.data?.orderId,
            });
            setValidProvisionForm(resetValidOnboardingForm);
        }
    }, [submitOrderApi?.isSuccess]);

    React.useEffect(() => {
        if (editOrderApi?.isSuccess) {
            setToggleModal({
                ...modal,
                isSuccessOpen: true,
                orderId: cartItemId,
            });
            setValidProvisionForm(resetValidOnboardingForm);
        }
    }, [editOrderApi?.isSuccess]);

    const handleSubmit = async () => {
        const payload = await getOrderPayload({ order });
        if (operation === 'edit') {
            const reSubmitPayload = { ...payload, 'order-id': cartItemId };
            editOrderApi.mutate(reSubmitPayload);
        } else {
            submitOrderApi.mutate(payload);
        }
    };

    const handleReset = () => {
        setShowPreview(null);
        setOrder(resetOrder);
        setValidProvisionForm(resetValidOnboardingForm);
        setToggleModal(resetToggleModal);
        setAvailableRepo(true);
    };

    const getButtonTitle = () => {
        let result = '';
        if (submitOrderApi?.isLoading || editOrderApi?.isLoading) {
            result = <CircularProgress color="inherit" size={16} />;
        } else if (operation !== 'edit') {
            result = 'Submit Request';
        } else result = 'Re-Submit Request';
        return result;
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} data-testid="preview-header">
            <Box sx={Style?.preview?.header?.previewDetailsTextWrap}>
                <Typography component="span" sx={Style?.preview?.header?.previewDetailsText}>
                    Provision Details (Preview)
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                {showPreview === 'pre-preview-order' && (
                    <Btn
                        size="medium"
                        onClick={() => setShowPreview(null)}
                        variant="outlined"
                        color="cmpPrimary2"
                        data-testid="preview-edit-btn"
                        sx={Style?.preview?.editBtn}
                    >
                        Edit Order
                    </Btn>
                )}
                <Btn
                    size="medium"
                    onClick={() => handleSubmit()}
                    variant="contained"
                    color="cmpPrimary2"
                    data-testid="preview-submit-btn"
                    sx={Style?.preview?.editBtn}
                >
                    {getButtonTitle()}
                </Btn>
            </Box>
            <DialogBox handleNewOrderBtn={handleReset} handleHomeBtn={() => history.push('/')} operation={operation} />
        </Box>
    );
};

export default PreviewHeader;
