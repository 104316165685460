import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { orderPayload, validVSForm, selectedVSService } from 'pages/provisioning/virtual-service/store';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';
import InfoImg from 'components/info-img';

export const ContextPath = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [helperText, setHelperText] = React.useState(false);
    const [contextPathValid, setContextPathValid] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [validForm, setValidForm] = useRecoilState(validVSForm);
    const [selectedVS] = useRecoilState(selectedVSService);

    const handleValueChange = (contextPath, aIndex) => {
        setActiveIndex(aIndex);
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                if (index === selectedVS) {
                    return {
                        ...item,
                        aksService: item?.aksService.map((service, i) => {
                            if (i === aIndex) {
                                return {
                                    ...service,
                                    contextPath,
                                };
                            }
                            return service;
                        }),
                    };
                }
                return item;
            }),
        }));
    };
    React.useEffect(() => {
        if (
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath !== null &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath !== '' &&
            order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath !== undefined &&
            !/^(?!.*\/\/)[a-zA-Z0-9/_-]{1,255}(?<![_-])$/.test(order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath)
        ) {
            if (order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath?.charAt(0) !== '/') {
                setContextPathValid(true);
                setHelperText(true);
                setValidForm({ ...validForm, isInvalidContextPath: true });
                return;
            }
            setContextPathValid(false);
            setHelperText(true);
            setValidForm({ ...validForm, isInvalidContextPath: true });
        } else {
            if (
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath !== null &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath !== '' &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath !== undefined &&
                order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath?.charAt(0) !== '/'
            ) {
                setContextPathValid(true);
                setHelperText(true);
                setValidForm({ ...validForm, isInvalidContextPath: true });
                return;
            }
            setContextPathValid(false);
            setHelperText(false);
            setValidForm({ ...validForm, isInvalidContextPath: false });
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath]);
    const validateInput = () => {
        if ((isTouched && order?.isConfigureTouched && !order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath) || helperText) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (!order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath && order?.isConfigureTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [order?.aksVirtualService?.[selectedVS]?.aksService?.[activeIndex].contextPath, order?.isConfigureTouched]);
    const getToolTip = () => {
        return (
            <InfoImg
                title={
                    <>
                        <Box className={ProvisioningStyles.infoStyle.wrapper}>
                            <Box sx={ProvisioningStyles.infoStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs sx={ProvisioningStyles.infoStyle.topLeftNamingRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Context Path:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            A context path in a virtual service is the root URL path that routes requests to a specific backend service.
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>1) Context path should start with /.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>2) It can have alpha-numeric value.</Typography>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            3) It can only contain slash(/), underscore(_) and hyphen(-) as special chars.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={ProvisioningStyles.infoStyle.bottomDividerRule}>
                                        <Typography sx={ProvisioningStyles.infoStyle.typographyStyle}>
                                            <span style={ProvisioningStyles.infoStyle.headingStyle}>Example: </span>
                                            /v1/authorize-client_id
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        );
    };
    return (
        <>
            {order?.aksVirtualService?.[selectedVS]?.aksService?.map((service, index) => (
                <Box display="flex" alignItems="center" gap={1} key={index} data-testid={`context-path-box-${index}`}>
                    <CssTextField
                        size="small"
                        fullWidth
                        autoComplete="off"
                        label="Context Path *"
                        error={!!((helperText && activeIndex === index) || isTouched || validateInput())}
                        helperText={
                            helperText &&
                            activeIndex === index && (
                                <span>
                                    {contextPathValid
                                        ? 'Context path should start with "/"'
                                        : 'Context path should have alpha-numeric value and can contain slash(/), underscore(_) and hyphen(-) as special chars.'}
                                </span>
                            )
                        }
                        value={service?.contextPath || ''}
                        onChange={(e) => handleValueChange(e.target.value, index)}
                        className={activeIndex === index ? validateInput() : ''}
                    />
                    {getToolTip()}
                </Box>
            ))}
        </>
    );
};
export default React.memo(ContextPath);
