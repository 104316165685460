/* eslint-disable import/no-named-default */
import { default as Applications } from './Application';
import { default as Sector } from './Sector';
import { default as Environments } from './Environments';
import { default as Region } from './Region';
import { default as CostCenter } from './CostCenter';
import { default as Project } from './Project';
import { default as Notes } from './Notes';
import { default as ModificationType } from './ModificationType';
import { default as StorageAccount } from './StorageAccount';
import { default as Capabilities } from './Capabilities';
import { default as ModifyService } from './ModifyService';
import { default as ContainerName } from './ContainerName';
import { default as FileShareName } from './FileShareName';
import { default as Tier } from './Tier';
import { default as QueueName } from './QueueName';
import { default as Redundancy } from './Redundancy';
import { default as StaticWebpage } from './StaticWebpage';
import { default as IndexDocumentName } from './IndexDocumentName';
import { default as ErrorDocumentPath } from './ErrorDocumentPath';
import { default as Performance } from './Performance';
import { default as BlobAccessTier } from './BlobAccessTier';
import { default as SourceSubscription } from './SourceSubscription';
import { default as TargetSubscription } from './TargetSubscription';
import { default as ResourceGroupText } from './ResourceGroupText';
import { default as ResourceGroup } from './ResourceGroup';

const Forms = {
    Applications,
    Sector,
    Environments,
    Region,
    CostCenter,
    Project,
    Notes,
    ModificationType,
    StorageAccount,
    Capabilities,
    ModifyService,
    ContainerName,
    FileShareName,
    Tier,
    QueueName,
    Redundancy,
    StaticWebpage,
    IndexDocumentName,
    ErrorDocumentPath,
    Performance,
    BlobAccessTier,
    SourceSubscription,
    TargetSubscription,
    ResourceGroupText,
    ResourceGroup,
};

export default Forms;
