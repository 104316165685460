import theme from 'assets/style/theme';
import { styled } from '@mui/material/styles';
import { Button, TextField, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Tab from '@mui/material/Tab';

const profileStyle = {
    mainwraper: {
        background: theme.palette.white,
        color: theme.palette.black.dark,
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: theme.spacing('100%'),
        height: theme.spacing('100%'),
        // pt: 2,
    },
    header: {
        wrapper: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            py: theme.spacing(2),
            paddingRight: theme.spacing('53px'),
            paddingLeft: theme.spacing('53px'),
            borderBottom: '1px solid #E0E0E0',
            height: theme.spacing('82px'),
        },
        container: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
        subWrapper: { display: 'flex', justifyContent: 'space-between' },
        searchWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: 300,
            background: theme.palette.white,
            border: '1px solid #E0E0E0',
            borderRadius: '20px',
            mr: 2,
        },
        searchInput: { ml: theme.spacing(1), mt: theme.spacing(1), flex: 1, background: theme.palette.white, color: theme.palette.black.dark },
        clearIcon: { color: theme.palette.blue.main },
        createBtn: {
            textTransform: 'none',
            height: '100%',
            pl: 2,
            backgroundColor: theme.palette.blue.main,
            '&:hover': {
                backgroundColor: theme.palette.cyan.main,
            },
        },
        createBtnContainer: { pl: 2 },
        headerText: { fontFamily: 'Stolzl', fontWeight: 'medium', fontSize: '30px', color: theme.palette.black.dark },
    },
    list: {
        userIcon: { fontSize: '27px', marginTop: '5px' },
        wrapper: {
            color: theme.palette.white,
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            paddingTop: theme.spacing('26px'),
            paddingBottom: theme.spacing(1),
            backgroundColor: theme.palette.grey.lighter,
            paddingX: theme.spacing('53px'),
        },
        tableContainer: {
            marginTop: theme.spacing(0.5),
            // borderColor:'1px solid #E8E8E8',
            borderRadius: '3px',
            boxShadow: '1px 1px 2px #00000014',
        },
        table: {
            marginTop: theme.spacing(-1.125),
            borderSpacing: theme.spacing(0),
            paddingTop: theme.spacing(1),
            minWidth: 500,
            height: 'auto',
        },
        row: { height: theme.spacing('50px') },
        hrow: { height: theme.spacing('50px'), backgroundColor: theme.palette.blue.main },

        emptyRow: {
            row: { background: 'transparent !important' },
            cell: { border: '0 !important' },
        },
        editBtn: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3.375),
            height: theme.spacing(3.375),
            background: theme.palette.blue.main,
            marginRight: theme.spacing(1),
        },
        editIcon: {
            width: theme.spacing('15px'),
            height: theme.spacing('15px'),
            color: theme.palette.white,
            cursor: 'pointer',
            marginLeft: '3px',
        },
        deleteBtn: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3.375),
            height: theme.spacing(3.375),
            background: '#F4273B',
        },
        deleteIcon: {
            fontSize: theme.spacing(2.25),
            color: theme.palette.white,
            cursor: 'pointer',
            width: theme.spacing(1.5),
            height: theme.spacing(1.625),
        },
        paginationWrapper: {
            direction: 'column',
            justifyContent: 'center',
            paddingX: theme.spacing('52px'),
        },
        paginationSubWrapper: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        tableFooter: { height: '70px', border: '1px solid rgba(224, 224, 224, 1)' },
        tablePagination: {
            width: '100%',
            color: theme.palette.black.dark,
            border: '1px solid #E0E0E0',

            '.MuiTablePagination-spacer': {
                flex: 0,
            },
            '.MuiToolbar-root': {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                alignItems: 'center',
            },
            '.MuiTablePagination-displayedRows': {
                fontWeight: 500,
                fontSize: '1rem',
                color: '#1E1E1E',
                paddingTop: '2px',
            },
        },
    },
    details: {
        wrapper: {
            background: theme.palette.grey.lighter,
            color: theme.palette.white,
            overflow: 'scroll',
            flexDirection: 'column',
            display: 'flex',
            width: '100%',
            height: '100%',
            px: theme.spacing('53px'),
            pt: theme.spacing('26px'),
        },
        loader: {
            wrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' },
            circleIcon: { color: theme.palette.black.dark },
        },
        profileHeader: {
            wrapper: { background: theme.palette.white, px: theme.spacing(2), py: theme.spacing(1) },
            subWrapper: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: theme.spacing(1) },
            typography: {
                display: 'flex',
                color: theme.palette.blue.main,
                alignItems: 'center',
                marginLeft: theme.spacing(-1.875),
                fontSize: '25px',
                fontFamily: 'Stolzl',
                fontWeight: 'normal',
            },
            arrowIcon: { color: theme.palette.blue.main },
            approveBtn: { fontSize: '14px', color: theme.palette.white, textTransform: 'none' },
            circularIcon: { marginLeft: theme.spacing(1.25) },
            modifyBtn: { fontSize: '14px', color: theme.palette.white, textTransform: 'none', marginLeft: '10px' },
            newLzBtn: { fontSize: '14px', color: theme.palette.white, textTransform: 'none', ml: theme.spacing(1) },
            backtxt: { marginLeft: '-3px' },
            headingTxt: { fontFamily: 'Stolzl', fontSize: '25px', fontWeight: 500, paddingLeft: 16, color: '#000000' },
        },
        profileDetails: {
            wrapper: { display: 'flex', color: theme.palette.gray.dark, fontSize: theme.spacing(2), py: theme.spacing(1) },
            item: { width: '33%', display: 'flex', flexDirection: 'column', pl: theme.spacing(1) },
            label: { textTransform: 'uppercase' },
            value: { fontSize: theme.spacing(2.25), fontWeight: '500' },
        },
        tabs: {
            wrapper: { background: theme.palette.white, mt: theme.spacing(2), px: theme.spacing(2), pt: theme.spacing(1) },
            tabs: { marginLeft: theme.spacing(2.5) },
            customTab: { marginLeft: theme.spacing(1.25) },
            label: { display: 'flex', alignItems: 'center' },
            chip: { marginLeft: theme.spacing(1.25) },
        },
        tabValue: {
            wrapper: { background: theme.palette.white, pb: theme.spacing(1) },
            wrapper2: { background: theme.palette.white, pb: theme.spacing(1), display: 'flex', flexGrow: 1, overflow: 'auto' },
            landingZone: {
                tableContainer: {
                    // border:'1px solid #E0E0E0',
                    borderRadius: '3px',
                    boxShadow: '1px 1px 2px #00000014',
                },
                table: { marginTop: theme.spacing(-1.125), borderSpacing: theme.spacing(0), minWidth: 500, height: 'auto', paddingTop: theme.spacing(1) },
                row: { height: theme.spacing('50px'), backgroundColor: theme.palette.blue.main },
                loaderRow: { background: 'transparent !important' },
                loaderCell: { border: '0 !important' },
                circularIcon: { color: theme.palette.black },
                noDataFound: { color: theme.palette.black },
                styledRow: { height: theme.spacing('50px') },
                editWrapper: {
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: theme.spacing(3.375),
                    height: theme.spacing(3.375),
                    background: theme.palette.blue.main,
                    marginRight: theme.spacing(1),
                },
                editIcon: { width: theme.spacing('15px'), height: theme.spacing('15px'), color: theme.palette.white, cursor: 'pointer', marginLeft: '3px' },
                deleteWrapper: {
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: theme.spacing(3.375),
                    height: theme.spacing(3.375),
                    background: '#DB1E36',
                },
                deleteIcon: {
                    fontSize: theme.spacing(2.25),
                    color: theme.palette.white,
                    cursor: 'pointer',
                    width: theme.spacing('12px'),
                    height: theme.spacing('13px'),
                },
                addWrapper: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', background: theme.palette.white },
                addBtn: {
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: theme.spacing(4.375),
                    height: theme.spacing(4.375),
                    background: theme.palette.blue.main,
                    marginRight: theme.spacing(1),
                },
                addIcon: { fontSize: theme.spacing(3.125), color: theme.palette.white, cursor: 'pointer' },
                externalIcon: { background: theme.palette.blue[90], borderRadius: theme.spacing(0.75), fontSize: theme.spacing(2) },
            },
        },
    },
    filterButton: {
        height: '38px',
        width: '100px',
        color: theme.palette.blue.main,
        border: '2px solid #0047BA',
        '&:hover': {
            border: '2px solid #00A0DD',
            color: '#00A0DD',
            backgroundColor: 'transparent !important',
        },
    },
    orderFilter: {
        container: {
            color: theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: '#FFFFFF',
            width: 260,
            padding: 2,
            position: 'relative',
            top: 8,
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 100,
            fill: '#FFFFFF',
            zIndex: 9999,
            '&::before': {
                backgroundColor: theme.palette.white,
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 6px 8px',
            },
        },

        filterIcon: { color: theme.palette.white },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#3381FF' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
        dropdownStack: { mb: theme.spacing(2), pt: theme.spacing(1) },
    },
    createAppProfile: {
        wrapper: { width: theme.spacing(100), display: 'flex', flexDirection: 'column', height: theme.spacing('100%') },
        container: { px: 2, pt: 2 },
        btnwrapper: { pt: 4 },
        alertBox: { display: 'flex', justifyContent: 'center', pb: 2 },
        alert: { width: '320px' },
        circularProg: { marginLeft: '10px' },
        cancelbtn: {
            marginLeft: '10px',
            color: '#6E6F6F',
            border: '2px solid #707070',
            backgroundColor: '#ffffff',
            textTransform: 'none',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                backgroundColor: '#ffffff',
            },
        },
    },
    deleteModal: {
        deleteMsgWrap: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
        actionWrapper: { display: 'flex', justifyContent: 'center', mt: 2 },
        yesCircularProg: { marginLeft: '10px' },
        noBtn: {
            marginLeft: '10px',
            textTransform: 'none',
            color: '#6E6F6F',
            border: '2px solid #707070',
            backgroundColor: '#ffffff',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                backgroundColor: '#ffffff',
            },
        },
        modalMsg: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
        checkCircleICon: { fontSize: '50px', color: '#16BF5E' },
        defaultsMsg: { color: '#626262', fontSize: '18px' },
        continueWrapper: { display: 'flex', justifyContent: 'center', mt: 2 },
        laterBtn: { marginLeft: '10px' },
    },
    appWoner: { select: { width: '100%' }, searchIcon: { cursor: 'pointer', color: '#0047BA' }, infoicon: { color: '#0047BA' } },

    profiledetailsheader: {
        wrapper: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            py: theme.spacing(2),
            paddingRight: theme.spacing('53px'),
            paddingLeft: theme.spacing('53px'),
            borderBottom: '1px solid #E0E0E0',
            height: theme.spacing('82px'),
        },
        container: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },

        typography: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(-1.875),
            fontFamily: 'Stolzl',
            fontSize: '30px',
            fontWeight: 'medium',
            color: theme.palette.black.dark,
        },
        backIcon: { color: theme.palette.blue.main },
        filterWrapper: { display: 'flex', justifyContent: 'right' },
        filterSubWrap: { height: '100%' },
        addBox: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(4.375),
            height: theme.spacing(4.375),
            background: theme.palette.blue.main,
            marginRight: theme.spacing(1),
        },
        addIcon: {
            fontSize: theme.spacing(3.125),
            color: theme.palette.white,
            cursor: 'pointer',
        },
    },
};
export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: theme.palette.white,
    '&:hover': {
        backgroundColor: '#00A0DD',
        color: theme.palette.white,
    },
}));

export const CssTextField = styled(TextField)({
    '& label+div .MuiOutlinedInput-input': {
        height: '30px',
    },
    '& label.MuiFormLabel-filled+div .MuiOutlinedInput-input': {
        height: '30px',
    },
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& label': {
        paddingTop: '5px',
    },
    '& label.MuiInputLabel-shrink': {
        backgroundColor: '#FFFFFF',
    },

    '& label.MuiInputLabel-shrink+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& .MuiInputBase-input': {
        color: '#000000',

        background: '#FFFFFF',
        '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: '#000000',
        },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        color: '#000000',
        backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#000000',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0047BA',
        },
    },
});

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
        zIndex: 'auto',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: '0px',
        color: '#242424',
        borderSpacing: 0,

        '&:first-of-type': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
        [theme.breakpoints.up('md')]: {
            '&:nth-of-type(2)': {
                width: '215px',
                paddingRight: '5px',
                color: '#0047BA',
                cursor: 'pointer',
            },
            '&:nth-of-type(3)': {
                width: '215px',
                paddingRight: '5px',
            },
            '&:nth-of-type(4)': {
                width: '100px',
                paddingRight: '5px',
            },
            '&:nth-of-type(5)': {
                width: '80px',
                paddingRight: '5px',
            },
            '&:nth-of-type(6)': {
                width: '180px',
                paddingRight: '5px',
            },
            '&:nth-of-type(7)': {
                width: '100px',
                paddingLeft: '3px',
                paddingRight: '3px',
            },
            '&:nth-of-type(8)': {
                width: '100px',
                paddingLeft: '3px',
                paddingRight: '3px',
            },
            '&:nth-of-type(9)': {
                width: '100px',
                paddingLeft: '3px',
                paddingRight: '3px',
            },
            '&:nth-of-type(10)': {
                width: '100px',
                paddingLeft: '3px',
                paddingRight: '3px',
            },
        },
        [theme.breakpoints.up('lg')]: {
            '&:nth-of-type(2)': {
                width: '400px',
                paddingRight: '5px',
            },
            '&:nth-of-type(3)': {
                width: '400px',
                paddingRight: '5px',
            },
            '&:nth-of-type(4)': {
                width: '140px',
                paddingRight: '5px',
            },
            '&:nth-of-type(5)': {
                width: '100px',
                paddingRight: '5px',
            },
            '&:nth-of-type(6)': {
                width: '250px',
                paddingRight: '7px',
            },
            '&:nth-of-type(7)': {
                width: '100px',
                paddingLeft: '3px',
                paddingRight: '3px',
            },
            '&:nth-of-type(8)': {
                width: '120px',
                paddingRight: '5px',
            },
            '&:nth-of-type(9)': {
                width: '190px',
                paddingRight: '5px',
            },
            '&:nth-of-type(10)': {
                width: '80px',
                paddingRight: '5px',
            },
        },
    },
}));
export const StyledTableRow = styled(TableRow)(() => ({
    backgroundColor: '#ffffff',
    height: '50px',
    '&:hover': {
        backgroundColor: theme.palette.cyan['90'],
    },
    '&:nth-of-type(1) td': {
        height: '53px',
        paddingTop: '5px',
    },

    '&:last-child td, &:last-child th': {},
}));

export const CustomTab = styled(Tab)({
    fontSize: '14px',
    color: '#0047BA',
    background: '#E5ECF8',
    borderRadius: '4px 4px 0px 0px',
    border: '2px solid #0047BA',
    borderBottom: 'none',
    '&.Mui-selected': {
        background: '#0047BA',
        color: '#FFFFFF',
        '.MuiChip-root': {
            color: '#0047BA',
            background: '#FFFFFF',
        },
    },
    '.MuiChip-root': {
        background: '#0047BA',
        color: '#FFFFFF',
        borderRadius: '12px',
        width: '40px',
        height: '24px',
    },
});
export default profileStyle;
