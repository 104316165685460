/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { Box, Badge, Stack } from '@mui/material';
import ManageDataDisk from 'pages/provisioning/couchbase/DataDiskModal';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails, validateDisk } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import Btn from 'components/button';

function numberIteration(n) {
    let number = [];
    if (n) {
        number = Array.from(Array(n), (e, i) => ({ label: i + 1, value: i + 1 }))?.filter((z) => z?.value !== 1);
    }
    return number;
}

const DataDisk = ({ indexVal }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const validate = useSetRecoilState(validateDisk);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const DataDiskConfigApi = useMutation(() => axios.get(endPoints.ssp.vm.linuxDiskConfigurations));

    const setDataDisks = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };
    const setDataDiskOpen = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    async function getDataDisks(dataDisks) {
        let result = [];
        await Promise.all(
            dataDisks.map(async (disk, index) => {
                result = [...result, { ...disk, mountPoint: index < order?.nodes?.[indexVal]?.dataDisks.length ? disk?.mountPoint : '' }];
            })
        );
        return result;
    }

    const handleChange = async (selected) => {
        if (Number(selected.value) === 0) {
            const countForDelete = order?.nodes?.[indexVal]?.dataDisks?.length;
            if (countForDelete !== 0) {
                const data = {
                    isOpen: true,
                    newDataDiskCount: '0',
                    deleteCount: countForDelete,
                };
                setDataDisks('isDataDisk', data, indexVal);
            }
        } else {
            const comparePrev = Number(selected?.value) - order?.nodes?.[indexVal]?.dataDisks?.length;
            if (comparePrev > 0) {
                const dataDisks = [
                    ...order?.nodes?.[indexVal]?.dataDisks,
                    ...Array(comparePrev).fill({
                        diskSizeInGib: 32,
                        diskType: ['Production'].includes(order?.environment) ? 'Premium SSD' : 'Standard SSD',
                        fsType: 'ext4',
                        mountPoint: '',
                    }),
                ];
                const result = await getDataDisks(dataDisks);
                setDataDisks('dataDisks', result, indexVal);
            } else {
                const countForDelete = Number(Math.abs(comparePrev));
                if (countForDelete !== 0) {
                    const data = {
                        isOpen: true,
                        newDataDiskCount: Number(selected?.value),
                        deleteCount: countForDelete,
                    };
                    setDataDisks('isDataDisk', data, indexVal);
                }
            }
        }
        validate({ DataDiskConfigApi, indexVal });
    };
    React.useEffect(() => {
        if (
            (options?.length && options?.length === 1 && !order?.nodes?.[indexVal]?.dataDisks?.length > 0) ||
            (options?.length && options?.length > 1 && order?.nodes?.[indexVal]?.dataDisks?.length === 0)
        ) {
            handleChange(options[0]);
        }

        if (options?.length && options?.length > 1 && order?.nodes?.[indexVal]?.dataDisks?.length > 0) {
            handleChange({ label: `${order?.nodes?.[indexVal]?.dataDisks?.length}`, value: `${order?.nodes?.[indexVal]?.dataDisks?.length}` });
        }
    }, [options]);

    React.useEffect(() => {
        if (DataDiskConfigApi?.isSuccess && order?.nodes?.[indexVal]?.maxDataDiskCount > 0) {
            const data = numberIteration(order?.nodes?.[indexVal]?.maxDataDiskCount);
            setOptions(data);
        }
    }, [DataDiskConfigApi?.isSuccess, order?.nodes?.[indexVal]?.maxDataDiskCount]);

    React.useEffect(() => {
        if (order?.nodes?.[indexVal]?.vmType === null) {
            setOptions([]);
        }
    }, [order?.nodes?.[indexVal]?.vmType]);

    React.useEffect(() => {
        DataDiskConfigApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.nodes?.[indexVal]?.dataDisksCount && order?.isTouched) setIsTouched(true);
    }, [order?.nodes?.[indexVal]?.dataDisksCount, order?.isTouched]);

    return (
        <>
            <Stack direction="row" spacing={1} sx={ProvisioningStyles.dataDisk.stack}>
                <Box sx={ProvisioningStyles.dataDisk.boxWrapper}>
                    <Select
                        key="dataDiskName"
                        label="Data Disk*"
                        noOptionsText={order?.nodes?.[indexVal]?.vmType === null ? 'Select VM Type first' : 'No Options'}
                        value={order?.nodes?.[indexVal]?.dataDisksCount || null}
                        options={options}
                        handleOnSelect={(value) => handleChange(value)}
                        isOptionEqualToValue={(option, value) => option.label.toString() === value.toString()}
                        isRequired={isTouched}
                    />
                </Box>
                <Box sx={ProvisioningStyles.dataDisk.btnWrapper}>
                    <Badge
                        sx={ProvisioningStyles.dataDisk.badgeWidth}
                        color="error"
                        variant={order?.nodes?.[indexVal]?.dataDisksCount && !order?.nodes?.[indexVal]?.isConfigDiskFulfilled ? 'dot' : 'standard'}
                    >
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            disabled={!order?.nodes?.[indexVal]?.dataDisksCount}
                            onClick={() => {
                                setDataDiskOpen('isDiskOpen', true, indexVal);
                            }}
                            size="small"
                            sx={ProvisioningStyles.dataDisk.configureBtn}
                            variant="contained"
                            color="cmpPrimary"
                            data-testid="config-btn"
                        >
                            Configure Disk*
                        </Btn>
                    </Badge>
                </Box>
            </Stack>
            <ManageDataDisk indexVal={indexVal} />
        </>
    );
};

DataDisk.propTypes = {
    indexVal: PropTypes.number,
};

DataDisk.defaultProps = {
    indexVal: 0,
};

export default React.memo(DataDisk);
