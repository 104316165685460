// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import { sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/couchbase';
import { addBucketNames, removeBucketNames, updateBucketNames } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import PropTypes from 'prop-types';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

const BucketNameModal = ({ indexVal }) => {
    const { cartItemId, operation, catalogServiceId } = useParams();
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const addNames = useSetRecoilState(addBucketNames);
    const removeNames = useSetRecoilState(removeBucketNames);
    const updateNames = useSetRecoilState(updateBucketNames);
    const [lastModifiedBN, setLastModifiedBN] = React.useState(undefined);
    const [total, setTotal] = React.useState(0);

    const onClickAdd = (count) => {
        addNames({ count, indexVal });
    };
    const onClickDelete = (index) => {
        removeNames({ index, indexVal });
    };
    const changeSelection = (inputValue, index, key) => {
        updateNames({ inputValue, index, indexVal, key });
    };

    const checkValidBucketNames = (bucketNameValue) => {
        if (bucketNameValue && (bucketNameValue !== '' || bucketNameValue !== null) && !/^[a-zA-Z0-9-_]{3,209}$/.test(bucketNameValue)) {
            return true;
        }
        return false;
    };

    const checkBucketNameDuplicates = (value) => {
        const bucketNameValues = order?.buckets?.[indexVal]?.bucketNames?.map((item) => item?.name);
        const duplicateBucketNames = bucketNameValues?.filter((item, index) => bucketNameValues?.indexOf(item) !== index);
        if (value) {
            return duplicateBucketNames?.indexOf(value) > -1;
        }
        return false;
    };

    const isFormValid = () => {
        let errorCount = 0;
        let isBucketNameValid = false;
        if (order?.buckets?.[indexVal]?.bucketNames?.length) {
            order?.buckets?.[indexVal]?.bucketNames?.forEach((item) => {
                if (!(item?.name?.length > 0 && /^[a-zA-Z0-9-_]{3,209}$/.test(item?.name))) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isBucketNameValid = errorCount === 0;
        return isBucketNameValid;
    };

    function ifDuplicateValue() {
        const arr = order?.buckets?.[indexVal]?.bucketNames?.map((item) => item?.name);
        // eslint-disable-next-line
        for (var i = 0; i < arr?.length - 1; i++) {
            // eslint-disable-next-line
            for (var j = i + 1; j < arr?.length; j++) {
                if (arr[i] === arr[j]) {
                    return true;
                }
            }
        }
        return false;
    }

    React.useEffect(() => {
        let totalBucketNames = 0;
        order?.buckets?.forEach((item) => {
            if (item?.bucketNames?.length > 0) {
                totalBucketNames += item?.bucketNames?.length;
            }
        });
        setTotal(totalBucketNames);
    }, [order?.buckets]);

    React.useEffect(() => {
        if ((cartItemId && operation && !catalogServiceId) || (cartItemId && operation && catalogServiceId)) {
            let totalBucketNames = 0;
            order?.buckets?.forEach((item) => {
                if (item?.bucketNames?.[0]?.name !== '') {
                    totalBucketNames += item?.bucketNames?.length;
                }
            });
            setTotal(totalBucketNames);
        }
    }, []);

    React.useEffect(() => {
        if (isOpen?.isBucketNamesOpen) {
            setLastModifiedBN(order?.buckets?.[indexVal]?.bucketNames);
        }
    }, [isOpen?.isBucketNamesOpen]);

    return (
        <Modal
            title="Bucket Names"
            handleClose={() => {
                setIsOpen({ ...isOpen, isBucketNamesOpen: false });
                const bucketNameList = { ...order?.buckets?.[indexVal], bucketNames: lastModifiedBN };
                const updatedBucketDetails = [...order?.buckets?.slice(0, indexVal), bucketNameList, ...order?.buckets?.slice(indexVal + 1)];
                setOrder({
                    ...order,
                    buckets: updatedBucketDetails,
                });
            }}
            open={isOpen.isBucketNamesOpen}
        >
            {total >= 32 && order?.buckets[indexVal]?.bucketNames?.length === 1 && order?.buckets[indexVal]?.bucketNames?.[0]?.name === '' && (
                <Box sx={ProvisioningStyles.bucketNameModal.wrapper}>
                    <Box>
                        <b>Total Bucket names has reached the maximum limit of 32.</b>
                    </Box>
                    <Box>
                        <b>To add bucket names in this list, please delete some names from other bucket list.</b>
                    </Box>
                </Box>
            )}
            {total <= 32 && (
                <Box sx={ProvisioningStyles.bucketNameModal.wrapper}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="h6">Add Bucket Names</Typography>
                    </Stack>
                    <Box sx={ProvisioningStyles.bucketNameModal.contentWrapper}>
                        {order?.buckets?.[indexVal]?.bucketNames?.map((item, index) => (
                            <Box key={index.toString()} sx={ProvisioningStyles.bucketNameModal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                                <Box sx={ProvisioningStyles.bucketNameModal.bucketWrapper}>
                                    <>
                                        <CssTextField
                                            id="outlined-size-small"
                                            size="small"
                                            sx={{
                                                width: (theme) => theme.spacing('100%'),
                                                '& .MuiFormHelperText-root': {
                                                    marginTop: (theme) => theme.spacing('-2'),
                                                },
                                            }}
                                            label="Bucket Name *"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete="off"
                                            error={checkValidBucketNames(item?.name)}
                                            onChange={(e) => {
                                                changeSelection(e.target.value, index, 'name');
                                            }}
                                            value={item?.name ?? ''}
                                            className={checkValidBucketNames(item?.name) || checkBucketNameDuplicates(item?.name) ? 'validate' : ''}
                                        />
                                        {item?.name && checkValidBucketNames(item?.name) && (
                                            <Typography variant="body2" gutterBottom sx={ProvisioningStyles.bucketNameModal.warningTypography}>
                                                Bucket Name should contain atleast 3 chars.
                                            </Typography>
                                        )}
                                        {item?.name && checkBucketNameDuplicates(item?.name) && (
                                            <Typography variant="body2" gutterBottom sx={ProvisioningStyles.bucketNameModal.warningTypography}>
                                                Bucket Names must not have duplicate values.
                                            </Typography>
                                        )}
                                    </>
                                </Box>
                                <Box sx={ProvisioningStyles.bucketNameModal.iconWrapper}>
                                    <Box className="flex w-full ">
                                        {index !== 0 && order?.buckets?.[indexVal]?.bucketNames?.length > 1 && (
                                            <Box sx={ProvisioningStyles.bucketNameModal.deleteWrapper}>
                                                <FaTrashAlt
                                                    style={ProvisioningStyles.bucketNameModal.delete}
                                                    onClick={() => onClickDelete(index)}
                                                    className="w-7 h-7 text-red-base cursor-pointer"
                                                />
                                            </Box>
                                        )}
                                        {index === order?.buckets?.[indexVal]?.bucketNames.length - 1 && total < 32 ? (
                                            <Box sx={ProvisioningStyles.bucketNameModal.addWrapper}>
                                                <MdAdd
                                                    style={ProvisioningStyles.bucketNameModal.add}
                                                    onClick={() => {
                                                        onClickAdd(1);
                                                    }}
                                                    className="w-7 h-7 text-blue-base cursor-pointer"
                                                />
                                            </Box>
                                        ) : (
                                            <span className="w-7 h-7" />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box className="footer" sx={ProvisioningStyles.bucketNameModal.actionWrapper}>
                        <Btn
                            className="custom-btn"
                            variant="contained"
                            color="cmpPrimary"
                            onClick={() => {
                                setIsOpen({ ...isOpen, isBucketNamesOpen: false });
                            }}
                            disabled={!isFormValid() || ifDuplicateValue()}
                            data-testid="save-btn"
                        >
                            SAVE
                        </Btn>
                        <Btn
                            className="custom-btn"
                            sx={ProvisioningStyles.bucketNameModal.cancelBtn}
                            onClick={() => {
                                setIsOpen({ ...isOpen, isBucketNamesOpen: false });
                                const updatedBucketNames = { ...order?.buckets?.[indexVal], bucketNames: lastModifiedBN };
                                const updatedBucketDetails = [
                                    ...order?.buckets?.slice(0, indexVal),
                                    updatedBucketNames,
                                    ...order?.buckets?.slice(indexVal + 1),
                                ];
                                setOrder({
                                    ...order,
                                    buckets: updatedBucketDetails,
                                });
                            }}
                            variant="outlined"
                            color="cmpWarning"
                            layout="outline"
                            data-testid="cancel-btn"
                        >
                            CANCEL
                        </Btn>
                    </Box>
                </Box>
            )}
        </Modal>
    );
};

BucketNameModal.propTypes = {
    indexVal: PropTypes.number,
};

BucketNameModal.defaultProps = {
    indexVal: 0,
};

export default BucketNameModal;
