import React from 'react';
import { Grid } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { getEnv } from 'utils';
import dashboardStyle from 'pages/dashboard/style';
import Card from './components/Card';
import { usersCount, publishedCatalogsCount, applicationCount, ordersSubmittedCount, ordersFulfilledCount, provisionedOrdersCount } from './store';
import { applicationQuery, provisionedResourceQuery } from './utils';

const OverallCount = () => {
    const [appCount, setAppCount] = useRecoilState(applicationCount);
    const [userCount, setUsersCount] = useRecoilState(usersCount);
    const [publishedCatalogCount, setPublishedCatalogCount] = useRecoilState(publishedCatalogsCount);
    const [ordersSubmitted, setSubmittedOrdersCount] = useRecoilState(ordersSubmittedCount);
    const [ordersFulfilled, setFulfilledOrdersCount] = useRecoilState(ordersFulfilledCount);
    const [provisionedOrders, setProvisionedOrdersCount] = useRecoilState(provisionedOrdersCount);
    const applicationsCount = useMutation((payload) => axios.post(`${endPoints.ssp.dashboard.es}/${getEnv('REACT_APP_ES_INDEX_APP_PROFILE')}`, payload));
    const usersCountQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.sql, payload));
    const publishedCatalogsQuery = useMutation((payload) => axios.get(endPoints.catalog.allList, payload));
    const ordersSubmittedCountQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.sql, payload));
    const ordersFulfilledCountQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.sql, payload));
    const provisionedCountQuery = useMutation((payload) => axios.post(`${endPoints.ssp.dashboard.es}/${getEnv('REACT_APP_ES_INDEX_EVENTS')}`, payload));

    React.useEffect(() => {
        applicationsCount.mutate(applicationQuery);
        usersCountQuery.mutate({
            query: 'select count(distinct requestorGpid) from cmp_events',
        });
        publishedCatalogsQuery.mutate();
        ordersSubmittedCountQuery.mutate({
            query: "select count(*) from cmp_orders where docType in ('order', 'manager-approval-orders') and entityDetails.name not like 'CLOUD MARKETPLACE(CMP)%'",
        });

        ordersFulfilledCountQuery.mutate({
            query: "select count(*) from cmp_orders where docType in ('order', 'manager-approval-orders') and entityDetails.name not like 'CLOUD MARKETPLACE(CMP)%' and currentStatus.status in ('COMPLETED','Completed', 'Provisioning Completed')",
        });
        provisionedCountQuery.mutate(provisionedResourceQuery);
    }, []);

    React.useEffect(() => {
        if (applicationsCount?.isSuccess) setAppCount(applicationsCount?.data?.data?.hits?.total?.value || 0);
    }, [applicationsCount?.isSuccess]);

    React.useEffect(() => {
        if (usersCountQuery?.isSuccess) setUsersCount(usersCountQuery?.data?.data?.rows?.[0]?.[0] || 0);
    }, [usersCountQuery?.isSuccess]);

    React.useEffect(() => {
        if (publishedCatalogsQuery?.isSuccess) setPublishedCatalogCount(publishedCatalogsQuery?.data?.data?.catalogs?.length || 0);
    }, [publishedCatalogsQuery?.isSuccess]);

    React.useEffect(() => {
        if (ordersSubmittedCountQuery?.isSuccess) setSubmittedOrdersCount(ordersSubmittedCountQuery?.data?.data?.rows?.[0]?.[0] || 0);
    }, [ordersSubmittedCountQuery?.isSuccess]);

    React.useEffect(() => {
        if (ordersFulfilledCountQuery?.isSuccess) setFulfilledOrdersCount(ordersFulfilledCountQuery?.data?.data?.rows?.[0]?.[0] || 0);
    }, [ordersFulfilledCountQuery?.isSuccess]);

    React.useEffect(() => {
        if (provisionedCountQuery?.isSuccess) setProvisionedOrdersCount(provisionedCountQuery?.data?.data?.hits?.total?.value || 0);
    }, [provisionedCountQuery?.isSuccess]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Grid container sx={dashboardStyle?.ApplicationOnBoardStyle?.wrapper} spacing={2}>
                <Card
                    count={appCount}
                    isLoading={applicationsCount.isLoading}
                    text="Applications Onboarded"
                    secondaryText=""
                    tooltip="Number of application profiles configured in CMP"
                />
                <Card
                    count={userCount}
                    isLoading={usersCountQuery.isLoading}
                    text="Users"
                    secondaryText=""
                    tooltip="Distinct users requested services from CMP"
                />
                <Card
                    count={publishedCatalogCount}
                    isLoading={publishedCatalogsQuery.isLoading}
                    text="Service Offerings"
                    secondaryText=""
                    tooltip="Number of cloud services offered through CMP"
                />
                <Card
                    count={ordersSubmitted}
                    isLoading={ordersSubmittedCountQuery.isLoading}
                    text="Orders Submitted"
                    secondaryText=""
                    tooltip="Number of orders submitted through CMP"
                />
                <Card
                    count={ordersFulfilled}
                    isLoading={ordersFulfilledCountQuery.isLoading}
                    text="Orders Fulfilled"
                    secondaryText=""
                    tooltip="Number of orders completed provisioning through CMP. One order can request multiple resources."
                />
                <Card
                    count={provisionedOrders}
                    isLoading={provisionedCountQuery.isLoading}
                    text="Provisioned Resources"
                    secondaryText=""
                    tooltip="Number of resources provisioned through CMP. More than one resource can be part of a single order."
                />
            </Grid>
        </ErrorBoundary>
    );
};
export default React.memo(OverallCount);
