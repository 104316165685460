/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import Select from 'components/select';
import { aksCatalogServiceCode, kafkaCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';

const Environments = () => {
    const params = useParams();
    const { operation } = params;
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    let envEndpoint;
    switch (landingZoneForm?.service) {
        case aksCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipEnvironments}/AKS/${landingZoneForm?.department}`;
            break;
        case kafkaCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipEnvironments}/Kafka/${landingZoneForm?.department}`;
            break;
        default:
            envEndpoint = endPoints.landingZones.environments;
    }
    const environmentApi = useMutation(() => axios.get(envEndpoint));
    const setEnv = (environment, environmentDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            environment,
            environmentDetails,
            geoRecoveryRegion: null,
            geoRecoveryRegionDetails: null,
            ...([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service) && {
                region: null,
                cluster: null,
            }),
            components:
                landingZoneForm?.components?.map((item) => ({
                    componentId: item?.componentId,
                    landingZone: null,
                    landingZoneDetails: null,
                    provider: item?.provider,
                    service: item?.service,
                })) || [],
            connections: [],
        });
    };
    const seldValueExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEnv(selected?.label, selected);
        } else {
            setEnv(null, null);
        }
    };
    const isDisable = () => {
        if (operation === 'edit') {
            return ![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service);
        }
        return false;
    };
    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service)) {
                setOptions(environmentApi?.data?.data?.map((app) => ({ label: app, value: app })));
            } else {
                setOptions(environmentApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
            }
        }
    }, [environmentApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.environment) {
            setLandingZoneForm({ ...landingZoneForm, environment: options?.[0].label });
        }
        if (options && options?.length && landingZoneForm?.environment) {
            const environment = seldValueExistsInOptions(landingZoneForm?.environment);
            setLandingZoneForm({ ...landingZoneForm, environment: environment?.label });
        }
    }, [options]);

    React.useEffect(() => {
        if (![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service)) environmentApi.mutate();
    }, []);
    React.useEffect(() => {
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service) && landingZoneForm?.department) environmentApi.mutate();
    }, [landingZoneForm?.department]);

    React.useEffect(() => {
        if (!landingZoneForm?.environment && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.environment, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="environment"
                label="Environment Name*"
                value={landingZoneForm?.environment || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={environmentApi.isLoading || isDisable()}
                isLoading={environmentApi.isLoading}
                isRequired={isTouched}
                disableClearable={false}
            />
        </Box>
    );
};

export default React.memo(Environments);
