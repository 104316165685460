/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { isAppNameValid, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const AppName = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [isValid, setAppNameVaild] = useRecoilState(isAppNameValid);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setAppName = (appName) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                appName,
            });
        }
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 6 || value?.length < 3) {
                setAppNameVaild(false);
                setAppName(value);
            } else if (!/^[a-zA-Z]+$/.test(value)) {
                setAppNameVaild(false);
                setAppName(value);
            } else {
                setAppNameVaild(true);
                setAppName(value);
            }
        } else {
            setAppNameVaild(false);
            setAppName(null);
        }
    };

    React.useEffect(() => {
        if (lzModal?.lzDetails?.config?.appName) setAppName(lzModal?.lzDetails?.config?.appName);
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landZoneForm?.appName?.length > 0);
    }, [landZoneForm?.appName]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="App Name"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landZoneForm?.appName}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'App name should be min. 3 to max. 6 chars and alphabets only' : ''}
                `}
                />
            </Stack>
        </>
    );
};

export default React.memo(AppName);
