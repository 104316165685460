/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const AppTier = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const appTierApi = useMutation((catalogCode) => axios.get(`${endPoints.ssp.vm.appTiers_v2}/${catalogCode}`));
    const setAppTier = (appTier, appTierCode) => {
        setOrder({
            ...order,
            appTier,
            appTierCode,
            region: null,
            regionDisplayName: null,
            landingZone: null,
            landingZoneDetails: null,
            envDetails: undefined,
            tags: [],
            defaultTags: [],
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };
    const seldValueExistsInOptions = (appTierValue) => options.find((option) => appTierValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (selected.value !== order?.value && seldValueExistsInOptions(selected.value)) {
            if (selected?.value !== order?.appTier) setAppTier(selected?.value, selected?.data?.code);
        }
    };

    React.useEffect(() => {
        if (appTierApi?.isSuccess) {
            setOptions(appTierApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [appTierApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options.length && options.length === 1 && !order?.appTier) {
            setOrder({ ...order, appTier: options?.[0]?.value, appTierCode: options?.[0]?.data?.code });
        }
        if (options && options.length && order?.appTier) {
            const appTier = seldValueExistsInOptions(order?.appTier);
            if (appTier) setOrder({ ...order, appTier: appTier?.value, appTierCode: appTier?.data?.code });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order?.appProfile && order?.environment && order?.catalogCode) {
            appTierApi.mutate(order?.catalogCode);
        }
        // eslint-disable-next-line
    }, [order.appProfile, order.environment]);

    React.useEffect(() => {
        if (!order?.appTier && order?.isTouched) setIsTouched(true);
    }, [order?.appTier, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="appTier"
                label="App Tier *"
                value={order?.appTier || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled
                isLoading={appTierApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(AppTier);
