import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { Styles } from 'pages/reference-implementation/components/form-card/style';
import { InputInfo } from 'pages/reference-implementation/components/input-info';

const FormCard = ({ title, marginBottom, children, showInfoIcon, infoMessage }) => {
    return (
        <Box borderRadius={1.5} marginBottom={marginBottom} border="1px solid #E0E0E0" bgcolor="#FFFFFF">
            {title && (
                <>
                    <Box sx={Styles.formCard.wrapper}>
                        <Typography sx={Styles.formCard.heading} variant="subtitle1">
                            <Stack direction="row">{title}</Stack>
                            {showInfoIcon && <InputInfo title={infoMessage} />}
                        </Typography>
                    </Box>
                    <Divider variant="middle" />
                </>
            )}
            <Box display="flex" gap={4} flexWrap="wrap" padding={4}>
                {children}
            </Box>
        </Box>
    );
};

FormCard.propTypes = {
    title: PropTypes.any,
    children: PropTypes.element,
    marginBottom: PropTypes.any,
    showInfoIcon: PropTypes.bool,
    infoMessage: PropTypes.string,
};

FormCard.defaultProps = {
    title: undefined,
    children: <></>,
    marginBottom: 2,
    showInfoIcon: false,
    infoMessage: '',
};

export default React.memo(FormCard);
