export const Style = {
    wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
    orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
    nsgOrderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'none', mr: (theme) => theme.spacing(0.5) },
    newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1) },
    newOrderBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
    thankyouTypography: { textAlign: 'center' },
    backHome: {
        textTransform: 'none',
        color: '#0047BA',
        border: '2px solid #0047BA',
        '&:hover': {
            color: '#00A0DD',
            borderWidth: '2px',
            border: '2px solid #00A0DD',
            backgroundColor: 'white',
        },
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            background: '#F4273B',
            mt: 0.5,
        },
        delete: {
            fontSize: (theme) => theme.spacing(2.25),
            fill: '#FFFFFF',
            color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
};
export default Style;
