import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';
import { resetOrder } from 'pages/project-onboarding/utils';

export const Sector = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const sectorApi = useMutation(() => axios.get(endPoints.day2Operation.ProjectOnboarding.sectors));

    const setSector = (sectorVal) => {
        const { sector, courseDetails, ...rest } = resetOrder;
        setOrder({
            ...order,
            sector: sectorVal,
            ...rest,
            serviceResources: {
                ...order.serviceResources,
                isProjectAdmin: false,
                isBoard: false,
                isRepos: false,
                isPipelines: false,
                isSonarQube: false,
                isArtifactory: false,
                isSecurityTools: false,
                isTestPlan: false,
            },
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sector) => options.find((option) => sector === option.value);

    const handleOnSelectChange = (selected) => setSector(selected.value);

    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            setOptions(sectorApi?.data?.data?.sectors?.map((app) => ({ label: app?.name, value: app?.name })));
        }
    }, [sectorApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.sector) {
            setOrder({ ...order, sector: options?.[0].value });
        }
        if (options && options?.length && order?.sector) {
            const sector = isExistsInOptions(order?.sector);
            setOrder({ ...order, sector: sector?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        sectorApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.sector && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.sector, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} marginRight={3.4}>
            <Select
                dataTestId="sector-autocomplete"
                key="sector"
                label="Sector *"
                value={order?.sector || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading}
                isLoading={sectorApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Sector);
