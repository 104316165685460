/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { islandingZoneDescVaild, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const LandingZoneDesc = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [isValid, setDescVaild] = useRecoilState(islandingZoneDescVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setDesc = (landingZoneDesc = null) => {
        setLandingZoneForm({
            ...landZoneForm,
            landingZoneDesc,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 100 || value?.length < 3) {
                setDescVaild(false);
                setDesc(value);
            }
            // else if (!/^[a-zA-Z]+$/.test(value)) {
            //     setDescVaild(false);
            //     setDesc(value);
            // }
            else {
                setDescVaild(true);
                setDesc(value);
            }
        } else {
            setDescVaild(false);
            setDesc();
        }
    };

    React.useEffect(() => {
        if (lzModal?.lzDetails?.config?.landingZoneDesc) setDesc(lzModal?.lzDetails?.config?.landingZoneDesc);
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landZoneForm?.landingZoneDesc?.length > 0);
    }, [landZoneForm?.landingZoneDesc]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Description*"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landZoneForm?.landingZoneDesc}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    // onBlur={() => landZoneForm?.landingZoneDesc?.length > 0 && setShrinkField(false)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'Landing zone desc should be min. 3 to max. 100 chars' : ''}
                `}
                />
                <InfoImg title="Landing zone description" />
            </Stack>
        </>
    );
};

export default React.memo(LandingZoneDesc);
