import React from 'react';
import { Grid } from '@mui/material';
// import { DateTime } from 'luxon';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import { dateRangeValue, dashboardFilter } from 'pages/dashboard-enhancement/store';
import Card from './components/Card';

const OverallCount = () => {
    const [appCount, setAppCount] = React.useState({
        solutionsProvisioned: 0,
        servicesProvisioned: 0,
    });
    const dateRange = useRecoilValue(dateRangeValue);
    const dashboardFilterObj = useRecoilValue(dashboardFilter);

    const aggregatedCountQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            providers: dashboardFilterObj?.provider || [],
            sectors: dashboardFilterObj?.sector || [],
            services: dashboardFilterObj?.services || [],
            solutions: dashboardFilterObj?.solutions || [],
            environments: dashboardFilterObj?.environment || [],
            regions: dashboardFilterObj?.regions || [],
            applicationService: dashboardFilterObj?.ApplicationService?.label || '',
            metricType: 'kpis',
        };
        setAppCount({
            solutionsProvisioned: 0,
            servicesProvisioned: 0,
        });
        aggregatedCountQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (aggregatedCountQuery?.isSuccess) {
            setAppCount(aggregatedCountQuery?.data?.data);
        } else {
            setAppCount({
                solutionsProvisioned: 0,
                servicesProvisioned: 0,
            });
        }
    }, [aggregatedCountQuery?.isSuccess]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Grid container sx={dashboardStyle?.ApplicationOnBoardStyle?.wrapper} spacing={1}>
                <Card
                    count={appCount?.servicesProvisioned}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Services Provisioned"
                    secondaryText=""
                    tooltip="Number of cloud services offered through CMP"
                />
                <Card
                    count={appCount?.solutionsProvisioned}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Solutions Provisioned"
                    secondaryText=""
                    tooltip="Number of orders submitted through CMP"
                />
            </Grid>
        </ErrorBoundary>
    );
};
export default React.memo(OverallCount);
