import React from 'react';
import { Box, Badge, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toggleSspModels, sspSubmitOrder, isDataDiskConfigFullfilled } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import Btn from 'components/button';
import Form from 'pages/provisioning/day2-operation/components/Form';

const ConfigureDataDisk = () => {
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [isDataDiskConfig] = useRecoilState(isDataDiskConfigFullfilled);
    const order = useRecoilValue(sspSubmitOrder);
    const getVariant = () => {
        if (Number(order?.dataDisksCount) === 0) return 'standard';
        if (isDataDiskConfig === false) return 'dot';
        return 'standard';
    };
    return (
        <>
            <Stack direction="row" spacing={1} my={2} sx={ProvisioningStyles.configure.stack}>
                <Box sx={ProvisioningStyles.configure.boxWrapper}>
                    <Form.DataDisk />
                </Box>
                <Box sx={ProvisioningStyles.configure.btnWrapper}>
                    <Badge sx={ProvisioningStyles.configure.badgeWidth} color="error" variant={getVariant()}>
                        <Btn
                            className="btn-disabled custom-btn"
                            fullWidth
                            onClick={() => setIsOpen({ ...isOpen, isDataDiskModalOpen: true })}
                            size="small"
                            sx={ProvisioningStyles.configure.configureBtn}
                            variant="contained"
                            color="cmpPrimary"
                            disabled={!(Number(order?.dataDisksCount) > 0)}
                        >
                            Configure *
                        </Btn>
                    </Badge>
                </Box>
            </Stack>
        </>
    );
};

export default ConfigureDataDisk;
