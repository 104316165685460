import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import DialogModal from 'components/dialog';
import { FiAlertTriangle } from 'react-icons/fi';

const ServiceDialog = (props) => {
    const { isOpen, member, handleNo, handleYes } = props;

    return (
        <DialogModal open={isOpen} handleClose={() => {}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <>
                    <FiAlertTriangle color="#C02223" size={45} />
                    <Typography sx={{ fontFamily: 'Stolzl', fontSize: '20px', fontWeight: 'bold', color: '#000000', paddingTop: '10px', paddingBottom: '5px' }}>
                        <>Are you sure you want to remove {member}?</>
                    </Typography>
                </>

                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        data-testid="removeMemberNo-btn"
                        onClick={() => handleNo()}
                        disableElevation
                        variant="contained"
                        sx={{ marginRight: '10px', textTransform: 'none' }}
                        color="cmpPrimary"
                    >
                        No
                    </Button>
                    <Button
                        data-testid="removeMemberYes-btn"
                        onClick={() => {
                            handleYes();
                        }}
                        disableElevation
                        variant="outlined"
                        sx={{
                            textTransform: 'none',
                            color: '#0047BA',
                            border: '2px solid #0047BA',
                            '&:hover': {
                                color: '#00A0DD',
                                borderWidth: '2px',
                                border: '2px solid #00A0DD',
                                backgroundColor: 'white',
                            },
                        }}
                        color="cmpPrimary"
                    >
                        Yes
                    </Button>
                </Box>
            </Box>
        </DialogModal>
    );
};

ServiceDialog.propTypes = {
    isOpen: PropTypes.bool,
    member: PropTypes.string,
    handleNo: PropTypes.func,
    handleYes: PropTypes.func,
};

ServiceDialog.defaultProps = {
    isOpen: false,
    member: '',
    handleNo: () => null,
    handleYes: () => null,
};

export default React.memo(ServiceDialog);
