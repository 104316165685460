import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { endPoints } from 'chatbot/components/api/endpoints';
import { isResponseLoading } from 'chatbot/store';
import chatBotStyle from '../style';

const Roles = (props) => {
    const { actions, state } = props;
    const [, setResponseLoading] = useRecoilState(isResponseLoading);
    const [roles, setRoles] = React.useState([]);
    const [showBtn, setShowBtn] = React.useState(true);

    React.useEffect(async () => {
        try {
            setResponseLoading(true);
            if (state?.accessToken) {
                const responseData = await axios.get(endPoints.roles, {
                    headers: {
                        Authorization: `Bearer ${state?.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (responseData?.data) setRoles(responseData?.data?.roles);
            }
        } catch (error) {
            actions.displayErrorAction('Error in fetching roles. Please try again later');
        } finally {
            setResponseLoading(false);
        }
    }, []);

    const handleClick = async (role) => {
        const roleValue = Object.keys(roles)?.find((key) => roles[key] === role);
        actions.roleButtonAction(roleValue, role);
        setShowBtn(false);
    };

    return (
        <div display="flex" style={{ marginBottom: '20px', marginLeft: '32px' }}>
            {showBtn && roles && (
                <>
                    {Object.values(roles)?.map((role, index) => (
                        <li key={index} style={chatBotStyle.roleList}>
                            <Button sx={{ ...chatBotStyle.listButton, marginLeft: '3px' }} variant="outlined" onClick={() => handleClick(role)}>
                                {role}
                            </Button>
                        </li>
                    ))}
                </>
            )}
        </div>
    );
};

Roles.propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
};

Roles.defaultProps = {
    actions: {},
    state: {},
};

export default Roles;
