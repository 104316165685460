import theme from 'assets/style/theme';
import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';
import { tableSortLabelClasses } from '@mui/material/TableSortLabel';
import { tableCellClasses } from '@mui/material/TableCell';

const inventoryStyle = {
    container: {
        display: 'flex',
        backgroundColor: theme.palette.grey.lighter,
        width: '100%',
        height: '100%',
        paddingTop: theme.spacing(3.25),
        px: theme.spacing(6.625),
    },
    boxStyle: { padding: theme.spacing(1.875) },
    info: {
        name: {
            color: theme.palette.black,
            fontSize: '27px',
            justifyContent: 'left',
            fontFamily: 'Stolzl',
        },
        count: {
            color: theme.palette.black,
            fontSize: '16px',
            fontFamily: 'Roboto',
        },
    },
    allServiceTitle: {
        color: theme.palette.black,
        fontSize: '18px',
        fontFamily: 'Stolzl',
        my: '20px',
    },
    landingPageFilter: {
        wrapper: { background: '#0047BA', px: theme.spacing(3.75), py: theme.spacing(3), borderRadius: '24px' },
    },
    form: {
        heading: {
            color: theme.palette.white,
            fontSize: '27px',
            justifyContent: 'left',
            fontFamily: 'Stolzl',
        },
        label: {
            pb: '8px',
            color: theme.palette.white,
            fontSize: '18px',
            justifyContent: 'left',
            fontFamily: 'Stolzl',
        },
        applyBtn: {
            width: theme.spacing(25),
            height: theme.spacing(5.625),
            textTransform: 'none',
            backgroundColor: '#003182',
            fontSize: '16px',
            fontFamily: 'Stolzl',
        },
        appOwner: {
            select: { width: '100%' },
            searchIcon: { cursor: 'pointer', color: '#0047BA' },
            infoicon: { color: '#0047BA' },
            clearIcon: {
                cursor: 'pointer',
                color: 'rgba(0, 0, 0, 0.54)',
                width: '1em',
                height: '1em',
            },
        },
    },
    dateRange: {
        calendarWrap: {
            padding: 2,
            position: 'relative',
            top: '10px',
            marginRight: '20px',
        },
    },
    wrapper: {
        background: '#F8F8F8 0% 0% no-repeat padding-box',
        color: theme.palette.white,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        // px: theme.spacing(3),
        // pt: theme.spacing(2),
    },
    header: {
        // wrapper: { backgroundColor: theme.palette.white, borderBottom: '1px solid #0000000 !important', py: theme.spacing(2),paddingRight:theme.spacing(5),paddingLeft:theme.spacing(5) },
        wrapper: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            py: theme.spacing(2),
            paddingRight: theme.spacing('53px'),
            paddingLeft: theme.spacing('53px'),
            borderBottom: '1px solid #E0E0E0',
            height: theme.spacing('82px'),
        },
        skyTypeDD: {
            subscriptonWrapper: { maxWidth: '300px', width: '100%', backgroundColor: theme.palette.white },
            regionWrapper: { maxWidth: '300px', width: '100%', backgroundColor: theme.palette.white },
            wrapper: { backgroundColor: theme.palette.white, borderRadius: theme.spacing(0.25), width: '33.33%', maxWidth: theme.spacing(37.5) },
            select: {
                maxWidth: '300px',
                borderColor: '#E9E9E9',
            },
            textField: { backgroundColor: theme.palette.white, borderRadius: theme.spacing(0.625) },
            inputProps: { color: theme.palette.black.dark },
            totalCount: {
                borderRadius: theme.spacing(1.5),
                width: 'auto',
                marginRight: theme.spacing(2.75),
                color: theme.palette.blue.main,
                backgroundColor: theme.palette.white,
                fontWeight: '600',
                fontSize: theme.spacing(1.5),
                paddingLeft: theme.spacing(0.5),
                paddingRight: theme.spacing(0.5),
            },
        },
        search: {
            wrapper: { display: 'flex', alignItems: 'center', width: 300, border: '1px solid #CBCBCB', borderRadius: theme.spacing(3) },
            inputBase: { ml: theme.spacing(1), mt: theme.spacing(1), flex: theme.spacing(1), background: theme.palette.white, color: '#757575' },
            clearIcon: { color: theme.palette.blue.main },
            searchIcon1: { color: theme.palette.blue.main },
            searchIcon2: { color: theme.palette.blue.main },
        },
        headTextBox: { display: 'flex', gap: 1, width: 'auto' },
        headText: { fontFamily: 'Stolzl', fontSize: { md: '20px', lg: '25px' }, fontWeight: 600, color: '#000000' },
        searchContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1, width: '80%' },
    },
    footer: {
        wrapper: {
            flexDirection: 'column',
            justifyContent: 'center',
            paddingRight: theme.spacing('53px'),
            paddingLeft: theme.spacing('53px'),
            paddingTop: theme.spacing('20px'),
        },
        subWrapper: { flexDirection: 'row', justifyContent: 'space-between', pb: theme.spacing(1) },
        selectedWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
        selectedText: { color: theme.palette.black.dark, fontFamily: 'Roboto', fontSize: '14px' },
        activeInactiveBtn: { display: 'flex', gap: 1 },
        activeBtn: { textTransform: 'none' },
        InActiveBtn: { textTransform: 'none' },
        circularIcon: { marginLeft: '7px' },
    },
    pagination: {
        wrapper: { paddingLeft: theme.spacing('53px'), paddingRight: theme.spacing('53px') },
        subWrapper: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
        tableFooter: {
            height: '70px',
            border: '1px solid rgba(224, 224, 224, 1)',
            backgroundColor: '#FFFFFF',
        },
        tablePagination: {
            backgroundColor: '#FFFFFF',
            width: 'auto',
            color: theme.palette.black.dark,
            border: '1px solid #E0E0E0',
            '.MuiTablePagination-spacer': {
                flex: 0,
                // '-webkit-flex': 0,
            },
            '.MuiToolbar-root': {
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row-reverse',
                alignItems: 'center',
            },
            '.MuiTablePagination-displayedRows': {
                fontWeight: 500,
                fontSize: '1rem',
                color: '#1E1E1E',
                paddingTop: '2px',
            },
        },
        footermsg: { fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'normal', color: theme.palette.black.dark },
    },
    details: {
        wrapper: { height: '400px', overflowY: 'auto', minWidth: '70%', overflowX: 'hidden', paddingTop: '10px' },
        // wrapper: { height: '400px',},
        jsonLabelValue: { margin: 0 },
        footer: { display: 'flex', justifyContent: 'flex-end', pt: theme.spacing(2) },
        activeBtn: {
            textTransform: 'none',
            // backgroundColor: '#91C24C',
            // '&:hover': {
            //     backgroundColor: '#91C24C',
            // },
        },
        circularIcon: { marginLeft: '7px' },
        headerText: { fontFamily: 'Stolzl', fontSize: '28px', fontWeight: 'normal', marginLeft: theme.spacing(4) },
        gridContainer: { marginLeft: theme.spacing('29px'), marginTop: theme.spacing('20px') },
    },
    list: {
        wrapper: {
            color: theme.palette.white,
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'row',
            pt: theme.spacing(1.7),
            pb: theme.spacing(1),
            paddingLeft: theme.spacing('53px'),
            paddingRight: theme.spacing('53px'),
        },
        tableContainer: {
            overflowX: 'hidden',
            marginTop: theme.spacing(0.5),
            // border:'1px solid #E0E0E0',
            borderRadius: '3px',
            boxShadow: '1px 1px 2px #00000014',
        },
        table: { marginTop: theme.spacing(-1.125), borderSpacing: theme.spacing(0), minWidth: 500, height: 'auto' },
        checkboxTblHead: { height: theme.spacing(4), color: theme.palette.white },
        loadingRow: { background: 'transparent !important' },
        loadingCell: { border: '0 !important' },
        checkbox: { height: theme.spacing(3.75), fill: theme.palette.blue.main },
        circularIcon: { marginLeft: theme.spacing(1.25), color: '#0047BA' },
        styledTableRow: {
            backgroundColor: theme.palette.white,
            height: '50px',
            '&:hover': {
                backgroundColor: theme.palette.cyan['90'],
            },
            '&:nth-of-type(1) td': {
                height: '63px',
                paddingTop: '15px',
            },
            '&:last-child td, &:last-child th': {},
        },
        subscriptionCount: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            color: theme.palette.black.dark,
            paddingTop: theme.spacing(2.5),
            paddingLeft: theme.spacing('54px'),
            paddingBottom: theme.spacing(0.5),
        },
        styledTableSortLabel: {
            [`&.${tableSortLabelClasses.active}`]: {
                color: theme.palette.white,
            },
            '&:hover': {
                color: theme.palette.white,
            },
        },
        styledTableCell: {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: theme.palette.blue.main,
                color: theme.palette.white,
                fontSize: theme.spacing(2),
                padding: theme.spacing(0),
                height: '50px',
                border: 'none',
                '&:second-child': {
                    '& svg': {
                        opacity: 1,
                        color: 'red',
                    },
                },
                '&:first-of-type': {
                    paddingLeft: '13px',
                },
                '&:not(:first-of-type)': {
                    paddingLeft: '13px',
                },
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
                borderTop: '1px solid rgba(224, 224, 224, 1)',
                paddingTop: '15px',
                color: theme.palette.blue[30],
                height: '50px',
            },

            '&:first-of-type': {
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
            },
            '&:last-child': {
                borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
        },
    },

    filterButton: { color: theme.palette.blue.main, borderColor: theme.palette.blue.main, border: '2px solid', height: '37px' },
    orderFilter: {
        container: {
            color: theme.palette.white,
            borderColor: '#0063cc',
            border: '2px solid',
        },
        wrapper: {
            background: '#FFFFFF',
            width: 260,
            padding: 2,
            position: 'relative',
            top: 2,
        },
        arrow: {
            position: 'absolute',
            fontSize: 60,
            top: -34,
            left: 106,
            fill: '#FFFFFF',
            zIndex: 1,
        },
        // filter: {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     border: 'solid 1px #ffffff',
        //     marginRight: 1.5,
        //     paddingX: 0.5,
        // }
        filterIcon: { color: theme.palette.white },
        closeIcon: { cursor: 'pointer', '&:hover': { color: '#f99c24' } },
        popper: {
            container: { display: 'flex', justifyContent: 'end' },
        },
        dropdownStack: { mb: theme.spacing(2), pt: theme.spacing(1), width: '100%' },
    },
    loadingStack: { width: theme.spacing('100%'), height: 'calc(100vh - 100px)' },
};

export default inventoryStyle;

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: theme.palette.blue.main,
    },
    '& label.MuiFormLabel-filled': {
        color: theme.palette.blue.main,
        padding: '0 2px',
    },
    '& label': {
        color: '#757575',
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& div.subscriptionSelect .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.blue.main,
            color: '#757575',
        },
    },
    // '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //         borderColor: '#E9E9E9',
    //         color: '#AFB8CC',
    //     },
    //     '&:hover fieldset': {
    //         borderColor: '#E9E9E9',
    //     },
    //     '&.Mui-focused fieldset': {
    //         borderColor: '#E9E9E9',
    //     },
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#CBCBCB',
            color: '#757575',
        },
        '&:hover fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.MuiFormLabel-filled fieldset': {
            borderColor: '#0047BA',
        },
    },
});
export const CssTextField2 = styled(TextField)({
    '& label+div .MuiOutlinedInput-input': {
        height: '20px',
    },
    '& label.MuiFormLabel-filled+div .MuiOutlinedInput-input': {
        height: '20px',
    },
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiFormLabel-filled': {
        // background: '#00709B',
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label': {
        // color: '#AFB8CC',
        // borderRadius: 10,
    },
    '& .MuiInputBase-input': {
        color: '#27344B',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            background: 'transparent',
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#AFB8CC',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E9E9E9',
        },
    },
});
export const InventoryTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: theme.palette.blue.main,
    },
    '& label.MuiFormLabel-filled': {
        color: theme.palette.blue.main,
        padding: '0 2px',
    },
    '& label': {
        color: theme.palette.gray.light,
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& div.subscriptionSelect .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.blue.main,
            color: theme.palette.gray.light,
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.blue.main,
            color: theme.palette.gray.light,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.blue.main,
        },
        '&.MuiFormLabel-filled fieldset': {
            borderColor: '#0047BA',
        },
    },
});
export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: theme.palette.white,
    '&:hover': {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
    },
}));
