/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import Chart from './components/Chart';
import AppDashboardDateRange from './ApplicationDateRange';
import ApplicationFilter from './ApplicationFilter';
import ApplicationBoradChart from './ApplicationBoradChart';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload?.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const ApplicationOnBoarded = () => {
    const DashboardMetricsFilter = () => {
        return (
            <Stack spacing={2} direction="row" sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
                <AppDashboardDateRange />
                <ApplicationFilter />
            </Stack>
        );
    };
    return (
        <Chart title="Applications Onboarded" showFilter FilterComponent={DashboardMetricsFilter}>
            <ApplicationBoradChart />
        </Chart>
    );
};
export default React.memo(ApplicationOnBoarded);
