import React from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, TablePagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { scope } from 'utils/scope';
import { camelToTitle, getUserScopes } from 'utils';
import TablePaginationActions from 'components/tablePaginationActions';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Empty from 'components/empty';
import {
    // landingZoneForm,
    // toggleCreateLandingZone,
    landingZoneTotalCount,
    landZoneListFilter,
    toggleConfirmLZModel,
    toggleSummaryLZ,
    toggleLandingZoneModal,
} from 'pages/app-profiles/store';
import LandingZoneStyle, { StyledTableCell, StyledTableRow } from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { isAppOwner, isCreatedByUser } from 'pages/app-profiles/utils';
import { getLandingZoneData } from 'pages/app-profiles/landing-zones-new/getLandingZoneData';
import { getLandingZoneSummary } from 'pages/app-profiles/landing-zones-new/getLandingZoneSummary';

const LandingZoneListNew = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { appProfileName, appOwnerGpid } = props;
    // const [form, setForm] = useRecoilState(landingZoneForm);
    // const [lzModal, setLZModal] = useRecoilState(toggleCreateLandingZone);
    const [landingZoneModal, setLandingZoneModal] = useRecoilState(toggleLandingZoneModal);
    const [lzSummary, setLzSummary] = useRecoilState(toggleSummaryLZ);
    const [modal, setModal] = useRecoilState(toggleConfirmLZModel);
    const [totalCount, setTotalCount] = useRecoilState(landingZoneTotalCount);
    const [filterObj, setFilterObj] = useRecoilState(landZoneListFilter);
    const handleChangePage = (_event, newPage) => {
        setFilterObj({ ...filterObj, pageNumber: newPage + 1 });
    };
    const handleChangeRowsPerPage = (event) => {
        setFilterObj({ ...filterObj, pageNumber: 1, pageSize: parseInt(event.target.value, 10) });
    };
    const landingZoneDisplayColumns = ['', 'provider', 'service', 'Label', 'environment', 'region', 'status', 'createdBy'];
    const landingZoneListApi = useMutation((payload) => axios.post(endPoints.landingZones.landingZones, payload));
    const handleDelete = (id1) => {
        setModal({
            ...modal,
            type: 'delete',
            isOpen: true,
            message: t([`landingZone.confirmText.delete`]),
            id: id1,
            appProfileName,
        });
    };
    React.useEffect(() => {
        if (appProfileName) {
            landingZoneListApi.mutate({
                appProfileName,
                // appTier: filterObj?.appTier,
                cloudProvider: filterObj?.cloudProvider,
                cloudService: filterObj?.cloudService,
                environment: filterObj?.environment,
                page: filterObj?.pageNumber,
                region: filterObj?.region,
                size: filterObj?.pageSize,
            });
        }
    }, [filterObj]);
    React.useEffect(() => {
        if (landingZoneListApi?.data) {
            setTotalCount(landingZoneListApi?.data?.data?.totalLandingZonesCount);
        }
    }, [landingZoneListApi?.data]);
    const modTableActions = () => (
        <TablePaginationActions
            rowsPerPageOptions={[5, 10, 20, 25, 100]}
            handleChRowsPerPage={handleChangeRowsPerPage}
            count={totalCount}
            rowsPerPage={filterObj?.pageSize}
            page={filterObj?.pageNumber - 1}
            onPageChange={handleChangePage}
        />
    );
    return (
        <>
            <Box data-testid="landing-zone-list" sx={LandingZoneStyle?.list?.wrapper}>
                <TableContainer sx={LandingZoneStyle?.list?.tableContainer}>
                    <Table sx={LandingZoneStyle?.list?.table} size="small" stickyHeader>
                        <TableHead>
                            <TableRow sx={LandingZoneStyle?.list?.tableRow}>
                                {landingZoneDisplayColumns?.map((head, index) => (
                                    <React.Fragment key={`header${index}`}>
                                        {index === 0 && <StyledTableCell key={`head-dummy-${index.toString()}`}>&nbsp;</StyledTableCell>}
                                        <StyledTableCell key={`head-${index.toString()}`}>{camelToTitle(head)}</StyledTableCell>
                                    </React.Fragment>
                                ))}
                                {(getUserScopes()?.includes(scope[2]) || isAppOwner({ appOwnerGpid })) && (
                                    <StyledTableCell align="center">Actions</StyledTableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {landingZoneListApi?.isLoading && (
                                <StyledTableRow sx={LandingZoneStyle?.list?.loaderRow}>
                                    <StyledTableCell sx={LandingZoneStyle?.list?.loaderCell} colSpan="11">
                                        <Empty isLoading={landingZoneListApi?.isLoading} msg="Loading..." color="#000000" />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {!landingZoneListApi.isLoading && (landingZoneListApi.error || !landingZoneListApi?.data?.data?.landingZones?.length > 0) && (
                                <StyledTableRow sx={LandingZoneStyle?.list?.loaderRow}>
                                    <StyledTableCell sx={LandingZoneStyle?.list?.loaderCell} colSpan="11">
                                        <Empty
                                            isLoading={landingZoneListApi?.isLoading}
                                            notFound
                                            msg={landingZoneListApi?.error || 'No records'}
                                            color="#000000"
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                            {!landingZoneListApi.isLoading &&
                                landingZoneListApi?.data?.data?.landingZones?.length &&
                                landingZoneListApi?.data?.data?.landingZones?.map((result, index) => (
                                    <StyledTableRow sx={LandingZoneStyle?.list?.tableRow} className="animate-opacity" key={`row-${index.toString()}`}>
                                        <StyledTableCell key={`data-dummy-${index.toString()}`} width="20px">
                                            &nbsp;
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {isCreatedByUser({ name: result?.created?.by?.name }) && (
                                                <AccountCircleIcon sx={LandingZoneStyle?.list?.userIcon} color="cmpPrimary" />
                                            )}
                                            {!isCreatedByUser({ name: result?.created?.by?.name }) && (
                                                <VerifiedUserIcon sx={LandingZoneStyle?.list?.userIcon} color={result?.isDraft ? 'cmpWarning' : 'cmpSuccess'} />
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>{result?.cloudProvider}</StyledTableCell>
                                        <StyledTableCell>{result?.cloudService}</StyledTableCell>
                                        <StyledTableCell>{result?.label || '-'}</StyledTableCell>
                                        <StyledTableCell>{result?.environment}</StyledTableCell>
                                        {/* <StyledTableCell>{result?.appTier?.name}</StyledTableCell> */}
                                        <StyledTableCell>{result?.region.name}</StyledTableCell>
                                        <StyledTableCell>{result?.isDraft ? 'Draft' : 'Approved'}</StyledTableCell>
                                        <StyledTableCell>{result?.created?.by?.name}</StyledTableCell>

                                        {(getUserScopes()?.includes(scope[2]) || isAppOwner({ appOwnerGpid })) && (
                                            <StyledTableCell>
                                                <Box display="flex" justifyContent="center">
                                                    {getUserScopes()?.includes(scope[2]) && (
                                                        <>
                                                            <Link
                                                                to={{
                                                                    pathname: `/app-details/${id}/edit/${result?.id}`,
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={LandingZoneStyle?.list?.editWrapper}
                                                                    onClick={() => {
                                                                        setLandingZoneModal({ ...landingZoneModal, isOpen: true });
                                                                    }}
                                                                    data-testid="edit-btn"
                                                                >
                                                                    <FaEdit style={LandingZoneStyle?.list?.editIcon} />
                                                                </Box>
                                                            </Link>
                                                            {/* <Box
                                                                sx={LandingZoneStyle?.list?.editWrapper}
                                                                onClick={() => {
                                                                    const data = getLandingZoneData({ appName: appProfileName, data: result });
                                                                    setForm({ ...form, ...data });
                                                                    setLZModal({ ...lzModal, isOpen: true, type: 'edit', lzDetails: result });
                                                                }}
                                                                data-testid="edit-btn"
                                                            >
                                                                <FaEdit style={LandingZoneStyle?.list?.editIcon} />
                                                            </Box> */}
                                                            <Box
                                                                sx={LandingZoneStyle?.list?.deleteWrapper}
                                                                onClick={() => handleDelete(result?.id)}
                                                                data-testid="delete-btn"
                                                            >
                                                                <FaTrashAlt style={LandingZoneStyle?.list?.deleteIcon} />
                                                            </Box>
                                                        </>
                                                    )}
                                                    {isAppOwner({ appOwnerGpid }) && (
                                                        <Box
                                                            sx={LandingZoneStyle?.list?.eyeWrapper}
                                                            onClick={() => {
                                                                const data = getLandingZoneData({ appName: appProfileName, data: result });
                                                                const summary = getLandingZoneSummary({ appName: appProfileName, data: result });
                                                                setLzSummary({ ...lzSummary, isOpen: true, lzDetails: summary, form: data });
                                                            }}
                                                            data-testid="view-btn"
                                                        >
                                                            <RemoveRedEyeRoundedIcon sx={LandingZoneStyle?.list?.eyeIcon} />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </StyledTableCell>
                                        )}
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                    {landingZoneListApi?.data?.data?.landingZones?.length && totalCount && (
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[5, 10, 20, 25, 100]}
                            sx={LandingZoneStyle?.list?.pagination?.tablePagination}
                            count={totalCount}
                            rowsPerPage={filterObj?.pageSize}
                            page={filterObj?.pageNumber - 1}
                            SelectProps={{
                                sx: {
                                    display: 'none',
                                },
                            }}
                            labelRowsPerPage=""
                            ActionsComponent={modTableActions}
                            onPageChange={() => {}}
                        />
                    )}
                </TableContainer>
            </Box>
        </>
    );
};
LandingZoneListNew.propTypes = {
    appProfileName: PropTypes.string,
    appOwnerGpid: PropTypes.string,
};
LandingZoneListNew.defaultProps = {
    appProfileName: '',
    appOwnerGpid: '',
};
export default LandingZoneListNew;
