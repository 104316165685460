/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import ServiceOffering from './ServiceOfferings';
import DashboardMetrics from './DashboardMetrics';
import ApplicationOnboarded from './ApplicationOnBoard';
import dashboardStyle from './style';

const DashboardEnhancement = () => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Helmet>
            <title>Dashboard - CMP</title>
        </Helmet>
        <Box sx={dashboardStyle.dashboard_wrapper} data-testId="dashboardWrapper" className="dashboardWrapper">
            <Box sx={dashboardStyle.box_wrapper}>
                <Box sx={dashboardStyle.dashboard_container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ServiceOffering />
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardMetrics />
                        </Grid>
                        <Grid item xs={12}>
                            <ApplicationOnboarded />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    </ErrorBoundary>
);

export default DashboardEnhancement;
