import groupBy from 'lodash/groupBy';
import { getLoggedUserGPID } from 'utils';

export const getPreviewPayload = ({ order }) => {
    const getADGroupOwnerId = () => {
        let ownerId = '';
        if (order?.nonPrivilegedADGroupOwnerDetails) {
            ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid
                ? order?.nonPrivilegedADGroupOwnerDetails?.gpid
                : order?.nonPrivilegedADGroupOwnerDetails?.id;
        } else if (order?.privilegedADGroupOwnerDetails) {
            ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
        }
        return ownerId;
    };
    const payload = {
        ...(order?.projectId && { 'project-id': order?.projectId }),
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        'budget-code': order?.budgetCode,
        ...(order?.billingInfo && { 'billing-information': order?.billingInfo }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'business-application': order?.businessApp,
        'manager-id': order?.appOwnerDetails?.gpid,
        ...(order?.businessAppService === 'Yes' && {
            'application-service': order?.appService,
        }),
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'business-owner-id': order?.businessContactDetails?.gpid,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-group': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
        ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-group': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),
        ...(order?.orderId && {
            'order-id': order?.orderId,
        }),
    };
    return payload;
};

export const getCostCalcPayload = ({ order }) => {
    return {
        'project-id': order?.projectId,
        sector: order?.sector,
        environment: order?.environment,
    };
};

export const getDownloadConfigPayload = ({ order = {} }) => {
    const payload = {
        'project-id': order?.projectId,
        'application-name': order?.applicationName,
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'budget-code': order?.budgetCode,
        'reader-ad-group': order?.adGroupReader || null,
        'contributor-ad-group': order?.adGroupContributor || null,
        requestorGpid: getLoggedUserGPID(),
    };
    return payload;
};

export const getOrderPayload = ({ order }) => {
    const getADGroupOwnerId = () => {
        let ownerId = '';
        if (order?.nonPrivilegedADGroupOwnerDetails) {
            ownerId = order?.nonPrivilegedADGroupOwnerDetails?.gpid
                ? order?.nonPrivilegedADGroupOwnerDetails?.gpid
                : order?.nonPrivilegedADGroupOwnerDetails?.id;
        } else if (order?.privilegedADGroupOwnerDetails) {
            ownerId = order?.privilegedADGroupOwnerDetails?.gpid ? order?.privilegedADGroupOwnerDetails?.gpid : order?.privilegedADGroupOwnerDetails?.id;
        }
        return ownerId;
    };
    const payload = {
        ...(order?.projectId && { 'project-id': order?.projectId }),
        'business-unit': order?.businessUnit,
        sector: order?.sector,
        ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
        'budget-code': order?.budgetCode,
        ...(order?.billingInfo && { 'billing-information': order?.billingInfo }),
        environment: order?.environment,
        'data-classification': order?.dataClassification,
        'business-application': order?.businessApp,
        'manager-id': order?.appOwnerDetails?.gpid,
        ...(order?.businessAppService === 'Yes' && {
            'application-service': order?.appService,
        }),
        'application-name': order?.applicationName,
        'app-short-name': order?.appShortName,
        'business-owner-id': order?.businessContactDetails?.gpid,
        ...(order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'Yes' && { 'reader-ad-group': order?.nonPrivilegedADGroupList?.map((z) => z?.readerValue) }),
        ...(order?.privilegedADGroup === 'Yes' && { 'contributor-ad-group': order?.privilegedADGroupList?.map((z) => z?.contributorValue) }),
        ...(order?.environment?.toLowerCase() !== 'production' &&
            (order?.nonPrivilegedADGroup === 'No' || order?.privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': getADGroupOwnerId(),
            }),
        ...(order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.privilegedADGroupOwnerDetails?.gpid
                    ? order?.privilegedADGroupOwnerDetails?.gpid
                    : order?.adGroupOwnerDetails?.id,
            }),
    };
    return payload;
};

export const getResubmitOrderPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    const nonPrivilegedADGroup = catalog?.readerAdGroup?.length ? 'Yes' : 'No';
    const privilegedADGroup = catalog?.contributorAdGroup?.length ? 'Yes' : 'No';

    const payload = {
        ...(catalog?.projectId && { 'project-id': catalog?.projectId }),
        'business-unit': catalog?.businessUnit,
        sector: catalog?.sector,
        ...(['cgf']?.includes(catalog?.sector?.toLowerCase()) && { division: catalog?.division }),
        'budget-code': catalog?.costCenter,
        ...(catalog?.billingInformation && { 'billing-information': catalog?.billingInformation }),
        environment: catalog?.environment,
        'data-classification': catalog?.dataClassification,
        'business-application': catalog?.businessApplication,
        'manager-id': order?.manager?.id,
        ...(catalog?.businessApplication &&
            catalog?.applicationService && {
                'application-service': catalog?.applicationService,
            }),
        'application-name': catalog?.applicationName,
        'app-short-name': catalog?.applicationShortName,
        'business-owner-id': catalog?.businessOwnerId,
        ...(catalog?.readerAdGroup && catalog?.readerAdGroup?.length && { 'reader-ad-group': catalog?.readerAdGroup }),
        ...(catalog?.contributorAdGroup && catalog?.contributorAdGroup?.length && { 'contributor-ad-group': catalog?.contributorAdGroup }),
        ...(catalog?.environment?.toLowerCase() !== 'production' &&
            (nonPrivilegedADGroup === 'No' || privilegedADGroup === 'No') && {
                'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id,
            }),
        ...(catalog?.environment?.toLowerCase() === 'production' &&
            privilegedADGroup === 'No' && {
                'active-directory-group-owner-id': order?.activeDirectoryGroupOwner?.id,
            }),
    };
    return payload;
};
const getRoleAssignments = (roles = []) => {
    const accessLevels = [...new Set(roles?.map((role) => role['access-levels'])?.flat(Infinity))];
    const rolesobj = {};
    if (!accessLevels?.includes(null)) {
        accessLevels?.forEach((accessLevel) => {
            rolesobj[accessLevel] =
                (accessLevel !== null && roles?.filter((role) => role['access-levels']?.includes(accessLevel))?.map((item) => item?.assignee)) || [];
        });
    }
    return Object.keys(rolesobj)?.length > 0 ? rolesobj : null;
};

export const snakeToTitle = (camelCase = '') => {
    const snakeCase = camelCase
        ?.toLowerCase()
        ?.split('-')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
    return snakeCase;
};
const getResourceItem = (resourceType = '', resourceObj = {}) => {
    const formattedObj = { 'Resource Type': snakeToTitle(resourceType), 'Resource Properties': {}, 'Role Assignments': null, 'Access Policy': null };

    Object.keys(resourceObj)?.map((key) => {
        if (key === 'role-assignments' || key === 'access-Policy') {
            formattedObj[snakeToTitle(key)] = getRoleAssignments(resourceObj[key]);
        }
        if (key !== 'network' && key !== 'tags' && key !== 'role-assignments' && key !== 'access-Policy') {
            formattedObj['Resource Properties'][key !== 'powerBi-Gateway-RequestUrl' ? snakeToTitle(key) : 'Power BI Gateway Request URL'] = resourceObj[key];
        }
        return '';
    });
    return formattedObj;
};
const getResources = (resources = {}) => {
    const result = [];
    Object.keys(resources)?.map((resource) => {
        if (resource === 'resource-group' || resource === 'log-analytics-workspace') {
            result?.push(getResourceItem(resource, resources[resource]));
        } else if (resource === 'blob-storage' || resource === 'data-lake-storage') {
            const groupByStorageType = groupBy(resources[resource], 'storage-type');
            Object.keys(groupByStorageType)?.map((item) => {
                const formattedObj = {
                    'Resource Type': `${snakeToTitle(resource)} (${item?.split('_')?.[0]})`,
                    'Resource Properties': { 'Storage Account Name(s)': [] },
                    'Role Assignments': null,
                    'Access Policy': null,
                };
                groupByStorageType?.[item]?.forEach((i) => {
                    Object.keys(i)?.map((key) => {
                        if (key === 'role-assignments' || key === 'access-Policy') {
                            formattedObj[snakeToTitle(key)] = getRoleAssignments(i[key]);
                        }
                        if (key !== 'network' && key !== 'tags' && key !== 'role-assignments' && key !== 'storage-type' && key !== 'access-Policy') {
                            if (key === 'storage-account-name') {
                                formattedObj['Resource Properties']['Storage Account Name(s)'] = [
                                    ...formattedObj?.['Resource Properties']?.['Storage Account Name(s)'],
                                    i?.['storage-account-name'],
                                ];
                            } else {
                                formattedObj['Resource Properties'][snakeToTitle(key)] = i[key];
                            }
                        }
                        return '';
                    });
                });
                result.push(formattedObj);
                return '';
            });
        } else {
            resources[resource]?.map((item) => {
                result?.push(getResourceItem(resource, item));
                return '';
            });
        }
        return '';
    });

    const resourceWithAdGroup = result?.filter((item) => item['Role Assignments'] !== null) || [];
    const resourceWithoutAdGroup = result?.filter((item) => item['Role Assignments'] === null) || [];
    const sortByResourceProperties =
        [
            ...resourceWithoutAdGroup?.filter((item) => Object?.keys(item?.['Resource Properties'])?.length > 1),
            ...resourceWithoutAdGroup?.filter((item) => Object?.keys(item?.['Resource Properties'])?.length === 1),
        ] || [];

    return [...resourceWithAdGroup, ...sortByResourceProperties];
};

export const getpreviewDetails = ({ order }) => {
    const previewDetails = { resources: getResources(order?.environmentDetails?.resources) };
    Object.keys(order?.environmentDetails).map((key) => {
        if (!['estimated-cost', 'resources', 'tech-input-json']?.includes(key)) {
            previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
        }
        return '';
    });
    return previewDetails;
};
