export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        nsExist: catalogConfig?.namespaceExist,
        namespaceName: catalogConfig?.namespaceName,
        performance: catalogConfig?.performance,
        ...(catalogConfig?.performance === 'Standard' && {
            throughputUnits: catalogConfig?.throughputUnit,
            isAutoInflate: catalogConfig?.enableAutoInflate,
        }),
        ...(catalogConfig?.performance === 'Standard' &&
            catalogConfig?.enableAutoInflate && {
                autoInflateMaxThroughputUnits: catalogConfig?.autoInflateMaximumThroughputUnit,
            }),
        ...(catalogConfig?.performance === 'Premium' && {
            processingUnits: catalogConfig?.processingUnit,
        }),
        isGeoReplication: catalogConfig?.enableGeoReplication,
        eventHubDetails: catalogConfig?.eventHubDetails?.map((item) => ({
            eventHubName: item?.eventHubName,
            retention: item?.retentionPeriod?.toString(),
            partitionCount: item?.partitionCount,
            consumerGroupList: item?.consumerGroup?.split(',').map((z1) => ({ value: z1 })),
        })),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        nsExist: catalogConfig?.namespaceExist,
        namespaceName: catalogConfig?.namespaceName,
        performance: catalogConfig?.performance,
        ...(catalogConfig?.performance === 'Standard' && {
            throughputUnits: catalogConfig?.throughputUnit,
            isAutoInflate: catalogConfig?.enableAutoInflate,
        }),
        ...(catalogConfig?.performance === 'Standard' &&
            catalogConfig?.enableAutoInflate && {
                autoInflateMaxThroughputUnits: catalogConfig?.autoInflateMaximumThroughputUnit,
            }),
        ...(catalogConfig?.performance === 'Premium' && {
            processingUnits: catalogConfig?.processingUnit,
        }),
        isGeoReplication: catalogConfig?.enableGeoReplication,
        eventHubDetails: catalogConfig?.eventHubDetails?.map((item) => ({
            eventHubName: item?.eventHubName,
            retention: item?.retentionPeriod.toString(),
            partitionCount: item?.partitionCount,
            consumerGroupList: item?.consumerGroup?.split(',').map((z1) => ({ value: z1 })),
        })),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const resetAppProfile = {
    appProfile: null,
    appProfileDetails: null,
};
export const resetOrder = {
    entityDetails: null,
    sector: null,
    sectorDetails: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    hCode: null,
    hCodeDetails: null,
    costCenter: null,
    envDetails: null,
    namespaceName: null,
    nsExist: 'no',
    performance: 'Standard',
    throughputUnits: '1',
    isAutoInflate: 'no',
    autoInflateMaxThroughputUnits: null,
    processingUnits: '1',
    isGeoReplication: 'yes',
    eventHubDetails: [{ eventHubName: '', partitionCount: 1, retention: null, isEventHubTouched: false, consumerGroupList: [{ value: '' }] }],
    project: '',
    tags: [],
    estimatedCost: {},
    appTier: 'Kafka Topics',
    appTierCode: 'KT',
    validationStatus: 'clearValidation',
    isTouched: false,
    cartMessage: false,
};

export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
    Approval_Manager: '---',
    Data_Clasification: '---',
    Project: '---',
    TLS_Version: '---',
    Geo_Replication_Region: '---',
    Selected_Networks: '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isConsumerGroupOpen: false,
    isSubmitOrderSuccess: false,
    isUpdatedToCart: false,
    isCostSplitOpen: false,
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};
