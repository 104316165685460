import { styled } from '@mui/material/styles';
import { RadioGroup, Radio } from '@mui/material';

const ProvisioningStyles = {
    card: {
        paper: {
            padding: (theme) => theme.spacing(2),
            background: (theme) => theme?.palette?.gray?.lighter,
            height: (theme) => theme.spacing('100%'),
        },
        form: {
            '& > *': {
                marginBottom: (theme) => theme.spacing(2.5),
            },
        },
        box: { mb: (theme) => theme.spacing(0.375) },
        typography: { marginBottom: (theme) => theme.spacing(0.25) },
    },
    provisioning_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    provisioningBox: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        width: (theme) => theme.spacing('100%'),
        height: (theme) => theme.spacing('100%'),
    },
    sspform_wrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
            mt: (theme) => theme.spacing(3.125),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: 26 },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
        warningIcon: { fill: '#D78B95', height: (theme) => theme.spacing(3), width: (theme) => theme.spacing(3), mt: (theme) => theme.spacing('-3px') },
        errorAlert: {
            backgroundColor: '#AE172B',
            color: '#FFFFFF',
            display: 'flex ',
            alignItems: 'center ',
            justifyContent: 'center ',
            fontSize: 14,
            fontFamily: 'Roboto',
            '& .MuiAlert-action': {
                display: 'none',
            },
        },
    },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    Dialog: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' },
        icon: { color: '#d02424' },
        backBtn: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        AgreeBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },
    dataDiskModel: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        diskTypeWrapper: { width: '30%' },
        fileSystemWrapper: { width: '30%', pl: (theme) => theme.spacing(1) },
        diskletterWrapper: { width: '30%', px: (theme) => theme.spacing(1) },
        diskSizeWrapper: { width: '30%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        delete: {
            fontSize: (theme) => theme.spacing(2.25),
            fill: '#FFFFFF',
            color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        warningTypography: { color: (theme) => theme.palette.red.light },
        activeSelectField: {
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                // background: '#00709B',
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
        },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            background: '#F4273B',
            mt: 0.5,
        },
    },
    application: {
        stack: { direction: 'row', alignItems: 'center', justifyContent: 'center' },
    },
    box: {
        bgcolor: (theme) => theme.palette.white,
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    quickConfig: {
        envTitle: { backgroundColor: '#0047BA', borderRadius: '5px 5px 0px 0px' },
        spanColor: { color: '#000000' },
        labelValue: { padding: (theme) => theme.spacing(0.5, 1) },
        selectBtn: {
            marginLeft: (theme) => theme.spacing('auto'),
            display: 'flex',
            alignItems: 'flex-end',
            pr: (theme) => theme.spacing(1.5),
            pb: (theme) => theme.spacing(1.5),
        },
        paper: {
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue[80],
        },
        wrapper: {
            background: '#FBFBFB 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: (theme) => theme.palette.white,
            borderRadius: '5px',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: '#E8E8E8',
        },
        content: {
            background: (theme) => theme.palette.white,
            margin: (theme) => theme.spacing(0.25),
            marginTop: (theme) => theme.spacing(0),
            color: (theme) => theme.palette.gray[33],
            borderBottomLeftRadius: (theme) => theme.spacing(1),
            borderBottomRightRadius: (theme) => theme.spacing(1),
        },
        selectedBtn: {
            textTransform: 'none',
            px: 1,
            '&.Mui-disabled': {
                backgroundColor: '#01408B',
                color: 'white',
            },
        },
        cirProgWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        labeledPaper: { display: 'flex', flexDirection: 'column' },
        emptycontainer: { backgroundColor: '#FFFFFF' },
        emptymsg: { fontFamily: 'Stolzl', fontSize: '20px', color: '#000000', fontWeigtht: 'medium' },
        emptySuggestion: { fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'normal' },
        summaryTitle: { width: '93%', flexShrink: 0, fontSize: '16px', fontWeight: 'normal' },
        expandIcon: { color: (theme) => theme.palette.white },
    },
    configure: {
        stack: { alignItems: 'center', justifyContent: 'flex-start' },
        boxWrapper: { width: '60%', bgcolor: (theme) => theme.palette.white },
        btnWrapper: { width: '60%', ml: (theme) => theme.spacing(0.25) },
        badgeWidth: { width: '100%' },
        configureBtn: {
            paddingTop: (theme) => theme.spacing(1),
            paddingBottom: (theme) => theme.spacing(1),
            height: (theme) => theme.spacing(4.641),
            fontSize: { md: (theme) => theme.spacing(1.25), lg: (theme) => theme.spacing(1.5) },
        },
    },
    infoIcon: { color: '#0047BA', fontSize: '19px', marginTop: '5px' },
    costCenter: { width: '100%' },
    timeoutSlider: {
        '& .MuiSlider-thumb': {
            borderRadius: 0,
            width: 15,
            height: 15,
        },
        '& .MuiSlider-track': {
            borderRadius: 0,
        },
    },
    timeoutBox: { border: '1px solid #E0E0E0', width: '50px', height: '50%' },
    serverRefresh: {
        textField: {
            input: {
                '&::placeholder': {
                    textAlign: { md: 'left', lg: 'left' },
                    fontSize: { md: (theme) => theme.spacing(1.625), lg: (theme) => theme.spacing(1.75) },
                    color: (theme) => theme.palette.gray[44],
                    opacity: (theme) => theme.spacing(1),
                },
            },
            '& .MuiOutlinedInput-root': {
                display: { md: 'flex' },
                flexDirection: { md: 'row', lg: 'row' },
            },
        },
        radioGroup: {
            display: 'flex',
            justifyContent: 'right',
            width: 'inherit',
            fontSize: { md: (theme) => theme.spacing(1.25) },
            mr: { md: (theme) => theme.spacing(-2.25) },
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNo: {
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    disposal: {
        infoIcon: { color: (theme) => theme.palette.gray.main },
        helpText: { color: '#FF3939' },
    },
    modalBox: {
        wrapper: {
            minWidth: (theme) => theme.spacing(65),
            width: (theme) => theme.spacing(75),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
        },
        paper: {
            padding: '10px',
            marginBottom: '10px',
        },
        contentWrapper: { overflow: 'auto', flexGrow: 1, px: (theme) => theme.spacing(2) },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        addDisk: {
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        saveBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
        },
        cancelBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    envContainer: {
        badge: { marginRight: (theme) => theme.spacing(2) },
        samBtn: {
            marginRight: (theme) => theme.spacing(2),
            // borderWidth: (theme) => theme.spacing(0.125),
            // borderStyle: 'solid',
            // borderColor: (theme) => theme.palette.cyan.main,
            // color: (theme) => theme.palette.cyan[70],
        },
        applicationBtn: {
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        tagBtn: { textTransform: 'none' },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { fontSize: '30px', fontWeight: 500, fontFamily: 'Stolzl', color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        checkOutBtn: {
            width: '180px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },
    tags: {
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        box: { display: 'flex', flexDirection: 'column' },
        titlebox: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(2) },
        name: { fontWeight: 'bold', width: '31%' },
        colon: { width: (theme) => theme.spacing(0.25), mx: 1.5 },
        val: { width: (theme) => theme.spacing(50), fontWeight: 'bold', flexGrow: 1 },
        dataWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(1) },
        dataNameWrapper: {
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',

            borderRadius: (theme) => theme.spacing(0.25),
            borderColor: 'gray.lowMedium',
            py: (theme) => theme.spacing(0.5),
            px: (theme) => theme.spacing(1.5),
        },
        dataColan: { width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        dataValueBoxWrapper: { flexGrow: 1 },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(0.25) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        activeSelectField: {
            background: 'transparent',
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0047BA',
            },
        },
    },
};

export default ProvisioningStyles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
