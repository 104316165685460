import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useRecoilState } from 'recoil';
import { showPreviewOrder } from 'pages/provisioning/virtual-service/store';
import { SubmitForValidation } from 'pages/provisioning/virtual-service/components/SubmitForValidation';
import PreviewStyles from 'pages/provisioning/virtual-service/components/preview/style';

const PreviewHeader = () => {
    const [, setShowPreview] = useRecoilState(showPreviewOrder);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box pr={3} display="flex" flexDirection="column">
                <Typography component="span" sx={PreviewStyles?.previewHeaderText1}>
                    Provision Details
                </Typography>
                <Typography component="span" sx={PreviewStyles?.previewHeaderText2}>
                    Preview Order
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button
                    onClick={() => {
                        setShowPreview(false);
                        document.getElementById('applayoutbox').scrollTop = 0;
                    }}
                    variant="outlined"
                    data-testid="preview-edit-btn"
                    sx={PreviewStyles?.previewBtn}
                >
                    Edit Order
                </Button>
                <SubmitForValidation />
            </Box>
        </Box>
    );
};

export default PreviewHeader;
