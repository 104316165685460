/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import Select, { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';
import DeleteIcon from '@mui/icons-material/Delete';
import { MdAdd } from 'react-icons/md';
import { addSecurityToolCodeRepo, removeSecurityToolCodeRepo, updateSecurityToolCodeRepo } from 'pages/project-onboarding/store/selector';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const SecurityToolCodeRepo = () => {
    const [order] = useRecoilState(OrderPayload);
    const [options] = React.useState(order?.secToolRepoList);
    const addRepo = useSetRecoilState(addSecurityToolCodeRepo);
    const removeRepo = useSetRecoilState(removeSecurityToolCodeRepo);
    const updateRepo = useSetRecoilState(updateSecurityToolCodeRepo);

    const checkDuplicateCodeRepo = (repository) => {
        const codeRepository = order?.securityToolCodeRepoList?.map((codeRepo) => codeRepo?.repo);
        const duplicateCodeRepository = codeRepository.filter((item, index) => codeRepository.indexOf(item) !== index);
        if (repository) {
            return duplicateCodeRepository.indexOf(repository) > -1;
        }
        return false;
    };

    const handleOnSelectChange = (selected, index) => {
        updateRepo({ selected, index });
    };

    const getOptions = () => {
        return options?.filter((repo) => {
            return !order?.securityToolCodeRepoList?.find((item) => item?.repo === repo?.value);
        });
    };
    return (
        <Box sx={ProjectOnboardingStyles.codeRepoStyle.contentWrapper}>
            {order?.securityToolCodeRepoList?.map((codeRepo, index) => (
                <Box
                    display="flex"
                    flexDirection="row"
                    key={index.toString()}
                    sx={ProjectOnboardingStyles.codeRepoStyle.rowWrapper}
                    className="space-x-2 mb-5 animate-opacity"
                >
                    <Box display="flex" gap={1} sx={ProjectOnboardingStyles.codeRepoStyle.codeRepoWrapper}>
                        <Box display="flex" sx={{ width: '100%' }}>
                            <CssTextField
                                size="small"
                                sx={{
                                    width: (theme) => theme.spacing('65%'),
                                    '& .MuiFormHelperText-root': {
                                        marginTop: (theme) => theme.spacing('-2'),
                                    },
                                    '& .MuiInputBase-root': {
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                    },
                                    '& .MuiFormLabel-root': {
                                        color: '#0047BA !important',
                                    },
                                }}
                                disabled
                                autoComplete="off"
                                label="Repository Name"
                                value="https://dev.azure.com/PepsiCoIT/"
                            />
                            <Select
                                dataTestId="codeRepo-dropdown"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        },
                                    },
                                }}
                                value={codeRepo?.repo || null}
                                options={getOptions()}
                                handleOnSelect={(value) => handleOnSelectChange(value, index)}
                                isRequired={checkDuplicateCodeRepo(codeRepo?.repo)}
                                customValidate={checkDuplicateCodeRepo(codeRepo?.repo) ? 'validate' : ''}
                            />
                        </Box>
                    </Box>
                    <Box sx={ProjectOnboardingStyles.codeRepoStyle.iconWrapper}>
                        <Box className="flex w-full ">
                            {index !== 0 && order?.securityToolCodeRepoList?.length > 1 && (
                                <Box sx={ProjectOnboardingStyles.codeRepoStyle.deleteWrapper}>
                                    <DeleteIcon
                                        sx={ProjectOnboardingStyles?.removeBtn}
                                        color="error"
                                        onClick={() => removeRepo(codeRepo)}
                                        className="animate-opacity"
                                    />
                                </Box>
                            )}
                            {index === order?.securityToolCodeRepoList.length - 1 && order?.securityToolCodeRepoList?.length < 5 ? (
                                <MdAdd
                                    style={ProjectOnboardingStyles.codeRepoStyle.add}
                                    onClick={() => addRepo({ count: 1 })}
                                    className="w-7 h-7 text-blue-base cursor-pointer"
                                />
                            ) : (
                                <span className="w-7 h-7" />
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default SecurityToolCodeRepo;
