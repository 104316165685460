import { atom } from 'recoil';

export const cartCount = atom({
    key: 'cartCount',
    default: 0,
});

export const cart = atom({
    key: 'cart',
    default: [],
});

export const deleteCartItemId = atom({
    key: 'deleteCartItemId',
    default: undefined,
});

export const deleteByApplication = atom({
    key: 'deleteByApplication',
    default: undefined,
});

export const deleteModalPopup = atom({
    key: 'deleteModalPopup',
    default: false,
});

export const orderSuccessPopup = atom({
    key: 'orderSuccessPopup',
    default: false,
});

export const orderId = atom({
    key: 'cart_orderId',
    default: undefined,
});

export const toggleCostModals = atom({
    key: 'toggleCostModals',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isCatalogDetails: { id: '', isDetailsOpen: false, isDetailsLoading: false },
        isOneTimeCost: false,
    },
});
export const costSummaryData = atom({
    key: 'costSummaryData',
    default: null,
});
export const catalogDetails = atom({
    key: 'catalogDetails',
    default: null,
});
