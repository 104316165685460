import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI003/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const AppOwner = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const appOwnerApi = useMutation(() =>
        axios.get(`${endPoints.riServices.COMMON.businessAppOwner?.replace('{businessApplicationName}', order?.businessApp)}`)
    );

    React.useEffect(() => {
        if (order?.businessAppService === 'Yes' && order?.businessApp && !order?.appOwner) {
            appOwnerApi.mutate();
        }
    }, [order?.businessApp, order?.businessAppService, order?.appOwner]);
    React.useEffect(() => {
        if (appOwnerApi?.data?.data) {
            setOrder({
                ...order,
                appOwner: appOwnerApi?.data?.data?.owner,
            });
        }
    }, [appOwnerApi?.isSuccess]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Application Owner" isRequired />}
                value={order?.appOwner || ''}
                disabled
            />
        </Box>
    );
};

export default AppOwner;
