import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import theme from 'assets/style/theme';
import { TableCell, TableContainer, TableRow, Typography } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const Item = styled(Paper)(() => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 1px #0000004B',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'flex-start',
    height: '50px',
}));

export const StyledCard = styled(Card)({
    // Add your card styles here if needed
    width: '286px',
    height: '123px',
    background: '#FFFFFF',
    // boxShadow: '0px 0px 2px #00000036',
    borderradius: '12px',
    margin: '20px 50px 20px 20px',
});
export const BoldTypography = styled(Typography)({
    fontWeight: '700',
    marginTop: '-30px',
    marginRight: '20px',
    fontSize: '60px',
});

export const StyledTableHeader = styled(TableCell)(() => ({
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
}));

export const StyledTableCell = styled(TableCell)(() => ({
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
}));
export const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

export const StyledDataTable = styled(TableContainer)(() => ({
    padding: '0px 0px 30px 0px',
    borderadius: '12px',
    width: '98% !important',
}));

export const style = {
    wrapper: {
        background: theme.palette.grey.lighter,
        color: theme.palette.white,
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    dataTable: {
        background: '#ffff',
        borderRadius: '12px',
        width: '98%',
        marginLeft: '30px',
        marginRight: '20px',
        boxShadow: '0px 0px 2px #00000036',
    },

    overViewIamge: {
        marginLeft: '20px',
        marginTop: '15px',
    },
    TypographyText: {
        fontSize: '20px',
    },
    allProjectDiv: {
        display: 'flex',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        height: '60px',
    },
    stickyNote: {
        margin: '20px',
    },
    projectText: {
        color: '#1378D4',
        fontSize: '17px',
        marginTop: '20px',
    },
    chartText: {
        color: '#1378D4',
        fontSize: '17px',
        marginTop: '30px',
        marginLeft: '10px',
    },
    boxStyle: { padding: () => theme.spacing(1.875) },
    innerboxStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '3em',
    },
    descTypo: {
        marginTop: '50px',
    },
    dividerStyle: { color: '#CCCCCC', borderBottom: '0px 1px 0px #00000036', marginY: () => theme.spacing(1.875) },
    graphContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        flexWrap: 'wrap',
    },
    legendView: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    labelStyle: {
        marginBottom: '10px',
        textAlign: 'center',
    },
    legendContainer: {
        display: 'flex',
        alignItems: 'center',
    },
};

export const ProgressIcon = {
    provisioning_wrapper: {
        // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: () => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: () => theme.palette.black.dark },
};
