import { styled } from '@mui/material/styles';
import { RadioGroup, Radio } from '@mui/material';

export const RI002Styles = {
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            background: '#F4273B',
            mt: 0.5,
        },
        delete: {
            fontSize: (theme) => theme.spacing(2.25),
            fill: '#FFFFFF',
            color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    warningTypography: { color: (theme) => theme.palette.red.light },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    adinfoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', width: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    validateBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    welcomeBanner: {
        boxStyle: { marginLeft: '25px', marginRight: '25px' },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    },
    fontStyle: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
    },
};

export default RI002Styles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
