import { atom } from 'recoil';

export const modal = atom({
    key: 'nsg-modal',
    default: {
        isSubmitRuleSuccess: false,
    },
});

export const jobId = atom({
    key: 'nsg-jobId',
    default: null,
});

export const nsgOrder = atom({
    key: 'nsg-order',
    default: {
        vnet: '',
        subnet: '',
        requestNumber: '',
        isTouched: false,
        isSubmitRuleSuccess: false,
    },
});

export const isVnetValid = atom({
    key: 'nsg-isVnetValid',
    default: false,
});

export const isSubNetValid = atom({
    key: 'nsg-isSubNetValid',
    default: false,
});

export const isChangeRequestNumberValid = atom({
    key: 'nsg-isChangeRequestNumberValid',
    default: false,
});
