/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';

const Sku = () => {
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const [isTouched, setIsTouched] = React.useState(false);
    // eslint-disable-next-line
    const skuApi = useMutation(() => axios.get(`${endPoints.landingZones.sku}`));

    const setSku = (sku = null) => {
        setLandingZoneForm({
            ...landingZoneForm,
            sku,
        });
    };
    const seldValueExistsInOptions = (item) => options.find((value) => item === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSku(selected?.label);
        } else {
            setSku();
        }
    };
    React.useEffect(() => {
        if (skuApi?.isSuccess) {
            setOptions(skuApi?.data?.map((app) => ({ label: app.value, value: app.value, data: app })));
        }
    }, [skuApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.sku) {
            const sku = options.find((value) => landingZoneForm?.sku === value?.label);
            setSku(sku?.label);
        }
        if (options?.length && options?.length === 1 && !landingZoneForm?.sku) {
            setLandingZoneForm({ ...landingZoneForm, sku: options[0]?.label });
        }
    }, [options]);

    React.useEffect(() => {
        setOptions([]);
        skuApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.sku && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.sku, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="alb-sku"
                value={landingZoneForm?.sku}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled
                isLoading={skuApi.isLoading}
                label="SKU *"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Sku);
