import React from 'react';
import { FormControl, FormControlLabel, Box, Stack, Typography, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import { OrderPayload, validOnboardingForm, isValidationLoading } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/project-onboarding/style';

const AvailableServiceAccountQuestion = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [, setIsLoading] = useRecoilState(isValidationLoading);

    const setAvailableServiceAccount = (isServiceAccountExists = 'no') => {
        setOrder({
            ...order,
            isServiceAccountExists,
            serviceAccount: null,
        });
        setValidForm({ ...validForm, isInvalidServiceAccount: false });
        setIsLoading(false);
    };

    const handleOnSelectChange = (selected) => {
        setAvailableServiceAccount(selected);
    };
    const getValidate = () => {
        if (!order?.isServiceAccountExists && order?.isTouched) return 'ritmCheckbox';
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={ProjectOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Box sx={{ ...ProjectOnboardingStyles.radioFields.radioText, color: '#0047BA', fontWeight: 700 }} id="autoinflateRadioGrp">
                        Do you have service account ?
                    </Box>
                    <InfoTooltip
                        maxWidth="390px"
                        title={
                            <>
                                <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                                    <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                    <b>Service Account:</b>
                                                </Typography>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                    A Service Account is used to run the SonarQube scan in Azure DevOps pipeline.
                                                </Typography>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                    Please select the appropriate option:
                                                </Typography>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                    <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Yes: </b>If Yes option is selected, user must provide a
                                                    service account which will be onboarded in SonarQube.
                                                </Typography>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                    <span style={{ fontSize: '8px' }}>&#9899;</span> <b>No: </b> If No option is selected, CMP will create an AD
                                                    service account as part of the same request and onboard in SonarQube.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Box>
                <CustomRadioGroup
                    data-testid="available-clarity-id"
                    aria-label="availableClarityId"
                    name="availableClarityIdRadioBtnGroup"
                    row
                    value={order?.isServiceAccountExists || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    className={getValidate()}
                >
                    <FormControlLabel value="yes" control={<CustomRadio className={getValidate()} />} label="Yes" />
                    <FormControlLabel value="no" control={<CustomRadio className={getValidate()} />} label="No" />
                </CustomRadioGroup>
            </FormControl>

            {/* <InfoTooltip title="Add the User who require Test Plan License to work on the Test Suite." /> */}
        </Stack>
    );
};

export default React.memo(AvailableServiceAccountQuestion);
