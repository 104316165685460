/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';

export const ApplicationUrl = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [helperText, setHelperText] = React.useState(false);

    const setApplicationUrl = (applicationUrl = null) => {
        setOrder({
            ...order,
            applicationUrl,
        });
    };

    React.useEffect(() => {
        if (
            order?.applicationUrl &&
            order?.applicationUrl !== null &&
            order?.applicationUrl !== '' &&
            order?.applicationUrl !== undefined &&
            !/^[a-zA-Z0-9-./:]{3,}\.[a-zA-Z0-9]{3}$/.test(order?.applicationUrl)
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isAppUrlValid: true });
        } else {
            setHelperText(false);
            setValidForm({ ...validForm, isAppUrlValid: false });
        }
    }, [order?.applicationUrl]);

    const validateInput = () => {
        if ((isTouched && order?.isConfigureTouched && !order?.applicationUrl) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.applicationUrl && order?.isConfigureTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationUrl, order?.isConfigureTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-url-text">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Application URL *"
                error={helperText}
                helperText={helperText && <span>Application URL is invalid.</span>}
                value={order?.applicationUrl || ''}
                onChange={(e) => setApplicationUrl(e.target.value)}
                className={validateInput()}
                data-testid="appUrlText"
            />
        </Box>
    );
};

export default ApplicationUrl;
