import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { requestDetails, isValidApplicationName } from 'pages/onboard-developers/store';
import { useRecoilState } from 'recoil';
import HelpImg from 'pages/onboard-developers/components/helpImg';
import styles from 'pages/onboard-developers/style';

const Application = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [details, setDetails] = useRecoilState(requestDetails);
    const [isValidAppName, setIsValidAppName] = useRecoilState(isValidApplicationName);
    const [showHelperText, setShowHelperText] = React.useState(false);
    const [helperMsg, setHelperMsg] = React.useState('');

    React.useEffect(() => {
        if (details?.applicationName && details?.applicationName !== null && details?.applicationName !== undefined) {
            if (details?.applicationName?.length < 3 || details?.applicationName > 256) {
                setIsValidAppName(true);
                setHelperMsg('Application Name must be between 3 and 255 characters in length.');
                setShowHelperText(true);
            } else {
                setIsValidAppName(false);
                setHelperMsg('');
                setShowHelperText(false);
            }
        } else {
            setIsValidAppName(false);
            setHelperMsg('');
            setShowHelperText(false);
        }
    }, [details?.applicationName]);
    const validateInput = () => {
        if ((isTouched && details?.isTouched && !details?.applicationName) || isValidAppName) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (details?.isTouched && !details?.applicationName) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [details?.isTouched, details?.applicationName]);

    return (
        <Stack direction="row" spacing={1} sx={styles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                onh
                autoComplete="off"
                label="Application Name*"
                onChange={(e) => {
                    setDetails({ ...details, applicationName: e.target.value });
                }}
                value={details?.applicationName || ''}
                className={validateInput()}
                error={isTouched || showHelperText}
                helperText={showHelperText && helperMsg && <span style={styles?.helpMessage}>{helperMsg}</span>}
            />
            <HelpImg title="Please Provide Application Name" />
        </Stack>
    );
};

export default Application;
