/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { isLdapVaild, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const Ldap = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [isValid, setIsLdapVaild] = useRecoilState(isLdapVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setLdap = (ldap) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                ldap,
            });
        }
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length < 3 || value?.length > 215) {
                setIsLdapVaild(false);
                setLdap(value);
            } else if (/^([a-z0-9-_])$/.test(value)) {
                setIsLdapVaild(false);
                setLdap(value);
            } else {
                setIsLdapVaild(true);
                setLdap(value);
            }
        } else {
            setIsLdapVaild(false);
            setLdap('');
        }
    };

    React.useEffect(() => {
        if (lzModal?.lzDetails?.config?.ldap) setLdap(lzModal?.lzDetails?.config?.ldap);
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landZoneForm?.ldap?.length > 0);
    }, [landZoneForm?.ldap]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="LDAP Group"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landZoneForm?.ldap}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'ldap group must be atleast 3 chars in length and can contain only hyphen(-) & underscore(_) as special char ' : ''}
                `}
                />
            </Stack>
        </>
    );
};

export default React.memo(Ldap);
