const transformData = (data) => {
    return data.map((group) => {
        return {
            ...group,
            components: group.components.map((component) => {
                return {
                    ...component,
                    groupType: group.groupType,
                    groupHeader: group.groupHeader,
                };
            }),
        };
    });
};
export default transformData;
