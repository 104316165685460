import React from 'react';
import { Stack } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { OrderPayload } from 'pages/service-account-creation/store';
import ProvisioningStyles from 'pages/service-account-creation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const filter = createFilterOptions();
const ApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(order?.appProfileDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    const appGetApi = useMutation(() => axios.get(`${endPoints.serviceAccountCreation.applicationService}?searchKey=${ccInputValue}`));

    const setAppService = (ciService) => {
        setOrder({
            ...order,
            applicationServiceCI: ciService?.label,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appGetApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (appGetApi?.isSuccess) {
            if (appGetApi?.data?.data?.appProfiles) {
                const combinedValue = appGetApi?.data?.data?.appProfiles?.map((app) => ({
                    label: `${app.businessCi}`,
                    value: `${app.businessCi}`,
                    data: app,
                }));
                setOptions(combinedValue);
            }
        }
    }, [appGetApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.cloudProvider && order?.cloudService) {
            appGetApi.mutate();
        }
    }, [order?.cloudProvider, order?.cloudService]);

    React.useEffect(() => {
        if (value !== null && value?.value) {
            setAppService(value);
        }
    }, [value]);

    React.useEffect(() => {
        return !order?.applicationServiceCI && order?.isTouched ? setIsTouched(true) : setIsTouched(false);
    }, [order?.isTouched, order?.applicationServiceCI]);

    return (
        <>
            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                <Select
                    key="applicationName"
                    label="Application Service CI *"
                    loading={appGetApi?.isLoading}
                    placeholder="Type to search"
                    value={order?.applicationServiceCI || null}
                    includeInputInList
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={ProvisioningStyles.costCenter}
                    freeSolo
                    isRequired={isTouched}
                />
                <HelpImg title="Type to Search Application." />
            </Stack>
        </>
    );
};

export default React.memo(ApplicationService);
