import React from 'react';
import { Box, Badge, Stack, Grid, Typography, Divider } from '@mui/material';
import Btn from 'components/button';
import Select, { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { sspSubmitOrder, toggleSspModels, isInvalidEventhubName, isEventhubNameExist } from 'pages/provisioning/store/event-hub';
import { addEventHubDetails, removeEventHubDetails, updateEventHubDetails } from 'pages/provisioning/store/event-hub/selecters';
import ConsumerGroupModal from 'pages/provisioning/event-hub/ConsumerGroupModal';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import range from 'lodash/range';

const EventHubDetails = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const [, setInvalidEventhubName] = useRecoilState(isInvalidEventhubName);
    const [, setEventhubNameExist] = useRecoilState(isEventhubNameExist);
    const [indexVal, setIndexVal] = React.useState(0);
    const [existingEventHubNames, setExistingEventHubNames] = React.useState([]);
    const [retentionOptions, setRetentionOptions] = React.useState([]);
    const addEventHubData = useSetRecoilState(addEventHubDetails);
    const removeEventHubData = useSetRecoilState(removeEventHubDetails);
    const updateEventHubData = useSetRecoilState(updateEventHubDetails);

    const eventHubDetailApi = useMutation(() =>
        axios.get(
            `${endPoints?.ssp?.eventHub?.eventHubLookup}/subscription/${order?.envDetails?.config?.subscription?.id}/resourceGroup/${order?.envDetails?.config?.resourceGroup}/namespace/${order?.namespaceName}`
        )
    );

    const changeSelection = (key, selected, index) => {
        updateEventHubData({ key, selected, index });
    };

    React.useEffect(() => {
        setRetentionOptions(
            order?.performance === 'Standard'
                ? range(1, 8).map((i) => ({
                      label: `${i}`,
                      value: `${i}`,
                  }))
                : range(1, 16).map((i) => ({
                      label: `${i}`,
                      value: `${i}`,
                  }))
        );
    }, [order?.performance]);

    React.useEffect(() => {
        if (eventHubDetailApi?.isSuccess) {
            const eventHubNames = eventHubDetailApi?.data?.data?.eventHubs?.map((item) => item.name);
            setExistingEventHubNames(eventHubNames);
        }
    }, [eventHubDetailApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.nsExist === 'yes' && order?.namespaceName && order?.envDetails?.config) {
            eventHubDetailApi.mutate();
        }
    }, [order?.nsExist, order?.namespaceName, order?.envDetails?.config]);

    const checkEventHubNameDuplicates = (value) => {
        const eventHubValues = order?.eventHubDetails?.map((data) => data?.eventHubName);
        const duplicateEventHubName = eventHubValues?.filter((item, index) => eventHubValues?.indexOf(item) !== index);
        if (value) {
            return duplicateEventHubName?.indexOf(value) > -1;
        }
        return false;
    };

    const partitionCountValidation = (partitionCount) => {
        if (order?.performance && partitionCount && (partitionCount !== '' || partitionCount !== null)) {
            if (order?.performance === 'Standard' && (!/^(?!(0))[0-9]+$/.test(partitionCount) || parseInt(partitionCount, 10) > 32)) return true;
            if (order?.performance === 'Premium' && (!/^(?!(0))[0-9]+$/.test(partitionCount) || parseInt(partitionCount, 10) > 100)) return true;
        }
        return false;
    };

    const eventHubNameValidation = (eventhubName) => {
        setInvalidEventhubName(false);
        if (eventhubName && (eventhubName !== '' || eventhubName !== null) && !/^(?![-._])[a-zA-Z0-9-_.]{3,256}(?<![-._])$/.test(eventhubName)) {
            setInvalidEventhubName(true);
            return true;
        }
        return false;
    };
    const eventHubNameExistsValidation = (eventhubName) => {
        setEventhubNameExist(false);
        if (eventhubName && (eventhubName !== '' || eventhubName !== null) && existingEventHubNames.includes(eventhubName)) {
            setEventhubNameExist(true);
            return true;
        }
        return false;
    };

    return (
        <>
            <Grid container spacing={2}>
                {order?.eventHubDetails?.map((eventHubData, index) => (
                    <>
                        <React.Fragment key={index.toString()}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                        <CssTextField
                                            size="small"
                                            sx={{
                                                width: (theme) => theme.spacing('100%'),
                                                '& .MuiFormHelperText-root': {
                                                    marginTop: (theme) => theme.spacing('-2'),
                                                },
                                            }}
                                            autoComplete="off"
                                            label="Name *"
                                            value={eventHubData?.eventHubName || ''}
                                            onChange={(e) => {
                                                changeSelection('eventHubName', e.target.value, eventHubData);
                                            }}
                                            data-testid="event-hub-name"
                                            className={
                                                eventHubNameValidation(eventHubData?.eventHubName) ||
                                                checkEventHubNameDuplicates(eventHubData?.eventHubName) ||
                                                eventHubNameExistsValidation(eventHubData?.eventHubName)
                                                    ? 'validate'
                                                    : '' || (eventHubData?.isEventHubTouched && !eventHubData?.eventHubName ? 'validate' : '')
                                            }
                                        />
                                        <HelpImg
                                            maxmWidth="450px"
                                            title={
                                                <>
                                                    <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                                        <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                                                    <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                                        <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                                        1) Alpha-numeric value
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                                        2) Min.: 3 chars to Max.: 256 chars
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                                        3) Can contain hyphen(-), underscore(_) and period(.) as special chars
                                                                    </Typography>
                                                                    <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                                        4) Should start and end with letter or number
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Stack>

                                    {eventHubData?.eventHubName && eventHubNameValidation(eventHubData?.eventHubName) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                            Event Hub name should be min 3 and max 256 chars, can contain letters, numbers, hyphen, underscore & period and
                                            should start and end with letter or number.
                                        </Typography>
                                    )}
                                    {eventHubData?.eventHubName && eventHubNameExistsValidation(eventHubData?.eventHubName) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                            Event Hub name already exists.
                                        </Typography>
                                    )}
                                    {eventHubData?.eventHubName && checkEventHubNameDuplicates(eventHubData?.eventHubName) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                            Eventhub name must not have duplicate values.
                                        </Typography>
                                    )}
                                    <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                        <Select
                                            key="retention"
                                            label="Retention(in Days) *"
                                            value={eventHubData?.retention || null}
                                            handleOnSelect={(value) => changeSelection('retention', value, eventHubData)}
                                            options={retentionOptions}
                                            isRequired={eventHubData?.isEventHubTouched && !eventHubData?.retention}
                                            data-testid="retention"
                                        />
                                        <HelpImg
                                            maxmWidth="450px"
                                            title={
                                                <>
                                                    <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                                        <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    Retention period is displayed in days.
                                                                    <br />
                                                                    For Standard performance Retention period should be from 1 to 7 days.
                                                                    <br />
                                                                    For Premium performance Retention period should be from 1 to 15 days.
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                                        <>
                                            <CssTextField
                                                size="small"
                                                sx={{
                                                    width: (theme) => theme.spacing('100%'),
                                                    '& .MuiFormHelperText-root': {
                                                        marginTop: (theme) => theme.spacing('-2'),
                                                    },
                                                }}
                                                autoComplete="off"
                                                label="Partition Count *"
                                                value={eventHubData?.partitionCount}
                                                onChange={(e) => {
                                                    changeSelection('partitionCount', e.target.value, eventHubData);
                                                }}
                                                data-testid="partionCount"
                                                className={
                                                    partitionCountValidation(eventHubData?.partitionCount)
                                                        ? 'validate'
                                                        : '' || (eventHubData?.isEventHubTouched && !eventHubData?.partitionCount ? 'validate' : '')
                                                }
                                            />
                                        </>
                                        {order?.performance === 'Standard' ? (
                                            <HelpImg
                                                maxmWidth="450px"
                                                title={
                                                    <>
                                                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        Partition count should be max 32.
                                                                        <br />
                                                                        <a
                                                                            href="https://learn.microsoft.com/en-us/azure/event-hubs/event-hubs-scalability#partitions"
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Learn more
                                                                        </a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                }
                                            />
                                        ) : (
                                            <HelpImg
                                                maxmWidth="450px"
                                                title={
                                                    <>
                                                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        Partition count should be max 100
                                                                        <br />
                                                                        But there&apos;s a limit of 200 per PU at the namespace level. Default Vaule 1 each.
                                                                        <br />
                                                                        <a
                                                                            href="https://learn.microsoft.com/en-us/azure/event-hubs/event-hubs-scalability#partitions"
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            Learn more
                                                                        </a>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                }
                                            />
                                        )}
                                    </Stack>
                                    {order?.performance === 'Standard' &&
                                        eventHubData?.partitionCount &&
                                        partitionCountValidation(eventHubData?.partitionCount) && (
                                            <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                                Partition count should be between 1 to 32
                                            </Typography>
                                        )}
                                    {order?.performance === 'Premium' &&
                                        eventHubData?.partitionCount &&
                                        partitionCountValidation(eventHubData?.partitionCount) && (
                                            <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                                Partition count should be between 1 to 100
                                            </Typography>
                                        )}
                                    <Stack direction="row" spacing={1} sx={ProvisioningStyles.modalBtnStyle.stack}>
                                        <Grid container justifyContent="flex-start">
                                            <Box sx={ProvisioningStyles.modalBtnStyle.btnWrapper}>
                                                <Badge
                                                    sx={ProvisioningStyles.modalBtnStyle.badgeStyle}
                                                    color="error"
                                                    variant={order?.eventHubDetails[index]?.consumerGroupList?.[0]?.value.length === 0 ? 'dot' : 'standard'}
                                                >
                                                    <Btn
                                                        className="btn-disabled custom-btn"
                                                        fullWidth
                                                        onClick={() => {
                                                            setIsOpen({ ...isOpen, isConsumerGroupOpen: true });
                                                            setIndexVal(index);
                                                        }}
                                                        size="small"
                                                        sx={ProvisioningStyles.modalBtnStyle.modalBtn}
                                                        variant="contained"
                                                        color="cmpPrimary"
                                                        data-testid="add-consumergrp"
                                                    >
                                                        Consumer Groups
                                                    </Btn>
                                                </Badge>
                                            </Box>
                                        </Grid>
                                        <Grid container justifyContent="flex-end">
                                            {index !== 0 && order?.eventHubDetails?.length > 1 && (
                                                <Box sx={ProvisioningStyles.consumerGroupModal.deleteWrapper} mr={1}>
                                                    <FaTrashAlt
                                                        style={ProvisioningStyles.consumerGroupModal.delete}
                                                        onClick={() => removeEventHubData(eventHubData)}
                                                        className="w-7 h-7 text-red-base cursor-pointer"
                                                    />
                                                </Box>
                                            )}
                                            {index === order?.eventHubDetails?.length - 1 && order?.eventHubDetails?.length < 5 ? (
                                                <Box sx={ProvisioningStyles.consumerGroupModal.addWrapper}>
                                                    <MdAdd
                                                        style={ProvisioningStyles.consumerGroupModal.add}
                                                        onClick={() => addEventHubData({ count: 1 })}
                                                        className="w-7 h-7 text-blue-base cursor-pointer"
                                                    />
                                                </Box>
                                            ) : (
                                                <span className="w-7 h-7" />
                                            )}
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </React.Fragment>
                        <React.Fragment key={index.toString()}>
                            {order?.eventHubDetails?.length > 1 && index !== order?.eventHubDetails?.length - 1 && (
                                <Grid item xs={12}>
                                    <Divider style={{ borderColor: 'rgba(0, 0, 0, 0.30)' }} />
                                </Grid>
                            )}
                        </React.Fragment>
                    </>
                ))}
                <ConsumerGroupModal indexVal={indexVal} />
            </Grid>
        </>
    );
};

export default EventHubDetails;
