import { getValueFromKey } from 'utils';
import mappingLabel from 'utils/mappingLabel';

export const snakeToTitle = (camelCase = '') => {
    const snakeCase = camelCase
        ?.toLowerCase()
        ?.split('-')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
    return snakeCase;
};
function camelToKebab(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
const transformResourceProperties = (item) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
        if (typeof value === 'string' && key !== 'storage-type') {
            acc[snakeToTitle(camelToKebab(key))] = value;
        } else if (Array.isArray(value) && key === 'topics') {
            acc[snakeToTitle(camelToKebab(key))] = value.map((topic) => ({
                topicName: topic?.['topic-name'],
                consumerGroup: topic?.['consumer-group'] || '-',
            }));
        }
        return acc;
    }, {});
};
const transformRoleAssignments = (roleAssignments, isOrderpage = false) => {
    if (!Array.isArray(roleAssignments)) return null;
    if (roleAssignments.every(({ 'access-levels': accessLevels }) => accessLevels === null)) {
        return null;
    }
    if (isOrderpage) {
        return roleAssignments.reduce((acc, { 'assignee-name': assigneeName, 'access-levels': accessLevels }) => {
            accessLevels?.forEach((accessLevel) => {
                if (!acc[accessLevel]) acc[accessLevel] = [];
                acc[accessLevel].push(assigneeName);
            });
            return acc;
        }, {});
    }
    return roleAssignments.reduce((acc, { assignee, 'access-levels': accessLevels }) => {
        accessLevels?.forEach((accessLevel) => {
            if (!acc[accessLevel]) acc[accessLevel] = [];
            acc[accessLevel].push(assignee);
        });
        return acc;
    }, {});
};
export const transformAccessLevels = (roleAssignments) => {
    if (!Array.isArray(roleAssignments)) return null;
    return roleAssignments.map((item) => {
        const { 'access-levels': accessLevels, ...rest } = item;
        return {
            ...rest,
            'access-levels': accessLevels?.length ? accessLevels : ['Group Assignment'],
        };
    });
};
const getFormattedObject = ({ resourceType, resourceProperties, roleAssignments = null, accessPolicy = null }) => {
    return {
        'Resource Type': resourceType,
        'Resource Properties': resourceProperties,
        'Role Assignments': roleAssignments,
        'Access Policy': accessPolicy,
    };
};
const getlabelType = (item, resource) => {
    if (item[getValueFromKey(resource, mappingLabel)]) {
        return item[getValueFromKey(resource, mappingLabel)] !== 'DataFoundation' ? `(${item[getValueFromKey(resource, mappingLabel)]})` : '(Data Foundation)';
    }
    return '';
};
const getResourceType = (resource, componentId, item) => {
    // return snakeToTitle(resource)?.toLowerCase() === snakeToTitle(componentId?.replace(/_/g, ' '))?.toLowerCase() || !componentId
    //     ? `${snakeToTitle(resource)}`
    //     : `${snakeToTitle(resource)} (${componentId})`;

    let resourceType = '';

    if (
        resource !== 'data-factory' &&
        resource !== 'data-lake-storage' &&
        resource !== 'data-bricks-cluster' &&
        (snakeToTitle(resource)?.toLowerCase() === snakeToTitle(componentId?.replace(/_/g, ' '))?.toLowerCase() || !componentId)
    ) {
        resourceType = `${snakeToTitle(resource)}`;
    } else if (['data-factory', 'data-lake-storage', 'data-bricks-cluster']?.includes(resource)) {
        resourceType = `${snakeToTitle(resource)} ${getlabelType(item, resource)}`;
    } else {
        resourceType = `${snakeToTitle(resource)}`;
    }
    return resourceType;
};
const convertToObjectArray = (input) => {
    if (input && typeof input === 'object' && !Array.isArray(input)) {
        return [input];
    }
    return input;
};
export const getResources = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    const result = [];
    Object.keys(resources)?.map((resource) => {
        if (!resources[resource]) return '';
        if (resource === 'blob-storage') {
            const storageAccountName = resources[resource]?.map((item) => item?.['storage-account-name']).join(', ');
            const resourceType = `${snakeToTitle(resource)}`;
            const resourceProperties = { 'Storage Account Name': storageAccountName };
            result.push(getFormattedObject({ resourceType, resourceProperties }));
            return '';
        }
        const convertedResource = convertToObjectArray(resources[resource]);
        if (typeof convertedResource === 'string') return '';
        convertedResource?.map((item) => {
            const { 'component-id': componentId, ...rest } = item;
            const resourceType = getResourceType(resource, componentId, item);
            const resourceProperties = transformResourceProperties(rest);
            const newRoleAssignments = transformAccessLevels(rest['role-assignments']);
            const roleAssignments = transformRoleAssignments(newRoleAssignments, order?.isOrderPage);
            const accessPolicy = transformRoleAssignments(rest['access-Policy'] || rest['access-policy'], order?.isOrderPage);
            result.push(getFormattedObject({ resourceType, resourceProperties, roleAssignments, accessPolicy }));
            return '';
        });
        return '';
    });
    return result;
};
export const getpreviewDetails = ({ order }) => {
    const previewDetails = { resources: getResources({ order }) };
    Object.keys(order?.environmentDetails).map((key) => {
        if (!['estimated-cost', 'resources', 'tech-input-json', 'catalogServiceCode', 'catalog-service-code']?.includes(key)) {
            if (['subscription']?.includes(key) && typeof order?.environmentDetails?.[key] === 'object') {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key]?.subscription;
            } else {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
            }
        }
        return '';
    });
    return previewDetails;
};
export const transformSubscriptions = ({ resources }) => {
    return resources.reduce((acc, sub) => {
        const key = `${sub?.['architecture-component-name']} Subscription`;
        if (typeof sub?.subscription === 'object' && sub?.subscription?.subscription) {
            acc[key] = sub.subscription.subscription;
        } else {
            acc[key] = sub.subscription;
        }
        return acc;
    }, {});
};
export const getPreviewDetailsMultiRI = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    const previewDetails = {
        resources: resources?.map((resource) =>
            getpreviewDetails({
                order: { environmentDetails: { resources: resource, 'architecture-component-name': resource?.['architecture-component-name'] } },
            })
        ),
    };
    Object.keys(order?.environmentDetails).map((key) => {
        if (!['estimated-cost', 'resources', 'tech-input-json']?.includes(key)) {
            if (['subscription']?.includes(key) && typeof order?.environmentDetails?.[key] === 'object') {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key]?.subscription;
            } else {
                previewDetails[snakeToTitle(key)] = order?.environmentDetails?.[key];
            }
        }
        return '';
    });
    const multiSubscription = transformSubscriptions({ resources });
    return { ...previewDetails, ...multiSubscription };
};
export const getPreviewDetailsMultiRIOrderSummary = ({ order }) => {
    const resources = order?.environmentDetails?.resources;
    return {
        resources: resources?.map((resource) =>
            getpreviewDetails({
                order: {
                    ...(order?.isOrderPage && {
                        isOrderPage: order?.isOrderPage,
                    }),
                    environmentDetails: {
                        resources: resource,
                        'architecture-component-name': resource?.['architecture-component-name'],
                    },
                },
            })
        ),
    };
};
