import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { requestDetails } from 'pages/onboard-developers/store';
import HelpImg from 'pages/onboard-developers/components/helpImg';

export const Sector = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [details, setDetails] = useRecoilState(requestDetails);
    const [options, setOptions] = React.useState([]);

    const sectorApi = useMutation(() => axios.get(endPoints.ssp.vm.sector));

    const setSector = (sector) => {
        setDetails({
            ...details,
            sector,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sector) => options.find((option) => sector === option.value);

    const handleOnSelectChange = (selected) => setSector(selected.value);

    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            setOptions(sectorApi?.data?.data?.sectors?.map((app) => ({ label: app?.name, value: app?.name })));
        }
    }, [sectorApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !details?.sector) {
            setDetails({ ...details, sector: options?.[0].value });
        }
        if (options && options?.length && details?.sector) {
            const sector = isExistsInOptions(details?.sector);
            setDetails({ ...details, sector: sector?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        sectorApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!details?.sector && details?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [details?.sector, details?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="sector"
                label="Sector *"
                value={details?.sector || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading}
                isLoading={sectorApi.isLoading}
                isRequired={isTouched}
            />
            <HelpImg title="Please select valid sector." />
        </Box>
    );
};

export default React.memo(Sector);
