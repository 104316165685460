export const ProjectOnboarding = {
    clarityId: 'api/static/lookups/v2/1.0/clarity-project/{clarityId}',
    applicationCI: 'api/app-registrations/3.0/all-app-profiles',
    sectors: 'api/static/lookups/2.0/sectors',
    adoPlatform: 'api/static/lookups/v2/1.0/ado-platform-type',
    adoProjectType: 'api/static/lookups/v2/1.0/ado-project-type',
    applicationOwner: 'api/employees/3.0/employee/list',
    businessApp: 'api/cmdb/lookups/1.0/business-application',
    applicationService: 'api/cmdb/lookups/1.0/business-application/services',
    appOwner: 'api/order/snow/1.0/{businessApp}/it-owner',
    member: 'api/employees/2.0/employee/list',
    appShortName: 'api/ri/resource/name/1.0/app-short-name/available',
    learningApi: 'api/day2/1.0/internal/order/project-onboarding/my-learning/courses',
    costApi: 'api/costs/1.0/cost-calculation',
    programmingLanguageApi: '/project-onboarding-programminglangs.json',
    buildToolsApi: '/project-onboarding-buildTools.json',
    packageTypesApi: '/project-onboarding-packageTypes.json',
    // repoName: 'project-onboarding-repoNames.json',
    // clarityId: 'project-onboarding-clarity-details.json',
    // adoPlatform: 'project-onboarding-adoPlatform.json',
    // adoProjectType: 'project-onboarding-project-type.json',
    // costApi: '/project-onboarding-cost-calculation.json',
    // codeRepo: 'project-onboarding-codeRepo.json',
    Jwt: {
        teamName: 'api/day2/1.0/internal/order/security-tool/team-name/sector',
        submitOrder: 'api/day2/1.0/internal/order/project/submit',
        editOrder: 'api/day2/1.0/internal/order/project',
        orderDetails: 'api/order/v2/1.0/cmp/order',
        validateMember: 'api/day2/1.0/internal/order/project-onboarding/validate/my-learning/',
        adoProjectName: 'api/project/integration/order/search/project',
        adoProjectDetails: 'api/project/integration/order/project/validate',
        sonarQubeRepo: 'api/day2/1.0/internal/order/project-onboarding/sonarqube/repo',
        securityToolRepo: 'api/day2/1.0/internal/order/project-onboarding/fortify-synk/repo',
        serviceAccountsList: 'api/day2/order/sonar/service-accounts',
        validateServiceAccount: 'api/day2/order/service-accounts/validate',
        // adoProjectName: '/project-onboarding-adoprojectname.json',
        // adoProjectDetails: 'project-onboarding-project-details.json',
        //  sonarQubeRepo: 'project-onboarding-sonarqube.json',
        // securityToolRepo: 'project-onboarding-sonarqube.json',
    },
};
export default ProjectOnboarding;
