export const Styles = {
    CostDetails: {
        card: {
            background: '#FFFFFF',
            px: '16px',
            pt: '16px',
            pb: '0px',
            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
        },
        headingWrapper: { display: 'flex' },
        heading: { marginBottom: '8px', fontSize: '20px', fontWeight: 'medium', fontFamily: 'Stolzl' },
        loaderIcon: { color: (theme) => theme?.palette?.black?.dark },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex', alignItems: 'center' },
        item: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        unit: { fontSize: '14px', fontWeight: '300', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        estCost: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        labelStyle: { fontSize: '18px', fontWeight: 'bold', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        note: { fontSize: '14px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        recurringNote: {
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            color: (theme) => theme?.palette?.black?.dark,
            marginTop: (theme) => theme.spacing(1),
        },
        recurringHtmlNote: {
            marginLeft: '15px',
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: 'Roboto',
            color: (theme) => theme?.palette?.black?.dark,
            marginTop: (theme) => theme.spacing(1),
            textAlign: 'auto',
            wordBreak: 'break-all',
        },
        noteList: {
            listStylePosition: 'outside',
            paddingInlineStart: '10px',
            marginBlockStart: '0px',
        },

        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        infoWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '200px',
            maxWidth: '500px',
        },
        infoBox: { overflow: 'auto' },
        gridcontainer: { padding: '10px 0px 10px 10px' },
        griditem: {
            mb: (theme) => theme.spacing('3px'),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // flexGrow:1
        },
        labelItem: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
        labelValue: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
    },
    costInfo: {
        titleWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            width: '240px',
            maxWidth: '600px',
        },
        gridcontainer: {
            mb: (theme) => theme.spacing('3px'),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // flexGrow:1
            // width: 'auto',
        },
        labelItem: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
        labelValue: {
            display: 'block',
            vaiant: (theme) => theme.typography.caption.fontSize,
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 'normal',
        },
    },
};

export default Styles;
