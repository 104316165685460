import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/alert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box } from '@mui/material';

const DestroyAlert = ({ message, title }) => {
    return (
        <Box component="div" style={{ marginBottom: '10px' }}>
            <Alert
                title={title}
                message={message}
                Icon={
                    <NotificationsIcon
                        sx={{
                            color: '#F99C24',
                            backgroundColor: '#FEEBD3',
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            padding: '6px',
                            marginRight: '5px',
                            marginTop: '-4px',
                            fontSize: '10px',
                        }}
                    />
                }
                borderLeftColor="#F99C24"
                backgroundColor="#FEEBD3"
                color="#000000"
            />
        </Box>
    );
};

DestroyAlert.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
};

DestroyAlert.defaultProps = {
    message: 'In terraform plan output few resources are marked for destroy(deletion), please review and submit for approvals.',
    title: 'Warning',
};

export default DestroyAlert;
