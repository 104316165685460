import React from 'react';
import Form from 'pages/app-profiles/landing-zones-new/components/Form';
import { Box, Grid, Typography, Paper, Divider } from '@mui/material';
import { landingZoneData } from 'pages/app-profiles/store';
import { useRecoilState } from 'recoil';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import LandingZoneStyle from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';

const StorageAccountFields = () => {
    const [landingZoneDetails, setLandingZoneDetails] = useRecoilState(landingZoneData);

    const handleAdd = () => {
        const form = { ...landingZoneDetails };
        try {
            form.vnetSubnet = [
                ...form.vnetSubnet,
                {
                    networkSubscription: null,
                    networkSubscriptionDetails: null,
                    vNet: null,
                    vNetDetails: null,
                    subnet: null,
                    subnetDetails: null,
                },
            ];
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneDetails(form);
    };

    const handleDelete = (vnetSubnetToRemove) => {
        const form = { ...landingZoneDetails };
        const currentVnetSubnet = form?.vnetSubnet;
        const vnetSubnetRemoveIndex = currentVnetSubnet.findIndex((val) => val === vnetSubnetToRemove);
        const vnetSubnet = [...currentVnetSubnet.slice(0, vnetSubnetRemoveIndex), ...currentVnetSubnet.slice(vnetSubnetRemoveIndex + 1)];
        setLandingZoneDetails({ ...landingZoneDetails, vnetSubnet });
    };

    const isDuplicateVnetSubnetValue = (value) => {
        const vnetSubnetValues = landingZoneDetails?.vnetSubnet?.map((data) => data?.vNet + data?.subnet + data?.networkSubscription);
        const duplicateVnetSubnet = vnetSubnetValues.filter((item, index) => vnetSubnetValues.indexOf(item) !== index);
        if (value) {
            return duplicateVnetSubnet.indexOf(value) > -1;
        }
        return false;
    };

    return (
        <>
            <Grid item xs={6}>
                {' '}
                <Form.Environments />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.Region />
            </Grid>
            <Grid item xs={6}>
                <Form.Subscription />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.ResourceGroup />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.DataClassification />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.LandingZoneLabel />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Form.LandingZoneDesc />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Paper sx={LandingZoneStyle?.vnetSubNetPaper}>
                    <Box display="flex" m={2}>
                        <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                            Service Endpoint
                        </Typography>
                    </Box>
                    <Divider />
                    <Box display="flex" m={2}>
                        <Typography sx={LandingZoneStyle?.selectedNetworks}>Selected Networks</Typography>
                    </Box>

                    <Grid container spacing={1.5} ml={1}>
                        <>
                            {landingZoneDetails?.vnetSubnet?.map((item, index) => (
                                <React.Fragment key={`selected-nw-${index}`}>
                                    <Grid item xs={10}>
                                        <Form.NetworkSubscription index={index} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Form.ServiceEndPointVNet index={index} />
                                        {isDuplicateVnetSubnetValue(item?.vNet + item?.subnet + item.networkSubscription) && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" gutterBottom sx={LandingZoneStyle.vnetSubnetStyle.warningTypography}>
                                                    Duplicate Vnet and Subnet values are not allowed.
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Form.ServiceEndPointSubNet index={index} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {index !== 0 && landingZoneDetails?.vnetSubnet?.length > 1 && (
                                            <Box sx={LandingZoneStyle.vnetSubnetStyle.deleteWrapper} mr={1} ml={1}>
                                                <FaTrashAlt
                                                    style={LandingZoneStyle.vnetSubnetStyle.delete}
                                                    onClick={() => handleDelete(item)}
                                                    className="w-7 h-7 text-red-base cursor-pointer"
                                                    data-testid={`DelBtn-${index}`}
                                                />
                                            </Box>
                                        )}
                                        {index === landingZoneDetails?.vnetSubnet?.length - 1 && landingZoneDetails?.vnetSubnet?.length < 5 ? (
                                            <Box sx={LandingZoneStyle.vnetSubnetStyle.addWrapper} ml={1}>
                                                <MdAdd
                                                    style={LandingZoneStyle.vnetSubnetStyle.add}
                                                    onClick={() => handleAdd()}
                                                    className="w-7 h-7 text-blue-base cursor-pointer"
                                                    data-testid={`AddBtn-${index}`}
                                                />
                                            </Box>
                                        ) : (
                                            <span className="w-7 h-7" />
                                        )}
                                    </Grid>
                                    {landingZoneDetails?.vnetSubnet?.length > 1 && landingZoneDetails?.vnetSubnet?.length - 1 !== index && (
                                        <Grid item xs={10}>
                                            <Divider variant="horizontal" flexItem xs={{ marginY: 1 }} />
                                        </Grid>
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};
export default StorageAccountFields;
