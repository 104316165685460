/* istanbul ignore file */
import React from 'react';
import { Grid, Box, TableContainer, Table, TableBody, Typography, Divider } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import SecurityToolMember from 'pages/project-onboarding/components/Form/ServiceMembers/SecurityToolMember';
import { TeamName } from '../TeamName';
import { ApplicationUrl } from '../ApplicationUrl';
import SecurityTools from '../SecurityTools';
import { SecurityToolCodeRepo } from '../SecurityToolCodeRepo';
import ServiceDialog from './ServiceDialog';

const SecurityToolConfiguration = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [deleteMember, setDeleteMember] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleDeleteMember = () => {
        if (order?.fsMemberDetails) {
            if (deleteMember?.gpid === order?.fsMemberDetails?.gpid) {
                setOrder({
                    ...order,
                    fsMemberList: null,
                    fsMemberDetails: null,
                });
                setValidForm({ ...isValidForm, isDuplicateFSMember: false });
            }
        }
        setOrder({
            ...order,
            fsMemberList: order?.fsMemberList?.filter((item) => item.gpid !== deleteMember?.gpid),
        });
        setValidForm({ ...isValidForm, isFSMaxMemberLimit: false });
    };

    const handleNo = () => {
        setIsOpen(false);
    };
    const handleYes = () => {
        handleDeleteMember();
        setIsOpen(false);
    };

    return (
        <Box data-testid="security-tool-config">
            <Grid container spacing={4} data-testid="security-tool-config-fields">
                <Grid item xs={12} sm={6}>
                    <TeamName />
                </Grid>
                <Grid item xs={12} sm={6} data-testid="app-url-field">
                    <ApplicationUrl />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SecurityTools />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SecurityToolMember />
                </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" mt={2}>
                {order?.projectDetails && order?.projectDetails?.repos !== 0 && order?.projectDetails?.repos !== null && (
                    <>
                        <Box display="flex" mt={2}>
                            <Typography variant="h6">Code Repository</Typography>
                        </Box>
                        <SecurityToolCodeRepo />
                    </>
                )}
                <Box display="flex" mt={2}>
                    <Typography variant="h6">Fortify and Snyk Members *</Typography>
                </Box>
                <Divider />
                <Box display="flex">
                    {order?.fsMemberList?.length > 0 ? (
                        <Box width="100%">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {order?.fsMemberList &&
                                            order?.fsMemberList?.length &&
                                            order?.fsMemberList?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left" width="1">
                                                        <Typography>{item?.gpid || ''}</Typography>
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.name || '-'}
                                                    </TableCell>
                                                    <TableCell align="left" width="1">
                                                        {item?.email === 'null' || !item?.email ? '-' : item?.email}
                                                    </TableCell>
                                                    <TableCell width="1">
                                                        <DeleteIcon
                                                            sx={ProjectOnboardingStyles?.removeBtn}
                                                            color="error"
                                                            onClick={() => {
                                                                setDeleteMember(item);
                                                                setIsOpen(true);
                                                            }}
                                                            className="animate-opacity"
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Box mt={2}>
                            <Typography variant="body1">No Members Selected</Typography>
                        </Box>
                    )}
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                    {order?.fsMemberList && order?.fsMemberList?.length > 0 ? (
                        <Typography>
                            <b>{order?.fsMemberList?.length}</b> {order?.fsMemberList?.length < 2 ? 'Member' : 'Members'} Selected{' '}
                        </Typography>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Box>
            </Box>
            <ServiceDialog isOpen={isOpen} member={deleteMember?.name} handleNo={handleNo} handleYes={handleYes} />
        </Box>
    );
};

export default React.memo(SecurityToolConfiguration);
