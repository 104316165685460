// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder, isValidCluster } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const Cluster = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [, setValidCluster] = useRecoilState(isValidCluster);
    const [showHelperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');

    const handleValueChange = (value) => {
        if (value !== order?.cluster) {
            setOrder({
                ...order,
                cluster: value,
            });
        }
    };

    React.useEffect(() => {
        if (
            order?.cluster &&
            order?.cluster !== null &&
            order?.cluster !== '' &&
            order?.cluster !== undefined &&
            !/^[A-Za-z0-9-_]{3,209}$/.test(order?.cluster)
        ) {
            setValidCluster(true);
            setHelperText(true);
            setHelperMsg('Cluster name must be min 3 chars and can contain hyphen or underscore as special char.');
        } else {
            setValidCluster(false);
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.cluster]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.cluster) || showHelperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.cluster && order?.isTouched) setIsTouched(true);
    }, [order?.cluster, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Cluster *"
                error={showHelperText}
                helperText={showHelperText && <span style={ProvisioningStyles.disposal.helpText}>{showHelperMsg}</span>}
                value={order?.cluster || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
            />
            <HelpImg title="Cluster should be minimum 3 chars and can contain hyphen or underscore as special char." />
        </Stack>
    );
};

export default Cluster;
