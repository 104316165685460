/* eslint-disable import/no-named-default */
import { default as Applications } from './Application';
import { default as Sector } from './Sector';
import { default as Environments } from './Environments';
import { default as Region } from './Region';
import { default as CostCenter } from './CostCenter';
import { default as Project } from './Project';
import { default as Notes } from './Notes';
import { default as ServerName } from './ServerName';
import { default as ModificationType } from './ModificationType';
import { default as CurrentSku } from './CurrentSku';
import { default as ConfigureNewSku } from './ConfigureNewSku';
import { default as CurrentStorageDisk } from './CurrentStorageDisk';
import { default as DataDisk } from './DataDisk';
import { default as Sku } from './Sku';
import { default as DataDiskSupport } from './DataDiskSupport';
import { default as MaxIOPS } from './MaxIOPS';
import { default as PremiumDiskSupport } from './PremiumDiskSupport';
import { default as Ram } from './Ram';
import { default as Cost } from './Cost';
import { default as Incident } from './Incident';
import { default as DiskSku } from './DiskSku';
import { default as DiskSize } from './DiskSize';
import { default as DiskTier } from './DiskTier';
import { default as ProvisionedIops } from './ProvisionedIops';
import { default as ProvisionedThroughput } from './ProvisionedThroughput';
import { default as MaxShare } from './MaxShare';
import { default as MaxBurstIops } from './MaxBurstIops';
import { default as MaxBurstThroughput } from './MaxBurstThroughput';
import { default as CostStorage } from './CostStorage';

const Forms = {
    Applications,
    Sector,
    Environments,
    Region,
    CostCenter,
    Project,
    Notes,
    ServerName,
    ModificationType,
    CurrentSku,
    ConfigureNewSku,
    CurrentStorageDisk,
    DataDisk,
    Sku,
    DataDiskSupport,
    MaxIOPS,
    PremiumDiskSupport,
    Ram,
    Cost,
    Incident,
    DiskSku,
    DiskSize,
    DiskTier,
    ProvisionedIops,
    ProvisionedThroughput,
    MaxShare,
    MaxBurstIops,
    MaxBurstThroughput,
    CostStorage,
};

export default Forms;
