import React from 'react';
import Modal from 'components/modal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import {
    toggleSspModels,
    OrderPayload,
    selectedMembersList,
    toggleConfirmModel,
    currentSelection,
    invalidMember,
    memberForm,
    costApiLoading,
} from 'pages/myidm/store';
import DeleteIcon from '@mui/icons-material/Delete';
import AddMemberSearch from 'pages/myidm/components/Form/AddMemberSearch';
import { Box, TableContainer, Table, TableBody, TableHead, CircularProgress } from '@mui/material';
import { Styles, StyledTableCell, StyledTableRow, ActionStyledTableRow } from 'pages/myidm/style';
import Btn from 'components/button';

const MembersListModal = () => {
    const [isModelOpen, setModelOpen] = useRecoilState(toggleSspModels);
    const [members, setMembers] = useRecoilState(selectedMembersList);
    const [modal, setModal] = useRecoilState(toggleConfirmModel);
    const [current, setCurrent] = useRecoilState(currentSelection);
    const [, setInvalid] = useRecoilState(invalidMember);
    const [form, setForm] = useRecoilState(memberForm);
    const [isLoading, setLoading] = React.useState(false);
    const [isCancelLoading, setCancelLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const isCostApiLoading = useRecoilValue(costApiLoading);
    const getmypamsafeApi = useMutation((payload) => axios.post(endPoints.myIdm.mypamsafe, payload));

    const handleCloseModal = () => {
        setModelOpen({ ...isModelOpen, isMemberlist: false });
        setForm({
            ...form,
            member: null,
            selectedMember: null,
        });
    };
    const handleDelete = () => {
        setMembers(() => members.filter((value) => value?.gpid !== current?.gpid));
        setCurrent(null);
        setModal({ ...modal, isOpen: false, message: '', action: false });
        if (members?.length === 1) setInvalid(true);
    };

    const handleDeleteConfirm = (member) => {
        setCurrent(member);
        setModal({ ...modal, isOpen: true, message: `Are you sure you want to remove ${member.name} ?`, action: false });
    };

    React.useEffect(() => {
        if (modal?.action && current?.gpid) {
            handleDelete();
        }
    }, [modal.action]);

    const membersCount = members?.length;
    React.useEffect(() => {
        if (getmypamsafeApi?.data?.data) {
            let result = [];
            const safeGpid = getmypamsafeApi?.data?.data?.safeDetails?.map((pamsafe) => pamsafe?.safeOwner);
            if (getmypamsafeApi?.data?.data?.safeDetails?.length > 0) {
                members?.map((obj) => {
                    if (!safeGpid?.includes(obj?.gpid)) {
                        result?.push(obj?.gpid);
                    }
                    return '';
                });
            } else {
                result = members?.map((mem) => mem?.gpid);
            }

            setOrder({ ...order, mypamsafeDetails: getmypamsafeApi?.data?.data?.safeDetails, nonSafeDetailsGpid: result });
            setModelOpen({ ...isModelOpen, isMemberlist: false });
            setLoading(false);
        }
    }, [getmypamsafeApi?.isSuccess]);
    const handleSavingMembers = () => {
        setLoading(true);

        setOrder({ ...order, members });
        if (order?.adGroupType !== 'Privileged') {
            setModelOpen({ ...isModelOpen, isMemberlist: false });
            setLoading(false);
        }

        if (order?.adGroupType === 'Privileged') {
            const gpidMembers = members.map((mem) => mem.gpid);
            getmypamsafeApi?.mutate({ membersGpid: gpidMembers });
        }
    };
    const handleCancelRequest = () => {
        setCancelLoading(true);
        setModelOpen({ ...isModelOpen, isMemberlist: false });
        setCancelLoading(false);
        setForm({
            ...form,
            member: null,
            selectedMember: null,
        });
    };
    return (
        <Modal title="Members List" handleClose={() => handleCloseModal()} open={isModelOpen?.isMemberlist} width="65%">
            <>
                <Box
                    sx={{ minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' }}
                >
                    <Box sx={{ overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' }}>
                        <AddMemberSearch />
                        <Box sx={{ marginTop: '-40px' }}>
                            <TableContainer sx={Styles?.AddMemberForm?.table.tableWrapper}>
                                <Table sx={{ ...Styles?.AddMemberForm?.table?.main, height: 'auto', borderSpacing: 0 }} size="small" stickyHeader>
                                    {membersCount > 0 && (
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>GPID</StyledTableCell>
                                                <StyledTableCell>Full Name</StyledTableCell>
                                                <StyledTableCell>Email ID</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                    )}
                                    <TableBody>
                                        <ActionStyledTableRow>
                                            {membersCount < 1 && <StyledTableCell>No members selected.</StyledTableCell>}
                                        </ActionStyledTableRow>

                                        {membersCount > 0 ? (
                                            <>
                                                {!members.loading &&
                                                    members?.map((member) => (
                                                        <StyledTableRow className="animate-opacity" key={member?.gpid}>
                                                            <StyledTableCell>{member?.gpid || ''}</StyledTableCell>
                                                            <StyledTableCell>{member?.name || '-'}</StyledTableCell>
                                                            <StyledTableCell>{member?.email || '-'}</StyledTableCell>

                                                            <StyledTableCell>
                                                                <DeleteIcon
                                                                    sx={Styles?.AddMemberForm?.removeBtn}
                                                                    color="error"
                                                                    onClick={() => handleDeleteConfirm(member)}
                                                                    data-testid="remove-btn"
                                                                    className="animate-opacity"
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                            </>
                                        ) : null}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) }}>
                        {(membersCount > 0 || order?.members?.length > 0) && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                <Btn
                                    variant="contained"
                                    color="cmpPrimary"
                                    onClick={() => handleSavingMembers()}
                                    sx={{
                                        width: (theme) => theme.spacing(15.75),
                                        height: (theme) => theme.spacing(4.375),
                                        marginBottom: 1,
                                        marginRight: 1,
                                    }}
                                    disabled={isLoading || isCostApiLoading}
                                >
                                    {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Save'}
                                </Btn>
                                <Btn
                                    onClick={() => handleCancelRequest()}
                                    color="cmpPrimary"
                                    variant="outlined"
                                    sx={{
                                        width: (theme) => theme.spacing(15.75),
                                        height: (theme) => theme.spacing(4.375),
                                        marginBottom: 1,
                                    }}
                                    data-testId="cancel-btn"
                                >
                                    {isCancelLoading ? <CircularProgress color="inherit" size={16} /> : 'Cancel'}
                                </Btn>
                            </Box>
                        )}
                    </Box>
                </Box>
            </>
        </Modal>
    );
};

export default React.memo(MembersListModal);
