import React from 'react';
import { Box, Divider, Grid, Stack } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const SecurityTool = () => {
    const [order] = useRecoilState(OrderPayload);
    return (
        <Box sx={Style?.preview?.wrapper}>
            <Box sx={Style?.preview?.heading}>Fortify / Snyk - SAST</Box>
            <Divider variant="horizontal" sx={Style?.preview?.divder} />
            <Grid container sx={Style?.preview?.fs?.container}>
                <Grid item xs={12} sm={12}>
                    <Stack direction="column" spacing={1}>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Box sx={Style?.preview?.fs?.label}>Team Name:</Box>
                            <Box sx={Style?.preview?.fs?.value}>{order?.teamName}</Box>
                        </Box>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Box sx={Style?.preview?.fs?.label}>Application URL:</Box>
                            <Box sx={Style?.preview?.fs?.value}>{order?.applicationUrl}</Box>
                        </Box>
                        <Box sx={Style?.preview?.fs?.subWrapper}>
                            <Box sx={Style?.preview?.fs?.label}>Security Tools:</Box>
                            <Box sx={Style?.preview?.fs?.value}>{order?.securityTool}</Box>
                        </Box>
                        {order?.securityToolCodeRepoList?.[0]?.repo !== '' && order?.securityToolCodeRepoList?.[0]?.repo !== null && (
                            <>
                                <Box sx={Style?.preview?.fs?.subWrapper}>
                                    <Box sx={Style?.preview?.fs?.label}>Code Repository:</Box>
                                </Box>
                                {order?.securityToolCodeRepoList?.map((repo, index) => (
                                    <Box sx={Style?.preview?.fs?.value} key={`repo${index}`}>
                                        {index + 1}. https://dev.azure.com/PepsiCoIT/{repo?.repo}
                                    </Box>
                                ))}
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SecurityTool;
