/* eslint-disable react/prop-types   */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress } from '@mui/material';
import { BarChart, Bar, Tooltip, YAxis, XAxis, ResponsiveContainer } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard-enhancement/style';
import Chart from '../components/Chart';
import { dateRangeValue, dashboardFilter } from '../store';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const SolutionsProvisioned = () => {
    const [data, setData] = React.useState([]);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);

    const ordersTrendQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.metricApi, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            providers: dashboardFilterObj?.provider || [],
            sectors: dashboardFilterObj?.sector || [],
            services: dashboardFilterObj?.services || [],
            solutions: dashboardFilterObj?.solutions || [],
            environments: dashboardFilterObj?.environment || [],
            regions: dashboardFilterObj?.regions || [],
            applicationService: dashboardFilterObj?.ApplicationService?.label || '',
            metricType: 'solutionsByCatalog',
        };
        setData([]);
        ordersTrendQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (ordersTrendQuery?.isSuccess) {
            const results = ordersTrendQuery?.data?.data?.map((item) => ({
                name: item?.key,
                Solutions: item?.distinct_item_ids?.value,
            }));
            setData(results || []);
        }
    }, [ordersTrendQuery?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 6);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);
    const hideXAxis = true;

    const EllipsisAxisTick = ({ x, y, payload, ...rest }) => {
        const [text] = React.useState(payload.value);
        return (
            <g transform={`translate(${x},${y})`}>
                <text {...rest} x={0} y={0} dy={3.5} fontSize="16px" fontFamily="Roboto" fontWeight="medium">
                    {text}
                </text>
            </g>
        );
    };
    return (
        <Chart title="SOLUTIONS PROVISIONED - BY SOLUTION" subTitle>
            {/* <Typography sx={dashboardStyle.chart.subchartTitle}>SOLUTIONS PROVISIONED</Typography> */}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {ordersTrendQuery.isLoading && (
                    <Box sx={dashboardStyle.orderCreationTrend.wraper}>
                        <CircularProgress sx={dashboardStyle.orderCreationTrend.circularProgress} size={16} />
                    </Box>
                )}
                {!ordersTrendQuery.isLoading && data?.length <= 0 && <Box sx={dashboardStyle.orderCreationTrend.wraper}>No data available</Box>}
                {data?.length > 0 && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.provisioned_resource}>
                        {/* // <div className="charScrollbar" style={{ width: '488px', height: '280px', overflow: 'auto' }}> */}
                        {/* <ResponsiveContainer width="100%" height={300}> */}
                        <ResponsiveContainer width="100%" height={data?.length < 4 ? 250 : data?.length * 18 + 100}>
                            <BarChart
                                width={470}
                                height={data?.length < 4 ? 250 : data?.length * 18 + 100}
                                layout="vertical" // Set layout to "vertical" for horizontal bars
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 15,
                                }}
                            >
                                <XAxis type="number" hide={hideXAxis} />
                                <YAxis dataKey="name" type="category" interval={0} tick={<EllipsisAxisTick />} />
                                <Tooltip cursor={false} />
                                {/* <Legend /> */}
                                <Bar dataKey="Solutions" fill="#91C24C" label={{ position: 'right' }} barSize={23} radius={[0, 25, 25, 0]} />
                            </BarChart>
                            {/* </ResponsiveContainer> */}
                            {/* </div> */}
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(SolutionsProvisioned);
