import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Box, Grid, Typography } from '@mui/material';
import FileUpload from 'components/fileupload';
import DeleteIcon from '@mui/icons-material/Delete';
import { mapExtensionsToMimeTypes, convertMBtoBytes, convertBytesToMB } from 'utils';
import { useRecoilState } from 'recoil';
import { OrderPayload, uploadedFilesState } from 'pages/reference-implementation/provisioning/RI001/store';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { RIStyles } from 'pages/reference-implementation/Style';

function UploadScan() {
    const [order, setOrder] = useRecoilState(OrderPayload);
    // const [isTouched, setIsTouched] = React.useState(false);
    const fileExtensions = ['.pdf', '.ppt', '.pptx'];
    const fileTypes = mapExtensionsToMimeTypes(fileExtensions);
    const [uploadedFiles, setUploadedFiles] = useRecoilState(uploadedFilesState);
    const maxFiles = 3;

    const handleFileUpload = (files) => {
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleDelete = (fileName) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };
    const handleDeleteRelativeFiles = (file) => {
        const deletedFiles = {
            id: file?.id,
            fileName: file?.name,
            blobRelativeLink: file?.blobRelativeLink,
        };
        setOrder({ ...order, deletedFiles: [...(order?.deletedFiles ?? []), deletedFiles] });
    };
    // const validateInput = () => {
    //     return !!(isTouched && order?.isTouched && !uploadedFiles?.length);
    // };
    const getHelpText = () => {
        return (
            <Box sx={RIStyles.adinfoStyle.wrapper}>
                <Box sx={RIStyles.adinfoStyle.namingRuleWrapper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={RIStyles.adinfoStyle.topLeftNamingRule}>
                            <Typography sx={RIStyles.infoStyle.typographyStyle}>Upload File Guidelines:</Typography>
                            <ul>
                                <li>
                                    <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                        <b>Allowed File Types:</b> PDF (.pdf) and PowerPoint (.ppt, .pptx) files.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                        <b>Number of Files:</b> You can upload between 1 to 3 files at a time.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                        <b>Maximum File Size:</b> Maximum upload limit 10 MB (including 3 files).
                                    </Typography>
                                </li>
                            </ul>
                            <Typography sx={RIStyles.adinfoStyle.typographyStyle}>
                                {`Note: Ensure your files meet the above requirements to avoid upload issues. Duplicate files will not be
                                                accepted. If you encounter any errors during the upload process, please check the file type, size, and ensure
                                                you haven't exceeded the file count limit. Drag and drop your files into the designated area or click to select
                                                files from your computer.`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    };
    // React.useEffect(() => {
    //     if (!uploadedFiles?.length && order?.isTouched) setIsTouched(true);
    // }, [uploadedFiles, order?.isTouched]);

    return (
        <Box>
            <Box
                sx={{
                    color: '#0047BA',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    fontWeight: 'normal',
                    pb: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '10px',
                }}
            >
                <Box>Upload Architecture Document in .ppt or .pdf format</Box>
                <InputInfo iconFontSize="16px" maxWidth="500px" title={getHelpText()} />
            </Box>
            <FileUpload
                // isValid={validateInput()}
                onFileUpload={handleFileUpload}
                uploadedFileList={uploadedFiles}
                accept={fileTypes}
                maxSize={convertMBtoBytes(10)}
                maxFiles={maxFiles}
                multiple
            />
            {uploadedFiles.length > 0 && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '75%' }}>File Name</TableCell>
                                <TableCell sx={{ width: '15%' }}>File Size</TableCell>
                                <TableCell sx={{ width: '10' }} align="right">
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uploadedFiles.map((file, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {file.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {convertBytesToMB(file.size)} MB
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => {
                                                if (file?.blobRelativeLink) {
                                                    handleDeleteRelativeFiles(file);
                                                }
                                                handleDelete(file.name);
                                            }}
                                            aria-label={`delete-${index}`}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}

export default UploadScan;
