import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CssTextField } from 'pages/reference-implementation/components/select';
import HelpImg from 'pages/provisioning/_components/helpImg';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import { RIStyles } from 'pages/reference-implementation/Style';

const TransactionIDPrefix = (props) => {
    const { isRequired, handleOnSelect, transactionprefixtextValue, errors } = props;

    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                data-testid="tarnsactionIdPrefix"
                label="Transaction ID Prefix *"
                autoComplete="off"
                error={isRequired || errors?.showError}
                helperText={errors?.showError && <span style={{ color: '#FF3939' }}>{errors?.showMessage}</span>}
                value={transactionprefixtextValue || ''}
                onChange={(event) => handleOnSelect(event.target.value)}
                className={errors?.showError || errors?.errorMessage ? 'validate' : ''}
            />
            <HelpImg
                maxmWidth="450px"
                title={
                    <>
                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>2) Min.: 3 chars to Max.: 20 chars</Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            3) Can contain only hyphen(-) as special char
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            4) Should not start or end with any special char
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>5) Should not contain consecutive hypens</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

TransactionIDPrefix.propTypes = {
    errors: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
    transactionprefixtextValue: PropTypes.string,
};

TransactionIDPrefix.defaultProps = {
    errors: null,
    handleOnSelect: () => {},
    isRequired: false,
    transactionprefixtextValue: '',
};

export default TransactionIDPrefix;
