/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const NetworkConnectivity = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);

    const setNetworkConnectivity = (networkConnectivity) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                networkConnectivity,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setNetworkConnectivity(null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setNetworkConnectivity(selected?.label);
        } else {
            setNetworkConnectivity(null);
        }
    };

    React.useEffect(() => {
        if (options?.length && options?.length === 1) {
            handleOnSelectChange(options[0]);
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        setOptions([{ label: 'Selected Networks (Default)', value: 'Selected Networks (Default)' }]);
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="networkConnectivity"
                value={landZoneForm?.networkConnectivity}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Network Connectivity"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(NetworkConnectivity);
