import React from 'react';
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import LabelValue from 'components/label-value';
import { cartStyle } from 'pages/cart/style';
import { getOrderSummary } from 'utils/OrderSummary/getOrderSummary';
import CostSplit from 'pages/cart/components/cost-split';

function StackCatalogDetails(props) {
    const { items } = props;
    return (
        <Grid container xs={12} spacing={1} sx={cartStyle?.CostDetails?.stackContainer} className="order-details">
            <Typography sx={cartStyle?.catalogDetails?.summary}>Summary</Typography>
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="Catalog" value={items?.catalogType} />
            </Grid>
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="Sector" value={items?.sector} />
            </Grid>
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="Environment" value={items?.catalogConfig?.environmentDetails?.environment} />
            </Grid>
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="Region" value={items?.catalogConfig?.environmentDetails?.region} />
            </Grid>
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="Domain Name Service" value={`${items?.catalogConfig?.isDnsRequired === true ? 'Yes' : 'No'}`} />
            </Grid>
            {items?.catalogConfig?.isDnsRequired === true && (
                <>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="FQDN Name" value={items?.catalogConfig?.fqdn} />
                    </Grid>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="IP Address" value={items?.catalogConfig?.ipAddress} />
                    </Grid>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="SSL Certificate" value={items?.catalogConfig?.sslCertificate} />
                    </Grid>
                </>
            )}
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="ECE Integration" value={`${items?.catalogConfig?.isEceRequired === true ? 'Yes' : 'No'}`} />
            </Grid>
            {items?.catalogConfig?.isEceRequired === true && (
                <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                    <LabelValue width={160} label="IT LDAP Group" value={items?.catalogConfig?.ldapGroup} />
                </Grid>
            )}
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="CLE Integration" value={`${items?.catalogConfig?.isCleRequired === true ? 'Yes' : 'No'}`} />
            </Grid>
            {items?.catalogConfig?.isCleRequired === true && (
                <>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="ServiceNow Assignment Group" value={items?.catalogConfig?.serviceNowAssignmentGroup} />
                    </Grid>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="Catalog Size" value={items?.catalogConfig?.catalogSize} />
                    </Grid>
                </>
            )}
            <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                <LabelValue width={160} label="Conjure Integration" value={`${items?.catalogConfig?.isConjourRequired === true ? 'Yes' : 'No'}`} />
            </Grid>
            {items?.catalogConfig?.isConjourRequired === true && (
                <>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="Safe Name" value={items?.catalogConfig?.safeName} />
                    </Grid>
                    <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}>
                        <LabelValue width={160} label="SOX" value={items?.catalogConfig?.sox} />
                    </Grid>
                </>
            )}
            {/* <Grid item className="stackDetails" xs={12} sx={cartStyle.catalogDetails.gridLabel}> */}
            <CostSplit data={items?.estimatedCost} width={160} />
            {/* </Grid> */}
            <Grid item xs={12} sx={cartStyle.applicationCostDetails.wrapper} data-testid="stackcomponent">
                {items?.catalogConfig?.components
                    ?.map(
                        (item) =>
                            getOrderSummary({
                                orders: { ...item, catalogServices: [{ ...item?.catalogService }] },
                            })?.[0]
                    )
                    ?.map((catalog, index) => (
                        <Accordion sx={cartStyle?.costAccordian?.accordian} disableGutters defaultExpanded={index === 0} key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={cartStyle?.costAccordian?.expandIcon} />}
                                sx={cartStyle?.costAccordian?.container}
                            >
                                <Typography sx={cartStyle?.costAccordian?.summaryTitle}>{catalog?.catalogType}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={cartStyle?.costAccordian?.accordianDetails}>
                                <Grid container xs={12} spacing={1} sx={cartStyle?.CostDetails?.stackContainer} className="order-details">
                                    {Object.keys(catalog?.summary).map((label) => (
                                        <>
                                            {label === 'cost' && <CostSplit data={catalog?.summary[label]} width={160} />}
                                            {label !== 'cost' && (
                                                <Grid className="stackDetails" key={label} xs={12} sx={cartStyle.catalogDetails.gridLabel} item>
                                                    <LabelValue width={160} key={label} label={`${label}`} value={catalog?.summary[label]} />
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                                    {Object.keys(catalog?.summary).length % 2 !== 0 && (
                                        <>
                                            <Grid className="stackDetails" xs={12} item>
                                                &nbsp;
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
            </Grid>
        </Grid>
    );
}

StackCatalogDetails.propTypes = {
    items: PropTypes.any.isRequired,
};

StackCatalogDetails.defaultProps = {};
export default StackCatalogDetails;
