import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const CostStorage = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const handleValueChange = (value) => {
        if (value !== order?.costStorage) {
            setOrder({
                ...order,
                costStorage: `$${value}`,
            });
        }
    };

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.costStorage) {
            return 'validate';
        }
        return '';
    };
    React.useEffect(() => {
        if (
            order?.diskSizeInGB &&
            order?.diskTier &&
            order?.provisionedIops &&
            order?.provisionedThroughput &&
            order?.maxShare &&
            order?.maxBurstIops &&
            order?.maxBurstThroughput
        ) {
            handleValueChange(Math.floor(Math.random().toFixed(2) * 1000)?.toString());
        }
    }, [
        order?.diskSizeInGB,
        order?.diskTier,
        order?.provisionedIops,
        order?.provisionedThroughput,
        order?.maxShare,
        order?.maxBurstIops,
        order?.maxBurstThroughput,
    ]);
    React.useEffect(() => {
        if (!order?.costStorage && order?.isTouched) setIsTouched(true);
    }, [order?.costStorage, order?.isTouched]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                autoComplete="off"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Cost / Month *"
                value={order?.costStorage || ''}
                onChange={(e) => handleValueChange(e.target.value)}
                className={validateInput()}
                disabled
            />
        </Stack>
    );
};

export default CostStorage;
