import React from 'react';
import { Grid, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import Form from 'pages/myidm/components/Form';
import FormCard from 'pages/myidm/components/form-card';
import { OrderPayload } from 'pages/myidm/store';
// import AddMemberSearch from 'pages/myidm/components/Form/AddMemberSearch';

const MemberForm = () => {
    const order = useRecoilValue(OrderPayload);
    React.useEffect(() => [order]);
    return (
        // <Grid item md={12} boxShadow={2} borderRadius={1.5}>
        <FormCard title="" gap={0} padding={2} margin={2}>
            <Grid item container spacing={2} sx={{ mt: 2, mb: 3 }}>
                <Grid item md={6} sm={6} xs={12}>
                    <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                        <Form.BusinessApp />
                    </Stack>
                    {order?.isNewADGroup && order?.isNewADGroup === 'yes' && (
                        <>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.ApplicationService />
                            </Stack>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.AppShortName />
                            </Stack>
                        </>
                    )}

                    {order?.isNewADGroup && order?.isNewADGroup === 'yes' && (
                        <>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.ADGroupType />
                            </Stack>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.SelectMemberBtn />
                            </Stack>
                            {/* <Stack direction="column" spacing={2}>
                                    <AddMemberSearch />
                                </Stack> */}
                        </>
                    )}
                    {((order?.isNewADGroup && order?.isNewADGroup === 'no') || !order?.isNewADGroup) && (
                        <>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.Sector />
                            </Stack>
                        </>
                    )}
                    {((order?.isNewADGroup && order?.isNewADGroup === 'no') || !order?.isNewADGroup) && (
                        <>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.SelectMemberBtn />
                            </Stack>
                        </>
                    )}
                    {/* {((!order?.isNewADGroup && order?.isNewADGroup !== 'yes') || order?.isNewADGroup === 'no') && (
                            <Stack direction="column" spacing={2}>
                                <AddMemberSearch />
                            </Stack>
                        )} */}
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                        <Form.Environments />
                    </Stack>

                    {order?.isNewADGroup && order?.isNewADGroup === 'yes' && (
                        <>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.ApplicationName />
                            </Stack>
                        </>
                    )}
                    {(!order?.isNewADGroup || order?.isNewADGroup === 'no') && (
                        <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                            <Form.ADGroup />
                        </Stack>
                    )}

                    {order?.isNewADGroup && order?.isNewADGroup === 'yes' && (
                        <>
                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.Sector />
                            </Stack>

                            <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
                                <Form.GroupOwner />
                            </Stack>
                        </>
                    )}
                </Grid>
            </Grid>
        </FormCard>
        // </Grid>
    );
};

export default MemberForm;
