import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isAppNameValid } from 'pages/reference-implementation/provisioning/RI003/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const ApplicationName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setApplicationNameVaild] = useRecoilState(isAppNameValid);
    const [helperText, setHelperText] = React.useState(false);

    const setApplicationName = (applicationName = null) => {
        setOrder({
            ...order,
            applicationName,
            appShortName: null,
        });
    };

    React.useEffect(() => {
        if (
            order?.applicationName &&
            order?.applicationName !== null &&
            order?.applicationName !== '' &&
            order?.applicationName !== undefined &&
            !/^[a-zA-Z0-9 _-]{4,64}$/.test(order?.applicationName)
        ) {
            setHelperText(true);
            setApplicationNameVaild(false);
        } else {
            setHelperText(false);
            setApplicationNameVaild(true);
        }
    }, [order?.applicationName]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.applicationName) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.applicationName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Application Name" isRequired />}
                error={helperText}
                helperText={helperText && <span>Application name must be min 4 chars and accepts only hyphen(-) & underscore(_) as special chars.</span>}
                value={order?.applicationName || ''}
                onChange={(e) => setApplicationName(e.target.value)}
                className={validateInput()}
                onKeyDown={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (event.target.value || event.target.value !== '')) {
                        setOrder({
                            ...order,
                            appShortName: null,
                        });
                    }
                }}
            />
        </Box>
    );
};

export default ApplicationName;
