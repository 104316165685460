import theme from 'assets/style/theme';
import { styled } from '@mui/material/styles';
import { TextField, Button, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const LandingZoneStyle = {
    wrapper: {
        background: theme.palette.grey.lighter,
        color: theme.palette.white,
        overflow: 'hidden',
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    helptext: { marginLeft: theme.spacing(1) },

    header: {
        wrapper: {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            py: theme.spacing(2),
            paddingRight: theme.spacing('53px'),
            paddingLeft: theme.spacing('53px'),
            borderBottom: '1px solid #E0E0E0',
            height: theme.spacing('82px'),
        },
        container: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },

        typography: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: theme.spacing(-1.875),
            fontFamily: 'Stolzl',
            fontSize: '30px',
            fontWeight: 'medium',
            color: theme.palette.black.dark,
        },
        backIcon: { color: theme.palette.blue.main },
        filterWrapper: { display: 'flex', justifyContent: 'right' },
        filterSubWrap: { height: '100%' },
        addBox: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(4.375),
            height: theme.spacing(4.375),
            background: theme.palette.blue.main,
            marginRight: theme.spacing(1),
        },
        addIcon: {
            fontSize: theme.spacing(3.125),
            color: theme.palette.white,
            cursor: 'pointer',
        },
    },
    vnetSubnetStyle: {
        delete: {
            fontSize: theme.spacing(2.25),
            fill: '#FFFFFF',
            color: theme.palette.white,
            cursor: 'pointer',
            width: theme.spacing(1.5),
            height: theme.spacing(1.625),
        },
        add: {
            fontSize: theme.spacing(2.25),
            fill: '#FFFFFF',
            color: theme.palette.white,
            cursor: 'pointer',
            width: theme.spacing(1.5),
            height: theme.spacing(1.625),
        },
        warningTypography: { color: theme.palette.red.light },
        addWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3),
            height: theme.spacing(3),
            background: '#0047BA',
            mt: 0.5,
        },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3),
            height: theme.spacing(3),
            background: '#F4273B',
            mt: 0.5,
        },
    },
    list: {
        appProfileDeails: {
            wrapper: {
                color: theme.palette.white,
                flexGrow: 1,
                overflow: 'auto',
                display: 'flex',
                pt: theme.spacing('6px'),
                pb: theme.spacing(1),
                backgroundColor: theme.palette.grey.lighter,
                //  px: theme.spacing('53px'),
            },
            tableContainer: {
                marginTop: theme.spacing(0.5),
                // border:'1px solid #E0E0E0',
                borderRadius: '3px',
                //  boxShadow: '1px 1px 2px #00000014',
            },
        },
        wrapper: {
            color: theme.palette.white,
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            pt: theme.spacing('6px'),
            pb: theme.spacing(1),
            backgroundColor: theme.palette.grey.lighter,
            //  px: theme.spacing('53px'),
        },
        tableContainer: {
            marginTop: theme.spacing(0.5),
            // border:'1px solid #E0E0E0',
            borderRadius: '3px',
            boxShadow: '1px 1px 2px #00000014',
        },
        table: { marginTop: theme.spacing(-1.125), paddingTop: theme.spacing(1), minWidth: 500, height: 'auto', borderSpacing: theme.spacing(0) },
        tableRow: { height: theme.spacing('50px'), backgroundColor: theme.palette.blue.main },
        loaderRow: { background: 'transparent !important' },
        loaderCell: { border: '0 !important' },
        editWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3.375),
            height: theme.spacing(3.375),
            background: theme.palette.blue.main,
            marginRight: theme.spacing(1),
        },
        editIcon: {
            width: theme.spacing('15px'),
            height: theme.spacing('15px'),
            color: theme.palette.white,
            cursor: 'pointer',
            marginLeft: '3px',
        },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3.375),
            height: theme.spacing(3.375),
            background: theme.palette.red.main,
            marginRight: theme.spacing(1),
        },
        deleteIcon: {
            fontSize: theme.spacing(2.25),
            color: theme.palette.white,
            cursor: 'pointer',
            width: theme.spacing(1.5),
            height: theme.spacing(1.625),
        },
        eyeWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: theme.spacing(3.375),
            height: theme.spacing(3.375),
            background: theme.palette.blue.main,
        },
        eyeIcon: {
            fontSize: 19,
            color: '#ffffff',
            cursor: 'pointer',
        },
        userIcon: { fontSize: '27px', marginTop: '5px' },
        pagination: {
            tableFooter: { height: '70px', border: '1px solid rgba(224, 224, 224, 1)', backgroundColor: theme.palette.white },
            tablePagination: {
                background: '#ffffff',
                width: '100%',
                color: theme.palette.black.dark,
                border: '1px solid rgba(224, 224, 224, 1)',

                '.MuiTablePagination-selectLabel, .MuiTablePagination-input': {
                    // fontWeight: "bold",
                    // color: "blue"
                    display: 'none',
                },
                '.MuiTablePagination-spacer': {
                    flex: 0,
                },
                '.MuiToolbar-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                },
                '.MuiTablePagination-displayedRows': {
                    fontWeight: 500,
                    fontSize: '1rem',
                    color: '#1E1E1E',
                    paddingTop: '2px',
                },
            },
        },
    },
    createModal: {
        wrapper: { width: 700, display: 'flex', flexDirection: 'column', height: '100%' },
        fieldsWrapper: { flexGrow: 1, pt: theme.spacing(2) },
        cloudProviderBox: { mt: theme.spacing(1), px: theme.spacing(1) },
        fieldBox: { fontSize: theme.spacing(1.875), display: 'flex', mb: theme.spacing(1) },
        fieldLabel: { display: 'flex', alignItems: 'center', width: '30%', py: theme.spacing(0.5) },
        fieldValue: { flexGrow: 1 },
        colon: { display: 'flex', alignItems: 'center', width: theme.spacing(0.75), mx: theme.spacing(1) },
        btnWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: theme.spacing(2), paddingTop: theme.spacing('20%') },
        circularIcon: { marginLeft: theme.spacing(1.25) },
        cancelBtn: {
            marginLeft: theme.spacing(1.25),
            textTransform: 'none',
            color: '#6E6F6F',
            border: '2px solid #707070',
            backgroundColor: '#ffffff',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                backgroundColor: '#ffffff',
            },
        },
        modal: { width: 500 },
    },
    deleteModal: {
        title: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
        btnWrapper: { display: 'flex', justifyContent: 'center', mt: theme.spacing(2) },
        yesBtn: { display: 'flex', alignItems: 'center' },
        noBtn: {
            marginLeft: theme.spacing(1.25),
            textTransform: 'none',
            color: '#6E6F6F',
            border: '2px solid #707070',
            backgroundColor: '#ffffff',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                backgroundColor: '#ffffff',
            },
        },
        circularIcon: { marginLeft: theme.spacing(1.25) },
    },
    dynamicFields: {
        wrapper: { px: theme.spacing(1) },
        subWrapper: { fontSize: theme.spacing(1.875), display: 'flex', mb: theme.spacing(1) },
        label: { display: 'flex', alignItems: 'center', width: '30%', py: theme.spacing(0.5) },
        colon: { display: 'flex', alignItems: 'center', width: theme.spacing(0.75), mx: theme.spacing(1) },
        value: { flexGrow: 1 },
    },
    landingZoneFilter: {
        filterBtn: {
            height: '38px',
            width: '100px',
            color: theme.palette.blue.main,
            border: '2px solid #0047BA',
            '&:hover': {
                border: '2px solid #00A0DD',
                color: '#00A0DD',
                backgroundColor: 'transparent !important',
            },
        },
        wrapper: {
            background: '#FFFFFF',
            width: 600,
            paddingTop: 0.5,
            paddingRight: 2,
            paddingBottom: 2,
            paddingLeft: 2,
            position: 'relative',
            top: 10,
        },
        popperStyle: { zIndex: 999, marginRight: 'calc(100vw - 100px)', left: '50px' },
        dropDownStack: { mb: 2, pt: 0.5 },
        dropDownContainer: { height: '12px', display: 'flex', justifyContent: 'flex-end' },
        closeBox: { marginTop: '-4px', marginRight: '-12px', cursor: 'pointer' },
    },
    text: { color: '#FFFFFF' },
    select: { width: '100%' },
    infoIcon: { color: '#0047BA' },
    vnetSubNetPaper: {
        height: '100%',
        borderRadius: theme.spacing(0.75),
        marginBottom: theme.spacing(3.125),
        border: '1px solid #E0E0E0',
    },
    privateEndpoints: {
        fontFamily: 'Stolzl',
        fontSize: '18px',
        fontWeight: 'medium',
        color: '#000000',
    },
    selectedNetworks: {
        fontFamily: 'Stolzl',
        fontSize: '14px',
        fontWeight: 'medium',
        color: '#000000',
    },
    addBtn: {
        marginLeft: 'auto',
    },
};

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#0047BA',
    },
    '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
        color: '#0047BA',
        padding: '0 2px',
    },
    '& label.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& label.MuiInputLabel-shrink+div fieldset': {
        border: ' 1px solid #0047BA',
    },
    '& label+div fieldset': {
        '&:hover': {
            border: ' 1px solid #0047BA  !important',
        },
    },
    '& label': {},
    '& label.Mui-disabled': {
        color: '#A5A5A5',
    },
    '& label.Mui-disabled.MuiFormLabel-filled+div .MuiOutlinedInput-input': {
        color: '#000000 !important ',
        backgroundColor: '#FFFFFF',
    },
    '& label.Mui-disabled.MuiFormLabel-filled+div fieldset': {
        border: ' 1px solid #A5A5A5',
        color: '#000000',
    },

    '& .MuiInputBase-input': {
        color: '#000000',
        background: '#FFFFFF',
        '&.Mui-disabled': {
            backgroundColor: '#ffffff',
            color: '#000000',
        },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        color: '#000000',
        backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E9E9E9',
            color: '#000000',
        },
        '&:hover fieldset': {
            borderColor: '#E9E9E9',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0047BA',
        },
    },
});

export const ColorButton = styled(Button)(() => ({
    background: '#0047BA',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#00A0DD',
        color: '#ffffff',
    },
}));

export const RestButton = styled(Button)(() => ({
    display: 'none',
    background: '#e9e9e9',
    color: '#626262',
    '&:hover': {
        backgroundColor: '#e9e9e9',
        color: '#626262',
    },
}));

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        background: '#0047BA',
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: '0px',
        color: theme.palette.black.dark,
        backgroundColor: theme.palette.white,
        borderSpacing: 0,
        '&:first-of-type': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '&:last-child': {
            borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}));
export const StyledTableRow = styled(TableRow)(() => ({
    height: theme.spacing('50px'),
    backgroundColor: '#ffffff',
    '&:hover': {
        backgroundColor: theme.palette.cyan['90'],
    },
    '&:last-child td, &:last-child th': {},
}));
export default LandingZoneStyle;
