import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Box } from '@mui/material';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import Alert from 'components/alert';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { catalogs } from 'store';
import { sspSubmitOrder, toggleSspModels, isMandatoryTagsFullfilled, costEstimateState, isLoadingCostApiState } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import { resetCostEstimateDetails } from 'pages/provisioning/couchbase/utils';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import { getPayload } from 'pages/provisioning/couchbase/getPayload';

const ProvisioningHeader = () => {
    const { catalogCode, cartItemId, catalogServiceId } = useParams();
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [isWelcomeBannerOpen, setWelcomeBannerOpen] = React.useState(true);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [modal] = useRecoilState(toggleSspModels);
    const isMandatoryTags = useRecoilValue(isMandatoryTagsFullfilled);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [, setIsLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const [isNodeEmpty, setNodeEmpty] = React.useState(false);

    const getCostPayload = () => {
        const payload = getPayload({ order, catalogCode, cartItemId, catalogServiceId });
        const costPayload = payload?.catalogService;
        delete costPayload?.estimatedCost;
        return costPayload;
    };

    const checkVarConditions = () =>
        order?.sector &&
        order?.environment &&
        order?.region &&
        order?.tags &&
        order?.hCodeDetails &&
        order?.billingEntity &&
        order?.envDetails &&
        order?.landingZoneDetails &&
        order?.cluster &&
        order?.dbVersion &&
        order?.nodesCount &&
        !isNodeEmpty;
    const cost = useMutation(() => axios.post(endPoints?.ssp?.costCalculation, getCostPayload()));

    React.useEffect(() => {
        let errorCount = 0;
        order?.nodes?.forEach((z) => {
            if (
                !z?.osTypeVersion ||
                !z?.vmType ||
                !z?.vmTypeDetails?.name ||
                !z?.domain ||
                !z?.haDeployment ||
                (z?.haDeployment === 'Availability Zones' && !z?.availabilityZone) ||
                !z?.isConfigDiskFulfilled ||
                !z?.osDiskType
            ) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
            setNodeEmpty(errorCount !== 0);
        });
    }, [order?.nodes]);

    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        if (checkVarConditions() === true) {
            cost?.mutate();
        }
        // eslint-disable-next-line
    }, [
        order?.sector,
        order?.environment,
        order?.region,
        order?.hCodeDetails,
        order?.landingZoneDetails,
        order?.cluster,
        order?.dbVersion,
        order?.nodesCount,
        order?.nodes,
        isNodeEmpty,
    ]);

    React.useEffect(() => {
        setIsLoadingCostApi({ isLoading: cost?.isFetching });
    }, [cost?.isFetching]);

    React.useEffect(() => {
        if (cost?.data?.data && checkVarConditions()) {
            if (cost?.data?.data) setCostEstimate(cost?.data?.data);
            setOrder({ ...order, estimatedCost: cost?.data?.data });
        }
        // eslint-disable-next-line
    }, [cost?.isSuccess]);

    React.useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        // eslint-disable-next-line
    }, []);

    const isReadyToSubmit = () =>
        !order?.sector || !order?.environment || !order?.region || !order?.landingZoneDetails || !order?.hCodeDetails || isMandatoryTags;

    const getDisImage = (code) => {
        if (cmpCatalogs?.length) {
            const result = cmpCatalogs.filter((service) => service.catalogDefinitionId === code);
            return result[0]?.iconPath;
        }
        return null;
    };

    const getProvisiongTitle = (code) => {
        if (cmpCatalogs?.length) {
            const result = cmpCatalogs.filter((service) => service.catalogDefinitionId === code);
            return result[0]?.displayName;
        }
        return null;
    };
    return (
        <>
            {cmpCatalogs?.length > 0 && <StickyHeader title={`${getProvisiongTitle(catalogCode)} Provisioning`} imgSrc={getDisImage(catalogCode)} />}
            <Box sx={ProvisioningStyles.ProvisioningHeader.header}>
                {!order?.isTouched && isReadyToSubmit() && isWelcomeBannerOpen && !modal.isCostSplitOpen && (
                    <Alert
                        title={null}
                        message="Welcome! Please fill out all of the required fields marked with the * asterisk to complete your order."
                        Icon={<InfoImg style={ProvisioningStyles.ProvisioningHeader.infoIcon} />}
                        borderLeftColor="#0047BA"
                        backgroundColor="#DFE6F2"
                        color="#000000"
                        ftSize="15px"
                        onClose={() => setWelcomeBannerOpen(false)}
                        closeicon
                    />
                )}
            </Box>
        </>
    );
};

ProvisioningHeader.propTypes = {};

ProvisioningHeader.defaultProps = {};

export default React.memo(ProvisioningHeader);
