import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { endPoints } from 'chatbot/components/api/endpoints';
import { isResponseLoading } from 'chatbot/store';
import chatBotStyle from '../style';

const TopicButton = (props) => {
    const { actions, state } = props;
    const [, setResponseLoading] = useRecoilState(isResponseLoading);
    const [showBtn, setShowBtn] = React.useState(true);

    const handleClick = async () => {
        try {
            setResponseLoading(true);
            actions.loaderAction();
            const responseData = await axios.get(endPoints.topics, {
                headers: {
                    Authorization: `Bearer ${state?.accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (responseData?.data) actions.topicButtonAction(responseData?.data?.topics);
        } catch (error) {
            actions.stopLoaderAction(error?.response?.data?.detail || 'Please try again later');
        } finally {
            setResponseLoading(false);
        }
        setShowBtn(false);
    };

    return (
        <div display="flex" style={{ marginBottom: '20px' }}>
            {showBtn && (
                <Button variant="contained" style={chatBotStyle.topicButton} onClick={() => handleClick()}>
                    See all the topics I can assist you with
                </Button>
            )}
        </div>
    );
};

TopicButton.propTypes = {
    actions: PropTypes.object,
    state: PropTypes.object,
};

TopicButton.defaultProps = {
    actions: {},
    state: {},
};

export default TopicButton;
