import React from 'react';
import { Stack } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { environmentDetails, sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const Application = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [envDetails, setEnvDetails] = useRecoilState(environmentDetails);
    const [value, setValue] = React.useState(order?.appProfileDetails || null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.appProfile || '');
    const [options, setOptions] = React.useState([]);

    const appProfileDetailsApi = useMutation((param) => axios.get(`${endPoints.ssp.vm.appProfileDetails}/${param?.id}`));

    const appGetApi = useMutation(() =>
        axios.post(`${endPoints.ssp.vm.appProfiles}`, {
            cloudProvider: order?.cloudProvider,
            cloudService: order?.cloudService,
            name: ccInputValue,
        })
    );

    const setAppService = (appProfile, appData) => {
        setOrder({
            ...order,
            appProfile: appProfile?.label,
            appProfileDetails: appProfile,
            entityDetails: appData,
            billingEntity: appData?.billingEntity || null,
            ...(appData?.financialDetails && {
                hCode: appData?.financialDetails,
                hCodeDetails: { label: appData?.financialDetails, value: appData?.financialDetails, projectName: appData?.projectName },
            }),
            ...(!appData?.financialDetails && { hCode: null, hCodeDetails: null }),
            environment: null,
            region: null,
            regionDisplayName: null,
            landingZone: null,
            landingZoneDetails: null,
            hoursOfOperation: null,
            envDetails: undefined,
            tags: [],
            defaultTags: [],
            validationStatus: 'forceValidate',
        });
        setEnvDetails({
            ...envDetails,
            ...(appData?.projectName && { Project: appData?.projectName }),
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appGetApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        resetOptions();
        fetch();
    }, [ccInputValue]);

    React.useEffect(() => {
        if (appGetApi?.isSuccess) {
            if (appGetApi?.data?.data) {
                const combinedValue = appGetApi?.data?.data.map((app) => ({ label: `${app.name}`, value: `${app.name}`, data: app }));
                setOptions(combinedValue);
            }
        }
    }, [appGetApi?.isSuccess]);

    React.useEffect(() => {
        if (value === null) {
            resetOptions();
        }
        if (value !== null && value?.data?.id) {
            setAppService(value, null);
            appProfileDetailsApi.mutate({
                id: value?.data?.id,
            });
        }
    }, [value]);

    React.useEffect(() => {
        if (appProfileDetailsApi?.isSuccess) {
            setAppService({ label: appProfileDetailsApi?.data?.data?.name, value: appProfileDetailsApi?.data?.data?.name }, appProfileDetailsApi?.data?.data);
        }
    }, [appProfileDetailsApi?.isSuccess]);

    React.useEffect(() => {
        if (!order?.appProfile && order?.isTouched) setIsTouched(true);
    }, [order?.appProfile, order?.isTouched]);

    return (
        <>
            <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
                <Select
                    key="applicationName"
                    label="Application *"
                    placeholder="Type to search"
                    isLoading={appGetApi?.isLoading && !order?.appProfileDetails}
                    value={order?.appProfileDetails || null}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue || '');
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(x) => x}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={ProvisioningStyles.costCenter}
                    freeSolo
                    isRequired={isTouched}
                />
                <HelpImg title="Type to Search Application." />
            </Stack>
        </>
    );
};

export default React.memo(Application);
