import React from 'react';
import { Box, Typography, Table, TableBody, TableContainer } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useRecoilValue } from 'recoil';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

import { OrderPayload } from 'pages/project-onboarding/store';

export const ProjectAdministrator = () => {
    const order = useRecoilValue(OrderPayload);

    return (
        <Box display="flex" flexDirection="column" p="0px" width="100%" data-testid="project-administrators">
            <Box display="flex" mt={1}>
                <Typography sx={{ color: '#0047BA', fontWeight: '600' }}>PROJECT ADMINISTRATORS:</Typography>
            </Box>
            <Box>
                <TableContainer sx={ProjectOnboardingStyles.adminList}>
                    <Table size="small">
                        <TableBody>
                            {order?.existingAdminMembers?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">
                                        <Typography>{item}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default React.memo(ProjectAdministrator);
