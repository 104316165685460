import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState, useRecoilValue } from 'recoil';
import { OrderPayload, isAppShortNameValid, isAppShortNameExist, isAppNameValid } from 'pages/reference-implementation/provisioning/RI002/store';
import useDebounce from 'pages/reference-implementation/provisioning/RI002/hooks/use-debounce';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const ApplicationShortName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const appNameValid = useRecoilValue(isAppNameValid);
    const [helperText, setHelperText] = React.useState(false);
    const [validAppShortName, setValidAppShortName] = React.useState('');

    const appShortNameValidationApi = useMutation((payload) => axios.post(endPoints.riServices.RI002.appShortNameValidation, payload));
    const appShortNameApi = useMutation((payload) => axios.post(endPoints.riServices.COMMON.getappShortName, payload));

    const debouncedInputValue = useDebounce(validAppShortName, 500);
    const debouncedApplicationNameValue = useDebounce(order?.applicationName, 500);

    const setAppShortName = (appShortName = null) => {
        setOrder({
            ...order,
            appShortName,
        });
    };

    React.useEffect(() => {
        if (appShortNameValidationApi?.isSuccess) {
            if (appShortNameValidationApi?.isSuccess) {
                setAppShortName(order?.appShortName);
                setAppShortNameExist(false);
            } else {
                setAppShortNameExist(true);
            }
        }
    }, [appShortNameValidationApi?.isSuccess]);

    React.useEffect(() => {
        if (appShortNameApi?.data?.data) {
            setOrder({
                ...order,
                appShortName: appShortNameApi?.data?.data,
            });
        }
    }, [appShortNameApi?.data?.data]);
    React.useEffect(() => {
        if (debouncedApplicationNameValue && order?.appService && appNameValid && order?.applicationName) {
            const payload = {
                applicationName: order?.applicationName,
                applicationService: order?.appService,
            };
            appShortNameApi?.mutate(payload);
        }
    }, [order?.appService, appNameValid, debouncedApplicationNameValue, order?.applicationName]);
    React.useEffect(() => {
        if (
            debouncedInputValue &&
            order?.applicationName &&
            order?.appService &&
            appShortNameApi?.data?.data == null &&
            !appShortNameApi?.isLoading &&
            appShortNameApi?.isSuccess
        ) {
            const payload = {
                applicationName: order?.applicationName,
                applicationShortName: debouncedInputValue,
                applicationService: order?.appService,
            };
            appShortNameValidationApi?.mutate(payload);
        }
    }, [debouncedInputValue, order?.applicationName, order?.appService]);

    React.useEffect(() => {
        if (
            order?.appShortName &&
            order?.appShortName !== null &&
            order?.appShortName !== '' &&
            order?.appShortName !== undefined &&
            !/^[a-zA-Z0-9]{2,8}$/.test(order?.appShortName)
        ) {
            setHelperText(true);
            setAppShortNameVaild(false);
        } else {
            setValidAppShortName(order?.appShortName);
            setHelperText(false);
            setAppShortNameVaild(true);
        }
    }, [order?.appShortName]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.appShortName) ||
            helperText ||
            (appShortNameValidationApi?.error && appShortNameValidationApi?.error?.length)
        ) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.appShortName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appShortName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Application Short Name" isRequired />}
                error={helperText || (appShortNameValidationApi?.error && appShortNameValidationApi?.error?.length)}
                helperText={
                    helperText && (
                        <span>The &apos;ApplicationShortName&apos; must consist of alphanumeric characters and have a length between 2 and 8 characters.</span>
                    )
                }
                value={order?.appShortName || ''}
                onChange={(e) => {
                    setAppShortName(e.target.value);
                }}
                disabled={!appNameValid || !order?.applicationName || appShortNameApi?.data?.data}
                className={validateInput()}
            />
        </Box>
    );
};

export default ApplicationShortName;
