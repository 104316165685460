import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'adf-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'adf-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'adf-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isMandatoryTagsFullfilled = atom({
    key: 'adf-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'adf-orderId',
    default: '',
});

export const isEnvDetailsLoading = atom({
    key: 'adf-isEnvDetailsLoading',
    default: false,
});

export const sspSubmitOrder = atom({
    key: 'adf-sspOrder',
    default: {
        appProfile: null,
        appProfileDetails: null,
        entityDetails: null,
        sector: null,
        environment: null,
        region: null,
        regionDisplayName: null,
        hCode: null,
        hCodeDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        landingZone: null,
        landingZoneDetails: null,
        dataFactoryName: null,
        dataFactoryVersion: null,
        envDetails: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: 1,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: 0,
                currency: 'USD',
            },
            totalRecurringCost: {
                frequency: 'monthly',
                estCost: 0,
                currency: 'USD',
            },
            notes: "ADF Workspace provision doesn't have any cost associated. However, the costing model is based on the Datasets, Linked Services, Pipelines, Integration Runtime, SHIR, Triggers and Debug Runs. Further details please reachout to DL-ITAdvancedAnalyticsPlatformSupport@pepsico.com",
        },
        validationStatus: 'clearValidation',
        isTouched: false,
        isADFNameValid: false,
    },
});

export const environmentDetails = atom({
    key: 'adf-environmentDetails',
    default: {
        subscription: '-',
        resource_group: '-',
        Approval_Manager: '-',
        Project: '-',
    },
});

export const costEstimateState = atom({
    key: 'adf-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'adf-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});

export const isValidEndpoint = atom({
    key: 'adf-isValidEndpoint',
    default: false,
});

export const isValidDataFactoryName = atom({
    key: 'adf-isValidDataFactoryName',
    default: true,
});
