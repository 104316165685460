import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Box, Grid, Stack, TextField } from '@mui/material';
import Modal from 'components/modal';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder, toggleSspModels, isMandatoryTagsFullfilled } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import Btn from 'components/button';

const Tags = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const setIsMandatoryTags = useSetRecoilState(isMandatoryTagsFullfilled);
    const [tags, setTags] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const hoursOfOperationApi = useMutation(() => axios.get(`${endPoints.ssp.vm.hoursOfOperation}`));
    const setHours = (hoursOfOperation = null, hoursOfOperationDetail = null) => {
        setOrder({
            ...order,
            hoursOfOperation,
            hoursOfOperationDetail,
        });
    };
    const seldValueExistsInOptions = (hoursValue) => options.find((option) => hoursValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setHours(selected?.value, selected?.data);
        }
    };
    const onChangeQty = (id, value) => {
        const editEnvTags = tags.map((tag) => {
            let { ...currentTag } = tag;
            if (tag.id === id) {
                currentTag = { ...currentTag, defaultValue: value };
            }
            return currentTag;
        });
        setTags(editEnvTags);
    };
    const isValidBtn = () => {
        const validLength = tags?.filter((tag) => !tag?.defaultValue && tag?.editableOnBlank);
        return !!validLength?.length;
    };
    const isValid = (tag) => {
        isValidBtn();
        return !!(!tag?.defaultValue && tag?.editableOnBlank);
    };
    const handleSaveTags = () => {
        setOrder({ ...order, tags });
        setModal({ ...modal, isTagOpen: false });
    };
    const validate = (isEditable, value) => {
        if (isEditable) {
            return value === '' || value === null || value === undefined ? 'validate' : '';
        }
        return '';
    };
    const handleResetTags = () => {
        if (order?.defaultTags) {
            const tempDefaultTags = [];
            order?.defaultTags.forEach((obj) => {
                if (obj.name === 'Sector') {
                    tempDefaultTags.push({ ...obj, defaultValue: order?.sector || '-' });
                } else if (obj.name === 'Billingentity') {
                    tempDefaultTags.push({ ...obj, defaultValue: order?.billingEntity || '-' });
                } else if (obj.name === 'Costcenter') {
                    tempDefaultTags.push({ ...obj, defaultValue: order?.hCodeDetails?.label || '-' });
                } else {
                    tempDefaultTags.push(obj);
                }
            });
            setTags(tempDefaultTags);
        }
    };
    const setDefaultHours = () => {
        const defaultHours = order?.tags?.find((tag) => tag?.name === 'HoursOfOperation');
        const hours = seldValueExistsInOptions(defaultHours?.defaultValue);
        if (hours) handleOnSelectChange(hours);
    };
    React.useEffect(() => {
        if (hoursOfOperationApi?.isSuccess) {
            setOptions(hoursOfOperationApi?.data?.data?.values.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [hoursOfOperationApi?.isSuccess]);
    React.useEffect(() => {
        hoursOfOperationApi.mutate();
    }, []);
    React.useEffect(() => {
        setTags([]);
    }, [order?.envDetails?.config?.resourceGroup]);
    React.useEffect(() => {
        if (order?.tags.length) {
            const tagsMandateCheck = order?.tags.filter((tag) => tag.defaultValue === '' && tag.editableOnBlank)?.length > 0;
            setIsMandatoryTags(tagsMandateCheck);
        }
        // eslint-disable-next-line
    }, [order?.tags]);
    React.useEffect(() => {
        if (modal?.isTagOpen) {
            const tempTags = [];
            order?.tags.forEach((obj) => {
                if (obj.name === 'Sector') {
                    tempTags.push({ ...obj, defaultValue: order?.sector || '-' });
                } else if (obj.name === 'Billingentity') {
                    tempTags.push({ ...obj, defaultValue: order?.billingEntity || '-' });
                } else if (obj.name === 'Costcenter') {
                    tempTags.push({ ...obj, defaultValue: order?.hCodeDetails?.label || '-' });
                } else {
                    tempTags.push(obj);
                }
            });
            setTags(tempTags);
            setDefaultHours();
        }
    }, [modal?.isTagOpen]);
    return (
        <>
            <Modal
                title="Tags"
                handleClose={() => {
                    setModal({ ...modal, isTagOpen: false });
                    setDefaultHours();
                }}
                open={modal.isTagOpen}
            >
                <Box sx={ProvisioningStyles.tags.wrapper}>
                    <Box sx={ProvisioningStyles.tags.contentWrapper}>
                        {tags && tags?.length && (
                            <>
                                <Box sx={ProvisioningStyles.tags.boxStyle}>
                                    <Grid container spacing={3}>
                                        {tags.map((tag, index) => (
                                            <Grid item md={6} key={index.toString()}>
                                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                                    {tag?.name === 'HoursOfOperation' && (
                                                        <Select
                                                            key="hoursOfOperation"
                                                            value={tag?.defaultValue}
                                                            options={options}
                                                            handleOnSelect={(value) => {
                                                                onChangeQty(tag.id, value?.label);
                                                                handleOnSelectChange(value);
                                                            }}
                                                            disabled={hoursOfOperationApi.isLoading}
                                                            isLoading={hoursOfOperationApi.isLoading}
                                                            sx={ProvisioningStyles.tags.activeSelectField}
                                                            isRequired={tag?.editableOnBlank}
                                                            label={tag.name}
                                                            data-testid="hoursOfOperation"
                                                        />
                                                    )}
                                                    {tag?.name === 'Migration' && (
                                                        <Select
                                                            key="migration"
                                                            value={tag?.defaultValue}
                                                            options={[
                                                                { label: 'Yes', value: 'Yes' },
                                                                { label: 'No', value: 'No' },
                                                            ]}
                                                            handleOnSelect={(value) => {
                                                                onChangeQty(tag.id, value?.label);
                                                            }}
                                                            sx={ProvisioningStyles.tags.activeSelectField}
                                                            isRequired={tag?.editableOnBlank}
                                                            label={tag.name}
                                                        />
                                                    )}
                                                    {tag?.name !== 'HoursOfOperation' && tag?.name !== 'Migration' && (
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            onChange={(e) => onChangeQty(tag.id, e.target.value)}
                                                            disabled={!tag?.editableOnBlank}
                                                            value={tag?.defaultValue}
                                                            error={isValid(tag)}
                                                            className={validate(tag?.editableOnBlank, tag?.defaultValue)}
                                                            label={tag.name}
                                                            sx={ProvisioningStyles.tags.activeSelectField}
                                                        />
                                                    )}
                                                </Stack>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box className="footer" sx={ProvisioningStyles.tags.actionWrapper}>
                        <Btn
                            className="custom-btn"
                            variant="contained"
                            color="cmpPrimary"
                            disabled={isValidBtn()}
                            onClick={() => handleSaveTags()}
                            sx={{ textTransform: 'none' }}
                            data-testid="save"
                        >
                            Save
                        </Btn>
                        <Btn
                            className="custom-btn"
                            sx={ProvisioningStyles.tags.resetBtn}
                            variant="outlined"
                            color="cmpWarning"
                            onClick={() => handleResetTags()}
                            layout="outline"
                            data-testid="reset"
                        >
                            Reset
                        </Btn>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
export default React.memo(Tags);
