import { atom } from 'recoil';

export const toggleSamRequest = atom({
    key: 'eip-toggleSamRequest',
    default: false,
});

export const toggleSspModels = atom({
    key: 'eip-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'eip-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'eip-isMandatoryTagsFullfilled',
    default: false,
});

export const sspSubmitOrder = atom({
    key: 'eip-sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        environment: null,
        appTier: null,
        appTierCode: null,
        region: null,
        regionDisplayName: null,
        landingZone: null,
        landingZoneDetails: null,
        hCode: null,
        hCodeDetails: null,
        sector: null,
        sectorDetails: null,
        domainNameService: 'No',
        eceIntegration: 'No',
        cleIntegration: 'No',
        iTLDAPGroup: null,
        catalogSize: null,
        appSupportMailId: null,
        conjureIntegration: 'No',
        fqdnName: null,
        ipAddress: null,
        sslCertificate: null,
        tags: [],
        estimatedCost: {
            details: [],
            qty: null,
            totalOneTimeCost: {
                frequency: 'one time',
                estCost: null,
                currency: 'USD',
            },

            totalRecurringCost: {
                frequency: 'monthly',
                estCost: null,
                currency: 'USD',
            },
            notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
        },
        catalogCode: null,
        components: null,
        config: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'eip-environmentDetails',
    default: {
        Approval_Manager: '---',
        Project: '---',
    },
});

export const costEstimateState = atom({
    key: 'eip-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'eip-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
export const isValidFqdns = atom({
    key: 'eip-isValidFqdns',
    default: false,
});
export const isValidSafeName = atom({
    key: 'eip-isValidSafeName',
    default: false,
});
export const showPreviewStack = atom({
    key: 'eip-showPreviewStack',
    default: false,
});
export const isValidappSupportMailId = atom({
    key: 'eip-isValidappSupportMailId',
    default: false,
});

export const isValidiTLDAP = atom({
    key: 'eip-isValidiTLDAP',
    default: false,
});

export const deleteServiceResource = atom({
    key: 'eipstack-deleteServiceResource',
    default: {
        isOpen: false,
        id: null,
    },
});
