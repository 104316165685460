import { atom } from 'recoil';

export const riCatalogDialog = atom({
    key: 'riCatalogDialog',
    default: false,
});

export const riSelectedCatalog = atom({
    key: 'riSelectedCatalog',
    default: undefined,
});

export const orderSuccessPopup = atom({
    key: 'ri-orderSuccessPopup',
    default: false,
});

export const orderId = atom({
    key: 'ri-orderId',
    default: undefined,
});
