import React from 'react';
import { Stack } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const Incident = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [showHelperText, setHelperText] = React.useState(false);
    const [showHelperMsg, setHelperMsg] = React.useState('');
    const handleValueChange = (value) => {
        if (value !== order?.incident) {
            setOrder({
                ...order,
                incident: value,
            });
        }
    };
    React.useEffect(() => {
        if (order?.incident && !/^INC[0-9]{8}$/.test(order?.incident)) {
            setHelperText(true);
            setHelperMsg('Incident number should start with "INC" followed by 8 digits number.');
        } else {
            setHelperText(false);
            setHelperMsg('');
        }
    }, [order?.incident]);

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <CssTextField
                autoComplete="off"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                label="Incident Number"
                error={showHelperText}
                helperText={showHelperText && <span style={ProvisioningStyles.disposal.helpText}>{showHelperMsg}</span>}
                value={order?.incident || ''}
                onChange={(e) => handleValueChange(e?.target?.value)}
            />
            <HelpImg title='Incident number should start with "INC" followed by 8 digits number.' />
        </Stack>
    );
};

export default Incident;
