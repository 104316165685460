import React from 'react';
import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import dashboardStyle from 'pages/dashboard/style';
import Card from './components/Card';
import {
    usersCount,
    publishedCatalogsCount,
    applicationCount,
    ordersSubmittedCount,
    ordersFulfilledCount,
    provisionedOrdersCount,
    dateRangeValue,
    dashboardFilter,
} from './store';

const OverallCount = () => {
    const [appCount, setAppCount] = useRecoilState(applicationCount);
    const [userCount, setUsersCount] = useRecoilState(usersCount);
    const [publishedCatalogCount, setPublishedCatalogCount] = useRecoilState(publishedCatalogsCount);
    const [ordersSubmitted, setSubmittedOrdersCount] = useRecoilState(ordersSubmittedCount);
    const [ordersFulfilled, setFulfilledOrdersCount] = useRecoilState(ordersFulfilledCount);
    const [provisionedOrders, setProvisionedOrdersCount] = useRecoilState(provisionedOrdersCount);
    const [dateRange, setDateRange] = useRecoilState(dateRangeValue);
    const [dashboardFilterObj, setDashboardFilterObj] = useRecoilState(dashboardFilter);

    const aggregatedCountQuery = useMutation((payload) => axios.post(endPoints.ssp.dashboard.aggregatedCount, payload));

    React.useEffect(() => {
        let payloadObj = {};
        payloadObj = {
            fromDate: DateTime.fromJSDate(new Date(dateRange?.startDate)).startOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            toDate: DateTime.fromJSDate(new Date(dateRange?.endDate)).endOf('day').toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            ...(dashboardFilterObj?.provider && dashboardFilterObj?.provider !== null && { provider: dashboardFilterObj?.provider }),
            ...(dashboardFilterObj?.service && dashboardFilterObj?.service !== null && { service: `${dashboardFilterObj?.service}*` }),
            ...(dashboardFilterObj?.sector && dashboardFilterObj?.sector !== null && { sector: dashboardFilterObj?.sector }),
            ...(dashboardFilterObj?.region && dashboardFilterObj?.region !== null && { region: dashboardFilterObj?.region }),
        };
        aggregatedCountQuery.mutate(payloadObj);
    }, [dateRange, dashboardFilterObj]);

    React.useEffect(() => {
        if (aggregatedCountQuery?.isSuccess) {
            setAppCount(aggregatedCountQuery?.data?.data?.aggregatedCount?.applicationsOnboarded || 0);
            setUsersCount(aggregatedCountQuery?.data?.data?.aggregatedCount?.users || 0);
            setPublishedCatalogCount(aggregatedCountQuery?.data?.data?.aggregatedCount?.serviceOfferings || 0);
            setSubmittedOrdersCount(aggregatedCountQuery?.data?.data?.aggregatedCount?.ordersSubmitted || 0);
            setFulfilledOrdersCount(aggregatedCountQuery?.data?.data?.aggregatedCount?.ordersFulfilled || 0);
            setProvisionedOrdersCount(aggregatedCountQuery?.data?.data?.aggregatedCount?.provisionedResources || 0);
        } else {
            setAppCount(0);
            setUsersCount(0);
            setPublishedCatalogCount(0);
            setSubmittedOrdersCount(0);
            setFulfilledOrdersCount(0);
            setProvisionedOrdersCount(0);
        }
    }, [aggregatedCountQuery?.isSuccess]);

    React.useEffect(() => {
        const dt = new Date();
        dt.setMonth(dt.getMonth() - 5);
        return () => {
            setDateRange({ startDate: dt.toString(), endDate: new Date() });
            setDashboardFilterObj({ provider: null, service: null, sector: null, region: null });
        };
        // eslint-disable-next-line
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Grid container sx={dashboardStyle?.ApplicationOnBoardStyle?.wrapper} spacing={2}>
                <Card
                    count={appCount}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Applications Onboarded"
                    secondaryText=""
                    tooltip="Number of application profiles configured in CMP"
                />
                <Card
                    count={userCount}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Users"
                    secondaryText=""
                    tooltip="Distinct users requested services from CMP"
                />
                <Card
                    count={publishedCatalogCount}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Service Offerings"
                    secondaryText=""
                    tooltip="Number of cloud services offered through CMP"
                />
                <Card
                    count={ordersSubmitted}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Orders Submitted"
                    secondaryText=""
                    tooltip="Number of orders submitted through CMP"
                />
                <Card
                    count={ordersFulfilled}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Orders Fulfilled"
                    secondaryText=""
                    tooltip="Number of orders completed provisioning through CMP. One order can request multiple resources."
                />
                <Card
                    count={provisionedOrders}
                    isLoading={aggregatedCountQuery.isLoading}
                    text="Provisioned Resources"
                    secondaryText=""
                    tooltip="Number of resources provisioned through CMP. More than one resource can be part of a single order."
                />
            </Grid>
        </ErrorBoundary>
    );
};
export default React.memo(OverallCount);
