import {
    iaasVMCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    paasSQLCatalogServiceCode,
    storageAccountCatalogServiceCode,
    aksCatalogServiceCode,
    iaasCouchbaseServiceCode,
    keyvaultCatalogServiceCode,
    AlbCatalogServiceCode,
    eventhubCatalogServiceCode,
    kafkaCatalogServiceCode,
    eipStackCatalogServiceCode,
    adfCatalogServiceCode,
} from 'utils/catalogServiceCode';

const getServiceEndpoints = (vnetSubnet = [], cloudService) => {
    let result = [];
    if (vnetSubnet?.length === 1) {
        vnetSubnet?.forEach((item) => {
            if (item?.vNet === null || item?.subnet === null || item?.networkSubscription === null || item?.networkSubscription === '') result = [];
            if (item?.vNet !== null && item?.subnet !== null && item?.subnet !== '')
                result = [
                    {
                        networkSubscription: item?.networkSubscription || '',
                        ...(storageAccountCatalogServiceCode === cloudService && {
                            networkSubscriptionId: item?.networkSubscriptionDetails?.id ? item?.networkSubscriptionDetails?.id : '',
                        }),
                        subnet: item?.subnet || '',
                        vnet: { id: item?.vNetDetails?.resourceId || '', name: item?.vNet || '' },
                    },
                ];
        });
    }
    if (vnetSubnet?.length > 1) {
        return vnetSubnet?.map((item) => ({
            networkSubscription: item?.networkSubscription || '',
            ...(storageAccountCatalogServiceCode === cloudService && {
                networkSubscriptionId: item?.networkSubscriptionDetails?.id ? item?.networkSubscriptionDetails?.id : '',
            }),
            subnet: item?.subnet || '',
            vnet: { id: item?.vNetDetails?.resourceId || '', name: item?.vNet || '' },
        }));
    }
    return result;
};
export const getPayload = ({ appName, data, landingZoneId, operation }) => {
    const payload = {
        ...(operation === 'edit' && { id: landingZoneId || '' }),
        appProfileName: appName,
        isDraft: data?.isDraft || false,
        cloudProvider: data?.provider || '',
        cloudService: data?.service || '',
        label: data?.landingZoneLabel || '',
        description: data?.landingZoneDesc || '',
        environment: data?.environment || '',
        region: {
            name: data?.regionDetails?.label || '',
            code: data?.regionDetails?.value || '',
        },
        config: {
            ...((iaasVMCatalogServiceCode === data?.service ||
                iaasOracleCatalogServiceCode === data?.service ||
                iaasSQLCatalogServiceCode === data?.service) && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                vnet: {
                    id: data?.vNetDetails?.resourceId || '',
                    name: data?.vNetDetails?.label || '',
                },
                subnet: data?.subNet || '',
                availabilitySet: data?.availSet || '',
                availabilityZone: data?.availZoneDetails?.map((app) => app?.label) || [],
            }),

            ...(paasSQLCatalogServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',

                vnetSubnet: getServiceEndpoints(data?.vnetSubnet, data?.service),
            }),

            ...(storageAccountCatalogServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                dataClassification: {
                    displayName: data?.dataClassification || '',
                    value: data?.dataClassificationDetails?.value || '',
                },
                vnetSubnet: getServiceEndpoints(data?.vnetSubnet, data?.service),
            }),
            ...(aksCatalogServiceCode === data?.service && {
                department: data?.department || '',
                businessUnit: data?.businessUnit || '',
                cluster: data?.cluster || '',
                // projectName: data?.projectName || '',
                isaDocument: data?.ISADocument || '',
            }),
            ...(iaasCouchbaseServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                vnet: {
                    id: data?.vNetDetails?.resourceId || '',
                    name: data?.vNetDetails?.label || '',
                },
                subnet: data?.subNet || '',
                availabilityZone: data?.availZoneDetails?.map((app) => app?.label) || [],
            }),
            ...(keyvaultCatalogServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                vnet: {
                    id: data?.vNetDetails?.resourceId || '',
                    name: data?.vNetDetails?.label || '',
                },
                subnet: data?.subNet || '',
                dataClassification: {
                    displayName: data?.dataClassification || '',
                    value: data?.dataClassificationDetails?.value || '',
                },
                networkConnectivity: data?.networkConnectivity || '',
                appName: data?.appName || '',
            }),
            ...(AlbCatalogServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                vnet: {
                    id: data?.vNetDetails?.resourceId || '',
                    name: data?.vNetDetails?.label || '',
                },
                subnet: data?.subNet || '',
                sku: data?.sku || '',
                type: data?.type || '',
                appName: data?.appName || '',
            }),
            ...(eventhubCatalogServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                dataClassification: {
                    displayName: data?.dataClassification || '',
                    value: data?.dataClassificationDetails?.value || '',
                },
                geoRecoveryRegion: {
                    name: data?.geoRecoveryRegionDetails?.label || '',
                    id: data?.geoRecoveryRegionDetails?.value || '',
                },
                vnetSubnet: data?.vnetSubnet?.map((item) => ({
                    subnet: item?.subnet || '',
                    vnet: { id: item?.vNetDetails?.resourceId || '', name: item?.vNet || '' },
                })),
            }),
            ...(kafkaCatalogServiceCode === data?.service && {
                department: data?.department || '',
                cluster: data?.cluster || '',
                projectName: data?.projectName || '',
                businessUnit: data?.businessUnit || '',
                ldapGroup: data?.ldap || '',
                isaDocument: data?.ISADocument || '',
            }),
            ...(eipStackCatalogServiceCode === data?.service && {
                dataClassification: {
                    displayName: data?.dataClassification || '',
                    value: data?.dataClassificationDetails?.value || '',
                },
                components: data?.components
                    ?.filter((component) => component?.landingZoneDetails?.id)
                    ?.map((item) => ({
                        ...(operation === 'edit' && { componentId: item?.componentId }),
                        landingzoneId: item?.landingZoneDetails?.id,
                        name: item?.name,
                        provider: item?.provider,
                        service: item?.service,
                        optional: item?.optional,
                    })),
                connections: data?.connections
                    ?.filter((connection) => data?.components?.find((component) => component?.name === connection?.targetComponentId)?.landingZoneDetails?.id)
                    ?.map((item) => ({
                        connectionType: item?.connectionType,
                        name: item?.name,
                        sourceComponent: item?.sourceComponentId,
                        targetComponent: item?.targetComponentId,
                    })),
            }),
            ...(adfCatalogServiceCode === data?.service && {
                subscription: {
                    id: data?.subscriptionDetails?.id || '',
                    name: data?.subscriptionDetails?.label || '',
                },
                resourceGroup: data?.resGroup || '',
                dataClassification: {
                    displayName: data?.dataClassification || '',
                    value: data?.dataClassificationDetails?.value || '',
                },
            }),
        },
    };
    return payload;
};
export default getPayload;
