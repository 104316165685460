import { styled } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';

const landingStyle = {
    bannerbox: { height: { md: '300px', lg: '400px', xl: '600px' } },
    title: { color: (theme) => theme.palette.white },
    paper: {
        padding: (theme) => theme.spacing(2),
        color: (theme) => theme.palette.text.secondary,
        height: (theme) => theme.spacing(45),
    },
    image: {
        width: (theme) => theme.spacing(16),
        height: (theme) => theme.spacing(16),
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: (theme) => theme.spacing(182),
        maxHeight: (theme) => theme.spacing(45),
    },
    btn: {
        textTransform: 'capitalize',
    },
    service: {
        marginTop: (theme) => theme.spacing(1),
        border: 0,
        '&:hover a': {
            color: (theme) => theme.palette.white,
            backgroundPosition: 'left',
        },
    },

    landingWraper: {
        display: 'flex',
        flexDirection: 'column',
        //  overflow: 'auto',
        width: '100%',
        backgroundColor: '#001C4A',
        // width: { md: (theme) => theme.spacing(182), lg: (theme) => theme.spacing(182), xl: (theme) => theme.spacing(200) },
        // background: 'transparent linear-gradient(179deg, #004E92 0%, #000428 100%) 0% 0% no-repeat padding-box',
    },
    loadingStack: { width: (theme) => theme.spacing('100%'), height: 'calc(100vh - 100px)' },
    catalogDivHeight: {
        height: (theme) => theme.spacing(45),
    },
    cardmediawrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    paperHeight: { height: (theme) => theme.spacing(27) },
    catalogWraper: { display: 'flex', flexDirection: 'column', height: (theme) => theme.spacing(45), position: 'relative' },

    multicatalog: {
        position: 'static',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        margin: '0px',
        padding: '0px',
        marginLeft: '15px',
    },
    roles: {
        rightwrapper: {
            backgroundColor: '#FFFFFF',
            padding: { md: '0px 51px 13px', lg: '30px 50px 38px 60px' },
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        benfitsTypo: { color: '#001C4A', fontSize: { md: 30, lg: 45 }, fontFamily: 'Stolzl', fontWeight: 'bold', mt: { md: 15, lg: 2 } },
        expertise: { color: '#001C4A', fontSize: 25, fontFamily: 'Stolzl', fontWeight: 400, mt: { md: 5, lg: 2 } },
        leftwrapper: { backgroundColor: '#FFFFFF', width: '60%' },
        griditem: { display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 },
        boxIcon: { backgroundColor: '#001C4A', padding: 1.5, borderRadius: '50%' },
        msgicon: { width: '50%', color: '#001C4A', fontSize: '15px', fontWeight: 600, mt: 1, textAlign: 'center' },
        utilizationmsg: { width: '75%', color: '#001C4A', fontSize: '15px', fontWeight: 600, ml: 2, mt: 1 },
        numberdiv: { fontSize: '150px', color: '#1AAAE0', marginLeft: '35px', marginTop: '-15px', fontFamily: 'Stolzl' },
    },
};

export const catalogServiceCardstyle = {
    link: {
        '&.MuiLink-root': {
            fontWeight: 900,
            marginTop: '30% !important',
        },
    },
    serviceOffering: { fontFamily: 'Stolzl !important' },
    catalogServiceWraper: { height: '340px', width: '290px', position: 'relative', border: '2px solid #00A0DD' },
    catalogServiceAllWraper: {
        position: 'relative',
        width: '280px',
        maxWidth: '280px',
        height: '340px',
        mr: 'auto',
        transition: 'box-shadow 0.5s',
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
        },
    },
    boxStyle: { padding: (theme) => theme.spacing(1.875) },
    landboxStyle: { padding: (theme) => theme.spacing('25px') },

    landinnerboxStyle: {
        display: 'flex',
        flexDirection: 'row',
        // paddingBottom: (theme) => theme.spacing(2),
        alignItems: 'center',
    },
    innerboxStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: (theme) => theme.spacing('38px'),
    },
    spanStyle: { marginRight: '15px' },
    catalogTitle: {
        fontSize: '16px',
        fontFamily: 'Stolzl',
        color: '#1E1E1E',
        height: '3em',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },
    landtitleTypo: {
        fontSize: '16px',
        fontFamily: 'Stolzl',
        color: '#1E1E1E',
        paddingTop: (theme) => theme.spacing(0.5),
        marginRight: (theme) => theme.spacing(0.5),
        height: '35px',
        width: { md: '205px', lg: '205px', xl: '320px' },
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    titleTypo: {
        fontSize: '16px',
        fontFamily: 'Stolzl',
        color: '#1E1E1E',
        marginLeft: '10px',
    },
    dividerStyle: { color: '#CCCCCC', marginY: (theme) => theme.spacing(1.875) },

    landdescTypo: {
        color: (theme) => theme.palette.black,
        fontSize: '16px',
        opacity: '1',
        fontFamily: 'Roboto',
        height: (theme) => theme.spacing(7.875),
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    descTypo: {
        color: (theme) => theme.palette.black,
        fontSize: '12px',
        opacity: '1',
        fontFamily: 'Roboto',
        height: (theme) => theme.spacing(8.2),
        lineHeight: '21px',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        mb: (theme) => theme.spacing(1.5),
    },
    tagOuterBox: { display: 'flex', mb: (theme) => theme.spacing(0.5), height: (theme) => theme.spacing(6.3) },
    provisionserviceButton: { display: 'flex', justifyContent: 'center', bottom: '6px', position: 'absolute', alignItems: 'center' },
    provisionServiceInnerButton: {
        color: (theme) => theme.palette.blue.main,
        borderWidth: (theme) => theme.spacing(0.2),
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.blue.main,
        textTransform: 'none',
        width: 'fit-content',
        padding: { md: '5px 55px', lg: '5px 57px', xl: '6px 98px' },
        '&:hover': {
            backgroundColor: (theme) => theme.palette.blue.main,
            color: (theme) => theme.palette.white,
        },
    },
    provisionServiceAllInnerButton: {
        color: (theme) => theme.palette.blue.main,
        marginBottom: (theme) => theme.spacing('2px'),
        borderWidth: (theme) => theme.spacing(0.2),
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.blue.main,
        textTransform: 'none',
        width: '100%',
        height: '39px',
        '&:hover': {
            borderWidth: (theme) => theme.spacing(0.2),
            borderColor: '#00A0DD',
            color: '#00A0DD',
        },
    },
    provisionBox: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    moreMsgBtn: { m: 0.3, borderRadius: 1, minWidth: '10px' },
    titlediv: { padding: '4px' },
    tooltipdiv: { padding: '1px' },
    tagsBox: { marginLeft: '15px', width: (theme) => theme.spacing('90%'), display: 'flex', flexWrap: 'wrap' },
    tagsButton: { m: 0.3, borderRadius: 1 },
    tagsinnerwraper: { width: (theme) => theme.spacing('10%') },
    tagscontainer: { display: 'flex', mb: (theme) => theme.spacing(1.5) },
    provisionButton: { display: 'flex', justifyContent: 'center', marginTop: (theme) => theme.spacing(3.25), alignItems: 'center' },

    provisionInnerButton: {
        color: '#0047BA',
        borderWidth: (theme) => theme.spacing(0.2),
        borderStyle: 'solid',
        borderColor: '#0047BA',
        textTransform: 'none',
        width: '100%',
        // padding: { md: '3px 30px', lg: '5px 59px', xl: '6px 100px' },
        // left:{lg:'10px',xl:"5px"},
        '&:hover': {
            borderWidth: (theme) => theme.spacing(0.2),
            borderStyle: 'solid',
            borderColor: '#00A0DD',
            color: '#00A0DD',
            backgroundColor: 'transparent !important',
        },
    },
    placeholderloader: { padding: '20px 25px' },
    sserviceofferings: { fontWeight: 'bolder', fontSize: 15, color: '#00A0DD' },
    catalogImage: { width: '38px', height: '38px' },
    tagButton: { m: (theme) => theme.spacing(0.3), borderRadius: (theme) => theme.spacing(0.4) },
    servicesContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' },
};
export const catalogServiceStyle = {
    catalogServiceGrid: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: { md: 'center', lg: 'flex-start' },
        mx: (theme) => theme.spacing(0.5),
        marginLeft: (theme) => theme.spacing(3),
    },
    catalogServiceGridItem: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: (theme) => theme.spacing(1) },
    title: {
        color: (theme) => theme.palette.white,
        fontSize: '28px',
        display: 'flex',
        justifyContent: 'center',
        mt: (theme) => theme.spacing('30px'),
        mb: (theme) => theme.spacing('25px'),
        fontFamily: 'Stolzl',
    },
    catalogContainer: {
        display: { md: 'flex', lg: 'flex', xl: 'flex' },
        flexWrap: { md: 'wrap', lg: 'wrap', xl: 'wrap' },
        justifyContent: { md: 'space-evenly', lg: 'space-between', xl: 'space-even' },
    },
    catalogCardContainer: { mb: (theme) => theme.spacing(2), marginRight: (theme) => theme.spacing(2) },
    circleProgressContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    circleProgessColor: { color: (theme) => theme.palette.white },
    bottomMsgContainer: { textAlign: 'center', color: '#FFFFFF', marginTop: 5 },
    link: { color: '#ffffff', textDecorationColor: '#FFFFFF', fontWeight: 900, fontFamily: 'Stolzl' },
    multiCarouselWrapperdiv: { display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 2 },
    slidercontainer: { width: '80%' },
    servicesContainer: { marginTop: 1, textAlign: 'center', marginBottom: (theme) => theme.spacing('40px') },
    bottomMsg: { fontSize: 18, textTransform: 'none' },
    bottomMsgSpan: { color: '#00A0DD' },
    compareMasgWrapper: { marginTop: 2 },
    compareMsgTypo: { marginTop: 2 },
    nextArrow: { color: '#FFFFFF', fontSize: 35 },
    prevarrow: {
        color: '#FFFFFF',
        fontSize: 35,
        '&.MuiIcon-root:hover': {
            color: '#FFFFFF',
        },
    },
};
export const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.blue[90],
    cursor: 'unset',
    color: theme.palette.blue.main,
    height: theme.spacing(3),
    fontSize: theme.spacing(1.2),
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.spacing(0.8),
    '&:hover': {
        background: theme.palette.blue['90'],
        color: theme.palette.blue.main,
    },
}));
export const ColorButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(to left, #ffffff 50%, #91C24C 50%) right',
    backgroundSize: theme.spacing(13),
    transition: '.5s ease-out',
    borderRadius: theme.spacing(0),
    color: theme.palette.cyan.main,
    boxShadow: 'none',
    fontSize: theme.spacing(10),
}));
export const latestOrderStyle = {
    mainGrid: { marginBottom: (theme) => theme.spacing(1) },
    mainBox: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
    viewAllHref: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: (theme) => theme.spacing.blue['40'],
        cursor: 'pointer',
        textDecoration: 'none',
        marginLeft: 'auto',
    },
    lanchIconStyle: {
        color: '#ffffff',
        fontSize: (theme) => theme.spacing(13),
        fontWeight: (theme) => theme.spacing(75),
        marginLeft: (theme) => theme.spacing(0.5),
    },
    errorBoundaryStyle: { marginTop: (theme) => theme.spacing(10) },
    cursorStyle: { cursor: 'pointer' },
    emptyWidth: { width: 120 },
};
export const CatalogServiceWraper = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: { md: 'center', lg: 'flex-start' },
    [theme.breakpoints.only('md')]: {
        marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(0.5),
    },
    [theme.breakpoints.up('xl')]: {
        mx: theme.spacing(0.5),
        marginLeft: theme.spacing(3),
    },
    // [theme.breakpoints.up('xl')]: {
    //     mx:theme.spacing(0.5),
    //     marginLeft:theme.spacing(5)
    // },
}));
export const CatalogServiceTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.white,
    fontSize: theme.spacing(3.6),
    display: 'flex',
    justifyContent: 'center',
    my: 1,
    mb: 2,
    fontWeight: 500,
    // [theme.breakpoints.only('md')]: {
    //     marginTop: theme.spacing(2),
    // },
    // [theme.breakpoints.down('md')]: {
    //     marginLeft: theme.spacing(3.3),
    // },
    // [theme.breakpoints.up('lg')]: {
    //     mx:theme.spacing(0.5),
    // },
    // [theme.breakpoints.up('xl')]: {
    //     mx:theme.spacing(0.5),
    //     marginLeft:theme.spacing(3)
    // },
}));
export const multicart = {
    position: 'static !important',
    display: 'flex !important',
    flexDirection: 'column !important',
    overflow: 'hidden !important',
    margin: '0px !important',
    padding: '0px !important',
    marginLeft: '15px !important',
};

export const CustomTagButton = styled(Button)(() => ({
    backgroundColor: '#2157B8',
    cursor: 'unset',
    color: '#FFFFFF',
    height: 16,
    fontSize: '10px !important',
    fontWeight: 400,

    px: 0,
    borderRadius: 0.5,
    '&:hover': {
        background: '#2157B8',
        color: '#FFFFFF',
    },
}));

export const CustomTagMoreButton = styled(Button)(() => ({
    backgroundColor: '#2157B8',
    cursor: 'unset',
    color: '#FFFFFF',
    height: 16,
    width: 16,
    fontSize: 10,
    fontWeight: 400,

    px: 0,
    borderRadius: 0.5,
    '&:hover': {
        background: '#2157B8',
        color: '#FFFFFF',
    },
}));

export const NewCustomTagButton = styled(Button)(() => ({
    backgroundColor: '#FFFFFF',
    cursor: 'unset',
    color: '#2157B8',
    height: 25,
    fontSize: '12px !important',
    fontWeight: 400,
    fontFamily: 'Stolzl !important',
    textAlign: 'center',
    minWidth: 'unset',
    padding: '5px 10px !important',
    px: 0,
    borderRadius: 0.5,
    '&:hover': {
        background: '#2157B8',
        color: '#FFFFFF',
    },
}));

export const NewCustomTagMoreButton = styled(Button)(() => ({
    backgroundColor: '#FFFFFF',
    cursor: 'unset',
    color: '#2157B8',
    height: 25,
    width: 25,
    fontSize: 12,
    fontWeight: 400,

    px: 0,
    borderRadius: 0.25,
    '&:hover': {
        background: '#2157B8',
        color: '#FFFFFF',
    },
}));
export default landingStyle;
