/* This is list of order status in CMP, Don't change the order of array, add new once at last */
import { FAILED, RUNNING, SUCCESS } from 'utils/ValidationStatus';

export const orderStatus = [
    { name: 'Pending Approval', statusCode: 'PendingApproval' },
    { name: 'Provisioning', statusCode: 'Provisioning' },
    { name: 'Cancelled', statusCode: 'Cancelled' },
    { name: 'Rejected', statusCode: 'Rejected' },
    { name: 'Completed', statusCode: 'Completed' },
    { name: 'User Action Required', statusCode: 'ReturnToUser' },
    { name: 'Provisioning Completed', statusCode: 'ProvisioningComplete' },
    { name: 'in-progress', statusCode: 'INPROGRESS' },
    { name: `Validation ${RUNNING}`, statusCode: 'ValidationRunning' },
    { name: `Validation ${SUCCESS}`, statusCode: 'ValidationSuccess' },
    { name: `Validation ${FAILED}`, statusCode: 'ValidationFailed' },
    { name: 'Failed', statusCode: 'Failed' },
];
export default orderStatus;
