import {
    aksCatalogServiceCode,
    AlbCatalogServiceCode,
    eipStackCatalogServiceCode,
    eventhubCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    iaasVMCatalogServiceCode,
    kafkaCatalogServiceCode,
    keyvaultCatalogServiceCode,
    paasSQLCatalogServiceCode,
    privateEndPointCatalogServiceCode,
    storageAccountCatalogServiceCode,
    iaasCouchbaseServiceCode,
    adfCatalogServiceCode,
} from 'utils/catalogServiceCode';

export const getLandingZoneData = ({ appName, data }) => {
    const getServiceEndpoints = (vnetSubnet = []) => {
        if (vnetSubnet?.length <= 0) {
            return [
                {
                    networkSubscription: null,
                    networkSubscriptionDetails: null,
                    subnet: null,
                    vNet: null,
                    vNetDetails: { label: null, resourceId: null },
                },
            ];
        }
        if (vnetSubnet?.length >= 1) {
            return vnetSubnet?.map((item) => ({
                networkSubscription: item?.networkSubscription,
                networkSubscriptionDetails: item?.networkSubscriptionDetails,
                subnet: item?.subnet,
                vNet: item?.vnet?.name,
                vNetDetails: { label: item?.vnet?.name, resourceId: item?.vnet?.id },
            }));
        }
        return [];
    };

    return {
        id: data?.id,
        isDraft: data?.isDraft,
        appProfileName: appName,
        cloudProvider: data?.cloudProvider,
        cloudService: data?.cloudService,
        landingZoneLabel: data?.label,
        landingZoneDesc: data?.description,
        environment: data?.environment,
        region: data?.region.name,
        regionDetails: {
            label: data?.region?.name,
            value: data?.region?.code,
        },
        ...((iaasVMCatalogServiceCode === data?.cloudService ||
            iaasOracleCatalogServiceCode === data?.cloudService ||
            iaasSQLCatalogServiceCode === data?.cloudService) && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            availSet: data?.config?.availabilitySet,
            availZone: data?.config?.availabilityZone,
            availZoneDetails: data?.config?.availabilityZone?.map((app) => ({ label: app, value: app })),
        }),
        ...(iaasCouchbaseServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            availZone: data?.config?.availabilityZone,
            availZoneDetails: data?.config?.availabilityZone?.map((app) => ({ label: app, value: app })),
        }),
        ...((iaasVMCatalogServiceCode === data?.cloudService ||
            iaasOracleCatalogServiceCode === data?.cloudService ||
            iaasSQLCatalogServiceCode === data?.cloudService) && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            availSet: data?.config?.availabilitySet,
            availZone: data?.config?.availabilityZone,
            availZoneDetails: data?.config?.availabilityZone?.map((app) => ({ label: app, value: app })),
            appName: data?.config?.appName,
        }),
        ...(paasSQLCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vnetSubnet: getServiceEndpoints(data?.config?.vnetSubnet),
        }),
        ...(storageAccountCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            vnetSubnet: getServiceEndpoints(data?.config?.vnetSubnet),
        }),
        ...(aksCatalogServiceCode === data?.cloudService && {
            department: data?.config?.department,
            businessUnit: data?.config?.businessUnit,
            cluster: data?.config?.cluster,
            // projectName: data?.config?.projectName,
            ISADocument: data?.config?.isaDocument,
        }),
        ...(keyvaultCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: { label: data?.config?.vnet?.name, resourceId: data?.config?.vnet?.id },
            subNet: data?.config?.subnet,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            networkConnectivity: data?.config?.networkConnectivity,
            appName: data?.config?.appName,
        }),
        ...(kafkaCatalogServiceCode === data?.cloudService && {
            department: data?.config?.department,
            cluster: data?.config?.cluster,
            projectName: data?.config?.projectName,
            businessUnit: data?.config?.businessUnit,
            ldap: data?.config?.ldapGroup,
            ISADocument: data?.config?.isaDocument,
        }),
        ...(AlbCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            sku: data?.config?.sku,
            type: data?.config?.type,
            appName: data?.config?.appName,
        }),
        ...(privateEndPointCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            endPointType: data?.config?.type,
            appName: data?.config?.appName,
        }),
        ...(eventhubCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            geoRecoveryRegion: data?.config?.geoRecoveryRegion?.name,
            geoRecoveryRegionDetails: {
                label: data?.config?.geoRecoveryRegion?.name,
                value: data?.config?.geoRecoveryRegion?.id,
            },
            vnetSubnet: data?.config?.vnetSubnet?.map((item) => ({
                subNet: item?.subnet,
                vNet: item?.vnet?.name,
                vNetDetails: { label: item?.vnet?.name, resourceId: item?.vnet?.id },
            })),
        }),
        ...(eipStackCatalogServiceCode === data?.cloudService && {
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            components: data?.config?.components?.map((item) => ({
                componentId: item?.componentId,
                landingZone: null,
                landingZoneDetails: { id: item?.landingzoneId },
                name: item?.name,
                provider: item?.provider,
                service: item?.service,
                optional: item?.optional,
            })),
            connections: data?.config?.connections?.map((item) => ({
                connectionType: item?.connectionType,
                connectionTypeDetails: { id: item?.connectionType },
                name: item?.name,
                sourceComponentId: item?.sourceComponent,
                sourceComponentName: data?.config?.components?.find((component) => component?.name === item?.sourceComponent)?.service,
                targetComponentId: item?.targetComponent,
                targetComponentName: data?.config?.components?.find((component) => component?.name === item?.targetComponent)?.service,
                optional: data?.config?.components?.find((component) => component?.name === item?.targetComponent)?.optional,
            })),
        }),
        ...(adfCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
        }),
    };
};
export default getLandingZoneData;
