/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Switch as SwitchMui } from '@mui/material';

export const CssSwitchField = styled(SwitchMui)({
    '& .Mui-checked': {
        '& + .MuiSwitch-track': {
            backgroundColor: '#91C24C !important',
        },
        color: '#23bf58 !important',
        transform: 'translateX(26px) !important',
    },
    '& .MuiSwitch-switchBase': {
        padding: '1px',
    },
    '& .MuiSwitch-thumb': {
        color: '#ffffff',
        width: '19px',
        height: '17px',
        margin: '1px',
        borderRadius: '3px',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#C8C8C8',
        opacity: '1 !important',
        '&:after, &:before': {
            color: 'white',
            fontSize: '10px',
            position: 'absolute',
            top: '5px',
        },
        '&:after': {
            content: "'ON'",
            left: '7px',
        },
        '&:before': {
            content: "'OFF'",
            right: '3px',
        },
        borderRadius: '3px',
    },
});

const Switch = (props) => {
    const { handleChange, ...rest } = props;
    return <CssSwitchField data-testid="switch" sx={{ width: '49px', height: '21px', padding: '0px' }} onChange={handleChange} {...rest} />;
};

Switch.propTypes = {
    handleChange: PropTypes.func,
};

Switch.defaultProps = {
    handleChange: undefined,
};

export default Switch;
