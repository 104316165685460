// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, Box, Alert, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Modal from 'components/modal';
import { customToast } from 'api/utils';
import { OrderPayload, toggleModal, isPrivilegedADGroupValid } from 'pages/reference-implementation/provisioning/RI003/store';
import { addContributorADGroup, removeContributorADGroup } from 'pages/reference-implementation/provisioning/RI003/store/selector';
import { RI003Styles } from 'pages/reference-implementation/provisioning/RI003/style';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import ADGroupContributor from './ADGroupContributor';

const PrivilegedADGroupModal = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [openInfo, setOpenInfo] = React.useState(true);
    const addGroup = useSetRecoilState(addContributorADGroup);
    const removeGroup = useSetRecoilState(removeContributorADGroup);
    const [lastModifiedPG, setLastModifiedPG] = React.useState(undefined);
    const [emptyContributorList, setEmptyContributorList] = React.useState([]);
    const [, setPrivilegedADGroupValid] = useRecoilState(isPrivilegedADGroupValid);

    const adGroupValidationApi = useMutation((payload) => axios.post(endPoints.riServices.COMMON.adGroupValidation, payload));

    const isFormValid = () => {
        let errorCount = 0;
        let isContributorADGroupValid = false;
        if (order?.privilegedADGroupList?.length) {
            order?.privilegedADGroupList?.forEach((adGroup) => {
                if (adGroup && (adGroup?.contributorValue === '' || adGroup?.contributorValue === null)) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isContributorADGroupValid = errorCount === 0;
        return isContributorADGroupValid;
    };
    const isDuplicateADGroup = () => {
        const contributorADGroupArray = order?.privilegedADGroupList?.map((item) => item?.contributorValue);
        return new Set(contributorADGroupArray).size !== contributorADGroupArray.length;
    };

    React.useEffect(() => {
        if (isOpen?.isPrivilegedADGroupOpen) {
            setLastModifiedPG(order?.privilegedADGroupList);
            setOpenInfo(true);
        }
    }, [isOpen?.isPrivilegedADGroupOpen]);

    React.useEffect(() => {
        if (adGroupValidationApi?.isSuccess) {
            if (adGroupValidationApi?.data?.isSuccess) {
                if (!isFormValid() || isDuplicateADGroup()) {
                    customToast(['AD Group cannot be empty or duplicate.']);
                } else {
                    setPrivilegedADGroupValid(false);
                    setTimeout(() => {
                        setPrivilegedADGroupValid(true);
                    }, 1000);
                    setIsOpen({ ...isOpen, isPrivilegedADGroupOpen: false });
                }
            }
        }
    }, [adGroupValidationApi?.isSuccess]);

    React.useEffect(() => {
        if (adGroupValidationApi?.isError && adGroupValidationApi?.error) {
            const errorMessage = Array.isArray(adGroupValidationApi?.error) ? adGroupValidationApi?.error : [adGroupValidationApi?.error?.message];
            if (!isFormValid() || isDuplicateADGroup()) {
                errorMessage.push('AD Group cannot be empty or duplicate.');
            }
            customToast(errorMessage);
        }
    }, [adGroupValidationApi?.isError]);

    const handleSubmit = async () => {
        const emptyArr = [];
        order?.privilegedADGroupList.forEach((z, i) => {
            if (z?.contributorValue === '' || z?.contributorValue === null) {
                emptyArr.push(i);
            }
        });
        setEmptyContributorList(emptyArr);
        const adGroups = order?.privilegedADGroupList?.map((z) => z.contributorValue);
        if (adGroups?.length && !adGroups?.some((z) => z === '' || z === null)) {
            const payload = { adGroups, isPrivileged: true };
            adGroupValidationApi?.mutate(payload);
        } else if (!isFormValid() || isDuplicateADGroup()) {
            customToast(['AD Group cannot be empty or duplicate.']);
        }
    };

    const updateEmptyIndexList = (indexVal) => {
        setEmptyContributorList(emptyContributorList?.filter((z) => z !== indexVal));
    };

    return (
        <Modal
            title="Privileged Active Directory Groups"
            handleClose={() => {
                setIsOpen({ ...isOpen, isPrivilegedADGroupOpen: false });
                setOrder({
                    ...order,
                    privilegedADGroupList: lastModifiedPG,
                });
            }}
            open={isOpen.isPrivilegedADGroupOpen}
        >
            <Box sx={RI003Styles.modal.wrapper}>
                <Box sx={{ ...RI003Styles.modal.contentWrapper, paddingTop: '0px' }}>
                    <Grid container spacing={4} sx={{ marginBottom: '10px' }}>
                        <Grid item xs={12} sm={1} />
                        <Grid item xs={12} sm={10}>
                            {openInfo && (
                                <Alert
                                    icon={false}
                                    severity="info"
                                    color="info"
                                    sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenInfo(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Maximum allowed Active Directory Groups are 10
                                </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={1} />
                    </Grid>

                    {order?.privilegedADGroupList?.map((item, index) => (
                        <Box key={index.toString()} sx={RI003Styles.modal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={RI003Styles.modal.groupWrapper}>
                                <React.Fragment key={index.toString()}>
                                    <ADGroupContributor
                                        indexVal={index}
                                        validationData={adGroupValidationApi?.data?.messages || adGroupValidationApi?.error}
                                        indexList={emptyContributorList}
                                        updateEmptyList={updateEmptyIndexList}
                                    />
                                </React.Fragment>
                            </Box>
                            <Box sx={RI003Styles.modal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {index !== 0 && order?.privilegedADGroupList?.length > 1 && (
                                        <Box sx={RI003Styles.modal.deleteWrapper}>
                                            <FaTrashAlt
                                                style={RI003Styles.modal.delete}
                                                onClick={() => removeGroup(item)}
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                                data-testid="removeIcon"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.privilegedADGroupList.length - 1 && order?.privilegedADGroupList?.length < 10 ? (
                                        <MdAdd
                                            style={RI003Styles.modal.add}
                                            onClick={() => {
                                                addGroup({ count: 1 });
                                            }}
                                            className="w-7 h-7 text-blue-base cursor-pointer"
                                            data-testid="addIcon"
                                        />
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={RI003Styles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary2"
                        onClick={() => {
                            handleSubmit();
                        }}
                        data-testid="save-btn"
                    >
                        {adGroupValidationApi?.isLoading ? <CircularProgress color="inherit" size={25} data-testid="save-loader" /> : 'Save'}
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={RI003Styles.modal.cancelBtn}
                        onClick={() => {
                            setIsOpen({ ...isOpen, isPrivilegedADGroupOpen: false });
                            setOrder({
                                ...order,
                                privilegedADGroupList: lastModifiedPG,
                            });
                        }}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default PrivilegedADGroupModal;
