/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';

const EventHubSubNet = ({ index }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const subNetApi = useMutation((params) => axios.get(endPoints.landingZones.Subnet, { params: params }));

    const setEventHubSubNet = (subnet, subnetDetails) => {
        const form = { ...landingZoneForm };
        try {
            const result = form?.vnetSubnet?.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        subnet: subnet !== '' && subnet !== null && subnet !== undefined ? subnet : null,
                        subnetDetails: subnetDetails !== '' && subnetDetails !== null && subnetDetails !== undefined ? subnetDetails : null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    const seldValueExistsInOptions = (value) => options.find((option) => value === option?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEventHubSubNet(selected?.label, selected);
        }
    };

    const resetOptions = () => {
        setOptions([]);
        setEventHubSubNet(null, null);
    };

    React.useEffect(() => {
        if (subNetApi?.isSuccess) {
            setOptions(subNetApi?.data?.data?.length && subNetApi?.data?.data[0].subnets.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [subNetApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.vnetSubnet?.[index]?.subNet) {
            const subnet = options.find((value) => landingZoneForm?.vnetSubnet[index]?.subNet === value?.label);
            if (subnet) setEventHubSubNet(subnet?.label, subnet);
        }
    }, [options]);

    React.useEffect(() => {
        if (landingZoneForm?.vnetSubnet?.[index]?.vNet && landingZoneForm?.subscription) {
            const params = { name: landingZoneForm?.vnetSubnet?.[index]?.vNet, subscriptionName: landingZoneForm?.subscription };
            subNetApi.mutate(params);
        } else if (!landingZoneForm) {
            resetOptions();
        }
    }, [landingZoneForm?.vnetSubnet?.[index]?.vNet, landingZoneForm?.subscription]);

    React.useEffect(() => {
        if (landingZoneForm?.vNet?.name && landingZoneForm?.subscription?.name && landingZoneForm) {
            subNetApi.mutate({ name: landingZoneForm?.vNet?.name, subscriptionName: landingZoneForm?.subscription?.name });
        }
    }, [landingZoneForm?.vNet?.name, landingZoneForm?.subscription?.name]);

    React.useEffect(() => {
        if (landingZoneForm?.isTouched && !landingZoneForm?.vnetSubnet?.[index]?.subnet) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.vnetSubnet, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="SubNet"
                value={landingZoneForm?.vnetSubnet?.[index]?.subnet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subNetApi.isLoading || landingZoneForm?.dataClassification === 'Restricted'}
                isLoading={subNetApi.isLoading}
                disableClearable={false}
                label="Subnet *"
                isRequired={isTouched}
            />
        </Box>
    );
};

EventHubSubNet.propTypes = {
    index: PropTypes.number,
};

EventHubSubNet.defaultProps = {
    index: 0,
};

export default React.memo(EventHubSubNet);
