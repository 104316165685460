/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { islandingZoneLabelVaild, landingZoneData } from 'pages/app-profiles/store';
import LandingZoneStyle from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';

const LandingZoneLabel = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [isValid, setLabelVaild] = useRecoilState(islandingZoneLabelVaild);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [ShrinkField, setShrinkField] = React.useState(false);
    const setLabel = (landingZoneLabel = null) => {
        setLandingZoneForm({
            ...landingZoneForm,
            landingZoneLabel,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 100 || value?.length < 3) {
                setLabelVaild(false);
                setLabel(value);
            } else if (!/^[a-zA-Z0-9-]+$/.test(value)) {
                setLabelVaild(false);
                setLabel(value);
            } else {
                setLabelVaild(true);
                setLabel(value);
            }
        } else {
            setLabelVaild(false);
            setLabel();
        }
    };

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landingZoneForm?.landingZoneLabel?.length > 0);
    }, [landingZoneForm?.landingZoneLabel]);

    const validateInput = () => {
        if ((isTouched && landingZoneForm?.isTouched && !landingZoneForm?.landingZoneLabel) || !isValid) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!landingZoneForm?.landingZoneLabel && landingZoneForm?.isTouched) {
            setIsTouched(true);
        }
    }, [landingZoneForm?.landingZoneLabel, landingZoneForm?.isTouched]);

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Label*"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landingZoneForm?.landingZoneLabel || ''}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'Landing zone label should be min. 3 to max. 100 chars and alphabets only' : ''}
                `}
                    className={validateInput()}
                />
                <Box sx={LandingZoneStyle?.helptext}>
                    <InfoImg title="Label should be unique, which enables you create multiple landing zones with different configurations." />
                </Box>
            </Stack>
        </>
    );
};

export default React.memo(LandingZoneLabel);
