/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, YAxis, XAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { Typography, Box, CircularProgress, Stack } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { getEnv, titleCase } from 'utils';
import dashboardStyle from 'pages/dashboard/style';
import Chart from './components/Chart';
import { orderCreatedWithCMP } from './store';
import { provisionedResourceQuery } from './utils';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};

const ProvisionedResources = () => {
    const [data, setData] = useRecoilState(orderCreatedWithCMP);
    const query = useMutation((payload) => axios.post(`${endPoints.ssp.dashboard.es}/${getEnv('REACT_APP_ES_INDEX_EVENTS')}`, payload));
    const catalogApi = useMutation(() => axios.get(`${endPoints.catalog.allList}?includeInActiveCatalogs=true`));
    React.useEffect(() => {
        catalogApi.mutate();
        query.mutate(provisionedResourceQuery);
    }, []);
    React.useEffect(() => {
        if (query?.isSuccess && catalogApi?.isSuccess) {
            const result =
                query?.data?.data?.aggregations?.catalog_agg?.buckets?.map((item) => ({
                    name: titleCase(catalogApi?.data?.data?.catalogs?.find((catalog) => catalog?.catalogDefinitionId === item?.key)?.displayName),
                    value: item?.doc_count,
                    code: item?.key,
                })) || [];
            setData(result);
        }
    }, [query?.isSuccess, catalogApi?.isSuccess]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            return (
                <Stack direction="row" sx={dashboardStyle.provisionedServices.cutomtooltipWrapper}>
                    <Box sx={{ ...dashboardStyle.provisionedServices.cutomtooltip, background: payload[0].payload.fill }} />
                    <Typography sx={dashboardStyle.provisionedServices.typographyStyle}>
                        {payload[0]?.payload?.name} <b>({payload[0]?.payload?.value})</b>
                    </Typography>
                </Stack>
            );
        }
        return null;
    };

    return (
        <Chart title="Provisioned Resources">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {(query.isLoading || data?.length <= 0) && (
                    <Box sx={dashboardStyle.provisionedServices.wrapper}>
                        <CircularProgress sx={dashboardStyle.provisionedServices.circularProgress} size={16} />
                    </Box>
                )}
                {!query.isLoading && data?.length > 0 && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.provisioned_resource}>
                        <ResponsiveContainer width="100%" height={500}>
                            <BarChart layout="vertical" data={data} barCategoryGap={1} margin={{ top: 0, right: 50, left: 0, bottom: 0 }}>
                                {/* <XAxis interval={0} dataKey="name" height={60} tickCount={10} tick={<CustomizedAxisTick />} /> */}
                                <XAxis type="number" hide domain={[0, 'dataMax + 2']} />
                                <YAxis type="category" width={200} dataKey="name" />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar type="monotone" dataKey="value" barSize={15} fill="#91C24C" label={{ position: 'right' }} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(ProvisionedResources);
