import { styled } from '@mui/material/styles';
import { RadioGroup, Radio, Box } from '@mui/material';

export const ScrollableContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    // height: '100vh',
    // overflowY: 'auto',
    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(3.75),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));

export const LeftBox = styled(Box)(({ theme }) => ({
    width: '70%',
    height: 'auto',
    paddingRight: theme.spacing(2),
}));

export const StickyRightBox = styled(Box)({
    width: '30%',
    height: '100vh',
    position: 'sticky',
    top: 0,
});

export const ScrollableContent = styled(Box)({
    // height: '200vh',
});

export const RIStyles = {
    form: {
        heading: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(2.25),
            fontWeight: '500',
            fontFamily: 'Roboto',
            paddingBottom: (theme) => theme.spacing(1.25),
        },
        accessMgmt: {
            heading: {
                fontSize: (theme) => theme.spacing(1.75),
                fontFamily: 'Roboto',
                fontWeight: '500',
            },
            label: {
                fontSize: (theme) => theme.spacing(1.75),
                fontFamily: 'Roboto',
                fontWeight: '500',
                color: '#1478D4',
            },
            infoIconWrapper: { marginTop: '3px', marginLeft: '10px' },
        },
        searchIcon: { cursor: 'pointer', color: '#A5A5A5' },
        radioLabel: { color: '#666666', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' },
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            background: '#F4273B',
            mt: 0.5,
        },
        delete: {
            fontSize: (theme) => theme.spacing(2.25),
            fill: '#FFFFFF',
            color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    warningTypography: { color: (theme) => theme.palette.red.light },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    adinfoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', width: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    infoIcon: { color: '#1478D4', fontSize: '20px', marginTop: '3px' },
    validateBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    fixedSummary: {
        position: 'fixed',
        top: '78px',
        right: '32px',
        paddingLeft: '16px',
        width: '30%',
        zIndex: 0,
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    welcomeBanner: {
        boxStyle: { marginLeft: '25px', marginRight: '25px' },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    },
    fontStyle: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
    },
    tags: {
        subHeader: { fontWeight: 600, fontSize: '14px', color: '#000', fontFamily: 'Roboto', mb: 2 },
        wrapper: { minWidth: (theme) => theme.spacing(75), width: (theme) => theme.spacing(100), display: 'flex', flexDirection: 'column', height: '100%' },
        contentWrapper: { flexGrow: 1, px: (theme) => theme.spacing(2) },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(1) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#0047BA',
            textTransform: 'none',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#0047BA',
                border: '2px solid #0047BA',
                background: 'transparent',
            },
        },
    },
    componentSelectionModal: {
        wrapper: { width: 550, margin: 'auto', mt: 5, p: 2, bgcolor: 'background.paper', borderRadius: 2 },
        cardWrapper: {
            maxHeight: '340px',
            overflowY: 'scroll',
            mb: 2,
            '@media (min-height: 700px)': {
                maxHeight: '500px',
            },
        },
        catalogImg: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100px',
            backgroundColor: '#E5ECF8',
            borderRadius: '6px',
        },
        componentWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        componentCardWrapper: { display: 'flex', flexDirection: 'column', width: '146px', gap: 2, cursor: 'pointer', height: '162px' },
        componentName: { fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', textAlign: 'center' },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#0047BA',
            textTransform: 'none',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#0047BA',
                border: '2px solid #0047BA',
                background: 'transparent',
            },
        },
        clearIcon: { color: (theme) => theme.palette.blue.main },
        searchIcon: { color: (theme) => theme.palette.blue.main },
    },
    configuration: {
        cosmos: {
            heading: {
                fontSize: (theme) => theme.spacing(1.75),
                fontFamily: 'Roboto',
                fontWeight: '500',
            },
        },
    },
    CostSummary: {
        card: {
            background: '#FFFFFF',
            padding: '15px 25px',
            border: '1px solid #E0E0E0',
            borderTop: '8px solid #F6EB39',
            boxShadow: '0px 3px 8px #0000000F',
            height: 'fit-content',
            // overflow: 'hidden',
            flexDirection: 'column',
            display: 'flex',
            // minHeight: '30%',
            // maxHeight: { xs: '100%', sm: '100%', md: '100%', lg: '600px' },
        },
        headingWrapper: { display: 'flex' },
        heading: { marginBottom: '8px', fontSize: '20px', fontWeight: 'medium', fontFamily: 'Stolzl' },
        loaderIcon: { color: (theme) => theme?.palette?.black?.dark },
        // summaryWrapper: { flexGrow: 1, overflowY: 'auto', display: 'flex' },
        costEstWrapper: { py: 1 },
        labelWrapper: { textAlign: 'right' },
        estWrapper: { display: 'flex' },
        item: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        unit: { fontSize: '12px', fontWeight: '300', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        estCost: { fontSize: '18px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        colon: { px: 1.5 },
        qtyWrapper: { textAlign: 'right' },
        qtyLabel: { fontSize: '16px', fontWeight: 'normal', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        labelStyle: {
            fontSize: '16px',
            fontWeight: 'medium',
            fontFamily: 'Roboto',
            color: (theme) => theme?.palette?.black?.dark,
        },
        valueStyle: { fontSize: '20px', fontWeight: 'bold', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        orText: { fontSize: '20px', fontWeight: 'regular', fontFamily: 'Stolzl', color: (theme) => theme?.palette?.black?.dark },
        note: { fontSize: '14px', fontWeight: 'regular', fontFamily: 'Roboto', color: (theme) => theme?.palette?.black?.dark },
        cartWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        cardWrapper: {
            borderRadius: '6px',
            border: '1px solid #E0E0E0',
            bgcolor: '#FFFFFF',
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(1.75),
            fontWeight: 'medium',
            fontFamily: 'Stolzl',
            padding: 2,
        },
        infoIcon: {
            marginLeft: '10px',
            color: '#1478D4',
            fontSize: '16px',
            cursor: 'pointer',
        },
        squareInfoIcon: {
            marginTop: '6px',
            fontSize: '16px',
        },
        oneTimeBtn: {
            marginLeft: '10px',
            color: '#0047BA',
            fontSize: '28px',
            cursor: 'pointer',
        },
        monthlyBtn: {
            marginLeft: '10px',
            color: '#0047BA',
            fontSize: '28px',
            cursor: 'pointer',
        },
        button: {
            width: '100%',
            height: (theme) => theme.spacing(5.625),
            fontSize: '16px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
            minWidth: '250px',
        },
        previewButton: {
            width: '100%',
            height: (theme) => theme.spacing(5.625),
            fontSize: '16px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
            backgroundColor: '#FFFFFF',
            '&.MuiButtonBase-root:hover': {
                backgroundColor: '#FFFFFF',
            },
        },
        submitBtn: {
            backgroundColor: '#1478D4',
            width: (theme) => theme.spacing(18.75),
            height: (theme) => theme.spacing(4.375),
            textTransform: 'none',
        },
        previewBtn: {
            borderColor: '#1478D4',
            width: '100%',
            height: (theme) => theme.spacing(5.625),
            fontSize: '18px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
        },
        notes: {
            color: (theme) => theme?.palette?.black?.dark,
            fontSize: (theme) => theme.spacing(1.75),
            fontWeight: 'regular',
            fontFamily: 'Roboto',
        },
    },
    staticContent: {
        title: {
            color: (theme) => theme.palette.black.dark,
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'center',
            mt: (theme) => theme.spacing('15px'),
            mb: (theme) => theme.spacing('15px'),
            fontFamily: 'Stolzl',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        textContainer: {
            display: 'flex',
            width: '100%',
            // paddingLeft: '158px',
            justifyContent: 'center',
            color: (theme) => theme.palette.black.dark,
            fontFamily: 'Stolzl',
            fontSize: (theme) => theme.spacing('16px'),
            mt: (theme) => theme.spacing('15px'),
            mb: (theme) => theme.spacing('15px'),
        },
    },
};

export default RIStyles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
