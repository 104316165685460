import React from 'react';
import { Stack, Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { iaasVMCatalogServiceCode, privateEndPointCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';
import { useTranslation } from 'react-i18next';
import LandingZoneStyle from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';

const filter = createFilterOptions();
const Vnet = () => {
    const { t } = useTranslation();
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const vnetGetApi = useMutation((payload) =>
        axios.get(`${endPoints.landingZones.Vnet}/location/${payload?.region}/subscription/${payload?.subscriptionId}`)
    );

    const setVnet = (vNet = null, vNetDetails = null) => {
        setLandingZoneForm({
            ...landingZoneForm,
            vNet,
            vNetDetails,
            subNet: null,
            subNetDetails: null,
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
    };

    const getFilteredData = (vNetData) => {
        if (![iaasVMCatalogServiceCode].includes(landingZoneForm?.service)) {
            return vNetData;
        }
        return vNetData?.filter((app) => !['paas', 'private', 'isolated'].some((str) => app?.name?.toLowerCase()?.includes(str)));
    };

    const seldValueExistsInOptions = (vnetValue) => options.find((val) => vnetValue === val?.label);
    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.vNet) {
            setLandingZoneForm({ ...landingZoneForm, vNet: options?.[0].label, vNetDetails: options?.[0] });
        }
        if (options && options?.length > 1 && landingZoneForm?.vNet) {
            const vNet = seldValueExistsInOptions(landingZoneForm?.vNet);
            setLandingZoneForm({ ...landingZoneForm, vNet: vNet?.value, vNetDetails: vNet });
        }
    }, [options]);

    React.useEffect(() => {
        if (vnetGetApi?.isSuccess) {
            if (vnetGetApi?.data?.data) {
                const filteredVnet = getFilteredData(vnetGetApi?.data?.data);
                const combinedValue = filteredVnet?.map((app) => ({ label: app.name, value: app.name, resourceId: app?.resourceId }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [vnetGetApi?.isSuccess]);

    React.useEffect(() => {
        if (landingZoneForm?.service !== privateEndPointCatalogServiceCode && landingZoneForm?.regionDetails && landingZoneForm?.subscriptionDetails) {
            resetOptions();
            vnetGetApi.mutate({ region: landingZoneForm?.regionDetails?.value, subscriptionId: landingZoneForm?.subscriptionDetails?.id });
        }
    }, [landingZoneForm.regionDetails, landingZoneForm?.subscriptionDetails, landingZoneForm?.resGroupDetails]);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setVnet(selected?.label, selected);
        }
    };
    React.useEffect(() => {
        if (!landingZoneForm?.vNet && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.vNet, landingZoneForm?.isTouched]);
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Select
                    key="VNet"
                    label="VNet*"
                    noOptionsText="No options"
                    loading={vnetGetApi?.isLoading}
                    forcePopupIcon={false}
                    disabled={vnetGetApi?.isLoading}
                    value={landingZoneForm?.vNet || null}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demoVnet"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={LandingZoneStyle.select}
                    // freeSolo
                    disableClearable={false}
                    isRequired={isTouched}
                />
                <Box sx={LandingZoneStyle?.helptext}>
                    <InfoImg title={t([`landingZone.infoText.vnet`])} />
                </Box>
            </Stack>
        </>
    );
};

export default React.memo(Vnet);
