import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { requestDetails } from 'pages/onboard-developers/store';
import { useRecoilValue } from 'recoil';

const FirstName = () => {
    const details = useRecoilValue(requestDetails);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="First Name *"
                value={details?.firstName || ''}
                disabled
            />
        </Box>
    );
};

export default FirstName;
