import React from 'react';
import { Stack, FormControl, Box, FormControlLabel, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { orderPayload, selectedVSService } from 'pages/provisioning/virtual-service/store';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/virtual-service/style';

const CustomDomainSelected = () => {
    const [order, setOrder] = useRecoilState(orderPayload);
    const [selectedVS] = useRecoilState(selectedVSService);

    const setCustomDomainSelected = (customDomainSelected) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            aksVirtualService: prevOrder?.aksVirtualService.map((item, index) => {
                if (index === selectedVS) {
                    return {
                        ...item,
                        customDomainSelected,
                        customDomain: [
                            {
                                name: null,
                            },
                        ],
                    };
                }
                return item;
            }),
        }));
    };
    const handleOnSelectChange = (selected) => {
        setCustomDomainSelected(selected);
    };

    return (
        <Stack direction="row" spacing={1} sx={ProvisioningStyles.stack}>
            <FormControl sx={{ justifyContent: 'center', width: '100%' }}>
                <Box id="customDomainSelected">
                    <Typography className="text-label">Do you want to add host with custom domain ?</Typography>
                </Box>
                <CustomRadioGroup
                    data-testid="custom-domain-selected-group"
                    aria-label="customDomainSelected"
                    name="customDomainSelected"
                    row
                    value={order?.aksVirtualService?.[selectedVS]?.customDomainSelected}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" />
                    <FormControlLabel
                        value="No"
                        sx={{
                            marginLeft: '0px',
                        }}
                        control={<CustomRadio />}
                        label="No"
                    />
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(CustomDomainSelected);
