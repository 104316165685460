import { selector } from 'recoil';
import { orderPayload, validVSForm } from './index';

//  ***********************Allowed Headers**************************

export const addAllowedHeader = selector({
    key: 'virtual_service_addAllowedHeader',
    get: ({ get }) => get(orderPayload)?.aksCors?.allowedHeaders,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.allowedHeaders;

        const allowedHeaders = [...currentHeadersList, { allowedHeader: null }];
        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, allowedHeaders },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const removeAllowedHeader = selector({
    key: 'virtual_service_removeAllowedHeader',
    get: ({ get }) => get(orderPayload)?.aksCors?.allowedHeaders,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.allowedHeaders;

        const headerToRemoveIndex = currentHeadersList?.findIndex((val) => val === params?.confirmModal?.header);

        const updatedAksVirtualService = [...currentHeadersList];
        updatedAksVirtualService.splice(headerToRemoveIndex, 1);

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, allowedHeaders: updatedAksVirtualService },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const updateAllowedHeader = selector({
    key: 'virtual_service_updateAllowedHeader',
    get: ({ get }) => get(orderPayload)?.aksCors?.allowedHeaders,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.allowedHeaders;

        const updatedAllowedHeader = {
            ...currentHeadersList[params?.index],
            allowedHeader: params?.value,
            isAllowedHeaderTouched: false,
        };

        const allowedHeaders = [...currentHeadersList.slice(0, params?.index), updatedAllowedHeader, ...currentHeadersList.slice(params?.index + 1)];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, allowedHeaders },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

//  ***********************Allowed Origins**************************

export const addAllowedOrigin = selector({
    key: 'virtual_service_addAllowedOrigin',
    get: ({ get }) => get(orderPayload).allowedOrigins,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.allowedOrigins;

        const allowedOrigins = [...currentHeadersList, { allowedOrigin: null }];
        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, allowedOrigins },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const removeAllowedOrigin = selector({
    key: 'virtual_service_removeAllowedOrigin',
    get: ({ get }) => get(orderPayload).allowedOrigins,
    set: ({ set, get }, params) => {
        const currentOriginsList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.allowedOrigins;

        const headerToRemoveIndex = currentOriginsList?.findIndex((val) => val === params?.confirmModal?.header);

        const updatedAksVirtualService = [...currentOriginsList];
        updatedAksVirtualService.splice(headerToRemoveIndex, 1);

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, allowedOrigins: updatedAksVirtualService },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const updateAllowedOrigin = selector({
    key: 'virtual_service_updateAllowedOrigin',
    get: ({ get }) => ({
        allowedOrigins: get(orderPayload).allowedOrigins,
        validForm: get(validVSForm),
    }),
    set: ({ set, get }, params) => {
        const currentOriginsList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.allowedOrigins;
        let updatedAllowedOrigins = null;
        if (
            params?.value &&
            params?.value !== null &&
            params?.value !== '' &&
            params?.value !== undefined &&
            !/^https?:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(params?.value)
        ) {
            updatedAllowedOrigins = {
                ...currentOriginsList[params?.index],
                allowedOrigin: params?.value,
                isInvalid: true,
            };
        } else {
            updatedAllowedOrigins = {
                ...currentOriginsList[params?.index],
                allowedOrigin: params?.value,
                isInvalid: false,
                isAllowedOriginTouched: false,
            };
        }

        const allowedOrigins = [...currentOriginsList.slice(0, params?.index), updatedAllowedOrigins, ...currentOriginsList.slice(params?.index + 1)];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, allowedOrigins },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

//  ***********************Expose Headers**************************

export const addExposeHeader = selector({
    key: 'virtual_service_addExposeHeader',
    get: ({ get }) => get(orderPayload)?.aksCors?.exposeHeaders,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.exposeHeaders;

        const exposeHeaders = [...currentHeadersList, { exposeHeader: null }];
        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, exposeHeaders },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const removeExposeHeader = selector({
    key: 'virtual_service_removeExposeHeader',
    get: ({ get }) => get(orderPayload)?.aksCors?.exposeHeaders,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.exposeHeaders;

        const headerToRemoveIndex = currentHeadersList?.findIndex((val) => val === params?.confirmModal?.header);

        const updatedAksVirtualService = [...currentHeadersList];
        updatedAksVirtualService.splice(headerToRemoveIndex, 1);

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, exposeHeaders: updatedAksVirtualService },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const updateExposeHeader = selector({
    key: 'virtual_service_updateExposeHeader',
    get: ({ get }) => get(orderPayload)?.aksCors?.exposeHeaders,
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors?.exposeHeaders;

        const updatedExposeHeader = {
            ...currentHeadersList[params?.index],
            exposeHeader: params?.value,
            isExposeHeaderTouched: false,
        };

        const exposeHeaders = [...currentHeadersList.slice(0, params?.index), updatedExposeHeader, ...currentHeadersList.slice(params?.index + 1)];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksCors: { ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksCors, exposeHeaders },
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const updateServiceName = selector({
    key: 'virtual_service_updateServiceName',
    get: ({ get }) => ({
        allowedOrigins: get(orderPayload).aksVirtualService,
        validForm: get(validVSForm),
    }),
    set: ({ set, get }, params) => {
        const currentHeadersList = get(orderPayload).aksVirtualService;
        if (params?.value && params?.value !== null && params?.value !== '' && params?.value !== undefined && !/^[a-zA-Z0-9_]{3,64}$/.test(params?.value)) {
            const updatedallowedOrigin = {
                ...currentHeadersList[params.index],
                serviceName: params.value,
                isInvalidServiceName: false,
                isServiceNameInvalid: true,
            };
            const aksVirtualService = [...currentHeadersList.slice(0, params.index), updatedallowedOrigin, ...currentHeadersList.slice(params.index + 1)];
            set(orderPayload, { ...get(orderPayload), aksVirtualService });
            set(validVSForm, { ...get(validVSForm), isInvalidAllowedOrigin: true });
        } else {
            const updatedallowedOrigin = {
                ...currentHeadersList[params.index],
                serviceName: params.value,
                isInvalidServiceName: false,
                isServiceNameInvalid: false,
            };
            const aksVirtualService = [...currentHeadersList.slice(0, params.index), updatedallowedOrigin, ...currentHeadersList.slice(params.index + 1)];
            set(orderPayload, { ...get(orderPayload), aksVirtualService });
            set(validVSForm, { ...get(validVSForm), isInvalidServiceName: false });
        }
    },
});

// *********************** MultiContext Path**************************

export const addMultiContextPath = selector({
    key: 'virtual_service_addMultiContextPath',
    get: ({ get }) => get(orderPayload),
    set: ({ set, get }, params) => {
        const currentContextPath = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksService;
        const multiContextPath = [...currentContextPath, { serviceName: null, servicePort: null, contextPath: null }];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksService: multiContextPath,
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const removeMultiContextPath = selector({
    key: 'virtual_service_removeMultiContextPath',
    get: ({ get }) => get(orderPayload),
    set: ({ set, get }, params) => {
        const currentContextPath = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksService;
        const contextToRemoveIndex = currentContextPath?.findIndex((val) => val === params?.confirmModal?.service);

        const updatedAksVirtualService = [...currentContextPath];
        updatedAksVirtualService.splice(contextToRemoveIndex, 1);

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksService: updatedAksVirtualService,
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const updateMultiSelection = selector({
    key: 'virtual_service_updateMultiSelection',
    get: ({ get }) => ({
        allowedOrigins: get(orderPayload),
        validForm: get(validVSForm),
    }),
    set: ({ set, get }, params) => {
        const currentContexPathList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksService;

        const updatedContextPath = {
            ...currentContexPathList[params?.index],
            [params?.field]: params?.value,
        };

        const multiService = [...currentContexPathList.slice(0, params?.index), updatedContextPath, ...currentContexPathList.slice(params?.index + 1)];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    aksService: multiService,
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

// *********************** Multi Hostnames Prefix**************************

export const addMultiHostNamePrefix = selector({
    key: 'virtual_service_addMultiHostNamePrefix',
    get: ({ get }) => get(orderPayload),
    set: ({ set, get }, params) => {
        const currentHostNamePrefix = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.hostNamesPrefix;
        const multiHostNamePrefix = [...currentHostNamePrefix, { hostNamePrefix: null, isHostNameTouched: false }];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    hostNamesPrefix: multiHostNamePrefix,
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const removeMultiHostNamePrefix = selector({
    key: 'virtual_service_removeMultiHostNamePrefix',
    get: ({ get }) => get(orderPayload),
    set: ({ set, get }, params) => {
        const currentHostNamePrefix = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.hostNamesPrefix;
        const hostNameToRemoveIndex = currentHostNamePrefix?.findIndex((val) => val === params?.confirmModal?.service);

        const updatedAksVirtualService = [...currentHostNamePrefix];
        updatedAksVirtualService.splice(hostNameToRemoveIndex, 1);

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    hostNamesPrefix: updatedAksVirtualService,
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

export const updateMultiHostNames = selector({
    key: 'virtual_service_updateMultiHostNames',
    get: ({ get }) => ({
        allowedOrigins: get(orderPayload),
        validForm: get(validVSForm),
    }),
    set: ({ set, get }, params) => {
        const currentHostNamesList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.hostNamesPrefix;

        const updatedContextPath = {
            ...currentHostNamesList[params?.index],
            [params?.field]: params?.value,
        };

        const multiHostNames = [...currentHostNamesList.slice(0, params?.index), updatedContextPath, ...currentHostNamesList.slice(params?.index + 1)];

        set(orderPayload, {
            ...get(orderPayload),
            aksVirtualService: [
                ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
                {
                    ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
                    hostNamesPrefix: multiHostNames,
                },
                ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
            ],
        });
    },
});

// export const updateMultiServicePort = selector({
//     key: 'virtual_service_updateMultiServicePort',
//     get: ({ get }) => ({
//         allowedOrigins: get(orderPayload),
//         validForm: get(validVSForm),
//     }),
//     set: ({ set, get }, params) => {
//         const currentContexPathList = get(orderPayload)?.aksVirtualService?.[params?.selectedVS]?.aksService;
//         console.log('🚀 ~ updateMultiServicePort:', currentContexPathList);

//         const updatedContextPath = {
//             ...currentContexPathList[params?.index],
//             servicePort: params?.value,
//         };

//         console.log('🚀 ~ updatedContextPath:', updatedContextPath);
//         const servicePorts = [...currentContexPathList.slice(0, params?.index), updatedContextPath, ...currentContexPathList.slice(params?.index + 1)];

//         set(orderPayload, {
//             ...get(orderPayload),
//             aksVirtualService: [
//                 ...get(orderPayload)?.aksVirtualService?.slice(0, params?.selectedVS),
//                 {
//                     ...get(orderPayload)?.aksVirtualService?.[params?.selectedVS],
//                     aksService: servicePorts,
//                 },
//                 ...get(orderPayload)?.aksVirtualService?.slice(params?.selectedVS + 1),
//             ],
//         });
//     },
// });
