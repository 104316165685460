import { RICatalogCode004, RICatalogCode004A, RICatalogCode003, RICatalogCode003A, RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';

export const isADGroupFormValid = (order) => {
    let errorCount = 0;
    let isValid = false;
    if (order?.adGroupList?.length) {
        order?.adGroupList?.forEach((item) => {
            if (item && (item?.adGroupValue === '' || item?.adGroupValue === null)) {
                errorCount += 1;
            }
        });
    }
    isValid = errorCount === 0;
    return isValid;
};

export const isADGroupDuplicate = (order) => {
    const adGroupArray = order?.adGroupList?.map((item) => item?.adGroupValue);
    return new Set(adGroupArray).size !== adGroupArray.length;
};
export const isNonProd = (order) => ['production']?.includes(order?.environment?.toLowerCase());
export const isReadyToSubmit = ({
    order,
    businessAppValid,
    projectIdValid,
    businessUnitValid,
    budgetCodeValid,
    appShortNameVaild,
    appShortNameExist,
    nonPrivilegedADGroupValid,
    privilegedADGroupValid,
    appUrlValid,
    validApplicationSupportMail,
    validOwners,
    // appContextPathValid,
}) => {
    const validPrivilegedADGroup = () => {
        let errorCount = 0;
        order?.privilegedADGroupList?.forEach((z) => {
            if (z?.contributorValue === null || z?.contributorValue === '') {
                errorCount += 1;
            }
        });
        if (order?.privilegedADGroup === 'Yes' && (!privilegedADGroupValid || errorCount > 0)) {
            return true;
        }
        return false;
    };

    const validNonPrivilegedADGroup = () => {
        let errorCount = 0;
        order?.nonPrivilegedADGroupList?.forEach((z) => {
            if (z?.readerValue === null || z?.readerValue === '') {
                errorCount += 1;
            }
        });
        if (order?.nonPrivilegedADGroup === 'Yes' && (!nonPrivilegedADGroupValid || errorCount > 0)) {
            return true;
        }
        return false;
    };
    return (
        (order?.projectId && !projectIdValid) ||
        !order?.businessUnit ||
        !businessUnitValid ||
        !order?.sector ||
        !order?.program ||
        // (order?.appContextPath && !appContextPathValid) ||
        (order?.sector?.toLowerCase() === 'cgf' && !order?.division) ||
        (['cgf', 'amesa', 'snt dps']?.includes(order?.sector?.toLowerCase()) && !order?.region) ||
        !order?.budgetCode ||
        !budgetCodeValid ||
        !order?.environment ||
        !order?.dataClassification ||
        !order?.businessApp ||
        !order?.appService ||
        !order?.appShortName ||
        !appShortNameVaild ||
        appShortNameExist ||
        (![RICatalogCode004, RICatalogCode004A]?.includes(order?.catalogCode) && (!order?.appUrl || !appUrlValid)) ||
        (order?.businessAppService === 'No' && (!order?.businessApp || !businessAppValid || !order?.appOwner || !order?.appOwnerDetails)) ||
        (order?.businessAppService === 'Yes' && (!order?.businessApp || !order?.appService || !order?.appOwner || !order?.appOwnerDetails)) ||
        !order?.businessContact ||
        !order?.businessContactDetails ||
        (order?.environment?.toLowerCase() !== 'production' && validNonPrivilegedADGroup()) ||
        validPrivilegedADGroup() ||
        (order?.environment?.toLowerCase() !== 'production' && (!order?.nonPrivilegedADGroup || !order?.privilegedADGroup)) ||
        (order?.environment?.toLowerCase() === 'production' && !order?.privilegedADGroup) ||
        (order?.environment?.toLowerCase() !== 'production' &&
            order?.nonPrivilegedADGroup === 'No' &&
            (!order?.nonPrivilegedADGroupOwner || !order?.nonPrivilegedADGroupOwnerDetails)) ||
        (order?.environment?.toLowerCase() !== 'production' &&
            order?.privilegedADGroup === 'No' &&
            (!order?.privilegedADGroupOwner || !order?.privilegedADGroupOwnerDetails)) ||
        (order?.environment?.toLowerCase() === 'production' &&
            order?.privilegedADGroup === 'No' &&
            (!order?.privilegedADGroupOwner || !order?.privilegedADGroupOwnerDetails)) ||
        ([RICatalogCode003, RICatalogCode003A]?.includes(order?.catalogCode) && (!order?.appSupportMailId || validApplicationSupportMail)) ||
        ([RICatalogCode003, RICatalogCode003A, RICatalogCode005]?.includes(order?.catalogCode) && !Object.values(validOwners).every((item) => item === true))
    );
};

export const isDuplicate = (adGroupArray, adGroup) => {
    const duplicateReaderADGroups = adGroupArray?.filter((item, index) => adGroupArray.indexOf(item) !== index);
    if (adGroup) {
        return duplicateReaderADGroups.indexOf(adGroup) > -1;
    }
    return false;
};

export const isADGroupError = (validationMessage = [], adGroup = null) => {
    let errorCount = 0;
    let adGroupError = false;
    if (Array.isArray(validationMessage)) {
        validationMessage?.forEach((item) => {
            if (item.includes(adGroup)) {
                // eslint-disable-next-line no-plusplus
                errorCount++;
            }
        });
    }
    adGroupError = errorCount !== 0;
    return adGroupError;
};

export const environmentConfig = {
    development: {
        regex: /^[a-zA-Z0-9-]{3,15}\.dev\.(mypepsico|pepsico)\.com$/,
        message: 'Application URL is Invalid. Must be in the format: xxx.dev.mypepsico.com or xxx.dev.pepsico.com (where xxx is 3-15 characters)',
    },
    qa: {
        regex: /^[a-zA-Z0-9-]{3,15}\.(qa|ite)\.(mypepsico|pepsico)\.com$/,
        message:
            'Application URL is Invalid. Must be in the format: xxx.qa.mypepsico.com, xxx.qa.pepsico.com, xxx.ite.pepsico.com  or xxx.ite.pepsico.com (where xxx is 3-15 characters)',
    },
    'pre-production': {
        regex: /^[a-zA-Z0-9-]{3,15}\.(preprod|ite)\.(mypepsico|pepsico)\.com$/,
        message:
            'Application URL is Invalid. Must be in the format: xxx.preprod.mypepsico.com, xxx.preprod.pepsico.com, xxx.ite.mypepsico.com or xxx.ite.pepsico.com (where xxx is 3-15 characters)',
    },
    production: {
        regex: /^[a-zA-Z0-9-]{3,15}\.(mypepsico|pepsico)\.com$/,
        message: 'Application URL is Invalid. Must be in the format: xxx.mypepsico.com or xxx.pepsico.com (where xxx is 3-15 characters)',
    },
};

export const validateAppURL = (url, environment) => {
    const config = environmentConfig[environment?.toLowerCase()];

    if (!config) {
        return {
            isValid: false,
            message: 'Invalid environment specified',
        };
    }

    const isValid = config.regex.test(url);
    return {
        isValid,
        message: isValid ? 'Valid URL' : config.message,
    };
};
