import React from 'react';
import { Box, Grid, Stack, Button, Typography, Slide, Divider, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import Btn from 'components/button';
import { orderPayload, validationStatus, validationErrorReport, selectedVSService } from 'pages/provisioning/virtual-service/store';
import { FaClone } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import { virtualServiceTypes } from 'pages/provisioning/virtual-service/utils';
import ProvisioningStyles from 'pages/provisioning/virtual-service/style';

export const VirtualServiceAdded = ({ handleVSAction }) => {
    const [order] = useRecoilState(orderPayload);
    const [selectedVS] = useRecoilState(selectedVSService);
    const [validationErrorStatus] = useRecoilState(validationStatus);
    const [, setValidationError] = useRecoilState(validationErrorReport);
    let hasError = false;
    let vsErrorDetails = [];

    const getVSValidationStatus = (virtualService, vsType, vsIndex) => {
        if (validationErrorStatus?.length > 0) {
            const errorLogs = [];
            validationErrorStatus?.forEach((error) => {
                if (error?.virtualServiceName === virtualService?.virtualServiceName && error?.msg?.length > 0) {
                    errorLogs.push(true);
                }
                if (error?.services?.length > 0 && vsType !== virtualServiceTypes?.[2]?.name) {
                    error?.services?.forEach((service) => {
                        if (
                            virtualService?.aksService
                                ?.map((vsService) => vsService?.serviceName)
                                .flat()
                                ?.includes(service?.name)
                        ) {
                            errorLogs.push(true);
                        }
                    });
                }
                if (
                    error?.services?.length > 0 &&
                    vsType === virtualServiceTypes?.[2]?.name &&
                    error?.virtualServiceName === virtualService?.virtualServiceName
                ) {
                    error?.services?.forEach((service) => {
                        if (
                            virtualService?.aksService
                                ?.map((vsService) => vsService?.serviceName)
                                .flat()
                                ?.includes(service?.name)
                        ) {
                            errorLogs.push(true);
                        }
                    });
                }
                if (error?.hostNames?.length > 0) {
                    error?.hostNames?.forEach((host) => {
                        if (
                            virtualService?.hostNamesPrefix
                                ?.map((hostName) => hostName?.hostNamePrefix)
                                .flat()
                                ?.includes(host?.prefix)
                        ) {
                            errorLogs.push(true);
                        }
                    });
                }
                if (error?.customDomain?.length > 0) {
                    error?.customDomain?.forEach((domain) => {
                        if (
                            virtualService?.customDomain
                                ?.map((domainName) => domainName?.name)
                                .flat()
                                ?.includes(domain?.prefix)
                        ) {
                            errorLogs.push(true);
                        }
                    });
                }
            });
            hasError = errorLogs.some((value) => value === true);
            if (!vsErrorDetails.some((vs) => vs.currentVSIndex === vsIndex)) {
                vsErrorDetails = [...vsErrorDetails, { currentVSIndex: vsIndex, error: hasError }];
            }

            return hasError;
        }
        if (!vsErrorDetails.some((vs) => vs.currentVSIndex === vsIndex)) {
            vsErrorDetails = [...vsErrorDetails, { currentVSIndex: vsIndex, error: false }];
        }
        hasError = false;
        return false;
    };

    const getConfigureLabel = (virtualServiceType, virtualServiceName, serviceName) => {
        if (virtualServiceType === virtualServiceTypes?.[2]?.code) {
            if (virtualServiceName === null && serviceName !== null) {
                return 'Re-Configure';
            }
        }
        return 'Configure';
    };

    React.useEffect(() => {
        setValidationError(vsErrorDetails);
    }, [hasError, selectedVS, order]);

    return (
        <>
            <Grid display="flex" justifyContent="space-between">
                <Typography className="vs-added-heading" sx={{ fontWeight: 600, fontSize: '16px', color: '#000', fontFamily: 'Roboto' }}>
                    Virtual Services Added {order?.aksVirtualService?.length > 0 ? `(${order?.aksVirtualService?.length})` : ''}
                </Typography>{' '}
            </Grid>
            <Divider variant="middle" />
            <Box display="flex" flexDirection="column" alignItems="center">
                {order?.aksVirtualService?.map((value, index) => (
                    <Slide direction="up" in={!!order?.aksVirtualService?.length} mountOnEnter unmountOnExit key={`vs-slide-${index}`}>
                        <Grid item container key={`vs-container-${index}`} paddingBottom={1} paddingTop={1} className="vs-service-list-container">
                            <Grid item md={8} sm={6} xs={12} key={`grid-type-${index}`}>
                                <Stack direction="column">
                                    {value?.virtualServiceType && (
                                        <Box>
                                            <Typography className="vs-service-list-label">{value?.virtualServiceType}</Typography>
                                        </Box>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sm={6}
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    justifyContent: 'end',
                                    alignItems: 'center',
                                    flexDirection: 'row-reverse',
                                }}
                                key={`grid-action-${index}`}
                            >
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleVSAction(index, 'remove')}
                                    disabled={false}
                                    sx={ProvisioningStyles?.project?.vsIcon}
                                    data-testid={`remove-vs-${index}`}
                                >
                                    <DeleteIcon sx={{ fontSize: '25px' }} />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="cmpWarning"
                                    onClick={() => handleVSAction(index, 'clone', value?.virtualServiceType)}
                                    sx={ProvisioningStyles?.project?.vsIcon}
                                    disabled={value?.aksService?.[0]?.serviceName === null || value?.aksService?.[0]?.contextPath === null}
                                    data-testid={`clone-vs-${index}`}
                                >
                                    <Box sx={{ width: '16px', height: '16px' }}>
                                        <FaClone size={16} color="white" />
                                    </Box>
                                </Button>
                                {(value?.virtualServiceName !== null && value?.virtualServiceName !== '') ||
                                (value?.aksService?.[0]?.serviceName === null && value?.aksService?.[0]?.contextPath !== null) ? (
                                    <>
                                        {value?.aksService?.[0]?.serviceName !== null && value?.aksService?.[0]?.contextPath !== null ? (
                                            <Badge
                                                color="error"
                                                variant={value?.customDomainSelected === 'Yes' && value?.customDomain?.[0]?.name === null ? 'dot' : 'standard'}
                                            >
                                                <Button
                                                    className={`edit-button ${
                                                        getVSValidationStatus(order?.aksVirtualService?.[index], value?.virtualServiceType, index) === true
                                                            ? 'error-button-border'
                                                            : ''
                                                    }`}
                                                    variant={
                                                        getVSValidationStatus(order?.aksVirtualService?.[index], value?.virtualServiceType, index) === true
                                                            ? 'outlined'
                                                            : 'contained'
                                                    }
                                                    color={
                                                        getVSValidationStatus(order?.aksVirtualService?.[index], value?.virtualServiceType, index) === true
                                                            ? 'cmpDanger'
                                                            : 'cmpPrimary'
                                                    }
                                                    onClick={() => handleVSAction(index, 'edit')}
                                                    disabled={false}
                                                    data-testid={`edit-vs-${index}`}
                                                    sx={ProvisioningStyles?.project?.vsButton}
                                                >
                                                    Edit
                                                </Button>
                                            </Badge>
                                        ) : (
                                            <Btn
                                                variant="contained"
                                                color="cmpPrimary"
                                                onClick={() => handleVSAction(index, 'configure')}
                                                disabled={false}
                                                sx={ProvisioningStyles?.project?.vsButton}
                                                data-testid={`re-configure-vs-${index}`}
                                            >
                                                Re-Configure
                                            </Btn>
                                        )}
                                    </>
                                ) : (
                                    <Btn
                                        sx={ProvisioningStyles?.project?.vsButton}
                                        variant="contained"
                                        color="cmpPrimary"
                                        onClick={() => handleVSAction(index, 'configure')}
                                        disabled={false}
                                        data-testid={`configure-btn-${index}`}
                                    >
                                        {getConfigureLabel(value?.virtualServiceType, value?.virtualServiceName, value?.aksService?.[0]?.serviceName)}
                                    </Btn>
                                )}
                            </Grid>
                        </Grid>
                    </Slide>
                ))}
            </Box>
        </>
    );
};

VirtualServiceAdded.propTypes = {
    handleVSAction: PropTypes.func.isRequired,
};

export default React.memo(VirtualServiceAdded);
