import Application from './Application';
import Sector from './Sector';
import Environments from './Environments';
import AppTier from './AppTier';
import Region from './Region';
import LandingZones from './LandingZones';
import BillingEntities from './BillingEntities';
import CostCenter from './CostCenter';
import Cluster from './Cluster';
import DbVersion from './DbVersion';
import NodesCount from './NodesCount';
import OsType from './OsType';
import VmType from './VmType';
import Domain from './Domain';
import HaDeployment from './HaDeployment';
import AvailabilityZone from './AvailabilityZone';
import SamAccountId from './SamAccountId';
import TimeZone from './TimeZone';
import SslCertificate from './SslCertificate';
import DataDisk from './DataDisk';
import ServiceMappings from './ServiceMappings';
import AddBucket from './AddBucket';

const Forms = {
    Application,
    Environments,
    AppTier,
    Region,
    Sector,
    LandingZones,
    BillingEntities,
    CostCenter,
    Cluster,
    DbVersion,
    NodesCount,
    OsType,
    VmType,
    Domain,
    HaDeployment,
    AvailabilityZone,
    SamAccountId,
    TimeZone,
    SslCertificate,
    DataDisk,
    ServiceMappings,
    AddBucket,
};

export default Forms;
