import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAxios } from 'hooks';
import { endPoints } from 'api/endpoints';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { catalogs } from 'store';
import {
    defaultVmDataDisk,
    environmentDetails,
    isConfigDiskFulfilled,
    isMandatoryTagsFullfilled,
    orderId,
    quickConfigDetails,
    sspSubmitOrder,
    toggleSspModels,
} from 'pages/provisioning/store/iaas-sql';
import ProvisioningStyles from 'pages/provisioning/iaas-sql/style';
import { formOrderObjFromCart, formOrderObjFromOrderDetails, resetOrder, restEnv, resetToggleSspModal } from './utils';

const ProvisioningHeader = React.lazy(() => import('pages/provisioning/iaas-sql/ProvisioningHeader'));
const ApplicationDetails = React.lazy(() => import('pages/provisioning/iaas-sql/ApplicationDetails'));
const SspForm = React.lazy(() => import('pages/provisioning/iaas-sql/SspForm'));
const Tags = React.lazy(() => import('pages/provisioning/iaas-sql/Tags'));
const AddedToCartConfirmModal = React.lazy(() => import('pages/provisioning/iaas-sql/AddedToCartConfirmModal'));
const UpdatedToCartConfirmModal = React.lazy(() => import('pages/provisioning/iaas-sql/UpdatedToCartConfirmModal'));
const ConfirmDD = React.lazy(() => import('pages/provisioning/iaas-sql/ConfirmDD'));
const SamRequest = React.lazy(() => import('pages/provisioning/iaas-sql/sam-request'));
const QuickConfig = React.lazy(() => import('pages/provisioning/iaas-sql/QuickConfig'));
const CurrentServerDetails = React.lazy(() => import('pages/provisioning/iaas-sql/components/CurrentServerDetails'));
const ShowCostDetails = React.lazy(() => import('pages/provisioning/iaas-sql/ShowCostDetails'));

const IaaSSQLProvisioningPage = () => {
    const [isLoading, setLoading] = React.useState(true);
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [vmDataDisk, setVmDataDisk] = useRecoilState(defaultVmDataDisk);
    const [, resetOrderId] = useRecoilState(orderId);
    const [, resetEnvDetails] = useRecoilState(environmentDetails);
    const [, resetIsMandatoryTagsFullfilled] = useRecoilState(isMandatoryTagsFullfilled);
    const [, resetIsConfigDiskFulfilled] = useRecoilState(isConfigDiskFulfilled);
    const [, resetToggleSspModels] = useRecoilState(toggleSspModels);
    const [, resetQuickConfig] = useRecoilState(quickConfigDetails);
    const { osType, serverType, catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const axios = useAxios();
    async function getCosts() {
        const { data } = await axios.get(`${endPoints.catalog.get}/${catalogCode}/costs`);
        return data;
    }
    async function checkCatalog() {
        const isWindows = osType === 'Windows';
        const selector = isWindows ? 'azure_windows_vm_host' : 'azure_rhel_vm_host';
        const data = cmpCatalogs?.find((item) => item?.catalogDefinitionId === catalogCode);
        const costs = await getCosts();
        setVmDataDisk({
            ...vmDataDisk,
            diskSizeInGib: data?.provisioning?.requestPayloadDefinition?.extra_vars[selector]?.os_disk_size_gb || 128,
        });
        if (cartItemId && operation && !catalogServiceId) {
            const { data: cartItem } = await axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`);
            const orderObj = await formOrderObjFromCart(cartItem, osType, serverType, data?.images?.sku);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        } else if (cartItemId && operation && catalogServiceId) {
            const { data: orderDetails } = await axios.get(`${endPoints.order.orderDetails}/${cartItemId}`);
            const orderObj = await formOrderObjFromOrderDetails(catalogServiceId, orderDetails, osType, serverType, data?.images?.sku);
            setOrder((previous) => ({
                ...previous,
                ...orderObj,
                emptyCost: costs,
            }));
        } else {
            setOrder((previous) => ({
                ...previous,
                osType,
                sku: data?.images?.sku,
                serverType,
                catalogCode,
                catalogType: data?.displayName,
                cloudProvider: data?.cloudProvider,
                cloudService: data?.cloudService,
                validationStatus: 'clearValidation',
                isTouched: false,
                emptyCost: costs,
            }));
        }
        setLoading(false);
    }
    const reset = () => {
        setOrder({
            ...order,
            ...resetOrder,
        });
        resetOrderId('');
        resetEnvDetails(restEnv);
        resetIsMandatoryTagsFullfilled(false);
        resetToggleSspModels(resetToggleSspModal);
        resetIsConfigDiskFulfilled(false);
        resetQuickConfig(undefined);
    };
    React.useEffect(() => {
        if (cmpCatalogs?.length && osType && serverType && catalogCode) {
            setLoading(true);
            reset();
            setVmDataDisk(undefined);
            checkCatalog();
        }
    }, [cmpCatalogs, osType, serverType, catalogCode]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => reset();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <Helmet>
                <title>IaaS SQL Provisioning - CMP</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                }
            >
                {isLoading && (
                    <Box sx={ProvisioningStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProvisioningStyles.circularProgressColor} />
                        <Typography variant="body2" sx={ProvisioningStyles.circularProgressColor}>
                            Loading
                        </Typography>
                    </Box>
                )}
                {!isLoading && (
                    <Box sx={ProvisioningStyles.provisioningBox} data-testid="iaas-sql-wrapper">
                        <ProvisioningHeader />
                        <Box sx={ProvisioningStyles.sspform_wrapper}>
                            <SspForm />
                        </Box>
                        <br />
                        <ApplicationDetails />
                        <CurrentServerDetails />
                        <Tags />
                        <AddedToCartConfirmModal />
                        <UpdatedToCartConfirmModal />
                        <ConfirmDD />
                        <SamRequest />
                        <QuickConfig />
                        <ShowCostDetails />
                    </Box>
                )}
            </Suspense>
        </>
    );
};

export default IaaSSQLProvisioningPage;
