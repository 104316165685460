import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload, validOnboardingForm, isValidationLoading } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const ServiceAccount = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [, setIsLoading] = useRecoilState(isValidationLoading);
    const [helperText, setHelperText] = React.useState(false);

    const validateServiceAccount = useMutation(() =>
        axios.get(`${endPoints.day2Operation.ProjectOnboarding?.Jwt?.validateServiceAccount}?serviceAccountId=${order?.serviceAccount}`)
    );

    const setServiceAccount = (serviceAccount = null) => {
        setOrder({
            ...order,
            serviceAccount,
        });
    };

    React.useEffect(() => {
        if (
            order?.serviceAccount &&
            order?.serviceAccount !== null &&
            order?.serviceAccount !== '' &&
            order?.serviceAccount !== undefined &&
            !/^[a-zA-Z0-9_-\s\\(\\)]{4,64}$/.test(order?.serviceAccount)
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isAppNameValid: true });
        } else {
            setHelperText(false);
            setValidForm({ ...validForm, isAppNameValid: false });
        }
    }, [order?.serviceAccount]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.serviceAccount) ||
            helperText ||
            (validForm?.isInvalidServiceAccount &&
                validForm?.isInvalidServiceAccount &&
                (validateServiceAccount?.data?.messages?.length > 0 || validateServiceAccount?.error?.length > 0))
        ) {
            return 'validate';
        }
        return '';
    };

    const getHelperText = () => {
        if (helperText) {
            return <span>Service Account must have min 4 chars and can contain space, hyphen(-), underscore(_), slash(/) as special chars.</span>;
        }
        if (
            validForm?.isInvalidServiceAccount &&
            validForm?.isInvalidServiceAccount &&
            (validateServiceAccount?.data?.messages?.length > 0 || validateServiceAccount?.error?.length > 0)
        ) {
            return <Box sx={ProjectOnboardingStyles.error}>{validateServiceAccount?.data?.messages?.[0] || validateServiceAccount?.error?.[0]}</Box>;
        }
        return '';
    };

    React.useEffect(() => {
        if (validateServiceAccount?.isSuccess && validateServiceAccount?.data?.data) {
            setValidForm({ ...validForm, isInvalidServiceAccount: false });
        }
        if (validateServiceAccount?.isSuccess && !validateServiceAccount?.data?.data) {
            setValidForm({ ...validForm, isInvalidServiceAccount: true });
        }
    }, [validateServiceAccount?.isSuccess]);

    React.useEffect(() => {
        if (order?.serviceAccount) {
            validateServiceAccount.mutate();
        }
    }, [order?.serviceAccount]);

    React.useEffect(() => {
        setIsLoading(validateServiceAccount?.isLoading);
    }, [validateServiceAccount]);

    React.useEffect(() => {
        if (!order?.serviceAccount && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.serviceAccount, order?.isTouched]);

    return (
        <Box display="flex" alignItems="flex-start" flexDirection="column" data-testid="service-account-text">
            <CssTextField
                data-testid="app-name-text"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                disabled={!order?.adoPlatform}
                autoComplete="off"
                label="Service Account *"
                error={helperText}
                helperText={getHelperText()}
                value={order?.serviceAccount}
                onChange={(e) => setServiceAccount(e.target.value)}
                className={validateInput()}
            />
            {/* {validForm?.isInvalidServiceAccount && (
                <Box sx={ProjectOnboardingStyles.error}>
                    {validForm?.isInvalidServiceAccount &&
                        (validateServiceAccount?.data?.messages?.length > 0 || validateServiceAccount?.error?.length > 0) && (
                            <Box sx={ProjectOnboardingStyles.error}>{validateServiceAccount?.data?.messages?.[0] || validateServiceAccount?.error?.[0]}</Box>
                        )}
                </Box>
            )} */}
        </Box>
    );
};

export default React.memo(ServiceAccount);
