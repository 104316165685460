import React from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { FiAlertTriangle } from 'react-icons/fi';
import { toggleConfirmModel } from 'pages/myidm/store';

export const ConfirmModal = () => {
    const [modal, setModal] = useRecoilState(toggleConfirmModel);

    const handleYes = () => {
        if (modal?.type === 'delete' && !modal?.removeAll) setModal({ ...modal, isOpen: false, action: true });

        if (modal?.type === 'delete' && modal?.removeAll) setModal({ ...modal, isOpen: false, removeAllAction: true });
    };
    const handleNo = () => {
        setModal({ ...modal, isOpen: false, action: false });
    };
    return (
        <>
            <Dialog open={modal?.isOpen} onClose={handleNo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent sx={{ maxWidth: modal?.type === 'delete' ? '375px' : '450px' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" style={{ textAlign: 'center' }}>
                        {modal?.type === 'delete' && <FiAlertTriangle color="#d02424" size={70} />}
                        <Typography variant="h5">{modal?.message}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button variant="contained" onClick={() => handleYes()} className="uppercase tracking-wider text-sm" data-testid="confirmYes">
                            Yes
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ marginLeft: '10px' }}
                            onClick={() => handleNo()}
                            layout="outline"
                            className="uppercase tracking-wider text-sm"
                            data-testid="confirmNo"
                        >
                            No
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ConfirmModal;
