/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { aksCatalogServiceCode, kafkaCatalogServiceCode } from 'utils/catalogServiceCode';
import { ddRegion, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Region = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDRegion, setLzDDRegion] = useRecoilState(ddRegion);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    let envEndpoint;
    switch (landZoneForm?.cloudService) {
        case aksCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipRegions}/AKS/${landZoneForm?.department}/${landZoneForm?.environment}`;
            break;
        case kafkaCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipRegions}/Kafka/${landZoneForm?.department}/${landZoneForm?.environment}`;
            break;
        default:
            envEndpoint = endPoints.landingZones.regions;
    }
    const regionApi = useMutation(() => axios.get(envEndpoint));
    const setRegion = (region, regionDetails) => {
        // if (!lzModal?.lzDetails) {
        setLandingZoneForm({
            ...landZoneForm,
            region,
            regionDetails,
            geoRecoveryRegion: null,
            geoRecoveryRegionDetails: null,
            resGroup: null,
            resGroupDetails: null,
            vNet: null,
            vNetDetails: null,
            subNet: null,
            subNetDetails: null,
            nwSecGrp: null,
            nwSecGrpDetails: null,
            appSecGrp: null,
            appSecGrpDetails: null,
            availSet: null,
            availSetDetails: null,
            availZoneDetails: [],
            vnetSubnet: [{ networkSubscription: null, networkSubscriptionDetails: null, vNet: null, vNetDetails: null, subNet: null, subNetDetails: null }],
            connections:
                landZoneForm?.connections?.map((item) => ({
                    componentId: item?.componentId,
                    landingZone: null,
                    landingZoneDetails: null,
                    name: item?.name,
                    provider: item?.provider,
                    service: item?.service,
                })) || [],
        });
        // }
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setRegion(selected?.label, selected);
        } else {
            setRegion(null, null);
        }
    };
    const isDisable = () => {
        if (lzModal?.type === 'edit') {
            return ![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService);
        }
        return false;
    };
    React.useEffect(() => {
        if (regionApi?.isSuccess) {
            if ([aksCatalogServiceCode].includes(landZoneForm?.cloudService)) {
                setOptions(regionApi?.data?.data?.map((app) => ({ label: app.name, value: app.region, data: app })));
            } else if ([kafkaCatalogServiceCode].includes(landZoneForm?.cloudService)) {
                setOptions(regionApi?.data?.data?.filter((app) => app?.primary === true).map((app) => ({ label: app.name, value: app.region, data: app })));
            } else {
                setOptions(regionApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.name })));
            }
            setLzDDRegion({ ...lzDDRegion, isLoaded: true });
        }
    }, [regionApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const region = options.find((value) => lzModal?.lzDetails?.region?.name === value?.label);
            if (region) setLzDDRegion({ ...lzDDRegion, isLoaded: true, data: region });
        }
    }, [options]);
    React.useEffect(() => {
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) && landZoneForm?.environment) {
            regionApi.mutate();
        }
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) && !landZoneForm?.environment) {
            setOptions([]);
        }
    }, [landZoneForm?.environment]);
    React.useEffect(() => {
        if (![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landZoneForm?.cloudService)) regionApi.mutate();
    }, []);
    return (
        <Box bgcolor="white">
            <Select
                key="regionName"
                value={landZoneForm?.region || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={regionApi.isLoading || isDisable()}
                isLoading={regionApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        {...params}
                        label={[kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) ? 'Primary Region*' : 'Region Name*'}
                        placeholder={[kafkaCatalogServiceCode].includes(landZoneForm?.cloudService) ? 'Primary Region' : 'Region Name'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Region);
