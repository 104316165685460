/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack, Grid, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { ddGeoRecoveryRegion, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const GeoRecoveryRegion = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDGeoRecoveryRegion, setLzDDGeoRecoveryRegion] = useRecoilState(ddGeoRecoveryRegion);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);

    const geoRecoveryRegionApi = useMutation(() => axios.get(`${endPoints.landingZones.geoRecoveryRegion}/${landZoneForm?.regionDetails?.value}`));

    const setGeoRecoveryRegion = (geoRecoveryRegion, geoRecoveryRegionDetails) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                geoRecoveryRegion,
                geoRecoveryRegionDetails,
            });
        }
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setGeoRecoveryRegion(selected?.label, selected);
        } else {
            setGeoRecoveryRegion(null, null);
        }
    };
    React.useEffect(() => {
        if (geoRecoveryRegionApi?.isSuccess) {
            setOptions([{ label: geoRecoveryRegionApi?.data?.data?.locationName, value: geoRecoveryRegionApi?.data?.data?.locationCode }]);
            setLzDDGeoRecoveryRegion({ ...lzDDGeoRecoveryRegion, isLoaded: true });
        }
    }, [geoRecoveryRegionApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const geoRecoveryRegion = options.find((value) => lzModal?.lzDetails?.config?.geoRecoveryRegion?.name === value?.label);
            if (geoRecoveryRegion) setLzDDGeoRecoveryRegion({ ...lzDDGeoRecoveryRegion, isLoaded: true, data: geoRecoveryRegion });
        }
    }, [options]);
    React.useEffect(() => {
        if (landZoneForm?.region) {
            geoRecoveryRegionApi.mutate();
        }
    }, [landZoneForm?.region]);
    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Select
                    key="georecoveryRegionName"
                    value={landZoneForm?.geoRecoveryRegion || null}
                    options={options}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    disabled={geoRecoveryRegionApi.isLoading || landZoneForm?.environment !== 'Production'}
                    isLoading={geoRecoveryRegionApi.isLoading}
                    disableClearable={false}
                    renderInput={(params) => (
                        <CssTextField
                            {...params}
                            label="Geo-Replication Region"
                            placeholder="Geo-Replication Region"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                        />
                    )}
                />
                <InfoImg
                    title={
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>Geo-replication region is enabled for production environment only.</Typography>
                                    <Typography>Geo-replication region should be in pairing as below:</Typography>
                                    <Typography>1) East US - South Central US</Typography>
                                    <Typography>2) Australia East - Southeast Asia</Typography>
                                    <Typography>3) Germany West Central - UK South</Typography>
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </Stack>
        </>
    );
};

export default React.memo(GeoRecoveryRegion);
