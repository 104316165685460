export const getOrderPayload = ({ order }) => {
    return {
        uBusinessApplication: order?.businessApp,
        uName: order?.appService,
        uEnvironment: order?.environment,
        uSupportGroup: order?.supportGrp,
        uBusinessCriticality: order?.businessCriticality,
        uSoxClassifications: (order?.soxClassification === 'Yes')?.toString(),
        uImpCompany: order?.sector,
        uShortDescription: order?.shortDescription,
    };
};

export const resetOrder = {
    catalogCode: null,
    businessApp: null,
    businessAppDetails: null,
    appService: null,
    environment: null,
    supportGrp: null,
    businessCriticality: null,
    soxClassification: 'No',
    sector: null,
    shortDescription: null,
    isTouched: false,
};
