/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddCloudService, landingZoneForm, toggleCreateLandingZone } from 'pages/app-profiles/store';
import { CssTextField } from 'pages/app-profiles/landing-zones/LandingZoneStyle';
import { eipStackCatalogServiceCode } from 'utils/catalogServiceCode';
import { getLandingZoneFromLocalStorage, resetLandingZone } from '../../utils';

const CloudService = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDCloudService, setLzDDCloudService] = useRecoilState(ddCloudService);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const cloudServiceApi = useMutation((payload) => axios.get(`${endPoints.landingZones.cloudService}/${payload}/cloudservices`));
    const setcloudService = (cloudService, cloudServiceDetails) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                cloudService,
                cloudServiceCode: cloudServiceDetails?.catalogDefinitionId,
                cloudServiceDetails,
                environment: null,
                environmentDetails: null,
                region: null,
                regionDetails: null,
                ...resetLandingZone(cloudService),
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setcloudService(null, null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setcloudService(selected?.value, selected);
        } else {
            setcloudService(null, null);
        }
    };
    const getDistinctByCloudService = (cloudServices) =>
        cloudServices?.reduce((memo, e1) => {
            const matches = memo.filter((e2) => e1.value === e2.value);
            if (matches.length === 0) memo.push(e1);
            return memo;
        }, []);
    React.useEffect(() => {
        if (cloudServiceApi?.isSuccess) {
            setOptions(
                getDistinctByCloudService(
                    cloudServiceApi?.data?.data?.map((app) => ({
                        label: app.cloudService,
                        value: app.cloudService,
                        catalogDefinitionId: app.catalogDefinitionId,
                    }))
                )
            );
            setLzDDCloudService({ ...lzDDCloudService, isLoaded: true });
        }
    }, [cloudServiceApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const cloudService = options.find((value) => lzModal?.lzDetails?.cloudService === value?.value);
            if (cloudService) setLzDDCloudService({ ...lzDDCloudService, isLoaded: true, data: cloudService });
        }
    }, [options]);
    React.useEffect(() => {
        if (landZoneForm.cloudProvider && !lzModal?.lzDetails) {
            resetOptions();
            cloudServiceApi.mutate(landZoneForm.cloudProvider);
        }
    }, [landZoneForm.cloudProvider]);
    React.useEffect(() => {
        if (lzModal.lzDetails?.cloudProvider && lzModal?.lzDetails) {
            cloudServiceApi.mutate(lzModal.lzDetails?.cloudProvider);
        }
    }, [lzModal.lzDetails?.cloudProvider]);
    React.useEffect(() => {
        if (lzModal?.isLoadedAllDD && lzModal?.lzDetails) handleOnSelectChange(lzDDCloudService?.data);
    }, [landZoneForm.cloudProvider]);
    return (
        <Box bgcolor="white">
            <Select
                key="CatalogService"
                value={landZoneForm?.cloudService || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    cloudServiceApi.isLoading ||
                    lzModal?.type === 'edit' ||
                    (lzModal?.type === 'create' && getLandingZoneFromLocalStorage() && eipStackCatalogServiceCode !== landZoneForm?.cloudService)
                }
                isLoading={cloudServiceApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Service*"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(CloudService);
