import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, FormControl, FormControlLabel, Box } from '@mui/material';
import Select from 'pages/reference-implementation/components/select';
import { CustomRadioGroup, CustomRadio, RIStyles } from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';

const CosmosPostgresSql = (props) => {
    const { resource, handleDrawerAction } = props;
    const [formData, setFormData] = useState({
        size: resource?.configureData?.size || '',
        isHAEnabled: resource?.configureData?.isHAEnabled ?? 'No',
    });
    const appGetApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.cosmosPostgreSizes}`));
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formData.size) {
            newErrors.size = { type: 'required', message: 'Size is required' };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            size: true,
        });
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );
    const formatSizeLabel = (size) => {
        const storageGb = size?.storageMb / 1024;
        const formattedSize = `${size?.size} (Storage: ${storageGb} GB, nodes: ${size?.nodeCount}, vCores: ${size?.vCores})`;
        return { label: formattedSize, value: size?.size };
    };

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    const options = appGetApi?.data?.data?.postgreSizes?.map((size) => formatSizeLabel(size));
    const value = React.useMemo(() => options?.find((item) => item?.value === formData?.size) ?? null, [formData?.size, appGetApi]);

    useEffect(() => {
        appGetApi.mutate();
    }, []);
    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            key="size"
                            label="Size *"
                            placeholder="Select size"
                            options={options}
                            handleOnSelect={(selectedValue) => handleOnSelectChange('size', selectedValue?.value)}
                            value={value}
                            isLoading={appGetApi.isLoading}
                            isRequired={shouldShowError('size')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={RIStyles?.configuration?.cosmos?.heading}>High Availability Enabled</Typography>
                        <FormControl>
                            <CustomRadioGroup
                                aria-label="HighAvailabilityEnabled"
                                name="highAvailabilityEnabled"
                                row
                                value={formData.isHAEnabled}
                                onChange={(event) => handleOnSelectChange('isHAEnabled', event.target.value)}
                            >
                                <FormControlLabel value="Yes" control={<CustomRadio />} label="Yes" disabled />
                                <FormControlLabel
                                    value="No"
                                    sx={{
                                        marginLeft: '60px',
                                    }}
                                    control={<CustomRadio />}
                                    label="No"
                                    disabled
                                />
                            </CustomRadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

CosmosPostgresSql.propTypes = {
    resource: PropTypes.any.isRequired,
    handleDrawerAction: PropTypes.func.isRequired,
};

export default CosmosPostgresSql;
