import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import RIAccordion from 'pages/reference-implementation/components/accordion';
import ResourceWrapper from './ResourceWrapper';

const MultipleResourceWrapper = (props) => {
    const { resources, defaultExpanded } = props;
    return (
        <Box>
            {resources?.map((resource, index) => (
                <RIAccordion
                    title={resource?.['Architecture Component Name']}
                    key={`ri-resource-${index}`}
                    defaultExpanded={defaultExpanded}
                    classN
                    open={defaultExpanded || index === resources?.length - 1}
                >
                    <ResourceWrapper resources={resource?.resources} />
                </RIAccordion>
            ))}
        </Box>
    );
};

MultipleResourceWrapper.propTypes = {
    resources: PropTypes.any,
    defaultExpanded: PropTypes.bool,
};

MultipleResourceWrapper.defaultProps = {
    resources: null,
    defaultExpanded: false,
};

export default MultipleResourceWrapper;
