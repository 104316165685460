import { format } from 'date-fns';

export const isReadytoSubmit = ({ order, isvalidForm }) => {
    return (
        !order?.applicationCI ||
        !order?.sector ||
        !order?.environment ||
        !order?.source ||
        !order?.sourceValue ||
        !order?.sourceDirectory ||
        !order?.sourceAssignmentGrp ||
        !order?.sasUrl ||
        !order?.sasToken ||
        !order?.tokenExpiry ||
        !order?.destinationDirectory ||
        !order?.destinationAssignmentGrp ||
        !order?.appOwner ||
        !order?.sourceEmail ||
        !order?.destinationEmail ||
        !order?.ritmNumber ||
        isvalidForm?.isSourceDirectoryValid ||
        isvalidForm?.isSourceAssignmentGrpValid ||
        isvalidForm?.isSourceFileNamePatternValid ||
        isvalidForm?.isRitmNumberValid ||
        isvalidForm?.isSasUrlValid ||
        isvalidForm?.isSasTokenValid ||
        isvalidForm?.isDestinationDirectoryValid ||
        isvalidForm?.isRitmCheckboxValid ||
        isvalidForm?.isDestinationAssignmentGrpValid
    );
};

export const getOrderPayload = ({ order }) => {
    return {
        applicationName: order?.applicationCI,
        sector: order?.sector,
        environment: order?.environment,
        source: order?.source,
        sourceValues: order?.sourceValue,
        sourceDirectory: order?.sourceDirectory,
        sourceAssignmentGroup: order?.sourceAssignmentGrp,
        sourceFilenamePattern: order?.sourceFileNamePatternList.map((fileNamePattern) => fileNamePattern?.fileName),
        sasUrl: order?.sasUrl,
        sasToken: order?.sasToken,
        tokenExpiry: format(new Date(order?.tokenExpiry), 'yyyyMMdd'),
        destinationDirectory: order?.destinationDirectory,
        destinationAssignmentGroup: order?.destinationAssignmentGrp,
        sourceEmail: order?.sourceEmail,
        destinationEmail: order?.destinationEmail,
        applicationOwner: order?.appOwnerDetails?.gpid,
        ritmNumber: order?.ritmNumber?.length ? order?.ritmNumber?.join(', ') : null,
    };
};
export const resetOrder = {
    applicationCI: null,
    applicationCIDetails: null,
    sector: null,
    environment: null,
    source: null,
    sourceValue: null,
    sourceValues: null,
    sourceDirectory: null,
    sourceAssignmentGrp: null,
    sourceFileNamePattern: null,
    sourceFileNamePatternList: [{ fileName: null }],
    sasUrl: null,
    sasToken: null,
    destinationDirectory: null,
    destinationAssignmentGrp: null,
    appOwner: null,
    appOwnerDetails: null,
    tokenExpiry: null,
    isTouched: false,
    sourceEmail: false,
    destinationEmail: false,
    ritmNumber: [],
};

export const resetvalidProvisionForm = {
    isSourceDirectoryValid: false,
    isSourceAssignmentGrpValid: false,
    isSourceFileNamePatternValid: false,
    isSasUrlValid: false,
    isSasTokenValid: false,
    isDestinationDirectoryValid: false,
    isDestinationAssignmentGrpValid: false,
};

export const patternRegex = (order) => {
    return order && order !== '' && order !== undefined && !/^[a-zA-Z0-9-*_]{1,}\.[a-zA-Z0-9*]{2,}$/.test(order);
};

export const isSourceFilePatternError = (validationMessage = [], adGroup = null) => {
    let errorCount = 0;
    let sourceFilePatternError = false;
    validationMessage?.forEach((item) => {
        if (item.includes(adGroup)) {
            // eslint-disable-next-line no-plusplus
            errorCount++;
        }
    });
    sourceFilePatternError = errorCount !== 0;
    return sourceFilePatternError;
};

export const showRITMForEnvironments = ['Production', 'QA', 'Pre-Prod'];
