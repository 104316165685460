export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: data?.itemDetail?.catalogService?.sector,
        },
        vaultAccessPolicy: catalogConfig?.vaultAccessPolicy,
        keyVaultName: catalogConfig?.keyVaultName,
        pricingTier: catalogConfig?.pricingTier,
        emptyCost: data?.itemDetail?.catalogService?.estimatedCost,
        tagsQc: { ...catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        vaultAccessPolicy: catalogConfig?.vaultAccessPolicy,
        keyVaultName: catalogConfig?.keyVaultName,
        pricingTier: catalogConfig?.pricingTier,
        emptyCost: data?.itemDetail?.catalogService?.estimatedCost,
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    environment: null,
    pricingTier: 'standard',
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    vaultAccessPolicy: null,
    keyVaultName: null,
    hCode: null,
    hCodeDetails: null,
    appTier: 'Azure Key Vault',
    appTierCode: 'AKV',
    envDetails: null,
    tags: [],
    estimatedCost: {},
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetForQuickConfig = {
    environment: null,
    region: null,
    regionDisplayName: null,
    keyVaultName: '',
    vaultAccessPolicy: null,
    pricingTier: 'standard',
    project: null,
    appTier: 'Azure Key Vault',
    appTierCode: 'AKV',
    envDetails: null,
};

export const resetEnvironmentDetails = {
    Subscription: '-',
    'Resource Group': '-',
    'Approval Manager': '-',
    'Soft-Delete': 'Enabled',
    'Day to retain deleted Vaults': '90 Days',
    'Purge Protection': 'Enabled',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isUpdatedToCart: false,
    isCostSplitOpen: false,
};

export const resetCostEstimateDetails = {
    details: null,
    totalRecurringCost: {
        item: 'Total Recurring',
        frequency: 'per 10000 operations',
        estCost: null,
        currency: 'USD',
    },
    totalOneTimeCost: {
        item: 'Total One Time',
        frequency: 'One Time',
        estCost: null,
        currency: 'USD',
    },
    qty: null,
    notes: 'Total Est. Cost displayed is for the frequency of per 10000 operations & any Pepsico internal chargebacks',
};
