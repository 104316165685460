import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles, { CustomRadioGroup, CustomRadio } from 'pages/provisioning/day2-operation-paas/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const Performance = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);

    const setPerformanceValue = (performance) => {
        setOrder({
            ...order,
            performance,
        });
    };

    const handleOnSelectChange = (selected) => {
        setPerformanceValue(selected);
    };

    return (
        <Stack direction="row" sx={ProvisioningStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '37.13px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' }} id="performanceRadioGrp">
                    Performance
                </Box>
                <CustomRadioGroup
                    aria-label="performance"
                    name="performanceRadioBtnGroup"
                    row
                    value={order?.performance}
                    onChange={(event, value) => handleOnSelectChange(value)}
                >
                    <FormControlLabel value="Standard" control={<CustomRadio />} label="Standard" />
                    <Stack direction="row">
                        <FormControlLabel value="Premium" sx={ProvisioningStyles.radioFields.radioBtnNoForm} control={<CustomRadio />} label="Premium" />
                        <Box sx={ProvisioningStyles?.helpImg}>
                            <HelpImg title="Please select performance" />
                        </Box>
                    </Stack>
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default Performance;
