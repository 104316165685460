import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isBusinessAppValid } from 'pages/reference-implementation/provisioning/RI002/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const BusinessAppText = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setBusinessCITextVaild] = useRecoilState(isBusinessAppValid);
    const [helperText, setHelperText] = React.useState(false);
    const setBusinessCIText = (businessApp = null) => {
        setOrder({
            ...order,
            businessApp,
            appService: `${businessApp} ${['production']?.includes(order?.environment?.toLowerCase()) ? 'PROD' : 'NON-PROD'}`,
        });
    };

    React.useEffect(() => {
        if (order?.businessApp && order?.businessApp !== '' && order?.businessApp !== undefined && !/^[a-zA-Z0-9_-\s\\(\\)]{4,64}$/.test(order?.businessApp)) {
            setHelperText(true);
            setBusinessCITextVaild(false);
            setOrder({
                ...order,
                appShortName: null,
                appService: null,
            });
        } else {
            setHelperText(false);
            setBusinessCITextVaild(true);
        }
    }, [order?.businessApp]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.businessApp) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.businessApp && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessApp, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Business Application" isRequired />}
                error={helperText}
                helperText={helperText && <span>Business application must be min 4 chars and accepts only hyphen(-) & underscore(_) as special chars.</span>}
                value={order?.businessApp || ''}
                onChange={(e) => setBusinessCIText(e.target.value)}
                className={validateInput()}
            />
        </Box>
    );
};

export default BusinessAppText;
