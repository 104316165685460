import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/sso-okta/store';

export const ApplicationType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const appTypeApi = useMutation(() => axios.get(endPoints.ssoOkta.applicationType));

    const setEnv = (appType) => {
        setOrder({
            ...order,
            appType,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (appTypeValue) => options.find((option) => appTypeValue === option.value);

    const handleOnSelectChange = (selected) => setEnv(selected.value);

    React.useEffect(() => {
        if (appTypeApi?.isSuccess) {
            setOptions(appTypeApi?.data?.data?.map((app) => ({ label: app?.name, value: app?.name, data: app })));
        }
    }, [appTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.appType) {
            setOrder({ ...order, appType: options?.[0].value });
        }
        if (options && options?.length && order?.appType) {
            const appType = isExistsInOptions(order?.appType);
            setOrder({ ...order, appType: appType?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        appTypeApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.appType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.appType, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="app-type-autocomplete"
                key="appType"
                label="Application Type *"
                value={order?.appType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={appTypeApi.isLoading}
                isLoading={appTypeApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ApplicationType);
