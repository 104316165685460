import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import DialogModal from 'components/dialog';
import SuccessImage from 'assets/images/success.png';
import { orderId } from 'pages/reference-implementation/store';
import { selectOrder } from 'pages/orders/store';
import { Style } from 'pages/reference-implementation/components/order-success/style';

export const OrderSuccessDialog = ({ isOpen, reset, redirectUrl }) => {
    const history = useHistory();
    const [isLoading] = React.useState(false);
    const [cmpOrderId] = useRecoilState(orderId);
    const setSelectedOrder = useSetRecoilState(selectOrder);
    const handleOrderIdOnClick = async () => {
        await setSelectedOrder({ orderId: cmpOrderId });
        history.push('/orders');
    };
    return (
        <DialogModal open={isOpen} handleClose={() => {}}>
            <Box sx={Style.wrapper}>
                <img src={SuccessImage} alt="" />
                <Typography gutterBottom sx={Style.requsetTypography}>
                    Your Request is Submitted
                </Typography>
                <Typography gutterBottom sx={Style.thankyouTypography}>
                    Thank you for using Cloud Marketplace. Your order has been submitted successfully. Please go to
                    <Typography
                        component="span"
                        variant="body2"
                        sx={Style.orderIdTypography}
                        onClick={() => {
                            handleOrderIdOnClick();
                        }}
                        data-testid="order-id"
                    >
                        {cmpOrderId}
                    </Typography>
                    to review your order
                </Typography>
                <Box sx={Style.newOrderBox}>
                    <Button
                        onClick={() => {
                            reset();
                        }}
                        disabled={isLoading}
                        sx={Style.newOrderBtn}
                        disableElevation
                        variant="contained"
                        color="cmpPrimary"
                        data-testid="place-new-order"
                    >
                        Place New Order
                    </Button>
                    <Button
                        onClick={() => {
                            history.push(redirectUrl);
                        }}
                        to={redirectUrl}
                        component={Link}
                        variant="outlined"
                        color="cmpPrimary"
                        disableElevation
                        disabled={isLoading}
                        sx={Style.backHome}
                        data-testid="back"
                    >
                        Back to Home
                    </Button>
                </Box>
            </Box>
        </DialogModal>
    );
};

OrderSuccessDialog.propTypes = {
    reset: PropTypes.func,
    redirectUrl: PropTypes.string,
    isOpen: PropTypes.bool,
};

OrderSuccessDialog.defaultProps = {
    reset: () => null,
    redirectUrl: undefined,
    isOpen: false,
};
export default React.memo(OrderSuccessDialog);
