const styles = {
    infoIcon: { color: '#0047BA', fontSize: '20px', marginTop: '3px' },
    canceltBtn: {
        width: (theme) => theme.spacing(12.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
        marginRight: 1,
        border: '2px solid #0047BA',
    },
    submitBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
};

export default styles;
