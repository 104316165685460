import React from 'react';
import { Stack, Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { RIStyles } from 'pages/reference-implementation/Style';
import ProvisioningStyles from 'pages/provisioning/kafka/style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const TransactionalPrincipalId = (props) => {
    const { isRequired, handleOnSelect, TransactionalPrincipalIdvalue, errors } = props;
    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                data-testid="TransactionalPrincipalId"
                label="Transactional Principal Id*"
                autoComplete="off"
                error={isRequired || errors?.showError}
                helperText={errors?.showError && <span style={{ color: '#FF3939' }}>{errors?.showMessage}</span>}
                value={TransactionalPrincipalIdvalue || ''}
                onChange={(event) => handleOnSelect(event.target.value)}
                className={errors?.showError || isRequired ? 'validate' : ''}
            />
            <HelpImg
                maxmWidth="450px"
                title={
                    <>
                        <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                            <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>1) Alpha-numeric value</Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>2) Min.: 3 chars to Max.: 209 chars</Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            3) Can contain only underscore(_) as special char
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            4) Should not start or end with any special char
                                        </Typography>
                                        <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                            5) Should not contain consecutive underscores
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Stack>
    );
};

TransactionalPrincipalId.propTypes = {
    // resource: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
    TransactionalPrincipalIdvalue: PropTypes.any,
    // handleShowError: PropTypes?.func,
    errors: PropTypes.any,
};

TransactionalPrincipalId.defaultProps = {
    // resource: null,
    handleOnSelect: () => {},
    isRequired: false,
    TransactionalPrincipalIdvalue: null,
    // handleShowError: () => {},
    errors: {},
};

export default TransactionalPrincipalId;
