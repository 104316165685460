import React from 'react';
import Modal from 'components/modal';
import { useRecoilState } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import PreConfig from 'assets/images/PreConfig.png';
import { Box, Typography } from '@mui/material';
import Btn from 'components/button';
import Empty from 'components/empty';
import { togglecatalogDetails } from 'pages/provisioning/store/catalog';
import LabelValue from 'components/label-value';
import EditCatalogDetails from './EditCatalogDetails';
import { getCatalogDetails } from './utils';
import styles from './styles';

function CatalogDetails() {
    const { catalogDefinitionId } = useParams();
    const history = useHistory();
    const [selectedCatalog, setSelectedCatalog] = useRecoilState(togglecatalogDetails);
    const [catalogDetails, setCatalogDetails] = React.useState(null);

    const cataloDetailsApi = useMutation(() => axios.get(`${endPoints.catalog.get}/${catalogDefinitionId}`));
    React.useEffect(() => {
        if (cataloDetailsApi?.data?.data) {
            setCatalogDetails(getCatalogDetails(cataloDetailsApi?.data?.data));
        }
    }, [cataloDetailsApi.isSuccess]);
    React.useEffect(() => {
        cataloDetailsApi.mutate();
    }, []);

    return (
        <div>
            <Modal
                data-testid="catalog-details"
                title={`${selectedCatalog?.isEdit ? 'Edit Catalog Details' : 'Catalog Details'}`}
                handleClose={() => {
                    setSelectedCatalog({ ...selectedCatalog, isOpen: false, isEdit: false });
                    history.push('/catalogs');
                }}
                width="75%"
                open={selectedCatalog?.isOpen}
            >
                <>
                    {cataloDetailsApi?.isLoading && <Empty isLoading={cataloDetailsApi?.isLoading} color="#0047BA" data-testid="emptyLoading" />}
                    {!cataloDetailsApi?.isLoading && !catalogDetails && (
                        <Box width="100%" sx={styles?.emptycontainer} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <img src={PreConfig} alt="" />
                            <Typography sx={styles?.emptymsg}>Sorry, No Catalog details are availble</Typography>
                        </Box>
                    )}
                    {!cataloDetailsApi?.isLoading && !selectedCatalog.isEdit && catalogDetails && (
                        <Box>
                            <>
                                {!cataloDetailsApi?.isLoading &&
                                    Object.keys(catalogDetails).map((key) => (
                                        <Box className="app-details" key={`catalog${key}`} data-testid="catalogId">
                                            <LabelValue key={[key]} label={key} value={catalogDetails[key]} />
                                        </Box>
                                    ))}
                            </>
                            <Box sx={styles?.catalogdetails?.buttonWrapper}>
                                <Btn
                                    sx={styles?.catalogdetails?.editBtn}
                                    size="medium"
                                    onClick={() => {
                                        setSelectedCatalog({ ...selectedCatalog, isOpen: true, isEdit: true });
                                    }}
                                    variant="contained"
                                    color="cmpPrimary"
                                >
                                    Edit
                                </Btn>
                                <Btn
                                    sx={styles?.catalogdetails?.cancelBtn}
                                    size="medium"
                                    onClick={() => {
                                        setSelectedCatalog({ ...selectedCatalog, isOpen: false });
                                        history.push('/catalogs');
                                    }}
                                    variant="outlined"
                                    color="cmpWarning"
                                    layout="outline"
                                >
                                    Cancel
                                </Btn>
                            </Box>
                        </Box>
                    )}
                    {!cataloDetailsApi?.isLoading && selectedCatalog?.isEdit && <EditCatalogDetails data={cataloDetailsApi?.data?.data} />}
                </>
            </Modal>
        </div>
    );
}

export default CatalogDetails;
