/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';

const PackageTypes = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const packageTypeApi = useMutation(() => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.packageTypesApi}`));
    const setPackageType = (packageType, packageTypeDetails) => {
        setOrder({
            ...order,
            packageType,
            packageTypeDetails,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setPackageType(selected.value, selected);

    React.useEffect(() => {
        if (packageTypeApi?.isSuccess) {
            setOptions(packageTypeApi?.data?.data?.packageTypes?.map((app) => ({ label: app?.name, value: app?.name, data: app })));
        }
    }, [packageTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.packageType) {
            setOrder({ ...order, packageType: options?.[0].value, packageTypeDetails: options?.[0] });
        }
        if (options && options?.length && order?.packageType) {
            const packages = isExistsInOptions(order?.packageType);
            setOrder({ ...order, packageType: packages?.value, packageTypeDetails: packages });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.buildTool) {
            resetOptions();
            packageTypeApi.mutate();
        }
    }, [order?.buildTool]);

    React.useEffect(() => {
        if (!order?.packageType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.packageType, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="packageTypes"
                label="Package Types *"
                value={order?.packageType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={packageTypeApi.isLoading}
                isLoading={packageTypeApi.isLoading}
                isRequired={isTouched}
                data-testid="packageType-auto"
            />
        </Box>
    );
};

export default React.memo(PackageTypes);
