/* eslint-disable import/no-named-default */

import { default as Provider } from './Provider';
import { default as Service } from './Service';
import { default as Environments } from './Environments';
import { default as Region } from './Region';
import { default as Subscription } from './Subscription';
import { default as ResourceGroup } from './ResourceGroup';
import { default as VNet } from './VNet';
import { default as Subnet } from './Subnet';
import { default as AvailabilitySet } from './AvailabilitySet';
import { default as AvailabilityZone } from './AvailabilityZone';
import { default as LandingZoneLabel } from './LandingZoneLabel';
import { default as LandingZoneDesc } from './LandingZoneDesc';
import { default as NetworkSubscription } from './NetworkSubscription';
import { default as ServiceEndPointVNet } from './ServiceEndPointVNet';
import { default as ServiceEndPointSubNet } from './ServiceEndPointSubNet';
import { default as DataClassification } from './DataClassification';
import { default as Department } from './Department';
import { default as Cluster } from './Cluster';
import { default as ISADocument } from './IsaDocument';
import { default as BusinessUnit } from './BusinessUnit';
import { default as NetworkConnectivity } from './NetworkConnectivity';
import { default as AppName } from './AppName';
import { default as Sku } from './Sku';
import { default as Type } from './Type';
import { default as EventHubSubNet } from './EventHubSubNet';
import { default as EventHubVNet } from './EventHubVNet';
import { default as Ldap } from './Ldap';
import { default as ProjectName } from './ProjectName';
import { default as GeoRecoveryRegion } from './GeoRecoveryRegion';
import { default as ComponentName } from './ComponentName';
import { default as LandingZones } from './LandingZones';
import { default as SourceResource } from './SourceResource';
import { default as DestinationResource } from './DestinationResource';
import { default as ConnectionType } from './ConnectionType';
import { default as CloudProvider } from './CloudProvider';
import { default as CloudService } from './CloudService';

const Forms = {
    Provider,
    Service,
    Environments,
    Region,
    Subscription,
    ResourceGroup,
    VNet,
    Subnet,
    AvailabilitySet,
    AvailabilityZone,
    LandingZoneLabel,
    LandingZoneDesc,
    NetworkSubscription,
    ServiceEndPointVNet,
    ServiceEndPointSubNet,
    DataClassification,
    Department,
    Cluster,
    ISADocument,
    BusinessUnit,
    NetworkConnectivity,
    AppName,
    Sku,
    Type,
    EventHubSubNet,
    EventHubVNet,
    Ldap,
    ProjectName,
    GeoRecoveryRegion,
    ComponentName,
    LandingZones,
    SourceResource,
    DestinationResource,
    ConnectionType,
    CloudProvider,
    CloudService,
};

export default Forms;
