import React from 'react';
import { Stack } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { profileForm, toggleCreateProfile } from 'pages/app-profiles/store';
import profileStyle, { CssTextField } from '../../style';

const filter = createFilterOptions();
const BusinessCI = () => {
    const { t } = useTranslation();
    const [modal] = useRecoilState(toggleCreateProfile);
    const [form, setForm] = useRecoilState(profileForm);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);
    const bCIGetApi = useMutation(() => axios.get(`${endPoints.appProfile.businessCI}/${ccInputValue?.toUpperCase()}`));
    const bCIPostApi = useMutation((payload) => axios.post(`${endPoints.appProfile.businessCI}`, payload));
    const setHCode = (businessCI, businessCIDetails) => {
        setForm({
            ...form,
            businessCI,
            businessCIDetails,
            appName: businessCI,
            recoveryPriority: businessCIDetails?.drRecoveryPriority,
        });
    };
    const resetOptions = () => {
        setOptions(initOptions);
        setHCode(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                bCIGetApi.mutate();
            }, 1000),
        []
    );
    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);
    React.useEffect(() => {
        if (bCIGetApi?.isSuccess) {
            if (bCIGetApi?.data?.data?.applications) {
                const combinedValue = bCIGetApi?.data?.data?.applications.map((app) => ({
                    label: `${app.ciName}`,
                    drRecoveryPriority: app?.drRecoveryPriority,
                }));
                setOptions(combinedValue);
            }
        }
    }, [bCIGetApi?.isSuccess]);
    React.useEffect(() => {
        if (bCIPostApi?.isSuccess) {
            if (bCIPostApi?.data?.data?.applications) {
                const combinedValue = bCIPostApi?.data?.data?.applications.map((app) => ({
                    label: `${app.ciName}`,
                    drRecoveryPriority: app?.drRecoveryPriority,
                }));
                if (form?.businessCIDetails) combinedValue.push(form?.businessCIDetails);
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [bCIPostApi?.isSuccess]);
    React.useEffect(() => {
        bCIPostApi.mutate({
            pageOffset: 0,
            pageLimit: 20,
        });
    }, []);
    React.useEffect(() => {
        if (value === null && initOptions?.length) {
            resetOptions();
        } else if (value) {
            setHCode(value?.label, value);
        }
    }, [value]);
    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Autocomplete
                    key="businessCI"
                    size="small"
                    loading={bCIGetApi?.isLoading}
                    value={form?.businessCIDetails || null}
                    getOptionLabel={(option) => option.label}
                    onInputChange={(event, newInputValue) => {
                        setCCInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        const filtered = filter(optionValues, params);

                        /*   const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = optionValues.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `Add "${inputValue}"`,
                            });
                        }
*/
                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    sx={profileStyle.appWoner.select}
                    freeSolo
                    renderInput={(params) => (
                        <CssTextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Business CI"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: <>{params.InputProps.endAdornment}</>,
                            }}
                            fullWidth
                        />
                    )}
                    disabled={modal?.type === 'edit'}
                />
                <InfoImg title={t([`appProfile.infoText.businessCI`])} />
            </Stack>
        </>
    );
};

export default React.memo(BusinessCI);
