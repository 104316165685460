/* eslint-disable import/no-named-default */

import { default as Environments } from './Environments';
import { default as Applications } from './Application';
import { default as AppTier } from './AppTier';
import { default as Region } from './Region';
import { default as Subscription } from './Subscription';
import { default as ResourceGroup } from './ResourceGroup';
import { default as VNet } from './VNet';
import { default as SubNet } from './Subnet';
import { default as Replication } from './Replication';
import { default as DataClassification } from './DataClassification';
import { default as Department } from './Department';
import { default as BusinessUnit } from './BusinessUnit';
import { default as Cluster } from './Cluster';
import { default as ProjectName } from './ProjectName';
import { default as IsaDocument } from './IsaDocument';
import { default as AppName } from './AppName';
import { default as StorageSubResource } from './StorageSubResource';
import { default as NetworkConnectivity } from './NetworkConnectivity';
import { default as DnsZoneEnable } from './DnsZoneEnable';
import { default as PrivateDnsZone } from './PrivateDnsZone';
import { default as ProxPlacementGroup } from './ProxPlaceMentGroup';
import { default as AvailabilitySet } from './AvailabilitySet';
import { default as AvailabilityZone } from './AvailabilityZone';
import { default as NetworkSecurityGroup } from './NetWorkSecGroup';
import { default as ApplicationSecurityGroup } from './ApplicationSecurityGroup';
import { default as CloudProvider } from './CloudProvider';
import { default as CloudService } from './CloudService';
import { default as LandingZoneLabel } from './LandingZoneLabel';
import { default as LandingZoneDesc } from './LandingZoneDesc';
import { default as Ldap } from './Ldap';
import { default as Sku } from './Sku';
import { default as Type } from './Type';
import { default as GeoRecoveryRegion } from './GeoRecoveryRegion';
import { default as EventHubVNet } from './EventHubVNet';
import { default as EventHubSubNet } from './EventHubSubNet';
import { default as ComponentName } from './ComponentName';
import { default as LandingZones } from './LandingZones';
import { default as SourceResource } from './SourceResource';
import { default as DestinationResource } from './DestinationResource';
import { default as ConnectionType } from './ConnectionType';
import { default as NetworkSubscription } from './NetworkSubscription';
import { default as ServiceEndPointVNet } from './ServiceEndPointVNet';
import { default as ServiceEndPointSubNet } from './ServiceEndPointSubNet';

const Forms = {
    Applications,
    Environments,
    AppTier,
    Region,
    Subscription,
    ResourceGroup,
    VNet,
    SubNet,
    Replication,
    DataClassification,
    Department,
    BusinessUnit,
    Cluster,
    ProjectName,
    AppName,
    IsaDocument,
    StorageSubResource,
    NetworkConnectivity,
    DnsZoneEnable,
    PrivateDnsZone,
    NetworkSecurityGroup,
    ApplicationSecurityGroup,
    ProxPlacementGroup,
    AvailabilitySet,
    AvailabilityZone,
    CloudProvider,
    CloudService,
    LandingZoneLabel,
    LandingZoneDesc,
    Ldap,
    Sku,
    Type,
    GeoRecoveryRegion,
    EventHubVNet,
    EventHubSubNet,
    ComponentName,
    LandingZones,
    SourceResource,
    DestinationResource,
    ConnectionType,
    NetworkSubscription,
    ServiceEndPointVNet,
    ServiceEndPointSubNet,
};

export default Forms;
