import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'pages/reference-implementation/components/select';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI002/store';
import { updateADGroup } from 'pages/reference-implementation/provisioning/RI002/store/selector';
import useDebounce from 'pages/reference-implementation/provisioning/RI002/hooks/use-debounce';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

const ADGroupField = ({ indexVal, checkADGroupDuplicates }) => {
    const [order] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const updateGroup = useSetRecoilState(updateADGroup);
    const adGroupsApi = useMutation((searchVal) => axios.get(`${endPoints.ssp.adGroup}/${searchVal}`));

    const handleOnSelectChange = (selectedValue, index, key) => {
        updateGroup({ selectedValue, index, key });
    };
    const inputChangeHandler = (selectedValue, index, key) => {
        updateGroup({ selectedValue, index, key });
    };
    const debouncedInputValue = useDebounce(order?.adGroupList?.[indexVal]?.adGroupInputValue, 500);
    useEffect(() => {
        if (debouncedInputValue) {
            adGroupsApi.mutate(debouncedInputValue);
        } else {
            setOptions([]);
        }
    }, [debouncedInputValue]);

    React.useEffect(() => {
        if (adGroupsApi?.isSuccess && adGroupsApi.data?.data?.adGroupDtos && debouncedInputValue !== '') {
            const adList = adGroupsApi.data?.data?.adGroupDtos.map((adGroup) => ({
                label: adGroup.userGroupName,
                value: adGroup.userGroupName,
            }));
            setOptions(adList);
        }
    }, [adGroupsApi?.isSuccess]);

    return (
        <>
            <Select
                key="adGroupName"
                label={<InputLabel label="AD Group" />}
                value={order?.adGroupList?.[indexVal]?.adGroupValue || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value, indexVal, 'inputSelect')}
                isLoading={adGroupsApi.isLoading}
                noOptionsText="Type to search"
                autoComplete
                includeInputInList
                filterSelectedOptions
                fullWidth
                onInputChange={(event, newInputValue) => {
                    inputChangeHandler(newInputValue, indexVal, 'inputChange');
                }}
                customValidate={checkADGroupDuplicates(order?.adGroupList?.[indexVal]?.adGroupValue) ? 'validate' : ''}
            />
        </>
    );
};

ADGroupField.propTypes = {
    indexVal: PropTypes.number,
    checkADGroupDuplicates: PropTypes.func,
};

ADGroupField.defaultProps = {
    indexVal: 0,
    checkADGroupDuplicates: () => null,
};

export default React.memo(ADGroupField);
