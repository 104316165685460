import { styled } from '@mui/material/styles';
import { RadioGroup, Radio, Paper, Typography, Box } from '@mui/material';

export const RI003Styles = {
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            background: '#F4273B',
            mt: 0.5,
        },
        delete: {
            fontSize: (theme) => theme.spacing(2.25),
            fill: '#FFFFFF',
            color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    warningTypography: { color: (theme) => theme.palette.red.light },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    adinfoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', width: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    validateBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    welcomeBanner: {
        boxStyle: { marginLeft: '25px', marginRight: '25px' },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    },
    fontStyle: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
    },

    ResourceMgmtSectionStyle: {
        papaerStyle: {
            padding: 2,
            borderRadius: (theme) => theme.spacing(1),
            minWidth: '245px',
            height: '123px',
            background: '#ffffff',
            color: '#fffff',
            border: '2px dashed #A5A5A5',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            // borderBottomColor: '#0047BA',
        },
        addComponentStyle: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
        addIconStyle: { color: '#0047BA' },
        addTextStyle: { color: '#0047BA', fontFamily: 'Roboto', fontWeight: 'medium', fontSize: '18px' },
        resourceWrapper: {
            // padding: 2,
            borderRadius: (theme) => theme.spacing(1),
            background: '#e5ecf8',
            color: '#000000',
            borderBottom: '4px solid',
            borderBottomColor: '#0047BA',
            minWidth: { md: '245px', lg: '275px' },
            height: { md: '123px', lg: '163px' },
        },
        resourceContainer: { display: 'flex', flexDirection: 'column', height: '100%', padding: '16px 0px 16px 16px', justifyContent: 'space-between' },
        resourceImageContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px',
            width: '100%',
        },
        resourceImagStyle: {
            width: '25px',
            height: '23px',
        },
        resourceTitle: { fontFamily: 'Roboto', fontWeight: 'medium', fontSize: '1.3rem' },
        mandatoryInfo: { fontFamily: 'Stolzl', fontWeight: 'medium', fontStyle: 'italic', fontSize: '0.625rem', pt: 1 },
        resourceActionContainer: { display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' },
        configureBtn: {
            width: '85%',
            height: '35px',
            fontSize: '14px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
            minWidth: '90px',
            // color:'#0047BA'
        },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
        cloneicon: { color: '#616161', fontSize: '1.563rem' },
        deleteicon: {
            cursor: 'pointer',
            color: '#F4273B',
            fontSize: '1.563rem',
        },
        infoIconWrapper: { marginTop: '3px', marginLeft: '10px' },
    },

    confirmDialog: {
        IconColor: '#C02223',

        delete: {
            fontSize: '1.125rem',
            fill: '#F4273B',
            color: '#F4273B',
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        dialogContentBox: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
        dialogActionBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        dialogNoBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            color: '#6E6F6F',
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
    },
    consumerGrp: {
        badge: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 1,
            '& .MuiBadge-badge': {
                fontWeight: 400,
                fontSize: '12px',
                right: '0%',
                top: '0%',
            },
        },
    },
};

export default RI003Styles;

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));

export const CustomPaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    background: '#e5ecf8',
    color: '#000000',
    borderBottom: '4px solid',
    borderBottomColor: '#0047BA',
    minHeight: '123px',
    height: '100%',
    // [theme.breakpoints.not('lg')]: {
    //     width: '265px',
    //     height: '123px',
    // },
    [theme.breakpoints.between('1024', '1080')]: {
        width: '170px',
        height: '123px',
    },
}));

export const CustomAddPaper = styled(Paper)(({ theme, disabled }) => ({
    width: '100%',
    padding: 2,
    borderRadius: theme.spacing(1),
    background: '#ffffff',
    color: '#fffff',
    border: '2px dashed #A5A5A5',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: '123px',
    opacity: disabled ? 0.5 : 1,
    // [theme.breakpoints.not('lg')]: {
    //     width: '265px',
    //     height: '123px',
    // },
    // [theme.breakpoints.between('1024', '1080')]: {
    //     width: '170px',
    //     height: '123px',
    // },
}));
export const ResourceTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '1.3rem',
    marginLeft: 2,
    [theme.breakpoints.between('1024', '1080')]: {
        fontSize: '0.8rem',
    },
}));

export const ConfigureBox = styled(Box)(({ theme }) => ({
    width: '75%',
    [theme.breakpoints.between('1024', '1080')]: {
        width: '50%',
    },
}));
export const ActionBox = styled(Box)(({ theme }) => ({
    width: '25%',
    [theme.breakpoints.between('1024', '1080')]: {
        width: '50%',
    },
}));
