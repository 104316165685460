import React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FiAlertTriangle } from 'react-icons/fi';
import { useRequest } from 'hooks';
import { endPoints } from 'api/endpoints';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
// eslint-disable-next-line import/named
import { updateNodesDetails, removeDataDiskIndex, validateDisk } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import Btn from 'components/button';

const ConfirmDD = ({ indexVal }) => {
    const [order] = useRecoilState(sspSubmitOrder);
    const updateNodesData = useSetRecoilState(updateNodesDetails);
    const removeDiskIndex = useSetRecoilState(removeDataDiskIndex);
    const validate = useSetRecoilState(validateDisk);

    const DataDiskConfig = useRequest({
        key: 'linuxDataDiskConfig',
        url: endPoints.ssp.vm.linuxDiskConfigurations,
    });
    const setDataDisks = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };

    const handleYes = () => {
        setDataDisks('dataDisksCount', order?.nodes?.[indexVal]?.isDataDisk?.newDataDiskCount, indexVal);
        let diskLength = order?.nodes?.[indexVal]?.dataDisks?.length;
        if (order?.nodes?.[indexVal]?.isDataDisk?.deleteCount !== 0) {
            setDataDisks('isConfigDiskFulfilled', order?.nodes?.[indexVal]?.isDataDisk?.newDataDiskCount.toString() === '0', indexVal);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < order?.nodes?.[indexVal]?.isDataDisk?.deleteCount; i++) {
                // eslint-disable-next-line no-plusplus
                diskLength--;
                removeDiskIndex({ diskLength, indexVal });
            }
        }
        setTimeout(() => {
            validate({ DataDiskConfig, indexVal });
        }, 500);
        const data = {
            isOpen: false,
            newDataDiskCount: 0,
            deleteCount: 0,
        };
        setDataDisks('isDataDisk', data, indexVal);
    };

    const handleNo = () => {
        const data = {
            isOpen: false,
            newDataDiskCount: 0,
            deleteCount: 0,
        };
        setDataDisks('isDataDisk', data, indexVal);
    };
    return (
        <>
            <Dialog
                open={order?.nodes?.[indexVal]?.isDataDisk?.isOpen}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-testid="dialogModal"
            >
                <DialogContent>
                    <Box sx={ProvisioningStyles.confirmDD.dialogContentBox}>
                        <FiAlertTriangle color={ProvisioningStyles.Dialog.IconColor} size={70} />
                        <Typography variant="h5">Are you sure?</Typography>
                        <div className="text-gray-dark">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            You'll lose last {order?.nodes?.[indexVal]?.isDataDisk?.deleteCount} data disks from the list.
                            <br />
                            Do you want delete?
                        </div>
                    </Box>

                    <Box sx={ProvisioningStyles.confirmDD.dialogActionBox}>
                        <Btn
                            variant="contained"
                            onClick={() => handleYes()}
                            className="uppercase tracking-wider text-sm custom-btn"
                            color="cmpPrimary"
                            sx={{ textTransform: 'none' }}
                            data-testid="yes-btn"
                        >
                            Yes
                        </Btn>
                        <Btn
                            variant="outlined"
                            color="cmpWarning"
                            sx={ProvisioningStyles.confirmDD.dialogNoBtn}
                            onClick={() => handleNo()}
                            layout="outline"
                            className="uppercase tracking-wider text-sm custom-btn"
                            data-testid="no-btn"
                        >
                            No
                        </Btn>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

ConfirmDD.propTypes = {
    indexVal: PropTypes.number,
};

ConfirmDD.defaultProps = {
    indexVal: 0,
};

export default ConfirmDD;
