import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';

export const AudienceURI = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    // const [, setAudienceURI] = useRecoilState(isAudienceURIValid);

    // const [helperText, setHelperText] = React.useState(false);

    const setApplicationUrl = (audienceURI = null) => {
        setOrder({
            ...order,
            audienceURI,
        });
    };

    // React.useEffect(() => {
    //     if (
    //         order?.audienceURI &&
    //         order?.audienceURI !== null &&
    //         order?.audienceURI !== '' &&
    //         order?.audienceURI !== undefined &&
    //         !/^[a-zA-Z0-9-]+$/.test(order?.audienceURI)
    //     ) {
    //         setHelperText(true);
    //         setAudienceURI(false);
    //     } else {
    //         setHelperText(false);
    //         setAudienceURI(true);
    //     }
    // }, [order?.audienceURI]);

    const validateInput = () => {
        if (isTouched && order?.isTouched && !order?.audienceURI) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.audienceURI && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.audienceURI, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-url-box">
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label="Audience URI (SP Entity ID)*"
                // error={helperText}
                // helperText={helperText && <span>Audience URI is invalid.</span>}
                value={order?.audienceURI || ''}
                onChange={(e) => setApplicationUrl(e.target.value)}
                className={validateInput()}
                data-testid="audienceUrl"
            />
            <HelpImg
                title={
                    <Box sx={{ whiteSpace: 'pre' }}>
                        <Box>The application-defined unique identifier that is the intended audience of the SAML assertion.</Box>
                        <Box>This is most often the SP Entity ID of your application.</Box>
                        {/* <Box>This is the most often the SP Entity ID of your application.</Box> */}
                    </Box>
                }
                maxWidth="600px"
            />
        </Box>
    );
};

export default AudienceURI;
