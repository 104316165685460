import React from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { getLoggedUserClaims, getLoggedUserGPID } from 'utils';
import { ResetRequestDetails } from 'pages/onboard-developers/utils';
import FormCard from './components/form-card';
import { requestDetails, successPopup } from './store';
import Form from './provisioning/components';
import ProvisioningHeader from './provisioning/ProvisioningHeader';
import CancelBtn from './components/CancelBtn';
import OrderSuccessPopup from './components/OrderSuccessPopup';
import SubmitRequestBtn from './components/SubmitRequestBtn';

const OnboardDevelopers = () => {
    const [details, setDetails] = useRecoilState(requestDetails);
    const [, setSuccessModal] = useRecoilState(successPopup);
    React.useEffect(() => {
        const loggeduserDetails = getLoggedUserClaims()?.accessToken;
        setDetails({
            ...details,
            firstName: loggeduserDetails?.claims?.FirstName,
            lastName: loggeduserDetails?.claims?.LastName,
            gpid: getLoggedUserGPID(),
            email: loggeduserDetails?.claims?.email,
        });
    }, []);
    const resetForm = () => {
        setDetails(ResetRequestDetails);
        setSuccessModal({ orderId: null, isOpen: false });
    };
    React.useEffect(() => {
        return () => resetForm();
        // eslint-disable-next-line
    }, []);
    return (
        <Box>
            <ProvisioningHeader />
            <Box display="flex" padding={4} gap={3} flexWrap="wrap">
                <Box flex={3}>
                    <FormCard>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Form.ApplicationCI />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Sector />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Gpid />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.FirstName />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.LastName />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.Email />
                            </Grid>
                            <Grid item sm={12}>
                                <Box sx={{ float: 'right', display: 'flex', flexDirection: 'row' }}>
                                    <CancelBtn />
                                    <SubmitRequestBtn />
                                </Box>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Box>

                <Box flex={1.5} />
            </Box>
            <OrderSuccessPopup />
        </Box>
    );
};
export default OnboardDevelopers;
