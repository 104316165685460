/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import { catalogs } from 'store';
import { resetCostEstimateDetails } from 'pages/myidm/utils';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import { useRecoilState } from 'recoil';
import ProvisioningStyles from 'pages/myidm/style';
import {
    OrderPayload,
    costEstimateState,
    isAppShortNameValid,
    isAppShortNameExist,
    isAppNameValid,
    selectedMembersList,
    costApiLoading,
} from 'pages/myidm/store';
import { costPayload } from 'pages/myidm/utils/getPayload';

const ProvisioningHeader = () => {
    const { catalogCode, catalogServiceId } = useParams();
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setCostEstimate] = useRecoilState(costEstimateState);
    const [members] = useRecoilState(selectedMembersList);
    const [appShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [appShortNameExist] = useRecoilState(isAppShortNameExist);
    const [applicationNameValid] = useRecoilState(isAppNameValid);
    const [, setiscostApiLoading] = useRecoilState(costApiLoading);
    const [cmpCatalogs] = useRecoilState(catalogs);

    const getCostPayload = () => {
        const day2Catalog = cmpCatalogs?.filter((catlg) => catlg?.catalogDefinitionId === catalogCode);
        const payload = costPayload({ order, catalogServiceId, day2Catalog });
        delete payload?.estimatedCost;
        return payload;
    };
    const cost = useMutation(() => axios.post(endPoints?.ssp?.costCalculation, getCostPayload()));

    React.useEffect(() => {
        if (cost?.isSuccess && cost.data?.data) {
            if (cost.data?.data) setCostEstimate(cost.data?.data);
            setOrder({ ...order, estimatedCost: cost.data?.data });
            setiscostApiLoading(false);
        }
        // eslint-disable-next-line
    }, [cost?.isSuccess]);
    const isReadyTogetCost = () => {
        return (
            (order?.isNewADGroup === 'no' && !order?.adGroup && !order?.adGroupType) ||
            (order?.isNewADGroup === 'yes' && (!order?.appShortName || !order?.groupOwner || !order?.adGroupType || !order?.applicationName)) ||
            !order?.businessApplication ||
            !order?.environment ||
            !order?.sector ||
            !order?.members?.length > 0 ||
            !order?.adGroupType ||
            order?.adGroupType !== 'Privileged' ||
            (order?.isNewADGroup === 'yes' && (!appShortNameVaild || appShortNameExist)) ||
            (order?.isNewADGroup === 'yes' && (!applicationNameValid || !order?.appService)) ||
            (order?.adGroupType === 'Privileged' && !order?.nonSafeDetailsGpid?.length > 0)

            // !order?.mypamsafeDetails?.filter((pam) => pam?.safeName === null || pam?.safeName === '')?.length > 0
        );
    };
    useEffect(() => {
        setCostEstimate({ ...resetCostEstimateDetails, details: order?.emptyCost?.costs });
        if (!isReadyTogetCost()) {
            setiscostApiLoading(true);
            cost.mutate();
        }
    }, [
        order?.isNewADGroup,
        order?.adGroup,
        order?.adGroupType,
        members,
        order?.appShortName,
        order?.groupOwner,
        order?.adGroupType,
        order?.applicationName,
        order?.businessApplication,
        order?.environment,
        order?.sector,
        order?.mypamsafeDetails,
        order?.nonSafeDetailsGpid,
    ]);

    return (
        <Box>
            <StickyHeader
                title={`${
                    order?.isNewADGroup && order?.isNewADGroup === 'yes'
                        ? 'Create Active Directory Group and  Add Members'
                        : 'Adding Members to Active Directory Group'
                }`}
                sx={ProvisioningStyles.ProvisioningHeader}
                width="100px"
                infoTitle="Click here for further details"
            />
        </Box>
    );
};

export default React.memo(ProvisioningHeader);
