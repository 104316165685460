import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Grid, Stack } from '@mui/material';
import Vm from 'pages/provisioning/event-hub/components/Form';
import FormCard from 'pages/provisioning/_components/FormCard';
import { costEstimateState, isLoadingCostApiState, sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/event-hub';
import CostSummary from 'pages/provisioning/_components/CostSummary';
import EnvironmentContainer from 'pages/provisioning/event-hub/EnvironmentContainer';
import CartBtn from 'pages/provisioning/event-hub/components/CartBtn';

const SspForm = () => {
    const [costEstimate] = useRecoilState(costEstimateState);
    const [isLoadingCostApi] = useRecoilState(isLoadingCostApiState);
    const order = useRecoilValue(sspSubmitOrder);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };

    return (
        <Grid container spacing={3.125} pt={3.125} pb={1}>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <FormCard title="Application">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.Applications />
                                    <Vm.Environments />
                                    <Vm.LandingZones />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.Sector />
                                    <Vm.Region />
                                    <Vm.CostCenter />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <FormCard title="Namespace Details">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.NSExist />
                                    <Vm.NamespaceName />
                                    {order?.performance === 'Standard' && <Vm.ThroughputUnits />}
                                    {order?.environment === 'Production' && order?.performance === 'Premium' && <Vm.ProcessingUnits />}
                                    {order?.environment === 'Production' && order?.performance === 'Standard' && order?.isAutoInflate === 'yes' && (
                                        <Vm.GeoReplication />
                                    )}
                                    <Vm.AvailablePartitionCount />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.Performance />
                                    {order?.environment !== 'Production' && order?.performance === 'Premium' && <Vm.ProcessingUnits />}
                                    {order?.performance === 'Standard' && <Vm.AutoInflate />}
                                    {order?.performance === 'Standard' && order?.isAutoInflate === 'yes' && <Vm.AutoInflateMaxThroughputUnits />}
                                    {order?.environment === 'Production' && order?.performance === 'Premium' && <Vm.GeoReplication />}
                                    {order?.environment === 'Production' && order?.performance === 'Standard' && order?.isAutoInflate === 'no' && (
                                        <Vm.GeoReplication />
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <FormCard title="Event Hubs Details">
                        <Vm.EventHubDetails />
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <EnvironmentContainer />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <CostSummary handleCostInfoBtn={handleCostInfoBtn} cartBtn={<CartBtn />} costEstimate={costEstimate} isLoading={isLoadingCostApi?.isLoading} />
            </Grid>
        </Grid>
    );
};

export default SspForm;
