import React from 'react';
import { Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';
import toast from 'react-hot-toast';
import { endPoints } from './endpoints';

export const isRIEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [
        ...Object?.keys(endPoints?.riServices?.Jwt)?.map((key) => endPoints?.riServices?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI001?.Jwt)?.map((key) => endPoints?.riServices?.RI001?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI001A?.Jwt)?.map((key) => endPoints?.riServices?.RI001A?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI002?.Jwt)?.map((key) => endPoints?.riServices?.RI002?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI003?.Jwt)?.map((key) => endPoints?.riServices?.RI003?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI003O?.Jwt)?.map((key) => endPoints?.riServices?.RI003O?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI003A?.Jwt)?.map((key) => endPoints?.riServices?.RI003A?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI004?.Jwt)?.map((key) => endPoints?.riServices?.RI004?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI004A?.Jwt)?.map((key) => endPoints?.riServices?.RI004A?.Jwt?.[key]),
        ...Object?.keys(endPoints?.riServices?.RI005?.Jwt)?.map((key) => endPoints?.riServices?.RI005?.Jwt?.[key]),
    ];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });

    return isContains;
};

export const isDay2Endpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.myIdm?.Jwt)?.map((key) => endPoints?.myIdm?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};

export const isServiceAccountEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.serviceAccountCreation?.Jwt)?.map((key) => endPoints?.serviceAccountCreation?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};

export const isSsoEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.ssoOkta?.Jwt)?.map((key) => endPoints?.ssoOkta?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};
export const isOnboardAppServiceEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.onboardAppService?.Jwt)?.map((key) => endPoints?.onboardAppService?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};
export const isOnBoardDevelopersEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.onBoardDevelopers?.Jwt)?.map((key) => endPoints?.onBoardDevelopers?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};
export const isMdtEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.mdt?.Jwt)?.map((key) => endPoints?.mdt?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};
export const isSecurityToolEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.securityTool?.Jwt)?.map((key) => endPoints?.securityTool?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};

export const isProjectOnboardingEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.day2Operation?.ProjectOnboarding?.Jwt)?.map((key) => endPoints?.day2Operation?.ProjectOnboarding?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};
export const isVirtualServiceEndpoint = (url) => {
    let isContains = false;
    const JwtUrls = [...Object?.keys(endPoints?.virtualService?.Jwt)?.map((key) => endPoints?.virtualService?.Jwt?.[key])];

    JwtUrls?.forEach((item) => {
        if (url?.includes(item)) isContains = true;
    });
    return isContains;
};

export const isBlob = (url) => url?.includes('/download-attachment') || url?.includes('/architecture');

export const isOktaToken = (url) =>
    isRIEndpoint(url) ||
    isDay2Endpoint(url) ||
    isOnBoardDevelopersEndpoint(url) ||
    isSsoEndpoint(url) ||
    isOnboardAppServiceEndpoint(url) ||
    isServiceAccountEndpoint(url) ||
    isMdtEndpoint(url) ||
    isSecurityToolEndpoint(url) ||
    isVirtualServiceEndpoint(url) ||
    isProjectOnboardingEndpoint(url);

export const customToast = (messages = [], type = 'error') => {
    const color = type === 'error' ? '#ff003e' : '#735c0f';
    const Icon = type === 'error' ? CancelIcon : WarningIcon;
    toast.dismiss();
    toast.custom(
        (t) => (
            <Box
                display="flex"
                px={2}
                py={2}
                gap={1}
                sx={{
                    background: '#fff',
                    borderRadius: 2,
                    alignItems: 'center',
                    maxWidth: '80%',
                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
                }}
            >
                <Icon sx={{ color }} />
                <div>
                    {messages?.length === 1 ? (
                        <div>{messages?.[0]}</div>
                    ) : (
                        messages?.map((item, i) => (
                            <ul key={i} style={{ listStylePosition: 'outside', paddingInlineStart: '10px', marginBlockStart: '1px', marginBlockEnd: '1px' }}>
                                <li>{item}</li>
                            </ul>
                        ))
                    )}
                </div>
                <Box sx={{ color: '#0047BA', cursor: 'pointer' }} onClick={() => toast.dismiss(t.id)}>
                    Close
                </Box>
            </Box>
        ),
        {
            duration: 50000,
        }
    );
};
