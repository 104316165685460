import React from 'react';
import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { FiAlertTriangle } from 'react-icons/fi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Btn from 'components/button';
import { appProfileTypeState, toggleConfirmProfileModel } from '../store';
import profileStyle from './style';

const ConfirmDeleteModal = () => {
    const [appProfileType, setAppProfileType] = useRecoilState(appProfileTypeState);
    const [modal, setModal] = useRecoilState(toggleConfirmProfileModel);
    const deleteApi = useMutation(() => axios.delete(`${endPoints.appProfile.submitAppProfile}/${modal?.id}`));
    const handleYes = () => {
        if (modal?.type === 'delete') deleteApi.mutate();
    };
    const handleSaveYes = () => {
        setModal({ ...modal, isOpen: false });
    };
    const handleNo = () => {
        setModal({ ...modal, isOpen: false });
    };
    React.useEffect(() => {
        if (deleteApi?.isSuccess) {
            setAppProfileType({ ...appProfileType, pageNumber: 1 });
            toast.success(deleteApi?.data?.messages[0]);
            setModal({ ...modal, isOpen: false });
        }
    }, [deleteApi?.isSuccess]);
    return (
        <>
            <Dialog
                open={modal?.isOpen}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-testid="dialogModal"
            >
                <DialogContent sx={{ maxWidth: modal?.type === 'delete' ? '375px' : '450px' }}>
                    {modal?.type === 'delete' && (
                        <>
                            <Box sx={profileStyle.deleteModal.deleteMsgWrap}>
                                {modal?.type === 'delete' && <FiAlertTriangle color="#d02424" size={70} />}
                                <Typography variant="h5">{modal?.message}</Typography>
                            </Box>

                            <Box sx={profileStyle.deleteModal.actionWrapper}>
                                <Btn
                                    className="custom-btn"
                                    display="flex"
                                    alignitems="center"
                                    variant="contained"
                                    onClick={() => handleYes()}
                                    color="cmpPrimary"
                                    data-testid="yes-btn"
                                >
                                    Yes {deleteApi?.isLoading && <CircularProgress sx={profileStyle.deleteModal.yesCircularProg} color="inherit" size={14} />}
                                </Btn>
                                <Btn
                                    className="custom-btn"
                                    variant="outlined"
                                    color="cmpWarning"
                                    style={profileStyle.deleteModal.noBtn}
                                    onClick={() => handleNo()}
                                    layout="outline"
                                    data-testid="no-btn"
                                >
                                    No
                                </Btn>
                            </Box>
                        </>
                    )}
                    {modal?.type === 'save' && (
                        <>
                            <Box sx={profileStyle.deleteModal.modalMsg}>
                                {modal?.type === 'save' && <CheckCircleIcon sx={profileStyle.deleteModal.checkCircleICon} />}
                                <Typography variant="h5">{modal?.message}</Typography>
                                {modal?.type === 'save' && <Box sx={profileStyle.deleteModal.defaultsMsg}>You want to add App defaults</Box>}
                            </Box>

                            <Box sx={profileStyle.deleteModal.continueWrapper}>
                                <Btn variant="contained" onClick={() => handleSaveYes()} className="custom-btn" data-testid="continue-btn">
                                    Continue
                                </Btn>
                                <Btn
                                    variant="outlined"
                                    style={profileStyle.deleteModal.laterBtn}
                                    onClick={() => handleNo()}
                                    layout="outline"
                                    className="custom-btn"
                                    data-testid="do-later-btn"
                                >
                                    DO LATER
                                </Btn>
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};
export default ConfirmDeleteModal;
