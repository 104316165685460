import {
    iaasVMCatalogServiceCode,
    iaasOracleCatalogServiceCode,
    iaasSQLCatalogServiceCode,
    AlbCatalogServiceCode,
    paasSQLCatalogServiceCode,
    storageAccountCatalogServiceCode,
    aksCatalogServiceCode,
    iaasCouchbaseServiceCode,
    keyvaultCatalogServiceCode,
    eventhubCatalogServiceCode,
    kafkaCatalogServiceCode,
    eipStackCatalogServiceCode,
    adfCatalogServiceCode,
} from 'utils/catalogServiceCode';

export const setLandingZoneOnLocalStorage = (data) => localStorage.setItem('landingZone', data);
export const getLandingZoneFromLocalStorage = () => JSON.parse(localStorage.getItem('landingZone'));
export const clearLandingZoneFromLocalStorage = () => localStorage.removeItem('landingZone');

export const formLandingZoneObject = (data = null) => {
    const getServiceEndpoints = (vnetSubnet = []) => {
        if (vnetSubnet?.length <= 0) {
            return [
                {
                    networkSubscription: null,
                    networkSubscriptionDetails: null,
                    subnet: null,
                    vNet: null,
                    vNetDetails: { label: null, resourceId: null },
                },
            ];
        }
        if (vnetSubnet?.length >= 1) {
            return vnetSubnet?.map((item) => ({
                networkSubscription: item?.networkSubscription,
                networkSubscriptionDetails: item?.networkSubscriptionDetails,
                subnet: item?.subnet,
                vNet: item?.vnet?.name,
                vNetDetails: { label: item?.vnet?.name, resourceId: item?.vnet?.id },
            }));
        }
        return [];
    };
    return {
        provider: data?.cloudProvider,
        service: data?.cloudService,
        // serviceCode: 'AZWINVM2016',
        environment: data?.environment,
        region: data?.region?.name,
        regionDetails: { label: data?.region?.name, value: data?.region?.code },
        landingZoneLabel: data?.label,
        landingZoneDesc: data?.description,
        ...((iaasVMCatalogServiceCode === data?.cloudService ||
            iaasOracleCatalogServiceCode === data?.cloudService ||
            iaasSQLCatalogServiceCode === data?.cloudService) && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: data?.config?.subscription,
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: data?.config?.vnet,
            subNet: data?.config?.subnet,
            availSet: data?.config?.availabilitySet,
            availZone: data?.config?.availabilityZone,
        }),
        ...(iaasCouchbaseServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            availZone: data?.config?.availabilityZone,
            availZoneDetails: data?.config?.availabilityZone?.map((app) => ({ label: app, value: app })),
        }),
        ...(paasSQLCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vnetSubnet: getServiceEndpoints(data?.config?.vnetSubnet),
        }),
        ...(storageAccountCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            vnetSubnet: getServiceEndpoints(data?.config?.vnetSubnet),
        }),
        ...(aksCatalogServiceCode === data?.cloudService && {
            department: data?.config?.department,
            businessUnit: data?.config?.businessUnit,
            cluster: data?.config?.cluster,
            // projectName: data?.config?.projectName,
            ISADocument: data?.config?.isaDocument,
        }),
        ...(keyvaultCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: { label: data?.config?.vnet?.name, resourceId: data?.config?.vnet?.id },
            subNet: data?.config?.subnet,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            networkConnectivity: data?.config?.networkConnectivity,
            appName: data?.config?.appName,
        }),
        ...(AlbCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            vNet: data?.config?.vnet?.name,
            vNetDetails: {
                label: data?.config?.vnet?.name,
                resourceId: data?.config?.vnet?.id,
            },
            subNet: data?.config?.subnet,
            sku: data?.config?.sku,
            type: data?.config?.type,
            appName: data?.config?.appName,
        }),
        ...(eventhubCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            geoRecoveryRegion: data?.config?.geoRecoveryRegion?.name,
            geoRecoveryRegionDetails: {
                label: data?.config?.geoRecoveryRegion?.name,
                value: data?.config?.geoRecoveryRegion?.id,
            },
            vnetSubnet: data?.config?.vnetSubnet?.map((item) => ({
                subNet: item?.subnet,
                vNet: item?.vnet?.name,
                vNetDetails: { label: item?.vnet?.name, resourceId: item?.vnet?.id },
            })),
        }),
        ...(eipStackCatalogServiceCode === data?.cloudService && {
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
            components: data?.config?.components,
            connections: data?.config?.connections,
        }),
        ...(adfCatalogServiceCode === data?.cloudService && {
            subscription: data?.config?.subscription?.name,
            subscriptionDetails: {
                label: data?.config?.subscription?.name,
                id: data?.config?.subscription?.id,
            },
            resGroup: data?.config?.resourceGroup,
            dataClassification: data?.config?.dataClassification?.displayName,
            dataClassificationDetails: data?.config?.dataClassification,
        }),
        ...(kafkaCatalogServiceCode === data?.cloudService && {
            department: data?.config?.department,
            cluster: data?.config?.cluster,
            projectName: data?.config?.projectName,
            businessUnit: data?.config?.businessUnit,
            ldap: data?.config?.ldapGroup,
            ISADocument: data?.config?.isaDocument,
        }),
        isTouched: false,
    };
};

export const resetLandingZoneData = {
    provider: null,
    service: null,
    serviceCode: null,
    serviceDetails: null,
    environment: null,
    environmentDetails: null,
    region: null,
    regionDetails: null,
    subscription: null,
    subscriptionDetails: null,
    resGroup: null,
    resGroupDetails: null,
    vNet: null,
    vNetDetails: null,
    subNet: null,
    subNetDetails: null,
    availSet: null,
    availSetDetails: null,
    availZone: null,
    availZoneDetails: null,
    landingZoneLabel: '',
    landingZoneDesc: '',
    ISADocument: null,
    businessUnit: null,
    appName: null,
    dataClassification: null,
    dataClassificationDetails: null,
    components: [],
    connections: [],
    isTouched: false,
};

export const resetLandingZone = (cloudService) => ({
    environment: null,
    region: null,
    businessUnit: null,
    subscription: null,
    subscriptionDetails: null,
    resGroup: null,
    resGroupDetails: null,
    vNet: null,
    vNetDetails: null,
    subNet: null,
    subNetDetails: null,
    nwSecGrp: null,
    nwSecGrpDetails: null,
    appSecGrp: null,
    appSecGrpDetails: null,
    proxPlacGrp: null,
    proxPlacGrpDetails: null,
    availSet: null,
    availSetDetails: null,
    availZone: null,
    availZoneDetails: null,
    department: null,
    ldap: null,
    landingZoneDesc: null,
    landingZoneLabel: null,
    sku: cloudService === AlbCatalogServiceCode ? 'Standard' : null,
    type: cloudService === AlbCatalogServiceCode ? 'Internal' : null,
    geoRecoveryRegion: null,
    geoRecoveryRegionDetails: null,
    dataClassification: null,
    dataClassificationDetails: null,
    vnetSubnet: [{ networkSubscription: null, networkSubscriptionDetails: null, vNet: null, vNetDetails: null, subNet: null, subNetDetails: null }],
    cluster: null,
    endPointType: null,
    endPointTypeDetails: null,
    appName: null,
});
