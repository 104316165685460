/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Box } from '@mui/material';
import Select from 'components/select';
import { sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';
import { validateDisk } from '../../../store/day2-operation/selecters';

function numberIteration(n) {
    let number = [];
    if (n) {
        number = Array.from(Array(n), (e, i) => ({ label: (i + 1)?.toString(), value: (i + 1)?.toString() }));
    }
    return number;
}

const DataDisk = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const validate = useSetRecoilState(validateDisk);
    const checkIsNone = (value) => {
        if (value.toString() === '0') return 'None';
        return value.toString();
    };

    const handleChange = (selected) => {
        if (Number(selected.value) === 0) {
            const countForDelete = order?.dataDisks?.length;
            if (countForDelete !== 0) {
                setIsOpen({
                    ...isOpen,
                    isDataDisk: {
                        isOpen: true,
                        newDataDiskCount: '0',
                        deleteCount: countForDelete,
                    },
                });
            }
        } else {
            const comparePrev = Number(selected?.value) - order?.dataDisks?.length;
            let dataDisks = order?.dataDisks;
            if (comparePrev > 0) {
                dataDisks = [
                    ...order?.dataDisks,
                    ...Array(comparePrev).fill({
                        lun: null,
                        diskName: null,
                        storageType: null,
                        diskSizeInGib: null,
                        maxIops: null,
                        maxThroughput: null,
                        encryption: null,
                        hostCaching: null,
                    }),
                ];
                setOrder({ ...order, dataDisksCount: selected?.value, dataDisks });
            } else {
                const countForDelete = Number(Math.abs(comparePrev));
                if (countForDelete !== 0) {
                    setIsOpen({
                        ...isOpen,
                        isDataDisk: {
                            isOpen: true,
                            newDataDiskCount: Number(selected?.value),
                            deleteCount: countForDelete,
                        },
                    });
                }
            }
        }
        validate();
    };
    React.useEffect(() => {
        const none = { label: '0', value: '0' };
        const data = numberIteration(order?.maxDataDiskCount);
        data.unshift(none);
        setOptions(data);
    }, []);
    React.useEffect(() => {
        if (!order?.dataDisksCount && order?.isTouched) setIsTouched(true);
    }, [order?.dataDisksCount, order?.isTouched]);
    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="dataDiskName"
                label="Configure Data Disk"
                value={order?.dataDisksCount || '0'}
                options={options}
                handleOnSelect={(value) => handleChange(value)}
                isOptionEqualToValue={(option, value) => option.label.toString() === value.toString()}
                getOptionLabel={(option) => (option?.label?.toString() ? checkIsNone(option.label) : checkIsNone(option))}
                isRequired={isTouched}
                // onBlur={() => setIsTouched(true)}
            />
        </Box>
    );
};
export default React.memo(DataDisk);
