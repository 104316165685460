import React from 'react';
import { Box, Badge } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { getLoggedUserClaims, getLoggedUserFullName } from 'utils';
import EnvironmentDetails from 'pages/provisioning/_components/EnvironmentDetails';
import { format } from 'date-fns';
import {
    sspSubmitOrder,
    environmentDetails,
    toggleSspModels,
    isMandatoryTagsFullfilled,
    isEnvDetailsLoading,
} from 'pages/provisioning/store/day2-operation-paas';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import Btn from 'components/button';

const EnvironmentContainer = () => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [envDetails, setEnvDetails] = useRecoilState(environmentDetails);
    const [, setLoading] = useRecoilState(isEnvDetailsLoading);
    const resetEnvDetails = useResetRecoilState(environmentDetails);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const [isMandatoryTags, setIsMandatoryTags] = useRecoilState(isMandatoryTagsFullfilled);

    const envDetailsDataApi = useMutation(() => axios.get(endPoints.ssp.day2Operation.environmentDetails));
    // const envDetailsDataApi = useMutation((payload) => axios.post(endPoints.ssp.vm.environmentDetails, payload));
    const tagsApi = useMutation(() => axios.get(endPoints.ssp.vm.mandatoryTags));
    const tagsBasedOnResoureGroupApi = useMutation(() => axios.get(`${endPoints.ssp.vm.resourceGroupTags}${order?.envDetails?.config?.resourceGroup}/tags`));
    const userClaims = getLoggedUserClaims();
    const checkVarConditions = () => order.appProfile && order.environment && order.region && order?.cloudProvider;
    React.useEffect(() => {
        if (tagsApi?.data?.data?.values) {
            const tagsValue = tagsApi.data.data.values.map((tag) => {
                if (tag?.name === 'Environment' && (!tag?.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.environment };
                }
                if (tag?.name === 'ApplicationService' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.appProfile };
                }
                if (tag?.name === 'HoursOfOperation' && (!tag.editableOnBlank || !tag.editable)) {
                    if (order?.tagsQc?.HoursOfOperation) {
                        return { ...tag, defaultValue: order?.tagsQc?.HoursOfOperation };
                    }
                    return { ...tag, defaultValue: 'Always ON' };
                }
                if (tag?.name === 'Migration') {
                    if (order?.tagsQc?.Migration) {
                        return { ...tag, defaultValue: order?.tagsQc?.Migration };
                    }
                    return tag;
                }
                if (tag?.name === 'FinancialTag' && (!tag.editableOnBlank || !tag.editable)) {
                    return tag;
                }
                if (tag?.name === 'Servicename' && (!tag.editableOnBlank || !tag.editable)) {
                    if (order?.tagsQc?.Servicename) {
                        return { ...tag, defaultValue: order?.tagsQc?.Servicename };
                    }
                    if (tagsBasedOnResoureGroupApi?.data?.data?.tags?.serviceName) {
                        return { ...tag, defaultValue: tagsBasedOnResoureGroupApi?.data?.data?.tags?.serviceName };
                    }
                    return tag;
                }
                if (tag?.name === 'Requestedby' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: getLoggedUserFullName() };
                }
                if (tag?.name === 'Provisioneddate' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: format(new Date(), 'yyyy-MM-dd') };
                }
                if (tag?.name === 'Sector' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.sector || '-' };
                }
                if (tag?.name === 'Billingentity' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.billingEntity || '-' };
                }
                if (tag?.name === 'Costcenter' && (!tag.editableOnBlank || !tag.editable)) {
                    return { ...tag, defaultValue: order?.hCode ? order?.hCode : '-' };
                }
                return tag;
            });
            setOrder({ ...order, tags: tagsValue, defaultTags: tagsValue });
        }
    }, [tagsApi.data?.data, order?.tagsQc]);

    React.useEffect(() => {
        if (tagsBasedOnResoureGroupApi?.data) tagsApi.mutate();
    }, [tagsBasedOnResoureGroupApi?.data]);

    React.useEffect(() => {
        setIsMandatoryTags(false);
        if (order?.tags?.length) {
            const tagsMandateCheck = order?.tags.filter((tag) => tag.defaultValue === '' && tag.editableOnBlank)?.length > 0;
            setIsMandatoryTags(tagsMandateCheck);
        }
    }, [order?.tags]);

    React.useEffect(() => {
        if (tagsBasedOnResoureGroupApi?.data) tagsBasedOnResoureGroupApi?.reset();
        if (checkVarConditions()) {
            setLoading(true);
            envDetailsDataApi.mutate();
            /* envDetailsDataApi.mutate({
                appProfileName: order?.appProfile,
                environment: order?.environment,
                appTier: 'AP',
                region: order?.region,
                cloudProvider: order?.cloudProvider,
                cloudService: order?.cloudService,
            }); */
        } else {
            resetEnvDetails();
        }
    }, [order?.appProfile, order?.environment, order?.region]);

    React.useEffect(() => {
        if (checkVarConditions() && order?.envDetails?.config?.resourceGroup) {
            tagsBasedOnResoureGroupApi.mutate();
        }
    }, [order?.appProfile, order.environment, order.appTier, order.region, order?.envDetails?.config?.resourceGroup]);

    React.useEffect(() => {
        if (checkVarConditions() && envDetailsDataApi?.isSuccess) {
            const env = envDetailsDataApi?.data?.data;
            if (!env) {
                toast.error('Environmet details is not available.');
            }
            if (env) {
                setOrder({
                    ...order,
                    requestorGpid: userClaims?.accessToken?.claims?.gpid,
                    requestorName: getLoggedUserFullName(),
                    project: env?.config?.projectName,
                    vNet: env?.config?.vnet?.name,
                    envDetails: {
                        ...env,
                    },
                });
                setEnvDetails({
                    Subscription: env?.config?.subscription?.name,
                    'DR Priority': env?.drPriority,
                    Resource_Group: env?.config?.resourceGroup,
                    Metallic_Selection: 'Silver',
                    Backup_Tier: 'Tier 1',
                    Virtual_Network: env?.config?.vnet?.name,
                    // Network_Security_Group: env?.config?.networkSecurityGroup,
                    Subnet_Name: env?.config?.subnet,
                    // Application_Security_Group: env?.config?.applicationSecurityGroup,
                    Recovery_Vault_Name: 'pep-gold-daily5weeks-scus-01-vt',
                    Approval_Manager: env?.applicationManager,
                    Project: order?.hCodeDetails?.projectName ? order?.hCodeDetails?.projectName : '-',
                    Availability_Set: env?.config?.availabilitySet,
                    Azure_Site_Recovery: order?.environment === 'Production' && (env?.drPriority === 'RP1' || env?.drPriority === 'RP2') ? 'Yes' : 'No',
                });
            }
            setLoading(false);
        }
    }, [envDetailsDataApi?.isSuccess]);

    return (
        <>
            <EnvironmentDetails envs={envDetails} isLoading={envDetailsDataApi?.isLoading}>
                <Box>
                    {order?.region && order?.tags?.length !== 0 && (
                        <>
                            <Badge color="error" variant={isMandatoryTags ? 'dot' : null} sx={ProvisioningStyles.envContainer.badge}>
                                <Btn
                                    className="btn-disabled custom-btn"
                                    onClick={() => setModal({ ...modal, isTagOpen: true })}
                                    variant="contained"
                                    color="cmpPrimary"
                                    disableElevation
                                    data-testid="tags-btn"
                                >
                                    Tags*
                                </Btn>
                            </Badge>
                        </>
                    )}
                    <Btn
                        className="btn-disabled custom-btn"
                        disabled={!order?.appProfile}
                        onClick={() =>
                            order?.appProfile
                                ? setModal({
                                      ...modal,
                                      isApplicationOpen: true,
                                  })
                                : false
                        }
                        variant="outlined"
                        color="cmpPrimary"
                        disableElevation
                        sx={ProvisioningStyles?.envContainer?.applicationBtn}
                        data-testid="app-details"
                    >
                        Application Details
                    </Btn>
                </Box>
            </EnvironmentDetails>
        </>
    );
};

export default EnvironmentContainer;
