/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
/* eslint-disable  import/no-extraneous-dependencies  */
import { getEnv, getUserScopes } from 'utils/index';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useHeaderStyle as style } from 'assets/style';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { MenuItem, Typography, Box } from '@mui/material';
import { usePopupState, bindMenu, bindHover } from 'material-ui-popup-state/hooks';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useOktaAuth } from '@okta/okta-react';
import './styles.css';
import { NavLink } from 'assets/style/headerStyles';

const DropDownMenu = (props) => {
    const { name, menuLink, subMenu, alignment, children, handleChange, activemenuItem } = props;
    const history = useHistory();
    const okta = useOktaAuth();
    const accessScopes = getUserScopes();
    const popupState = usePopupState({ variant: 'popper', popupId: `menu_${name.replace(' ', '_')}` });
    const popupSubState = usePopupState({ variant: 'popper', popupId: `submenu_${name.replace(' ', '_')}` });

    const handleLogout = () => {
        const newUser = localStorage.getItem('newUser');
        localStorage.clear();
        okta?.oktaAuth.tokenManager.clear();
        window.location.replace(getEnv('REACT_APP_OKTA_LOGOUT_URL'));
        localStorage.setItem('newUser', newUser);
    };

    const submenuRender = (subname, submenuLink, subchildMenu) => {
        return (
            <Box {...bindHover(popupSubState)}>
                <MenuItem
                    data-testid="submenu-item"
                    {...bindHover(popupSubState)}
                    sx={{
                        padding: '0px',
                        backgroundColor: '#5A5A5A',
                        fontFamily: 'Roboto',
                        fontSize: '15px',
                        fontWeight: 'normal',
                        color: '#ffffff',
                    }}
                >
                    {subname}
                    {subchildMenu.length !== 0 && <ArrowRightIcon sx={style.rightArrow} />}
                </MenuItem>
                {subchildMenu.length !== 0 && (
                    <HoverMenu
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        {...bindMenu(popupSubState)}
                        sx={{
                            ...style.hovermenus,
                            marginLeft: 1.9,
                        }}
                    >
                        {subchildMenu.map((subitem) => {
                            let shouldMenuRender = false;
                            if (!subitem.allowedScopes || subitem.allowedScopes.length === 0) shouldMenuRender = true;
                            else {
                                subitem.allowedScopes.map((allowedScope) => {
                                    if (accessScopes.includes(allowedScope)) shouldMenuRender = true;
                                    return true;
                                });
                            }
                            if (shouldMenuRender) {
                                return (
                                    <MenuItem
                                        key={subitem.id}
                                        data-testid="sub-menu-items"
                                        onClick={() => {
                                            handleChange(name);
                                            if (subitem.link && !subitem.list) {
                                                popupSubState.close();
                                                popupState.close();
                                                history.push(`${subitem.link}`);
                                            }
                                        }}
                                        sx={style.hovermenuitems}
                                    >
                                        <Typography
                                            component="h4"
                                            sx={style.menuitemColor}
                                            data-testid="submenu-out-btn"
                                            onClick={() => {
                                                history.push(`${subitem.link}`);
                                                popupSubState.close();
                                                popupState.close();
                                            }}
                                        >
                                            {subitem?.name}
                                        </Typography>
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                    </HoverMenu>
                )}
            </Box>
        );
    };

    return (
        <>
            <NavLink
                data-testid="menu-item"
                {...bindHover(popupState)}
                to={menuLink || '#'}
                onClick={() => {
                    handleChange(children || name);
                }}
                onMouseEnter={() => popupSubState.close()}
                sx={{ ...style.headermenu, borderBottom: `${activemenuItem === (children || name) && '4px solid #64C6EA'}` }}
            >
                {children || name}
                {subMenu.length !== 0 && (popupState.isOpen ? <ExpandLess sx={style.dropdownArrow} /> : <ExpandMore sx={style.dropdownArrow} />)}
            </NavLink>
            {subMenu.length !== 0 && (
                <HoverMenu
                    anchorOrigin={{ vertical: 'bottom', horizontal: alignment }}
                    transformOrigin={{ vertical: 'top', horizontal: alignment }}
                    {...bindMenu(popupState)}
                    sx={style.hovermenus}
                >
                    {subMenu.map((item) => {
                        let shouldRender = false;
                        if (!item.allowedScopes || item.allowedScopes.length === 0) shouldRender = true;
                        else {
                            item.allowedScopes.map((allowedScope) => {
                                if (accessScopes.includes(allowedScope)) shouldRender = true;
                                return true;
                            });
                        }
                        if (shouldRender) {
                            return (
                                <MenuItem
                                    key={item.id}
                                    data-testid="sub-menu-item"
                                    onClick={() => {
                                        handleChange(name);
                                        if (item.link && !item.list && !item?.children) {
                                            popupState.close();
                                            if (item.id === 'signout') {
                                                handleLogout();
                                            } else {
                                                history.push(`${item.link}`);
                                            }
                                        }
                                    }}
                                    sx={style.hovermenuitems}
                                >
                                    {!item?.children ? (
                                        <Typography
                                            component="h4"
                                            sx={style.menuitemColor}
                                            data-testid="sign-out-btn"
                                            onClick={() => (item.id === 'signout' ? handleLogout() : history.push(`${item.link}`))}
                                        >
                                            {item?.name}
                                        </Typography>
                                    ) : (
                                        submenuRender(item.name, item?.link || false, item?.children)
                                    )}
                                </MenuItem>
                            );
                        }
                        return null;
                    })}
                </HoverMenu>
            )}
        </>
    );
};

DropDownMenu.propTypes = {
    name: PropTypes.string,
    menuLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    subMenu: PropTypes.any,
    children: PropTypes.element,
    alignment: PropTypes.oneOf(['left', 'right', 'center']),
    handleChange: PropTypes.func,
    activemenuItem: PropTypes.string,
};
DropDownMenu.defaultProps = {
    name: 'name',
    menuLink: false,
    subMenu: [],
    children: null,
    alignment: 'left',
    handleChange: undefined,
    activemenuItem: '',
};

export default React.memo(DropDownMenu);
