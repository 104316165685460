/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { iaasVMCatalogServiceCode, eventhubCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';

const Subscription = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    const subscriptionApi = useMutation(() => axios.get(endPoints.landingZones.subscription));

    const setSubScription = (subscription, subscriptionDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            subscription,
            subscriptionDetails,
            resGroup: null,
            resGroupDetails: null,
            vNet: null,
            vNetDetails: null,
            subNet: null,
            subNetDetails: null,
            availSet: null,
            availSetDetails: null,
            vnetSubnet:
                landingZoneForm?.service === eventhubCatalogServiceCode
                    ? [{ vNet: null, vNetDetails: null, subNet: null, subNetDetails: null }]
                    : landingZoneForm?.vnetSubnet,
        });
    };

    const seldValueExistsInOptions = (subValue) => options.find((value) => subValue === value?.label);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSubScription(selected?.label, selected);
        }
    };

    React.useEffect(() => {
        if (subscriptionApi?.isSuccess) {
            const filteredSubscriptions = [iaasVMCatalogServiceCode].includes(landingZoneForm?.service)
                ? subscriptionApi?.data?.data?.filter((sub) => sub?.displayName?.startsWith('pep-'))
                : subscriptionApi?.data?.data;
            setOptions(filteredSubscriptions?.map((app) => ({ label: app?.displayName, value: app?.subscriptionId, id: app?.subscriptionId })));
        }
    }, [subscriptionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.subscription) {
            setLandingZoneForm({ ...landingZoneForm, subscription: options?.[0].label, subscriptionDetails: options?.[0] });
        }
        if (options && options?.length && landingZoneForm?.subscription) {
            const subscription = seldValueExistsInOptions(landingZoneForm?.subscription);
            setLandingZoneForm({ ...landingZoneForm, subscription: subscription?.label, subscriptionDetails: subscription });
        }
    }, [options]);

    React.useEffect(() => {
        subscriptionApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.subscription && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.subscription, landingZoneForm?.isTouched]);

    return (
        <Box bgcolor="white">
            <Select
                key="Subscription"
                label="Subscription*"
                value={landingZoneForm?.subscription || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subscriptionApi.isLoading}
                isLoading={subscriptionApi.isLoading}
                isRequired={isTouched}
                disableClearable={false}
            />
        </Box>
    );
};

export default React.memo(Subscription);
