/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { landingZoneData, isISADocumentVaild } from 'pages/app-profiles/store';

const IsaDocument = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);

    const [isValid, setISADocumentVaild] = useRecoilState(isISADocumentVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setISADocument = (ISADocument) => {
        setLandingZoneForm({
            ...landingZoneForm,
            ISADocument,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 10 || value?.length < 10) {
                setISADocumentVaild(false);
                setISADocument(value);
            } else if (!/^ISA[0-9]*$/.test(value)) {
                setISADocumentVaild(false);
                setISADocument(value);
            } else {
                setISADocumentVaild(true);
                setISADocument(value);
            }
        } else {
            setISADocumentVaild(false);
            setISADocument('');
        }
    };

    React.useEffect(() => {
        if (landingZoneForm?.ISADocument) setISADocument(landingZoneForm?.ISADocument);
    }, [landingZoneForm?.ISADocument]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landingZoneForm?.ISADocument?.length > 0);
    }, [landingZoneForm?.ISADocument]);
    React.useEffect(() => {
        if (!landingZoneForm?.ISADocument && landingZoneForm?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.ISADocument, landingZoneForm?.isTouched]);
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="ISA Document *"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landingZoneForm?.ISADocument}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid || isTouched}
                    helperText={`
                    ${!isValid ? 'ISA Document should start with ISA<XXXXXXX> followed by 7 digit number. Ex.: ISA0123456' : ''}
                `}
                />
            </Stack>
        </>
    );
};

export default React.memo(IsaDocument);
