export const getPayload = ({ order = [], catalogCode, catalogServiceId }) => {
    const tags = {};

    // Formatting Tags for submitOrderAPI
    order?.tags?.forEach((tag) => {
        if (tag.name === 'Sector') {
            tags[tag.name] = order?.sector || '-';
        } else if (tag.name === 'BillingEntity') {
            tags[tag.name] = order?.billingEntity;
        } else if (tag.name === 'CostCenter') {
            tags[tag.name] = order?.hCodeDetails?.label;
        } else {
            tags[tag.name] = tag.defaultValue;
        }
    });

    const payload = {
        entityDetails: {
            name: order?.appProfile,
            businessCi: order?.entityDetails?.businessCi,
            applicationManager: order?.entityDetails?.owner?.name,
            applicationManagerEmail: order?.entityDetails?.owner?.emailId,
            applicationManagerGpid: order?.entityDetails?.owner?.id,
            costCenter: order?.entityDetails?.financialDetails,
            project: order?.entityDetails?.projectName || null,
            recoveryPriority: order?.entityDetails?.recoveryPriority,
            billingEntity: order?.entityDetails?.billingEntity || '-',
            sector: order?.entityDetails?.sector || null,
        },
        catalogService: {
            ...(catalogServiceId && { catalogServiceId }),
            cloudProvider: order?.cloudProvider,
            cloudService: order?.cloudService,
            catalogServiceCode: catalogCode,
            catalogType: order?.catalogType,
            sector: order?.sector,
            estimatedCost: { ...order?.estimatedCost, type: catalogCode },
            quantity: 1,
            catalogConfig: {
                projectName: order?.projectName,
                notes: order?.notes,
                modifyService: order?.modifyService,
                environmentDetails: {
                    subscription: order?.envDetails?.config?.subscription?.name,
                    subscriptionId: order?.envDetails?.config?.subscription?.id,
                    resourceGroup: order?.envDetails?.config?.resourceGroup,
                    virtualNetwork: order?.envDetails?.config?.vnet?.name,
                    virtualNetworkId: order?.envDetails?.config?.vnet?.id,
                    subNetName: order?.envDetails?.config?.subnet,
                    networkSecurityGroup: order?.envDetails?.config?.networkSecurityGroup,
                    applicationSecurityGroup: order?.envDetails?.config?.applicationSecurityGroup,
                    applicationManager: order?.envDetails?.applicationManager,
                    applicationManagerEmail: order?.envDetails?.applicationManagerEmail,
                    applicationManagerGpid: order?.envDetails?.applicationManagerGpid,
                    businessService: order?.envDetails?.businessService,
                    drPriority: order?.envDetails?.drPriority,
                    metallic: order?.envDetails?.metallic || 'Silver',
                    projectName: order?.hCodeDetails?.projectName || null,
                    sector: order?.sector,
                    environment: order?.environment,
                    region: order?.region,
                    costCenter: order?.hCode,
                },
                tags,
                type: catalogCode,
            },
        },
    };
    return payload;
};
export default getPayload;
