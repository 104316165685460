/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddCluster, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Cluster = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDCluster, setLzDDCluster] = useRecoilState(ddCluster);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    let clusterEndpoint;
    switch (landZoneForm?.cloudService) {
        case 'PaaS/AKSNS':
            clusterEndpoint = `${endPoints.landingZones.cluster}/AKS`;
            break;
        case 'PaaS/Kafka Topics':
            clusterEndpoint = `${endPoints.landingZones.cluster}/Kafka`;
            break;
        default:
            clusterEndpoint = endPoints.landingZones.cluster;
    }
    const clusterApi = useMutation((payload) => axios.post(clusterEndpoint, payload));

    const setCluster = (cluster) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                cluster,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setCluster(null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setCluster(selected?.label);
        } else {
            setCluster(null);
        }
    };
    React.useEffect(() => {
        if (clusterApi?.isSuccess) {
            setOptions(clusterApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
            setLzDDCluster({ ...lzDDCluster, isLoaded: true });
        }
    }, [clusterApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const cluster = options.find((value) => lzModal?.lzDetails?.config?.cluster === value?.label);
            if (cluster) setLzDDCluster({ ...lzDDCluster, isLoaded: true, data: cluster });
        }
    }, [options]);

    React.useEffect(() => {
        if (landZoneForm?.environment && landZoneForm?.regionDetails?.value && landZoneForm?.department) {
            resetOptions();
            clusterApi.mutate({
                environment: landZoneForm?.environment,
                region: landZoneForm?.regionDetails?.value,
                department: landZoneForm?.department,
            });
        }
    }, [landZoneForm?.environment, landZoneForm?.regionDetails?.value, landZoneForm?.department]);

    return (
        <Box bgcolor="white">
            <Select
                key="cluster"
                value={landZoneForm?.cluster || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={clusterApi.isLoading}
                isLoading={clusterApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Cluster"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Cluster);
