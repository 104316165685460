import React from 'react';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { endPoints } from 'api/endpoints';
import { useAxios } from 'hooks';
import { useMutation } from 'react-query';
import Popper from '@mui/material/Popper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Badge, Button, Paper, Box, Stack, ClickAwayListener } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { dashboardFilter } from './store';
import dashboardStyle, { ColorButton } from './style';

const filterDefaultObj = {
    provider: null,
    service: null,
    sector: null,
    region: null,
};

const DashboardFilter = () => {
    const axios = useAxios();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [, setDashboardFilterObj] = useRecoilState(dashboardFilter);
    const [filters, setFilter] = React.useState(filterDefaultObj);
    const [provider, setProvider] = React.useState([]);
    const [service, setService] = React.useState([]);
    const [sector, setSector] = React.useState([]);
    const [region, setRegion] = React.useState([]);

    const getProviders = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    const getService = useMutation(() => axios.get(endPoints.ssp.dashboard.services));
    const getSector = useMutation(() => axios.get(endPoints.ssp.vm.sector));
    const getRegion = useMutation(() => axios.get(endPoints.landingZones.regions));

    React.useEffect(() => {
        if (getProviders?.isSuccess) {
            setProvider(getProviders?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [getProviders?.isSuccess]);
    React.useEffect(() => {
        if (getService?.isSuccess) {
            setService(getService?.data?.map((app) => ({ label: app.name, value: app.value })));
        }
    }, [getService?.isSuccess]);
    React.useEffect(() => {
        if (getSector?.isSuccess) {
            if (getSector?.data?.data?.sectors) setSector(getSector?.data?.data?.sectors.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [getSector?.isSuccess]);
    React.useEffect(() => {
        if (getRegion?.isSuccess) {
            setRegion(getRegion?.data?.data?.map((app) => ({ label: app.displayName, value: app.name })));
        }
    }, [getRegion?.isSuccess]);
    React.useEffect(() => {
        getProviders.mutate();
        getService.mutate();
        getSector.mutate();
        getRegion.mutate();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-popper' : undefined;

    const handleOnSelect = (name, selected) => {
        if (name === 'provider') {
            setFilter({ provider: selected, service: null, sector: null, region: null });
        } else {
            setFilter({ ...filters, [name]: selected });
        }
    };

    const handleSubmitFilter = () => {
        const appliedFilters = {};
        if (filters) {
            Object.keys(filters)?.forEach((filter) => {
                if (filters[filter]) {
                    appliedFilters[filter] = filters[filter]?.value || filters[filter];
                }
            });
        }
        setDashboardFilterObj(appliedFilters);
        handleClick();
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Button
                    sx={dashboardStyle.filterButton}
                    startIcon={
                        <Badge
                            variant="dot"
                            overlap="circular"
                            color={filters && Object.keys(filters)?.filter((key) => filters[key] !== null && filters[key] !== '')?.length ? 'error' : 'default'}
                        >
                            <FilterAltIcon color="#0047BA" size={36} />
                        </Badge>
                    }
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    onClick={handleClick}
                >
                    Filter
                </Button>

                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                    <Paper elevation={5} sx={dashboardStyle.orderFilter.wrapper}>
                        <ArrowDropUpIcon sx={dashboardStyle.orderFilter.arrow} />
                        <Box sx={dashboardStyle.orderFilter.popper.container}>
                            <CloseIcon
                                fontSize="small"
                                sx={dashboardStyle.orderFilter.closeIcon}
                                onClick={() => {
                                    setFilter(filterDefaultObj);
                                    setDashboardFilterObj(filterDefaultObj);
                                    handleClick();
                                }}
                            />
                        </Box>
                        <Stack spacing={2} mb={2} pt={1}>
                            <Select
                                key="providerName"
                                options={provider}
                                value={filters?.provider || ''}
                                handleOnSelect={(value) => {
                                    handleOnSelect('provider', value);
                                }}
                                label="Select Provider"
                            />
                            <Select
                                key="serviceName"
                                options={service}
                                value={filters?.service || ''}
                                handleOnSelect={(value) => {
                                    handleOnSelect('service', value);
                                }}
                                label="Select Service"
                            />
                            <Select
                                key="sector"
                                options={sector}
                                value={filters?.sector || ''}
                                handleOnSelect={(value) => {
                                    handleOnSelect('sector', value);
                                }}
                                label="Select Sector"
                            />
                            <Select
                                key="region"
                                options={region}
                                value={filters?.region || ''}
                                handleOnSelect={(value) => {
                                    handleOnSelect('region', value);
                                }}
                                label="Select Region"
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <ColorButton disableElevation onClick={handleSubmitFilter} variant="contained">
                                Apply
                            </ColorButton>
                        </Stack>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default DashboardFilter;
