import React from 'react';
import { Avatar } from '@mui/material';
import { LoggedUser } from 'utils';

function UserAvatar() {
    const initials = (str) =>
        str
            .match(/\b(\w)/g)
            .join('')
            .substring(0, 2)
            .toUpperCase();

    return (
        <div>
            <Avatar sx={{ backgroundColor: '#104e8b' }} type="button">
                {initials(LoggedUser().FullName())}
            </Avatar>
        </div>
    );
}

export default UserAvatar;
