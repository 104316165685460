import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import AppDetails from 'pages/provisioning/_components/AppDetails';
import { sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/event-hub';

const ApplicationDetails = () => {
    const order = useRecoilValue(sspSubmitOrder);
    const [modal, setModal] = useRecoilState(toggleSspModels);

    return (
        <AppDetails
            isApplicationOpen={modal?.isApplicationOpen}
            applicationService={order?.appProfile}
            handleCloseModal={() => setModal({ ...modal, isApplicationOpen: false })}
        />
    );
};

ApplicationDetails.propTypes = {};

ApplicationDetails.defaultProps = {};

export default ApplicationDetails;
