import React from 'react';
import { Grid, Badge, Table, TableBody, TableContainer, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useRecoilState } from 'recoil';
import Checkbox from '@mui/material/Checkbox';
import Btn from 'components/button';
import { OrderPayload, toggleModal } from 'pages/project-onboarding/store';
import { serviceList, noConfigServiceList } from 'pages/project-onboarding/utils/constants';
import { getServiceName, getServiceVal, getServiceDisabled, getServiceChecked, getBadgeColor } from 'pages/project-onboarding/utils/getFunctions';

export const ServiceResources = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);

    const setServiceResources = (event, service) => {
        setOrder({
            ...order,
            serviceResources: {
                ...order.serviceResources,
                ...(service === 'projectAdmin' && { isProjectAdmin: event.target.checked }),
                ...(service === 'boards' && { isBoard: event.target.checked }),
                ...(service === 'repos' && { isRepos: event.target.checked }),
                ...(service === 'pipelines' && { isPipelines: event.target.checked }),
                ...(service === 'sonarQube' && { isSonarQube: event.target.checked }),
                ...(service === 'artifactory' && { isArtifactory: event.target.checked }),
                ...(service === 'securityTools' && { isSecurityTools: event.target.checked }),
                ...(service === 'testplans' && { isTestPlan: event.target.checked }),
            },
        });
    };

    const handleConfigure = (service) => {
        switch (service) {
            case 'projectAdmin':
                setIsOpen({ ...isOpen, isProjectAdminOpen: true });
                break;
            case 'sonarQube':
                setIsOpen({ ...isOpen, isSonarQubeOpen: true });
                break;
            case 'artifactory':
                setIsOpen({ ...isOpen, isArtifactoryOpen: true });
                break;
            case 'securityTools':
                setIsOpen({ ...isOpen, isFortifySnykOpen: true });
                break;
            case 'testplans':
                setIsOpen({ ...isOpen, isTestPlanOpen: true });
                break;
            default:
                setIsOpen({ ...isOpen });
        }
    };

    React.useEffect(() => {
        if (order?.projectDetails) {
            setOrder({
                ...order,
                allowedAdminMembers:
                    order?.existingAdminMembers?.length > 0 ? order?.allowedAdminMembers - order?.existingAdminMembers?.length : order?.allowedAdminMembers,
            });

            if (order?.adoProjectType) {
                setOrder({
                    ...order,
                    serviceResources: {
                        ...order.serviceResources,
                        isProjectAdmin: order?.serviceResources?.isProjectAdmin ? true : order?.existingADOServices?.includes('projectAdmin'),
                        isBoard: order?.serviceResources?.isBoard ? true : order?.existingADOServices?.includes('boards'),
                        isRepos: order?.serviceResources?.isRepos ? true : order?.existingADOServices?.includes('repos'),
                        isPipelines: order?.serviceResources?.isPipelines ? true : order?.existingADOServices?.includes('pipelines'),
                    },
                });
            }
        }
    }, [order?.projectDetails, order?.adoProjectType]);

    const getProjectAdminVal = () => {
        if (
            ((order?.isADOProject === 'no' && order?.isClarityId === 'yes' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null) ||
                order?.isADOProject === 'yes') &&
            order?.existingAdminMembers?.length > 0 &&
            order?.existingAdminMembers?.length < 5
        ) {
            return false;
        }
        return true;
    };

    React.useEffect(() => {
        if (!order?.serviceResources?.isProjectAdmin) {
            setOrder({
                ...order,
                adminAccessMember: null,
                adminAccessMemberDetails: null,
                adminAccessMemberList: [],
                serviceResources: {
                    ...order.serviceResources,
                    isBoard: false,
                    isRepos: false,
                },
            });
        }
    }, [order?.serviceResources?.isProjectAdmin]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isBoard) {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isTestPlan: false,
                    isSecurityTools: false,
                },
            });
        } else {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isProjectAdmin: getProjectAdminVal(),
                },
            });
        }
    }, [order?.serviceResources?.isBoard]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isRepos) {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isPipelines: false,
                    isSonarQube: false,
                    isSecurityTools: false,
                },
            });
        } else {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isProjectAdmin: getProjectAdminVal(),
                },
            });
        }
    }, [order?.serviceResources?.isRepos]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isPipelines) {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isSonarQube: false,
                    isSecurityTools: false,
                },
            });
        } else {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isProjectAdmin: getProjectAdminVal(),
                    isRepos: true,
                },
            });
        }
    }, [order?.serviceResources?.isPipelines]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isSonarQube) {
            setOrder({
                ...order,
                sonarQubeUser: null,
                sonarQubeUserDetails: null,
                sonarQubeUserList: [],
                sonarQubeCodeRepo: null,
                appShortName: null,
            });
        } else {
            setOrder({
                ...order,
                sonarQubeCodeRepoList: [{ repo: '', isRepoTouched: false }],
                serviceResources: {
                    ...order.serviceResources,
                    isRepos: true,
                    isPipelines: true,
                },
            });
        }
    }, [order?.serviceResources?.isSonarQube]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isArtifactory) {
            setOrder({
                ...order,
                progLanguage: null,
                progLanguageDetails: null,
                buildTool: null,
                buildToolDetails: null,
                packageType: null,
                packageTypeDetails: null,
                artifactoryUser: null,
                artifactoryUserDetails: null,
                artifactoryUserList: [],
            });
        }
    }, [order?.serviceResources?.isArtifactory]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isSecurityTools) {
            setOrder({
                ...order,
                teamName: null,
                applicationUrl: null,
                securityTool: null,
                securityToolCodeRepoList: [{ repo: '', isRepoTouched: false }],
                fsMember: null,
                fsMemberDetails: null,
                fsMemberList: [],
            });
        } else {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isBoard: true,
                    isRepos: true,
                    isPipelines: true,
                },
            });
        }
    }, [order?.serviceResources?.isSecurityTools]);

    React.useEffect(() => {
        if (!order?.serviceResources?.isTestPlan) {
            setOrder({
                ...order,
                hCode: null,
                hCodeDetails: null,
                testPlanUser: null,
                testPlanUserDetails: null,
                testPlanUserList: [],
                estimatedCost: {
                    details: [],
                    qty: null,
                    totalOneTimeCost: {
                        frequency: 'one time',
                        estCost: null,
                        currency: 'USD',
                    },
                    totalRecurringCost: {
                        frequency: 'monthly',
                        estCost: null,
                        currency: 'USD',
                    },
                    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
                },
            });
        } else {
            setOrder({
                ...order,
                serviceResources: {
                    ...order.serviceResources,
                    isBoard: true,
                },
            });
        }
    }, [order?.serviceResources?.isTestPlan]);

    const getCheckedVal = (isEnabled, service) => {
        if (isEnabled) {
            return getServiceVal(service, order?.serviceResources);
        }
        return false;
    };

    return (
        <Grid container spacing={2} data-testid="service-resources">
            <TableContainer>
                <Table size="small">
                    <TableBody style={{ width: '100%' }}>
                        {serviceList?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="left" style={{ width: '35%' }}>
                                    <Typography>
                                        <Checkbox
                                            disabled={
                                                ['artifactory', 'securityTools'].includes(item)
                                                    ? true
                                                    : getServiceDisabled(item, order?.existingADOServices, order)
                                            }
                                            checked={
                                                order?.isADOProject === 'yes' ||
                                                (order?.isADOProject === 'no' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null)
                                                    ? getServiceChecked(item, order?.existingADOServices, order) ||
                                                      getCheckedVal(!getServiceDisabled(item, order?.existingADOServices, order), item)
                                                    : getServiceVal(item, order?.serviceResources)
                                            }
                                            onChange={(event) => setServiceResources(event, item)}
                                        />
                                        <span
                                            style={{
                                                color:
                                                    getServiceDisabled(item, order?.existingADOServices, order) === true ||
                                                    ['Fortify/Snyk - SAST', 'Artifactory (Coming soon in CMP)'].includes(getServiceName(item))
                                                        ? 'grey'
                                                        : 'black',
                                            }}
                                        >
                                            {getServiceName(item)}
                                        </span>
                                    </Typography>
                                </TableCell>

                                <TableCell align="left" style={{ width: '55%' }}>
                                    {item === 'artifactory' && (
                                        <span style={{ fontSize: '10px' }}>
                                            To enable Artifactory for the selected ADO project, Service request needs to be submitted in SNOW. Please connect
                                            ITALMToolsSupport@pepsico.com for more details.
                                        </span>
                                    )}
                                </TableCell>

                                <TableCell align="right" style={{ width: '10%' }}>
                                    {!noConfigServiceList?.includes(item) && (
                                        <Badge
                                            color="error"
                                            variant={
                                                !getServiceVal(item, order?.serviceResources) ||
                                                getServiceDisabled(item, order?.existingADOServices, order) ||
                                                getBadgeColor(item, order)
                                                    ? 'standard'
                                                    : 'dot'
                                            }
                                        >
                                            <Btn
                                                size="small"
                                                disabled={
                                                    !getServiceVal(item, order?.serviceResources) || getServiceDisabled(item, order?.existingADOServices, order)
                                                }
                                                onClick={() => handleConfigure(item)}
                                                variant="contained"
                                                color="cmpPrimary"
                                                data-testid="configure-btn"
                                            >
                                                CONFIGURE
                                            </Btn>
                                        </Badge>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default React.memo(ServiceResources);
