import { AlbCatalogServiceCode } from 'utils/catalogServiceCode';

export const setLandingZoneOnLocalStorage = (data) => localStorage.setItem('landingZone', data);
export const getLandingZoneFromLocalStorage = () => JSON.parse(localStorage.getItem('landingZone'));
export const clearLandingZoneFromLocalStorage = () => localStorage.removeItem('landingZone');

export const resetLandingZone = (cloudService) => ({
    subscription: null,
    subscriptionDetails: null,
    resGroup: null,
    resGroupDetails: null,
    vNet: null,
    vNetDetails: null,
    subNet: null,
    subNetDetails: null,
    nwSecGrp: null,
    nwSecGrpDetails: null,
    appSecGrp: null,
    appSecGrpDetails: null,
    proxPlacGrp: null,
    proxPlacGrpDetails: null,
    availSet: null,
    availSetDetails: null,
    availZone: null,
    availZoneDetails: null,
    department: null,
    ldap: null,
    landingZoneDesc: null,
    landingZoneLabel: null,
    sku: cloudService === AlbCatalogServiceCode ? 'Standard' : null,
    type: cloudService === AlbCatalogServiceCode ? 'Internal' : null,
    geoRecoveryRegion: null,
    geoRecoveryRegionDetails: null,
    dataClassification: null,
    dataClassificationDetails: null,
    vnetSubnet: [{ networkSubscription: null, networkSubscriptionDetails: null, vNet: null, vNetDetails: null, subNet: null, subNetDetails: null }],
    cluster: null,
    endPointType: null,
    endPointTypeDetails: null,
    appName: null,
});

export const getResetFilter = ({ fieldName }) => {
    if (fieldName === 'cloudProvider') {
        return {
            cloudService: null,
            environment: null,
            region: null,
        };
    }
    if (fieldName === 'cloudService') {
        return {
            environment: null,
            region: null,
        };
    }
    if (fieldName === 'environment') {
        return {
            region: null,
        };
    }
    return {};
};
