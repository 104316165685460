import _ from 'lodash';

export const getAddMemberPayload = ({ order, members }) => {
    const newArray = members?.map((obj) => {
        return _.omit(obj, ['label']);
    });

    const getMembers = (arr) => {
        const result = [];
        const pamsafeGPids = order?.mypamsafeDetails?.map((pam) => pam?.safeOwner);
        const getsafename = (safeobj) => order?.mypamsafeDetails?.filter((pam) => pam?.safeOwner === safeobj?.gpid)?.[0]?.safeName;

        arr?.map((obj) => {
            if (order?.mypamsafeDetails?.length > 0) {
                if (pamsafeGPids?.includes(obj?.gpid)) {
                    const mergedobj = { ...obj, safeName: getsafename(obj) };
                    result?.push(mergedobj);
                } else if (result?.length > 0 && result.some((res) => res?.gpid !== obj?.gpid)) {
                    const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                    result?.push(mergedobj);
                } else if (result?.length === 0 && result.some((res) => res?.gpid !== obj?.gpid)) {
                    const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                    result?.push(mergedobj);
                } else {
                    const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                    result?.push(mergedobj);
                }
            } else {
                const mergedobj = { ...obj, safeName: 'SAFE NOT AVAILABLE' };
                result?.push(mergedobj);
            }
            return '';
        });
        return result;
    };

    const payload = {
        businessApplication: order?.businessApplication,
        environment: order?.environment,
        sector: order?.sector,
        ...((!order?.isNewADGroup || order?.isNewADGroup === 'no') && {
            adGroup: order?.adGroup,
        }),
        ...(order?.isNewADGroup === 'no' && {
            newADGroup: order?.isNewADGroup === 'yes',
        }),
        ...(order?.isNewADGroup === 'yes' && {
            newADGroup: order?.isNewADGroup === 'yes',
            applicationShortName: order?.appShortName,
            groupOwner: { gpid: order?.groupOwnerDetails?.gpid, email: order?.groupOwnerDetails?.email, name: order?.groupOwnerDetails?.name },
            adGroupType: order?.adGroupTypeDetails?.value,
            applicationName: order?.applicationName,
            applicationService: order?.appService,
        }),
        members: order?.adGroupType === 'Privileged' ? getMembers(newArray) : newArray,
    };
    return payload;
};

export const getDay2ResubmitRequestPayload = ({ order }) => {
    const catalog = order?.catalogServices?.[0]?.catalogConfig;
    const payload = {
        ...(catalog?.adGroup && {
            adGroup: catalog?.adGroup,
        }),
        ...((catalog?.newADGroup === true || catalog?.newADGroup === false) && {
            newADGroup: catalog?.newADGroup,
        }),
        ...(catalog?.newADGroup === true && {
            applicationShortName: catalog?.applicationShortName,
            groupOwner: catalog?.groupOwner,
            adGroupType: catalog?.adGroupType,
            applicationName: catalog?.applicationName,
            applicationService: catalog?.applicationService,
        }),
        businessApplication: catalog?.businessApplication,
        environment: catalog?.environment,
        sector: catalog?.sector,
        members: catalog?.members,
    };
    return payload;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    if (data?.catalogServices?.[0]?.catalogConfig) {
        const response = data?.catalogServices?.[0]?.catalogConfig;
        const getPamsafedetails = (resmembers) => {
            const safeRes = resmembers?.filter((mem) => mem?.safeName !== 'SAFE NOT AVAILABLE');
            const result = safeRes?.map((safemem) => ({ safeOwner: safemem?.gpid, safeName: safemem?.safeName }));
            return result;
        };
        const getnonSafeDetails = (safemembers) => {
            const res = safemembers?.filter((mem) => mem?.safeName === 'SAFE NOT AVAILABLE');
            return res?.map((mems) => mems?.gpid);
        };
        return {
            businessApplication: response?.businessApplication,
            sector: response?.sector,
            environment: response?.environment,
            members: response?.members,
            adGroupType: response?.adGroupType,
            ...(response?.adGroupType === 'Privileged' && {
                mypamsafeDetails: getPamsafedetails(response?.members),
                nonSafeDetailsGpid: getnonSafeDetails(response?.members),
            }),
            ...(response?.adGroupType !== 'Privileged' && {
                mypamsafeDetails: [],
                nonSafeDetailsGpid: [],
            }),
            ...(response?.adGroup && {
                adGroup: response?.adGroup,
            }),
            ...(response?.newADGroup === false && {
                isNewADGroup: response?.newADGroup === true ? 'yes' : 'no',
            }),
            ...(response?.newADGroup === true && {
                isNewADGroup: response?.newADGroup === true ? 'yes' : 'no',
                adGroupType: response?.adGroupType,
                appShortName: response?.applicationShortName,
                groupOwnerDetails: { name: response?.groupOwner?.name },
                adGroupTypeDetails: { value: response?.adGroupType },
                applicationName: response?.applicationName,
                appService: response?.applicationService,
            }),
            isTouched: false,
        };
    }
    return null;
};
export const costPayload = ({ order = {}, catalogServiceId, day2Catalog }) => {
    const payload = {
        ...(catalogServiceId && { catalogServiceId }),
        cloudProvider: day2Catalog?.[0]?.cloudProvider,
        cloudService: day2Catalog?.[0]?.cloudService,
        catalogServiceCode: day2Catalog?.[0]?.catalogDefinitionId,
        catalogType: day2Catalog?.[0]?.catalogType,
        sector: order?.sector,
        quantity: 1,
        catalogConfig: {
            safeMemberCount: order?.nonSafeDetailsGpid?.length,
            isPrivileged: order?.adGroupType === 'Privileged',
        },
    };

    return payload;
};
