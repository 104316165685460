/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { islandingZoneLabelVaild, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const LandingZoneLabel = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [isValid, setLabelVaild] = useRecoilState(islandingZoneLabelVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);
    const setLabel = (landingZoneLabel = null) => {
        setLandingZoneForm({
            ...landZoneForm,
            landingZoneLabel,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 100 || value?.length < 3) {
                setLabelVaild(false);
                setLabel(value);
            } else if (!/^[a-zA-Z0-9-]+$/.test(value)) {
                setLabelVaild(false);
                setLabel(value);
            } else {
                setLabelVaild(true);
                setLabel(value);
            }
        } else {
            setLabelVaild(false);
            setLabel();
        }
    };

    React.useEffect(() => {
        if (lzModal?.lzDetails?.config?.landingZoneLabel) setLabel(lzModal?.lzDetails?.config?.landingZoneLabel);
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landZoneForm?.landingZoneLabel?.length > 0);
    }, [landZoneForm?.landingZoneLabel]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Label*"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landZoneForm?.landingZoneLabel}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    // onBlur={() => landZoneForm?.landingZoneLabel?.length > 0 && setShrinkField(false)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'Landing zone label should be min. 3 to max. 100 chars and alphabets only' : ''}
                `}
                />
                <InfoImg title="Label should be unique, which enables you create multiple landing zones with different configurations." />
            </Stack>
        </>
    );
};

export default React.memo(LandingZoneLabel);
