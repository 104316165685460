import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Stack, Box } from '@mui/material';
import Empty from 'components/empty';
import ProvisioningStyles from 'pages/provisioning/aksns/style';

const ProvisioningHeader = React.lazy(() => import('pages/provisioning/nsg-common-rule/ProvisioningHeader'));
const ProvisioningForm = React.lazy(() => import('pages/provisioning/nsg-common-rule/ProvisioningForm'));
const OrderSuccessPopup = React.lazy(() => import('pages/provisioning/nsg-common-rule/components/OrderSuccessPopup'));

const NSGProvisioningPage = () => (
    <>
        <Helmet>
            <title>NSG Common Rule - CMP</title>
        </Helmet>
        <Suspense
            fallback={
                <Stack sx={ProvisioningStyles?.loadingStack}>
                    <Empty color="#000000" isLoading />
                </Stack>
            }
        >
            <Box sx={ProvisioningStyles.provisioningBox}>
                <ProvisioningHeader />
                <Box sx={ProvisioningStyles.sspform_wrapper}>
                    <ProvisioningForm />
                </Box>
                <OrderSuccessPopup />
            </Box>
        </Suspense>
    </>
);

export default NSGProvisioningPage;
