import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Styles from 'pages/provisioning/virtual-service/style';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const StickyHeader = (props) => {
    const { title, iconPath, showCartBtn, cartBtn, back, handleBackBtn, showInfoIcon, infoIcon } = props;
    return (
        <Box sx={Styles?.header?.wrapper} data-testid="sticky-header">
            <Box sx={Styles?.header?.subWrapper}>
                <Box style={Styles?.header?.ImgSpan}>
                    {back && (
                        <IconButton onClick={handleBackBtn} sx={Styles?.header?.backBtn} data-testid="back-icon">
                            <ArrowBackIosNewIcon sx={Styles?.header?.backIcon} />
                        </IconButton>
                    )}
                    <img src={iconPath} alt="" style={Styles?.header?.img} />
                </Box>
                <Box sx={Styles?.header?.title}>{title}</Box>
                {showInfoIcon && <Box sx={Styles?.header?.infoIconStyle}>{infoIcon}</Box>}
            </Box>
            {showCartBtn && <Box sx={Styles?.header?.buttonWrapper}>{cartBtn}</Box>}
        </Box>
    );
};

StickyHeader.propTypes = {
    title: PropTypes.string,
    iconPath: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    showCartBtn: PropTypes.any,
    cartBtn: PropTypes.any,
    handleBackBtn: PropTypes.func.isRequired,
    back: PropTypes.bool,
    showInfoIcon: PropTypes.bool,
    infoIcon: PropTypes.any,
};

StickyHeader.defaultProps = {
    title: '',
    iconPath: '',
    showCartBtn: false,
    cartBtn: null,
    back: false,
    showInfoIcon: false,
    infoIcon: null,
};

export default React.memo(StickyHeader);
