/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const Environments = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const environmentApi = useMutation((payload) => axios.post(endPoints.ssp.vm.environments, payload));
    const setEnv = (environment) => {
        setOrder((previous) => ({
            ...previous,
            environment,
            appTier: null,
            region: null,
            regionDisplayName: null,
            landingZone: null,
            landingZoneDetails: null,
            envDetails: undefined,
            tags: [],
            defaultTags: [],
        }));
    };
    const resetOptions = () => {
        setOptions([]);
    };
    const seldValueExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);
    const handleOnSelectChange = (selected) => {
        if (selected.value !== order?.environment && seldValueExistsInOptions(selected.value)) {
            setEnv(selected.value);
        }
    };

    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            setOptions(environmentApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [environmentApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.environment) {
            setOrder({ ...order, environment: options?.[0].value });
        }
        if (options && options?.length && order?.environment) {
            const environment = seldValueExistsInOptions(order?.environment);
            setOrder({ ...order, environment: environment?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order.appProfile && order?.cloudProvider && order?.cloudService) {
            environmentApi.mutate({
                appProfileName: order?.appProfile,
                cloudProvider: order?.cloudProvider,
                cloudService: order?.cloudService,
            });
        }
        // eslint-disable-next-line
    }, [order?.appProfile, order?.cloudProvider, order?.cloudService]);

    React.useEffect(() => {
        if (!order?.environment && order?.isTouched) setIsTouched(true);
    }, [order?.environment, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="environment"
                label="Environment *"
                value={order?.environment || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={environmentApi.isLoading}
                isLoading={environmentApi.isLoading}
                isRequired={isTouched}
                // onBlur={() => setIsTouched(true)}
            />
        </Box>
    );
};

export default React.memo(Environments);
