import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const usersCount = atom({
    key: 'dashboardnew-usersCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const publishedCatalogsCount = atom({
    key: 'dashboardnew-publishedCatalogsCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const applicationCount = atom({
    key: 'dashboardnew-applicationCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const ordersSubmittedCount = atom({
    key: 'dashboardnew-ordersSubmittedCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const ordersFulfilledCount = atom({
    key: 'dashboardnew-ordersFulfilledCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const provisionedOrdersCount = atom({
    key: 'dashboardnew-provisionedOrdersCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const orderCreatedWithCMP = atom({
    key: 'dashboardnew-orderCreatedWithCMP',
    default: [],
});

export const applicationOnBoard = atom({
    key: 'dashboardnew-applicationOnBoard',
    default: [],
});

export const orderCreationTrend = atom({
    key: 'dashboardnew-orderCreationTrend',
    default: [],
});
export const servicesCatalogsData = atom({
    key: 'dashboardnew-servicesCatalogsData',
    default: {
        loading: true,
    },
});

const dt = new Date();
dt.setMonth(dt.getMonth() - 5);
export const dateRangeValue = atom({
    key: 'dashboardnew-dateRangeValue',
    default: { startDate: dt.toString(), endDate: new Date() },
});

export const dashboardFilter = atom({
    key: 'dashboardnew-dashboardFilter',
    default: { provider: null, service: null, sector: null, region: null },
});
