import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload } from 'pages/provisioning/virtual-service/store';

export const Environment = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [options, setOptions] = React.useState([]);

    const environmentApi = useMutation(() => axios.get(endPoints.virtualService.environment));

    const setEnvironment = (environment) => {
        setOrder({
            ...order,
            environment,
            program: null,
            department: null,
            aksCluster: null,
            aksNamespace: null,
            aksVirtualService: order?.aksVirtualService.map((vs) => {
                return {
                    ...vs,
                    customDomain: [
                        {
                            name: null,
                        },
                    ],
                };
            }),
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setEnvironment(selected.value, selected?.data?.backendName);

    React.useEffect(() => {
        if (environmentApi?.isSuccess) {
            setOptions(environmentApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName, data: app })));
        }
    }, [environmentApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.environment) {
            setOrder({ ...order, environment: options?.[0].value });
        }
        if (options && options?.length && order?.environment) {
            const environment = isExistsInOptions(order?.environment);
            setOrder({ ...order, environment: environment?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        environmentApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.environment && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.environment, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="environment"
                data-testid="environment-autocomplete"
                label="Environment *"
                value={order?.environment || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={environmentApi.isLoading}
                isLoading={environmentApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Environment);
