import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';

const StyledButton = styled(LoadingButton)(() => ({}));

const Btn = (props) => {
    const { children, text, ...rest } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StyledButton className="custom-btn" disableElevation disableRipple {...rest}>
            {children || text}
        </StyledButton>
    );
};

Btn.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
};

Btn.defaultProps = {
    text: 'Button',
    children: undefined,
};

export default Btn;
