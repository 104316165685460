import * as React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'assets/style/headerStyles';
import { usePopupState, bindHover, bindFocus } from 'material-ui-popup-state/hooks';
import { useHeaderStyle as style } from 'assets/style';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import { CascadingMenuItem } from 'components/cascading-hover-menus/components/cascading-menu-item';
import { CascadingSubmenu } from 'components/cascading-hover-menus/components/cascading-submenu';
import { CascadingMenu } from 'components/cascading-hover-menus/components/cascading-menu';

function renderSubMenuItems(items, parentId) {
    return items?.map((item) => {
        if (item.children && item.children.length > 0) {
            return (
                <CascadingSubmenu key={item.id} popupId={`${parentId}-${item.id}`} title={item.name}>
                    {renderSubMenuItems(item.children, `${parentId}-${item.id}`)}
                </CascadingSubmenu>
            );
        }
        return (
            <CascadingMenuItem key={item.id} disabled={item.isDisabled}>
                <Box
                    component={NavLink}
                    to={item?.link || '#'}
                    sx={{
                        ...style?.cascadingMenu?.menuItem?.navLink,
                        ...(item.isDisabled && style?.cascadingMenu?.menuItem?.disabledNavLink),
                    }}
                >
                    <Box sx={style?.cascadingMenu?.menuItem?.subMenu}>{item.name}</Box>
                </Box>
            </CascadingMenuItem>
        );
    });
}

const CascadingHoverMenus = (props) => {
    const { activeMenuItem, menuItems } = props;
    return (
        <>
            {menuItems?.map((item) => {
                const popupState = usePopupState({
                    popupId: `menu-${item.id}`,
                    variant: 'popover',
                });

                return (
                    <React.Fragment key={item.id}>
                        <NavLink
                            to={item?.link || '#'}
                            variant="contained"
                            {...bindHover(popupState)}
                            {...bindFocus(popupState)}
                            sx={{ ...style.headermenu, borderBottom: `${activeMenuItem === item?.name && '4px solid #64C6EA'}` }}
                        >
                            {item.name}
                            {item?.children &&
                                item?.children?.length !== 0 &&
                                (popupState?.isOpen ? <ExpandLess sx={style.dropdownArrow} /> : <ExpandMore sx={style.dropdownArrow} />)}
                        </NavLink>
                        {item?.children && item.children?.length && (
                            <CascadingMenu
                                popupState={popupState}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            >
                                {renderSubMenuItems(item.children, item.id)}
                            </CascadingMenu>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

CascadingHoverMenus.propTypes = {
    activeMenuItem: PropTypes.string,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            children: PropTypes.array,
            isDisabled: PropTypes.bool,
        })
    ),
};

CascadingHoverMenus.defaultProps = {
    activeMenuItem: '',
    menuItems: null,
};

export default CascadingHoverMenus;
