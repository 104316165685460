/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const DiskTier = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setDiskTier = (diskTier, diskTierDetail) => {
        setOrder({ ...order, diskTier, diskTierDetail });
    };
    const seldValueExistsInOptions = (diskTierValue) => options.find((option) => diskTierValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setDiskTier(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        setOptions(
            ['P1', 'P2', 'P3', 'P4', 'P6', 'P10', 'P15', 'P20', 'P30', 'P40', 'P50', 'P60', 'P70', 'P80']?.map((data) => ({ label: data, value: data }))
        );
    }, []);
    React.useEffect(() => {
        if (!order?.diskTier && order?.isTouched) setIsTouched(true);
    }, [order?.diskTier, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="diskTier"
                label="Disk Tier *"
                value={order?.diskTier || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(DiskTier);
