import React from 'react';
import { useRecoilState } from 'recoil';
import { Box, Grid, Typography, Divider, Paper } from '@mui/material';
import Lz from 'pages/app-profiles/landing-zones/components/Form';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { toggleCreateLandingZone, landingZoneForm } from 'pages/app-profiles/store';
import LandingZoneStyle from './LandingZoneStyle';

const StorageAccountFields = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const handleAdd = () => {
        const form = { ...landZoneForm };
        try {
            form.privateEndPoints = [
                ...form.privateEndPoints,
                {
                    endPointName: null,
                    storageSubResource: null,
                    dnsZone: null,
                    privateDnsZone: null,
                },
            ];
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    React.useEffect(() => {
        if (!lzModal?.lzDetails) {
            handleAdd();
        }
    }, [lzModal?.lzDetails]);

    const isDuplicateVnetSubnetValue = (value) => {
        const vnetSubnetValues = landZoneForm?.vnetSubnet?.map((data) => data?.vNet + data?.subnet + data?.networkSubscription);
        const duplicateVnetSubnet = vnetSubnetValues.filter((item, index) => vnetSubnetValues.indexOf(item) !== index);
        if (value) {
            return duplicateVnetSubnet.indexOf(value) > -1;
        }
        return false;
    };
    const handleDelete = (vnetSubnetToRemove) => {
        const form = { ...landZoneForm };
        const currentVnetSubnet = form?.vnetSubnet;
        const vnetSubnetRemoveIndex = currentVnetSubnet.findIndex((val) => val === vnetSubnetToRemove);
        const vnetSubnet = [...currentVnetSubnet.slice(0, vnetSubnetRemoveIndex), ...currentVnetSubnet.slice(vnetSubnetRemoveIndex + 1)];
        setLandingZoneForm({ ...landZoneForm, vnetSubnet });
    };
    const handleAddNetworks = () => {
        const form = { ...landZoneForm };
        try {
            form.vnetSubnet = [
                ...form.vnetSubnet,
                {
                    networkSubscription: null,
                    networkSubscriptionDetails: null,
                    vNet: null,
                    vNetDetails: null,
                    subnet: null,
                    subnetDetails: null,
                },
            ];
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };
    return (
        <>
            <Grid item xs={6}>
                {' '}
                <Lz.Environments />
            </Grid>
            {/* <Grid item xs={6}>
                {' '}
                <Lz.AppTier />
            </Grid> */}
            <Grid item xs={6}>
                {' '}
                <Lz.Region />
            </Grid>
            <Grid item xs={6}>
                <Lz.Subscription />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.ResourceGroup />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.DataClassification />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.LandingZoneLabel />
            </Grid>
            <Grid item xs={6}>
                {' '}
                <Lz.LandingZoneDesc />
            </Grid>
            <Grid item xs={12} mt={2}>
                <Paper
                    sx={{
                        height: '100%',
                        borderRadius: (theme) => theme.spacing(0.75),
                        marginBottom: (theme) => theme.spacing(3.125),
                        border: '1px solid #E0E0E0',
                    }}
                >
                    <Box display="flex" m={2}>
                        <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                            Service Endpoint
                        </Typography>
                    </Box>
                    <Divider />

                    <Box display="flex" m={2}>
                        <Typography sx={LandingZoneStyle?.selectedNetworks}>Selected Networks</Typography>
                    </Box>
                    <Grid container spacing={2} ml={1}>
                        <>
                            {landZoneForm?.vnetSubnet?.map((item, index) => (
                                <>
                                    <Grid item xs={10}>
                                        <Lz.NetworkSubscription index={index} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Lz.ServiceEndPointVNet index={index} />
                                        {isDuplicateVnetSubnetValue(item?.vNet + item?.subnet + item.networkSubscription) && (
                                            <Grid item xs={12}>
                                                <Typography variant="body2" gutterBottom sx={LandingZoneStyle.vnetSubnetStyle.warningTypography}>
                                                    Duplicate Vnet and Subnet values are not allowed.
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Lz.ServiceEndPointSubNet index={index} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        {index !== 0 && landZoneForm?.vnetSubnet?.length > 1 && (
                                            <Box sx={LandingZoneStyle.vnetSubnetStyle.deleteWrapper} mr={1} ml={1}>
                                                <FaTrashAlt
                                                    style={LandingZoneStyle.vnetSubnetStyle.delete}
                                                    onClick={() => handleDelete(item)}
                                                    className="w-7 h-7 text-red-base cursor-pointer"
                                                    data-testid={`DelBtn-${index}`}
                                                />
                                            </Box>
                                        )}
                                        {index === landZoneForm?.vnetSubnet?.length - 1 && landZoneForm?.vnetSubnet?.length < 5 ? (
                                            <Box sx={LandingZoneStyle.vnetSubnetStyle.addWrapper} ml={1}>
                                                <MdAdd
                                                    style={LandingZoneStyle.vnetSubnetStyle.add}
                                                    onClick={() => handleAddNetworks()}
                                                    className="w-7 h-7 text-blue-base cursor-pointer"
                                                    data-testid={`AddBtn-${index}`}
                                                />
                                            </Box>
                                        ) : (
                                            <span className="w-7 h-7" />
                                        )}
                                    </Grid>
                                    {landZoneForm?.vnetSubnet?.length > 1 && landZoneForm?.vnetSubnet?.length - 1 !== index && (
                                        <Grid item xs={10}>
                                            <Divider variant="horizontal" flexItem xs={{ marginY: 1 }} />
                                        </Grid>
                                    )}
                                </>
                            ))}
                        </>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} mt={2} display="none">
                <Box display="flex" mb={2}>
                    <Typography variant="h1" sx={LandingZoneStyle?.privateEndpoints}>
                        Private endpoint
                    </Typography>
                    <Btn sx={LandingZoneStyle?.addBtn} text="Add" variant="contained" color="primary" onClick={() => handleAdd()} />
                </Box>
                <Grid container spacing={2}>
                    {landZoneForm?.privateEndPoints?.length > 0 && (
                        <>
                            {landZoneForm?.privateEndPoints?.map((item, index) => (
                                <>
                                    {/* <Grid item xs={6}>
                                        <Lz.EndPointName index={index} />
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <Lz.StorageSubResource index={index} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Lz.DnsZoneEnable index={index} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Lz.PrivateDnsZone index={index} />
                                    </Grid>
                                </>
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
export default StorageAccountFields;
