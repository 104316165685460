import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/event-hub';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';

const Region = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);

    const regionApi = useMutation((payload) => axios.post(endPoints.ssp.vm.regions, payload));
    const setRegion = (region, displayName) => {
        setOrder({
            ...order,
            region,
            regionDisplayName: displayName,
            landingZone: null,
            landingZoneDetails: null,
            envDetails: undefined,
            nsExist: 'no',
            namespaceName: null,
            performance: 'Standard',
            throughputUnits: '1',
            isAutoInflate: 'no',
            autoInflateMaxThroughputUnits: null,
            processingUnits: '1',
            eventHubDetails: [
                {
                    eventHubName: '',
                    partitionCount: 1,
                    retention: null,
                    isEventHubTouched: false,
                    consumerGroupList: [{ value: '' }],
                },
            ],
            cartMessage: false,
            isTouched: false,
            tags: [],
            defaultTags: [],
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.data?.code || regionValue === value?.data?.name);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== order?.region && seldValueExistsInOptions(selected?.value)) {
            if (order?.region !== selected?.data?.code) setRegion(selected?.data?.code, selected?.data?.name);
        }
    };

    React.useEffect(() => {
        resetOptions();
        if (order?.appProfile && order?.environment) {
            regionApi.mutate({
                cloudService: order?.cloudService,
                environment: order?.environment,
                appProfileName: order?.appProfile,
                cloudProvider: order?.cloudProvider,
            });
        }
    }, [order?.appProfile, order?.environment]);

    React.useEffect(() => {
        if (regionApi?.isSuccess) {
            setOptions(regionApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [regionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.region) {
            setOrder({ ...order, region: options?.[0].data?.code, regionDisplayName: options?.[0]?.data?.name });
        }
        if (options && options?.length && order?.region) {
            const region = seldValueExistsInOptions(order?.region);
            setOrder({ ...order, region: region?.data?.code, regionDisplayName: region?.data?.name });
        }
    }, [options]);

    React.useEffect(() => {
        if (!order?.regionDisplayName && order?.isTouched) setIsTouched(true);
    }, [order?.regionDisplayName, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="regionDisplayName"
                label="Region *"
                value={order?.regionDisplayName || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={regionApi.isLoading}
                isLoading={regionApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Region);
