import React from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { RIStyles } from 'pages/reference-implementation/Style';
import HelpImg from 'pages/provisioning/_components/helpImg';

const LdapGroup = (props) => {
    const { isRequired, handleOnSelect, ldapGroupvalue, errors } = props;
    return (
        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                data-testid="ldapGroupText"
                label="LDAP Group *"
                autoComplete="off"
                error={isRequired || errors?.ldap?.showError}
                helperText={errors?.ldap?.showError && <span style={{ color: '#FF3939' }}>{errors?.ldap?.errorMessage}</span>}
                value={ldapGroupvalue || ''}
                onChange={(event) => handleOnSelect(event.target.value)}
                className={errors?.ldap?.showError || isRequired ? 'validate' : ''}
            />
            <HelpImg maxmWidth="450px" title="LDAP group must be atleast 3 chars in length and can contain only hyphen(-) & underscore(_) as special char" />
        </Stack>
    );
};

LdapGroup.propTypes = {
    // resource: PropTypes.any,
    handleOnSelect: PropTypes.func,
    isRequired: PropTypes.bool,
    ldapGroupvalue: PropTypes.any,
    // handleShowError: PropTypes?.func,
    errors: PropTypes.any,
};

LdapGroup.defaultProps = {
    // resource: null,
    handleOnSelect: () => {},
    isRequired: false,
    ldapGroupvalue: null,
    // handleShowError: () => {},
    errors: {},
};

export default LdapGroup;
