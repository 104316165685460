import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Styles from 'pages/provisioning/_components/style';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import InfoImg from 'pages/provisioning/_components/InfoImg';

const StickyHeader = (props) => {
    const { title, imgSrc, showCartBtn, cartBtn, back, handleBackBtn, width, showInfoIcon, infoTitle } = props;
    return (
        <Box sx={Styles?.StickHeader?.wrapper} data-testid="sticky-header">
            <Box sx={Styles?.StickHeader?.subWrapper}>
                <Box style={Styles?.StickHeader?.ImgSpan}>
                    {back && (
                        <IconButton onClick={handleBackBtn} sx={Styles?.backBtn}>
                            <ArrowBackIosNewIcon sx={Styles?.backIcon} />
                        </IconButton>
                    )}
                    {imgSrc && <img src={imgSrc} alt="" height="33.43px" width={width} />}
                </Box>
                {showInfoIcon ? (
                    <Box sx={Styles?.StickHeader?.showInfoIcon} gap={1}>
                        <Box sx={Styles?.StickHeader?.title}>{title}</Box>
                        <InfoImg
                            title={
                                <>
                                    <a href="/access-management-adding-members" target="_blank" rel="noreferrer">
                                        {infoTitle}
                                    </a>
                                </>
                            }
                        />
                    </Box>
                ) : (
                    <Box sx={Styles?.StickHeader?.title}>{title}</Box>
                )}
            </Box>

            {showCartBtn && <Box sx={Styles?.StickHeader?.buttonWrapper}>{cartBtn}</Box>}
        </Box>
    );
};

StickyHeader.propTypes = {
    title: PropTypes.string,
    imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.any]),
    showCartBtn: PropTypes.any,
    showInfoIcon: PropTypes.any,
    cartBtn: PropTypes.any,
    handleBackBtn: PropTypes.func,
    back: PropTypes.bool,
    width: PropTypes.string,
    infoTitle: PropTypes.string,
};

StickyHeader.defaultProps = {
    title: 'Page Title',
    imgSrc: '',
    showCartBtn: false,
    showInfoIcon: false,
    cartBtn: null,
    back: false,
    width: '33.43px',
    infoTitle: '',
    handleBackBtn: () => {},
};

export default React.memo(StickyHeader);
