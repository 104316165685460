import React, { useState } from 'react';
import Chatbot, { createChatBotMessage, createClientMessage } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import './style.css';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { endPoints } from 'chatbot/components/api/endpoints';
import { Button } from '@mui/material';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { getLoggedUserGPID } from 'utils';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import { openChatbot, isResponseLoading } from './store';
import chatBotStyle from './style';

const ChatbotComponent = () => {
    const [isOpenChat, setIsOpenChat] = useRecoilState(openChatbot);
    const [responseLoading] = useRecoilState(isResponseLoading);
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const gpid = getLoggedUserGPID();
    const accessToken = localStorage.getItem('accessToken');

    React.useEffect(async () => {
        try {
            if (accessToken) {
                const responseData = await axios.get(`${endPoints.history}/${gpid}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (responseData?.data) {
                    await setIsLoading(true);

                    const messageHistoryData = responseData?.data?.map((item) =>
                        item?.role === 'ai'
                            ? /* eslint-disable */
                              createChatBotMessage(<div dangerouslySetInnerHTML={{ __html: item.content }} />, {
                                  withAvatar: true,
                              })
                            : createClientMessage(item.content)
                    );
                    setChatHistory(messageHistoryData);
                    await setIsLoading(false);
                }
            }
        } catch (error) {
            /* eslint-disable */
            console.log(error?.message);
        }
    }, []);

    const validateInput = (input) => {
        if (input?.length === 0 || responseLoading) return false;
        return true;
    };

    return (
        <div display="flex">
            <Button sx={chatBotStyle.chatBotButton} variant="contained" onClick={() => setIsOpenChat(!isOpenChat)}>
                <ForumOutlinedIcon sx={chatBotStyle.chatIcon} />
            </Button>

            <div
                style={{
                    ...chatBotStyle.chatBotWindow,
                    visibility: isOpenChat ? 'visible' : 'hidden',
                    opacity: isOpenChat ? 1 : 0,
                }}
            >
                {isLoading && <div>Loading...</div>}
                {!isLoading && (
                    <Chatbot
                        config={config(chatHistory, gpid, accessToken)}
                        messageParser={MessageParser}
                        actionProvider={ActionProvider}
                        validator={validateInput}
                        placeholderText="Please type your request"
                    />
                )}
            </div>
        </div>
    );
};

export default ChatbotComponent;
