import React from 'react';
import axios from 'api/request';

const AxiosLoader = () => {
    const [counter, setCounter] = React.useState(0);

    React.useEffect(() => {
        const inc = (mod) => setCounter((c) => c + mod);

        // eslint-disable-next-line no-sequences
        const handleRequest = (config) => (inc(1), config);
        // eslint-disable-next-line no-sequences
        const handleResponse = (response) => (inc(-1), response);
        // eslint-disable-next-line no-sequences
        const handleError = (error) => (inc(-1), Promise.reject(error));

        // add request interceptors
        const reqInterceptor = axios.interceptors.request.use(handleRequest, handleError);
        // add response interceptors
        const resInterceptor = axios.interceptors.response.use(handleResponse, handleError);
        return () => {
            // remove all intercepts when done
            axios.interceptors.request.eject(reqInterceptor);
            axios.interceptors.response.eject(resInterceptor);
        };
    }, []);

    return counter > 0;
};

export default AxiosLoader;
