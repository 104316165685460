/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';

const BuildTools = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const buildToolApi = useMutation(() => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.buildToolsApi}`));
    const setbuildTool = (buildTool, buildToolDetails) => {
        setOrder({
            ...order,
            buildTool,
            buildToolDetails,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setbuildTool(selected.value, selected);

    React.useEffect(() => {
        if (buildToolApi?.isSuccess) {
            setOptions(buildToolApi?.data?.data?.buildTools?.map((app) => ({ label: app?.name, value: app?.name, data: app })));
        }
    }, [buildToolApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.buildTool) {
            setOrder({ ...order, buildTool: options?.[0].value, buildToolDetails: options?.[0] });
        }
        if (options && options?.length && order?.buildTool) {
            const buildtools = isExistsInOptions(order?.buildTool);
            setOrder({ ...order, buildTool: buildtools?.value, buildToolDetails: options?.[0] });
        }
    }, [options]);

    React.useEffect(() => {
        if (order?.progLanguage) {
            resetOptions();
            buildToolApi.mutate();
        }
    }, [order?.progLanguage]);

    React.useEffect(() => {
        if (!order?.buildTool && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.buildTool, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="buildTools"
                label="Build Tools *"
                value={order?.buildTool || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={buildToolApi.isLoading}
                isLoading={buildToolApi.isLoading}
                isRequired={isTouched}
                data-testid="buildTool-auto"
            />
        </Box>
    );
};

export default React.memo(BuildTools);
