export const getServiceAccountCreationPayload = ({ order, isSQLServer }) => {
    const payload = {
        applicationName: order?.applicationName,
        accountOwnerId: order?.appOwnerDetails?.gpid || order?.accountOwnerId,
        environment: order?.environment,
        sector: order?.sector,
        applicationService: order?.applicationServiceCI,
        accountType: order?.accountType,
        applicationShortName: order?.appShortName,
    };

    if (isSQLServer) payload.sqlServerName = order?.sqlServerDetails?.gpid || order?.sqlServerName;

    return payload;
};

export const getServiceAccountResubmitPayload = ({ order }) => {
    const payload = {
        applicationName: order?.applicationName,
        accountOwnerId: order?.appOwnerDetails?.gpid || order?.accountOwnerId,
        environment: order?.environment,
        sector: order?.sector,
        applicationService: order?.applicationServiceCI,
        accountType: order?.accountType,
        applicationShortName: order?.appShortName,
    };
    return payload;
};

export const formOrderObjFromOrderDetails = (data = null) => {
    if (data?.catalogServices?.[0]?.catalogConfig) {
        const response = data?.catalogServices?.[0]?.catalogConfig;

        return {
            accountType: response?.accountType,
            applicationName: response?.applicationName,
            accountOwnerId: response?.accountOwnerId,
            sector: response?.sector,
            environment: response?.environment,
            appOwner: null,
            appOwnerDetails: { name: response?.accountOwner, gpid: response?.accountOwnerId },
            sqlServerName: response?.sqlServerName,
            applicationServiceCI: response?.applicationService,
            appShortName: response?.applicationShortName,
            isTouched: false,
        };
    }
    return null;
};
