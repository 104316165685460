/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { islandingZoneDescVaild, landingZoneData } from 'pages/app-profiles/store';
import LandingZoneStyle from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';

const LandingZoneDesc = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [isValid, setDescVaild] = useRecoilState(islandingZoneDescVaild);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setDesc = (landingZoneDesc = null) => {
        setLandingZoneForm({
            ...landingZoneForm,
            landingZoneDesc,
        });
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 100 || value?.length < 3) {
                setDescVaild(false);
                setDesc(value);
            } else {
                setDescVaild(true);
                setDesc(value);
            }
        } else {
            setDescVaild(false);
            setDesc();
        }
    };

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landingZoneForm?.landingZoneDesc?.length > 0);
    }, [landingZoneForm?.landingZoneDesc]);

    const validateInput = () => {
        if ((isTouched && landingZoneForm?.isTouched && !landingZoneForm?.landingZoneDesc) || !isValid) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!landingZoneForm?.landingZoneDesc && landingZoneForm?.isTouched) {
            setIsTouched(true);
        }
    }, [landingZoneForm?.landingZoneDesc, landingZoneForm?.isTouched]);

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Description*"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landingZoneForm?.landingZoneDesc || ''}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'Landing zone desc should be min. 3 to max. 100 chars' : ''}
                `}
                    className={validateInput()}
                />
                <Box sx={LandingZoneStyle.helptext}>
                    <InfoImg title="Landing zone description" />
                </Box>
            </Stack>
        </>
    );
};

export default React.memo(LandingZoneDesc);
