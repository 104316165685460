/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useParams } from 'react-router-dom';
import Select from 'components/select';
import { aksCatalogServiceCode, kafkaCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';

const Region = () => {
    const params = useParams();
    const { operation } = params;
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    let envEndpoint;
    switch (landingZoneForm?.service) {
        case aksCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipRegions}/AKS/${landingZoneForm?.department}/${landingZoneForm?.environment}`;
            break;
        case kafkaCatalogServiceCode:
            envEndpoint = `${endPoints.landingZones.eipRegions}/Kafka/${landingZoneForm?.department}/${landingZoneForm?.environment}`;
            break;
        default:
            envEndpoint = endPoints.landingZones.regions;
    }
    const regionApi = useMutation(() => axios.get(envEndpoint));
    const setRegion = (region, regionDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            region,
            regionDetails,
            geoRecoveryRegion: null,
            geoRecoveryRegionDetails: null,
            resGroup: null,
            resGroupDetails: null,
            vNet: null,
            vNetDetails: null,
            subNet: null,
            subNetDetails: null,
            nwSecGrp: null,
            nwSecGrpDetails: null,
            appSecGrp: null,
            appSecGrpDetails: null,
            availSet: null,
            availSetDetails: null,
            availZoneDetails: [],
            vnetSubnet: [{ networkSubscription: null, networkSubscriptionDetails: null, vNet: null, vNetDetails: null, subNet: null, subNetDetails: null }],
            components:
                landingZoneForm?.components?.map((item) => ({
                    componentId: item?.componentId,
                    landingZone: null,
                    landingZoneDetails: null,
                    provider: item?.provider,
                    service: item?.service,
                })) || [],
            connections: [],
        });
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setRegion(selected?.label, selected);
        }
    };
    const isDisable = () => {
        if (operation === 'edit') {
            return ![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service);
        }
        return false;
    };
    React.useEffect(() => {
        if (regionApi?.isSuccess) {
            if ([aksCatalogServiceCode].includes(landingZoneForm?.service)) {
                setOptions(regionApi?.data?.data?.map((app) => ({ label: app.name, value: app.region, data: app })));
            } else if ([kafkaCatalogServiceCode].includes(landingZoneForm?.service)) {
                setOptions(regionApi?.data?.data?.filter((app) => app?.primary === true).map((app) => ({ label: app.name, value: app.region, data: app })));
            } else {
                setOptions(regionApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.name })));
            }
        }
    }, [regionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !landingZoneForm?.region) {
            setLandingZoneForm({ ...landingZoneForm, region: options?.[0].label, regionDetails: options?.[0] });
        }
        if (options && options?.length && landingZoneForm?.region) {
            const region = seldValueExistsInOptions(landingZoneForm?.region);
            setLandingZoneForm({ ...landingZoneForm, region: region?.label, regionDetails: region });
        }
    }, [options]);

    React.useEffect(() => {
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service) && landingZoneForm?.environment) {
            regionApi.mutate();
        }
        if ([aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service) && !landingZoneForm?.environment) {
            setOptions([]);
        }
    }, [landingZoneForm?.environment]);

    React.useEffect(() => {
        if (![aksCatalogServiceCode, kafkaCatalogServiceCode].includes(landingZoneForm?.service)) regionApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.region && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.region, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="regionName"
                label={[kafkaCatalogServiceCode].includes(landingZoneForm?.service) ? 'Primary Region*' : 'Region Name*'}
                value={landingZoneForm?.region || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={regionApi.isLoading || isDisable()}
                isLoading={regionApi.isLoading}
                isRequired={isTouched}
                disableClearable={false}
            />
        </Box>
    );
};

export default React.memo(Region);
