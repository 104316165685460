import { atom } from 'recoil';

export const sspSubmitOrder = atom({
    key: 'inventory-sspOrder',
    default: {
        catalogType: null,
        region: null,
        sector: null,
        environment: null,
    },
});
export const inventoryTypeState = atom({
    key: 'inventoryType',
    default: {
        label: '',
        value: '',
        data: {
            displayName: '',
            searchColumns: [],
            displayColumns: [],
            defaultSortColumn: '',
            type: '',
        },
        key: '',
        pageNumber: 1,
        pageSize: 10,
        sortType: 'desc',
    },
});

export const inventorySearchTextState = atom({
    key: 'inventorySearchTextState',
    default: '',
});

export const selectedIdsState = atom({
    key: 'inventorySelectedIds',
    default: [],
});

export const inventoryListsState = atom({
    key: 'inventoryLists',
    default: [],
});

export const totalCountState = atom({
    key: 'inventoryTotalCount',
    default: null,
});

export const toggleDetailModal = atom({
    key: 'inventoryDetailModal',
    default: {
        isDetailOpen: false,
        inventoryDetails: null,
    },
});
export const dateRangeValue = atom({
    key: 'inventoryDateRangeValue',
    default: { startDate: null, endDate: null },
});
export default {};
