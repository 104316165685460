/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const DbVersion = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);

    const getDbVersions = useMutation(() => axios.get(endPoints.ssp.couchbase.dbVersions));
    const setDbVersion = (dbVersion, dbVersionDetails) => {
        setOrder({
            ...order,
            dbVersion,
            dbVersionDetails,
        });
    };

    const seldValueExistsInOptions = (dbVersionValue) => options.find((option) => dbVersionValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setDbVersion(selected?.value, selected?.data);
        }
    };

    React.useEffect(() => {
        if (getDbVersions?.isSuccess) {
            setOptions(getDbVersions?.data?.data?.dbVersions?.map((app) => ({ label: app.dbVersion, value: app.dbVersion, data: app })));
        }
    }, [getDbVersions?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.dbVersion) {
            setOrder({ ...order, dbVersion: options?.[0]?.value });
        }
        if (options && options?.length && order?.dbVersion) {
            const dbVersion = seldValueExistsInOptions(order?.dbVersion);
            if (dbVersion) setOrder({ ...order, dbVersion: dbVersion?.value });
        }
    }, [options]);

    React.useEffect(() => {
        getDbVersions.mutate();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (!order?.dbVersion && order?.isTouched) setIsTouched(true);
    }, [order?.dbVersion, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="DbVersion"
                label="Couchbase DB Version *"
                value={order?.dbVersion || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={getDbVersions.isLoading}
                isLoading={getDbVersions.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(DbVersion);
