/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';
import stubFalse from 'lodash/stubFalse';

const Domain = ({ indexVal }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const setDomain = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };
    const resetOptions = () => {
        setOptions([]);
    };
    const domainApi = useMutation(() => axios.get(`${endPoints.ssp.vm.adDomain}/linux`));
    const seldValueExistsInOptions = (domainValue) => options.find((option) => domainValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setDomain('domain', selected?.value, indexVal);
        }
    };
    React.useEffect(() => {
        if (domainApi?.isSuccess) {
            setOptions(domainApi?.data?.data?.domains.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [domainApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length === 0 && !order?.nodes?.[indexVal]?.domain) {
            setDomain('domain', options?.[0]?.value, indexVal);
        }
        if (options?.length && options?.length > 1 && order?.nodes?.[indexVal]?.domain) {
            const domain = seldValueExistsInOptions(order?.nodes?.[indexVal]?.domain);
            if (domain) {
                setDomain('domain', domain?.value, indexVal);
            }
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        domainApi.mutate();
        // eslint-disable-next-line
    }, []);
    React.useEffect(() => {
        if (!order?.nodes?.[indexVal]?.domain && order?.isTouched) {
            setIsTouched(true);
        } else {
            setIsTouched(stubFalse);
        }
    }, [order?.nodes?.[indexVal]?.domain, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="domain"
                label="Domain *"
                value={order?.nodes?.[indexVal]?.domain || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value, 'Default OU')}
                disabled={domainApi.isLoading || [1, 2, 3].includes(indexVal)}
                isLoading={domainApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

Domain.propTypes = {
    indexVal: PropTypes.number,
};

Domain.defaultProps = {
    indexVal: 0,
};

export default React.memo(Domain);
