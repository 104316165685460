/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddReplication, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const Replication = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [lzDDReplication, setLzDDReplication] = useRecoilState(ddReplication);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const replicationApi = useMutation(() => axios.get(`${endPoints.landingZones.replication}`));

    const setReplication = (replication = null, replicationDetails = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                replication,
                replicationDetails,
            });
        }
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setReplication(selected?.label, selected?.data);
        } else {
            setReplication();
        }
    };
    React.useEffect(() => {
        if (replicationApi?.isSuccess) {
            setOptions(replicationApi?.data?.data?.map((app) => ({ label: app.displayName, value: app.displayName, data: app })));
            setLzDDReplication({ ...lzDDReplication, isLoaded: true });
        }
    }, [replicationApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const replication = options.find((value) => lzModal?.lzDetails?.config?.replication?.displayName === value?.label);
            if (replication) setLzDDReplication({ ...lzDDReplication, isLoaded: true, data: replication });
        }
    }, [options]);

    React.useEffect(() => {
        replicationApi.mutate();
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="replication"
                value={landZoneForm?.replication}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={replicationApi.isLoading}
                isLoading={replicationApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Replication"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(Replication);
