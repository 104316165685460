/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from '../../../store';

const ServiceEndPointSubNet = ({ index }) => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);

    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const subNetApi = useMutation((params) => axios.get(endPoints.landingZones.Subnet, { params: params }));

    const setEventHubSubNet = (subnet, subnetDetails = null) => {
        const form = { ...landingZoneForm };
        try {
            const result = form.vnetSubnet.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        subnet: subnet !== '' && subnet !== null && subnet !== undefined ? subnet : null,
                        subnetDetails: subnetDetails !== '' && subnetDetails !== null && subnetDetails !== undefined ? subnetDetails : null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };

    const seldValueExistsInOptions = (value) => options.find((option) => value === option?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setEventHubSubNet(selected?.label, selected);
        } else {
            setEventHubSubNet();
        }
    };

    React.useEffect(() => {
        if (subNetApi?.isSuccess) {
            setOptions(subNetApi?.data?.data?.length && subNetApi?.data?.data[0].subnets.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [subNetApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm) {
            const subnet = options.find((value) => landingZoneForm?.vnetSubnet?.[index]?.subnet === value?.label);
            if (subnet) setEventHubSubNet(subnet?.label, subnet);
        }
    }, [options]);

    React.useEffect(() => {
        if (landingZoneForm?.vnetSubnet?.[index]?.vNet && landingZoneForm?.vnetSubnet?.[index]?.networkSubscriptionDetails && !options?.length) {
            const params = {
                name: landingZoneForm?.vnetSubnet?.[index]?.vNet,
                subscriptionName: landingZoneForm?.vnetSubnet?.[index]?.networkSubscription,
            };
            subNetApi.mutate(params);
        }
    }, [landingZoneForm?.vnetSubnet]);
    React.useEffect(() => {
        if (
            landingZoneForm?.isTouched &&
            ((!landingZoneForm?.vnetSubnet?.[index]?.subnet && landingZoneForm?.vnetSubnet?.[index]?.networkSubscription) || index >= 1)
        ) {
            setIsTouched(true);
        } else {
            setIsTouched(false);
        }
    }, [landingZoneForm?.vnetSubnet, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="SubNet"
                label="SubNet"
                value={landingZoneForm?.vnetSubnet?.[index]?.subnet || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subNetApi?.isLoading || landingZoneForm?.dataClassification === 'Restricted'}
                isLoading={subNetApi?.isLoading}
                disableClearable={false}
                isRequired={isTouched}
            />
        </Box>
    );
};

ServiceEndPointSubNet.propTypes = {
    index: PropTypes.number,
};

ServiceEndPointSubNet.defaultProps = {
    index: 0,
};

export default React.memo(ServiceEndPointSubNet);
