/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import ProvisioningStyles from 'pages/provisioning/day2-operation/style';

const ServerName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const setServerName = (serverName, serverNameDetail) => {
        setOrder({ ...order, serverName, serverNameDetail });
    };
    const serverNameApi = useMutation(() => axios.get(`${endPoints.ssp.day2Operation.serverNames}`));
    const seldValueExistsInOptions = (serverNameValue) => options.find((option) => serverNameValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setServerName(selected?.value, selected?.data);
        }
    };
    React.useEffect(() => {
        if (serverNameApi?.isSuccess) {
            if (serverNameApi?.data?.data) setOptions(serverNameApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [serverNameApi?.isSuccess]);

    React.useEffect(() => {
        serverNameApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!order?.serverName && order?.isTouched) setIsTouched(true);
    }, [order?.serverName, order?.isTouched]);

    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="serverName"
                label="Server Name *"
                value={order?.serverName || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={serverNameApi.isLoading}
                isLoading={serverNameApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ServerName);
