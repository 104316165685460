import React, { useState, lazy, Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload, optionsList, SQLServer } from 'pages/service-account-creation/store';
import { getServiceAccountCreationPayload } from 'pages/service-account-creation/utils/getPayload';
import { orderId as cmpOrderId, orderSuccessPopup } from 'pages/reference-implementation/store';
import { ServiceAccountDefinitionId } from 'utils/catalogDefinitionId';
import { endPoints } from 'api/endpoints';
import { OrderSuccessDialog } from 'pages/service-account-creation/components/order-success';
import ProvisioningStyles from 'pages/service-account-creation/style';
import Btn from 'components/button';

const ErrorBanner = lazy(() => import('pages/service-account-creation/components/error-banner'));

export const SubmitRequest = () => {
    const { cartItemId, operation } = useParams();
    const [, setOrderId] = useRecoilState(cmpOrderId);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isSuccessOpen, setSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [isSQLServer] = useRecoilState(SQLServer);
    const [, setOptions] = useRecoilState(optionsList);
    const [errorBannerOpen, setErrorBannerOpen] = useState(false);
    const submitOrderApi = useMutation((payload) => axios.post(endPoints?.serviceAccountCreation?.Jwt?.createOrder, payload));
    const editOrderApi = useMutation((payload) => axios.put(`${endPoints?.serviceAccountCreation?.Jwt?.editOrder}/${cartItemId}`, payload));

    const resetForm = () => {
        setOptions([]);
        setOrder({
            ...order,
            applicationName: null,
            environment: null,
            appOwner: null,
            appOwnerDetails: null,
            sqlServerName: null,
            sqlServerDetails: null,
            applicationServiceCI: null,
            appShortName: null,
            accountType: null,
            sector: null,
            isTouched: false,
        });
    };
    const handleSubmit = async () => {
        const payload = getServiceAccountCreationPayload({ order, isSQLServer });
        if (operation === 'edit') {
            editOrderApi.mutate(payload);
        } else {
            submitOrderApi.mutate(payload);
        }
    };

    const isReadyToSubmit = () => {
        if (isSQLServer) {
            return (
                !order?.applicationName ||
                !order?.environment ||
                !order?.appOwner ||
                !order?.applicationServiceCI ||
                !order?.sector ||
                !order?.accountType ||
                !order?.appShortName ||
                !order?.sqlServerName
            );
        }
        return (
            !order?.applicationName ||
            !order?.environment ||
            !order?.appOwner ||
            !order?.applicationServiceCI ||
            !order?.sector ||
            !order?.accountType ||
            !order?.appShortName
        );
    };

    const handleValidation = () => {
        if (!isReadyToSubmit()) {
            handleSubmit();
        } else {
            setErrorBannerOpen(true);
            setOrder({
                ...order,
                isTouched: true,
            });
        }
    };
    const handleReset = () => {
        setSuccessPopup(false);
        resetForm();
    };

    useEffect(() => {
        if (submitOrderApi?.data?.data?.orderId) {
            setOrderId(submitOrderApi?.data?.data?.orderId);
            setSuccessPopup(true);
        } else if (editOrderApi?.isSuccess) {
            setOrderId(cartItemId);
            setSuccessPopup(true);
        } else {
            setSuccessPopup(false);
        }
    }, [submitOrderApi?.isSuccess, editOrderApi?.isSuccess]);
    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={submitOrderApi?.isLoading}
                        sx={ProvisioningStyles?.cartBtn}
                        color="cmpPrimary"
                        data-testid="add-to-cart-btn"
                    >
                        {submitOrderApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Submit Request'}
                    </Btn>
                )}
                {cartItemId && operation === 'edit' && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={submitOrderApi?.isLoading}
                        sx={ProvisioningStyles?.resubmitBtn}
                        color="cmpPrimary"
                        data-testid="add-to-cart-btn"
                    >
                        {editOrderApi?.isLoading ? <CircularProgress color="inherit" size={16} /> : 'Re-Submit Request'}
                    </Btn>
                )}
            </Box>
            <OrderSuccessDialog
                isOpen={isSuccessOpen}
                reset={handleReset}
                redirectUrl={`/service-account-${isSQLServer ? 'ci' : 'creation'}/${ServiceAccountDefinitionId[0]}`}
                type={operation}
            />
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click submit request."
                    handleCloseCallback={() => setErrorBannerOpen(false)}
                />
            )}
        </Suspense>
    );
};

SubmitRequest.propTypes = {};

SubmitRequest.defaultProps = {};

export default React.memo(SubmitRequest);
