import React from 'react';
import Modal from 'components/modal';
import { Box, Button, Divider } from '@mui/material';
import { useRecoilState, useResetRecoilState } from 'recoil';
import Select from 'components/select';
import toast from 'react-hot-toast';
import { appDefaultForm, toggleCreateAppDefault } from './store';

// eslint-disable-next-line react/prop-types
const CreateAppDefault = () => {
    const [form, setForm] = useRecoilState(appDefaultForm);
    const resetForm = useResetRecoilState(appDefaultForm);
    const [lzModal, setLZModal] = useRecoilState(toggleCreateAppDefault);
    React.useEffect(() => {
        if (lzModal?.isOpen === false) {
            resetForm();
        }
    }, [lzModal?.isOpen]);
    return (
        <Modal
            title={lzModal?.type === 'create' ? 'Create New App Defaults - Cloud Marketplace' : 'Modify App Defaults - Cloud Marketplace'}
            handleClose={() => setLZModal({ ...lzModal, isOpen: false })}
            open={lzModal?.isOpen}
        >
            <Box style={{ minWidth: 700, width: 'auto' }} display="flex" flexDirection="column" height="100%">
                <Box flexGrow={1} overflow="auto">
                    <Box px={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                Cloud Provider
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="cloudProvider"
                                    value={form?.cloudProvider}
                                    handleOnSelect={(value) => setForm({ ...form, cloudProvider: value?.value })}
                                    options={[{ label: 'Azure', value: 'Azure' }]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box px={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                Cloud Service
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="cloudService"
                                    value={form?.cloudService}
                                    handleOnSelect={(value) => setForm({ ...form, cloudService: value?.value })}
                                    options={[{ label: 'PaaS/SQL', value: 'PaaS/SQL' }]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box px={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                Catalog Service
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="catalogService"
                                    value={form?.catalogService}
                                    handleOnSelect={(value) => setForm({ ...form, catalogService: value?.value })}
                                    options={[{ label: 'AZPAASSQL', value: 'AZPAASSQL' }]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box px={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                Environment
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="environment"
                                    value={form?.environment}
                                    handleOnSelect={(value) => setForm({ ...form, environment: value?.value })}
                                    options={[{ label: 'Production', value: 'Production' }]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box px={1} pt={1} sx={{ fontSize: '18px', color: '#626262', fontWeight: '500' }}>
                        Purchase Options
                    </Box>
                    <Box px={1} pt={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                AD Groups
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="adGroups"
                                    value={form?.adGroups}
                                    handleOnSelect={(value) => setForm({ ...form, adGroups: value?.value })}
                                    options={[
                                        { label: 'AD Group 1', value: 'AD Group 1' },
                                        { label: 'AD Group 2', value: 'AD Group 2' },
                                        { label: 'AD Group 2', value: 'AD Group 2' },
                                        { label: 'AD Group 3', value: 'AD Group 3' },
                                        { label: 'AD Group 4', value: 'AD Group 4' },
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box px={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                Deployment Modal Code
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="deploymentModalCode"
                                    value={form?.deploymentModalCode}
                                    handleOnSelect={(value) => setForm({ ...form, deploymentModalCode: value?.value })}
                                    options={[
                                        { label: 'DTU', value: 'DTU' },
                                        { label: 's0', value: 's0' },
                                        { label: 's1', value: 's1' },
                                        { label: 's2', value: 's2' },
                                        { label: 's3', value: 's3' },
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box px={1}>
                        <Box fontSize={15} display="flex" className="key" mb={1}>
                            <Box display="flex" alignItems="center" width="30%" py={0.5}>
                                Storage
                            </Box>
                            <Box display="flex" alignItems="center" width={6} mx={1}>
                                :
                            </Box>
                            <Box flexGrow={1}>
                                <Select
                                    key="storage"
                                    value={form?.storage}
                                    handleOnSelect={(value) => setForm({ ...form, storage: value?.value })}
                                    options={[
                                        { label: '50 GB', value: '50 GB' },
                                        { label: '100 GB', value: '100 GB' },
                                        { label: '200 GB', value: '200 GB' },
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="footer" display="flex" alignItems="center" justifyContent="flex-end" p={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (lzModal?.type === 'create') {
                                setLZModal({ ...lzModal, isOpen: false });
                                // setConfirmModal({
                                //     ...confirmModal,
                                //     type: 'save',
                                //     isOpen: true,
                                //     message: 'App Profile Created Successfully!',
                                // });
                            }
                            if (lzModal?.type === 'edit') {
                                toast.success('Modified Data saved successfully');
                                // setShowUpdatedMsg(true);
                                setLZModal({ ...lzModal, isOpen: false });
                            }
                        }}
                    >
                        SAVE
                    </Button>
                    <Button
                        style={{ marginLeft: '10px' }}
                        variant="outlined"
                        color="primary"
                        layout="outline"
                        onClick={() => setLZModal({ ...lzModal, isOpen: false })}
                    >
                        CANCEL
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateAppDefault;
