import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const usersCount = atom({
    key: 'dashboardnewenhancement-usersCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const publishedCatalogsCount = atom({
    key: 'dashboardnewenhancement-publishedCatalogsCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const applicationCount = atom({
    key: 'dashboardnewenhancement-applicationCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const ordersSubmittedCount = atom({
    key: 'dashboardnewenhancement-ordersSubmittedCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const ordersFulfilledCount = atom({
    key: 'dashboardnewenhancement-ordersFulfilledCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const provisionedOrdersCount = atom({
    key: 'dashboardnewenhancement-provisionedOrdersCount',
    default: 0,
});

// eslint-disable-next-line import/prefer-default-export
export const orderCreatedWithCMP = atom({
    key: 'dashboardnewenhancement-orderCreatedWithCMP',
    default: [],
});

export const applicationOnBoard = atom({
    key: 'dashboardnewenhancement-applicationOnBoard',
    default: [],
});

export const orderCreationTrend = atom({
    key: 'dashboardnewenhancement-orderCreationTrend',
    default: [],
});
export const servicesCatalogsData = atom({
    key: 'dashboardnewenhancement-servicesCatalogsData',
    default: {
        loading: true,
    },
});

const dt = new Date();
dt.setMonth(dt.getMonth() - 6);
export const dateRangeValue = atom({
    key: 'dashboardnewenhancement-dateRangeValue',
    default: { startDate: dt.toString(), endDate: new Date() },
});
const appdt = new Date();
appdt.setMonth(appdt.getMonth() - 6);
export const appdateRangeValue = atom({
    key: 'dashboardnewenhancement-appdateRangeValue',
    default: { startDate: appdt.toString(), endDate: new Date() },
});
export const dashboardFilter = atom({
    key: 'dashboardnewenhancement-dashboardFilter',
    default: { provider: null, services: null, sector: null, environment: null, solutions: null, ApplicationService: null },
});

export const appdashboardFilter = atom({
    key: 'dashboardnewenhancement-applicationdashboardFilter',
    default: { sector: null },
});
