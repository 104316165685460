import React from 'react';
import { Grid, Box } from '@mui/material';
import FormCard from 'pages/mdt-onboarding/components/form-card';
import { orderPayload } from 'pages/mdt-onboarding/store';
import { showRITMForEnvironments } from 'pages/mdt-onboarding/utils';
import { useRecoilState } from 'recoil';
import Form from 'pages/mdt-onboarding/provisioning/components';

const InputForm = () => {
    const [order] = useRecoilState(orderPayload);
    return (
        <Box sx={{ flexGrow: 1, mx: (theme) => theme.spacing(6.625) }}>
            <Grid container spacing={3.125} pt={3.125} pb={1}>
                <Grid item xs={8}>
                    <Grid item xs={12}>
                        <FormCard title="Application">
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Form.ApplicationCI />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Sector />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Environment />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.ApplicationOwner />
                                </Grid>
                            </Grid>
                        </FormCard>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <FormCard title="Source Details">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Form.Source />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.SourceValue />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.SourceDirectory />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.SourceAssignmentGrp />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.SourceEmail />
                            </Grid>
                            <Grid item xs={12} sm={6} />
                            {showRITMForEnvironments.includes(order?.environment) && (
                                <Grid item xs={12} sm={6}>
                                    <Form.RITMContainer />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <Form.SourcePatternTextArea />
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={8}>
                    <FormCard title="Destination Details">
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Form.SASUrl />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.SASToken />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.TokenExpiry />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.DestinationDirectory />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.DestinationEmail />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Form.DestinationAssignmentGrp />
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InputForm;
