import React from 'react';
import { Grid, Stack } from '@mui/material';
import Form from 'pages/provisioning/virtual-service/components';
import { useRecoilState } from 'recoil';
import { selectedVSService, virtualServicesType } from 'pages/provisioning/virtual-service/store';
import { getSelectedVSServiceTypeIndex, virtualServiceTypes } from 'pages/provisioning/virtual-service/utils';
import { SubModalField } from 'pages/provisioning/virtual-service/components/project/SubModalField';
import PropTypes from 'prop-types';

const VSContainer = ({ order, handleSubDrawerAction }) => {
    const [selectedVS] = useRecoilState(selectedVSService);
    const [vsTypes] = useRecoilState(virtualServicesType);

    const selectedVSServiceTypeIndex = getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS);

    const isAllowedOriginsEmptyORInvalid = React.useMemo(() => {
        return order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.some(
            (origin) => origin?.allowedOrigin === null || origin?.allowedOrigin === '' || origin?.isInvalid === true
        );
    }, [order, selectedVS]);

    const renderContextPath = () => {
        switch (selectedVSServiceTypeIndex?.value) {
            case virtualServiceTypes?.[0]?.code:
                return (
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Form.ContextPath />
                        </Stack>
                    </Grid>
                );
            case virtualServiceTypes?.[1]?.code:
                return (
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Form.MultiContextPath />
                        </Stack>
                    </Grid>
                );
            case virtualServiceTypes?.[2]?.code:
                return (
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Form.MultiServiceNameContextPath />
                        </Stack>
                    </Grid>
                );

            default:
                return null;
        }
    };

    const isSingleVSType = [virtualServiceTypes?.[0]?.code, virtualServiceTypes?.[1]?.code].includes(
        getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value
    );

    return (
        <>
            <Grid container spacing={2} pt={2} pb={1}>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        {isSingleVSType && <Form.ServiceName />}
                        <Form.VirtualServiceName />
                        <Form.MultiHostName />
                        <Form.CustomDomainSelected />
                        {order?.aksVirtualService?.[selectedVS]?.customDomainSelected === 'Yes' && <Form.CustomDomainName />}
                    </Stack>
                </Grid>
                {isSingleVSType && (
                    <>
                        <Grid item xs={12}>
                            <Stack direction="column" spacing={2}>
                                <Form.ServicePort />
                            </Stack>
                        </Grid>
                    </>
                )}
                {renderContextPath()}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <Form.CorsPolicy />
                    </Stack>
                </Grid>
                {order?.aksVirtualService?.[selectedVS]?.corsPolicy === 'Yes' && (
                    <>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <SubModalField
                                    label="Allowed Headers"
                                    handleAction={(type) => handleSubDrawerAction(type, 'Allowed Headers')}
                                    action="Configure"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <SubModalField
                                    label="Allowed Origins"
                                    handleAction={(type) => handleSubDrawerAction(type, 'Allowed Origins')}
                                    action="Configure"
                                    isError={isAllowedOriginsEmptyORInvalid}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2}>
                                <SubModalField
                                    label="Expose Headers"
                                    handleAction={(type) => handleSubDrawerAction(type, 'Expose Headers')}
                                    action="Configure"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="column" spacing={2} sx={{ minHeight: 80 }}>
                                <Form.AllowedMethods />
                            </Stack>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

VSContainer.propTypes = {
    order: PropTypes.object.isRequired,
    handleSubDrawerAction: PropTypes.func.isRequired,
};

export default VSContainer;
