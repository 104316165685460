/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import PropTypes from 'prop-types';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddSubscription, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const NetworkSubscription = ({ index }) => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDSubscription, setLzDDSubscription] = useRecoilState(ddSubscription);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const subscriptionApi = useMutation(() => axios.get(endPoints.landingZones.subscription));

    const setSubScription = (networkSubscription, networkSubscriptionDetails) => {
        const form = { ...landZoneForm };
        try {
            const result = form.vnetSubnet.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        networkSubscription: networkSubscription != null ? networkSubscription : null,
                        networkSubscriptionDetails: networkSubscriptionDetails != null ? networkSubscriptionDetails : null,
                        vNet: null,
                        vNetDetails: null,
                        subnet: null,
                        subnetDetails: null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };
    const setNetwotkSubscription = (networkSubscription, networkSubscriptionDetails) => {
        const form = { ...landZoneForm };
        try {
            const result = form.vnetSubnet.map((item, i) => {
                let obj = { ...item };
                if (index === i) {
                    obj = {
                        ...obj,
                        networkSubscription: networkSubscription != null ? networkSubscription : null,
                        networkSubscriptionDetails: networkSubscriptionDetails != null ? networkSubscriptionDetails : null,
                    };
                }
                return obj;
            });
            form.vnetSubnet = result;
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
        setLandingZoneForm(form);
    };
    const seldValueExistsInOptions = (subValue) => options.find((value) => subValue === value?.label);

    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setSubScription(selected?.label, selected);
        } else {
            setSubScription(null, null);
        }
    };

    React.useEffect(() => {
        if (subscriptionApi?.isSuccess) {
            setOptions(subscriptionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.subscriptionId, id: app?.subscriptionId })));
            setLzDDSubscription({ ...lzDDSubscription, isLoaded: true });
        }
    }, [subscriptionApi?.isSuccess]);

    React.useEffect(() => {
        if (
            options?.length > 0 &&
            lzModal?.lzDetails &&
            !lzModal?.lzDetails?.config?.vnetSubnet?.[index]?.networkSubscriptionDetails &&
            lzModal?.lzDetails?.config?.vnetSubnet?.[index]?.networkSubscription
        ) {
            const region = options.find((value) => lzModal?.lzDetails?.config?.vnetSubnet?.[index]?.networkSubscription === value?.label);
            if (region) setNetwotkSubscription(region?.label, region);
        }
    }, [options]);

    React.useEffect(() => {
        subscriptionApi.mutate();
    }, []);

    return (
        <Box bgcolor="white">
            <Select
                key="Network Subscription"
                value={landZoneForm?.vnetSubnet?.[index]?.networkSubscription}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={subscriptionApi.isLoading}
                isLoading={subscriptionApi.isLoading}
                disableClearable={false}
                renderInput={(params) => (
                    <CssTextField
                        size="large"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Network Subscription"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        fullWidth
                    />
                )}
            />
        </Box>
    );
};
NetworkSubscription.propTypes = {
    index: PropTypes.number,
};

NetworkSubscription.defaultProps = {
    index: 0,
};

export default React.memo(NetworkSubscription);
