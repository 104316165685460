/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, Tooltip } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/error-fallback';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { getEnv } from 'utils';
import dashboardStyle from 'pages/dashboard/style';
import Chart from './components/Chart';
import { applicationOnBoard } from './store';
import { applicationQuery } from './utils';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload?.value?.replace(/-/g, "'")}
        </text>
    </g>
);
CustomizedAxisTick.defaultProps = {
    x: 1,
    y: 2,
    payload: { value: 'Test' },
};
CustomizedAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
};
const ApplicationOnBoarded = () => {
    const [data, setData] = useRecoilState(applicationOnBoard);
    const query = useMutation((payload) => axios.post(`${endPoints.ssp.dashboard.es}/${getEnv('REACT_APP_ES_INDEX_APP_PROFILE')}`, payload));

    React.useEffect(() => {
        query.mutate(applicationQuery);
    }, []);

    React.useEffect(() => {
        if (query?.isSuccess) {
            setData(
                query?.data?.data?.aggregations?.orders_by_time?.buckets?.map((item) => ({
                    name: DateTime.fromISO(item?.key_as_string, { zone: 'utc' }).toFormat('LLL-yy'),
                    value: item?.doc_count,
                })) || []
            );
        }
    }, [query?.isSuccess]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload?.length) {
            return (
                <Stack direction="row" sx={dashboardStyle.provisionedServices.cutomtooltipWrapper}>
                    <Box sx={{ ...dashboardStyle.provisionedServices.cutomtooltip, background: payload[0].payload.fill }} />
                    <Typography sx={dashboardStyle.provisionedServices.typographyStyle}>
                        Application(s):<b>{payload[0]?.payload?.value}</b>
                    </Typography>
                </Stack>
            );
        }
        return null;
    };
    return (
        <Chart title="Applications OnBoarded">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                {(query.isLoading || data?.length <= 0) && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.circularProgress_wrapper}>
                        <CircularProgress sx={dashboardStyle.ApplicationOnBoardStyle.circularProg} size={16} />
                    </Box>
                )}
                {!query.isLoading && data?.length > 0 && (
                    <Box sx={dashboardStyle.ApplicationOnBoardStyle.chart_wrapper}>
                        <ResponsiveContainer width="90%" height={280}>
                            <BarChart data={data}>
                                <XAxis interval={0} dataKey="name" height={60} tickCount={10} tick={<CustomizedAxisTick />} />
                                <YAxis domain={[0, 'dataMax + 2']} />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar type="monotone" dataKey="value" barSize={15} fill="#00A0DD" label={{ position: 'top' }} />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                )}
            </ErrorBoundary>
        </Chart>
    );
};
export default React.memo(ApplicationOnBoarded);
