import React from 'react';
import PropTypes from 'prop-types';
import Empty from 'components/empty';
import { Box, Divider, Grid, Paper, Typography, CircularProgress } from '@mui/material';
import LabelValue from 'components/label-value';
import Styles from 'pages/provisioning/_components/style';

const EnvironmentDetails = ({ title, envs, children, isLoading, connections }) => (
    <Grid item xs={12}>
        <Paper variant="outlined" sx={Styles.envDetails.wrapper}>
            <Box sx={Styles.envDetails.titleWrapper}>
                <Typography variant="h6" sx={Styles.envDetails.typograpy}>
                    {title}
                </Typography>
                {children && <>{children}</>}
            </Box>
            <Divider sx={{ color: '#4D5970' }} variant="middle" />
            <Box sx={Styles.envDetails.labelBOx}>
                {!envs && <Empty />}
                {isLoading && (
                    <Box sx={Styles.envDetails.loadingBox}>
                        <CircularProgress data-testid="progress-icon" color="inherit" size={16} sx={Styles.envDetails.crlProg} />{' '}
                    </Box>
                )}
                {envs && !isLoading && !connections && (
                    <Grid container spacing={2} className="env-details">
                        {Object.keys(envs).map((key, labelidx) => (
                            <Grid item className="" key={`details${labelidx}`} xs={12} sx={Styles.envDetails.labelGrid}>
                                <LabelValue width={190} label={key === 'Availability_Zones' ? 'Allowed_Availability_Zones' : key} value={envs[key]} />
                            </Grid>
                        ))}
                        {Object.keys(envs).length % 2 !== 0 && (
                            <>
                                <Grid item className="" xs={12}>
                                    &nbsp;
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
                {envs && !isLoading && connections && (
                    <Grid container className="env-details">
                        {Object.keys(envs).map((key, idx) => (
                            <Grid item className="" key={`envs${idx}`} xs={12} sx={Styles.envDetails.labelGrid}>
                                {key !== 'connections' && <LabelValue width={190} label={key} value={envs[key]} />}
                                {key === 'connections' && (
                                    <Grid item className="" xs={12} sx={Styles.envDetails.labelGrid}>
                                        {envs?.connections?.map((connection, index) => (
                                            <Grid container className="env-details" data-testid="connectionsGrid" key={`Connection ${index}`}>
                                                <Grid
                                                    item
                                                    className=""
                                                    xs={12}
                                                    sx={{ fontSize: '16px', fontFamily: 'Stolzl', color: '#0047BA', fontWeight: 'medium', marginLeft: 1 }}
                                                >
                                                    {`Connection ${index + 1}`}:
                                                </Grid>
                                                <Grid item className="connection" xs={12} sx={Styles.envDetails.labelGrid}>
                                                    <LabelValue width={190} label="Source Resource" value={connection?.sourceComponent} />
                                                    <LabelValue width={190} label="Destination Resource" value={connection?.targetComponent} />
                                                    <LabelValue width={190} label="Connection Type" value={connection?.connectionType} />
                                                    {connection?.subNetName && <LabelValue width={190} label="Subnet Name" value={connection?.subNetName} />}
                                                    {connection?.virtualNetwork && (
                                                        <LabelValue width={190} label="Virtual Network" value={connection?.virtualNetwork} />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Paper>
    </Grid>
);

EnvironmentDetails.propTypes = {
    title: PropTypes.string,
    envs: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isLoading: PropTypes.bool,
    connections: PropTypes.bool,
};

EnvironmentDetails.defaultProps = {
    title: 'Environment Details',
    children: undefined,
    envs: undefined,
    isLoading: false,
    connections: false,
};

export default React.memo(EnvironmentDetails);
