// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Modal from 'components/modal';
import { sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/event-hub';
import { addConsumerGroup, removeConsumerGroup, updateConsumerGroup } from 'pages/provisioning/store/event-hub/selecters';
import ProvisioningStyles from 'pages/provisioning/event-hub/style';
import PropTypes from 'prop-types';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import HelpImg from 'pages/provisioning/_components/helpImg';

const ConsumerGroupModal = ({ indexVal }) => {
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [isOpen, setIsOpen] = useRecoilState(toggleSspModels);
    const addGroup = useSetRecoilState(addConsumerGroup);
    const removeGroup = useSetRecoilState(removeConsumerGroup);
    const updateGroup = useSetRecoilState(updateConsumerGroup);
    const [lastModifiedCG, setLastModifiedCG] = React.useState(undefined);

    const onClickAdd = (count) => {
        addGroup({ count, indexVal });
    };
    const onClickDelete = (index) => {
        removeGroup({ index, indexVal });
    };
    const changeSelection = (inputValue, index, key) => {
        updateGroup({ inputValue, index, indexVal, key });
    };

    const checkValidConsumerGroup = (consumerGroupValue) => {
        if (
            consumerGroupValue &&
            (consumerGroupValue !== '' || consumerGroupValue !== null) &&
            !/^(?![-])(?!.*--)[a-z0-9-]{6,209}(?<!-)$/.test(consumerGroupValue)
        ) {
            return true;
        }
        return false;
    };

    const checkConsumerGroupDuplicates = (value) => {
        const consumerGroupValues = order?.eventHubDetails?.[indexVal]?.consumerGroupList?.map((group) => group?.value);
        const duplicateConsumerGroups = consumerGroupValues?.filter((item, index) => consumerGroupValues?.indexOf(item) !== index);
        if (value) {
            return duplicateConsumerGroups?.indexOf(value) > -1;
        }
        return false;
    };

    const isFormValid = () => {
        let errorCount = 0;
        let isConsumerGroupValid = false;
        if (order?.eventHubDetails?.[indexVal]?.consumerGroupList?.length) {
            order?.eventHubDetails?.[indexVal]?.consumerGroupList?.forEach((group) => {
                if (!(group.value.length > 0 && /^(?![-])(?!.*--)[a-z0-9-]{6,209}(?<!-)$/.test(group.value))) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isConsumerGroupValid = errorCount === 0;
        return isConsumerGroupValid;
    };

    function ifDuplicateValue() {
        const arr = order?.eventHubDetails?.[indexVal]?.consumerGroupList?.map((group) => group?.value);
        // eslint-disable-next-line
        for (var i = 0; i < arr?.length - 1; i++) {
            // eslint-disable-next-line
            for (var j = i + 1; j < arr?.length; j++) {
                if (arr[i] === arr[j]) {
                    return true;
                }
            }
        }

        return false;
    }

    React.useEffect(() => {
        if (isOpen?.isConsumerGroupOpen) {
            setLastModifiedCG(order?.eventHubDetails?.[indexVal]?.consumerGroupList);
        }
    }, [isOpen?.isConsumerGroupOpen]);

    return (
        <Modal
            title="Consumer Groups"
            handleClose={() => {
                setIsOpen({ ...isOpen, isConsumerGroupOpen: false });
                const eventHubUpdatedConsumerList = { ...order?.eventHubDetails?.[indexVal], consumerGroupList: lastModifiedCG };
                const updatedEventHubDetails = [
                    ...order?.eventHubDetails?.slice(0, indexVal),
                    eventHubUpdatedConsumerList,
                    ...order?.eventHubDetails?.slice(indexVal + 1),
                ];
                setOrder({
                    ...order,
                    eventHubDetails: updatedEventHubDetails,
                });
            }}
            open={isOpen.isConsumerGroupOpen}
        >
            <Box sx={ProvisioningStyles.consumerGroupModal.wrapper}>
                <Stack direction="row" spacing={1}>
                    <Typography variant="h6">Add Consumer Groups</Typography>
                    <HelpImg
                        maxmWidth="450px"
                        title={
                            <>
                                <Box sx={ProvisioningStyles.helpImgStyle.wrapper}>
                                    <Box sx={ProvisioningStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={ProvisioningStyles.helpImgStyle.namingRule}>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    <span style={{ fontWeight: 600 }}>Naming Convention Rules:</span>
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>1) Lowercase Alpha-numeric value</Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    2) Min.: 6 chars to Max.: 209 chars
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    3) Can contain only hyphen(-) as special char
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    4) Should not start or end with any special char
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>
                                                    5) Should not contain consecutive hyphens
                                                </Typography>
                                                <Typography sx={ProvisioningStyles.helpImgStyle.typographyStyle}>6) Should be unique value</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Stack>
                <Box sx={ProvisioningStyles.consumerGroupModal.contentWrapper}>
                    {order?.eventHubDetails?.[indexVal]?.consumerGroupList?.map((groupData, index) => (
                        <Box key={index.toString()} sx={ProvisioningStyles.consumerGroupModal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={ProvisioningStyles.consumerGroupModal.consumerGrpWrapper}>
                                <>
                                    <CssTextField
                                        id="outlined-size-small"
                                        size="small"
                                        sx={{
                                            width: (theme) => theme.spacing('100%'),
                                            '& .MuiFormHelperText-root': {
                                                marginTop: (theme) => theme.spacing('-2'),
                                            },
                                        }}
                                        label="Group Name *"
                                        variant="outlined"
                                        fullWidth
                                        autoComplete="off"
                                        error={checkValidConsumerGroup(groupData?.value)}
                                        onChange={(e) => {
                                            changeSelection(e.target.value, index, 'value');
                                        }}
                                        value={groupData?.value ?? ''}
                                        className={
                                            checkValidConsumerGroup(groupData?.value) || checkConsumerGroupDuplicates(groupData?.value) ? 'validate' : ''
                                        }
                                    />
                                    {groupData?.value && checkConsumerGroupDuplicates(groupData?.value) && (
                                        <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                            Consumer group must not have duplicate values.
                                        </Typography>
                                    )}
                                    {groupData?.value && checkValidConsumerGroup(groupData?.value) && (
                                        <>
                                            <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                                Consumer group must be min 6 characters in length, no uppercase,
                                            </Typography>
                                            <Typography variant="body2" gutterBottom sx={ProvisioningStyles.consumerGroupModal.warningTypography}>
                                                starts with alphanumeric and contains only hyphen(-) as special char.
                                            </Typography>
                                        </>
                                    )}
                                </>
                            </Box>
                            <Box sx={ProvisioningStyles.consumerGroupModal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {index !== 0 && order?.eventHubDetails?.[indexVal]?.consumerGroupList?.length > 1 && (
                                        <Box sx={ProvisioningStyles.consumerGroupModal.deleteWrapper}>
                                            <FaTrashAlt
                                                style={ProvisioningStyles.consumerGroupModal.delete}
                                                onClick={() => onClickDelete(index)}
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.eventHubDetails?.[indexVal]?.consumerGroupList.length - 1 &&
                                    order?.eventHubDetails?.[indexVal]?.consumerGroupList?.length < 5 ? (
                                        <Box sx={ProvisioningStyles.consumerGroupModal.addWrapper}>
                                            <MdAdd
                                                style={ProvisioningStyles.consumerGroupModal.add}
                                                onClick={() => {
                                                    onClickAdd(1);
                                                }}
                                                className="w-7 h-7 text-blue-base cursor-pointer"
                                            />
                                        </Box>
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={ProvisioningStyles.consumerGroupModal.actionWrapper}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary"
                        onClick={() => {
                            setIsOpen({ ...isOpen, isConsumerGroupOpen: false });
                        }}
                        disabled={!isFormValid() || ifDuplicateValue()}
                        data-testid="save-btn"
                    >
                        SAVE
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={ProvisioningStyles.consumerGroupModal.cancelBtn}
                        onClick={() => {
                            setIsOpen({ ...isOpen, isConsumerGroupOpen: false });
                            const eventHubUpdatedConsumerList = { ...order?.eventHubDetails?.[indexVal], consumerGroupList: lastModifiedCG };
                            const updatedEventHubDetails = [
                                ...order?.eventHubDetails?.slice(0, indexVal),
                                eventHubUpdatedConsumerList,
                                ...order?.eventHubDetails?.slice(indexVal + 1),
                            ];
                            setOrder({
                                ...order,
                                eventHubDetails: updatedEventHubDetails,
                            });
                        }}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel-btn"
                    >
                        CANCEL
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

ConsumerGroupModal.propTypes = {
    indexVal: PropTypes.number,
};

ConsumerGroupModal.defaultProps = {
    indexVal: 0,
};

export default ConsumerGroupModal;
