import React from 'react';
import { Stack, Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import InfoImg from 'pages/provisioning/_components/InfoImg';
import { useTranslation } from 'react-i18next';
import { iaasVMCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';
import LandingZoneStyle from 'pages/app-profiles/landing-zones-new/LandingZoneStyle';

const filter = createFilterOptions();
const ResourceGroup = () => {
    const { t } = useTranslation();
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [ccInputValue, setCCInputValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [initOptions, setInitOptions] = React.useState([]);

    const getResourceGroupUrl = (payload) => `${endPoints.landingZones.resourceGroup}/location/${payload?.region}/subscription/${payload?.subscriptionId}`;

    const resGrpGetApi = useMutation(() =>
        axios.get(
            `${endPoints.landingZones.resourceGroup}/location/${landingZoneForm?.regionDetails?.value}/subscription/${landingZoneForm?.subscriptionDetails?.id}?name=${ccInputValue}`
        )
    );

    const resGrpPostApi = useMutation((payload) => axios.get(getResourceGroupUrl(payload)));

    const setResourceGroup = (resGroup, resGroupDetails) => {
        setLandingZoneForm({
            ...landingZoneForm,
            resGroup,
            resGroupDetails,
            ...((landingZoneForm?.landingZoneLabel === '' || landingZoneForm?.landingZoneLabel === null || landingZoneForm?.landingZoneLabel === undefined) && {
                landingZoneLabel: resGroup?.split('-')?.slice(0, 3)?.join('-'),
            }),
        });
    };

    const resetOptions = () => {
        setOptions(initOptions);
        setResourceGroup(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                if (ccInputValue !== '') resGrpGetApi.mutate();
            }, 1000),
        [ccInputValue]
    );

    const getFilteredData = (resGrpData) => {
        if (![iaasVMCatalogServiceCode].includes(landingZoneForm?.service)) {
            return resGrpData;
        }
        return resGrpData?.filter((app) => !['network', 'paas', 'isolated'].some((str) => app?.name?.toLowerCase()?.includes(str)));
    };

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.resGroup) {
            const resGrp = options.find((app) => landingZoneForm?.resGroup === app?.label);
            if (resGrp) setResourceGroup(resGrp?.label, resGrp);
        }
    }, [options]);

    React.useEffect(() => {
        if (resGrpGetApi?.isSuccess) {
            if (resGrpGetApi?.data?.data) {
                const filteredRG = getFilteredData(resGrpGetApi?.data?.data);
                const combinedValue = filteredRG?.map((app) => ({ label: `${app.name}`, value: `${app.name}` }));
                setOptions(combinedValue);
            }
        }
    }, [resGrpGetApi?.isSuccess]);

    React.useEffect(() => {
        if (resGrpPostApi?.isSuccess) {
            if (resGrpPostApi?.data?.data) {
                const filteredRG = getFilteredData(resGrpPostApi?.data?.data);
                const combinedValue = filteredRG?.map((app) => ({ label: app.name, value: app.name }));
                setOptions(combinedValue);
                setInitOptions(combinedValue);
            }
        }
    }, [resGrpPostApi?.isSuccess]);

    React.useEffect(() => {
        if (landingZoneForm.region && landingZoneForm?.subscriptionDetails) {
            resGrpPostApi.mutate({ region: landingZoneForm?.regionDetails?.value, subscriptionId: landingZoneForm?.subscriptionDetails?.id });
        }
    }, [landingZoneForm.region, landingZoneForm?.subscriptionDetails, landingZoneForm?.endPointType]);

    const handleOnSelectChange = (selected) => {
        setResourceGroup(selected?.label, selected);
    };
    React.useEffect(() => {
        if (!landingZoneForm?.resGroup && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.resGroup, landingZoneForm?.isTouched]);

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="center">
                <Select
                    noOptionsText="No options"
                    label="Resource GroupName*"
                    loading={resGrpGetApi?.isLoading || resGrpPostApi?.isLoading}
                    forcePopupIcon={false}
                    disabled={resGrpPostApi?.isLoading}
                    key="Resource GroupName"
                    value={landingZoneForm?.resGroup || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue);
                    }}
                    handleOnSelect={(value) => handleOnSelectChange(value)}
                    filterOptions={(optionValues, params) => filter(optionValues, params)}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.label;
                    }}
                    renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>{option.label}</li>
                    )}
                    sx={LandingZoneStyle.select}
                    // freeSolo
                    disableClearable
                    isRequired={isTouched}
                />
                <Box sx={LandingZoneStyle?.helptext}>
                    <InfoImg title={t([`landingZone.infoText.resourceGroup`])} />
                </Box>
            </Stack>
        </>
    );
};

export default React.memo(ResourceGroup);
