/* eslint-disable     import/prefer-default-export    */

import { atom } from 'recoil';

export const togglecatalogDetails = atom({
    key: 'togglecatalogDetails',
    default: {
        isOpen: false,
        isEdit: false,
    },
});
export const updateCatalog = atom({
    key: 'updateCatalog',
    default: false,
});
export const allCatalogsList = atom({
    key: 'allCatalogsList',
    default: [],
});
