import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'day2-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'day2-quickConfigDetails',
    default: undefined,
});
export const toggleSspModels = atom({
    key: 'day2-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSkuModalOpen: false,
        isStorageDiskModalOpen: false,
        isDataDiskModalOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
        isDataDisk: { isOpen: false, newDataDiskCount: 0, deleteCount: 0 },
    },
});

export const isEnvDetailsLoading = atom({
    key: 'day2-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'day2-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'day2-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'day2-sspOrder',
    default: {
        appProfile: null,
        entityDetails: null,
        sector: null,
        sectorDetails: null,
        region: null,
        regionDisplayName: null,
        environment: null,
        hoursOfOperation: null,
        hoursOfOperationDetail: null,
        hCode: null,
        hCodeDetails: null,
        billingEntity: null,
        billingEntityDetails: null,
        tags: [],
        estimatedCost: null,
        cloudProvider: 'Azure',
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
        // projectId: null,
        // projectNameDetail: null,
        notes: null,
        serverName: null,
        serverNameDetail: null,
        modificationType: 'SKU',
        modificationTypeDetail: null,
        currentSku: null,
        configureNewSku: null,
        configureNewSkuDetail: null,
        dataDisks: [],
        maxDataDiskCount: 5,
    },
});

export const environmentDetails = atom({
    key: 'day2-environmentDetails',
    default: {
        Subscription: '---',
        'DR Priority': '---',
        Resource_Group: '---',
        Metallic_Selection: '---',
        Backup_Tier: '---',
        Virtual_Network: '---',
        Subnet_Name: '---',
        Recovery_Vault_Name: '---',
        Approval_Manager: '---',
        Project: '---',
        Availability_Set: '---',
        Azure_Site_Recovery: '---',
    },
});

export const costEstimateState = atom({
    key: 'day2-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'day2-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
export const isValidNotes = atom({
    key: 'day2-isValidNotes',
    default: false,
});
export const isSkuConfigFullfilled = atom({
    key: 'day2-isSkuConfigFullfilled',
    default: false,
});
export const isStorageDiskConfigFullfilled = atom({
    key: 'day2-isStorageDiskConfigFullfilled',
    default: false,
});
export const isDataDiskConfigFullfilled = atom({
    key: 'day2-isDataDiskConfigFullfilled',
    default: false,
});
