/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import StickyHeader from 'pages/provisioning/_components/StickyHeader';
import { useParams } from 'react-router-dom';
import ProvisioningStyles from 'pages/service-account-creation/style';
import { SubmitRequest } from 'pages/service-account-creation/components/SubmitRequest';
import { SQLServer } from 'pages/service-account-creation/store';
import { catalogs } from 'store';
import { useRecoilState } from 'recoil';

const ProvisioningHeader = () => {
    const { catalogCode } = useParams();
    const [cmpCatalogs] = useRecoilState(catalogs);
    const [isSQLServer] = useRecoilState(SQLServer);

    // const getDisImage = (code) => {
    //     if (cmpCatalogs?.length) {
    //         const result = cmpCatalogs.filter((service) => service.catalogDefinitionId === code);
    //         return result[0]?.iconPath;
    //     }
    //     return null;
    // };

    const getProvisiongTitle = (code) => {
        if (cmpCatalogs?.length) {
            const result = cmpCatalogs.filter((service) => service.catalogDefinitionId === code);
            return result[0]?.displayName;
        }
        return null;
    };

    return (
        <Box>
            {cmpCatalogs?.length > 0 && (
                <StickyHeader
                    title={`${getProvisiongTitle(catalogCode)}${isSQLServer ? ' (SQL Server) ' : ''}`}
                    sx={ProvisioningStyles.ProvisioningHeader}
                    // imgSrc={getDisImage(catalogCode)}
                    showCartBtn
                    cartBtn={<SubmitRequest />}
                />
            )}
        </Box>
    );
};

export default React.memo(ProvisioningHeader);
