import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';

export const ApplicationName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [helperText, setHelperText] = React.useState(false);

    const setApplicationName = (applicationName = null) => {
        setOrder({
            ...order,
            applicationName,
            appShortName: null,
        });
    };

    React.useEffect(() => {
        if (
            order?.applicationName &&
            order?.applicationName !== null &&
            order?.applicationName !== '' &&
            order?.applicationName !== undefined &&
            !/^[a-zA-Z0-9_-\s\\(\\)]{4,64}$/.test(order?.applicationName)
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isAppNameValid: true });
        } else {
            setHelperText(false);
            setValidForm({ ...validForm, isAppNameValid: false });
        }
    }, [order?.applicationName]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.applicationName) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.applicationName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.applicationName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1} data-testid="application-name-text">
            <CssTextField
                data-testid="app-name-text"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                disabled={!order?.adoPlatform}
                autoComplete="off"
                label="Application Name *"
                error={helperText}
                helperText={
                    helperText && (
                        <span>Application name must have min 4 chars and can contain space, hyphen(-), underscore(_), slash(/) as special chars.</span>
                    )
                }
                value={order?.applicationName || ''}
                onChange={(e) => setApplicationName(e.target.value)}
                className={validateInput()}
                onKeyDown={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (event.target.value || event.target.value !== '')) {
                        setOrder({
                            ...order,
                            appShortName: null,
                        });
                    }
                }}
            />
        </Box>
    );
};

export default React.memo(ApplicationName);
