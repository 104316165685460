const ProvisioningStyles = {
    ProvisioningHeader: {
        pageTitle: {
            color: (theme) => theme.palette.white,
            textTransform: 'uppercase',
            fontSize: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(0.5),
        },
        header: {
            mx: (theme) => theme.spacing(6.625),
        },
        gridContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        gridItem: { display: 'flex' },
        ImgSpan: { marginRight: '4px' },
        img: { width: '90.43px', height: '93.43px' },
        catalogsApiLoadingSpan: {
            background: '#E0E0E0',
            width: 33,
            height: 33,
            marginRight: '4px',
            borderRadius: '50%',
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
    },
    successPopup: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1) },
        newOrderBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
        requsetTypography: { textAlign: 'center' },
        thankyouTypography: { textAlign: 'center' },
        backHome: {
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },
    submitOrder: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
};

export default ProvisioningStyles;
