import React from 'react';
import { useRecoilState } from 'recoil';
import MultiSelect from 'components/multiSelect';
import Popper from '@mui/material/Popper';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Badge, Button, Paper, Box, Stack, ClickAwayListener } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { cmpSectors } from 'store';
import { appdashboardFilter } from './store';
import dashboardStyle, { ColorButton } from './style';

const filterDefaultObj = {
    sector: null,
};

const ApplicationFilter = () => {
    const [sectors] = useRecoilState(cmpSectors);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [AppDashboardFilterObj, setAppDashboardFilterObj] = useRecoilState(appdashboardFilter);
    const [appfilters, setAppFilter] = React.useState(filterDefaultObj);

    const handleClick = (event) => {
        setAppFilter(AppDashboardFilterObj);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        setAppFilter(AppDashboardFilterObj);
        setAnchorEl(null);
        setOpen(false);
    };

    const id = open ? 'simple-app-popper' : undefined;

    const handleOnSelect = (name, selected) => {
        setAppFilter({ ...appfilters, [name]: selected });
    };

    const handleSubmitFilter = () => {
        const appliedFilters = {};
        if (appfilters) {
            Object.keys(appfilters)?.forEach((filter) => {
                if (appfilters[filter]) {
                    appliedFilters[filter] = appfilters[filter]?.value || appfilters[filter];
                }
            });
        }
        setAppDashboardFilterObj(appliedFilters);
        handleClick();
    };

    React.useEffect(() => {
        if (!AppDashboardFilterObj) {
            setAppFilter(filterDefaultObj);
        }
        if (AppDashboardFilterObj) {
            setAppFilter(AppDashboardFilterObj);
        }
    }, [AppDashboardFilterObj]);
    const getSelectedValue = (selectedServices, allValues) => {
        const res = selectedServices && selectedServices?.map((service) => allValues.filter((opt) => opt.label === service));
        return res?.length ? res.reduce((acc, val) => acc.concat(val), []) : [];
    };

    const sendSelectedValues = (selectedServices, filterKey) => {
        const res = selectedServices.map((service) => (service?.label ? service?.label : service));
        handleOnSelect(filterKey, res);
    };
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Button
                    sx={dashboardStyle.filterButton}
                    startIcon={
                        <Badge
                            variant="dot"
                            overlap="circular"
                            color={
                                appfilters && Object.keys(appfilters)?.filter((key) => appfilters[key] !== null && appfilters[key] !== '')?.length
                                    ? 'error'
                                    : 'default'
                            }
                        >
                            <FilterAltIcon color="#0047BA" size={36} />
                        </Badge>
                    }
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    onClick={handleClick}
                    data-testid="appfilterBtn"
                >
                    Filter
                </Button>

                <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: '999' }}>
                    <Paper elevation={5} sx={dashboardStyle.orderFilter.appfilterwrapper}>
                        <ArrowDropUpIcon sx={dashboardStyle.orderFilter.arrow} />
                        <Box sx={dashboardStyle.orderFilter.popper.container}>
                            <CloseIcon
                                fontSize="small"
                                sx={dashboardStyle.orderFilter.closeIcon}
                                onClick={() => {
                                    setAppFilter(filterDefaultObj);
                                    setAppDashboardFilterObj(filterDefaultObj);
                                    handleClick();
                                }}
                                data-testid="appcloseBtn"
                            />
                        </Box>
                        <Stack spacing={2} mb={2} pt={1}>
                            <MultiSelect
                                key="sectorName"
                                data-testid="appFilter"
                                value={getSelectedValue(appfilters?.sector, sectors)}
                                options={sectors}
                                // sx={orderStyle?.orderFilter?.seletedFieldlabel}
                                label="Select Sectors"
                                handleOnSelect={(value) => sendSelectedValues(value, 'sector')}
                                filterOptions={(options, state) =>
                                    options.filter((element) => element?.value?.toLowerCase().includes(state?.inputValue?.toLowerCase()))
                                }
                                renderTags={(val) => <Box sx={{ marginLeft: '10px' }} data-testid="catalog-service-text">{`${val?.length} Selected`}</Box>}
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                            <ColorButton disableElevation onClick={handleSubmitFilter} variant="contained" data-testid="appapplybtn">
                                Apply
                            </ColorButton>
                        </Stack>
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default ApplicationFilter;
