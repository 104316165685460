import React from 'react';
import PropTypes from 'prop-types';

const MessageParser = ({ children, actions }) => {
    const parse = (message) => {
        actions.userInputResult(message);
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse,
                    actions,
                });
            })}
        </div>
    );
};

MessageParser.propTypes = {
    children: PropTypes.any,
    actions: PropTypes.any,
};

MessageParser.defaultProps = {
    children: undefined,
    actions: undefined,
};

export default MessageParser;
