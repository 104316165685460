import { selector } from 'recoil';
import { isConfigureTagsFullfilled, sspSubmitOrder } from './index';

export const addModifyTag = selector({
    key: 'day2-paas-addModifyTag',
    get: ({ get }) => get(sspSubmitOrder).modifyTags,
    set: ({ set, get }, count) => {
        const currentDataDisks = get(sspSubmitOrder).modifyTags;
        let modifyTags = [];
        modifyTags = [
            ...currentDataDisks,
            ...Array(count.count).fill({
                name: null,
                value: null,
            }),
        ];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            modifyTags,
            modifyTagsCount: modifyTags.length,
        });
        set(isConfigureTagsFullfilled, false);
    },
});
export const updateModifyTag = selector({
    key: 'day2-paas-updateModifyTag',
    get: ({ get }) => get(sspSubmitOrder).modifyTags,
    set: ({ set, get }, dataDiskToUpdate) => {
        const currentDataDisks = get(sspSubmitOrder).modifyTags;
        const dataDisksToUpdateIndex = currentDataDisks.findIndex((val) => val === dataDiskToUpdate.index);
        const updatedDisk = { ...dataDiskToUpdate.index, [dataDiskToUpdate.key]: dataDiskToUpdate.selected };
        const modifyTags = [...currentDataDisks.slice(0, dataDisksToUpdateIndex), updatedDisk, ...currentDataDisks.slice(dataDisksToUpdateIndex + 1)];
        set(sspSubmitOrder, { ...get(sspSubmitOrder), modifyTags });
        set(isConfigureTagsFullfilled, false);
    },
});
export const updateModifyTagByIndex = selector({
    key: 'day2-paas-updateModifyTagByIndex',
    get: ({ get }) => get(sspSubmitOrder).modifyTags,
    set: ({ set, get }, params) => {
        const currentDataDisks = get(sspSubmitOrder).modifyTags;
        const updatedDisk = { ...currentDataDisks[params?.index], [params?.key]: params.selected };
        const modifyTags = [...currentDataDisks.slice(0, params?.index), updatedDisk, ...currentDataDisks.slice(params?.index + 1)];
        set(sspSubmitOrder, { ...get(sspSubmitOrder), modifyTags });
        set(isConfigureTagsFullfilled, false);
    },
});
export const removeModifyTag = selector({
    key: 'day2-paas-removeModifyTag',
    get: ({ get }) => get(sspSubmitOrder).modifyTags,
    set: ({ set, get }, dataDiskToRemove) => {
        const currentDataDisks = get(sspSubmitOrder).modifyTags;
        const dataDisksToRemoveIndex = currentDataDisks.findIndex((val) => val === dataDiskToRemove);
        const modifyTags = [...currentDataDisks.slice(0, dataDisksToRemoveIndex), ...currentDataDisks.slice(dataDisksToRemoveIndex + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            modifyTags,
            modifyTagsCount: modifyTags.length,
        });
        set(isConfigureTagsFullfilled, false);
    },
});

export const removeModifyTagIndex = selector({
    key: 'day2-paas-removeModifyTagIndex',
    get: ({ get }) => get(sspSubmitOrder).modifyTags,
    set: ({ set, get }, dataDisksToRemoveIndex) => {
        const currentDataDisks = get(sspSubmitOrder).modifyTags;
        const modifyTags = [...currentDataDisks.slice(0, dataDisksToRemoveIndex), ...currentDataDisks.slice(dataDisksToRemoveIndex + 1)];
        set(sspSubmitOrder, {
            ...get(sspSubmitOrder),
            modifyTags,
            modifyTagsCount: modifyTags.length,
        });
        set(isConfigureTagsFullfilled, false);
    },
});
export const validateModifyTag = selector({
    key: 'day2-paas-validateModifyTag',
    get: ({ get }) => get(sspSubmitOrder).modifyTags,
    set: ({ set, get }) => {
        const currentDataDisks = get(sspSubmitOrder)?.modifyTags;
        let errorCount = 0;
        if (currentDataDisks?.length) {
            let isDataDiskValid = false;
            currentDataDisks.forEach((disk) => {
                if (disk?.name && disk?.value) {
                    isDataDiskValid = true;
                } else {
                    isDataDiskValid = false;
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
            isDataDiskValid = errorCount === 0;
            // console.log(errorCount);
            set(isConfigureTagsFullfilled, isDataDiskValid);
        } else {
            set(isConfigureTagsFullfilled, true);
        }
    },
});
