import React from 'react';
import { useRecoilState } from 'recoil';
import { Grid, Stack } from '@mui/material';
import Vm from 'pages/provisioning/day2-operation/components/Form';
import FormCard from 'pages/provisioning/_components/FormCard';
import { toggleSspModels, costEstimateState, sspSubmitOrder } from 'pages/provisioning/store/day2-operation';
import CostSummary from 'pages/provisioning/_components/CostSummary';
import EnvironmentContainer from 'pages/provisioning/day2-operation/EnvironmentContainer';
import CartBtn from 'pages/provisioning/day2-operation/components/CartBtn';
import ConfigureSKU from 'pages/provisioning/day2-operation/components/ConfigureSKU';
import ConfigureDataDisk from 'pages/provisioning/day2-operation/components/ConfigureDataDisk';
import ConfigureStorageDisk from 'pages/provisioning/day2-operation/components/ConfigureStorageDisk';

const SspForm = () => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [costEstimate] = useRecoilState(costEstimateState);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };

    return (
        <Grid container spacing={3.125} pt={3.125} pb={1}>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <FormCard title="Application">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.Applications />
                                    <Vm.Sector />
                                    <Vm.Environments />
                                    <Vm.Region />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.CostCenter />
                                    {/* <Vm.Project /> */}
                                    <Vm.Incident />
                                    <Vm.Notes />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <FormCard title="Instance Details / Server Details">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.ServerName />
                                    {order?.modificationType?.toLowerCase() === 'sku' && <Vm.CurrentSku />}
                                    {order?.modificationType?.toLowerCase() !== 'sku' && <Vm.CurrentStorageDisk />}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Vm.ModificationType />
                                    {order?.modificationType?.toLowerCase() === 'sku' && <ConfigureSKU />}
                                    {order?.modificationType?.toLowerCase() === 'storage disk' && <ConfigureStorageDisk />}
                                    {order?.modificationType?.toLowerCase() === 'add disk' && <ConfigureDataDisk />}
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
                <Grid item xs={12}>
                    <EnvironmentContainer />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <CostSummary handleCostInfoBtn={handleCostInfoBtn} cartBtn={<CartBtn />} costEstimate={costEstimate} isLoading={false} />
            </Grid>
        </Grid>
    );
};

export default SspForm;
