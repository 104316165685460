/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ddAvailZone, landingZoneForm, toggleCreateLandingZone } from '../../../store';
import { CssTextField } from '../../LandingZoneStyle';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AvailabilityZone = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDAvailZone, setLzDDAvailZone] = useRecoilState(ddAvailZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const availZoneApi = useMutation(() => axios.get(endPoints.landingZones.availabilityZone));

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            setLzDDAvailZone({ ...lzDDAvailZone, isLoaded: true, data: null });
        }
    }, [options]);
    React.useEffect(() => {
        if (availZoneApi?.isSuccess) {
            if (!lzModal?.lzDetails) {
                const avZone = availZoneApi?.data?.data[0]?.zonesList?.find((app) => app?.region === landZoneForm?.regionDetails?.value);
                if (avZone)
                    setOptions(
                        avZone?.zones
                            ?.join()
                            .split(',')
                            .map((app) => ({ label: app, value: app }))
                    );
            } else {
                const avZone = availZoneApi?.data?.data[0]?.zonesList?.find((app) => app?.region === lzModal?.lzDetails?.region?.code);
                if (avZone)
                    setOptions(
                        avZone?.zones
                            ?.join()
                            .split(',')
                            .map((app) => ({ label: app, value: app }))
                    );
            }
            setLzDDAvailZone({ ...lzDDAvailZone, isLoaded: true });
        }
    }, [availZoneApi?.isSuccess]);
    React.useEffect(() => {
        // resetOptions();
        if (landZoneForm?.region && !lzModal?.lzDetails) {
            availZoneApi.mutate();
        } else if (!lzModal?.lzDetails) {
            setOptions([]);
        }
    }, [landZoneForm?.region]);
    React.useEffect(() => {
        if (lzModal?.lzDetails) {
            availZoneApi.mutate();
        }
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Autocomplete
                id="checkboxes-tags-availabilityZone"
                size="small"
                key="AvailabilityZone"
                fullWidth
                disablePortal
                disabled={availZoneApi?.isLoading}
                multiple
                value={landZoneForm?.availZoneDetails || []}
                onChange={(_event, newValue) => {
                    setLandingZoneForm({
                        ...landZoneForm,
                        availZone: [...newValue]
                            ?.map((app) => app?.label)
                            .sort((a, b) => (a?.label > b?.label && 1) || -1)
                            .join(','),
                        availZoneDetails: [...newValue].sort((a, b) => (a?.label > b?.label && 1) || -1),
                    });
                }}
                disableClearable
                options={options}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <CssTextField
                        {...params}
                        label="Availability Zone"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {availZoneApi?.isLoading && <CircularProgress color="inherit" size={16} />}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default React.memo(AvailabilityZone);
