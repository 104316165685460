import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import ProvisioningStyles from 'pages/provisioning/day2-operation-paas/style';
import Btn from 'components/button';
import { getPayload } from 'pages/provisioning/day2-operation-paas/getPayload';
import { validateObject } from 'utils/validateObject';
import { sspSubmitOrder, toggleSspModels } from 'pages/provisioning/store/day2-operation-paas';

const CartBtn = () => {
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(sspSubmitOrder);
    const [modal, setModal] = useRecoilState(toggleSspModels);
    const handleSubmit = async () => {
        setLoading(true);
        const payload = await getPayload({ order, catalogCode, catalogServiceId });
        try {
            await validateObject({ data: payload, catalogCode });
            setModal({ ...modal, isSubmitOrderSuccess: true });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            // eslint-disable-next-line
            console.log('Exception during schema validation', e);
        }
    };

    const isReadyToSubmit = () =>
        !order?.sector ||
        !order.region ||
        !order?.entityDetails ||
        !order?.envDetails ||
        !order?.tags ||
        !order?.hCodeDetails ||
        !order?.projectName ||
        !order?.modifyService;

    const handleValidation = () => {
        if (!isReadyToSubmit()) {
            handleSubmit();
        } else {
            setOrder({ ...order, isTouched: true });
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {!cartItemId && !operation && (
                    <Btn
                        size="medium"
                        onClick={() => handleValidation()}
                        variant="contained"
                        disabled={isLoading}
                        sx={ProvisioningStyles?.cartBtn}
                        color="cmpPrimary"
                    >
                        {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add To Cart'}
                    </Btn>
                )}
            </Box>
        </>
    );
};

CartBtn.propTypes = {};

CartBtn.defaultProps = {};

export default React.memo(CartBtn);
