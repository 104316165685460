import * as React from 'react';
import { bindFocus, bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import MenuItem from '@mui/material/MenuItem';
import ChevronRight from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import { useHeaderStyle as style } from 'assets/style';
import { Typography } from '@mui/material';
import { CascadingContext } from 'components/cascading-hover-menus/components/cascading-context';
import { CascadingMenu } from 'components/cascading-hover-menus/components/cascading-menu';

export const CascadingSubmenu = ({ title, popupId, children, ...props }) => {
    const { parentPopupState } = React.useContext(CascadingContext);
    const popupState = usePopupState({
        popupId,
        variant: 'popover',
        parentPopupState,
    });

    return (
        <>
            <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)} sx={style?.hovermenuitems} data-testid="sub-menu">
                <Typography component="h4" sx={{ ...style.menuitemColor, display: 'flex', alignItems: 'center' }}>
                    {title}
                    <ChevronRight />
                </Typography>
            </MenuItem>
            <CascadingMenu
                popupState={popupState}
                {...props}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {children}
            </CascadingMenu>
        </>
    );
};
CascadingSubmenu.propTypes = {
    title: PropTypes.string.isRequired,
    popupId: PropTypes.string.isRequired,
    children: PropTypes.node,
};
CascadingSubmenu.defaultProps = {
    children: null,
};

export default {};
