import React from 'react';

function ChesterAvatar() {
    return (
        <div>
            <img src="https://pepsicosb.service-now.com//d0dcf80c87561d508c74315d0ebb35cc.iix" alt="bot" height="30px" />
        </div>
    );
}

export default ChesterAvatar;
