import { atom } from 'recoil';

export const toggleQuickConfig = atom({
    key: 'keyvault-toggleQuickConfig',
    default: false,
});

export const quickConfigDetails = atom({
    key: 'keyvault-quickConfigDetails',
    default: undefined,
});

export const toggleSspModels = atom({
    key: 'keyvault-toggleSspModels',
    default: {
        isCost: { costType: '', isCostOpen: false },
        isTagOpen: false,
        isApplicationOpen: false,
        isSubmitOrderSuccess: false,
        isUpdatedToCart: false,
        isCostSplitOpen: false,
    },
});

export const isEnvDetailsLoading = atom({
    key: 'keyvault-isEnvDetailsLoading',
    default: false,
});

export const isMandatoryTagsFullfilled = atom({
    key: 'keyvault-isMandatoryTagsFullfilled',
    default: false,
});

export const orderId = atom({
    key: 'keyvault-orderId',
    default: '',
});

export const sspSubmitOrder = atom({
    key: 'keyvault-sspOrder',
    default: {
        appProfile: null,
        environment: null,
        pricingTier: 'standard',
        region: null,
        appTier: 'Azure Key Vault',
        appTierCode: 'AKV',
        vaultAccessPolicy: null,
        keyVaultName: '',
        hCode: null,
        hCodeDetails: null,
        tags: [],
        estimatedCost: {},
        catalogCode: null,
        placeNewOrder: new Date(),
        validationStatus: 'clearValidation',
        isTouched: false,
    },
});

export const environmentDetails = atom({
    key: 'keyvault-environmentDetails',
    default: {
        Subscription: '-',
        'Resource Group': '-',
        'Approval Manager': '-',
        'Soft-Delete': 'Enabled',
        'Day to retain deleted Vaults': '90 Days',
        'Purge Protection': 'Enabled',
    },
});

export const costEstimateState = atom({
    key: 'keyvault-costEstimateState',
    default: null,
});
export const isLoadingCostApiState = atom({
    key: 'keyvault-isLoadingCostApiState',
    default: {
        isLoading: false,
    },
});
