import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { componentGroupsState, OrderPayload } from 'pages/reference-implementation/provisioning/RI003O/store';
import { platformManaged, selectedEnv } from 'pages/reference-implementation/provisioning/RI003O/utils/constant';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { getComponentsFromSchema } from '../../utils';

export const Sector = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const componentGroups = useRecoilValue(componentGroupsState);
    const [options, setOptions] = React.useState([]);
    const sectorApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.sectors}?platformManaged=${platformManaged}`));

    const setSector = (sector) => {
        setOrder({
            ...order,
            sector,
            region: null,
            division: null,
            program: null,
            resourceMgmt: {
                [selectedEnv]: {
                    components: getComponentsFromSchema({ componentGroups, particularPrompt: ['mandatoryComponents'] })?.[0]?.components || [],
                },
            },
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sector) => options.find((option) => sector === option.value);

    const handleOnSelectChange = (selected) => setSector(selected.value);

    React.useEffect(() => {
        if (sectorApi?.isSuccess) {
            setOptions(sectorApi?.data?.data?.map((app) => ({ label: app?.sectorDisplayName, value: app?.sectorDisplayName })));
        }
    }, [sectorApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.sector) {
            setOrder({ ...order, sector: options?.[0].value });
        }
        if (options && options?.length && order?.sector) {
            const sector = isExistsInOptions(order?.sector);
            setOrder({ ...order, sector: sector?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        sectorApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.sector && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.sector, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="sector"
                label={<InputLabel label="Sector" isRequired />}
                value={order?.sector || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sectorApi.isLoading}
                isLoading={sectorApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Sector);
