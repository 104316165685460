import React from 'react';
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { endPoints } from 'api/endpoints';
import { RecurringFrequency } from 'utils/costFrequency';
import { useMutation } from 'react-query';
import axios from 'api/request';
import PropTypes from 'prop-types';
import { currencySymbols } from 'utils';
import { eipStackCatalogDefinitionId } from 'utils/catalogDefinitionId';
import InfoImg from 'components/info-img';
import { cartStyle } from 'pages/cart/style';

function CostAccordian(props) {
    const { item, modal, defaultExpanded } = props;
    const [catalogData, setCatalogData] = React.useState('');
    const cartItemApi = useMutation((cartItemId) => axios.get(`${endPoints.cart.getCartItem}/${cartItemId}`));

    const getData = (val) =>
        catalogData?.catalogService?.catalogConfig?.components?.filter((catlog) => catlog?.catalogService?.componentId === val)?.[0]?.catalogService
            ?.estimatedCost?.details;

    React.useEffect(() => {
        if (cartItemApi?.isSuccess) {
            setCatalogData(cartItemApi?.data?.data?.itemDetail);
        }
    }, [cartItemApi?.isSuccess]);
    React.useEffect(() => {
        if (eipStackCatalogDefinitionId.includes(item?.catalogServiceCode)) {
            cartItemApi?.mutate(item?.itemId);
        }
    }, [item]);
    return (
        <Grid item xs={12} sx={cartStyle.applicationCostDetails.wrapper}>
            <Accordion sx={cartStyle?.costAccordian?.accordian} disableGutters defaultExpanded={defaultExpanded}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={cartStyle?.costAccordian?.expandIcon} />} sx={cartStyle?.costAccordian?.container}>
                    <Typography sx={cartStyle?.costAccordian?.summaryTitle}> {item?.catalogType}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={cartStyle?.costAccordian?.accordianDetails}>
                    <Grid container xs={12} spacing={1} sx={cartStyle?.CostDetails?.gridContainer}>
                        <Grid item xs={12}>
                            {item?.estimatedCosts?.details &&
                                item?.estimatedCosts?.details
                                    ?.filter((detail) => detail.frequency.toLowerCase() === modal?.isCost?.costType?.toLowerCase().toLowerCase())
                                    ?.map((detail, idx) => (
                                        <Grid container xs={12} key={`detail-${idx}`}>
                                            <Grid item xs={4}>
                                                <Box sx={cartStyle?.CostDetails?.item}>{detail?.item || detail?.name} :</Box>
                                                <Box sx={cartStyle?.CostDetails?.unit}>({detail?.frequency})</Box>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Box sx={cartStyle?.CostDetails?.estWrapper}>
                                                    <Box sx={cartStyle?.CostDetails?.colon}>&nbsp;</Box>
                                                    <Box sx={cartStyle?.CostDetails?.estCost}>
                                                        {detail?.estCost?.toString() && currencySymbols[detail?.currency]}
                                                        {(detail?.estCost?.toString() && detail?.estCost?.toFixed(2)) || '---'}
                                                        {eipStackCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                                                            detail?.estCost?.toString() &&
                                                            RecurringFrequency.includes(modal?.isCost?.costType?.toLowerCase()) && (
                                                                <Box sx={{ marginLeft: 1 }}>
                                                                    <InfoImg
                                                                        title={
                                                                            <Box sx={cartStyle?.CostDetails?.infoWrapper}>
                                                                                <Grid container sx={cartStyle?.CostDetails?.infogridContainer}>
                                                                                    {(getData(detail?.componentId) || [])?.map((label, index) => (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={12}
                                                                                            sx={cartStyle?.CostDetails?.infogriditem}
                                                                                            key={index}
                                                                                        >
                                                                                            <Box width="70%">
                                                                                                <Typography sx={cartStyle?.CostDetails?.infotypo}>
                                                                                                    {' '}
                                                                                                    {label?.item}:
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box width="30%">
                                                                                                <Typography sx={cartStyle?.CostDetails?.infotypoValue}>
                                                                                                    {' '}
                                                                                                    {currencySymbols[label?.currency]}
                                                                                                    {label?.estCost}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    ))}
                                                                                </Grid>
                                                                            </Box>
                                                                        }
                                                                        placement="right"
                                                                    />
                                                                </Box>
                                                            )}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                        </Grid>

                        <Grid item xs={4.4}>
                            <Box sx={cartStyle?.CostDetails?.OneTimeCostLabel}>
                                {modal?.isCost?.costType?.toLowerCase() === 'one time' ? 'Total One-Time Cost:' : 'Total Recurring Cost:'}
                            </Box>
                            {modal?.isCost?.costType?.toLowerCase() === 'monthly' && (
                                <Box sx={cartStyle?.CostDetails?.unit}>({item?.estimatedCosts?.totalRecurringCost?.frequency})</Box>
                            )}
                        </Grid>

                        <Grid item xs={7.6} sx={cartStyle?.CostDetails?.OneTimeCostLabel}>
                            {modal?.isCost?.costType?.toLowerCase() === 'one time'
                                ? `${
                                      currencySymbols[item?.estimatedCosts?.totalOneTimeCost?.currency]
                                  }${item?.estimatedCosts?.totalOneTimeCost?.estCost?.toFixed(2)}`
                                : `${
                                      currencySymbols[item?.estimatedCosts?.totalRecurringCost?.currency]
                                  }${item?.estimatedCosts?.totalRecurringCost?.estCost?.toFixed(2)}`}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

CostAccordian.propTypes = {
    item: PropTypes.oneOfType([PropTypes.object]).isRequired,
    modal: PropTypes.oneOfType([PropTypes.object]).isRequired,
    defaultExpanded: PropTypes.bool.isRequired,
};

CostAccordian.defaultProps = {};
export default CostAccordian;
