/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { ddppGrp, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const ProxPlaceMentGroup = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [lzDDPPGrp, setLzDDPPGrp] = useRecoilState(ddppGrp);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [options, setOptions] = React.useState([]);
    const PPGroupApi = useMutation((subscriptionId) => axios.get(endPoints.landingZones.ProxProfileGroup, { params: { subscriptionId } }));
    const setProxPlacGrp = (proxPlacGrp = null, proxPlacGrpDetails = null) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                proxPlacGrp,
                proxPlacGrpDetails,
            });
        }
    };
    const resetOptions = () => {
        setOptions([]);
        setProxPlacGrp();
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setProxPlacGrp(selected?.label, selected);
        }
    };
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && lzModal?.lzDetails) {
            const ppg = options.find((value) => lzModal?.lzDetails?.config?.proximityPlacementGroup === value?.label);
            if (ppg) setLzDDPPGrp({ ...lzDDPPGrp, isLoaded: true, data: ppg });
        }
    }, [options]);
    React.useEffect(() => {
        if (PPGroupApi?.isSuccess) {
            setOptions(PPGroupApi?.data?.data?.map((app) => ({ label: app.name, value: app.name })));
        }
    }, [PPGroupApi?.isSuccess]);

    React.useEffect(() => {
        if (landZoneForm.subscriptionDetails?.id && !lzModal?.lzDetails) {
            resetOptions();
            PPGroupApi.mutate(landZoneForm.subscriptionDetails?.id);
        }
    }, [landZoneForm.subscriptionDetails]);
    React.useEffect(() => {
        if (lzModal?.lzDetails?.config?.subscription?.id && lzModal?.lzDetails) {
            PPGroupApi.mutate(lzModal?.lzDetails?.config?.subscription?.id);
        }
    }, [lzModal?.lzDetails]);
    return (
        <Box bgcolor="white">
            <Select
                key="proxPlacGrp"
                value={landZoneForm?.proxPlacGrp}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={PPGroupApi.isLoading}
                isLoading={PPGroupApi.isLoading}
            />
        </Box>
    );
};

export default React.memo(ProxPlaceMentGroup);
