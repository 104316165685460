import React from 'react';
import { Box } from '@mui/material';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import Select from 'components/select';
import { orderPayload } from 'pages/provisioning/virtual-service/store';

const ApplicationService = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState(order?.applicationService || '');
    const [options, setOptions] = React.useState([]);

    const applicationServiceApi = useMutation(() => axios.get(`${endPoints.virtualService.applicationService}?searchKey=${ccInputValue}`));

    const setAppService = (ciService) => {
        setOrder({
            ...order,
            applicationService: ciService?.label,
            applicationServiceDetails: ciService?.data,
        });
    };
    const resetOptions = () => {
        setOptions([]);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                applicationServiceApi.mutate();
            }, 1000),
        []
    );

    React.useEffect(() => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue && ccInputValue !== '' && ccInputValue?.length > 2) fetch();
    }, [ccInputValue, fetch]);

    React.useEffect(() => {
        if (applicationServiceApi?.isSuccess) {
            if (applicationServiceApi?.data?.data?.appProfiles) {
                const combinedValue = applicationServiceApi?.data?.data?.appProfiles?.map((app) => ({
                    label: `${app.businessCi}`,
                    value: `${app.businessCi}`,
                    data: app,
                }));
                setOptions(combinedValue);
            }
        }
    }, [applicationServiceApi?.isSuccess]);

    React.useEffect(() => {
        if (value === null) {
            resetOptions();
        }
        if (value !== null && value?.value) {
            setAppService(value);
        }
    }, [value]);

    React.useEffect(() => {
        if (order?.aksNamespace) {
            resetOptions();
        }
    }, [order?.aksNamespace]);

    React.useEffect(() => {
        if (!order?.applicationService && order?.isTouched) setIsTouched(true);
    }, [order?.applicationService, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="app-service-autocomplete"
                key="application Service"
                loading={applicationServiceApi?.isLoading}
                label="Application Service *"
                placeholder="Type to search"
                value={order?.applicationService || null}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue && newInputValue !== '') setCCInputValue(newInputValue || '');
                }}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        setValue({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            label: newValue.inputValue,
                        });
                    } else {
                        setValue(newValue);
                    }
                }}
                onBlur={() => {
                    if (!value || !order?.applicationService) {
                        resetOptions();
                    }
                }}
                onKeyUp={(event) => {
                    if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                        resetOptions();
                        setOrder({
                            ...order,
                            applicationService: null,
                            applicationServiceDetails: null,
                        });
                    }
                }}
                filterOptions={(x) => x}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props}>{option.label}</li>
                )}
                freeSolo
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ApplicationService);
