/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { eipStackCatalogServiceCode } from 'utils/catalogServiceCode';
import { landingZoneData } from 'pages/app-profiles/store';
import { getLandingZoneFromLocalStorage } from '../../utils';

const CloudProvider = () => {
    const params = useParams();
    const { operation } = params;
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [isTouched, setIsTouched] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const cloudProviderApi = useMutation(() => axios.get(endPoints.landingZones.cloudProvider));
    const setcloudProvider = (cloudProvider) => {
        setLandingZoneForm({
            ...landingZoneForm,
            cloudProvider,
        });
    };
    const seldValueExistsInOptions = (regionValue) => options?.find((value) => regionValue === value?.data?.name);
    const handleOnSelectChange = (selected) => {
        if (selected?.value !== landingZoneForm?.cloudProvider && seldValueExistsInOptions(selected?.value)) {
            setcloudProvider(selected?.data?.name);
        }
    };
    React.useEffect(() => {
        if (cloudProviderApi?.isSuccess) {
            setOptions(cloudProviderApi?.data?.data?.map((app) => ({ label: app.name, value: app.name, data: app })));
        }
    }, [cloudProviderApi?.isSuccess]);

    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.cloudProvider) {
            const cloudProvider = options.find((value) => landingZoneForm?.cloudProvider === value?.value);
            setcloudProvider(cloudProvider);
        }
        if (options?.length && options?.length === 1 && !landingZoneForm?.cloudProvider) {
            setTimeout(() => {
                handleOnSelectChange(options[0]);
            }, 300);
        }
    }, [options]);

    React.useEffect(() => {
        cloudProviderApi.mutate();
    }, []);
    React.useEffect(() => {
        if (!landingZoneForm?.cloudProvider && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.cloudProvider, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="Catalog Provider"
                value={landingZoneForm?.cloudProvider || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={
                    cloudProviderApi.isLoading ||
                    operation === 'edit' ||
                    (operation === 'create' && getLandingZoneFromLocalStorage() && eipStackCatalogServiceCode !== landingZoneForm?.cloudService)
                }
                isLoading={cloudProviderApi.isLoading}
                label="Provider*"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(CloudProvider);
