import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, Stack, CircularProgress } from '@mui/material';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import toast from 'react-hot-toast';
import Empty from 'components/empty';
import { useParams } from 'react-router-dom';
import { catalogs } from 'store';
import { OrderPayload, validOnboardingForm, toggleModal, showPreviewOrder, isSonarQubeRepoAvailable, costEstimateState } from 'pages/project-onboarding/store';
import { resetOrder, resetValidOnboardingForm, resetToggleModal, formOrderObjFromOrderDetails } from 'pages/project-onboarding/utils';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import ConfigureServiceModal from 'pages/project-onboarding/components/Form/ConfigureServiceModal';
import PreviewOrderDetails from 'pages/project-onboarding/components/preview/preview-order-details';
import { PRE_PREVIEW_ORDER } from 'pages/project-onboarding/utils/constants';
import { ProjectOnboardCatalogDefinitionId } from 'utils/catalogDefinitionId';
// import LoadFeatureData from 'components/load-feature-data/LoadFeatureData';
// import featureData from 'pages/project-onboarding/utils/FeatureData';

const StickyHeader = React.lazy(() => import('pages/project-onboarding/components/sticky-header'));
const InputForm = React.lazy(() => import('pages/project-onboarding/InputForm'));

const ProjectOnboarding = () => {
    const { catalogCode, cartItemId, operation } = useParams();
    const [day2Catalogs] = useRecoilState(catalogs);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [, setCostEstimateDetails] = useRecoilState(costEstimateState);
    const [validProvisionForm, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [isLoading, setLoading] = React.useState(false);
    const [, setAvailableRepo] = useRecoilState(isSonarQubeRepoAvailable);
    const catalogData = day2Catalogs?.find((item) => ProjectOnboardCatalogDefinitionId?.includes(item?.catalogDefinitionId));

    const orderDetailsApi = useMutation(() => axios.get(`${endPoints?.day2Operation?.ProjectOnboarding.Jwt.orderDetails}/${cartItemId}`));
    const learningDetailsApi = useMutation(() => axios.get(endPoints?.day2Operation?.ProjectOnboarding.learningApi));
    const costApi = useMutation((payload) => axios.post(endPoints?.day2Operation?.ProjectOnboarding?.costApi, payload));

    React.useEffect(() => {
        toast.dismiss();
    }, [order]);

    const handleReset = () => {
        setOrder(resetOrder);
        setValidProvisionForm(resetValidOnboardingForm);
        setToggleModal(resetToggleModal);
        setShowPreview(null);
        setAvailableRepo(true);
    };

    async function checkCatalog() {
        setLoading(true);

        try {
            if (operation === 'edit') {
                const { data: orderDetails } = await orderDetailsApi.mutateAsync();
                const orderObj = formOrderObjFromOrderDetails(orderDetails);
                // eslint-disable-next-line
                setOrder((previous) => ({
                    ...previous,
                    ...orderObj,
                }));
                if (orderObj?.clarityId) setValidProvisionForm({ ...validProvisionForm, isClarityIdValid: true });
            } else {
                setOrder((previous) => ({
                    ...previous,
                    catalogProvider: catalogData?.cloudProvider,
                    catalogType: catalogData?.catalogType,
                    cloudService: catalogData?.cloudService,
                    isTouched: false,
                }));
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const isShowBackBtn = () => {
        if (showPreview === PRE_PREVIEW_ORDER) {
            return true;
        }
        return false;
    };
    const handleBackBtn = () => {
        if (showPreview === PRE_PREVIEW_ORDER) {
            setShowPreview(null);
        }
    };
    const getOrderDetails = async () => {
        try {
            if (cartItemId && operation === 'edit') {
                const { data: orderDetails } = await orderDetailsApi.mutateAsync();
                const orderObj = formOrderObjFromOrderDetails(orderDetails);
                // eslint-disable-next-line
                setOrder((previous) => ({
                    ...previous,
                    ...orderObj,
                }));
            }
        } catch (error) {
            // eslint-disable-next-line no-empty
        }
    };

    React.useEffect(() => {
        if (learningDetailsApi?.isSuccess && learningDetailsApi?.data?.data) {
            setOrder({
                ...order,
                courseDetails: learningDetailsApi?.data?.data?.myLearningCoursesList,
            });
        }
    }, [learningDetailsApi?.isSuccess]);

    React.useEffect(() => {
        if (catalogCode) {
            handleReset();
            checkCatalog();
            learningDetailsApi?.mutate();
        }
    }, [catalogCode]);
    React.useEffect(() => {
        if (catalogCode) {
            handleReset();
            checkCatalog();
        }
    }, [operation]);

    /* eslint-disable arrow-body-style */
    React.useEffect(() => {
        return () => handleReset();
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        handleReset();
        getOrderDetails();
    }, [operation, cartItemId]);

    const getCostDetail = () => {
        const costPayload = {
            catalogServiceCode: catalogData?.catalogDefinitionId,
            sector: order?.sector,
            quantity: 1,
            catalogConfig: {
                testPlans: {
                    hCode: order?.hCode,
                    members: order?.testPlanUserList,
                },
            },
        };

        costApi.mutate(costPayload);
    };

    React.useEffect(() => {
        if (order?.testPlanUserList && order?.testPlanUserList?.length > 0) getCostDetail();
    }, [order?.testPlanUserList]);

    React.useEffect(() => {
        if (costApi?.isLoading) {
            setOrder({
                ...order,
                isCostApiLoading: true,
            });
        }
    }, [costApi?.isLoading]);

    React.useEffect(() => {
        if (costApi?.data?.data) {
            setOrder({ ...order, estimatedCost: costApi?.data?.data, isCostApiLoading: false });
            setCostEstimateDetails(costApi?.data?.data);
        }
    }, [costApi?.isSuccess]);

    return (
        <>
            <Helmet>
                <title>DevOps Project Onboarding</title>
            </Helmet>
            <Suspense
                fallback={
                    <Box sx={ProjectOnboardingStyles.provisioning_wrapper}>
                        <CircularProgress color="inherit" size={16} sx={ProjectOnboardingStyles.circularProgressColor} />
                    </Box>
                }
            >
                {isLoading && (
                    <Stack sx={ProjectOnboardingStyles?.emptyStack}>
                        <Empty isLoading color="#000000" msg="Loading..." />
                    </Stack>
                )}
                {!isLoading && (
                    <>
                        <StickyHeader title="Azure DevOps Project Onboarding" imgSrc={null} back={isShowBackBtn()} handleBackBtn={() => handleBackBtn()} />
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Grid display="flex" flexDirection="column" padding={4} gap={3} data-testId="project-onboard">
                                    {/* <LoadFeatureData order={order} setOrder={setOrder} featureData={featureData} /> */}
                                    {showPreview === PRE_PREVIEW_ORDER && <PreviewOrderDetails />}
                                    {!showPreview && (
                                        <>
                                            <InputForm />
                                            <ConfigureServiceModal />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Suspense>
        </>
    );
};

export default React.memo(ProjectOnboarding);
