import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isServiceProviderSigningCertValid } from 'pages/sso-okta/store';
import HelpImg from 'pages/provisioning/_components/helpImg';
import { ROLE_TO_SHOW } from 'pages/sso-okta/utils/constant';

export const ServiceProviderSigningCertificate = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setServiceProviderSigningCertValid] = useRecoilState(isServiceProviderSigningCertValid);
    const [helperText, setHelperText] = React.useState(false);
    const pemRegex = /^-----BEGIN CERTIFICATE-----[\s\S]+-----END CERTIFICATE-----$/;
    const setServiceProviderSigningCertification = (serviceProviderSigningCert = null) => {
        setOrder({
            ...order,
            serviceProviderSigningCert,
        });
    };

    React.useEffect(() => {
        if (
            order?.serviceProviderSigningCert &&
            order?.serviceProviderSigningCert !== null &&
            order?.serviceProviderSigningCert !== '' &&
            order?.serviceProviderSigningCert !== undefined &&
            !pemRegex.test(order?.serviceProviderSigningCert)
        ) {
            setHelperText(true);
            setServiceProviderSigningCertValid(false);
        } else {
            setHelperText(false);
            setServiceProviderSigningCertValid(true);
        }
    }, [order?.serviceProviderSigningCert]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.serviceProviderSigningCert) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.serviceProviderSigningCert && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.serviceProviderSigningCert, order?.isTouched]);

    return (
        <>
            {ROLE_TO_SHOW?.includes(order?.dataClassification?.toLowerCase()) && (
                <Box display="flex" alignItems="center" gap={1} data-testid="application-url-box">
                    <CssTextField
                        size="small"
                        sx={{
                            width: (theme) => theme.spacing('100%'),
                            '& .MuiFormHelperText-root': {
                                marginTop: (theme) => theme.spacing('-2'),
                            },
                        }}
                        autoComplete="off"
                        label="Service Provider (SP) Signing Certificate *"
                        error={helperText}
                        helperText={helperText && <span>Service Provider (SP) Signing Certificate is invalid.</span>}
                        value={order?.serviceProviderSigningCert || ''}
                        onChange={(e) => setServiceProviderSigningCertification(e.target.value)}
                        className={validateInput()}
                        data-testid="serviceProviderText"
                    />
                    <HelpImg
                        title={
                            <Box sx={{ whiteSpace: 'pre' }}>
                                <>
                                    <Box>Determines the public key certificate used to verify the digitally encrypt the SAML assertion.</Box>
                                    <Box> Below is the sample format for the certificate input value -</Box>
                                    <Box>-----BEGIN CERTIFICATE----- MIIHKTCCBhGgAwIBAgIRAPigeym…. -----END CERTIFICATE-----</Box>
                                </>
                            </Box>
                        }
                        maxWidth="600px"
                    />
                </Box>
            )}
        </>
    );
};

export default ServiceProviderSigningCertificate;
