import { atom } from 'recoil';

export const ArchImgUrl = atom({
    key: 'RI003O-ArchImgUrl',
    default: undefined,
});

export const isExpandArchDiagram = atom({
    key: 'RI003O-isExpandArchDiagram',
    default: false,
});

export const OrderPayload = atom({
    key: 'RI003O-OrderPayload',
    default: {
        catalogCode: null,
        projectId: null,
        projectDetails: null,
        businessUnit: null,
        sector: null,
        program: null,
        division: null,
        environment: null,
        dataClassification: null,
        budgetCode: null,
        budgetCodeDetails: null,
        billingInfo: null,
        nonPrivilegedADGroupList: [{ readerValue: '' }],
        privilegedADGroupList: [{ contributorValue: '' }],
        environmentDetails: null,
        adGroup: 'Yes',
        privilegedADGroup: null,
        nonPrivilegedADGroup: null,
        businessAppService: 'No',
        businessApp: null,
        appService: null,
        isTouched: false,
        estimatedCost: null,
        appOwner: null,
        appOwnerDetails: null,
        certificateOwnerOne: null,
        certificateOwnerOneDetails: null,
        certificateOwnerTwo: null,
        certificateOwnerTwoDetails: null,
        businessContact: null,
        businessContactDetails: null,
        privilegedADGroupOwner: null,
        privilegedADGroupOwnerDetails: null,
        nonPrivilegedADGroupOwner: null,
        nonPrivilegedADGroupOwnerDetails: null,
        applicationName: null,
        appShortName: null,
        isaNumber: null,
        appSupportMailId: null,
        resourceMgmt: null,
    },
});

export const isGetAppShortNameLoading = atom({
    key: 'RI003O-isGetAppShortNameLoading',
    default: false,
});

export const isAppNameValid = atom({
    key: 'RI003O-isAppNameValid',
    default: false,
});

export const isAppShortNameValid = atom({
    key: 'RI003O-isAppShortNameValid',
    default: false,
});

export const isAppShortNameExist = atom({
    key: 'RI003O-isAppShortNameExist',
    default: false,
});

export const isBusinessNameValid = atom({
    key: 'RI003O-isBusinessNameValid',
    default: true,
});

export const isBudgetCodeValid = atom({
    key: 'RI003O-isBudgetCodeValid',
    default: true,
});

export const isBillingInfoValid = atom({
    key: 'RI003O-isBillingInfoValid',
    default: true,
});

export const isProjectIdValid = atom({
    key: 'RI003O-isProjectIdValid',
    default: false,
});

export const adGroupErrorMsg = atom({
    key: 'RI003O-adGroupErrorMsg',
    default: false,
});

export const contributorErrorMsg = atom({
    key: 'RI003O-contributorErrorMsg',
    default: false,
});

export const isADGroupValid = atom({
    key: 'RI003O-isADGroupValid',
    default: true,
});

export const isBusinessAppValid = atom({
    key: 'RI003O-isBusinessAppValid',
    default: false,
});

export const isAppUrlValid = atom({
    key: 'RI003O-isAppUrlValid',
    default: false,
});

export const isAppContextPathValid = atom({
    key: 'RI003O-isAppContextPathValid',
    default: false,
});

export const isNonPrivilegedADGroupValid = atom({
    key: 'RI003O-isNonPrivilegedADGroupValid',
    default: false,
});

export const isPrivilegedADGroupValid = atom({
    key: 'RI003O-isPrivilegedADGroupValid',
    default: false,
});

export const costEstimateState = atom({
    key: 'RI003O-costEstimateState',
    default: null,
});

export const toggleModal = atom({
    key: 'RI003O-toggleModal',
    default: {
        isADGroupModalOpen: false,
        isNonPrivilegedADGroupOpen: false,
        isPrivilegedADGroupOpen: false,
        isCost: { costType: '', isCostOpen: false },
    },
});

export const showPreviewOrder = atom({
    key: 'RI003O-showPreviewOrder',
    default: false,
});

export const previewDetails = atom({
    key: 'RI003O-previewdetails',
    default: null,
});
export const previewApiLoading = atom({
    key: 'RI003O-previewApiLoading',
    default: false,
});
export const isSectorDisabled = atom({
    key: 'RI003O-isSectorDisabled',
    default: true,
});
export const isBusinessUnitDisabled = atom({
    key: 'RI003O-isBusinessUnitDisabled',
    default: true,
});
export const isHcodeDisabled = atom({
    key: 'RI003O-isHcodeDisabled',
    default: true,
});
export const previewOrderError = atom({
    key: 'RI003O-previewError',
    default: {
        isPreviewError: false,
        message: '',
    },
});

export const isValidappSupportMailId = atom({
    key: 'RI003O-isValidappSupportMailId',
    default: false,
});
export const isValidOwners = atom({
    key: 'RI003O-isValidOwners',
    default: {
        isvalidAppOwner: true,
        isvalidCertificateOwnerOne: true,
        isvalidCertificateOwnerTwo: true,
    },
});
export const componentGroupsState = atom({
    key: 'RI003O-componentGroupsState',
    default: null,
});

export const SecondaryApprover = atom({
    key: 'RI003O-secondaryApprover',
    default: { selectedFieldFrom: { module: null, subModule: null }, value: { groupOwner: null, groupOwnerDetails: null } },
});
