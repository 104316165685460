/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import { useMutation } from 'react-query';
import axios from 'api/request';
import Select from 'components/select';
import { sspSubmitOrder } from 'pages/provisioning/store/couchbase';
import { updateNodesDetails } from 'pages/provisioning/store/couchbase/selecters';
import ProvisioningStyles from 'pages/provisioning/couchbase/style';

const TimeZone = ({ indexVal }) => {
    const [order] = useRecoilState(sspSubmitOrder);
    const [options, setOptions] = React.useState([]);
    const updateNodesData = useSetRecoilState(updateNodesDetails);

    const setTimeZone = (key, selected, index) => {
        updateNodesData({ key, selected, index });
    };
    const timezoneApi = useMutation(() => axios.get(`${endPoints.ssp.vm.timezones}`));
    const seldValueExistsInOptions = (timeZoneValue) => options.find((option) => timeZoneValue === option?.value);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.value)) {
            setTimeZone('timeZone', selected?.value, indexVal);
        }
    };
    React.useEffect(() => {
        if (timezoneApi?.isSuccess) {
            setOptions(timezoneApi?.data?.data?.values.map((app) => ({ label: app.displayName, value: app.displayName, data: app })));
        }
    }, [timezoneApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && !order?.nodes?.[indexVal]?.timeZone) {
            const hours = seldValueExistsInOptions('UTC');
            if (hours) handleOnSelectChange(hours);
        }
        if (options?.length && order?.nodes?.[indexVal]?.timeZone) {
            const hours = options.find((option) => order?.nodes?.[indexVal]?.timeZone === option?.data?.LinuxTimeZone);
            if (hours) setTimeZone(hours?.value, hours?.data);
        }
    }, [options]);

    React.useEffect(() => {
        timezoneApi.mutate();
        // eslint-disable-next-line
    }, [order?.appProfileDetails]);
    return (
        <Box sx={ProvisioningStyles.box}>
            <Select
                key="timeZone"
                label="TimeZone*"
                value={order?.nodes?.[indexVal]?.timeZone || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={timezoneApi.isLoading}
                isLoading={timezoneApi.isLoading}
                isRequired
            />
        </Box>
    );
};

TimeZone.propTypes = {
    indexVal: PropTypes.number,
};

TimeZone.defaultProps = {
    indexVal: 0,
};

export default React.memo(TimeZone);
