import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { CssTextField } from 'components/select';
import Autocomplete from '@mui/material/Autocomplete';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useMutation } from 'react-query';
import { memberForm, selectedMembersList, currentSelection, optionsList, OrderPayload, invalidMember, isMembersApiLoading } from 'pages/myidm/store';
import { Styles } from 'pages/myidm/style';
import SearchIcon from '@mui/icons-material/Search';
import { AddMemberBtn } from 'pages/myidm/components';
import { InputInfo } from 'pages/myidm/components/input-info';

const AddMemberSearch = () => {
    const [form, setForm] = useRecoilState(memberForm);
    const [members, setMembers] = useRecoilState(selectedMembersList);
    const [current] = useRecoilState(currentSelection);
    const getmembersApiLoader = useRecoilValue(isMembersApiLoading);
    const [options, setOptions] = useRecoilState(optionsList);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [invalid, setInvalid] = useRecoilState(invalidMember);
    const [searched, setSearched] = React.useState(false);

    const [value, setValue] = useState(null);
    const [showError, setShowError] = useState({ status: false, message: '' });
    const [ccInputValue, setCCInputValue] = useState('-');
    const appOwnerApi = useMutation(() => axios.get(`${endPoints.appProfile.appOwner}?key=${ccInputValue}`));
    const crossDomainUserApi = useMutation((payload) => axios.get(`${endPoints?.myIdm?.isCrossDomainUser}/${payload?.gpid}`));
    const setTableList = (selectedMember, member) => {
        setForm({
            ...form,
            selectedMember,
            member,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        setTableList(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                appOwnerApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        if (ccInputValue === '') {
            resetOptions();
        }
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };
    React.useEffect(() => {
        if (appOwnerApi?.data?.data) {
            const combinedValue = appOwnerApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [appOwnerApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setTableList(value?.label, value);
        }
    }, [value]);

    const handleAddMember = () => {
        if (members?.length > 9) {
            setInvalid(true);
            setShowError({ status: true, message: 'You have reached max limit of 10 members.' });
        } else {
            const { member } = form;
            setMembers([...members, member]);
            setForm({
                ...form,
                selectedMember: null,
                member: null,
            });

            setShowError({ status: false, message: '' });
            setInvalid(true);
        }
    };
    const inputValidate = (input) => {
        const isPresent = members?.filter((element) => element?.gpid === input?.gpid);
        const isaMember =
            (order?.adGroupMembers?.length > 0 && order?.adGroupMembers?.filter((member) => member?.gpid?.toLowerCase() === input?.gpid?.toLowerCase())) || [];
        if (form.selectedMember !== null) {
            if (form.selectedMember === form.member.label) {
                setInvalid(false);
            } else {
                setInvalid(true);
            }
        }
        if (input) {
            if (isPresent?.length === 0 && input && form?.selectedMember === null && isaMember?.length === 0) {
                setInvalid(false);
                setShowError({ status: false, message: '' });
                setTableList(input?.label, input);
            }
            // else if (isPresent.length === 0 && isaMember?.length === 0 && order?.crossDomainUser && input === null) {

            //     setShowError({ status: false, message: '' });
            // }
            // else if (isPresent.length > 0 && input === null && form.selectedMember === null && isaMember?.length === 0 && order?.crossDomainUser) {
            //     setShowError({ status: false, message: '' });
            // }
            else if (order?.crossDomainUser === true) {
                setShowError({
                    status: true,
                    message:
                        'Given member belongs to CWW domain, for Non-Universal AD group we can only add CORPPVT members hence please choose another member if you want to proceed further',
                });
                setInvalid(true);
            } else if (isaMember?.length > 0) {
                setShowError({
                    status: true,
                    message: 'Member already present in the AD Group and please choose different member if you wish to continue further.',
                });
                setInvalid(true);
            } else if (isPresent?.length > 0) {
                setShowError({ status: true, message: 'Member already added in the list.' });
                setInvalid(true);
            } else {
                setShowError({ status: false, message: '' });
            }
        } else {
            setShowError({ status: false, message: '' });
        }
    };
    React.useEffect(() => {
        if (crossDomainUserApi?.isSuccess && crossDomainUserApi?.data?.data) {
            setOrder({ ...order, crossDomainUser: crossDomainUserApi?.data?.data?.isCrossDomainUser });
        }
    }, [crossDomainUserApi?.isSuccess]);
    React.useEffect(() => {
        inputValidate(form?.member);
        // if (form?.member?.gpid)
    }, [form, order?.crossDomainUser]);
    React.useEffect(() => {
        if (order?.isUniversalADGroup === false && form?.member?.gpid) crossDomainUserApi.mutate({ gpid: form?.member?.gpid });
    }, [order?.isUniversalADGroup, form?.member]);

    React.useEffect(() => {
        if (members?.length > 0) {
            inputValidate(form?.member);
        } else {
            resetOptions();
        }
    }, [current]);

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };

    return (
        <>
            {' '}
            <Grid item sx={{ mb: 2, minHeight: 94 }}>
                <Box display="flex" alignItems="flex-start" gap={1}>
                    <Autocomplete
                        key="member"
                        size="small"
                        noOptionsText={getNoOptionText()}
                        // disabled={
                        //     order?.isNewADGroup === 'yes'
                        //         ? !order?.appShortName || !order?.groupOwner || !order?.adGroupType || !order?.applicationName
                        //         : !order?.adGroup || getmembersApiLoader
                        // }
                        error
                        includeInputInList
                        loading={appOwnerApi?.isLoading || crossDomainUserApi?.isLoading}
                        value={form?.member || null}
                        onInputChange={(event, newInputValue) => {
                            if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                        }}
                        data-testid="addmembers-search"
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                setValue({
                                    label: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                setValue({
                                    label: newValue.inputValue,
                                });
                            } else {
                                setValue(newValue);
                                inputValidate(newValue);
                            }
                        }}
                        onBlur={() => {
                            setSearched(false);
                            setOptions([]);
                        }}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                setSearched(true);
                                handleSearch();
                            }
                            if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                                setSearched(false);
                                resetOptions();
                            }
                        }}
                        filterOptions={(optionValues, params) => {
                            // eslint-disable-next-line no-underscore-dangle
                            const _results = [];
                            // eslint-disable-next-line no-plusplus
                            for (let i = 0; i < optionValues.length; i++) {
                                // eslint-disable-next-line no-restricted-syntax
                                for (const key in optionValues[i]) {
                                    if (
                                        optionValues[i][key] !== null &&
                                        typeof optionValues[i][key] !== 'boolean' &&
                                        typeof optionValues[i][key] !== 'number'
                                    ) {
                                        if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                            _results.push(optionValues[i]);
                                        }
                                    }
                                }
                            }
                            return [...new Set(_results)];
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="free-solo-with-text-demo"
                        options={options}
                        isOptionEqualToValue={(option, value1) => {
                            return option.label === value1?.label;
                        }}
                        sx={{ width: '70%' }}
                        // renderOption={(props, option) => (
                        //     <li {...props} key={option.gpid}>
                        //         {option.label}
                        //     </li>
                        // )}
                        renderInput={(params) => (
                            <CssTextField
                                sx={{
                                    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                                        paddingRight: '10px',
                                    },
                                }}
                                size="large"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="AD Group Member"
                                helperText={showError.status && <span style={Styles.AddMemberForm.helpText}>{showError.message}</span>}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            <SearchIcon
                                                sx={Styles.AddMemberActions.searchSelect.searchIcon}
                                                onClick={() => {
                                                    setSearched(true);
                                                    handleSearch();
                                                }}
                                                data-testid="search-btn"
                                            />
                                        </>
                                    ),
                                }}
                                fullWidth
                            />
                        )}
                    />
                    <Box display="flex" alignItems="center" gap={1}>
                        <InputInfo title="You can add maximum 10 members." />
                        <AddMemberBtn
                            handleAddMember={() => handleAddMember()}
                            invalid={invalid || appOwnerApi?.isLoading || getmembersApiLoader || crossDomainUserApi?.isLoading}
                        />
                    </Box>
                </Box>
            </Grid>
        </>
    );
};

export default React.memo(AddMemberSearch);
