import range from 'lodash/range';

export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const catalogConfig = data?.itemDetail?.catalogService?.catalogConfig || null;
    const consumerGroupRadio =
        data?.itemDetail?.catalogService?.catalogConfig?.consumerGroup && data?.itemDetail?.catalogService?.catalogConfig?.consumerId ? 'yes' : 'no';
    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: environmentDetails?.sector || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // catalogSize: null,
        // catalogSizeDetails: { value: catalogConfig?.size },
        appTier: environmentDetails?.applicationTier || 'null',
        appTierCode: environmentDetails?.appTierCode || null,
        department: environmentDetails?.department,
        departmentDetails: { label: environmentDetails?.department, value: environmentDetails?.department },
        appSupportMailId: catalogConfig?.appSupportMailId,
        cluster: catalogConfig?.cluster,
        clusterDetails: { label: catalogConfig?.cluster, value: catalogConfig?.cluster },
        businessUnit: environmentDetails?.businessUnit,
        businessUnitDetails: { label: environmentDetails?.businessUnit, value: environmentDetails?.businessUnit },
        project: catalogConfig?.project,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        topic: data?.itemDetail?.catalogService?.catalogConfig?.topicName,
        retentionUnit: catalogConfig?.retentionUnit,
        retentionDetails: { millisec: data?.itemDetail?.catalogService?.catalogConfig?.retentionPeriod },
        partitions: data?.itemDetail?.catalogService?.catalogConfig?.partitionCount,
        consumerGroupRadio,
        writeIDX: data?.itemDetail?.catalogService?.catalogConfig?.producerId,
        ...(data?.itemDetail?.catalogService?.catalogConfig?.consumerGroup && {
            consumerGroupList: data?.itemDetail?.catalogService?.catalogConfig?.consumerGroup?.split(',').map((z) => ({ value: z })),
        }),
        ...(data?.itemDetail?.catalogService?.catalogConfig?.consumerId && {
            readIDX: data?.itemDetail?.catalogService?.catalogConfig?.consumerId,
        }),
        transactionalId: data?.itemDetail?.catalogService?.catalogConfig?.isTransactional ? 'yes' : 'no',
        ...(data?.itemDetail?.catalogService?.catalogConfig?.isTransactional && {
            transactionPrincipalId: data?.itemDetail?.catalogService?.catalogConfig?.transactionPrincipalId,
            transactionPrefixText: data?.itemDetail?.catalogService?.catalogConfig?.transactionPrefixText,
        }),
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: entityDetails?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        environment: environmentDetails?.environment || null,
        region: environmentDetails?.region || null,
        regionDisplayName: { label: environmentDetails?.region || null, value: environmentDetails?.region || null },
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        department: environmentDetails?.department,
        departmentDetails: { label: environmentDetails?.department, value: environmentDetails?.department },
        appSupportMailId: catalogConfig?.appSupportMailId,
        cluster: catalogConfig?.cluster,
        clusterDetails: { label: catalogConfig?.cluster, value: catalogConfig?.cluster },
        // catalogSize: null,
        // catalogSizeDetails: { value: catalogConfig?.size },
        businessUnit: environmentDetails?.businessUnit,
        businessUnitDetails: { label: environmentDetails?.businessUnit, value: environmentDetails?.businessUnit },
        project: catalogConfig?.project,
        hCode: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        topic: catalogConfig?.topicName,
        retentionUnit: catalogConfig?.retentionUnit,
        retentionDetails: { millisec: catalogConfig?.retentionPeriod },
        consumerGroupList: catalogConfig?.consumerGroup?.split(',').map((z) => ({ value: z })),
        partitions: catalogConfig?.partitionCount,
        writeIDX: catalogConfig?.producerId,
        readIDX: catalogConfig?.consumerId,
        transactionalId: catalogConfig?.isTransactional ? 'yes' : 'no',
        ...(catalogConfig?.isTransactional && {
            transactionPrincipalId: catalogConfig?.transactionPrincipalId,
            transactionPrefixText: catalogConfig?.transactionPrefixText,
        }),
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = (data = null, cloudService = null) => {
    const currentComponent = data?.landingZoneDetails?.config?.components?.filter((component) => component?.service === cloudService);
    return {
        appProfile: data?.appProfile,
        appProfileDetails: data?.appProfileDetails,
        entityDetails: data?.entityDetails,
        billingEntity: data?.entityDetails?.billingEntity || null,
        hCode: data?.entityDetails?.financialDetails || null,
        ...(data?.entityDetails?.financialDetails && {
            hCodeDetails: { label: data?.entityDetails?.financialDetails, projectName: data?.entityDetails?.projectName },
        }),
        ...(!data?.entityDetails?.financialDetails && { hCodeDetails: null }),
        sector: data?.sector,
        environment: data?.environment,
        region: data?.region || null,
        landingZoneDetails: {
            id: currentComponent?.[0]?.landingzoneId || null,
        },
    };
};
export const resetAppProfile = {
    appProfile: null,
    appProfileDetails: null,
};
export const resetOrder = {
    entityDetails: null,
    sector: null,
    sectorDetails: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    department: null,
    departmentDetails: null,
    cluster: null,
    appSupportMailId: null,
    clusterDetails: null,
    topic: '',
    consumerGroupList: [{ value: '' }],
    businessUnit: null,
    businessUnitDetails: null,
    project: '',
    readIDX: '',
    writeIDX: '',
    transactionalId: 'no',
    consumerGroupRadio: 'no',
    transactionPrincipalId: '',
    transactionPrefixText: '',
    partitions: null,
    retention: null,
    retentionUnit: 'In Days',
    retentionDetails: null,
    hCode: null,
    hCodeDetails: null,
    appTier: 'Kafka Topics',
    appTierCode: 'KT',
    envDetails: null,
    // catalogSize: null,
    // catalogSizeDetails: null,
    tags: [],
    estimatedCost: {},
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetEnvironmentDetails = {
    Project: '-',
    Approval_Manager: '-',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isConsumerGroupOpen: false,
    isSubmitOrderSuccess: false,
    isUpdatedToCart: false,
    isCostSplitOpen: false,
};

export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const getRetention = (millisec) => {
    try {
        let seconds = Math.floor(millisec / 1000); //   coverting millisec to seconds (Ex.: 5400000/1000 = 5400)
        let minutes = Math.floor(seconds / 60); //   coverting seconds to minutes (Ex.: 5400/60 = 90)
        let hours = Math.floor(minutes / 60); //    coverting minutes to hours (Ex.: 90/60 = 1.5 => 1 (due to Math.floor))
        seconds %= 60; // rounding up remaining seconds by module  (Ex.: 5400%60 = 0)
        minutes = seconds >= 30 ? minutes + 1 : minutes; //  if seconds greater than 30 adding 1 to minute (Ex.: 0>=30 (false) => 90)
        minutes %= 60; //  In order to get the exact minutes  (Ex.: 90%60 = 30)
        hours %= 24; // In order to get the exact hours  (Ex.: 1%24 = 1)
        return { hours, minutes };
    } catch (e) {
        return { hours: 0, minutes: 0 };
    }
};
export const retentionUnits = ['In Days', 'In Hours'];

export const minuteOptions = [0, 30, 45].map((min) => ({
    label: min,
    value: min,
    data: {
        displayValue: min,
        millisec: 1000 * 60 * min, // converting minutes to millisec (Ex.: 60000 * value)
    },
}));

export const hourOptions = range(0, 24).map((hour) => ({
    label: hour,
    value: hour,
    data: {
        displayValue: hour,
        millisec: 1000 * 60 * 60 * hour, // converting hours to millisec (Ex.: 3600000 * value)
    },
}));

export const dayOptions = range(1, 15).map((day) => ({
    label: day,
    value: day,
    data: {
        displayValue: day,
        millisec: 1000 * 60 * 60 * 24 * day, // converting days to millisec (Ex.: 86400000 * value)
    },
}));
