import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload, selectedMembersList, costEstimateState, memberForm } from 'pages/myidm/store';
import { resetCostEstimateDetails } from 'pages/myidm/utils';
import { InputInfo } from 'pages/myidm/components/input-info';

export const ADGroupType = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const [form, setForm] = useRecoilState(memberForm);
    const [, setMembers] = useRecoilState(selectedMembersList);
    const [, setcostEstimate] = useRecoilState(costEstimateState);

    const adGroupTypeApi = useMutation(() => axios.get(endPoints.myIdm.AdGroupType));

    const setADGroupType = (adGroupType, adGroupTypeDetails) => {
        setOrder({
            ...order,
            adGroupType,
            adGroupTypeDetails,
            estimatedCost: resetCostEstimateDetails,
            nonSafeDetailsGpid: [],
            mypamsafeDetails: [],
            members: [],
        });
        setForm({
            ...form,
            selectedMember: null,
            member: null,
        });
        setMembers([]);
        setcostEstimate(resetCostEstimateDetails);
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (adGrpValue) => options.find((option) => adGrpValue === option.value);
    const handleOnSelectChange = (selected) => setADGroupType(selected.label, selected);

    React.useEffect(() => {
        if (adGroupTypeApi?.isSuccess) {
            setOptions(adGroupTypeApi?.data?.data?.map((app) => ({ label: app?.label, value: app?.value, data: app })));
        }
    }, [adGroupTypeApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.adGroupType) {
            setOrder({ ...order, adGroupType: options?.[0].value, adGroupTypeDetails: options?.[0] });
        }
        if (options && options?.length && order?.adGroupTypeDetails && !order?.adGroupType) {
            const adGrp = isExistsInOptions(order?.adGroupTypeDetails?.value);
            setOrder({ ...order, adGroupType: adGrp?.label, adGroupTypeDetails: adGrp });
        }
    }, [options]);

    React.useEffect(() => {
        if (!order?.adGroupType && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.adGroupType, order?.isTouched]);

    React.useEffect(() => {
        if (order?.isNewADGroup && order?.isNewADGroup === 'yes') {
            resetOptions();
            adGroupTypeApi?.mutate();
        }
    }, [order?.isNewADGroup]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="adGroupType"
                label="Active Directory Group Type *"
                value={order?.adGroupType || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={adGroupTypeApi?.isLoading}
                isLoading={adGroupTypeApi?.isLoading}
                isRequired={isTouched}
                data-testid="adGrpType-auto"
            />
            <InputInfo title="Please Provide Active Directory Group Type" />
        </Box>
    );
};

export default React.memo(ADGroupType);
