import React from 'react';
import Modal from 'components/modal';
import { Box, Button, Stack, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios from 'api/request';
import { useMutation } from 'react-query';
import { endPoints } from 'api/endpoints';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from 'file-saver';
import Empty from 'components/empty';
import styles from '../styles';
import { toggleModal, selectedDocument } from '../store';

const HelpDocumentModal = () => {
    const [selectedDoc, setSelectedDocument] = useRecoilState(selectedDocument);
    const [isOpen, setToggleModal] = useRecoilState(toggleModal);
    const [pdfUrl, setPdfUrl] = React.useState(undefined);
    const viewDocumentApi = useMutation((payload) => axios.post(endPoints.helpDocuments.downloadDoc, payload));
    const downloadDocumentApi = useMutation((payload) => axios.post(endPoints.helpDocuments.downloadDoc, payload));

    React.useEffect(() => {
        if (selectedDoc) {
            setPdfUrl(undefined);
            const fileFormat = selectedDoc?.blobRelativeLink?.split('.')?.slice(-1)[0] || null;
            if (fileFormat === 'pdf') {
                viewDocumentApi.mutate({
                    id: selectedDoc?.id,
                    blobRelativeLink: selectedDoc?.blobRelativeLink,
                });
            }
        }
    }, [selectedDoc]);

    React.useEffect(() => {
        // istanbul ignore if
        if (viewDocumentApi?.isSuccess) {
            const blob = new Blob([viewDocumentApi?.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            setPdfUrl(fileURL);
        }
    }, [viewDocumentApi?.isSuccess]);

    React.useEffect(() => {
        // istanbul ignore if
        if (downloadDocumentApi?.isSuccess) {
            const blob = new Blob([downloadDocumentApi?.data], { type: 'application/pdf' });
            const file = new File([blob], selectedDoc?.blobRelativeLink.split(['/']).slice(-1).pop(), { type: 'application/pdf' });
            saveAs(file);
        }
    }, [downloadDocumentApi?.isSuccess]);

    const downloadButton = (
        <Button
            varient="contained"
            sx={styles.modal.downloadBtn}
            onClick={() => {
                downloadDocumentApi.mutate({
                    id: selectedDoc?.id,
                    blobRelativeLink: selectedDoc?.blobRelativeLink,
                });
            }}
            disabled={viewDocumentApi?.isLoading || downloadDocumentApi?.isLoading}
            endIcon={
                downloadDocumentApi?.isLoading ? (
                    <CircularProgress sx={styles.modal.circularprog} color="inherit" size={14} />
                ) : (
                    <FileDownloadIcon sx={styles.modal.filedownbtn} />
                )
            }
            data-testid="download-btn"
        >
            Download
        </Button>
    );

    return (
        <Modal
            handleClose={() => {
                downloadDocumentApi.reset();
                viewDocumentApi.reset();
                setPdfUrl(undefined);
                setSelectedDocument(undefined);
                setToggleModal(false);
            }}
            open={isOpen}
            title={selectedDoc?.title}
            downloadButton={downloadButton}
            showDownLoadButton={!(!viewDocumentApi?.isLoading && !viewDocumentApi.isError && !pdfUrl)}
            minwidth="40%"
            width="70%"
        >
            <Box flexGrow={1} sx={styles.modal.wrapper}>
                {!viewDocumentApi?.isLoading && viewDocumentApi.isError && (
                    <Box sx={styles.modal.emptywrapper}>
                        <Empty notFound isPopup style={styles.modal.empty}>
                            <span style={styles.modal.emptyMsg}> Unable to retrieve the document currently</span>
                        </Empty>
                    </Box>
                )}
                {!viewDocumentApi?.isLoading && !viewDocumentApi.isError && !pdfUrl && (
                    <Box sx={styles.modal.emptywrapper}>
                        <Empty notFound isPopup style={styles.modal.empty}>
                            <span style={styles.modal.emptyMsg}>This file cannot be viewed on the browser, still you can download it!</span>
                            {downloadButton}
                        </Empty>
                    </Box>
                )}
                {viewDocumentApi?.isLoading && (
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress color="inherit" size={16} sx={styles.modal.emptyMsg} />{' '}
                    </Box>
                )}
                {!viewDocumentApi?.isLoading && !viewDocumentApi?.isError && pdfUrl && selectedDoc && (
                    <Stack direction="column" spacing={1} sx={styles.modal.stackwrapper}>
                        <object data={`${pdfUrl}`} type="application/pdf" height="100%" width="100%">
                            <embed src={`${pdfUrl}`} type="application/pdf" style={styles.modal.embed} />
                        </object>
                    </Stack>
                )}
            </Box>
        </Modal>
    );
};

export default HelpDocumentModal;
