/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload } from 'pages/project-onboarding/store';

const ProgrammingLanguages = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const progLanguageApi = useMutation(() => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.programmingLanguageApi}`));
    const setprogLanguage = (progLanguage, progLanguageDetails) => {
        setOrder({
            ...order,
            progLanguage,
            progLanguageDetails,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (environmentValue) => options.find((option) => environmentValue === option.value);

    const handleOnSelectChange = (selected) => setprogLanguage(selected.value, selected);

    React.useEffect(() => {
        if (progLanguageApi?.isSuccess) {
            setOptions(progLanguageApi?.data?.data?.ProgrammingLanguages?.map((app) => ({ label: app?.name, value: app?.name, data: app })));
        }
    }, [progLanguageApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.progLanguage) {
            setOrder({ ...order, progLanguage: options?.[0].value, progLanguageDetails: options?.[0] });
        }
        if (options && options?.length && order?.progLanguage) {
            const languages = isExistsInOptions(order?.progLanguage);
            setOrder({ ...order, progLanguage: languages?.value, progLanguageDetails: languages });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        progLanguageApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.progLanguage && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.progLanguage, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="progLanguages"
                label="Programming Languages *"
                value={order?.progLanguage || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={progLanguageApi.isLoading}
                isLoading={progLanguageApi.isLoading}
                isRequired={isTouched}
                data-testid="progLanguage-auto"
            />
        </Box>
    );
};

export default React.memo(ProgrammingLanguages);
