import React from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { orderPayload, isDivisionRequired, isAppRegionRequired, isDisplayProgram } from 'pages/provisioning/virtual-service/store';
import { isDisplayField } from 'pages/provisioning/virtual-service/utils/getFunction';

export const Program = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(orderPayload);
    const divisionReq = useRecoilValue(isDivisionRequired);
    const appRegionReq = useRecoilValue(isAppRegionRequired);
    const [options, setOptions] = React.useState([]);
    const [, setDisplayProgram] = useRecoilState(isDisplayProgram);
    const [showProgram, setShowProgram] = React.useState(true);

    const programApi = useMutation((payload) => axios.post(`${endPoints.virtualService.program}`, payload));

    const setProgram = (program) => {
        setOrder({
            ...order,
            program,
            department: null,
            aksCluster: null,
            aksNamespace: null,
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (program) => options.find((option) => program === option.value);

    const handleOnSelectChange = (selected) => setProgram(selected.value);

    React.useEffect(() => {
        if (programApi?.isSuccess) {
            setShowProgram(true);
            setOptions(programApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        }
    }, [programApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.program) {
            setOrder({ ...order, program: options?.[0].value });
        }
        if (options && options?.length > 1 && order?.program) {
            const program = isExistsInOptions(order?.program);
            setOrder({ ...order, program: program?.value });
        }
    }, [options]);
    React.useEffect(() => {
        if (!programApi.isLoading && programApi?.isSuccess && showProgram && programApi?.data?.data !== undefined && isDisplayField(options, 'program')) {
            setDisplayProgram(true);
        }
    }, [options, programApi.isLoading, programApi?.isSuccess]);
    React.useEffect(() => {
        setShowProgram(false);
        if (
            !(!order?.sector || !order?.environment || (divisionReq && order?.division === null) || (appRegionReq && order?.applicationRegionDetails === null))
        ) {
            programApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                ...(order?.division && { division: order?.division }),
                ...(order?.applicationRegion && { applicationRegion: order?.applicationRegion }),
            });
        } else {
            resetOptions();
        }
    }, [order?.sector, order?.environment, divisionReq, order?.division, appRegionReq, order?.applicationRegion]);

    React.useEffect(() => {
        if (!order?.program && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.program, order?.isTouched]);

    return (
        <>
            {!programApi.isLoading && programApi?.isSuccess && showProgram && programApi?.data?.data !== undefined && isDisplayField(options, 'program') && (
                <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Select
                            key="program"
                            dataTestId="program-autocomplete"
                            label="Program Name *"
                            value={order?.program || null}
                            options={options}
                            handleOnSelect={(value) => handleOnSelectChange(value)}
                            disabled={programApi.isLoading}
                            isLoading={programApi.isLoading}
                            isRequired={isTouched}
                        />
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default React.memo(Program);
