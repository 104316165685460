/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { isProjectNameVaild, landingZoneForm, toggleCreateLandingZone } from '../../../store';

const ProjectName = () => {
    const [lzModal] = useRecoilState(toggleCreateLandingZone);
    const [landZoneForm, setLandingZoneForm] = useRecoilState(landingZoneForm);
    const [isValid, setProjectNameVaild] = useRecoilState(isProjectNameVaild);
    const [ShrinkField, setShrinkField] = React.useState(false);

    const setProjectName = (projectName) => {
        if (!lzModal?.lzDetails) {
            setLandingZoneForm({
                ...landZoneForm,
                projectName,
            });
        }
    };

    const handleChange = (value) => {
        if (value !== '' && value !== null && value !== undefined) {
            if (value?.length > 10 || value?.length < 3) {
                setProjectNameVaild(false);
                setProjectName(value);
            } else if (!/^[a-zA-Z]+$/.test(value)) {
                setProjectNameVaild(false);
                setProjectName(value);
            } else {
                setProjectNameVaild(true);
                setProjectName(value);
            }
        } else {
            setProjectNameVaild(false);
            setProjectName('');
        }
    };

    React.useEffect(() => {
        if (lzModal?.lzDetails?.config?.projectName) setProjectName(lzModal?.lzDetails?.config?.projectName);
    }, [lzModal?.lzDetails]);

    React.useEffect(() => {
        if (!ShrinkField) setShrinkField(landZoneForm?.projectName?.length > 0);
    }, [landZoneForm?.projectName]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <CssTextField
                    size="small"
                    label="Project Name"
                    fullWidth
                    onChange={(event) => handleChange(event.target.value)}
                    value={landZoneForm?.projectName}
                    InputLabelProps={{ shrink: ShrinkField }}
                    onClick={() => setShrinkField(true)}
                    // onBlur={() => landZoneForm?.projectName?.length > 0 && setShrinkField(false)}
                    autoComplete="off"
                    error={!isValid}
                    helperText={`
                    ${!isValid ? 'Project name should be min. 3 to max. 10 chars and alphabets only' : ''}
                `}
                />
                {/* <InfoImg title="Type  Endpoint Name" /> */}
            </Stack>
        </>
    );
};

export default React.memo(ProjectName);
