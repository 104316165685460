import React from 'react';
import { useRecoilState } from 'recoil';
import { costEstimateState, toggleSspModels } from 'pages/provisioning/store/event-hub';
import CostDetails from 'pages/provisioning/_components/CostDetails';

const ShowCostDetails = () => {
    const [costEstimate] = useRecoilState(costEstimateState);
    const [modal, setModal] = useRecoilState(toggleSspModels);

    return (
        <CostDetails
            type={modal?.isCost?.costType}
            isCostOpen={modal?.isCost?.isCostOpen}
            costEstimate={costEstimate}
            handleCloseModal={() => setModal({ ...modal, isCost: { costType: '', isCostOpen: false } })}
        />
    );
};

ShowCostDetails.propTypes = {};

ShowCostDetails.defaultProps = {};

export default ShowCostDetails;
