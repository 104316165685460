/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';
import Select from 'components/select';
import { landingZoneData } from 'pages/app-profiles/store';

const BusinessUnit = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [landingZoneForm, setLandingZoneForm] = useRecoilState(landingZoneData);
    const [options, setOptions] = React.useState([]);
    // eslint-disable-next-line
    const businessUnitApi = useMutation(() => axios.get(endPoints.landingZones.businessUnit));

    const setBusinessUnit = (businessUnit) => {
        setLandingZoneForm({
            ...landingZoneForm,
            businessUnit,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        // setBusinessUnit(null);
    };
    const seldValueExistsInOptions = (regionValue) => options.find((value) => regionValue === value?.label);
    const handleOnSelectChange = (selected) => {
        if (seldValueExistsInOptions(selected?.label)) {
            setBusinessUnit(selected?.label);
        } else {
            setBusinessUnit(null);
        }
    };
    React.useEffect(() => {
        if (businessUnitApi?.isSuccess) {
            setOptions(businessUnitApi?.data?.data?.map((app) => ({ label: app, value: app, data: app })));
        }
    }, [businessUnitApi?.isSuccess]);
    React.useEffect(() => {
        if (options?.length && options?.length > 0 && landingZoneForm?.businessUnit) {
            const businessUnit = options.find((value) => landingZoneForm?.businessUnit === value?.label);
            setBusinessUnit(businessUnit?.label);
        }
    }, [options]);

    React.useEffect(() => {
        businessUnitApi.mutate();
        return () => resetOptions();
    }, []);

    React.useEffect(() => {
        if (!landingZoneForm?.businessUnit && landingZoneForm?.isTouched) setIsTouched(true);
    }, [landingZoneForm?.businessUnit, landingZoneForm?.isTouched]);
    return (
        <Box bgcolor="white">
            <Select
                key="businessUnit"
                value={landingZoneForm?.businessUnit || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={businessUnitApi.isLoading}
                isLoading={businessUnitApi.isLoading}
                disableClearable={false}
                label="Business Unit"
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(BusinessUnit);
