import { RI001 } from './RI/RI001';
import { RI002 } from './RI/RI002';
import { RI003 } from './RI/RI003';
import { RI003O } from './RI/RI003O';
import { RI004 } from './RI/RI004';
import { RI003A } from './RI/RI003A';
import { RI004A } from './RI/RI004A';
import { RI005 } from './RI/RI005';
import { COMMON } from './RI/COMMON';
import { Day2Common } from './Day2Operations/Day2Common';
import { ProjectOnboarding } from './Day2Operations/ProjectOnboarding';
import { RI001A } from './RI/RI001A';

export const endPoints = {
    catalog: {
        get: '/api/static/lookups/1.0/catalog',
        allList: '/api/static/lookups/1.0/catalogs',
    },
    user: {
        auth: '/user/auth',
    },
    ssp: {
        dashboard: {
            sql: '/api/static/lookups/1.0/elastic-search/eiap-ece/sql',
            es: '/api/static/lookups/1.0/elastic-search/eiap-ece/dsl',
            applicationOnBoard: '/api/elastic/lookups/1.0/application-onboarded',
            provisionedResources: '/api/elastic/lookups/1.0/provisioned-resources',
            orderTrend: '/api/elastic/lookups/1.0/order-trend',
            aggregatedCount: '/api/elastic/lookups/1.0/aggregated-count',
            catalogs: '/api/static/lookups/1.0/Azure/cloudservices',
            services: 'dashboard-service.json',

            //       dashboardenhancementapi's
            orderTrendMock: '/dashboard-enhancement/orderTrend.json',
            metricApi: 'api/elastic/lookups/1.0/metrics',
            applicationOnBoarded: '/dashboard-enhancement/applicationsOnboarded.json',
            catalogsCount: '/dashboard-enhancement/catalogCounts.json',
            catalogsProvisioned: '/dashboard-enhancement/catalogsProvisioned.json',
            servicesByCatalog: '/dashboard-enhancement/servicesByCatalog.json',
            servicesByMonth: '/dashboard-enhancement/servicesByMonth.json',
            // servicesByCatalog: '/dashboard-enhancemnt/servicesByCatalog.json',
            solutionByMonth: '/dashboard-enhancement/solutionsByMonth.json',
            solutionsByCatalog: '/dashboard-enhancement/solutionsBycatalog.json',
            orderProcessingService: '/dashboard-enhancement/orderProcessingService.json',
            orderProcessingSolution: '/dashboard-enhancement/orderProcessingSolutions.json',
        },
        applicationDetails: '/api/cmdb/lookups/1.0/applications',
        vm: {
            appProfiles: '/api/app-registrations/2.0/provisioning/app-profiles',
            appProfileDetails: '/api/app-registrations/1.0/app-profiles',
            appTiers: '/api/app-registrations/1.0/app-tiers',
            appTiers_v2: '/api/static/lookups/1.0/app-tiers',
            azureManagedDisks: '/selfserviceprovisioning/vm/azure-managed-disks',
            costCalculation: '/selfserviceprovisioning/vm/cost-calculation',
            dataDisks: '/selfserviceprovisioning/vm/data-disks',
            environments: '/api/app-registrations/1.0/environments',
            environmentDetails: '/api/app-registrations/1.0/environment-details',
            environmentDetails_v2: '/api/app-registrations/2.0/environment-details',
            hoursOfOperation: '/selfserviceprovisioning/vm/hours-of-operation',
            linuxDiskConfigurations: '/selfserviceprovisioning/vm/linux-data-disk-config',
            windowsDiskConfigurations: '/selfserviceprovisioning/vm/windows-data-disk-config',
            defaultDataDisk: '/api/static/lookups/1.0/oel-data-disks',
            mandatoryTags: '/selfserviceprovisioning/vm/mandatory-tags',
            osTypes: '/selfserviceprovisioning/vm/ostypes',
            // osVersions: '/selfserviceprovisioning/vm/osversions',
            osVersions: 'selfserviceprovisioning/vm/marketplace-images',
            customMarketPlaceImages: 'api/azure/lookups/1.0/custom-images',
            customImages: 'api/azure/lookups/2.0/custom-images',
            powerBrokerRole: '/selfserviceprovisioning/vm/powerbrokerrole',
            regions: '/api/app-registrations/1.0/regions',
            resourceGroupTags: '/selfserviceprovisioning/vm/resourcegroup/',
            timezones: '/selfserviceprovisioning/vm/os-timezones',
            types: '/selfserviceprovisioning/vm/vmtypes',
            vmTypes: 'api/azure/lookups/1.0/vmsizes',
            submitOrder: '/selfserviceprovisioning/vm/submit-order',
            disasterRecoveryOptions: '/selfserviceprovisioning/vm/disaster-recovery-options',
            azureRecoveryVault: '/selfserviceprovisioning/vm/azure-revovery-vault',
            hCode: 'api/cmdb/lookups/1.0/projects',
            adOu: 'api/static/lookups/1.0/ad-organizational-units',
            sector: 'api/static/lookups/1.0/sectors',
            adDomain: 'api/static/lookups/1.0/ad-domains',
            billingEntities: 'api/static/lookups/1.0/billing-entities',
            sam: 'api/idm/lookups/samaccounts',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/vm',
            serverName: 'api/cmdb/lookups/1.0/servers',
        },
        couchbase: {
            dbVersions: 'api/static/lookups/1.0/couchbase/dbversions',
            // nodeCount: 'couchbaseNodeCount.json',
            defaults: 'api/static/lookups/1.0/catalog/COUCHBASEDB/defaults',
            serviceMappings: 'api/static/lookups/1.0/couchbase/servicemapping',
            bucketTypes: 'api/static/lookups/1.0/couchbase/buckettypes',
        },
        aks: {
            departments: 'api/eip/lookups/2.0/departments/AKS',
            businessUnit: 'api/eip/lookups/1.0/business-units',
            cluster: 'api/eip/lookups/1.0/clusters/AKS',
            catalogSize: 'api/eip/lookups/1.0/catalog-sizes',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/aksns',
            validate: 'api/eip/lookups/1.0/validate',
            adGroup: 'api/eip/lookups/1.0/all-adgroups',
            validateADGroup: 'api/eip/lookups/1.0/validate-adgroup-name',
            network: 'api/eip/lookups/1.0/clusters/network',
        },
        storage: {
            dataClassification: 'api/static/lookups/1.0/application-data-classification',
            redundancy: 'api/static/lookups/1.0/storage-replication',
            premiumAccountType: 'api/static/lookups/1.0/storage-premium-account-type',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/paas/azure-storage',
            accountName: 'api/azure/lookups/1.0/validate-storage-account-name',
        },
        oel: {
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/iaas/oracle',
        },
        oracle: {
            oracleDataDisks: 'api/static/lookups/1.0/oracle-data-disks',
            oracleQuickConfig: 'selfserviceprovisioning/vm/1.0/config/iaas/oracle',
            dbCharacterSet: 'api/static/lookups/1.0/oracle-database-character-sets',
            dbNationalCharacterSet: 'api/static/lookups/1.0/oracle-national-character-sets',
            oracleId: 'api/idm/lookups/oracleaccounts',
        },
        sqlPaas: {
            serverCollation: 'api/azure/lookups/sql/collations',
            deploymentModel: 'api/azure/lookups/sql/deploymentmodels',
            serviceTiers: 'api/azure/lookups/sql/servicetiers',
            capabilities: 'api/azure/lookups/sql/capabilities',
            storageSizes: 'api/azure/lookups/sql/max-storagesizes',
            logicalHosts: 'api/azure/lookups/sql/logical-servers',
            elasticPoolNames: 'api/azure/lookups/1.0/elastic-pool',
            sqlDatabases: 'api/azure/lookups/sql/logical-databases',
            checkLogicalHostExists: 'api/azure/lookups/sql/validate-server',
            costCalculation: 'api/azure/lookups/sql/cost-calculation',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/sqlpaas',
        },
        sqlIaas: {
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/iaas/sql',
            sqlVersions: '/api/static/lookups/1.0/sql-versions',
            sqlService: '/api/static/lookups/1.0/sql-services',
            sqlDataDisks: 'api/static/lookups/1.0/sql-data-disks',
        },
        nsg: {
            submitRule: '/api/nsg/automation/ansible/1.0/rule',
            allList: '/catalog.json',
        },
        adGroup: 'api/azure/lookups/1.0/ad-groups',
        sku: {
            types: 'api/azure/lookups/1.0/landingzone-reference-types',
            skuList: 'api/azure/lookups/1.0/landingzone-reference',
            skuListVmTypes: 'api/azure/lookups/1.0/landingzone-reference-vmsizes',
            vmTypeUpdate: 'api/azure/lookups/1.0/vmskus-reference-update',
        },
        keyVault: {
            keyVaultName: '/api/azure/lookups/1.0/validate-key-vault-name',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/key-vault',
            costCalculation: '/keyVaultCostSummary.json',
        },
        alb: {
            sessionPersistence: 'api/azure/lookups/1.0/session-persistance',
            protocols: 'api/azure/lookups/1.0/protocols',
            availZones: 'api/azure/lookups/1.0/alb/availability-zones',
            virtualMachine: 'api/azure/lookups/1.0/virtual-machines',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/load-balancer',
        },
        kafka: {
            departments: 'api/eip/lookups/2.0/departments/Kafka',
            cluster: 'api/eip/lookups/1.0/clusters/Kafka',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/kafka',
            validate: 'api/eip/lookups/1.0/validate-kafka-topic',
            retention: 'kafka-retention-periods.json',
        },
        privateEndpoint: {
            applications: 'api/app-registrations/3.0/all-app-profiles',
            environments: 'api/static/lookups/1.0/environments',
            regions: 'api/azure/lookups/locations',
            dataClassification: 'api/static/lookups/1.0/application-data-classification',
            resource: 'api/azure/lookups/private-endpoint/1.0/location/region/subscription/subscriptionId/resources',
            resourceType: 'api/azure/lookups/private-endpoint/1.0/resource-types',
            endpointType: 'api/azure/lookups/private-endpoint/1.0/private-endpoint-type',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/azure-private-endpoint',
            validatePrivateEndPoint: 'api/azure/lookups/private-endpoint/1.0/validate-private-endpoint',
            dns: 'api/azure/lookups/private-endpoint/1.0/dns',
            targetSubResource: 'api/azure/lookups/private-endpoint/1.0/location/region/subscription/subscriptionId/resources',
            modifiedSubscription: 'api/azure/lookups/private-endpoint/1.0/modified-subscriptions',
            resources: '/1.0/location/region/subscription/subscriptionId/resources',
            validate: 'api/azure/lookups/private-endpoint/1.0/location/region/subscription/subscriptionId/databricks/value/validate-databricks-name',
        },
        privateEndpointV2: {
            applications: 'api/app-registrations/3.0/all-app-profiles',
            environments: 'api/static/lookups/1.0/environments',
            resourceType: 'private-endpoint/1.0/resource-types',
            resources: 'private-endpoint/1.0/resource-details',
            resourcesSubType: 'private-endpoint/1.0/sub-resource-types',
            validate: 'private-endpoint/1.0/internal/validate',
            preview: 'private-endpoint/1.0/preview',
            costCalculation: 'api/costs/1.0/cost-calculation',
            order: 'PE/order.json',
            submit: 'private-endpoint/order-submit',
            reSubmit: 'private-endpoint/order-resubmit',
        },
        eventHub: {
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/azure-eventhub',
            eventHubLookup: 'api/azure/lookups/1.0/event-hub',
            validateNamespace: 'api/azure/lookups/1.0/validate-event-hub',
            throughputUnits: 'api/azure/lookups/1.0/event-hub/throughput-units',
            processingUnits: 'api/azure/lookups/1.0/event-hub/processing-units',
        },
        day2Operation: {
            // projects: '/day2-projects.json',
            projects: '/api/static/lookups/projects',
            serverNames: '/day2-server-names.json',
            modificationTypes: '/day2-modification-types.json',
            configureNewSku: '/day2-configure-new-sku.json',
            environmentDetails: '/day2-env-details.json',
            quickConfig: '/day2-config.json',
            sku: '/day2-sku.json',
            iops: '/day2-iops.json',
            ram: '/day2-ram.json',
            storageType: '/day2-storage-type.json',
            diskSku: '/day2-disk-sku.json',
        },
        day2OperationPass: {
            modificationTypes: '/day2pass-modification-types.json',
            storageAccounts: '/day2pass-storage-ac.json',
            capabilities: '/day2pass-capabilities.json',
            modifyService: '/day2pass-modify-service.json',
            tier: '/day2pass-tier.json',
            redundancy: '/day2pass-redundancy.json',
            targetSub: '/day2pass-target-sub.json',
            resourceGrp: '/day2pass-resource-grp.json',
            quickConfig: '/day2paas-config.json',
        },
        eipStack: {
            ipAddress: 'api/eip/lookups/1.0/clusters/ip',
            catalogSize: 'api/static/lookups/1.0/cle/catalog-sizes',
        },
        azureDataFactory: {
            version: 'api/static/lookups/1.0/adf-versions',
            quickConfig: 'selfserviceprovisioning/vm/1.0/config/paas/adf',
            validateAdfName: 'api/azure/lookups/1.0/validate-adf-name',
        },
        submitOrder: '/orders',
        costCalculation: 'api/costs/1.0/cost-calculation',
    },
    cart: {
        getCartCount: 'api/cart/1.0/cart/item/count',
        getCart: 'api/cart/1.0/cart', // /cart.json or api/cart/1.0/cart
        getCartItem: 'api/cart/1.0/cart/item', // /cartItem.json or api/cart/1.0/cart/item/<cartItemId>
        addItem: 'api/cart/1.0/cart/item', // api/cart/1.0/cart/item/<cartItemId>
        submitOrder: 'api/cart/1.0/cart/submit',
        deleteCartItemByApplication: 'api/cart/1.0/cart/application/items',
    },
    appProfile: {
        allAppProfiles: 'api/app-registrations/1.0/all-app-profiles',
        myAppProfiles: 'api/app-registrations/1.0/app-profiles',
        appProfileById: 'api/app-registrations/1.0/app-profiles',
        businessCI: 'api/cmdb/lookups/1.0/applications',
        appOwner: 'api/employees/2.0/employee/list',
        submitAppProfile: 'api/app-registrations/1.0/app-profile',
    },

    landingZones: {
        subscription: 'api/azure/lookups/subscriptions',
        resourceGroup: 'api/azure/lookups/resourceGroups',
        Vnet: 'api/azure/lookups/vnet',
        Subnet: 'api/azure/lookups/1.0/subnets',
        NetSecGroup: 'api/azure/lookups/nsg',
        AppSecGroup: 'api/azure/lookups/asg',
        ProxProfileGroup: 'api/azure/lookups/1.0/proximity-placement-groups',
        availabilitySet: 'api/azure/lookups/1.0/availability-sets',
        availabilityZone: 'api/azure/lookups/1.0/availability-zones',
        cloudProvider: 'api/static/lookups/1.0/cloudproviders',
        cloudService: 'api/static/lookups/1.0',
        createLandingZone: 'api/app-registrations/2.0/landing-zone',
        editLandingZone: 'api/app-registrations/1.0/landing-zone',
        approveDraftLZ: 'api/app-registrations/1.0/approve-draft-landing-zone',
        environments: 'api/static/lookups/1.0/environments',
        eipEnvironments: 'api/eip/lookups/1.0/environments',
        appTier: 'api/static/lookups/1.0/applicationtiers',
        regions: 'api/azure/lookups/locations',
        eipRegions: 'api/eip/lookups/1.0/regions',
        geoRecoveryRegion: 'api/azure/lookups/1.0/location-pair',
        landingZones: 'api/app-registrations/1.0/landing-zones',
        landingZone: 'api/app-registrations/1.0/landing-zone',
        replication: 'api/static/lookups/1.0/storage-replication',
        departments: 'api/eip/lookups/2.0/departments',
        eipDepartments: 'api/eip/lookups/1.0/catalog-departments',
        dataClassification: 'api/static/lookups/1.0/application-data-classification',
        businessUnit: 'api/eip/lookups/1.0/business-units',
        cluster: 'api/eip/lookups/1.0/clusters',
        sku: '/azure-alb-sku.json',
        type: '/azure-alb-type.json',
        privateEndpoint: {
            endPointType: 'api/azure/lookups/private-endpoint/1.0/private-endpoint-type',
            subscription: 'api/azure/lookups/private-endpoint/1.0/subscriptions',
            resourceGroup: 'api/azure/lookups/private-endpoint/1.0/location/{region}/subscription/{subscriptionId}/resource-groups',
            vNet: 'api/azure/lookups/private-endpoint/1.0/location/{region}/subscription/{subscriptionId}/vnet',
            subnet: 'api/azure/lookups/private-endpoint/1.0/subnets',
        },
        eipStack: {
            components: 'api/static/lookups/1.0/cloudprovider/{cloudprovider}/catalog/{cloudservice}',
            connection: 'api/static/lookups/1.0/connections',
            costCalculation: '/eiap-costCalcuation.json',
            // components: 'eip-landingzone-components.json',
        },
    },
    order: {
        myOrders: '/my-orders',
        allOrders: '/all-orders',
        getOrder: '/orders/details/{orderId}',
        getCatalogs: '/order/catalogs/{orderId}',
        getComponentLogs: '/orders/component/logs',
        getLogs: '/orders/logs/{orderId}',
        getLogsUpdated: '/orders/logs/{orderId}/{subOrderId}',
        cancelOrder: '/cancelorder',
        orderDetails: '/orders',
        changeManger: '/order/change-manager',
        mangerList: '/api/employees/1.0/managers',
        orderStatus: '/order-statuses',
        saveOrder: '/order/{orderId}/catalog/{catalogServiceId}',
        allAppProfiles: '/api/app-registrations/2.0/all-app-profiles',
    },
    approvals: {
        myApprovals: '/my-approvals',
        approveOrRejectOrders: '/orders/manage',
        changeCostCenter: '/approver/order',
    },

    helpDocuments: {
        getDocuments: '/api/azure/lookups/1.0/documents',
        downloadDoc: '/api/azure/lookups/1.0/download-attachment',
    },
    general: {
        support: 'mailto:DL-GlobalCMPOperations@pepsico.com?subject=Query on CMP Services:',
        help: '/help',
        feedback:
            'https://forms.office.com/Pages/ResponsePage.aspx?id=lTLMQg7NnES5jlzltWDB083kw2uYiVRKpnB6VMbU87FUQUk0TVdXVDhaTzZESU1ZWllNUTYyVDBTUC4u&wdLOR=cCD537E5B-2024-4780-B8D1-40098B305781',
        welcomepdf: 'https://pepsico.sharepoint.com/:w:/t/CTO-DigitalServices/EYYTlpq9e7RFrjPRNo5BZwIBOUJAHU4qZYb3heO1j-b8xg?e=dceTpP',
    },

    ssoOkta: {
        applicationCI: 'api/cmdb/lookups/1.0/business-application/services',
        businessApp: 'api/cmdb/lookups/1.0/business-application',
        environments: 'api/static/lookups/v2/1.0/environments',
        sectors: 'api/static/lookups/1.0/sectors',
        applicationType: 'api/static/lookups/1.0/application-types',
        dataClassification: 'api/static/lookups/1.0/application-data-classification',
        appShortNameValidation: 'api/ri/resource/name/1.0/app-short-name/availability',
        appShortName: 'api/ri/resource/name/1.0/app-short-name/available',
        Jwt: {
            submitOrder: 'api/day2/1.0/internal/order/sso-okta/submit',
            saveChangesInOrder: 'api/day2/1.0/internal/order/sso-okta',
        },
    },
    onboardAppService: {
        environments: 'api/static/lookups/1.0/environments',
        sectors: 'api/static/lookups/1.0/sectors',
        businessCriticality: 'api/static/lookups/1.0/business-criticality',
        Jwt: {
            submitOrder: 'api/order/snow/1.0/application-service-creation',
        },
    },
    securityTool: {
        applicationCI: 'api/app-registrations/3.0/all-app-profiles',
        environments: 'api/static/lookups/1.0/environments',
        sectors: 'api/static/lookups/1.0/sectors',
        applicationOwner: 'api/employees/3.0/employee/list',
        member: 'api/employees/2.0/employee/list',
        Jwt: {
            teamName: 'api/day2/1.0/internal/order/security-tool/team-name/sector',
            createOrder: 'api/day2/1.0/internal/order/security-tool/submit',
            editOrder: 'api/day2/1.0/internal/order/security-tool',
        },
    },
    day2Operation: {
        Day2Common,
        ProjectOnboarding,
    },
    riServices: {
        COMMON,
        RI001,
        RI002,
        RI003,
        RI003O,
        RI003A,
        RI004,
        RI004A,
        RI005,
        RI001A,
        order: {
            // getOrderLogCatalogs: 'api/order/v2/1.0/order/catalogs/{orderId}',
            getOrderLogCatalogs: 'api/order/v2/internal/1.0/order/catalogs/{orderId}',
        },
        downloadApplyOutput: 'api/order/v2/1.0/internal/order',
        approval: {
            orderDetails: 'api/order/v2/1.0/cmp/order',

            // orderDetails: 'project-onboarding-approval-details.json',
        },
        Jwt: {
            // orders
            allOrders: 'api/order/v2/1.0/all-orders',
            myOrders: 'api/order/v2/1.0/my-orders',
            orderDetails: 'api/order/v2/1.0/cmp/order',
            changeManger: 'api/order/v2/1.0/order/change-manager',
            cancelOrder: 'api/order/v2/1.0/cmp/cancelorder', //    api/order/v2/1.0/cancelorder
            getOrderLogDetails: 'api/order/v2/1.0/orders/logs',

            // approvals
            myApprovals: 'api/order/v2/1.0/my-approvals',
            approve: 'api/order/v2/1.0/cmp/orders/approve-order', //   api/order/v2/1.0/orders/approve-order
            reject: 'api/order/v2/1.0/cmp/orders/reject-order', //  api/order/v2/1.0/orders/reject-order
            returnToUser: 'api/order/v2/1.0/cmp/orders/return-to-user',
            costCenter: 'api/order/v2/approver/order',

            // provisioning
            catalogs: 'api/ri-metadata-lookups/reference-implementation/v1/list',
            architecture: 'api/ri-metadata-lookups/reference-implementation/v1/{rid}/architecture',
            // internal
            catalogsInternal: 'api/ri-metadata-lookups/reference-implementation/v1/internal/list',
        },
    },
    inventoryReport: {
        catalogTypes: '/api/inventory/report/1.0/inventory-catalogs-types',
        listAks: '/api/inventory/report/1.0/inventory-report',
        downloadExcel: '/api/inventory/report/1.0/export-inventory-report',
    },
    myIdm: {
        sectors: 'api/static/lookups/1.0/sectors',
        isexitAdGrp: '/myidm/nonexistadgrp.json',
        AdGroupType: '/myidm/adGroupsType.json',
        applicationNameApi: 'api/cmdb/lookups/1.0/business-application/services',
        adGroupApi: 'api/ri/order/myidm/1.0/all-adgroups',
        adGroupMembersApi: 'api/ri/order/myidm/1.0/group-membership', //     /myidm/adGroupsMembers.json
        adGroupIsUniversalApi: 'api/ri/order/myidm/1.0/validate/universal-group', //      /myidm/adgrp-isUniversal.json
        isCrossDomainUser: 'api/ri/order/myidm/1.0/validate/cross-domain-user', //    /myidm/adgrp-crossdomainUser.json
        mypamsafe: 'api/ri/resource/name/1.0/privileged-addmember/validate', // '/api/ri/resource/name/1.0/privileged-addmember/validate'     ///myidm/getmypamsafe.json
        myIdmCost: '/myidm/cad-grpCostRes.json',
        adGroupDetailsApi: 'api/eip/lookups/1.0/adgroups-details',
        Jwt: {
            createOrder: 'api/day2/1.0/internal/order/adgroup/submit',
            editOrder: 'api/day2/1.0/internal/order/adgroup',
        },
    },
    serviceAccountCreation: {
        accountTypes: 'api/static/lookups/1.0/service-account',
        applicationService: 'api/app-registrations/3.0/all-app-profiles',
        environments: 'api/static/lookups/1.0/environments',
        appOwner: 'api/employees/3.0/employee/list',
        sqlServerName: 'api/static/lookups/v2/1.0/sqlserver-details?applicationService',
        sqlServerNameCreate: 'api/static/lookups/v2/1.0/sqlserver-details',
        sectors: 'api/static/lookups/1.0/sectors',
        orderDetails: 'serviceOrderDetails.json',
        Jwt: {
            createOrder: 'api/day2/1.0/internal/order/service-account/submit',
            editOrder: 'api/day2/1.0/internal/order/service-account',
            appShortNameValidation: 'api/ri/resource/name/1.0/app-short-name-service-account/validate',
        },
    },
    onBoardDevelopers: {
        applicationCI: 'api/app-registrations/3.0/all-app-profiles',

        Jwt: {
            submitOrder: 'api/day2/1.0/internal/order/onboarding-developer/submit',
        },
    },
    applicationGateWayURL: {
        environmentDetails: 'api/app-gateway/1.0/properties?isPreview=true', //       //appGateWayurl-envdetails.json
        submitApi: '1.0/appgateway/order/submit', // /ri-submit-order.json
        costCalculation: '/appGatewayURL-costCalculations.json',
        applicationDetails: 'api/cmdb/lookups/1.0/applications/',
        resubmit: '1.0/appgateway/order/resubmit',
        SupportCerts: 'api/static/lookups/v2/1.0/ssl-certificate', //  https://cloud.dev.mypepsico.com/cmp/api/static/lookups/v2/1.0/ssl-certificate
        programApi: 'api/static/lookups/v2/2.0/program',
        validScanRequirementApi: '/appGateUrl-scanRequirement.json',
        isaValidation: 'api/order/snow/1.0/isa-number',
        scanValidation: 'api/order/snow/1.0/scan-number', //         /appGatewayUrl-scanApi.json
    },
    mdt: {
        applicationCI: 'api/app-registrations/3.0/all-app-profiles',
        appOwner: 'api/employees/3.0/employee/list',
        environment: 'api/static/lookups/1.0/environments',
        sectors: 'api/static/lookups/1.0/sectors',
        Jwt: {
            source: 'api/static/lookups/v2/1.0/source-type',
            sourceValue: 'api/static/lookups/v2/1.0/source-value',
            submitOrder: 'api/day2/1.0/internal/order/mdt/submit',
            sourceNameValidation: 'api/day2/1.0/internal/order/mdt/validate/mdt-filepatterns', // 'mdt-sourceFilenamePattern-success.json',
        },
    },
    virtualService: {
        applicationService: 'api/app-registrations/3.0/all-app-profiles',
        applicationOwner: 'api/employees/3.0/employee/list',
        sector: 'api/static/lookups/v2/1.0/sectors',
        environment: 'api/static/lookups/v2/1.0/environments',
        appRegions: 'api/static/lookups/v2/1.0/regions',
        program: 'api/static/lookups/v2/1.0/program',
        cluster: 'api/static/lookups/v2/1.0/internal/aks-cluster',
        costDetails: 'api/services/virtual-service/1.0/cost-calculation',
        orderDetails: '/orders',
        submitOrder: 'api/services/virtual-service/orders',
        validationStatus: 'api/services/orders/{orderId}/validation-status',
        namespaceValidate: 'api/services/virtual-service/1.0/aks/namespace/validate',
        costCenter: 'api/cmdb/lookups/1.0/projects',
        certificateValidation: 'api/services/virtual-service/1.0/certificate/details',
        orderDetails1: 'virtual-service/order-details.json',
        manager: 'api/employees/1.0/employee',
        Jwt: {
            allowedMethods: 'api/services/virtual-service/cors/1.0/allowed-methods',
            allowedHeaders: 'api/services/virtual-service/cors/1.0/allowed-headers',
            exposeHeaders: 'api/services/virtual-service/cors/1.0/expose-headers',
            restrictedPorts: 'api/services/virtual-service/1.0/restricted-ports',
            vsOnboardTypes: 'api/services/virtual-service/1.0/onboard-types',
        },
    },
};

export const routerLinks = {};
